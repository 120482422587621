import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, Dialog, DialogActions, DialogContent,
  DialogTitle, FormControlLabel, Typography
} from '@material-ui/core';
import CheckboxLight from '@components/inputs/checkbox-light';
import { eidv } from '@app/api/client';
import EVStatusCard from './eidv-status-card';
import { manualApproveDto } from '../dto';
import { useSnackbar } from 'notistack';

const allSteps = {
  'basic-details': true,
  'photo-document': true,
  'facial-match': true,
  'address-information': true,
  'proof-of-address': true,
}

export default function EVApproveDialog({ open, onClose, steps, data, fetchData }) {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedSteps, setSelectedSteps] = useState({ ...allSteps })

  useEffect(() => {
    setSelectedSteps({ ...allSteps })
  }, [open])

  const filteredSteps = useMemo(() => {
    return steps.filter(step => step.link !== 'overview' && step.status === 'pending approval')
  }, [steps])

  const handleSave = async () => {
    for (let index = 0; index < filteredSteps.length; index++) {
      const step = filteredSteps[index]
      if (selectedSteps[step.link]) {
        let approveData;
        switch (step.link) {
          case 'basic-details':
            approveData = manualApproveDto.basicDetails(data);
            await eidv.updateAuthenticityVerificationUserData(approveData, data?.id)
            break;

          case 'photo-document':
            approveData = manualApproveDto.photoId(data);
            await eidv.updateAuthenticityVerificationUserData(approveData, data?.id)
            break;

          case 'facial-match':
            approveData = manualApproveDto.facialMatch(data);
            await eidv.updateFacialMatchUserData(approveData, data?.id)
            break;

          case 'address-information':
            approveData = manualApproveDto.addressInformation(data);
            await eidv.updateAddressInformationUserData(approveData, data?.id)
            break;

          case 'proof-of-address':
            approveData = manualApproveDto.proofOfAddress(data);
            await eidv.updateAddressInformationUserData(approveData, data?.id)
            break;

          default:
            break;
        }
      }
    }

    enqueueSnackbar('eIDV selected step(s) were successfully approved!', { variant: 'success' })
    fetchData();
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>
        Approve Selected Steps
      </DialogTitle>
      <DialogContent>
        <Typography style={{ color: '#484848' }} align="center">Select the steps you wish to manually approve within this session.</Typography>
        <Box mt={2} display="flex" justifyContent="center">
          <Box>
            {filteredSteps.map(step => (
              <Box>
                <FormControlLabel
                  labelPlacement="end"
                  label={(
                    <EVStatusCard
                      {...step}
                      noHover
                    />
                  )}
                  control={
                    <CheckboxLight />
                  }
                  onChange={() => setSelectedSteps({ ...selectedSteps, [step.link]: !selectedSteps[step.link] })}
                  checked={selectedSteps[step.link] || false}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button style={{ width: '160px' }} variant={'outlined'} onClick={onClose}>
          CANCEL
        </Button>
        <Button
          style={{ width: '160px' }}
          variant={'contained'}
          onClick={handleSave}
          type="submit"
          disabled={filteredSteps.every(e => !selectedSteps[e.link])}
        >
          {'APPROVE'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
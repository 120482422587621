export const PLACEHOLDER = {
  Documents: 'Document',
  People: 'Person',
  'Legal Entities': 'Legal Entity',
};

export const PAGE_SIZE = 15;

export const BADGE_COLOR = {
  DOCUMENT: {
    background: '#C0A2B8',
    color: '#FFF',
  },
  PERSON: {
    background: '#D5F0F0',
  },
  'LEGAL ENTITY': {
    background: '#D1C2B6',
  },
};

export const RISK_COLOR = {
  low: '#4E922C',
  medium: '#A98320',
  high: '#B6514C',
};

export const DEBOUNCE_DELAY = 1000;

export const DOCUMENTS = {
  AUTHENTICITY_VERIFICATION: 'Photo ID',
  ADDRESS: 'Proof of Address',
};

export const RADIO_GROUP = [
  { value: 'all', label: 'View All' },
  { value: 'legal_entity', label: 'View Legal Entities' },
  { value: 'person', label: 'View People ' },
];

export const SWITCH_GROUP = [
  { value: 'client', label: 'Client Reviews' },
  { value: 'monitoring', label: 'Monitoring Reviews' },
];

export const TABS = [
  {
    id: 'schedule',
    label: 'My To Do List',
  },
  {
    id: 'calendar',
    label: 'Calendar',
  },
];

export const EXPIRATION_CHECKS = {
  idDocuments: ['NEVER', 'USER_DEFINED_NOT_REQUIRED', 'USER_DEFINED_REQUIRED'],
  proofOfAddress: ['PER_TYPE_DEFINED'],
  all: [
    'NEVER',
    'PER_TYPE_DEFINED',
    'USER_DEFINED_NOT_REQUIRED',
    'USER_DEFINED_REQUIRED',
  ],
};

export const EXCLUDED_TABS = [
  { label: 'ID Documents', value: 'idDocuments', checked: true },
  {
    label: 'Proof of Address Documents',
    value: 'proofOfAddress',
    checked: false,
  },
];

export const SORTABLE_COLUMNS = {
  legal_entity: ['name', 'riskRating', 'country', 'review'],
  person: ['name', 'riskRating', 'country', 'review'],
  all: ['riskRating', 'review'],
  eidv: ['statusLabel', 'initiatedDate', 'updatedDate'],
  document: ['documentName', 'uploadDate', 'issueDate', 'expiryLabel'],
};

export const BUTTONS_GROUP = [
  { label: '1 Month', value: 1, active: false },
  { label: '3 Months', value: 3, active: false },
  { label: '6 Months', value: 6, active: true },
];

export const DAYS = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

export const CLIENT_TYPE = {
  People: 'PERSON',
  'Legal Entities': 'LEGAL_ENTITY',
};

export const MONTHS = [
  { id: '01', name: 'January', key: 1 },
  { id: '02', name: 'February', key: 2 },
  { id: '03', name: 'March', key: 3 },
  { id: '04', name: 'April', key: 4 },
  { id: '05', name: 'May', key: 5 },
  { id: '06', name: 'June', key: 6 },
  { id: '07', name: 'July', key: 7 },
  { id: '08', name: 'August', key: 8 },
  { id: '09', name: 'September', key: 9 },
  { id: '10', name: 'October', key: 10 },
  { id: '11', name: 'November', key: 11 },
  { id: '12', name: 'December', key: 12 },
];

export const GET_MONTHS = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
};

import DocumentManagement from '@views/protected/admin/document-management';

const routes = [
  {
    path: '/document-management/:tab?',
    component: DocumentManagement,
    guest: false,
  },
]

export default routes;
import React, {useMemo} from 'react';
import { Modal } from '@components/modals';


export default function CategoryDeleteDialog({ categoryName, open, onClose, onConfirm }) {

  const title = 'Delete Category'

  const description = useMemo(() => `Are you sure you want to delete document category "${categoryName}"?\n This will remove "${categoryName}" from all entities in your account.`, [categoryName])

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title={title}
        mainText={description}
        actions={
          [
            {
              type: 'main',
              label: 'NO',
              action: onClose,
              style: { width: 160 },
            },
            {
              type: 'secondary',
              label: 'YES',
              action: onConfirm,
              style: { width: 160 },
            },
          ]
        }
        actionsDirection="row"
      />
    </React.Fragment>
  );
}
import React, {useMemo} from 'react';

import {
  Box,
  Typography
} from '@material-ui/core';
import {
  EditIcon,
  SearchHistoryInactiveIcon,
  WarningIcon
} from '@app/icons';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import history from '@app/history';
import CustomTooltip from '@components/controls/tooltip';
import prepareTimestamp from '@utils/date';
import grey from '@material-ui/core/colors/grey';

export default function ComplianceProfileView(props) {

    const { data, setData, baseUri, readonly } = props;//eslint-disable-line

  const onUpdate = () => {
    history.push(`/portfolio/periodic-reviews/${data.id}`)
  }

  const lastReview = useMemo(() => {
    return data?.last_review_at
      ? prepareTimestamp(data.last_review_at, 'MM/DD/YYYY', true)
      : null
  }, [data])

  const nextReview = useMemo(() => {
    return data?.next_review_at
      ? prepareTimestamp(data.next_review_at, 'MM/DD/YYYY', false)
      : null
  }, [data])

  const warningTooltipText = useMemo(() => {
    return nextReview
            && data?.client_type === 'PERSON'
      ? `Set on ${prepareTimestamp(nextReview, 'DD MMM YYYY')} based on background check, risk and country of residence.`
      : `Set on ${prepareTimestamp(nextReview, 'DD MMM YYYY')} based on background check, risk and jurisdiction.`
  }, [data, nextReview])



  return (
    <React.Fragment>
      <Box display={'flex'} mt={3} flexDirection={'column'}>
        <Box display={'flex'} alignItems={'center'}>
          <Box display={'flex'} alignItems={'center'} style={{width: '100px'}}>
            <Typography variant={'h5'}>Last Review</Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'}>
            <CustomTooltip title="Last Review Date" placement="top">
              <SearchHistoryInactiveIcon width={12}/>
            </CustomTooltip>
                        &nbsp;
            <Typography variant={'caption'} style={{color: grey[500]}}>
              { lastReview || 'N/A' }
            </Typography>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'center'} mt={1}>
          <Box display={'flex'} style={{width: '100px'}}>
            <Typography variant={'h5'}>Next Review</Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'}>
            <CustomTooltip title="Next Review Date" placement="top">
              <SearchHistoryInactiveIcon width={12}/>
            </CustomTooltip>
                        &nbsp;
            <Typography variant={'caption'} style={{color: grey[500]}}>
              { nextReview || 'N/A' }
            </Typography>
                        &nbsp;
            { nextReview &&
                            <CustomTooltip title={warningTooltipText} placement="top">
                              <WarningIcon/>
                            </CustomTooltip>
            }
                        &nbsp;
            {!readonly &&
                            <ButtonWithIcon startIcon={<EditIcon />} onClick={onUpdate}>
                                Edit
                            </ButtonWithIcon>
            }
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  )
}
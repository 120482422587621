export const DOCUMENTS = {
  AUTHENTICITY_VERIFICATION: 'Photo ID',
  ADDRESS: 'Proof of Address',
};

export const SORTABLE_COLUMNS = ['statusLabel', 'initiatedDate', 'updatedDate'];

export const RADIO_FILTERS = [
  { value: 'statusLabel', label: 'Status' },
  { value: 'initiatedDate', label: 'Created On' },
  { value: 'updatedDate', label: 'Updated On' },
];

export const PREPARE_SORT = {
  statusLabel: 'status',
  initiatedDate: 'initiatedDate',
  updatedDate: 'updatedDate',
};

export const FILTER_DEFAULTS = [
  { type: 'toggle', isChecked: false, value: 'COMPLETED', label: 'Completed', name: 'status' },
  { type: 'toggle', isChecked: false, value: 'CANCELED', label: 'Canceled', name: 'status' },
  { type: 'toggle', isChecked: false, value: 'EXPIRED', label: 'Expired', name: 'status' },
  { type: 'toggle', isChecked: false, value: 'PENDING_APPROVAL', label: 'Pending Approval', name: 'status' },
  { type: 'toggle', isChecked: false, value: 'REJECTED', label: 'Rejected', name: 'status' },
  { type: 'toggle', isChecked: false, value: 'REPEATED', label: 'Repeated', name: 'status' },
  { type: 'toggle', isChecked: false, value: 'REQUESTED', label: 'Requested', name: 'status' },
]
import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Typography } from '@material-ui/core';
import { eidv } from '@app/api/client';
import EVTitle from '../components/eidv-title';
import EVError from '../components/ediv-error';
import EVPendingButtons from '../components/eidv-pending';
import ImageWrapper from '../components/eidv-image-preview';
import { useSnackbar } from 'notistack';
import { manualApproveDto } from '../dto';

const selectUserName = state => {
  if (state.auth.user) {
    return state.auth.user.name + ' ' + state.auth.user.lastName
  }
};

export default function EVFacialMatch({ data, handleDownload, clientData, fetchData, handleRepeat, readonly }) {
  const { enqueueSnackbar } = useSnackbar();
  const userName = useSelector(selectUserName);
  const details = data.facialMatch || {};
  const pending = data.status === 'pending approval' && details.status === 'pending approval';
  const completed = details.status === 'completed';

  const errors = () => {
    const errors = [];
    if (!details.faceImageAcquired) {
      errors.push('The client did not provide a selfie.');
    }
    if (!details.facePrintExtractedFromAcquiredImage) {
      errors.push('The faceprint was not successfully acquired from the user provided selfie.');
    }
    if (!details.extractedFacePrintMatchedDocumentFrontSide) {
      errors.push('The faceprint from the selfie did not match the client face image on the document.');
    }
    if (!details.facePrintValidated) {
      errors.push('The faceprint was not successfully validated.');
    }
    return errors;
  }

  const onManualApprove = () => {
    const facialData = manualApproveDto.facialMatch(data)
    eidv.updateFacialMatchUserData(facialData, data?.id).then(() => {
      enqueueSnackbar('Facial Match was approved successfully!', { variant: 'success' })
      fetchData();
    })
  }

  return (
    <React.Fragment>
      <Box mb={3}>
        <EVTitle status={details.status}>Facial Match</EVTitle>
        <Box>
          {details.isManuallyApproved &&
            <Box>
              <Typography>Facial images manually compared and confirmed.</Typography>
              <Typography>[{userName}{details.manuallyApprovedStatusAt ? `, ${details.manuallyApprovedStatusAt}`: ''}]</Typography>
            </Box>
          }
          {!details.isManuallyApproved &&
            <Box>
              <Typography>Biometric facial match: <b>{completed ? 'confirmed' : 'not confirmed'}</b></Typography>
              {completed && <Typography>Depth and 3D analysis: <b>pass</b></Typography>}
              {completed && <Typography>Liveness test: <b>pass</b></Typography>}
            </Box>
          }
        </Box>
        <Box
          mt={2}
          width={840}
          display="flex"
          justifyContent="center"
          bgcolor="#F8F8F8"
          borderRadius="12px"
        >
          <ImageWrapper
            title="Document Image"
            imgSrc={details.documentUrl}
            clientName={clientData.name}
            onDownload={() => handleDownload('facialMatch.documentUrl', 'Document Image')}
            boxWidth={420}
            imgWidth={300}
            imgHeight={215}
          />
          <ImageWrapper
            title="Face Image"
            imgSrc={details.faceDocumentUrl}
            clientName={clientData.name}
            onDownload={() => handleDownload('facialMatch.faceDocumentUrl', 'Face Image')}
            boxWidth={420}
            imgWidth={300}
            imgHeight={215}
          />
        </Box>

        <Box display="flex" flexWrap="wrap">
          {pending && errors().map(error => (
            <Box mt={2} mr={2}>
              <EVError message={error} centerVertically />
            </Box>
          ))}
        </Box>
      </Box>

      {(pending && !readonly) &&
        <EVPendingButtons onRepeat={handleRepeat} onApprove={onManualApprove} />
      }
    </React.Fragment>
  )
}
import Dashboard from '@views/protected/admin/dashboard'

const routes = [
  {
    path: '/dashboard/:tab?',
    component: Dashboard,
    guest: false,
  }
]

export default routes;
import { Box, Typography } from '@material-ui/core';
import NoResultsFound from '@components/layout/no-results-found';
export const NoData = (action, type, noActionsStyle) => {
  if (!action) {
    return (
      <Box>
        {type === 'eidv' && (
          <Typography className={noActionsStyle}>
            The To-Do list will alert you about expired, rejected and pending
            approval electronic identify verifications.
          </Typography>
        )}
        {type === 'client' && (
          <Typography className={noActionsStyle}>
            When you complete Risk Rating for your clients, the dates for yearly
            reviews will appear here in the list or calendar form.
            <br />
            The To-Do list will alert you about upcoming reviews six months
            before the review date.
          </Typography>
        )}
        {type === 'document' && (
          <Typography className={noActionsStyle}>
            When you upload documents for your clients, the dates for document
            expiration will appear here in the list or calendar form.
            <br />
            The To-Do list will alert you about upcoming reviews 30 days prior
            to expiry.
          </Typography>
        )}
      </Box>
    );
  } else {
    return (
      <Box mt={25} flexGrow={1}>
        <NoResultsFound>
          The list of {type} reviews will appear here
        </NoResultsFound>
      </Box>
    );
  }
};

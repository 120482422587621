import React, { useState, useMemo, useEffect, useCallback } from 'react';
import {
  Box,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
} from '@material-ui/core';
import TableList, { TCELL_TYPES } from '@components/lists/table-list';
import OutlinedSearch from '@components/inputs/outlined-search';
import InlineHeadBadge from '@components/badges/inline-head-badge';
import FilterWithContext from '@components/dropdowns/filter-with-context';
import NoResultsFound from '@components/layout/no-results-found';
import { clients } from '@app/api/admin';
import { Modal } from '@components/modals';
import history from '@app/history';
import { makeStyles } from '@material-ui/core/styles';
import debounce from 'awesome-debounce-promise';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import debugMode from '@utils/debugMode';
import prepareTimestamp from '@utils/date';

const useStyles = makeStyles((theme) => ({
  filterActive: {
    backgroundColor: '#7A5B44',
    '&:hover': {
      backgroundColor: '#7A5B44',
      opacity: 0.9,
    },
  },
}));
const radioGroup = [
  { value: 'fullName', label: 'Sort by Full Name' },
  { value: 'email', label: 'Sort by Email' },
  { value: 'lastLogin', label: 'Sort by Last Login' },
];

const TABLE_COLUMNS = [
  { field: 'fullName', headerName: 'Full Name', bold: true },
  { field: 'email', headerName: 'Email' },
  { field: 'organization', headerName: 'Organization' },
  // { field: 'teamLead', headerName: 'Team Leader' },
  {
    field: 'testUser',
    headerName: 'Test User',
    cellType: TCELL_TYPES.CUSTOM,
    custom: (item) => (!!item.testUser ? 'Yes' : 'No'),
  },
  { field: 'lastLogin', headerName: 'Last Login' },
  { field: 'registeredAt', headerName: 'Registered On' },
];

const accessGroup = [
  { value: 'active', label: 'Active', isChecked: true },
  { value: 'blocked', label: 'Blocked', isChecked: true },
];

const usersGroup = [
  { value: 'real', label: 'Real User', isChecked: false },
  { value: 'test', label: 'Test User', isChecked: false },
];

const pageSize = 12;

const debouncedUsers = debounce(clients.searchUsers, 500);

const sortableColumns = ['fullName', 'email', 'testUser', 'registeredAt', 'lastLogin'];
const getSortField = {
  fullName: 'name',
  email: 'email',
  testUser: 'testUser',
  lastLogin: 'lastSystemAccess',
  registeredAt: 'registeredAt'
};

export default function ManageUsers({ setUserData }) {
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');

  const [page, setPage] = useState(0);
  const [pagesCount, setPagesCount] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [sort, setSort] = useState('registeredAt');
  const [sortDirection, setSortDirection] = useState('DESC');
  const [accessFilters, setAccessFilters] = useState(accessGroup);
  const [usersFilters, setUsersFilters] = useState(usersGroup);
  const [modalAction, setModalAction] = useState(null);

  const classes = useStyles(); // eslint-disable-line

  const getAllUsers = useCallback(async () => {
    const testUser = usersFilters.find((el) => el.value === 'test').isChecked;
    const checkedUsers = usersFilters.filter((el) => el.isChecked);
    const allUsers = (
      await debouncedUsers({
        sortDirection: sortDirection,
        sortBy: getSortField[sort],
        pageNumber: page,
        pageSize,
        email: search || null,
        testUser: checkedUsers.length === 1 ? testUser : null,
      })
    ).data;

    if (!!allUsers) {
      const prepareUsers = allUsers.entries.map((user) => ({
        ...user,
        fullName: `${user.name} ${user.lastName}`,
        teamLead: '',
        registeredAt: prepareTimestamp(user.registeredAt, 'MM/DD/YYYY', false) || '',
        lastLogin: prepareTimestamp(user.lastSystemAccess, 'MM/DD/YYYY', false) || '',
        status: 'Active',
      }));
      setPagesCount(allUsers.pages);
      setTotalCount(allUsers.count);
      setData(prepareUsers);
    }
  }, [page, search, usersFilters, sortDirection, sort]);

  useEffect(() => {
    getAllUsers();
  }, [getAllUsers, page, search, usersFilters, sortDirection, sort]);

  useEffect(() => {
    setUserData(data);
  }, [data, setUserData]);

  const onSelect = (id) => {
    const newData = data.map((item) =>
      item.id === id ? { ...item, isChecked: !item.isChecked } : item
    );
    setData(newData);
  };

  const onSearchChange = useCallback((event) => {
    setPage(0);
    setSearch(event.target.value);
  }, []);

  // eslint-disable-next-line
  const onFilterChange = (event, type) => {
    let newFilters = type === 'access' ? accessFilters : usersFilters;
    newFilters = newFilters.map((el) =>
      el.value === event.target.value
        ? { ...el, isChecked: event.target.checked }
        : el
    );
    type === 'access'
      ? setAccessFilters(newFilters)
      : setUsersFilters(newFilters);
  };

  // eslint-disable-next-line
  const handleMenuAction = (item, type) => {
    setModalAction({ item, type, prefix: 'user' });
  };

  const handleView = (id, item) => {
    history.push(`/user-management/manage-users/view-user/?userId=${id}`);
  };

  // eslint-disable-next-line
  const handleEdit = (id, item) => {
    history.push(`/user-management/manage-users/edit-user/?userId=${id}`);
  };

  const onTableSort = (value) => {
    setPage(0);
    if (sort !== value) {
      setSort(value);
      setSortDirection('ASC');
    } else {
      setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
    }
  };

  const formattedData = useMemo(() => {
    return (
      data?.map((e) => {
        const item = { ...e };

        switch (item.status) {
          case 'Active':
            item.cellProps = {
              status: {
                style: {
                  color: '#4E922C',
                },
              },
            };
            break;

          case 'Blocked':
            item.cellProps = {
              status: {
                style: {
                  color: '#B6514C',
                },
              },
            };
            break;

          default:
            break;
        }

        return item;
      }) || []
    );
  }, [data]);

  const threeDotsActions = useMemo(() => {
    return debugMode.getIsDebugMode()
      ? [
        { label: 'View', handler: handleView },
        { label: 'Edit', handler: handleEdit },
        {
          label: 'Delete',
          handler: (id, item) => handleMenuAction(item, 'delete'),
        },
        {
          label: 'Block',
          handler: (id, item) => handleMenuAction(item, 'block'),
          disabled: (item) => item.status === 'Blocked',
        },
        {
          label: 'Unblock',
          handler: (id, item) => handleMenuAction(item, 'unblock'),
          disabled: (item) => item.status === 'Active',
        },
        {
          label: 'Mark as Test User',
          handler: (id, item) => handleMenuAction(item, 'make-test'),
          disabled: (item) => !!item.testUser,
        },
        {
          label: 'Mark as Real User',
          handler: (id, item) => handleMenuAction(item, 'make-real'),
          disabled: (item) => !item.testUser,
        },
      ]
      : [
        { label: 'View', handler: handleView },
        {
          label: 'Mark as Test User',
          handler: (id, item) => handleMenuAction(item, 'make-test'),
          disabled: (item) => !!item.testUser,
        },
        {
          label: 'Mark as Real User',
          handler: (id, item) => handleMenuAction(item, 'make-real'),
          disabled: (item) => !item.testUser,
        },
        {
          label: 'Delete',
          handler: (id, item) => handleMenuAction(item, 'delete'),
        },
      ];
  }, [handleMenuAction]);

  const execModalAction = async () => {
    if (modalAction.type === 'make-test' || modalAction.type === 'make-real') {
      await clients.updateUser(modalAction.item.id, {
        testUser: modalAction.type === 'make-test',
      });
      enqueueSnackbar(
        `The user "${modalAction?.item?.fullName}" was successfully marked as ${
          modalAction.type === 'make-test' ? 'Test' : 'Real'
        } User!`,
        { variant: 'success' }
      );
    }
    if (modalAction.type === 'delete') {
      await clients.deleteUser(modalAction.item.id);
      enqueueSnackbar(
        `The user "${modalAction?.item?.fullName}" was successfully deleted!`,
        { variant: 'success' }
      );
    }
  };

  return (
    <Box display={'flex'} flexGrow={'1'} mt={2} flexDirection={'column'}>
      <Box
        display={'flex'}
        width={'100%'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box display={'flex'}>
          <Box mr={1}>
            <InlineHeadBadge badgeColor={'#CAD7CA'} badgeValue={totalCount}>
              Users
            </InlineHeadBadge>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'center'} height={'48px'}>
          <FilterWithContext
            isActive={
              !!usersFilters.filter((el) => el.isChecked).length &&
              classes.filterActive
            }
          >
            <Grid container spacing={8}>
              {debugMode.getIsDebugMode() && (
                <>
                  <Grid item xs={4}>
                    <RadioGroup
                      name="sort"
                      value={sort}
                      onChange={(e) => setSort(e.target.value)}
                    >
                      {radioGroup.map((el, i) => (
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          height={'40px'}
                          key={`${el.value} - ${i}`}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            value={el.value}
                            label={el.label}
                          />
                        </Box>
                      ))}
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={4}>
                    {accessFilters.map((el, i) => (
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        height={'40px'}
                        key={`${el.value} - ${i}`}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              value={el.value}
                              checked={el.isChecked}
                              onClick={(event) =>
                                onFilterChange(event, 'access')
                              }
                            />
                          }
                          label={el.label}
                        />
                      </Box>
                    ))}
                  </Grid>
                </>
              )}
              <Grid item xs={4}>
                {usersFilters.map((el, i) => (
                  <Box key={`${el.value} - ${i}`}>
                    <FormControlLabel
                      control={
                        <Switch
                          value={el.value}
                          checked={el.isChecked}
                          onClick={(event) => onFilterChange(event, 'users')}
                        />
                      }
                      label={el.label}
                    />
                  </Box>
                ))}
              </Grid>
            </Grid>
          </FilterWithContext>
        </Box>
      </Box>
      <Box display={'flex'} mb={3}>
        <OutlinedSearch
          value={search}
          onChange={onSearchChange}
          label="Search users by email"
        />
      </Box>
      {!!formattedData.length ? (
        <Grid container>
          <Grid item sm={12}>
            <TableList
              sortableColumns={sortableColumns}
              columns={
                debugMode.getIsDebugMode()
                  ? [
                    ...TABLE_COLUMNS,
                    { field: 'status', headerName: 'Status' },
                  ]
                  : TABLE_COLUMNS
              }
              items={formattedData}
              onSelect={onSelect}
              onClick={handleView}
              pageSize={pageSize}
              pagesCount={pagesCount}
              totalCount={totalCount}
              sortDirection={sortDirection}
              sort={sort}
              onSort={onTableSort}
              page={page}
              onPageChange={(event, value) => setPage(value - 1)}
              maxHeight="calc(100vh - 380px)"
              threeDotsActions={threeDotsActions}
            />
          </Grid>
        </Grid>
      ) : (
        <Box mt={25} flexGrow={1}>
          <NoResultsFound>The list of users will appear here</NoResultsFound>
        </Box>
      )}
      <Modal
        open={!!modalAction}
        onClose={() => setModalAction(null)}
        title={(() => {
          if (modalAction?.type === 'make-test') {
            return 'Mark as Test User';
          }
          if (modalAction?.type === 'make-real') {
            return 'Mark as Real User';
          }
          return `${_.upperFirst(modalAction?.type)} ${
            modalAction?.prefix || 'user'
          }`;
        })()}
        mainText={(() => {
          if (modalAction?.type === 'make-test') {
            return `Do you want to mark "${modalAction?.item?.fullName}" as test user?`;
          }
          if (modalAction?.type === 'make-real') {
            return `Do you want to mark "${modalAction?.item?.fullName}" as real user?`;
          }
          return `Are you sure you want to ${modalAction?.type} ${modalAction?.prefix} ${modalAction?.item?.fullName}?`;
        })()}
        actions={[
          {
            type: 'main',
            label: 'NO',
            action: () => setModalAction(null),
            style: { width: 160 },
          },
          {
            type: 'secondary',
            label: 'YES',
            action: async () => {
              await execModalAction();
              getAllUsers();
              setModalAction(null);
            },
            style: { width: 160 },
          },
        ]}
        actionsDirection="row"
      />
    </Box>
  );
}

import React from 'react';
import { Switch } from 'react-router-dom';
import resolveRoutes from '@app/routes/index';
import PublicRoute from './components/router/PublicRoute';
import ProtectedRoute from './components/router/ProtectedRoute';
import { useSelector } from 'react-redux';
import NotFound from '@views/public/not-found';
import { withPageLayout } from '@components/layout/page-layout';
import LoggedNotFound from '@views/protected/not-found';

function Routes() {
  const isGuest = useSelector(state => !state.auth.user);
  const { side, userIsAdmin } = useSelector(state => state.auth);

  return (
    <React.Fragment>
      <Switch>
        {resolveRoutes(side, userIsAdmin)
          .map((route, i) => (
            (route.guest || (route.always && isGuest))
              ? <PublicRoute
                path={route.path}
                key={route.path}
                component={route.component}
                exact
              />
              : <ProtectedRoute
                path={route.path}
                key={route.path}
                component={route.component}
                baseRoute={route.baseRoute}
                exact={route.exact}
              />
          ))}
        {isGuest
          ? <PublicRoute
            path="*"
            component={NotFound}
            exact
          />
          : <ProtectedRoute
            path="*"
            component={withPageLayout(LoggedNotFound)}
            exact
          />
        }
      </Switch>
    </React.Fragment>
  );
}

export default Routes;

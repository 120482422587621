import React, {useEffect} from 'react';
import { useTheme } from '@material-ui/core/styles';
import _ from 'lodash';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControl, FormHelperText,
  InputLabel, OutlinedInput
} from '@material-ui/core';

import * as yup from 'yup';
import parseYupErrors from '@app/yup';
import {clientApi} from '@app/api';
import ColorRadioGroup from '@components/inputs/color-radio-group';
import { useSnackbar } from 'notistack';
import useErrorState from '@utils/errorState';
import { useSuppressSnackbar } from '@utils/useSuppressSnackbar';

// import {makeStyles} from "@material-ui/core/styles";
//
// const useStyles = makeStyles((theme) => ({
//     root: {
//
//     }
// }));

const schema = yup.object().shape({
  name: yup.string()
    .trim()
    .required()
    .min(2)
    .max(100)
    .test('not_home', 'Home is not allowed as a folder name', function () {
      return this.parent.name.trim().toLowerCase() !== 'home';
    }),
  color: yup.string().required(),
});

export default function FolderFormDialog(props) {
  const { enqueueSnackbar } = useSnackbar()
  const setSuppressSnackbar = useSuppressSnackbar();
  const { id, defaultName } = props

  const { palette } = useTheme()
  const folderColors = Object.values(palette.folder)

  const defaultData = {
    name: defaultName || '',
    color: [...folderColors].shift()
  }

  const [data, setData] = React.useState(defaultData)

  useEffect(() => {
    setErrors({})
    if( !id ) {
      setData(defaultData)
    } else {
      clientApi.folder.show(id).then(response => {
        setData(response.data);
      })
    }
    // eslint-disable-next-line
    }, [props.open])

  const [errors, setErrors] = useErrorState({});

  const linkState = key => {
    return event => {
      const dataClone = _.cloneDeep(data);
      const value = event.target.value.replace(/\s+/g, ' ')
      _.set(dataClone, key, value);
      setData(dataClone);
    };
  };

  const onSave = () => {
    setErrors({});
    schema
      .validate(data, {abortEarly: false})
      .then(function () {
        // Create
        setSuppressSnackbar(true);
        if( !id ) {
          clientApi.folder.store(data)
            .then((response) => {
              enqueueSnackbar(`Folder "${data.name}" was successfully created!`, { variant: 'success' });
              props.onSaved(
                response.data
              );
            })
            .catch(err => {
              if (err.response.data.error) {
                setErrors({
                  ...errors,
                  ...{
                    name: err.response.data.error
                  }
                }
                , true);
              }
            })
            .finally(() => setSuppressSnackbar(false))
        } else {
          clientApi.folder.update(id, data)
            .then((response) => {
              enqueueSnackbar(`Folder "${data.name}" was successfully updated!`, { variant: 'success' });
              props.onSaved(response.data);
            })
            .catch(err => {
              if (err.response.data.error) {
                setErrors({
                  ...errors,
                  ...{
                    name: err.response.data.error
                  }
                }
                , true);
              }
            })
            .finally(() => setSuppressSnackbar(false))
        }
      })
      .catch(function (err) {
        setErrors(parseYupErrors(err), true);
      });
  }

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={props.onClose}>
        <form onSubmit={(e) => e.preventDefault()}>
          <DialogTitle>
            {!id && 'New Folder'}
            {!!id && 'Update Folder'}
          </DialogTitle>
          <DialogContent>
            <FormControl id="email" fullWidth variant="outlined" error={!!errors['name']}>
              <InputLabel htmlFor="email">Name</InputLabel>
              <OutlinedInput
                fullWidth
                value={data.name}
                onChange={linkState('name')}
                autoFocus
              />
              <Box height={'15px'}>
                {!!errors['name'] &&
                                    <FormHelperText style={{ margin: '3px 0' }}>{errors['name'] || null}</FormHelperText>
                }
              </Box>
            </FormControl>
            <FormControl id="color" fullWidth error={!!errors['color']}>
              <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                <ColorRadioGroup colors={folderColors} value={data.color} onChange={linkState('color')} />
              </Box>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button style={{ width: '160px' }} variant={'outlined'} onClick={props.onClose}>
                            CANCEL
            </Button>
            <Button style={{ width: '160px' }} variant={'contained'} onClick={onSave} type="submit">
                            SAVE
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
export function getIsInheritedItem(rulebook, item) {
  return (
    rulebook?.ownedBy !== 'SYSTEM'
    && !!item.definedByRulebookVersionId
    && item.definedByRulebookVersionId !== rulebook?.rulebookVersionId
  )
    || (
      rulebook?.ownedBy === 'SYSTEM'
      && !!item.definedByRulebookVersionId
    )
}
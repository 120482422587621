import React, {useCallback, useMemo} from 'react';
import { Box, Typography, Divider, FormControlLabel, Switch, MenuItem, makeStyles } from '@material-ui/core';
import MenuWithContext from '@components/dropdowns/menu-with-context';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px',
    lineHeight: '18px',
    color: '#656565',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '18px',
    color: '#232323',
  },
  switch: {
    width: '36px',
    height: '18px',
    padding: '2px 6px',
    '& .MuiSwitch-switchBase': {
      top: -3,
      padding: '4px',
    },
  },
  switchControl: {
    marginLeft: '-5px',
    '& .MuiFormControlLabel-label': {
      marginLeft: 4,
    }
  },
  advancedSelect: {
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      boxShadow: 'none',
    },
  },
  iconButton2: {
    width: 36,
    height: 36,
    position: 'absolute',
    right: 14,
    padding: 0,
  },
}));

export default function Header({ category, setCategory, onDelete }) {
  const { name, categoryRuleType } = category || {};
  const classes = useStyles();

  const isExpected = useMemo(() => {
    return (category.categoryRuleType === 'PER_TYPE' && category.types?.some(t => t.expected))
      || category.categoryRuleType === 'ONE_OF'
  }, [category])

  const isOneOff = useMemo(() => {
    return categoryRuleType === 'ONE_OF'
  }, [categoryRuleType])

  const isPerType = useMemo(() => {
    return categoryRuleType === 'PER_TYPE'
  }, [categoryRuleType])

  const switchCategoryRuleType = useCallback(() => {
    const newCategory = {
      ...category,
      types: category.categoryRuleType === 'PER_TYPE'
        ? category.types.map(t => ({
          ...t,
          expected: false
        }))
        : category.types,
      categoryRuleType: category.categoryRuleType === 'ONE_OF'? 'PER_TYPE': 'ONE_OF'
    }
    setCategory(newCategory)
  }, [category, setCategory])

  return (
    <Box display="flex" justifyContent="center" alignItems="center" px={2} mb={2}>
      <Box display="flex" flexDirection="column" width={'30%'} pr={2}>
        <Typography className={classes.subtitle}>Document Category</Typography>
        <Typography className={classes.title}>{name}</Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box display="flex" justifyContent="space-between" alignItems="center" width={'70%'} pl={2}>
        <Box display="flex" flexDirection="column" >
          <Typography className={classes.subtitle}>Is Expected</Typography>
          <Typography variant="body1" style={{ color: '#232323' }}>{isExpected ? 'Yes' : 'No'}</Typography>
        </Box>
        <Box display="flex" flexDirection="column" >
          <Typography className={classes.subtitle}>At least one document type is required</Typography>
          <FormControlLabel
            control={<Switch checked={isOneOff} onClick={switchCategoryRuleType} classes={{ root: classes.switch }} />}
            label={isOneOff ? 'Yes' : 'No'}
            classes={{ root: classes.switchControl }}
            disabled={category?.disabled}
          />
        </Box>
        <Box display="flex" flexDirection="column" >
          <Typography className={classes.subtitle}>Expected by document types</Typography>
          <FormControlLabel
            control={<Switch checked={isPerType} onClick={switchCategoryRuleType} classes={{ root: classes.switch }} />}
            label={isPerType ? 'Yes' : 'No'}
            classes={{ root: classes.switchControl }}
            disabled={category?.disabled}
          />
        </Box>
        {!category.disabled &&
          <MenuWithContext title="More Actions">
            <MenuItem onClick={onDelete}>
              Exclude
            </MenuItem>
          </MenuWithContext>
        }
      </Box>
    </Box>
  )
}

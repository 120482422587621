const config = {
  MuiDialog: {
    paper: {
      padding: '50px',
      borderRadius: '12px'
    },
    paperWidthSm: {
      maxWidth: '800px',
      minWidth: '400px'
    }
  },
  MuiDialogTitle: {
    root: {
      textAlign: 'center',
      textTransform: 'capitalize'
    }
  },
  MuiDialogActions: {
    root: {
      marginTop: '60px',
      justifyContent: 'center'
    }
  },
  MuiDialogContent: {
    root: {
      overflow: 'visible',
      overflowY: 'visible',
      padding: '8px 0'
    }
  }
};

export default config
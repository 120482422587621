import EntityManagement from '@views/protected/admin/entity-management';

const routes = [
  {
    path: '/entity-management/:tab?',
    component: EntityManagement,
    guest: false,
  },
]

export default routes;
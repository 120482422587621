const EntityInputTypeEnum = {
  'TEXT': 'Text',
  'NUMBER': 'Number',
  'DATE': 'Date',
  'BOOLEAN': 'Boolean',
  'COUNTRY_LIST': 'Country List',
  'LIST': 'Custom List'
}

const EntityInputDisplayLevelEnum = {
  'SUMMARY_INFO': 'Summary Info',
  'EXTENDED_SETTINGS': 'Extended Settings',
  'CITIZENSHIP_SETTINGS': 'Citizenship Settings', // For Person
  'OWNERSHIPS_SETTINGS': 'Ownership Settings', // For Legal Entity
  'ACTIVITY': 'Activity and Regulation', // For Legal Entity
  'NO_DISPLAY': 'No Display'
}

const EntityInputDisplayLevelEnumPerson = {
  SUMMARY_INFO: EntityInputDisplayLevelEnum.SUMMARY_INFO,
  EXTENDED_SETTINGS: EntityInputDisplayLevelEnum.EXTENDED_SETTINGS,
  CITIZENSHIP_SETTINGS: EntityInputDisplayLevelEnum.CITIZENSHIP_SETTINGS,
  NO_DISPLAY: EntityInputDisplayLevelEnum.NO_DISPLAY,
}

const EntityInputDisplayLevelEnumLegalEntity = {
  SUMMARY_INFO: EntityInputDisplayLevelEnum.SUMMARY_INFO,
  EXTENDED_SETTINGS: EntityInputDisplayLevelEnum.EXTENDED_SETTINGS,
  OWNERSHIPS_SETTINGS: EntityInputDisplayLevelEnum.OWNERSHIPS_SETTINGS,
  ACTIVITY: EntityInputDisplayLevelEnum.ACTIVITY,
  NO_DISPLAY: EntityInputDisplayLevelEnum.NO_DISPLAY,
}

export {
  EntityInputTypeEnum,
  EntityInputDisplayLevelEnum,
  EntityInputDisplayLevelEnumPerson,
  EntityInputDisplayLevelEnumLegalEntity,
}
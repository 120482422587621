import React, { useCallback } from 'react';
import { Typography, Card, Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import CustomChip from '@components/controls/custom-chip';

import AdditionalDataPersons from './components/additionalDataPersons';
import AdditionalDataEntities from './components/additionalDataEntities';

import { DownloadIcon } from '@app/icons';
import downloadUrl from '@utils/downloadUrl';
import { clientDocument } from '@app/api/client';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { goBack } from '@utils/global-search-helpers/goBack';
import history from '@app/history';
import { useStyles } from './styles';
import AdditionalDocuments from './components/additionalDocuments';

const DOCUMENTS = 'Documents';

const FOLDERS = 'Folders';

const typeColors = {
  Clients: '#C6C6C6',
  People: '#79AEB6',
  'Legal Entities': '#D1C2B6',
  Folders: '#A1B6CA',
  PERSON: '#79AEB6',
  LEGAL_ENTITY: '#D1C2B6',
  Documents: '#C0A2B8',
};

const chipLabel = {
  Clients: null,
  People: 'Person',
  'Legal Entities': 'Legal Entity',
  Folders: 'Folder',
  Documents: 'Document',
};

const clientType = {
  PERSON: 'Person',
  LEGAL_ENTITY: 'Legal Entity',
};

const clientTypeBGColor = {
  PERSON: '#D5F0F0',
  LEGAL_ENTITY: '#D1C2B6',
};

export default function GlobalSearchCard({ item, searchType }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleClick = useCallback(() => {
    goBack();

    switch (searchType?.value) {
      case DOCUMENTS:
        history.push(
          `/portfolio/view/${item?.associations[0]?.clientId}/kyc-documents`
        );
        break;
      case FOLDERS:
        history.push(`/portfolio/tab-persons/${item.id}`);
        break;
      default:
        history.push(`/portfolio/view/${item.id}`);
    }
  }, [searchType, item]);

  const onDownloadDocument = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const document = (await clientDocument.show(item.id)).data;
    downloadUrl(document.downloadUrl, document.fileName);
    enqueueSnackbar(
      `Document "${item.fileName}" was successfully downloaded!`,
      { variant: 'success' }
    );
  };

  const additionalData = {
    Clients:
      item.clientType === 'PERSON' ? (
        <AdditionalDataPersons item={item} />
      ) : (
        <AdditionalDataEntities item={item} />
      ),
    People: <AdditionalDataPersons item={item} />,
    'Legal Entities': <AdditionalDataEntities item={item} />,
    Folders: null,
    Documents: <AdditionalDocuments item={item} />,
  };

  const pathCreator = () => {
    let path = '';
    if (searchType?.value === FOLDERS) {
      path = 'HOME';
    }
    if (searchType.value !== FOLDERS) {
      path =
        item.folderName !== 'HOME'
          ? `HOME / ${item.folderName}`
          : item.folderName;
    }
    return path;
  };

  return (
    <Card className={classes.cardRoot} onClick={handleClick}>
      <Box>
        <CustomChip
          labelColor={
            searchType.value === DOCUMENTS || searchType.value === FOLDERS
              ? '#fff'
              : '#484848'
          }
          label={chipLabel[searchType?.value] || clientType[item.clientType]}
          background={
            searchType?.value !== 'Clients'
              ? searchType?.bgColor
              : clientTypeBGColor[item.clientType]
          }
          propsClasses={classes.cardChip}
        />
      </Box>
      <Box my={1}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          width={'100%'}
        >
          <Typography className={classes.cardName}>{item.name}</Typography>
          {searchType.value === DOCUMENTS && (
            <Box ml={2}>
              <ButtonWithIcon
                startIcon={<DownloadIcon />}
                onClick={onDownloadDocument}
              >
                Download
              </ButtonWithIcon>
            </Box>
          )}
        </Box>
        {searchType?.value === FOLDERS && item.name === 'HOME' ? (
          <Typography className={classes.folderPath}>{item.name}</Typography>
        ) : (
          <Typography className={classes.folderPath}>
            {pathCreator()} /{' '}
            <span
              style={{
                color:
                  searchType?.value !== 'Clients'
                    ? typeColors[searchType?.value]
                    : typeColors[item.clientType],
              }}
            >
              {searchType.value === DOCUMENTS ? item.clientName : item.name}
            </span>
          </Typography>
        )}
      </Box>
      {additionalData[searchType?.value]}
    </Card>
  );
}

import React from 'react';
import {Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid} from '@material-ui/core';
import clsx from 'clsx';
import {
  DownloadIcon,
  DeleteIcon
} from '@app/icons';
import RegularPagination from '@components/pagination/regular-pagination';
import {SortTableIcon, SortTableAscIcon, SortTableDescIcon} from '@app/icons';
import listColumns from '../helpers/list-columns';
import CustomTooltip from '@components/controls/tooltip';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
  tableRow: {
    '&:hover': {
      cursor: ({withHover}) => withHover? 'pointer': 'auto',
      backgroundColor: ({withHover}) => withHover? '#F8F8F8': 'transparent'
    }
  }
})

export default function RisksListTable({data, readOnly, sortableColumns, handleSort, lines, onExport, onView, onDelete, sortDirection, sort, page, pagesCount, totalCount, onPageChange, pageSize}) {

  const classes = useStyles({
    withHover: !!onView
  })

  const getSortIcon = (field) => {
    if (field === sort) {
      if (sortDirection === 'ASC') {
        return <SortTableAscIcon />
      } else {
        return <SortTableDescIcon />
      }
    }
    return <SortTableIcon />
  }

  const getColumns = () => {
    return listColumns(data?.client_type)
  }
  console.log(pagesCount, totalCount)
  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {getColumns().map(col => (
                  <TableCell onClick={() => handleSort && handleSort(col.field)} key={`header-cell-${col.field}`}>
                    <Box style={{whiteSpace: 'nowrap'}}>
                      {col.headerName}
                      {sortableColumns && sortableColumns.indexOf(col.field) !== -1 &&
                                            <span>&nbsp;{getSortIcon(col.field)}</span>
                      }
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {lines.map((item) => (
                <React.Fragment key={`line-${item.id}`}>
                  <TableRow classes={{root: classes.tableRow}} onClick={() => onView && onView(item)}>
                    {getColumns().map((col, iCol) => (
                      <TableCell className={clsx([!iCol && 'bolded'])} key={`body-cell-${item.id}-${col.field}`}>
                        { item[col.field]? item[col.field] : 'N/A'}
                      </TableCell>
                    ))}
                    { onExport &&
                      <TableCell align={'right'} width={50}>
                        <Box display={'flex'} flexDirection={'row'}>
                          <CustomTooltip title={'Export'} placement="top">
                            <IconButton onClick={(event) => {
                              event.stopPropagation()
                              onExport(item.id)
                            }} p={1}>
                              <DownloadIcon />
                            </IconButton>
                          </CustomTooltip>
                          {
                            !!item.isDraft && !readOnly &&
                                  <CustomTooltip title="Delete" placement="top">
                                    <IconButton
                                      onClick={event => {
                                        event.stopPropagation()
                                        onDelete(item.id)
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </CustomTooltip>
                          }
                        </Box>
                      </TableCell>
                    }
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <Box width={'100%'} my={3}>
          <RegularPagination
            page={page}
            pagesCount={pagesCount}
            onChange={onPageChange}
            totalCount={totalCount}
            pageSize={pageSize}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
const setDefault = () => {
  localStorage.removeItem('profilesVersion');
  return {
    type: 'SET_SEARCH_HISTORY_DEFAULT'
  }
}

const setProfilesVersion = profilesVersion => {
  localStorage.setItem('profilesVersion', JSON.stringify(profilesVersion))
  return {
    type: 'SET_PROFILES_VERSION',
    profilesVersion,
    id: profilesVersion.id !== 'last_version' ? profilesVersion.id : '',
  }
}

const setSearchVersion = searchVersions => ({
  type: 'SET_SEARCH_VERSION',
  searchVersions,
})


const searchHistory = {
  setDefault,
  setSearchVersion,
  setProfilesVersion,
}

export default searchHistory;
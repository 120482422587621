import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Collapse, Dialog, FormControl, FormHelperText } from '@material-ui/core';
import debounce from 'awesome-debounce-promise';

import { person, eidv, folder } from '@app/api/client';
import { prepareClient } from '@dto/client';
import ElectronicVerificationAdd from '@components/complex/client-view/electronic-verification/add-eidv';
import SelectSearchable from '@components/inputs/select-searchable';
import LinkButton from '@components/buttons/link-button';
import EidvInfo from '@components/complex/client-view/electronic-verification/components/eidv-info';
import { handleOnEnter } from '@utils/handleOnEnter';

const debouncedGetPerson = debounce(person.index, 500);

export default function EidvAdd({ open, onClose, onCreate }) {
  const [searchValue, setSearchValue] = useState('');
  const [clients, setClients] = useState([])
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [cases, setCases] = useState([])
  const [folders, setFolders] = useState([]);
  const [folderId, setFolderId] = useState(null);
  const [clientError, setClientError] = useState('');
  const [showSendingModal, setShowSendingModal] = useState(false);

  useEffect(() => {
    if (!open) {
      setSearchValue('');
      setSelectedClientId(null);
      setCases([]);

      const home = folders.find(e => e.name === 'HOME');
      if (home) {
        setFolderId(home.id)
      }
    }
  }, [open]) // eslint-disable-line

  const fetchPersons = (search) => {
    debouncedGetPerson({
      pageNumber: 0,
      pageSize: 30,
      searchKeyword: search,
      sortDirection: 'ASC',
      sortBy: 'firstName',
    }).then((res) => {
      setClients(res.data.entries.map((client) => prepareClient(client)))
    })
  }

  const selectedClient = useMemo(() => {
    if (selectedClientId === 'custom') {
      return { id: 'custom' }
    }
    return clients.find(e => e.id === selectedClientId)
  }, [selectedClientId, clients])

  useEffect(() => {
    if (selectedClientId && selectedClientId !== 'custom') {
      eidv.index({ clientId: selectedClientId }).then((res) => {
        setCases(res.data.entries)
      })
    } else {
      return [];
    }
  }, [selectedClientId])

  // Get and set HOME as default folder
  useEffect(() => {
    folder.index({ pageSize: 1000 }).then((res) => {
      const folders = res.data?.entries || []
      const home = folders.find(e => e.name === 'HOME');
      setFolders(folders)
      setFolderId(home.id)
    })
  }, [])

  const options = useMemo(() => {
    const options = clients.map(client => ({
      value: client.id,
      label: client.name,
    }))

    options.unshift({ label: 'Invite Client', value: 'custom' })
    options.push({
      label: 'Consider refining the filter to find your client faster',
      value: '0',
      disabled: true,
    })

    return options
  }, [clients])

  const handleSelect = (e) => {
    setSelectedClientId(e.target.value)
    setSearchValue(e.target.label)
    setClientError('')
  };

  const handleChange = (event, newInputValue) => {
    if (event?.type === 'change') {
      setSearchValue(newInputValue);

      // Attaching fetchPersons here so that we only trigger search on input change
      // We should not trigger search when client is selected
      fetchPersons(newInputValue)
    }
  }

  const onRequest = () => {
    person.index({
      pageNumber: 0,
      pageSize: 30,
      sortDirection: 'ASC',
      sortBy: 'firstName',
    }).then((res) => {
      setClients(res.data.entries.map((client) => prepareClient(client)))
      setShowSendingModal(true);
      onClose();
    })
  }

  const clientSearch = (
    <Box>
      <FormControl variant="outlined" fullWidth error={!!clientError}>
        <SelectSearchable
          inputValue={searchValue}
          getOptionDisabled={(option) => option.disabled}
          renderOption={(option) => (
            <span
              style={{
                fontStyle: option.value === '0' ? 'italic' : 'normal',
              }}
            >
              {option.label}
            </span>
          )}
          onInputChange={handleChange}
          value={selectedClientId}
          onChange={handleSelect}
          options={options}
          label={'Client'}
          error={!!clientError}
          firstOption={{ label: 'Invite Client', value: 'custom' }}
        />
        {clientError &&
          <FormHelperText>{clientError}</FormHelperText>
        }
      </FormControl>
      <Collapse in={selectedClientId === 'custom'}>
        <Box mt={1}>
          <FormControl fullWidth variant="outlined">
            <SelectSearchable
              value={folderId}
              onChange={(e) => setFolderId(e.target.value)}
              options={folders.map(e => ({ label: e.name, value: e.id }))}
              label="Select Folder"
              disableClearable
            />
            <FormHelperText>
            </FormHelperText>
          </FormControl>
        </Box>
      </Collapse>
    </Box>
  )

  return (
    <React.Fragment>
      <ElectronicVerificationAdd
        cases={cases}
        showSendingModal={showSendingModal}
        setShowSendingModal={setShowSendingModal}
        fromEidv
        clientSearch={clientSearch}
        data={selectedClient}
        onCreate={onCreate}
        folderId={folderId}
        onClose={onClose}
        setClientError={setClientError}
      />
      <Dialog
        open={open}
        onClose={onClose}
        onKeyDown={handleOnEnter(onRequest)}
        disableAutoFocus
        disableRestoreFocus
      >
        <Box>
          <EidvInfo />
        </Box>
        <Box mt={2} display="flex" justifyContent="center">
          <Button
            variant="contained"
            onClick={onRequest}
          >
            {'Send Request'}
          </Button>
        </Box>
        <Box mt={1} display="flex" justifyContent="center">
          <LinkButton
            onClick={onClose}
          >
            {'Cancel'}
          </LinkButton>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}

import React, { useEffect, useState } from 'react';
import { Box, Button } from '@material-ui/core';

import history from '@app/history';
import { ChevronLeftIcon } from '@app/icons';
import { Modal } from '@components/modals';

import ButtonWithIcon from '@components/buttons/button-with-icon';
import InnerHeader from '@components/layout/inner-header';

import { useParams } from 'react-router-dom';
import ClientView from '@components/complex/client-view';
import { clientApi } from '@app/api';
import { useSnackbar } from 'notistack';
import { getClientName, getClientPronoun } from '@utils/formatClientData';

export default function UpdateExistingConfirm(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { type, searchId, profileId, clientId } = useParams();
  const [search, setSearch] = useState({});
  const [client, setClient] = useState({});
  const [lastSearchId, setLastSearchId] = useState(0);
  const [stepsCount, setStepsCount] = useState(3);
  const [bgModalOpen, setBgModalOpen] = useState(false);
  const { flow, steps, onNext, onNextAlt, getBaseUri } = props;

  useEffect(() => {
    fetch();
    if (flow === 'ongoing-monitoring') {
      findLastSearch();
    }
    }, []) // eslint-disable-line

  const fetch = async () => {
    if (searchId) {
      const search = (await clientApi.search.show(searchId)).data;
      setSearch(search);
    }
    if (clientId) {
      const client = (await clientApi.client.show(clientId)).data;
      setClient(client);
    }
  }

  const findLastSearch = async () => {
    const data = (await clientApi.client.show(clientId)).data;
    if (!data.linkedSearches.length) {
      return setStepsCount(6);
    }

    Promise.all(
      data.linkedSearches.map(searchId => clientApi.search.show(searchId))
    ).then(searchResults => {
      const latestSearch = searchResults.reduce((prev, current) => {
        return prev.data.createdOn > current.data.createdOn ? prev : current;
      }).data;

      setLastSearchId(latestSearch.searchId);
    })
  }

  const linkClient = async () => {
    const client = (await clientApi.client.linkToSearch(clientId, searchId)).data;
    if (profileId) {
      await clientApi.client.linkToProfile(clientId, profileId);
    }
        
    // If there is no profile, set search rating as low, otherwise null
    await clientApi.search.updateSearchReview(searchId, { bgCheckRiskRating: !profileId ? 'Low' : null })

    if (type === 'person') {
      enqueueSnackbar(`"${client.firstName} ${client.lastName}" details were successfully updated!`, { variant: 'success' });
    } else {
      enqueueSnackbar(`"${client.name}" details were successfully updated!`, { variant: 'success' });
    }

        
    return onNext({ clientId, searchType: type, searchId, searchName: search.name })
  }

  const addBgCheck = async () => {
    onNextAlt({ clientId });
  }

  const handleNext = () => {
    if (flow === 'ongoing-monitoring') {
      if (!lastSearchId) {
        setBgModalOpen(true);
      } else {
        onNext({ searchId: lastSearchId, clientId });
      }
    } else {
      linkClient();
    }
  }

  const NextButton = () => {
    return (
      <Box width="100%" display="flex" justifyContent="center" mt={5} mb={2}>
        <Button
          variant="contained"
          size="large"
          onClick={handleNext}
        >
          {'NEXT'}
        </Button>
      </Box>
    )
  }

  const generateNoBgMessage = () => {
    return `${getClientName(client)} must have at least one background check before ${getClientPronoun(client)} can be added to ongoing monitoring.`
  }

  return (
    <Box height="100%">
      <Box px={6}>
        <InnerHeader
          title={`Confirm ${type === 'person' ? 'Person' : 'Legal Entity'}`}
          stepper={{
            currentStep: steps?.currentStep || 2,
            stepsCount: steps?.stepsCount || stepsCount
          }}
          buttons={
            <React.Fragment>
              <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={() => { history.goBack() }}>
                {'Back'}
              </ButtonWithIcon>
            </React.Fragment>
          }
        />
      </Box>
      <Box display="flex" flexDirection="row" flexGrow={1} height="calc(100% - 98px)">
        <ClientView
          clientId={clientId}
          readonly={true}
          nextButton={<NextButton />}
          highlightSearchId={lastSearchId}
          baseUri={getBaseUri({ type, searchId, profileId, clientId })}
        />
      </Box>

      <Modal
        open={bgModalOpen}
        onClose={() => setBgModalOpen(false)}
        title="Run Background Check"
        mainText={generateNoBgMessage()}
        actions={
          [
            {
              type: 'main',
              label: 'CANCEL',
              action: () => setBgModalOpen(false),
              style: { width: 180 },
            },
            {
              type: 'secondary',
              label: 'RUN BG CHECK',
              action: () => addBgCheck(),
              style: { width: 180 },
            },
          ]
        }
        actionsDirection="row"
      />
    </Box>
  )
}

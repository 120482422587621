import React, {useEffect, useMemo, useState} from 'react';
import {Box, FormControl, InputLabel, MenuItem, Select} from '@material-ui/core';
import moment from 'moment';
import DatePicker from '@components/calendar/date-picker';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

const dateFormat = 'MM/DD/YYYY'

const useStyles = makeStyles((theme) => ({
  calendarMenuItemRoot: {
    backgroundColor: 'transparent',
    height: 'auto',
    '& .MuiTouchRipple-root': {
      display: 'none'
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent'
      }
    }
  },
  activeCalendar: {
    padding: 0,
    '& .label': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      padding: '6px 16px',
      borderRadius: '16px'
    }
  }
}))

export default function SubscriptionsDate({ value, onChange, disabled, defaultValue }) {
  const [nextReviewOpen, setNextReviewOpen] = useState(false)
  const [internalValue, setInternalValue] = useState(0)

  const classes = useStyles()
  
  useEffect(() => {
    if(!value) {
      setInternalValue(defaultValue)
    } else {
      setInternalValue(6)
    }
    }, [defaultValue]) //eslint-disable-line

  const datesFromNow = useMemo(() => ({
    1: 'Today',
    2: 'Yesterday',
    3: 'Last 7 Days',
    4: 'This Month',
    5: 'Last Month',
  }), [])

  const onCustomDateChange = v => {
    if(internalValue === 6) {
      onChange(moment(v).format('YYYY-MM-DD'))
    }
  }

  const onCustomDateAccept = v => {
    if(internalValue === 6) {
      setNextReviewOpen(false)
    }
  }

  const handleChange = event => {
    event.preventDefault();
    event.stopPropagation();
    setInternalValue(event.target.value)
  }

  const onOpen = event => {
    event.preventDefault();
    event.stopPropagation();
    setNextReviewOpen(true)
  }
  // Close custom select on click away, recognize background overlay by z-index
  const onClose = event => {
    if(event.target.style?.['z-index'] === '-1') {
      setNextReviewOpen(false)
    }
  }

  return (
    <React.Fragment>
      <FormControl
        fullWidth
        variant="outlined"
        disabled={disabled}
      >
        <InputLabel>Subscription Date</InputLabel>
        <Select
          value={internalValue || defaultValue}
          onChange={handleChange}
          open={nextReviewOpen}
          onOpen={onOpen}
          onClose={onClose}
          renderValue={v => {
            if(v === 6) {
              return moment(value).format(dateFormat)
            } else {
              return datesFromNow[v]
            }
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
        >
          <MenuItem onClick={() => setNextReviewOpen(false)} value={1}>Today</MenuItem>
          <MenuItem onClick={() => setNextReviewOpen(false)} value={2}>Yesterday</MenuItem>
          <MenuItem onClick={() => setNextReviewOpen(false)} value={3}>Last 7 Days</MenuItem>
          <MenuItem onClick={() => setNextReviewOpen(false)} value={4}>This Month</MenuItem>
          <MenuItem onClick={() => setNextReviewOpen(false)} value={5}>Last Month</MenuItem>
          <MenuItem
            value={6}
            classes={{root: classes.calendarMenuItemRoot}}
            className={clsx(internalValue===6 && classes.activeCalendar)}
          >
            <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
              <Box display={'flex'} className={'label'}>
                                Set custom date
              </Box>
              { internalValue === 6 && nextReviewOpen &&
                            <Box display={'flex'} flexGrow={1} justifyContent={'center'}>
                              <Box display={'flex'}>
                                <DatePicker
                                  variant="static"
                                  value={value}
                                  minDate={new Date()}
                                  onChange={onCustomDateChange}
                                  onAccept={onCustomDateAccept}
                                />
                              </Box>
                            </Box>
              }
            </Box>
          </MenuItem>
        </Select>
      </FormControl>
    </React.Fragment>
  );
}

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles, MenuItem,
  TextField,
  Typography,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import CheckboxLight from '@components/inputs/checkbox-light';
import { Modal } from '@components/modals';
import { RadioButtonGroup } from '@components/buttons';
import SelectClearable from '@components/inputs/select-clearable';
import SelectSearchable from '@components/inputs/select-searchable';
import useErrorState from '@utils/errorState';
import parseYupErrors from '@app/yup';
import * as yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';
import {selectCategories, selectTypeDialogOpen, selectTypes, setSelectTypeDialogOpen} from '../duck';
import {usageTypes} from '@utils/entityType';

export const useStyles = makeStyles((theme) => ({
  customButtonModalWidth: {
    width: '180px',
  },
}))

const radioItems = [
  { label: 'Never Expires', value: 'NEVER' },
  { label: 'Ask the user for expiration date', value: 'USER_DEFINED_REQUIRED' },
  { label: 'Ask the user for non-required expiration date', value: 'USER_DEFINED_NOT_REQUIRED' },
  { label: 'Has acceptable age per user setting', value: 'PER_TYPE_DEFINED' },
]

const schema = yup.object().shape({
  name: yup.string().nullable().when('typeId', {
    is: 'custom',
    then: yup.string().required().label('Document Type Name'),
  })
})

const MODEL_DEFAULT = {
  typeId: null,
  name: '',
  categoryIds: [],
  expirationType: 'NEVER',
  typeExpirationInMonths: 0,
  usageTypes: []
}

export default function SelectTypeDialog({ onSave, clientType }) {
  const classes = useStyles()

  const [errors, setErrors] = useErrorState({})

  const [model, setModel] = useState(MODEL_DEFAULT)

  const { categoryId = null, excludeIds = [] } = useSelector(selectTypeDialogOpen) || {}

  const categories = useSelector(selectCategories)

  const allTypes = useSelector(selectTypes)

  const types = useMemo(() => allTypes?.filter(t => t.usageTypes.includes(clientType)), [ allTypes, clientType ])

  const category = categories?.find(c => c.id === categoryId)

  const isSystemCategory = category?.ownedBy === 'SYSTEM'

  const dispatch = useDispatch()
  const onClose = useCallback(() => {
    setErrors({});
    dispatch(
      setSelectTypeDialogOpen(null)
    )
  }, [dispatch, setErrors])

  useEffect(() => {
    let initModel = {
      ...MODEL_DEFAULT,
      usageTypes: [clientType]
    }
    if (!!categoryId) {
      initModel = {
        ...initModel,
        categoryIds: [categoryId]
      }
    }
    setModel(initModel)
  }, [categoryId, clientType])

  const onChange = field =>
    e => setModel({
      ...model,
      [field]: e.target.value
    })

  const onInternalSave = useCallback(async () => {
    setErrors({});
    try {
      await schema.validate(model, { abortEarly: false })
      const newType = model.typeId === 'custom'
        ? model
        : types.find(t => t.id === model.typeId)
      newType.categoryIds = [
        ...newType.categoryIds,
        categoryId
      ]
      onSave(newType, categoryId)
      console.log('ON TYPE SELECT', newType)
      onClose()
    } catch (err) {
      console.error(err)
      setErrors(parseYupErrors(err), true)
    }
  }, [model, types]) //eslint-disable-line

  const typesOptions = useMemo(() => {
    let ts = types
      ?.filter(t => excludeIds.indexOf(t.id) === -1)

    if (isSystemCategory) {
      ts = ts?.filter(t => t.ownedBy !== 'SYSTEM')
    }

    ts = ts?.map(t => ({ label: t.name, value: t.id }))
    return ts || []
  },
  [types, excludeIds, isSystemCategory]
  )

  console.log('model', model)
  console.log('categoryId/category.usageTypes', categoryId, category?.usageTypes)

  return (
    <Modal
      open={!!categoryId}
      onClose={onClose}
      title={'Add Document Type'}
      style={{overflow: 'scroll'}}
      actions={
        [
          {
            type: 'main',
            label: 'CANCEL',
            action: onClose,
          },
          {
            type: 'secondary',
            label: 'ADD',
            action: onInternalSave,
          },
        ]
      }
      propsClassses={classes.customButtonModalWidth}
      actionsDirection="row"
      content={
        <Box width={'425px'} my={4} display="flex" flexDirection="column" alignItems="stretch">
          <FormControl variant="outlined" fullWidth error={!!errors['typeId']}>
            <SelectSearchable
              label={'Select Document Type'}
              value={model.typeId || ''}
              onChange={onChange('typeId')}
              error={!!errors['typeId']}
              options={typesOptions}
              firstOption={{ label: 'Add New Type', value: 'custom' }}
            />
            { !!errors['typeId'] &&
              <FormHelperText>{ errors['typeId'] || null }</FormHelperText>
            }
          </FormControl>
          { model?.typeId === 'custom' && (
            <React.Fragment>
              <TextField
                value={model.name}
                onChange={onChange('name')}
                fullWidth
                label="Document Type Name"
                variant="outlined"
                error={!!errors['name']}
                helperText={!!errors['name'] && errors['name']}
              />
              <FormControl variant="outlined" fullWidth error={!!errors['categoryId']}>
                <InputLabel htmlFor="name">Select Category</InputLabel>
                <SelectClearable
                  value={model.categoryIds}
                  onChange={onChange('categoryIds')}
                  clearable={false}
                  multiple
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null
                  }}
                  renderValue={
                    (selectedIds) => categories
                      ?.filter(c => selectedIds.indexOf(c.id) !== -1)
                      .map(c => c.name)
                      .join(', ')}
                >
                  {categories
                    ?.filter(c => (
                      c.usageTypes.includes(clientType)
                    ))
                    ?.map(c => (
                      <MenuItem key={c.id} value={c.id} disabled={c.id === categoryId}>
                        <ListItemIcon>
                          <CheckboxLight
                            checked={model.categoryIds.indexOf(c.id) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText>
                          {c.name}
                        </ListItemText>
                      </MenuItem>
                    ))}
                </SelectClearable>
                { !!errors['categoryId'] &&
                    <FormHelperText>{ errors['categoryId'] || null }</FormHelperText>
                }
              </FormControl>

              {
                category?.usageTypes?.length > 1 && (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={!!errors.usageTypes}
                  >
                    <InputLabel>Document Class</InputLabel>
                    <SelectClearable
                      value={model.usageTypes}
                      onChange={onChange('usageTypes')}
                      clearable={false}
                      multiple
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null
                      }}
                      renderValue={
                        (selectedIds) => usageTypes
                          .filter(type => selectedIds.indexOf(type.value) !== -1)
                          .map(type => type.label)
                          .join(', ')}
                    >
                      {usageTypes.map(type => (
                        <MenuItem key={type.value} value={type.value} disabled={type.value === clientType}>
                          <ListItemIcon>
                            <CheckboxLight
                              checked={model.usageTypes.indexOf(type.value) > -1}
                            />
                          </ListItemIcon>
                          <ListItemText>
                            {type.label}
                          </ListItemText>
                        </MenuItem>
                      ))}
                    </SelectClearable>
                    {!!errors.usageTypes &&
                      <FormHelperText>{errors.usageTypes || null}</FormHelperText>
                    }
                  </FormControl>
                )
              }

              <Box mt={2} mb={3}>
                <Typography variant="h4">Expiration Type</Typography>
                <Box mt={2} ml={'12px'}>
                  <RadioButtonGroup
                    items={radioItems}
                    handleChange={onChange('expirationType')}
                    selectedValue={model.expirationType}
                    labelPropsStyle={classes.checkboxLabel}
                  />
                  {!!errors['expirationType'] &&
                      <FormHelperText>{errors['expirationType'] || null}</FormHelperText>
                  }
                </Box>
              </Box>
              {model.expirationType === 'PER_TYPE_DEFINED' &&
                  <TextField
                    value={model.typeExpirationInMonths}
                    onChange={onChange('typeExpirationInMonths')}
                    fullWidth
                    label="Acceptable Age (months)"
                    variant="outlined"
                    error={!!errors['typeExpirationInMonths']}
                    helperText={!!errors['typeExpirationInMonths'] && errors['typeExpirationInMonths']}
                  />
              }
            </React.Fragment>
          )}
        </Box>
      }
    />
  )
}
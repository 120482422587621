import React from 'react';
import { useSnackbar } from 'notistack';
import { Box, Typography } from '@material-ui/core';
import { eidv } from '@app/api/client';
import AuthenticityRibbon from '@assets/icons/EIDV/AuthenticityRibbon.png';
import EVTitle from '../components/eidv-title';
import EVError from '../components/ediv-error';
import EVPendingButtons from '../components/eidv-pending';
import ImageWrapper from '../components/eidv-image-preview';
import { manualApproveDto } from '../dto';

export default function EVPhotoDocument({ data, handleDownload, clientData, fetchData, handleRepeat, readonly }) {
  const { enqueueSnackbar } = useSnackbar();
  const details = data.photoId || {};
  const pending = data.status === 'pending approval' && details.status === 'pending approval';
  const completed = details.status === 'completed';

  const errors = () => {
    const errors = [];
    if (!details.imageAcquiredDocumentFrontSide) {
      errors.push('The image of the document front side was not successfully acquired.');
    }
    if (!details.faceExtractedDocumentFrontSide) {
      errors.push('The faceprint of the person was not successfully extracted from the document front side.');
    }
    if (!details.documentFrontSideValidated) {
      errors.push('The document parameters were not successfully verified for the image of the document front side.');
    }
    if (!details.imageAcquiredDocumentBackSide) {
      errors.push('The image of the document back side was not successfully acquired.');
    }
    if (!details.dataExtractedDocumentBackSide) {
      errors.push('Data from the Machine Readable Zone was not extracted from the document back side.');
    }
    if (!details.documentBackSideValidated) {
      errors.push('The document parameters were not successfully verified for the image of the document back side.');
    }
    if (!details.clientVerifiedExtractedData) {
      errors.push('The client did not confirm the data and continued the process.');
    }
    return errors;
  }

  const onManualApprove = () => {
    const basicData = manualApproveDto.photoId(data);
    eidv.updateAuthenticityVerificationUserData(basicData, data?.id).then(() => {
      enqueueSnackbar('Photo ID Document Authenticity was approved successfully!', { variant: 'success' })
      fetchData();
    })
  }

  const isPassport = details.documentType === 'PASSPORT';
  return (
    <React.Fragment>
      <Box mb={3}>
        <EVTitle status={details.status}>Photo ID Document Authenticity Verification</EVTitle>
        {completed &&
          <Box my={3} ml="-2px" display="flex" alignItems="center">
            <Box>
              <img alt="" src={AuthenticityRibbon} />
            </Box>
            <Box ml="4px">
              <Typography style={{ color: '#000000' }}>Document has been authenticated as government-issued original</Typography>
            </Box>
          </Box>
        }
        <Box
          mt={2}
          width={isPassport ? 420 : 840}
          display="flex"
          justifyContent="center"
          bgcolor="#F8F8F8"
          borderRadius="12px"
        >
          <ImageWrapper
            title={isPassport ? 'Document' : 'Document Front Side'}
            imgSrc={details.documentFrontUrl}
            clientName={clientData.name}
            onDownload={() => handleDownload('photoId.documentFrontUrl', 'Document Front Side')}
            boxWidth={420}
            imgWidth={300}
            imgHeight={215}
          />
          {!isPassport &&
            <ImageWrapper
              title="Document Back Side"
              imgSrc={details.documentBackUrl}
              clientName={clientData.name}
              onDownload={() => handleDownload('photoId.documentBackUrl', 'Document Back Side')}
              boxWidth={420}
              imgWidth={300}
              imgHeight={215}
            />
          }
        </Box>

        <Box display="flex" flexWrap="wrap">
          {pending && errors().map(error => (
            <Box mt={2} mr={2}>
              <EVError message={error} centerVertically />
            </Box>
          ))}
        </Box>
      </Box>

      {(pending && !readonly) &&
        <EVPendingButtons onRepeat={handleRepeat} onApprove={onManualApprove} />
      }
    </React.Fragment>
  )
}
import React from 'react';
import {
  capitalize,
} from '@material-ui/core';
import { clientApi } from '@app/api';
import { useSnackbar } from 'notistack';
import { Modal } from '@components/modals';

export default function DeleteDialog(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { data } = props;

  const typeTextMap = {
    'company': 'legal entity',
    'person': 'person',
    'all': 'client',
  }
  const typeTextMapPlural = {
    'company': 'legal entities',
    'person': 'people',
    'all': 'clients',
  }
  const isBatch = data && data['ids'] && Array.isArray(data.ids);
  let typeText = data && typeTextMap[data.type];
  if(isBatch){
    typeText = data && typeTextMapPlural[data.type];
  }

  const onDelete = async () => {
    for (const id of data.searchIds) {
      await clientApi.search.removeFromMonitoring(id);
    }
    if (isBatch) {
      enqueueSnackbar(`${capitalize(typeText)} were successfully removed from monitoring!`, { variant: 'success' });
    } else {
      enqueueSnackbar(`${capitalize(typeText)} "${data?.name}" was successfully removed from monitoring!`, { variant: 'success' });
    }
    props.onDeleted();
  }

  const getMainText = () => {
    if (isBatch) {
      return `Are you sure you want to remove the selected ${typeText} from ongoing monitoring?`
    }
    return `Are you sure you want to remove the selected ${typeText} "${data?.name}" from ongoing monitoring?`
  }

  return (
    <React.Fragment>
      <Modal
        open={props.open}
        onClose={props.onClose}
        title="Remove from Ongoing Monitoring"
        mainText={getMainText()}
        actions={
          [
            {
              type: 'main',
              label: 'CANCEL',
              action: props.onClose,
              style: { width: 160 },
            },
            {
              type: 'secondary',
              label: 'REMOVE',
              action: onDelete,
              style: { width: 160 },
            },
          ]
        }
        actionsDirection="row"
      />
    </React.Fragment>
  );
}
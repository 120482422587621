import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Box, Typography, Grid, Badge, withStyles } from '@material-ui/core';
import subscriptionTheme from '@components/complex/subscription/theme';
import UsageItems from '@components/complex/subscription/items/usage-items';
import { clients, invoice, subscriptionPlans } from '@app/api/admin';
import { useWindowSize} from '@utils/useWindowSize';

import { makeStyles, ThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: '40px'
  }
}))

const StyledBadge = withStyles((theme) => ({
  badge: props => ({
    width: '32px',
    height: '16px',
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '7px',
    background: props.customColor,
    color: '#fff',
    right: 20,
  }),
}))(Badge);

const payingColors = {
  'default': '#7F8AF2',
  'Default plan': '#7F8AF2',
  'Basic plan': '#7FD6F2',
  'Basic +': '#7FD6F2',
  'Small business plan': '#4A9BB5',
  'Small business +': '#4A9BB5',
  'Premium plan': '#B6514C',
  'Premium +': '#B6514C',
}

export default function Overview({ userIsAdmin }) {
  const classes = useStyles(); // eslint-disable-line
  const [width] = useWindowSize();

  const [plans, setPlans] = useState([]);
  const [users, setUsers] = useState([]);
  const [payingData, setPayingData] = useState([]);
  const [payingUsersCount, setPayingUsersCount] = useState(null);
  const [invoices, setInvoices] = useState(null);

  const isSmallMonitor = useMemo(() => {
    return width <= 1340
  }, [width])

  const getAllPlans = useCallback(async () => {
    const res = await subscriptionPlans.getAll();

    if (res.status === 200) {
      setPlans(res.data);
    }
  }, [])
  
  const getPayingUsers = useCallback(async () => {
    const usersPaying = [];
    for (const plan of plans) {
      const users = (await clients.searchUsers({
        testUser: false,
        planId: +plan.id
      }))?.data;
      if(users){
        usersPaying.push({
          name: plan.name,
          count: users.count
        })
      }
    }

    setPayingData(usersPaying);
    const count = usersPaying.reduce((acc, val) => acc + val.count, 0);
    setPayingUsersCount(count.toString());
  }, [plans])

  const getAllUsers = useCallback(async () => {
    const users = (await clients.searchUsers({
      testUser: false
    })).data;

    if (users) {
      setUsers(users);
    }
  }, [])

  const getInvoices = useCallback(async () => {
    const allInvoices = (await invoice.getInvoices({
      testUser: false,
      pageSize: 1
    })).data;

    if (allInvoices) {
      const paidInvoices = (await invoice.getInvoices({
        testUser: false,
        status: 'PAID',
        pageSize: 1,
      })).data;

      setInvoices({
        generated: allInvoices.count,
        paid: paidInvoices.count
      });
    }
  }, [])

  useEffect(() => {
    getAllPlans();
    getAllUsers();
    getInvoices();
  }, [getAllPlans, getAllUsers, getInvoices])
  
  useEffect(() => {
    if (!!plans.length) {
      getPayingUsers();
    }
  }, [getPayingUsers, plans])

  return (
    <ThemeProvider theme={subscriptionTheme}>
      <Box mt={5} mb={6} display="flex" justifyContent="space-between">
        <Typography variant="h5">APP OVERVIEW</Typography>
      </Box>
      {(!!payingUsersCount && !!invoices) && (
        <Grid container>
          <Grid item sm={(isSmallMonitor && userIsAdmin) ? 5 : userIsAdmin ? 4 : 3} className={classes.marginBottom}>
            <UsageItems title="SUBSCRIPTION PLANS" value={plans.length} variant="h1" />
          </Grid>
          {!userIsAdmin && (
            <Grid item sm={3} className={classes.marginBottom}>
              <UsageItems title="PROMO CODES" value={0} variant="h1" />
            </Grid>
          )}
          <Grid item sm={userIsAdmin ? 2 : 3} className={classes.marginBottom}>
            <UsageItems title="INVOICES GENERATED" value={invoices?.generated || 'N/A'} variant="h1" />
          </Grid>
          <Grid item sm={3} className={classes.marginBottom}>
            <UsageItems
              title="INVOICES PAID"
              value={invoices?.paid || 'N/A'}
              variant="h1"
              additionalData={!!invoices && (
                <Box mt={1}>
                  <Box display="flex" alignItems="center">
                    <StyledBadge badgeContent={invoices?.generated} customColor="#7A5B44" max={1000} />
                    <Typography variant="subtitle1">Generated</Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <StyledBadge badgeContent={invoices?.paid} customColor="#6EC744" max={1000} />
                    <Typography variant="subtitle1">Paid</Typography>
                  </Box>
                </Box>
              )}
            />
          </Grid>
          {userIsAdmin && (
            <Grid item sm={(isSmallMonitor && userIsAdmin) ? 2 : 3} className={classes.marginBottom}>
              <UsageItems title="" />
            </Grid>
          )}
          <Grid item sm={(isSmallMonitor && userIsAdmin) ? 5 : ((userIsAdmin && !isSmallMonitor) || (!userIsAdmin && isSmallMonitor)) ? 4 : 3} className={classes.marginBottom}>
            <UsageItems
              title="USERS PAYING FOR SUBSCRIPTIONS"
              value={payingUsersCount}
              variant="h1"
              additionalData={(
                <Box mt={1}>
                  {payingData.map((el, i) => (
                    <Box display="flex" alignItems="center" key={`${el.name}-${i}`}>
                      <StyledBadge badgeContent={el.count} customColor={payingColors[el.name] || payingColors['default']} max={1000} />
                      <Typography variant="subtitle1">{el.name === 'Small business plan' ? 'Small business' : el.name}</Typography>
                    </Box>
                  )).reverse()}
                </Box>
              )}
            />
          </Grid>
          <Grid item sm={(isSmallMonitor && userIsAdmin) ? 4 : 3} className={classes.marginBottom}>
            <UsageItems
              title="NUMBER OF USERS"
              value={users.count}
              variant="h1"
              additionalData={!userIsAdmin && (
                <Box mt={1}>
                  <Box display="flex" alignItems="center">
                    <StyledBadge badgeContent={users.count} customColor="#656BBA" max={1000} />
                    <Typography variant="subtitle1">Regular Users</Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <StyledBadge badgeContent={10} customColor="#62A3B8" max={1000} />
                    <Typography variant="subtitle1">Admins</Typography>
                  </Box>
                </Box>
              )}
            />
          </Grid>
          {!userIsAdmin && (
            <Grid item sm={(isSmallMonitor && userIsAdmin) ? 4 : 3} className={classes.marginBottom}>
              <UsageItems
                title="TOTAL ENTITIES"
                value="1.69k"
                variant="h1"
                additionalData={(
                  <Box mt={1}>
                    <Box display="flex" alignItems="center">
                      <StyledBadge badgeContent="1.78k" customColor="#7F8AF2" max={1000} />
                      <Typography variant="subtitle1">People</Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <StyledBadge badgeContent={198} customColor="#7FD6F2" max={1000} />
                      <Typography variant="subtitle1">Companies</Typography>
                    </Box>
                  </Box>
                )}
              />
            </Grid>
          )}
        </Grid>
      )}
    </ThemeProvider>
  )
}
import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { eidv } from '@app/api/client';
import history from '@app/history';
import casesDto from './dto';

import ElectronicVerificationAddWrapper from './add-eidv-wrapper';
import ElectronicVerificationView from './view';
import CheckEidvCredits from './check-eidv-credits';

function ElectronicVerification(props) {
  const { baseUri, data, updateEidvStatus, fetchClient } = props
  const [cases, setCases] = useState([]);
  const [selectedCaseId, setSelectedCaseId] = useState(null);

  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const forceStart = search.get('forceStart');

  useEffect(() => {
    if (data?.id) {
      fetchCases();
    }
  }, [data?.id]) // eslint-disable-line

  const fetchCases = (redirect) => {
    eidv.index({clientId:data?.id}).then((res) => {
      const formattedCases = res.data.entries.map(e => casesDto.read(e));
      setCases(formattedCases)
      if (redirect) {
        const caseId = formattedCases[0].id;
        setSelectedCaseId(formattedCases[0].id);
        updateEidvStatus && updateEidvStatus();
        history.replace(`${baseUri}/${caseId}/overview`);
      }
    })
  }

  const handleOnCreate = () => {
    fetchCases(true);
  }

  if (!cases.length) {
    return (
      <ElectronicVerificationAddWrapper
        path={baseUri}
        cases={cases}
        onCreate={handleOnCreate}
        forceStart={!!forceStart}
        onUpdateEmailCallback={() => fetchClient(true)}
        {...props}
      />
    )
  }

  return (
    <Switch>
      <Route path={`${baseUri}/new`}>
        <ElectronicVerificationAddWrapper
          path={baseUri}
          cases={cases}
          onCreate={handleOnCreate}
          forceStart={!!forceStart}
          onUpdateEmailCallback={() => fetchClient(true)}
          {...props}
        />
      </Route>
      <Route path={`${baseUri}/:caseId/:tab`}>
        <ElectronicVerificationView
          path={baseUri}
          cases={cases}
          fetchCases={fetchCases}
          selectedCaseId={selectedCaseId}
          setSelectedCaseId={setSelectedCaseId}
          {...props}
        />
      </Route>
      <Route path={`${baseUri}/`}>
        <ElectronicVerificationView
          path={baseUri}
          cases={cases}
          fetchCases={fetchCases}
          selectedCaseId={selectedCaseId}
          setSelectedCaseId={setSelectedCaseId}
          {...props}
        />
      </Route>
    </Switch>
  )
}

export default function EidvIndex(props) {
  return (
    <CheckEidvCredits>
      <ElectronicVerification {...props} />
    </CheckEidvCredits>
  )
}
import React, {useEffect, useMemo, useState} from 'react';
import {getModeByUrlParam} from '../utils/modeUtil';
import {Box} from '@material-ui/core';
import {useParams} from 'react-router-dom';
import {clientApi} from '@app/api';
import Form from './form/form';
import EntitySettingParticularHeader from './header';

export default function Edit() {
  const {tab, filterValue, id} = useParams()
  const [data, setData] = useState(null)

  const mode = useMemo(() => {
    return getModeByUrlParam(tab)
  }, [tab])

  useEffect(() => {
    fetch() //eslint-disable-line
  }, []) //eslint-disable-line

  const fetch = async () => {
    const res = (await clientApi.clientRulebook.show(id)).data
    setData(
      res
    )
  }

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      <EntitySettingParticularHeader
        mode={mode}
        filterValue={filterValue}
        jurisdiction={data?.jurisdiction}
        assocType={data?.associationType}
        ownedBy={data?.ownedBy}
        id={id}
        readOnly={true}
      />
      <Box display={'flex'} mt={2} style={{overflow: 'auto', maxHeight: 'calc(100vh - 300px)'}}>
        <Box display={'flex'} style={{width: '100%'}}>
          <Form data={data} setData={setData} readOnly={true}/>
        </Box>
      </Box>
    </Box>
  )
}
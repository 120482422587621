import React from 'react';

import {
  Box, Button, Typography,
} from '@material-ui/core';

import history from '@app/history';
import EidvSentIcon from '@assets/icons/EIDV/EidvSent.png';
import CheckedOption from '../../../../views/auth/checked-option'

const texts = [
  'Photo ID Document upload',
  'Document authenticity verification',
  'Facial match using optical recognition',
  'Address extraction',
  'Proof of address document upload',
]

export default function NoEidv() {
  return (
    <Box>
      <Box mb={-1} display="flex" justifyContent="center">
        <img alt="" src={EidvSentIcon}></img>
      </Box>
      <Box mt={1}>
        <Typography variant="h5" align="center" style={{ color: '#484848' }}>Electronic Identity Verification</Typography>
      </Box>
      <Box mt={1}>
        <Typography variant="h5" align="center" style={{ color: '#484848' }}>helps you onboard customers fast, using a secure digital flow</Typography>
      </Box>
      <Box mt={3} pl={6} display="flex" justifyContent="center">
        <Box display="flex" flexDirection="column">
          {texts.map(text => {
            return (
              <Box display="flex" key={text}>
                <CheckedOption>
                  <Typography variant="subtitle2" style={{ color: '#484848' }}>
                    {text}
                  </Typography>
                </CheckedOption>
              </Box>
            )
          })}
        </Box>
      </Box>

      <Box mt={4}>
        <Typography variant="h5" align="center" style={{ color: '#484848' }}>eIDV is not included in your current subscription plan.</Typography>
      </Box>

      <Box mt={3} pb={2} display="flex" justifyContent="center">
        <Button variant="contained" onClick={() => history.push('/my-profile/subscription/plans')}>
          VIEW SUBSCRIPTION PLANS
        </Button>
      </Box>
    </Box>
  )
}

import React, { useMemo, useState } from 'react';

import { Box, Typography, makeStyles, IconButton, Button, Link } from '@material-ui/core';
import {
  ImageInfoIcon
} from '@app/icons';
import CustomTooltip from '@components/controls/tooltip';
import LabelsForRender from '@components/layout/labels-for-render';
import LinkButton from '@components/buttons/link-button'
import EVTitle from '../components/eidv-title';
import ImageWrapper from '../components/eidv-image-preview';
import EidvCancelModal from '../components/eidv-cancel-modal';

const CANCELABLE_STATUS = ['requested', 'in progress', 'repeated', 'requested repetition'];

const useStyles = makeStyles((theme) => ({
  docWrapper: {
    position: 'relative',
  },
  docButton: {
    padding: 0,
    width: 30,
    height: 30,
  },
  infoButton: {
    position: 'absolute',
    top: 8,
    left: 8,
    padding: 4,
  },
}));

function UploadedDocument({ doc, handleDownload, clientName }) {
  const classes = useStyles();

  return (
    <Box>
      <Box display="flex" justifyContent="center" className={classes.docWrapper}>
        <ImageWrapper
          title={doc.name}
          imgSrc={doc.url}
          clientName={clientName}
          onDownload={() => handleDownload(doc.downloadPath, doc.name)}
          boxWidth={220}
          imgWidth={200}
          imgHeight={160}
          isPdf={doc.type === 'pdf'}
        >
          {doc.tooltip?.length &&
            <CustomTooltip
              title={(
                <LabelsForRender
                  min
                  labelProps={{ style: { color: 'white', textAlign: 'left', width: 100, maxWidth: 'none' } }}
                  valueProps={{ style: { color: 'white' } }}
                  labels={doc.tooltip}
                  boxProps={{ m: 2 }}
                />
              )}
              placement="right-start"
            >
              <IconButton className={classes.infoButton}>
                <ImageInfoIcon width={24} height={24} />
              </IconButton>
            </CustomTooltip>
          }
        </ImageWrapper>
      </Box>
    </Box>
  )
}

export default function EVOverview({ data, clientData, handleDownload, handleRepeat, handleApprove, readonly, fetchData }) {
  const [showCancelModal, setShowCancelModal] = useState(false);

  const docs = useMemo(() => {
    const documents = data.overview || {}
    const docs = [];

    if (documents.authDocument?.url) {
      docs.push(documents.authDocument)
    }
    if (documents.addressDocument?.url) {
      docs.push(documents.addressDocument)
    }

    if (data.status === 'rejected') {
      if (documents.faceDocument?.url) {
        docs.push(documents.faceDocument)
      }

      return docs.map(doc => ({
        ...doc,
        tooltip: undefined,
        name: 'Document',
      }))
    }

    return docs;
  }, [data])

  const overviewText = useMemo(() => {
    switch (data.status) {
      case 'requested':
        return 'Client has not completed electronic identity verification yet.'

      case 'expired':
        return 'Client has not completed electronic identity verification within 7 days. The session has expired.'

      case 'pending approval':
      case 'completed':
        return !!docs.length ? '' : 'Client has not provided any documents.'

      case 'canceled':
        return (data.note && data.note !== 'N/A')
          ? `Electronic verification was canceled with reason: \n${data.note}`
          : 'Electronic verification was canceled.'

      case 'rejected':
        return data.rejectionReason

      default:
        return ''
    }
  }, [data, docs])

  return (
    <React.Fragment>
      <EidvCancelModal
        open={showCancelModal}
        setOpen={setShowCancelModal}
        data={data}
        clientData={clientData}
        fetchData={fetchData}
      />

      <Box>
        <EVTitle status={data.status}>Overview</EVTitle>
        <Box my={3}>
          <Typography align="left" variant="subtitle1" style={{ color: '#484848', whiteSpace: 'pre-wrap' }}>
            {overviewText}
          </Typography>
        </Box>
        {!!docs.length &&
          <Box mt={2} width={docs.length * 260} style={{ borderRadius: 12, backgroundColor: '#F8F8F8' }}>
            <Box pt={2}>
              <Typography variant="subtitle1" align="center" style={{ color: '#484848' }}>Uploaded Documents</Typography>
            </Box>
            <Box px={2} display="flex" justifyContent="center" alignItems="center">
              {docs.map(doc => (
                <Box p={2} key={doc.downloadPath}>
                  <UploadedDocument
                    doc={doc}
                    handleDownload={handleDownload}
                    clientName={clientData.name}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        }
      </Box>
      {!readonly &&
        <Box marginTop="auto">
          {data?.status === 'pending approval' &&
            <Box mb={2} display="flex" justifyContent="flex-end" alignItems="center">
              <Box mr={2}>
                <LinkButton onClick={handleRepeat}>
                  REPEAT STEPS
                </LinkButton>
              </Box>
              <Box width={196}>
                <Button onClick={handleApprove} variant="contained" style={{ width: '100%' }}>APPROVE STEPS</Button>
              </Box>
            </Box>
          }
          {CANCELABLE_STATUS.includes(data?.status) &&
            <Box mb={2}>
              <Typography align="right"><Link onClick={() => setShowCancelModal(true)} style={{ color: '#B6514C' }}>Click here</Link> if you wish to Cancel this Session</Typography>
            </Box>
          }
        </Box>
      }
    </React.Fragment>
  )
}

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import {AppBar, Toolbar, Button, Box, useScrollTrigger, Menu, MenuItem, InputBase } from '@material-ui/core';
import { Logo } from '@app/icons';
import LockIcon from '@assets/img/icons/lock.png';
import history from '@app/history';

import { useSelector } from 'react-redux';
import auth from '@app/auth';

import { SearchOtherIcon, DropdownDownIcon } from '@app/icons';
import { getColorStyles } from '@app/theme';

import GlobalSearch from './global-search/index';

const selectIsGuest = state => !state.auth.user;
const selectFirstName = state => state.auth.user && state.auth.user.name;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 15px',
    minHeight: 64,
    ...getColorStyles(theme.palette.header, true)
  },
  adminAppStyle: {
    display: 'flex',
    justifyContent: 'center',
  },
  search: {
    position: 'relative',
    borderRadius: '12px',
    marginRight: '20px',
    height: '28px',
    width: '248px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
    },
    backgroundColor: '#E9F4F4',
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inerhit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
    fontSize: '12px',
  },
  menu: {
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuIcon: {
    marginLeft: '4px',
    alignItems: 'center'
  },
  searchIconRoot: {
    height: '16px',
    width: '16px',
  },
  menuIconFlipped: {
    transform: 'rotate(180deg)'
  },
  dropdownButton: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    textTransform: 'none',
  }
}))

export default function Header(props) {
  const classes = useStyles();

  const isGuest = useSelector(selectIsGuest);
  const firstName = useSelector(selectFirstName);
  const { userIsAdmin, side } = useSelector(store => store.auth);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: props.scrollRef || window,
  });

  const onLogout = function () {
    console.log('User manually logged out')
    auth.logout(true)
    // history.push('/login');
  }

  const handleRedirect = (url) => {
    handleClose()
    history.push(url)
  }

  return (
    <AppBar
      display="flex"
      position={props.headerPosition || 'fixed'}
      color={'transparent'} elevation={(scrollTrigger ? 1 : 0)}
      className={clsx(scrollTrigger && 'scrolled', classes.root,
        {
          [classes.adminAppStyle]: props.isMenu && props.isSearch
        })
      }
    >
      <Toolbar>
        <Box flexGrow={1}>
          <Button
            onClick={() => (props.isMaintenance || props.isBack) ? history.goBack() : history.push('/')}
            className="logo-button"
            disableRipple
          >
            <Logo />
          </Button>
        </Box>

        {(side !== 'admin' && !userIsAdmin && !isGuest && !props.isMaintenance) && (
          <Box flexGrow={1}>
            <GlobalSearch />
          </Box>
        )}

        {props.isSearch && (
          <React.Fragment>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchOtherIcon />
              </div>
              <InputBase
                placeholder="Search for anything…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          </React.Fragment>
        )}

        <Box className="right">
          {isGuest &&
                            <React.Fragment>
                              <Box display={{ xs: 'none', sm: 'flex' }}>
                                <Button onClick={ () => history.push('/login') } style={{ textTransform: 'none' }}>
                                        Login
                                </Button>
                                <Button
                                  onClick={() => props.redirect
                                    ? props.redirect('signup')
                                    : history.push('/signup')
                                  }
                                  style={{ textTransform: 'none' }}
                                >
                                        Sign Up
                                </Button>
                              </Box>
                              <Box display={{ xs: 'flex', sm: 'none' }}>
                                <Button  onClick={ () => history.push('/login') }>
                                  <img alt="login" src={LockIcon}/>
                                </Button>
                              </Box>
                            </React.Fragment>
          }
          {!isGuest &&
                            props.isMenu && (
            <div className={classes.menu}>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                endIcon={<DropdownDownIcon />}
                classes={{ label: classes.dropdownButton, endIcon: clsx(classes.menuIcon, Boolean(anchorEl) && classes.menuIconFlipped) }}
              >
                {firstName}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                {((userIsAdmin && side === 'admin') || !userIsAdmin) && (
                  <MenuItem onClick={() => handleRedirect('/my-profile/my-account')}>My Profile</MenuItem>
                )}
                {((userIsAdmin && side === 'admin') || !userIsAdmin) && (
                  <MenuItem onClick={() => handleRedirect('/my-profile/subscription')}>My Subscription</MenuItem>
                )}
                <MenuItem onClick={onLogout}>Logout</MenuItem>
              </Menu>
            </div>
          )}

          {!isGuest
                            && !props.isMenu && (
            <React.Fragment>
              <Box display={{ xs: 'none', sm: 'flex' }}>
                <Button onClick={ onLogout }>
                                            Logout
                </Button>
              </Box>
              <Box display={{ xs: 'flex', sm: 'none' }}>
                <Button  onClick={ onLogout }>
                  <img alt="logout" src={LockIcon}/>
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  styleBadgeRoot: {
    width: '100px',
    fontWeight: 400,
  },
  noActions: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#A4A4A4',
  },
  header: {
    marginBottom: '22px',
  },
  back: {
    height: '40px',
    // width: '300px',
    borderRadius: '9px',
    padding: '2px',
    '&:hover': {
      background: '#F8F8F8',
      cursor: 'pointer',
    },
  },
  buttonGroupRoot: {
    height: 30,
    width: '220px!important',
  },
  mounthButton: {
    padding: 0,
    border: '1px solid #EDEDED',
    color: '#A4A4A4',
    background: '#F8F8F8',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px',
    textTransform: 'none',
    '&.Mui-disabled': {
      border: '1px solid #EDEDED',
    },
    '&.active': {
      color: '#232323',
      background: '#fff',
    },
    '&:hover': {
      background: '#EDEDED',
    },
  },
  reset: {
    width: 205,
    marginRight: '10px',
    '&.MuiButton-sizeSmall': {
      padding: '2px 5px',
    },
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1650px)']: {
      display: 'none',
    },
  },
  advancedSelect: {
    padding: '10px',
    '&.MuiInputBase-input': {
      fontSize: '12px',
    },
  },
  inputLabel: {
    fontSize: '12px',
    top: '-5px',
    left: '-8px',
    opacity: 0.7,
  },
}));

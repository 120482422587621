import React, { useEffect, useState } from 'react';

import { Box, Typography, FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import { eidv } from '@app/api/client';
import { useSnackbar } from 'notistack';
import { Modal } from '@components/modals';

const CANCEL_NOTE_LENGTH = 200;

export default function EidvCancelModal({ data, clientData, open, setOpen, fetchData }) {
  const { enqueueSnackbar } = useSnackbar();
  const [note, setNote] = useState('')

  useEffect(() => {
    setNote('');
  }, [open])

  const cancelEidv = () => {
    eidv.cancel({ note: note || 'N/A' }, data.id).then(() => {
      enqueueSnackbar('eIDV request has been cancelled!', { variant: 'success' })
      setOpen(false);
      fetchData();
    })
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      title={`Cancel ${clientData.name || data?.email || ''} eIDV Session`}
      mainText="Are you sure you want to cancel this session?"
      actions={[
        {
          type: 'main',
          label: 'No',
          action: () => setOpen(false),
          style: { width: 150 },
        },
        {
          type: 'secondary',
          label: 'Yes',
          action: () => cancelEidv(),
          style: { width: 150 },
        }
      ]}
      actionsDirection="row"
      content={(
        <FormControl fullWidth variant="outlined" style={{ position: 'relative' }}>
          <InputLabel>Note</InputLabel>
          <OutlinedInput
            value={note}
            onChange={(e) => setNote(e.target.value)}
            multiline
            inputProps={{
              maxLength: CANCEL_NOTE_LENGTH
            }}
            rows={3}
          />
          <Box
            position="absolute"
            top={8}
            right={8}
          >
            <Typography variant="body2" align="right">{note.length}/{CANCEL_NOTE_LENGTH}</Typography>
          </Box>
        </FormControl>
      )}
    />
  )
}

import React, { useMemo, useState, useEffect } from 'react';
// eslint-disable-next-line
import { Box, Grid, RadioGroup, FormControlLabel, Radio, Switch, makeStyles } from "@material-ui/core";
import TableList from '@components/lists/table-list';
import OutlinedSearch from '@components/inputs/outlined-search';
import InlineHeadBadge from '@components/badges/inline-head-badge';
import FilterWithContext from '@components/dropdowns/filter-with-context';
import { Modal } from '@components/modals';

import { useSnackbar } from 'notistack';
import history from '@app/history';
import { dummy } from '../../dummy';

const useStyles = makeStyles((theme) => ({
  modalButtonWidth: {
    width: '160px',
  },
}));

const TABLE_COLUMNS = [
  { field: 'name', headerName: 'Name', bold: true },
  { field: 'code', headerName: 'Code' },
  { field: 'billableTypes', headerName: 'Billable Types' },
  { field: 'usageCount', headerName: 'Usage Count' },
  { field: 'type', headerName: 'Type' },
  { field: 'value', headerName: 'Value' },
  { field: 'expirationDate', headerName: 'Expiration Date' },
  { field: 'status', headerName: 'Status' },
]

const switchGroup = [
  { value: 'active', label: 'Active Promo Codes', isChecked: true },
  { value: 'percentageType', label: 'Percentage Type', isChecked: true },
  { value: 'expired', label: 'Expired Promo Codes', isChecked: true },
  { value: 'amountType', label: 'Amount Type', isChecked: true },
  { value: 'blocked', label: 'Blocked Promo Codes', isChecked: true },
]


export default function PromoCodes({ baseRoute, tabID, openModal, setOpenModal, setBillingData }) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState(dummy.promoCodes);
  const [search, setSearch] = useState();
  // eslint-disable-next-line
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState(switchGroup);

  useEffect(() => {
    setBillingData(data);
  }, [data, setBillingData])

  const onSearchChange = (event) => {
    setSearch(event.target.value);
    // setPage(0);
  }

  const onFilterChange = (event) => {
    const changed = filters.map(el =>
      el.value === event.target.value ? ({ ...el, isChecked: event.target.checked }) : el
    );
    setFilters(changed);
  }

  const onSelect = (id) => {
    const newData = data.map(
      (item) => item.id === id
        ? ({ ...item, isChecked: !item.isChecked })
        : item
    );
    setData(newData);
  }

  const handleConfirm = () => {
    const actions = { delete: 'deleted', block: 'blocked', unblock: 'unblocked' };
    enqueueSnackbar(
      `Promo Code "${openModal.name}" was successfully ${actions[openModal.type]}!`,
      { variant: 'success' }
    );
    setOpenModal(null);
  }

  const handleMenuAction = (id, item, type) => {
    setOpenModal({ id, name: item.name, type, prefix: 'code' });
  }

  const formattedData = useMemo(() => {
    return data.map(e => {
      const item = { ...e };

      switch (item.status) {
        case 'Active':
          item.cellProps = {
            status: {
              style: {
                color: '#4E922C'
              }
            }
          }
          item.threeDotsActionsDisabled = ['Unblock']
          break;
        
        case 'Blocked':
          item.cellProps = {
            status: {
              style: {
                color: '#B6514C'
              }
            }
          }
          item.threeDotsActionsDisabled = ['Block']
          break;
        
        default:
          item.threeDotsActionsDisabled = ['Unblock']
          break;
      }

      return item;
    })
  }, [data])

  const tableActions = [
    { label: 'Edit', handler: (id, item) => history.push(`${baseRoute}/edit-${tabID}/?promoId=${id}`) },
    { label: 'Delete', handler: (id, item) => handleMenuAction(id, item, 'delete') },
    { label: 'Block', handler: (id, item) => handleMenuAction(id, item, 'block') },
    { label: 'Unblock', handler: (id, item) => handleMenuAction(id, item, 'unblock') },
  ]

  return (
    <Box display={'flex'} flexGrow={'1'} mt={2} flexDirection={'column'}>
      <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'}>
          <Box mr={1}>
            <InlineHeadBadge
              badgeColor={'#CAD7CA'}
              badgeValue={4}
            >
              PROMO CODES
            </InlineHeadBadge>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <FilterWithContext>
            <Grid container style={{ width: '380px' }} justify="space-between">
              {filters.map((el, i) => (
                <Grid item sm={5} style={{ margin: '0 10px' }}>
                  <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value} - ${i}`}>
                    <FormControlLabel
                      control={<Switch value={el.value} checked={el.isChecked} onClick={onFilterChange} />}
                      label={el.label}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </FilterWithContext>
        </Box>
      </Box>
      <Box display={'flex'} mb={3}>
        <OutlinedSearch
          value={search}
          onChange={onSearchChange}
          label="Search promo codes"
        />
      </Box>
      <Grid container>
        <Grid item sm={12}>
          <TableList
            columns={TABLE_COLUMNS}
            items={formattedData}
            page={page}
            onSelect={onSelect}
            pageSize={12}
            pagesCount={1}
            totalCount={10}
            sortableColumns={TABLE_COLUMNS.map(el => el.field)}
            handlers={{
              export: (id) => {}
            }}
            maxHeight="calc(100vh - 380px)"
            threeDotsActions={tableActions}
          />
        </Grid>
      </Grid>
      <Modal
        open={!!openModal}
        onClose={() => setOpenModal(null)}
        title={`${openModal?.type} promo ${openModal?.prefix}`}
        mainText={`Are you sure you want to ${openModal?.type} promo ${openModal?.prefix} "${openModal?.name}"?`}
        propsClassses={classes.modalButtonWidth}
        actions={
          [
            {
              type: 'main',
              label: 'No',
              action: () => setOpenModal(null),
            },
            {
              type: 'secondary',
              label: 'Yes',
              action: handleConfirm,
            },
          ]
        }
        actionsDirection="row"
      />
    </Box>
  )
}

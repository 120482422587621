import React, { useEffect, useState } from 'react';
import { Box, Typography, makeStyles, ThemeProvider } from '@material-ui/core';
import subscriptionTheme from '@components/complex/subscription/theme';
import UsageItems from '@components/complex/subscription/items/usage-items';
import { clients } from '@app/api/admin';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflowY: 'auto',
  },
  itemWrapper: {
    width: 325,
  },
}));

export default function Eidv({ userIsAdmin }) {
  const classes = useStyles();
  const [data, setData] = useState({});

  useEffect(() => {
    getTotalEidvs();
  }, []);

  const getTotalEidvs = async () => {
    const statuses = [
      'PENDING_APPROVAL',
      'REPEATED',
      'REJECTED',
      'EXPIRED',
      'COMPLETED',
      'REQUESTED',
      'CANCELED',
      'IN_PROGRESS',
    ];
    const types = [
      'CLIENT_DOESNT_EXIST',
      'CLIENT_EXISTS_NEW_CASE',
      'CLIENT_EXISTS_REPEATED_CASE',
    ];
    Promise.all([
      clients.getTotalEidvs(statuses, types),
      clients.getTotalEidvs(statuses, types.slice(0, 2)),
      clients.getTotalEidvs(statuses, [types[2]]),
      ...statuses
        .slice(0, 7)
        .map((item) => clients.getTotalEidvs([item], types)),
    ]).then((value) =>
      setData({
        total: value[0]?.data?.eidvsCases,
        clientsWith: value[1]?.data?.eidvsCases,
        additional: value[2]?.data?.eidvsCases,
        pending: value[3]?.data?.eidvsCases,
        repeted: value[4]?.data?.eidvsCases,
        rejected: value[5]?.data?.eidvsCases,
        expired: value[6]?.data?.eidvsCases,
        completed: value[7]?.data?.eidvsCases,
        requested: value[8]?.data?.eidvsCases,
        canceled: value[9]?.data?.eidvsCases,
      })
    );
  };

  return (
    <ThemeProvider theme={subscriptionTheme}>
      <Box mt={5} mb={6} display="flex" justifyContent="space-between">
        <Typography variant="h5">
          ELECTRONIC IDENTITY VERIFICATIONS OVERVIEW
        </Typography>
      </Box>
      {!!data && (
        <Box className={classes.root}>
          <Box display="flex" justifyContent="space-between">
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Total of electronic verification requests"
                value={data.total}
              />
            </Box>
          </Box>
          <Box display="flex" my={7}>
            <Box className={classes.itemWrapper} mr={8}>
              <UsageItems
                title="Total of clients with electronic verification requests"
                value={data.clientsWith}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Total of additional electronic verification requests for clients"
                value={data.additional}
              />
            </Box>
          </Box>
          <Box display="flex" my={7}>
            <Box className={classes.itemWrapper} mr={8}>
              <UsageItems
                title="Count of requested electronic verification requests"
                value={data.requested}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Count of pending review electronic verification requests"
                value={data.pending}
              />
            </Box>
          </Box>
          <Box display="flex" my={7}>
            <Box className={classes.itemWrapper} mr={8}>
              <UsageItems
                title="Count of repeated electronic verification requests"
                value={data.repeted}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Count of rejected electronic verification requests"
                value={data.rejected}
              />
            </Box>
          </Box>
          <Box display="flex" mt={7}>
            <Box className={classes.itemWrapper} mr={8}>
              <UsageItems
                title="Count of expired electronic verification requests"
                value={data.expired}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Count of completed electronic verification requests"
                value={data.completed}
              />
            </Box>
          </Box>
          <Box display="flex" mt={7}>
            <Box className={classes.itemWrapper} mr={8}>
              <UsageItems
                title="Count of canceled verification requests"
                value={data.canceled}
              />
            </Box>
          </Box>
        </Box>
      )}
    </ThemeProvider>
  );
}

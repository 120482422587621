import React from 'react';

import { adminApi } from '@app/api/';
import { useSnackbar } from 'notistack';
import CategoryTypeCreateDialog from '../../../../../components/complex/client-view/kyc-documents/components/category-type-create-dialog';

export default function TypeDialog({ open, onClose, categories, editData, fetchData, types }) {
  const { enqueueSnackbar } = useSnackbar();

  const createType = async (model) => {
    const parsedData = {
      ...model,
      id: undefined,
      isSystem: undefined
    }

    await adminApi.type.createType(parsedData)
    enqueueSnackbar(`Document Type "${model.name}" was successfully created!`, { variant: 'success' });
    fetchData();
    onClose();
  }

  const updateType = async (model) => {
    const parsedData = {
      ...model,
      id: undefined,
      isSystem: undefined
    }

    await adminApi.type.updateType(editData.id, parsedData)
    fetchData();

    enqueueSnackbar(`Document Type "${model.name}" was successfully updated!`, { variant: 'success' });
    onClose();
  }

  return (
    <CategoryTypeCreateDialog
      open={open}
      categoryType={editData}
      categories={categories}
      onClose={onClose}
      isAdmin
      useCustom
      types={types || []}
      onSave={editData ? updateType : createType}
      title={editData ? 'Update Document Type' : 'Add Document Type'}
    />
  )
}
import React from 'react';
import clsx from 'clsx';

import CustomTooltip from '@components/controls/tooltip';

import {
  CheckmarkSuccessIcon,
  CheckmarkWarningIcon,
  CheckmarkErrorIcon,
} from '@app/icons';

export default function getKycDocumentStatus(clientData, classes) {
  const status = clientData?.clientStatus?.documentsClientStatus
  switch (status) {
    case 'COMPLETE':
      return (
        <CustomTooltip title="KYC documents are complete but not all the required documents are up to date" placement="top">
          <CheckmarkWarningIcon />
        </CustomTooltip>
      )

    case 'COMPLETE_UP_TO_DATE':
      return (
        <CustomTooltip title="KYC Documents are complete and all the required documents are up to date" placement="top">
          <CheckmarkSuccessIcon />
        </CustomTooltip>
      )

    case 'NOT STARTED':
    case 'INCOMPLETE':
    default:
      return (
        <CustomTooltip title="KYC Documents are incomplete" placement="top">
          <CheckmarkErrorIcon className={clsx(classes.greyscaleCheckmark)} />
        </CustomTooltip>
      )
  }
}
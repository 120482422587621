import React, { useMemo, useState } from 'react';
import * as yup from 'yup';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  InputLabel,
  ListItemText,
  ListItemIcon,
  IconButton
} from '@material-ui/core';
import { countryCodeList } from '@services/country-service';
import SelectClearable from '@components/inputs/select-clearable';
import { entityTypes } from '@utils/entityType';
import SelectSearchable from '@components/inputs/select-searchable';
import CustomTooltip from '@components/controls/tooltip';
import { EditIcon } from '@app/icons';
import { Modal } from '@components/modals';
import parseYupErrors from '@app/yup';
import { adminApi } from '@app/api';
import { useSnackbar } from 'notistack';

export const schema = yup.object().shape({
  name: yup.string().trim().required().max(100).label('Entity Name'),
  jurisdiction: yup.string().trim().required().label('Jurisdiction'),
  entityType: yup.string().trim().required().label('Entity Type'),
});

export default function Details({ data, setData, errors, setErrors, scope, globalRules, type, rulebookId, clearDocuments }) {
  const { enqueueSnackbar } = useSnackbar();
  const [changeNameOpen, setChangeNameOpen] = useState(false);
  const [pendingTypeChange, setPendingTypeChange] = useState(null);

  const changeType = () => {
    setData({
      ...data,
      entityType: pendingTypeChange,
    })

    setPendingTypeChange(null);
    clearDocuments();
  }

  const onChange = field => e => {
    const value = e.target.value

    if (field === 'entityType') {
      if (data.entityType) {
        if ((value === 'PERSON' && data.entityType !== 'PERSON') ||
          (value !== 'PERSON' && data.entityType === 'PERSON')
        ) {
          return setPendingTypeChange(value)
        }
      }
    }

    setData({
      ...data,
      [field]: value
    })
  }

  const typeOptions = useMemo(() => {
    if (globalRules) {
      return entityTypes.filter(e =>
        globalRules.some(rule => rule.clientType === e.name || rule.legalEntityType === e.name)
      )
    } else {
      return entityTypes
    }
  }, [globalRules])

  const handleSaveNameChange = async () => {
    setErrors({});
    try {
      await schema.validate(data, { abortEarly: false })
    } catch (err) {
      setErrors(parseYupErrors(err))
    }

    await adminApi.rulebooks.updateClientRulebook(rulebookId, { name: data.name })
    enqueueSnackbar(`Rulebook "${data.name}" was successfully updated!`, { variant: 'success' })
    setChangeNameOpen(false)
  }

  const typeChangeWarning = useMemo(() => {
    if (!pendingTypeChange) {
      return ''
    }
    const fromType = entityTypes.find(e => e.name === data.entityType)
    const toType = entityTypes.find(e => e.name === pendingTypeChange)
    return `Changing entity type from ${fromType.label} to ${toType.label} will clear out the documents setting. Do you wish to proceed?`
  }, [pendingTypeChange]) // eslint-disable-line

  return (
    <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
      <Modal
        open={pendingTypeChange}
        onClose={() => setPendingTypeChange(null)}
        title="Confirm Type Change"
        mainText={typeChangeWarning}
        actions={[
          {
            type: 'main',
            label: 'Cancel',
            action: () => setPendingTypeChange(null),
            style: { width: 150 },
          },
          {
            type: 'secondary',
            label: 'Yes',
            action: changeType,
            style: { width: 150 },
          }
        ]}
        actionsDirection="row"
      />

      <Modal
        open={changeNameOpen}
        onClose={() => setChangeNameOpen(false)}
        title="Change Entity Name"
        actions={[
          {
            type: 'main',
            label: 'Cancel',
            action: () => setChangeNameOpen(false),
            style: { width: 150 },
          },
          {
            type: 'secondary',
            label: 'Apply',
            action: handleSaveNameChange,
            style: { width: 150 },
          }
        ]}
        actionsDirection="row"
        content={(
          <Box width={400} pb={4}>
            <Box py={1}>
              <TextField
                value={data?.name || ''}
                onChange={onChange('name')}
                fullWidth
                label="Entity Name"
                variant="outlined"
                error={!!errors['name']}
                helperText={!!errors['name'] && errors['name']}
              />
            </Box>
          </Box>
        )}
      />

      <Box display="flex" mt={4}>
        <Grid container>
          <Grid container justify={'center'}>
            <Grid item xs={10} md={10} lg={6}>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors?.entityType}
                disabled={type !== 'create'}
              >
                <InputLabel>Entity Type</InputLabel>
                <SelectClearable
                  value={data?.entityType}
                  onChange={onChange('entityType')}
                  clearable={false}
                  renderValue={(entityType) => {
                    const entity = entityTypes?.find(e => e.name === entityType)
                    return (
                      <Box display={'flex'}>
                        <Box display={'flex'} style={{ width: 20, paddingRight: 16 }}>
                          {{
                            ...entity.icon,
                            props: {
                              style: {
                                height: 20
                              }
                            }
                          }}
                        </Box>
                        <Box display={'flex'}>
                          {entity.label}
                        </Box>
                      </Box>
                    )
                  }}
                >
                  {entityTypes.map(entity => (
                    <MenuItem
                      key={entity.name}
                      value={entity.name}
                      disabled={!typeOptions.some(e => e.name === entity.name)}
                    >
                      <ListItemIcon
                        style={{
                          minWidth: 20,
                          width: 20,
                          paddingRight: 16
                        }}>
                        {{
                          ...entity.icon,
                          props: {
                            style: {
                              height: 20
                            }
                          }
                        }}
                      </ListItemIcon>
                      <ListItemText>
                        {entity.label}
                      </ListItemText>
                    </MenuItem>
                  ))}
                </SelectClearable>
                {(!data?.entityType && typeOptions.length !== entityTypes.length) &&
                  <FormHelperText>One or more entity types does not have a global entity defined</FormHelperText>
                }
                {errors?.entityType &&
                  <FormHelperText>{errors?.entityType}</FormHelperText>
                }
              </FormControl>
            </Grid>
          </Grid>

          <Grid container justify={'center'}>
            <Grid item xs={10} md={10} lg={6}>
              <Box position="relative" display="flex" alignItems="center" width="100%">
                <TextField
                  value={data?.name || ''}
                  onChange={onChange('name')}
                  fullWidth
                  label="Entity Name"
                  variant="outlined"
                  error={!!errors['name']}
                  helperText={!!errors['name'] && errors['name']}
                  disabled={type === 'edit'}
                />
                {type === 'edit' &&
                  <CustomTooltip title="Change Entity Name" placement="top">
                    <IconButton onClick={() => setChangeNameOpen(true)} style={{ position: 'absolute', right: 14, padding: 6 }}>
                      <EditIcon />
                    </IconButton>
                  </CustomTooltip>
                }
              </Box>
            </Grid>
          </Grid>
          {(type === 'clone' || scope === 'local') && (
            <Grid container justify={'center'}>
              <Grid item xs={10} md={10} lg={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!errors?.jurisdiction}
                  disabled={type === 'edit'}
                >
                  <SelectSearchable
                    value={(data?.jurisdiction && data?.jurisdiction !== 'UNDEFINED') ? data?.jurisdiction : ''}
                    onChange={onChange('jurisdiction')}
                    options={
                      Object.keys(countryCodeList).map(optionValue =>
                        ({ label: countryCodeList[optionValue], value: optionValue })
                      )}
                    label={'Jurisdiction'}
                    error={!!errors?.jurisdiction}
                    disabled={type === 'edit'}
                  />
                  {errors?.jurisdiction &&
                    <FormHelperText>{errors?.jurisdiction}</FormHelperText>
                  }
                </FormControl>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  )
}

import React from 'react';

import {
  Box,
  Button,
  Grid
} from '@material-ui/core';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import InnerHeader from '@components/layout/inner-header';
import PageLayout from '@components/layout/page-layout';
import history from '@app/history';
import EidvInfo from '@components/complex/client-view/electronic-verification/components/eidv-info';
import { ChevronLeft } from '@material-ui/icons';
import { useParams } from 'react-router';

export default function PersonEidvStep(props) {
  const { clientId } = useParams();

  const goBack = () => {
    history.goBack()
  }

  return (
    <PageLayout>
      <Box px={6}>
        <InnerHeader
          title={'Verify Identity'}
          stepper={{
            currentStep: 4,
            stepsCount: 4
          }}
          buttons={
            <React.Fragment>
              <ButtonWithIcon startIcon={<ChevronLeft />} onClick={goBack}>
                Back
              </ButtonWithIcon>
            </React.Fragment>
          } />
      </Box>

      <Box style={{ overflowY: 'auto' }} pt={5}>
        <Grid container justify={'center'}>
          <Grid item xs={10} md={10} lg={10}>
            <Grid item xs={12}>
              <EidvInfo />
            </Grid>
            <Grid container item xs={12} justify="center">
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} my={3}>
                <Box display={'flex'} my={1}>
                  <Button variant="contained" style={{ width: '208px' }} onClick={() => history.push(`/portfolio/view/${clientId}/electronic-verification?forceStart=true`)}>
                    SEND REQUEST
                  </Button>
                </Box>
                <Box display={'flex'} my={1}>
                  <Button style={{ width: '208px' }} onClick={() => history.push(`/portfolio/view/${clientId}`)}>
                    SKIP
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>

    </PageLayout>
  )
}

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import CalendarCard from './card';
import { EXPIRATION_CHECKS } from '../../constants';
import { clientApi } from '@app/api';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useStyles } from './styles';

const weekdaysShort = moment.weekdaysShort();

export default function MonthList({ isYearly }) {
  const classes = useStyles();

  const { dateInterval, documentFilter } = useSelector(
    (state) => state.calendarSchedule
  );

  const [data, setData] = useState(null);
  const [date, setDate] = useState(moment());

  const isCurrentMonth = useMemo(
    () => date.format('M') === moment().format('M'),
    [date]
  );

  const getData = useCallback(async () => {
    const res = (
      await clientApi.client.getClientsByNextReview({
        groupBy: 'Day',
        from: moment(
          `${dateInterval.year}-${dateInterval.month+1}-${dateInterval.day}`
        )
          .utc()
          .startOf('month')
          .format('YYYY-MM-DD'),
        to: moment(
          `${dateInterval.year}-${dateInterval.month+1}-${dateInterval.day}`
        )
          .utc()
          .endOf('month')
          .format('YYYY-MM-DD'),
      })
    ).data;

    const resDoc = (
      await clientApi.clientDocument.setExpiryDate({
        expirationTypes: EXPIRATION_CHECKS[documentFilter.types],
        from: moment(
          `${dateInterval.year}-${dateInterval.month+1}-${dateInterval.day}`
        )
          .utc()
          .startOf('month'),
        groupBy: 'Day',
        to: moment(
          `${dateInterval.year}-${dateInterval.month+1}-${dateInterval.day}`
        )
          .utc()
          .endOf('month'),
      })
    ).data;

    const preapredData = {
      legalEntitiesCount: res.legalEntitiesCount,
      personsCount: res.personsCount,
      documentsCount: resDoc.documents,
    };

    setData(preapredData);
  }, [dateInterval, documentFilter]);

  useEffect(() => {
    getData();
  }, [getData, dateInterval.year]);

  useEffect(() => {
    setDate(moment(`${dateInterval.year}-${dateInterval.month + 1}`));
  }, [dateInterval]);

  const getFirstDayOfMonth = useCallback(() => {
    return moment(date).startOf('month').format('d');
  }, [date]);

  const getCurrentDay = useCallback(() => {
    return moment().format('D');
  }, []);

  const getBlanks = useCallback(() => {
    let blanks = [];
    for (let i = 0; i < getFirstDayOfMonth(); i++) {
      blanks.push(<td style={{ width: '14%' }}>{''}</td>);
    }

    return blanks;
  }, [getFirstDayOfMonth]);

  const getDaysInMonth = useCallback(() => {
    let daysInMonth = [];
    for (let d = 1; d <= date.daysInMonth(); d++) {
      const key = `${d}`.length < 2 ? `0${d}` : d;

      daysInMonth.push(
        <td key={d} style={{ width: '14%' }}>
          <CalendarCard
            item={{
              name: d,
              personsCount: data?.personsCount[key] || 0,
              legalEntitiesCount: data?.legalEntitiesCount[key] || 0,
              documentsCount: data?.documentsCount[key] || 0,
            }}
            active={isCurrentMonth && getCurrentDay() === d.toString()}
            isYearly={isYearly}
          />
        </td>
      );
    }

    return daysInMonth;
  }, [date, data, isCurrentMonth, isYearly, getCurrentDay]);

  const getTotalDays = useCallback(() => {
    const totalSlots = [...getBlanks(), ...getDaysInMonth()];

    let rows = [];
    let cells = [];

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
      if (i === totalSlots.length - 1) {
        rows.push(cells);
      }
    });

    return rows.map((d, i) => {
      return <tr key={`${d}-${i}`}>{d}</tr>;
    });
  }, [getBlanks, getDaysInMonth]);

  return (
    <Box
      display="flex"
      my={2}
      flexDirection="column"
      className={classes.wrapper}
    >
      {!!data && (
        <table>
          <thead>
            <tr>
              {weekdaysShort.map((el, i) => (
                <th key={`${el}-${i}`}>
                  <Typography key={`${el}-${i}`} className={classes.label}>
                    {el.toUpperCase()}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{getTotalDays()}</tbody>
        </table>
      )}
    </Box>
  );
}

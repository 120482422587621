import React from 'react';
import { NotFoundFrame1 } from '@app/icons';
import './index.css'
import {Box} from '@material-ui/core';

export default function NotFoundAnimation() {
  return(
    <Box display="flex" justifyContent="center" alignItems="center">
      <div className="circle">
        <span className="circle1"></span>
        <div className="profilepicture">
          <NotFoundFrame1 width={'230px'} height={'230px'} />
        </div>
      </div>
    </Box>
  )
}
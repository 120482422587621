import React from 'react';
import {
  TableCell, makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import { omit } from 'lodash';

const useStyles = makeStyles((theme) => ({
  boldCell: {
    fontWeight: 500,
    color: '#232323',
  },
  disabledCell: {
    opacity: 0.5,
  },
}));

export default function BaseTableCell(props) {
  const classes = useStyles();
  const { col, item, children } = props;

  const customProps = omit(props, ['col', 'item', 'children']);

  return (
    <TableCell
      className={clsx(col.bold && classes.boldCell, item.isDisabled && classes.disabledCell)}
      {...customProps}
      {...col.colProps}
      {...item.rowProps}
      {...(item.cellProps && (item.cellProps[col.field] || {}))}
    >
      {item.hiddenFields ? !item.hiddenFields.includes(col.field) && children : children}
    </TableCell>
  )
}
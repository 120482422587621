import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, Dialog, DialogActions, DialogContent,
  DialogTitle, FormControl, FormHelperText, Grid, InputLabel,
  makeStyles, MenuItem, OutlinedInput,
} from '@material-ui/core';
import moment from 'moment'
import ReactInputMask from 'react-input-mask';

import * as yup from 'yup';
import parseYupErrors from '@app/yup';
import { countryCodeList } from '@services/country-service';
import SelectSearchable from '@components/inputs/select-searchable';
import SelectClearable from '@components/inputs/select-clearable';
import DatePicker from '@components/calendar/input-date-picker';

const personalSchema = yup.object().shape({
  firstName: yup.string().label('Name').required().trim().max(30),
  lastName: yup.string().label('Last name').required().trim().max(30),
  gender: yup.string().required(),
  dob: yup.date()
    .nullable()
    .required()
    .transform((curr, orig) => orig === '' ? null : curr)
    .label('Date of birth')
    .test('min_date', 'Date of birth should be after 1900', function () {
      return !moment(this.parent.dob).isBefore('01/01/1900')
    })
    .max(new Date(), 'Date of birth should be before current date')
    .typeError('Invalid date format'),
  personalNumber: yup.string().label('Personal Number').trim().max(30),
  nationality: yup.string().required(),
  documentId: yup.string().label('Document ID').required().trim().max(30),
  issuingCountry: yup.string().label('Country').max(100).required(),
  validUntil: yup.date().label('Valid Until')
    .nullable()
    .required()
    .transform((curr, orig) => orig === '' ? null : curr)
    .min(new Date(), 'Valid Until should be after current date')
    .typeError('Invalid date format'),
  documentIssuingDate: yup.date().label('Document Issuing Date')
    .nullable()
    .required()
    .transform((curr, orig) => orig === '' ? null : curr)
    .max(new Date(), 'Document Issuing Date should be before current date')
    .typeError('Invalid date format'),
});

const addressSchema = yup.object().shape({
  addressLine1: yup.string().label('Address Line 1').required().trim().max(50),
  addressLine2: yup.string().label('Address Line 2').trim().max(50),
  city: yup.string().required().trim().max(50),
  region: yup.string().trim().max(50),
  postalCode: yup.string().label('Postal Code').required().trim(),
  country: yup.string().required().trim(),
  issuingDate: yup.date().label('Document Issuing Date')
    .nullable()
    .required()
    .transform((curr, orig) => orig === '' ? null : curr)
    .max(new Date(), 'Document Issuing Date should not be after current date')
    .typeError('Invalid date format'),
});

const schemas = {
  personal: personalSchema,
  address: addressSchema,
}

const useStyles = makeStyles((theme) => ({
  maskInput: {
    '& .MuiInputBase-input': {
      '&::placeholder': {
        color: theme.palette.text.BL1,
      }
    }
  },
}))

export default function BasicDetailsDialog({ open, onClose, onSave, type, details }) {
  const classes = useStyles();

  const [model, setModel] = useState({})
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (details) {

      let model = {};
      switch (type) {
        case 'personal':
          model = {
            firstName: details.firstName?.value || '',
            lastName: details.lastName?.value || '',
            gender: details.gender?.value.toUpperCase() || '',
            dob: details.dob?.value ? moment(details.dob.value).format('YYYY-MM-DD') : '',
            personalNumber: details.personalNumber?.value || '',
            nationality: details.nationalityCode?.value || '',
            documentId: details.documentId?.value || '',
            issuingCountry: details.issuingCountryCode?.value || '',
            validUntil: details.validUntil?.value ? moment(details.validUntil.value).format('YYYY-MM-DD') : '',
            documentIssuingDate: details.documentIssuingDate?.value ? moment(details.documentIssuingDate.value).format('YYYY-MM-DD') : '',
          }
          break;

        case 'address':
          model = {
            addressLine1: details.addressLine1?.value || '',
            addressLine2: details.addressLine2?.value || '',
            city: details.city?.value || '',
            region: details.region?.value || '',
            postalCode: details.postalCode?.value || '',
            country: details.countryCode?.value || '',
            issuingDate: details.issuingDate?.value ? moment(details.issuingDate.value).format('YYYY-MM-DD') : '',
          }
          break;

        default:
          break;
      }
      setModel(model)
      setErrors({})
    }
  }, [type, details, open])

  const handleChange = (prop) => (event) => {
    const newModel = { ...model, [prop]: event.target.value };
    setModel(newModel);

    if (errors[prop]) {
      schema.validateAt(prop, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [prop]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [prop]: err.message });
        });
    }
  };

  const personalFields = [
    { key: 'firstName', label: 'Name' },
    { key: 'lastName', label: 'Last Name' },
    { key: 'gender', label: 'Gender',
      type: 'select',
      options: {
        MALE: 'Male',
        FEMALE: 'Female'
      }
    },
    { key: 'dob', label: 'Date Of Birth',
      type: 'date',
      endAdornment: <DatePicker
        handleChange={handleChange('dob')}
        inputValue={model['dob'] || '01-01-1970'}
      />
    },
    { key: 'personalNumber', label: 'Personal Number' },
    { key: 'nationality', label: 'Nationality',
      type: 'select',
      allowSearch: true,
      options: Object.keys(countryCodeList).map(optionValue =>
        ({ label: countryCodeList[optionValue], value: optionValue })),
    },
    { key: 'documentId', label: 'Document ID' },
    { key: 'issuingCountry', label: 'Country',
      type: 'select',
      allowSearch: true,
      options: Object.keys(countryCodeList).map(optionValue =>
        ({ label: countryCodeList[optionValue], value: optionValue })),
    },
    { key: 'validUntil', label: 'Valid Until',
      type: 'date',
      endAdornment: <DatePicker
        handleChange={handleChange('validUntil')}
        inputValue={model['validUntil']}
      />
    },
    { key: 'documentIssuingDate', label: 'Document Issuing Date',
      type: 'date',
      endAdornment: <DatePicker
        handleChange={handleChange('documentIssuingDate')}
        inputValue={model['documentIssuingDate']}
      />
    },
  ]

  const addressFields = [
    { key: 'addressLine1', label: 'Address Line 1' },
    { key: 'addressLine2', label: 'Address Line 2' },
    { key: 'city', label: 'City' },
    { key: 'region', label: 'Region/State/Province' },
    { key: 'postalCode', label: 'Postal Code' },
    { key: 'country', label: 'Country',
      type: 'select',
      allowSearch: true,
      options: Object.keys(countryCodeList).map(optionValue =>
        ({ label: countryCodeList[optionValue], value: optionValue })),
    },
    { key: 'issuingDate', label: 'Document Issuing Date',
      type: 'date',
      endAdornment: <DatePicker
        handleChange={handleChange('issuingDate')}
        inputValue={model['issuingDate']}
      />
    },
  ]

  const fields = () => {
    const fields = {
      personal: personalFields,
      address: addressFields,
    }

    return type ? fields[type] : [];
  }

  const schema = useMemo(() => {
    return type ? schemas[type] : {};
  }, [type])

  const handleUpdate = () => {
    setErrors({});
    schema
      .validate(model, { abortEarly: false })
      .then(() => {
        onSave(model)
      })
      .catch(err => setErrors(parseYupErrors(err), true));
  }

  const renderField = (field) => (
    <FormControl
      key={field.key}
      fullWidth
      variant="outlined"
      error={!!errors[field.key]}
      disabled={field.disabled}
      id={field.key}
    >
      {!field.allowSearch && <InputLabel htmlFor="name" shrink={field.type === 'date' || undefined}>{field.label}</InputLabel>}
      {field.mask &&
        <ReactInputMask
          mask={field.mask ? field.mask : null}
          value={model[field.key]}
          onChange={handleChange(field.key)}
          maskChar={null}
        >
          {(inputProps) => <OutlinedInput
            {...inputProps}
            placeholder={field.placeholder}
            endAdornment={field.endAdornment}
            classes={{ root: classes.maskInput, input: classes.maskInput }}
          />}
        </ReactInputMask>
      }
      {(field.type !== 'select' && !field.mask) &&
        <OutlinedInput
          value={model[field.key]}
          type={field.type === 'date' ? 'date' : 'text'}
          onChange={handleChange(field.key)}
          disabled={field.disabled}
          multiline={field.multiline}
          rows={field.multiline ? 3 : undefined}
          endAdornment={field.endAdornment}
        />
      }
      {(field.type === 'select' && !field.allowSearch) &&
        <SelectClearable
          value={model[field.key]}
          type={field.type === 'date' ? 'date' : 'text'}
          onChange={handleChange(field.key)}
        >
          {Object.keys(field.options).map(optionValue => (
            <MenuItem
              key={optionValue}
              value={optionValue}
            >
              {field.options[optionValue]}
            </MenuItem>
          ))}
        </SelectClearable>
      }
      {(field.type === 'select' && field.allowSearch) &&
        <SelectSearchable
          value={model[field.key]}
          onChange={handleChange(field.key)}
          options={field.options}
          label={field.label}
          error={!!errors[field.key]}
        />
      }

      {errors[field.key] &&
        <FormHelperText>{errors[field.key]}</FormHelperText>
      }
    </FormControl>
  )

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle style={{ textAlign: 'left', paddingLeft: 0, textTransform: 'capitalize' }}>
        {type} Details
      </DialogTitle>
      <DialogContent>
        <Box width={875}>
          <Grid container justify="space-between">
            {fields().map(e => (
              <Box width={425} key={e.key}>
                {renderField(e)}
              </Box>
            ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'flex-end', paddingRight: 0 }}>
        <Button style={{ width: '160px' }} variant={'outlined'} onClick={onClose}>
          CANCEL
        </Button>
        <Button style={{ width: '160px' }} variant={'contained'} onClick={handleUpdate} type="submit">
          {'UPDATE'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Box, Grid, IconButton} from '@material-ui/core';
import TableList, { TCELL_TYPES } from '@components/lists/table-list';
import NoResultsFound from '@components/layout/no-results-found';
import {entitySettingsMode, getParticularUrl} from './utils/modeUtil';
import { clientApi } from '@app/api';
import Badge from '@components/badges/badge';
import history from '@app/history';
import CustomTooltip from '@components/controls/tooltip';
import {useDispatch, useSelector} from 'react-redux';
import {fetchCategories, fetchTypes, selectCategories, selectTypes} from './duck';
import {countryCodeList} from '@services/country-service';
import DeleteDialog from './dialogs/delete-dialog';
import { getCountryName } from '@services/country-service';
import { useSnackbar } from 'notistack';
import { HourglassRed } from '@app/icons';
import CompareVersionsDialog from './dialogs/compare-versions-dialog';
import _ from 'lodash';

export default function DashboardTable({filterMode, mode, filterValue, searchTerm}) {

  const dispatch = useDispatch()
  const [lines, setLines] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10) //eslint-disable-line
  const [pagesCount, setPagesCount] = useState(10)
  const [totalCount, setTotalCount] = useState(100)
  const [sort, setSort] = useState('name')
  const [sortDirection, setSortDirection] = useState('ASC')
  const [pendingDeleteId, setPendingDeleteId] = useState(null)
  const [compareVersions, setCompareVersions] = useState(null)
  const { enqueueSnackbar } = useSnackbar()

  const categories = useSelector(selectCategories)
  const types = useSelector(selectTypes)

  useEffect(() => {
    fetchCategoriesAndTypes() //eslint-disable-line
  }, []) //eslint-disable-line

  useEffect(() => {
    if (!!categories && !!types) {
      fetchLines() //eslint-disable-line
    }
  }, [page, pageSize, sort, sortDirection, categories, types, searchTerm, filterMode]) //eslint-disable-line

  const onCompareVersionsOpen = rb => async event => {
    event.preventDefault()
    event.stopPropagation()
    const basedOnRes = await clientApi.clientRulebook.show(rb.basedOnRulebookVersionId)
    const { data } = basedOnRes
    setCompareVersions([
      rb.rulebookVersionId,
      data.rulebookVersionId,
      data.latestRulebookVersionId
    ])
  }

  const tableColumns = useMemo(() => {
    const defaultColumns = [
      { field: 'name', headerName: 'Name', cellType: TCELL_TYPES.CUSTOM, custom: (item) => (
        <Box
          display={'flex'}
          alignItems={'center'}
        >
          { !!item?.name && (
            <Box
              display={'flex'}
            >
              { item?.name }
            </Box>
          ) }
          { !item?.isBasedOnLatestRulebookVersion && item?.ownedBy === 'USER' && (
            <Box
              display={'flex'}
            >
              <CustomTooltip
                title={
                  <>
                    There is a newer version of entity definition. Please check the differences and apply them if necessary to your custom entity definition.
                    <br/>
                    <b>Hit the icon to view differences.</b>
                  </>
                }
              >
                <IconButton onClick={onCompareVersionsOpen(item)}>
                  <HourglassRed width={19} height={19} />
                </IconButton>
              </CustomTooltip>
            </Box>
          )}
        </Box>
      )
      },
      { field: 'jurisdiction', headerName: 'Jurisdiction', cellType: TCELL_TYPES.CUSTOM, custom: (item) => {
        return (!!item?.jurisdiction && item?.jurisdiction !== 'UNDEFINED' && countryCodeList?.[item?.jurisdiction]) || 'GLOBAL'
      }},
      { field: 'documentCategories', headerName: 'Document Categories', cellType: TCELL_TYPES.CUSTOM, custom: (item) => (
        <Box>
          <CustomTooltip
            title={
              !!item?.categoriesCount && (
                <React.Fragment>
                  { item.documentRules.map(category => (
                    <p key={`${category?.ref?.id}`}>{category?.ref?.name}</p>
                  ))}
                </React.Fragment>
              )}
            placement="bottom">
            <Box>
              {
                !!item?.categoriesCount
                  ? (
                    <Badge backgroundColor={'#C0A2B8'} value={item.categoriesCount}/>
                  )
                  : (
                    'No categories included'
                  )
              }
            </Box>
          </CustomTooltip>
        </Box>
      ), preventClick: true },
      { field: 'documentTypes', headerName: 'Documents', cellType: TCELL_TYPES.CUSTOM, custom: (item) => (
        <Box>
          <CustomTooltip
            title={
              !!item?.typesCount && (
                <React.Fragment>
                  { item.documentRules.map(category => (
                    category.types.map(type => (
                      <p key={`${category?.ref?.id}-${type?.ref?.id}`}>{type?.ref?.name}</p>
                    ))
                  ))}
                </React.Fragment>
              )}
            placement="bottom">
            <Box>
              {
                !!item?.typesCount
                  ? (
                    <Badge backgroundColor={'#C0A2B8'} value={item?.typesCount}/>
                  )
                  : (
                    'No documents included'
                  )
              }
            </Box>
          </CustomTooltip>
        </Box>
      ), preventClick: true },
      // {
      //   field: 'roles',
      //   headerName: 'Roles',
      //   cellType: TCELL_TYPES.CUSTOM,
      //   custom: () => (
      //     <Badge backgroundColor={'linear-gradient(270deg, #9A7C6A 0%, #7A5B44 100%)'} value={0}/>
      //   ),
      //   preventClick: true
      // },
      // {
      //   field: 'relationships',
      //   headerName: 'Relationships',
      //   cellType: TCELL_TYPES.CUSTOM,
      //   custom: () => (
      //     <Badge backgroundColor={'linear-gradient(270deg, #9A7C6A 0%, #7A5B44 100%)'} value={0}/>
      //   ),
      //   preventClick: true
      // },
    ]
    return mode !== entitySettingsMode.JURISDICTION
      ? defaultColumns
      : defaultColumns.filter(col => col.field !== 'jurisdiction')
  }, [mode]) // eslint-disable-line

  const sortableColumns = useMemo(() =>
    tableColumns
      .filter(col => col.field !== 'jurisdiction')
      .map(col => col.field),
  [tableColumns]
  )

  const fetchCategoriesAndTypes = async () => {
    dispatch(fetchCategories())
    dispatch(fetchTypes())
  }

  const fetchLines = async () => {
    const params = {
      pageNumber: page,
      pageSize,
      sort,
      sortDirection,
      name: !!searchTerm? searchTerm: null,
      latest: true,
      ownedBy: filterMode === 'my'
        ? 'USER'
        : 'SYSTEM',
      global: filterMode === 'system-global'
    }
    // Trying to fetch user custom rulebooks
    if ( mode === entitySettingsMode.JURISDICTION ) {
      if (filterMode !== 'system-global') {
        params.jurisdiction = filterValue
      }
    } else {
      if (filterValue === 'PERSON') {
        params.clientType = 'PERSON'
      } else {
        params.clientType = 'LEGAL_ENTITY'
        params.legalEntityType = filterValue
      }
    }
    let res = (await clientApi.clientRulebook.index(
      params
    )).data
    setPagesCount( res.pages )
    setLines(
      prepareLines( res.entries )
    )
    setTotalCount( res.count )
    setIsLoading( false )
  }

  const prepareLines = lines => lines.map(line => {
    const extCategories = line.documentRules?.map(rulebookCategory => {
      const category = categories.find(c => c.id === rulebookCategory.categoryId)
      return {
        ...rulebookCategory,
        ref: category,
        types: rulebookCategory.types.map(rulebookType => {
          const type = types.find(t => t.id === rulebookType.typeId)
          return {
            ...rulebookType,
            ref: type
          }
        })
      }
    }) || []
    const newLine = {
      id: line.rulebookVersionId,
      ...line,
      categoriesCount: extCategories.length,
      typesCount: extCategories.reduce((acc, category) => {
        return acc + category.types.length
      }, 0)
    }
    newLine.documentRules = extCategories
    return newLine
  })


  const onSort = value => {
    setPage(0)
    if (sort !== value) {
      setSort(value)
      setSortDirection('ASC')
    } else {
      const newSortDirection = sortDirection === 'ASC' ? 'DESC' : 'ASC'
      setSortDirection(newSortDirection)
    }
  }

  const onCompareVersionsClose = () => {
    setCompareVersions(null)
  }

  const onView = useCallback((id, item) => {
    history.push(
      getParticularUrl(mode, filterValue, 'view', id, null, 'fields')
    )
  }, [filterValue, mode])

  const onEdit = useCallback(id => {
    history.push(
      getParticularUrl(mode, filterValue, 'edit', id, null, 'fields')
    )
  }, [filterValue, mode])

  const onClone = useCallback(id => {
    history.push(
      getParticularUrl(mode, filterValue, 'clone', id, 1, 'fields')
    )
  }, [filterValue, mode])

  const onDeleteFlowStart = useCallback( (id, item) => {
    setPendingDeleteId(item?.rulebookId)
  }, [setPendingDeleteId])

  const onDelete = async () => {
    try {
      await clientApi.clientRulebook.removeVersion(pendingDeleteId)
      enqueueSnackbar(`Rulebook "${pendingDeleteName}" was successfully removed from jurisdiction ${pendingDeleteJurisdictionName}!`, { variant: 'success' })
      fetchLines()
    } catch (err) {
      //enqueueSnackbar('Something went wrong...', { variant: 'error' })
    }
    setPendingDeleteId(null)
  }

  const pendingDeleteRulebook = useMemo(() => {
    return lines.find(r => r.rulebookId === pendingDeleteId)
  }, [lines, pendingDeleteId])

  const pendingDeleteName = useMemo(() => {
    return pendingDeleteRulebook?.name || ''
  }, [pendingDeleteRulebook])

  const pendingDeleteJurisdictionName = useMemo(() => {
    return getCountryName(pendingDeleteRulebook?.jurisdiction)
  }, [pendingDeleteRulebook])

  const actions = useMemo(() => {
    const actionsAssoc = {
      my: [
        { label: 'View', handler: onView },
        { label: 'Edit', handler: onEdit },
        { label: 'Clone', handler: onClone },
        { label: 'Delete', handler: onDeleteFlowStart }
      ],
      'system-local': [
        { label: 'View', handler: onView },
        { label: 'Clone', handler: onClone }
      ],
      'system-global': [
        { label: 'View', handler: onView },
        { label: 'Clone', handler: onClone }
      ]
    }
    return actionsAssoc[filterMode]
  }, [filterMode, onView, onEdit, onClone, onDeleteFlowStart])

  const onUpgradeVersion = async () => {
    const [rulebookVersionId] = compareVersions
    const rbRes = await clientApi.clientRulebook.show(rulebookVersionId)
    const origRbData = rbRes.data
    const newRbData = {
      ...origRbData,
      documentRules: origRbData.documentRules?.filter( c => {
        return !c.definedByRulebookVersionId || (c.definedByRulebookVersionId === origRbData.rulebookVersionId && origRbData.ownedBy !== 'SYSTEM')
      }).map(c => _.omit(c, ['ref'])),
      inputFields: origRbData.inputFields
        .filter(inputField =>
          !inputField.definedByRulebookVersionId
          || (inputField.definedByRulebookVersionId === origRbData.rulebookVersionId && origRbData.ownedBy !== 'SYSTEM')
        ),
      updateBasedOnRulebookToLatest: true
    }
    try {
      await clientApi.clientRulebook.createVersion(origRbData.rulebookId, newRbData)
      enqueueSnackbar('Rulebook version has been successfully upgraded!', { variant: 'success' })
      fetchLines()
    } catch (err) {
      enqueueSnackbar('Something went wrong...', { variant: 'error' })
    }
    setCompareVersions(null)
  }

  return (
    <Box
      display={'flex'}
      flexGrow={1}
    >
      <Grid
        container
        style={{
          paddingRight: '20px'
        }}
      >
        {lines.length && !isLoading ?
          <Grid item sm={12}>
            <TableList
              columns={tableColumns}
              items={lines}
              page={page}
              pageSize={pageSize}
              pagesCount={pagesCount}
              totalCount={totalCount}
              onPageChange={(event, value) => setPage(value - 1)}
              sortableColumns={sortableColumns}
              onSort={onSort}
              sort={sort}
              sortDirection={sortDirection}
              threeDotsActions={actions}
              onClick={onView}
              //maxHeight={tableMaxHeight}
            />
          </Grid>
          : !isLoading && (
            <Box mt={16} flexGrow={1}>
              <NoResultsFound>
                No records found.
              </NoResultsFound>
            </Box>
          )}
      </Grid>
      <DeleteDialog
        open={!!pendingDeleteId}
        onClose={() => {
          setPendingDeleteId(null)
        }}
        onDelete={onDelete}
        name={pendingDeleteName}
        jurisdictionName={pendingDeleteJurisdictionName}
      />
      <CompareVersionsDialog
        open={compareVersions !== null}
        onClose={onCompareVersionsClose}
        rulebookVersionFromId={compareVersions?.[1]}
        rulebookVersionToId={compareVersions?.[2]}
        onConfirm={onUpgradeVersion}
      />
    </Box>
  )
}
import config from './config';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

const poolData = {
  UserPoolId: config.cognito.user_pool_id,
  ClientId: config.cognito.client_id,
};

const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

const register = function (email, password) {
  const attributeList = [];

  const dataEmail = {
    Name: 'email',
    Value: email,
  };

  // const dataPhoneNumber = {
  //     Name: 'phone_number',
  //     Value: '+15555555555',
  // };
  const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);
  // const attributePhoneNumber = new AmazonCognitoIdentity.CognitoUserAttribute(
  //     dataPhoneNumber
  // );

  attributeList.push(attributeEmail);
  //attributeList.push(attributePhoneNumber);

  userPool.signUp(email, password, attributeList, null, function(
    err,
    result
  ) {
    if (err) {
      alert(err.message || JSON.stringify(err));
      return;
    }
    const cognitoUser = result.user;
    console.log('user name is ' + cognitoUser.getUsername());
  });
}

const logout = function () {
  return new Promise((resolve) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser !== null) {
      cognitoUser.signOut();
    }
    resolve();
  });
}

const login = function (email, password, prompt) {
  const authenticationData = {
    Username: email,
    Password: password
  };
  const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
    authenticationData
  );
  const userData = {
    Username: email,
    Pool: userPool,
  };
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function(result) {
        loginSuccess(resolve, result)
      },
      onFailure: function(err) {
        reject(err);
      },
      mfaRequired: function(codeDeliveryDetails) {
        resolve({ mfaRequired: true, cognitoUser })
      }
    });
  });
}

const loginSuccess = function (resolve, result) {
  const accessToken = result.getAccessToken().getJwtToken();
  const payload = result.getAccessToken().decodePayload();

  resolve({ token: accessToken, groups: payload['cognito:groups'] });
}

const verifyOtp = function (cognitoUser, code) {
  return new Promise((resolve, reject) => {
    cognitoUser.sendMFACode(code, {
      onSuccess: function (result) {
        loginSuccess(resolve, result)
      },
      onFailure: function (err) {
        reject(err)
      }
    })
  })
}

/**
 * Refresh the session and returns access token
 */
const getAccessToken = function () {
  const cognitoUser = userPool.getCurrentUser();

  return new Promise((resolve, reject) => {
    if (cognitoUser) {
      cognitoUser.getSession((err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data.getAccessToken().getJwtToken());
        }
      });
    } else {
      reject('No session');
    }
  });
}

/**
 * Get logged in user attributes
 */
const getUserAttributes = function () {
  const cognitoUser = userPool.getCurrentUser();

  return new Promise((resolve, reject) => {
    if (cognitoUser) {
      cognitoUser.getSession((err, data) => {
        cognitoUser.getUserAttributes((err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(res)
          }
        })
        if (err) {
          reject(err);
        }
      });
    } else {
      reject('No session');
    }
  });
}

const forgotPassword = function (email){

  const userData = {
    Username: email,
    Pool: userPool,
  };
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: function(data) {
        // successfully initiated reset password request
        console.log('ForgotPassword: ' + data);
        resolve(data);
      },
      onFailure: function(err) {
        console.log('ForgotPassword err: ', err);
        reject(err);
      },
      //Optional automatic callback
      // inputVerificationCode: function(data) {
      //     console.log('Code sent to: ' + data);
      //     const code = document.getElementById('code').value;
      //     const newPassword = document.getElementById('new_password').value;
      //     cognitoUser.confirmPassword(code, newPassword, {
      //         onSuccess() {
      //             console.log('Password confirmed!');
      //         },
      //         onFailure(err) {
      //             console.log('Password not confirmed!');
      //         },
      //     });
      // },
    });
  })
}

const resetPassword = function (email, newPassword, code){

  const userData = {
    Username: email,
    Pool: userPool,
  };
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(code, newPassword, {
      onSuccess() {
        console.log('Password confirmed!');
        resolve(true);
      },
      onFailure(err) {
        console.log('Password not confirmed!');
        reject(err);
      },
    });
  });
}

const changePassword = function (oldPassword, newPassword){
  const cognitoUser = userPool.getCurrentUser();

  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, data) => {
      if (err) {
        reject(err);
      } else {
        cognitoUser.changePassword(oldPassword, newPassword, ((err, data) => {
          if (err) {
            console.log('Change password code err: ', err);
            reject(err);
          }
          resolve(data);
        }));
      }
    });
  });
}


const changeEmail = function (newEmail, password){
  let cognitoUser = userPool.getCurrentUser();

  return new Promise((resolve, reject) => {
    login(cognitoUser.getUsername(), password).then((res) => {
      cognitoUser = userPool.getCurrentUser();

      cognitoUser.getSession((err, data) => {
        if (err) {
          reject(err);
        } else {
          cognitoUser.updateAttributes([{ Name: 'email', Value: newEmail }], ((err, data) => {
            if (err) {
              console.log('Change password code err: ', err);
              reject(err);
            }
            resolve(data);
          }))
        }
      });
    }).catch((err) => {
      reject(err);
    })
  });
}

const resendActivationEmail = function (email) {
  const userData = {
    Username: email,
    Pool: userPool,
  };
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.resendConfirmationCode((err, data) => {
      if (err) {
        console.log('Resend confirmation code err: ', err);
        reject(err);
      }
      console.log('Resend confirmation code: ' + data);
      resolve(data);
    })
  });
}

/**
 * Start Phone Number Verification
 */
const getPhoneVerificationCode = function () {
  const cognitoUser = userPool.getCurrentUser();

  return new Promise((resolve, reject) => {
    if (cognitoUser) {
      cognitoUser.getSession((err, data) => {
        cognitoUser.getAttributeVerificationCode('phone_number', {
          onSuccess: function(result) {
            resolve('send')
          },
          onFailure: function(err) {
            reject(err)
          },
          inputVerificationCode: null,
        })
      });
    } else {
      reject('No session');
    }
  });
}

/**
 * Verify Phone Number
 */
const verifyPhoneNumber = function (otp) {
  const cognitoUser = userPool.getCurrentUser();

  return new Promise((resolve, reject) => {
    if (cognitoUser) {
      cognitoUser.getSession((err, data) => {
        cognitoUser.verifyAttribute('phone_number', otp, {
          onSuccess: function(result) {
            resolve(result)
          },
          onFailure: function(err) {
            reject(err)
          }
        })
      });
    } else {
      reject('No session');
    }
  });
}

/**
 * Get MFA Settings
 */
const getMfaSettings = function () {
  const cognitoUser = userPool.getCurrentUser();

  return new Promise((resolve, reject) => {
    if (cognitoUser) {
      cognitoUser.getSession((err, data) => {
        cognitoUser.getUserData((err, data) => {
          if (err) {
            reject(err)
          }
          const { PreferredMfaSetting } = data;
          resolve(PreferredMfaSetting)
        });
      });
    } else {
      reject('No session');
    }
  });
}

/**
 * Get MFA Settings
 */
const setMfaSettings = function (enabled) {
  const cognitoUser = userPool.getCurrentUser();

  return new Promise((resolve, reject) => {
    if (cognitoUser) {
      cognitoUser.getSession((err, data) => {
        cognitoUser.setUserMfaPreference({ PreferredMfa: enabled, Enabled: enabled }, null, (err, res) => {
          if (err) {
            reject(err)
          }
          resolve(res)
        });
      });
    } else {
      reject('No session');
    }
  });
}

const setRememberDevice = (remember) => {
  const cognitoUser = userPool.getCurrentUser();

  return new Promise((resolve, reject) => {
    if (cognitoUser) {
      cognitoUser.getSession((err, data) => {
        cognitoUser.getCachedDeviceKeyAndPassword()
        if (remember) {
          cognitoUser.setDeviceStatusRemembered({
            onSuccess: (res) => {
              resolve(res)
            },
            onFailure: (err) => {
              resolve(err)
            }
          })
        } else {
          cognitoUser.setDeviceStatusNotRemembered({
            onSuccess: (res) => {
              resolve(res)
            },
            onFailure: (err) => {
              resolve(err)
            }
          })
        }
      });
    } else {
      reject('No session');
    }
  });
}

const forgetCurrentDevice = (remember) => {
  const cognitoUser = userPool.getCurrentUser();

  return new Promise((resolve, reject) => {
    if (cognitoUser) {
      cognitoUser.getSession((err, data) => {
        cognitoUser.getCachedDeviceKeyAndPassword()
        cognitoUser.forgetDevice({
          onSuccess: (res) => {
            resolve(res)
          },
          onFailure: (err) => {
            resolve(err)
          }
        })
      });
    } else {
      reject('No session');
    }
  });
}

const cognito = {
  login,
  verifyOtp,
  logout,
  forgotPassword,
  resetPassword,
  register,
  resendActivationEmail,
  getAccessToken,
  changePassword,
  changeEmail,
  getUserAttributes,
  getPhoneVerificationCode,
  verifyPhoneNumber,
  getMfaSettings,
  setMfaSettings,
  setRememberDevice,
  forgetCurrentDevice,
}

export default cognito;
import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import NoResultsFound from '@components/layout/no-results-found';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';

import InputFieldDialog from '../../../client/settings/entity/particular/form/dialogs/input-field-dialog';
import InputFieldCard from './input-field-cards';

export default function InputFields({ inputFields, setInputFields, isAddNew, type, clientType }) {
  const [fieldDialogOpen, setFieldDialogOpen] = useState(false)
  const [editDataIndex, setEditDataIndex] = useState(null)

  useEffect(() => {
    if (isAddNew) {
      setFieldDialogOpen(true);
    }
  }, [isAddNew])

  const closeDialog = () => {
    setFieldDialogOpen(false);
    setEditDataIndex(null);
  }

  const handleInputFieldChange = (index, inputField) => {
    inputFields[index] = inputField
    setInputFields(inputFields.slice())
  }

  const handleEdit = (index) => {
    setEditDataIndex(index);
    setFieldDialogOpen(true);
  }

  const handleSave = (inputField) => {
    if (editDataIndex !== null) {
      inputFields[editDataIndex] = inputField
      setInputFields(inputFields.slice())
    } else {
      inputFields.push({
        ...inputField,
        dragId: uuidv4(),
      })
      setInputFields(inputFields.slice())
    }

    closeDialog();
  }

  const handleDelete = (index) => {
    inputFields.splice(index, 1);
    setInputFields(inputFields.slice());
  }

  // DragDrop Functions
  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    const ordered = reorder(
      inputFields,
      result.source.index,
      result.destination.index
    )

    setInputFields(ordered.slice())
  }
  // End

  return (
    <React.Fragment>
      <InputFieldDialog
        open={fieldDialogOpen}
        onClose={closeDialog}
        onSave={handleSave}
        origData={editDataIndex !== null && inputFields[editDataIndex]}
        clientType={clientType}
        isEdit={type === 'edit'}
      />
      {!!inputFields.length ?
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ width: '100%' }}
              >
                <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                  {inputFields.map((inputField, index) => (
                    <Draggable key={inputField.dragId} draggableId={inputField.dragId} index={index} isDragDisabled={inputField.isGlobal}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <InputFieldCard
                            disabled={inputField.isGlobal}
                            inputField={inputField}
                            setInputField={(field) => handleInputFieldChange(index, field)}
                            onDelete={() => handleDelete(index)}
                            onEdit={() => handleEdit(index)}
                            clientType={clientType}
                            isEdit={type === 'edit'}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                </Box>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        :
        <Box mt={8} flexGrow={1}>
          <NoResultsFound>
            The documents configuration will appear here
          </NoResultsFound>
        </Box>
      }
    </React.Fragment>
  )
}

import React from  'react';
import CustomButton from '@views/common/button';
import CustomTooltip from '@components/controls/tooltip';
import { LoaderOtherIcon } from '@app/icons';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  stepperTooltipPlacementTop: {
    bottom: '5px',
  },
  wrapperExportButtonLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  loader: {
    marginRight: '5px',
    height: '16px',
    width: '16px',
    animation: '$spinner 1s linear infinite',
  },
}))

export default function RenderExportButton({ riskPriority, disableExportButton, action }) {
  const classes = useStyles();

  return (
    <CustomTooltip
      title="Before exporting your report set the BG check risk for this search"
      placement="top"
      disableHoverListener={riskPriority !== 'Not Set'}
      tooltipPlacementTop={classes.stepperTooltipPlacementTop}
    >
      <div>
        <CustomButton
          action={action}
          label={disableExportButton ? (
            <span className={classes.wrapperExportButtonLabel}>
              <LoaderOtherIcon className={classes.loader} />
            EXPORTING...
            </span>
          ) : 'EXPORT REPORT'}
          customClasses="customButtonStandard"
          disabled={riskPriority === 'Not Set' || disableExportButton}
        />
      </div>
    </CustomTooltip>
  )}
import React, {useMemo} from 'react';
import {Box, FormControl, Grid, Typography} from '@material-ui/core';
import RadioButtonGroup from '@components/buttons/radio-button-group';
import SelectSearchable from '@components/inputs/select-searchable';
import {countryCodeList} from '@services/country-service';

const SORT_ITEMS_BY_SCOPE = {
  local: [
    { label: 'Name', value: 'name' },
    { label: 'Jurisdiction', value: 'jurisdiction' }
  ],
  global: [
    { label: 'Date', value: 'date' },
    { label: 'Name', value: 'name' },
    { label: 'Jurisdiction', value: 'jurisdiction' },
    { label: 'Class', value: 'class' },
    { label: 'Type', value: 'type' }
  ]
}
//
// const CLASS_LIST = [
//   { label: 'Class A', value: 'class A'},
//   { label: 'Class B', value: 'class B'}
// ]
//
// const TYPE_LIST = [
//   { label: 'Type A', value: 'type A'},
//   { label: 'Type B', value: 'type B'}
// ]

export default function Filter({filters, setFilters, scope = 'local'}) {

  const sort = useMemo(() => filters.sortBy, [filters])

  const sortOptions = useMemo(() => SORT_ITEMS_BY_SCOPE[scope], [scope])
    
  const onSort = value => {
    setFilters({
      ...filters,
      sortBy: value
    })
  }

  const onFilterChange = name => event => {
    const value = event.target.value
    setFilters({
      ...filters,
      [name]: value
    })
  }

  return (
    <Grid container spacing={2} style={{width: 'auto'}}>

      <Grid item xs={scope === 'local'? 6: 12}>
        <Box display={'flex'} py={1} width={200}>
          <Typography variant="h5">
            Sort By
          </Typography>
        </Box>
        <RadioButtonGroup
          items={sortOptions}
          handleChange={e => onSort(e.target.value)}
          selectedValue={sort}
          formControlSize={40}
        />
      </Grid>
      <Grid item xs={6}>
        <Box display={'flex'} flexDirection={'column'} py={1} width={200}>
          <Typography variant="h5">
            Filter By
          </Typography>
          {/*<Box display={'flex'} mt={1}>*/}
          {/*  <FormControl*/}
          {/*    fullWidth*/}
          {/*    variant="outlined"*/}
          {/*  >*/}
          {/*    <InputLabel>Class</InputLabel>*/}
          {/*    <SelectClearable*/}
          {/*      onChange={onFilterChange('filterByClass')}*/}
          {/*      value={filters.filterByClass || ''}*/}
          {/*      clearable*/}
          {/*    >*/}
          {/*      { CLASS_LIST.map(({label, value}) => (*/}
          {/*        <MenuItem key={value} value={value}>{label}</MenuItem>*/}
          {/*      ))}*/}
          {/*    </SelectClearable>*/}
          {/*  </FormControl>*/}
          {/*</Box>*/}
          {/*<Box display={'flex'} mt={1}>*/}
          {/*  <FormControl*/}
          {/*    fullWidth*/}
          {/*    variant="outlined"*/}
          {/*  >*/}
          {/*    <InputLabel>Type</InputLabel>*/}
          {/*    <SelectClearable*/}
          {/*      onChange={onFilterChange('filterByType')}*/}
          {/*      value={filters.filterByType || ''}*/}
          {/*      clearable*/}
          {/*    >*/}
          {/*      { TYPE_LIST.map(({label, value}) => (*/}
          {/*        <MenuItem key={value} value={value}>{label}</MenuItem>*/}
          {/*      ))}*/}
          {/*    </SelectClearable>*/}
          {/*  </FormControl>*/}
          {/*</Box>*/}
          <Box display={'flex'} mt={1}>
            <FormControl
              fullWidth
              variant="outlined"
            >
              <SelectSearchable
                label={'Jurisdiction'}
                onChange={onFilterChange('filterByJurisdiction')}
                value={filters.filterByJurisdiction || ''}
                options={Object.entries(countryCodeList).map(([countryCode, countryName]) => ({
                  label: countryName,
                  value: countryCode
                }))}
              />
            </FormControl>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
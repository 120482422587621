import axios from 'axios';
import { datadogLogs } from '@datadog/browser-logs'
import config from './config';
import history from './history';
import auth from './auth';

import store from '@app/store';
import { loader } from '@store/actions';
import authActions from '@store/actions/auth';
import generalActions from '@store/actions/general';
import { maintenanceApi } from '@app/api';

const instance = axios.create({
  baseURL: config.api
});

// Sign requests with jwt if exists
instance.interceptors.request.use(
  async function (config) {
    if (!config.headers.hideLoader) {
      store.dispatch(loader.setLoader(true));
    }
    config.headers.hideLoader = undefined;

    // Inject jwt token to requests
    const token = await auth.getAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    // Prepare query string object
    // config.paramsSerializer = function (params) {
    //     return qs.stringify(params, {arrayFormat: 'brackets'});
    // };
    return config;
  },
  function (error) {
    store.dispatch(loader.setLoader(true));
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    store.dispatch(loader.setLoader(false));
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, async function (error) {
    // Check if the request is canceled by our app
    if (error instanceof axios.Cancel) {
      return Promise.reject(error)
    }

    store.dispatch(loader.setLoader(false));
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
        
    // We cannot distinguish connection error from no internet
    // Use navigator.onLine instead to determine if user can reach the internet
    const isDisconnected = !navigator.onLine;

    if (isDisconnected) {
      store.dispatch(generalActions.setSnackbar('Please check your internet connection', { variant: 'error' }))
    } else { // Check if backend is alive
      if (!error?.response || !error?.response?.status) {
        redirectToMaintenance();
        return Promise.reject(error);
      }

      if (error.response?.status !== 401) {
        const res = await maintenanceApi.healthCheck();

        if (res?.status !== 200) {
          redirectToMaintenance();
          return Promise.reject(error);
        }
      }
    }

    if(error?.response?.status === 401) {
      console.log('User logged out due to rejection by server')
      auth.logout();
      history.push('/login');
      return Promise.reject(error);
    }

    if (error?.response?.data?.error
        && error?.response?.data.error !== 'Bad Request') {
      store.dispatch(generalActions.setSnackbar(error?.response?.data?.error, { variant: 'error' }, true))
    } else if (!error?.response.data?.errorMessages) { // errorMessages usually means that it's a submission error that needs to be handled at form level
      store.dispatch(generalActions.setSnackbar('Something went wrong...', { variant: 'error' }, true))

      // Log to datadog
      datadogLogs.logger.error('Unexpected API call error', {
        error: {
          config: error.config,
          message: error.message,
          stack: error.stack,
        }
      })
    }
    return Promise.reject(error);
  }
);

export default instance;

// no loader, no maintenance check, etc.
// currently used for healthcheck endpoint only
export const baseAxios = axios.create({
  baseURL: config.api
});

const redirectToMaintenance = () => {
  if (history.location?.pathname !== '/maintenance') {
    store.dispatch(authActions.setLastVisitedPage(history.location));
  }
  history.push('/maintenance');
}

export const createCancelSource = () => {
  const source = axios.CancelToken.source();
  return source
}
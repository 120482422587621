const initialState = {
  currentPages: [0],
  currentItemsLength: 0,
};

const paginations = (state = initialState, action) => {

  switch (action.type) {
    case 'SET_PAGINATION_DEFAULT':
      return initialState;
    case 'SET_CURRENT_PAGES':
      return { ...state, currentPages: action.currentPages};
    case 'SET_CURRENT_ITEMS_LENGTH':
      return { ...state, currentItemsLength: action.currentItemsLength};
    default:
      return state
  }
};

export default paginations;

import React from 'react';

import {
  Box, Typography
} from '@material-ui/core';
import { eidvStatusIcons } from './eidv-status-card';

export default function EVTitle({ status, children }) {
  return (
    <Box mb={2} display="flex" alignItems="center">
      {eidvStatusIcons[status] || eidvStatusIcons.default}
      <Box ml={1}>
        <Typography variant="h4" style={{ fontSize: 20, color: '#484848' }}>{children}</Typography>
      </Box>
    </Box>
  )
}
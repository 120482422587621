import React, { useEffect, useMemo, useRef } from 'react';
import PageLayout from '@components/layout/page-layout';
import { clientApi } from '@app/api';
import debounce from 'awesome-debounce-promise';
import clsx from 'clsx';
import _ from 'lodash';
import CustomChip from '@components/controls/custom-chip';
import getRiskRatingLabel from '@utils/riskRatingLabel';

import {
  Box,
  MenuItem,
  Typography
} from '@material-ui/core';
import { saveAs } from 'file-saver';
import PortfolioFilters from '../components/filters';

import { useStyles } from './styles';

import {
  NewPersonIcon,
  NewEntityIcon,
  NewFolderIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DownloadIcon,
  PortfolioEntitiesIcon,
  SearchIcon,
  SearchLegalEntity,
  Delete2Icon,
} from '@app/icons';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { portfolio } from '@store/actions';


import FoldersTreeView from './folders-tree-view';
import InlineHeadBadge from '@components/badges/inline-head-badge';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import InnerHeader from '@components/layout/inner-header';
import ViewToggle from '@components/controls/view-toggle';
import OutlinedSearch from '@components/inputs/outlined-search';
import { Modal } from '@components/modals';
import FolderFormDialog from '../dialogs/folder-form-dialog';
import history from '@app/history';
import DeleteDialog from '../dialogs/delete-dialog';
import { prepareClient, getSortField, getSortFields } from '@dto/client';

import CardList from '@components/lists/card-list';
import TableList, { TCELL_TYPES } from '@components/lists/table-list';
import MenuWithContext from '@components/dropdowns/menu-with-context';
import { joinStringArray } from '@utils/joinStringArray';
import NoResultsFound from '@components/layout/no-results-found';
import { resolveQueryFilterSort, filterDefaults } from '../services/query-filter-sort';
import { useSnackbar } from 'notistack';
import FilterWithContext from '@components/dropdowns/filter-with-context';
import { useFirstRender } from '@hooks/useFirstRender';
import AddPersonDialog from '../dialogs/invite-person';
import { outdatedChip } from '@components/badges/outdated-chip';

const debouncedPersonIndex = debounce(clientApi.person.index, 1000);
const debouncedCompanyIndex = debounce(clientApi.company.index, 1000);

const listViewColumns = {
  person: [
    { field: 'name', headerName: 'Full name', bold: true, cellType: TCELL_TYPES.TEXT_ICON, decoration: outdatedChip },
    { field: 'citizenship', headerName: 'Citizenship' },
    { field: 'risk_score', headerName: 'Risk Rating', colProps: { style: { textTransform: 'capitalize' } } },
    { field: 'formatted_created_at', headerName: 'Date Added' },
    { field: 'last_review_at', headerName: 'Last Review' },
    { field: 'next_review_at', headerName: 'Next Review' }
  ],
  company: [
    { field: 'name', headerName: 'Name', bold: true, cellType: TCELL_TYPES.TEXT_ICON, decoration: outdatedChip },
    { field: 'country', headerName: 'Country' },
    { field: 'risk_score', headerName: 'Risk Rating', colProps: { style: { textTransform: 'capitalize' } } },
    { field: 'formatted_created_at', headerName: 'Date Added' },
    { field: 'last_review_at', headerName: 'Last Review' },
    { field: 'next_review_at', headerName: 'Next Review' }
  ]
}

export default function Index(props) {
  const params = props.computedMatch.params;

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar()
  const firstRender = useFirstRender();

  const classes = useStyles();
  const queryFilterSort = resolveQueryFilterSort()

  const tabs = [
    {
      id: 'tab-persons',
      label: 'People',
      // tagValue: 10,
      // tagColor: '#D5F0F0'
    },
    {
      id: 'tab-companies',
      label: 'Legal Entities',
      // tagValue: 5,
      // tagColor: '#D5F0F0'
    }
  ]

  // Pagination
  const [page, setPage] = React.useState(0)
  const [pageSize] = React.useState(9) //eslint-disable-line
  const [pagesCount, setPagesCount] = React.useState(1)
  const [totalCount, setTotalCount] = React.useState(0)
  const onPageChange = (event, page) => {
    setPage(page - 1)
  }

  const [tabID, setTabID] = React.useState(params.tab || 'tab-persons');
  const [foldersTreeOpen, setFoldersTreeOpen] = React.useState(true);
  const [folderId, setFolderId] = React.useState(params.folderId || null);
  const [view, setView] = React.useState('cards'); // list/cards
  const [search, setSearch] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [flatFolders, setFlatFolders] = React.useState([]);
  const [folders, setFolders] = React.useState([]);
  const [sort, setSort] = React.useState(queryFilterSort.sort);
  const [sortDirection, setSortDirection] = React.useState(queryFilterSort.sortDirection);
  const [pendingFolderFormID, setPendingFolderFormID] = React.useState(false);
  const [pendingDelete, setPendingDelete] = React.useState(null);
  const [csvModalOpen, setCsvModalOpen] = React.useState(false);
  const [filters, setFilters] = React.useState(queryFilterSort.filters);
  const [rootFolderId, setRootFolderId] = React.useState(null);
  const [showAddPerson, setShowAddPerson] = React.useState(false);

  const clientType = useMemo(() => tabID === 'tab-persons' ? 'person' : 'company', [
    tabID
  ])

  const folderName = useMemo(() => {
    return flatFolders.find(folder => folder.id === +folderId)?.name
  }, [folderId, flatFolders])

  useEffect(() => {
    fetchFolders()

    return () => {
      dispatch(portfolio.setDefault());
    }
  }, []) //eslint-disable-line

  const prevFilters = useRef(filters)

  useEffect(() => {
    // Wait for folder init
    if (!rootFolderId || !folderId) {
      return;
    }
    const isNextReviewRangeChanged = _.differenceWith(
      prevFilters.current,
      filters,
      _.isEqual
    )?.[0]?.name === 'nextReview'
    const nextReview = filters.find(f => f.name === 'nextReview')
    if (!isNextReviewRangeChanged
      || ((nextReview.value.from && nextReview.value.to) || (!nextReview.value.from && !nextReview.value.to))
    ) {
      fetchItems()
    }
    prevFilters.current = filters
    // eslint-disable-next-line
  }, [tabID, folderId, rootFolderId, page, sort, sortDirection, filters])


  useEffect(() => {
    if (!firstRender && search !== null) {
      const timeOutId = setTimeout(() => fetchItems(true), 300);
      return () => clearTimeout(timeOutId);
    }
    // eslint-disable-next-line
  }, [search]);

  const linkFilterState = (event, name) => {
    let newFilters;
    if (name === 'nextReview') {
      newFilters = filters.map(el =>
        el.name === name ?
          {
            ...el,
            value: event.value
          } : el
      );
    } else {
      newFilters = filters.map(el => el.value === event.target.value
        ? ({ ...el, isChecked: event.target.checked })
        : el
      );
    }
    setFilters(newFilters);
    dispatch(portfolio.setSortAndFilters({ sort, filters: newFilters, sortDirection }));
  };

  const generateParams = (searchKeyword) => {
    const riskRatings = filters.filter(el => el.name === 'riskRating').filter(el => el.isChecked)?.map(el => el?.value)
    const legalEntityTypes = filters.filter(el => el.name === 'legalEntityType' && el.isChecked).map(el => el.value);
    const selectedMonitory = filters.filter(el => el.name === 'monitory' && el.isChecked);
    const monitory = selectedMonitory.length === 1
      ? selectedMonitory[0].value === 'On'
      : null;
    const nextReview = filters.find(el => el.name === 'nextReview')?.value;
    const fromNextDateOfReview = nextReview?.from && nextReview?.to ? moment(nextReview?.from).utc(true).startOf('day') : null;
    const toNextDateOfReview = nextReview?.from && nextReview?.to ? moment(nextReview?.to).utc(true).endOf('day') : null;

    return {
      pageNumber: page,
      pageSize,
      searchKeyword: searchKeyword || search?.trim() || null,
      sortBy: sort && getSortField(sort, clientType),
      sortDirection: sortDirection,
      monitory,
      folderId: Number(folderId) !== Number(rootFolderId) ? folderId : null,
      riskRatings,
      legalEntityTypes,
      fromNextDateOfReview,
      toNextDateOfReview
    }
  }

  const fetchItems = async (debounce = false) => {
    const searchKeyword = search?.trim() || ''
    if (searchKeyword.length < 2 && searchKeyword.length) {
      enqueueSnackbar('Search keyword should have at least 2 characters!', {
        variant: 'error',
      });
      return;
    }
    const params = generateParams(searchKeyword);
    let data;
    if (debounce) {
      data = (clientType === 'person')
        ? (await debouncedPersonIndex(params)).data
        : (await debouncedCompanyIndex(params)).data;
    } else {
      data = (clientType === 'person')
        ? (await clientApi.person.index(params)).data
        : (await clientApi.company.index(params)).data;
    }

    setData(
      (data?.entries || []).map(item => ({ ...prepareClient(item), isChecked: false }))
    )
    const newPages = data?.pages || 1;
    setPagesCount(newPages);
    setTotalCount(data?.count || 0);

    if (page > newPages - 1) {
      setPage(newPages - 1);
    }
  }

  const counterExpiredReviews = useMemo(() => {
    if (folderId && data) {
      return {
        badgeValue: data.filter(el => moment(el.next_review_at) < moment())?.length || 0,
        badgeColor: tabID === 'tab-persons' ? '#D5F0F0' : '#D1C2B6'
      }
    }
  }, [folderId, data, tabID])

  const fetchFolders = async () => {
    const foldersData = (await clientApi.folder.index({ pageSize: 1000 })).data?.entries || []
    setFlatFolders([...foldersData])
    const rootIdx = foldersData.findIndex(f => f.name.toLowerCase() === 'home')
    const rootFolder = foldersData[rootIdx]
    setRootFolderId(rootFolder.id)
    foldersData.splice(rootIdx, 1)
    setFolders([{
      ...rootFolder,
      children: [...foldersData]
    }])
    if (!folderId) {
      setFolderId(rootFolder.id)
    }
  }

  const onTabChange = (index) => {
    setPage(0)
    const tabID = tabs[index].id
    setTabID(tabID)
    let uri = `/portfolio/${tabID}`
    if (folderId) {
      uri += `/${folderId}`
    }
    history.push(uri)
  }

  const onSelect = (id, isChecked) => {
    if (id === 'all') {
      const newData = data.map(item => ({ ...item, isChecked }))
      setData(newData);
    } else {
      const newData = data.map(
        (item) => item.id === id
          ? ({ ...item, isChecked: !item.isChecked })
          : item
      );
      setData(newData);
    }
  }

  const onExportCSV = (exportAll) => {
    const params = {
      ...generateParams(),
      pageSize: 100000,
      pageNumber: 0,
      clientType: clientType === 'person' ? 'PERSON' : 'LEGAL_ENTITY'
    }

    if (exportAll) params.clientType = undefined;

    clientApi.csv.export(params).then((res) => {
      enqueueSnackbar('The selected list of clients were successfully exported!', { variant: 'success' })
      const file = new Blob([res.data], { type: 'text/csv' });
      saveAs(file, res.headers['content-disposition'].split('filename=')[1]);
    });
    setCsvModalOpen(false)
  };

  const onSearch = (value) => {
    setPage(0)
    setSearch(value)
  }

  const onSort = (value) => {
    setPage(0)
    if (sort !== value) {
      setSort(value)
      setSortDirection('ASC')
      dispatch(portfolio.setSortAndFilters({ value, filters, sortDirection: 'ASC' }))
    } else {
      const sorting = sortDirection === 'ASC' ? 'DESC' : 'ASC'
      setSortDirection(sorting)
      dispatch(portfolio.setSortAndFilters({ sort, filters, sortDirection: sorting }))
    }
  }

  const onToggleFoldersTree = () => {
    setFoldersTreeOpen(!foldersTreeOpen)
  }

  const onUpdate = (id) => {
    history.push(`/portfolio/view/${id}/personal-details?edit=true`)
  }

  const onView = (id) => {
    history.push(`/portfolio/view/${id}`)
  }

  const onFolderUpdate = (id) => {
    setPendingFolderFormID(id);
  }

  const onDelete = (id) => {
    const item = data.find(v => v.id === id);
    setPendingDelete({
      type: clientType,
      id,
      name:
        clientType === 'person'
          ? [item.first_name, item.last_name].join(' ')
          : item.name
    })
  }

  const onFolderDelete = (id) => {
    const folder = flatFolders.find(v => v.id === id);
    if (folder.name.toLowerCase() === 'home') {
      return;
    }
    setPendingDelete({
      type: 'folder',
      id: folder.id,
      name: folder.name
    })
  }

  const getSelectedIds = () => {
    return data.reduce((acc, item) => {
      if (item.isChecked) {
        acc.push(item.id)
      }
      return acc;
    }, [])
  }

  const onBatchDelete = () => {
    const type = tabID === 'tab-persons' ? 'person' : 'company'
    const names = []
    const ids = data.reduce((acc, item) => {
      if (item['isChecked']) {
        acc.push(item.id);
        names.push(item.name);
      }
      return acc;
    }, [])
    setPendingDelete({
      type,
      ids,
      name: joinStringArray(names.map(e => `"${e}"`))
    })
  }

  const countSelected = useMemo(() => {
    return data.filter(e => e.isChecked).length;
  }, [data])

  const onFolderSave = () => {
    setPendingFolderFormID(false)
    fetchFolders()
    fetchItems()
  }

  const onDeleteSuccess = () => {
    setPendingDelete(null)
    fetchFolders()
    fetchItems()
  }

  const onFolderSelect = (folderId) => {
    setFolderId(folderId)
    history.push(`/portfolio/${tabID}/${folderId}`)
  }

  const onBatchMoveToFolder = () => {
    history.push(`/portfolio/${clientType}/move-to-folder?ids=${getSelectedIds().join(',')}`)
  }

  const onResetFilter = filterName => {
    setFilters(filters.map(
      (filter, iFilter) => filter.name === filterName ? filterDefaults[iFilter] : filter)
    )
  }

  const renderedFilterBadges = useMemo(() => {
    const badges = []
    // Risk Filters applied
    const riskRatingOptions = filters.filter(f => f.name === 'riskRating')
    const riskRatingSelected = riskRatingOptions.filter(f => f.isChecked)
    if (riskRatingSelected.length) {
      badges.push({
        name: 'riskRating',
        content: `Risk Rating: ${riskRatingSelected.map(f => f.label).join(',')}`
      })
    }
    // Legal entity type
    const legalEntityTypeOptions = filters.filter(f => f.name === 'legalEntityType')
    const legalEntityTypeSelected = legalEntityTypeOptions.filter(f => f.isChecked)
    if (legalEntityTypeSelected.length) {
      badges.push({
        name: 'legalEntityType',
        content: `Legal Entity Type: ${legalEntityTypeSelected.map(f => f.label).join(',')}`
      })
    }
    // Monitoring
    const monitoryFilters = filters.filter(f => f.name === 'monitory')
    if (monitoryFilters[0]?.isChecked !== monitoryFilters[1]?.isChecked) {
      badges.push({
        name: 'monitory',
        content: monitoryFilters.find(f => f.isChecked).badgeLabel
      })
    }
    // Next review date
    const nextReviewFilter = filters.find(f => f.name === 'nextReview')
    if (nextReviewFilter.value.from && nextReviewFilter.value.to) {
      badges.push({
        name: 'nextReview',
        content: `Next Review Date: ${nextReviewFilter.value?.from && moment(nextReviewFilter.value?.from).format('MM/DD/YYYY')} - ${nextReviewFilter.value?.to && moment(nextReviewFilter.value?.to).format('MM/DD/YYYY')}`
      })
    }
    return badges.map(badge => (
      <Box key={`filter-badge-${badge.name}`} ml={1}>
        <CustomChip
          label={badge.content}
          deleteIcon={
            <Box className={classes.deleteChipIcon}>
              <Delete2Icon viewBox="0 0 17 12" />
            </Box>
          }
          onDelete={() => onResetFilter(badge.name)}
          propsClasses={classes.chip}
        />
      </Box>
    ))
  }, [filters]) //eslint-disable-line

  const listData = useMemo(() => data.map(item => ({
    ...item,
    risk_score: getRiskRatingLabel(item.risk_score)
  })), [data])

  return (
    <React.Fragment>
      <AddPersonDialog
        open={showAddPerson}
        onClose={() => setShowAddPerson(false)}
        folders={flatFolders}
        folderId={folderId}
        folderName={folderName}
      />

      <FolderFormDialog
        open={pendingFolderFormID !== false}
        id={pendingFolderFormID}
        onSaved={onFolderSave}
        onClose={() => {
          setPendingFolderFormID(false)
        }}
      />

      <DeleteDialog
        data={pendingDelete}
        open={!!pendingDelete}
        onDeleted={onDeleteSuccess}
        onClose={() => {
          setPendingDelete(null)
        }}
      />

      <Modal
        open={csvModalOpen}
        onClose={() => setCsvModalOpen(false)}
        title="Export CSV"
        mainText={`Do you want to export your entire client list or a list of ${clientType === 'person' ? 'individuals' : 'legal entities'} only?`}
        actions={
          [
            {
              type: 'main',
              label: `${clientType === 'person' ? 'People' : 'Legal Entities'} Only`,
              action: () => onExportCSV(false),
              style: { width: 240 },
            },
            {
              type: 'secondary',
              label: 'All Clients',
              action: () => onExportCSV(true),
              style: { width: 240 },
            },
          ]
        }
        actionsDirection="row"
      />

      <PageLayout>
        <Box px={6}>
          <InnerHeader
            onTabChange={onTabChange}
            ind={tabs.findIndex(tab => tab.id === tabID)}
            tabs={tabs}
            buttons={
              <React.Fragment>
                <ButtonWithIcon
                  startIcon={<DownloadIcon />}
                  onClick={() => setCsvModalOpen(true)}
                  disabled={totalCount === 0}
                  tooltip={totalCount === 0 ? 'You will need at least one entity to enable Export CSV' : undefined}
                >
                  Export CSV
                </ButtonWithIcon>
                <ButtonWithIcon startIcon={<NewFolderIcon />} onClick={() => setPendingFolderFormID(null)}>
                  New Folder
                </ButtonWithIcon>
                {tabID === 'tab-persons' &&
                  <ButtonWithIcon startIcon={<NewPersonIcon />} onClick={() => setShowAddPerson(true)}>
                    New Person
                  </ButtonWithIcon>
                }
                {tabID === 'tab-companies' &&
                  <ButtonWithIcon startIcon={<NewEntityIcon />} onClick={() => { history.push(`/portfolio/create-company?folderId=${folderId}&folderName=${folderName}`) }}>
                    New Legal Entity
                  </ButtonWithIcon>
                }
                <MenuWithContext badge={countSelected}>
                  <MenuItem onClick={() => { history.push('/portfolio/import/upload-file') }}>Import CSV</MenuItem>
                  <MenuItem disabled={!countSelected} onClick={onBatchMoveToFolder}>Move to Folder</MenuItem>
                  <MenuItem disabled={!countSelected} onClick={onBatchDelete}>
                    {countSelected > 1 ? 'Delete Entities' : 'Delete Entity'}
                  </MenuItem>
                </MenuWithContext>
              </React.Fragment>
            } />
        </Box>
        <Box display={'flex'} flexDirection={'row'} flexGrow={1} className={classes.scrollableTreeWrapper}>
          {foldersTreeOpen &&
            <Box className={classes.sideTree}>
              <React.Fragment>
                <Box className={clsx(classes.collapseMenuButton, 'in')} onClick={onToggleFoldersTree}>
                  <ChevronLeftIcon />
                </Box>
                <Box display={'flex'} pl={3} pr={2} style={{ height: '95%', width: '100%' }}>
                  <FoldersTreeView
                    onUpdate={onFolderUpdate}
                    onDelete={onFolderDelete}
                    items={folders}
                    onSelect={onFolderSelect}
                    selected={folderId}
                    onNewFolder={() => setPendingFolderFormID(null)}
                    counterExpiredReviews={counterExpiredReviews}
                    tab={tabID === 'tab-persons' ? 'person' : 'legal entities'}
                  />
                </Box>
              </React.Fragment>
            </Box>
          }
          {!foldersTreeOpen &&
            <Box className={classes.sideTreeCollapse} onClick={onToggleFoldersTree}>
              <Box className={clsx(classes.collapseMenuButton, 'out')}>
                <ChevronRightIcon />
              </Box>
              <Box display={'flex'} justifyContent={'center'} alignItems={'flex-start'}>
                <Box alignItems={'flex-start'} style={{
                  writingMode: 'tb-rl',
                  cursor: 'pointer'
                }}>
                  <Box mt={1} style={{ textTransform: 'uppercase', transform: 'rotate(180deg)' }}>
                    <Typography variant={'h5'} style={{ wordSpacing: '5px' }}>
                      {folderId && folderName}
                      {!folderId && 'all'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          }
          <Box display={'flex'} pl={4} pr={6} flexGrow={'1'} className={classes.content}>
            <Box display={'flex'} flexGrow={'1'} flexDirection={'column'}>
              <Box display={'flex'} mt={1} width={'100%'} alignItems={'flex-start'}>
                <Box display={'flex'} height={'48px'} alignItems={'center'}>
                  <Box mr={3}>
                    <InlineHeadBadge
                      badgeColor={tabID === 'tab-persons' ? '#D5F0F0' : '#D1C2B6'}
                      badgeValue={totalCount}
                    >
                      {tabID === 'tab-persons' && 'People'}
                      {tabID === 'tab-companies' && 'Legal Entities'}
                    </InlineHeadBadge>
                  </Box>
                </Box>
                <Box display={'flex'} flexGrow={1} flexWrap={'wrap'} style={{ minHeight: '48px' }} alignItems={'center'}>
                  {renderedFilterBadges}
                </Box>
                <Box display={'flex'} height={'48px'} alignItems={'center'}>
                  <FilterWithContext
                    isActive={!!renderedFilterBadges.length}
                  >
                    <PortfolioFilters
                      sort={sort}
                      onSort={onSort}
                      clientType={clientType}
                      filters={filters}
                      linkFilterState={linkFilterState}
                    />
                  </FilterWithContext>
                  <ViewToggle value={view} onChange={setView} />
                </Box>
              </Box>
              <Box display={'flex'}>
                <OutlinedSearch
                  label={`Search ${tabID === 'tab-persons' ? 'people' : 'legal entities'}`}
                  value={search}
                  onChange={event => onSearch(event.target.value)}
                  icons={tabID === 'tab-persons'
                    ? <SearchIcon />
                    : <SearchLegalEntity />
                  }
                />
              </Box>
              {!!data.length &&
                <Box display={'flex'} mt={2}>
                  {view === 'cards' &&
                    <CardList
                      onSelect={onSelect}
                      extended={!foldersTreeOpen}
                      items={data}
                      page={page}
                      type={clientType}
                      pagesCount={pagesCount}
                      pageSize={pageSize}
                      totalCount={totalCount}
                      onPageChange={onPageChange}
                      onClick={onView}
                      maxHeight="calc(100vh - 390px)"
                      disabled={false}
                      actions={[
                        { label: 'View', handler: onView },
                        { label: 'Update', handler: onUpdate },
                        { label: 'Delete Entity', handler: onDelete }
                      ]}
                    />
                  }
                  {view === 'list' &&
                    <TableList
                      columns={listViewColumns[clientType]}
                      sortableColumns={Object.keys(getSortFields(clientType))}
                      items={listData}
                      onSelect={onSelect}
                      selectAll
                      page={page}
                      pageSize={pageSize}
                      pagesCount={pagesCount}
                      totalCount={totalCount}
                      onPageChange={onPageChange}
                      onClick={onView}
                      onSort={onSort}
                      sort={sort}
                      sortDirection={sortDirection}
                      maxHeight="calc(100vh - 380px)"
                      threeDotsActions={[
                        { label: 'View', handler: onView },
                        { label: 'Update', handler: onUpdate },
                        { label: 'Delete Entity', handler: onDelete }
                      ]}
                    />
                  }
                </Box>
              }
              {!data.length &&
                <Box display={'flex'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
                  <NoResultsFound icon={<PortfolioEntitiesIcon />}>
                    {
                      !search && filters.some(f => f.isChecked)
                        ? 'No entities found for the applied filters'
                        : 'When you save entities in your Portfolio they will appear here'
                    }
                  </NoResultsFound>
                </Box>
              }
            </Box>
          </Box>
        </Box>
      </PageLayout>

    </React.Fragment>
  )
}
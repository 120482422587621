import SubscriptionManagement from '@views/protected/admin/subscription-management';

const routes = [
  {
    path: '/subscription-management',
    component: SubscriptionManagement,
    guest: false,
  },
]

export default routes;
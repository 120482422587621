import React from 'react';
import { Modal } from '@components/modals';


export default function ExcludeDialog(props) {

  const {open, onClose, onConfirm} = props

  const getTitle = () => {
    return 'Delete note';
  }

  const getMainText = () => {
    return 'Are you sure you want to delete the selected note?'
  }

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title={getTitle()}
        mainText={getMainText()}
        actions={
          [
            {
              type: 'main',
              label: 'NO',
              action: onClose,
              style: { width: 160 },
            },
            {
              type: 'secondary',
              label: 'YES',
              action: onConfirm,
              style: { width: 160 },
            },
          ]
        }
        actionsDirection="row"
      />
    </React.Fragment>
  );
}
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';
import history from '@app/history';
import { EditIcon } from '@app/icons';
import { clientApi } from '@app/api';
import auth from '@app/store/actions/auth';
import LabelsForRender from '@components/layout/labels-for-render';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import formatAddress from '@utils/formatAddress';
import prepareTimestamp from '../../../../utils/date';

const selectUser = state => state.auth.user;

export default function MyAccount(props) {
  const userData = useSelector(selectUser);
  const dispatch = useDispatch();

  const personalInfo = [
    { field: 'First name', value: userData.name || 'N/A' },
    { field: 'Middle name', value: userData.middleName || 'N/A' },
    { field: 'Last name', value: userData.lastName || 'N/A' },
    { field: 'Date of birth', value: userData.dob ? prepareTimestamp(userData.dob, 'MM/DD/YYYY', false) : 'N/A' },
    { field: 'Organization', value: userData.organization || 'N/A' },
    { field: 'Address', value: formatAddress(userData) || 'N/A' },
  ]

  const contactInfo = [
    { field: 'Primary email', value: userData.email || 'N/A' },
    { field: 'Other email', value: userData.otherEmails || 'N/A' },
    { field: 'Phone number', value: userData.phoneNumber || 'N/A' },
  ]
    
  React.useEffect(() => {
    clientApi.user.getCurrentUser().then((res) => {
      localStorage.setItem('user', JSON.stringify(res.data));
      dispatch(auth.setUser(res.data));
    })
  }, [dispatch])

  return (
    <Box pt={3} px={8}>
      <Grid container>
        <Grid item xs={6}>
          <Box mb={2} mt={1}>
            <Typography variant="h5">
                            Personal Information
            </Typography>
          </Box>
          <LabelsForRender labels={personalInfo} boxProps={{ mb: 1 }} />
        </Grid>
        <Grid item xs={5}>
          <Box mb={2} mt={1}>
            <Typography variant="h5">
                            Contact Information
            </Typography>
          </Box>
          <LabelsForRender labels={contactInfo} boxProps={{ mb: 1 }} />
        </Grid>
        <Grid item xs={1}>
          <ButtonWithIcon
            startIcon={<EditIcon />}
            onClick={() => history.push('/my-profile/my-account/edit')}
          >
                        Edit
          </ButtonWithIcon>
        </Grid>
      </Grid>
    </Box>
  )
};

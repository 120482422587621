import React, {useEffect, useMemo, useState} from 'react';
import PageLayout from '@components/layout/page-layout';
import SideMenuLayout, {
  useStyles as useSideMenuStyles,
} from '@components/layout/side-menu-layout';
import {Box, Typography} from '@material-ui/core';
import clsx from 'clsx';
import {
  RiskRatingTemplateActiveIcon,
  RiskRatingTemplateInactiveIcon,
  SubscriptionManagement,
  SubscriptionManagementInactive,
  SettingsActiveIcon,
  SettingsInactiveIcon,
  NotificationsIcon,
  NotificationsInactiveIcon,
} from '@app/icons';
import history from '@app/history';
import RiskRatingSettings from './risk-rating';
import InnerHeader from '@components/layout/inner-header';
import {useParams} from 'react-router-dom';
import SubscriptionPlans from '@components/complex/subscription/plans';
import EntitySettingRoutes from './entity/routes';
import debugMode from '../../../../utils/debugMode';
import EmailsNotificationsSettings from './emails-notifications';

const TABS = {
  'subscription-plans': [
    {
      id: 'subscription-plans',
      label: 'Subscription Plans',
    },
  ],
  'risk-rating': [
    {
      id: 'risk-rating',
      label: 'Risk Rating Templates',
    },
  ],
  'entity-by-type': [
    {
      id: 'entity-by-type',
      label: 'By Entity type',
    },
  ],
  'entity-by-jurisdiction': [
    {
      id: 'entity-by-jurisdiction',
      label: 'By Jurisdiction',
      hasSearch: true,
      searchLabelText: 'Search Jurisdiction'
    },
  ],
  'emails-notifications': [
    {
      id: 'emails-notifications',
      label: 'Emails',
    },
    // {
    //   id: 'notifications',
    //   label: 'Notifications',
    // },
  ],
};

export default function Settings(props) {
  const { tab } = useParams();

  const sideMenuClasses = useSideMenuStyles();

  const [isDebug] = useState(debugMode.getIsDebugMode())
  const [sideTab, setSideTab] = useState(tab || 'subscription-plans');

  // eslint-disable-next-line
  const [hTab, setHeaderTab] = useState(null)
  const baseUri = '/settings'

  const [searchTerm, setSearchTerm] = useState('')

  const getUri = (tabID) => {
    if (tabID) {
      return `${baseUri}/${tabID}`;
    }
    return baseUri;
  };

  useEffect(() => {
    onSetSideTab(sideTab);
    // eslint-disable-next-line
  }, [])

  const onSetSideTab = (t) => {
    if (t !== sideTab) {
      history.replace(getUri(t))
    }
    setSideTab(t)
  }

  const onSetHeaderTab = iHeaderTab => {
    const newHeaderTab = headerTabs[iHeaderTab]?.id
    if (newHeaderTab) {
      setHeaderTab(
        newHeaderTab
      )
    }
  }

  const headerTabs = useMemo(() => TABS[sideTab], [sideTab])

  const headerTab = useMemo(() => hTab || headerTabs[0]?.id, [hTab, headerTabs])

  const headerTabData = useMemo(() => headerTabs && headerTabs.find((tab) => tab.id === headerTab), [headerTab, headerTabs])

  const sideTabs = useMemo(() => {
    return [
      {
        slug: 'subscription-plans',
        name: 'Subscription Plans',
        disabled: false,
        statusIcon: null,
        component: <SubscriptionPlans isSettings />,
        icon: <SubscriptionManagement />,
        iconInactive: <SubscriptionManagementInactive />,
      },
      {
        slug: 'risk-rating',
        name: 'Risk Rating Settings',
        disabled: false,
        statusIcon: null,
        component: RiskRatingSettings,
        icon: <RiskRatingTemplateActiveIcon />,
        iconInactive: <RiskRatingTemplateInactiveIcon />,
      },
      {
        slug: 'emails-notifications',
        name: isDebug ? 'Emails & Notifications' : 'Emails',
        disabled: false,
        statusIcon: null,
        component: EmailsNotificationsSettings,
        icon: <NotificationsIcon />,
        iconInactive: <NotificationsInactiveIcon />,
      },
      {
        slug: 'entity',
        name: 'Entity Settings',
        disabled: false,
        statusIcon: null,
        icon: <SettingsActiveIcon />,
        iconInactive: <SettingsInactiveIcon />,
        children: [
          {
            slug: 'entity-by-type',
            name: 'By Entity Type',
            component: <EntitySettingRoutes/>
          },
          {
            slug: 'entity-by-jurisdiction',
            name: 'By Jurisdiction',
            component: <EntitySettingRoutes/>
          }
        ]
      },
    ].filter(item => !!item);
  }, [isDebug]);

  const isMenuItemRoot = () =>
    sideTabs.some(
      tabItem =>
        !tabItem.children
          ? `/settings/${tabItem.slug}` === props.location.pathname
          : tabItem.children.some(subTab => `/settings/${subTab.slug}` === props.location.pathname)
    )

  return (
    <PageLayout>
      <SideMenuLayout
        collapseMenu
        activeMenuName={
          sideTabs.find((tabItem) => tabItem.slug === sideTab)?.name
        }
        menu={sideTabs.map((tabItem) => (
          <Box
            key={tabItem.slug}
            className={clsx(
              sideMenuClasses.tab,
              sideTab.indexOf(tabItem.slug) === 0 && 'active',
              tabItem.disabled && 'disabled'
            )}
            onClick={() => {
              if(sideTab !== tabItem.slug && !tabItem.disabled && !tabItem.children) {
                onSetSideTab(tabItem.slug)
              }
            }}
          >
            <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
              <Box display={'flex'}>
                <Box display={'flex'} flexGrow={1} alignItems={'center'}>
                  <Box display={'flex'} mr={1}>
                    {!tabItem.disabled && tabItem.icon && tabItem.icon}
                    {tabItem.disabled && tabItem.icon && tabItem.iconInactive}
                  </Box>
                  {tabItem.name}
                </Box>
                {tabItem.statusIcon && tabItem.statusIcon}
              </Box>
              { (tabItem.children || []).map(subTab => (
                <Box
                  key={subTab.slug}
                  className={clsx(sideMenuClasses.subTab, (sideTab.indexOf(subTab.slug) === 0 && 'active'))}
                  onClick={() => {
                    sideTab !== subTab.slug &&
                      !subTab.disabled &&
                      onSetSideTab(subTab.slug);
                  }}
                >
                  <Typography variant={'subtitle2'}>
                    {subTab.name}
                  </Typography>
                </Box>
              )) }
            </Box>
          </Box>
        ))}
        content={
          <Box display={'flex'} flexDirection={'column'} pl={4} pr={5}>
            {isMenuItemRoot() && (
              <Box display={'flex'} mb={5}>
                <InnerHeader
                  onTabChange={onSetHeaderTab}
                  ind={headerTabs.findIndex((tab) => tab.id === headerTab)}
                  tabs={headerTabs}
                  hasSearch={!!headerTabData.hasSearch}
                  searchLabelText={headerTabData.searchLabelText}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
              </Box>
            )}

            {sideTabs.map(
              (tabItem) =>
                sideTab.indexOf(tabItem.slug) === 0 && (
                  !tabItem.children ? (
                    <Box display={'flex'} key={tabItem.slug}>
                      <Box key={tabItem.slug} flexGrow={1} style={{ position: 'relative' }}>
                        {tabItem.component}
                      </Box>
                    </Box>
                  ) : (
                    tabItem.children.map(subTab => sideTab.indexOf(subTab.slug) === 0 && (
                      <Box display={'flex'} key={subTab.slug}>
                        <Box key={subTab.slug} flexGrow={1} style={{ position: 'relative' }}>
                          {{...subTab.component, props: {searchTerm}}}
                        </Box>
                      </Box>
                    ))
                  )
                )
            )}
          </Box>
        }
      />
    </PageLayout>
  );
}

import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, InputLabel, OutlinedInput, FormControl } from '@material-ui/core';
import InnerHeaderControls from '@components/layout/inner-header-controls';
import InnerHeader from '@components/layout/inner-header';
import BackButton from '@views/common/back-button';
import LabelsForRender from '@components/layout/labels-for-render';

import { useLocation } from 'react-router-dom';
import { clients } from '@app/api/admin'; //eslint-disable-line
import { dummy } from '../../dummy';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  subtitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '18px',
    color: theme.palette.text.BL1,
    marginBottom: '15px',
  },
  wrapperLabels: {
    marginBottom: '10px',
    alignItems: 'flex-start'
  },
  input: {
    width: '305px',
    marginRight: '10px',
  },
  editButton: {
    height: '30px',
    marginTop: '-8px',
  }
}))

const tabs = [
  {
    id: 'account-details',
    label: 'Account Details',
  },
]

export default function ViewTeamMember() {
  const classes = useStyles();

  const location = useLocation();
  const search = new URLSearchParams(location.search);

  const [userId] = useState(+search.get('userId'));
  const [tabID, setTabID] = useState('account-details'); //eslint-disable-line
  const [user, setUser] = useState(null);

  const getUser = useCallback(async () => {
    // const currUser = (await clients.getUserById(userId)).data;
    const currUser =  dummy.members.find(el => el.id === userId);
    setUser({
      ...currUser,
      teamMembership: [ { field: 'Team Leader', value: currUser.teamLeader } ],
      personalInformation: [
        { field: 'First Name', value: currUser.firstName },
        { field: 'Last Name', value: currUser.lastName },
        { field: 'Address', value: currUser.address },
        { field: 'Phone', value: currUser.phone },
      ]
    });
  }, [userId])

  useEffect(() => {
    getUser();
  }, [getUser])


  return (
    <React.Fragment>
      <Box display={'flex'} my={2} mx={1} flexDirection={'column'}>
        <InnerHeaderControls
          controlsLeft={<BackButton wrapped />}
        />
      </Box>
      <Box display={'flex'} mx={6} flexDirection={'column'}>
        {!!user && (<Typography variant="h4">{user?.name}</Typography>)}
        <Box ml={-1} mb={4}>
          <InnerHeader
            onTabChange={() => {}}
            ind={0}
            tabs={tabs}
          />
        </Box>
      </Box>

      <Box display={'flex'} mx={6} flexDirection={'column'} height={'100%'}>
        <Box>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography className={classes.subtitle}>Team Membership</Typography>
            <LabelsForRender labels={user?.teamMembership || []} propsWrapper={classes.wrapperLabels} />
          </Box>
          <Box display={'flex'} flexDirection={'column'} mt={3}>
            <Typography className={classes.subtitle}>Personal Information</Typography>
            <LabelsForRender labels={user?.personalInformation || []} propsWrapper={classes.wrapperLabels} />
          </Box>
        </Box>
        <Box display={'flex'} mb={6} flexDirection={'column'} width={'30%'} mt={3}>
          <Typography className={classes.subtitle}>Account Protection</Typography>
          <FormControl fullWidth variant="outlined" >
            <InputLabel htmlFor="name">Email</InputLabel>
            <OutlinedInput
              value={user?.email}
              disabled
            />
          </FormControl>
        </Box>
      </Box>
    </React.Fragment>
  )
}
import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, Grid, Button } from '@material-ui/core';

import InnerHeaderControls from '@components/layout/inner-header-controls';
import BackButton from '@views/common/back-button';
import RenderInputs from '@components/layout/render-inputs';

import { clients, subscriptionPlans } from '@app/api/admin';
import { useLocation } from 'react-router-dom';
import history from '@app/history';
import { useSnackbar } from 'notistack';
import useErrorState from '@utils/errorState';
import * as yup from 'yup';
import parseYupErrors from '@app/yup';
import debounce from 'awesome-debounce-promise';
import { withLoggedNotFound } from '@views/protected/not-found';

import { makeStyles } from '@material-ui/core/styles';
import { revertFormatCurrencyInput } from '@utils/formatCurrency';
import { formatCurrencyInput } from '@utils/formatCurrency';

const useStyles = makeStyles((theme) => ({
  container: {
    overflowY: 'auto'
  },
  backButton: {
    marginLeft: 0
  },
  addButton: {
    marginRight: '-10px'
  },
  totalWrapper: {
    maxHeight: '75px',
    overflowY: 'auto',
    margin: '8px 0'
  },
}))

const inputs = [
  {
    key: 'name',
    label: 'Name',
    type: 'input',
  },
  {
    key: 'level',
    label: 'Level',
    type: 'input',
    integer: true,
  },
  {
    key: 'regularCredits',
    label: 'Regular Credits',
    type: 'input',
    integer: true,
  },
  {
    key: 'premiumCredits',
    label: 'Premium Credits',
    type: 'input',
    integer: true,
  },
  {
    key: 'eidvCredits',
    label: 'Electronic Identity Verifications Credits',
    type: 'input',
    integer: true
  },
  {
    key: 'price',
    label: 'Price',
    type: 'input',
    currency: true,
  },
]

const defaultModel = {
  name: '',
  level: '',
  regularCredits: '',
  premiumCredits: '',
  eidvCredits: '',
  price: '',
  userId: '',
  userInput: '',
  userInputOverride: '',
}

const schema = yup.object().shape({
  name: yup.string().label('Name').required().max(30),
  level: yup.string().label('Level').required(),
  regularCredits: yup.string().label('Regular Credits').required(),
  premiumCredits: yup.string().label('Premium Credits').required(),
  eidvCredits: yup.string().label('Electronic Identity Verifications Credits').required(),
  price: yup.string().label('Price').required()
    .test('valid_currency', 'Price is invalid', function () {
      const strippedText = revertFormatCurrencyInput(this.parent.price);
      if (isNaN(strippedText)) return false;
      return true;
    }),
  userId: yup.string().label('User'),
});

const debouncedUsers = debounce(clients.searchUsers, 500);

function AddSubscriptions({ isEdit, pageNotFound }) {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [subId] = useState(search.get('subId'));

  const { enqueueSnackbar } = useSnackbar();

  const [model, setModel] = useState(defaultModel);
  const [users, setUsers] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [subLoaded, setSubLoaded] = useState(false);

  const classes = useStyles();

  const [errors, setErrors] = useErrorState({});

  useEffect(() => {
    const getSub = async () => {
      let item;
      let model = {};
      try {
        const res = await subscriptionPlans.getById(subId)
      
        item = res.data;
        if (item.ownedBy === 'SYSTEM') {
          enqueueSnackbar('This subscription is not editable!', { variant: 'error' });
          history.push('/subscription-management/subscription')
        }
        
        model = {
          name: item.name,
          level: item.level,
          regularCredits: item.regularCredits,
          premiumCredits: item.premiumCredits,
          eidvCredits: item.eidvCredits,
          price: formatCurrencyInput(item.price.toString()),
        } 
      } catch (error) {
        pageNotFound();
      }

      if (item?.userId) {
        const user = (await clients.getUserById(item.userId)).data;
        if (user) {
          model.userId = item.userId;
          model.userInput = '';
          model.userInputOverride = `${user.name} ${user.lastName} (${user.email})`
        }
      }

      setModel(model);
      setSubLoaded(true);
    }

    if (isEdit && subId) {
      getSub();
    } else {
      setSubLoaded(true)
    }
  }, [isEdit, subId]) // eslint-disable-line

  useEffect(() => {
    if (!subLoaded) {
      return;
    }
    // Don't debounce it on first load
    const fetchFunction = firstLoad ? clients.searchUsers : debouncedUsers;
    fetchFunction({
      pageNumber: 0,
      pageSize: 40,
      name: model.userInput,
      sortBy: 'name',
    }).then((res) => {
      const users = res.data.entries.map(user => ({
        label: `${user.name} ${user.lastName} (${user.email})`,
        value: user.id,
      }));
      if (firstLoad) {
        if (model.userId && model.userInputOverride) {
          if (!users.find(e => e.value === model.userId)) {
            users.push({
              label: model.userInputOverride,
              value: model.userId,
            })
          }
        }
      }
      setUsers(users);
      setFirstLoad(false);
    })
  }, [model.userInput, subLoaded]) // eslint-disable-line

  const handleChange = (prop) => (event) => {
    const newModel = { ...model, [prop]: event.target.value };

    setModel((model) => ({
      ...model,
      [prop]: event.target.value
    }));

    if (errors[prop]) {
      schema.validateAt(prop, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [prop]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [prop]: err.message });
        });
    }
  };

  const onSave = () => {
    setErrors({});
    schema
      .validate(model, { abortEarly: false })
      .then(function (valid) {
        const data = {
          ...model,
          userInput: undefined,
          price: revertFormatCurrencyInput(model.price),
        }
        
        if (isEdit) {
          subscriptionPlans.update(subId, data).then((res) => {
            enqueueSnackbar(`Subscription "${model.name}" was successfully updated!`, { variant: 'success' });
            history.push('/subscription-management/subscription')
          })
        } else {
          subscriptionPlans.create(data).then((res) => {
            enqueueSnackbar(`Subscription "${model.name}" was successfully created!`, { variant: 'success' });
            history.push('/subscription-management/subscription')
          })
        }
      })
      .catch(function (err) {
        console.log(err)
        setErrors(parseYupErrors(err), true);
      });
  }

  const userField = useMemo(() => {
    return {
      key: 'userId',
      inputKey: 'userInput',
      inputOverrideKey: 'userInputOverride',
      label: 'User',
      type: 'selectSearchable',
      options: users,
    }
  }, [users])

  return (
    <React.Fragment>
      <InnerHeaderControls
        controlsLeft={<BackButton wrapped customButtonClasses={classes.backButton} />}
      />
      <Box className={classes.container}>
        <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center">
          <Box display="flex" my={3} mb={2} alignItems="center" justifyContent="center">
            <Typography variant="h4">{`${isEdit ? 'Edit' : 'Add new'} subscription`}</Typography>
          </Box>
          {inputs.concat(userField).map((field, i) => (
            <Grid container item sm={4} key={`${field.key}-${i}`}>
              <RenderInputs
                ind={i}
                field={field}
                model={model}
                errors={errors}
                handleChange={handleChange}
              />
            </Grid>
          ))}
          <Box display="flex" mt={4} mb={2} alignItems="center" justifyContent="center" width={'100%'}>
            <Button style={{ width: '180px', marginTop: !model['billableTypes'] && '54px' }} variant={'contained'} onClick={onSave}>
              SAVE
            </Button>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default withLoggedNotFound(AddSubscriptions);
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LabelsForRender from '@components/layout/labels-for-render';
import { useSelector } from 'react-redux';

export const useStyles = makeStyles((theme) => ({
  wrapperLabels: {
    marginBottom: '2px',
    alignItems: 'flex-start'
  },
}));

export default function UserInfoCardProfileInfo() {
  const classes = useStyles();

  const { selectedProfile } = useSelector(store => store.backgroundCheck);

  return (
    <LabelsForRender
      labels={selectedProfile.meta || []}
      anchorProps={{
        vertical: 'bottom',
        horizontal: -50,
      }}
      propsWrapper={classes.wrapperLabels}
    />
  )
}



import React, { useEffect, useMemo, useState } from 'react';

import {
  Box, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

import { ChevronLeftIcon } from '@app/icons';
import { clientDocumentCategory, clientDocumentType } from '@app/api/client';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import CategoryDeleteDialog from './category-delete-dialog';
import CategoryCard from './category-card';
import CategoryTypeCard from './category-type-card';
import CategoryCreateDialog from './category-create-dialog';
import CategoryTypeCreateDialog from './category-type-create-dialog';
import CategoryTypeDeleteDialog from './category-type-delete-dialog';

const useStyles = makeStyles(theme => ({
  typeWrapper: {
    width: 'calc(100% - 32px)',
    backgroundColor: '#F8F8F8',
    borderRadius: '12px',
    padding: 16,
  },
}))


export default function CategoryWrapper(props) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    rulebookCategories, categories, setCategories,
    selectedCategory, setSelectedCategory,
    documentTypes,
    setPendingDocumentDialogShown, setPendingDocumentFile,
    setPendingDocumentCategory,
    fetchCategories,
    readonly, data
  } = props;

  const classes = useStyles()

  const [pendingCategoryDelete, setPendingCategoryDelete] = useState(null);
  const [pendingCategoryEdit, setPendingCategoryEdit] = useState(null);
  const [createCategoryOpen, setCreateCategoryOpen] = useState(false);

  const [pendingCategoryTypeDelete, setPendingCategoryTypeDelete] = useState(null);
  const [pendingCategoryTypeEdit, setPendingCategoryTypeEdit] = useState(null);
  const [createCategoryTypeOpen, setCreateCategoryTypeOpen] = useState(false);

  const categoriesByRulebook = useMemo(() => {
    const categoriesIndexed = _.keyBy(categories, 'id')

    rulebookCategories.forEach(rbCategory => {
      const category = categoriesIndexed[rbCategory.categoryId]

      const typesIndexed = _.keyBy(category.types, 'id')
      rbCategory.types.forEach(rbType => {
        const type = typesIndexed[rbType.typeId]
        if (type) {
          typesIndexed[rbType.typeId] = {
            ...type,
            expected: !!rbType?.expected || false,
            includedByRulebook: true
          }
        }
      })

      categoriesIndexed[rbCategory.categoryId] = {
        ...category,
        rule: rbCategory.categoryRuleType,
        includedByRulebook: true,
        types: Object.values(typesIndexed)
      }
    })

    const filteredCategories = Object.values(categoriesIndexed)
      .filter(category => !!category.includedByRulebook || category.ownedBy === 'USER')
      .filter(category => category.usageTypes.includes(data.client_type))
      .map(category => ({
        ...category,
        expectedCount: (category.types || []).reduce((acc, type) =>
          !!type.expected ? ++acc : acc,
        0
        ),
        expected: (category.types || []).some(type => !!type.expected) || category.rule === 'ONE_OF',
        types: (category.types || [])
          .filter(type => !!type.includedByRulebook || category.isCustom || type.ownedBy === 'USER')
          .filter(type => type.usageTypes.includes(data.client_type))
      }))

    return filteredCategories.sort(
      (category_1, category_2) => {
        if (category_1.ownedBy === category_2.ownedBy) {
          return category_1.name === 'Other'
            ? 1 : category_1.name.localeCompare(category_2.name, 'en', { sensitivity: 'base', numeric: true })
        }
        return category_2.ownedBy === 'SYSTEM' ? 1 : -1
      }
    )

  }, [categories, rulebookCategories, data])

  useEffect(() => {
    if (selectedCategory) {
      const category = categoriesByRulebook.find(e => e.id === selectedCategory.id)
      setSelectedCategory(category)
    }
  }, [categoriesByRulebook]) // eslint-disable-line

  const onCategoryDeleteConfirm = async () => {
    await clientDocumentCategory.remove(pendingCategoryDelete.id)
    setCategories([
      ...categories.filter(c => c.id !== pendingCategoryDelete.id)
    ])
    enqueueSnackbar(`Category "${pendingCategoryDelete.name}" was successfully deleted!`, { variant: 'success' });
    setPendingCategoryDelete(null)
  }

  const onCategoryTypeDeleteConfirm = async () => {
    // Unlink
    if (pendingCategoryTypeDelete.categoryIds.length > 1) {
      await clientDocumentType.update(pendingCategoryTypeDelete.id, {
        ...pendingCategoryTypeDelete,
        categoryIds: pendingCategoryTypeDelete.categoryIds.filter(e => e !== selectedCategory.id)
      })
    } else { // Otherwise delete
      await clientDocumentType.remove(pendingCategoryTypeDelete.id)
    }
    enqueueSnackbar(`Document "${pendingCategoryTypeDelete.name}" was successfully deleted!`, { variant: 'success' });
    fetchCategories();
    setPendingCategoryTypeDelete(null)
  }

  const onDrop = type => files => {
    const file = files[0]
    onStartDocumentCreateFlow(type, file)
  }

  const onCategoryTypeClick = type => () => {
    onStartDocumentCreateFlow(type)
  }

  const onStartDocumentCreateFlow = (type, file) => {
    if (!!file) {
      setPendingDocumentFile(file)
    }
    if (!!type) {
      setPendingDocumentCategory({ ...selectedCategory, selectedType: type })
    }
    setPendingDocumentDialogShown('new')
  }

  const onCategorySave = async (data) => {
    // Update flow
    if (pendingCategoryEdit) {
      clientDocumentCategory.update(pendingCategoryEdit.id, data).then(() => {
        enqueueSnackbar(`Category "${data.name}" was successfully updated!`, { variant: 'success' });
        fetchCategories();
      })
    } else { // Create flow
      clientDocumentCategory.store(data).then(() => {
        enqueueSnackbar(`Category "${data.name}" was successfully created!`, { variant: 'success' });
        fetchCategories();
      })
    }
    setPendingCategoryEdit(false);
    setCreateCategoryOpen(false);
  }

  const onCategoryTypeSave = async (data) => {
    // Update flow
    const parsedData = {
      ...data,
      id: undefined,
      isSystem: undefined
    }
    if (data.id && data.id !== 'custom') {
      const updateFunc = data.isSystem ? clientDocumentType.updateSystem : clientDocumentType.update;
      updateFunc(data.id, parsedData).then(async (res) => {
        enqueueSnackbar(`Document "${parsedData.name}" was successfully updated!`, { variant: 'success' });
        fetchCategories();
      })
    } else { // Create flow
      clientDocumentType.store(parsedData).then(async (res) => {
        enqueueSnackbar(`Document "${parsedData.name}" was successfully created!`, { variant: 'success' });
        fetchCategories();
      })
    }
    setPendingCategoryTypeEdit(false);
    setCreateCategoryTypeOpen(false);
  }

  return (
    <Box>
      {!selectedCategory &&
        <Box>
          <Box height={32} display="flex" alignItems="center">
            <Typography variant="h4">KYC Documents</Typography>
          </Box>
          <Box
            display={'flex'}
            flexWrap={'wrap'}
            mt={3}
          >
            {categoriesByRulebook.map(category => (
              <CategoryCard
                key={`card-${category.id}`}
                category={category}
                onDelete={() => setPendingCategoryDelete(category)}
                onEdit={() => setPendingCategoryEdit(category)}
                onClick={() => setSelectedCategory(category)}
                readonly={readonly}
              />
            ))}
            {!readonly &&
              <CategoryCard
                onClick={() => setCreateCategoryOpen(true)}
              />
            }
          </Box>
        </Box>
      }
      {selectedCategory &&
        <Box>
          <Box height={32} display="flex" alignItems="center">
            <ButtonWithIcon
              startIcon={<ChevronLeftIcon />}
              onClick={() => setSelectedCategory(null)}
            >
              <Typography variant="h4">{selectedCategory.name}</Typography>
            </ButtonWithIcon>
          </Box>
          <Box
            display={'flex'}
            flexWrap={'wrap'}
            mt={3}
            className={classes.typeWrapper}
          >
            <Box width="100%" py={1}>
              <Typography variant="subtitle1" align="center" style={{ color: '#484848' }}>To upload documents click on the category icon or drop files over the belonging document type</Typography>
            </Box>
            {selectedCategory.types.map(categoryType => (
              <CategoryTypeCard
                key={`type-card-${categoryType.id}`}
                categoryType={categoryType}
                onDrop={onDrop(categoryType)}
                onClick={onCategoryTypeClick(categoryType)}
                onDelete={() => setPendingCategoryTypeDelete(categoryType)}
                onEdit={() => setPendingCategoryTypeEdit(categoryType)}
                isCustom={categoryType.ownedBy === 'USER'}
                readonly={readonly}
              />
            ))}
            {!readonly &&
              <CategoryTypeCard
                onClick={() => setCreateCategoryTypeOpen(true)}
              />
            }
          </Box>
        </Box>
      }
      <CategoryDeleteDialog
        open={!!pendingCategoryDelete}
        categoryName={pendingCategoryDelete?.name}
        onClose={() => setPendingCategoryDelete(null)}
        onConfirm={onCategoryDeleteConfirm}
      />
      <CategoryCreateDialog
        open={createCategoryOpen || !!pendingCategoryEdit}
        category={pendingCategoryEdit}
        onClose={() => {
          setPendingCategoryEdit(null);
          setCreateCategoryOpen(false);
        }}
        onSave={onCategorySave}
        clientType={data?.client_type}
      />
      <CategoryTypeDeleteDialog
        open={!!pendingCategoryTypeDelete}
        typeName={pendingCategoryTypeDelete?.name}
        onClose={() => setPendingCategoryTypeDelete(null)}
        onConfirm={onCategoryTypeDeleteConfirm}
      />
      <CategoryTypeCreateDialog
        open={createCategoryTypeOpen || !!pendingCategoryTypeEdit}
        categoryType={pendingCategoryTypeEdit}
        selectedCategory={selectedCategory}
        categories={categoriesByRulebook}
        types={documentTypes}
        data={data}
        onClose={() => {
          setPendingCategoryTypeEdit(null);
          setCreateCategoryTypeOpen(false);
        }}
        onSave={onCategoryTypeSave}
      />
    </Box>
  )
}
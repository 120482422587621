export const generatePassword = () => {
  let pass = '';
  const letter = 'abcdefghijklmnopqrstuvwxyz';
  const upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const number = '0123456789';
  const symbol = '!@#$%^&*()_+~|}{[]:;?></-=';
    
  for (let i = 1; i <= 2; i++) {
    pass = pass + letter.charAt(Math.floor(Math.random() * letter.length));
    pass = pass + upper.charAt(Math.floor(Math.random() * upper.length));
    pass = pass + number.charAt(Math.floor(Math.random() * number.length));
    pass = pass + symbol.charAt(Math.floor(Math.random() * symbol.length));
  }

  return pass;
}
import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PageLayout from '@components/layout/page-layout';
import InnerHeader from '@components/layout/inner-header';
import ButtonWithIcon from '@components/buttons/button-with-icon'; // eslint-disable-line
import { DownloadIcon } from '@app/icons'; // eslint-disable-line

import history from '@app/history';
import { Route, Switch, Redirect } from 'react-router';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Searches from './searches';
import Overview from './overview';
import Eidv from './eidv';
import PortfolioDashboard from './portfolio';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 'calc(100% - 105px)',
    overflowY: 'auto',
    padding: '2px 16px',
  }
}));

const tabs = [
  {
    id: 'overview',
    label: 'Overview',
  },
  {
    id: 'searches',
    label: 'Searches',
  },
  {
    id: 'electronic-verification',
    label: 'Electronic Verifications',
  },
  {
    id: 'portfolio',
    label: 'Portfolio',
  },
]

export default function Dashboard(props) {
  const classes = useStyles(); // eslint-disable-line

  const { userIsAdmin, side } = useSelector(state => state.auth);

  const { tab } = useParams();
  const [tabID, setTabID] = useState(tab || 'overview');

  const onTabChange = (index) => {
    const tabID = tabs[index].id;
    setTabID(tabID);
    history.push(`/dashboard/${tabID}`)
  }

  return (
    <PageLayout>
      <Box mx={6} height="100%">
        <InnerHeader
          onTabChange={onTabChange}
          ind={tabs.findIndex(tab => tab.id === tabID)}
          tabs={tabs}
          // buttons={tabID === 'searches' && (
          //   <ButtonWithIcon
          //     startIcon={<DownloadIcon />}
          //     onClick={() => {}}
          //   >
          //     Export
          //   </ButtonWithIcon>
          // )}
        />
        <Box display={'flex'} flexDirection={'column'} height={'calc(100% - 40px)'}>
          <Switch>
            <Route exact path="/dashboard/overview">
              <Overview userIsAdmin={userIsAdmin && side === 'client'} />
            </Route>
            <Route exact path="/dashboard/searches">
              <Searches userIsAdmin={userIsAdmin && side === 'client'} />
            </Route>
            <Route exact path="/dashboard/electronic-verification">
              <Eidv userIsAdmin={userIsAdmin && side === 'client'} />
            </Route>
            <Route exact path="/dashboard/portfolio">
              <PortfolioDashboard userIsAdmin={userIsAdmin && side === 'client'} />
            </Route>

            <Redirect to={`/dashboard/${tabID}`} />
          </Switch>
        </Box>
      </Box>
    </PageLayout>
  )
}
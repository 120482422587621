import React from 'react';
import { Modal } from '@components/modals';


export default function DocumentDeleteDialog({ isOldVersion, documentName, open, onClose, onConfirm }) {

  const title = 'Delete Document'

  const description = `Are you sure you want to delete document "${documentName}"?`;
  const descriptionOldVersion = 'Are you sure you want to delete the selected document version?'

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title={title}
        mainText={isOldVersion ? descriptionOldVersion : description}
        actions={
          [
            {
              type: 'main',
              label: 'NO',
              action: onClose,
              style: { width: 160 },
            },
            {
              type: 'secondary',
              label: 'YES',
              action: onConfirm,
              style: { width: 160 },
            },
          ]
        }
        actionsDirection="row"
      />
    </React.Fragment>
  );
}
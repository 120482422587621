const config = {
  MuiTable: {
    root: {
      marginTop: -8,
      borderCollapse: 'separate',
      borderSpacing: '0 8px'
    }
  },
  MuiTableCell: {
    root: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      border: 'none',
      borderBottom: 'none',
      paddingTop: 0,
      paddingBottom: 0,
      '&:first-child': {
        paddingLeft: 16,
        borderTopLeftRadius: 12,
        borderBottomLeftRadius: 12,
      },
      '&:last-child': {
        paddingRight: 16,
        borderTopRightRadius: 12,
        borderBottomRightRadius: 12,
      },
      '&.bolded': {
        fontWeight: 500,
        color: '#232323'
      }
    },
    head: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: '#232323'
    },
    body: {
      fontWeight: 'normal',
      textTransform: 'none',
      color: '#656565',
      alignItems: 'center',
    },
    stickyHeader: {
      backgroundColor: '#FFFFFF',
    }
  },
  MuiTableRow: {
    root: {
      height: 36,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#F8F8F8',
      },
    },
    head: {
      '&:hover': {
        backgroundColor: 'transparent',
      }
    }
  },
}

export default config
import React from 'react';
import {
  MyPortfolioActiveIcon,
  MyPortfolioInactiveIcon,
  BackgroundCheckActiveIcon,
  BackgroundCheckInactiveIcon,
  SearchHistoryActiveIcon,
  SearchHistoryInactiveIcon,
  ContinuousScanningActiveIcon,
  ContinuousScanningInactiveIcon,
  CalendarSheduleActiveIcon,
  CalendarSheduleInactiveIcon,
  MyTeamActiveIcon,
  MyTeamInactiveIcon,
  FolderUpdatedActive,
  FolderUpdatedInactive,
  EidvMenuItemActive,
  EidvMenuItemInactive,
} from '@app/icons';

const items = [
  {
    uri: '/calendar-schedule',
    icons: {
      active: <CalendarSheduleActiveIcon />,
      inactive: <CalendarSheduleInactiveIcon />,
    },
    text: 'Schedule',
  },
  {
    uri: '/portfolio',
    icons: {
      active: <MyPortfolioActiveIcon />,
      inactive: <MyPortfolioInactiveIcon />,
    },
    text: 'My Portfolio',
  },
  {
    uri: '/background-check',
    icons: {
      active: <BackgroundCheckActiveIcon />,
      inactive: <BackgroundCheckInactiveIcon />,
    },
    text: 'Background Check',
  },
  {
    uri: '/search-history',
    icons: {
      active: <SearchHistoryActiveIcon />,
      inactive: <SearchHistoryInactiveIcon />,
    },
    text: 'Search History',
  },
  {
    uri: '/ongoing-monitoring',
    icons: {
      active: <ContinuousScanningActiveIcon />,
      inactive: <ContinuousScanningInactiveIcon />,
    },
    text: 'Ongoing Monitoring',
  },
  {
    uri: '/electronic-verification',
    icons: { active: <EidvMenuItemActive />, inactive: <EidvMenuItemInactive /> },
    text: 'Electronic Identity Verification',
  },
  {
    uri: '/documents',
    icons: { active: <FolderUpdatedActive />, inactive: <FolderUpdatedInactive /> },
    text: 'My Documents',
  },
];

const clientDebugItems = [
  {
    uri: '/calendar-schedule',
    icons: {
      active: <CalendarSheduleActiveIcon />,
      inactive: <CalendarSheduleInactiveIcon />,
    },
    text: 'Schedule',
  },
  {
    uri: '/portfolio',
    icons: {
      active: <MyPortfolioActiveIcon />,
      inactive: <MyPortfolioInactiveIcon />,
    },
    text: 'My Portfolio',
  },
  {
    uri: '/background-check',
    icons: {
      active: <BackgroundCheckActiveIcon />,
      inactive: <BackgroundCheckInactiveIcon />,
    },
    text: 'Background Check',
  },
  {
    uri: '/search-history',
    icons: {
      active: <SearchHistoryActiveIcon />,
      inactive: <SearchHistoryInactiveIcon />,
    },
    text: 'Search History',
  },
  {
    uri: '/ongoing-monitoring',
    icons: {
      active: <ContinuousScanningActiveIcon />,
      inactive: <ContinuousScanningInactiveIcon />,
    },
    text: 'Ongoing Monitoring',
  },
  {
    uri: '/electronic-verification',
    icons: { active: <EidvMenuItemActive />, inactive: <EidvMenuItemInactive /> },
    text: 'Electronic Identity Verification',
  },
  {
    uri: '/documents',
    icons: { active: <FolderUpdatedActive />, inactive: <FolderUpdatedInactive /> },
    text: 'My Documents',
  },
  {
    uri: '/team',
    icons: {
      active: <MyTeamActiveIcon />,
      inactive: <MyTeamInactiveIcon />,
    },
    text: 'My Team',
  },
];

const resolveClientMenu = (debug) => (debug ? clientDebugItems : items);

export default resolveClientMenu;

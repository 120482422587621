import React, { useRef } from 'react';
import { Box, Container, Grid, Typography, makeStyles } from '@material-ui/core';
import LayoutHeader from '@components/layout/header';
import { useSelector } from 'react-redux';

import {
  BASE_SANCTIONS,
  PEP,
  PREMIUM_SANCTIONS,
  WARNINGS,
  FITNESS_PROBITY,
  PEP_CLASS_1,
  PEP_CLASS_2,
  PEP_CLASS_3,
  PEP_CLASS_4,
  RCAS,
  ADVERCE_MEDIA
} from './dummy';

export const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    textTransform: 'uppercase',
    color: '#232323',
  },
  wrapperList: {
    columns: '3 auto',
  },
  values: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#656565',
    marginTop: '10px'
  },
}));

export default function CASourcesList() {
  const isGuest = useSelector(state => !state.auth.user);
  const classes = useStyles();

  const scrollArea = useRef(null);

  return (
    <Box ref={scrollArea} pt={'64px'} maxHeight="calc(100% - 64px)" id="landing-wrapper">
      <LayoutHeader scrollRef={scrollArea.current} isMenu={!isGuest} />
      <Box className={'position-relative'} display={'flex'}>
        <Box className={'overlay overlay-1'}/>
        <Container>
          <Box height={'360px'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignContent={'center'}>
            <Box px={'2rem'}>
              <Typography variant="h1" align="center">
                List of search sources
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>

      <Box className={'position-relative'} display={'flex'} flexDirection={'column'} alignContent={'center'} py={9} mx={'50px'}>
        <Box px={'2rem'}>
          <Typography variant="h2" align="center">
            Basic Plans
          </Typography>
        </Box>
        <Box mt={'26px'} display={'flex'} justifyContent={'center'} flexWrap={'wrap'}>
          <Typography variant="subtitle1" align="center" style={{ color: '#656565' }}>
            Our basic plans will run background check searches on all the sanctions list and limited PEP sources. For example, only high-level politicians will be found in this dataset.<br/>
            Sources searched within basic plans are as follows:
          </Typography>
        </Box>
        <Box display={'flex'} flexDirection={'column'} my={5} mx={14}>
          <Typography className={classes.subtitle} spacing={4}>
            SANCTIONS ({BASE_SANCTIONS.length})
          </Typography>
          <Grid container>
            {BASE_SANCTIONS.map((el, i) => (
              <Grid item sm={4} key={`${el}-${i}`}>
                <Typography className={classes.values}>{el}</Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box display={'flex'} flexDirection={'column'} my={5} mx={14}>
          <Typography className={classes.subtitle} spacing={4}>
            PEP ({PEP.length})
          </Typography>
          <Grid container>
            {PEP.map((el, i) => (
              <Grid item sm={4} key={`${el}-${i}`}>
                <Typography className={classes.values}>{el}</Typography>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box px={'2rem'} mt={5}>
          <Typography variant="h2" align="center">
            Premium Plans
          </Typography>
        </Box>
        <Box mt={'26px'} display={'flex'} justifyContent={'center'} flexWrap={'wrap'}>
          <Typography variant="subtitle1" align="start" style={{ color: '#656565' }}>
            Our premium plans allow users to search a comprehensive dataset of more than 1300 sources, including Sanctions, 4 PEP classes and Adverse media.<br/>
            Our premium dataset is powered by Comply Advantage, the most modern dataset provider in the space, offering the only near real-time AML Solution.<br/> 
            Comply Advantage is the first company to have built the most dynamic global database of Global Sanctions, Global Watchlists & Global Fitness & Probity lists,<br/>
            Global PEPs, and Global Adverse Media, using automated processes such as AI, ML & NLP to provide updates in near real-time.<br/>
            Sources searched within premium plans are as follows:
          </Typography>
        </Box>
        <Box display={'flex'} flexDirection={'column'} my={5} mx={14}>
          <Typography className={classes.subtitle}>
            ADVERSE MEDIA
          </Typography>
          <Grid container>
            {ADVERCE_MEDIA.map((el, i) => (
              <Box width={'100%'} key={`${el}-${i}`}>
                <Typography className={classes.values}>{el}</Typography>
              </Box>
            ))}
          </Grid>
        </Box>
        <Box display={'flex'} flexDirection={'column'} mt={5} mb={3} mx={14}>
          <Box mb={2}>
            <Typography className={classes.subtitle}>
              Politically Exposed Persons (PEP)
            </Typography>
          </Box>
          <Typography className={classes.subtitle}>
            PEP CLASS 1 ({PEP_CLASS_1.length})
          </Typography>
          <Grid container>
            {PEP_CLASS_1.map((el, i) => (
              <Grid item sm={4} key={`${el}-${i}`}>
                <Box width={'90%'}><Typography className={classes.values}>{el}</Typography></Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box display={'flex'} flexDirection={'column'} mb={3} mx={14}>
          <Typography className={classes.subtitle}>
            PEP CLASS 2 ({PEP_CLASS_2.length})
          </Typography>
          <Grid container>
            {PEP_CLASS_2.map((el, i) => (
              <Grid item sm={4} key={`${el}-${i}`}>
                <Box width={'90%'}><Typography className={classes.values}>{el}</Typography></Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box display={'flex'} flexDirection={'column'} mb={3} mx={14}>
          <Typography className={classes.subtitle}>
            PEP CLASS 3 ({PEP_CLASS_3.length})
          </Typography>
          <Grid container>
            {PEP_CLASS_3.map((el, i) => (
              <Grid item sm={4} key={`${el}-${i}`}>
                <Box width={'90%'}><Typography className={classes.values}>{el}</Typography></Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box display={'flex'} flexDirection={'column'} mb={3} mx={14}>
          <Typography className={classes.subtitle}>
            PEP CLASS 4 ({PEP_CLASS_4.length})
          </Typography>
          <Grid container>
            {PEP_CLASS_4.map((el, i) => (
              <Grid item sm={4} key={`${el}-${i}`}>
                <Box width={'90%'}><Typography className={classes.values}>{el}</Typography></Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box display={'flex'} flexDirection={'column'} mb={5} mx={14}>
          <Typography className={classes.subtitle}>
            PEP Relatives and Close Associates (RCAs) ({RCAS.length})
          </Typography>
          <Grid container>
            {RCAS.map((el, i) => (
              <Grid item sm={4} key={`${el}-${i}`}>
                <Box width={'90%'}><Typography className={classes.values}>{el}</Typography></Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box display={'flex'} flexDirection={'column'} my={5} mx={14}>
          <Typography className={classes.subtitle}>
            SANCTIONS ({PREMIUM_SANCTIONS.length})
          </Typography>
          <Grid container>
            {PREMIUM_SANCTIONS.map((el, i) => (
              <Grid item sm={4} key={`${el}-${i}`}>
                <Box width={'90%'}><Typography className={classes.values}>{el}</Typography></Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box display={'flex'} flexDirection={'column'} mt={5} mx={14}>
          <Typography className={classes.subtitle}>
            FITNESS AND PROBITY ({FITNESS_PROBITY.length})
          </Typography>
          <Grid container>
            {FITNESS_PROBITY.map((el, i) => (
              <Grid item sm={4} key={`${el}-${i}`}>
                <Box width={'90%'}><Typography className={classes.values}>{el}</Typography></Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box display={'flex'} flexDirection={'column'} my={5} mx={14}>
          <Typography className={classes.subtitle}>
            WARNINGS AND REGULATORY ENFORCEMENT ({WARNINGS.length})
          </Typography>
          <Grid container>
            {WARNINGS.map((el, i) => (
              <Grid item sm={4} key={`${el}-${i}`}>
                <Box width={'90%'}><Typography className={classes.values}>{el}</Typography></Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}
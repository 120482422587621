import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Box, Grid, RadioGroup, FormControlLabel, Radio, Switch } from '@material-ui/core';
import TableList from '@components/lists/table-list';
import OutlinedSearch from '@components/inputs/outlined-search';
import InlineHeadBadge from '@components/badges/inline-head-badge';
import FilterWithContext from '@components/dropdowns/filter-with-context';
import history from '@app/history';
import { dummy } from '../../dummy';

const TABLE_COLUMNS = [
  { field: 'email', headerName: 'Sender', bold: true, useTooltip: true },
  { field: 'module ', headerName: 'Module ' },
  { field: 'subject', headerName: 'Subject', useTooltip: true },
  { field: 'receiver', headerName: 'Receiver', useTooltip: true },
  { field: 'updatedOn', headerName: 'Updated On' },
  { field: 'emailStatus', headerName: 'Email Status' },
  { field: 'attachments', headerName: 'Attachments', useTooltip: true },
  { field: 'failedCounter', headerName: 'Failed Counter' },
]

const radioGroup = [
  { value: 'updatedOn', label: 'Sort by Updated On' },
  { value: 'email', label: 'Sort by Sender' },
  { value: 'receiver', label: 'Sort by Receiver' },
  { value: 'subject', label: 'Sort by Subject' },
  { value: 'module', label: 'Sort by Module' },
]

const switchGroup = [
  { value: 'sulie', label: 'Sign Up and Log In Emails', isChecked: true },
  { value: 'dne', label: 'Document Notifications Emails', isChecked: true },
  { value: 'she', label: 'Sharing Emails', isChecked: true },
  { value: 'shae', label: 'Subscriptions and Account Emails', isChecked: true },
  { value: 'be', label: 'Billing Emails', isChecked: true },
  { value: 'ess', label: 'Sent Emails', isChecked: true },
  { value: 'esp', label: 'Pending Emails', isChecked: true },
  { value: 'esf', label: 'Failed Emails', isChecked: true },
]

export default function Emails({ setEmails }) {
  const [data, setData] = useState(dummy.emails);
  const [search, setSearch] = useState();
  const [sort, setSort] = useState('updatedOn');
  const [filters, setFilters] = useState(switchGroup);
  // eslint-disable-next-line
  const [page, setPage] = useState(0);

  useEffect(() => {
    setEmails(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const onSearchChange = (event) => {
    setSearch(event.target.value);
    // setPage(0);
  }

  const onSelect = (id) => {
    const newData = data.map(
      (item) => item.id === id
        ? ({ ...item, isChecked: !item.isChecked })
        : item
    );
    setData(newData);
  }

  const onFilterChange = (event) => {
    const changed = filters.map(el =>
      el.value === event.target.value ? ({ ...el, isChecked: event.target.checked }) : el
    );
    setFilters(changed);
  }

  const handleView = useCallback((id, item) => {
    history.push(`/communication/view-email/?subject=${item.subject}`);
  }, [])

  const formattedData = useMemo(() => {
    return data.map(e => {
      const item = { ...e };
      
      switch (item.emailStatus) {
        case 'Sent':
          item.threeDotsActions = ['View'];
          break;

        case 'Pending':
          item.threeDotsActions = ['View', 'Cancel'];
          item.cellProps = {
            emailStatus: {
              style: {
                color: '#A98320'
              }
            }
          }
          break;

        case 'Failed':
          item.threeDotsActions = ['View', 'Resend'];
          item.cellProps = {
            emailStatus: {
              style: {
                color: '#B6514C'
              }
            }
          }
          break;
        
        default:
          break;
      }

      switch (item.isRead) {
        case 'Yes':
          item.threeDotsActions = ['Cancel'];
          break;
        
        case 'No':
          item.threeDotsActions = ['Cancel', 'Resend'];
          break;
        
        default:
          break;
      }

      return item;
    })
  }, [data])

  return (
    <Box display={'flex'} flexGrow={'1'} mt={2} flexDirection={'column'}>
      <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'}>
          <Box mr={1}>
            <InlineHeadBadge
              badgeColor={'#CAD7CA'}
              badgeValue={8}
            >
              Emails
            </InlineHeadBadge>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <FilterWithContext>
            <Grid container spacing={6}>
              <Grid item xs={5}>
                <RadioGroup name="sort" value={sort} onChange={e => setSort(e.target.value)}>
                  {radioGroup.map((el, i) => (
                    <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value} - ${i}`}>
                      <FormControlLabel
                        control={<Radio />}
                        value={el.value}
                        label={el.label}
                      />
                    </Box>
                  ))}
                </RadioGroup>
              </Grid>
              <Grid item xs={5}>
                {filters.map((el, i) => (
                  <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value} - ${i}`}>
                    <FormControlLabel
                      control={<Switch value={el.value} checked={el.isChecked} onClick={onFilterChange} />}
                      label={el.label}
                    />
                  </Box>
                ))}
              </Grid>
            </Grid>
          </FilterWithContext>
        </Box>
      </Box>
      <Box display={'flex'} mb={3}>
        <OutlinedSearch
          value={search}
          onChange={onSearchChange}
          label="Search Emails"
        />
      </Box>
      <Grid container>
        <Grid item sm={12}>
          <TableList
            columns={TABLE_COLUMNS}
            items={formattedData}
            onSelect={onSelect}
            page={page}
            pageSize={12}
            pagesCount={1}
            totalCount={8}
            // onPageChange={onPageChange}
            sortableColumns={TABLE_COLUMNS.map(el => el.field)}
            // onSort={onSort}
            // sort={sort}
            // sortDirection={sortDirection}
            maxHeight="calc(100vh - 380px)"
            onClick={handleView}
            threeDotsActions={[
              { label: 'View', handler: handleView },
              { label: 'Cancel', handler: () => {} },
              { label: 'Resend', handler: () => {} },
            ]}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

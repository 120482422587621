import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

const selectIsGuest = state => !state.auth.user;
const selectLastPage = state => state.auth.lastVisitedPage;

export default function PublicRoute(props){
  const isGuest = useSelector(selectIsGuest);
  const lastVisitedPage = useSelector(selectLastPage);
  const userIsAdmin = useSelector(state => state.auth.userIsAdmin);
  const Component = props.component;

  const path = useMemo(() => {
    if (lastVisitedPage) {
      return [lastVisitedPage?.pathname, lastVisitedPage?.hash, lastVisitedPage?.search].join('')
    }

    return userIsAdmin ? '/dashboard' : '/background-check';
  }, [userIsAdmin, lastVisitedPage]);

  // Do it this way to avoid rendering the component
  // and reduce needless api calls
  if (!isGuest) {
    return <Redirect to={path} />
  } else {
    return (
      <Component {...props} />
    )
  }
}

import SearchHistory from '@views/protected/client/search-history';
import Team from '@views/protected/client/team';
import OngoingMonitoring from '@views/protected/client/ongoing-monitoring/ongoing-monitoring-route';
import GlobalSearch from '@views/protected/client/global-search';
import ElectronicIdentityVerification from '@views/protected/client/eidv';

// Grouped routes
import calendarSchedule from './calendar-schedule';
import portfolio from './portfolio';
import backgroundCheck from './background-check';
import myProfile from './my-profile';
import settings from './settings';
import documents from './documents';
import Maintenance from '@views/public/maintenance';

const routes = [
  ...calendarSchedule,
  ...portfolio,
  ...documents,
  ...myProfile,
  ...backgroundCheck,
  {
    path: '/search-history',
    component: SearchHistory,
    guest: false,
  },
  {
    path: '/ongoing-monitoring',
    component: OngoingMonitoring,
    guest: false,
  },
  {
    path: '/maintenance',
    component: Maintenance,
    guest: false,
  },
  {
    path: '/electronic-verification',
    component: ElectronicIdentityVerification,
    guest: false,
  },
  {
    path: '/team',
    component: Team,
    guest: false,
  },
  {
    path: '/global-search',
    component: GlobalSearch,
    guest: false,
  },
  ...settings,
];

export default routes;

import React, {useEffect} from 'react';

import { TreeView, TreeItem } from '@material-ui/lab';
import { Box } from '@material-ui/core';

import InlineBadge from '@components/badges/inline-badge';
import { FolderSmallBlankIcon, FolderSmallRootIcon, AddActive2Icon } from '@app/icons';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import FolderIconWithColor from './folder-icon-with-color';
import CustomTooltip from '@components/controls/tooltip';
import {makeStyles} from '@material-ui/core/styles';

// Items example
//
// prop.items = [
//     {
//         id: '1',
//         label:'Root Folder',
//         children: [
//             {
//                 id: '2',
//                 label: 'Folder 1.1',
//                 badgeType: 'primary',
//                 badgeValue: 5
//             }
//         ]
//     }
// ]

const useStyles = makeStyles((theme) => ({
  treeItemLabel: {
    textAlign: 'left',
    wordBreak: 'break-word'
  },
  treeRoot: {
    overflowY: 'auto',
    paddingTop: '16px'
  },
  addButton: {
    padding: 0,
    minWidth: 0,
    '&.MuiButton-text': {
      '&:hover': {
        background: '#C6C6C6',
      }
    }
  }
}))

export default function FoldersTreeView(props) {
  // eslint-disable-next-line
    const { items, selected, onNewFolder } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState([]);
  const [isShow, setIsShow] = React.useState(false);

  useEffect(() => {
    setExpanded([
      ...items.length? [String(items[0].id)]: []
    ])
  }, [items])

  const onSelect = (event, id) => {
    props.onSelect(parseInt(id))
    event.preventDefault()
  }

  const handleAddFolder = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onNewFolder();
  }

  return (
    <TreeView
      defaultCollapseIcon={<FolderSmallBlankIcon/>}
      defaultExpandIcon={<FolderSmallBlankIcon/>}
      defaultEndIcon={<FolderSmallBlankIcon />}
      expanded={expanded}
      onNodeSelect={onSelect}
      selected={ selected && String(selected)}
      classes={{ root: classes.treeRoot }}
    >
      {items.map(item =>
        <TreeItem
          key={item.id}
          nodeId={String(item.id)}
          collapseIcon={<FolderSmallRootIcon/>}
          expandIcon={<FolderSmallRootIcon/>}
          endIcon={<FolderSmallRootIcon />}
          classes={{label: classes.treeItemLabel}}
          label={
            <React.Fragment>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width={'100%'}
                onMouseEnter={() => setIsShow(true)}
                onMouseLeave={() => setIsShow(false)}
              >
                {item.name}
                {isShow && (
                  <CustomTooltip
                    title="New Folder"
                    placement="top"
                  >
                    <span>
                      <ButtonWithIcon className={classes.addButton} onClick={handleAddFolder}>
                        <AddActive2Icon />
                      </ButtonWithIcon>
                    </span>
                  </CustomTooltip>
                )}
              </Box>
              {item.badgeValue &&
                                <InlineBadge color={item.badgeColor}>
                                  {item.badgeValue}
                                </InlineBadge>
              }
            </React.Fragment>

          }>
          {item.children && item.children.map(childItem =>
            <TreeItem
              key={childItem.id}
              nodeId={String(childItem.id)}
              endIcon={
                <FolderIconWithColor
                  data={childItem}
                  onDelete={props.onDelete}
                  onUpdate={props.onUpdate}
                />}
              classes={{label: classes.treeItemLabel}}
              label={
                <React.Fragment>
                  {childItem.name}
                  {childItem.badgeValue &&
                                    <InlineBadge color={childItem.badgeColor}>
                                      {childItem.badgeValue}
                                    </InlineBadge>
                  }
                </React.Fragment>
              }>
            </TreeItem>
          )}
        </TreeItem>
      )}
    </TreeView>
  )
}
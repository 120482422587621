import React, { useState } from 'react';
import { Route, Switch } from 'react-router';
import history from '@app/history';

import CreatePersonDetails from './step1-details-person';
import CreateCompanyDetails from './step1-details-company';
import CreateChooseFolder from './step2-folder';
import CreateSetMonitoring from './step3-monitoring';
import CreateBgCheck from './step3-bg-check';
import { backgroundCheck } from '@store/actions';
import { useDispatch } from 'react-redux';

export default function CreatePerson(props) {
  const dispatch = useDispatch();
  const [clientId, setClientId] = useState(null)
  const [step1Model, setStep1Model] = useState(null)
  const [folderId] = useState(() => {
    const urlSearchParams = new URLSearchParams(props.location.search)
    const initFolderId = urlSearchParams.get('folderId')
    const initFolderName = urlSearchParams.get('folderName')
    const isFolderPassed = initFolderName !== 'HOME' && !!initFolderId

    return isFolderPassed ? +initFolderId : undefined
  })

  const handleDetailsNext = (type) => (id, model, searchId = '') => {
    setClientId(id);
    setStep1Model(model);
    if (type === 'person') {
      history.push('/portfolio/create-person/choose-folder/' + searchId)
    } else {
      history.push('/portfolio/create-company/choose-folder/' + searchId)
    }
  }

  const handleFinish = (id) => {
    history.push('/portfolio/view/' + (id || clientId))
  }

  const handleFolderNext = (type) => (searchId) => {
    if (searchId) history.push(`/portfolio/create-${type}/monitoring/${searchId}/${clientId}`)
    else history.push(`/portfolio/create-${type}/bg-check/${clientId}`)
  }

  const sendToEidv = (id) => {
    history.push(`/portfolio/person-eidv/${id || clientId}`)
  }

  const sendToBgCheck = (id, type) => {
    dispatch(backgroundCheck.updateCurrentSearch({
      type,
    }));
    history.push(`/portfolio/bg-check/add-monitoring-bg-check/${id}`)
  }

  return (
    <Switch>
      <Route path="/portfolio/create-person" exact>
        <CreatePersonDetails handleNext={handleDetailsNext('person')} cachedModel={step1Model} clientId={clientId} steps={{ currentStep: 1, stepsCount: 4 }} />
      </Route>
      <Route path={'/portfolio/create-person/choose-folder'} exact>
        <CreateChooseFolder handleNext={handleFolderNext('person')} clientId={clientId} initFolderId={folderId} type="person" steps={{ currentStep: 2, stepsCount: 4 }} />
      </Route>
      <Route path={'/portfolio/create-person/bg-check/:clientId'} exact>
        <CreateBgCheck handleNext={(id, isBgCheck) => isBgCheck ? sendToBgCheck(id, 'PERSON') : sendToEidv(id)} cachedModel={step1Model} type="person" steps={{ currentStep: 3, stepsCount: 4 }} />
      </Route>


      <Route path={'/portfolio/create-person/choose-folder/:searchId'} exact>
        <CreateChooseFolder handleNext={handleFolderNext('person')} clientId={clientId} initFolderId={folderId} type="person" steps={{ currentStep: 2, stepsCount: 4 }} />
      </Route>
      <Route path={'/portfolio/create-person/monitoring/:searchId/:clientId'} exact>
        <CreateSetMonitoring handleNext={sendToEidv} steps={{ currentStep: 3, stepsCount: 4 }} />
      </Route>
      <Route path="/portfolio/create-person/:searchId/:profileId?">
        <CreatePersonDetails handleNext={handleDetailsNext('person')} cachedModel={step1Model} clientId={clientId} steps={{ currentStep: 1, stepsCount: 4 }} />
      </Route>


      <Route path="/portfolio/create-company" exact>
        <CreateCompanyDetails handleNext={handleDetailsNext('company')} cachedModel={step1Model} clientId={clientId} steps={{ currentStep: 1, stepsCount: 3 }} />
      </Route>
      <Route path={'/portfolio/create-company/choose-folder'} exact>
        <CreateChooseFolder handleNext={handleFolderNext('company')} clientId={clientId} initFolderId={folderId} type="company" steps={{ currentStep: 2, stepsCount: 3 }} />
      </Route>
      <Route path={'/portfolio/create-company/bg-check/:clientId'} exact>
        <CreateBgCheck handleNext={(id, isBgCheck) => isBgCheck ? sendToBgCheck(id, 'COMPANY') : handleFinish(id)} cachedModel={step1Model} type="company" steps={{ currentStep: 3, stepsCount: 3 }} />
      </Route>

      <Route path={'/portfolio/create-company/choose-folder/:searchId'} exact>
        <CreateChooseFolder handleNext={handleFolderNext('company')} clientId={clientId} initFolderId={folderId} type="company" steps={{ currentStep: 2, stepsCount: 3 }} />
      </Route>
      <Route path={'/portfolio/create-company/monitoring/:searchId/:clientId'} exact>
        <CreateSetMonitoring handleNext={handleFinish} steps={{ currentStep: 3, stepsCount: 3 }} />
      </Route>
      <Route path="/portfolio/create-company/:searchId/:profileId?">
        <CreateCompanyDetails handleNext={handleDetailsNext('company')} cachedModel={step1Model} clientId={clientId} steps={{ currentStep: 1, stepsCount: 3 }} />
      </Route>


      <Route path="*" exact>
        <CreatePersonDetails handleNext={handleDetailsNext} cachedModel={step1Model} clientId={clientId} />
      </Route>
    </Switch>
  )
}

import React from  'react';
import { Box, Typography, Grid, Link } from '@material-ui/core';
import BuyMoreButton from './buy-more-button';

import { useStyles } from './styles'
import CreditBar from '@components/complex/subscription/items/credit-bar';
import { minimumZero } from '@utils/numberUtil';

export default function PremiumCreditsBlock({ currentPlan }) {
  const classes = useStyles();

  return (
    <Box my={2}>
      <Typography className={classes.subtitleFirst}>Extended Due Diligence</Typography>
      <Grid container display="flex" alignItems="center">
        <Grid item sm={4}>
          <Typography className={classes.subtitleSecond}>
            {minimumZero(currentPlan?.availablePremiumCredits)} Premium Credits Available
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <BuyMoreButton />
        </Grid>
      </Grid>
      <Box my={1}>
        <CreditBar
          remainingCredits={minimumZero(currentPlan?.remainingPremiumCredits)}
          availableCredits={minimumZero(currentPlan?.availablePremiumCredits)}
          reservedCredits={minimumZero(currentPlan?.reservedPremiumCredits)}
          maxCredits={minimumZero(currentPlan?.premiumCredits)}
          isAdmin
          type="premium"
        />
      </Box>
      <Box my={1}>
        <Typography className={classes.text}>
          {'Searches performed with premium credits will effectively be checked against a comprehensive dataset of more than '}
          <Link href="/sources-list" target="_blank">1300 sources</Link>
          {', including Sanctions, 4 PEP classes and Adverse media.'}
        </Typography>
      </Box>
    </Box>
  )
}

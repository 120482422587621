const config = {
  MuiAutocomplete: {
    root: {
      padding: 0
    },
    inputRoot: {
      alignItems: 'baseline',
      '&[class*="MuiOutlinedInput-root"]': {
        padding: 6
      }
    },
    popper: {
      borderRadius: 12,
      boxShadow: '0px 9px 26px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    },
    paper: {
      margin: 0,
      borderRadius: 12,
    },
    listbox: {
      padding: 0,
    },
    clearIndicator: {
      padding: '0 !important'
    }
  },
}

export default config
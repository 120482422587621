import React, { useState, useCallback } from 'react';
import { Box, Typography, Card } from '@material-ui/core';
import CardPopover from './card-popover';

import { calendarSchedule } from '@app/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './styles';
import clsx from 'clsx';

const DUMMY = <Box mt={1} height={'24px'} />;
export default function CalendarCard({ item, active, isYearly, index }) {
  const { name, personsCount, legalEntitiesCount, documentsCount } = item;
  const { sort, dateInterval } = useSelector((state) => state.calendarSchedule);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [placeholder, setPlaceholder] = useState();

  const classes = useStyles({ placeholder, isYearly });

  const handlePopup = useCallback((event, type) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setPlaceholder(type);
  }, []);

  const onCardClick = useCallback(() => {
    dispatch(
      calendarSchedule.setDateInterval({
        ...dateInterval,
        interval: 'Month',
        month: index,
      })
    );
  }, [dateInterval, index, dispatch]);

  const renderDocumentsCount = useCallback(
    () =>
      !!documentsCount ? (
        <Box
          className={classes.documentsBox}
          mt={1}
          onClick={(event) => handlePopup(event, 'Documents')}
        >
          <Typography className={[classes.boxLabel, classes.boxDocuments]} >
            <b>{documentsCount}</b>{' '}
            {documentsCount === 1 ? 'Document' : 'Documents'}
          </Typography>
        </Box>
      ) : (
        DUMMY
      ),
    [classes, handlePopup, documentsCount]
  );
  const renderPersonsCount = useCallback(
    () =>
      !!personsCount ? (
        <Box
          className={classes.personsBox}
          mt={1}
          onClick={(event) => handlePopup(event, 'People')}
        >
          <Typography className={classes.boxLabel}>
            <b>{personsCount}</b> {personsCount === 1 ? 'Person' : 'People'}
          </Typography>
        </Box>
      ) : (
        DUMMY
      ),
    [personsCount, classes, handlePopup]
  );

  const renderLegalEntitiesCount = useCallback(
    () =>
      !!legalEntitiesCount ? (
        <Box
          className={classes.entitiesBox}
          mt={1}
          onClick={(event) => handlePopup(event, 'Legal Entities')}
        >
          <Typography className={classes.boxLabel}>
            <b>{legalEntitiesCount}</b>{' '}
            {legalEntitiesCount === 1 ? 'Legal Entity' : 'Legal Entities'}
          </Typography>
        </Box>
      ) : (
        DUMMY
      ),
    [legalEntitiesCount, classes, handlePopup]
  );

  const renderCounters = useCallback(() => {
    if (sort === 'person') {
      return (
        <React.Fragment>
          {renderPersonsCount()}
          {DUMMY}
        </React.Fragment>
      );
    }

    if (sort === 'legal_entity') {
      return (
        <React.Fragment>
          {DUMMY}
          {renderLegalEntitiesCount()}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {renderPersonsCount()}
        {renderLegalEntitiesCount()}
        {renderDocumentsCount()}
      </React.Fragment>
    );
  }, [
    sort,
    renderPersonsCount,
    renderLegalEntitiesCount,
    renderDocumentsCount,
  ]);

  return (
    <Card
      className={clsx(classes.cardRoot, active && classes.active)}
      onClick={() => isYearly && !anchorEl && onCardClick()}
    >
      <Box display="flex" my={isYearly && 2}>
        <Typography
          className={clsx(classes.title, active && classes.activeLabel)}
        >
          {name}
        </Typography>
      </Box>
      {renderCounters()}

      <CardPopover
        item={item}
        isYearly={isYearly}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        placeholder={placeholder}
      />
    </Card>
  );
}

import prepareTimestamp from '@utils/date';
import getRiskRatingLabel from '@utils/riskRatingLabel';

const riskRatingListDto = {
  read: data => ({
    id: data?.id || data?.jsonData?.id,
    name: 'Me',
    risk: getRiskRatingLabel(data?.jsonData?.userInput?.riskRating),
    createdOn: data?.startDate,
    lastUpdatedOn: data?.lastUpdatedOn,
    started: data?.startDate ? prepareTimestamp(data?.startDate, 'MM/DD/YYYY') : 'N/A',
    finished: data?.dateOfReview ? prepareTimestamp(data?.dateOfReview, 'MM/DD/YYYY') : 'N/A',
    isDraft: data?.jsonData?.userInput?.draft || !data?.dateOfReview || false,
    nextReview: data?.jsonData?.userInput?.nextReview
  }),
}

export default riskRatingListDto
import React from 'react';
import {
  Box,
  Typography,
  Grid,
} from '@material-ui/core';


export default function ComingSoon(props) {
  return (
    <Grid container justify={'center'} alignItems={'center'} style={{ height: '100%' }}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Box>
            <Typography variant={'h1'} align={'center'}>{props.title} Coming Soon</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display={'flex'} my={5} justifyContent={'center'}>
            <Typography variant={'subtitle1'} align={'center'} style={{ maxWidth: '600px' }}>
                            We're working hard on creating the best digital experience for managing your
                            KYC. This will take just a few more weeks.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
import React, { useEffect, useState } from 'react';
import { SidesLayout } from '@components/layout/sides-layout';
import LayoutHeader from '@components/layout/header';
import {
  Box,
  Typography,
  Link
} from '@material-ui/core';

const reqex = /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i;

export default function TermsOfUse(props) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if(reqex.test(navigator.userAgent)){
      setIsMobile(true);
    }
    return () => {
      setIsMobile(false);
    }
  }, [])

  const Text = (props) => {
    return (
      <Box mb={1} {...props}>
        <Typography variant={props.variant || 'body1'} style={props?.variant === 'h3' ? { color: '#7A5B44' } : undefined}>
          {props.children}
        </Typography>
      </Box>
    )
  }

  const Wrapper = (props) => {
    if (isMobile) {
      return (
        <React.Fragment>
          <Box mb={6}><LayoutHeader /></Box>
          {props.children}
        </React.Fragment>
      )
    }

    return (
      <SidesLayout>{props.children}</SidesLayout>
    )
        
  }

  return (
    <Wrapper>
      <Box px={4} pb={2} maxHeight="calc(100vh - 16px)" style={{ overflowY: 'auto' }}>
        <Text variant="h1" mt={2}>
                    KYC Passport Terms of Service
        </Text>
        <Text mt={2}>
                    KYC Passport is a product of Commercial Passport Corporation (“CPC”). 
        </Text>

        <Text mt={4} variant="h3">
                    1. Your relationship with Commercial Passport
        </Text>
        <Text>
                    1.1 Your use of Commercial Passport products, software, services and web sites (referred to collectively as the “Services” in this document) is subject to the terms of a legal agreement between you and Commercial Passport whose registered office is at  100 King St W CA-ON M5X 1B8, Toronto, Canada. This document explains how the agreement is made up, and sets out some of the terms of that agreement.
        </Text>
        <Text>
                    1.2 These Terms of Service form a legally binding agreement between you and CPC in relation to your use of the Services. It is important that you take the time to read them carefully. This legal agreement is referred to below as the “Terms”.
        </Text>

        <Text mt={2} variant="h3">
                    2. Accepting the Terms
        </Text>
        <Text>
                    2.1 In order to use the Services, you must first agree to the Terms. You may not use the Services if you do not accept the Terms.
        </Text>
        <Text>
                    2.2 You can accept the Terms by:
        </Text>
        <Text ml={2}>
                    (A) clicking to accept or agree to the Terms, where this option is made available to you by CPC in the user interface for any Service; or
        </Text>
        <Text ml={2}>
                    (B) by actually using the Services. In this case, you understand and agree that CPC will treat your use of the Services as acceptance of the Terms from that point onwards.
        </Text>
        <Text>
                    2.3 You may not use the Services and may not accept the Terms if (a) you are not of legal age to form a binding contract with CPC, or (b) you are a person barred from receiving the Services under the laws of Canada or other countries including the country in which you are resident or from which you use the Services.
        </Text>
        <Text>
                    2.4 Before you continue, you should print off or save a local copy of the Terms for your records.
        </Text>

        <Text mt={2} variant="h3">
                    3. Language of the Terms
        </Text>
        <Text>
                    3.1 Where CPC has provided you with a translation of the English language version of the Terms, then you agree that the translation is provided for your convenience only and that the English language versions of the Terms will govern your relationship with CPC.
        </Text>
        <Text>
                    3.2 If there is any contradiction between what the English language version of the Terms says and what a translation says, then the English language version shall take precedence.
        </Text>

        <Text mt={2} variant="h3">
                    4. Provision of the Services by CPC
        </Text>
        <Text>
                    4.1 You acknowledge and agree that the form and nature of the Services which CPC provides may change from time to time without prior notice to you.
        </Text>
        <Text>
                    4.2 You acknowledge and agree that CPC may stop (permanently or temporarily) providing the Services (or any features within the Services) to you or to users generally at CPC’s sole discretion, without prior notice to you. You may stop using the Services at any time. You do not need to specifically inform CPC when you stop using the Services.
        </Text>
        <Text>
                    4.3 You acknowledge and agree that if CPC disables access to your account, you may be prevented from accessing the Services, your account details or any data files, written text, electronic documents, image files, photographs or other images, files or other content (“Your Data”) which is contained in your account.
        </Text>
        <Text>
                    4.4 You acknowledge and agree that while CPC may not currently have set a fixed upper limit on the number of transmissions you may send or receive through the Services or on the amount of storage space used for the provision of any Service, such fixed upper limits may be set by CPC at any time, at CPC’s discretion.
        </Text>

        <Text mt={2} variant="h3">
                    5. Use of the Services by you
        </Text>
        <Text>
                    5.1 In order to access certain Services, you may be required to provide information about yourself (such as identification or contact details) as part of the registration process for the Service, or as part of your continued use of the Services. You agree that any registration information you give to CPC will always be accurate, correct and up to date.
        </Text>
        <Text>
                    5.2 You agree to use the Services only for purposes that are permitted by (a) the Terms and (b) any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions.
        </Text>
        <Text>
                    5.3 You agree not to access (or attempt to access) any of the Services by any means other than through the interface that is provided by CPC, unless you have been specifically allowed to do so in a separate agreement with CPC. You specifically agree not to access (or attempt to access) any of the Services through any automated means (including use of scripts or web crawlers) and shall ensure that you comply with the instructions set out in any robots.txt file present on the Services.
        </Text>
        <Text>
                    5.4 You agree that you will not engage in any activity that interferes with or disrupts the Services (or the servers and networks which are connected to the Services).
        </Text>
        <Text>
                    5.5 Unless you have been specifically permitted to do so in a separate agreement with CPC, you agree that you will not reproduce, duplicate, copy, attempt to create a substitute or similar service through use of or access to, sell, trade or resell the Services for any purpose.
        </Text>
        <Text>
                    5.6 You agree that you are solely responsible for (and that CPC has no responsibility to you or to any third party for) any breach of your obligations under the Terms and for the consequences (including any loss or damage which CPC may suffer) of any such breach.
        </Text>
        <Text>
                    5.7 You agree that you are responsible for your own conduct and Content while using the Services and for any consequences thereof. You agree to use the Services only for purposes that are legal, proper and in accordance with these Terms.
        </Text>

        <Text mt={2} variant="h3">
                    6. Billing and Payment
        </Text>
        <Text>
                    6.1 If you select a paid Services level, you may choose to subscribe and pay for the Services on an annual subscription basis and either a monthly or as-delivered basis for Services other than subscription fees, and CPC will bill the account administrator in advance for use of the Services. Current pricing for paid subscription plans and other Services are as set forth in CPC’s Standard Price List, and CPC reserves the right to modify pricing at any time, provided however that CPC will notify the account administrator of a paid level account by email prior to any monthly or annual price increase affecting that account. If you upgrade to a higher paid level or tier, CPC will credit any remaining balance from your previous subscription payment to your new level or tier. You may choose to discontinue your paid level account at any time; however, CPC does not issue refunds for unused subscription periods.
        </Text>
        <Text>
                    6.2 You agree to maintain valid and up-to-date billing information on file with CPC. You may update this billing information at any time in your Account settings.
        </Text>
        <Text>
                    6.3 All payments due are in U.S. dollars unless otherwise indicated on the subscription pricing page or invoice.
        </Text>
        <Text ml={2}>
                    1. Credit Card or Debit Card. Fees for accounts where you are paying with a credit card, debit card or other non-invoice form of payment are due at the beginning of the month for which Services will be provided to you. For credit cards, or debit cards: (i) CPC will charge you for all fees when due at the beginning of each service month or year, as applicable; and (ii) these fees are considered delinquent if not received at the start of each service month or year.
        </Text>
        <Text ml={2}>
                    2. Invoices. Payments for invoices are due ten days after the invoice date, unless otherwise specified, and are considered delinquent after such date.
        </Text>
        <Text ml={2}>
                    3. Renewal. For paid levels, at the end of each annual or monthly subscription period (as applicable), the Services will automatically renew for an additional year or month, respectively. If you wish to change your subscription level or term, the account administrator must change the settings in the account administration console provided as part of the Services.
        </Text>
        <Text ml={2}>
                    4. Other Forms of Payment. CPC may enable other forms of payment by making them available in the account administration and payments page. These other forms of payment may be subject to additional terms which you may have to accept prior to using the additional forms of payment.
        </Text>
        <Text>
                    6.4 Delinquent payments may bear interest at the rate of one-and-one-half percent per month (or the highest rate permitted by law, if less) from the payment due date until paid in full. You will be responsible for all reasonable expenses (including attorneys’ fees) incurred by CPC in collecting such delinquent amounts, except where such delinquent amounts are due to CPC’s billing inaccuracies.
        </Text>
        <Text>
                    6.5 CPC will endeavor to notify you (or in the case of a Team account, your account administrator) if your paid account has delinquent fees. If delinquent fees are not paid, CPC will automatically downgrade your account to a free CPC account and/or suspend your use of the Services.
        </Text>
        <Text>
                    6.6 You are responsible for any taxes, duties, and customs fees associated with the sale of the Services (other than CPC’s income tax) (collectively “Taxes”), and you will pay CPC for the Services without any reduction for Taxes. If CPC is obligated to collect or pay Taxes, the Taxes will be invoiced to you, unless you provide CPC with a valid tax exemption certificate authorized by the appropriate taxing authority. If you are required by law to withhold any Taxes from your payments to CPC, you must provide CPC with an official tax receipt or other appropriate documentation to support such payments.
        </Text>
        <Text>
                    6.7 These billing and payment terms may not apply if you are not subscribing to the Services directly from CPC.
        </Text>

        <Text mt={2} variant="h3">
                    7. Your passwords and account security
        </Text>
        <Text>
                    7.1 You agree and understand that you are responsible for maintaining the confidentiality of passwords associated with any account you use to access the Services.
        </Text>
        <Text>
                    7.2 Accordingly, you agree that you will be solely responsible to CPC for all activities that occur under your account.
        </Text>
        <Text>
                    7.3 If you become aware of any unauthorized use of your password or of your account, you agree to notify CPC immediately at <Link variant="body1" target="_blank" href="mailto:support@kycpassport.com">support@kycpassport.com</Link>.
        </Text>
        <Text>
                    7.4 Password Creation
        </Text>
        <Text ml={2}>
                    a. All user-level and system-level passwords must conform to the <i>Password Construction Guidelines</i> (minimum 8 characters, one uppercase letter, one number and one special character).
        </Text>
        <Text ml={2}>
                    b. Users must use a separate, unique password for each of their work-related accounts.  Users may not use any work-related passwords for their own, personal accounts.
        </Text>
        <Text ml={2}>
                    c. User accounts that have system-level privileges granted through group memberships or programs such as sudo must have a unique password from all other accounts held by that user to access system-level privileges. In addition, it is highly recommended that some form of multi-factor authentication is used for any privileged accounts.
        </Text>
        <Text>
                    7.5 Password Change
        </Text>
        <Text ml={2}>
                    a. Passwords should be changed only when there is reason to believe a password has been compromised.
        </Text>
        <Text ml={2}>
                    b. Password cracking or guessing may be performed on a periodic or random basis by the Infosec Team or its delegates. If a password is guessed or cracked during one of these scans, the user will be required to change it to be in compliance with the Password Construction Guidelines.
        </Text>
        <Text>
                    7.6 Password Protection
        </Text>
        <Text ml={2}>
                    a. Passwords must not be shared with anyone, including supervisors and coworkers. All passwords are to be treated as sensitive, Confidential CPC information. Corporate Information Security recognizes that legacy applications do not support proxy systems in place. Please refer to the technical reference for additional details. 
        </Text>
        <Text ml={2}>
                    b. Passwords must not be inserted into email messages, Alliance cases or other forms of electronic communication, nor revealed over the phone to anyone. 
        </Text>
        <Text ml={2}>
                    c. Passwords may be stored only in “password managers” authorized by the organization.
        </Text>
        <Text ml={2}>
                    d. Do not use the "Remember Password" feature of applications (for example, web browsers).
        </Text>
        <Text ml={2}>
                    e. Any user suspecting that his/her password may have been compromised must report the incident and change all passwords.
        </Text>


        <Text mt={2} variant="h3">
                    8. Privacy and your personal information
        </Text>
        <Text>
                    8.1 For information about CPC’s data protection practices, please read CPC’s privacy policy at <Link variant="body1" target="_blank" href="https://kycpassport.com/privacy-policy">https://kycpassport.com/privacy-policy</Link>. This policy explains how CPC treats your personal information, and protects your privacy, when you use the Services.
        </Text>
        <Text>
                    8.2 You agree to the use of Your Data in accordance with CPC’s privacy policies.
        </Text>

        <Text mt={2} variant="h3">
                    9. Third Party Content
        </Text>
        <Text>
                    9.1 You understand that all information (such as data files, written text, electronic documents, image files, photographs or other images) which you may have access to as part of, or through your use of, the Services are the sole responsibility of the person from which such content originated. All such information is referred to below as the “Content”.
        </Text>
        <Text>
                    9.2 CPC reserves the right (but shall have no obligation) to pre-screen, review, flag, filter, modify, refuse or remove any or all Content from any Service.
        </Text>
        <Text>
                    9.3 You agree that you are solely responsible for (and that CPC does not have any responsibility to you or to any third party for) any Content that you create, transmit or display while using the Services and for the consequences of your actions (including any loss or damage which CPC or third parties may suffer) by doing so.
        </Text>

        <Text mt={2} variant="h3">
                    10. Proprietary rights
        </Text>
        <Text>
                    10.1 You acknowledge and agree that CPC (or CPC’s licensors) and its resellers own all legal right, title and interest in and to the Services, including any intellectual property rights which subsist in the Services (whether those rights happen to be registered or not, and wherever in the world those rights may exist). You further acknowledge that the Services may contain information which is designated confidential by CPC or its resellers and that you shall not disclose such information without CPC’s prior written consent.
        </Text>
        <Text>
                    10.2 Unless you have agreed otherwise in writing with CPC, nothing in the Terms gives you a right to use any of CPC’s or its resellers’ trade names, trade marks, service marks, logos, domain names, and other distinctive brand features.
        </Text>
        <Text>
                    10.3 If you have been given an explicit right to use any of these brand features in a separate written agreement with CPC, then you agree that your use of such features shall be in compliance with that agreement, and any applicable provisions of the Terms.
        </Text>
        <Text>
                    10.4 Other than the limited license set forth in Section 13, CPC acknowledges and agrees that it obtains no right, title or interest from you (or your licensors) under these Terms in or to any Content that you submit or transmit through the Services, including any intellectual property rights which subsist in that Content (whether those rights happen to be registered or not, and wherever in the world those rights may exist). Unless you have agreed otherwise in writing with CPC, you agree that you are responsible for protecting and enforcing those rights and that CPC has no obligation to do so on your behalf.
        </Text>
        <Text>
                    10.5 You agree that you shall not remove, obscure, or alter any proprietary rights notices (including copyright and trademark notices) which may be affixed to or contained within the Services.
        </Text>
        <Text>
                    10.6 Unless you have been expressly authorized to do so in writing by CPC, you agree that in using the Services, you will not use any trade mark, service mark, trade name, logo of any company or organization in a way that is likely or intended to cause confusion about the owner or authorized user of such marks, names or logos.
        </Text>

        <Text mt={2} variant="h3">
                    11. Publicity
        </Text>
        <Text>
                    11.1. If you are a bank, insurance company, fund administration business or law firm in any case having more than 50 employees and have a paid CPC account, CPC may identify you as a customer on our site or in our customer list, blogs, and other public communications. To request removal of this identification, please notify us in writing at <Link variant="body1" target="_blank" href="mailto:support@kycpassport.com">support@kycpassport.com</Link> or by sending a letter to CPC’s address which is found at the beginning of these Terms. CPC will not identify any other user of its Services in any public manner without the user’s prior written consent. You shall have the unfettered right to withhold such consent for any reason.
        </Text>

        <Text mt={2} variant="h3">
                    12. License from CPC
        </Text>
        <Text>
                    12.1 CPC gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the software provided to you by CPC as part of the Services as provided to you by CPC (referred to as the “Software” below). This license is for the sole purpose of enabling you to use and enjoy the benefit of the Services as provided by CPC, in the manner permitted by the Terms.
        </Text>
        <Text>
                    12.2 You may not (and you may not permit anyone else to) copy, modify, create a derivative work of, reverse engineer, decompile or otherwise attempt to extract the source code of the Software or any part thereof, unless this is expressly permitted or required by law, or unless you have been specifically told that you may do so by CPC, in writing.
        </Text>
        <Text>
                    12.3 Unless CPC has given you specific written permission to do so, you may not assign (or grant a sub-license of) your rights to use the Software, grant a security interest in or over your rights to use the Software, or otherwise transfer any part of your rights to use the Software.
        </Text>

        <Text mt={2} variant="h3">
                    13. License from you
        </Text>
        <Text>
                    13.1 The Services are designed to facilitate the secure exchange of legal entity and individual identification information among users. You retain copyright and any other rights you already hold in Content which you submit, upload, share, post or display on or through, the Services. In support of that core function, you hereby grant CPC a limited license to use your Content to provide the Services and its features during the term of this Agreement, including the right to store, reproduce, adapt, modify, analyse, translate and distribute Content to other users on your instruction, solely for the purpose of enabling CPC to provide you with the Services in accordance with the CPC Privacy Policy.
        </Text>
        <Text>
                    13.2 You understand that CPC, in performing the required technical steps to provide the Services to our users, may (a) transmit or distribute your Content over various public networks and in various media; and (b) make such changes to your Content as are necessary to conform and adapt that Content to the technical requirements of connecting networks, devices, services or media. You agree that this license shall permit CPC to take these actions.
        </Text>
        <Text>
                    13.3 You confirm and warrant to CPC that you have all the rights, power and authority necessary to grant the above license. You agree that you will not submit, share, upload, post or display Content on or through, the Service that is copyrighted, protected by trade secret or otherwise subject to third party proprietary rights, including privacy and publicity rights, unless you are the owner of such rights or have permission from their rightful owner to submit, share, upload, post or display the Content and to grant CPC all of the license rights granted in this Section.
        </Text>
                
        <Text mt={2} variant="h3">
                    14. Software updates
        </Text>
        <Text>
                    14.1 The Software which you use may be updated from time to time from CPC. These updates are designed to improve, enhance and further develop the Services and may take the form of bug fixes, enhanced functions, new software modules and completely new versions. You agree that such updates will be made as part of your use of the Services.
        </Text>
                
        <Text mt={2} variant="h3">
                    15. Ending your relationship with CPC
        </Text>
        <Text>
                    15.1 The Terms will continue to apply until terminated by either you or CPC as set out below.
        </Text>
        <Text>
                    15.2 If you want to terminate your legal agreement with CPC, you may do so by (a) notifying CPC at any time and (b) closing your accounts for all of the Services which you use, where CPC has made this option available to you. Your notice should be sent, in writing, to CPC’s address which is set out at the beginning of these Terms.
        </Text>
        <Text>
                    15.3 CPC may at any time, terminate its legal agreement with you:
        </Text>
        <Text ml={2}>
                    (A) if you have breached any provision of the Terms (or have acted in a manner which clearly shows that you do not intend to, or are unable to comply with the provisions of the Terms); or
        </Text>
        <Text ml={2}>
                    (B) if CPC is required to do so by law (for example, where the provision of the Services to you is, or becomes, unlawful); or
        </Text>
        <Text ml={2}>
                    (C) if CPC is transitioning to no longer providing the Services to users in the area in which you are resident or from which you use the service; or
        </Text>
        <Text ml={2}>
                    (D) if the provision of the Services to you by CPC is, in CPC’s opinion, no longer commercially viable.
        </Text>
        <Text>
                    15.4 In addition, CPC may terminate its legal agreement with you, for any reason or no reason, by giving you 5 days’ written notice to the email address you provide when you register for CPC. If we discontinue your access to the Services at the end of this 5 day period, our termination will be effective at the end of this period, and we will refund any prepaid, unused subscription fees for the Services as soon as practicable thereafter. Otherwise, no fees are refundable, and you may continue using the Services through the end of your subscription period, at which point our termination will become effective.
        </Text>
        <Text>
                    15.5 Nothing in this Section shall affect CPC’s rights regarding provision of Services under Section 4 of the Terms.
        </Text>
        <Text>
                    15.6 When these Terms come to an end, all of the legal rights, obligations and liabilities that you and CPC have benefited from, been subject to (or which have accrued over time whilst the Terms have been in force) or which are expressed to continue indefinitely, shall be unaffected by this cessation, and the provisions of paragraph 23.6 shall continue to apply to such rights, obligations and liabilities indefinitely.
        </Text>
        <Text>
                    15.7 If your CPC account is canceled, your information on our servers may be deactivated but not deleted. We do not actively delete your profile information, for example your name and email address. Any content that you do not wish to remain on our servers after ending your relationship with CPC should be deleted prior to closing your account.
        </Text>

        <Text mt={2} variant="h3">
                    16. EXCLUSION OF WARRANTIES
        </Text>
        <Text>
                    16.1 NOTHING IN THESE TERMS, INCLUDING SECTIONS 16 AND 17, SHALL EXCLUDE OR LIMIT CPC’S WARRANTY OR LIABILITY FOR LOSSES WHICH MAY NOT BE LAWFULLY EXCLUDED OR LIMITED BY APPLICABLE LAW. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR CONDITIONS OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR LOSS OR DAMAGE CAUSED BY NEGLIGENCE, BREACH OF CONTRACT OR BREACH OF IMPLIED TERMS, OR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, ONLY THE LIMITATIONS WHICH ARE LAWFUL IN YOUR JURISDICTION WILL APPLY TO YOU AND OUR LIABILITY WILL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
        </Text>
        <Text>
                    16.2 YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK AND THAT THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE.”
        </Text>
        <Text>
                    16.3 IN PARTICULAR, CPC AND ITS LICENSORS AND RESELLERS DO NOT REPRESENT OR WARRANT TO YOU THAT:
        </Text>
        <Text ml={2}>
                    (A) YOUR USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS,
        </Text>
        <Text ml={2}>
                    (B) YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR, 
        </Text>
        <Text ml={2}>
                    (C) ANY INFORMATION OBTAINED BY YOU AS A RESULT OF YOUR USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE, AND
        </Text>
        <Text ml={2}>
                    (D) THAT DEFECTS IN THE OPERATION OR FUNCTIONALITY OF ANY SOFTWARE PROVIDED TO YOU AS PART OF THE SERVICES WILL BE CORRECTED.
        </Text>
        <Text>
                    16.4 ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR OTHER DEVICE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
        </Text>
        <Text>
                    16.5 NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM CPC OR THROUGH OR FROM THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS.
        </Text>
        <Text>
                    16.6 CPC AND ITS LICENSORS AND RESELLERS FURTHER EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
        </Text>

        <Text mt={2} variant="h3">
                    17. LIMITATION OF LIABILITY
        </Text>
        <Text>
                    17.1 SUBJECT TO OVERALL PROVISION IN PARAGRAPH 16.1 ABOVE, YOU EXPRESSLY UNDERSTAND AND AGREE THAT CPC AND ITS LICENSORS SHALL NOT BE LIABLE TO YOU FOR:
        </Text>
        <Text ml={2}>
                    (A) ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL CONSEQUENTIAL OR EXEMPLARY DAMAGES WHICH MAY BE INCURRED BY YOU, HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY. THIS SHALL INCLUDE, BUT NOT BE LIMITED TO, ANY LOSS OF PROFIT (WHETHER INCURRED DIRECTLY OR INDIRECTLY), ANY LOSS OF GOODWILL OR BUSINESS REPUTATION, ANY LOSS OF DATA SUFFERED, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR OTHER INTANGIBLE LOSS;
        </Text>
        <Text ml={2}>
                    (B) ANY LOSS OR DAMAGE WHICH MAY BE INCURRED BY YOU, INCLUDING BUT NOT LIMITED TO LOSS OR DAMAGE AS A RESULT OF:
        </Text>
        <Text ml={4}>
                    (I) ANY CHANGES WHICH CPC MAY MAKE TO THE SERVICES, OR FOR ANY PERMANENT OR TEMPORARY CESSATION IN THE PROVISION OF THE SERVICES (OR ANY FEATURES WITHIN THE SERVICES);
        </Text>
        <Text ml={4}>
                    (II) THE DELETION OF, CORRUPTION OF, OR FAILURE TO STORE, ANY CONTENT AND OTHER COMMUNICATIONS DATA MAINTAINED OR TRANSMITTED BY OR THROUGH YOUR USE OF THE SERVICES;
        </Text>
        <Text ml={4}>
                    (III) YOUR FAILURE TO PROVIDE CPC WITH ACCURATE ACCOUNT INFORMATION;
        </Text>
        <Text ml={4}>
                    (IV) YOUR FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE AND CONFIDENTIAL;
        </Text>
        <Text ml={4}>
                    (V) THE UNAUTHORIZED OR UNLAWFUL ACCESS TO YOUR DATA OR CONTENT;
        </Text>
        <Text>
                    17.2 THE LIMITATIONS ON CPC’S LIABILITY, AND THAT OF ITS LICENSORS AND RESELLERS, TO YOU IN PARAGRAPH 17.1 ABOVE SHALL APPLY WHETHER OR NOT CPC, ITS LICENSORS OR RESELLERS HAVE BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF ANY SUCH LOSSES ARISING.
        </Text>

        <Text mt={2} variant="h3">
                    18. Abuse of Services
        </Text>
        <Text>
                    18.1 The Services are intended to be used to facilitate the secure upload, processing and exchange of identification and client due diligence/know your customer materials for individuals and legal entities. Use of the Services for any other purpose, or involving content or data which does not relate to such intended use, may be determined by CPC to be improper.
        </Text>
        <Text>
                    18.2 CPC may remove Content if it believes or has reason to believe such Content has been uploaded or is being used for improper purposes. Without prior notice and at any time at its sole discretion, CPC reserves the right to remove any of Content, disable your ability to share or upload Content within the Service, or terminate your access to the Service for uploading or sharing such Content in violation of these Terms of Service.
        </Text>
        <Text>
                    18.3 You may not use the Services to:
        </Text>
        <Box mb={2}>
          <ul>
            {[
              'Upload, post, transmit, or otherwise make available any of Your Data that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another’s privacy or otherwise objectionable',
              'Upload or transmit documents that contain false or falsified information',
              'Impersonate, or misrepresent your relationship with, any person or entity, or misrepresent any entity’s relationship with any person or other entity',
              'Upload or transmit any Content that you do not have a right to make available, or that infringes any privacy or other proprietary rights of any party;',
            ].map(e => (
              <li><Typography variant="body1">{e}</Typography></li>
            ))}
          </ul>
        </Box>
        <Text>
                    18.4 CPC reserves the right in its sole discretion to decide whether your conduct and use of the Services is inappropriate and whether it complies with these Terms of Service. CPC may terminate your access for such inappropriate conduct in violation of these Terms of Service at any time and remove any such objectionable Content, without prior notice and at its sole discretion.
        </Text>
        <Text>
                    18.5 You are solely liable and responsible for your interactions with other users. We reserve the right to monitor disputes between you and other users, but we have no obligation to do so.
        </Text>
                
        <Text mt={2} variant="h3">
                    19. Confidential Information
        </Text>
        <Text>
                    19.1 CPC will treat all documents, information and other Content you upload relating to individual and legal entities profiled within your account as confidential. CPC may review this information for accuracy where you request Services requiring a review by CPC. CPC will not share your Content with other users or other persons except where in the context of the Services you give instructions (via the software) to share such information with other users or non-user recipients.
        </Text>

        <Text mt={2} variant="h3">
                    20. Data Security and Liability
        </Text>
        <Text>
                    20.1 CPC implements security procedures to help protect Your Data from security attacks. However, you understand that use of the Services necessarily involves transmission of Your Data over networks that are not owned, operated or controlled by us, and we are not responsible for any of Your Data lost, altered, intercepted or stored across such networks. We cannot guarantee that our security procedures will be error-free, that transmissions of Your Data will always be secure or that unauthorized third parties will never be able to defeat our security measures or those of our third party service providers.
        </Text>
        <Text>
                    20.2 You will defend, indemnify and hold harmless CPC from and against any loss, cost, liability or damage, including attorneys’ fees, for which CPC becomes liable arising from or relating to any claim relating to Your Data, including but not limited to any claim brought by a third party alleging that Your Data, or your use of the Services in breach of this Agreement violates applicable law. This indemnification obligation is subject to your receiving (i) prompt written notice of such claim (but in any event notice in sufficient time for you to respond without prejudice); (ii) the exclusive right to control and direct the investigation, defense, or settlement of such claim; and (iii) all reasonable necessary cooperation of CPC at your expense.
        </Text>

        <Text mt={2} variant="h3">
                    21. Other content
        </Text>
        <Text>
                    21.1 The Services may include hyperlinks to other web sites or content or resources. CPC may have no control over any web sites or resources which are provided by companies or persons other than CPC.
        </Text>
        <Text>
                    21.2 You acknowledge and agree that neither CPC nor its licensors or resellers are responsible for the availability of any such external sites or resources, and does not endorse any advertising, products or other materials on or available from such web sites or resources.
        </Text>
        <Text>
                    22.3 You acknowledge and agree that neither CPC nor its licensors or resellers are liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy or existence of any advertising, products or other materials on, or available from, such web sites or resources.
        </Text>

        <Text mt={2} variant="h3">
                    22. Changes to the Terms
        </Text>
        <Text>
                    22.1 CPC may make changes to the Terms from time to time. When these changes are made, CPC will make a new copy of the Terms available at <Link variant="body1" target="_blank" href="https://kycpassport.com/terms-of-use">https://kycpassport.com/terms-of-use</Link>.
        </Text>
        <Text>
                    22.2 You understand and agree that if you use the Services after the date on which the Terms have changed, CPC will treat your use as acceptance of the updated Terms.
        </Text>

        <Text mt={2} variant="h3">
                    23. General legal terms
        </Text>
        <Text>
                    23.1 Sometimes when you use the Services, you may (as a result of, or through your use of the Services) use a service or download a piece of software, or purchase goods, which are provided by another person or company. Your use of these other services, software or goods may be subject to separate terms between you and the company or person concerned. If so, the Terms do not affect your legal relationship with these other companies or individuals.
        </Text>
        <Text>
                    23.2 The Terms constitute the whole legal agreement between you and CPC and govern your use of the Services (but excluding any services which CPC may provide to you under a separate written agreement), and completely replace any prior agreements between you and CPC in relation to the Services.
        </Text>
        <Text>
                    23.3 You agree that CPC may provide you with notices, including those regarding changes to the Terms, by email, regular mail, or postings on the Services.
        </Text>
        <Text>
                    23.4 You agree that if CPC does not exercise or enforce any legal right or remedy which is contained in the Terms (or which CPC has the benefit of under any applicable law), this will not be taken to be a formal waiver of CPC’s rights and that those rights or remedies will still be available to CPC.
        </Text>
        <Text>
                    23.5 If any court of law, having the jurisdiction to decide on this matter, rules that any provision of these Terms is invalid, then that provision will be removed from the Terms without affecting the rest of the Terms. The remaining provisions of the Terms will continue to be valid and enforceable.
        </Text>
        <Text>
                    23.6 You hereby agree to indemnify, defend and hold CPC, its resellers, partners, officers, directors, agents, affiliates, and licensors (“the Indemnified Parties”) harmless from and against any claim or liability arising out of (a) any Content you submit, share, upload or display on or to the Service; (b) any use by CPC end users of your Content; (c) any breach of or noncompliance with any representation, warranty or obligation in these Terms or applicable policies; and (d) any claim that your Content violates any applicable law or is used for an unlawful purpose. You shall cooperate fully in the defense of any claim. CPC reserves the right, at its own expense, to assume the exclusive defense and control of any matter subject to indemnification by you. You acknowledge that damages for improper use of the Services may be irreparable; therefore, CPC is entitled to seek equitable relief, including injunction and preliminary injunction, in addition to all other remedies. This section shall take precedence only over the indemnity provision provided in any Terms.
        </Text>
        <Text>
                    23.7 The Terms, and your relationship with CPC under the Terms, shall be governed by the laws of Canada without regard to its conflict of laws provisions. You and CPC agree to submit to the exclusive jurisdiction of the ordinary courts located within Ontario, Canada to resolve any legal matter arising from the Terms. Notwithstanding this, you agree that CPC shall still be allowed to apply for injunctive remedies (or an equivalent type of urgent legal relief) in any jurisdiction.
        </Text>
                
        <Text mt={4} style={{ whiteSpace: 'pre-line' }}>
          {'Copyright: Commercial Passport Corporation 2021 \n 100 King St W CA-ON M5X 1B8, Toronto, Canada'}
        </Text>
        <Text>
                    
        </Text>
      </Box>
    </Wrapper>
  );
}
import { countriesISO } from '@services/country-service';
import { LegalEntitySubtypeEnum } from '@services/client-type';

const whitelistedFields = [
  'registrationNumber', 'type', 'registeredOfficeCountry', 'formationDate', // Legal Entity

  'gender', 'dob', 'phoneNumber', 'citizenship', 'residency', 'address', 'placeOfBirth' // Person
]

export const infoCardPrepareFields = (data) => {
  let meta = [];

  for (const key in data) {
    const result = key.replace( /([A-Z])/g, ' $1' );
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

    if (whitelistedFields.includes(key) && !!data[key]) {
      let value = data[key];

      switch (key) {
        case 'registeredOfficeCountry':
          const country = countriesISO.find(el => el.code === value);

          meta.push({
            field: 'Jurisdiction',
            value: country.name,
          });
          break;

        case 'type':
          meta.push({
            field: 'Entity Type',
            value: LegalEntitySubtypeEnum[value],
          });
          break;

        case 'citizenship':
        case 'residency':
          const country2 = countriesISO.find(el => el.code === value);

          meta.push({
            field: finalResult,
            value: country2.name,
          });
          break;

        case 'address':
          meta.push({
            field: 'Address 1',
            value: value.address1
          })
          meta.push({
            field: 'Address 2',
            value: value.address2
          })
          meta.push({
            field: 'City',
            value: value.city
          })
          meta.push({
            field: 'State / Province / Region',
            value: value.stateProvinceRegion
          })
          meta.push({
            field: 'Postal / Zip Code',
            value: value.postalZipCode
          })
          break;

        default:
          meta.push({ field: finalResult, value });
          break;
      }
    }
  }

  return {
    bgCheckRiskRating: data.bgCheckRiskRating,
    isWhitelisted: data.isWhitelisted,
    name: data.name,
    tags: data.tags,
    clientId: data.clientId,
    meta,
  }
}
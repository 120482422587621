import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  FormControl,
  FormHelperText, InputLabel, ListItemIcon, ListItemText,
  makeStyles, MenuItem,
  TextField,
  //FormLabel
} from '@material-ui/core';
import { Modal } from '@components/modals';
import SelectSearchable from '@components/inputs/select-searchable';
import { clientApi } from '@app/api';
import useErrorState from '@utils/errorState';
import parseYupErrors from '@app/yup';
import * as yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';
import {selectCategoryDialogOpen, setSelectCategoryDialog} from '../duck';
import CheckboxLight from '@components/inputs/checkbox-light';
import {usageTypes} from '@utils/entityType';
import SelectClearable from '@components/inputs/select-clearable';

export const useStyles = makeStyles((theme) => ({
  customButtonModalWidth: {
    width: '180px',
  },
}));

const schema = yup.object().shape({
  categoryId: yup.string().required().label('Document Category'),
  name: yup.string().nullable().when('categoryId', {
    is: 'custom',
    then: yup.string().required().label('Document Category Name'),
  })
})

const MODEL_DEFAULT = {
  categoryId: '',
  name: '',
  usageTypes: []
}

export default function SelectCategoryDialog({ onSave, excludeIds = [], clientType }) {

  const classes = useStyles()
  const [errors, setErrors] = useErrorState({})
  const [model, setModel] = useState(MODEL_DEFAULT)

  const [categories, setCategories] = useState([])

  const open = useSelector(selectCategoryDialogOpen)
  const dispatch = useDispatch()

  const onClose = useCallback(() => {
    dispatch(
      setSelectCategoryDialog(false)
    )
  }, [dispatch])

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!open) {
      setModel({
        ...MODEL_DEFAULT,
        usageTypes: [ clientType ]
      })
    }
  }, [open, clientType])

  const fetchCategories = useCallback(async () => {
    const categoriesData = (await clientApi.clientDocumentCategory.index({
      ownedBy: 'SYSTEM'
    })).data;
    setCategories(categoriesData?.entries || [])
  }, [])

  const onChange = field =>
    e => setModel({
      ...model,
      [field]: e.target.value
    })
  const onInternalSave = useCallback(async () => {
    setErrors({});
    try {
      await schema.validate(model, {abortEarly: false})
      let newCategory = model.categoryId === 'custom'
        ? {
          ...model,
          categoryId: null
        }
        : categories.find(c => c.id === model.categoryId)
      newCategory = {
        ...newCategory,
      }
      onSave(newCategory)
      onClose()
      console.log('ON CATEGORY SELECT', newCategory)
    } catch (err) {
      console.log(err)
      setErrors(parseYupErrors(err), true)
    }
  }, [model, categories]) //eslint-disable-line

  const isCustom = useMemo(() => {
    return model.categoryId === 'custom'
  }, [model])

  const onInternalClose = useCallback(() => {
    setErrors({});
    onClose();
  }, [setErrors, onClose])

  const categoriesOptions = useMemo(() => {
    console.log('Select Category - clientType', clientType, categories)
    const filteredCategories = categories
      .filter(c => excludeIds.indexOf(c.id) === -1 && c.usageTypes.includes(clientType))

    console.log('Select Category - filteredCategories', filteredCategories)
    return filteredCategories.map(c => ({ label: c.name, value: c.id }))
  },
  [categories, excludeIds, clientType]
  )

  return (
    <Modal
      open={!!open}
      onClose={onInternalClose}
      title={'Add Document Category'}
      actions={
        [
          {
            type: 'main',
            label: 'CANCEL',
            action: onInternalClose,
          },
          {
            type: 'secondary',
            label: 'ADD',
            action: onInternalSave,
          },
        ]
      }
      propsClassses={classes.customButtonModalWidth}
      actionsDirection="row"
      content={
        <Box width={'425px'} my={4} display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" justifyContent="center" width={'100%'}>
            <FormControl variant="outlined" fullWidth error={!!errors['categoryId']}>
              <SelectSearchable
                label={'Select Category'}
                value={model.categoryId}
                onChange={onChange('categoryId')}
                options={categoriesOptions}
                error={!!errors['categoryId']}
                firstOption={{ label: 'Add New Category', value: 'custom' }}
              />
              { !!errors['categoryId'] &&
                <FormHelperText>{ errors['categoryId'] || null }</FormHelperText>
              }
            </FormControl>
          </Box>
          {isCustom && (
            <>
              <TextField
                value={model.name}
                onChange={onChange('name')}
                fullWidth
                label="Document Category Name"
                variant="outlined"
                error={!!errors['name']}
                helperText={!!errors['name'] && errors['name']}
              />
              <FormControl
                variant="outlined"
                fullWidth
                error={!!errors.usageTypes}
              >
                <InputLabel>Document Class</InputLabel>
                <SelectClearable
                  value={model.usageTypes}
                  onChange={onChange('usageTypes')}
                  clearable={false}
                  multiple
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null
                  }}
                  renderValue={
                    (selectedIds) => usageTypes
                      .filter(type => selectedIds.indexOf(type.value) !== -1)
                      .map(type => type.label)
                      .join(', ')}
                >
                  {usageTypes.map(type => (
                    <MenuItem key={type.value} value={type.value} disabled={type.value === clientType}>
                      <ListItemIcon>
                        <CheckboxLight
                          checked={model.usageTypes.indexOf(type.value) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        {type.label}
                      </ListItemText>
                    </MenuItem>
                  ))}
                </SelectClearable>
                {!!errors.usageTypes &&
                  <FormHelperText>{errors.usageTypes || null}</FormHelperText>
                }
              </FormControl>
            </>
          )}
        </Box>
      }
    />
  )
}
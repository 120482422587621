import React, { useState, useMemo } from 'react';
import {
  Box, Button, FormControl, FormControlLabel, FormHelperText,
  InputLabel, makeStyles, MenuItem, OutlinedInput, Switch, Typography
} from '@material-ui/core';
import Select from '@components/inputs/select-clearable';
import CustomTooltip from '@components/controls/tooltip';
import history from '@app/history';
import parseYupErrors from '@app/yup';
import * as yup from 'yup';
import useErrorState from '@utils/errorState';
import BillingCard from './billing-card';
import { Modal } from '@components/modals';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

const selectUser = state => state.auth.user;

const useStyles = makeStyles((theme) => ({
  switchlabel: {
    color: '#232323',
    '&.Mui-disabled': {
      color: '#232323'
    }
  },
  subtitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '18px',
    color: theme.palette.text.BL1,
    marginBottom: '15px',
  },
}));

const paymentOptions = [
  { label: 'Primary Credit Card', value: 'cc' },
]

const billingMethods = [
  {
    id: 1,
    clientName: 'John Smith',
    isPrimary: true,
    cardType: 'visa',
    number: '5698',
    expired: '12/20',
    fullName: 'John Smith',
    address: '235 W Santa Clara St, Ventura, CA 93001',
  },
  {
    id: 2,
    clientName: 'John Smith',
    isPrimary: false,
    cardType: 'visa',
    number: '5697',
    expired: '12/20',
    fullName: 'John Smith',
    address: '235 W Santa Clara St, Ventura, CA 93001',
  }
]

const schema = yup.object().shape({
  primaryEmail: yup.string().label('Primary Email').lowercase().trim().email('Invalid email format').required().max(50),
  secondaryEmail: yup.string().label('Secondary Email').lowercase().trim().email('Invalid email format').max(50).test('same_email', 'Primary and Secondary emails must be different', function () {
    return !this.parent.secondaryEmail || this.parent.primaryEmail !== this.parent.secondaryEmail
  }),
});

export default function BillingDetails(props) {
  const { baseRoute } = props;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const userData = useSelector(selectUser);
  const { side, userIsAdmin } = useSelector(store => store.auth);

  const isAdmin = useMemo(() => {
    return (side === 'admin' || userIsAdmin);
  }, [side, userIsAdmin])

  const [model, setModel] = useState({
    primaryEmail: isAdmin ? 'johns@abccorp.com' : userData.email,
    secondaryEmail: isAdmin ? 'eugen.l@email.com' : '',
  });
  const [sendInvoices, setSendInvoices] = useState(!isAdmin);
  const [autoPay, setAutoPay] = useState(!isAdmin);
  const [autoRenew, setAutoRenew] = useState(!isAdmin);
  const [paymentOption, setPaymentOption] = useState(paymentOptions[0].value);
  const [errors, setErrors] = useErrorState({});
  const [pendingDelete, setPendingDelete] = useState(null);
  const [pendingPrimary, setPendingPrimary] = useState(null);
  const [hasTyped, setHasUserTyped] = useState(false);

  const handleChange = (prop) => (event) => {
    const newModel = { ...model, [prop]: event.target.value };
    setModel(newModel);
    setHasUserTyped(true);

    if (errors[prop]) {
      schema.validateAt(prop, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [prop]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [prop]: err.message });
        });
    }

  };

  const handleSubmit = () => {

  }

  const onSubmit = () => {
    setErrors({});
    schema
      .validate(model, { abortEarly: false })
      .then(() => handleSubmit())
      .catch(err => setErrors(parseYupErrors(err), true));
  }
    
  const handleNew = () => {
    history.push(`${baseRoute}/add`);
  }

  const handleEdit = billing => () => {
    history.push(`${baseRoute}/edit/${billing.id}`);
  }

  const handlePendingDelete = billing => () => {
    setPendingDelete({ lastDigits: billing.number, id: billing.id });
  }

  const handleDelete = () => {
    enqueueSnackbar(`Billing method "${pendingPrimary.lastDigits}" has been successfully removed!`, { variant: 'success' });
    setPendingDelete(null);
  }

  const handlePendingPrimary = billing => () => {
    setPendingPrimary({ lastDigits: billing.number, id: billing.id });
  }

  const handlePrimary = () => {
    enqueueSnackbar(`Your primary card is changed to "${pendingPrimary.lastDigits}"!`, { variant: 'success' });
    setPendingPrimary(null);
  }

  return (
    <Box>
      <Box mb={1}>
        <Typography className={classes.subtitle}>Payment Settings</Typography>
      </Box>
      <Box mb={1}>
        <FormControlLabel
          control={
            <CustomTooltip
              title="Admin can only preview data"
              placement="top"
              disableHoverListener={!isAdmin}
            >
              <span>
                <Switch checked={sendInvoices} onClick={() => setSendInvoices(!sendInvoices)} disabled={isAdmin} />
              </span>
            </CustomTooltip>
          }
          label="Send Invoices and Payments Transactions"
          classes={{ label: classes.switchlabel }}
        />
      </Box>
      <Box mb={1}>
        <Typography variant="subtitle2" align="left" style={{ color: '#656565' }}>These emails will be used to send invoices and financial statements.</Typography>
      </Box>


      <Box display="flex">
        <Box width={300} mr={2}>
          <FormControl id="primaryEmail" variant="outlined" fullWidth error={errors.primaryEmail} disabled={!sendInvoices}>
            <InputLabel>Primary Email</InputLabel>
            <OutlinedInput
              value={model.primaryEmail}
              onChange={handleChange('primaryEmail')}
            />
            <FormHelperText>{errors.primaryEmail}</FormHelperText>
          </FormControl>
        </Box>
        <Box width={300} mr={2}>
          <FormControl id="secondaryEmail" variant="outlined" fullWidth error={errors.secondaryEmail} disabled={!sendInvoices}>
            <InputLabel>Secondary Email</InputLabel>
            <OutlinedInput
              value={model.secondaryEmail}
              onChange={handleChange('secondaryEmail')}
            />
            <FormHelperText>{errors.secondaryEmail}</FormHelperText>
          </FormControl>
        </Box>
        <Box pt={1}>
          <CustomTooltip
            title="Admin can only preview data"
            placement="top"
            disableHoverListener={!isAdmin}
          >
            <span>
              <Button
                variant="contained"
                size="large"
                onClick={!isAdmin && onSubmit}
                style={{ width: 160 }}
                disabled={!hasTyped || isAdmin}
              >
                {'UPDATE'}
              </Button>
            </span>
          </CustomTooltip>
        </Box>
      </Box>


      <Box mt={4} mb={1}>
        <Typography variant="subtitle2" align="left" style={{ color: '#656565' }}>Payments Automation Preferences</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box width={300} mr={2}>
          <FormControlLabel
            control={
              <CustomTooltip
                title="Admin can only preview data"
                placement="top"
                disableHoverListener={!isAdmin}
              >
                <span>
                  <Switch checked={autoPay} onClick={() => setAutoPay(!autoPay)} disabled={isAdmin} />
                </span>
              </CustomTooltip>
            }
            label="Auto-pay invoices"
            classes={{ label: classes.switchlabel }}
          />
          <FormControlLabel
            control={
              <CustomTooltip
                title="Admin can only preview data"
                placement="top"
                disableHoverListener={!isAdmin}
              >
                <span>
                  <Switch checked={autoRenew} onClick={() => setAutoRenew(!autoRenew)} disabled={isAdmin} />
                </span>
              </CustomTooltip>
            }
            label="Subscription plan Auto-Renew"
            classes={{ label: classes.switchlabel }}
          />
        </Box>
        <Box width={300} mr={2}>
          <FormControl id="paymentOption" variant="outlined" fullWidth>
            <InputLabel>Default Payment Option</InputLabel>
            <Select
              value={paymentOption}
              onChange={(e) => setPaymentOption(e.target.value)}
              clearable={false}
              disabled={isAdmin}
            >
              {paymentOptions.map(option => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Box mt={4} mb={2}>
        <Typography className={classes.subtitle}>Billing Methods</Typography>
      </Box>
      <Box display="flex">
        {billingMethods.map(billing => (
          <Box width={300} mr={2} key={billing.id}>
            <BillingCard data={billing} makePrimary={handlePendingPrimary(billing)} onEdit={handleEdit(billing)} onDelete={handlePendingDelete(billing)} isAdmin={isAdmin} />
          </Box>
        ))}
        {!isAdmin && (
          <Box width={300}>
            <BillingCard onAdd={handleNew} />
          </Box>
        )}
      </Box>


      <Modal
        open={!!pendingDelete?.id}
        onClose={() => setPendingDelete(null)}
        title="Delete Billing Method"
        mainText={`Are you sure you want to delete the card "${pendingDelete?.lastDigits}"?`}
        actions={
          [
            {
              type: 'main',
              label: 'NO',
              action: () => setPendingDelete(null),
              style: { width: 160 },
            },
            {
              type: 'secondary',
              label: 'YES',
              action: handleDelete,
              style: { width: 160 },
            },
          ]
        }
        actionsDirection="row"
      />

      <Modal
        open={!!pendingPrimary?.id}
        onClose={() => setPendingPrimary(null)}
        title="Make Primary"
        mainText={`Are you sure you want to make the card "${pendingPrimary?.lastDigits}" as the default billing method?`}
        actions={
          [
            {
              type: 'main',
              label: 'NO',
              action: () => setPendingPrimary(null),
              style: { width: 160 },
            },
            {
              type: 'secondary',
              label: 'YES',
              action: handlePrimary,
              style: { width: 160 },
            },
          ]
        }
        actionsDirection="row"
      />
    </Box>
  )
}

import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import {
  Box, Button, FormControlLabel, Grid, Switch,
  makeStyles, Typography, MenuItem
} from '@material-ui/core';
import { useParams } from 'react-router';

import history from '@app/history';
import { ChevronLeftIcon, InfoOtherIcon } from '@app/icons';

import Input from '@components/inputs/filled-input';
import Select from '@components/inputs/filled-select';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import InnerHeader from '@components/layout/inner-header';
import CustomTooltip from '@components/controls/tooltip';

const useStyles = makeStyles((theme) => ({
  required: {
    color: 'red',
  },
  fieldWrapper: {
    marginRight: 10,
  },
  formLabel: {
    textTransform: 'uppercase',
    fontSize: 12,
  },
  opacity1: {
    opacity: 0.4,
  },
  opacity2: {
    opacity: 0.2,
  },
  error2: {
    fontStyle: 'italic',
    color: '#B6514C',
    height: 20,
  },
}));

const tabs = [
  {
    id: 'person',
    label: 'People',
  },
  {
    id: 'company',
    label: 'Legal Entities',
  },
]

const personFields = [
  { label: 'First Name', value: 'personFirstName', required: true, width: 15 },
  { label: 'Last Name', value: 'personLastName', required: true, width: 15 },
  { label: 'Date of Birth', value: 'personDob', tooltip: 'Supported format: MM/DD/YYYY, YYYY-MM-DD', width: 15, },
  { label: 'Residency', value: 'personCountry', tooltip: 'Uses Alpha-2 codes (US, UK, etc) or full country name', width: 10 },
  { label: 'Gender', value: 'personGender', tooltip: 'Use male, m, female, f', width: 15 },
  { label: 'External ID', value: 'externalId', tooltip: '', width: 15 },
]

const personFields2 = [
  { label: 'Full Name', value: 'personFullName', required: true, tooltip: 'Must be two or more words', width: 30 },
  { label: 'Date of Birth', value: 'personDob', tooltip: 'Supported format: MM/DD/YYYY, YYYY-MM-DD', width: 15 },
  { label: 'Residency', value: 'personCountry', tooltip: 'Uses Alpha-2 codes (US, UK, etc) or full country name', width: 10 },
  { label: 'Gender', value: 'personGender', tooltip: 'Use male, m, female, f', width: 15 },
  { label: 'External ID', value: 'externalId', tooltip: '', width: 15 },
]

const companyFields = [
  { label: 'Legal Entity Name', value: 'entityName', required: true, width: 30 },
  { label: 'Entity Type', value: 'entityType', required: true, tooltip: 'Uses Corporation, Foundation, Partnership, Trust, or Limited Liability Company', width: 20 },
  { label: 'Entity Country', value: 'entityCountry', tooltip: 'Uses Alpha-2 codes (US, UK, etc) or full country name', width: 15 },
  { label: 'Registration Number', value: 'entityNumber', width: 20 },
  { label: 'External ID', value: 'externalId', tooltip: '', width: 15 },
]

export default function ImportCSVMapping(props) {
  const {
    parsedFile, type, fileHeaders,
    skipFirstRow, setSkipFirstRow,
    fieldMapping, setFieldMapping,
    fullName, setFullName, setOmittedRows
  } = props;
  const classes = useStyles();

  const { tab } = useParams();
  const [tabId, setTabId] = useState(tab || 'person');

  useEffect(() => {
    if (tab) {
      setTabId(tab);
    }
  }, [tab])

  const onTabChange = (index) => {
    const tabId = tabs[index].id;
    setTabId(tabId);
    history.replace(`/portfolio/import/mapping/${tabId}`)
  }

  const handleChange = (field) => (event) => {
    setFieldMapping({ ...fieldMapping, [field]: event.target.value });
  }

  const fieldsToUse = useMemo(() => {
    if (tabId === 'person') {
      return fullName ? personFields2 : personFields;
    }
    return companyFields
  }, [fullName, tabId])

  const typeOptions = useMemo(() => {
    if (!parsedFile?.length || type !== 'both') return []

    const options = [];
    parsedFile.forEach(row => {
      const value = row[fieldMapping.type];
      if (!options.includes(value)) {
        options.push(value);
      }
    })
    return options.map(e => ({ value: e, label: e }));
  }, [parsedFile, fieldMapping.type, type])

  const parsedFileToUse = useMemo(() => {
    if (type !== 'both') return parsedFile;

    return parsedFile.filter(row => {
      const typeKey = fieldMapping.type;
      const typeValue = tabId === 'person' ? fieldMapping.personType : fieldMapping.companyType;

      return row[typeKey] === typeValue;
    })
  }, [parsedFile, fieldMapping, tabId, type])

  const options = useMemo(() => {
    if (!parsedFile?.length || !fileHeaders) return []

    const options = [];
    Object.entries(fileHeaders).forEach(([key, value]) => {
      options.push({ value: key, label: value });
    })
    return options;
  }, [parsedFile, fileHeaders])

  const filteredOptions = useMemo(() => {
    if (type !== 'both' || !skipFirstRow) return options;
    if (!parsedFileToUse?.length || !fileHeaders) return []

    const filteredOptions = [];
    Object.entries(fileHeaders).forEach(([key, value]) => {
      filteredOptions.push({ value: key, label: value });
    })
    return filteredOptions;
  }, [parsedFileToUse, options, type, skipFirstRow, fileHeaders])

  const mappingErrors = useMemo(() => {
    const errors = []
    const checkPersonError = () => {
      if (fullName) {
        if (!filteredOptions.find(e => e.value === fieldMapping.personFullName)) {
          errors.push('Column FULL NAME must be mapped.')
        }
      } else {
        if (!filteredOptions.find(e => e.value === fieldMapping.personFirstName)) {
          errors.push('Column FIRST NAME must be mapped.')
        }
        if (!filteredOptions.find(e => e.value === fieldMapping.personLastName)) {
          errors.push('Column LAST NAME must be mapped.')
        }
      }

      if (!filteredOptions.find(e => e.value === fieldMapping.personCountry)) {
        errors.push('Column RESIDENCY must be mapped.')
      }
    }

    const checkCompanyError = () => {
      if (!filteredOptions.find(e => e.value === fieldMapping.entityName)) {
        errors.push('Column LEGAL ENTITY NAME must be mapped.')
      }
      if (!filteredOptions.find(e => e.value === fieldMapping.entityType)) {
        errors.push('Column LEGAL ENTITY TYPE must be mapped.')
      }
    }

    const checkTypeError = () => {
      if (!filteredOptions.find(e => e.value === fieldMapping.type)) {
        errors.push('Column ENTITY TYPE must be mapped.')
      }
      if (!typeOptions.find(e => e.value === fieldMapping.personType)) {
        errors.push('Column ENTITY VALUE (People) must be mapped.')
      }
      if (!typeOptions.find(e => e.value === fieldMapping.companyType)) {
        errors.push('Column ENTITY VALUE (Legal Entities) must be mapped.')
      }
    }

    switch (type) {
      case 'person':
        checkPersonError()
        break;
            
      case 'company':
        checkCompanyError()
        break;
            
      case 'both':
        checkTypeError();
        checkPersonError();
        checkCompanyError();
        break;

      default:
        break;
    }

    return errors
  }, [type, fieldMapping, filteredOptions, fullName, typeOptions])

  const handleNext = () => {
    if (type === 'both' && tabId === 'person') {
      history.replace('/portfolio/import/mapping/company')
    } else {
      setOmittedRows({ person: {}, company: {} })
      history.push(`/portfolio/import/confirm/${type === 'company' ? 'company' : 'person'}`)
    }
  }

  return (
    <Box height="100%" px={6}>
      <Box>
        <InnerHeader
          title="Confirm Data Mapping"
          buttons={
            <React.Fragment>
              <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={() => { history.goBack() }}>
                {'Back'}
              </ButtonWithIcon>
            </React.Fragment>
          }
        />
      </Box>
      {type === 'both' &&
                <Box>
                  <InnerHeader
                    onTabChange={onTabChange}
                    ind={tabs.findIndex(tab => tab.id === tabId)}
                    tabs={tabs}
                  />
                </Box>
      }
      <Box height={type === 'both' ? 'calc(100% - 196px)' : 'calc(100% - 98px)'} style={{ overflowY: 'auto' }}>
        <Box mt={2}>
          <Grid container alignItems="flex-end">
            {type === 'both' &&
                            <Box className={classes.fieldWrapper} style={{ width: 120 }}>
                              <Grid container alignItems="center">
                                <Typography className={classes.formLabel} variant="h5">
                                        Entity Type<span className={classes.required}>*</span>
                                </Typography>
                                <CustomTooltip
                                  title="Determines which column should be used to detect types: person or legal entity"
                                  placement="top"
                                >
                                  <InfoOtherIcon />
                                </CustomTooltip>
                              </Grid>
                              <Select
                                value={fieldMapping.type}
                                onChange={handleChange('type')}
                                fullWidth
                              >
                                {options.map(option => (
                                  <MenuItem key={option.value} value={option.value}>{option.label || '<Empty>'}</MenuItem>
                                ))}
                              </Select>
                            </Box>
            }
            {type === 'both' &&
                            <Box className={classes.fieldWrapper} style={{ width: 120 }}>
                              <Grid container alignItems="center">
                                <Typography className={classes.formLabel} variant="h5">
                                        Entity Value<span className={classes.required}>*</span>
                                </Typography>
                                <CustomTooltip
                                  title={`Determines which value, under Type column is applicable for ${tabId === 'person' ? 'person' : 'legal entity'}`}
                                  placement="top"
                                >
                                  <InfoOtherIcon />
                                </CustomTooltip>
                              </Grid>
                              <Select
                                value={tabId === 'person' ? fieldMapping.personType : fieldMapping.companyType}
                                onChange={handleChange(tabId === 'person' ? 'personType' : 'companyType')}
                                fullWidth
                              >
                                {typeOptions.map(option => (
                                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                ))}
                              </Select>
                            </Box>
            }
            {type !== 'both' &&
                            <FormControlLabel
                              control={<Switch checked={skipFirstRow} onChange={e => setSkipFirstRow(!skipFirstRow)} />}
                              label="Skip the Header in 1st row"
                            />
            }
            {tabId === 'person' &&
                            <FormControlLabel
                              style={{ marginLeft: 16 }}
                              control={<Switch checked={fullName} onChange={e => setFullName(!fullName)} />}
                              label="Full Name in one column"
                            />
            }
          </Grid>
        </Box>
        <Box mt={3}>
          <Typography variant="subtitle1">We have selected a row from the top of your file as an example. Please select the columns which correspond to the correct field.</Typography>
        </Box>
        <Box mt={3}>
          <Grid container alignItems="center">
            {fieldsToUse.map(field => (
              <Box
                key={field.value}
                className={classes.fieldWrapper}
                style={{ width: `calc(${field.width}% - 10px)` }}
              >
                <Grid container alignItems="center">
                  <Typography className={classes.formLabel} variant="h5">
                    {field.label}
                    {field.required && <span className={classes.required}>*</span>}
                  </Typography>
                  {field.tooltip &&
                                        <CustomTooltip
                                          title={field.tooltip}
                                          placement="top"
                                        >
                                          <InfoOtherIcon />
                                        </CustomTooltip>
                  }
                </Grid>
              </Box>
            ))}
          </Grid>
          <Grid container>
            {fieldsToUse.map(field => (
              <Box
                key={field.value}
                className={classes.fieldWrapper}
                style={{ width: `calc(${field.width}% - 10px)` }}
              >
                <Select
                  value={fieldMapping[field.value]}
                  onChange={handleChange(field.value)}
                  fullWidth
                >
                  {filteredOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>{option.label || '<Empty>'}</MenuItem>
                  ))}
                  {!field.required && <MenuItem value="skipThisField">(Leave Empty)</MenuItem>}
                </Select>
              </Box>
            ))}
          </Grid>
        </Box>
        <Box mt={3}>
          <Typography variant="subtitle1">As a guide, the first 5 rows have been extracted from your file: </Typography>
        </Box>
        <Box mt={3}>
          {[...Array(5)].map((a, index) => (
            <Grid
              key={index}
              container
              className={clsx(
                index === 3 && classes.opacity1,
                index === 4 && classes.opacity2,
              )}
            >
              {fieldsToUse.map(field => (
                <Box
                  key={field.value}
                  className={classes.fieldWrapper}
                  style={{ width: `calc(${field.width}% - 10px)` }}
                >
                  <Input
                    value={parsedFileToUse[index] ? parsedFileToUse[index][fieldMapping[field.value]] || '' : ''}
                    className={classes.field}
                    readOnly
                    fullWidth
                  />
                </Box>
              ))}
            </Grid>
          ))}
        </Box>
        {!!mappingErrors.length &&
                    <Box mt={2} mb={2}>
                      {mappingErrors.map(err => (
                        <Typography key={err} className={classes.error2} variant="subtitle2">{err}</Typography>
                      ))}
                    </Box>
        }
        <Box mt={4} mb={4}>
          <Grid container alignItems="center" justify="center">
            <Button
              variant="contained"
              size="large"
              disabled={!!mappingErrors.length}
              onClick={handleNext}
            >
              {'Next'}
            </Button>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

import React from 'react';

import {Box, Button, Link} from '@material-ui/core';
import { Logo } from '@app/icons';
import history from '@app/history';

export default function SidesLayout(props) {
  let leftSide, rightSide;

  if(Array.isArray(props.children)){
    leftSide = props.children.find(component => component.type.name === 'SidesLayoutLeft') || '';
    rightSide = props.children.find(component => component.type.name === 'SidesLayoutRight') || '';
  } else {
    rightSide = props.children;
  }

  return (
    <Box display="flex" className="sides-layout" height="100vh">
      <Box display={{ xs: 'none', sm: 'flex' }} minHeight="100vh" className="layout-side layout-side-left">
        <Box display="flex" justifyContent="center" alignItems="center" className="top-half-overlay">
          <Button onClick={ () => history.push('/') } className="logo-button" disableRipple>
            <Logo className="logo" />
          </Button>
        </Box>
        <Box className="bottom-half-overlay">
          <Box display="flex" justifyContent="center" alignItems="flex-end" height="95%">
            <Link
              variant={'body1'}
              style={{ fontSize: 10 }}
              href="/terms-of-use"
              target="_blank"
            >
                            Terms and Conditions
            </Link>
            <Box mx={1} height="18px" width="1px" style={{ backgroundColor: 'black' }}></Box>
            <Link
              variant={'body1'}
              style={{ fontSize: 10 }}
              href="/privacy-policy"
              target="_blank"
            >
                            Privacy Policy
            </Link>
          </Box>
        </Box>
        { leftSide }
      </Box>
      <Box display="flex" className="layout-side layout-side-right">
        { rightSide }
      </Box>
    </Box>
  );
}
import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';
import { SearchResultsOtherIcon } from '@app/icons';

export default function NoResultsFound(props) {

  const { palette } = useTheme()
  const { children, icon } = props

  return (
    <Box display={'flex'}>
      <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
        <Box display={'flex'} justifyContent={'center'}>
          {icon || <SearchResultsOtherIcon />}
        </Box>
        <Box display={'flex'} justifyContent={'center'} mt={4}>
          <Typography variant="h3" style={{color: palette.text.GY4}}>
            {children || 'No Results Found.'}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
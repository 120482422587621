import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import ButtonWithIcon from '@components/buttons/button-with-icon';

import {ChevronLeftIcon} from '@app/icons';
import history from '@app/history';

const useStyles = makeStyles((theme) => ({
  backButtonRoot: {
    margin: '30px 0 0 25px',
    textTransform: 'none',
  },
  startIcon: {
    marginRight: 0,
  },
  backButtonText: {
    fontSize: 12,
  },
  backButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '30px',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '30px',
    marginBottom: theme.spacing(2),
    color: theme.palette.text.LB3
  },
}));

const BackButton = ({ path, wrapped, action, customButtonClasses }) => {
  const classes = useStyles();

  return (
    <Box className={clsx({
      [classes.backButtonWrapper]: wrapped
    })}>
      <ButtonWithIcon
        classes={{ root: clsx(classes.backButtonRoot, customButtonClasses), startIcon: classes.startIcon }}
        startIcon={<ChevronLeftIcon />}
        onClick={() => action ? action() : path ? history.push(path) : history.goBack()}
      >
        <span className={classes.backButtonText}>Back</span>
      </ButtonWithIcon>
    </Box>
        
  )
}

export default BackButton;

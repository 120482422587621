import { createMuiTheme } from '@material-ui/core/styles';
import common from './common'
import admin from './admin'

const resolveTheme = (side, userIsAdmin) =>
  (side === 'admin')
    ? createMuiTheme(common, admin)
    : userIsAdmin
      ? createMuiTheme(common, admin)
      : createMuiTheme(common)

/**
 *
 * Use function to extend custom component class with font styles like
 *
 * customClass: {
 *     ...getColorStyles(color.text.LB1)
 * }
 *
 * @param color color from object below
 * @param isBackground whether we need styles for background
 * @param isImportant whether need use this style as !important
 * @returns {{background: *}|{WebkitTextFillColor: string, WebkitBackgroundClip: string, background: *}|{color: *}}
 */
export function getColorStyles(color, isBackground = false, isImportant = false) {
  const isGradient = color.indexOf('gradient') !== -1;
  if(isImportant) {
    color += ' !important';
  }
  if (isGradient) {
    return isBackground
      ? { background: color }
      : {
        background: color,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
      }
  } else {
    return isBackground
      ? { background: color }
      : { color }
  }
}

export default resolveTheme
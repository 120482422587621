import React from 'react';
import InlineBadge from '@components/badges/inline-badge';
import { CLASS_COLOR } from '../constants';

export const badgeComponent = (key, value, styleBadgeRoot) => {
  return (
    <InlineBadge color={CLASS_COLOR[key]} styleBadgeRoot={styleBadgeRoot}>
      {value || key}
    </InlineBadge>
  )
}
import React from 'react';

import {
  DocumentsPhotoID,
  DocumentsProofOfAddress,
  DocumentsCustomDocument,
  DocumentsLegalEntities,
  DocumentsOtherDocument
} from '@app/icons';

const categoryIconAssoc = {
  'photo id': <DocumentsPhotoID />,
  'proof of address': <DocumentsProofOfAddress />,
  'legal entities': <DocumentsLegalEntities />,
}

const categoryIconAssocSmall = {
  'photo id': <DocumentsPhotoID style={{ width: 24, height: 16 }} />,
  'proof of address': <DocumentsProofOfAddress style={{ width: 24, height: 16 }} />,
  'legal entities': <DocumentsLegalEntities style={{ width: 24, height: 16 }} />,
}

const FALLBACK_ICON = <DocumentsOtherDocument />
const FALLBACK_ICON_SMALL = <DocumentsOtherDocument style={{ width: 24, height: 16 }} />

export const getIconByCategoryName = (category, small) => {
  if (category.isCustom) {
    return small
      ? <DocumentsCustomDocument style={{ width: 24, height: 16 }} />
      : <DocumentsCustomDocument />
  } else {
    return small
      ? categoryIconAssocSmall[category.name.toLowerCase()] || FALLBACK_ICON_SMALL
      : categoryIconAssoc[category.name.toLowerCase()] || FALLBACK_ICON
  }
}

export function resolveCategoryIconPipe(categories) {
  return categories.map(category => ({
    ...category,
    icon: getIconByCategoryName(category),
    iconSmall: getIconByCategoryName(category, true)
  }))
}
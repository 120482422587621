import React, { useMemo, useState } from 'react';
import { CarbonViewFilledIcon } from '@app/icons';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { Box, FormControlLabel, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomTooltip from '@components/controls/tooltip';
import { clientApi } from '@app/api';
import prepareTimestamp from '@utils/date';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Modal } from '@components/modals';
import ModalContent from '../../modal-content';


export const useStyles = makeStyles((theme) => ({
  whitelistedButton: {
    padding: 0,
    minWidth: 0,
    marginLeft: '8px'
  },
  wrapperLabels: {
    marginBottom: '2px',
    alignItems: 'flex-start'
  },
}));

export default function UserInfoCardWhitelisted(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { getCurrentProfile } = props;

  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [profileId] = useState(search.get('profileId'));

  const [openModal, setOpenModal] = useState(null);
  const [whitelistData, setWhitelistData] = useState(null);

  const { profilesVersionId } = useSelector(store => store.searchHistory);
  const { selectedProfile } = useSelector(store => store.backgroundCheck);

  const whitelistedData = useMemo(() => {
    const profileWhitelistNotes = selectedProfile.notes?.filter(el => el.noteType === 'profileWhitelist') || [];
    return ({
      value: 'On',
      reason: selectedProfile.whitelistedReason,
      note: profileWhitelistNotes[profileWhitelistNotes.length - 1] || ''
    })
  }, [selectedProfile]);


  const handleChangeWhitelist = async (shouldWhitelist) => {

    const data = {
      profileId,
      payload: {
        bgCheckRiskRating: selectedProfile.bgCheckRiskRating,
        whitelisted: shouldWhitelist,
        whitelistedReason: whitelistData?.label || null
      },
    }
    const res = await clientApi.profile.updateProfileReview(data);

    if (res.status === 200) {
      if (whitelistData?.note?.note && whitelistData?.note?.note.trim()) {
        if (!!whitelistData?.note?.id) {
          const noteId = whitelistData?.note?.id;
          await clientApi.profile.updateNote(
            profileId,
            noteId,
            { note: whitelistData.note.note, noteType: 'profileWhitelist' }
          );
        } else {
          await clientApi.profile.addNote(
            profileId,
            { note: whitelistData.note.note, noteType: 'profileWhitelist' }
          );
        }
      }

      enqueueSnackbar(`Profile "${selectedProfile.name}" was successfully ${data.payload.whitelisted ? 'added to' : 'removed from'} whitelist!`, { variant: 'success' });
      getCurrentProfile();
      setWhitelistData(null);
      setOpenModal(null);
    }
  }

  const onCancel = () => {
    setOpenModal(null);
    setWhitelistData(null);
  }

  const handleWhitelistActions = () => {
    if (selectedProfile.isWhitelisted) {
      handleChangeWhitelist(false);
    } else {
      setOpenModal(true);
    }
  }

  return (
    <Box display="flex" alignItems="center">
      <Modal
        open={!!openModal}
        onClose={() => setOpenModal(null)}
        title="Profile Whitelist"
        actions={
          [
            {
              type: 'main',
              label: 'Cancel',
              action: onCancel,
            },
            {
              type: 'secondary',
              label: 'Whitelist',
              action: () => handleChangeWhitelist(true),
              disabled: profilesVersionId || !whitelistData
            },
          ]
        }
        propsClassses={classes.customButtonModalWidth}
        actionsDirection="row"
        content={<ModalContent setData={setWhitelistData} type="profile" data={whitelistedData} disabled={profilesVersionId} />}
      />

      <FormControlLabel
        control={
          <Switch
            value={!!selectedProfile.isWhitelisted}
            onChange={handleWhitelistActions}
            checked={!!selectedProfile.isWhitelisted}
          />
        }
        label="Whitelist"
        disabled={!!profilesVersionId}
      />
      {selectedProfile.isWhitelisted &&
        <CustomTooltip
          title={
            <p style={{ textAlign: 'start' }}>
              Whitelisted Reason: {selectedProfile.whitelistedReason} <br />
              Whitelisted At: {prepareTimestamp(selectedProfile.whitelistedAt, 'MM/DD/YYYY hh:mm A')} <br />
            </p>
          }
          placement="top"
        >
          <span style={{ marginRight: '10px' }}>
            <ButtonWithIcon
              className={classes.whitelistedButton}
              onMouseDown={() => setOpenModal(true)}
            >
              <CarbonViewFilledIcon width={'16px'} />
            </ButtonWithIcon>
          </span>
        </CustomTooltip>
      }
    </Box>
  )
}



import React from 'react';
import {
  Box, makeStyles, Typography
} from '@material-ui/core';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import CustomChip from '@components/controls/custom-chip';

const useStyles = makeStyles((theme) => ({
  regular: {
    color: '#4E922C',
    fontWeight: 'bold'
  },
  changeRoot: {
    padding: 0,
    margin: '-3px 3px 0 3px'
  },
  startIcon: {
    marginRight: '2px',
  }
}));

export default function SubscriptionPlan({ isAdmin, name, upgradeAction, renewAction, chipLabel }) {
  const classes = useStyles();

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent={isAdmin && 'space-between'}>
        <Typography variant="h5">SUBSCRIPTION PLAN</Typography>
        {chipLabel && (
          <Box ml={1} mb={'5px'}>
            <CustomChip label={chipLabel} type={chipLabel === 'Requested' ? 'ERROR' : 'ADDED'} />
          </Box>
        )}
        {isAdmin && (
          <Box>
            {upgradeAction && (
              <ButtonWithIcon
                onClick={() => upgradeAction('upgrade')}
                classes={{ root: classes.changeRoot, startIcon: classes.startIcon }}
              >
                                Upgrade
              </ButtonWithIcon>
            )}
            {renewAction && (
              <ButtonWithIcon
                onClick={() => renewAction('renew')}
                classes={{ root: classes.changeRoot, startIcon: classes.startIcon }}
              >
                                Renew
              </ButtonWithIcon>
            )}
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="h3">{name || 'Basic'}</Typography>
      </Box>
    </Box>
  )
}

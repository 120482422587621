import React, {useEffect, useMemo, useState} from 'react';

import {
  Box,
  Button, capitalize,
  Grid,
  Typography
} from '@material-ui/core';
import {
  ChevronLeftIcon
} from '@app/icons';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import LinkButton from '@components/buttons/link-button';
import InnerHeader from '@components/layout/inner-header';
import PageLayout from '@components/layout/page-layout';
import history from '@app/history';
import {clientApi} from '@app/api';
import { useStyles as labelsStyles } from '@components/layout/labels-for-render';
import prepareTimestamp from '@utils/date';
import NextReviewPicker from '@components/calendar/next-date-picker';
import grey from '@material-ui/core/colors/grey';
import {prepareClient} from '@dto/client';
import RiskRatingIcon from '@components/flexible-icons/risk-rating-icon';
import moment from 'moment';

export default function UpdatePeriodicReviews(props) {

  const {id} = props.computedMatch.params

  useEffect(() => {
    clientApi.client.show(id).then(({data}) => {
      const preparedClientData = prepareClient(data)
      setClientData(
        preparedClientData
      )
      if(preparedClientData?.next_review_at && (new Date(preparedClientData?.next_review_at) > new Date())) {
        setNextReview(prepareTimestamp(preparedClientData.next_review_at, 'MM/DD/YYYY'))
      }
    })
    // eslint-disable-next-line
    }, [])

  const labelsClasses = labelsStyles()

    const [clientData, setClientData] = useState() //eslint-disable-line

  const lastReview = useMemo(() => {
    return clientData?.last_review_at
      ? prepareTimestamp(clientData.last_review_at, 'MM/DD/YYYY')
      : null
  }, [clientData])

  const [nextReview, setNextReview] = React.useState(null)

  const onSave = () => {
    if( nextReview ) {
      clientApi.client.updateNextReview(
        clientData.id,
        moment(nextReview).format('YYYY-MM-DD')
      ).then(() => {
        history.goBack()
      })
    }
  }

  const onBack = () => {
    history.goBack()
  }

  return (
    <React.Fragment>
      <PageLayout>
        <Box px={6}>
          <InnerHeader
            title={'Edit Periodic Review'}
            buttons={
              <React.Fragment>
                <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={onBack}>
                                    Back
                </ButtonWithIcon>
              </React.Fragment>
            } />
        </Box>

        <Box display={'flex'} mt={6} style={{overflowY: 'scroll'}}>
          <Grid container justify={'center'}>
            <Grid item xs={4}>
              <Box display={'flex'} flexDirection={'column'}>
                <Box display={'flex'}>
                  <Typography variant={'subtitle2'} style={{fontWeight: 'bold'}}>
                                        Period Review Details
                  </Typography>
                </Box>
                <Box mt={2} display={'flex'} justifyContent={'space-between'}>
                  <Box display={'flex'} alignItems={'center'}>
                    <Typography style={{width:'100px', maxWidth:'100px'}} className={labelsClasses.labelField}>
                                            RISK RATING
                    </Typography>
                    <Box display={'flex'} alignItems={'center'}>
                      <RiskRatingIcon
                        risk={clientData?.risk_score}
                        iconProps={{style: {marginTop: '3px', marginRight: '5px'}}}
                      />
                                            &nbsp;
                      <Typography classes={{root: labelsClasses.labelValue}}>
                        {capitalize(clientData?.risk_score || 'N/A')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display={'flex'} alignItems={'center'}>
                    <Typography style={{width:'100px', maxWidth:'100px'}} className={labelsClasses.labelField}>
                                            LAST REVIEW DATE
                    </Typography>
                    <Box display={'flex'} alignItems={'center'}>
                      <Typography classes={{root: labelsClasses.labelValue}}>
                        {lastReview || 'N/A'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box mt={2} display={'flex'}>
                  <NextReviewPicker
                    value={nextReview || ''}
                    defaultValue={4}
                    onChange={setNextReview}
                  />
                </Box>
                { nextReview &&
                                    <Box mt={1} display={'flex'} justifyContent={'center'}>
                                      <Typography variant={'caption'} style={{color: grey[500]}}>
                                            Review scheduled for {prepareTimestamp(nextReview, 'MM/DD/YYYY')}
                                      </Typography>
                                    </Box>
                }

                <Box mt={10} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                  <Box display={'flex'}>
                    <Button
                      style={{width: '160px'}}
                      variant={'contained'}
                      onClick={onSave}
                      type="submit"
                      disabled={!nextReview}
                    >
                                            SAVE
                    </Button>
                  </Box>
                  <Box display={'flex'} mt={1}>
                    <LinkButton onClick={onBack}>
                                            CANCEL
                    </LinkButton>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </PageLayout>
    </React.Fragment>
  )
}
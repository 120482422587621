const setUser = user => ({
  type: 'SET_USER',
  user: user
})
const setSide = side => ({
  type: 'SET_SIDE',
  side: side
})
const setAdmin = userIsAdmin => ({
  type: 'SET_ADMIN',
  userIsAdmin
})
const setLastVisitedPage = url => ({
  type: 'SET_LAST_VISITED_PAGE',
  lastVisitedPage: url
})

const auth = {
  setUser,
  setSide,
  setAdmin,
  setLastVisitedPage,
}

export default auth;
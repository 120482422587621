import React, { useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';

import {
  Box, Typography, Paper, makeStyles, IconButton
} from '@material-ui/core';
import { AddActive2Icon, ChevronLeftIcon } from '@app/icons';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import history from '@app/history';
import { eidv } from '@app/api/client';
import { get } from 'lodash';
import downloadUrl from '@utils/downloadUrl';
import casesDto, { getDocType } from './dto';

import EVSelect from './components/eidv-dropdown';
import EidvItemsWrapper from './components/eidv-items-wrapper';
import { useParams } from 'react-router-dom';
import EVOverview from './subpages/overview';
import EVBasicDetails from './subpages/basic-details';
import EVFacialMatch from './subpages/facial-match';
import EVPhotoDocument from './subpages/photo-document';
import EVAddressInformation from './subpages/address-information';
import EVProofOfAddress from './subpages/proof-of-address';
import PdfPreview from '../../../pdf/pdf-preview';
import EVRepeatDialog from './components/eidv-repeat-dialog';
import { useSnackbar } from 'notistack';
import EVApproveDialog from './components/eidv-finish-dialog';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 'calc(100% - 48px - 24px)',
    borderRadius: '12px',
    boxShadow: '0px 5px 26px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    marginTop: 24,
    display: 'flex',
    width: '100%',
  },
  containerNoClient: {
    height: 'calc(100% - 48px - 16px - 24px - 8px)',
  }
}));

export default function ElectronicVerificationView(props) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { caseId, tab } = useParams();
  const {
    path,
    cases, fetchCases,
    selectedCaseId, setSelectedCaseId,
    data, readonly,
    updateEidvStatus, fetchClient, noClient,
    redirectOnComplete
  } = props;

  const selectedTab = tab || 'overview';
  const [showRepeatModal, setShowRepeatModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [selectedCase, setSelectedCase] = useState({});

  useEffect(() => {
    if (!caseId && cases.length) {
      const id = cases[0].id
      history.replace(`${path}/${id}/overview`)
    }
    if (caseId && !tab) {
      history.replace(`${path}/${caseId}/overview`)
    }
    if (caseId) {
      setSelectedCaseId(+caseId)
    }
  }, [caseId, tab, cases, path, setSelectedCaseId])

  useEffect(() => {
    if (!selectedCaseId) {
      return {}
    } else {
      fetchData();
    }
  }, [selectedCaseId]) // eslint-disable-line

  const fetchData = () => {
    eidv.show(selectedCaseId).then(res => {
      const newCase = casesDto.read(res.data);

      // If the status changes, we should update the dropdown and the status on portfolio
      if (selectedCase.id && newCase.status !== selectedCase.status) {
        fetchCases && fetchCases();
        if (newCase.status === 'completed') {
          fetchClient && fetchClient(true);
          if (redirectOnComplete) {
            history.push(`/portfolio/view/${res.data.clientId}/electronic-verification`)
          }
        } else {
          updateEidvStatus && updateEidvStatus();
        }
      }
      setSelectedCase(casesDto.read(res.data))
    })
  }

  const tabs = useMemo(() => {
    return {
      'overview': (props) => <EVOverview {...props} />,
      'basic-details': (props) => <EVBasicDetails {...props} />,
      'photo-document': (props) => <EVPhotoDocument {...props} />,
      'facial-match': (props) => <EVFacialMatch {...props} />,
      'address-information': (props) => <EVAddressInformation {...props} />,
      'proof-of-address': (props) => <EVProofOfAddress {...props} />,
    }
  }, [])

  const useFullPage = useMemo(() => {
    return [
      'photo-document',
      'facial-match',
      'address-information'
    ].includes(selectedTab)
  }, [selectedTab])

  // To download images, we have to refresh the data as the s3 url
  // has most likely expired.
  const handleDownload = (path, fileName) => {
    eidv.show(selectedCaseId).then(res => {
      const selectedCase = casesDto.read(res.data);
      const imageUrl = get(selectedCase, path);
      const type = getDocType(imageUrl);
      const ext = type === 'img' ? '.png' : '.pdf';
      downloadUrl(imageUrl, fileName + ext);
    })
  }

  const handleRepeat = () => {
    setShowRepeatModal(true);
  }

  const handleApprove = () => {
    setShowApproveModal(true);
  }

  const onRepeat = (data) => {
    eidv.repeatCase(data, selectedCaseId).then(res => {
      enqueueSnackbar('Repeat eIDV request was submitted successfully!', { variant: 'success' })
      setShowRepeatModal(false);
      fetchData();
    })
  }

  // Get from client data, or get from eidv data
  const clientData = useMemo(() => {
    if (data?.name) {
      return data;
    } else {
      return {
        ...selectedCase.basicDetails,
        name: selectedCase?.basicDetails?.name ? `${selectedCase.basicDetails.name} ${selectedCase.basicDetails.lastName}` : undefined,
      }
    }
  }, [data, selectedCase])

  return (
    <Box height="100%" position="relative">
      <EVRepeatDialog
        open={showRepeatModal}
        onClose={() => setShowRepeatModal(false)}
        steps={selectedCase?.steps || []}
        selectedStep={selectedTab}
        onSave={onRepeat}
      />

      <EVApproveDialog
        open={showApproveModal}
        onClose={() => setShowApproveModal(false)}
        steps={selectedCase?.steps || []}
        fetchData={fetchData}
        data={selectedCase}
      />

      {!noClient &&
        <Box display="flex" alignItems="center">
          <Typography variant="h3">Electronic Verification</Typography>
          <Box ml={3} width={450}>
            <EVSelect
              cases={cases}
              selectedCaseId={selectedCaseId}
              setSelectedCaseId={setSelectedCaseId}
              path={path}
            />
          </Box>
          {!readonly &&
            <Box pr={3} marginLeft="auto">
              <ButtonWithIcon
                startIcon={<AddActive2Icon />}
                onClick={() => history.push(`${path}/new`)}
              >
                New eIDV
              </ButtonWithIcon>
            </Box>
          }
        </Box>
      }

      {noClient &&
        <Box mt={2} display="flex" alignItems="center">
          <IconButton onClick={() => history.goBack()}>
            <ChevronLeftIcon />
          </IconButton>
          <Typography variant="h3">{clientData.name || selectedCase.email}</Typography>
        </Box>
      }

      <Paper className={clsx(classes.container, noClient && classes.containerNoClient)}>
        <EidvItemsWrapper
          items={selectedCase?.steps || []}
          path={`${path}/${caseId}`}
          show={!useFullPage}
          selected={selectedTab}
          pending={selectedCase?.status === 'pending approval'}
        />
        <Box
          p={3}
          width={`calc(100% - ${selectedTab === 'overview' ? 218 : 42}px)`}
          display="flex"
          flexDirection="column"
          maxHeight="100%"
          overflow="auto"
        >
          {tabs[selectedTab]({ data: selectedCase, handleDownload, clientData, fetchData, handleRepeat, handleApprove, readonly })}
        </Box>
      </Paper>
      {/* Preload images and documents to cache them */}
      {selectedCase?.addressInfo?.addressDocumentUrl && <img alt="loader" src={selectedCase.addressInfo.addressDocumentUrl} style={{ display: 'none' }} />}
      {selectedCase?.photoId?.documentFrontUrl && <img alt="loader" src={selectedCase.photoId.documentFrontUrl} style={{ display: 'none' }} />}
      {selectedCase?.photoId?.documentBackUrl && <img alt="loader" src={selectedCase.photoId.documentBackUrl} style={{ display: 'none' }} />}
      {selectedCase?.facialMatch?.documentUrl && <img alt="loader" src={selectedCase.facialMatch.documentUrl} style={{ display: 'none' }} />}
      {selectedCase?.facialMatch?.faceDocumentUrl && <img alt="loader" src={selectedCase.facialMatch.faceDocumentUrl} style={{ display: 'none' }} />}
      {selectedCase?.addressInfo?.addressDocumentType === 'pdf' &&
        <Box display="none">
          <PdfPreview pdfSrc={selectedCase?.addressInfo?.addressDocumentUrl} />
        </Box>
      }
    </Box>
  )
}
import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import CalendarHeader from './components/header';
import YearList from './components/year-list';
import MonthList from './components/month-list';

import { useSelector } from 'react-redux';

export default function ToDoList() {
  const { dateInterval } = useSelector((state) => state.calendarSchedule);

  const isYearly = useMemo(
    () => dateInterval.interval === 'Year',
    [dateInterval.interval]
  );

  return (
    <Box display="flex" mt={2} flexDirection="column">
      <CalendarHeader isYearly={isYearly} />
      {isYearly ? (
        <YearList isYearly={isYearly} />
      ) : (
        <MonthList isYearly={isYearly} />
      )}
    </Box>
  );
}

const initialState = {
  loader: 0,
  moreLoader: false,
};

const loader = (state = initialState, action) => {

  switch (action.type) {
    case 'SET_LOADER_DEFAULT':
      return initialState;
    case 'SET_LOADER':
      return {
        ...state,
        loader:
                    action.loader
                      ? state.loader + 1
                      : (state.loader > 0 ? state.loader - 1: 0)
      };
    case 'SET_MORE_LOADER':
      return {
        ...state,
        moreLoader: action.loader
      };
    default:
      return state
  }
};

export default loader;

import React, { useState, useCallback, useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import CalendarCard from './card';

import { clientApi } from '@app/api';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { MONTHS, EXPIRATION_CHECKS } from '../../constants';
import { useStyles } from './styles';

export default function YearList({ isYearly }) {
  const classes = useStyles({ isYearly });

  const { dateInterval, documentFilter } = useSelector(
    (state) => state.calendarSchedule
  );

  const [data, setData] = useState([]);

  const getData = useCallback(async () => {
    const res = (
      await clientApi.client.getClientsByNextReview({
        groupBy: 'Month',
        from: moment(`${dateInterval.year}`)
          .startOf('year')
          .format('YYYY-MM-DD'),
        to: moment(`${dateInterval.year}`).endOf('year').format('YYYY-MM-DD'),
      })
    ).data;

    const resDoc = (
      await clientApi.clientDocument.setExpiryDate({
        expirationTypes: EXPIRATION_CHECKS[documentFilter.types],
        from: moment(`${dateInterval.year}`).startOf('year'),
        groupBy: 'Month',
        to: moment(`${dateInterval.year}`).endOf('year'),
      })
    ).data.documents;
    const preapredData = MONTHS.map((el) => ({
      ...el,
      legalEntitiesCount: res.legalEntitiesCount[el.id] || 0,
      personsCount: res.personsCount[el.id] || 0,
      documentsCount: resDoc[el.id] || 0,
    }));
    setData(preapredData);
  }, [dateInterval, documentFilter]);

  useEffect(() => {
    getData();
  }, [getData, dateInterval.year]);

  return (
    <Box
      display="flex"
      mt={2}
      flexDirection="column"
      className={classes.wrapper}
      p={'4px'}
    >
      <Grid container>
        {!!data.length &&
          data.map((el, i) => (
            <Grid item sm={3} key={`${el}-${i}`}>
              <CalendarCard item={el} isYearly={isYearly} index={i} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}

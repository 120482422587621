import prepareTimestamp from '@utils/date';
import ExpiryDateLabel from '@components/flexible-icons/expiry-date-label';
export const prepareDocuments = (items) => {
  return (
    items.map((document) => {
      let dates;
      if (document.updateDate || document.issueDate || document.expiryDate) {
        const uploadDate = document.uploadDate
          ? prepareTimestamp(document.uploadDate, 'MM/DD/YYYY', false)
          : 'N/A';
        const issueDate = document.issueDate
          ? prepareTimestamp(document.issueDate, 'MM/DD/YYYY', false)
          : 'N/A';
        const expiryDate = document.expiryDate
          ? prepareTimestamp(document.expiryDate, 'MM/DD/YYYY', false)
          : 'N/A';

        dates = {
          uploadDate,
          issueDate,
          expiryDate,
        };
      }
      const expiryLabel = !!document.expiryDate
        ? ExpiryDateLabel(document.expiryDate, document.status)
        : 'Does not expire';
      return {
        expiryLabel: expiryLabel,
        ...document,
        ...dates,
      };
    }) || []
  );
};
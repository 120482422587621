const initialState = {
  currentPlan: {},
  searchModel: {
    name: '',
    yob: '',
    country: '',
    fuzziness: 0.5,
    gender: '',
    registrationNumber: '',
    listTypes: ['SANCTIONS', 'PEP', 'ADVERSE_MEDIA'],
    searchProvider: 'ALEPH',
    type: 'PERSON',
  },
  isError: false
};

const backgroundSearch = (state = initialState, action) => {

  switch (action.type) {
    case 'SET_BACKGROUND_SEARCH_DEFAULT':
      return initialState;
    case 'SET_CURRENT_PLAN':
      return {
        ...state,
        currentPlan: action.currentPlan,
      };
    case 'SET_SEARCH_MODEL':
      return {
        ...state,
        searchModel: {
          ...state.searchModel,
          ...action.searchModel
        }
      };
    case 'SET_IS_ERROR':
      return {
        ...state,
        isError: action.isError,
      };
    default:
      return state
  }
};

export default backgroundSearch;

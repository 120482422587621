import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@material-ui/core';
import { Modal } from '@components/modals';
import NewInnerHeader from '@components/layout/new-inner-header';
import { ChevronLeftIcon } from '@app/icons';

import HeaderTitleLine from './components/new-search-components/header-title-line';
import HeaderFilterLine from './components/new-search-components/header-filter-line';
import HistoryRightCard from './components/new-search-components/history-right-card';
import CardList from './components/new-search-components/card-list';

import { useLocation, useParams } from 'react-router-dom';
import { clientApi } from '@app/api';
import { useSelector, useDispatch } from 'react-redux';
import { backgroundCheck, searchHistory } from '@store/actions';
import history from '@app/history';
import { isEmpty } from 'lodash';

import { FUZZINESS_VALUE } from './components/seacrh-components/constants';
import clsx from 'clsx';
import { useStyles } from '../styles';

const mainText = 'You\'ve whitelisted all potential hits. You can save this person/legal entity to your Portfolio.';
const fields = ['country', 'gender', 'name', 'type', 'yob', 'registrationNumber', 'searchProvider', 'fuzziness'];

export default function NewSearch(props) {
  const { steps, onNext, onNextAlt, flow } = props;
  const { currentSearch, profiles } = useSelector(store => store.backgroundCheck);
  const { profilesVersionId } = useSelector(store => store.searchHistory);
  const dispatch = useDispatch();

  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [searchId] = useState(search.get('searchId'));
  const [searchName] = useState(search.get('searchName'));
  const [searchType] = useState(search.get('searchType'));
  const [locationFrom] = useState(search.get('from'));
  const { clientId } = useParams();

  // eslint-disable-next-line
  const redirectLink = () => {
    return onNextAlt({ clientId, searchId, searchName, searchType, type: searchType?.toLowerCase() });
  }

  const [openModal, setOpenModal] = useState(false);
  const [isShowRightCard, setIsShowRightCard] = useState(!!profilesVersionId || locationFrom ==='repeat-search');
  const [tags, setTags] = useState([]);
  const [isResize, setIsResize] = useState(false);
  const [isNoResetResize, setIsNoResetResize] = useState(false);
  const classes = useStyles({ topBlockHeight: isResize ? 115 : 80 });

  const prepareCurrentSearchData = (currentSearch) => {
    const currTags = [];

    for (const key in currentSearch) {
      if (key === 'listTypes') {
        currTags.push(...currentSearch[key])
      } else if (fields.includes(key)) {
        key === 'fuzziness'
          ? currTags.push(FUZZINESS_VALUE[currentSearch[key]])
          : currTags.push(currentSearch[key]);
      }
    }

    setTags(currTags);
  }

  const getCurrentSearch = async () => {
    const res = await clientApi.search.getSearch(searchId);

    if (res.status === 200) {
      let data = {};
      for (const key in res.data) {
        if (!!res.data[key] && key !== 'searchId' && key !== 'createdOn') {
          data = { ...data, [key]: res.data[key] }
        }
      }

      dispatch(backgroundCheck.updateCurrentSearch(res.data));
      prepareCurrentSearchData(data);
    }
  }

  useEffect(() => {
    getCurrentSearch();
  }, [searchId]) // eslint-disable-line

  const handleClose = () => {
    history.goBack();
  }

  const handleSaveInPortfolio = () => {
    setOpenModal(false);
    history.push(`/portfolio/create-${currentSearch.type.toLowerCase() || searchType.toLowerCase()}/${searchId}`);
  }

  const handleShowRightCard = (bool) => {
    setIsShowRightCard(bool);
  }

  const handleBackButtonAction = () => {
    localStorage.removeItem('isShowWhitelisted')
    dispatch(searchHistory.setDefault());
    dispatch(backgroundCheck.setShowWhitelisted(false));
    history.goBack();
  }

  return (
    <React.Fragment>
      {(steps && !isEmpty(profiles)) &&
        <Box px={6} width={isShowRightCard ? 'calc(100% - 400px)' : 'auto'}>
          <NewInnerHeader
            title="Choose Search Profile"
            stepper={{
              currentStep: steps?.currentStep || 4,
              stepsCount: steps?.stepsCount || 6
            }}
            leftAligned
            buttons={
              <IconButton onClick={handleBackButtonAction} style={{ padding: 0 }}>
                <ChevronLeftIcon style={{ width: 36, height: 36 }} />
              </IconButton>
            }
          />
        </Box>
      }
      <Box
        className={clsx(isEmpty(profiles) && classes.displayNone)}
        height="100%"
        width="100%"
        display="flex"
        flexDirection="column"
        position="relative"
      >
        <Box ml={'50px'} mr={'50px'} mt={steps ? 2 : 4} className={classes.divider} pb={1}>
          <HeaderTitleLine
            tags={tags}
            searchName={currentSearch.name || searchName}
            isShowRightCard={isShowRightCard}
            setIsShowRightCard={bool => handleShowRightCard(bool)}
            lastRunOn={currentSearch.lastRunOn}
            setIsResize={bool => setIsResize(bool)}
            isResize={isResize}
            setIsNoResetResize={bool => setIsNoResetResize(bool)}
            isNoResetResize={isNoResetResize}
            onBack={steps ? undefined : handleBackButtonAction}
            getCurrentSearch={getCurrentSearch}
            currentSearch={currentSearch}
          />
          <HeaderFilterLine
            setOpen={(bool) => setOpenModal(bool)} // related wirh modal and whitelist logic
            isShowRightCard={isShowRightCard}
            hideMonitoring={!!flow}
            searchId={searchId}
            redirectLink={redirectLink}
            getCurrentSearch={getCurrentSearch}
          />
        </Box>
        <Box>
          {!isEmpty(currentSearch) && <CardList isShowRightCard={isShowRightCard} onNext={onNext} redirectLink={redirectLink} getCurrentSearch={getCurrentSearch} />}
        </Box>
        <HistoryRightCard
          isShowRightCard={isShowRightCard}
          setIsShowRightCard={bool => handleShowRightCard(bool)}
          setIsResize={bool => setIsResize(bool)}
          isNoResetResize={isNoResetResize}
          redirectLink={redirectLink}
        />
      </Box>
      {/* MODAL NO USED IN CURRENT TIME, MAY BE WILL BE USED AGAIN */}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title={'All Hits Whitelisted'}
        mainText={mainText}
        propsClassses={classes.modalButtonWidth}
        actions={
          [
            {
              type: 'main',
              label: 'RUN NEW SEARCH',
              action: handleClose,
            },
            {
              type: 'secondary',
              label: 'SAVE IN PORTFOLIO',
              action: handleSaveInPortfolio,
            },
          ]
        }
        actionsDirection="row"
      />
    </React.Fragment>
  )
}

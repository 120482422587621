import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import subscriptionTheme from '@components/complex/subscription/theme';
import UsageItems from '@components/complex/subscription/items/usage-items';
import { clients } from '@app/api/admin';

import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { formatThousands } from '@utils/numberUtil';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflowY: 'auto'
  },
  itemWrapper: {
    width: 325,
  }
}))

const dateOptions = [ // eslint-disable-line
  { label: 'Past day', value: 'pastDay' },
  { label: 'Past 7 days', value: 'past7Days' },
  { label: 'Past 30 days', value: 'past30Days' },
  { label: 'Last Quarter', value: 'lastQuater' },
  { label: 'Last Year', value: 'lastYear' },
  { label: 'Custom', value: 'custom' },
]

export default function PortfolioDashboard() {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = useState('past30Days'); // eslint-disable-line

  const [data, setData] = useState({});
  const [isLoad, setIsLoad] = useState(false);
  
  const getData = useCallback(async () => {
    const [
      entities,
    ] = await Promise.all([
      clients.getTotalEntities(),
    ])

    setData({
      ...entities.data,
    });
    setIsLoad(true);
  }, [])

  useEffect(() => {
    getData();
  }, [getData])

  return (
    <ThemeProvider theme={subscriptionTheme}>
      <Box my={5} display="flex" justifyContent="space-between">
        <Typography variant="h5">PORTFOLIO OVERVIEW</Typography>
      </Box>
      {isLoad && (
        <Box className={classes.root}>
          <Box display="flex" justifyContent="space-between">
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Number of people"
                value={formatThousands(data.persons)}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Number of legal entities"
                value={formatThousands(data.legalEntities)}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems />
            </Box>
          </Box>
        </Box>
      )}
    </ThemeProvider>
  )
}
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Card, Grid, makeStyles } from '@material-ui/core';
import { clientApi } from '@app/api';
import auth from '@app/store/actions/auth';
import EmailAddress from './security-details-component/email-address';
import AccountProtection from './security-details-component/account-protection';
import SmsBasedOtp from './security-details-component/sms-based-otp';

const useStyles = makeStyles((theme) => ({
  card: {
    '&:hover': {
      cursor: 'default',
    }
  },
}));

export default function SecurityDetails(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchUser();
    //eslint-disable-next-line
    }, [])

  const fetchUser = () => {
    clientApi.user.getCurrentUser().then((res) => {
      localStorage.setItem('user', JSON.stringify(res.data));
      dispatch(auth.setUser(res.data));
    })
  }

  return (
    <Box mt={6}>
      <Grid container justify="center">
        <Grid item xs={6} style={{ paddingRight: 20 }}>
          <Card id="card-email" classes={{ root: classes.card }}>
            <Box p={3}>
              <EmailAddress fetchUser={fetchUser} />
            </Box>
          </Card>
          <Box mt={4}></Box>
          <Card id="card-account-protection" classes={{ root: classes.card }}>
            <Box p={3}>
              <AccountProtection />
            </Box>
          </Card>
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: 20 }}>
          <Card id="card-phone" classes={{ root: classes.card }} style={{ height: '100%' }}>
            <Box p={3}>
              <SmsBasedOtp fetchUser={fetchUser} />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
};

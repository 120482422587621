import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import {
  Box, Button
} from '@material-ui/core';
import LinkButton from '@components/buttons/link-button';
import history from '@app/history';

import EidvInfo from './components/eidv-info';
import ElectronicVerificationAdd from './add-eidv';

export default function ElectronicVerificationAddWrapper(props) {
  const {
    path, cases, forceStart, readonly,
  } = props;
  const [showSendingModal, setShowSendingModal] = useState(false);

  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const type = search.get('type');

  useEffect(() => {
    if (forceStart) {
      setShowSendingModal(true);
    }
  }, [forceStart])

  const onRequest = () => {
    setShowSendingModal(true);
  }

  return (
    <Box>
      <ElectronicVerificationAdd
        {...props}
        showSendingModal={showSendingModal}
        setShowSendingModal={setShowSendingModal}
        type={type}
      />
      <Box mt={8}>
        <EidvInfo />
      </Box>
      {!readonly &&
        <Box mt={2} display="flex" justifyContent="center">
          <Button
            variant="contained"
            onClick={onRequest}
          >
            {'Send Request'}
          </Button>
        </Box>
      }
      {(!!cases.length && !readonly) &&
        <Box mt={1} display="flex" justifyContent="center">
          <LinkButton
            onClick={() => history.push(`${path}/`)}
          >
            {'Cancel'}
          </LinkButton>
        </Box>
      }
    </Box>
  )
}
import React from 'react';
import {
  Box, Typography
} from '@material-ui/core';

import ImagePreview from '@components/image/image-preview';
import PdfPreview from '@components/pdf/pdf-preview';

// Can be pdf as well
export default function ImageWrapper({ title, imgSrc, clientName, onDownload, boxWidth, imgWidth, imgHeight, isPdf, children }) {
  return (
    <Box width={boxWidth} pb={3} bgcolor="#F8F8F8" borderRadius="12px" display="flex" justifyContent="center">
      <Box>
        <Box my={2}>
          <Typography align="center" variant="h5">{title}</Typography>
        </Box>
        {isPdf ?
          <PdfPreview
            width={imgWidth}
            height={imgHeight}
            pdfSrc={imgSrc}
            documentName={title}
            clientName={clientName}
            onDownload={onDownload}
          >
            {children}
          </PdfPreview>
          :
          <ImagePreview
            width={imgWidth}
            height={imgHeight}
            imgSrc={imgSrc}
            documentName={title}
            clientName={clientName}
            onDownload={onDownload}
          >
            {children}
          </ImagePreview>
        }
      </Box>
    </Box>
  )
}

import { makeStyles } from '@material-ui/core';

const box_style = {
  height: '24px',
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 15px',
  width: '90%',
  '&:hover': {
    boxShadow:
      '0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    cursor: 'pointer',
    opacity: 0.7,
  },
};

const font_style = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
};

const colors = {
  People: '#D5F0F0',
  'Legal Entities': '#D1C2B6',
  Documents: '#C0A2B8',
};

const fontColors = {
  People: '#484848',
  'Legal Entities': '#484848',
  Documents: '#fff',
}

const fontWeight = {
  People: 'bold',
  'Legal Entities': 'bold',
  Documents: 300,
}

export const useStyles = makeStyles((theme) => ({
  cardRoot: (props) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow:
      '0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    borderRadius: '12px',
    padding: props.isYearly ? '30px 20px' : '20px',
    margin: props.isYearly ? '4px' : '2px',
  }),
  title: {
    ...font_style,
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '28px',
    textAlign: 'center',
  },
  documentsBox: {
    ...box_style,
    background: '#C0A2B8',
  },
  personsBox: {
    ...box_style,
    background: '#D5F0F0',
  },
  entitiesBox: {
    ...box_style,
    background: '#D1C2B6',
  },
  boxLabel: {
    ...font_style,
    fontSize: '10px',
    lineHeight: '7px',
    color: '#484848',
  },
  boxDocuments: {
    color: '#fff',
  },
  active: {
    border: '1px solid #A4A4A4',
  },
  activeLabel: {
    background: '#EDEDED',
    width: '28px',
    height: '28px',
    borderRadius: '50%',
  },
  wrapperPopoverContent: {
    width: '180px',
    maxHeight: '180px',
  },
  popoverLabel: {
    ...font_style,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#484848',
  },
  buttonRoot: {
    padding: 0,
  },
  search: (props) => ({
    borderRadius: '12px',
    marginRight: '20px',
    minHeight: '24px',
    width: '100%',
    backgroundColor: colors[props.placeholder],
    display: 'flex',
    alignItems: 'center',
  }),
  searchIcon: {
    padding: '0 10px',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    paddingRight: '10px',
    color: 'inerhit',
    width: '100%',
  },
  inputInput: (props) =>({
    width: '100%',
    ...font_style,
    fontSize: '10px',
    lineHeight: '7px',
    color: fontColors[props.placeholder],
    fontWeight: fontWeight[props.placeholder],
  }),
  popoverContent: {
    height: '100%',
    overflowY: 'auto',
  },
  nameWrapper: (props) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#F8F8F8',
      borderRadius: '12px',
    },
  }),
  name: {
    ...font_style,
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#484848',
  },
  wrapper: {
    height: 'calc(100vh - 250px)',
    overflowY: 'auto',
  },
  label: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '10px',
    lineHeight: '20px',
    color: '#232323',
  },
  iconButtonRoot: {
    padding: '6px',
  },
  headerLabel: {
    ...font_style,
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '25px',
    color: '#484848',
  },
}));

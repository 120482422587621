import React, { useEffect, useMemo, useState } from 'react';
import { omit } from 'lodash';
import { Grid, makeStyles, TextField, withStyles } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { CloseIcon, Dropdown3Icon } from '@app/icons';
import clsx from 'clsx';

const filter = createFilterOptions();

const CustomAutocomplete = withStyles((theme) => ({
  clearIndicator: {
    padding: '6px !important',
  },
  endAdornment: {
    right: '0 !important',
    top: 'calc(50% - 18px)',
  },
  popupIndicator: {
    marginRight: 14,
    padding: 6,
  },
  inputRoot: {
    padding: 0,
    '&.MuiOutlinedInput-root': {
      padding: 0,
    },
    '& .MuiAutocomplete-input': {
      padding: '16px 20px !important',
    },
  },
  root: {
    '& .MuiFormControl-root': {
      margin: 0,
    },
  },
  option: {},
}))(Autocomplete);

const useStyles = makeStyles((theme) => ({
  showClearIndicator: {
    visibility: 'visible !important',
  },
  hideClearIndicator: {
    visibility: 'hidden !important',
  },
  dropdownButton: {
    width: 24,
    height: 24,
  },
  withFirstOption: {
    '&:first-child': {
      color: '#7A5B44',
      fontWeight: 'bold',
    },
  },
}));

/**
 * 
 * @param {firstOption} props.firstOption
 *    use firstOption to force a single option to stay on top.
 *    currently being used for "Add new" behavior that will always be shown at top.
 *    example at src/components/complex/client-view/kyc-documents/components/category-type-create-dialog.js
 * @param {overrideLabel} props.overrideLabel
 *    override the label shown to show a selected value without triggering search on some cases
 * @returns 
 */
export default function SelectSearchable(props) {
  const { options, firstOption, overrideLabel } = props;
  const classes = useStyles();
  // Update selected label once user has selected something
  // clears selected label when user changes anything
  const [selectedLabel, setSelectedLabel] = useState('')

  useEffect(() => {
    if(overrideLabel) {
      setSelectedLabel(overrideLabel);
    }
  }, [overrideLabel])

  // Mock textfield onChange
  const handleChange = (e, newValue) => {
    props.onChange({
      target: { value: newValue?.value || '', label: newValue?.label || '' },
    });
    setSelectedLabel(newValue?.label || '')
  };

  const handleInputChange = (e, newValue) => {
    setSelectedLabel('');
    if (props.onInputChange) {
      if (e?.type === 'click' && !newValue) { // This indicates that the input is cleared (by clicking X)
        props.onInputChange({ type: 'change' }, newValue)
      } else {
        props.onInputChange(e, newValue)
      }
    }
  }

  const internalOptions = useMemo(() => {
    if (!firstOption || (firstOption && options.some(opt => opt.value === firstOption.value))) {
      return options
    } else {
      return [
        firstOption,
        ...options
      ]
    }
  }, [options, firstOption])

  const value = useMemo(() => {
    return (
      internalOptions.find((option) => option.value === props.value) || {
        label: '',
        value: '',
      }
    );
  }, [props.value, internalOptions]);

  const autocompleteProps = omit(props, ['error', 'options', 'firstOption', 'overrideLabel']);
  const filterOptions = (options, params) => {
    const filtered = filter(options, params)

    if (firstOption && !filtered.find(e => e.value === firstOption.value)) {
      filtered.unshift({
        value: firstOption.value,
        label: firstOption.label,
      })
    }

    return filtered;
  }

  return (
    <CustomAutocomplete
      {...autocompleteProps}
      onInputChange={handleInputChange}
      inputValue={selectedLabel || props.inputValue}
      options={internalOptions}
      getOptionLabel={(option) => option.label}
      onChange={handleChange}
      value={value}
      classes={{
        clearIndicator: value.value
          ? classes.showClearIndicator
          : classes.hideClearIndicator,
        option: clsx(firstOption && classes.withFirstOption)
      }}
      popupIcon={
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.dropdownButton}
        >
          {!props.disabled && <Dropdown3Icon style={{ height: 8 }} />}
        </Grid>
      }
      closeIcon={<CloseIcon />}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={props.label}
          error={props.error}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
      filterOptions={props.filterOptions ? props.filterOptions : filterOptions}
    />
  );
}

import authRoutes from './auth';
import publicRoutes from './public';
import protectedClientRoutes from './protected/client';
import protectedAdminRoutes from './protected/admin';
import ComponentsList from '@views/components-list';
import Debug from '@views/protected/debug';
import Maintenance from '@views/public/maintenance';
import PersonalizedMaintenance from '@views/public/personalized-maintenance';
import Unsubscribe from '@views/public/unsubscribe';

const resolveRoutes = (side, userIsAdmin) => ([
  ...authRoutes,
  ...publicRoutes,
  ...[
    {
      path: '/debug',
      component: Debug,
      guest: false
    },
    ...((side === 'admin' || userIsAdmin)
      ? protectedAdminRoutes
      : protectedClientRoutes)
  ],
  {
    path: '/components-list',
    component: ComponentsList,
    guest: true
  },
  {
    path: '/maintenance',
    component: Maintenance,
    guest: true
  },
  {
    path: '/under-maintenance',
    component: PersonalizedMaintenance,
    guest: false
  },
  {
    path: '/unsubscribe',
    component: Unsubscribe,
    always: true
  },
])

export default resolveRoutes
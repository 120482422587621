import React from 'react';
import PageLayout from '@components/layout/page-layout';
import { Route, Switch, Redirect } from 'react-router';
import { Box } from '@material-ui/core';
import EIDVPage from './page';
import CheckEidvCredits from '@components/complex/client-view/electronic-verification/check-eidv-credits';
import EidvView from './page/view';

const ElectronicIdentityVerification = () => {
  return (
    <PageLayout>
      <Box maxHeight="calc(100%-98px)" style={{ overflowY: 'auto' }}>
        <Switch>
          <Route exact path="/electronic-verification">
            <CheckEidvCredits>
              <EIDVPage />
            </CheckEidvCredits>
          </Route>
          <Route path="/electronic-verification/:caseId/:tab?">
            <EidvView />
          </Route>
          <Redirect to={'/electronic-verification'} />
        </Switch>
      </Box>
    </PageLayout>
  );
};

export default ElectronicIdentityVerification;

import React, {useEffect, useMemo, useState} from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Button,
  DialogActions,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import { EntityInputTypeEnum, EntityInputDisplayLevelEnumPerson, EntityInputDisplayLevelEnumLegalEntity } from '@services/entity-input';
import TagsListInput from '@components/inputs/tags-list-input';
import * as yup from 'yup';
import parseYupErrors from '@app/yup';
import cloneDeep from 'lodash/cloneDeep';

const INPUT_FIELD_TEMPLATE = {
  definition: {
    type: 'TEXT',
    size: 30,
    options: null
  },
  displayConfig: {
    level: 'SUMMARY_INFO',
    order: 0
  },
  displayName: '',
  validation: 'OPTIONAL'
}

const schema = yup.object().shape({
  displayName: yup.string().required().label('Field Name')
})

export default function InputFieldDialog({ open, origData, onClose, onSave, clientType }) {
  const [model, setModel] = useState(cloneDeep(INPUT_FIELD_TEMPLATE))
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (!!open) {
      if (!!origData) {
        setModel(cloneDeep(origData))
      } else {
        setModel(cloneDeep(INPUT_FIELD_TEMPLATE))
      }
    }
  }, [open, origData]) // eslint-disable-line

  const onTypeChange = (type) => {
    const newModel = { ...model }
    if (type === 'TEXT') {
      newModel.definition.size = INPUT_FIELD_TEMPLATE.definition.size
    } else {
      newModel.definition.size = null
    }
    if (type === 'LIST') {
      newModel.definition.options = []
      newModel.definition.allowOther = false
    } else {
      newModel.definition.options = null
      newModel.definition.allowOther = null
    }
    newModel.definition.type = type;
    setModel(newModel)
  }

  const validateForm = () => {
    const errors = { definition: {} }
    let isValid = true;
    if (model.definition.type === 'TEXT') {
      if (!model.definition.size) {
        errors.definition.size = 'Max Length is a required field'
        isValid = false;
      }
      if (!!origData?.id
        && origData.definition.type === 'TEXT'
        && origData.definition.size > model.definition.size
      ) {
        errors.definition.size = 'You can not reduce max length for existing input field'
        isValid = false;
      }
      if (model.definition.size < 1
      ) {
        errors.definition.size = 'Max length must be positive number.'
        isValid = false;
      }
      if (!Number.isInteger(Number(model.definition.size))) {
        errors.definition.size = 'Max length must be integer number.'
        isValid = false;
      }
    }

    if (model.definition.type === 'LIST') {
      if (!model.definition.options?.length) {
        errors.definition.options = 'At least one custom list item must be provided'
        isValid = false;
      }
    }

    if (!isValid) {
      setErrors(errors)
    }
    return isValid;
  }

  const onInternalSave = async () => {
    setErrors({});
    try {
      await schema.validate(model, {abortEarly: false})
      if (!validateForm()) {
        return
      }

      onSave(model)
    } catch (err) {
      const errs = parseYupErrors(err)
      setErrors(errs)
    }
  }

  // Custom List Items Management
  const onCustomListItemsChange = items => {
    setErrors({})
    setModel({
      ...model,
      definition: {
        ...model?.definition || {},
        options: items
      }
    })
  }

  const displayLevels = useMemo(() => {
    return clientType === 'PERSON' ? EntityInputDisplayLevelEnumPerson : EntityInputDisplayLevelEnumLegalEntity
  }, [clientType])

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={(e) => e.preventDefault()}>
        <DialogTitle>
          Create Input Field
        </DialogTitle>
        <DialogContent>
          <Box width={'400px'}>
            <TextField
              value={model.displayName}
              onChange={event => {
                setErrors({})
                setModel({
                  ...model,
                  displayName: event.target.value
                })
              }}
              label="Field Name"
              variant="outlined"
              fullWidth
              error={!!errors.displayName}
              helperText={errors.displayName}
            />
            { !origData?.id && (
              <FormControl
                variant="outlined"
                fullWidth
              >
                <InputLabel>Answer Type</InputLabel>
                <Select
                  value={model.definition.type}
                  onChange={event => {
                    setErrors({})
                    onTypeChange(event.target.value)
                  }}
                >
                  {Object.entries(EntityInputTypeEnum).map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            { model.definition.type === 'TEXT' && (
              <TextField
                value={model.definition.size}
                onChange={event => {
                  setErrors({})
                  setModel({
                    ...model,
                    definition: {
                      ...model.definition,
                      size: event.target.value
                    }
                  })
                }}
                label="Max Length"
                variant="outlined"
                fullWidth
                error={!!errors.definition?.size}
                helperText={errors.definition?.size}
              />
            )}
            { model.definition.type === 'LIST' && (
              <TagsListInput
                label={'Custom List Items'}
                origData={origData}
                values={model?.definition?.options || []}
                onChange={onCustomListItemsChange}
                exists={!!origData?.id}
                error={errors.definition?.options}
                allowOther={model.definition.allowOther}
                allowOtherChange={event => {
                  setErrors({})
                  setModel({
                    ...model,
                    definition: {
                      ...model.definition,
                      allowOther: !model.definition.allowOther
                    }
                  })
                }}
              />
            )}
            <FormControl
              variant="outlined"
              fullWidth
            >
              <InputLabel>Display Level</InputLabel>
              <Select
                value={model.displayConfig.level}
                onChange={event => {
                  setErrors({})
                  setModel({
                    ...model,
                    displayConfig: {
                      ...model.displayConfig,
                      level: event.target.value
                    }
                  })
                }}
              >
                {Object.entries(displayLevels).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button style={{ width: '160px' }} variant={'outlined'} onClick={onClose}>
            CANCEL
          </Button>
          <Button style={{ width: '160px' }} variant={'contained'} onClick={onInternalSave} type="submit">
            SAVE
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
import React from  'react';
import { Typography, Box } from '@material-ui/core';


export default function VersionTextBlock({ versionState }) {
  return (
    <Box
      my={2} p={1}
      style={{ backgroundColor: '#D5F0F0' }}
    >
      <Typography variant="subtitle2" align="center" style={{ color: '#484848' }}>
        {versionState !== 'UNCHANGED' ? (
          'New changes identified. Please review them in the list below.'
        ) : (
          'Changes from previous versions were not acknowledged. Please review them.'
        )}
      </Typography>
    </Box>
  )
};

import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Collapse
} from '@material-ui/core';

import SelectClearable from '@components/inputs/select-clearable';
import CheckboxLight from '@components/inputs/checkbox-light';
import SelectSearchable from '@components/inputs/select-searchable';

import * as yup from 'yup';
import parseYupErrors from '@app/yup';

const DEFAULT_DATA = {
  name: '',
  usageTypes: ['PERSON'],
}

export const USAGE_TYPES = [
  { label: 'Person', value: 'PERSON' },
  { label: 'Legal Entity', value: 'LEGAL_ENTITY' },
]

export default function CategoryCreateDialog({ open, onClose, onSave, category, title, clientType, categories }) {
  const isEdit = !!category
  const [model, setModel] = useState(DEFAULT_DATA)
  const [selectedCategory, setSelectedCategory] = useState(null)

  const schema = yup.object().shape({
    name: yup.string().trim().required().label('Category Name'),
    usageTypes: yup.mixed().test('typeRequired', 'Usage types is a required field', (value) => value.length > 0),
  });

  const [errors, setErrors] = useState({}) //eslint-disable-line

  useEffect(() => {
    setErrors({})
    const newModel = {
      ...DEFAULT_DATA,
      usageTypes: clientType ? [clientType] : DEFAULT_DATA.usageTypes
    }

    if (category) {
      newModel.name = category.name
      newModel.usageTypes = category.usageTypes
    }

    setModel(newModel)
    setSelectedCategory(null)
  }, [open, category, clientType])


  const onInternalSave = () => {
    if (categories) {
      if (selectedCategory && selectedCategory !== 'custom') {
        const category = categories.find(e => e.id === selectedCategory)
        return onSave(category)
      }
    }

    setErrors({});
    schema
      .validate(model, { abortEarly: false })
      .then(() => {
        onSave(model)
      })
      .catch(err => {
        setErrors(
          parseYupErrors(err)
        )
      })
  }

  const linkModel = (field) => event => {
    setErrors({})
    setModel({
      ...model,
      [field]: event.target.value
    })
  }


  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={(e) => e.preventDefault()}>
        <DialogTitle>
          {title || (isEdit ? `Edit Category ${category.name}` : 'Create Custom Category')}
        </DialogTitle>
        <DialogContent>
          <Box width={'400px'}>
            {categories &&
              <FormControl variant="outlined" fullWidth>
                <SelectSearchable
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  options={categories.map(e => ({ label: e.name, value: e.id }))}
                  label="Select Category"
                  firstOption={{ label: 'Add New Category', value: 'custom' }}
                />
              </FormControl>
            }
            <Collapse in={!categories || selectedCategory === 'custom'}>
              <FormControl
                variant="outlined"
                fullWidth
                error={!!errors.name}
              >
                <InputLabel>
                  Category Name
                </InputLabel>
                <OutlinedInput
                  value={model.name}
                  onChange={linkModel('name')}
                />
                {!!errors.name &&
                  <FormHelperText>
                    {errors.name}
                  </FormHelperText>
                }
              </FormControl>
              <FormControl variant="outlined" fullWidth error={!!errors.usageTypes}>
                <InputLabel>Document Class</InputLabel>
                <SelectClearable
                  value={model.usageTypes}
                  onChange={linkModel('usageTypes')}
                  clearable={false}
                  multiple
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null
                  }}
                  renderValue={
                    (selectedIds) => USAGE_TYPES
                      .filter(type => selectedIds.indexOf(type.value) !== -1)
                      .map(type => type.label)
                      .join(', ')}
                >
                  {USAGE_TYPES.map(type => (
                    <MenuItem key={type.value} value={type.value} disabled={type.value === clientType}>
                      <ListItemIcon>
                        <CheckboxLight
                          checked={model.usageTypes.indexOf(type.value) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        {type.label}
                      </ListItemText>
                    </MenuItem>
                  ))}
                </SelectClearable>
                {!!errors.usageTypes &&
                  <FormHelperText>{errors.usageTypes || null}</FormHelperText>
                }
              </FormControl>
            </Collapse>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button style={{ width: '160px' }} variant={'outlined'} onClick={onClose}>
            CANCEL
          </Button>
          <Button style={{ width: '160px' }} variant={'contained'} onClick={onInternalSave} type="submit">
            {categories ? 'ADD' : 'SAVE'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import {
  Box,
  Grid,
  Switch,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';
import InlineHeadBadge from '@components/badges/inline-head-badge';
import FilterWithContext from '@components/dropdowns/filter-with-context';
import OutlinedSearch from '@components/inputs/outlined-search';
import TableList from '@components/lists/table-list';
import { Modal } from '@components/modals';
import { clients, subscription, subscriptionPlans } from '@app/api/admin';
import SubscriptionCard from './subscription-card';
import { useLocation } from 'react-router-dom';
import history from '@app/history';
import { useSnackbar } from 'notistack';
import prepareTimestamp from '@utils/date';
import debounce from 'awesome-debounce-promise';
import { capitalize } from '@utils/textUtil';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: 'calc(100% - 115px)',
    overflowY: 'auto',
    padding: '10px 16px',
  },
  modalButtonWidth: {
    width: '160px',
  },
  carouselContainer: {
    width: '100%',
    height: '260px',
    margin: '0 -10px',
  },
  carouselItem: {
    width: '370px !important',
  },
  filterActive: {
    backgroundColor: '#7A5B44',
    '&:hover': {
      backgroundColor: '#7A5B44',
      opacity: 0.9,
    },
  },
}));

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 2550 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 2549, min: 2045 },
    items: 4,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: { max: 2045, min: 1550 },
    items: 3,
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 1549, min: 0 },
    items: 2,
    partialVisibilityGutter: 30,
  },
};

const switchGroup = [
  { value: 'active', label: 'Active Subscriptions', isChecked: true },
  { value: 'expired', label: 'Expired Subscriptions', isChecked: true },
];

const usersGroup = [
  { value: 'real', label: 'Real User', isChecked: false },
  { value: 'test', label: 'Test User', isChecked: false },
];

const pageSize = 12;

const debouncedUsers = debounce(clients.searchUsers, 500);

export default function SubscribersList({ baseRoute, userIsAdmin }) {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [planId] = useState(search.get('planId'));

  const carouselRef = useRef();

  const [searchValue, setSearchValue] = useState();
  const [page, setPage] = useState(0); // eslint-disable-line
  const [data, setData] = useState([]);
  const [plans, setPlans] = useState([]);
  const [openModal, setOpenModal] = useState(null);
  const [filters, setFilters] = useState(switchGroup);
  const [usersFilters, setUsersFilters] = useState(usersGroup);
  const [activePlanId, setActivePlanId] = useState(+planId);
  const [subscribersCount, setSubscribersCount] = useState(null);
  const [pagesCount, setPagesCount] = useState(null);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (carouselRef.current && !!data.length) {
      carouselRef.current.goToSlide(
        plans.findIndex((el) => el.isActive),
        true
      );
    }
  }, [plans, data]);

  const TABLE_COLUMNS = [
    { field: 'fullName', headerName: 'Full name', bold: true },
    { field: 'email', headerName: 'Email' },
    { field: 'subscriptionName', headerName: 'Current Plan' },
    { field: 'subscriptionStartDate', headerName: 'Subscription Date' },
    { field: 'expirationDate', headerName: 'Expires On' },
    { field: 'upgradeRequest', headerName: 'Upgrade Request' }, // no sort
    { field: 'renewRequest', headerName: 'Renew Request' }, // no sort
    { field: 'paidRenewSubscription', headerName: 'Paid Renewal' }, // no sort
  ];

  const getAllPlans = useCallback(async () => {
    const res = await subscriptionPlans.getAll();

    if (res.status === 200) {
      const newPlans = await Promise.all(
        res.data
          .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
          .map(async (el) => ({
            ...el,
            isActive: el.id === +planId,
            subscribers: (
              await clients.searchUsers({ planId: el.id })
            ).data.count,
          }))
      );

      const allSubscribers = (await clients.searchUsers()).data.count;
      setPlans([
        {
          id: 111,
          name: 'All',
          isActive: !newPlans.find((el) => el.isActive),
          subscribers: allSubscribers,
        },
        ...newPlans,
      ]);
    }
  }, [planId]);

  const getAdditionalPlanData = useCallback(
    async ({
      requestedUpgradePlanId,
      requestedRenewalPlanId,
      paidRenewalPlanId,
    }) => {
      let additionalData = {
        requestedUpgradePlanId,
        upgradeRequest: 'N/A',
        requestedRenewalPlanId,
        renewRequest: 'N/A',
        paidRenewalPlanId,
        paidRenewSubscription: 'N/A',
      };
      if (requestedUpgradePlanId) {
        additionalData.upgradeRequest =
          (await subscriptionPlans.getById(requestedUpgradePlanId))?.data
            ?.name || 'N/A';
      }
      if (requestedRenewalPlanId) {
        additionalData.renewRequest =
          (await subscriptionPlans.getById(requestedRenewalPlanId))?.data
            ?.name || 'N/A';
      }
      if (paidRenewalPlanId) {
        additionalData.paidRenewSubscription =
          (await subscriptionPlans.getById(paidRenewalPlanId))?.data?.name ||
          'N/A';
      }
      return additionalData;
    },
    []
  );

  const getUsers = useCallback(async () => {
    const testUser = usersFilters.find((el) => el.value === 'test').isChecked;
    const checkedUsers = usersFilters.filter((el) => el.isChecked);
    const payload = activePlanId
      ? { planId: +activePlanId, pageNumber: page, pageSize }
      : { pageNumber: page, pageSize };
    const res = await debouncedUsers({
      ...payload,
      testUser: checkedUsers.length === 1 ? testUser : null,
      email: searchValue || null,
      sortDirection: 'ASC',
      sortBy: 'name'
    });

    if (res.status === 200) {
      const usersData = [];
      const { entries, count, pages } = res.data;

      await Promise.all(
        entries.map(async (user) => {
          const userSubscription = (
            await subscription.getSubscriptionForUser(user.id)
          )?.data;

          if (userSubscription) {
            const userPlan = (
              await subscriptionPlans.getById(userSubscription.currentPlanId)
            ).data;
            const requestData = await getAdditionalPlanData({
              ...userSubscription,
            });

            if (userPlan) {
              usersData.push({
                ...user,
                ...requestData,
                fullName: `${user.name} ${user.lastName}`,
                subscriptionName: userPlan.name,
                subscriptionStartDate: userSubscription.startDate
                  ? prepareTimestamp(userSubscription.startDate, 'MM/DD/YYYY')
                  : 'N/A',
                expirationDate: userSubscription.expirationDate
                  ? prepareTimestamp(
                    userSubscription.expirationDate,
                    'MM/DD/YYYY'
                  )
                  : 'N/A',
                price: `$${userPlan.price}`,
                status: 'Active',
                isChecked: false,
              });
            }
          }
        })
      );

      setSubscribersCount(count);
      setPagesCount(pages);
      setData(usersData);
    }
  }, [activePlanId, page, searchValue, getAdditionalPlanData, usersFilters]);

  useEffect(() => {
    getAllPlans();
  }, [planId, getAllPlans]);

  useEffect(() => {
    getUsers();
  }, [activePlanId, getUsers, page, searchValue, usersFilters]);

  const onSearchChange = (event) => {
    setSearchValue(event.target.value);
    setPage(0);
  };

  const onSelect = (id) => {
    const newData = data.map((item) =>
      item.id === id ? { ...item, isChecked: !item.isChecked } : item
    );
    setData(newData);
  };

  const onFilterChange = (event) => {
    const changed = filters.map((el) =>
      el.value === event.target.value
        ? { ...el, isChecked: event.target.checked }
        : el
    );
    setFilters(changed);
  };

  const onUsersFilterChange = (event) => {
    const changed = usersFilters.map((el) =>
      el.value === event.target.value
        ? { ...el, isChecked: event.target.checked }
        : el
    );
    setUsersFilters(changed);
  };

  const handleClickCard = (item) => {
    setPlans((elements) =>
      elements.map((el) =>
        el.id === item.id
          ? { ...el, isActive: true }
          : { ...el, isActive: false }
      )
    );

    if (item.name === 'All') {
      setActivePlanId(null);
      return;
    }
    setActivePlanId(item.id);
    setPage(0);
  };
  const changeSubscriptionPlan = useCallback(
    async (user, type) => {
      const res =
        type === 'upgraded'
          ? await subscription.upgradePlanPaid(user.id)
          : await subscription.renewalPlanPaid(user.id);

      if (res.status === 200) {
        const planName = plans.find(
          (el) => el.id === res.data.currentPlanId
        )?.name;
        enqueueSnackbar(
          `${user.fullName} has the new plan "${planName}" successfully ${type}!`,
          { variant: 'success' }
        );
        getAllPlans();
        getUsers();
      }
      setOpenModal(null);
    },
    [enqueueSnackbar, plans, getAllPlans, getUsers]
  );

  const formattedData = useMemo(() => {
    return data?.map((e) => {
      const item = { ...e };

      switch (item.status) {
        case 'Active':
          item.cellProps = {
            status: {
              style: {
                color: '#4E922C',
              },
            },
          };
          break;

        case 'Expired':
          item.cellProps = {
            status: {
              style: {
                color: '#B6514C',
              },
            },
          };
          break;

        default:
          break;
      }

      if (!item.requestedUpgradePlanId) {
        item.threeDotsActionsDisabled = ['Upgrade Subscription'];
      }

      if (!item.requestedRenewalPlanId) {
        item.threeDotsActionsDisabled = !!item.threeDotsActionsDisabled?.length
          ? [...item.threeDotsActionsDisabled, 'Renew Subscription']
          : ['Renew Subscription'];
      }

      return item;
    });
  }, [data]);

  const tableActions = useMemo(() => {
    return userIsAdmin
      ? [
        {
          label: 'Upgrade Subscription',
          handler: (id, item) =>
            setOpenModal({ item, type: 'upgrade', action: 'upgraded' }),
        },
        {
          label: 'Renew Subscription',
          handler: (id, item) =>
            setOpenModal({ item, type: 'renew', action: 'renewed' }),
        },
      ]
      : [
        { label: 'Subscription Details', handler: (id, item) => {} },
        {
          label: 'Subscription History',
          handler: (id, item) =>
            history.push(
              '/user-management/view-user/subscription/?fullName=Cody Fisher'
            ),
        },
        {
          label: 'Upgrade Subscription',
          handler: (id, item) =>
            setOpenModal({ item, type: 'upgrade', action: 'upgraded' }),
        },
        {
          label: 'Renew Subscription',
          handler: (id, item) =>
            setOpenModal({ item, type: 'renew', action: 'renewed' }),
        },
        {
          label: 'Terminate Subscription',
          handler: (id, item) => setOpenModal({ name: item.name }),
        },
      ];
  }, [userIsAdmin, setOpenModal]);

  const handleClick = (id) => {
    history.push(
      `/user-management/manage-users/view-user/subscription/?userId=${id}`
    );
  };

  return (
    <Box
      display="flex"
      mt={2}
      flexDirection="column"
      className={classes.wrapper}
    >
      {!!plans.length && (
        <React.Fragment>
          <Box width={'100%'} display="flex" flexDirection="row">
            <Carousel
              ref={carouselRef}
              additionalTransfrom={0}
              arrows
              autoPlaySpeed={3000}
              centerMode={true}
              className=""
              containerClass={classes.carouselContainer}
              dotListClass=""
              focusOnSelect={false}
              infinite={false}
              itemClass={classes.carouselItem}
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={responsive}
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
            >
              {plans.map((el, i) => (
                <Box key={`searched-card-${el.id}-${i}`}>
                  <SubscriptionCard
                    item={el}
                    onClick={handleClickCard}
                    key={`searched-card-${el.id}-${i}`}
                    subscribersCount={subscribersCount}
                  />
                </Box>
              ))}
            </Carousel>
          </Box>
          <Box display="flex" flexDirection="column" width="100%" mt={2}>
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
              mt={1}
            >
              <InlineHeadBadge
                badgeColor={'#CAD7CA'}
                badgeValue={subscribersCount}
              >
                SUBSCRIBERS
              </InlineHeadBadge>
              <FilterWithContext
                isActive={
                  !!usersFilters.filter((el) => el.isChecked).length &&
                  classes.filterActive
                }
              >
                <Grid container spacing={8}>
                  {userIsAdmin ? (
                    <Box height={'40px'} />
                  ) : (
                    <Grid item xs={6}>
                      {filters.map((el, i) => (
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          height={'40px'}
                          key={`${el.value} - ${i}`}
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                value={el.value}
                                checked={el.isChecked}
                                onClick={onFilterChange}
                              />
                            }
                            label={el.label}
                          />
                        </Box>
                      ))}
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    {usersFilters.map((el, i) => (
                      <Box key={`${el.value} - ${i}`}>
                        <FormControlLabel
                          control={
                            <Switch
                              value={el.value}
                              checked={el.isChecked}
                              onClick={onUsersFilterChange}
                            />
                          }
                          label={el.label}
                        />
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              </FilterWithContext>
            </Box>
            <Box display={'flex'} mb={3}>
              <OutlinedSearch
                value={searchValue}
                onChange={onSearchChange}
                label="Search subscribers by email"
              />
            </Box>
            {!!data.length && (
              <Grid container>
                <Grid item sm={12}>
                  <TableList
                    onClick={handleClick}
                    columns={TABLE_COLUMNS}
                    items={formattedData}
                    page={page}
                    onPageChange={(event, value) => setPage(value - 1)}
                    onSelect={onSelect}
                    pageSize={pageSize}
                    pagesCount={pagesCount}
                    totalCount={subscribersCount}
                    // sortableColumns={['fullName', 'email']}
                    // maxHeight="calc(100vh - 670px)"
                    threeDotsActions={tableActions}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        </React.Fragment>
      )}
      <Modal
        open={!!openModal}
        onClose={() => setOpenModal(null)}
        title={
          openModal?.type
            ? `${capitalize(openModal?.type)} Subscription`
            : 'Terminate Subscription'
        }
        mainText={
          openModal?.type
            ? `Are you sure you want to ${openModal?.type} the subscription plan for user "${openModal?.item?.fullName}"?`
            : `Are you sure you want to terminate "${openModal?.name}" subscription plan?`
        }
        propsClassses={classes.modalButtonWidth}
        actions={[
          {
            type: 'main',
            label: 'No',
            action: () => setOpenModal(null),
          },
          {
            type: 'secondary',
            label: 'Yes',
            action: () =>
              openModal?.type
                ? changeSubscriptionPlan(openModal?.item, openModal?.action)
                : setOpenModal(null),
          },
        ]}
        actionsDirection="row"
      />
    </Box>
  );
}

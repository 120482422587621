import React from 'react';
import { useSnackbar } from 'notistack';
import { Box } from '@material-ui/core';
import { eidv } from '@app/api/client';
import EVTitle from '../components/eidv-title';
import EVError from '../components/ediv-error';
import EVPendingButtons from '../components/eidv-pending';
import ImageWrapper from '../components/eidv-image-preview';
import { manualApproveDto } from '../dto';

export default function EVProofOfAddress({ data, handleDownload, clientData, fetchData, handleRepeat, readonly }) {
  const { enqueueSnackbar } = useSnackbar();
  const details = data.proofOfAddress || {};
  const pending = data.status === 'pending approval' && details.status === 'pending approval';

  const errors = () => {
    const errors = [];
    if (details.addressProofAcquired) {
      errors.push('The client did not provide proof of address document.');
    }
    return errors;
  }

  const onManualApprove = () => {
    const addressData = manualApproveDto.proofOfAddress(data);
    eidv.updateAddressInformationUserData(addressData, data?.id).then(() => {
      enqueueSnackbar('Proof of Address was approved successfully!', { variant: 'success' })
      fetchData();
    })
  }


  return (
    <React.Fragment>
      <Box mb={3}>
        <EVTitle status={details.status}>Proof of address document upload</EVTitle>
        <Box
          mt={2}
          width={420}
          display="flex"
          justifyContent="center"
          bgcolor="#F8F8F8"
          borderRadius="12px"
        >
          <ImageWrapper
            title="Document Image"
            imgSrc={details.addressDocumentUrl}
            clientName={clientData.name}
            onDownload={() => handleDownload('proofOfAddress.addressDocumentUrl', 'Proof of Address')}
            boxWidth={420}
            imgWidth={300}
            imgHeight={215}
            isPdf={details.addressDocumentType === 'pdf'}
          />
        </Box>

        <Box display="flex" flexWrap="wrap">
          {pending && errors().map(error => (
            <Box mt={2} mr={2}>
              <EVError message={error} centerVertically />
            </Box>
          ))}
        </Box>
      </Box>

      {(pending && !readonly) &&
        <EVPendingButtons onRepeat={handleRepeat} onApprove={onManualApprove} />
      }
    </React.Fragment>
  )
}
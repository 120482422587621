import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Button, makeStyles } from '@material-ui/core';
import store from '@app/store';
import { loader } from '@store/actions';
import { Modal } from '@components/modals';
import { Box, Typography } from '@material-ui/core';

import ModalBackground from '@assets/img/modal/UpdateModalBackground.svg';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '40px',
    fontWeight: '700',
    lineHeight: '48px',
    color: '#232323',
  },
  body: {
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '28px',
  },
  background: {
    backgroundImage: `url (${ModalBackground})`,
  }
}));
/**
 * To handle checking and applying new update, we use service worker
 * SW is set to check for update every 2 minutes
 * When SW found an update, we will show a snackbar and let user force apply the update
 *
 * This detection, however, only happens once (when the SW gets installed, but is waiting for previous SW to die).
 * Since it only happens once, there are 2 additional cases to handle
 *  1. If user close/refresh the app without updating
 *  2. If user has multiple tabs open
 *
 * Case #1
 * If user close or refresh the app without updating,
 * the app should update when they open it again (after refresh too)
 * However, this does not work if there is another tab open at the time.
 * In that case, we will always assume that there is another tab open.
 * So we will always show update available until they click on it.
 *
 * Case #2
 * If there are multiple tabs open when user receive an update,
 * there is a possibility that only one tab will receive the notification.
 * In this case, we use a combination of localStorage and sessionStorage
 * to check if there is an update, and not depend on SW callback.
 *
 *
 * localStorage - updateAvailable
 * { date: date, updated: bool }
 * Updated indicates whether the service worker has been updated
 * Once it has been updated newly opened tabs will load with the update
 * So we don't need to show it again
 *
 * sessionStorage - lastUpdated: date
 * Indicates when this tab is last updated, or when it was opened.
 * We periodically check the updateAvailable and if there is an update
 * that is earlier than the time of lastUpdated, we will show the notification.
 * Regardless of whether the update has been applied or not.
 * When user confirms the update, the page will get refreshed and the update will be applied.
 *
 * Update this to trigger fake update - 3
 **/

function UpdateDetector() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [worker, setWorker] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const onUpdateDetected = (registration, date) => {
    setWorker(registration);
    setUpdateAvailable(true);
    localStorage.setItem(
      'updateAvailable',
      JSON.stringify({ date, updated: false })
    );
  };

  const onRegistered = (registration) => {
    setWorker(registration);
    if (registration.waiting && registration.active) {
      onUpdateDetected(registration, Date.now());
    }
  };

  useEffect(() => {
    const config = {
      onUpdate: onUpdateDetected,
      onRegistered: onRegistered,
    };

    serviceWorkerRegistration.register(config);

    sessionStorage.setItem('lastUpdated', Date.now());

    // Check if there is a pending update
    let updateAvailable = localStorage.getItem('updateAvailable');
    if (updateAvailable) {
      updateAvailable = JSON.parse(updateAvailable);

      if (!updateAvailable.updated) {
        setUpdateAvailable(true);
      }
    }

    // Periodically check if there is a new update
    setInterval(() => {
      const lastUpdated = 0 + sessionStorage.getItem('lastUpdated');

      let updateAvailable = localStorage.getItem('updateAvailable');
      if (updateAvailable) {
        updateAvailable = JSON.parse(updateAvailable);

        if (updateAvailable.date > lastUpdated) {
          setUpdateAvailable(true);
        }
      }
    }, 2 * 60 * 1000);
  }, []); // eslint-disable-line

  const updateServiceWorker = () => {
    setModalOpen(false)
    worker?.waiting?.postMessage &&
      worker.waiting.postMessage({ type: 'SKIP_WAITING' });

    let updateAvailable = localStorage.getItem('updateAvailable');
    if (updateAvailable) {
      updateAvailable = JSON.parse(updateAvailable);
      localStorage.setItem(
        'updateAvailable',
        JSON.stringify({ ...updateAvailable, updated: true })
      );
    }

    enqueueSnackbar('Updating app...', { variant: 'success' });
    store.dispatch(loader.setLoader(true));
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const updateAction = () => (
    <Button
      variant="contained"
      size="medium"
      onClick={() => updateServiceWorker()}
    >
      Get New Version
    </Button>
  );

  useEffect(() => {
    if (updateAvailable && !modalOpen && worker) {
      setModalOpen(true);
    }
  }, [updateAvailable, modalOpen, worker]); // eslint-disable-line

  return (
    <Modal
      open={modalOpen}
      width="35vw"
      height="35vh"
      onClose={() => setModalOpen(false)}
      background={`white url(${ModalBackground}) no-repeat`}
      content={
        <Box
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="100%"
          height="100%"
        >
          <Box mb={2}>
            <Typography align="center" className={classes.title}>
              Update available
            </Typography>
          </Box>
          <Typography align="left" className={classes.body}>
            KYC Passport has just gotten better. <br />
            Please click the button below to get the latest version.
          </Typography>
          <Box mt={2}>{updateAction()}</Box>
        </Box>
      }
    />
  );
}

export default UpdateDetector;

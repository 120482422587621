import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import casesDto from './dto';

import { eidv } from '@app/api/client';
import CustomTooltip from '@components/controls/tooltip';

import {
  CheckmarkSuccessIcon,
  CheckmarkWarningIcon,
  CheckmarkErrorIcon,
} from '@app/icons';

export default function useEidvStatus(clientId, classes) {
  const [cases, setCases] = useState([])

  useEffect(() => {
    fetchCases()
  }, [clientId]) // eslint-disable-line

  const fetchCases = () => {
    eidv.index({ clientId }).then((res) => {
      const formattedCases = res.data.entries.map(e => casesDto.read(e));
      setCases(formattedCases)
    })
  }

  const eidvStatus = useMemo(() => {
    if (!cases.length) return 'INCOMPLETE';

    const lastCase = cases[0]
    switch (lastCase.status) {
      case 'completed':
      case 'complete':
        return 'COMPLETED'
    
      case 'expired':
      case 'pending approval':
      case 'rejected':
        return 'REQUIRES_REVIEW'
      
      case 'requested':
      case 'in progress':
      case 'repeated':
        return 'IN_PROGRESS'
      
      default:
        return 'INCOMPLETE'
    }
  }, [cases])

  const eidvStatusIcon = useMemo(() => {
    switch (eidvStatus) {
      case 'COMPLETED':
        return (
          <CustomTooltip title="Electronic Verification is complete" placement="top">
            <CheckmarkSuccessIcon />
          </CustomTooltip>
        )

      case 'IN_PROGRESS':
        return (
          <CustomTooltip title="Electronic Verification is in progress" placement="top">
            <CheckmarkWarningIcon />
          </CustomTooltip>
        )

      case 'REQUIRES_REVIEW':
        return (
          <CustomTooltip title="Electronic Verification requires review" placement="top">
            <CheckmarkErrorIcon />
          </CustomTooltip>
        )

      case 'INCOMPLETE':
      default:
        return (
          <CustomTooltip title="Electronic Identity Verification missing" placement="top">
            <CheckmarkErrorIcon className={clsx(classes.greyscaleCheckmark)} />
          </CustomTooltip>
        )
    }
  }, [eidvStatus, classes])


  return [fetchCases, eidvStatusIcon]
}
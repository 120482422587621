export const getClientName = (client) => {
  if (client.clientType === 'PERSON') {
    return `${client.firstName} ${client.lastName}`;
  }
  return client.name;
}

export const getClientPronoun = (client) => {
  if (client.gender === 'male') {
    return 'he';
  }
  if (client.gender === 'female') {
    return 'she';
  }
  return 'it';
}
import React, { useState, useMemo } from 'react';
import { Box, Grid, FormControlLabel, Switch, MenuItem } from '@material-ui/core';
import TableList from '@components/lists/table-list';
import OutlinedSearch from '@components/inputs/outlined-search';
import InlineHeadBadge from '@components/badges/inline-head-badge';
import FilterWithContext from '@components/dropdowns/filter-with-context';
import MenuWithContext from '@components/dropdowns/menu-with-context';
import { Modal } from '@components/modals';
import { SearchIcon, AddActive2Icon } from '@app/icons';
import useErrorState from '@utils/errorState';
import { arrayToString } from '@utils/textUtil';
import InnerHeader from '@components/layout/inner-header';
import ButtonWithIcon from '@components/buttons/button-with-icon';

import EditForm from './components/edit-form';
import AddForm from './components/add-form';

import history from '@app/history';

const TABLE_COLUMNS = [
  { field: 'name', headerName: 'Name', bold: true },
  { field: 'email', headerName: 'Email' },
  { field: 'status', headerName: 'Status' },
]

const items = [
  { id: 1,
    name: 'John Doe',
    email: 'jd@gmail.com',
    firstName: 'John',
    lastName: 'Doe',
    country: 'US',
    address: '711-2880 Nulla St. Mankato Mississippi 96522',
    city: 'Mankato',
    state: 'Mississipi',
    zip: '96522',
    phone: '+199877846484',
    status: 'Active',
    isChecked: false
  },
  { id: 2,
    name: 'Samantha Smith',
    email: 'sam@google.com',
    firstName: 'Samantha',
    lastName: 'Smith',
    country: 'US',
    address: '711-2880 Nulla St. Mankato Mississippi 96522',
    city: 'Mankato',
    state: 'Mississipi',
    zip: '96522',
    phone: '+199877846484',
    status: 'Blocked',
    isChecked: false
  },
  { id: 3,
    name: 'Alex Brave',
    email: 'jd@gmail.com',
    firstName: 'Alex',
    lastName: 'Brave',
    country: 'US',
    address: '711-2880 Nulla St. Mankato Mississippi 96522',
    city: 'Mankato',
    state: 'Mississipi',
    zip: '96522',
    phone: '+199877846484',
    status: 'Invited',
    isChecked: false
  },
]

const schema = {
  email: '',
  firstName: '',
  lastName: '',
  country: '',
  address: '',
  state: '',
  city: '',
  zip: '',
  phone: '',
}

const switchGroup = [
  { value: 'active', label: 'Active Team Members', isChecked: false },
  { value: 'blocked', label: 'Blocked Team Members', isChecked: false },
  { value: 'invited', label: 'Invited Team Members', isChecked: false },
]

const tabs = [
  {
    id: 'team',
    label: 'My Team',
  },
]

export default function MyTeam() {
  const [tabID, setTabID] = useState('team');
  const [modalAction, setModalAction] = useState(null);
  const [model, setModel] = useState(schema);
  const [search, setSearch] = useState('');
  const [data, setData] = useState(items);
  const [formData, setFormData] = useState([]);
  const [formError, setFormError] = useState('');
  const [filters, setFilters] = useState(switchGroup);

  const [errors, setErrors] = useErrorState({}); // eslint-disable-line

  const onTabChange = (index) => {
    const tabID = tabs[index].id;
    setTabID(tabID);
    history.push(`/billing-management/${tabID}`)
  }

  const handleAdd = (id, item, type) => {
    setModalAction({ id, name: '', type, prefix: 'team member', actionType: 'invite' });
  }

  const onSearchChange = (event) => {
    setSearch(event.target.value);
  }

  const onSelect = (id) => {
    const newData = data.map((item) => item.id === id
      ? ({ ...item, isChecked: !item.isChecked })
      : item
    );
    setData(newData);
  }

  const handleMenuAction = (id, item, type) => {
    setModalAction({ id, name: `"${item.name}"`, type, prefix: 'team member', actionType: '' });
  }

  const handleInvite = () => {
    if (!formData.length) {
      setFormError('At least one email should be provided.');
      return;
    }
    setModalAction(null);
  }

  const handleEdit = (id, item, type) => {
    const userData = data.find(el => el.id === id);
    let newModel = {};
    for (const key in model) {
      const value = key === 'phone' ? Number(userData[key]) : userData[key];
      newModel = { ...newModel, [key]: value }
    }
    setModel(newModel);

    setModalAction({ id, name: '', type, prefix: 'team member details', actionType: type });
  }

  const handleChange = (prop) => (event) => {
    const newModel = { ...model, [prop]: event.target.value };
    setModel(newModel);
  };

  const onFilterChange = (event) => {
    const changed = filters.map(el =>
      el.value === event.target.value ? ({ ...el, isChecked: event.target.checked }) : el
    );
    setFilters(changed);
  }

  const handleMoreAction = (type) => {
    const checkedItems = data.filter(el => el.isChecked).map(el => `"${el.name}"`);
    setModalAction({ name: arrayToString(checkedItems), type, prefix: 'team members' })
  }

  const formattedData = useMemo(() => {
    return data.map(e => {
      const item = { ...e };
      
      switch (item.status) {
        case 'Active':
          item.cellProps = {
            status: {
              style: {
                color: '#4E922C'
              }
            }
          }
          item.threeDotsActionsDisabled = ['Unblock']
          break;

        case 'Blocked':
          item.cellProps = {
            status: {
              style: {
                color: '#B6514C'
              }
            }
          }
          item.threeDotsActionsDisabled = ['Block']
          break;
        
        case 'Invited':
          item.cellProps = {
            status: {
              style: {
                opacity: 0.5
              }
            }
          }
          break;
        
        default:
          item.threeDotsActionsDisabled = ['Unblock']
          break;
      }

      return item;
    })
  }, [data])

  const content = {
    'edit': <EditForm model={model} errors={errors} handleChange={handleChange} />,
    'view': <EditForm model={model} errors={errors} handleChange={() => {}} disabled />,
    'invite': <AddForm setData={setFormData} formError={formError} setFormError={setFormError} />
  }

  return (
    <Box mx={6} height="100%">
      <InnerHeader
        onTabChange={onTabChange}
        ind={tabs.findIndex(tab => tab.id === tabID)}
        tabs={tabs}
        buttons={
          <ButtonWithIcon
            startIcon={<AddActive2Icon />}
            onClick={() => handleAdd(null, null, 'invite')}
          >
            New Team Member
          </ButtonWithIcon>
        }
      />
      <Box display={'flex'} flexGrow={'1'} mt={2} flexDirection={'column'}>
        <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
          <Box display={'flex'}>
            <Box mr={1}>
              <InlineHeadBadge
                badgeColor={'#D5F0F0'}
                badgeValue={3}
              >
                Team Members
              </InlineHeadBadge>
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'center'}>
            <FilterWithContext>
              <Grid container spacing={8}>
                <Grid item xs={6}>
                  {filters.map((el, i) => (
                    <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value} - ${i}`}>
                      <FormControlLabel
                        control={<Switch value={el.value} checked={el.isChecked} onClick={onFilterChange} />}
                        label={el.label}
                      />
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </FilterWithContext>
            <MenuWithContext title="More Actions">
              <MenuItem onClick={() => handleMoreAction('close')} disabled={!data?.find(el => el.isChecked)}>
                Close
              </MenuItem>
              <MenuItem onClick={() => handleMoreAction('block')} disabled={!data?.find(el => el.isChecked)}>
                Block
              </MenuItem>
            </MenuWithContext>
          </Box>
        </Box>
        <Box display={'flex'} mb={3}>
          <OutlinedSearch
            value={search}
            onChange={onSearchChange}
            icons={<SearchIcon />}
            label="Search team members"
          />
        </Box>
        <Grid container>
          <Grid item sm={12}>
            <TableList
              columns={TABLE_COLUMNS}
              items={formattedData}
              onSelect={onSelect}
              onClick={(id) => history.push(`/team/team-member-view?userId=${id}`)}
              pageSize={12}
              pagesCount={1}
              totalCount={9}
              sortableColumns={TABLE_COLUMNS.map(el => el.field)}
              maxHeight="calc(100vh - 380px)"
              threeDotsActions={[
                // { label: 'View', handler: (id, item) => handleEdit(id, item, 'view') },
                { label: 'Edit', handler: (id, item) => handleEdit(id, item, 'edit') },
                { label: 'Close', handler: (id, item) => handleMenuAction(id, item, 'close') },
                { label: 'Block', handler: (id, item) => handleMenuAction(id, item, 'block') },
                { label: 'Unblock', handler: (id, item) => handleMenuAction(id, item, 'unblock'), disabled: true },
              ]}
            />
          </Grid>
        </Grid>
        <Modal
          open={!!modalAction}
          onClose={() => setModalAction(null)}
          title={`${modalAction?.type} ${modalAction?.prefix}`}
          mainText={!modalAction?.actionType && `Are you sure you want to ${modalAction?.type} ${modalAction?.prefix} ${modalAction?.name}?`}
          actions={
            [
              {
                type: 'main',
                label: !!modalAction?.actionType ? 'CANCEL' : 'NO',
                action: () => setModalAction(null),
                style: { width: 160 },
              },
              {
                type: 'secondary',
                label: !modalAction?.actionType ? 'YES' : (modalAction?.actionType === 'edit' ? 'SAVE' : 'INVITE'),
                action: () => modalAction?.actionType === 'invite' ? handleInvite() : setModalAction(null),
                style: { width: 160 },
              },
            ]
          }
          actionsDirection="row"
          content={content[modalAction?.actionType]}
        />
      </Box>
    </Box>
  )
}
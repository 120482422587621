import React from 'react';
import { Box, Typography, IconButton } from '@material-ui/core';
import CustomTooltip from '@components/controls/tooltip';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paginationsButton: {
    padding: '4px',
  },
}))

export default function VersionsPagination({ pageNumber, pageSize, count, pages, setPageNumber }) {
  const classes = useStyles();

  const from = (pageNumber * pageSize) + 1;
  const to = Math.min((pageNumber + 1) * pageSize, count);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" width={'100%'}>
      <CustomTooltip
        title="Back"
        placement="top"
      >
        <span>
          <IconButton
            onClick={() => setPageNumber(pageNumber - 1)}
            disabled={!pageNumber}
            classes={{ root: classes.paginationsButton }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </span>
      </CustomTooltip>
      <Typography variant={'caption'}>
          Showing {from} to {to} out of {count}
      </Typography>
      <CustomTooltip
        title="Next"
        placement="top"
      >
        <span>
          <IconButton
            onClick={() => setPageNumber(pageNumber + 1)}
            disabled={pageNumber === pages - 1}
            classes={{ root: classes.paginationsButton }}
          >
            <ChevronRightIcon />
          </IconButton>
        </span>
      </CustomTooltip>
    </Box>
  )
}
import React, { useState, useMemo, useCallback } from 'react';
import { Box, MenuItem } from '@material-ui/core';
import InnerHeader from '@components/layout/inner-header';
import MenuWithContext from '@components/dropdowns/menu-with-context';

import Emails from './emails';
import Notifications from './notifications';

const tabs = [
  {
    id: 'emails',
    label: 'Emails',
  },
  {
    id: 'notifications',
    label: 'Notifications',
  },
]

export default function Communication(props) {
  const [tabID, setTabID] = useState('emails');
  const [data, setData] = useState({ emails: [], notifications: [] });

  const isEmails = useMemo(() => {
    return tabID === 'emails';
  }, [tabID])

  const onTabChange = (index) => {
    const tabID = tabs[index].id;
    setTabID(tabID);
  }

  const handleData = useCallback((key, items) => {
    setData(data => ({ ...data, [key]: items }));
  }, [setData])

  return (
    <Box mx={6} height="100%">
      <InnerHeader
        onTabChange={onTabChange}
        ind={tabs.findIndex(tab => tab.id === tabID)}
        tabs={tabs}
        buttons={
          <Box mr={1}>
            <MenuWithContext title="More Actions">
              {isEmails ? (
                <React.Fragment>
                  <MenuItem disabled={!data.emails.find(el => el.isChecked)}>
                    Cancel
                  </MenuItem>
                  <MenuItem disabled={!data.emails.find(el => el.isChecked)}>
                    Resend
                  </MenuItem>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <MenuItem disabled={!data.notifications.find(el => el.isChecked)}>
                    Mark As Read
                  </MenuItem>
                  <MenuItem disabled={!data.notifications.find(el => el.isChecked)}>
                    Delete
                  </MenuItem>
                </React.Fragment>
              )}
            </MenuWithContext>
          </Box>
        }
      />
      {isEmails ? (
        <Emails setEmails={data => handleData('emails', data)} />
      ) : (
        <Notifications setNotifications={data => handleData('notifications', data)} />
      )}
    </Box>
  )
}
import React, { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box } from '@material-ui/core';
import { adminApi } from '@app/api';
import NoResultsFound from '@components/layout/no-results-found';
import ConfirmDialog from '@components/dialogs/confirm-dialog';
import CategoryCreateDialog from '@components/complex/client-view/kyc-documents/components/category-create-dialog';
import Card from './category-card';
import { loadRulebookCategory } from '../utils/prepareRulebookData';
import { capitalize } from '@utils/textUtil';

export default function Documents({ documents, setDocuments, isAddNew, categories, fetchCategories, types, selectedGlobalRule, clientType }) {
  const { enqueueSnackbar } = useSnackbar()
  const [categoryDialogOpen, setCategoryDialogOpen] = useState(false)
  useEffect(() => {
    if (isAddNew) {
      setCategoryDialogOpen(true);
    }
  }, [isAddNew])

  const [pendingDeleteIndex, setPendingDeleteIndex] = useState(null)

  const onSelectCategory = async category => {
    let newDocument;
    if (!category.id) {
      newDocument = (await adminApi.category.createCategory(category)).data
      enqueueSnackbar(`Document category "${category.name}" was successfully created!`, { variant: 'success' })

      newDocument.types = [];
      newDocument.categoryId = newDocument.id;
      fetchCategories()
    } else {
      newDocument = loadRulebookCategory(category.id, categories, types)
    }
    newDocument = {
      ...newDocument,
      categoryRuleType: 'PER_TYPE',
    }
    const newDocuments = [...documents, newDocument]
    setDocuments(newDocuments)
    setCategoryDialogOpen(false)
  }

  const onCategoryUpdate = (newCategory) => {
    const index = documents.findIndex(e => e.id === newCategory.id)
    documents[index] = newCategory
    setDocuments([...documents])
  }

  const onDeleteConfirm = async () => {
    documents.splice(pendingDeleteIndex, 1);
    setDocuments(documents.slice())
    setPendingDeleteIndex(null)
  }

  const globalDocuments = useMemo(() => {
    if (!selectedGlobalRule) {
      return []
    }

    const globalDocuments = selectedGlobalRule.documentRules.map(rule => ({
      ...categories.find(e => e.id === rule.categoryId),
      ...rule,
      types: rule.types.map(type => ({
        ...types.find(e => e.id === type.typeId),
        expected: type.expected,
        isChecked: true,
      })),
      disabled: true,
    }))

    return globalDocuments
  }, [selectedGlobalRule]) // eslint-disable-line

  const filteredCategories = useMemo(() => {
    return categories.filter(e => e.usageTypes.includes(clientType))
  }, [categories, clientType])

  return (
    <React.Fragment>
      <CategoryCreateDialog
        open={categoryDialogOpen}
        onClose={() => setCategoryDialogOpen(false)}
        onSave={onSelectCategory}
        title="Add Document Category"
        categories={filteredCategories}
        clientType={clientType}
      />
      <ConfirmDialog
        open={pendingDeleteIndex !== null}
        onClose={() => setPendingDeleteIndex(null)}
        title={'Exclude category'}
        text={`Are you sure you want to exclude the "${capitalize(documents[pendingDeleteIndex]?.name)}"?`}
        onConfirm={onDeleteConfirm}
      />
      {(globalDocuments.length || documents.length) ?
        <Box display={'flex'} flexDirection={'column'} width={'100%'}>
          {globalDocuments.map((category, index) => (
            <Card
              key={index}
              category={category}
              onDelete={() => setPendingDeleteIndex(index)}
              setCategory={onCategoryUpdate}
              categories={categories}
              types={types}
              clientType={clientType}
              fetchCategories={fetchCategories}
            />
          ))}
          {documents.map((category, index) => (
            <Card
              key={index}
              category={category}
              onDelete={() => setPendingDeleteIndex(index)}
              setCategory={onCategoryUpdate}
              categories={categories}
              types={types}
              clientType={clientType}
              fetchCategories={fetchCategories}
            />
          ))}
        </Box>
        :
        <Box mt={8} flexGrow={1}>
          <NoResultsFound>
            The documents configuration will appear here
          </NoResultsFound>
        </Box>
      }
    </React.Fragment>
  )
}

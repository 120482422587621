import React, { useState, useEffect } from 'react';
import { Box, Typography, FormControl, InputLabel, OutlinedInput, Grid, Button, FormHelperText } from '@material-ui/core';
import OutlinedPassword from '@components/inputs/outlined-password';
import InnerHeader from '@components/layout/inner-header';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { ChevronLeft } from '@material-ui/icons';

import { useLocation } from 'react-router-dom';
import history from '@app/history';
import useErrorState from '@utils/errorState';
import * as yup from 'yup';
import parseYupErrors from '@app/yup';
import { dummy } from '../dummy';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
  },
  backButton: {
    marginLeft: 0
  }
}))

const inputs = [
  {
    key: 'firstName',
    label: 'First Name',
  },
  {
    key: 'lastName',
    label: 'Last Name',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'password',
    label: 'Password',
    password: true
  },
  {
    key: 'confirm_password',
    label: 'Confirm Password',
    password: true
  },
]

const defaultModel = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirm_password: '',
}

export default function AddAdmin({ isEdit }) {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [adminId] = useState(search.get('adminId'));

  const [model, setModel] = useState(defaultModel);

  const classes = useStyles();

  const [errors, setErrors] = useErrorState({});

  useEffect(() => {
    if (isEdit) {
      const admin = dummy.admins.find(el => +el.id === +adminId);
      setModel({
        firstName: admin.firstName,
        lastName: admin.lastName,
        email: admin.email,
        password: '123456Ab*',
        confirm_password: '123456Ab*',
      })
    }
  }, [adminId, isEdit])

  const handleChange = (prop) => (event) => {
    const newModel = { ...model, [prop]: event.target.value };
    setModel(newModel);

    if (errors[prop]) {
      schema.validateAt(prop, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [prop]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [prop]: err.message });
        });
    }
  };

  const schema = yup.object().shape({
    firstName: yup.string().label('First name').min(3).required('First name is required').max(30).trim(),
    lastName: yup.string().label('Last name').required('Last name is required').max(30),
    email: yup.string().label('Email').lowercase().trim().email('Invalid email format').required('Email is required').max(50),
    password: yup.string().label('Password').required()
      .test('format', 'Password must have at least 8 characters, one capital letter, one small letter, one number and one special character', function () {
        const regex = new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\\-=\\[\\]{};\':"\\\\|,.<>/?]).{8,}$');
        return regex.test(this.parent.password);
      })
      .test('format_space', 'Password can\'t start or end with space(s)', function () {
        const trimmedPassword = this.parent.password.trim();
        return trimmedPassword === this.parent.password;
      }),
    confirm_password: yup.string().label('Confirm password').required().test('match', 'Passwords do not match', function () {
      return this.parent.confirm_password === this.parent.password
    }),
  });

  const onSave = () => {
    setErrors({});
    schema
      .validate(model, { abortEarly: false })
      .then(function (valid) {
        history.push('/admin-management')
      })
      .catch(function (err) {
        console.log(err)
        setErrors(parseYupErrors(err), true);
      });
  }

  return (
    <React.Fragment>
      <Box display={'flex'} mx={6} flexDirection={'column'} height={'100%'}>
        <Box mb={isEdit ? 3 : 5}>
          <InnerHeader
            buttons={
              <ButtonWithIcon startIcon={<ChevronLeft />} onClick={() => history.goBack()}>
                Back
              </ButtonWithIcon>
            }
            title={isEdit && 'Edit admin'}
          />
        </Box>
        {!isEdit && (
          <Box display="flex" mt={1} mb={4} alignItems="center" justifyContent="center">
            <Typography variant="h4">Add new admin</Typography>
          </Box>
        )}
        <Grid container alignItems="center" direction="column">
          {inputs.map((field, i) => (
            <Grid item sm={4} className={classes.input} key={field.key}>
              {(!i && isEdit) && (
                <Box mb={2} display={'flex'}>
                  <Typography variant="h5">Basic Details</Typography>
                </Box>
              )}
              <FormControl
                fullWidth
                variant="outlined"
                id={field.key}
                error={!!errors[field.key]}
              >
                {field.password ? (
                  <React.Fragment>
                    <InputLabel htmlFor="name">{field.label}</InputLabel>
                    <OutlinedPassword
                      value={model[field.key]}
                      onChange={handleChange(field.key)}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <InputLabel htmlFor="name">{field.label}</InputLabel>
                    <OutlinedInput
                      value={model[field.key]}
                      onChange={handleChange(field.key)}
                    />
                  </React.Fragment>
                )}
                {!!errors[field.key] &&
                  <FormHelperText>{errors[field.key] || null}</FormHelperText>
                }
              </FormControl>
            </Grid>
          ))}
        </Grid>
        <Box display="flex" mt={8} alignItems="center" justifyContent="center">
          <Button style={{ width: '180px' }} variant={'contained'} onClick={onSave}>
            SAVE
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  )
}
import React, { useCallback } from  'react';
import { FormControl, InputLabel, MenuItem, OutlinedInput } from '@material-ui/core';
import SelectClearable from '@components/inputs/select-clearable';

import { useSelector, useDispatch } from 'react-redux';
import { backgroundSearch } from '@app/store/actions';

import { PREMIUM, YOB, ALPHABLETS, GENDERS } from '../../constants';
import { countriesISO } from '@services/country-service';
import { includes } from 'lodash';
import { useStyles } from '../styles';

export default function PersonInputs() {
  const classes = useStyles()

  const { searchModel } = useSelector(state => state.backgroundSearch);
  const dispatch = useDispatch();

  const handleYear = useCallback((e) => {
    const max = new Date().getFullYear();
    if (+e.target.value > max) {
      e.target.value = max
    } else if (+e.target.value < 0) {
      e.target.value = 0
    }
    return e.target.value;
  }, [])

  const handleChange = useCallback((event, key) => {
    const value = key === YOB ? handleYear(event) : (event?.target?.value || '');
    dispatch(backgroundSearch.setSearchModel({ [key]: value }));
  }, [dispatch, handleYear])

  const countryValue = useCallback(() => {
    const item = countriesISO.find(el => el.code === searchModel.country);
    return item?.code || '';
  }, [searchModel])

  const handleNumberInputs = useCallback((e) => {
    if (!includes(ALPHABLETS, e.key)) {
      e.preventDefault();
    } 
  }, [])

  return (
    <React.Fragment>
      <FormControl className={classes.inputPersonWrapper} variant="outlined">
        {!countryValue() && (
          <InputLabel htmlFor="name" className={classes.inputLabel}>Country</InputLabel>
        )}
        <SelectClearable
          value={countryValue()}
          onChange={(event) => handleChange(event, 'country')}
          style={{ width: '100%' }}
          classes={{ select: classes.advancedSelect }}
        >
          {countriesISO.map((item, i) => (
            <MenuItem
              key={`${item}-${i}`}
              value={item.code}
            >
              {item.name}
            </MenuItem>
          ))}
        </SelectClearable>
      </FormControl>
      <FormControl className={classes.inputPersonWrapper} variant="outlined">
        <OutlinedInput
          id="date"
          placeholder="Year of Birth"
          value={searchModel.yob}
          onChange={(event) => handleChange(event, 'yob')}
          inputProps={{ type: 'number', min: 1900, max: new Date().getFullYear().toString() }}
          type="number"
          onKeyDown={handleNumberInputs}
          classes={{ input: classes.advancedInput }}
        />
      </FormControl>
      {searchModel.searchProvider !== PREMIUM && (
        <FormControl className={classes.inputPersonWrapper} variant="outlined">
          {!searchModel.gender && (
            <InputLabel htmlFor="name" className={classes.inputLabel}>Gender</InputLabel>
          )}
          <SelectClearable
            value={searchModel.gender}
            onChange={(event) => handleChange(event, 'gender')}
            style={{ width: '100%' }}
            classes={{ select: classes.advancedSelect }}
          >
            {GENDERS.map((item, i) => (
              <MenuItem
                key={`${item}-${i}`}
                value={item}
              >
                {item}
              </MenuItem>
            ))}
          </SelectClearable>
        </FormControl>
      )}
    </React.Fragment>
  )
}

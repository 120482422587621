import React, {useEffect, useMemo, useState} from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  capitalize,
  Divider,
  Grid,
  makeStyles,
  MenuItem,
  Typography
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';

import { useStyles } from '../index/styles';
import FoldersTreeView from '../index/folders-tree-view';

import {clientApi} from '@app/api';
import {
  NewFolderIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PortfolioEntitiesIcon,
  SelectAllActiveIcon
} from '@app/icons';
import history from '@app/history';
import {prepareClient} from '@dto/client';

import ProgressDialog from '@components/modals/progress-dialog';
import PageLayout from '@components/layout/page-layout';
import Input from '@components/inputs/filled-input';
import Select from '@components/inputs/filled-select';
import CheckboxLight from '@components/inputs/checkbox-light';
import RegularPagination from '@components/pagination/regular-pagination';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import InnerHeader from '@components/layout/inner-header';
import NoResultsFound from '@components/layout/no-results-found';
import IconDropdownWithContext from '@components/dropdowns/icon-dropdown-with-context';
import CustomTooltip from '@components/controls/tooltip';
import FolderFormDialog from '../dialogs/folder-form-dialog';
import DeleteDialog from '../dialogs/delete-dialog';
import OrganizeSuccess from './dialog-success';

const personFields = [
  { label: 'First Name', value: 'first_name', width: 20 },
  { label: 'Last Name', value: 'last_name', width: 20 },
  { label: 'Date of Birth', value: 'date_of_birth', width: 10 },
  { label: 'Residency', value: 'residency', width: 15 },
  { label: 'Gender', value: 'gender', width: 10, capitalize: true },
]

const companyFields = [
  { label: 'Legal Entity Name', value: 'name', width: 30 },
  { label: 'Entity Type', value: 'type', width: 15 },
  { label: 'Country', value: 'country', width: 15 },
  { label: 'Registration Number', value: 'registration_number', width: 15 },
]

const useThisStyles = makeStyles((theme) => ({
  formLabel: {
    textTransform: 'uppercase',
    fontSize: 12,
  },
  fieldWrapper: {
    marginRight: 10,
  },
  button: {
    padding: '10px 20px !important',
    width: 200,
  },
}));

export default function OrganizeFolder(props) {
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const thisClasses = useThisStyles();

  const tabs = [
    {
      id: 'person',
      label: 'People',
    },
    {
      id: 'company',
      label: 'Legal Entities',
    }
  ]
  const [isUploading, setIsUploading] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [tabID, setTabID] = useState(params.tab || 'person');
  const personTab = useMemo(() => tabID === 'person', [tabID])
  const fieldsToUse = useMemo(() => personTab ? personFields : companyFields, [personTab])

  const [uploadedIds] = useState(() => {
    return JSON.parse(localStorage.getItem('importCsvIdsFolder'))
  })
  const [monitoringIdsExist] = useState(() => {
    return JSON.parse(localStorage.getItem('importCsvIdsMonitoring'))
  })

  const allIds = useMemo(() => {
    return personTab
      ? uploadedIds?.personIds || []
      : uploadedIds?.companyIds || []
  }, [personTab, uploadedIds])

  const useTabs = useMemo(() => {
    if (uploadedIds?.personIds.length && !uploadedIds?.companyIds.length) {
      return [tabs[0]];
    } else if (!uploadedIds?.personIds.length && uploadedIds?.companyIds.length) {
      return [tabs[1]];
    }
    return tabs;
    }, [uploadedIds]) // eslint-disable-line

  const [data, setData] = useState([]);
  const [selectedItemsPerson, setSelectedItemsPerson] = useState({});
  const [selectedItemsCompany, setSelectedItemsCompany] = useState({});
  const [selectedItems, setSelectedItems] = useMemo(() => {
    return personTab
      ? [selectedItemsPerson, setSelectedItemsPerson]
      : [selectedItemsCompany, setSelectedItemsCompany]
    }, [personTab, selectedItemsPerson, selectedItemsCompany]) // eslint-disable-line

  // Pagination
  const [personPage, setPersonPage] = useState(0)
  const [companyPage, setCompanyPage] = useState(0)
  const [page, setPage] = useMemo(() => {
    return personTab
      ? [personPage, setPersonPage]
      : [companyPage, setCompanyPage]
    }, [personTab, personPage, companyPage]) // eslint-disable-line

  const [pageSize, setPageSize] = useState(12)
  const [pagesCount, setPagesCount] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const onPageChange = (event, page) => {
    setPage(page - 1)
  }

  // Folders
  const [foldersTreeOpen, setFoldersTreeOpen] = useState(true);
  const [folderId, setFolderId] = useState(null);
  const [flatFolders, setFlatFolders] = useState([]);
  const [folders, setFolders] = useState([]);
  const [pendingFolderFormID, setPendingFolderFormID] = useState(false);
  const [pendingDelete, setPendingDelete] = useState(null);

  const [folderItemsPerson, setFolderItemsPerson] = useState({});
  const [folderItemsCompany, setFolderItemsCompany] = useState({});
  const [folderItems, setFolderItems] = useMemo(() => {
    return personTab
      ? [folderItemsPerson, setFolderItemsPerson]
      : [folderItemsCompany, setFolderItemsCompany]
  }, [personTab, folderItemsPerson, folderItemsCompany])

  const folderName = useMemo(() => {
    return flatFolders.find(folder => folder.id === folderId)?.name
  }, [folderId, flatFolders])

  useEffect(() => {
    if (uploadedIds) {
      fetchFolders()
    }
    }, []) //eslint-disable-line

  useEffect(() => {
    if (!uploadedIds ||
            (!uploadedIds?.personIds?.length && !uploadedIds?.companyIds?.length)
    ) {
      enqueueSnackbar('Cannot find recently imported client!', { variant: 'error' });
      history.goBack();
    }
    if (uploadedIds?.personIds.length && !uploadedIds?.companyIds.length) {
      onTabChange(0);
    } else if (!uploadedIds?.personIds.length && uploadedIds?.companyIds.length) {
      onTabChange(0);
    }
    }, [uploadedIds]) // eslint-disable-line

  useEffect(() => {
    fetchItems()
    }, [tabID, folderId, page, folderItems, pageSize]) // eslint-disable-line

  const fetchItems = async () => {
    let ids = allIds;
    if (folderId) {
      ids = folderItems[folderId] || [];
    }

    const totalCount = ids.length;
    setTotalCount(ids.length);

    if (totalCount > 0) {
      const startIndex = page * pageSize;
      const idsToFetch = ids.slice(startIndex, startIndex + pageSize);
    
      const data = (await clientApi.client.bulkFetch(idsToFetch)).data
      setData(data.map(item => prepareClient(item)))
    } else {
      setData([])
    }

    setPagesCount(Math.ceil(ids.length / pageSize));
  }

  const fetchFolders = async () => {
    const foldersData = (await clientApi.folder.index({pageSize:1000})).data?.entries || []
    setFlatFolders([...foldersData.sort((a, b) => a.name.toLowerCase() === 'home' ? -1 : 1)])
    const rootIdx = foldersData.findIndex(f => f.name.toLowerCase() === 'home')
    const rootFolder = foldersData[rootIdx]
    foldersData.splice(rootIdx, 1)
    setFolders([{
      ...rootFolder,
      children: [...foldersData]
    }])

    setFolderItemsPerson({ [rootFolder.id]: [...uploadedIds.personIds] });
    setFolderItemsCompany({ [rootFolder.id]: [...uploadedIds.companyIds] });
  }

  const hasNextTab = useMemo(() => {
    if (useTabs.length === 1) {
      return false;
    }
    const index = useTabs.findIndex(e => e.id === tabID)
    if (index === 0) {
      return true;
    }
    return false;
  }, [tabID, useTabs])

  const handleFinalize = () => {
    if (hasNextTab) {
      return onTabChange(1);
    }

    const combinedFolderItems = {}
    Object.entries(folderItemsPerson).forEach(([folderId, clientIds]) => {
      combinedFolderItems[folderId] = clientIds;
    })
    Object.entries(folderItemsCompany).forEach(([folderId, clientIds]) => {
      if (!combinedFolderItems[folderId]) {
        combinedFolderItems[folderId] = [];
      }
      combinedFolderItems[folderId] = combinedFolderItems[folderId].concat(clientIds);
    })

    const requests = Object.entries(combinedFolderItems)
      .filter(([folderId, clientIds]) => !!clientIds.length)
      .map(([folderId, clientIds]) => clientApi.folder.addClients(folderId, clientIds, true))

    setIsUploading(true);
    setShowProgress(true);

    Promise.all(requests).then(() => {
      enqueueSnackbar('Imported client(s) have been organized!', { variant: 'success' });
      setIsUploading(false);
      localStorage.removeItem('importCsvIdsFolder');
    })
  }

  const uploadSuccess = () => {
    setShowProgress(false);
    setShowSuccessDialog(true);
  }

  const onTabChange = (index) => {
    const tabID = useTabs[index].id
    setTabID(tabID)
    const uri = `/portfolio/organize/${tabID}`
    history.replace(uri)
  }

  const onToggleFoldersTree = () => {
    setFoldersTreeOpen(!foldersTreeOpen)
  }

  const onFolderUpdate = (id) => {
    setPendingFolderFormID(id);
  }

  const onFolderDelete = (id) => {
    const folder = flatFolders.find(v => v.id === id);
    if(folder.name.toLowerCase() === 'home') {
      return;
    }
    setPendingDelete({
      type: 'folder',
      id: folder.id,
      name: folder.name
    })
  }

  const onFolderSave = () => {
    setPendingFolderFormID(false)
    fetchFolders()
    fetchItems()
  }

  const onDeleteSuccess = () => {
    setPendingDelete(null)
    fetchFolders()
    fetchItems()
  }

  const onFolderSelect = (folderId) => {
    setPage(0)
    setFolderId(folderId)
  }

  const selectItem = (itemId) => {
    setSelectedItems({ ...selectedItems, [itemId]: !selectedItems[itemId] })
  }

  const allItemsSelected = useMemo(() => {
    return allIds.every(id => selectedItems[id])
  }, [selectedItems, allIds])

  const selectAllItems = () => {
    const newSelectedItems = { ...selectedItems }
    allIds.forEach(id => {
      newSelectedItems[id] = !allItemsSelected;
    })
    setSelectedItems(newSelectedItems)
  }

  const allPageItemsSelected = useMemo(() => {
    return data.every(e => selectedItems[e.id])
  }, [selectedItems, data])

  const somePageItemsSelected = useMemo(() => {
    return !allPageItemsSelected && data.some(e => selectedItems[e.id])
  }, [selectedItems, data, allPageItemsSelected])

  const selectPageItems = () => {
    const newSelectedItems = { ...selectedItems }
    data.forEach(e => {
      newSelectedItems[e.id] = !allPageItemsSelected;
    })
    setSelectedItems(newSelectedItems)
  }

  const changeItemsFolder = (newFolderId) => () => {
    const itemIds = Object.entries(selectedItems).filter(([key, value]) => !!value).map(([key, value]) => +key);

    Object.keys(folderItems).forEach(key => {
      folderItems[key] = folderItems[key].filter(e => !itemIds.includes(e))
    })

    if (!folderItems[newFolderId]) {
      folderItems[newFolderId] = [];
    }
    folderItems[newFolderId] = folderItems[newFolderId].concat(itemIds)

    setSelectedItems({})
    setFolderItems({ ...folderItems })
  }

  const changeItemFolder = (itemId) => (event) => {
    const prevFolderId = itemsFolder[itemId]
    const newFolderId = event.target.value

    folderItems[prevFolderId] = folderItems[prevFolderId].filter(e => e !== itemId)
    if (!folderItems[newFolderId]) {
      folderItems[newFolderId] = [];
    }
    folderItems[newFolderId].push(itemId)

    setFolderItems({ ...folderItems })
  }

  // reverse folderItems
  const itemsFolder = useMemo(() => {
    const itemsFolder = {};
    Object.entries(folderItems).forEach(([key, items]) => {
      items.forEach(itemId => {
        itemsFolder[itemId] = +key;
      })
    })

    return itemsFolder;
  }, [folderItems])

  const countSelected = useMemo(() => {
    return Object.values(selectedItems).filter(e => !!e).length
  }, [selectedItems])

  return (
    <PageLayout>
      <ProgressDialog
        open={showProgress}
        onClose={uploadSuccess}
        isLoading={isUploading}
        title="Organizing by Folder"
        progressText="Processing..."
      />

      <OrganizeSuccess
        open={showSuccessDialog}
        onClose={() => setShowSuccessDialog(false)}
        monitoringIdsExist={!!monitoringIdsExist}
      />

      <FolderFormDialog
        open={pendingFolderFormID !== false}
        id={pendingFolderFormID}
        onSaved={onFolderSave}
        onClose={() => {
          setPendingFolderFormID(false)
        }}
      />

      <DeleteDialog
        data={pendingDelete}
        open={!!pendingDelete}
        onDeleted={onDeleteSuccess}
        onClose={() => {
          setPendingDelete(null)
        }}
      />

      <Box px={6}>
        <InnerHeader title="Organize by Folder" />
      </Box>
      <Box px={6}>
        <InnerHeader
          onTabChange={onTabChange}
          ind={useTabs.findIndex(tab => tab.id === tabID)}
          tabs={useTabs}
          buttons={
            <React.Fragment>
              <ButtonWithIcon startIcon={<SelectAllActiveIcon />} onClick={selectAllItems}>
                {allItemsSelected ? 'Deselect All' : 'Select all'}
              </ButtonWithIcon>
              <ButtonWithIcon startIcon={<NewFolderIcon />} onClick={() => setPendingFolderFormID(null)}>
                                New Folder
              </ButtonWithIcon>
              <IconDropdownWithContext disabled={!countSelected} badge={countSelected} icon={<NewFolderIcon />} label="Change Folder">
                {flatFolders.map(option => (
                  <MenuItem onClick={changeItemsFolder(option.id)} key={option.id} value={option.id}>{option.name}</MenuItem>
                ))}
              </IconDropdownWithContext>
            </React.Fragment>
          } />
      </Box>


      <Box display={'flex'} flexDirection={'row'} flexGrow={1} className={classes.scrollableTreeWrapper}>
        {foldersTreeOpen &&
                    <Box className={classes.sideTree}>
                      <React.Fragment>
                        <Box className={clsx(classes.collapseMenuButton, 'in')} onClick={onToggleFoldersTree}>
                          <ChevronLeftIcon />
                        </Box>
                        <Box display={'flex'} pl={3} pr={2} style={{ height: '95%', width: '100%' }}>
                          <FoldersTreeView
                            onUpdate={onFolderUpdate}
                            onDelete={onFolderDelete}
                            items={folders}
                            onSelect={onFolderSelect}
                            selected={folderId}
                            onNewFolder={() => setPendingFolderFormID(null)}
                          />
                        </Box>
                      </React.Fragment>
                    </Box>
        }
        {!foldersTreeOpen &&
                    <Box className={classes.sideTreeCollapse} onClick={onToggleFoldersTree}>
                      <Box className={clsx(classes.collapseMenuButton, 'out')}>
                        <ChevronRightIcon />
                      </Box>
                      <Box display={'flex'} justifyContent={'center'} alignItems={'flex-start'}>
                        <Box alignItems={'flex-start'} style={{
                          writingMode: 'tb-rl',
                          cursor: 'pointer'
                        }}>
                          <Box mt={1} style={{ textTransform: 'uppercase', transform: 'rotate(180deg)' }}>
                            <Typography variant={'h5'} style={{ wordSpacing: '5px' }}>
                              {folderId && folderName}
                              {!folderId && 'all'}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
        }
        <Box pl={2} pr={6} flexGrow={'1'} className={classes.content} height={'100%'}>
          {!data.length &&
                        <Box display={'flex'} flexDirection="column" height={'100%'} alignItems={'center'} justifyContent={'center'}>
                          <Box>
                            <NoResultsFound icon={<PortfolioEntitiesIcon />}>
                                    You did not add any client(s) to this folder
                            </NoResultsFound>
                          </Box>
                          <Box mt={4} mb={4}>
                            <Grid container alignItems="center" justify="center">
                              <Button
                                variant="contained"
                                size="large"
                                onClick={handleFinalize}
                                className={thisClasses.button}
                              >
                                {hasNextTab ? 'Next' : 'Finish'}
                              </Button>
                            </Grid>
                          </Box>
                        </Box>
          }
          <Box height={'100%'}>
            <Box mt={2}>
              <Grid container alignItems="center">
                <CustomTooltip
                  title="Select all on this page"
                  placement="top"
                >
                  <Box>
                    <CheckboxLight
                      checked={allPageItemsSelected || somePageItemsSelected}
                      indeterminate={somePageItemsSelected}
                      onChange={selectPageItems}
                    />
                  </Box>
                </CustomTooltip>
                {fieldsToUse.map(field => (
                  <Box
                    key={field.value}
                    className={thisClasses.fieldWrapper}
                    style={{ width: `calc(${field.width}% - 10px)` }}
                  >
                    <Grid container alignItems="center">
                      <Typography className={thisClasses.formLabel} variant="h5">{field.label}</Typography>
                    </Grid>
                  </Box>
                ))}
                <Box width="15%">
                  <Grid container alignItems="center">
                    <Typography className={thisClasses.formLabel} variant="h5">Folders</Typography>
                  </Grid>
                </Box>
              </Grid>
            </Box>
            <Box height="calc(100% - 275px)" style={{ overflowY: 'auto' }}>
              <Grid container>
                {data.map(row => (
                  <Grid key={row.id} container alignItems="center">
                    <Box>
                      <CheckboxLight
                        checked={!!selectedItems[row.id]}
                        onChange={() => selectItem(row.id)}
                      />
                    </Box>
                    {fieldsToUse.map(field => (
                      <Box
                        key={field.value}
                        className={thisClasses.fieldWrapper}
                        style={{ width: `calc(${field.width}% - 10px)` }}
                      >
                        <Input
                          value={(field.capitalize ? capitalize(row[field.value] || '') : row[field.value]) || ''}
                          InputProps={{ readOnly: true }}
                          fullWidth
                        />
                      </Box>
                    ))}
                    <Box width="15%">
                      <Select
                        value={itemsFolder[row.id] || 'missing'}
                        onChange={changeItemFolder(row.id)}
                        fullWidth
                      >
                        {flatFolders.map(option => (
                          <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box width={'100%'} justifyContent={'center'} mt={3} mb={1}>
              <Divider />
            </Box>
            <Box width={'100%'} mb={2}>
              <RegularPagination
                page={page}
                pagesCount={pagesCount}
                pageSize={pageSize}
                pageSizes={[12, 25]}
                setPageSize={setPageSize}
                totalCount={totalCount}
                onChange={onPageChange}
              />
            </Box>
            <Box mt={4} mb={4}>
              <Grid container alignItems="center" justify="center">
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleFinalize}
                  className={thisClasses.button}
                >
                  {hasNextTab ? 'Next' : 'Finish'}
                </Button>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </PageLayout>
  )
}
import {Box, FormControlLabel, Grid, MenuItem, Switch, Card, CardContent, FormLabel} from '@material-ui/core';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CheckboxLight from '@components/inputs/checkbox-light';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import {
  AddIcon
} from '@app/icons';
import MenuWithContext from '@components/dropdowns/menu-with-context';

const useStyles = makeStyles(() => ({
  categoryName: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#232323'
  },
  typeName: {
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    color: '#232323'
  }
}))

export default function DocumentRow({
  rulebookCategory,
  readOnly,
  switchTypeInCategory,
  categoryHasType,
  onSelectTypeDialogOpen,
  switchCategoryRuleType,
  categoryTypeExpected,
  data,
  setData,
  onExclude
}) {

  const classes = useStyles()

  return (
    <Box
      display={'flex'}
      flexGrow={1}
      mt={2}
      style={{
        padding: 4
      }}
    >
      <Card style={{
        display: 'flex',
        flexGrow: 1
      }}>
        <CardContent style={{
          display: 'flex',
          flexGrow: 1
        }}>
          <Grid container>
            {/* Col 1 */}
            <Grid item xs={3}>
              {(!readOnly || rulebookCategory.categoryRuleType === 'ONE_OF') && (
                <Box display={'flex'} style={{ height: '40px' }}/>
              )}
              <Box
                display={'flex'}
                style={{ height: '40px' }}
                alignItems={'center'}
                className={classes.categoryName}
              >
                { rulebookCategory.ref.name }
              </Box>
            </Grid>
            {/* Col 2 */}
            <Grid item xs={5}>
              { (!readOnly || rulebookCategory.categoryRuleType === 'ONE_OF') && (
                <Box
                  display={'flex'}
                  style={{ height: '40px' }}
                />
              )}
              { rulebookCategory.ref.types.map(type =>
                (!readOnly || categoryHasType(rulebookCategory.categoryId, type.id))
                && (
                  <Box
                    display={'flex'}
                    style={{ height: '40px' }}
                    alignItems={'center'}
                    className={classes.typeName}
                    key={`rulebook-${rulebookCategory.definedByRulebookVersionId}-${rulebookCategory.ref.id}-${type.id}`}
                  >
                    { !readOnly && (
                      <FormControlLabel
                        labelPlacement="end"
                        label={type.name}
                        control={
                          <CheckboxLight
                            disabled={readOnly}
                          />
                        }
                        onChange={event => {
                          switchTypeInCategory(rulebookCategory.categoryId, type.id, event.target.checked)
                        }}
                        checked={categoryHasType(rulebookCategory.categoryId, type.id)}
                      />
                    ) }
                    { !!readOnly && (
                      <FormLabel>{type.name}</FormLabel>
                    ) }
                  </Box>
                )) }
              { !readOnly && (
                <Box
                  display={'flex'}
                  style={{ height: '40px' }}
                  alignItems={'center'}
                  className={classes.typeName}
                >
                  <ButtonWithIcon
                    startIcon={<AddIcon />}
                    onClick={onSelectTypeDialogOpen(
                      rulebookCategory.categoryId,
                      rulebookCategory.ref.types.map(type => type.id)
                    )}
                    style={{ marginLeft: -8 }}
                  >
                    Add New
                  </ButtonWithIcon>
                </Box>
              )}
            </Grid>
            {/* Col 3 */}
            <Grid item xs={3}>
              {/* Any Of */}
              {
                (!readOnly || rulebookCategory.categoryRuleType === 'ONE_OF')
                && (
                  <Box
                    display={'flex'}
                    style={{ height: '40px' }}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                  >
                    <FormControlLabel
                      labelPlacement="start"
                      label={'Any Of'}
                      control={
                        <Switch/>
                      }
                      onChange={event => {
                        switchCategoryRuleType(rulebookCategory.categoryId, event.target.checked)
                      }}
                      checked={rulebookCategory.categoryRuleType === 'ONE_OF'}
                      disabled={readOnly}
                    />
                  </Box>
                )}
              {/* List of types */}
              { rulebookCategory.categoryRuleType !== 'ONE_OF'
                && rulebookCategory.ref.types.map(type =>
                  (!readOnly || categoryHasType(rulebookCategory.categoryId, type.id))
                    && (
                      <Box
                        display={'flex'}
                        key={`rulebook-${rulebookCategory.definedByRulebookVersionId}-${rulebookCategory.ref.id}-${type.id}-expected`}
                        style={{ height: '40px' }}
                        alignItems={'center'}
                      >
                        <FormControlLabel
                          label={categoryTypeExpected(rulebookCategory.categoryId, type.id) ? 'Yes' : 'No'}
                          control={
                            <Switch
                              checked={categoryTypeExpected(rulebookCategory.categoryId, type.id)}
                              disabled={
                                !categoryHasType(rulebookCategory.categoryId, type.id)
                                || readOnly
                              }
                              onChange={event => {
                                setData({
                                  ...data,
                                  documentRules: data.documentRules.map(rc => ({
                                    ...rc,
                                    types: rc.types.map(rt => ({
                                      ...rt,
                                      expected: type.id === rt.typeId
                                        ? event.target.checked
                                        : rt.expected
                                    })) || []
                                  }))
                                })
                              }}
                            />
                          }
                        />
                      </Box>
                    )) }
            </Grid>
            {/* Col 4 Action */}
            <Grid item xs={1} style={{ textAlign: 'right' }}>
              { !readOnly && (
                <MenuWithContext title="More Actions">
                  <MenuItem onClick={onExclude}>
                    Exclude
                  </MenuItem>
                </MenuWithContext>
              )}
            </Grid>

            {/*<Grid item xs={3}>*/}
            {/*  <FormControlLabel*/}
            {/*    labelPlacement="end"*/}
            {/*    label={'Something...'}*/}
            {/*    control={*/}
            {/*      <CheckboxLight*/}
            {/*        readOnly={readOnly}*/}
            {/*      />*/}
            {/*    }*/}
            {/*    onChange={event => {*/}

            {/*    }}*/}
            {/*    checked={true}*/}
            {/*  />*/}
            {/*</Grid>*/}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}
const config = {
  MuiMenuItem:{
    root: {
      height: 30,
      marginLeft: 8,
      marginRight: 8,
    }
  },
  MuiMenu: {
    list: {
      maxHeight: '50vh'
    }
  }
}

export default config
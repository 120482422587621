import React, { useRef, useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import { paginations, scroll } from '@store/actions';

export default function Scroll({
  items,
  action,
  children,
  customClasses,
  quantityItems,
  handlePagination,
  allPages,
  isLastPage,
  getViewScrollHeight,
  pageSize
}) {
  const dispatch = useDispatch();
  const { currentPages } = useSelector(store => store.paginations);
  const { profilesVersionId } = useSelector(store => store.searchHistory);
  const { triggerScroll, isRequestSent, noScroll } = useSelector(store => store.scroll);

  const [toScrollTop, setToScrollTop] = useState(); // eslint-disable-line

  const scrollRef = useRef(null);

  const handleScrollTop = useCallback(async () => {
    const result = scrollRef.current.scrollHeight - scrollRef.current.offsetHeight;
    setToScrollTop(getViewScrollHeight ? result - 120 : result - 25);

    getViewScrollHeight && await getViewScrollHeight(scrollRef.current.offsetHeight);
  }, [scrollRef, getViewScrollHeight, setToScrollTop])

  useEffect(() => {
    if (children && scrollRef.current && currentPages[0] + 1 !== allPages) {
      handleScrollTop();
    }
  }, [children, scrollRef, items, currentPages, allPages, handleScrollTop])

  useEffect(() => {
    if (!!scrollRef.current) {
      scrollRef.current.scrollTo(0, 15);
    }
  }, [profilesVersionId, scrollRef])
  
  useEffect(() => {
    if (!!scrollRef.current && !!triggerScroll) {
      scrollRef.current.scrollTo(0, 25);
      dispatch(scroll.setTriggerScroll(false));
    }
  }, [triggerScroll, scrollRef, dispatch])

  const computePrevPage = useCallback(() => {
    const pages = [...currentPages];
    const prevPage = currentPages[0] - 1;
    pages.pop();
    pages.unshift(prevPage)

    dispatch(paginations.setCurrentPages(pages));
    handlePagination(pages, true, false);
  }, [currentPages, dispatch, handlePagination]);

  const computeNextPage = useCallback(() => {
    const pages = [...currentPages];
    const nextPage = pages[0] + 1;
    pages.shift();
    pages.push(nextPage);

    dispatch(paginations.setCurrentPages(pages));
    handlePagination(pages, false, true);
  }, [currentPages, dispatch, handlePagination]);

  const nextPage = useCallback(async () => {
    dispatch(scroll.setIsRequestSent(true));
    const nextPage = currentPages[0] + 1

    if(!items[nextPage]) {
      const maxPage = Math.ceil(quantityItems / pageSize);

      if(maxPage === nextPage) {
        dispatch(scroll.setIsRequestSent(false));
        computeNextPage();
        return;
      }
      if (maxPage < nextPage){
        dispatch(scroll.setIsRequestSent(false));
        return;
      }

      await action(nextPage);
      computeNextPage();
    } else {
      computeNextPage();
    }
    
    dispatch(scroll.setIsRequestSent(false));
  }, [currentPages, items, quantityItems, dispatch, action, pageSize, computeNextPage]);
  
  const prevPage = useCallback(async () => {
    dispatch(scroll.setIsRequestSent(true));
    const prevPage = currentPages[0] - 1

    if(!items[prevPage]) {
      await action(prevPage);
      computePrevPage();
    } else {
      computePrevPage();
    }
    
    dispatch(scroll.setIsRequestSent(false));
  }, [currentPages, items, dispatch, action, computePrevPage]);

  const scrollHandler = useCallback(async (e) => {
    if(e.target.scrollTop <= 10 && !!currentPages[0] && e.target.scrollHeight > e.target.clientHeight && !isRequestSent) {
      if (noScroll) {
        scrollRef?.current?.scrollTo(0, 25);
        dispatch(scroll.setNoScroll(false));
      } else {
        await prevPage();
        scrollRef?.current?.scrollTo(0, e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight + 30));
      }
    }

    if (e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight + 15) <= 10 && !isRequestSent && !isLastPage) {
      if (noScroll) {
        scrollRef?.current?.scrollTo(0, e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight + 30));
        dispatch(scroll.setNoScroll(false));
      } else {
        await nextPage();
        scrollRef?.current?.scrollTo(0, 25);
      }
    }
  }, [currentPages, scrollRef, isRequestSent, isLastPage, noScroll, prevPage, nextPage, dispatch])

  return (
    <Box
      className={customClasses}
      onScroll={e => (!!allPages && !!scrollRef.current) && scrollHandler(e)}
      ref={scrollRef}
    >
      {children}
    </Box>
  )
}

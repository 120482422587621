export const generatePromoCode = () => {
  let chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let randomPassword = '';
  for (let i = 0; i < 9; i++) {
    randomPassword = randomPassword + chars.charAt(
      Math.floor(Math.random() * chars.length)
    );
  }

  return randomPassword;
}
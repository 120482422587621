import React, { useEffect, useState } from 'react';
import { subscriptionPlans, eidv } from '@app/api/client';

import NoEidv from './no-eidv';

export default function CheckEidvCredits({ onCreditPass, children }) {
  const [noEidv, setNoEidv] = useState('blank');


  useEffect(() => {
    getCredits();
  }, []) // eslint-disable-line

  const getCredits = () => {
    subscriptionPlans.getSubscriptionForUser().then((res) => {
      subscriptionPlans.getById(res.data.currentPlanId).then((res) => {
        if (!!res.data.eidvCredits) {
          enableEidv();
        } else {
          // Check if user has existing eidv cases
          eidv.index({ pageNumber: 0, pageSize: 1 }).then((res) => {
            if (!res.data.count) {
              setNoEidv(true);
            } else {
              enableEidv();
            }
          })
        }
      })
    })
  }

  const enableEidv = () => {
    setNoEidv(false);
    onCreditPass && onCreditPass()
  }

  if (noEidv === 'blank') {
    return null
  }

  if (noEidv) {
    return (
      <NoEidv />
    )
  }

  return children
}
import React, { useEffect } from 'react';
import {Box, Tabs, Tab, Typography, LinearProgress, IconButton} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import InlineBadge from '@components/badges/inline-badge';
import CurrentStepHeadline from '../stepper/current-step-headline';

import { InfoOtherIcon, InfoBrownOtherIcon } from '@app/icons';
import CustomTooltip from '@components/controls/tooltip';
import OutlinedSearch from '@components/inputs/outlined-search';
import {
  SearchIcon,
  ChevronLeftIcon
} from '@app/icons';
import clsx from 'clsx';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '2px solid #EFEFEF',
    position: 'relative',
    flexGrow: 1,
  },
  mainRow: {
    height: props => props.height || '96px',
  },
  badge: {
    height: '20px',
    width: '20px'
  },
  title: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    zIndex: -1
  },
  searchInfo: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    color: theme.palette.text.GY4,
  },
  stepper: {
    position: 'absolute',
    width: '100%',
    left: 0,
    bottom: 0,
    height: 2,
    marginBottom: -2,
    backgroundColor: '#EFEFEF',
  },
  stepperBar: {
    backgroundColor: '#7A5B44',
  },
  tabs: props => ({
    maxWidth: props.scrollTabWidth,
  })
}));

const buttonsJustify = {
  'flex-end': 'flex-start',
  'flex-start': 'flex-end',
  'center':'left'
}

export default function InnerHeader(props) {
  const { shiftTabs = false, tabs, title, buttons, renderTabContent, ind, searchInfo, stepper, propsClassesRoot, propsDependency, scrollTabWidth, propsTabRoot, justifyTitle, hasSearch, searchLabelText, searchTerm, setSearchTerm, titleAbsolute = true, titleLogo, onBack, titleGrow = true, height, endButtons } = props;

  const classes = useStyles({ scrollTabWidth, height });

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (propsDependency) {
      setValue(0)
    }
  }, [propsDependency])

  useEffect(() => {
    if (!!ind || ind === 0) {
      setValue(ind);
      renderTabContent && renderTabContent(tabs[ind]?.label, ind);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ind])

  const handleChange = (event, newValue) => {
    if(props.hasOwnProperty('onTabChange')) {
      props.onTabChange(newValue);
    }
    setValue(newValue);
    renderTabContent && renderTabContent(event.target.textContent, newValue);
  };

  return (
    <Box
      className={clsx(classes.root, propsClassesRoot)}
      display={'flex'}
      flexDirection={'row'}
      mr={tabs?.length > 4? 3: 0}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        width={'100%'}
      >
        <Box
          className={classes.mainRow}
          display={'flex'}
          flexGrow={1}
        >
          { onBack && (
            <Box display={'flex'} alignItems={'center'}>
              <Box display={'flex'}>
                <IconButton onClick={onBack}>
                  <ChevronLeftIcon/>
                </IconButton>
              </Box>
            </Box>
          )}
          {tabs && !shiftTabs &&
            <React.Fragment>
              <Box height={'100%'} flexGrow={searchInfo ? 0 : 1}>
                <Tabs
                  className={classes.tabs}
                  value={value}
                  onChange={handleChange}
                  variant={tabs.length > 4 ? 'scrollable' : 'standard'}
                  scrollButtons="auto"
                >
                  {
                    tabs.map(tab => {
                      return (
                        <Tab classes={{ root: propsTabRoot }} key={tab.label} label={
                          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                            {tab.label}
                            {
                              tab.tagValue &&
                              <React.Fragment>
                                <Box display={'flex'} ml={1}>
                                  <InlineBadge classes={{root:classes.badge}} color={tab.tagColor}>
                                    {tab.tagValue}
                                  </InlineBadge>
                                </Box>
                              </React.Fragment>
                            }
                            {
                              tab.infoIcon &&
                              <React.Fragment>
                                <CustomTooltip
                                  title={tab.infoValue || 'Some features are restricted in this view. For an extensive searching experience please Login or Register.'}
                                  placement="top"
                                  width={tab.infoWidth}
                                >
                                  <Box display={'flex'} ml={1}>
                                    {tab.infoValue ? <InfoBrownOtherIcon /> : <InfoOtherIcon />}
                                  </Box>
                                </CustomTooltip>
                              </React.Fragment>
                            }
                          </Box>
                        }/>
                      )
                    })
                  }
                </Tabs>
              </Box>
              {searchInfo && (
                <Box height={'100%'} alignItems={'center'} display={'flex'} flexGrow={2} ml={3}>
                  <Typography className={classes.searchInfo}>
                    {searchInfo}
                  </Typography>
                </Box>
              )}
              <Box height={'100%'} alignItems={'center'} display={'flex'}>
                {buttons}
              </Box>
            </React.Fragment>
          }
          {title && !titleAbsolute &&
            <React.Fragment>
              <Box display={'flex'} flexGrow={titleGrow? 1: 0} alignItems={'center'}>
                { !!titleLogo && (
                  <Box display={'flex'} pr={1}>
                    {titleLogo}
                  </Box>
                ) }
                <Typography variant={'h3'}>
                  {title}
                </Typography>
              </Box>
            </React.Fragment>
          }
          {(!tabs || shiftTabs) && buttons &&
            <Box alignItems={'center'} display={'flex'} justifyContent={justifyTitle ? buttonsJustify[justifyTitle] : 'flex-start'} flexGrow={1}>
              {buttons}
            </Box>
          }
          {title && !!titleAbsolute &&
            <React.Fragment>
              <Box className={classes.title} display={'flex'} justifyContent={justifyTitle ? justifyTitle : 'center'} alignItems={'center'}>
                <Box display={'flex'} alignItems={'center'}>
                  { !!titleLogo && (
                    <Box display={'flex'} pr={1}>
                      {titleLogo}
                    </Box>
                  ) }
                  <Typography variant={'h3'}>
                    {title}
                  </Typography>
                </Box>
              </Box>
            </React.Fragment>
          }
          {stepper &&
            <Box height={'100%'} alignItems={'center'} display={'flex'} justifyContent={'flex-end'} flexGrow={1}>
              <CurrentStepHeadline {...stepper} />
            </Box>
          }
          {stepper &&
            <LinearProgress
              className={classes.stepper}
              variant="determinate"
              value={stepper.currentStep / stepper.stepsCount * 100}
              classes={{
                barColorPrimary: classes.stepperBar
              }}
            />
          }
          <Box display={'flex'} justifyContent={'flex-end'}>
            { !!hasSearch && (
              <Box display={'flex'} alignItems={'center'}>
                <OutlinedSearch
                  style={{width: '100px'}}
                  label={searchLabelText || 'Search'}
                  value={searchTerm}
                  onChange={event => setSearchTerm && setSearchTerm(event.target.value)}
                  icons={<SearchIcon />}
                />
              </Box>
            )}
            { !!endButtons && (
              <Box display={'flex'} alignItems={'center'} ml={!!hasSearch? 1: 0}>
                {endButtons}
              </Box>
            )}
          </Box>
        </Box>
        {!!tabs && shiftTabs && (
          <Box display={'flex'} flexGrow={1}>
            <Tabs
              className={classes.tabs}
              value={value}
              onChange={handleChange}
              variant={tabs.length > 4 ? 'scrollable' : 'standard'}
              scrollButtons="auto"
            >
              {
                tabs.map(tab => {
                  return (
                    <Tab classes={{ root: propsTabRoot }} key={tab.label} label={
                      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        {tab.label}
                        {
                          tab.tagValue &&
                          <React.Fragment>
                            <Box display={'flex'} ml={1}>
                              <InlineBadge classes={{root:classes.badge}} color={tab.tagColor}>
                                {tab.tagValue}
                              </InlineBadge>
                            </Box>
                          </React.Fragment>
                        }
                        {
                          tab.infoIcon &&
                          <React.Fragment>
                            <CustomTooltip
                              title={tab.infoValue || 'Some features are restricted in this view. For an extensive searching experience please Login or Register.'}
                              placement="top"
                              width={tab.infoWidth}
                            >
                              <Box display={'flex'} ml={1}>
                                {tab.infoValue ? <InfoBrownOtherIcon /> : <InfoOtherIcon />}
                              </Box>
                            </CustomTooltip>
                          </React.Fragment>
                        }
                      </Box>
                    }/>
                  )
                })
              }
            </Tabs>
          </Box>
        )}
      </Box>
    </Box>
  )
}
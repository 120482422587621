import React from 'react';
import {Box, Typography} from '@material-ui/core';
import CheckImg from '../../../../assets/img/cards/check.png';


export default function Card ( props ) {
  return (
    <Box className="card" display="flex" flexDirection="column" justifyContent="start" >
      <Box mb="20px" display="flex" className="card-header" flexDirection="column" justifyContent="center" alignItems="center" >
        <Box height="200px" display="flex" justifyContent="center" alignItems="center" >
          <img alt="icon" src={props.icon}/>
        </Box>
        <Box align="center" >
          <Typography noWrap variant="h3" >{ props.header || '' }</Typography>
        </Box>
      </Box>

      <Box className="separator" display="flex" justifyContent="center" >
        <img alt="check" src={CheckImg}/>
      </Box>

      <Box className="card-section" align="center" display="flex" justifyContent="center" alignItems="center" >
        <Box>{ props.content1 || '' }</Box>
      </Box>

      <Box className="separator" display="flex" justifyContent="center" >
        <img alt="check" src={CheckImg}/>
      </Box>

      <Box className="card-section" align="center" display="flex" justifyContent="center" alignItems="center" >
        <Box>{ props.content2 || '' }</Box>
      </Box>
    </Box>
  );
}
import React from 'react';

import {
  Box, Typography,
} from '@material-ui/core';

import CheckedOption from '../../../../../views/auth/checked-option'

const texts = [
  'Photo ID Document upload',
  'Document authenticity verification',
  'Facial match using optical recognition',
  'Address extraction',
  'Proof of address document upload',
]

export default function EidvInfo() {
  return (
    <Box>
      <Box>
        <Typography variant="h4" align="center" style={{ color: '#484848' }}>Ask your client to verify their identity</Typography>
      </Box>
      <Box mt={1}>
        <Typography variant="h5" align="center" style={{ color: '#484848' }}>using the secure, encrypted flow based on biometric and OCR.</Typography>
      </Box>
      <Box mt={2} pl={6} display="flex" justifyContent="center">
        <Box display="flex" flexDirection="column">
          {texts.map(text => {
            return (
              <Box display="flex" key={text}>
                <CheckedOption>
                  <Typography variant="subtitle2" style={{ color: '#484848' }}>
                    {text}
                  </Typography>
                </CheckedOption>
              </Box>
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}

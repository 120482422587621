import React, {useMemo, useState} from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Switch,
  TableCell,
  TableRow,
  TextField
} from '@material-ui/core';
import {
  DragAndDropIcon,
  //AddIcon
} from '@app/icons';
import MenuWithContext from '@components/dropdowns/menu-with-context';
import { makeStyles } from '@material-ui/core/styles';
import SelectClearable from '@components/inputs/select-clearable';
import { EntityInputTypeEnum, EntityInputDisplayLevelEnumPerson, EntityInputDisplayLevelEnumLegalEntity } from '@services/entity-input';
import {FileViewerArrowDown, FileViewerArrowUp} from '@app/icons';
import CustomTooltip from '@components/controls/tooltip';
//import ButtonWithIcon from '@components/buttons/button-with-icon';
//import CheckboxLight from '@components/inputs/checkbox-light';

const useStyles = makeStyles(theme => ({
  textField: {
    '& .MuiOutlinedInput-notchedOutline': {
      boxShadow: 'none'
    }
  },
  select: {
    textAlign: 'left'
  },
  label: {
    fontSize: '12px !important'
  },
  checkboxLabel: {
    textAlign: 'left'
  },
  sortButtonsGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    '& .up, & .down': {
      opacity: 0,
      transition: '0.3s',
      '&:hover': {
        opacity: 1
      }
    },
    '&:hover': {
      '& .up, & .down': {
        opacity: .6
      }
    }
  },
  row: {
    padding: 0
  }
}))

export default function InputFieldRow({ inputData, setInputData, onSortMove, onExclude, onStartEdit, openEditDialog, readOnly, clientType }) {

  const [errors, setErrors] = useState({})

  const onChange = fieldName => event => {
    const newInputData = {...inputData}
    if (fieldName === 'required') {
      newInputData.validation = event.target.checked ? 'REQUIRED' : 'OPTIONAL'
    } else if (fieldName === 'type') {
      // Open edit dialog
      if (['TEXT', 'LIST'].includes(event.target.value)) {
        openEditDialog()
      }
      newInputData.definition.type = event.target.value
      if (newInputData.definition.type !== 'TEXT') {
        newInputData.definition.size = null
      }
      if (newInputData.definition.type !== 'LIST') {
        newInputData.definition.options = null
      }
    } else if (fieldName === 'displayLevel') {
      newInputData.displayConfig.level = event.target.value
    } else {
      newInputData[fieldName] = event.target.value
    }
    setInputData(newInputData)
    validate(newInputData)
  }

  const validate = (newInputData) => {
    setErrors(
      !newInputData.displayName
        ? {
          displayName: 'Please fill Display Name'
        } : {}
    )
  }

  // const onAddLogic = () => {
  //
  // }
  //
  // const onLogicCheck = logicID => event => {
  //
  // }

  const classes = useStyles()

  const displayLevels = useMemo(() => {
    return clientType === 'PERSON' ? EntityInputDisplayLevelEnumPerson : EntityInputDisplayLevelEnumLegalEntity
  }, [clientType])

  return (
    <>
      <TableRow
        classes={{
          root: classes.row
        }}
      >
        <TableCell/>
        <TableCell
          style={{
            paddingRight: 0
          }}
        >
          <Box
            className={classes.sortButtonsGroup}
          >
            { !readOnly && (
              <>
                <Box className={'up'} onClick={onSortMove('up')}>
                  <FileViewerArrowUp width={16} height={16}/>
                </Box>
                <Box>
                  <DragAndDropIcon width={16} height={16}/>
                </Box>
                <Box className={'down'} onClick={onSortMove('down')}>
                  <FileViewerArrowDown width={16} height={16}/>
                </Box>
              </>
            )}
          </Box>
        </TableCell>
        <TableCell>
          <Box
            className={'wrapperWithSeparator'}
          >
            <TextField
              value={inputData.displayName}
              onChange={onChange('displayName')}
              fullWidth
              label="Global Field Name"
              error={!!errors?.displayName}
              helperText={errors?.displayName}
              classes={{ root: classes.textField}}
              disabled={!!readOnly}
              InputLabelProps={{
                classes: {
                  root: classes.label
                }
              }}
            />
          </Box>
        </TableCell>
        <TableCell align="right">
          <Box
            className={'wrapperWithSeparator'}
          >
            <CustomTooltip
              title={
                (!!readOnly || !!inputData.id)
                  ? 'You cannot change the field type for already saved fields.'
                  : null
              }
              placement="top-end"
            >
              <FormControl
                fullWidth
                style={{
                  marginTop: 0,
                  marginBottom: 0
                }}
              >
                <InputLabel
                  classes={{
                    root: classes.label
                  }}
                >
                  Field Type
                </InputLabel>
                <SelectClearable
                  clearable={false}
                  value={inputData?.definition?.type}
                  onChange={onChange('type')}
                  classes={{ root: classes.select }}
                  disabled={!!readOnly || !!inputData.id}
                >
                  {
                    Object.entries(EntityInputTypeEnum).map(([optionValue, optionLabel]) => (
                      <MenuItem
                        key={optionValue}
                        value={optionValue}
                      >
                        {optionLabel}
                      </MenuItem>
                    ))
                  }
                </SelectClearable>
              </FormControl>
            </CustomTooltip>
          </Box>
        </TableCell>
        <TableCell align="right">
          <Box
            className={'wrapperWithSeparator'}
          >
            <FormControl
              fullWidth
              style={{
                marginTop: 0,
                marginBottom: 0
              }}
            >
              <InputLabel
                classes={{
                  root: classes.label
                }}
              >
                Display Level
              </InputLabel>
              <SelectClearable
                clearable={false}
                value={inputData?.displayConfig?.level}
                onChange={onChange('displayLevel')}
                classes={{ root: classes.select }}
                disabled={!!readOnly}
              >
                {
                  Object.entries(displayLevels).map(([optionValue, optionLabel]) => (
                    <MenuItem
                      key={optionValue}
                      value={optionValue}
                    >
                      {optionLabel}
                    </MenuItem>
                  ))
                }
              </SelectClearable>
            </FormControl>
          </Box>
        </TableCell>
        <TableCell align="right">
          <FormControlLabel
            control={
              <Switch
                checked={inputData?.validation === 'REQUIRED'}
                onClick={onChange('required')}
              />
            }
            label={inputData?.validation === 'REQUIRED' ? 'Yes' : 'No'}
            disabled={!!readOnly}
          />
        </TableCell>
        {/*<TableCell align="right">*/}
        {/*  <Box*/}
        {/*    display={'flex'}*/}
        {/*    flexDirection={'column'}*/}
        {/*  >*/}
        {/*    <Box display={'flex'}>*/}
        {/*      <FormControlLabel*/}
        {/*        classes={{ root: classes.checkboxLabel }}*/}
        {/*        labelPlacement="end"*/}
        {/*        label="Mandatory if fund manager"*/}
        {/*        control={*/}
        {/*          <CheckboxLight*/}
        {/*            className="checkbox-user-card"*/}
        {/*          />*/}
        {/*        }*/}
        {/*        onChange={onLogicCheck()}*/}
        {/*        checked={true}*/}
        {/*        disabled={!!readOnly}*/}
        {/*      />*/}
        {/*    </Box>*/}
        {/*    { !readOnly && (*/}
        {/*      <Box display={'flex'}>*/}
        {/*        <ButtonWithIcon*/}
        {/*          startIcon={<AddIcon />}*/}
        {/*          onClick={onAddLogic}*/}
        {/*          style={{ marginLeft: -8, whiteSpace: 'nowrap' }}*/}
        {/*        >*/}
        {/*          Add New*/}
        {/*        </ButtonWithIcon>*/}
        {/*      </Box>*/}
        {/*    )}*/}
        {/*  </Box>*/}
        {/*</TableCell>*/}
        <TableCell>
          { !readOnly && (
            <MenuWithContext title="More Actions">
              <MenuItem onClick={onStartEdit}>
                Edit
              </MenuItem>
              <MenuItem onClick={onExclude}>
                Exclude
              </MenuItem>
            </MenuWithContext>
          )}
        </TableCell>
        <TableCell/>
      </TableRow>
    </>
  )
}
const config = {
  MuiTabs: {
    root: {
      height: '100%'
    },
    flexContainer: {
      height: '100%'
    },
    indicator: {
      backgroundColor: '#CAD7CA',
      width: 'auto',
      minWidth: '0px !important',
      transition: 'all 100ms linear'
    },
  },
  MuiTab: {
    root: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bolder',
      fontSize: '18px',
      lineHeight: '28px',
      alignItems: 'center',
      color: '#A9A9A9',
      minWidth: '0px !important',
      paddingLeft: 12,
      paddingRight: 12,
      '&.Mui-selected': {
        color: '#232323 !important'
      },
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
      }
    },
    wrapper: {
      alignItems: 'flex-start',
    },
    textColorInherit: {
      color: '#A9A9A9',
      opacity: 1
    }
  }
}

export default config
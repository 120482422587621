import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Modal } from '@components/modals';

import { adminApi } from '@app/api/';
import { useSnackbar } from 'notistack';
import { capitalize, capitalizeSentence } from '../../../../../utils/textUtil';

export const useStyles = makeStyles((theme) => ({
  customButtonModalWidth: {
    width: '180px',
  },
}));

export default function DocumentModal({ open, onClose, editData, fetchData, type }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const isCategory = type === 'category'

  const label = isCategory ? 'document category' : 'document type'

  const handleDelete = async () => {
    const status = (isCategory
      ? await adminApi.category.deleteCategory(editData.id)
      : await adminApi.type.deleteType(editData.id))
      .status;

    if (status === 200) {
      fetchData();
      enqueueSnackbar(`${capitalize(label)} "${editData.name}" was successfully deleted!`, { variant: 'success' });
      onClose();
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={`Delete ${capitalizeSentence(label)}`}
      mainText={`Are you sure want to delete ${label} "${editData?.name}"?`}
      actions={
        [
          {
            type: 'main',
            label: 'CANCEL',
            action: onClose,
          },
          {
            type: 'secondary',
            label: 'DELETE',
            action: handleDelete,
          },
        ]
      }
      propsClassses={classes.customButtonModalWidth}
      actionsDirection="row"
    />
  )
}
import React, { useEffect, useMemo, useState } from 'react';
import { Route, Switch } from 'react-router';
import csvtojson from 'csvtojson';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import history from '@app/history';
import { clientApi } from '@app/api';
import PageLayout from '@components/layout/page-layout';
import LoggedNotFound from '@views/protected/not-found';
import { countryCodeNameAliasesList } from '@services/country-service';
import ProgressDialog from '@components/modals/progress-dialog';

import ImportCSVFileContent from './step1-file-content';
import ImportCSVUploadFile from './step2-upload-file';
import ImportCSVMapping from './step3-confirm-data-mapping';
import ImportCSVConfirm from './step4-confirm-clients';
import UploadSuccessDialog from './dialogs/upload-success';

// Max 3000 clients per request
const BULK_CREATE_LIMIT = 3000
const UPLOAD_LIMIT = 3000

const entityTypeAliases = {
  Corporation: ['corporation', 'company', 'corporation/company', 'corporation / company', 'corp'],
  Foundation: ['foundation'],
  Partnership: ['partnership'],
  Trust: ['trust'],
  'Limited Liability Company': ['limited liability company / llc', 'limited liability company', 'llc', 'limited liability company/llc']
}

// Reverse Corporation: ['corpo', 'corp'] to { corpo: 'Corporation', corp: 'Coproration' }
export const entityTypes = {}
Object.entries(entityTypeAliases).forEach(([type, aliases]) => {
  aliases.forEach(alias => {
    entityTypes[alias] = type;
  })
})


// Import CSV wrapper
export default function ImportCSV() {
  const { enqueueSnackbar } = useSnackbar();

  const [file, setFile] = useState(null);
  const [parsedFile, setParsedFile] = useState(null);
  const [fileHeaders, setFileHeaders] = useState(null);
  const [type, setType] = useState('');
  const [skipFirstRow, setSkipFirstRow] = useState(false);
  const [fullName, setFullName] = useState(false);
  const [fieldMapping, setFieldMapping] = useState({
    personFirstName: 'FirstName',
    personLastName: 'LastName',
    personDob: 'DoB',
    personCountry: 'Residency',
    personGender: 'Gender',
    personFullName: 'FullName',
    entityName: 'LegalEntity Name',
    entityType: 'Entity Type',
    entityCountry: 'Registered Office Country',
    entityNumber: 'Registration Number',
    type: 'Client Type',
    personType: 'Person',
    companyType: 'LegalEntity',
    externalId: 'External ID',
  })
  const [isUploading, setIsUploading] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0)
  const [showUploadSuccess, setShowUploadSuccess] = useState(false);
  const [uploadCount, setUploadCount] = useState(0);

  // All rows are selected by default, when they are deselected,
  // we add them to this object map.
  const [omittedRows, setOmittedRows] = useState({
    person: {},
    company: {},
  });

  useEffect(() => {
    if (!type) {
      history.replace('/portfolio/import/file-content')
    }
  }, [type])

  useEffect(() => {
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        csvtojson({ noheader: true, trim: true, delimiter: 'auto' })
          .fromString(e.target.result)
          .then((csv) => {
            setOmittedRows({
              person: {},
              company: {},
            });

            const uploadLimit = skipFirstRow ? UPLOAD_LIMIT + 1 : UPLOAD_LIMIT;

            if (csv.length > uploadLimit) {
              enqueueSnackbar(`The uploaded document contains more than ${UPLOAD_LIMIT} names. Please note that only the first ${UPLOAD_LIMIT} will be imported.`, { variant: 'warning', persist: true });
            }
            if (csv.length) {
              const csvToUse = csv.slice(0, uploadLimit);
              const parsedFile = [];
              const firstRow = csv[0]; // field1: 'FirstName', field2: 'LastName'

              csvToUse.forEach((row, index) => {
                if (!(skipFirstRow && index === 0)) {
                  // restructure row object to use firstRow values as keys
                  const parsedRow = {};
                  Object.entries(row).forEach(([key, value]) => {
                    parsedRow[firstRow[key]] = value;
                  })

                  // represents row number
                  parsedRow.id = index + 1
                  parsedFile.push(parsedRow)
                }
              })

              setParsedFile(parsedFile)

              const header = {};
              Object.values(firstRow).forEach(value => {
                header[value] = value;
              })
              setFileHeaders(header)
            } else {
              setParsedFile([])
            }
          })
      }

      reader.readAsBinaryString(file);
    }
    // eslint-disable-next-line
    }, [file, skipFirstRow])

  const uploadFile = (file) => {
    setFile(file);
    history.push(`/portfolio/import/mapping/${type === 'company' ? 'company' : 'person'}`)
  }

  const preparePerson = (row) => {
    let firstName = row[fieldMapping.personFirstName];
    let lastName = row[fieldMapping.personLastName];
        
    if (fullName) {
      firstName = row[fieldMapping.personFullName].split(' ')[0];
      lastName = row[fieldMapping.personFullName].split(' ').slice(1).join(' ');
    }

    let dob = moment(row[fieldMapping.personDob], 'MM/DD/YYYY').format('YYYY-MM-DD');
    if (dob === 'Invalid date') dob = moment(row[fieldMapping.personDob], 'YYYY-MM-DD').format('YYYY-MM-DD');
    if (dob === 'Invalid date') dob = undefined;


    let residency = row[fieldMapping.personCountry] || '';
    residency = residency.replace(/ +(?= )/g,'');
    residency = residency.replace(/[^a-zA-Z ]/g, '');
    residency = countryCodeNameAliasesList[residency.toLowerCase()];

    const genders = {
      male: 'male',
      m: 'male',
      female: 'female',
      f: 'female'
    }
    let gender = row[fieldMapping.personGender] || undefined;
    if (gender) {
      gender = genders[gender.toLowerCase()];
    }

    return {
      firstName: firstName,
      lastName: lastName,
      dob,
      residency,
      gender,
      properties: { propertyMap: {} },
      typeSpecificProperties: { propertyMap: {} },
      externalId: row[fieldMapping.externalId]
    }
  }

  const prepareEntity = (row) => {
    let registeredOfficeCountry = row[fieldMapping.entityCountry] || '';
    registeredOfficeCountry = registeredOfficeCountry.replace(/ +(?= )/g,'');
    registeredOfficeCountry = registeredOfficeCountry.replace(/[^a-zA-Z ]/g, '');
    registeredOfficeCountry = countryCodeNameAliasesList[registeredOfficeCountry.toLowerCase()];

    let registrationNumber = row[fieldMapping.entityNumber];
    if (!registrationNumber || registrationNumber.length > 100) registrationNumber = undefined;

    let type = row[fieldMapping.entityType]
    type = entityTypes[type.toLowerCase()];
    type = type.toUpperCase().split(' ').join('_');

    const entity = {
      name: row[fieldMapping.entityName],
      type,
      registeredOfficeCountry,
      properties: { propertyMap: {} },
      typeSpecificProperties: { propertyMap: {} },
      externalId: row[fieldMapping.externalId]
    }

    if (registrationNumber) {
      entity.typeSpecificProperties.propertyMap.registrationNumber = {
        id: 'registrationNumber',
        value: registrationNumber,
      }
    }

    return entity
  }

  const uploadSelected = async () => {
    const filesToUpload = filteredParsedFile.filter(e => !omittedRows.person[e.id] && !omittedRows.company[e.id]);
    const data = {
      legalEntities: [],
      persons: [],
    }

    switch (type) {
      case 'person':
        data.persons = filesToUpload.map(e => preparePerson(e));
        break;

      case 'company':
        data.legalEntities = filesToUpload.map(e => prepareEntity(e));
        break;
        
      case 'both':
        data.persons = filesToUpload.filter(e => e[fieldMapping.type] === fieldMapping.personType).map(e => preparePerson(e));
        data.legalEntities = filesToUpload.filter(e => e[fieldMapping.type] === fieldMapping.companyType).map(e => prepareEntity(e));
        break;

      default:
        break;
    }
    setIsUploading(true);
    setShowProgress(true);
    try {
      const count = data.legalEntities.length + data.persons.length;
      let personIds = []
      let companyIds = []
      for (let index = 0; index < data.persons.length; index += BULK_CREATE_LIMIT) {
        const persons = data.persons.slice(index, index + BULK_CREATE_LIMIT)
        const ids = (await clientApi.csv.import({ persons, legalEntities: [] })).data
        personIds = personIds.concat(ids)

        let progress = ((index + BULK_CREATE_LIMIT) / count) * 100
        progress = progress > 100 ? 100 : progress
        setProgress(Math.floor(progress))
      }
      for (let index = 0; index < data.legalEntities.length; index += BULK_CREATE_LIMIT) {
        const legalEntities = data.legalEntities.slice(index, index + BULK_CREATE_LIMIT)
        const ids = (await clientApi.csv.import({ legalEntities, persons: [] })).data
        companyIds = companyIds.concat(ids)

        let progress = ((index + BULK_CREATE_LIMIT) / count) * 100
        progress = progress > 100 ? 100 : progress
        setProgress(Math.floor(progress))
      }

      // save ids to local storage. One for organize folder. One for monitoring
      localStorage.setItem('importCsvIdsFolder', JSON.stringify({ personIds, companyIds }));
      localStorage.setItem('importCsvIdsMonitoring', JSON.stringify({ personIds, companyIds }));

      setIsUploading(false);
      enqueueSnackbar(`${count} client(s) were successfully uploaded!`, { variant: 'success' });
      setUploadCount(count);
    } catch (err) {
      setIsUploading(false);
      setShowProgress(false);
    }
  }

  const uploadSuccess = () => {
    setShowProgress(false);
    setShowUploadSuccess(true);
  }

  const filteredParsedFile = useMemo(() => {
    if (type !== 'both') return parsedFile

    if (parsedFile) {
      return parsedFile.filter(row =>
        row[fieldMapping.type] === fieldMapping.personType ||
                row[fieldMapping.type] === fieldMapping.companyType
      )
    }

    return parsedFile

  }, [parsedFile, type, fieldMapping])

  return (
    <PageLayout>
      <Switch>
        <Route path="/portfolio/import/file-content">
          <ImportCSVFileContent type={type} setType={setType} />
        </Route>
        <Route path="/portfolio/import/upload-file">
          <ImportCSVUploadFile type={type} uploadFile={uploadFile} />
        </Route>
        <Route path="/portfolio/import/mapping/:tab">
          {parsedFile &&
                        <ImportCSVMapping
                          type={type}
                          parsedFile={parsedFile}
                          fileHeaders={fileHeaders}
                          skipFirstRow={skipFirstRow}
                          setSkipFirstRow={setSkipFirstRow}
                          fieldMapping={fieldMapping}
                          setFieldMapping={setFieldMapping}
                          fullName={fullName}
                          setFullName={setFullName}
                          setOmittedRows={setOmittedRows}
                        />
          }
        </Route>
        <Route path="/portfolio/import/confirm/:tab">
          {filteredParsedFile &&
                        <ImportCSVConfirm
                          type={type}
                          parsedFile={filteredParsedFile}
                          fieldMapping={fieldMapping}
                          fullName={fullName}
                          omittedRows={omittedRows}
                          setOmittedRows={setOmittedRows}
                          uploadSelected={uploadSelected}
                        />
          }
        </Route>
        <Route path="*" exact>
          <LoggedNotFound />
        </Route>
      </Switch>
      <ProgressDialog
        open={showProgress}
        onClose={uploadSuccess}
        isLoading={isUploading}
        title={isUploading ? 'Uploading clients' : 'Uploaded successfully'}
        progressText={true ? 'Uploading...' : `Uploading... ${progress}%`}
      />
      <UploadSuccessDialog
        open={showUploadSuccess}
        onClose={() => setShowUploadSuccess(false)}
        uploadCount={uploadCount}
      />
    </PageLayout>
  )
}

import {
  Corporation,
  //Establishment,
  Foundation,
  LimitedLiabilityCompany,
  //LimitedPartnership,
  Partnership,
  Person,
  //SegregatedPortfolioCompany,
  Trust,
} from '@app/icons';

export const entityTypes = [
  {
    name: 'CORPORATION',
    label: 'Corporation',
    icon: <Corporation />
  },
  // {
  //   name: 'ESTABLISHMENT',
  //   label: 'Establishment',
  //   icon: <Establishment />
  // },
  {
    name: 'FOUNDATION',
    label: 'Foundation',
    icon: <Foundation />
  },
  {
    name: 'PERSON',
    label: 'Person',
    icon: <Person />
  },
  // {
  //   name: 'LIMITED_PARTNERSHIP',
  //   label: 'Limited Partnership',
  //   icon: <LimitedPartnership />
  // },
  {
    name: 'LIMITED_LIABILITY_COMPANY',
    label: 'Limited Liability Company',
    icon: <LimitedLiabilityCompany />
  },
  {
    name: 'PARTNERSHIP',
    label: 'Partnership',
    icon: <Partnership />
  },
  // {
  //   name: 'SEGREGATED_PORTFOLIO_COMPANY',
  //   label: 'Segregated Portfolio Company',
  //   icon: <SegregatedPortfolioCompany />
  // },
  {
    name: 'TRUST',
    label: 'Trust',
    icon: <Trust />
  }
]

export const getIcon = name => entityTypes.find(entity => entity.name === name)?.icon

export const usageTypes = [
  { label: 'Person', value: 'PERSON' },
  { label: 'Legal Entity', value: 'LEGAL_ENTITY' },
]
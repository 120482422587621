import React, { useEffect, useMemo, useState } from 'react';
import { Box, MenuItem } from '@material-ui/core';
import OutlinedSearch from '@components/inputs/outlined-search';
import { AddActive2Icon, SearchIcon } from '@app/icons';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import MenuWithContext from '@components/dropdowns/menu-with-context';
import TableList from '@components/lists/table-list';
import _ from 'lodash';
import riskRatingTemplateDto from '@components/complex/client-view/risk-rating/dto/risk-rating-template-dto';
import history from '@app/history';
import RiskRatingSettingsDeleteDialog from './dialogs/delete-dialog';
import RiskRatingSettingsBatchDeleteDialog from './dialogs/batch-delete-dialog';
import { riskRatingTemplate } from '@app/api/client';
import { useSnackbar } from 'notistack';
import InlineHeadBadge from '@components/badges/inline-head-badge';

const TABLE_COLUMNS = [
  { field: 'templateName', headerName: 'Template Name', bold: true },
  { field: 'type', headerName: 'Type' },
]

export default function RiskRatingSettingsList(props) {

  const [search, setSearch] = useState('')

  const [data, setData] = useState([])

  const [sort, setSort] = useState('templateName') // eslint-disable-line

  const [sortDirection, setSortDirection] = useState('ASC') // eslint-disable-line

  const { enqueueSnackbar } = useSnackbar()

  const [pendingDeleteId, setPendingDeleteId] = useState(null);

  const [pendingBatchDelete, setPendingBatchDelete] = useState(false);

  const onSearchChange = (event) => {
    setSearch(event.target.value);
  }

  const onSort = column => {
    if (column === sort) {
      setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC')
    } else {
      setSort(column)
      setSortDirection('ASC')
    }
  }

  const onSelect = (id) => {
    const newData = data.map((item) => item.id === id
      ? ({ ...item, isChecked: !item.isChecked })
      : item
    );
    setData(newData);
  }

  useEffect(() => {
    fetch()  // eslint-disable-line
  }, []) // eslint-disable-line

  const sortedAndFilteredLines = useMemo(() => {
    let lines = [...data].sort((a, b) => a[sort].localeCompare(b[sort]))
    if (sortDirection === 'DESC') {
      lines = _.reverse(lines)
    }
    if (search) {
      lines = lines.filter(l => l.templateName.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    }
    return lines
  }, [data, sort, sortDirection, search])

  const fetch = async () => {
    const response = await riskRatingTemplate.index()
    const templatesData = response.data
    let templates = templatesData.map(
      riskRatingTemplateDto.read
    )
    setData(templates)
  }

  const onCreate = () => {
    history.push('/settings/risk-rating/create/intro')
  }

  const onSaveAs = id => {
    history.push(`/settings/risk-rating/${id}/save-as`)
  }

  const onUpdate = id => {
    history.push(`/settings/risk-rating/${id}/update`)
  }

  const onView = id => {
    history.push(`/settings/risk-rating/${id}/view`)
  }

  const onDelete = id => {
    setPendingDeleteId(id)
  }

  const onDeleteConfirm = async () => {
    await riskRatingTemplate.remove(pendingDeleteId)
    setPendingDeleteId(null)
    enqueueSnackbar('Risk Rating template was successfully deleted!', { variant: 'success' });
    await fetch()
  }

  const onBatchDelete = () => {
    if (selectedItems.length) {
      setPendingBatchDelete(true)
    }
  }

  const onBatchDeleteConfirm = async () => {
    await Promise.all(
      selectedItems
        .filter(item => !item.isDefault)
        .map(async item => {
          await riskRatingTemplate.remove(item.id)
        })
    )
    setPendingBatchDelete(false)
    enqueueSnackbar('Risk Rating templates were successfully deleted!', { variant: 'success' });
    await fetch()
  }

  const selectedItems = useMemo(() => sortedAndFilteredLines.filter(item => item.isChecked), [sortedAndFilteredLines])

  return (
    <React.Fragment>
      <Box
        display={'flex'}
        flexDirection={'column'}
        width={'100%'}
        style={{ overflowY: 'scroll' }}
        height="calc(100vh - 200px)"
        px={1}
      >
        {/* headline */}
        <Box display={'flex'} alignItems={'center'}>
          <Box display={'flex'} flexGrow={1}>
            <InlineHeadBadge
              badgeColor={'#656565'}
              textColor={'#FFF'}
              badgeValue={data && data.length}
            >
              Templates
            </InlineHeadBadge>
          </Box>
          <Box display={'flex'}>
            <ButtonWithIcon
              startIcon={<AddActive2Icon />}
              onClick={onCreate}
            >
              Create Template
            </ButtonWithIcon>
          </Box>
          <Box
            display={'flex'}
            ml={3}
          >
            <MenuWithContext>
              <MenuItem
                onClick={onBatchDelete}
                disabled={!selectedItems.filter(item => !item.isDefault).length}
              >
                Delete
              </MenuItem>
            </MenuWithContext>
          </Box>
        </Box>
        {/* search */}
        <Box display={'flex'}>
          <OutlinedSearch
            value={search}
            onChange={onSearchChange}
            icons={<SearchIcon />}
            label="Search templates"
          />
        </Box>
        {/* content */}
        <Box display={'flex'} mt={3} px={1}>
          <TableList
            columns={TABLE_COLUMNS}
            items={sortedAndFilteredLines}
            onSelect={onSelect}
            onClick={onView}
            sortableColumns={TABLE_COLUMNS.map(el => el.field)}
            onSort={onSort}
            sort={sort}
            sortDirection={sortDirection}
            threeDotsActions={[
              { label: 'View', handler: (id, item) => onView(id) },
              { label: 'Edit', handler: (id, item) => onUpdate(id), hidden: (id, item) => item.isDefault },
              { label: 'Save As', handler: (id, item) => onSaveAs(id) },
              { label: 'Delete', handler: (id, item) => onDelete(id), hidden: (id, item) => item.isDefault },
              //{ label: 'Block', handler: (id, item) => {} },
              //{ label: 'Unblock', handler: (id, item) => {}, disabled: true },
            ]}
          />
        </Box>
      </Box>
      <RiskRatingSettingsDeleteDialog
        open={!!pendingDeleteId}
        onClose={() => setPendingDeleteId(null)}
        onConfirm={onDeleteConfirm}
        name={data.find(t => t.id === pendingDeleteId)?.templateName}
      />
      <RiskRatingSettingsBatchDeleteDialog
        open={!!pendingBatchDelete}
        onClose={() => setPendingBatchDelete(false)}
        onConfirm={onBatchDeleteConfirm}
        names={selectedItems.filter(item => !item.isDefault).map(item => item.templateName)}
      />
    </React.Fragment>

  )
}
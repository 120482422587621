import React from 'react';
import { Modal } from '@components/modals';

export default function DeleteDialog({ open, title, text, onConfirm, onClose, confirmBtnText = 'DELETE' }) {

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title={title}
        mainText={text}
        actionsDirection="row"
        actions={
          [
            {
              type: 'main',
              label: 'CANCEL',
              action: onClose,
              style: { width: 160 },
            },
            {
              type: 'secondary',
              label: confirmBtnText,
              action: onConfirm,
              style: { width: 160 },
            },
          ]
        }
      />
    </React.Fragment>
  );
}
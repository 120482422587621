import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Grid, Divider, Box, Typography } from '@material-ui/core';

import UserCard from '@components/cards/user-card'
import { prepareProfilesFetchData, profilesPrepareDataAdvantage } from '@utils/prepare-data/prepareProfilesData';
import { prepareDataForPaginations } from '@utils/prepareDataForPaginations';
import RegularPagination from '@components/pagination/regular-pagination';

import { useLocation, useParams } from 'react-router-dom';
import { clientApi } from '@app/api';
import { useSelector, useDispatch } from 'react-redux';
import { backgroundCheck, paginations, scroll } from '@store/actions';
import clsx from 'clsx';
// import NoResultsFound from "@components/layout/no-results-found";
import ModalContent from '../modal-content';
import Scroll from '@components/scroll/scroll';
import { Modal } from '@components/modals';
import { isEmpty } from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import Instructions from './instructions';

const useStyles = makeStyles((theme) => ({
  newSearchContentBlockWrapper: props => ({
    margin: '0 48px',
    width: props.isShowRightCard ? 'calc(100% - 386px)' : '',
  }),
  newSearchContentBlock: props => ({
    height: props.scrollHeight,
    overflowY: props.isNoScroll ? 'hidden' : 'auto',
  }),
  wrapperUserCard: {
    width: '100%',
    padding: '20px 0'
  },
  marginGreedY: {
    margin: '5px 0',
  },
  dividerLength: {
    marginBottom: '10px',
  },
  profilesText: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '30px',
    color: theme.palette.text.GY5,
  },
  userCardInfroHeader: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '28px',
    color: theme.palette.text.BL1,
  },
  customButtonModalWidth: {
    width: '180px',
  },
}))

const ADVANTAGE_PROVIDER = 'COMPLY_ADVANTAGE';

const initialPage = 0;
const initialQuantity = 36;
const defaultPages = [0];

export default function CardList({ isShowRightCard, onNext, redirectLink, getCurrentSearch }) {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const dispatch = useDispatch();

  const search = new URLSearchParams(location.search);
  const searchId = search.get('searchId');
  const searchName = search.get('searchName');
  const searchType = search.get('searchType');
  const { clientId } = useParams();

  const { currentPages, currentItemsLength } = useSelector(store => store.paginations);
  const { profilesVersionId } = useSelector(store => store.searchHistory);
  const { profiles, quantityProfiles, profilesPages, isShowWhitelisted, currentSearch, profilesPage } = useSelector(store => store.backgroundCheck);
  const { isRequestSent, wrapperCardRef } = useSelector(store => store.scroll);

  const [viewScrollHeight, setViewScrollHeight] = useState(null);
  const [openModal, setOpenModal] = useState(null);
  const [whitelistData, setWhitelistData] = useState(null);
  const [lastOpenedSearchProfile] = useState(+(sessionStorage.getItem('lastOpenedSearchProfile') || 0));

  const wrapCardRef = useRef();

  const isNoScroll = useMemo(() => {
    return !profilesPages && profiles[currentPages[0]]?.length <= 4 && currentSearch.searchProvider !== ADVANTAGE_PROVIDER;
  }, [profilesPages, profiles, currentPages, currentSearch])

  const scrollHeight = location.pathname.includes('search-history/results') || location.pathname.includes('background-check/results')
    ? 'calc(100vh - 315px)'
    : 'calc(100vh - 375px)';

  const classes = useStyles({ isShowRightCard, isNoScroll, scrollHeight });

  const handleWrapperCardHeight = async () => {
    await dispatch(scroll.setWrapperCardRef(wrapCardRef?.current?.offsetHeight))
  }

  useEffect(() => {
    if (!!wrapCardRef?.current && !isRequestSent) {
      handleWrapperCardHeight();
    }
  }, [wrapCardRef, currentPages, isRequestSent]) // eslint-disable-line

  const getProfilesByVersionId = async (id, pageNumber, quantity) => {
    dispatch(scroll.setIsRequestSent(true));

    const isWhitelisted = isShowWhitelisted;
    const { status, data  } = await clientApi.searchVersion.getProfilesByVersionId(id, pageNumber, quantity, isWhitelisted);

    if (status === 200 && !!data.entries.length) {
      dispatch(scroll.setIsRequestSent(false));
      const changedProfiles = currentSearch.searchProvider === ADVANTAGE_PROVIDER
        ? profilesPrepareDataAdvantage(data.entries, profilesVersionId, currentSearch.searchProvider)
        : prepareProfilesFetchData(data.entries, profilesVersionId);
      dispatch(paginations.setCurrentItemsLength(changedProfiles.length));

      const pagesCount = data.pages === 1 ? 0 : data.pages;
      dispatch(backgroundCheck.setProfilesPages(pagesCount));

      const buffer = prepareDataForPaginations(changedProfiles, 36);

      dispatch(backgroundCheck.setQuantityProfiles(data.count));
      dispatch(backgroundCheck.setChangedProfiles(buffer));
    } else {
      dispatch(scroll.setIsRequestSent(false));
      redirectLink();
    }
  }

  const fetchData = async (pageNumber, quantity) => {
    try {
      const isWhitelisted = isShowWhitelisted;
      const res = profilesVersionId
        ? await clientApi.searchVersion.getProfilesByVersionId(profilesVersionId, pageNumber, quantity, isWhitelisted)
        : await clientApi.search.getProfiles(searchId, pageNumber, quantity, isWhitelisted);
      const { status, data } = res;
      return { status, data };
    } catch (e) {
      return e;
    }
  }

  useEffect(() => {
    dispatch(backgroundCheck.setProfilesPage(initialPage));
  }, [profilesVersionId, isShowWhitelisted, dispatch])
  
  useEffect(() => {
    return (() => {
      dispatch(backgroundCheck.setChangedProfiles({}));
      dispatch(backgroundCheck.setQuantityProfiles(0));
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (profilesVersionId) {
      getProfilesByVersionId(profilesVersionId);
    } else {
      handleInitial(initialPage, initialQuantity);
    }

    return (() => {
      dispatch(paginations.setCurrentPages(defaultPages));
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowWhitelisted, currentSearch]);

  const handleInitial = async (initialPage, initialQuantity) => {
    // prevent more scroll when requiest sent
    dispatch(scroll.setIsRequestSent(true));
    //
    const { status, data } = await fetchData(initialPage, initialQuantity);

    // Recursion - if last page is gone, fetch second to last.
    if (!data?.entries?.length && initialPage !== 0) {
      return handleInitial(initialPage - 1, initialQuantity);
    }

    if (status !== 200  || !data.entries.length) {
      dispatch(scroll.setIsRequestSent(false));
      return redirectLink();
    }

    const changedProfiles = currentSearch.searchProvider === ADVANTAGE_PROVIDER
      ? profilesPrepareDataAdvantage(data.entries, profilesVersionId, currentSearch.searchProvider)
      : prepareProfilesFetchData(data.entries, profilesVersionId);

    dispatch(paginations.setCurrentItemsLength(changedProfiles.length));
    dispatch(backgroundCheck.setProfiles({ [initialPage]: changedProfiles }));
    dispatch(backgroundCheck.setQuantityProfiles(data.count));

    dispatch(backgroundCheck.setProfilesPages(data.pages === 1 ? 0 : data.pages));

    dispatch(scroll.setIsRequestSent(false));
  };

  const checkboxHandleChange = id => {
    const selectedAll = [];
    let changedProfiles = profiles;
    for (const key in changedProfiles) {
      const arr = changedProfiles[key].map(el => el.id === id ? ({ ...el, isChecked: !el.isChecked}) : el);
      selectedAll.push(arr.every(el => el.isChecked))
      changedProfiles[key] = arr;
    }

    dispatch(backgroundCheck.setChangedProfiles(changedProfiles));
    dispatch(backgroundCheck.setSelectAllProfiles(selectedAll.every(el => el)));
  }

  const getSources = async (item) => {
    let profile = item;
    let profileClientId = profile.clientId;

    if (!!profilesVersionId) {
      const res = await clientApi.searchVersion.getProfileByVersionId(profilesVersionId, profile.id);
      if (res.status === 200) {
        profile = res.data;

        if (!profileClientId) {
          const resp = await clientApi.profile.show(profile.id);
          if (resp.status === 200) {
            profileClientId = resp.data.clientId;
          }
        }
      }
    }

    sessionStorage.setItem('lastOpenedSearchProfile', profile.id)
    return onNext({ searchId, profileId: profile.id, searchName: profile.name || profile.meta.name, clientId: clientId || profileClientId, searchType });
  }

  const handleChangeWhitelist = async (args) => {
    const { id, value } = openModal || args;

    if (!value) return ;

    dispatch(scroll.setIsRequestSent(true));
    dispatch(scroll.setNoScroll(true));
    dispatch(scroll.setWrapperCardRef(null));

    let checkedItems = {};
    for (const key in profiles) {
      if (isEmpty(checkedItems)) {
        checkedItems = profiles[key].find(el => el.id === id);
      }
    }

    const profileId = checkedItems.id
    const data = {
      profileId,
      payload: {
        bgCheckRiskRating: checkedItems.bgCheckRiskRating,
        whitelisted: value === 'On',
        whitelistedReason: whitelistData?.label || null
      },
    }
    const res = await clientApi.profile.updateProfileReview(data);

    if (res.status === 200) {
      enqueueSnackbar(`Profile "${checkedItems.name}" was successfully ${data.payload.whitelisted ? 'added to' : 'removed from'} whitelist!`, { variant: 'success' });
      
      let changedProfiles = profiles;
      for (const key in changedProfiles) {
        const arr = changedProfiles[key].map(el => el.id === id
          ? ({
            ...el,
            isWhitelisted: value === 'On',
            whitelistedAt: res.data.whitelistedAt,
            whitelistedReason: res.data.whitelistedReason,
            meta: el.meta.map(item => item.field === 'WHITELIST' ? ({ ...item, value }) : item)
          }) : el
        );
        changedProfiles[key] = arr;
      }

      if (whitelistData?.note?.note && whitelistData?.note?.note.trim()) {
        let response;
        if (!!whitelistData?.note?.id) {
          const noteId = whitelistData?.note?.id;
          response = await clientApi.profile.updateNote(
            profileId,
            noteId,
            { note: whitelistData.note.note, noteType: 'profileWhitelist' }
          );
        } else {
          response = await clientApi.profile.addNote(
            profileId,
            { note: whitelistData.note.note, noteType: 'profileWhitelist' }
          );
        }

        if (response.status === 200) {
          for (const key in changedProfiles) {
            const arr = changedProfiles[key].map(item => item.id === profileId ? ({ ...item, notes: response.data.notes }) : item);
            changedProfiles[key] = arr;
          }
        }
      }

      if (isShowWhitelisted) {
        dispatch(backgroundCheck.setChangedProfiles(changedProfiles));
      } else {
        await handleInitial(currentPages[0], initialQuantity);
      }
      getCurrentSearch();
      setWhitelistData(null);
      setOpenModal(null);
    }

    dispatch(scroll.setIsRequestSent(false));
  }

  const handleWhitelistActions = (e, id, data) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    let payload = { id, value: e.target.value || data.value };

    if (data) {
      payload = { id, ...data };
    }

    (e.target.value === 'On' || data?.value)
      ? setOpenModal(payload)
      : handleChangeWhitelist(payload);
  }

  const getProfiles = async (nextPage) => {
    const { status, data } = await fetchData(nextPage, initialQuantity);
    if (status !== 200  || !data.entries.length) {
      redirectLink();
      dispatch(scroll.setIsRequestSent(false));
      return;
    }

    const changedProfiles = currentSearch.searchProvider === ADVANTAGE_PROVIDER
      ? profilesPrepareDataAdvantage(data.entries, profilesVersionId, currentSearch.searchProvider)
      : prepareProfilesFetchData(data.entries, profilesVersionId);

    dispatch(paginations.setCurrentItemsLength(currentItemsLength + changedProfiles.length));
    dispatch(backgroundCheck.setProfiles({ [nextPage]: changedProfiles }));
  }

  const handlePagination = (pages, prev, next) => {
    if (next) {
      dispatch(backgroundCheck.setProfilesPage(profilesPage + 1));
    } else if (prev) {
      dispatch(backgroundCheck.setProfilesPage(profilesPage - 1));
    }
  }

  const pageChange = async (event, value) => {
    const result = value - 1;

    dispatch(backgroundCheck.setProfilesPage(result));
    if (!!profiles[result]) {
      dispatch(paginations.setCurrentPages([result]));
    } else {
      await handleInitial(result, initialQuantity);
      dispatch(paginations.setCurrentPages([result]));
    }
    dispatch(scroll.setTriggerScroll(true));
  }

  const hidesForScroll = useMemo(() => {
    let height = 0;
    if (!!wrapperCardRef && !!viewScrollHeight && wrapperCardRef < viewScrollHeight) {
      const result = (viewScrollHeight - wrapperCardRef) + 50;
      height = `${result}px`;
    }
    return <div style={{ width: '100%', marginBottom: 10, height }} />
  }, [wrapperCardRef, viewScrollHeight])

  const isLastPage = useMemo(() => profilesPages === profilesPage + 1, [profilesPages, profilesPage])

  return (
    <Box className={classes.newSearchContentBlockWrapper}>
      <Scroll
        customClasses={classes.newSearchContentBlock}
        action={getProfiles}
        quantityItems={quantityProfiles}
        items={profiles}
        handlePagination={handlePagination}
        allPages={profilesPages}
        isLastPage={isLastPage}
        getViewScrollHeight={value => setViewScrollHeight(value)}
        pageSize={36}
      >
        <Grid container spacing={1} classes={{ root: clsx(classes.marginGreedY, classes.wrapperUserCard) }} ref={wrapCardRef}>
          {(isEmpty(profiles) && !!profilesVersionId) ? (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Typography className={classes.profilesText}>
                No hits found for:
              </Typography>
              <Typography className={classes.userCardInfroHeader}>
                {searchName}
              </Typography>
            </Box>
          ) : !isEmpty(profiles[currentPages[0]]) && (
            <React.Fragment>
              {profiles[currentPages[0]].map((data, i) => {
                return (
                  <Grid item xs={isShowRightCard ? 4 : 3} key={`searched-card-${data.id}-${data.name}-${i}`}>
                    <UserCard
                      data={data}
                      name={data.name}
                      onSelect={checkboxHandleChange}
                      actionCard={() => getSources(data)}
                      handleChangeWhitelist={handleWhitelistActions}
                      disabled={!!profilesVersionId}
                      lastOpened={data.id === lastOpenedSearchProfile}
                      isLastVersion={!profilesVersionId}
                    />
                  </Grid>
                )
              })}
              {(profiles[currentPages[0]]?.length <= 20 && !!currentPages[0]) && (
                hidesForScroll
              )}
            </React.Fragment>
          )}
          {/*<Box flexGrow={1} mt={16} mb={7}>
            <NoResultsFound>
              No entities found for the applied filters
            </NoResultsFound>
          </Box>*/}
        </Grid>
      </Scroll>
      {!!currentItemsLength && (
        <React.Fragment>
          <Divider className={classes.dividerLength} />
          <Box display={'flex'}>
            <RegularPagination
              page={profilesPage}
              pagesCount={profilesPages}
              pageSize={initialQuantity}
              totalCount={quantityProfiles}
              onChange={pageChange}
              footer={<Instructions count={quantityProfiles} />}
            />
          </Box>
        </React.Fragment>
      )}
      <Modal
        open={!!openModal}
        onClose={() => setOpenModal(null)}
        title="Profile Whitelist"
        actions={
          [
            {
              type: 'main',
              label: 'Cancel',
              action: () => {
                setOpenModal(null);
                setWhitelistData(null);
              },
            },
            {
              type: 'secondary',
              label: 'Whitelist',
              action: () => handleChangeWhitelist(),
              disabled: !!profilesVersionId || (!openModal && !whitelistData)
            },
          ]
        }
        propsClassses={classes.customButtonModalWidth}
        actionsDirection="row"
        content={<ModalContent setData={setWhitelistData} type="profile" data={openModal} disabled={!!profilesVersionId} />}
      />
    </Box>
  );
}

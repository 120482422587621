export const TABS = [
  {
    id: 'global',
    label: 'Global Entities',
  },
  {
    id: 'local',
    label: 'Local Entities',
  },
]

// export const CARD_DATA = [
//   {
//     id: 'corporation',
//     label: 'Corporation',
//     icon: <Corporation />
//   },
//   {
//     id: 'establishment',
//     label: 'Establishment',
//     icon: <Establishment />
//   },
//   {
//     id: 'foundation',
//     label: 'Foundation',
//     icon: <Foundation />
//   },
//   {
//     id: 'person',
//     label: 'Person',
//     icon: <Person />
//   },
//   {
//     id: 'limitedPartnership',
//     label: 'LimitedP artnership',
//     icon: <LimitedPartnership />
//   },
//   {
//     id: 'limitedLiabilityCompany',
//     label: 'Limited Liability Company',
//     icon: <LimitedLiabilityCompany />
//   },
//   {
//     id: 'partnership',
//     label: 'Partnership',
//     icon: <Partnership />
//   },
//   {
//     id: 'segregatedPortfolioCompany',
//     label: 'Segregated Portfolio Company',
//     icon: <SegregatedPortfolioCompany />
//   },
//   {
//     id: 'trust',
//     label: 'Trust',
//     icon: <Trust />
//   },
// ]

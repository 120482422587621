import React, { useState } from 'react';
import { makeStyles, Select, IconButton, InputAdornment, withStyles } from '@material-ui/core';
import { CloseIcon, CloseInactiveIcon, Dropdown3Icon } from '@app/icons';
import { omit } from 'lodash';

const useStyles = makeStyles((theme) => ({
  adornment: {
    position: 'absolute',
    padding: 0,
    right: 60,
  },
  iconButton: {
    padding: 0,
    width: 36,
    height: 36,
  },
  iconButton2: {
    width: 36,
    height: 36,
    position: 'absolute',
    right: 14,
    padding: 0,
    '&.Mui-disabled': {
      display: 'none'
    }
  },
  noHighlight: {
    backgroundColor: '#ffffff !important'
  },
  input: {
    fontWeight: 'normal',
    fontSize: 14,
    paddingTop: 4,
    paddingBottom: 4,
  }
}));

const NoLabelSelect = withStyles({
  outlined: {
    padding: '16px 20px'
  }
})(Select)

export default function SelectClearable(props) {
  const { style, value, clearable = true, inputProps, noFocus, disabled, hideIcons, noLabel } = props;
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [isNotFocused, setIsNotFocused] = useState(true);

  const handleClear = (event) => {
    event.preventDefault();
    event.stopPropagation();

    // If this doesn't work for you
    // let's introduce onClear as custom prop.
    if (props.onChange) {
      props.onChange({ target: { value: '' } });
      setIsNotFocused(false);
    }
  }

  const handleFocus = () => {
    if (isNotFocused && !noFocus) {
      setIsNotFocused(false);
      setIsOpen(true);
    }
  }
  const selectProps = omit(props, ['clearable', 'hideIcons', 'noLabel']);

  const SelectComponent = noLabel ? NoLabelSelect : Select;
  return (
    <SelectComponent
      {...selectProps}
      style={{ ...style, cursor: 'pointer' }}
      onFocus={handleFocus}
      onBlur={() => setIsNotFocused(true)}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      inputProps={inputProps}
      IconComponent={() =>
        !hideIcons &&
        <IconButton
          className={classes.iconButton2}
          onClick={() => setIsOpen(true)}
          tabIndex="-1"
          disabled={disabled}
        >
          <Dropdown3Icon />
        </IconButton>
      }
      endAdornment={
        (!!value && !!clearable && !hideIcons) ?
          <InputAdornment className={classes.adornment}>
            <IconButton
              onClick={handleClear}
              edge="end"
              className={classes.iconButton}
              disabled={disabled}
              title="Clear"
            >
              {disabled ? <CloseInactiveIcon /> : <CloseIcon />}
            </IconButton>
          </InputAdornment>
          : undefined
      }
    >
      {props.children}
    </SelectComponent>
  )
}

import React, { useState, useMemo } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import RenderInputs from '@components/layout/render-inputs';
import ChipInput from 'material-ui-chip-input'
import CustomChip from '@components/controls/custom-chip';
import { InfoBrownOtherIcon, ErrorIcon } from '@app/icons';
import { isValidEmail } from '@utils/textUtil';

const useStyles = makeStyles((theme) => ({
  label: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: '#656565',
    marginLeft: '16px'
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '20px',
    color: '#656565',
    marginLeft: '30px'
  },
  errorWrapper: props => ({
    width: '395px',
    maxHeight: !props.formError && '90px',
    minHeight: !props.formError && '90px',
    height: !props.formError && '90px',
    backgroundColor: '#F8EEEE',
    borderRadius: '12px',
    padding: '15px'
  }),
  errorText: props => ({
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#232323',
    marginLeft: '11px',
    marginTop: props.formError && '3px'
  }),
  linkified: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.palette.text.LB3,
    cursor: 'pointer'
  },
  chip: {
    height: '20px',
    background: '#EEE9E7'
  },
  chipInputRoot: {
    '& .MuiOutlinedInput-inputAdornedStart': {
      paddingLeft: '6px',
      paddingTop: '12px'
    }
  },
}))

const inputs = [
  {
    key: 'teamLeader',
    label: 'Select Team Leader',
    options: [
      'John Smith (js@gmail.com)',
      'Devon Lane (devonlane@mai..)',
      'John Doe (jdoe@mail.com)'
    ],
    type: 'select',
  },
  // {
  //   key: 'email',
  //   label: 'Emails',
  //   type: 'input',
  // },
]

export default function InviteForm({ model, handleChange, errors, disabled }) {
  const [values, setValues] = useState([]);
  const [formError, setFormError] = useState('');

  const classes = useStyles({ formError });

  const isError = useMemo(() => {
    return values.length > 10 || !!formError;
  }, [values, formError])

  const handleAddChip = (chip) => {
    setFormError('');
    setValues(items => ([ ...items, chip ]));
  }
  const handleDeleteChip = (chip) => {
    const newValues = values.filter(el => el !== chip);
    !newValues.length && setFormError('');
    setValues(newValues);
  }

  const chipRenderer = (data, key) => {
    const isValid = isValidEmail(data.text);
    setFormError(isValid ? '' : 'Incorrect email.');

    return (
      <Box ml={'5px'}>
        <CustomChip
          label={
            <span style={{ color: '#7A5B44', fontSize: '12px' }}>{data.text}</span>
          }
          onDelete={() => handleDeleteChip(data.chip)}
          background={isValid ? '#EDEDED' : '#EEE9E7'}
        />
      </Box>
    )
  }

  return <Box width={'425px'} my={4}>
    {inputs.map((field, i) => (
      <RenderInputs
        ind={i}
        field={field}
        model={model}
        errors={errors}
        handleChange={handleChange}
        key={`${field.key}-${i}`}
        disabled={disabled || field.key === 'email'}
      />
    ))}
    <ChipInput
      label="Emails"
      value={values}
      onAdd={(chip) => handleAddChip(chip)}
      chipRenderer={chipRenderer}
      variant="outlined"
      fullWidth
      error={isError}
      classes={{ chip: classes.chip, root: classes.chipInputRoot }}
    />
    {isError && (
      <Box display="flex" flexDirection="column" justifyContent="space-between" mt={3} className={classes.errorWrapper}>
        <Box display="flex" flexDirection="row" alignItems="flex-start" ml={'-5px'}>
          <ErrorIcon width={28} />
          <Typography className={classes.errorText}>
            {formError || 'You have reached the limit of team members.'}
          </Typography>
        </Box>
      </Box>
    )}
    <Box display="flex" flexDirection="column" mt={3}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <InfoBrownOtherIcon />
        <Typography className={classes.label}>INVITE MULTIPLE TEAM MEMBERS</Typography>
      </Box>
      <Typography className={classes.text}>You can enter up to 10 emails separated by comma.</Typography>
    </Box>
    <Box display="flex" flexDirection="column" mt={3}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <InfoBrownOtherIcon />
        <Typography className={classes.label}>Permissions</Typography>
      </Box>
      <Typography className={classes.text}>
        Team members will have access to the entire account except subscriptions, invoicing, and billing
      </Typography>
    </Box>
  </Box>
}
import React from 'react';
import { Route, Switch } from 'react-router';
import EmailsSettings from './emails';

export default function EmailsNotificationsSettings() {
  return (
    <Switch>
      <Route exact path="/settings/emails-notifications">
        <EmailsSettings />
      </Route>
    </Switch>
  )
}
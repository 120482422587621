import { BackgroundCheck } from '@views/protected/client/background-check';

const routes = [
  {
    path: '/background-check',
    component: BackgroundCheck,
    guest: false,
  }
]

export default routes;
import React, { useState, useEffect, useMemo } from 'react';
import { Box, Grid, FormControlLabel, Switch } from '@material-ui/core';
import TableList, { TCELL_TYPES } from '@components/lists/table-list';
import OutlinedSearch from '@components/inputs/outlined-search';
import InlineHeadBadge from '@components/badges/inline-head-badge';
import FilterWithContext from '@components/dropdowns/filter-with-context';
import { Modal } from '@components/modals';
import { DeleteIcon } from '@app/icons';
import { dummy } from '../dummy';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modalButtonWidth: {
    width: '160px',
  },
}))

const TABLE_COLUMNS = [
  { field: 'title', headerName: 'Title', bold: true, useTooltip: true },
  { field: 'notification', headerName: 'Notification ', useTooltip: true },
  { field: 'recipient', headerName: 'Recipient', useTooltip: true },
  { field: 'isRead', headerName: 'Is Read' },
  { field: 'readDate', headerName: 'Read Date' },
  { field: 'notificationDate', headerName: 'Notification Date' },
  { cellType: TCELL_TYPES.ICON_BUTTON, field: 'remove', headerName: 'Actions', icon: <DeleteIcon />, tooltip: 'Remove' }
]

const left = [
  { value: 'readNotifications', label: 'Read Notifications', isChecked: true },
  { value: 'unreadNotifications', label: ' Unread Notifications', isChecked: true },
  // { value: 'notificationDate', label: 'Notification Date', isChecked: true },
]

const right = [
  { value: 'sulie', label: 'Sign Up and Log In Emails', isChecked: true },
  { value: 'dne', label: 'Document Notifications Emails', isChecked: true },
  { value: 'she', label: 'Sharing Emails', isChecked: true },
  { value: 'shae', label: 'Subscriptions and Account Emails', isChecked: true },
  { value: 'be', label: 'Billing Emails', isChecked: true },
]

export default function Notifications({ setNotifications }) {
  const [data, setData] = useState(dummy.notifications);
  const [search, setSearch] = useState();
  const [filters, setFilters] = useState({ left, right });
  const [openModal, setOpenModal] = useState(null);
  // eslint-disable-next-line
  const [page, setPage] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    setNotifications(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const onSearchChange = (event) => {
    setSearch(event.target.value);
  }

  const onSelect = (id) => {
    const newData = data.map(
      (item) => item.id === id
        ? ({ ...item, isChecked: !item.isChecked })
        : item
    );
    setData(newData);
  }

  const onFilterChange = (event, side) => {
    const changed = filters[side].map(el =>
      el.value === event.target.value ? ({ ...el, isChecked: event.target.checked }) : el
    );
    setFilters(item => ({ ...item, [side]: changed }));
  }

  const handleDelete = () => {
    setData(items => items.filter(el => el.id !== openModal));
    setOpenModal(null);
  }

  const handleIsRead = (id, read) => {
    setData(items => items.map(el => el.id === id ? ({ ...el, isRead: read }) : el));
  }

  const formattedData = useMemo(() => {
    return data.map(e => {
      const item = { ...e };

      switch (item.isRead) {
        case 'Yes':
          item.threeDotsActions = ['Mark as Unread'];
          break;
        
        case 'No':
          item.threeDotsActions = ['Mark as Read'];
          break;
        
        default:
          break;
      }

      return item;
    })
  }, [data])

  return (
    <Box display={'flex'} flexGrow={'1'} mt={2} flexDirection={'column'}>
      <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'}>
          <Box mr={1}>
            <InlineHeadBadge
              badgeColor={'#CAD7CA'}
              badgeValue={7}
            >
              Notifications
            </InlineHeadBadge>
          </Box>
        </Box>
        <Box display={'flex'}>
          <FilterWithContext>
            <Grid container spacing={6}>
              <Grid item xs={5}>
                {filters.left.map((el, i) => (
                  <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value} - ${i}`}>
                    <FormControlLabel
                      control={<Switch value={el.value} checked={el.isChecked} onClick={(e) => onFilterChange(e, 'left')} />}
                      label={el.label}
                    />
                  </Box>
                ))}
              </Grid>
              <Grid item xs={5}>
                {filters.right.map((el, i) => (
                  <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value} - ${i}`}>
                    <FormControlLabel
                      control={<Switch value={el.value} checked={el.isChecked} onClick={(e) => onFilterChange(e, 'right')} />}
                      label={el.label}
                    />
                  </Box>
                ))}
              </Grid>
            </Grid>
          </FilterWithContext>
        </Box>
      </Box>
      <Box display={'flex'} mb={3}>
        <OutlinedSearch
          value={search}
          onChange={onSearchChange}
          label="Search Notifications"
        />
      </Box>
      <TableList
        columns={TABLE_COLUMNS}
        items={formattedData}
        onSelect={onSelect}
        page={page}
        pageSize={12}
        pagesCount={1}
        totalCount={7}
        sortableColumns={TABLE_COLUMNS.filter(el => el.field !== 'remove').map(el => el.field)}
        maxHeight="calc(100vh - 380px)"
        handlers={{
          remove: (id) => setOpenModal(id)
        }}
        threeDotsActions={[
          { label: 'Mark as Read', handler: (id) => handleIsRead(id, 'Yes') },
          { label: 'Mark as Unread', handler: (id) => handleIsRead(id, 'No') },
        ]}
      />
      <Modal
        open={!!openModal}
        onClose={() => setOpenModal(null)}
        title="Delete Notification"
        mainText="Are you sure you want to delete the selected notification?"
        propsClassses={classes.modalButtonWidth}
        actions={
          [
            {
              type: 'main',
              label: 'No',
              action: () => setOpenModal(null),
            },
            {
              type: 'secondary',
              label: 'Yes',
              action: () => handleDelete(),
            },
          ]
        }
        actionsDirection="row"
      />
    </Box>
  )
}

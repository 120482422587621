import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button, FormControlLabel, Switch, } from '@material-ui/core';

import InnerHeaderControls from '@components/layout/inner-header-controls';
import BackButton from '@views/common/back-button';
import RenderInputs from '@components/layout/render-inputs';
import DatePicker from '@components/calendar/input-date-picker'
import { generatePromoCode } from '@utils/generatePromoCode';

import history from '@app/history';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import useErrorState from '@utils/errorState';
import * as yup from 'yup';
import parseYupErrors from '@app/yup';
import sub from 'date-fns/sub';
import prepareTimestamp from '@utils/date';
import { dummy } from '../../dummy';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 'calc(100% - 30px)',
    overflowY: 'auto'
  },
  switchWrapper: {
    boxShadow: '0px 9px 26px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    borderRadius: '12px',
    width: '225px',
    padding: '16px 0',
    marginRight: '16px'
  },
  backButton: {
    marginLeft: 0
  },
  addButton: {
    marginRight: '-10px'
  },
  deleteButton: {
    padding: 0,
    minWidth: 0,
    marginLeft: '5px'
  },
  totalWrapper: {
    maxHeight: '75px',
    overflowY: 'auto',
    margin: '8px 0'
  },
  totalLabel: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '10px',
    lineHeight: '20px',
    color: theme.palette.text.BL1,
  },
  totalSum: {
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '20px',
    color: theme.palette.text.BL1,
  },
}))

const typeOptions = [
  'Percentage',
  'Amount',
]

const billableTypesOptions = [
  'Subscription: Basic',
  'Subscription: Pro',
  'Subscription: Ultra',
]

const inputs = [
  {
    key: 'name',
    label: 'Name',
    type: 'input',
  },
  {
    key: 'code',
    label: 'Code',
    type: 'input',
    element: {
      key: 'generate',
      label: 'Generate',
      type: 'button',
    }
  },
  {
    key: 'description',
    label: 'Description',
    type: 'input',
    multiline: true,
  },
  {
    key: 'type',
    label: 'Type',
    options: typeOptions,
    type: 'select',
  },
  {
    key: 'value',
    label: 'Value',
    dataType: 'number',
    type: 'input',
  },
  {
    key: 'usage',
    label: 'Usage',
    dataType: 'number',
    type: 'input',
  },
  {
    key: 'expirationDate',
    label: 'Expiration Date',
    dataType: 'date',
    type: 'input',
  },
  {
    key: 'billableTypes',
    label: 'Billable Types',
    options: billableTypesOptions,
    type: 'select',
  },
]

const switchGroup = [
  { value: 'basic', label: 'Subscription: Basic', isChecked: true },
  { value: 'pro', label: 'Subscription: Pro', isChecked: true },
  { value: 'ultra', label: 'Subscription: Ultra', isChecked: true },
]

const defaultModel = {
  name: '',
  code: '',
  description: '',
  type: '',
  value: '',
  usage: '',
  expirationDate: '',
  billableTypes: '',
}

export default function PromoCodeForm() {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [promoId] = useState(search.get('promoId'));

  const { enqueueSnackbar } = useSnackbar();

  const [model, setModel] = useState(defaultModel);
  const [filters, setFilters] = useState(switchGroup);

  const classes = useStyles();

  const [errors, setErrors] = useErrorState({});

  useEffect(() => {
    if (promoId) {
      const item = dummy.promoCodes.find(el => el.id === +promoId);

      setModel({
        name: item.name,
        code: item.code,
        description: '',
        type: item.type === '%' ? '0' : '1',
        value: item.value,
        usage: item.usageCount,
        expirationDate: prepareTimestamp(item.expirationDate, 'YYYY-MM-DD'),
        billableTypes: billableTypesOptions.indexOf(item.billableTypes),
      });
    }
  }, [promoId])

  const handleChange = (prop) => (event) => {
    const newModel = { ...model, [prop]: event.target.value };
    setModel(newModel);

    if (errors[prop]) {
      schema.validateAt(prop, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [prop]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [prop]: err.message });
        });
    }
  };

  const schema = yup.object().shape({
    name: yup.string().label('Name').required().max(30),
    expirationDate: yup.date()
      .nullable()
      .transform((curr, orig) => orig === '' ? null : curr)
      .label('Expiration Date')
      .required()
      .min(sub(new Date(), { days: 1 }), 'Expiration date should to not be earlier current date')
      .typeError('Invalid date format'),
    code: yup.string().label('Code').required().max(30),
    type: yup.string().label('Type').required().max(10),
    value: yup.string().label('Type').required().max(10),
    usage: yup.string().label('Type').required().max(10),
    billableTypes: yup.string().label('Type').required().max(10),
  });

  const onSave = () => {
    setErrors({});
    schema
      .validate(model, { abortEarly: false })
      .then(function (valid) {
        history.goBack();
        enqueueSnackbar(`Promo Code "${model.code}" was successfully created!`, { variant: 'success' });
      })
      .catch(function (err) {
        console.log(err)
        setErrors(parseYupErrors(err), true);
      });
  }

  const handlePromoCOde = () => {
    const code = generatePromoCode();

    const newModel = { ...model, code };
    setModel(newModel);
  }
  // eslint-disable-next-line
  const onFilterChange = (event) => {
    const changed = filters.map(el =>
      el.value === event.target.value ? ({ ...el, isChecked: event.target.checked }) : el
    );
    setFilters(changed);
  }

  const renderDatePicker = (
    <DatePicker
      handleChange={handleChange('expirationDate')}
      inputValue={model['expirationDate']}
    />
  )

  return (
    <React.Fragment>
      <InnerHeaderControls
        controlsLeft={<BackButton wrapped customButtonClasses={classes.backButton} />}
      />
      <Box className={classes.container}>
        <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center">
          <Box display="flex" mb={2} alignItems="center" justifyContent="center">
            <Typography variant="h4">Add new promo code</Typography>
          </Box>
          {inputs.map((field, i) => (
            <Grid container item sm={4} key={`${field.key}-${i}`}>
              <RenderInputs
                ind={i}
                field={field.key === 'expirationDate' ? { ...field, endAdornment: renderDatePicker } : field}
                model={model}
                errors={errors}
                handleChange={handleChange}
                action={handlePromoCOde}
              />
            </Grid>
          ))}
          <Box display="flex" mt={2} mb={5} alignItems="center" justifyContent="space-evenly" width={'100%'}>
            {model['billableTypes'] && (
              <Box width={'225px'} ml={2} />
            )}
            <Button style={{ width: '180px', marginTop: !model['billableTypes'] && '54px' }} variant={'contained'} onClick={onSave}>
              SAVE
            </Button>
            {model['billableTypes'] && (
              <Box className={classes.switchWrapper}>
                {filters.map((el, i) => (
                  <Box display={'flex'} alignItems={'center'} mx={4} height={'40px'} key={`${el.value} - ${i}`}>
                    <FormControlLabel
                      control={<Switch value={el.value} checked={el.isChecked} onClick={onFilterChange} />}
                      label={el.label}
                    />
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  )
}
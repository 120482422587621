import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select
} from '@material-ui/core';
import InnerHeader from '@components/layout/inner-header';
import ButtonBack from '@components/buttons/button-back';
import history from '@app/history';
import { riskRatingTemplate } from '@app/api/client';
import LinkButton from '@components/buttons/link-button';
import RiskRatingSettingsForm from './form';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import parseYupErrors from '@app/yup';
import CustomTooltip from '@components/controls/tooltip';
import _ from 'lodash';
import debounce from 'awesome-debounce-promise';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const schema = yup.object().shape({
  templateName: yup.string().trim().required()
});

export default function RiskRatingSettingsCreate(props) {
  const use2ColumnLayout = useMediaQuery('(min-width:1368px)');

  const [isFormShown, setIsFormShown] = useState(false)

  const [templates, setTemplates] = useState([])

  const [questions, setQuestions] = useState([])

  const [errors, setErrors] = useState({})

  const [templateNameDirty, setTemplateNameDirty] = useState(false)

  const [sourceTemplateId, setSourceTemplateId] = useState('')

  const [templateName, setTemplateName] = useState('')

  const { enqueueSnackbar } = useSnackbar()

  const sourceQuestions = useMemo(() => {
    if (sourceTemplateId) {
      return templates.find(t => t.id === sourceTemplateId).questions
    }
    return []
  }, [sourceTemplateId, templates])

  const onShowForm = () => {
    setIsFormShown(true)
  }

  const onSave = () => {
    riskRatingTemplate.store({
      templateName: _.trim(templateName),
      questions
    }).then(({ data }) => {
      enqueueSnackbar(`Risk rating template "${templateName}" was successfully saved!`, { variant: 'success' })
      goToView(data.id)
    })
  }

  const onBack = () => {
    history.push('/settings/risk-rating/create/intro')
  }

  const goToDashboard = () => {
    history.push('/settings/risk-rating')
  }

  const onCancel = () => {
    goToDashboard()
  }

  const goToView = (templateId) => {
    history.push(`/settings/risk-rating/${templateId}/view`)
  }

  useEffect(() => {
    fetchTemplates()
  }, [])

  useEffect(() => {
    setErrors({});
    if (templateNameDirty) {
      schema
        .validate({ templateName }, { abortEarly: false })
        .catch(err => {
          setErrors(
            parseYupErrors(err)
          )
        })
    }
  }, [templateName, templateNameDirty])

  const fetchTemplates = () => {
    return riskRatingTemplate.index()
      .then(({ data }) => {
        setTemplates(
          data.filter(t => t.systemDefault === true)
            .map(t => ({
              id: t.id,
              name: t.jsonData.templateName,
              questions: t.jsonData.questions
            }))
        )
      })
  }

  const isSaveDisabled = useMemo(() => !templateName.trim() || !questions.filter(q => !q.excluded).length,
    [templateName, questions]
  )

  const isNextDisabled = useMemo(() => !sourceTemplateId || !templateName.trim(),
    [sourceTemplateId, templateName]
  )

  const onChangeTemplateValue = value => {
    setTemplateName(value)
    setTemplateNameDirty(true)
  }

  const debouncedOnChangeTemplateValue = debounce(onChangeTemplateValue, 500);

  return (
    <Box display={'flex'} flexDirection={'column'} flexGrow={1} mt={5}>
      <Box display={'flex'}>
        <ButtonBack onClick={onBack} />
      </Box>
      <Box display={'flex'}>
        <InnerHeader
          ind={0}
          tabs={[
            { id: 'title', label: 'New template' }
          ]}
        />
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        style={{
          overflowY: use2ColumnLayout ? 'initial' : 'auto',
          margin: '-8px',
          padding: '8px',
        }}
        maxHeight="calc(100vh - 280px)"
      >
        <Box
          display={'flex'}
          mt={2}
        >
          <Box display={'flex'} flexGrow={1}>
            <Grid container alignItems={'center'}>
              <Grid item xs={10} md={6} style={{ paddingRight: 12 }}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="template">Select a system template</InputLabel>
                  <Select
                    id={'template'}
                    value={sourceTemplateId}
                    onChange={event => {
                      setSourceTemplateId(event.target.value)
                    }}
                  >
                    {templates.map(option => (
                      <MenuItem
                        key={option.id}
                        value={option.id}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={10} md={6} style={{ paddingLeft: 12 }}>
                <FormControl fullWidth variant="outlined" error={!!errors?.templateName}>
                  <InputLabel htmlFor="email">
                    Give your new template a name
                  </InputLabel>
                  <OutlinedInput
                    defaultValue={templateName}
                    onChange={event => {
                      debouncedOnChangeTemplateValue(event.target.value)
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box display={'flex'}>
          {isFormShown &&
            <Box display={'flex'} flexGrow={1} mt={2}>
              <RiskRatingSettingsForm
                sourceQuestions={sourceQuestions}
                questions={questions}
                setQuestions={setQuestions}
              />
            </Box>
          }
        </Box>
        <Box display={'flex'} flexDirection={'row-reverse'} my={3}>
          <Box display={'flex'}>
            {!isFormShown &&
              <CustomTooltip
                title={isNextDisabled && 'System template and template name are required fields'}
                placement="top">
                <Box>
                  <Button
                    style={{ width: '200px' }}
                    variant={'contained'}
                    onClick={onShowForm}
                    disabled={isNextDisabled}
                  >
                    NEXT
                  </Button>
                </Box>
              </CustomTooltip>
            }
            {isFormShown &&
              <CustomTooltip
                title={isSaveDisabled && 'Template name and at least one question should be included in the template'}
                placement="top">
                <Box>
                  <Button
                    style={{ width: '200px' }}
                    variant={'contained'}
                    onClick={onSave}
                    disabled={isSaveDisabled}
                  >
                    SAVE
                  </Button>
                </Box>
              </CustomTooltip>
            }
          </Box>
          <Box display={'flex'} mx={3}>
            <LinkButton onClick={onCancel}>
              CANCEL
            </LinkButton>
          </Box>
        </Box>
      </Box>

    </Box>
  )
}
import React, { useCallback } from 'react';
import { Box, Typography, IconButton } from '@material-ui/core';
import ViewTogleWithLabel from '@components/controls/view-toggle-with-label';
import { ChevronLeftIcon, ChevronRightIcon } from '@app/icons';

import { calendarSchedule } from '@app/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { GET_MONTHS } from '../../constants';
import { useStyles } from './styles';

export default function CalendarHeader({ isYearly }) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { dateInterval } = useSelector((state) => state.calendarSchedule);

  const changeYear = useCallback(
    (action) => {
      const year = moment(dateInterval.year)
        [action](1, 'years')
        .year()
        .toString();
      dispatch(calendarSchedule.setDateInterval({ ...dateInterval, year }));
    },
    [dateInterval, dispatch]
  );

  const changeMonth = useCallback(
    (action) => {
      const month = moment(`${dateInterval.year}-${dateInterval.month + 1}`)
        [action](1, 'months')
        .month();

      let payload = { ...dateInterval, month };
      if (
        (month === 0 && action === 'add') ||
        (month === 11 && action === 'subtract')
      ) {
        const year = moment(dateInterval.year)
          [action](1, 'years')
          .year()
          .toString();
        payload = { ...payload, year };
      }

      dispatch(calendarSchedule.setDateInterval({ ...payload }));
    },
    [dateInterval, dispatch]
  );

  const changeDateInterval = useCallback(
    (interval) => {
      dispatch(calendarSchedule.setDateInterval({ ...dateInterval, interval }));
    },
    [dispatch, dateInterval]
  );

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box />
      <Box display="flex" alignItems="center">
        <IconButton
          classes={{ root: classes.iconButtonRoot }}
          onClick={() =>
            isYearly ? changeYear('subtract') : changeMonth('subtract')
          }
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography className={classes.headerLabel}>
          {isYearly
            ? dateInterval.year
            : `${GET_MONTHS[dateInterval.month]} ${dateInterval.year}`}
        </Typography>
        <IconButton
          classes={{ root: classes.iconButtonRoot }}
          onClick={() => (isYearly ? changeYear('add') : changeMonth('add'))}
        >
          <ChevronRightIcon />
        </IconButton>
      </Box>
      <Box display="flex">
        <ViewTogleWithLabel
          labelFirst="Month"
          labelSecond="Year"
          active={dateInterval.interval}
          onChange={changeDateInterval}
        />
      </Box>
    </Box>
  );
}

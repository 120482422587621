import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel,
  OutlinedInput, FormHelperText, RadioGroup, FormControlLabel, Typography, makeStyles, Collapse, MenuItem, ListItemIcon, ListItemText
} from '@material-ui/core';

import CheckboxLight from '@components/inputs/checkbox-light';
import RadioButton from '@components/buttons/radio-button';
import SelectClearable from '@components/inputs/select-clearable';
import SelectSearchable from '@components/inputs/select-searchable';
import * as yup from 'yup';
import parseYupErrors from '@app/yup';
import { USAGE_TYPES } from './category-create-dialog';

const useStyles = makeStyles((theme) => ({
  radioBtn: {
    padding: 0,
    margin: '4px 9px'
  },
  categories: {
    maxHeight: 300,
    overflowY: 'auto',
  },
  checkbox: {
    margin: '4px 9px'
  },
}));

const DEFAULT_DATA = {
  name: '',
  expirationType: 'NEVER',
  categoryIds: [],
  typeExpirationInMonths: '',
  usageTypes: ['PERSON'],
}

const EXPIRATION_OPTIONS = [
  { value: 'NEVER', label: 'Never Expires' },
  { value: 'USER_DEFINED_REQUIRED', label: 'Ask the user for expiration date' },
  { value: 'USER_DEFINED_NOT_REQUIRED', label: 'Ask the user for non-required expiration date' },
  { value: 'PER_TYPE_DEFINED', label: 'Has acceptable age per user setting' },
]

export default function CategoryTypeCreateDialog({ open, onClose, onSave, types, categoryType, selectedCategory, categories, isAdmin, useCustom, data, title }) {
  const classes = useStyles();
  const isEdit = !!categoryType
  const [model, setModel] = useState(DEFAULT_DATA)
  const [selectedType, setSelectedType] = useState(useCustom ? 'custom' : '');

  const schema = yup.object().shape({
    name: yup.string().trim().required().label('Document Name'),
    expirationType: yup.string().required().label('Expiration Type'),
    categoryIds: yup.mixed().test('categoryRequired', 'Category is a required field', (value) => value.length > 0),
    usageTypes: yup.mixed().test('typeRequired', 'Usage types is a required field', (value) => value.length > 0),
    typeExpirationInMonths: yup.string().nullable().label('Acceptable Age')
      .when('expirationType', {
        is: 'PER_TYPE_DEFINED',
        then: yup.string().required(),
      }),
  });

  const [errors, setErrors] = useState({}) //eslint-disable-line

  useEffect(() => {
    setErrors({})
    setSelectedType(categoryType?.id || (useCustom ? 'custom' :  ''))
    if (!categoryType?.id) {
      setModel({
        ...DEFAULT_DATA,
        categoryIds: selectedCategory ? [selectedCategory.id] : [],
        usageTypes: data?.client_type
          ? [data.client_type]
          : (selectedCategory?.usageTypes || DEFAULT_DATA.usageTypes)
      })
    }
  }, [open, categoryType, selectedCategory, data]) // eslint-disable-line

  useEffect(() => {
    if (selectedType && selectedType !== 'custom' ) {
      const type = types.find(e => e.id === selectedType);
      const newModel = {};
      if (type) {
        newModel.name = type.name
        newModel.expirationType = type.expirationType
        newModel.typeExpirationInMonths = type.typeExpirationInMonths || ''
        newModel.usageTypes = type.usageTypes
      }

      const linkedCategories = categories.filter(category => category.types.some(t => t.id === selectedType))
      const categoryIds = selectedCategory ? [selectedCategory.id] : []
      linkedCategories.forEach((cat) => {
        if (!categoryIds.includes(cat.id)) {
          categoryIds.push(cat.id)
        }
      })
      newModel.categoryIds = categoryIds

      setModel(newModel)
    } else if (selectedType === 'custom') {
      setModel({
        ...DEFAULT_DATA,
        categoryIds: selectedCategory ? [selectedCategory.id] : [],
        usageTypes: data?.client_type
          ? [data.client_type]
          : (selectedCategory?.usageTypes || DEFAULT_DATA.usageTypes)
      })
    }
  }, [selectedType, types, categories, selectedCategory]) // eslint-disable-line

  useEffect(() => {
    const newModel = { ...model }
    newModel.categoryIds = newModel.categoryIds.filter(id => 
      filteredCategories.some(cat => cat.id === id)
    )
    setModel(newModel)
  }, [model.usageTypes]) // eslint-disable-line

  const onInternalSave = () => {
    setErrors({});
    schema
      .validate(model, { abortEarly: false })
      .then(() => {
        const type = types.find(e => e.id === selectedType)
        onSave({
          ...model,
          id: selectedType === 'custom' ? undefined : selectedType,
          isSystem: type?.ownedBy === 'SYSTEM'
        })
      })
      .catch(err => {
        setErrors(
          parseYupErrors(err)
        )
      })
  }

  const linkModel = (field) => event => {
    setErrors({})
    setModel({
      ...model,
      [field]: event.target.value
    })
  }

  const filteredCategories = useMemo(() => {
    let filteredCategories = [...categories]
    if (!selectedCategory?.usageTypes) {
      return categories
    }
    if (selectedCategory?.usageTypes) {
      filteredCategories = categories.filter(e => e.usageTypes
        .some(usage => selectedCategory.usageTypes.includes(usage))
      )
    }

    return filteredCategories.filter(e => model.usageTypes
      .every(usage => e.usageTypes.includes(usage))
    )
  }, [categories, selectedCategory, model.usageTypes])

  const filteredTypes = useMemo(() => {
    const filteredTypes = types
      .filter(type =>
        !(!isAdmin && selectedCategory?.ownedBy === 'SYSTEM' && type.ownedBy === 'SYSTEM') &&
        !selectedCategory?.types.find(e => e.id === type.id))
      .filter(type => {
        if (selectedCategory) {
          return type.usageTypes.every(usage => selectedCategory.usageTypes.includes(usage))
        } else {
          return true
        }
      })
      .map(e => ({ label: e.name, value: e.id }))

    filteredTypes.unshift({ label: 'Add New Document', value: 'custom' })

    return filteredTypes;
  }, [types, selectedCategory, isAdmin])

  const isSystem = !isAdmin && categoryType?.ownedBy === 'SYSTEM';
  const hideClass = selectedCategory?.usageTypes?.length === 1

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={(e) => e.preventDefault()} style={{ maxWidth: 400 }}>
        <DialogTitle>
          {title || (isEdit ? `Edit Document ${categoryType.name}` : 'Create Custom Document')}
        </DialogTitle>
        <DialogContent>
          <Box width={'400px'}>
            {(!isEdit && !useCustom) &&
              <FormControl variant="outlined" fullWidth error={!!errors['typeId']}>
                <SelectSearchable
                  value={selectedType}
                  onChange={(e) => setSelectedType(e.target.value)}
                  options={filteredTypes}
                  label="Select Document"
                  error={!!errors.typeId}
                  firstOption={{ label: 'Add New Document', value: 'custom' }}
                />
                {!!errors['typeId'] &&
                  <FormHelperText>{errors['typeId'] || null}</FormHelperText>
                }
              </FormControl>
            }
            <Collapse in={isEdit || selectedType === 'custom'}>
              <FormControl
                variant="outlined"
                fullWidth
                error={!!errors.name}
                disabled={isSystem}
              >
                <InputLabel>
                  Document Type Name
                </InputLabel>
                <OutlinedInput
                  value={model.name}
                  onChange={linkModel('name')}
                />
                {!!errors.name &&
                  <FormHelperText>
                    {errors.name}
                  </FormHelperText>
                }
              </FormControl>
              {!hideClass &&
                <FormControl variant="outlined" fullWidth error={!!errors.usageTypes}>
                  <InputLabel>Document Class</InputLabel>
                  <SelectClearable
                    value={model.usageTypes}
                    onChange={linkModel('usageTypes')}
                    clearable={false}
                    multiple
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null
                    }}
                    renderValue={
                      (selectedIds) => USAGE_TYPES
                        .filter(type => selectedIds.indexOf(type.value) !== -1)
                        .map(type => type.label)
                        .join(', ')}
                  >
                    {USAGE_TYPES.map(type => (
                      <MenuItem key={type.value} value={type.value} disabled={type.value === data?.client_type}>
                        <ListItemIcon>
                          <CheckboxLight
                            checked={model.usageTypes.indexOf(type.value) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText>
                          {type.label}
                        </ListItemText>
                      </MenuItem>
                    ))}
                  </SelectClearable>
                  {!!errors.usageTypes &&
                    <FormHelperText>{errors.usageTypes || null}</FormHelperText>
                  }
                </FormControl>
              }

              <FormControl variant="outlined" fullWidth error={!!errors.categoryIds}>
                <InputLabel htmlFor="name">Select Category</InputLabel>
                <SelectClearable
                  value={model.categoryIds}
                  onChange={linkModel('categoryIds')}
                  clearable={false}
                  multiple
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null
                  }}
                  renderValue={
                    (selectedIds) => categories
                      .filter(c => selectedIds.indexOf(c.id) !== -1)
                      .map(c => c.name)
                      .join(', ')}
                >
                  {filteredCategories?.map(c => (
                    <MenuItem
                      key={c.id}
                      value={c.id}
                      disabled={
                        (isSystem && c.ownedBy === 'SYSTEM') ||
                        selectedCategory?.id === c.id
                      }
                    >
                      <ListItemIcon>
                        <CheckboxLight
                          checked={model.categoryIds.indexOf(c.id) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        {c.name}
                      </ListItemText>
                    </MenuItem>
                  ))}
                </SelectClearable>
                {!!errors.categoryIds &&
                  <FormHelperText>{errors.categoryIds || null}</FormHelperText>
                }
                {(!hideClass && filteredCategories.length !== categories.length) &&
                  <FormHelperText>Some categories are filtered out due to your Document Class selection.</FormHelperText>
                }
              </FormControl>

              {isSystem &&
                <FormControl
                  variant="outlined"
                  fullWidth
                  disabled={isSystem}
                >
                  <InputLabel>
                    Expiration Type
                  </InputLabel>
                  <OutlinedInput
                    value={EXPIRATION_OPTIONS.find(e => e.value === model.expirationType).label}
                    disabled
                  />
                </FormControl>
              }

              {!isSystem &&
                <Box>
                  <Box mt={2}>
                    <Typography variant="h4">Expiration Type</Typography>
                  </Box>
                  <Box mt={1} pl={2} mb={2}>
                    <RadioGroup>
                      {EXPIRATION_OPTIONS.map(expiration =>
                        <FormControlLabel
                          control={
                            <RadioButton
                              key={expiration}
                              value={expiration.value}
                              onChange={linkModel('expirationType')}
                              checked={model.expirationType === expiration.value}
                              className={classes.radioBtn}
                            />
                          }
                          key={expiration.value}
                          label={<Typography variant="subtitle2">{expiration.label}</Typography>}
                          disabled={categoryType?.ownedBy === 'SYSTEM'}
                        />
                      )}
                    </RadioGroup>
                  </Box>
                  <Collapse in={model.expirationType === 'PER_TYPE_DEFINED'}>
                    <Box>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        disabled={isSystem}
                        error={!!errors.typeExpirationInMonths}
                      >
                        <InputLabel>
                          Acceptable Age (months)
                        </InputLabel>
                        <OutlinedInput
                          value={model.typeExpirationInMonths}
                          type="number"
                          onChange={linkModel('typeExpirationInMonths')}
                        />
                        {!!errors.typeExpirationInMonths &&
                          <FormHelperText>
                            {errors.typeExpirationInMonths}
                          </FormHelperText>
                        }
                      </FormControl>
                    </Box>
                  </Collapse>
                </Box>
              }
            </Collapse>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button style={{ width: '160px' }} variant={'outlined'} onClick={onClose}>
            CANCEL
          </Button>
          <Button style={{ width: '160px' }} variant={'contained'} onClick={onInternalSave} type="submit">
            {'SAVE'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
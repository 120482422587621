import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Link,
  Typography,
  OutlinedInput,
  FormHelperText,
  FormControl,
} from '@material-ui/core';
import moment from 'moment';

const RESEND_DELAY = 90 // in seconds
export default function EnterOtp({ loaded, errorMsg, onResend, code, setCode, rememberDevice }) {
  const [resendOtpAvailable, setResendOtpAvailable] = useState(false);
  const [resendOtpInterval, setResendOtpInterval] = useState(null);
  const [resendOtpDelay, setResendOtpDelay] = useState(0);

  useEffect(() => {
    if (!loaded) {
      setCode('');
    }
    }, [loaded]) // eslint-disable-line

  useEffect(() => {
    return () => resendOtpInterval && clearInterval(resendOtpInterval)
  }, [resendOtpInterval])

  useEffect(() => {
    if (resendOtpInterval && !resendOtpDelay) {
      clearInterval(resendOtpInterval)
      setResendOtpInterval(null)
      setResendOtpAvailable(true);
    }
  }, [resendOtpDelay, resendOtpInterval])

  useEffect(() => {
    if (loaded && !resendOtpAvailable && !resendOtpInterval && !resendOtpDelay) {
      setResendOtpDelay(RESEND_DELAY)
      const id = setInterval(() => {
        setResendOtpDelay(delay => delay - 1)
      }, 1000)

      setResendOtpInterval(id)
    }
  }, [loaded, resendOtpAvailable, resendOtpInterval, resendOtpDelay])

  const handleResendOtp = () => {
    setResendOtpAvailable(false);
    setCode('');
    onResend();
  }

  const formatDelayTime = useMemo(() => {
    const date = new Date(resendOtpDelay * 1000)
    return moment(date).format('mm:ss')
  }, [resendOtpDelay])

  return (
    <Box>
      <FormControl variant="outlined" fullWidth error={!!errorMsg}>
        <OutlinedInput
          inputProps={{ style: { textAlign: 'center' } }}
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        {errorMsg && <FormHelperText error={!!errorMsg}>{errorMsg || null}</FormHelperText>}
      </FormControl>
      {!!rememberDevice && rememberDevice}
      <Box mt={2}>
        <Typography variant="subtitle1" align="center">
          {resendOtpAvailable
            ? <Link onClick={handleResendOtp}>{'Re-send OTP '}</Link>
            : <b>{'Re-send OTP '}</b>
          }
                    will be available in {formatDelayTime}
        </Typography>
      </Box>
    </Box>
  );
}
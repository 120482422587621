import React from 'react';
import {Box, Typography} from '@material-ui/core';
import {ChevronLeft} from '@material-ui/icons';
import CustomChip from '@components/controls/custom-chip';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import history from '@app/history';

export default function Header(props) {

  const {clientData, baseUri, hasBack=true, onClose} = props

  const onInternalClose = () => {
    if (onClose) {
      onClose()
    } else {
      history.push(baseUri)
    }
  }

  return (
    <Box display={'flex'} my={4} flexGrow={1} flexDirection={'column'}>
      <Box display={'flex'} justifyContent={'center'}>
        { hasBack &&
                    <ButtonWithIcon
                      style={{ position: 'absolute', left:0 }}
                      startIcon={<ChevronLeft />}
                      onClick={onInternalClose}
                    >
                        Back
                    </ButtonWithIcon>
        }
        <Typography variant={'h3'}>
          { clientData?.name }
        </Typography>
      </Box>
      <Box display={'flex'}  justifyContent={'center'} alignItems={'center'} mt={1}>
        <Box>
          <CustomChip label={clientData?.client_type}/>
        </Box>
        { clientData?.folder_name &&
                <Box ml={1}>
                  <CustomChip label={clientData?.folder_name}/>
                </Box>
        }
      </Box>
    </Box>
  )
}
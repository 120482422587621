import React from 'react';
import { Box } from '@material-ui/core';
import debugMode from '@utils/debugMode';

import Documents from './components/documents';
import Client from './components/client';
import EidvPage from './components/eidv';
export default function ToDoList() {
  return (
    <Box display={'flex'} mt={2} flexDirection={'column'}>
      <Client />
      <Documents />
      {debugMode.getIsDebugMode() && <EidvPage />}
      {/* waiting implement API from BE
      <RenderReviews type='monitoring' />
      */}
    </Box>
  );
}

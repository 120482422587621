import React, { useState, useCallback } from 'react';
import { Box, Grid, Typography, InputLabel, OutlinedInput, FormControl } from '@material-ui/core';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { EditIcon } from '@app/icons';
import InnerHeaderControls from '@components/layout/inner-header-controls';
import LabelsForRender from '@components/layout/labels-for-render';
import InnerHeader from '@components/layout/inner-header';
import BackButton from '@views/common/back-button';
import { useLocation } from 'react-router-dom';
import history from '@app/history';
import { dummy } from '../dummy';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  subtitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '18px',
    color: theme.palette.text.BL1,
    marginBottom: '15px',
  },
  wrapperLabels: {
    marginBottom: '10px',
    alignItems: 'flex-start'
  },
}))

const tabs = [
  {
    id: 'accountDetails',
    label: 'Account Details',
  }
]

export default function ViewAdmin() {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [fullName] = useState(search.get('fullName'));
  const [adminId] = useState(search.get('adminId'));

  const classes = useStyles();

  const handleEdit = useCallback(() => {
    history.push(`/admin-management/edit-admin/?adminId=${adminId}`)
  }, [adminId])

  return (
    <React.Fragment>
      <Box display={'flex'} my={2} mx={1} flexDirection={'column'}>
        <InnerHeaderControls
          controlsLeft={<BackButton wrapped />}
        />
      </Box>
      <Box display={'flex'} mx={6} flexDirection={'column'}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="h4">{fullName}</Typography>
          <ButtonWithIcon startIcon={<EditIcon />} onClick={handleEdit}>
            Edit
          </ButtonWithIcon>
        </Box>
        <Box ml={-1} mb={4}>
          <InnerHeader
            ind={0}
            tabs={tabs}
          />
        </Box>
      </Box>
      <Box display={'flex'} mx={6} flexDirection={'column'} className={classes.root}>
        <Grid container spacing={12}>
          <Grid item xs={6}>
            <Typography className={classes.subtitle}>Personal Information</Typography>
            <LabelsForRender labels={dummy.personalInformation.filter(el => el.isAdmin)} propsWrapper={classes.wrapperLabels} />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.subtitle}>Account Protection</Typography>
            <FormControl fullWidth variant="outlined" >
              <InputLabel htmlFor="name">Email</InputLabel>
              <OutlinedInput
                value="eugen.l@gmail.com"
                disabled
              />
            </FormControl>
            {/* <Typography className={classes.subtitle}>Contact Information</Typography>
            <LabelsForRender labels={dummy.contactInformation} propsWrapper={classes.wrapperLabels} /> */}
          </Grid>
        </Grid>
        {/* <Box display={'flex'} mt={4} flexDirection={'column'} width={'40%'}>
          <Typography className={classes.subtitle}>Account Protection</Typography>
          <FormControl fullWidth variant='outlined' >
            <InputLabel htmlFor='name'>Email</InputLabel>
            <OutlinedInput
              value='eugen.l@gmail.com'
              disabled
            />
          </FormControl>
        </Box> */}
      </Box>
    </React.Fragment>
  )
}
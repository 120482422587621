import React from 'react';
import { Box, Divider, Grid, MenuItem } from '@material-ui/core';
import UserCard from '@components/cards/user-card';
import RegularPagination from '@components/pagination/regular-pagination';
import MenuWithContext from '../dropdowns/menu-with-context';

const defaultCompanyLabels = {
  risk_score: 'RISK RATING',
  type: 'TYPE',
  formed_at: 'FORMATION DATE',
  country: 'COUNTRY',
  last_review_at: 'LAST REVIEW',
  next_review_at: 'NEXT REVIEW',
  monitoring: 'MONITORING',
}

const defaultPersonLabels = {
  risk_score: 'RISK RATING',
  //middle_name: 'ALIASES',
  whitelist: 'WHITELIST',
  date_of_birth: 'DOB',
  gender: 'GENDER',
  citizenship: 'CITIZENSHIP',
  last_review_at: 'LAST REVIEW',
  next_review_at: 'NEXT REVIEW',
  monitoring: 'MONITORING',
}

const defaultWhitelist = [
  'risk_score',
]

export default function CardList(props) {
  const {
    items, type, companyLabels, personLabels, allLabels,
    page, pagesCount, totalCount, pageSize, onPageChange,
    onSelect, onClick, actions,
    maxHeight, disabled
  } = props;


  const getCompanyLabels = () => companyLabels || defaultCompanyLabels

  const getPersonLabels = () => personLabels || defaultPersonLabels

  const prepareLabels = (data) => {
    let labels;
    switch (type) {
      case 'person':
        labels = getPersonLabels();
        break;
      case 'company':
        labels = getCompanyLabels();
        break;
      case 'all':
        labels = allLabels;
        break;
      default:
        labels = getPersonLabels();
        break;
    }
    const keyValueList = [];
    for (const [field, label] of Object.entries(labels)) {
      if (!data[field] && !defaultWhitelist.includes(field))
        continue;
      keyValueList.push({
        field: label,
        value: data[field] || 'N/A'
      })
    }

    return keyValueList
  }

  const handleAction = (itemId, item, actionHandler) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    actionHandler(itemId, item);
  }

  return (
    <Grid container>
      <Grid container item xs={12} style={{ maxHeight: maxHeight, overflowY: 'auto' }} spacing={props.spacing || 1}>
        {items.map(item => (
          <Grid item xs={props.extended ? 3 : 4} key={`item-${item.id}`}>
            <UserCard
              name={item.name}
              data={{ meta: prepareLabels(item), ...item }}
              onSelect={onSelect}
              actionCard={() => onClick && onClick(item.id, item)}
              disabled={disabled}
              footer={actions &&
                                <Box display={'flex'} mb={1}>
                                  <MenuWithContext>
                                    {actions.map((action, id) => (
                                      <MenuItem
                                        key={action.label}
                                        onClick={handleAction(item.id, item, action.handler)}
                                      >
                                        {action.label}
                                      </MenuItem>
                                    ))}
                                  </MenuWithContext>
                                </Box>
              }
            />
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Box width={'100%'} justifyContent={'center'} mt={3} mb={1}>
          <Divider />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box width={'100%'}>
          <RegularPagination
            page={page}
            pagesCount={pagesCount}
            pageSize={pageSize}
            totalCount={totalCount}
            onChange={onPageChange}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
import React from 'react';
import { SidesLayout, SidesLayoutRight } from '@components/layout/sides-layout';
import {
  Box,
  Link,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from '@material-ui/core';

import OutlinedPassword from '@components/inputs/outlined-password';

import CheckboxIcon from '@assets/img/inputs/checkbox.svg';
import CheckboxCheckedIcon from '@assets/img/inputs/checkbox-checked.svg';

import history from '@app/history';
import { authApi } from '@app/api';

import * as yup from 'yup';
import parseYupErrors from '@app/yup';
import useErrorState from '@utils/errorState';

export default function Registration(props) {

  const [model, setModel] = React.useState({
    name: '',
    lastName: '',
    organization: '',
    email: '',
    password: '',
    password_confirm: '',
    agreement: false
  });

  const [errors, setErrors] = useErrorState({});

  const handleChange = (prop, isCheckbox) => (event) => {
    const value = !isCheckbox ? event.target.value : !!event.target.checked;
    const newModel = { ...model, [prop]: value };
    setModel(newModel);

    if (errors[prop]) {
      schema.validateAt(prop, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [prop]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [prop]: err.message });
        });
    }
  };

  const onRegister = () => {
    setErrors({});
    schema
      .validate(model, { abortEarly: false })
      .then(function (valid) {
        const email = model.email.toLowerCase().trim();
        authApi.register({
          ...model,
          email
        })
          .then(data => {
            history.push({
              pathname: '/instructions-sent',
              search: `?user=${btoa(email)}`
            })
          })
          .catch(err => {
            const errors = err.response.data.errorMessages || {};
            setErrors(errors, true);
          })
      })
      .catch(function (err) {
        console.log(err)
        setErrors(parseYupErrors(err), true);
      });
  }

  const schema = yup.object().shape({
    name: yup.string().label('First name').min(3).required().max(30).trim(),
    lastName: yup.string().label('Last name').required().max(30),
    email: yup.string().lowercase().trim().email('Invalid email format').required().max(50),
    organization: yup.string().required().max(100).trim(),
    password: yup.string().label('Password').required()
      .test('format', 'Password must have at least 8 characters, one capital letter, one small letter, one number and one special character', function () {
        const regex = new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\\-=\\[\\]{};\':"\\\\|,.<>/?]).{8,}$');
        return regex.test(this.parent.password);
      })
      .test('format_space', 'Password can\'t start or end with space(s)', function () {
        const trimmedPassword = this.parent.password.trim();
        return trimmedPassword === this.parent.password;
      }),
    password_confirm: yup.string().label('Confirm password').required().test('match', 'Passwords do not match', function () {
      return this.parent.password_confirm === this.parent.password
    }),
    agreement: yup.mixed().test('agreement', 'Please check agreement', function () {
      return !!this.parent.agreement
    }),
  });

  return (
    <SidesLayout>
      <SidesLayoutRight
        header={
          <Typography>
            <React.Fragment>
              Already registered?&ensp;
              <Link
                variant={'body1'}
                onClick={() => {
                  history.push('/login')
                }}
              >
                Login
              </Link>
            </React.Fragment>
          </Typography>
        }>

        <Grid container justify={'center'} alignItems={'center'}>
          <Grid item xs={10} md={8} lg={6}>
            <Grid item xs={12}>
              <Box mb={5}>
                <Typography variant={'h1'} align={'center'}>Registration</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={(e) => e.preventDefault()}>
                <FormControl id="name" fullWidth variant="outlined" error={!!errors['name']}>
                  <InputLabel htmlFor="name">First Name</InputLabel>
                  <OutlinedInput
                    id="name"
                    value={model.name}
                    onChange={handleChange('name')}
                  />
                  {!!errors['name'] &&
                    <FormHelperText>{errors['name'] || null}</FormHelperText>
                  }
                </FormControl>
                <FormControl id="lastName" fullWidth variant="outlined" error={!!errors['lastName']}>
                  <InputLabel htmlFor="lastName">Last Name</InputLabel>
                  <OutlinedInput
                    id="lastName"
                    value={model.lastName}
                    onChange={handleChange('lastName')}
                  />
                  {!!errors['lastName'] &&
                    <FormHelperText>{errors['lastName'] || null}</FormHelperText>
                  }
                </FormControl>
                <FormControl id="organization" fullWidth variant="outlined" error={!!errors['organization']}>
                  <InputLabel htmlFor="organization">Organization</InputLabel>
                  <OutlinedInput
                    id="organization"
                    value={model.organization}
                    onChange={handleChange('organization')}
                  />
                  {!!errors['organization'] &&
                    <FormHelperText>{errors['organization'] || null}</FormHelperText>
                  }
                </FormControl>
                <FormControl id="email" fullWidth variant="outlined" error={!!errors['email']}>
                  <InputLabel htmlFor="email">Email</InputLabel>
                  <OutlinedInput
                    id="email"
                    value={model.email}
                    onChange={handleChange('email')}
                    type="email"
                  />
                  {!!errors['email'] &&
                    <FormHelperText>{errors['email'] || null}</FormHelperText>
                  }
                </FormControl>
                <FormControl id="password" fullWidth variant="outlined" error={!!errors['password']}>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <OutlinedPassword
                    id="password"
                    value={model.password}
                    onChange={handleChange('password')}
                  />
                  {!!errors['password'] &&
                    <FormHelperText>{errors['password'] || null}</FormHelperText>
                  }
                </FormControl>
                <FormControl id="password-confirm" fullWidth variant="outlined" error={!!errors['password_confirm']}>
                  <InputLabel htmlFor="password-confirm">Confirm Password</InputLabel>
                  <OutlinedPassword
                    id="password_confirm"
                    value={model.password_confirm}
                    onChange={handleChange('password_confirm')}
                  />
                  {!!errors['password_confirm'] &&
                    <FormHelperText>{errors['password_confirm'] || null}</FormHelperText>
                  }
                </FormControl>
                <FormControl id="agreement" fullWidth variant="outlined" error={!!errors['agreement']}>
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox checked={model.agreement}
                        onChange={handleChange('agreement', true)}
                        checkedIcon={<img alt={''} src={CheckboxCheckedIcon} />}
                        icon={<img alt={''} src={CheckboxIcon} />}
                        color="default"
                      />}
                    label={
                      <React.Fragment>
                        {'I agree to the '}
                        <Link
                          variant={'body1'}
                          href="/terms-of-use"
                          target="_blank"
                        >
                          Terms and Conditions
                        </Link>
                        {' and '}
                        <Link
                          variant={'body1'}
                          href="/privacy-policy"
                          target="_blank"
                        >
                          Privacy Policy
                        </Link>
                      </React.Fragment>
                    }
                  />
                  {!!errors['agreement'] &&
                    <FormHelperText>{errors['agreement'] || null}</FormHelperText>
                  }
                </FormControl>
                <Box display={'flex'} justifyContent={{ xs: 'center', md: 'flex-end' }} mt={5} sm={6}>
                  <Box display={'flex'}>
                    <Button onClick={onRegister} variant="contained" size="large" type="submit">REGISTER</Button>
                  </Box>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Grid>

      </SidesLayoutRight>
    </SidesLayout>
  );
}
import React, { useState } from 'react';
import { SidesLayout, SidesLayoutRight } from '@components/layout/sides-layout';
import {
  Box,
  Link,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  Grid, Button, FormHelperText, FormControlLabel, Collapse
} from '@material-ui/core';

import history from '@app/history';

import auth from '@app/auth';
import OutlinedPassword from '@components/inputs/outlined-password';
import CheckboxLight from '@components/inputs/checkbox-light';
import * as yup from 'yup';
import parseYupErrors from '@app/yup';
import { InfoBrownOtherIcon } from '@app/icons';

import { useLocation } from 'react-router-dom';
import useErrorState from '@utils/errorState';
import EnterOtp from '@components/complex/enter-otp';


export default function Login(props) {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const searchId = search.get('searchId');
  const profileId = search.get('profileId');
  const searchName = search.get('searchName');

  const [model, setModel] = useState({
    email: '',
    password: ''
  });
  const [cognitoUser, setCognitoUser] = useState(undefined);
  const [askOtp, setAskOtp] = useState(false);
  const [code, setCode] = useState('');
  const [rememberDevice, setRememberDevice] = useState(false);
  const [errors, setErrors] = useErrorState({});

  const handleChange = (prop) => (event) => {
    const newModel = { ...model, [prop]: event.target.value };
    setModel(newModel);

    if (errors[prop]) {
      schema.validateAt(prop, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [prop]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [prop]: err.message });
        });
    }
  };

  // default redirect will be handled by PublicRoute
  const redirect = () => {
    if (profileId) {
      return history.push(`background-check/user-note-list?searchId=${searchId}&profileId=${profileId}&searchName=${searchName}`)
    }

    if (searchId) {
      return history.push(`background-check/results?searchId=${searchId}&searchName=${searchName}`)
    }
  }

  // mfa is required, save the cognitoUser to use later for verifying otp
  const onRequireMfa = (cogUser) => {
    setCognitoUser(cogUser);
    setAskOtp(true);
  }

  const onLogin = () => {
    setErrors({});

    schema
      .validate(model, { abortEarly: false })
      .then(function (valid) {
        auth.login(
          model.email.toLowerCase().trim(),
          model.password,
        ).then(({ cognitoUser }) => {
          if (cognitoUser) {
            return onRequireMfa(cognitoUser)
          }
          redirect();
        }).catch(err => {
          console.log({ err })
          setErrors({
            password: Object.values(err.errors).shift()
          }, true)
        })
      })
      .catch(function (err) {
        setErrors(parseYupErrors(err), true);
      });
  }

  const handleVerifyOtp = () => {
    auth.verifyOtp(cognitoUser, code, rememberDevice)
      .then(() => {
        redirect();
      }).catch(err => {
        setErrors({
          code: Object.values(err.errors).shift()
        })
      })
  }

  const schema = yup.object().shape({
    email: yup.string().lowercase().trim().email('Invalid email format').required(),
    password: yup.string().required()
  });

  const onResend = function () {
    const email = model.email;

    auth.resendActivationEmail(email).then(() => {
      history.push({
        pathname: '/instructions-sent',
        search: `?user=${btoa(email)}`
      })
    })
  }

  return (
    <React.Fragment>
      <SidesLayout>
        <SidesLayoutRight
          header={
            <Typography>
              <React.Fragment>
                                Not registered?&ensp;
                <Link
                  variant={'body1'}
                  onClick={() => {
                    history.push('/signup')
                  }}
                >
                                    Sign Up
                </Link>
              </React.Fragment>
            </Typography>
          }>

          {askOtp ?
            <Grid container justify={'center'} alignItems={'center'}>
              <Grid item style={{ width: 400 }}>
                <Grid item xs={12}>
                  <Box>
                    <Typography variant="h1" align={'center'}>Multi-Factor</Typography>
                    <Typography variant="h1" align={'center'}>Authentication</Typography>
                  </Box>
                  <Box mt={3}>
                    <Typography variant="h3" align="center">We have sent the password to your registered phone number</Typography>
                  </Box>
                  <Box mt={3} mb={3}>
                    <Typography variant="subtitle1" align="center">Enter the one-time password (OTP) you have received in SMS</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <EnterOtp
                    loaded={askOtp}
                    errorMsg={errors.code}
                    onResend={onLogin}
                    code={code}
                    setCode={setCode}
                    rememberDevice={
                      <Box>
                        <FormControlLabel
                          labelPlacement="end"
                          label="Trust this device"
                          control={
                            <CheckboxLight />
                          }
                          onChange={() => setRememberDevice(!rememberDevice)}
                          value={rememberDevice}
                          checked={rememberDevice}
                        />
                        <Collapse in={rememberDevice}>
                          <Box display="flex">
                            <Box>
                              <Box pl="3px" pr="10px" width={20}>
                                <InfoBrownOtherIcon />
                              </Box>
                            </Box>
                            <Box>
                              <Typography style={{ color: '#7A5B44' }}>If it is your first time registering this device, the next time you log in, we will again ask you to use SMS verification to authenticate this device. From that point on, your device will be registered as safe, and you will not be asked to use OTP when logging in from this device.</Typography>
                            </Box>
                          </Box>
                        </Collapse>
                      </Box>
                    }
                  />
                </Grid>
                <Grid item xs={12} container justify="center">
                  <Box mt={5}>
                    <Button onClick={handleVerifyOtp} variant="contained" size="large">CONTINUE</Button>
                  </Box>
                  <Box px={3} mt={4} mb={3}>
                    <Typography align={'center'}>
                      {'If you do not have access to your phone number anymore please send an email to '}
                      <Link
                        variant={'body1'}
                        target="_blank"
                        href="mailto:support@kycpassport.com"
                      >
                                                support@kycpassport.com
                      </Link>
                                            .
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            :
            <Grid container justify={'center'} alignItems={'center'}>
              <Grid item xs={10} md={8} lg={6}>
                <Grid item xs={12}>
                  <Box mb={5}>
                    <Typography variant={'h1'} align={'center'}>Login</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <FormControl id="email" fullWidth variant="outlined" error={!!errors['email'] || !!errors['password']}>
                      <InputLabel htmlFor="email">Email</InputLabel>
                      <OutlinedInput
                        id="email"
                        value={model.email}
                        onChange={handleChange('email')}
                        type="email"
                      />
                      {!!errors['email'] &&
                                                <FormHelperText>{errors['email'] || null}</FormHelperText>
                      }
                    </FormControl>
                    <FormControl id="password" fullWidth variant="outlined" error={!!errors['password']}>
                      <InputLabel htmlFor="password">Password</InputLabel>
                      <OutlinedPassword
                        value={model.password}
                        onChange={handleChange('password')}
                        error={!!errors['password']}
                      />
                      {!!errors['password'] &&
                                                <FormHelperText>
                                                  {errors['password'] === 'User is not confirmed.' ?
                                                    <span>
                                                            User is not yet confirmed. Do you want to <Link onClick={onResend}>resend</Link> the activation email?
                                                    </span>
                                                    :
                                                    errors['password']
                                                  }
                                                </FormHelperText>
                      }
                    </FormControl>
                    {!!Object.values(errors).length &&
                                            <Box display={'flex'} px={3} mt={2} mb={3}>
                                              <Typography align={'center'}>
                                                {'If you still have problems with accessing your account please send an email to '}
                                                <Link
                                                  variant={'body1'}
                                                  target="_blank"
                                                  href="mailto:support@kycpassport.com"
                                                >
                                                        support@kycpassport.com
                                                </Link>
                                                    .
                                              </Typography>
                                            </Box>
                    }

                    <Box display={{ xs: 'none', md: 'flex' }} mt={5} sm={6}>
                      <Box display={'flex'} flexGrow={1} alignItems={'center'}>
                        <Link
                          variant={'body1'}
                          onClick={() => {
                            history.push('/forgot-password')
                          }}>Forgot your password?</Link>
                      </Box>
                      <Box display={'flex'}>
                        <Button onClick={onLogin} type="submit" variant="contained" size="large">Login</Button>
                      </Box>
                    </Box>

                    <Box display={{ xs: 'flex', md: 'none' }} mt={5} sm={6} flexDirection={'column'}>
                      <Box display={'flex'} flexGrow={1} justifyContent={'center'}>
                        <Button onClick={onLogin} variant="contained" size="large">Login</Button>
                      </Box>
                      <Box mt={4} display={'flex'} flexGrow={1} alignItems={'center'} justifyContent={'center'}>
                        <Link
                          variant={'body1'}
                          onClick={() => {
                            history.push('/forgot-password')
                          }}>Forgot your password?</Link>
                      </Box>
                    </Box>
                  </form>
                </Grid>
              </Grid>
            </Grid>
          }
        </SidesLayoutRight>
      </SidesLayout>
    </React.Fragment>
  );
}
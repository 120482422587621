import client from './client'
import admin from './admin'

const resolveMenu = (side, userIsAdmin, debug) =>
  (side === 'admin')
    ? admin()
    : userIsAdmin
      ? admin(userIsAdmin)
      : client(debug)

export default resolveMenu
import React from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';


const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    border: '1px solid #EDEDED !important',
    boxSizing: 'border-box',
    borderRadius: '12px',
    width: '60px',
    height: '30px',
    '& .MuiButton-label': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '18px',
      color: theme.palette.text.BL1,
      textTransform: 'capitalize',
    },
  },
  noActive: {
    backgroundColor: theme.palette.background.GY1,
    '& .MuiButton-label': {
      color: theme.palette.text.GY4,
    }
  }
}));

export default function ViewToggleWithLabel({ labelFirst, labelSecond, active, onChange }) {
  const classes = useStyles();

  return (
    <ButtonGroup disableElevation>
      <Button
        className={clsx(classes.buttonRoot, active !== labelFirst && classes.noActive)}
        onClick={() => onChange(labelFirst)}
        disabled={active === labelFirst}
      >
        {labelFirst}
      </Button>
      <Button
        className={clsx(classes.buttonRoot, active !== labelSecond && classes.noActive)}
        onClick={() => onChange(labelSecond)}
        disabled={active === labelSecond}
      >
        {labelSecond}
      </Button>
    </ButtonGroup>
  )
}
import React from 'react';
import { Box, Typography, Button, Grid, FormControlLabel, RadioGroup, Radio, FormControl, MenuItem, makeStyles } from '@material-ui/core';
import FilterWithContext from '@components/dropdowns/filter-with-context';
import SelectClearable from '@components/inputs/select-clearable';
import OutlinedSearch from '@components/inputs/outlined-search';

import { RADIO_GROUP, VIEW_BY } from '../constants';
import { capitalize } from '@utils/textUtil';

export const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: '16px'
  },
  reset: {
    marginRight: '10px',
    '&.MuiButton-sizeSmall': {
      padding: '2px 5px',
    }
  },
  advancedSelect: {
    padding: '10px',
    width: '90px',
    '&.MuiInputBase-input': {
      fontSize: '12px'
    }
  },
}));

export default function DocumentsHeader(props) {
  const classes = useStyles();
  const {
    viewBy, setViewBy,
    search, setSearch,
    filter, setFilter,
    setPage,
    totalCount
  } = props;

  const changeViewBy = (event) => {
    setViewBy(event.target.value);
    setPage(0);
  }

  const handleInputChange = (event) => {
    setSearch(event.target.value);
    setPage(0);
  }

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setPage(0);
  }

  const handleReset = () => {
    setFilter('all');
    setPage(0);
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.header} mt={3} width={'100%'}>
      <Box display="flex" alignItems="center" mr={3}>
        <Typography variant="h5">VIEW BY</Typography>
        <Box ml={'12px'}>
          <FormControl variant="outlined">
            <SelectClearable
              value={viewBy}
              onChange={changeViewBy}
              classes={{ select: classes.advancedSelect }}
              clearable={false}
              noFocus={true}
            >
              {VIEW_BY.map(el => (
                <MenuItem key={el.id} value={el.id}>
                  {`${el.name} (${totalCount[el.id]})`}
                </MenuItem>
              ))}
            </SelectClearable>
          </FormControl>
        </Box>
      </Box>

      <Box display="flex" alignItems="center" height={'40px'}>
        <Button size="small" className={classes.reset} onClick={handleReset}>
          Reset filter
        </Button>
        <FilterWithContext label="FILTER" filterActive={filter !== 'all' && classes.filterActive} closeTrigger={filter}>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <RadioGroup name="filter" value={filter} onChange={handleFilterChange}>
                {RADIO_GROUP.map((el, i) => (
                  <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value} - ${i}`}>
                    <FormControlLabel
                      control={<Radio />}
                      value={el.value}
                      label={el.label}
                    />
                  </Box>
                ))}
              </RadioGroup>
            </Grid>
          </Grid>
        </FilterWithContext>
        <Box ml={4} width={'300px'}>
          <OutlinedSearch
            value={search}
            onChange={handleInputChange}
            label={`Search Document ${capitalize(viewBy)}`}
          />
        </Box>
      </Box>
    </Box>
  )
}
const config = {
  MuiPaginationItem: {
    page: {
      color: '#A4A4A4',
      '&$selected': {
        color: '#000',
        backgroundColor: 'transparent'
      },
      '&$disabled': {
        display: 'none'
      }
    }
  }
}

export default config;
import { Box, Card, FormControlLabel, Switch, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { notifications } from '@app/api/client';
import debounce from 'awesome-debounce-promise';
import { useSnackbar } from 'notistack';

// Not being used for now
// eslint-disable-next-line
function GroupedSetting({ groupName, items }) {
  <Box mb={2}>
    <Card>
      <Box p={3} style={{ borderBottom: '2px solid #EFEFEF' }}>
        <Typography variant="h5" style={{ fontSize: '16px' }}>{groupName}</Typography>
      </Box>
      <Box px={4}>
        <Box py={2} display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h5">DOCUMENTS</Typography>
          </Box>
          <Box>
            <Typography variant="h5">SEND EMAIL</Typography>
          </Box>
        </Box>
        {items.map(item => (
          <ItemSetting
            name={item.name}
            checked
            onClick={() => { }}
          />
        ))}
      </Box>
    </Card>
  </Box>
}

function ItemSetting({ name, checked, onClick }) {
  return (
    <Box
      mb={2} mx={-2} px={2}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderRadius="8px"
      style={{ backgroundColor: '#F8F8F8' }}
    >
      <Box>
        <Typography variant="h5" style={{ fontSize: '12px' }}>{name}</Typography>
      </Box>
      <Box>
        <FormControlLabel
          control={<Switch checked={checked} onClick={onClick} />}
          label={checked ? 'Enabled' : 'Disabled'}
        />
      </Box>
    </Box>
  )
}

function GroupSetting({ name, checked, handleChange }) {
  // For some very weird reason clicking on the form label triggers onClick twice
  const onChange = (e) => {
    e.stopPropagation();
    e.preventDefault();
    
    handleChange();
  }

  return (
    <Box mb={2}>
      <Card onClick={handleChange}>
        <Box p={3} display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h5" style={{ fontSize: '16px' }}>{name}</Typography>
          </Box>
          <Box my={-1} pr={2} pl={3} display="flex" justifyContent="flex-end" style={{ borderLeft: '2px solid #EFEFEF' }}>
            <FormControlLabel
              onClick={onChange}
              control={<Switch checked={checked} />}
              label={<Box width={48}>{checked ? 'Enabled' : 'Disabled'}</Box>}
            />
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

const settingsLabelMap = {
  ANNUAL_REVIEW: 'Annual review related emails',
  DOCUMENTS: 'Documents related emails',
  eIDV: 'eIDV related emails',
  ONGOING_MONITORING: 'Ongoing monitoring related emails',
  SUBSCRIPTIONS: 'Subscriptions related emails',
}

const debouncedUpdate = debounce(notifications.update, 500);

export default function EmailsSettings() {
  const { enqueueSnackbar } = useSnackbar();
  const [settings, setSettings] = useState({})

  useEffect(() => {
    notifications.index().then((res) => {
      setSettings(res.data.configMap || {})
    })
  }, [])

  const handleChange = (name, enabled) => {
    const newSettings = {
      ...settings,
      [name]: { on: enabled }
    }

    setSettings(newSettings)

    debouncedUpdate({ configMap: newSettings }).then(() => {
      enqueueSnackbar('Email notification settings successfully updated!', { variant: 'success' });
    })
  }

  return (
    <Box width="100%">
      {Object.keys(settingsLabelMap).map(e => (
        <GroupSetting key={e} name={settingsLabelMap[e]} checked={!!settings[e]?.on} handleChange={() => handleChange(e, !settings[e]?.on)} />
      ))}
    </Box>
  )
}
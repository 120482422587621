import React from  'react';
import PageLayout from '@components/layout/page-layout';
import history from '@app/history';
import { Route, Switch } from 'react-router';
import ChooseClients from '@views/protected/client/ongoing-monitoring/add-to-monitoring/step-1-choose-clients';
import SearchSettings from '@views/protected/client/ongoing-monitoring/add-to-monitoring/step-2-search-settings';
import OngoingMonitoringSettings from '@views/protected/client/ongoing-monitoring/add-to-monitoring/step-3-monitoring-settings';
import ImportChooseClients from '@views/protected/client/ongoing-monitoring/add-to-monitoring/step-1-import-csv-choose-clients';
import UpdateExisting from '@views/protected/client/background-check/subpages/update-existing';
import UpdateExistingConfirm from '@views/protected/client/background-check/subpages/update-existing-confirm';
import UpdateExistingMonitoring from '@views/protected/client/background-check/subpages/update-existing-monitoring';
import OngoingMonitoring from '@views/protected/client/ongoing-monitoring/index';
import OngoingSearchHistory from '@views/protected/client/ongoing-monitoring/search-history';
import MonitoringBgCheck from './monitoring-bg-check';
import NewSearch from '../background-check/subpages/new-search';
import MonitoringUserNoteList from './monitoring-bg-user-note-list';
import RepeatSearch from '../background-check/subpages/repeat-search';
import LoggedNotFound from '@views/protected/not-found';

export default function OngoingMonitoringRoute(props) {

  return (
    <PageLayout>
      <Switch>
        {/* Bulk add monitoring steps */}
        <Route path="/ongoing-monitoring/add-monitoring/search-settings" exact>
          <SearchSettings
            steps={{ currentStep: 2, stepsCount: 3 }}
            onNext={(params) =>
              history.push('/ongoing-monitoring/add-monitoring/monitoring-settings')}
            onRedirect={(params) =>
              history.push('/ongoing-monitoring/add-monitoring')}
          />
        </Route>
        <Route path="/ongoing-monitoring/add-monitoring/monitoring-settings" exact>
          <OngoingMonitoringSettings
            steps={{ currentStep: 3, stepsCount: 3 }}
            onRedirect={(params) =>
              history.push('/ongoing-monitoring/add-monitoring')}
          />
        </Route>
        <Route path="/ongoing-monitoring/add-monitoring/:tabId?" exact>
          <ChooseClients
            steps={{ currentStep: 1, stepsCount: 3 }}
            onNext={(params) =>
              history.push(`/ongoing-monitoring/add-monitoring-confirm/${params.type}/${params.clientId}`)}
            onNext2={(params) =>
              history.push('/ongoing-monitoring/add-monitoring/search-settings')}
            onChangeTab={(tab) =>
              history.replace(`/ongoing-monitoring/add-monitoring/${tab}`)}
          />
        </Route>

        {/* Import csv bulk monitoring flow */}
        <Route path="/ongoing-monitoring/import-add-monitoring" exact>
          <ImportChooseClients
            steps={{ currentStep: 1, stepsCount: 3 }}
            onNext={(params) =>
              history.push('/ongoing-monitoring/import-add-monitoring/search-settings')}
            onRedirect={(params) =>
              history.push('/ongoing-monitoring/add-monitoring')}
          />
        </Route>
        <Route path="/ongoing-monitoring/import-add-monitoring/search-settings" exact>
          <SearchSettings
            steps={{ currentStep: 2, stepsCount: 3 }}
            onNext={(params) =>
              history.push('/ongoing-monitoring/import-add-monitoring/monitoring-settings')}
            onRedirect={(params) =>
              history.push('/ongoing-monitoring/add-monitoring')}
            isImport
          />
        </Route>
        <Route path="/ongoing-monitoring/import-add-monitoring/monitoring-settings" exact>
          <OngoingMonitoringSettings
            steps={{ currentStep: 3, stepsCount: 3 }}
            onRedirect={(params) =>
              history.push('/ongoing-monitoring/add-monitoring')}
            isImport
          />
        </Route>

        {/* Add to ongoing monitoring steps */}
        <Route path="/ongoing-monitoring/add-monitoring/:type" exact>
          <UpdateExisting
            flow="ongoing-monitoring"
            steps={{ currentStep: 1, stepsCount: 3 }}
            onNext={(params) => {
              const link = `/ongoing-monitoring/add-monitoring-confirm/${params.type}/${params.clientId}`;
              params.isReplace
                ? history.replace(link)
                : history.push(link)
            }}
          />
        </Route>
        <Route path="/ongoing-monitoring/add-monitoring-confirm/:type/:clientId/:tab?" exact>
          <UpdateExistingConfirm
            flow="ongoing-monitoring"
            getBaseUri={(params) => 
            `/ongoing-monitoring/add-monitoring-confirm/${params.type}/${params.clientId}`}
            steps={{ currentStep: 2 }}
            onNext={(params) =>
              history.push(`/ongoing-monitoring/add-monitoring-finalize/${params.searchId}/${params.clientId}`)}
            onNextAlt={(params) =>
              history.push(`/ongoing-monitoring/add-monitoring-bg-check/${params.clientId}`)}
          />
        </Route>
        <Route path="/ongoing-monitoring/add-monitoring-finalize/:searchId/:clientId" exact>
          <UpdateExistingMonitoring
            flow="ongoing-monitoring"
            steps={{ currentStep: 3, stepsCount: 3 }}
            onNext={(params) =>
              history.push('/ongoing-monitoring')}
          />
        </Route>

        {/* Add to ongoing monitoring without bg check steps */}
        <Route path="/ongoing-monitoring/add-monitoring-bg-check/:clientId" exact>
          <MonitoringBgCheck
            steps={{ currentStep: 3, stepsCount: 6 }}
            onNext={(params) =>
              history.push(`/ongoing-monitoring/add-monitoring-new-search/${params.clientId}?searchId=${params.searchId}&searchName=${params.searchName}&searchType=${params.searchType}`)}
          />
        </Route>
        <Route path="/ongoing-monitoring/add-monitoring-new-search/:clientId" exact>
          <NewSearch
            flow="ongoing-monitoring"
            steps={{ currentStep: 4, stepsCount: 6 }}
            onNext={(params) =>
              history.push(`/ongoing-monitoring/add-monitoring-user-note-list/${params.clientId}?searchId=${params.searchId}&profileId=${params.profileId}&searchName=${params.searchName}`)
            }
            onNextAlt={(params) =>
              history.replace(`/ongoing-monitoring/add-monitoring-no-hits/${params.clientId}?searchId=${params.searchId}&searchName=${params.searchName}&searchType=${params.searchType}`)
            }
          />
        </Route>
        <Route path="/ongoing-monitoring/add-monitoring-user-note-list/:clientId" exact>
          <MonitoringUserNoteList
            baseRoute="ongoing-monitoring"
            steps={{ currentStep: 5, stepsCount: 6 }}
            onNext={(params) =>
              history.push(`/ongoing-monitoring/add-monitoring-finalize-bg/${params.searchId}/${params.clientId}`)
            }
          />
        </Route>
        <Route path="/ongoing-monitoring/add-monitoring-finalize-bg/:searchId/:clientId" exact>
          <UpdateExistingMonitoring
            flow="ongoing-monitoring"
            steps={{ currentStep: 6, stepsCount: 6 }}
            onNext={(params) =>
              history.push('/ongoing-monitoring')}
          />
        </Route>

        {/* Add to ongoing monitoring no search results */}
        <Route path="/ongoing-monitoring/add-monitoring-no-hits/:clientId" exact>
          <RepeatSearch
            steps={{ currentStep: 4, stepsCount: 5 }}
            onNext={(params) =>
              history.push(`/ongoing-monitoring/add-monitoring-finalize-no-hits/${params.searchId}/${params.clientId}`)
            }
            onNextAlt={(params) =>
              history.push(`/ongoing-monitoring/add-monitoring-bg-check/${params.clientId}`)
            }
            onRedirect={(params) =>
              history.push(`/ongoing-monitoring/add-monitoring-no-hits/${params.clientId}?searchId=${params.searchId}&searchName=${params.searchName}&searchType=${params.searchType}`)
            }
            onWhitelisted={(params) =>
              history.push(`/ongoing-monitoring/add-monitoring-user-note-list/${params.clientId}?searchId=${params.searchId}&searchName=${params.searchName}&searchType=${params.searchType}`)
            }
          />
        </Route>
        <Route path="/ongoing-monitoring/add-monitoring-finalize-no-hits/:searchId/:clientId" exact>
          <UpdateExistingMonitoring
            flow="ongoing-monitoring"
            steps={{ currentStep: 5, stepsCount: 5 }}
            onNext={(params) =>
              history.push('/ongoing-monitoring')}
          />
        </Route>

        <Route path="/ongoing-monitoring/update-existing-monitoring/:searchId/:clientId" exact>
          <UpdateExistingMonitoring
            flow="ongoing-monitoring"
            steps={{ currentStep: 1, stepsCount: 1 }}
            onNext={(params) =>
              history.push(`/ongoing-monitoring/history/${params.clientId}`)}
          />
        </Route>

        <Route path="/ongoing-monitoring/history/:clientId" exact>
          <OngoingSearchHistory baseRoute="ongoing-monitoring" />
        </Route>
        <Route path="/ongoing-monitoring/:tab?" exact>
          <OngoingMonitoring />
        </Route>
        <Route path="*" exact>
          <LoggedNotFound />
        </Route>
      </Switch>
    </PageLayout>
  )
};

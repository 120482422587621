import { sanctionsList } from '@services/sanctions-lists';

export const handleCardTitle = (title, isAdvantage, listType) => {
  if (listType === 'AML') return title;

  if (isAdvantage) return title.replace(/-/gi, ' ');

  if(title.includes('dataset')) {
    return title.slice(4).replace(/_/gi, ' ').replace(/dataset/gi, '');
  } else {
    return title.slice(4).replace(/_/gi, ' ');
  }
}

export const handleCardDescription = (title) => {
  if (title.includes('dataset')) {
    return sanctionsList[title.replace(/dataset_/gi, '').toUpperCase()]
  } else {
    return sanctionsList[title.toUpperCase()]
  }
}
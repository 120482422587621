import React from  'react';
import CustomButton from '@views/common/button';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  customButtonWidth: {
    width: '230px',
  },
}))

export default function SecondStepBGCheck({ updateExisting, selectedProfile, profileAction }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CustomButton
        action={() => updateExisting()}
        label="ATTACH TO EXISTING"
        customClasses="customButtonBordered"
        propsClassses={classes.customButtonWidth}
      />
      <CustomButton
        action={profileAction}
        label={selectedProfile.clientId ? 'VIEW IN PORTFOLIO' : 'SAVE IN PORTFOLIO'}
        customClasses="customButtonStandard"
        propsClassses={classes.customButtonWidth}
      />
    </React.Fragment>
  )}
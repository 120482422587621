import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  divider: {
    borderBottom: '2px solid #EFEFEF',
  },
  rootWrapper: {
    // padding: '30px 25px 30px 35px'
  },
  backButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '30px',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '30px',
    color: theme.palette.text.LB3
  },
  backButtonRoot: {
    height: '35px',
  },
  wrapper: {
    display: 'flex',
    marginTop: '15%',
  },
  titleRooot: {
    background: 'linear-gradient(100.63deg, #7A5B44 58.63%, rgba(173, 142, 131, 0.92) 143.04%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  search: {
    borderRadius: '12px',
    margin: '29px 0 15px',
    maxWidth: '695px',
  },
  creditSelect: {
    '&.MuiSelect-outlined': {
      paddingTop: '7px',
    },
    fontSize: '12px',
    lineHeight: '20px',
    color: '#828282',
  },
  searchIconRoot: {
    height: '16px',
    width: '16px',
  },
  searchIcon: {
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '28px',
    width: '28px',
    color: 'transparent',
  },
  addText: {
    whiteSpace: 'nowrap',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '20px',
    textAlign: 'right',
    color: '#828282',
    marginRight: '5px',
  },
  addIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '2px',
  },
  inputRoot: {
    color: 'inerhit',
    width: '95%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '95%',
    fontSize: '16px',
    lineHeight: 20,
    fontWeight: 500,
    '&::placeholder': {
      color: '#656565',
      opacity: 1,
      fontWeight: 500,
    }
  },
  personWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputPersonWrapper: {
    margin: '5px 0',
  },
  baseAddIconSettings: {
    color: 'transparent',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingLeft: '15px',
    width: '495px',
    alignItems: 'center'
  },
  searchButton: {
    background: 'linear-gradient(270deg, #9A7C6A 0%, #7A5B44 100%)',
    borderRadius: '12px',
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 700,
  },
  switchActions: {
    marginLeft: 50,
  },
  cardRoot: props => ({
    zIndex: 99,
    padding: '15px 15px 5px 15px',
    marginTop: props.sanctions ? 10 : 25,
    width: 490,
    background: theme.palette.common.white,
    boxShadow: '0px 9px 26px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    borderRadius: '12px',
    position: 'relative',
    overflow: props.sanctions ? 'inherit' : 'hidden',
    '&:after': {
      content: '""',
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: '0 6px 6px 6px',
      borderColor: 'white transparent',
      display: 'block',
      width: 0,
      zIndex: 1,
      top: -6,
      left: props.sanctions ? '58%' : '45%',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: '0 6px 6px 6px',
      borderColor: '#7F7F7F transparent',
      display: 'block',
      width: 0,
      zIndex: 1,
      top: -6,
      left: props.sanctions ? '58%' : '45%',
    },
  }),
  cardContent: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  starWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  customHR: {
    margin: '10px 0 20px 0',
    width: '100%',
    height: '1px',
    background: '#7F7F7F',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: '0 6px 6px 6px',
      borderColor: 'white transparent',
      display: 'block',
      width: 0,
      zIndex: 1,
      top: '-3px',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: '0 6px 6px 6px',
      borderColor: '#7F7F7F transparent',
      display: 'block',
      width: 0,
      zIndex: 1,
      top: '-5px',
    },
  },
  hrPerson: {
    '&:after': {
      left: '9%',
    },
    '&:before': {
      left: '9%',
    },
  },
  hrCompany: {
    '&:after': {
      left: '28%',
    },
    '&:before': {
      left: '28%',
    },
  },
  repeatWrapper: {
    marginTop: '50px',
  },
  repeatTitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '28px',
    color: theme.palette.text.BL1,
    margin: '15px 0 3px 0',
  },
  repeatText: {
    color: theme.palette.text.LB3,
    margin: '10px 0'
  },
  userNoteListWrapperRoot: {
    marginLeft: '50px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  userNoteListWrapper: {
    height: '100%',
    marginRight: '35px',
  },
  repeatButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '25px 0',
    height: '120px',
  },
  marginGreed: {
    margin: '5px 0',
  },
  wrapperFilters: {
    margin: '5px 15px 10px 0',
  },
  popoverVertical: {
    display: 'flex',
    flexDirection: 'column',
  },
  rootLabelChip: {
    height: '20px',
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.background.GY2}`,
    fontWeight: 'bold',
    fontSize: '8px',
    color: theme.palette.text.BL1,
  },
  checkboxTitle: {
    color: theme.palette.text.LB3,
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
  },
  downIconRoot: {
    fontSize: '16px',
  },
  tabs: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '25px',
    fontFamily: 'Roboto',
    color: theme.palette.text.BL1,
  },
  iconChipRoot: {
    color: theme.palette.text.BL1,
    height: '10px',
    width: '10px',
  },
  iconButtonMarginRight: {
    marginRight: '5px',
  },
  userInfoButtonWrapper: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  userInfoButton: {
    marginBottom: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  spacesUserInfoButton: {
    marginBottom: '21px',
  },
  wrapperCredits: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  scrollCard: {
    overflowY: 'auto',
    padding: '0 2px',
  },
  stepWrapperRoot: {
    width: '100%',
    margin: '35px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonNotStep: {
    color: theme.palette.text.LB3,
    '&:hover': {
      backgroundColor: 'inherit',
    }
  },
  stepLink: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '22px',
    color: theme.palette.text.LB3,
    marginLeft: '3px',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  customButtonModalWidth: {
    width: '180px',
  },
  propsMenuItemClasses: {
    padding: 0,
    margin: 0,
  },
  '@keyframes spinner': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  exportReportButton: {
    margin: '30px 25px 0 0',
  },
  monitoringButton: {
    marginTop: '30px',
  },
  subpageLayoutRoot: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  notFoundText: {
    color: theme.palette.text.GY4
  },
  modalButtonWidth: {
    width: '220px',
  },
  propsLayoutRoot: {
    maxHeight: 'calc(100% - 80px)',
    height: 'auto',
  },
  propsLayoutRootMonitoring: {
    maxHeight: 'calc(100% - 140px)',
    height: 'auto',
  },
  displayNone: {
    display: 'none',
  },
  customBackButtonClasses: {
    margin: '30px 0 0 37px !important',
  },
  customExportButtonClasses: {
    margin: '12px 342px 0 0 !important',
  },
  rerunData: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '22px',
    color: theme.palette.text.LB3,
  },
  searchModalButton: {
    width: '205px',
  },
  lastRunOnText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '20px',
    color: theme.palette.text.GY4,
  },
  customButtonSize: {
    width: '161px',
    height: '30px',
    marginLeft: '15px',
    '& .MuiButton-label': {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '13px',
      lineHeight: '28px',
    }
  },
  repeatContentWidth: props => ({
    width: props.isShowRightCard ? 'calc(100% - 306px)' : '',
  }),
  userNameText: {
    marginLeft: '5px',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '30px',
    color: theme.palette.text.BL1,
    marginRight: '15px'
  },
  tagsLine: {
    '& .MuiChip-root': {
      marginRight: 5,
    }
  },
  profilesText: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '30px',
    color: theme.palette.text.GY5,
  },
  showWhitelistedRoot: {
    margin: '0 5px 0 12px',
    paddingLeft: '15px',
    height: '32px',
    borderRadius: '12px',
  },
  showWhitelistedLabel: {
    color: theme.palette.text.LB3,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '20px',
  },
  rootControlLabel: {
    marginRight: 0,
  },
  advancedSelect: {
    padding: '10px',
    '&.MuiInputBase-input': {
      fontSize: '12px'
    }
  },
  advancedInput: {
    padding: '12px',
    '&.MuiInputBase-input': {
      fontSize: '12px'
    }
  },
  inputLabel: {
    fontSize: '12px',
    top: '-5px',
    left: '-8px',
    opacity: 0.7
  },
  linkified: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.palette.text.LB3,
    cursor: 'pointer'
  },
  errorWrapper: {
    height: '40px',
    backgroundColor: '#F8EEEE',
    borderRadius: '12px',
    padding: '15px'
  },
  errorText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#232323',
    marginLeft: '5px'
  },
  switchDisabled: {
    '& .Mui-checked': {
      '& .MuiSwitch-thumb': {
        backgroundColor: '#fff',
        border: '1px solid #C6C6C6',
      },
    },
    '& .MuiSwitch-colorSecondary': {
      '&.Mui-checked + .MuiSwitch-track': {
        background: '#000',
        opacity: 0.12,
      }
    }
  }
}))
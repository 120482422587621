import React, {useCallback} from 'react';
import {Card, CardContent, Box, Typography} from '@material-ui/core';
import history from '@app/history';

export default function EntryPointCard({icon, label, value, mode}) {

  const navigateDashboard = useCallback(() => {
    const tab = mode === 'jurisdiction'? 'entity-by-jurisdiction': 'entity-by-type'
    history.push(`/settings/${tab}/dashboard/${value}`)
  }, [mode, value])

  return <Card style={{
    width: 160,
    minHeight: 160
  }}>
    <CardContent style={{height: '100%', boxSizing: 'border-box'}}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        height={'100%'}
        onClick={navigateDashboard}
      >
        <Box display={'flex'} justifyContent={'center'}>
          { icon }
        </Box>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
          <Typography variant={'subtitle1'}>
            { label }
          </Typography>
        </Box>
      </Box>
    </CardContent>
  </Card>
}
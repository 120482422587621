import prepareTimestamp from '@utils/date';
import { arrayToString } from '../textUtil';

const types = {
  'PEP': 'PEP',
  'SANCTIONS': 'S',
  'sanction': 'S',
  'adverse-media-general': 'AM',
  'adverse-media': 'AM',
  'pep-class-1': 'PEP',
  'pep-class-2': 'PEP',
  'pep-class-3': 'PEP',
  'pep-class-4': 'PEP',
  'fitness-probity': 'FP',
  'warning': 'W',
};

const noFields = ['media', 'keywords', 'sources', 'source_notes', 'id', 'entity_type', 'types', 'fields', 'assets'];

export const prepareProfilesFetchData = (data, profilesVersionId) => {
  return data.map(el =>
    ({
      ...el,
      acknowledged: el.acknowledged,
      versionState: (!!el.acknowledged && el.versionState !== 'DELETED' && !profilesVersionId) ? 'UNCHANGED' : el.versionState,
      name: el.meta.name,
      isChecked: false,
      meta: cardPrepareFields({ ...el.meta, type: el.type, isWhitelisted: el.isWhitelisted }),
      media: el.meta?.media || [],
      assets: el.meta?.assets || [],
      entityType: el.meta?.entity_type || null,
    })
  );
}

export const profilesPrepareDataAdvantage = (data, profilesVersionId, searchProvider) => {
  return data.map(el => {
    const meta = cardPrepareFields({ ...el.meta, type: el.type, isWhitelisted: el.isWhitelisted }, searchProvider);

    return ({
      ...el,
      acknowledged: el.acknowledged,
      listHits: advantageParseTypes(el.meta.types),
      versionState: (!!el.acknowledged && el.versionState !== 'DELETED' && !profilesVersionId) ? 'UNCHANGED' : el.versionState,
      name: el.meta.name,
      media: el.meta?.media || [],
      assets: el.meta?.assets || [],
      entityType: el.meta?.entity_type || null,
      isChecked: false,
      meta,
    })
  });
}

export const cardPrepareFields = (data, searchProvider) => {
  let fields = [];
  if (data.type === 'PERSON') {
    delete data.type
    delete data.registrationNumber
  } else if (data.type === 'COMPANY') {
    delete data.type
    delete data.yob
    delete data.gender
  }

  for (let key in data) {
    if (typeof data[key] === 'object' && !!data[key] && !noFields.includes(key)) {
      const item = !!searchProvider
        ? advantageParseFields(data, key)
        : defaultParseFields(data, key);

      fields.push(item);
    } else if (typeof data[key] === 'string' && key !== 'name' && !noFields.includes(key)) {
      const field = !!searchProvider ? key.replace(/_/g, ' ' ) : key;

      let value = data[key];
      if (key === 'last_updated_utc') {
        value = prepareTimestamp(data[key], 'MM/DD/YYYY hh:mm A', false)
      }

      fields.push({ field, value });
    }
  }

  // if (!!data?.associates) {
  //   fields = [ ...fields, ...parseAssociations(data?.associates || [])];
  // }

  fields.push({ field: 'WHITELIST', value: !!data.isWhitelisted ? 'On' : 'Off' });

  return fields;
}

// eslint-disable-next-line
const parseAssociations = (data) => {
  let associations = {};
  const meta = [];

  data.forEach((el => {
    if (!associations[el.association]) {
      associations = { ...associations, [el.association]: data.filter((item, i) => item.association === el.association)}
    }
  }));

  for (let association in associations) {
    const value = arrayToString(associations[association].filter((el, i) => i <= 2).map((el, i) => el.name));
    const fullValue = arrayToString(associations[association].map(el => el.name));
    const field = association;

    meta.push({ field, value, fullValue })
  }

  return meta;
}

const defaultParseFields = (data, key) => {
  let arrStr = [];
  Object.entries(data[key]).forEach(el => {
    const value = el[0];
    const procent = (el[1] * 100).toString().length > 4 ? (el[1] * 100).toFixed(2) : (el[1] * 100);
    arrStr.push(`${value} ${procent}%`);
  })
  if (key === 'registrationNumber') {
    key = 'Registration #';
  }
  return { field: key, value: arrStr.join(', ') };
}

const advantageParseTypes = (data) => {
  const buffer = [];
  data.forEach(el => {
    if (types[el] && buffer.indexOf(types[el]) === -1) {
      buffer.push(types[el])
    }
  })
  return buffer;
}

const advantageParseFields = (data, key) => {
  let field = key.replace(/_/g, ' ' );

  let value;
  let fullValue = null;
  if (key === 'aka') {
    value = arrayToString(data[key].filter((el, i) => i <= 2).map((el, i) => el.name));

    const prepareFullValue = arrayToString(data[key].filter((el, i) => i <= 20).map(el => el.name));
    fullValue = data[key].length > 20 ? `${prepareFullValue}...` : prepareFullValue;
  }

  if (key === 'types') {
    const buffer = [];
    data[key].forEach(el => {
      if (types[el] && buffer.indexOf(types[el]) === -1) {
        buffer.push(types[el])
      }
    })
    value = arrayToString(buffer);
  }

  if (key === 'associates') {
    value = arrayToString(data[key].filter((el, i) => i <= 2).map((el, i) => `${el.name} (${el.association})`));
    fullValue = arrayToString(data[key].map(el => `${el.name} (${el.association})`));
  }

  return { field, value, fullValue };
}

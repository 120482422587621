import React from 'react';

import { Modal } from '@components/modals';
import history from '@app/history';

export default function UploadSuccessDialog(props) {
  const { open, onClose, uploadCount } = props;

  const onOrganize = () => {
    onClose();
    history.push('/portfolio/organize');
  }

  const onMonitoring = () => {
    onClose();
    history.push('/ongoing-monitoring/import-add-monitoring');
  }

  const handleClose = () => {
    onClose();
    history.push('/portfolio');
  }

  return (
    <React.Fragment>
      <Modal
        open={open}
        title="Success"
        mainText="Good work!"
        additionalText={`\n${uploadCount} client(s) were successfully imported.`}
        actions={
          [
            {
              type: 'secondary',
              label: 'ORGANIZE BY FOLDER',
              action: onOrganize,
              style: { width: 250 },
            },
            {
              type: 'main',
              label: 'ADD TO MONITORING',
              action: onMonitoring,
              style: { width: 250 },
            },
            {
              label: 'FINISH',
              action: handleClose,
              style: { width: 250 },
              linkButton: true,
            },
          ]
        }
        actionsDirection="column"
      />
    </React.Fragment>
  );
}
import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, MenuItem, Typography
} from '@material-ui/core';
import ButtonBackBreadcrumb from '@components/buttons/button-back-breadcrumb';
import history from '@app/history';
import { riskRatingTemplate } from '@app/api/client';
import { useParams } from 'react-router-dom';
import CustomTooltip from '@components/controls/tooltip';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { EditIcon } from '@app/icons';
import TableList from '@components/lists/table-list';
import MenuWithContext from '@components/dropdowns/menu-with-context';
import _ from 'lodash';
import RiskRatingSettingsDeleteDialog from './dialogs/delete-dialog';
import { useSnackbar } from 'notistack';
import questionTypes from './enums/questionTypes';

const TABLE_COLUMNS = [
  { field: 'sortNumber', headerName: '#', bold: true },
  { field: 'questionText', headerName: 'Question' },
  { field: 'typeLabel', headerName: 'Answer Type' },
  { field: 'answerValue', headerName: 'Risk (Default)' },
  { field: 'answerWeight', headerName: 'Weight (Default)' },
]

export default function RiskRatingSettingsView(props) {

  const { templateId } = useParams()

  const { enqueueSnackbar } = useSnackbar()

  const [questions, setQuestions] = useState([])

  const [hasPendingDelete, setHasPendingDelete] = useState(false)

  const [templateName, setTemplateName] = useState('')

  const [templateType, setTemplateType] = useState('')

  const [sort, setSort] = useState(null) // eslint-disable-line

  const [sortDirection, setSortDirection] = useState('ASC') // eslint-disable-line

  const onBack = () => {
    history.push('/settings/risk-rating')
  }

  const onUpdate = id => {
    history.push(`/settings/risk-rating/${templateId}/update`)
  }

  useEffect(() => {
    fetch()
  }, []) // eslint-disable-line

  const onSort = column => {
    if (column === sort) {
      setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC')
    } else {
      setSort(column)
      setSortDirection('ASC')
    }
  }

  const prepareQuestion = (questions) => {
    const preparedQuestion = questions

    preparedQuestion.forEach((question, index) => {
      question.sortNumber = index + 1;

      const getValue = (name) => {
        if (question.userInput?.[name]) {
          return `Yes (${question.userInput[name]})`;
        }
    
        if (question.possibleAnswers?.length) {
          if (question.possibleAnswers.some(e => e[name] !== undefined && e[name] !== null)) {
            return 'Yes (Multiple)'
          }
        }
        return '-';
      }
    
      question.answerValue = getValue('answerValue');
      question.answerWeight = getValue('answerWeight');
    })

    return preparedQuestion
  }

  const fetch = () => {
    return riskRatingTemplate.show(templateId)
      .then(({ data }) => {
        setQuestions(prepareQuestion(data.jsonData.questions))

        setTemplateName(data.jsonData.templateName)
        setTemplateType(data.systemDefault ? 'System' : 'Custom')
      })
  }

  const sortedFormattedQuestions = useMemo(() => {
    let lines = questions.map(q => ({
      ...q,
      typeLabel: questionTypes[q.type]
    }))
    if (sort) {
      lines = _.sortBy(lines, [sort])
    }
    if (sortDirection === 'DESC') {
      lines = _.reverse(lines)
    }
    return lines.map((line, i) => ({
      ...line,
    }))
  }, [questions, sort, sortDirection])

  const onDelete = () => {
    setHasPendingDelete(true)
  }

  const onDeleteConfirm = async () => {
    await riskRatingTemplate.remove(templateId)
    enqueueSnackbar('Risk Rating template was successfully deleted!', { variant: 'success' });
    history.push('/settings/risk-rating')
  }

  const onSaveAs = () => {
    history.push(`/settings/risk-rating/${templateId}/save-as`)
  }

  return (
    <React.Fragment>
      <Box display={'flex'} flexDirection={'column'} flexGrow={1} mt={5}>
        <Box
          display="flex" alignItems="center" justifyContent="space-between"
          pb={4}
          style={{ borderBottom: '2px solid #EFEFEF' }}
        >
          <ButtonBackBreadcrumb
            goBack={onBack}
            crumbs={[
              { label: 'Settings', onClick: () => history.push('/settings') },
              { label: 'Risk Rating Templates', onClick: () => history.push('/settings/risk-rating') },
              { label: templateName },
            ]}
          />
          <MenuWithContext>
            <MenuItem onClick={onSaveAs}>Save Template As</MenuItem>
            {templateType !== 'System' &&
              <MenuItem onClick={onDelete}>Delete Template</MenuItem>
            }
          </MenuWithContext>
        </Box>
        <Box
          display={'flex'}
          px={2}
          flexDirection={'column'}
          style={{
            overflowY: 'scroll'
          }}
          maxHeight="calc(100vh - 280px)"
        >
          <Box display={'flex'} mt={3}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              flexGrow={1}
            >
              <Typography variant="h3" style={{ fontSize: '28px' }}>Template Details</Typography>
              <Box display="flex" mt={4}>
                <Box>
                  <Typography variant="body2">Template Name</Typography>
                  <Box mt="4px">
                    <Typography variant="h5" style={{ fontSize: '16px' }}>{templateName}</Typography>
                  </Box>
                </Box>
                <Box ml={12}>
                  <Typography variant="body2">Template Type</Typography>
                  <Box mt="4px">
                    <Typography variant="h5" style={{ fontSize: '16px' }}>{templateType}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            {templateType !== 'System' &&
              <Box display={'flex'}>
                <CustomTooltip
                  title="Edit"
                  placement="top"
                >
                  <Box>
                    <ButtonWithIcon startIcon={<EditIcon />} onClick={onUpdate}>Edit</ButtonWithIcon>
                  </Box>
                </CustomTooltip>
              </Box>
            }
          </Box>
          <Box display={'flex'} mt={5}>
            <Typography variant={'body2'}>
              Template Questions List
            </Typography>
          </Box>
          <Box display={'flex'} my={1}>
            <TableList
              columns={TABLE_COLUMNS}
              items={sortedFormattedQuestions}
              sortableColumns={TABLE_COLUMNS.map(el => el.field)}
              onSort={onSort}
              sort={sort}
              sortDirection={sortDirection}
            />
          </Box>
        </Box>
      </Box>
      <RiskRatingSettingsDeleteDialog
        open={hasPendingDelete}
        onClose={() => setHasPendingDelete(false)}
        onConfirm={onDeleteConfirm}
        name={templateName}
      />
    </React.Fragment>

  )
}
import React from 'react';
import parseYupErrors from '@app/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControl, FormHelperText,
  InputLabel, OutlinedInput
} from '@material-ui/core';
import * as yup from 'yup';
import _ from 'lodash';

const INIT_STATE = {
  name: ''
}

export default function SaveTemplateDialog(props) {

  const {open, onClose, onSave} = props

  const [data, setData] = React.useState(INIT_STATE)

  const [errors, setErrors] = React.useState({})

  const schema = yup.object().shape({
    name: yup.string().trim().required()
  })

  const linkState = key => {
    return event => {
      const dataClone = _.cloneDeep(data);
      _.set(dataClone, key, event.target.value);
      setData(dataClone);
    };
  }

  const onSaveInternal = () => {
    setErrors({})
    schema
      .validate(data, {abortEarly: false})
      .then(() => {
        onSave(data)
        onClose()
        setData(INIT_STATE)
      })
      .catch(function (err) {
        setErrors(parseYupErrors(err), true);
      })
  }

  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose}>
        <form onSubmit={(e) => e.preventDefault()}>
          <DialogTitle>
                        Save template
          </DialogTitle>
          <DialogContent>
            <FormControl id="email" fullWidth variant="outlined" error={!!errors['name']}>
              <InputLabel htmlFor="email">Name</InputLabel>
              <OutlinedInput
                fullWidth
                value={data.name}
                onChange={linkState('name')}
                autoFocus
              />
              {!!errors['name'] &&
                                <FormHelperText>{errors['name'] || null}</FormHelperText>
              }
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button style={{ width: '160px' }} variant={'outlined'} onClick={onClose}>
                            CANCEL
            </Button>
            <Button style={{ width: '160px' }} variant={'contained'} onClick={onSaveInternal} type="submit">
                            SAVE
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  )
}
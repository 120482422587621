import React, {useEffect, useMemo, useState} from 'react';
import {Box} from '@material-ui/core';
import InnerHeader from '@components/layout/inner-header';
import { Route, Switch } from 'react-router';

import FormInputFields from './input-fields/input-fields';
import FormDocuments from './documents/documents';
import FormRelationships from './relationships';
import FormRoles from './roles';
import Details from './details';
import { useParams } from 'react-router-dom';
import history from '@app/history';
import {clientApi} from '@app/api';
import {fetchCategories, fetchTypes, selectCategories, selectTypes} from '../../duck';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import ChangeNameDialog from './dialogs/change-name-dialog';
import {useSnackbar} from 'notistack';
import {getIsInheritedItem} from '../../utils/inheritance';

const FORM_TABS = [
  {
    id: 'details',
    label: 'Details'
  },
  {
    id: 'fields',
    label: 'Input Fields'
  },
  {
    id: 'documents',
    label: 'Documents'
  },
  // {
  //   id: 'roles',
  //   label: 'Roles'
  // },
  // {
  //   id: 'relationships',
  //   label: 'Relationships'
  // }
]

export default function Form({ data, setData, errors = {}, setErrors, readOnly = false }) {
  const { tab, filterValue, id, formType, stepNumber, formTab } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [formTabId, setFormTabId] = useState(formTab || 'fields')
  const [changeNameDialogOpen, setChangeNameDialogOpen] = useState(false)
  const dispatch = useDispatch()

  const categories = useSelector(selectCategories)
  const types = useSelector(selectTypes)

  const onTabChange = tabIdx => {
    const formTab = formTabs[tabIdx]
    setFormTabId(
      formTab.id
    )
    history.push(buildFormTabUrl(formTab.id))
  }

  const buildFormTabUrl = formTabId => {
    let url = `/settings/${tab}/dashboard/${filterValue}/${formType}`
    if (id) {
      url += `/${id}`
    }
    if (stepNumber) {
      url += `/${stepNumber}`
    }
    return `${url}/${formTabId}`
  }

  const fetchCategoriesAndTypes = async () => {
    dispatch(fetchCategories())
    dispatch(fetchTypes())
  }

  const fetch = async () => {
    const res = (await clientApi.clientRulebook.show(id)).data
    // Init input fields ordering
    const inputFields = _.orderBy(res.inputFields, ['displayConfig.order'])
    // Code below fixes problem of same order values for all the inherited fields
    // const inputFields = _.orderBy(res.inputFields, ['displayConfig.order'])
    //   .map((inputField, index) => ({
    //     ...inputField,
    //     displayConfig: {
    //       ...inputField.displayConfig,
    //       order: index+1
    //     }
    //   }))
    // Drop some fields from clone source cuz they change for sure
    setData({
      ...res,
      inputFields,
      ...data
    })
  }

  const formTabs = useMemo(() => {
    return !['edit', 'view'].includes(formType)
      ? FORM_TABS.filter(t => t.id !== 'details')
      : FORM_TABS
  }, [formType])

  const init = async () => {
    if (['edit', 'clone'].includes(formType)) {
      await fetch() //eslint-disable-line
    }
    fetchCategoriesAndTypes()
  }

  useEffect(() => {
    init()
  }, []) // eslint-disable-line

  const rulebook = useMemo(() => {
    if (!data || !categories || !types) {
      return null
    }
    const extCategories = data.documentRules?.map(rulebookCategory => {
      const category = categories.find(c => c.id === rulebookCategory.categoryId)
      if (!category?.usageTypes?.includes(data.clientType)) {
        return null;
      }
      return {
        ...rulebookCategory,
        ref: {
          ...category,
          types: [...types].filter(t => t.categoryIds.indexOf(rulebookCategory.categoryId) !== -1)
        },
        types: rulebookCategory.types.map(rulebookType => {
          const type = types.find(t => t.id === rulebookType.typeId)
          if (!type?.usageTypes?.includes(data.clientType)) {
            return null;
          }
          return {
            ...rulebookType,
            ref: type
          }
        }).filter( c => c !== null)
      }
    }).filter( c => c !== null) || []
    const extRulebook = {
      ..._.cloneDeep(data),
      categoriesCount: extCategories.length,
      typesCount: extCategories.reduce((acc, category) => {
        return acc + category.types.length
      }, 0)
    }
    extRulebook.documentRules = extCategories
    return extRulebook
  }, [data, categories, types])

  const categoryHasType = (categoryId, typeId) => {
    return !!data.documentRules.find(rc => rc.categoryId === categoryId)
      ?.types?.some(rt => rt.typeId === typeId)
  }

  const switchTypeInCategory = (categoryId, typeId, include = true) => {
    const newData = {...data}
    const rulebookCategory = newData.documentRules.find(rc => rc.categoryId === categoryId)
    const hasType = !!rulebookCategory.types.some(rt => rt.typeId === typeId)
    if (!hasType && include) {
      rulebookCategory.types.push({
        expected: false,
        typeId: typeId
      })
    } else if (hasType && !include) {
      rulebookCategory.types = rulebookCategory.types.filter(rt => rt.typeId !== typeId)
    }
    setData(newData)
  }

  const switchCategoryRuleType = (categoryId, isAnyOf) => {
    const newData = {...data}
    const rulebookCategory = newData.documentRules.find(rc => rc.categoryId === categoryId)
    rulebookCategory.categoryRuleType = isAnyOf? 'ONE_OF': 'PER_TYPE'
    rulebookCategory?.types?.forEach(t => {
      t.expected = false
    })
    setData(newData)
  }

  const categoryTypeExpected = (categoryId, typeId) => {
    return !!data.documentRules.find(rc => rc.categoryId === categoryId)
      ?.types?.find(rt => rt.typeId === typeId)?.expected
  }

  const onSaveName = async ({name}) => {
    try {
      await clientApi.clientRulebook.updateVersion(
        data?.rulebookId,
        {
          name
        }
      )
      setData({
        ...data,
        name
      })
      enqueueSnackbar(`New Entity Name "${name}" successfully saved!`, { variant: 'success' })
    } catch (err) {
      enqueueSnackbar('Something went wrong...', { variant: 'error' })
    }
    setChangeNameDialogOpen(false)
  }

  //console.log('params in form - ', `tab:${tab}, filterValue:${filterValue}, id:${id}, formType:${formType}`)

  console.log('DATA:', data)

  console.log(
    'Init input fields order',
    _.orderBy(data?.inputFields || [], ['displayConfig.order']).map(nif => `${nif.displayName}:${!getIsInheritedItem(rulebook, nif)? 'c': 'i'}: ${nif.displayConfig.order}`)
  )

  return (
    <Box display={'flex'} flexDirection={'column'} style={{width: '100%'}}>
      <InnerHeader
        ind={formTabs.findIndex(t => t.id === formTabId)}
        onTabChange={onTabChange}
        tabs={formTabs}
        height={'50px'}
      />
      <Box
        display="flex"
        maxHeight="calc(100%-98px)"
        mt={3}
      >
        <Switch>
          <Route path="/settings/:tab/dashboard/:filterValue/:formType/:id?/:stepNumber?/details">
            <Details
              data={data}
              setData={setData}
              errors={errors}
              readOnly={readOnly}
              openChangeNameDialog={() => {
                setChangeNameDialogOpen(true)
              }}
              formType={formType}
            />
          </Route>
          <Route path="/settings/:tab/dashboard/:filterValue/:formType/:id?/:stepNumber?/fields">
            <FormInputFields
              rulebook={rulebook}
              data={data}
              setData={setData}
              categoryHasType={categoryHasType}
              switchTypeInCategory={switchTypeInCategory}
              categoryTypeExpected={categoryTypeExpected}
              readOnly={readOnly}
            />
          </Route>
          <Route path="/settings/:tab/dashboard/:filterValue/:formType/:id?/:stepNumber?/relationships">
            <FormRelationships
              rulebook={rulebook}
              data={data}
              setData={setData}
              readOnly={readOnly}
            />
          </Route>
          <Route path="/settings/:tab/dashboard/:filterValue/:formType/:id?/:stepNumber?/roles">
            <FormRoles
              rulebook={rulebook}
              data={data}
              setData={setData}
              readOnly={readOnly}
            />
          </Route>
          <Route path="/settings/:tab/dashboard/:filterValue/:formType/:id?/:stepNumber?/documents">
            <FormDocuments
              rulebook={rulebook}
              data={data}
              setData={setData}
              categoryHasType={categoryHasType}
              switchTypeInCategory={switchTypeInCategory}
              categoryTypeExpected={categoryTypeExpected}
              switchCategoryRuleType={switchCategoryRuleType}
              readOnly={readOnly}
            />
          </Route>
        </Switch>
      </Box>

      <ChangeNameDialog
        open={changeNameDialogOpen}
        onSave={onSaveName}
        initName={data?.name}
        onClose={() => {
          setChangeNameDialogOpen(false)
        }}
      />
    </Box>
  )
}
import React from 'react';
import {
  Link,
  Box,
  Divider
} from '@material-ui/core';

import history from '../../app/history';

export default function AuthHeader(props) {

  return (
    <React.Fragment>
      <Link
        variant={'body1'}
        onClick={() => {
          history.push('/login')
        }}
      >
                Login
      </Link>
      <Box ml={1} mr={1} height="100%">
        <Divider orientation={'vertical'} />
      </Box>
      <Link
        variant={'body1'}
        onClick={() => {
          history.push('/signup')
        }}
      >
                Sign Up
      </Link>
    </React.Fragment>
  );
}
import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';

import CustomTooltip from '@components/controls/tooltip';
import BaseTableCell from './base-table-cell';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: 1,
  },
}));

export default function IconButtonTableCell(props) {
  const classes = useStyles();
  const { col, item, handleAction, handlers, preventClick } = props;

  col.colProps = {
    ...col.colProps,
    align: col.colProps?.align || 'right'
  }

  return (
    <BaseTableCell col={col} item={item} onClick={preventClick}>
      <CustomTooltip title={col.tooltip} placement="top">
        <span>
          <IconButton
            onClick={handleAction(item.id, handlers[col.field])}
            className={classes.iconButton}
            disabled={item.isDisabled}
          >
            {col.icon}
          </IconButton>
        </span>
      </CustomTooltip>
    </BaseTableCell>
  )
}
import React from 'react';
import {
  Box, Typography
} from '@material-ui/core';
import CustomChip from '@components/controls/custom-chip';


export default function Expires({ expirationDate, chipLabel }) {
  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Typography variant="h5">EXPIRES ON</Typography>
        {chipLabel && (
          <Box ml={1} mb={'5px'}>
            <CustomChip label={chipLabel} type="ADDED" />
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="h3">{expirationDate}</Typography>
      </Box>
    </Box>
  )
}

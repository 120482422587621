import React, {useEffect, useState} from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Button,
  DialogActions,
  InputLabel,
  Select,
  MenuItem,
  FormControl, FormHelperText, TextField
} from '@material-ui/core';
import { countriesISO } from '@services/country-service';
import * as yup from 'yup';
import parseYupErrors from '@app/yup';


const schema = yup.object().shape({
  name: yup.string().required().label('Entity Name'),
  jurisdiction: yup.string().required().label('Jurisdiction'),
})

const MODEL_TEMPLATE = {
  name: '',
  jurisdiction: ''
}

export default function ExtraDialog({ open, onClose, onSave }) {

  const [model, setModel] = useState(MODEL_TEMPLATE)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    return () => {
      setModel(MODEL_TEMPLATE)
    }
  }, [open])

  const onInternalSave = async () => {
    setErrors({});
    try {
      await schema.validate(model, {abortEarly: false})
      onSave(model)
    } catch (err) {
      setErrors(parseYupErrors(err), true)
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={(e) => e.preventDefault()}>
        <DialogTitle>
          Select jurisdiction first
        </DialogTitle>
        <DialogContent>
          <Box width={'400px'}>
            <TextField
              value={model.name}
              onChange={event => {
                setErrors({})
                setModel({
                  ...model,
                  name: event.target.value
                })
              }}
              label="Entity Name"
              variant="outlined"
              fullWidth
              error={!!errors.name}
              helperText={errors.name}
            />
            <FormControl
              variant="outlined"
              fullWidth
              error={!!errors.jurisdiction}
            >
              <InputLabel>Jurisdiction</InputLabel>
              <Select
                value={model.jurisdiction}
                onChange={event => {
                  setErrors({})
                  setModel({
                    ...model,
                    jurisdiction: event.target.value
                  })
                }}
              >
                {countriesISO.map(({code, name}) => (
                  <MenuItem key={code} value={code}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.jurisdiction}</FormHelperText>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button style={{ width: '160px' }} variant={'outlined'} onClick={onClose}>
            CANCEL
          </Button>
          <Button style={{ width: '160px' }} variant={'contained'} onClick={onInternalSave} type="submit">
            SAVE
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
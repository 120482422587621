import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Typography
} from '@material-ui/core';
import * as yup from 'yup';
import parseYupErrors from '@app/yup';
import OutlinedPassword from '@components/inputs/outlined-password';
import auth from '@app/auth';
import { useSnackbar } from 'notistack';
import useErrorState from '@utils/errorState';
import SelectClearable from '@components/inputs/select-clearable';

const options = ['Every 1 Year']

export default function AccountProtection(props) {
  const [debug] = useState(parseInt(localStorage.getItem('debug') || 0))
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useErrorState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [forceChange, setForceChange] = useState(options[0])
  const [model, setModel] = useState({
    old_password: '',
    password: '',
    password_confirm: '',
  });

  useEffect(() => {
    setModel({ old_password: '', password: '', password_confirm: '' });
  }, [dialogOpen])

  const handleChange = (prop) => (event) => {
    const newModel = { ...model, [prop]: event.target.value };
    setModel(newModel);

    if (errors[prop]) {
      schema.validateAt(prop, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [prop]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [prop]: err.message });
        });
    }
  };

  const onSave = () => {
    setErrors({});

    schema
      .validate(model, { abortEarly: false })
      .then(function (valid) {
        auth.changePassword(model.old_password, model.password)
          .then(data => {
            enqueueSnackbar('Password was successfully updated!', { variant: 'success' });
            setDialogOpen(false);
          })
          .catch(err => {
            if (['NotAuthorizedException', 'InvalidParameterException'].includes(err.code)) {
              setErrors({
                old_password: 'Incorrect password'
              }, true)
            } else {
              setErrors({
                password_confirm: err.message
              }, true)
            }
          })
      })
      .catch(function (err) {
        console.log({ err });
        setErrors(parseYupErrors(err), true);
      });
  }

  const schema = yup.object().shape({
    old_password: yup.string().label('Current password').required(),
    password: yup.string().label('New password').required()
      .test('format', 'Password must have at least 8 characters, one capital letter, one small letter, one number and one special character', function () {
        const regex = new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\\-=\\[\\]{};\':"\\\\|,.<>/?]).{8,}$');
        return regex.test(this.parent.password);
      })
      .test('format_space', 'Password can\'t start or end with space(s)', function () {
        const trimmedPassword = this.parent.password.trim();
        return trimmedPassword === this.parent.password;
      }),
    password_confirm: yup.string().label('Confirm new password').required().test('match', 'Passwords do not match', function () {
      return this.parent.password_confirm === this.parent.password
    })
  });

  return (
    <Grid container alignItems="center">
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="xs">
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <FormControl id="old_password" fullWidth variant="outlined" error={!!errors['old_password']}>
            <InputLabel htmlFor="password">Current Password</InputLabel>
            <OutlinedPassword value={model.old_password} onChange={handleChange('old_password')} />
            {!!errors['old_password'] &&
                            <FormHelperText>{errors['old_password'] || null}</FormHelperText>
            }
          </FormControl>
          <FormControl id="password" fullWidth variant="outlined" error={!!errors['password']}>
            <InputLabel htmlFor="new-password">New Password</InputLabel>
            <OutlinedPassword value={model.password} onChange={handleChange('password')} />
            {!!errors['password'] &&
                            <FormHelperText>{errors['password'] || null}</FormHelperText>
            }
          </FormControl>
          <FormControl id="password_confirm" fullWidth variant="outlined" error={!!errors['password_confirm']}>
            <InputLabel htmlFor="password-confirm">Repeat Password</InputLabel>
            <OutlinedPassword value={model.password_confirm} onChange={handleChange('password_confirm')} />
            {!!errors['password_confirm'] &&
                            <FormHelperText>{errors['password_confirm'] || null}</FormHelperText>
            }
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button style={{ width: '160px' }} variant={'outlined'} onClick={() => setDialogOpen(false)}>
                        CANCEL
          </Button>
          <Button style={{ width: '160px' }} variant={'contained'} onClick={onSave}>
                        SAVE
          </Button>
        </DialogActions>
      </Dialog>

      <Grid item xs={6}>
        <Typography variant="h5">Account Protection</Typography>
      </Grid>

      <Grid item xs={12}>
        <Box mt={1}>
          <Typography variant="body1" style={{ color: '#A4A4A4' }}>The use of a strong password containing a combination of lowercase and uppercase letters, numbers and special characters (e.g. #$%) is required to secure your account. To increase the security of your password, do not use it for other applications or websites, and change it frequently.</Typography>
        </Box>
      </Grid>
      {!!debug &&
                <Grid item xs={12}>
                  <Box mt={2}>
                    <Grid container alignItems="center">
                      <Grid item xs={9}>
                        <Box pr={2}>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel>Force Password Change</InputLabel>
                            <SelectClearable
                              value={forceChange}
                              onChange={(e) => setForceChange(e.target.value)}
                              clearable={false}
                            >
                              {options.map(option => (
                                <MenuItem
                                  key={option}
                                  value={option}
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </SelectClearable>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Button variant={'contained'} onClick={onSave}>
                                    SAVE
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
      }
      <Grid item xs={12}>
        <Box mt={2}>
          <Button
            style={{ width: 270 }}
            variant="outlined"
            size="medium"
            onClick={() => setDialogOpen(true)}
          >
                        CHANGE PASSWORD
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
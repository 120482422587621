import React from 'react';
import clsx from 'clsx';
import { Badge, Box, Menu } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ButtonWithIcon from '@components/buttons/button-with-icon';

import { DropdownDownIcon } from '@app/icons';

const useStyles = makeStyles((theme) => ({
  contextButton: {
    borderRadius: 8,
    padding: 2,
  },
  buttonActive: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  },
  badge: {
    backgroundColor: 'rgb(202, 215, 202)'
  },
  pulsingButton: {
    backgroundColor: 'rgb(202, 215, 202)',
    animationName: '$pulsing',
    animationDuration: '2s',
    animationIterationCount: 'infinite',
  },
  '@keyframes pulsing': {
    '0%': {
      backgroundColor: 'rgb(202, 215, 202)',
    },
    '50%': {
      backgroundColor: 'rgb(213, 240, 240)',
    },
    '100%': {
      backgroundColor: 'rgb(202, 215, 202)',
    }
  }
}));

export default function IconDropdownWithContext({ icon, disabled, label, children, badge }) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(false);
  const [isPulsing, setIsPulsing] = React.useState(true);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!!badge) {
      setIsPulsing(false);
    }

    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Badge invisible={!badge} badgeContent={badge} classes={{ badge: classes.badge }} max={999}>
      <Box>
        <ButtonWithIcon
          startIcon={icon}
          disabled={disabled}
          className={clsx(open && classes.buttonActive, (!!badge && isPulsing) && classes.pulsingButton)}
          onClick={handleClick}
        >
          <Box mr={1}>{label}</Box>
          <DropdownDownIcon />
        </ButtonWithIcon>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClick={handleClose}
        >
          {children}
        </Menu>
      </Box>
    </Badge>
  )
}
import React from 'react';
import PageLayout from '@components/layout/page-layout';

import UserManagement from './user-management';
import AddUser from './add-user';
import EditUser from './edit-user';
import ViewUser from './view-user';
import ViewTeamMember from './view-user/view-team-member';

import { Route, Switch } from 'react-router';

export default function Index(props) {

  return (
    <PageLayout>
      <Switch>
        <Route exact path="/user-management/:tab?" component={UserManagement} />
        <Route exact path="/user-management/manage-users/add-user" component={AddUser} />
        <Route exact path="/user-management/manage-users/edit-user" component={EditUser} />
        <Route exact path="/user-management/manage-users/view-user/:tab?" component={ViewUser} />
        <Route exact path="/user-management/manage-users/view-user/team-members/view-member" component={ViewTeamMember} />
      </Switch>
    </PageLayout>
  )
}

import React from 'react';
import RenderAcknowledgButton from './renderAcknowledgeButton';
import RenderNotNowButton from './renderNotNowButton';
import RenderExportButton from './renderExportButton';

export default function FlowSH({ activeStep, isViewAsknowledgeButton, selectedProfile, handleNotNow, acknowledgeAction, exportAction, riskPriority, disableExportButton }) {
  const renderExportButton =(
    <RenderExportButton
      action={exportAction}
      riskPriority={riskPriority}
      disableExportButton={disableExportButton}
    />
  )

  return (
    !isViewAsknowledgeButton ? (
      renderExportButton
    ) : activeStep === 1 ? (
      <React.Fragment>
        <RenderAcknowledgButton action={acknowledgeAction} />
        <RenderNotNowButton handleNotNow={handleNotNow} />
      </React.Fragment>
    ) : (
      <React.Fragment>
        {renderExportButton}
      </React.Fragment>
    )
  )
}
import React, {useEffect, useState} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Typography
} from '@material-ui/core';
import CustomTooltip from '@components/controls/tooltip';
import {
  BackgroundCheckActiveIcon,
  SearchHistoryActiveIcon,
  SearchHistoryInactiveIcon,
  DownloadIcon
} from '@app/icons';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import {clientApi} from '@app/api';
import prepareTimestamp from '@utils/date';
import history from '@app/history';
import clsx from 'clsx';
import {exportBackgroundCheckPDF} from '@utils/exportPDF';
import _ from 'lodash';
import grey from '@material-ui/core/colors/grey';
import NoResultsFound from '@components/layout/no-results-found';
import { loader } from '@store/actions';
import {useDispatch} from 'react-redux';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  table: {

  },
  actionButton: {
    padding: '8px'
  },
  highlightedRow: {
    backgroundColor: '#F8F8F8',
    '&:hover': {
      cursor: 'default',
    }
  },
  disabledHoverRow: {
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'default',
    }
  }
}))

export default function BackgroundCheck(props) {
  const { enqueueSnackbar } = useSnackbar();

  const { data, readonly, highlightSearchId } = props;
  const [ lines, setLines ] = useState([])
  const [ lastSearch, setLastSearch ] = useState([])
  const [ fetched, setFetched ] = useState(false)

  const classes = useStyles();

  const dispatch = useDispatch()

  useEffect(() => {
    fetch().then()
    }, [data]) // eslint-disable-line

  const getColumns = () => {
    return data.client_type === 'PERSON'
      ? [
        { field: 'name', headerName: 'REVIEWER' },
        { field: 'country', headerName: 'CITIZENSHIP', type: 'country' },
        { field: 'risk', headerName: 'RISK RATING' },
        { field: 'notes', headerName: 'NOTES', type: 'list' },
        { field: 'date', headerName: 'DATE' }
      ] : [
        { field: 'name', headerName: 'REVIEWER' },
        { field: 'country', headerName: 'COUNTRY', type: 'country' },
        { field: 'risk', headerName: 'RISK RATING' },
        { field: 'notes', headerName: 'NOTES', type: 'list'  },
        { field: 'date', headerName: 'DATE' }
      ]
  }

  const fetch = async () => {
    const {linked_profiles, linked_searches} = data;
    if (!linked_profiles || !linked_searches) return;
    const searches = []
    const profiles  = []
    const linesData = [];

    await Promise.all(linked_profiles.map(async id => {
      const profile = (await clientApi.profile.show(id)).data
      if( profile ){
        profiles.push( profile )
      }
    }))
    await Promise.all(linked_searches.map(async id => {
      const search = (await clientApi.search.show(id)).data
      if( search ){
        searches.push( search )
      }
    }))
    const originSearches = _.keyBy([...searches], 'searchId')
    const remainingSearches = {...originSearches}

    await Promise.all(profiles.map( async profile => {
      const search = originSearches[profile.searchId];

      if(search){
        linesData.push({
          id: `${search.searchId}/${profile.id}`,
          name: 'Me',
          country: data.citizenship || data.country,
          risk: profile.bgCheckRiskRating || 'N/A',
          notes: profile.notes.length? profile.notes.map(n=>n.note): ['N/A'],
          date: search.lastRunOn && prepareTimestamp(search.lastRunOn, 'MM/DD/YYYY'),
          search_id: profile.searchId,
          profile_id: profile.id,
          createdOn: search.createdOn // needed for sort
        })
      }
      delete remainingSearches[profile.searchId];
    }))

    await Promise.all(Object.values(remainingSearches).map(async search => {
      linesData.push({
        id: search.searchId,
        name: 'Me',
        country: data.citizenship || data.country,
        risk: search.bgCheckRiskRating || 'N/A',
        notes: ['No hits found'],
        date: search.createdOn && prepareTimestamp(search.createdOn, 'MM/DD/YYYY'),
        search_id: search.searchId,
        profile_id: null,
        createdOn: search.createdOn // needed for sort
      })
    }))
    setLines(
      _.orderBy(linesData, ['createdOn'], ['desc'])
    )

    const maxTime = searches.reduce((acc, search) => {
      if(!acc || (search.createdOn > acc)) {
        acc = search.createdOn
      }
      return acc
    }, 0)

    if(maxTime) {
      setLastSearch(maxTime)
    }

    setFetched(true)
  }

  const onSearchHistory = () => {
    history.push(`/portfolio/history/${data.id}`)
  }

  const onBackgroundCheck = () => {
    history.push(`/portfolio/bg-check/add-monitoring-bg-check/${data.id}`)
  }

  const onExport = async (search_id, profile_id) => {
    dispatch(loader.setLoader(true));
    await exportBackgroundCheckPDF({ searchId: search_id, profileId: profile_id });
    enqueueSnackbar('The report was successfully exported!', { variant: 'success' });
  }

  return (
    <React.Fragment>
      <Box display={'flex'} flexGrow={1} flexDirection={'column'} mt={3}>

        <Box display={'flex'} justifyContent={'space-between'} mt={'-5px'}>
          <Box display={'flex'} alignItems={'center'}>
            <Typography variant={'h5'}>Background Checks</Typography>
                        &emsp;
            <CustomTooltip title="Last Updated" aria-label="Last Updated" placement="top">
              <SearchHistoryInactiveIcon width={12}/>
            </CustomTooltip>
                        &nbsp;
            <Typography variant={'caption'} style={{color: grey[500]}}>{lastSearch && prepareTimestamp(lastSearch)}</Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'}>
            {!readonly &&
                            <React.Fragment>
                              <ButtonWithIcon startIcon={<SearchHistoryActiveIcon />} onClick={onSearchHistory}>
                                    Search History
                              </ButtonWithIcon>
                              <ButtonWithIcon startIcon={<BackgroundCheckActiveIcon />} onClick={onBackgroundCheck}>
                                    Run Background Check
                              </ButtonWithIcon>
                            </React.Fragment>
            }
          </Box>
        </Box>


        { !!lines.length &&
                    <Box display={'flex'} mt={1} mx={-2}>
                      <TableContainer>
                        <Table aria-label="simple table" className={classes.table}>
                          <TableHead>
                            <TableRow>
                              {getColumns().map(col => (
                                <TableCell key={`header-${col.field}`}>
                                  <Box whiteSpace="nowrap">
                                    {col.headerName}
                                  </Box>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {lines.map((item) => (
                              <React.Fragment key={`line-${item.id}`}>
                                <TableRow className={clsx(highlightSearchId && (item.search_id === highlightSearchId ? classes.highlightedRow : classes.disabledHoverRow))}>
                                  {getColumns().map((col, iCol) => (
                                    <TableCell className={clsx([!iCol && 'bolded'])} key={`line-${item.id}-${col.field}`}>
                                      {item[col.field] && (
                                        (col?.type === 'list' && (
                                          item[col.field].map((note, iNote) => (
                                            <Box mt={!!iNote? 1: 0} flexDirection={'column'} key={`note-${item.id}-${iNote}`}>
                                              <Box display={'flex'}>{note}</Box>
                                            </Box>
                                          ))
                                        ))
                                                            || item[col.field]
                                      )}
                                      {!item[col.field] && 'N/A'}

                                    </TableCell>
                                  ))}
                                  <TableCell align={'right'} width={30}>
                                    <CustomTooltip title="Export" placement="top">
                                      <IconButton className={classes.actionButton} onClick={() => onExport(item.search_id, item.profile_id)}>
                                        <DownloadIcon />
                                      </IconButton>
                                    </CustomTooltip>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
        }

        { fetched && !lines.length &&
                    <Box mt={5}>
                      <NoResultsFound>
                            When you run a background check, the history of searches will appear here
                      </NoResultsFound>
                    </Box>
        }
      </Box>
    </React.Fragment>
  )
}
import React, { useMemo } from 'react';
import {
  Box,
  Grid,
  Link,
  Typography,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import RadioButtonGroup from '@components/buttons/radio-button-group';
import DateRangePicker from '@components/calendar/date-range-picker';

const EMPTY_RANGE_VALUE = { from: null, to: null };

const SORT_ITEMS = [
  { label: 'Date Added', value: 'uploadDate' },
  { label: 'Issue Date', value: 'issueDate' },
  { label: 'Expiry Date', value: 'expiryDate' },
  { label: 'Status', value: 'statusLabel' },
];

export default function DocumentFilters({
  sort,
  onSort,
  expiryDateRange,
  setExpiryDateRange,
  categories,
  handleSwitch,
  checkedCategories,
  showCustomCategories,
  handlePageChange
}) {
  const getExpiryDateRange = useMemo(
    () => expiryDateRange || EMPTY_RANGE_VALUE,
    [expiryDateRange]
  );

  const onExpiryDateRangeChange = (side) => (value) => {
    handlePageChange(0)
    setExpiryDateRange({ ...expiryDateRange, [side]: value });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={2}>
        <Box display={'flex'} pt={1} pb={1}>
          <Typography variant="h5">Sort By</Typography>
        </Box>
        <RadioButtonGroup
          items={SORT_ITEMS}
          handleChange={(e) => onSort(e.target.value)}
          selectedValue={sort}
          formControlSize={40}
        />
      </Grid>
      <Grid item xs={3}>
        <Box display={'flex'} pt={1} pb={1}>
          <Typography variant="h5">System Categories</Typography>
        </Box>
        {categories.system.map((el, i) => (
          <Box
            display={'flex'}
            alignItems={'center'}
            height={'40px'}
            key={`${el.id}-${el.name}-i`}
          >
            <FormControlLabel
              control={
                <Switch
                  value={el.id}
                  onChange={handleSwitch}
                  checked={checkedCategories.includes(el.id.toString())}
                />
              }
              label={el.name}
            />
          </Box>
        ))}
        {showCustomCategories &&
       <Box display={'flex'} pt={1} pb={1}>
         <Typography variant="h5">User Categories</Typography>
       </Box>}
        {showCustomCategories && categories.user.map((el, i) => (
          <Box
            display={'flex'}
            alignItems={'center'}
            height={'40px'}
            key={`${el.id}-${el.name}-i`}
          >
            <FormControlLabel
              control={
                <Switch
                  value={el.id}
                  onChange={handleSwitch}
                  checked={checkedCategories.includes(el.id.toString())}
                />
              }
              label={el.name}
            />
          </Box>
        ))}
      </Grid>
      <Grid item xs={7}>
        <Box display={'flex'} justifyContent={'center'}>
          <Box display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} pl={3} pt={1} pb={1}>
              <Typography variant="h5">Filter By Expiration Date</Typography>
            </Box>
            <Box display={'flex'}>
              <DateRangePicker
                startDate={getExpiryDateRange.from}
                endDate={getExpiryDateRange.to}
                setStartDate={onExpiryDateRangeChange('from')}
                setEndDate={onExpiryDateRangeChange('to')}
              />
            </Box>
            <Box display={'flex'} pl={3}>
              <Link
                underline={'none'}
                variant={'body1'}
                onClick={() => setExpiryDateRange(EMPTY_RANGE_VALUE)}
              >
                CLEAR DATE RANGE
              </Link>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

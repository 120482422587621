import React, {useState, useCallback, useEffect, useMemo} from 'react';
import { Box, makeStyles } from '@material-ui/core';
import InnerHeader from '@components/layout/inner-header';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { AddActive2Icon } from '@app/icons';
import EntitiesGrid from './views/entities-grid';
import history from '@app/history';
import { useParams } from 'react-router-dom';
import { Route, Switch, Redirect } from 'react-router';
import { capitalize } from '@utils/textUtil';
import {useDispatch, useSelector} from 'react-redux';
import { entityManagement } from '@app/store/actions';
import { adminApi } from '@app/api';
import { prepareRulebookData } from './utils/prepareRulebookData';

import { TABS } from './constants';
import FilterSearchHeader from './components/filter-search-header';

const useStyles = makeStyles((theme) => ({
  propsTabRoot: {
    maxWidth: '300px'
  },
}));

export default function EntityManagement(props) {
  const { tab } = useParams();
  const classes = useStyles();

  const [tabID, setTabID] = useState(tab || 'global');
  const [filters, setFilters] = useState({
    sortBy: 'jurisdiction',
    filterByClass: null,
    filterByType: null,
    filterByJurisdiction: null
  })

  const [search, setSearch] = useState('')

  const onTabChange = (index) => {
    const tabID = TABS[index].id;
    setTabID(tabID);
    history.push(`/entity-management/${tabID}`)
  }

  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    const params = {
      sortBy: 'name',
      global: tabID === 'global',
      latest: true,
      ownedBy: 'SYSTEM',
    }
    if (tabID === 'local') {
      if (filters.sortBy) {
        params.sortBy = filters.sortBy
      }
      if ( filters.filterByJurisdiction ) {
        params.jurisdiction = filters.filterByJurisdiction
      }
    }
    if (search) {
      params.name = search
    }
    const data = (await adminApi.rulebooks.getClientRulebooks(params)).data;
    const newData = await prepareRulebookData(data.entries);
    dispatch(entityManagement.setDocumentsData(newData));
  }, [dispatch, filters, search, tabID])

  useEffect(() => {
    fetchData();
  }, [fetchData])

  useEffect(() => {
    setTabID(tab || 'global')
  }, [tab])

  const { rulebooksData } = useSelector(state => state.entityManagement)

  const rulebooksScopeData = useMemo(() => rulebooksData[tab],
    [ rulebooksData, tab ]
  )
  
  const totalCount = useMemo(() => rulebooksScopeData?.length || 0, [rulebooksScopeData])

  return (
    <Box mx={6} height="100%">
      <InnerHeader
        onTabChange={onTabChange}
        ind={TABS.findIndex(tab => tab.id === tabID)}
        tabs={TABS}
        propsTabRoot={classes.propsTabRoot}
        buttons={
          <ButtonWithIcon
            startIcon={<AddActive2Icon />}
            onClick={() => history.push(`/entity-management/${tabID}/create`)}
          >
            Add {capitalize(tabID)} Entity
          </ButtonWithIcon>
        }
      />

      <Box display={'flex'} flexDirection={'column'} height={'calc(100% - 100px)'}>
        <FilterSearchHeader
          totalCount={totalCount}
          search={search}
          setSearch={setSearch}
          filters={filters}
          setFilters={setFilters}
          scope={tab}
        />

        <Switch>
          <Route exact path="/entity-management/:scope(local|global)?">
            <EntitiesGrid
              fetchData={fetchData}
            />
          </Route>
          <Redirect to={`/entity-management/${tabID}`} />
        </Switch>
      </Box>
    </Box>
  )
}

import React, { useState, useMemo } from 'react';
// eslint-disable-next-line
import { Box, Grid, RadioGroup, FormControlLabel, Radio, Switch, makeStyles } from "@material-ui/core";
import TableList from '@components/lists/table-list';
import OutlinedSearch from '@components/inputs/outlined-search';
import InlineHeadBadge from '@components/badges/inline-head-badge';
import FilterWithContext from '@components/dropdowns/filter-with-context';
import { Modal } from '@components/modals';

import { useSnackbar } from 'notistack';
import history from '@app/history';
import { dummy } from '../../dummy';

const useStyles = makeStyles((theme) => ({
  modalButtonWidth: {
    width: '160px',
  },
}));

const TABLE_COLUMNS = [
  { field: 'name', headerName: 'Name', bold: true },
  { field: 'description', headerName: 'Description' },
  { field: 'regularCredits', headerName: 'Regular Credits' },
  { field: 'premiumCredits', headerName: 'Premium Credits' },
  { field: 'price', headerName: 'Price' },
  { field: 'createdOn', headerName: 'Created On' },
  { field: 'updatedOn', headerName: 'Updated On' },
  { field: 'createdBy', headerName: 'Created By' },
  { field: 'status', headerName: 'Status' },
]

const switchGroup = [
  { value: 'active', label: 'Active Credit Packs', isChecked: true },
  { value: 'blocked', label: 'Blocked Credit Packs', isChecked: true },
]


export default function SearchCreditPacks({ baseRoute, tabID }) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState(dummy.creditPacks); // eslint-disable-line
  const [search, setSearch] = useState();
  // eslint-disable-next-line
  const [page, setPage] = useState(0);
  const [openModal, setOpenModal] = useState(null);
  const [filters, setFilters] = useState(switchGroup);

  const onSearchChange = (event) => {
    setSearch(event.target.value);
  }

  const onFilterChange = (event) => {
    const changed = filters.map(el =>
      el.value === event.target.value ? ({ ...el, isChecked: event.target.checked }) : el
    );
    setFilters(changed);
  }

  const handleConfirm = () => {
    const actions = { delete: 'deleted', block: 'blocked', unblock: 'unblocked' };
    enqueueSnackbar(
      `Search Credit Pack "${openModal.name}" was successfully ${actions[openModal.type]}!`,
      { variant: 'success' }
    );
    setOpenModal(null);
  }

  const handleMenuAction = (id, item, type) => {
    setOpenModal({ id, name: item.name, type, prefix: 'credit pack' });
  }

  const tableActions = [
    { label: 'Edit', handler: (id, item) => history.push(`${baseRoute}/edit-${tabID}/?creditId=${id}`) },
    { label: 'Delete', handler: (id, item) => handleMenuAction(id, item, 'delete') },
    { label: 'Block', handler: (id, item) => handleMenuAction(id, item, 'block') },
    { label: 'Unblock', handler: (id, item) => handleMenuAction(id, item, 'unblock') },
  ]

  const formattedData = useMemo(() => {
    return data.map(e => {
      const item = { ...e };

      switch (item.status) {
        case 'Active':
          item.cellProps = {
            status: {
              style: {
                color: '#4E922C'
              }
            }
          }
          item.threeDotsActionsDisabled = ['Unblock']
          break;
        
        case 'Blocked':
          item.cellProps = {
            status: {
              style: {
                color: '#B6514C'
              }
            }
          }
          item.threeDotsActionsDisabled = ['Block']
          break;
        
        default:
          item.threeDotsActionsDisabled = ['Unblock']
          break;
      }

      return item;
    })
  }, [data])

  return (
    <Box display={'flex'} flexGrow={'1'} mt={2} flexDirection={'column'}>
      <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'}>
          <Box mr={1}>
            <InlineHeadBadge
              badgeColor={'#CAD7CA'}
              badgeValue={5}
            >
              Search Credit Packs
            </InlineHeadBadge>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <FilterWithContext>
            {filters.map((el, i) => (
              <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value} - ${i}`}>
                <FormControlLabel
                  control={<Switch value={el.value} checked={el.isChecked} onClick={onFilterChange} />}
                  label={el.label}
                />
              </Box>
            ))}
          </FilterWithContext>
        </Box>
      </Box>
      <Box display={'flex'} mb={3}>
        <OutlinedSearch
          value={search}
          onChange={onSearchChange}
          label="Search credit packs"
        />
      </Box>
      <Grid container>
        <Grid item sm={12}>
          <TableList
            columns={TABLE_COLUMNS}
            items={formattedData}
            page={page}
            pageSize={12}
            pagesCount={1}
            totalCount={10}
            sortableColumns={TABLE_COLUMNS.map(el => el.field)}
            handlers={{
              export: (id) => {}
            }}
            maxHeight="calc(100vh - 380px)"
            threeDotsActions={tableActions}
          />
        </Grid>
      </Grid>
      <Modal
        open={!!openModal}
        onClose={() => setOpenModal(null)}
        title={`${openModal?.type} credit pack`}
        mainText={`Are you sure you want to ${openModal?.type} credit pack "${openModal?.name}"?`}
        propsClassses={classes.modalButtonWidth}
        actions={
          [
            {
              type: 'main',
              label: 'No',
              action: () => setOpenModal(null),
            },
            {
              type: 'secondary',
              label: 'Yes',
              action: handleConfirm,
            },
          ]
        }
        actionsDirection="row"
      />
    </Box>
  )
}

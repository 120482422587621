import { globalSearch } from '@app/store/actions';
import store from '@app/store';
import history from '@app/history';

export const goBack = async (prevLocation) => {
  if (prevLocation) {
    localStorage.removeItem('prevLocation');
    history.push(prevLocation);
  }

  localStorage.removeItem('globalSearch');
  localStorage.removeItem('globalSearchType');

  await store.dispatch(globalSearch.setDefault());
  await store.dispatch(globalSearch.setSearch(''));
  await store.dispatch(globalSearch.setSearchType(null));
}

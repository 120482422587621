import React, { useMemo } from 'react';
import InnerHeader from '@components/layout/inner-header';
import CustomTooltip from '@components/controls/tooltip';


import { ArchiveIcon, ArchiveInactiveIcon } from '@app/icons';
import CustomIconButton from '@components/buttons/button-with-icon';
import CheckboxLight from '@components/inputs/checkbox-light';

import { backgroundCheck } from '@store/actions';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  innerHeaderClassesRoot: {
    maxHeight: '60px',
  },
  stepperTooltipPlacementTop: {
    bottom: '5px',
  },
}))

export default function InnerHeaderBlock({
  tabActions, handleActiveTab, tabIndex,
  setShowSuppressModal,
}) {
  const { profilesVersionId } = useSelector(store => store.searchHistory);
  const { sources, selectAllSources } = useSelector(store => store.backgroundCheck);

  const dispatch = useDispatch();

  const classes = useStyles();

  const isAML = useMemo(() => {
    return sources.every(el => el.listType === 'AML');
  }, [sources])

  const selectAll = () => {
    dispatch(backgroundCheck.setSelectAllSources(!selectAllSources));
    const selected = sources
      .map(item => (item.listType === 'AML' || item.listType === 'A')
        ? item
        : ({ ...item, isChecked: !selectAllSources })
      );
    dispatch(backgroundCheck.setChangedSources(selected));
  }

  return (
    <React.Fragment>
      <InnerHeader
        tabs={tabActions}
        renderTabContent={handleActiveTab}
        ind={tabIndex}
        propsClassesRoot={classes.innerHeaderClassesRoot}
        scrollTabWidth={tabActions.length > 4 && '645px'}
        buttons={
          <React.Fragment>
            {!isAML && (
              <CustomTooltip
                title={!!profilesVersionId
                  ? 'Only the last version can be updated, the previous versions are read only'
                  : 'Before suppressing, select the sources'
                }
                placement="top"
                disableHoverListener={!!sources.find(el => !!el.isChecked)}
                tooltipPlacementTop={classes.stepperTooltipPlacementTop}
              >
                <div>
                  <CustomIconButton
                    onClick={() => setShowSuppressModal(true)}
                    startIcon={
                      (!!profilesVersionId || !sources.find(el => !!el.isChecked))
                        ? <ArchiveInactiveIcon />
                        : <ArchiveIcon />
                    }
                    className="minLabel"
                    disabled={!!profilesVersionId || !sources.find(el => !!el.isChecked)}
                  >
                    Suppress selected
                  </CustomIconButton>
                </div>
              </CustomTooltip>
            )}
            {!isAML && (
              <FormControlLabel
                style={{ marginRight: 0 }}
                labelPlacement="start"
                label={<Typography><b>Select all</b></Typography>}
                control={
                  <CheckboxLight
                    className="checkbox-user-card"
                  />
                }
                checked={selectAllSources}
                onClick={selectAll}
                disabled={!!profilesVersionId}
              />
            )}
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
};

import React, { useMemo } from 'react';
import {
  Box, LinearProgress, makeStyles, Typography, withStyles, Button
} from '@material-ui/core';
import CustomTooltip from '@components/controls/tooltip';
import history from '@app/history';

const CustomLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#EDEDED',
  },
  bar: props => ({
    borderRadius: 5,
    backgroundColor: props.colorBar || (props.isLargeValue ? '#4E922C' : '#B6514C'),
  }),
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  value: {
    color: '#232323',
    fontWeight: 'bold'
  },
}));

export default function MeasuredItem(props) {
  const { title, minValue, maxValue, value, isAdmin, baseRoute, type, colorBar } = props;

  const classes = useStyles();

  const progressValue = useMemo(() => {
    return value > maxValue ? maxValue : value;
  }, [value, maxValue])

  return (
    <Box>
      {title && (
        <Box display="flex" alignItems="center">
          <Typography variant="h5">{title}</Typography>
        </Box>
      )}
      <CustomTooltip
        title={`${value} ${type} credits remaining vs ${maxValue} total ${type} credits`}
        placement="top"
        disableHoverListener={!type}
      >
        <Box>
          <Box mt="10px">
            <CustomLinearProgress
              variant="determinate"
              value={(progressValue / (maxValue - minValue)) * 100}
              isLargeValue={value > Math.floor(maxValue / 2)}
              colorBar={colorBar}
            />
          </Box>
          <Box mt="4px" display="flex" justifyContent="space-between">
            <Typography className={classes.value} variant="body1">{value}</Typography>
            <Typography className={classes.value} variant="body1">{maxValue}</Typography>
          </Box>
        </Box>
      </CustomTooltip>
      {!isAdmin && (
        <Box mt={1}>
          <Button
            variant="outlined"
            size="small"
            style={{ padding: '2px 26px', lineHeight: '16px' }}
            onClick={() => history.push(baseRoute + '/plans')}
          >
            {title === 'TEAM MEMBERS' ? 'INCREASE LIMIT' : 'BUY MORE'}
          </Button>
        </Box>
      )}
    </Box>
  )
}

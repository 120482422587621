import React, { useEffect, useState, useCallback } from 'react';
import { Box, Grid, Typography, FormControlLabel, IconButton } from '@material-ui/core';
import CheckboxLight from '@components/inputs/checkbox-light';
import CustomButton from '@views/common/button';
import history from '@app/history';
import NewInnerHeader from '@components/layout/new-inner-header';
import { ChevronLeftIcon } from '@app/icons';
import { useStyles } from '../styles';

import LinkButton from '@components/buttons/link-button';
import { useDispatch, useSelector } from 'react-redux';
import { backgroundCheck, searchHistory, loader } from '@store/actions';

import { DownloadIcon } from '@app/icons';
import CustomIconButton from '@components/buttons/button-with-icon';
import HistoryRightCard from './components/new-search-components/history-right-card';
import HeaderTitleLine from './components/new-search-components/header-title-line';
import CustomTooltip from '@components/controls/tooltip';

import { useLocation, useParams } from 'react-router-dom';
import { exportBackgroundCheckPDF } from '@utils/exportPDF';
import { clientApi } from '@app/api';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import clsx from 'clsx';

import SetMonitoring from './components/new-search-components/set-monitoring';
import CheckAnimation from '@components/misc/check-animation';

const fields = ['country', 'gender', 'name', 'type', 'yob', 'registrationNumber', 'searchProvider'];

const initialPage = 0;
const initialQuantity = 36;

const pageSize = 20;

export default function RepeatSearch(props) {
  // eslint-disable-next-line
  const { steps, onNext, onNextAlt, isSearchHistory, hideMonitoring, onRedirect, onWhitelisted, onUpdateExisting } = props;
  const { profilesVersionId } = useSelector(store => store.searchHistory);
  // eslint-disable-next-line
  const { currentSearch, isShowWhitelisted } = useSelector(store => store.backgroundCheck);

  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const searchId = search.get('searchId');
  const searchName = search.get('searchName');
  const searchType = search.get('searchType');
  const { clientId } = useParams();

  const dispatch = useDispatch();

  const [savedClient, setSavedClient] = useState(null);
  const [isShowRightCard, setIsShowRightCard] = useState(!!profilesVersionId);
  const [tags, setTags] = useState([]);
  const [isResize, setIsResize] = useState(false);
  const [isNoResetResize, setIsNoResetResize] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [quantitySearches, setQuantitySearches] = useState({
    count: null,
    pages: null
  });

  const classes = useStyles({ isShowRightCard });

  const isSaveToPortfolio = location.pathname === '/search-history/save-to-portfolio/no-hits/' || location.pathname === '/background-check/repeat-search/';
  const actions = [
    {  className: 'customButtonBordered', label: 'RUN NEW SEARCH', action: () => handleNewSearch() },
    {  className: 'customButtonStandard', label: savedClient ? 'VIEW IN PORTFOLIO' : 'SAVE IN PORTFOLIO', action: () => linkToPortfolio() },
    {  linkButton: true, label: 'ATTACH TO EXISTING', action: () => updateExisting() },
  ];

  const altActions = [
    { className: 'customButtonBordered', label: 'RUN NEW SEARCH', action: () => altHandleNewSearch() },
    { className: 'customButtonStandard', label: 'SET MONITORING SETTINGS', action: () => setMonitoring() },
  ];

  const clientSearch = useCallback(async () => {
    const res = await clientApi.client.show(currentSearch.linkedClientId);

    if (res.status === 200) {
      setSavedClient(res.data);
    }
  }, [currentSearch, setSavedClient])

  const getSearchVersions = useCallback(async (lastRunOn) => {
    const res = await clientApi.searchVersion.getSearchVersions(searchId, pageNumber, pageSize);

    if (res.status === 200 && !!res.data.entries.length) {
      dispatch(searchHistory.setSearchVersion([
        {
          id: 'last_version',
          searchId: +searchId,
          performedOn: currentSearch.lastRunOn,
          acknowledged: currentSearch.acknowledged,
        },
        ...res.data.entries
      ]));
      !quantitySearches.count && setQuantitySearches({
        count: res.data.count,
        pages: res.data.pages
      });
    }
  }, [searchId, currentSearch, quantitySearches, pageNumber, dispatch])

  const prepareCurrentSearchData = useCallback((currentSearch) => {
    const currTags = [];

    for (const key in currentSearch) {
      if (key === 'listTypes') {
        currTags.push(...currentSearch[key])
      } else if (!!fields.includes(key) && !!currentSearch[key]) {
        currTags.push(currentSearch[key])
      }
    }

    setTags(currTags);
  }, [])

  const getCurrentSearch = async () => {
    const res = profilesVersionId
      ? (await clientApi.searchVersion.getSearchByVersionId(profilesVersionId))
      : (await clientApi.search.getSearch(searchId));

    if (res.status === 200) {
      let data = {};
      for (const key in res.data) {
        if (!!res.data[key] && key !== 'searchId' && key !== 'createdOn') {
          data = { ...data, [key]: res.data[key] }
        }
      }

      dispatch(backgroundCheck.updateCurrentSearch(res.data));
      prepareCurrentSearchData(res.data);
    }
  }

  useEffect(() => {
    getCurrentSearch();
  }, [searchId, profilesVersionId]) // eslint-disable-line

  useEffect(() => {
    if (!!currentSearch?.linkedClientId) {
      clientSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearch])

  useEffect(() => {
    getSearchVersions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearch, pageNumber])

  const handleNewSearch = useCallback(() => {
    dispatch(backgroundCheck.setDefault());
    history.push('/background-check');
  }, [dispatch])

  const linkToPortfolio = useCallback(() => {
    savedClient
      ? history.push(`/portfolio/view/${savedClient?.id}`)
      : history.push(`/portfolio/create-${searchType.toLowerCase()}/${searchId}`);
  }, [savedClient, searchType, searchId])

  const updateExisting = useCallback(() => {
    onUpdateExisting({ searchId, type: searchType.toLowerCase() });
  }, [searchType, searchId, onUpdateExisting])

  const altHandleNewSearch = useCallback(() => {
    onNextAlt({ clientId });
  }, [clientId, onNextAlt])

  const setMonitoring = useCallback(async () => {
    if (isSearchHistory) {
      return onNext({ searchId, clientId, searchType });
    }

    const client = (await clientApi.client.linkToSearch(clientId, searchId)).data;
    await clientApi.search.updateSearchReview(searchId, { bgCheckRiskRating: 'Low' })

    if (client.clientType === 'PERSON') {
      enqueueSnackbar(`"${client.firstName} ${client.lastName}" details were successfully updated!`, { variant: 'success' });
    } else {
      enqueueSnackbar(`"${client.name}" details were successfully updated!`, { variant: 'success' });
    }

    onNext({ searchId, clientId, searchType });
  }, [isSearchHistory, searchId, clientId, onNext, searchType, enqueueSnackbar])

  const handleExport = useCallback(async () => {
    dispatch(loader.setLoader(true));

    if (profilesVersionId) {
      const subLink = 'search-version/';
      await exportBackgroundCheckPDF({ searchId: profilesVersionId, subLink });
    } else {
      await exportBackgroundCheckPDF({ searchId });
    }
    enqueueSnackbar('The report was successfully exported!', { variant: 'success' });
  }, [profilesVersionId, searchId, dispatch, enqueueSnackbar])

  const handleShowRightCard = useCallback((bool) => {
    setIsShowRightCard(bool);
  }, [setIsShowRightCard])

  const redirectLink = () => {
    onRedirect({ searchId, searchName, searchType, clientId });
  }

  const handleShowWhitelisted = useCallback(async (e) => {
    dispatch(backgroundCheck.setShowWhitelisted(e.target.checked));
    !!e.target.checked
      ? localStorage.setItem('isShowWhitelisted', e.target.checked)
      : localStorage.removeItem('isShowWhitelisted');

    const isWhitelisted = e.target.checked;
    const { status, data } = await clientApi.search.getProfiles(searchId, initialPage, initialQuantity, isWhitelisted);

    if (status === 200 && !isEmpty(data.entries)) {
      onWhitelisted({ searchId, searchName, searchType, clientId })
    }
  }, [onWhitelisted, dispatch, searchId, searchName, searchType, clientId])

  const renderTitleLine = (
    <Box ml={'50px'} mr={isShowRightCard ? '55px' : '22px'}>
      <HeaderTitleLine
        tags={tags}
        lastRunOn={currentSearch.lastRunOn}
        searchName={currentSearch.name || searchName}
        isShowRightCard={isShowRightCard}
        setIsShowRightCard={setIsShowRightCard}
        setIsResize={setIsResize}
        isResize={isResize}
        setIsNoResetResize={setIsNoResetResize}
        isNoResetResize={isNoResetResize}
        onBack={steps ? undefined : () => history.goBack()}
        getCurrentSearch={getCurrentSearch}
        currentSearch={currentSearch}
      />
    </Box>
  )

  return (
    <React.Fragment>
      {steps &&
        <React.Fragment>
          <Box px={6} width={isShowRightCard ? 'calc(100% - 400px)' : 'auto'}>
            <NewInnerHeader
              title="Choose Search Profile"
              stepper={{
                currentStep: steps?.currentStep || 4,
                stepsCount: steps?.stepsCount || 5
              }}
              leftAligned
              buttons={
                <IconButton onClick={() => history.goBack()} style={{ padding: 0 }}>
                  <ChevronLeftIcon style={{ width: 36, height: 36 }} />
                </IconButton>
              }
            />
          </Box>
          <Box mt={2}>
            {renderTitleLine}
          </Box>
          <Grid container display="flex" alignItems="center" justify="flex-end" className={classes.repeatContentWidth}>
            <Box display="flex" alignItems="center" justifyContent="flex-end" mt={'5px'} mr={'32px'} width={'100%'}>
              <FormControlLabel
                classes={{
                  root: clsx([classes.showWhitelistedRoot, !!isShowWhitelisted && classes.pulsingButton]),
                  label: classes.showWhitelistedLabel
                }}
                labelPlacement="start"
                label="Include Whitelisted"
                control={
                  <CheckboxLight
                    className="checkbox-user-card"
                  />
                }
                onChange={handleShowWhitelisted}
                checked={!!isShowWhitelisted}
                disabled={!!profilesVersionId}
              />
            </Box>
          </Grid>
        </React.Fragment>
      }
      {!steps &&
        <React.Fragment>
          <Box mt={4}>
            {renderTitleLine}
          </Box>
          <Grid container display="flex" alignItems="center" justify="flex-end" className={classes.repeatContentWidth}>
            <Box display="flex" alignItems="center" justifyContent="flex-end" mt={'5px'} mr={'40px'} width={'100%'}>
              {!hideMonitoring && (
                <SetMonitoring />
              )}
              <CustomTooltip
                title={!!profilesVersionId
                  ? 'Only the last version can be updated, the previous versions are read only'
                  : 'Show Whitelisted'
                }
                placement="top"
              >
                <FormControlLabel
                  classes={{
                    root: classes.showWhitelistedRoot,
                    label: classes.showWhitelistedLabel
                  }}
                  labelPlacement="start"
                  label="Show Whitelisted"
                  control={
                    <CheckboxLight
                      className="checkbox-user-card"
                    />
                  }
                  onChange={handleShowWhitelisted}
                  checked={!!isShowWhitelisted}
                  disabled={!!profilesVersionId}
                />
              </CustomTooltip>
              <CustomIconButton
                onClick={() => handleExport()}
                startIcon={<DownloadIcon />}
                className="minLabel"
              >
                Export Report
              </CustomIconButton>
            </Box>
          </Grid>
        </React.Fragment>
      }
      <Box height={'calc(100% - 155px)'} overflow={'auto'}>
        <Grid
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          className={clsx(classes.repeatWrapper, classes.repeatContentWidth)}
        >
          <CheckAnimation />
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={4}>
            <Typography variant="h3" className={classes.repeatText}>{currentSearch.name || searchName} does not appear on any of the sanctions lists.</Typography>
            <Typography variant="h3" className={classes.repeatText}>You’re good to go!</Typography>
          </Box>
          {!profilesVersionId && (
            <div className={classes.repeatButtonWrapper}>
              {((steps && !isSaveToPortfolio) ? altActions : actions).map(item => (
                item.linkButton ?
                  <LinkButton
                    key={`repeat-search-${item.label}`}
                    style={{ width: '250px' }}
                    onClick={item.action}
                  >
                    {item.label}
                  </LinkButton>
                  :
                  <CustomButton
                    key={`repeat-search-${item.label}`}
                    action={item.action}
                    label={item.label}
                    customClasses={item.className}
                  />
              ))}
            </div>
          )}
        </Grid>
      </Box>
      <HistoryRightCard
        isShowRightCard={isShowRightCard}
        setIsShowRightCard={bool => handleShowRightCard(bool)}
        setIsResize={bool => setIsResize(bool)}
        isNoResetResize={isNoResetResize}
        redirectLink={redirectLink}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        quantitySearches={quantitySearches}
        pageSize={pageSize}
      />
    </React.Fragment>
  )
}

import React from  'react';
import PageLayout from '@components/layout/page-layout';
import history from '@app/history';
import History from './search-history';

import RepeatSearch from '../background-check/subpages/repeat-search';
import NewSearch from '../background-check/subpages/new-search';
import UserNoteList from '../background-check/subpages/user-note-list';
import UpdateExisting from '../background-check/subpages/update-existing';
import UpdateExistingConfirm from '../background-check/subpages/update-existing-confirm';

import { Route, Switch } from 'react-router';
import UpdateExistingMonitoring from '../background-check/subpages/update-existing-monitoring';
import MonitoringUserNoteList from '../ongoing-monitoring/monitoring-bg-user-note-list';
import LoggedNotFound from '@views/protected/not-found';

export default function SearchHistory(props) {
  return (
    <React.Fragment>
      <PageLayout>
        <Switch>
          {/* Update existing from user note list view */}
          <Route exact path="/search-history/update-existing/:type/:searchId/:profileId">
            <UpdateExisting
              flow="search-history"
              steps={{ currentStep: 1, stepsCount: 3 }}
              onNext={(params) => {
                const link = `/search-history/update-existing-confirm/${params.type}/${params.searchId}/${params.profileId}/${params.clientId}`;
                params.isReplace
                  ? history.replace(link)
                  : history.push(link)
              }}
            />
          </Route>
          <Route exact path="/search-history/update-existing-confirm/:type/:searchId/:profileId/:clientId/:tab?">
            <UpdateExistingConfirm
              getBaseUri={(params) =>
              `/search-history/update-existing-confirm/${params.type}/${params.searchId}/${params.profileId}/${params.clientId}`}
              flow="search-history"
              steps={{ currentStep: 2, stepsCount: 3 }}
              onNext={(params) =>
                history.push(`/search-history/update-existing-monitoring/${params.searchId}/${params.clientId}`)
              }
            />
          </Route>
          <Route exact path="/search-history/update-existing-monitoring/:searchId/:clientId">
            <UpdateExistingMonitoring
              flow="search-history"
              steps={{ currentStep: 3, stepsCount: 3 }}
              onNext={(params) =>
                history.push('/search-history')}
            />
          </Route>

          {/* Save to portfolio from table view */}
          <Route exact path="/search-history/save-to-portfolio/results">
            <NewSearch
              flow="search-history-table"
              steps={{ currentStep: 1, stepsCount: 2 }}
              onNext={(params) =>
                history.push(`/search-history/save-to-portfolio/user-note-list?clientId=${params.clientId}&searchType=${params.searchType}&searchId=${params.searchId}&profileId=${params.profileId}&searchName=${params.searchName}`)}
              onNextAlt={(params) =>
                history.replace(`/portfolio/create-${params.type}/${params.searchId}`)}
            />
          </Route>
          <Route exact path="/search-history/save-to-portfolio/user-note-list">
            <MonitoringUserNoteList
              steps={{ currentStep: 2, stepsCount: 2 }}
              isSaveToPortfolio
            />
          </Route>

          {/* Update existing from table view */}
          <Route exact path="/search-history/update-existing/:type/:searchId">
            <UpdateExisting
              flow="search-history-table"
              steps={{ currentStep: 1, stepsCount: 5 }}
              onNext={(params) => {
                const link = `/search-history/update-existing-confirm-no-profile/${params.type}/${params.searchId}/${params.clientId}`;
                params.isReplace
                  ? history.replace(link)
                  : history.push(link)
              }}
            />
          </Route>
          <Route exact path="/search-history/update-existing-confirm-no-profile/:type/:searchId/:clientId/:tab?">
            <UpdateExistingConfirm
              getBaseUri={(params) =>
              `/search-history/update-existing-confirm-no-profile/${params.type}/${params.searchId}/${params.clientId}`}
              flow="search-history-table"
              steps={{ currentStep: 2, stepsCount: 5 }}
              onNext={(params) =>
                history.push(`/search-history/update-existing-results/${params.clientId}?searchType=${params.searchType}&searchId=${params.searchId}&searchName=${params.searchName}`)
              }
            />
          </Route>
          <Route exact path="/search-history/update-existing-results/:clientId">
            <NewSearch
              flow="search-history-table"
              steps={{ currentStep: 3, stepsCount: 5 }}
              onNext={(params) =>
                history.push(`/search-history/update-existing-user-note-list/${params.clientId}?searchType=${params.searchType}&searchId=${params.searchId}&profileId=${params.profileId}&searchName=${params.searchName}`)
              }
              onNextAlt={(params) =>
                history.replace(`/search-history/update-existing-no-hits/${params.clientId}?searchId=${params.searchId}&searchName=${params.searchName}&searchType=${params.searchType}`)
              }
            />
          </Route>
          <Route exact path="/search-history/update-existing-user-note-list/:clientId">
            <MonitoringUserNoteList
              steps={{ currentStep: 4, stepsCount: 5 }}
              onNext={(params) =>
                history.push(`/search-history/update-existing-monitoring-hits/${params.searchId}/${params.clientId}`)
              }
            />
          </Route>
          <Route exact path="/search-history/update-existing-monitoring-hits/:searchId/:clientId">
            <UpdateExistingMonitoring
              flow="search-history"
              steps={{ currentStep: 5, stepsCount: 5 }}
              onNext={(params) =>
                history.push('/search-history')}
            />
          </Route>

          {/* Update existing from table view no hits */}
          <Route exact path="/search-history/update-existing-no-hits/:clientId">
            <RepeatSearch
              isSearchHistory
              steps={{ currentStep: 4, stepsCount: 5 }}
              onNext={(params) =>
                history.push(`/search-history/update-existing-monitoring-no-hits/${params.searchId}/${params.clientId}`)
              }
              onNextAlt={(params) =>
                history.push(`/ongoing-monitoring/add-monitoring-bg-check/${params.clientId}`)
              }
              onRedirect={(params) =>
                history.push(`/search-history/update-existing-no-hits/${params.clientId}?searchId=${params.searchId}&searchName=${params.searchName}&searchType=${params.searchType}`)
              }
              onWhitelisted={(params) =>
                history.push(`/search-history/update-existing-results/${params.clientId}?searchId=${params.searchId}&searchName=${params.searchName}&searchType=${params.searchType}`)
              }
              onUpdateExisting={(params) => {
                history.push(`/search-history/update-existing/${params.type}/${params.searchId}`)
              }}
            />
          </Route>
          <Route exact path="/search-history/update-existing-monitoring-no-hits/:searchId/:clientId">
            <UpdateExistingMonitoring
              flow="search-history"
              steps={{ currentStep: 5, stepsCount: 5 }}
              onNext={(params) =>
                history.push('/search-history')}
            />
          </Route>

          {/* Update monitoring from table with linked client */}
          <Route exact path="/search-history/update-existing-monitoring-table/:searchId/:clientId">
            <UpdateExistingMonitoring
              flow="search-history"
              steps={{ currentStep: 1, stepsCount: 1 }}
              onNext={(params) =>
                history.push('/search-history')}
            />
          </Route>

          <Route exact path="/search-history" component={History} />
          <Route exact path="/search-history/results">
            <NewSearch
              onNext={(params) =>
                history.push(`/search-history/user-note-list?searchId=${params.searchId}&profileId=${params.profileId}&searchName=${params.searchName}&profileClientId=${params.clientId || ''}`)
              }
              onNextAlt={(params) =>
                history.replace(`/search-history/repeat-search/?searchId=${params.searchId}&searchName=${params.searchName}&searchType=${params.searchType}`)
              }
            />
          </Route>
          <Route exact path="/search-history/user-note-list">
            <UserNoteList
              baseRoute="search-history"
            />
          </Route>
          <Route exact path="/search-history/repeat-search">
            <RepeatSearch
              onWhitelisted={(params) =>
                history.push(`/search-history/results?searchId=${params.searchId}&searchName=${params.searchName}&searchType=${params.searchType}`)
              }
              onRedirect={(params) =>
                history.push(`/search-history/repeat-search?searchId=${params.searchId}&searchName=${params.searchName}&searchType=${params.searchType}`)
              }
              onUpdateExisting={(params) => {
                history.push(`/search-history/update-existing/${params.type}/${params.searchId}`)
              }}
            />
          </Route>
          <Route path="*" exact>
            <LoggedNotFound />
          </Route>
        </Switch>
      </PageLayout>
    </React.Fragment>
  )
};

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.15)',
    borderRadius: '12px',
  },
  searchCreditWrapper: {
    background: theme.palette.background.GY1,
    borderRadius: '12px',
  },
  searchCreditValue: {
    fontStyle: 'normal',
    fontWeight: '900',
    fontSize: '13px',
    lineHeight: '18px',
    color: theme.palette.text.LB3,
    '&:hover': {
      cursor: 'default'
    }
  },
  personWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  switchActions: {
    marginLeft: 50,
  },
  inputPersonWrapper: {
    margin: '5px 0',
  },
  inputLabel: {
    fontSize: '12px',
    top: '-5px',
    left: '-8px',
    opacity: 0.7
  },
  advancedSelect: {
    padding: '10px',
    '&.MuiInputBase-input': {
      fontSize: '12px'
    }
  },
  advancedInput: {
    padding: '12px',
    '&.MuiInputBase-input': {
      fontSize: '12px'
    }
  },
  rootControlLabel: {
    marginRight: 0,
  },
  searchButton: {
    background: 'linear-gradient(270deg, #9A7C6A 0%, #7A5B44 100%)',
    borderRadius: '12px',
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 700,
  },
  errorWrapper: {
    height: '40px',
    backgroundColor: '#F8EEEE',
    borderRadius: '12px',
    padding: '15px'
  },
  errorText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#232323',
    marginLeft: '5px'
  },
  linkified: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.palette.text.LB3,
    cursor: 'pointer'
  },
  searchModalButton: {
    width: '205px',
  },
  modalContentText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '29px',
    color: theme.palette.text.BL1,
  },
  modalContentSubtitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '29px',
    color: theme.palette.text.BL1,
  },
  marginRight: {
    marginRight: '5px'
  },
  advancedButtonLabel: {
    fontFamily: 'Quicksand',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '38px',
    color: theme.palette.text.LB3,
    marginLeft: '-8px',
  }
}));
import React from  'react';
import CustomButton from '@views/common/button';
import CustomTooltip from '@components/controls/tooltip';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  customButtonWidth: {
    width: '230px',
  },
}))

export default function RenderAcknowledgButton({ action }) {
  const classes = useStyles();

  return (
    <CustomTooltip title="Confirm that you’ve reviewed the changes" placement="top">
      <span>
        <CustomButton
          action={action}
          label={'ACKNOWLEDGE'}
          customClasses="customButtonStandard"
          propsClassses={classes.customButtonWidth}
        />
      </span>
    </CustomTooltip>
  )}
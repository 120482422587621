const auth = (state = {
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
  side: localStorage.getItem('side') || 'client', // client/admin
  userIsAdmin: localStorage.getItem('userIsAdmin') || null,
  lastVisitedPage: '',
}, action) => {

  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.user }
    case 'SET_SIDE':
      return { ...state, side: action.side }
    case 'SET_ADMIN':
      return { ...state, userIsAdmin: action.userIsAdmin }
    case 'SET_LAST_VISITED_PAGE':
      return { ...state, lastVisitedPage: action.lastVisitedPage }
    default:
      return state
  }
}

export default auth

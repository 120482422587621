import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography
} from '@material-ui/core';
import * as yup from 'yup';
import parseYupErrors from '@app/yup';
import OutlinedPassword from '@components/inputs/outlined-password';
import auth from '@app/auth';
import { useSnackbar } from 'notistack';
import { EditIcon } from '@app/icons';
import useErrorState from '@utils/errorState';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { useSelector } from 'react-redux';

const selectUser = state => state.auth.user;

export default function EmailAddress(props) {
  const { enqueueSnackbar } = useSnackbar();
  const userData = useSelector(selectUser);
  const [errors, setErrors] = useErrorState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [model, setModel] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    setModel({
      email: '',
      password: '',
    })
  }, [dialogOpen])

  const handleChange = (prop) => (event) => {
    const newModel = { ...model, [prop]: event.target.value };
    setModel(newModel);

    if (errors[prop]) {
      schema.validateAt(prop, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [prop]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [prop]: err.message });
        });
    }
  };

  const onSave = () => {
    setErrors({});

    schema
      .validate(model, { abortEarly: false })
      .then(function (valid) {
        auth.changeEmail(model.email, model.password)
          .then(data => {
            enqueueSnackbar('Password was successfully updated!', { variant: 'success' });
            props.fetchUser();
            setDialogOpen(false);
          })
          .catch(err => {
            if (err.code === 'NotAuthorizedException') {
              setErrors({
                password: 'Incorrect password'
              }, true)
            } else {
              setErrors({
                password_confirm: err.message
              }, true)
            }
          })
      })
      .catch(function (err) {
        setErrors(parseYupErrors(err), true);
      });
  }

  const schema = yup.object().shape({
    email: yup.string().lowercase().trim().email('Invalid email format').required().max(50),
    password: yup.string().label('Password').required(),
  });

  return (
    <Grid container alignItems="center">
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="xs">
        <DialogTitle>Change Email</DialogTitle>
        <DialogContent>
          <FormControl id="email" fullWidth variant="outlined" error={!!errors['email']}>
            <InputLabel htmlFor="email">New Email</InputLabel>
            <OutlinedInput
              value={model.email}
              onChange={handleChange('email')}
            />
            {!!errors['email'] &&
                            <FormHelperText>{errors['email'] || null}</FormHelperText>
            }
          </FormControl>
          <FormControl id="password" fullWidth variant="outlined" error={!!errors['password']}>
            <InputLabel htmlFor="password">Current Password</InputLabel>
            <OutlinedPassword value={model.password} onChange={handleChange('password')} />
            {!!errors['password'] &&
                            <FormHelperText>{errors['password'] || null}</FormHelperText>
            }
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button style={{ width: '160px' }} variant={'outlined'} onClick={() => setDialogOpen(false)}>
                        CANCEL
          </Button>
          <Button style={{ width: '160px' }} variant={'contained'} onClick={onSave}>
                        SAVE
          </Button>
        </DialogActions>
      </Dialog>

      <Grid item xs={6}>
        <Typography variant="h5">Email Address</Typography>
      </Grid>
      <Grid item xs={6} container justify="flex-end">
        <Box style={{ visibility: 'hidden' }}>
          <ButtonWithIcon
            startIcon={<EditIcon />}
            onClick={() => setDialogOpen(true)}
          >
                        Edit
          </ButtonWithIcon>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box mt={1}>
          <FormControl
            fullWidth
            id="email"
            variant="outlined"
            disabled
          >
            <InputLabel>Email</InputLabel>
            <OutlinedInput
              value={userData.email}
            />
          </FormControl>
        </Box>
      </Grid>
    </Grid>

  );
}
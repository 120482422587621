import React from 'react';
import { SidesLayout, SidesLayoutRight } from '../../components/layout/sides-layout';
import {
  Box,
  Typography,
  Grid,
  Button, Link
} from '@material-ui/core';

import history from '@app/history';

export default function RegistrationComingSoon(props) {

  return (
    <SidesLayout>
      <SidesLayoutRight
        header={
          <Typography>
            <React.Fragment>
                            Already registered?&ensp;
              <Link
                variant={'body1'}
                onClick={() => {
                  history.push('/login')
                }}
              >
                                Login
              </Link>
            </React.Fragment>
          </Typography>
        }
      >

        <Grid container justify={'center'} alignItems={'center'}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Box>
                <Typography variant={'h1'} align={'center'}>Registration Coming Soon</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display={'flex'} my={5} justifyContent={'center'}>
                <Typography variant={'subtitle1'} align={'center'} style={{maxWidth:'600px'}}>
                                    We're working hard on creating the best digital experience for managing your
                                    KYC. This will take just a few more weeks.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display={'flex'} justifyContent={'center'}>
                <Button style={{width: '160px'}} variant={'contained'} onClick={() => history.goBack()}>
                                    BACK
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>

      </SidesLayoutRight>
    </SidesLayout>
  );
}
import React from 'react';
import { Modal } from '@components/modals';

export default function NewRiskRatingDialog({open, onClose, onNew, onExisting}) {

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title={'New Risk Rating'}
        mainText={'Starting from an existing risk rating will load the last Risk Assessment you completed for this client, including your answers, comments, and selected ratings.'}
        actions={
          [
            {
              type: 'main',
              label: 'NEW',
              action: onNew,
              style: { width: 200 },
            },
            {
              type: 'secondary',
              label: 'FROM EXISTING',
              action: onExisting,
              style: { width: 200 },
            },
          ]
        }
        actionsDirection="row"
      />
    </React.Fragment>
  );
}
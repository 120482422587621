import React, { useState, useEffect, useMemo } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import debounce from 'awesome-debounce-promise';

import history from '@app/history';
import {
  clientDocument,
  clientDocumentType,
  clientDocumentCategory,
  client,
} from '@app/api/client';
import { Delete2Icon, DocumentsVerifiedByEidv } from '@app/icons';
import NoResultsFound from '@components/layout/no-results-found';
import OutlinedSearch from '@components/inputs/outlined-search';
import FilterWithContext from '@components/dropdowns/filter-with-context';
import TableList, { TCELL_TYPES } from '@components/lists/table-list';
import CustomTooltip from '@components/controls/tooltip';
import CustomChip from '@components/controls/custom-chip';
import InlineHeadBadge from '@components/badges/inline-head-badge';
import DocumentDeleteDialog from '@components/complex/client-view/kyc-documents/components/document-delete-dialog';
// import DocumentDialog from '@components/complex/client-view/kyc-documents/components/document-dialog';
import PdfViewer from '@components/pdf/pdf-viewer';
import downloadUrl from '@utils/downloadUrl';
import ImageViewer from '@components/image/image-viewer';
import DocumentFilters from '../components/document-filters';
import { getSortField, prepareDocuments } from '../utils/prepareDocumentsData';
import { IMAGES_EXTENSION, PAGE_SIZE, SORTABLE_COLUMNS } from '../constants';
import { outdatedChip } from '../../../../../components/badges/outdated-chip';

const useStyles = makeStyles((theme) => ({
  chip: {
    '& .MuiChip-label': {
      paddingRight: '4px',
    },
    verticalAlign: 'unset',
  },
  deleteChipIcon: {
    height: '14px',
    width: '14px',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: theme.palette.background.GY2,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '-3px',
  },
}));

const verifiedByEidv = (item) => {
  if (item.verifiedBy === 'VERIFIED_VIA_EIDV') {
    return (
      <CustomTooltip title="Verified via eIDV" placement="top">
        <Box ml={1} display="flex" alignItems="center">
          <DocumentsVerifiedByEidv />
        </Box>
      </CustomTooltip>
    )
  }
  return null;
}

const combineDecoration = (item) => {
  return (
    <React.Fragment>
      {verifiedByEidv(item)}
      {outdatedChip(item)}
    </React.Fragment>
  )
}

const DOCUMENTS_COLUMNS = [
  { field: 'category', headerName: 'Category', bold: true, cellType: TCELL_TYPES.TEXT_ICON, decoration: combineDecoration },
  { field: 'documentType', headerName: 'File Type' },
  {
    field: 'documentName',
    headerName: 'File Name',
    cellType: TCELL_TYPES.TRUNCATED,
    useTooltip: true,
    colProps: {
      width: '5%',
    },
  },
  { field: 'entityName', headerName: 'Entity Name' },
  { field: 'folderName', headerName: 'Folder' },
  { field: 'uploadDate', headerName: 'Date Added' },
  { field: 'issueDate', headerName: 'Issue Date' },
  { field: 'expiryDate', headerName: 'Expiry Date' },
  { field: 'statusLabel', headerName: 'Status' },
  // { field: "docVersion", headerName: "Doc Version" },
];

const debouncedSearch = debounce(clientDocument.search, 500);

export default function AllDocuments() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [documents, setDocuments] = useState([]);
  const [pages, setPages] = useState('');
  const [count, setCount] = useState('');
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(0);
  const [categories, setCategories] = useState({
    system: [],
    user: [],
    all: [],
  });
  const [sortDirection, setSortDirection] = useState('DESC');
  const [sortField, setSortField] = useState('uploadDate');
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [expiryDateRange, setExpiryDateRange] = useState({
    from: null,
    to: null,
  });

  //three dots actions
  //delete document
  const [pendingDocumentDelete, setPendingDocumentDelete] = useState(null);
  //veiwers show
  const [pdfUrl, setPdfUrl] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  //update document action
  // const [pendingUpdateDocument, setPendingUpdateDocument] = useState(null);
  // const [pendingDocumentDialogShown, setPendingDocumentDialogShown] =
  //   useState(false);
  const [checkedCategories, setCheckedCategories] = useState([]);

  const generateParams = () => {
    return {
      categoryIds: checkedCategories,
      pageSize: PAGE_SIZE,
      fromExpirationDate: moment(expiryDateRange.from).utc(true).startOf('day'),
      toExpirationDate: moment(expiryDateRange.to).utc(true).endOf('day'),
      sortBy: getSortField[sortField],
      sortDirection: sortDirection,
      pageNumber: page,
      searchKeyword: search?.trim() || null,
    };
  };

  const fetchItems = async (debounce = false) => {
    const params = generateParams();
        
    if (params.searchKeyword?.length < 2 && params.searchKeyword?.length) {
      enqueueSnackbar('Search keyword should have at least 2 characters!', {
        variant: 'error',
      });
      return;
    }

    const data = debounce
      ? (await debouncedSearch(params)).data
      : (await clientDocument.search(params)).data;
    if (data) {
      setPages(data.pages);
      setCount(data.count);
      const types = await getTypes(data);
      const clients = await getClients(data);
      const newDocs = data.entries.map((item, index) => {
        const documentType = types.find((type) => type.id === item.typeId);
        const category = categories?.all?.find(
          (category) => category.id === item.categoryId
        );
        const client = clients.find(
          (client) => client.data?.id === item.associations[0]?.clientId
        );
        const clientName =
          client.data.clientType === 'PERSON'
            ? `${client.data?.firstName} ${client.data?.lastName}`
            : client.data?.name;
        return {
          ...item,
          folderName: client.data.folderName,
          entityName: clientName,
          documentType: documentType.name,
          category: category?.name,
          statusLabel: !!item.status && getStatus(item.status),
        };
      });
      const prepareData = prepareDocuments(newDocs);
      setDocuments(prepareData);
    }
  };

  const getClients = async (documents) => {
    const tmp = documents.entries.map((item) => item.associations[0]?.clientId);
    const clientsArray = [...new Set(tmp)];
    const clients = await Promise.all(
      clientsArray.map((item) => client.show(item))
    );
    return clients;
  };
  const getTypes = async (documents) => {
    const tmp = documents.entries.map((item) => item.typeId);
    const typeArray = [...new Set(tmp)];
    const types = await Promise.all(
      typeArray.map(async (item) => (await clientDocumentType.show(item)).data)
    );
    return types;
  };

  const getCategory = async () => {
    const categoriesResponse = (await clientDocumentCategory.index()).data;
    if (categoriesResponse) {
      const userCategories = categoriesResponse.entries?.filter(
        (item) => item.ownedBy === 'USER'
      );
      const systemCategories = categoriesResponse.entries?.filter(
        (item) => item.ownedBy === 'SYSTEM'
      );
      setCategories({
        system: systemCategories,
        user: userCategories,
        all: categoriesResponse.entries,
      });
    }
  };

  useEffect(() => {
    if (!!categories.all.length) {
      fetchItems();
    }
    // eslint-disable-next-line
  }, [
    sortField,
    sortDirection,
    page,
    expiryDateRange,
    categories,
    checkedCategories,
  ]);


  useEffect(() => {
    if (search !== null) {
      const timeOutId = setTimeout(() => fetchItems(true), 1000);
      return () => clearTimeout(timeOutId);
    }
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    getCategory();
    // eslint-disable-next-line
  }, []);

  const onSort = (value) => {
    setPage(0);
    if (sortField !== value) {
      setSortField(value);
      setSortDirection('ASC');
    } else {
      setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
    }
  };

  const onDocumentDeleteConfirm = async () => {
    await clientDocument.remove(pendingDocumentDelete.id);
    await fetchItems();
    enqueueSnackbar(
      `Document "${pendingDocumentDelete.fileName}" was successfully deleted!`,
      { variant: 'success' }
    );
    setPendingDocumentDelete(null);
  };
  //update document
  // const onStartDocumentUpdateFlow = async (document) => {
  //   setPendingUpdateDocument(document);
  //   if (document.category) {
  //   }
  //   setPendingDocumentDialogShown(true);
  // };
  // const closeDocumentDialog = () => {
  //   setPendingDocumentDialogShown(false);
  //   setTimeout(() => {
  //     setPendingUpdateDocument(null);
  //   }, 150);
  // };

  //download document
  const onDownloadDocument = async (item) => {
    const document = (await clientDocument.show(item.id)).data;
    downloadUrl(document.downloadUrl, document.fileName);
    enqueueSnackbar(
      `Document "${item.fileName}" was successfully downloaded!`,
      { variant: 'success' }
    );
  };

  const handleSearch = (text) => {
    setPage(0);
    setSearch(text);
  };
  const handleSwitch = (e) => {
    setPage(0);
    if (e.target.checked) {
      setCheckedCategories((prevState) => [...prevState, e.target.value]);
    } else {
      const newValues = checkedCategories.filter((p) => p !== e.target.value);
      setCheckedCategories(newValues);
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case 'EXPIRED':
        return <CustomChip label="EXPIRED" type="error" />;
      case 'EXPIRES_SOON':
        return <CustomChip label="EXPIRES SOON" type="warning" />;
      case 'UP_TO_DATE':
        return <CustomChip label="UP TO DATE" type="SUCCESS" />;
      default:
        return status;
    }
  };
  const getFile = async (item) => {
    const extension = item.fileName.split('.').pop();
    if (extension.toLowerCase() === 'pdf') {
      const url = (await clientDocument.show(item.id)).data?.downloadUrl;
      setPdfUrl(url);
      return setSelectedDocument(item);
    }
    if (IMAGES_EXTENSION.includes(extension.toLowerCase())) {
      const url = (await clientDocument.show(item.id)).data?.downloadUrl;
      setImgUrl(url);
      return setSelectedDocument(item);
    }
    enqueueSnackbar('Document View is supported only for PDFs and images.', {
      variant: 'warning',
    });
  };
  const viewClientHandler = (item) => {
    history.push(
      `/portfolio/view/${item.associations[0]?.clientId}/kyc-documents`
    );
  };

  const onRequestUpload = async (item) => {
    const clientId = item.associations[0]?.clientId;
    if (!clientId) {
      return enqueueSnackbar('Unable to fetch client information.', {
        variant: 'error',
      });
    }

    if (item?.category.toLowerCase() === 'photo id') {
      return history.push(
        `/portfolio/view/${clientId}/electronic-verification/new?forceStart=true&type=id`
      );
    }

    if (item?.category.toLowerCase() === 'proof of address') {
      return history.push(
        `/portfolio/view/${clientId}/electronic-verification/new?forceStart=true&type=address`
      );
    }
    return history.push(
      `/portfolio/view/${clientId}/electronic-verification/new?forceStart=true`
    );
  };

  const renderedFilterBadges = useMemo(() => {
    const badges = [];
    // Next review date
    if (expiryDateRange.from && expiryDateRange.to) {
      badges.push({
        name: 'expiryDate',
        content: `Expiry Date: ${moment(expiryDateRange.from).format(
          'MM/DD/YYYY'
        )} - ${moment(expiryDateRange.to).format('MM/DD/YYYY')}`,
      });
    }
    if (expiryDateRange.from && !expiryDateRange.to) {
      badges.push({
        name: 'expiryDate',
        content: `Expiry Date: From ${moment(expiryDateRange.from).format(
          'MM/DD/YYYY'
        )}`,
      });
    }
    if (expiryDateRange.to && !expiryDateRange.from) {
      badges.push({
        name: 'expiryDate',
        content: `Expiry Date: Until ${moment(expiryDateRange.to).format(
          'MM/DD/YYYY'
        )}`,
      });
    }
    if (!!checkedCategories.length) {
      const label = checkedCategories.length === 1 ? 'Category' : 'Categories';
      const categoriesName = checkedCategories.map(
        (item) => categories.all.find((el) => el.id.toString() === item).name
      );
      badges.push({
        name: 'categories',
        content: `${label}: ${categoriesName.join(', ')}`,
      });
    }
    return badges.map((badge) => (
      <Box key={`filter-badge-${badge.name}`} ml={1}>
        <CustomChip
          label={badge.content}
          deleteIcon={
            <Box className={classes.deleteChipIcon}>
              <Delete2Icon viewBox="0 0 17 12" />
            </Box>
          }
          onDelete={() =>
            badge.name === 'categories'
              ? setCheckedCategories([])
              : setExpiryDateRange({ from: null, to: null })
          }
          propsClasses={classes.chip}
        />
      </Box>
    ));
  }, [expiryDateRange, checkedCategories]); //eslint-disable-line
  return (
    <Box display="flex" flexDirection="column" mt={2}>
      <Box display="flex" alignItems="center">
        <Box display="flex" height="48px" alignItems="center">
          <Box mr={3}>
            <InlineHeadBadge
              fontWeight={500}
              badgeColor="#C0A2B8"
              textColor="#FFF"
              badgeValue={count}
            >
              Documents
            </InlineHeadBadge>
          </Box>
        </Box>
        <Box display="flex" flexWrap="wrap">
          {renderedFilterBadges}
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" mt={1}>
        <OutlinedSearch
          label={'Search by file name'}
          value={search}
          onChange={(event) => handleSearch(event.target.value)}
        />
        <Box ml={2}>
          <FilterWithContext
            isActive={
              expiryDateRange.from ||
              expiryDateRange.to ||
              !!checkedCategories.length
            }
          >
            <DocumentFilters
              checkedCategories={checkedCategories}
              categories={categories}
              sort={sortField}
              onSort={onSort}
              expiryDateRange={expiryDateRange}
              setExpiryDateRange={setExpiryDateRange}
              handlePageChange={(page) => setPage(page)}
              handleSwitch={handleSwitch}
              showCustomCategories={!!documents.length}
            />
          </FilterWithContext>
        </Box>
      </Box>
      <Box px={2} mt={2}>
        {!!documents.length && (
          <TableList
            columns={DOCUMENTS_COLUMNS}
            items={documents}
            onPageChange={(event, value) => setPage(value - 1)}
            onClick={(id, item) => getFile(item)}
            pagesCount={pages}
            totalCount={count}
            pageSize={PAGE_SIZE}
            page={page}
            sortableColumns={SORTABLE_COLUMNS}
            onSort={onSort}
            sortDirection={sortDirection}
            sort={sortField}
            threeDotsActions={[
              {
                label: 'Request Upload',
                handler: (id, item) => onRequestUpload(item),
              },
              {
                label: 'View Client',
                handler: (id, item) => viewClientHandler(item),
              },
              // {
              //   label: 'Edit',
              //   handler: async (id, item) => {
              //     await onStartDocumentUpdateFlow(item);
              //   },
              // },
              {
                label: 'Download',
                handler: async (id, item) => {
                  await onDownloadDocument(item);
                },
              },
              {
                label: 'Delete',
                handler: (id, item) => setPendingDocumentDelete(item),
              },
            ]}
          />
        )}
        {!documents.length && (
          <Box mt={16} flexGrow={1}>
            <NoResultsFound>
              {search || (expiryDateRange.from && expiryDateRange.to)
                ? 'No documents found for the applied filters'
                : 'When you upload documents, they will appear here'}
            </NoResultsFound>
          </Box>
        )}
      </Box>
      <DocumentDeleteDialog
        open={!!pendingDocumentDelete}
        documentName={pendingDocumentDelete?.documentName}
        onClose={() => setPendingDocumentDelete(null)}
        onConfirm={onDocumentDeleteConfirm}
      />
      <PdfViewer
        url={pdfUrl}
        open={!!pdfUrl}
        onClose={() => setPdfUrl(null)}
        documentName={selectedDocument?.documentName}
        clientName={selectedDocument?.entityName}
        onDownload={() => onDownloadDocument(selectedDocument)}
      />
      <ImageViewer
        url={imgUrl}
        open={!!imgUrl}
        onClose={() => setImgUrl(null)}
        documentName={selectedDocument?.documentName}
        clientName={selectedDocument?.entityName}
        onDownload={() => onDownloadDocument(selectedDocument)}
      />
      {/* <DocumentDialog
        open={!!pendingDocumentDialogShown}
        // Passed document means update flow
        document={pendingUpdateDocument}
        category={categories}
        onClose={closeDocumentDialog}
        file={pendingUpdateDocument}
        onSave={(item) => console.log(item)}
      /> */}
    </Box>
  );
}

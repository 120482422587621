export const prepareRulebookData = async (data) => {
  const rulebookGroups = {
    global: [],
    local: [],
  }
  for (const rulebook of data) {
    const key = rulebook.jurisdiction === 'UNDEFINED' ? 'global' : 'local'
    rulebookGroups[key].push(rulebook)
  }
  return rulebookGroups;
}

export const loadRulebookCategory = (categoryId, categories, types) => {
  const category = categories.find(e => e.id === categoryId)
  const categoryTypes = types.filter(type => type.categoryIds.includes(categoryId))

  return {
    ...category,
    categoryId,
    types: categoryTypes,
  }
}

export const loadRulebookCategories = async rulebookCategories => {
  return (await Promise.all(
    rulebookCategories.map(
      rulebookCategory => loadRulebookCategory(rulebookCategory)
    )
  )).filter(c => !!c)
}

export const prepareCategoryData = (item) => {
  return {
    ...item,
    categoryId: item.id,
    categoryRuleType: item.categoryRuleType || 'ONE_OF',
    isExpected: true,
    isOneOff: true,
    isPerType: false,
    types: item.types?.map(type => ({ ...type, typeId: type.id, isChecked: !!item.expected, expected: !!item.expected })) || []
  }
}


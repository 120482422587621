import {IconButton, InputAdornment, OutlinedInput} from '@material-ui/core';
import React from 'react';

import {
  PasswordInvisibleActiveIcon,
  PasswordVisibleActiveIcon,
  PasswordInvisibleDisabledIcon
} from '@app/icons';
import CustomTooltip from '@components/controls/tooltip';

export default function OutlinedPassword(props) {

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    if(!!props.value){
      setShowPassword(!showPassword);
    }
  };

  const onPasswordChange = (event) => {
    const value = event.target.value;
    if(!value) {
      setShowPassword(false);
    }
    props.onChange(event);
  }

  return (
    <OutlinedInput
      type={showPassword ? 'text' : 'password'}
      classes={props.classes}
      value={props.value}
      onChange={onPasswordChange}
      endAdornment={
        <InputAdornment position="end">
          <CustomTooltip
            title={showPassword ? 'Hide password' : 'Show password'}
            placement="top"
          >
            <span>
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
                disabled={!props.value}
              >
                {!props.value ?
                  <PasswordInvisibleDisabledIcon />
                  : (
                    showPassword
                      ? <PasswordVisibleActiveIcon />
                      : <PasswordInvisibleActiveIcon />
                  )
                }
              </IconButton>
            </span>
          </CustomTooltip>
        </InputAdornment>
      }
    />
  )
}
import React, { useState, useEffect } from 'react';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { InfoOtherIcon } from '@app/icons';
import { Box, Button, Card, Dialog, FormControlLabel, makeStyles, Popover, Typography } from '@material-ui/core';
import CheckboxLight from '@components/inputs/checkbox-light';
import CustomChip from '@components/controls/custom-chip';
import { pluralFormatter } from '@utils/textUtil';

const useStyles = makeStyles((theme) => ({
  fullWidthChip: {
    width: '100%',
    justifyContent: 'flex-start',
  }
}));

function InstructionsInfo(props) {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" {...props}>
      <Card>
        <Box width={150} p={2} height={200}>
          <Box width="100%">
            <Typography variant="h4" style={{ fontSize: '16px' }}>Legend</Typography>
          </Box>
          <Box mb={1} width="100%">
            <CustomChip label="ADDED" type="success" propsClasses={classes.fullWidthChip} />
          </Box>
          <Box mb={1} width="100%">
            <CustomChip label="UPDATED" type="warning" propsClasses={classes.fullWidthChip} />
          </Box>
          <Box mb={1} width="100%">
            <CustomChip label="DELETED" type="error" propsClasses={classes.fullWidthChip} />
          </Box>
          <Box mb={1} width="100%">
            <CustomChip label="SUPPRESSED" type="suppressed" propsClasses={classes.fullWidthChip} />
          </Box>
        </Box>
      </Card>
      <Box width={345} px={2}>
        <Box mb={3}>
          <Typography variant="subtitle2" align="left" style={{ lineHeight: '22px' }}>
            Please review the potential hits, suppress the false-positive sources.  If there are changes identified, click <b style={{ color: '#7A5B44' }}>“Acknowledge”</b> to confirm you have reviewed them. You will not receive any further emails/notifications once the search profile changes have been acknowledged. 
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" align="left" style={{ lineHeight: '22px' }}>
            <b style={{ color: '#656565' }}>Note:</b> Deleted (Red) hits will remain highlighted even after acknowledgement. Suppressed (Gray) hits will be collapsed by default, use “Expand” to see them or “Initiate” to unsupress them.
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default function UserNoteInstructions(props) {
  const [anchorEl, setAnchorEl] = useState(false);
  const [neverShowAgain, setNeverShowAgain] = useState(() => {
    return localStorage.getItem('neverShowUserNoteInstruction') === 'true'
  });
  const [isDialogOpen, setIsDialogOpen] = useState(() => {
    return localStorage.getItem('neverShowUserNoteInstruction') !== 'true'
  });

  useEffect(() => {
    localStorage.setItem('neverShowUserNoteInstruction', neverShowAgain ? 'true' : 'false')
  }, [neverShowAgain])

  return (
    <Box>
      <ButtonWithIcon
        startIcon={<InfoOtherIcon />}
        onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
        onMouseLeave={() => setAnchorEl(null)}
        onClick={() => setIsDialogOpen(true)}
      >
        Instructions
      </ButtonWithIcon>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        style={{
          pointerEvents: 'none',
        }}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
        <InstructionsInfo p={5} />
      </Popover>

      <Dialog open={!!props.count && isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <Typography variant="h3" align="center" style={{ whiteSpace: 'pre-wrap' }}>{`${props.count} potential ${pluralFormatter(props.count, 'hit', 'hits')} were detected\nthat match your search profile`}</Typography>
        <Box my={4}>
          <InstructionsInfo />
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <FormControlLabel
              labelPlacement="end"
              label="Do not show this message again."
              control={
                <CheckboxLight
                  className="checkbox-user-card"
                />
              }
              onChange={() => setNeverShowAgain(!neverShowAgain)}
              checked={neverShowAgain}
            />
          </Box>
          <Button variant="contained" onClick={() => setIsDialogOpen(false)}>
              DISMISS
          </Button>
        </Box>
      </Dialog>
    </Box>
  )
}

import React from 'react';
import { SidesLayout, SidesLayoutRight } from '../../components/layout/sides-layout';
import {
  Box,
  Typography,
  Grid,
  Button
} from '@material-ui/core';

import AuthHeader from './auth-header';
import history from '../../app/history';

export default function ResetPassSuccess(props) {

  return (
    <SidesLayout>
      <SidesLayoutRight
        header={<AuthHeader />}
      >

        <Grid container justify={'center'} alignItems={'center'}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Box mb={3}>
                <Typography variant={'h1'} align={'center'}>Success!</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display={'flex'} justifyContent={'center'}>
                <Typography variant={'subtitle1'} align={'center'}>
                                    Password successfully changed!
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display={'flex'} justifyContent={'center'} mt={6}>
                <Button
                  onClick={() => history.push('/login')}
                  variant="contained"
                  size="large"
                >
                                    LOGIN
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>

      </SidesLayoutRight>
    </SidesLayout>
  );
}
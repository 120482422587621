import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Collapse, Typography, Card, CardContent, IconButton, Box } from '@material-ui/core';
import {
  AddNoteIcon,
  DropdownDownIcon,
  Dropdown4Icon,
  RetriveIcon,
  RetriveInactiveIcon,
  ExpandIcon,
  ContractIcon,
  AddNoteInactiveIcon,
  RateNotsetIconNew,
  DropdownDisabledOtherIcon,
  CarbonViewFilledIcon
} from '@app/icons';
import CustomIconButton from '@components/buttons/button-with-icon';
import CustomChip from '@components/controls/custom-chip';
import Rate from '@components/controls/rate';
import AddCommentField from '@components/layout/add-comment';
import CardComment from '@components/layout/card-comments';
import LabelsForRender from '@components/layout/labels-for-render';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import CheckboxLight from '@components/inputs/checkbox-light';
import CheckboxLightIcon from '@assets/img/inputs/checkbox-light.svg';
import CustomTooltip from '@components/controls/tooltip';
import { setCardTypeColor } from '@utils/setCardTypeColor';
import { validURL } from '@utils/validURL';
import Linkify from 'linkifyjs/react';
import prepareTimestamp from '@utils/date';

import { isEmpty } from 'lodash';

const height = 140;
const minBlokHeight = 90;
const cardVisibleHeightSuppressed = 64;
const SUPPRESSED = 'suppressed';

export const useStyles = makeStyles((theme) => ({
  userCard: {
    width: '100%',
    margin: '12px 4px',
    padding: '0px 24px 24px',
    borderRadius: '12px',
    overflow: 'inherit',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    '&:hover': {
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      cursor: 'pointer',
    },
  },
  userCardHeader: {
    padding: 0,
    display: 'flex',
    alignItems: 'center'
  },
  tagsLine: {
    '& .MuiChip-root': {
      marginLeft: 5,
    }
  },
  title: {
    maxWidth: 700,
    display: 'inline-block',
    overflow: 'hidden !important',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '20px',
  },
  leftTitleBlock: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'auto'
  },
  tabs: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '25px',
    fontFamily: 'Roboto',
    color: theme.palette.text.BL1,
  },
  cardNoteContentWrapper: {
    margin: '10px 0',
    padding: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  amlTypeContentWrapper: {
    margin: '10px 0',
    padding: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  cardRightContainer: {
    width: '44%',
    marginRight: '5px',
  },
  cardLeftContentWrapper: {
    padding: 0,
    width: '52%',
    '&:last-child': {
      padding: 0,
      width: '52%',
    },
  },
  amlContentWrapper: {
    padding: 0,
  },
  moreIcon: props => ({
    '& .MuiButton-label': {
      color: props.noLogged ? theme.palette.text.GY3 : 'black',
      fontSize: 12,
      fontWeight: 600,
      fontFamily: 'Roboto',
    }
  }),
  cardRightContentWrapper: props => ({
    maxHeight: props.commentsHeight,
    overflowY: props.notesScroll ? 'auto' : 'hidden',
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
    '&::-webkit-scrollbar': {
      width: '2px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px #EDEDED',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#A4A4A4',
    }
  }),
  showButtonWrapper: {
    margin: '10px 5px 0 5px',
  },
  cardComment: {
    marginBottom: '10px',
    padding: '10px',
    // height: '85px',
    background: theme.palette.background.GY1,
    borderRadius: '12px',
  },
  checkboxRoot: {
    marginLeft: '5px',
  },
  commentButton: {
    borderRadius: '8px',
    padding: '10px',
    margin: '0 5px',
  },
  cardButton: {
    borderRadius: '8px',
    padding: '5px',
    margin: '0 5px',
  },
  showAddCommentButton: {
    background: theme.palette.background.GY1,
    '&.Mui-disabled': {
      background: theme.palette.background.GY1,
    }
  },
  collapseContainer: {
    '&.MuiCollapse-entered': {
      height: 'auto',
    },
  },
  description: {
    margin: '10px 0 15px 0',
  },
  wrapperLabels: {
    marginBottom: '5px',
    alignItems: 'flex-start'
  },
  linkified: {
    fontSize: 12,
    textDecoration: 'none',
    color: theme.palette.text.LB3,
    cursor: 'pointer',
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  userCardCheckbox: {
    margin: '0px 4px',
    padding: 4,
    borderRadius: 8,
  },
}));

const advantageTypes = ['AML', 'A'];
const types = {
  'SANCTIONS': 'S',
  'ADVERSE MEDIA': 'AM',
  'PEP': 'PEP',
};
const typeTooltip = {
  'S': 'sanction',
  'AM': 'adverse-media, adverse-media-general',
  'PEP': 'pep, pep-class-1, pep-class-2, pep-class-3, pep-class-4',
  'W': 'warning',
  'FP': 'fitness-probity',
  'A': 'assets',
};
const disableTooltipTitle = 'Some features are restricted in this view. For an extensive searching experience please Login or Register.';

const UserCardXL = (props) => {
  const {
    description,
    id,
    title,
    listType,
    isChecked,
    onSelect,
    meta,
    relevant,
    notes,
    changeRate,
    addComment,
    handleSupress,
    onSupress,
    handleDeleteComment,
    prepareEditComment,
    handleEditComment,
    markedIsIrrelevantOn,
    markedAsIsIrrelevantReason,
    isSearchVersions,
    versionState,
    versionStateChangedOn,
    noLogged,
    isProfilesVersion,
    bgCheckRiskRating,
    listingStarted,
    listingEnded,
    isAcknowledged
  } = props;

  const cardType = (!relevant && relevant !== null) ? SUPPRESSED : (versionState || '');

  const descriptionRef = useRef();

  const [isShowMore, setIsShowMore] = useState(false);
  const [isShowMoreSuppressed, setIsShowMoreSuppressed] = useState(false);
  const [cardVisibleHeight, setCardVisibleHeight] = useState(height);
  const [showMoreVisible, setShowMoreVisible] = useState(false);
  const [showAddComment, setShowAddComment] = useState(false);
  const [editNotes, setEditNotes] = useState(null);

  const classes = useStyles({
    commentsHeight: isShowMore ? descriptionRef.current?.offsetHeight - 25 : cardVisibleHeight - 15,
    notesScroll: true,
    noLogged
  });

  const heightCalculate = useCallback(() => {
    let blockHeight = descriptionRef.current?.offsetHeight ? descriptionRef.current?.offsetHeight : 0;
    blockHeight = ((blockHeight < minBlokHeight) ? minBlokHeight : blockHeight + 1);
    setCardVisibleHeight((blockHeight > height) ? height : blockHeight);
    setShowMoreVisible(blockHeight > height);
  }, [setCardVisibleHeight, setShowMoreVisible])

  useEffect(() => {
    heightCalculate()
  }, [meta, heightCalculate]);

  const handleShow = (selectedId) => {
    setIsShowMore(prev => !prev);
  }

  const variant = 'simple-popover';
  const typeColor = (!relevant && relevant !== null) ? SUPPRESSED : versionState;
  const cardColor = ((!relevant && relevant !== null) || (isSearchVersions && versionState !== 'UPDATED' && versionState !== 'UNCHANGED')) ? setCardTypeColor(typeColor) : null;

  const handleShowComment = useCallback((bool) => {
    setShowAddComment(bool);
  }, [setShowAddComment])

  const editComment = useCallback((noteId) => {
    const note = notes.find((el) => el.id === noteId);
    setEditNotes(note);
    prepareEditComment(noteId);
    handleShowComment(true);
  }, [prepareEditComment, handleShowComment, notes])

  const renderType = useMemo(() => {
    const type = listType?.type || (types[listType] || listType);
    return (
      <CustomTooltip title={typeTooltip[type]} placement="top" disableHoverListener={!typeTooltip[type]}>
        <Box className={classes.tagsLine} ml={1}>
          <CustomChip label={type} />
        </Box>
      </CustomTooltip>
    )
  }, [classes, listType])

  const randerDataLabels = useMemo(() => {
    return advantageTypes.includes(listType) ? (
      <CardContent ref={descriptionRef} classes={{ root: classes.amlContentWrapper }}>
        <LabelsForRender labels={meta} propsWrapper={classes.wrapperLabels} fullWidthValue={true} />
      </CardContent>
    ) : (
      <CardContent ref={descriptionRef}  classes={{ root: classes.cardLeftContentWrapper }}>
        <LabelsForRender labels={meta} propsWrapper={classes.wrapperLabels} />
      </CardContent>
    )
  }, [classes, listType, meta])

  const renderListing = useMemo(() => {

    return (listingStarted || listingEnded) && (
      <Box mb={1}>
        {(listingStarted && listingEnded) ? (
          <CustomChip
            label={`${listingStarted} - ${listingEnded}`}
            type="listing"
            background="#EDEDED"
          />
        ) : (
          <React.Fragment>
            {listingStarted && (
              <CustomChip
                label={`${listingStarted} - Present`}
                type="listing"
                background="#EDEDED"
              />
            )}
            {listingEnded && (
              <CustomChip
                label={`Until ${listingEnded}`}
                type="listing"
                background="#EDEDED"
              />
            )}
          </React.Fragment>
        )}
      </Box>
    )
  }, [listingStarted, listingEnded])

  const renderChip = useMemo(() => {
    return cardType === SUPPRESSED ? (
      <Box ml={0} mr={1}>
        <CustomTooltip title="You've marked this as irrelevant" placement="top">
          <span>
            <CustomChip
              label={`SUPPRESED ${markedIsIrrelevantOn}`}
              type="suppressed"
            />
          </span>
        </CustomTooltip>
      </Box>
    ) : (versionState === 'DELETED' || // Always show deleted
      (versionState !== 'UNCHANGED' &&
      versionState !== 'UPDATED' && // Never show updated
      versionStateChangedOn &&
      (isProfilesVersion || !isAcknowledged) // For last (current) version, only show if's not acknowledged
      )) && (
      <Box ml={0} mr={1}>
        <CustomChip
          label={`${versionState} ${prepareTimestamp(versionStateChangedOn, 'MM/DD/YYYY')}`}
          type={versionState}
        />
      </Box>
    )
  }, [cardType, markedIsIrrelevantOn, isProfilesVersion, versionState, versionStateChangedOn, isAcknowledged])

  const renderAddComment = useMemo(() => (
    <AddCommentField
      open={showAddComment}
      setOpen={bool => handleShowComment(bool)}
      addComment={(comment) => {
        addComment(comment);
        handleShowComment(!showAddComment);
      }}
      editNotes={editNotes}
      handleEditComment={(noteId, note, noChange) => {
        handleEditComment(noteId, note, noChange);
        setEditNotes(null);
        handleShowComment(false);
      }}
    />
  ), [addComment, showAddComment, handleShowComment, editNotes, handleEditComment])

  const collapsedHeight = useMemo(() => (
    (!!showAddComment && !isShowMore && cardVisibleHeight < height) ? height : cardVisibleHeight
  ), [showAddComment, isShowMore, cardVisibleHeight])

  return (
    <Card
      className={classes.userCard}
      key={variant}
      style={{
        background: cardColor && `linear-gradient(-48.28deg, #FFFFFF 94.85%, ${cardColor} 99.62%)`,
      }}
    >
      <Collapse
        in={cardType === SUPPRESSED ? isShowMoreSuppressed : true}
        collapsedHeight={cardType === SUPPRESSED ? cardVisibleHeightSuppressed : ''}
        classes={{ container: classes.collapseContainer }}
      >
        <Box mt="5px" height={22}>
          {renderChip}
        </Box>
        {/** Header block */}
        <CardContent className={classes.userCardHeader}> 
          <div className={classes.leftTitleBlock}>
            <CustomTooltip title={title} placement="top" disableHoverListener={!title}>
              {(listType === 'AML' && validURL(title)[0]?.type === 'link') ? (
                <Linkify options={{ target: '_blank', className: classes.linkified }}>
                  {title.toUpperCase() || 'No title'}
                </Linkify>
              ) : (
                <Typography variant="h4" className={classes.title}>{title ? title.toUpperCase() : 'No title'}</Typography>
              )}
            </CustomTooltip>
            {listType !== 'AML' && (
              <span>
                {listType && renderType}
              </span>
            )}
          </div>

          {!advantageTypes.includes(listType) && (
            cardType !== SUPPRESSED ?
              <Box display={'flex'} alignItems={'center'}>
                {addComment ? (
                  <CustomTooltip
                    title={!!isProfilesVersion
                      ? 'Only the last version can be updated, the previous versions are read only'
                      : 'Add Note'
                    }
                    placement="top"
                  >
                    <span>
                      <IconButton
                        onClick={() => handleShowComment(!showAddComment)}
                        classes={{ root: clsx(classes.commentButton, showAddComment && classes.showAddCommentButton) }}
                        disabled={showAddComment || !!isProfilesVersion}
                      >
                        {!!isProfilesVersion ? <AddNoteInactiveIcon /> : <AddNoteIcon />}
                      </IconButton>
                    </span>
                  </CustomTooltip>
                ) : (
                  <Box display={'flex'}>
                    <CustomTooltip
                      title="Some features are restricted in this view. For an extensive searching experience please Login or Register."
                      placement="top"
                    >
                      <AddNoteInactiveIcon />
                    </CustomTooltip>
                  </Box>
                )}
                {changeRate ? (
                  <Rate
                    changeRate={changeRate}
                    riskScore={bgCheckRiskRating}
                    disabled={!!isProfilesVersion}
                    tooltipTitle={!!isProfilesVersion
                      ? 'Only the last version can be updated, the previous versions are read only'
                      : 'Set Risk'
                    }
                  />
                ) : (
                  <Box display={'flex'}>
                    <CustomTooltip
                      title="Some features are restricted in this view. For an extensive searching experience please Login or Register."
                      placement="top"
                    >
                      <RateNotsetIconNew />
                    </CustomTooltip>
                  </Box>
                )}
                <Box display={'flex'}>
                  {onSelect ? (
                    <CustomTooltip
                      title={!!isProfilesVersion
                        ? 'Only the last version can be updated, the previous versions are read only'
                        : isChecked
                          ? 'Deselect'
                          : 'Select'
                      }
                      placement="top"
                    >
                      <span>
                        <CheckboxLight
                          className={classes.userCardCheckbox}
                          checked={isChecked}
                          onClick={(e) => onSelect(e, id)}
                          disabled={!!isProfilesVersion}
                        />
                      </span>
                    </CustomTooltip>
                  ) : (
                    <CustomTooltip
                      title="Some features are restricted in this view. For an extensive searching experience please Login or Register."
                      placement="top"
                    >
                      <img alt="" src={CheckboxLightIcon} />
                    </CustomTooltip>
                  )}
                </Box>
              </Box> : 
              <Box display={'flex'} className={classes.suppresedCardContentWrapper}>
                <CustomTooltip
                  title={
                    <p style={{ textAlign: 'start' }}>
                      Suppress Reason: {markedAsIsIrrelevantReason} <br/>
                      Suppress On: {prepareTimestamp(markedIsIrrelevantOn, 'MM/DD/YYYY hh:mm A')} <br/>
                    </p>
                  }
                  placement="top"
                >
                  <span>
                    <IconButton onMouseDown={() => onSupress(id)} className={classes.cardButton}>
                      <CarbonViewFilledIcon />
                    </IconButton>
                  </span>
                </CustomTooltip>
                <CustomTooltip
                  title={!!isProfilesVersion
                    ? 'Only the last version can be updated, the previous versions are read only'
                    : 'Initiate'
                  }
                  placement="top"
                >
                  <span>
                    <IconButton onClick={() => handleSupress(id)} disabled={!!isProfilesVersion} className={classes.cardButton}>
                      {!!isProfilesVersion
                        ? <RetriveInactiveIcon classes={{ root: classes.suppresedCardIcon }} />
                        : <RetriveIcon classes={{ root: classes.suppresedCardIcon }} />
                      }
                    </IconButton>
                  </span>
                </CustomTooltip>
                <CustomTooltip title={!isShowMoreSuppressed ? 'Expand' : 'Collapse'} placement="top">
                  <span>
                    <IconButton onClick={() => setIsShowMoreSuppressed(el => !el)} className={classes.cardButton}>
                      {!isShowMoreSuppressed ? <ExpandIcon /> : <ContractIcon />}
                    </IconButton>
                  </span>
                </CustomTooltip>
              </Box>
          )}
        </CardContent>

        {renderListing}

        {description && (
          <Typography className={classes.description}>
            {description}
          </Typography>
        )}
        {/** Header block */}

        <Collapse in={isShowMore} collapsedHeight={collapsedHeight}>
          <CardContent classes={{ root: advantageTypes.includes(listType) ? classes.amlTypeContentWrapper : classes.cardNoteContentWrapper }}>
            {randerDataLabels}
            {!advantageTypes.includes(listType) && (
              <React.Fragment>
                <div className={classes.cardRightContainer}>
                  {renderAddComment}
                  <CardContent classes={{ root: classes.cardRightContentWrapper }}>
                    {!isEmpty(notes) && notes.map((note) => (
                      <CardComment
                        title={`Me, ${prepareTimestamp(note.createdOn, 'MM/DD/YYYY')}`}
                        updatedAt={note.updatedOn ? `Edited ${prepareTimestamp(note.updatedOn, 'MM/DD/YYYY hh:mm A')}` : ''}
                        comment={note.note}
                        key={`comment-${id}-${note.id}`}
                        handleDeleteComment={() => handleDeleteComment(note.id)}
                        handleEditComment={() => editComment(note.id)}
                        disabled={!!isProfilesVersion}
                        maxLength={2000}
                      />
                    )).reverse()}
                  </CardContent>
                </div>
              </React.Fragment>
            )}
          </CardContent>
        </Collapse>
        {showMoreVisible && (
          <div className={classes.showButtonWrapper}>
            <CustomTooltip
              placement="top"
              title={disableTooltipTitle}
              disableHoverListener={!noLogged}
            >
              <span>
                <CustomIconButton
                  onClick={() => handleShow(id)}
                  startIcon={
                    noLogged
                      ? <DropdownDisabledOtherIcon size="medium" />
                      : isShowMore
                        ? <Dropdown4Icon size="medium" />
                        : <DropdownDownIcon size="medium" />
                  }
                  className={classes.moreIcon}
                  disabled={noLogged}
                >
                  {isShowMore ? 'Show Less' : 'Show More'}
                </CustomIconButton>
              </span>
            </CustomTooltip>
          </div>
        )}
      </Collapse>
    </Card>
  )
}

export default withTheme(UserCardXL);
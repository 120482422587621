import React, {useRef} from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker as NativeDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';

const useStyles = makeStyles({
  datePickerWrapper: {
    '& .MuiPickersCalendarHeader-dayLabel': {
      fontWeight: 'bold'
    },
    '& .MuiPickersToolbar-toolbar': {
      backgroundColor: 'transparent',
      color: '#000',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 60px',
      height: '36px',
      borderBottom: '1px solid #000',
      borderRadius: 0,
      '& .MuiPickersToolbarButton-toolbarBtn': {
        '&:hover': {
          backgroundColor: 'inherit'
        },
        '& .MuiPickersToolbarText-toolbarTxt': {
          color: '#000',
          fontSize: '14px',
          fontWeight: 'normal',
          '&.MuiPickersToolbarText-toolbarBtnSelected': {
            color: '#000',
            fontSize: '14px',
            fontWeight: 'bold',
          }
        },
      },
      '& .MuiPickersToolbarButton-toolbarBtn:nth-child(2)': {
        marginLeft: '1rem',
      },
    },
    '&:not(.date-no-set) .MuiPickersBasePicker-pickerView': {
      '& .MuiPickersCalendar-transitionContainer .MuiPickersDay-day.MuiPickersDay-daySelected': {
        backgroundColor: '#000',
        color: '#FFF',
        '& .MuiIconButton-label': {
          fontWeight: 'bold'
        }
      }
    },
    '& .MuiPickersBasePicker-pickerView': {
      width: 'auto',
      // Normal calendar with days groupped to weeks
      '& .MuiPickersCalendar-transitionContainer': {
        '& .MuiPickersDay-day': {
          borderRadius: 0,
          '&.MuiPickersDay-daySelected': {

          },
          '&.MuiPickersDay-current': {
            backgroundColor: '#BBB',
            color: '#000'
          },
        },
        '& .inside-range .MuiPickersDay-day': {
          background: '#DDD',
          fontWeight: 'bold',
          margin: 0,
          width: '40px',
        },
        '& .range-border .MuiPickersDay-day': {
          background: '#000',
          margin: 0,
          width: '40px',
          color: '#FFF',
          '&.MuiPickersDay-current': {
            backgroundColor: '#000',
            color: '#FFF'
          },
        }
      },
      // Years view
      '& .MuiPickersYearSelection-container': {
        height: '200px',
        '& .MuiPickersYear-root': {
          color: '#000',
          fontSize: '14px',
          '&.MuiPickersYear-yearSelected': {
            fontWeight: 'bold',
            margin: 0,
          }
        }
      },
      // Months view
      '& .MuiPickersMonthSelection-container': {
        '& .MuiPickersMonth-root': {
          color: '#232323',
          fontWeight: 'normal',
          '&.MuiPickersMonth-monthDisabled': {
            color: '#BBB'
          }
        },
        '& .MuiPickersMonth-monthSelected': {
          color: '#232323',
          fontSize: '16px',
          lineHeight: '22px',
          fontWeight: 'bold'
        }
      }
    },
  }
})

export default function DatePicker(props) {

  const classes = useStyles()

  const {onAccept, onChange, onYearChange, onMonthChange, value, ...other} = props;

  // Stop accept event in case of year change
  const preventAccept = useRef(false)

  const onInternalAccept = date => {
    setTimeout(() => {
      if(!preventAccept.current && onAccept) {
        onAccept(date)
      }
    }, 0)
  }

  const onInternalChange = date => {
    if(onChange) {
      onChange(date)
    }
  }

  const onInternalYearChange = date => {
    preventAccept.current = true
    if(onYearChange) {
      onYearChange(date)
    }
    setTimeout(() => {
      preventAccept.current = false
    }, 5)
  }

  const onInternalMonthChange = date => {
    preventAccept.current = true
    if(onMonthChange) {
      onMonthChange(date)
    }
    setTimeout(() => {
      preventAccept.current = false
    }, 5)
  }

  return (
    <Box className={clsx(classes.datePickerWrapper, !value && 'date-no-set')}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <NativeDatePicker
          views={['year', 'month', 'date']}
          {...other}
          value={moment(value)}
          onChange={onInternalChange}
          onAccept={onInternalAccept}
          onYearChange={onInternalYearChange}
          onMonthChange={onInternalMonthChange}
        />
      </MuiPickersUtilsProvider>
    </Box>
  )
}
import './assets/css/general.css';

import React, { useEffect } from 'react';
import { Container } from '@material-ui/core';
import { Router } from 'react-router-dom';
import history from '@app/history';
import { ThemeProvider } from '@material-ui/core/styles';
import resolveTheme from '@app/theme';
import Loader from '@components/layout/loader';
import CustomSnackbarProvider from './components/snackbar/CustomSnackbarProvider';
import StoreSnackbarInterceptor from './components/snackbar/StoreSnackbarInterceptor';
import { useIdleTimer } from 'react-idle-timer';
import auth from './app/auth';
import { useSelector } from 'react-redux';
import Routes from './routes';
import UpdateDetector from './UpdateDetector';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

function App() {
  const isGuest = useSelector(state => !state.auth.user)
  const { side, userIsAdmin } = useSelector(state => state.auth)

  useEffect(() => { }, [side]) // don't remove, this means redraw on menu change

  const handleOnIdle = () => {
    if (!isGuest) {
      auth.logout();
      console.log('User logged out due to idleness')
      history.push('/login');
    }
  }

  useIdleTimer({
    onIdle: handleOnIdle,
    timeout: 1000 * 60 * 60 * 2, // 2 hours
    debounce: 500
  })

  return (
    <div className="app">
      <ThemeProvider theme={resolveTheme(side, userIsAdmin)}>
        <CustomSnackbarProvider>
          <StoreSnackbarInterceptor />
          <UpdateDetector />
          <Loader />
          <Router history={history}>
            <Container className="main-container" maxWidth={false} disableGutters={true}>
              <Routes />
            </Container>
          </Router>
        </CustomSnackbarProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

import Cognito from './cognito';
import store from './store/index';
import { loader } from '@store/actions';
import ValidationError from './errors/validation-error';
import { clientApi } from './api';

const login = async function (email, password) {
  try {
    store.dispatch(loader.setLoader(true));
    const { token, groups, mfaRequired, cognitoUser } = await Cognito.login(email, password);

    if (mfaRequired) {
      store.dispatch(loader.setLoader(false));
      return { cognitoUser }
    }

    return proceedWithLogin(token, groups);
  } catch (err) {
    let message = err.message;

    switch (message) {
      case 'User does not exist.':
      case 'UserMigration failed with error Bad password.':
        message = 'Incorrect username or password.'
        break;
    
      default:
        break;
    }
    store.dispatch(loader.setLoader(false));
    throw new ValidationError({
      email: message
    });
  }
}

const verifyOtp = async function (cognitoUser, code, rememberDevice) {
  try {
    store.dispatch(loader.setLoader(true));
    const { token, groups } = await Cognito.verifyOtp(cognitoUser, code);
    Cognito.setRememberDevice(rememberDevice)

    return proceedWithLogin(token, groups);
  } catch (err) {
    const message = 'Invalid one-time password (OTP)';
    store.dispatch(loader.setLoader(false));
    throw new ValidationError({
      code: message
    });
  }
}

// next steps after login is successful
const proceedWithLogin = async function (token, groups) {
  const userIsAdmin = !!groups.find(el => el === 'admins');
  if (userIsAdmin) {
    store.dispatch({
      type: 'SET_ADMIN',
      userIsAdmin
    });
    localStorage.setItem('userIsAdmin', userIsAdmin);
  }

  const user = await clientApi.user.getCurrentUser();

  store.dispatch({
    type: 'SET_USER',
    user: user.data
  });
  localStorage.setItem('user', JSON.stringify(user.data));
  store.dispatch(loader.setLoader(false));

  return {};
}

// We don't need to do any redirect here
// Router will handle the redirect when user is logged out
const logout = async function (clearRedirect) {
  await Cognito.logout();
  store.dispatch({
    type: 'SET_USER',
    user: null
  });
  store.dispatch({
    type: 'SET_ADMIN',
    userIsAdmin: null
  });
  clearRedirect && store.dispatch({
    type: 'SET_LAST_VISITED_PAGE',
    lastVisitedPage: ''
  });
  localStorage.removeItem('token'); // We don't store token on localStorage anymore, but we want to remove stored token (from previous versions)
  localStorage.removeItem('user');
  localStorage.removeItem('userIsAdmin');
  localStorage.removeItem('importCsvIdsFolder');
  localStorage.removeItem('importCsvIdsMonitoring');
  localStorage.removeItem('isMfaEnabled');
}

const forgotPassword = async function (email, isResend) {
  try {
    store.dispatch(loader.setLoader(true));
    const forgotPass = await Cognito.forgotPassword(email);
    store.dispatch(loader.setLoader(false));
    return forgotPass;
  } catch (err) {
    store.dispatch(loader.setLoader(false));
    if (isResend) {
      throw err;
    } else {
      throw new ValidationError({
        email: err.message
      });
    }
  }
}

const resetPassword = async function (email, newPassword, code) {
  try {
    store.dispatch(loader.setLoader(true));
    const resetPass = await Cognito.resetPassword(email, newPassword, code);
    store.dispatch(loader.setLoader(false));
    return resetPass;
  } catch (err) {
    store.dispatch(loader.setLoader(false));
    throw err;
  }
}

// This functional used to create test users directly in cognito
// const res = await auth.register('abyss403@gmail.com', 'Password123!');
const register = async function (email, password) {
  try {
    return await Cognito.register(email, password);
  } catch (err) {
    console.log('error cognito registration: ', err);
  }
}

const resendActivationEmail = async function (email) {
  try {
    return await Cognito.resendActivationEmail(email);
  } catch (err) {
    throw err;
  }
}

const getAccessToken = async function (email) {
  try {
    return await Cognito.getAccessToken(email);
  } catch (err) {
    return null;
  }
}

const changePassword = async function (oldPassword, newPassword) {
  try {
    store.dispatch(loader.setLoader(true));
    const changePass = await Cognito.changePassword(oldPassword, newPassword);
    store.dispatch(loader.setLoader(false));
    return changePass;
  } catch (err) {
    store.dispatch(loader.setLoader(false));
    throw err;
  }
}

const changeEmail = async function (newEmail, password) {
  try {
    store.dispatch(loader.setLoader(true));
    const changeEmail = await Cognito.changeEmail(newEmail, password);
    store.dispatch(loader.setLoader(false));
    return changeEmail;
  } catch (err) {
    store.dispatch(loader.setLoader(false));
    throw err;
  }
}

const auth = {
  login,
  verifyOtp,
  logout,
  forgotPassword,
  resetPassword,
  register,
  resendActivationEmail,
  getAccessToken,
  changePassword,
  changeEmail
}

export default auth;
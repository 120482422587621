import { Box } from '@material-ui/core';
import CustomTooltip from '@components/controls/tooltip';
import { ChangelogOutdatedBadgeIcon } from '@app/icons';

export const outdatedChip = (item) => {
  const handleOutdatedClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    window.open(`/portfolio/view/${item.id}?review_version=true`, '_blank')
  }

  if (item.isBasedOnLatestRulebookVersion !== undefined && !item.isBasedOnLatestRulebookVersion) {
    return (
      <CustomTooltip title="There is a newer version of entity definition available. Review Now" placement="top" width={200}>
        <Box ml={1} display="flex" alignItems="center" onClick={handleOutdatedClick}>
          <ChangelogOutdatedBadgeIcon />
        </Box>
      </CustomTooltip>
    )
  }
  return null;
}
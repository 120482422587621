import React, { useMemo, useState } from 'react';
import { Box, Grid, FormControlLabel, Switch, IconButton, makeStyles } from '@material-ui/core';
import TableList, { TCELL_TYPES } from '@components/lists/table-list';
import OutlinedSearch from '@components/inputs/outlined-search';
import InlineHeadBadge from '@components/badges/inline-head-badge';
import FilterWithContext from '@components/dropdowns/filter-with-context';
import CustomTooltip from '@components/controls/tooltip';
import { DownloadIcon, DropdownUpIcon, DropdownDownIcon } from '@app/icons';
import { dummy } from '../../dummy';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: 1,
  },
  dropdownButton: {
    padding: 10,
  },
}));

const switchGroup = [
  { value: 'paid', label: 'Paid Transactions', isChecked: true },
  { value: 'unpaid', label: 'Unpaid Transactions', isChecked: true },
  { value: 'failed', label: 'Failed Transactions', isChecked: true },
]


export default function Payments() {
  const classes = useStyles();

  const [data, setData] = useState(dummy.payments);
  const [search, setSearch] = useState();
  // eslint-disable-next-line
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState(switchGroup);

  const handleOpen = (item) => {
    setData(items => items.map(el => el.id === item.id ? ({ ...el, isOpen: !el.isOpen }) : el ));
  }

  const customActions = (item) => (
    <Box>
      <CustomTooltip title="Download" placement="top">
        <span>
          <IconButton
            onClick={() => {}}
            className={classes.iconButton}
          >
            <DownloadIcon />
          </IconButton>
        </span>
      </CustomTooltip>
      <CustomTooltip title={item.isOpen ? 'Collapse' : 'Expand'} placement="top">
        <span>
          <IconButton
            onClick={() => handleOpen(item)}
            className={classes.dropdownButton}
          >
            {item.isOpen ? <DropdownUpIcon />  : <DropdownDownIcon />}
          </IconButton>
        </span>
      </CustomTooltip>
    </Box>
  )

  const customCollapseActions = (item) => (
    <CustomTooltip title="Download" placement="top">
      <span>
        <IconButton
          onClick={() => handleOpen(item)}
          className={classes.iconButton}
        >
          <DownloadIcon />
        </IconButton>
      </span>
    </CustomTooltip>
  )

  const TABLE_COLUMNS = [
    { field: 'email', headerName: 'Email' },
    { field: 'paymentDate', headerName: 'Payment Date' },
    { field: 'amount', headerName: 'Amount' },
    { field: 'status', headerName: 'Status' },
    { field: 'listInvoices', headerName: 'List of invoices' },
    { cellType: TCELL_TYPES.CUSTOM, field: 'actions', headerName: '', custom: customActions },
  ]
  
  const COLLAPSE_COLUMNS = [
    { field: 'subscription', headerName: 'Subscription' },
    { field: 'paymentDate', headerName: 'Payment Date' },
    { field: 'amount', headerName: 'Amount' },
    { field: 'status', headerName: 'Status' },
    { field: 'listInvoices', headerName: 'List of invoices' },
    { cellType: TCELL_TYPES.CUSTOM, field: 'actions', headerName: '', custom: customCollapseActions },
  ]

  const onSearchChange = (event) => {
    setSearch(event.target.value);
    // setPage(0);
  }

  // eslint-disable-next-line
  const onFilterChange = (event) => {
    const changed = filters.map(el =>
      el.value === event.target.value ? ({ ...el, isChecked: event.target.checked }) : el
    );
    setFilters(changed);
  }

  const onSelect = (id) => {
    const newData = data.map(
      (item) => item.id === id
        ? ({ ...item, isChecked: !item.isChecked })
        : item
    );
    setData(newData);
  }

  const prepareItems = (items) => {
    return items.map(e => {
      const item = { ...e };

      if (item.collapse) {
        item.collapse = prepareItems(item.collapse)
      }

      item.cellProps = {
        email: {
          style: {
            width: '150px'
          }
        },
        paymentDate: {
          style: {
            width: '150px'
          }
        },
        amount: {
          style: {
            width: '150px'
          }
        },
        status: {
          style: {
            width: '150px'
          }
        },
        listInvoices: {
          style: {
            width: '150px'
          }
        },
        actions: {
          style: {
            width: '150px'
          }
        },
        subscription: {
          style: {
            width: '160px'
          }
        },
      }

      return item;
    })
  }

  const formattedData = useMemo(() => {
    return prepareItems(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Box display={'flex'} flexGrow={'1'} mt={2} flexDirection={'column'}>
      <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'}>
          <Box mr={1}>
            <InlineHeadBadge
              badgeColor={'#CAD7CA'}
              badgeValue={8}
            >
              Payments
            </InlineHeadBadge>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <FilterWithContext>
            {filters.map((el, i) => (
              <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value} - ${i}`}>
                <FormControlLabel
                  control={<Switch value={el.value} checked={el.isChecked} onClick={onFilterChange} />}
                  label={el.label}
                />
              </Box>
            ))}
          </FilterWithContext>
        </Box>
      </Box>
      <Box display={'flex'} mb={3}>
        <OutlinedSearch
          value={search}
          onChange={onSearchChange}
          label="Search payments"
        />
      </Box>
      <Grid container>
        <Grid item sm={12}>
          <TableList
            columns={TABLE_COLUMNS}
            collapseColumns={COLLAPSE_COLUMNS}
            items={formattedData}
            page={page}
            onSelect={onSelect}
            pageSize={12}
            pagesCount={1}
            totalCount={10}
            sortableColumns={TABLE_COLUMNS.filter(el => el.field !== 'actions').map(el => el.field)}
            handlers={{
              export: (id) => {}
            }}
            maxHeight="calc(100vh - 380px)"
          />
        </Grid>
      </Grid>
    </Box>
  )
}

import React, { useEffect, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import InnerHeader from '@components/layout/inner-header';
import PageLayout from '@components/layout/page-layout';
import { Box, Button, Typography } from '@material-ui/core';
import { FIELD_TYPES } from '@app/constants';
import { countryCodeList } from '@services/country-service';
import parseYupErrors from '@app/yup';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { ChevronLeftIcon } from '@app/icons';
import { clientRulebook } from '@app/api/client';
import { convertFieldsToYupObject } from '@app/yup';
import {clientApi} from '@app/api';
import history from '@app/history';
import { prepareData } from './dto';
import { inputFieldsMapDto, SYSTEM_FIELDS_COMPANY, generateModelFromFields } from '@dto/client';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { LegalEntitySubtypeEnum } from '@services/client-type';
import CardForm from './card-form';
import DuplicateClientDialog, { useFindDuplicateClient } from '@components/modals/find-duplicate-client';

const JURISDICTION_FIELDS = [
  { key: 'registeredOfficeCountry', label: 'Jurisdiction', type: FIELD_TYPES.COUNTRY, required: true, isDefault: true, },
  { key: 'type', label: 'Entity Type', type: FIELD_TYPES.SELECT, required: true, isDefault: true,
    options: LegalEntitySubtypeEnum,
  },
]

export default function CreateCompanyDetails({ handleNext, cachedModel, clientId, steps }) {
  const { enqueueSnackbar } = useSnackbar();
  const { searchId, profileId } = useParams();
  const { duplicateDialogProps, findDuplicateCompany } = useFindDuplicateClient();
  const [modelMap, setModelMap] = useState(() => {
    if (cachedModel) {
      return cachedModel
    }
    return {
      jurisdiction: {
        registeredOfficeCountry: '',
      },
      basicDetails: {},
    }
  })

  const [errorsMap, setErrorsMap] = useState({
    jurisdiction: {},
    basicDetails: {},
  })
  const [schemaMap, setSchemaMap] = useState({})
  const [fieldsMap, setFieldsMap] = useState({
    jurisdiction: JURISDICTION_FIELDS,
    basicDetails: [],
  })
  const [collapseMap, setCollapseMap] = useState({
    jurisdiction: true,
    basicDetails: false,
  })
  const [hasWarning, setHasWarning] = useState(false);

  useEffect(() => {
    generateMapsFromFieldsMap({})
  }, []) // eslint-disable-line

  useEffect(() => {
    const generateMaps = async () => {
      let customFieldsMap = {}
      if (modelMap.jurisdiction.registeredOfficeCountry && modelMap.jurisdiction.type) {
        const res = await Promise.all([
          clientRulebook.resolve({
            jurisdiction: modelMap.jurisdiction.registeredOfficeCountry,
            type: 'LEGAL_ENTITY',
            legalEntityType: modelMap.jurisdiction.type
          }),
          clientRulebook.resolveTypeSpecific({
            legalEntityType: modelMap.jurisdiction.type
          }),
        ])

        const { inputFields } = res[0].data
        const typeSpecificInputFields = res[1].data.map(e => ({
          ...e,
          isTypeSpecific: true,
        }))

        customFieldsMap = inputFieldsMapDto(typeSpecificInputFields.concat(inputFields), 'company', true)
        handleMapChange('jurisdiction', 'collapseMap')(false)
        handleMapChange('basicDetails', 'collapseMap')(true)
        handleMapChange('extendedDetails', 'collapseMap')(true)
        handleMapChange('ownershipDetails', 'collapseMap')(true)
        handleMapChange('activityDetails', 'collapseMap')(true)
        generateMapsFromFieldsMap(customFieldsMap)
      }
    }

    generateMaps()
  }, [modelMap.jurisdiction.registeredOfficeCountry, modelMap.jurisdiction.type]) // eslint-disable-line

  const generateMapsFromFieldsMap = (fieldsMap, overrideModelMap) => {
    const newModelMap = cloneDeep(overrideModelMap || modelMap)
    const newSchemaMap = {}
    const newErrorsMap = {}
    const newFieldsMap = {
      jurisdiction: JURISDICTION_FIELDS,
      ...fieldsMap,
    }

    Object.entries(newFieldsMap).forEach(([key, fields]) => {
      newModelMap[key] = {
        ...generateModelFromFields(fields),
        ...newModelMap[key]
      }
      newSchemaMap[key] = convertFieldsToYupObject(fields)
      newErrorsMap[key] = {}
    })

    setModelMap(newModelMap)
    setSchemaMap(newSchemaMap)
    setErrorsMap(newErrorsMap)
    setFieldsMap(newFieldsMap)
  }

  useEffect(() => {
    if (searchId) {
      Promise.all([
        clientApi.search.show(searchId),
        profileId && clientApi.profile.show(profileId)
      ]).then(async ([searchRes, profileRes]) => {
        const searchData = searchRes.data;
        const profileData = profileRes?.data || {};

        const newModel = {
          jurisdiction: {},
          basicDetails: {},
        };
        if (searchData.name) {
          newModel.basicDetails.name = searchData.name;
        }
        if (profileData.meta?.name) {
          newModel.basicDetails.name = profileData.meta.name;
        }
        if (searchData.registrationNumber) {
          newModel.basicDetails.registrationNumber = searchData.registrationNumber;
        }
        if (searchData.country) {
          newModel.jurisdiction.registeredOfficeCountry = searchData.country;
        }
        newModel.basicDetails.searchId = searchId;
        if (!!profileId) {
          newModel.basicDetails.profileId = profileId;
        }

        generateMapsFromFieldsMap({
          basicDetails: SYSTEM_FIELDS_COMPANY
            .filter(e => !e.isRulebookCountry)
            .filter(e => e.key !== 'folderId')
        }, newModel)
        handleMapChange('basicDetails', 'collapseMap')(true)
      })
    }
    // eslint-disable-next-line
  }, [])

  const handleMapChange = (name, mapType) => (value) => {
    const setFunctionMap = {
      'modelMap': setModelMap,
      'errorsMap': setErrorsMap,
      'schemaMap': setSchemaMap,
      'fieldsMap': setFieldsMap,
      'collapseMap': setCollapseMap,
    }

    setFunctionMap[mapType](oldMap => ({
      ...oldMap,
      [name]: value
    }))
  }

  const onNext = async (suppressWarning, skipDuplicate) => {
    let hasError = false
    let hasWarning = false

    let cardWithError = '';
    for (const key in fieldsMap) {
      const model = modelMap[key]
      try {
        await schemaMap[key].validate(model, { abortEarly: false })
      } catch (err) {
        const errors = parseYupErrors(err)
        handleMapChange(key, 'errorsMap')(errors)
        const fields = fieldsMap[key]
        for (const errorKey in errors) {
          const field = fields.find(e => e.key === errorKey)
          if (field?.useWarning) {
            if (!suppressWarning && !cardWithError) {
              cardWithError = key;
            }
            hasWarning = true;
          } else {
            if (!cardWithError) {
              cardWithError = key;
            }
            hasError = true;
          }
        }
      }
    }

    if (cardWithError) {
      handleMapChange(cardWithError, 'collapseMap')(true)
      document.getElementById(cardWithError + 'Card').scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
    if (hasError) return;
    if (hasWarning && !suppressWarning) return setHasWarning(true);
    try {
      const data = prepareData(modelMap, fieldsMap)

      if (!skipDuplicate) {
        const hasDuplicate = await findDuplicateCompany({
          id: clientId,
          legalEntityType: data.type,
          name: data.name,
          registeredOfficeCountry: data.registeredOfficeCountry,
          registrationNumber: data.typeSpecificProperties.propertyMap.registrationNumber?.value,
        })
  
        if (hasDuplicate) return;
      }

      const res = clientId
        ? await clientApi.company.update(clientId, data)
        : await clientApi.company.store(data)

      if (searchId) {
        await clientApi.client.linkToSearch(res.data.id, searchId).then()

        // If there is no profile, set search rating as low, otherwise null
        await clientApi.search.updateSearchReview(searchId, { bgCheckRiskRating: !profileId ? 'Low' : null })
      }

      enqueueSnackbar(`Legal entity "${data.name}" was successfully saved in portfolio!`, { variant: 'success' });
      handleNext(res.data.id, modelMap, searchId);
    } catch (err) {
      // Error looks like this
      // {
      //   firstname: 'must not be empty',
      //   properties.propertyMap[1eb293b4-7a78-40c7-8bd1-505eacced78d].value: 'must not be null',
      // }
      const errorsMap = {
        jurisdiction: {},
        basicDetails: {},
        extendedDetails: {},
        ownershipDetails: {},
        activityDetails: {},
      }
      if (err.response?.data.errorMessages) {
        Object.entries(err.response?.data.errorMessages).forEach(([key, errMessage]) => {
          let propName = key
          if (propName.includes('properties.propertyMap')) {
            propName = propName.replace('properties.propertyMap[', '').replace('].value', '')
          }

          ['jurisdiction', 'basicDetails', 'extendedDetails', 'ownershipDetails', 'activityDetails'].forEach(mapKey => {
            if (fieldsMap[mapKey]) {
              const field = fieldsMap[mapKey].find(e => e.key === propName)

              if (field) {
                errorsMap[mapKey][propName] = field.label + ' ' + errMessage
              }
            }
          })
        })
      }

      setErrorsMap(errorsMap)
    }
  }

  const titleMap = useMemo(() => {
    return {
      jurisdiction: `Country of jurisdiction: ${countryCodeList[modelMap.jurisdiction.registeredOfficeCountry] || ''}`,
      basicDetails: 'Basic Details',
      extendedDetails: 'Extended Details',
      ownershipDetails: 'Ownership Information',
      activityDetails: 'Activity and Regulation'
    }
  }, [modelMap])

  const hasError = Object.values(errorsMap)
    .some(errors => Object.values(errors).some(e => !!e))

  return (
    <PageLayout>
      <DuplicateClientDialog
        {...duplicateDialogProps}
        type="legal entity"
        onProceed={() => onNext(true, true)}
      />

      <Box px={6}>
        <InnerHeader
          title="Add new legal entity"
          stepper={{
            currentStep: steps.currentStep,
            stepsCount: steps.stepsCount,
          }}
          buttons={
            <React.Fragment>
              <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={() => history.goBack()}>
                Back
              </ButtonWithIcon>
            </React.Fragment>
          }
        />
      </Box>
      <Box px={6} style={{ overflowY: 'auto', maxHeight: '85vh' }}>
        <Box display="flex" alignItems="center" flexDirection="column">
          {Object.keys(fieldsMap)
            .filter(key => fieldsMap[key].length)
            .map((key, index) => (
              <CardForm
                key={key}
                id={key + 'Card'}
                cardIndex={index}
                title={titleMap[key]}
                fields={fieldsMap[key]}
                schema={schemaMap[key]}
                model={modelMap[key]}
                setModel={handleMapChange(key, 'modelMap')}
                errors={errorsMap[key]}
                setErrors={handleMapChange(key, 'errorsMap')}
                collapse={collapseMap[key]}
                setCollapse={handleMapChange(key, 'collapseMap')}
                withoutCard={key === 'jurisdiction'}
              />
            ))}
          <Box width="100%" maxWidth={1000} mt={8} mb={3}>
            {(hasError && hasWarning) &&
              <Box>
                <Typography align="right">Fields highlighted in orange are required, but you can complete them later.</Typography>
                <Typography align="right">By clicking on Next button, this client will be saved but will be marked as incomplete.</Typography>
              </Box>
            }
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button style={{ width: '208px' }} variant="contained" onClick={() => onNext(hasWarning)}>
                Next
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </PageLayout>
  )
}

import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Box, Grid, FormControlLabel, IconButton, Switch } from '@material-ui/core';
import TableList, { TCELL_TYPES } from '@components/lists/table-list';
import OutlinedSearch from '@components/inputs/outlined-search';
import InlineHeadBadge from '@components/badges/inline-head-badge';
import FilterWithContext from '@components/dropdowns/filter-with-context';
import NoResultsFound from '@components/layout/no-results-found';
import CustomTooltip from '@components/controls/tooltip';
import { CircleArrowDownIcon, CircleArrowUpIcon } from '@app/icons';
import { Modal } from '@components/modals';
import InviteForm from './form/invite-form';
import EditForm from './form/edit-form';
import useErrorState from '@utils/errorState';

import history from '@app/history';
import { makeStyles } from '@material-ui/core/styles';
import { dummy } from '../dummy';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: 1,
  },
  dropdownButton: {
    padding: 10,
  },
}));

const switchGroup = [
  { value: 'active', label: 'Active Team Members', isChecked: true },
  { value: 'blocked', label: 'Blocked Team Members', isChecked: true },
]

const schema = {
  email: '',
  teamLeader: '',
  firstName: '',
  lastName: '',
  country: '',
  address: '',
  state: '',
  city: '',
  zip: '',
  phone: '',
}

export default function ManageTeams({ modalAction, setModalAction, setUserData }) {
  const classes = useStyles();

  const [model, setModel] = useState(schema);
  const [errors, setErrors] = useErrorState({}); //eslint-disable-line

  const [backup, setBackup] = useState(null); //eslint-disable-line
  const [data, setData] = useState(dummy.teams);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState(switchGroup);

  useEffect(() => {
    setUserData(data);
  }, [data, setUserData])

  const customActions = (item) => {
    return (
      <CustomTooltip title={item.isOpen ? 'Collapse' : 'Expand'} placement="top">
        <span>
          <IconButton
            onClick={() => handleCollapse(item)}
            className={classes.dropdownButton}
          >
            {item.isOpen ? <CircleArrowUpIcon />  : <CircleArrowDownIcon />}
          </IconButton>
        </span>
      </CustomTooltip>
    )}
  
  const TABLE_COLUMNS = [
    { cellType: TCELL_TYPES.CUSTOM, field: 'actions', headerName: '', custom: customActions },
    { field: 'name', headerName: 'Name', bold: true },
    { field: 'organization', headerName: 'Organization' },
    { field: 'role', headerName: 'Role' },
    { field: 'subscription', headerName: 'Subscription' },
    { field: 'lastLogin', headerName: 'Last Login' },
    { field: 'status', headerName: 'Status' },
  ]

  const COLLAPSE_COLUMNS = [
    { field: 'name', headerName: 'Name', useTooltip: true, tooltipValue: 'email' },
    { field: 'organization', headerName: 'Organization' },
    { field: 'role', headerName: 'Role' },
    { field: 'subscription', headerName: 'Subscription', isEmpty: true },
    { field: 'lastLogin', headerName: 'Last Login' },
    { field: 'status', headerName: 'Status' },
  ]

  const onSearchChange = useCallback((event) => {
    setSearch(event.target.value);

    const filtered = backup.filter(el =>
      el.name.toLowerCase().includes(event.target.value?.trim()?.toLowerCase())
    );
    setData(filtered);
  }, [backup])

  const handleChange = (prop) => (event) => {
    const newModel = { ...model, [prop]: event.target.value };
    setModel(newModel);
  };

  const onFilterChange = useCallback((event) => {
    const changed = filters.map(el =>
      el.value === event.target.value ? ({ ...el, isChecked: event.target.checked }) : el
    );
    setFilters(changed);
  }, [filters])

  const handleCollapse = useCallback((collapseItem) => {
    const newData = data.map(
      (item) => item.id === collapseItem.id
        ? ({ ...item, isOpen: !item.isOpen })
        : item
    );
    setData(newData);
  }, [data])

  const onSelectCollapse = useCallback((id, event, collapseItemID) => {
    const newData = data.map((item) => item.id === id
      ? ({ ...item, collapse: item.collapse.map(el => el.id === collapseItemID ? ({ ...el, isChecked: !el.isChecked }) : el) })
      : item
    );
    setData(newData);
  }, [data])

  const handleCloseModal = useCallback(() => {
    setModalAction(null);
  }, [setModalAction])

  const handleEdit = useCallback((id, item, type) => {
    let newModel = {};
    for (const key in model) {
      const value = key === 'phone' ? Number(item[key]) : item[key];
      newModel = { ...newModel, [key]: value }
    }
    setModel(newModel);

    setModalAction({ id, type });
  }, [model, setModalAction])

  const handleView = useCallback((id, item) => {
    item.role === 'Account Owner'
      ? history.push('/user-management/manage-users/view-user/account-usage/?userId=1')
      : history.push(`/user-management/manage-users/view-user/team-members/view-member?userId=${id}`)
  }, [])

  const handleMenuAction = useCallback((id, item, type) => {
    setModalAction({ id, name: `"${item.name}"`, type, prefix: 'user' });
  }, [setModalAction])

  const threeDotsActions = useMemo(() => {
    return [
      { label: 'View', handler: handleView },
      { label: 'Edit', handler: (id, item) => handleEdit(id, item, 'edit') },
      { label: 'Delete', handler: (id, item) => handleMenuAction(id, item, 'delete') },
      { label: 'Block', handler: (id, item) => handleMenuAction(id, item, 'block') },
      { label: 'Unblock', handler: (id, item) => handleMenuAction(id, item, 'unblock'), disabled: true },
    ]
  }, [handleEdit, handleView, handleMenuAction])

  const prepareItems = (items) => {
    return items.map(e => {
      const item = { ...e };

      if (item.collapse) {
        item.collapse = prepareItems(item.collapse)
      }

      item.backgroundColorRow = '#EDEDED';

      item.cellProps = {
        actions: {
          style: {
            width: '35px'
          }
        },
        name: {
          style: {
            width: '250px'
          }
        },
        organization: {
          style: {
            width: '150px'
          }
        },
        role: {
          style: {
            width: '150px'
          }
        },
        subscription: {
          style: {
            width: '150px'
          }
        },
        lastLogin: {
          style: {
            width: '200px'
          }
        },
        status: {
          style: {
            width: '150px'
          }
        },
      }

      switch (item.status) {
        case 'Active':
          item.cellProps = {
            ...item.cellProps,
            status: {
              style: {
                ...item?.status?.style,
                color: '#4E922C'
              }
            }
          }
          item.threeDotsActionsDisabled = ['Unblock']
          break;

        case 'Blocked':
          item.cellProps = {
            ...item?.cellProps,
            status: {
              style: {
                ...item?.status?.style,
                color: '#B6514C'
              }
            }
          }
          item.threeDotsActionsDisabled = ['Block']
          break;
        
        default:
          item.threeDotsActionsDisabled = ['Unblock']
          break;
      }
      
      return item;
    }) || [];
  }

  const formattedData = useMemo(() => {
    return prepareItems(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  
  const modalContent = {
    'invite': <InviteForm model={model} errors={errors} handleChange={handleChange} />,
    'edit': <EditForm model={model} errors={errors} handleChange={handleChange} />,
    'view': <EditForm model={model} errors={errors} handleChange={() => {}} disabled />
  }

  return (
    <Box display={'flex'} flexGrow={'1'} mt={2} flexDirection={'column'}>
      <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'}>
          <Box mr={3}>
            <InlineHeadBadge
              badgeColor={'#CAD7CA'}
              badgeValue={3}
            >
              Team Leaders
            </InlineHeadBadge>
          </Box>
          <Box mr={1}>
            <InlineHeadBadge
              badgeColor={'#CAD7CA'}
              badgeValue={5}
            >
              Team Members
            </InlineHeadBadge>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'center'} height={'48px'}>
          <FilterWithContext>
            <Grid container spacing={8}>
              <Grid item xs={6}>
                {filters.map((el, i) => (
                  <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value} - ${i}`}>
                    <FormControlLabel
                      control={<Switch value={el.value} checked={el.isChecked} onClick={onFilterChange} />}
                      label={el.label}
                    />
                  </Box>
                ))}
              </Grid>
            </Grid>
          </FilterWithContext>
        </Box>
      </Box>
      <Box display={'flex'} mb={3}>
        <OutlinedSearch
          value={search}
          onChange={onSearchChange}
          label="Search users"
        />
      </Box>
      {!!formattedData.length ? (
        <Grid container>
          <Grid item sm={12}>
            <TableList
              columns={TABLE_COLUMNS}
              collapseColumns={COLLAPSE_COLUMNS}
              items={formattedData}
              collapseClick={handleCollapse}
              onSelectCollapse={onSelectCollapse}
              onClick={(id, item) => handleCollapse(item)}
              pageSize={12}
              pagesCount={1}
              totalCount={data.length}
              page={0}
              // onPageChange={(event, value) => setPage(value - 1)}
              // sortableColumns={['fullName', 'email', 'lastLogin']} // waiting implementing sort from BE side
              maxHeight="calc(100vh - 380px)"
              threeDotsActions={threeDotsActions}
              threeDotsActionsCollapse={threeDotsActions}
            />
          </Grid>
        </Grid>
      ) : (
        <Box mt={25} flexGrow={1}>
          <NoResultsFound>
            The list of teams will appear here
          </NoResultsFound>
        </Box>
      )}
      <Modal
        open={!!modalAction}
        onClose={handleCloseModal}
        title={`${modalAction?.type} Team Member`}
        mainText={`Are you sure you want to ${modalAction?.type} ${modalAction?.prefix} ${modalAction?.name}?`}
        actions={modalAction?.type === 'view' ? [{
          type: 'secondary',
          label: 'OK',
          action: handleCloseModal,
          style: { width: 160 },
        }] : [
          {
            type: 'main',
            label: (modalAction?.type === 'edit' || modalAction?.type === 'invite') ? 'Cancel' : 'NO',
            action: handleCloseModal,
            style: { width: 160 },
          },
          {
            type: 'secondary',
            label: modalAction?.type === 'edit' ? 'Save' : modalAction?.type === 'invite' ?  'INVITE' : 'YES',
            action: handleCloseModal,
            style: { width: 160 },
          },
        ]
        }
        actionsDirection="row"
        content={modalContent[modalAction?.type]}
      />
    </Box>
  )
}
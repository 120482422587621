const initialState = {
  data: [],
  search: localStorage.getItem('globalSearch') || '',
  searchType: JSON.parse(localStorage.getItem('globalSearchType')) || null,
  pagesCount: null,
  totalCount: null,
  page: 0
};

const globalSearch = (state = initialState, action) => {

  switch (action.type) {
    case 'SET_GLOBAL_DEFAULT':
      return initialState;
    case 'SET_DATA': 
      return { ...state, data: action.data }
    case 'SET_SEARCH': 
      return { ...state, search: action.search }
    case 'SET_SEARCH_TYPE': 
      return { ...state, searchType: action.searchType }
    case 'SET_COUNTERS': 
      return { ...state, pagesCount: action.counters.pagesCount, totalCount: action.counters.totalCount }
    case 'SET_PAGE': 
      return { ...state, page: action.page }
    default:
      return state
  }
};

export default globalSearch;

import React, { useMemo } from 'react';
import {
  Box, LinearProgress, makeStyles, Typography, withStyles, Button
} from '@material-ui/core';
import CustomTooltip from '@components/controls/tooltip';
import history from '@app/history';

const CustomLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#EDEDED',
  },
  bar: {
    borderRadius: 5,
  },
  bar1Buffer: {
    backgroundColor: '#B6514C',
  },
  bar2Buffer: {
    backgroundColor: '#B6514C',
    opacity: 0.5,
  },
  dashed: {
    display: 'none'
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  value: {
    color: '#232323',
    fontWeight: 'bold'
  },
  valueBuffer: {
    position: 'absolute',
  },
}));

export default function CreditBar(props) {
  const { title, maxCredits, availableCredits, reservedCredits, remainingCredits, isAdmin, baseRoute, type } = props;

  const classes = useStyles();

  const spentCredits = useMemo(() => {
    return maxCredits - remainingCredits
  }, [remainingCredits, maxCredits])

  const creditsText = useMemo(() => {
    if (reservedCredits) {
      return spentCredits + reservedCredits
    }
    return spentCredits
  }, [spentCredits, reservedCredits])

  const progressValue = useMemo(() => {
    return spentCredits > maxCredits ? maxCredits : spentCredits
  }, [spentCredits, maxCredits])

  const tooltip = useMemo(() => {
    let text = `${spentCredits}\u00a0spent ${type} ${spentCredits === 1 ? 'credit' : 'credits'}`
    if (reservedCredits) {
      text += `, ${reservedCredits}\u00a0reserved ${type} ${reservedCredits === 1 ? 'credit' : 'credits'}`
    }
    text += `, ${availableCredits}\u00a0available ${type} ${availableCredits === 1 ? 'credit' : 'credits'}`
    return text
  }, [reservedCredits, spentCredits, availableCredits, type])

  return (
    <Box>
      {title && (
        <Box display="flex" alignItems="center">
          <Typography variant="h5">{title}</Typography>
        </Box>
      )}
      <CustomTooltip
        title={tooltip}
        placement="top"
      >
        <Box>
          <Box mt="10px">
            <CustomLinearProgress
              variant="buffer"
              valueBuffer={((progressValue + reservedCredits) / maxCredits) * 100}
              value={(progressValue / maxCredits) * 100}
            />
          </Box>
          <Box mt="4px" display="flex" justifyContent="space-between">
            <Typography className={classes.value} variant="body1">{creditsText || 0}</Typography>
            <Typography className={classes.value} variant="body1">{maxCredits || 0}</Typography>
          </Box>
          {!!reservedCredits &&
                        <Box>
                          <Typography variant="body1">*{reservedCredits} reserved</Typography>
                        </Box>
          }
        </Box>
      </CustomTooltip>
      {!isAdmin && (
        <Box mt={1}>
          <Button
            variant="outlined"
            size="small"
            style={{ padding: '2px 26px', lineHeight: '16px' }}
            onClick={() => history.push(baseRoute + '/plans')}
          >
            {'BUY MORE'}
          </Button>
        </Box>
      )}
    </Box>
  )
}

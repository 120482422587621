import riskRatingItemDto from './risk-rating-item-dto';

const riskRatingTemplateDto = {
  read: data => ({
    id: data?.id,
    templateId: data?.id,
    templateName: data?.jsonData.templateName,
    isDefault: data?.systemDefault || false,
    dateOfReview: null,
    items: (data?.jsonData?.questions || []).map(
      riskRatingItemDto.read
    ),
    riskRating: null,
    nextReview: null,
    isDraft: false,
    type: !!data?.systemDefault? 'System': 'Custom'
  }),
  write: data => ({
    templateName: data?.templateName,
    questions: (data?.items || [])
      .filter(item => !item?.excluded)
      .map( riskRatingItemDto.writeTemplate )
  })
}

export default riskRatingTemplateDto
import { Divider, withStyles } from '@material-ui/core';

export const DividerDashed = withStyles((theme) => ({
  root: props => ({
    border: '1px dashed #EFEFEF',
    height: 0,
    backgroundColor: 'inherit',

  }),
}))(Divider);

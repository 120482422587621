import React, { useCallback, useMemo } from 'react';
import { Box, Button, Card, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import CustomChip from '@components/controls/custom-chip';
import CustomTooltip from '@components/controls/tooltip';
import {
  DefaultPlan,
  BasicPlan,
  SmallBusinessPlan,
  PremiumPlan,
  BasicPlusPlan,
  SmallBusinessPlusPlan,
  PremiumPlusPlan,
  UnionOtherIcon,
  CustomPlan,
} from '@app/icons';
import prepareTimestamp from '@utils/date';
import history from '@app/history';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: '0 10px 15px 10px',
    width: 215,
    padding: 22,
    // height: 320,
    position: 'relative',
    overflow: 'visible',
    border: '1px solid #fff',
    display: 'flex',
    flexDirection: 'column',
  },
  disabledCard: {
    // backgroundColor: '#F8F8F8',
    border: '1px solid #A4A4A4',
  },
  cardAbsoluteTag: {
    position: 'absolute',
    top: -8,
    width: 'calc(100% - 44px)',
  },
  benefitLabel: {
    fontSize: 12,
    color: '#232323',
    lineHeight: '20px',
    fontWeight: 300,
  },
  benefitValue: {
    fontSize: 12,
    lineHeight: '20px',
    color: '#232323',
    fontWeight: 500,
    marginRight: '10px',
  },
  divider: {
    borderBottom: '2px solid #EFEFEF',
  },
  purchaseButton: {
    fontSize: 16,
    lineHeight: '28px',
    padding: '10px',
    width: '100%',
  },
  chip: {
    // width: '65px',
    height: '18px',
  },
}));

const planIcons = {
  'Default plan': <DefaultPlan />,
  'Basic plan': <BasicPlan />,
  'Small business plan': <SmallBusinessPlan />,
  'Premium plan': <PremiumPlan />,
  'Basic +': <BasicPlusPlan />,
  'Small business +': <SmallBusinessPlusPlan />,
  'Premium +': <PremiumPlusPlan />,
};

export default function PlanCard(props) {
  const classes = useStyles();
  // eslint-disable-next-line
  const {
    isCurrent,
    planID,
    name,
    premiumCredits,
    price,
    regularCredits,
    type,
    disabled,
    action,
    isAdmin,
    isRenewal,
    requestedUpgradePlanId,
    requestedRenewalPlanId,
    paidRenewalPlanId,
    currentPlanId,
    expirationDate,
    eidvCredits,
    paidRenwealPlanName,
  } = props;

  const disabledPlan = useMemo(() => {
    return (
      disabled ||
      (!isAdmin &&
        (requestedUpgradePlanId === planID ||
          requestedRenewalPlanId === planID)) ||
      paidRenewalPlanId >= currentPlanId
    );
  }, [
    disabled,
    requestedUpgradePlanId,
    requestedRenewalPlanId,
    planID,
    isAdmin,
    currentPlanId,
    paidRenewalPlanId,
  ]);

  // eslint-disable-next-line
  const getIcon = () => {
    switch (type) {
      case 'PAID':
        return <UnionOtherIcon />;

      default:
        return null;
    }
  };

  // eslint-disable-next-line
  const getType = () => {
    switch (type) {
      case 'PAID':
        return 'warning';

      default:
        return null;
    }
  };

  const renderButtonLabel = useCallback(() => {
    if (isAdmin) {
      return 'SELECT';
    }

    if (requestedUpgradePlanId === planID && !isCurrent && !disabled) {
      return 'Upgrade Requested';
    }

    if (requestedRenewalPlanId === planID && !isCurrent && !disabled) {
      return 'Upgrade Requested';
    }

    if (
      (requestedRenewalPlanId === currentPlanId && isCurrent) ||
      (requestedRenewalPlanId === planID && disabled)
    ) {
      return 'Renew Requested';
    }

    if (paidRenewalPlanId === currentPlanId && isCurrent) {
      return 'AUTO-RENEWED';
    }

    if (paidRenewalPlanId === planID && !isCurrent && isRenewal && !disabled) {
      return 'UPGRADED';
    }

    if (isCurrent) {
      return 'My Plan';
    }

    return disabled ? 'Downgrade' : 'Upgrade';
  }, [
    isAdmin,
    isCurrent,
    disabled,
    isRenewal,
    requestedUpgradePlanId,
    requestedRenewalPlanId,
    planID,
    paidRenewalPlanId,
    currentPlanId,
  ]);

  const renderTooltipLabel = useCallback(() => {
    if (
      (requestedUpgradePlanId === planID ||
        requestedRenewalPlanId === planID) &&
      !isAdmin &&
      !isCurrent &&
      !disabled
    ) {
      return 'Upgrade request sent, an email with activation confirmation should be sent. Once the payment is processed, your new plan will be activated.';
    }

    if (
      ((requestedRenewalPlanId === planID && isCurrent) ||
        (requestedRenewalPlanId === planID && disabled)) &&
      !isAdmin
    ) {
      return 'Renew request sent, an email with activation confirmation should be sent. Once the payment is processed, your plan will be renewed.';
    }

    if (paidRenewalPlanId >= currentPlanId && planID >= currentPlanId) {
      return `You have already paid for subscription plan "${paidRenwealPlanName}". It will become active after your current plan expires ${prepareTimestamp(
        expirationDate,
        'MM/DD/YYYY'
      )}.`;
    }

    if (isCurrent) {
      return `This is ${isAdmin ? 'user\'s' : 'your'} current plan`;
    }

    return 'Subscription plan downgrade is not supported. Please email us at support@kycpassport.com for more details';
  }, [
    isAdmin,
    isCurrent,
    requestedUpgradePlanId,
    requestedRenewalPlanId,
    planID,
    paidRenewalPlanId,
    currentPlanId,
    expirationDate,
    paidRenwealPlanName,
    disabled,
  ]);

  const renderChip = useCallback(() => {
    if (isCurrent) {
      if (paidRenewalPlanId === currentPlanId) {
        return (
          <Box
            className={classes.cardAbsoluteTag}
            display="flex"
            justifyContent="center"
          >
            <CustomChip label="Auto-Renewed" propsClasses={classes.chip} />
          </Box>
        );
      }

      return (
        <Box
          className={classes.cardAbsoluteTag}
          display="flex"
          justifyContent="center"
        >
          <CustomChip
            label={isAdmin ? 'User Plan' : 'My Plan'}
            propsClasses={classes.chip}
          />
        </Box>
      );
    }

    if (paidRenewalPlanId === planID && !isCurrent && isRenewal && !disabled) {
      return (
        <CustomTooltip
          title="This is your chosen next plan. It will become active after your current plan expires"
          placement="top"
        >
          <Box
            className={classes.cardAbsoluteTag}
            display="flex"
            justifyContent="center"
          >
            <CustomChip
              label={isAdmin ? 'User Next Plan' : 'My Next Plan'}
              labelColor={'#fff'}
              background={'#7A5B44'}
            />
          </Box>
        </CustomTooltip>
      );
    }
  }, [
    isCurrent,
    isAdmin,
    paidRenewalPlanId,
    currentPlanId,
    planID,
    isRenewal,
    disabled,
    classes,
  ]);

  return (
    <Card
      className={clsx(classes.card, isCurrent && classes.disabledCard)}
      onClick={() => isCurrent && history.push('/my-profile/subscription')}
    >
      {renderChip()}
      <Typography variant="h4" align="center">
        {name}
      </Typography>
      <Box display="flex" justifyContent="center">
        <CustomChip
          shadow
          border="0"
          label={
            eidvCredits === 0 ? 'Screening and Risk' : 'Digital Verification'
          }
          propsClasses={classes.chip}
          labelColor={'#534D49'}
          background={'#FFF'}
        />
      </Box>
      <Box my={4} display="flex" justifyContent="center">
        {planIcons[name] || <CustomPlan />}
      </Box>
      {/* <Box display="flex" justifyContent="center" mt={1}>
              <CustomChip type={getType()} label={type === 'PAID' ? 'Premium' : 'Default'} icon={getIcon()} coloredBorder disabled={isCurrent} />
          </Box> */}
      <Box
        mt={3}
        mb={4}
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="center"
      >
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          {/* <Typography className={classes.benefitValue}>10</Typography> */}
          <Typography className={classes.benefitValue}>
            {regularCredits}
          </Typography>
          <Typography className={classes.benefitValue}>
            {premiumCredits}
          </Typography>
          <Typography className={classes.benefitValue}>
            {eidvCredits}
          </Typography>
          <Typography className={classes.benefitValue}></Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          {/* <Typography className={classes.benefitLabel}>Team Members</Typography> */}
          <Typography className={classes.benefitLabel}>
            Regular Credits
          </Typography>
          <Typography className={classes.benefitLabel}>
            Premium Credits
          </Typography>
          <Typography className={classes.benefitLabel}>
            Electronic Identity Verifications
          </Typography>
          <Typography className={classes.benefitLabel}>
            Ongoing monitoring
          </Typography>
          <Typography className={classes.benefitLabel}>
            Risk Rating Templates
          </Typography>
          <Typography className={classes.benefitLabel}>
            KYC Document repository
          </Typography>
        </Box>
      </Box>
      {/* <Box mt="20px" className={classes.divider} /> */}
      <Box pt={1} style={{ marginTop: 'auto' }}>
        <Typography variant="h4" align="center">
          {price !== 0 ? `$${price}` : 'FREE'}
        </Typography>
        <Typography className={classes.benefitLabel} align="center">
          YEARLY SUBSCRIPTION
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" pt={2}>
        <CustomTooltip
          title={renderTooltipLabel()}
          placement="top"
          disableHoverListener={!disabledPlan}
        >
          <span style={{ width: '100%' }}>
            <Button
              variant="contained"
              size="medium"
              onClick={() =>
                isCurrent
                  ? history.push('/my-profile/subscription')
                  : action({
                    type: 'confirm',
                    eidvCredits,
                    premiumCredits,
                    regularCredits,
                    name,
                    id: planID,
                  })
              }
              disabled={disabledPlan}
              className={classes.purchaseButton}
            >
              {renderButtonLabel()}
            </Button>
          </span>
        </CustomTooltip>
      </Box>
    </Card>
  );
}

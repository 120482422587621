const config = {
  MuiSwitch: {
    root: {
      width: '52px',
      '& .Mui-checked': {
        '& .MuiSwitch-thumb': {
          backgroundColor: '#7A5B44',
          border: '1px solid #7A5B44',
        }
      },
      '& .Mui-disabled': {
        '& .MuiSwitch-thumb': {
          backgroundColor: '#888 !important',
          border: '1px solid #777 !important',
          color: '#fff',
        },
        '& .MuiSwitch-track': {
          backgroundColor: '#C6C6C6'
        },
      },
    },
    thumb: {
      border: '1px solid #000000',
      color: '#fff',
      width: '13px',
      height: '13px'
    },
    track: {
      opacity: 1,
      backgroundColor: '#A4A4A4'
    },
    switchBase: {
      padding: '11px',
      '&.Mui-checked': {
        transform: 'translateX(15px)',
      },
      '&.Mui-checked + .MuiSwitch-track': {
        opacity: 1
      }
    },
    colorSecondary: {
      '&.Mui-checked + .MuiSwitch-track': {
        background: '#A4A4A4'
      }
    }
  },
}

export default config;
import React, { useState } from 'react';
import { Box, Grid, RadioGroup, FormControlLabel, Radio, Switch } from '@material-ui/core';
import TableList from '@components/lists/table-list';
import OutlinedSearch from '@components/inputs/outlined-search';
import InlineHeadBadge from '@components/badges/inline-head-badge';
import FilterWithContext from '@components/dropdowns/filter-with-context';
import { Modal } from '@components/modals';
import { dummy } from '../dummy';
import history from '@app/history';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
}))

const radioGroup = [
  { value: 'fullName', label: 'Sort by Full Name' },
  { value: 'email', label: 'Sort by Email' },
  { value: 'lastLogin', label: 'Sort by Last Login' },
  { value: 'adminRole', label: 'Sort by Admin Role' },
]

const TABLE_COLUMNS = [
  { field: 'fullName', headerName: 'Full Name', bold: true },
  { field: 'email', headerName: 'Email' },
  { field: 'roleName', headerName: 'Role Name' },
  { field: 'lastLogin', headerName: 'Last Login' },
]

const switchGroup = [
  { value: 'juniorAnalyst', label: 'Junior Analyst', isChecked: true },
  { value: 'seniorAnalyst', label: 'Senior Analyst', isChecked: true },
  { value: 'superAdmin', label: 'Super Admin', isChecked: true },
]

export default function ManageAdmins() {
  const [data, setData] = useState(dummy.admins);
  const [search, setSearch] = useState();
  const [modalAction, setModalAction] = useState({});
  const [sort, setSort] = useState('lastLogin');
  const [filters, setFilters] = useState(switchGroup);

  const classes = useStyles(); // eslint-disable-line

  const onSelect = (id) => {
    const newData = data.map(
      (item) => item.id === id
        ? ({ ...item, isChecked: !item.isChecked })
        : item
    );
    setData(newData);
  }

  const onSearchChange = (event) => {
    setSearch(event.target.value);
  }

  const onFilterChange = (event) => {
    const changed = filters.map(el =>
      el.value === event.target.value ? ({ ...el, isChecked: event.target.checked }) : el
    );
    setFilters(changed);
  }

  const handleDelete = (item) => {
    setModalAction({
      id: item.id,
      name: item.fullName,
      type: 'delete',
      title: 'Delete admin'
    })
  }
  
  const handleBlock = (item) => {
    setModalAction({
      id: item.id,
      name: item.fullName,
      type: 'block',
      title: 'Block admin'
    })
  }

  const handleView = (id, item) => {
    history.push(`/admin-management/view-admin/?adminId=${id}&&fullName=${item.fullName}`)
  }

  const handleEdit = (id, item) => {
    history.push(`/admin-management/edit-admin/?adminId=${id}`)
  }

  return (
    <Box display={'flex'} flexGrow={'1'} mt={2} flexDirection={'column'}>
      <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'}>
          <Box mr={1}>
            <InlineHeadBadge
              badgeColor={'#CAD7CA'}
              badgeValue={9}
            >
              Admins
            </InlineHeadBadge>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <FilterWithContext>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <RadioGroup name="sort" value={sort} onChange={e => setSort(e.target.value)}>
                  {radioGroup.map((el, i) => (
                    <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value} - ${i}`}>
                      <FormControlLabel
                        control={<Radio />}
                        value={el.value}
                        label={el.label}
                      />
                    </Box>
                  ))}
                </RadioGroup>
              </Grid>
              <Grid item xs={6}>
                {filters.map((el, i) => (
                  <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value} - ${i}`}>
                    <FormControlLabel
                      control={<Switch value={el.value} checked={el.isChecked} onClick={onFilterChange} />}
                      label={el.label}
                    />
                  </Box>
                ))}
              </Grid>
            </Grid>
          </FilterWithContext>
        </Box>
      </Box>
      <Box display={'flex'} mb={3}>
        <OutlinedSearch
          value={search}
          onChange={onSearchChange}
          label="Search admins"
        />
      </Box>
      <Grid container>
        <Grid item sm={12}>
          <TableList
            columns={TABLE_COLUMNS}
            items={data}
            onSelect={onSelect}
            onClick={handleView}
            pageSize={12}
            pagesCount={1}
            totalCount={9}
            sortableColumns={TABLE_COLUMNS.map(el => el.field)}
            maxHeight="calc(100vh - 380px)"
            threeDotsActions={[
              { label: 'View', handler: handleView },
              { label: 'Edit', handler: handleEdit },
              { label: 'Delete', handler: (id, item) => handleDelete(item) },
              { label: 'Block', handler: (id, item) => handleBlock(item) },
              { label: 'Unblock', handler: () => {}, disabled: true },
            ]}
          />
        </Grid>
      </Grid>
      <Modal
        open={!!modalAction.id}
        onClose={() => setModalAction({})}
        title={modalAction.title}
        mainText={`Are you sure you want to ${modalAction.type} "${modalAction.name}"?`}
        actions={
          [
            {
              type: 'main',
              label: 'NO',
              action: () => setModalAction({}),
              style: { width: 160 },
            },
            {
              type: 'secondary',
              label: 'YES',
              action: () => setModalAction({}),
              style: { width: 160 },
            },
          ]
        }
        actionsDirection="row"
      />
    </Box>
  )
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LabelsForRender from '@components/layout/labels-for-render';
import { useSelector } from 'react-redux';

export const useStyles = makeStyles((theme) => ({
  wrapperLabels: {
    marginBottom: '2px',
    alignItems: 'flex-start'
  },
}));

export default function UserInfoCardBgCheckRisk({ handleChangeRisk }) {
  const classes = useStyles();

  const { profilesVersionId } = useSelector(store => store.searchHistory);
  const { selectedProfile } = useSelector(store => store.backgroundCheck);

  return (
    <LabelsForRender
      labels={[{ field: 'BG CHECK RISK', value: selectedProfile.bgCheckRiskRating || 'Not Set' }]}
      handleChangeRisk={handleChangeRisk}
      anchorProps={{
        vertical: 'bottom',
        horizontal: -50,
      }}
      risk={selectedProfile.bgCheckRiskRating}
      disabled={!!profilesVersionId}
      propsWrapper={classes.wrapperLabels}
    />
  )
}



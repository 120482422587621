import React, { useCallback, useState, useEffect } from 'react'; // eslint-disable-line
import {
  Box,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  makeStyles,
  Button,
  Typography,
} from '@material-ui/core'; // eslint-disable-line
import { useSelector, useDispatch } from 'react-redux';

import { clientDocumentCategory } from '@app/api/client'; // eslint-disable-line
import FilterWithContext from '@components/dropdowns/filter-with-context';
import { calendarSchedule } from '@app/store/actions';
import { RADIO_GROUP, SWITCH_GROUP, EXCLUDED_TABS } from './constants'; // eslint-disable-line

const useStyles = makeStyles((theme) => ({
  reset: {
    marginRight: '10px',
    '&.MuiButton-sizeSmall': {
      padding: '2px 15px',
    },
  },
  filterActive: {
    backgroundColor: '#7A5B44',
    '&:hover': {
      backgroundColor: '#7A5B44',
      opacity: 0.9,
    },
  },
}));

export default function HeaderButtonsBlock({ location }) {
  const classes = useStyles();
  const [checkbox, setCheckbox] = useState(['idDocuments']);
  // const [categories, setCategories] = useState([]);
  // const [checkedCategories, setCheckedCategories] = useState([]);

  const { sort, filters, documentFilter } = useSelector(
    (state) => state.calendarSchedule
  );

  const dispatch = useDispatch();

  const onSortChange = useCallback(
    (value) => {
      dispatch(calendarSchedule.setPaginationPage(0));
      dispatch(calendarSchedule.setSort(value));
    },
    [dispatch]
  );

  useEffect(() => {
    if (documentFilter.types === 'all') {
      setCheckbox(['idDocuments', 'proofOfAddress']);
    } else {
      setCheckbox([documentFilter.types]);
    }
  // eslint-disable-next-line
  }, []);

  // const getCategories = async () => {
  //   const categoriesResponse = (await clientDocumentCategory.index()).data;
  //   if (categoriesResponse) {
  //     const userCategories = categoriesResponse.entries?.filter(
  //       (item) => item.ownedBy === 'USER'
  //     );
  //     const systemCategories = categoriesResponse.entries?.filter(
  //       (item) => item.ownedBy === 'SYSTEM'
  //     );
  //     setCategories({
  //       system: systemCategories,
  //       user: userCategories,
  //       all: categoriesResponse.entries,
  //     });
  //   }
  // };

  const handleCheckbox = (e) => {
    let tmp = checkbox;
    if (e.target.checked) {
      tmp.push(e.target.value);
    } else {
      tmp = tmp.filter((p) => p !== e.target.value);
    }
    let types = tmp.length === 1 ? tmp[0] : 'all';
    dispatch(
      calendarSchedule.setDocumentFilter({
        types: types,
        categories: documentFilter.categories,
      })
    );
    setCheckbox(tmp);
  };

  // const handleCategories = (e) => {
  //   let tmp = checkedCategories;
  //   if (e.target.checked) {
  //     tmp.push(e.target.value);
  //   } else {
  //     tmp = tmp.filter((p) => p !== e.target.value);
  //   }
  //   setCheckedCategories(tmp);
  //   dispatch(
  //     calendarSchedule.setDocumentFilter({
  //       types: documentFilter.types,
  //       categories: tmp,
  //     })
  //   );
  // };

  const onFilterChange = useCallback(// eslint-disable-line
    (event) => {
      dispatch(
        calendarSchedule.setFilters({
          ...filters,
          [event.target.value]: event.target.checked,
        })
      );
    },
    [filters, dispatch]
  );

  const handleReset = () => {
    dispatch(calendarSchedule.setPaginationPage(0));
    if (location === 'all') {
      dispatch(calendarSchedule.setDefault());
      setCheckbox(['idDocuments']);
      // setCheckedCategories([]);
    } else if (location === 'document-reviews') {
      dispatch(
        calendarSchedule.setDocumentFilter({
          types: ['idDocuments'],
          categories: [],
        })
      );
      setCheckbox(['idDocuments']);
      // setCheckedCategories([]);
    } else {
      dispatch(calendarSchedule.setSort('all'));
    }
  };
  const setFilterActive = () =>
    !!{
      all: !(sort === 'all' && documentFilter.types === 'idDocuments'),
      'document-reviews': documentFilter.types !== 'idDocuments',
      'client-reviews': sort !== 'all',
    }[location];

  return (
    <Box display="flex" alignItems="center">
      {location !== 'eidv-reviews' && (
        <>
          <Button
            size="small"
            className={classes.reset}
            onClick={() => handleReset()}
          >
            Reset filter
          </Button>
          <FilterWithContext
            label="FILTER"
            filterActive={setFilterActive() && classes.filterActive}
          >
            <Grid container spacing={2}>
              {(location === 'all' || location === 'client-reviews') && (
                <Grid item xs={6}>
                  <Box display="flex" pb={1}>
                    <Typography variant="h5">View Entities</Typography>
                  </Box>
                  <RadioGroup
                    name="sort"
                    value={sort}
                    onChange={(e) => onSortChange(e.target.value)}
                  >
                    {RADIO_GROUP.map((el, i) => (
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        height={'40px'}
                        key={`${el.value} - ${i}`}
                      >
                        <FormControlLabel
                          control={<Radio />}
                          value={el.value}
                          label={el.label}
                        />
                      </Box>
                    ))}
                  </RadioGroup>
                </Grid>
              )}
              {(location === 'all' || location === 'document-reviews') && (
                <>
                  <Grid item xs={6}>
                    <Box display={'flex'} pb={1}>
                      <Typography variant="h5">View Documents</Typography>
                    </Box>
                    {EXCLUDED_TABS.map((item, index) => (
                      <Box display={'flex'} key={index} pr={4}>
                        <FormControlLabel
                          control={
                            <Switch
                              value={item.value}
                              checked={
                                checkbox.find((e) => e === item.value)
                                  ? true
                                  : false
                              }
                              onChange={(e) => handleCheckbox(e)}
                            />
                          }
                          label={item.label}
                        />
                      </Box>
                    ))}
                  </Grid>
                  {/* <Grid item>
                    <Box display={'flex'} pb={1}>
                      <Typography variant="h5">System Categories</Typography>
                    </Box>
                    {categories?.system?.map((el) => (
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        height={'40px'}
                        key={`${el.id}-${el.name}-i`}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              value={el.id}
                              onChange={handleCategories}
                              checked={checkedCategories.includes(
                                el.id.toString()
                              )}
                            />
                          }
                          label={el.name}
                        />
                      </Box>
                    ))}
                    <Box display={'flex'} pt={1} pb={1}>
                      <Typography variant="h5">User Categories</Typography>
                    </Box>
                    {categories?.user?.map((el) => (
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        height={'40px'}
                        key={`${el.id}-${el.name}-i`}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              value={el.id}
                              onChange={handleCategories}
                              checked={checkedCategories.includes(
                                el.id.toString()
                              )}
                            />
                          }
                          label={el.name}
                        />
                      </Box>
                    ))}
                  </Grid> */}
                </>
              )}
              {/* waiting implement API from BE
          {!location.pathname.includes('-reviews') && (
            <Grid item xs={6}>
              {SWITCH_GROUP.map((el, i) => (
                <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value} - ${i}`}>
                  <FormControlLabel
                    control={<Switch value={el.value} checked={filters[el.value]} onClick={onFilterChange} />}
                    label={el.label}
                  />
                </Box>
              ))}
            </Grid>
          )} */}
            </Grid>
          </FilterWithContext>
        </>
      )}
    </Box>
  );
}

import React, {useMemo, useState} from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import _ from 'lodash';
import {makeStyles} from '@material-ui/core/styles';
import InputFieldRow from './input-field-row';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import InputFieldDialog from '../dialogs/input-field-dialog';
import {
  AddIcon
} from '@app/icons';
import NoResultsFound from '@components/layout/no-results-found';
import {getIsInheritedItem} from '../../../utils/inheritance'

const useStyles = makeStyles(theme => ({
  tableOverlay: {

  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 20px',
    '& tr.MuiTableRow-root:hover': {
      background: 'inherit'
    },
    '& td.MuiTableCell-root': {
      borderRadius: 0
    },
    '& td.MuiTableCell-root:nth-child(2)': {
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12
    },
    '& td.MuiTableCell-root:nth-last-child(2)': {
      borderTopRightRadius: 12,
      borderBottomRightRadius: 12
    },
    '& td.MuiTableCell-root:not(:first-child, :last-child)': {
      background: '#FFF'
    },
    // Separators
    '& td.MuiTableCell-root:nth-child(4)': {
      padding: '4px 0 4px 14px'
    },
    '& td.MuiTableCell-root:nth-child(5)': {
      padding: '4px 0 4px 14px',
    },
    '& td.MuiTableCell-root .wrapperWithSeparator': {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      minHeight: '60px',
      padding: '0 14px 0 0',
      borderRight: '2px solid #EFEFEF'
    }
  }
}))

export default function FormInputFields({
  data,
  setData,
  readOnly,
  rulebook
}) {

  const classes = useStyles()

  const [pendingInputFieldIdx, setPendingInputFieldIdx] = useState(-1)

  const setInputData = index => newInputData => {
    const newInputFields = [ ...inputFieldsOrdered || [] ]
    newInputFields[index] = {
      ...newInputFields[index] || {},
      ...newInputData
    }
    setData({
      ...data,
      inputFields: newInputFields
    })
  }

  const onInputExclude = index => () => {
    const newInputFields = [ ...inputFieldsOrdered ]
    newInputFields.splice(index,1)
    setData({
      ...data,
      inputFields: newInputFields
    })
  }

  const onInputStartEdit = index => () => {
    setPendingInputFieldIdx(index)
  }

  const inputFieldsOrdered = useMemo(() =>
    _.orderBy(data?.inputFields || [], ['displayConfig.order']),
  [ data?.inputFields ]
  )

  const pushOrder = (newInputFields, inputField, direction, baseOrder = null) => {
    const newOrder =
      (direction === 'up')
        ? (baseOrder || inputField.displayConfig.order) - 0.0001
        : (baseOrder || inputField.displayConfig.order) + 0.0001

    const conflictInputField = newInputFields.find(
      inputFieldFind => !getIsInheritedItem(rulebook, inputFieldFind) && inputFieldFind.displayConfig.order === newOrder
    )

    if (conflictInputField) {
      pushOrder(newInputFields, conflictInputField, direction)
    }

    inputField.displayConfig.order = newOrder
  }

  const onSortMove = index => (direction = 'up') => () => {
    const newInputFields = [...inputFieldsOrdered]
    const currentInputField = inputFieldsOrdered[index]
    const currentPosition = currentInputField.displayConfig.order
    let swapInputField;
    if (direction === 'up' && index > 0) {
      swapInputField = inputFieldsOrdered[index-1]
    } else if (direction === 'down' && index < inputFieldsOrdered.length - 1) {
      swapInputField = inputFieldsOrdered[index+1]
    }
    if (swapInputField) {
      if (getIsInheritedItem(rulebook, swapInputField)) {
        pushOrder(newInputFields, currentInputField, direction, swapInputField.displayConfig.order)
      } else {
        currentInputField.displayConfig.order = swapInputField.displayConfig.order
        swapInputField.displayConfig.order = currentPosition
      }
    }
    console.log(
      'Input fields reorder',
      _.orderBy(newInputFields || [], ['displayConfig.order']).map(nif => `${nif.displayName}:${!getIsInheritedItem(rulebook, nif)? 'c': 'i'}: ${nif.displayConfig.order}`)
    )
    setData({
      ...data,
      inputFields: newInputFields
    })
  }

  const onSave = inputFieldData => {
    const idx = pendingInputFieldIdx
    setPendingInputFieldIdx(-1)
    let newInputFields = [...data.inputFields || []]
    if ( idx !== null ) {
      newInputFields[idx] = inputFieldData
    } else {
      inputFieldData.displayConfig.order = inputFieldsOrdered[0]?.displayConfig?.order - 0.0001 || 1
      newInputFields = [
        inputFieldData,
        ...(data?.inputFields || []),
      ]
    }
    setData({
      ...data,
      inputFields: newInputFields
    })
  }

  return (
    <Box
      display={'flex'}
      flexGrow={1}
      flexDirection={'column'}
    >
      { !readOnly && (
        <Box
          display={'flex'}
          pb={3}
          justifyContent={'flex-end'}
        >
          <Box display={'flex'}>
            <ButtonWithIcon
              startIcon={<AddIcon />}
              onClick={() => {
                setPendingInputFieldIdx(null)
              }}
              style={{ marginLeft: -8, whiteSpace: 'nowrap' }}
            >
              Add New
            </ButtonWithIcon>
          </Box>
        </Box>
      )}
      <Box display={'flex'}>

        { !inputFieldsOrdered?.length && (
          <Box display={'flex'} width={'100%'} justifyContent={'center'}>
            <NoResultsFound>
              You did not add any categories to this rulebook
            </NoResultsFound>
          </Box>
        )}

        { !!inputFieldsOrdered?.length && (
          <Table
            classes={{root: classes.table}}
            style={{
              background: '#F8F8F8'
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell/>
                <TableCell/>
                <TableCell width={'25%'}>INPUT FIELD NAME</TableCell>
                <TableCell>FIELD TYPE</TableCell>
                <TableCell>DISPLAY LEVEL</TableCell>
                <TableCell>IS REQUIRED</TableCell>
                {/* <TableCell>CONDITIONAL LOGIC</TableCell> */}
                <TableCell/>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              { inputFieldsOrdered.map((inputData, iRow) => (
                <InputFieldRow
                  key={iRow + inputData.displayName}
                  inputData={inputData}
                  setInputData={setInputData(iRow)}
                  onExclude={onInputExclude(iRow)}
                  onStartEdit={onInputStartEdit(iRow)}
                  onSortMove={onSortMove(iRow)}
                  openEditDialog={() => {
                    setPendingInputFieldIdx(iRow)
                  }}
                  readOnly={
                    readOnly || getIsInheritedItem(rulebook, inputData)
                  }
                  clientType={data?.clientType}
                />
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
      <InputFieldDialog
        open={pendingInputFieldIdx !== -1}
        origData={
          pendingInputFieldIdx !== false && inputFieldsOrdered[pendingInputFieldIdx]
        }
        onSave={onSave}
        onClose={() => {
          setPendingInputFieldIdx(-1)
        }}
        clientType={data?.clientType}
      />
    </Box>
  )
}
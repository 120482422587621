const RISK_RATING_LABELS = {
  'closely-monitoring': 'Closely Monitoring',
  exempted: 'Exempted',
  high: 'High',
  medium: 'Medium',
  low: 'Low'
}

export default function getRiskRatingLabel(riskRatingValue, emptyLabel = 'N/A') {
  return RISK_RATING_LABELS?.[riskRatingValue] || emptyLabel
}
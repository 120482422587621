import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button } from '@material-ui/core';
import RenderInputs from '@components/layout/render-inputs';
import { personalInputs, contactInputs, protectionInputs, defaultModel, schema, withoutPasswordSchema } from './inputs-data';
import useErrorState from '@utils/errorState';
import parseYupErrors from '@app/yup';
import { generatePassword } from '@utils/generatePassword';
import DatePicker from '@components/calendar/input-date-picker'
import history from '@app/history';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    padding: '0 5px',
  },
  inputsWrapper: {
    width: '100%',
  },
  passwordInfo: {
    fontStyle: 'italic',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '20px',
    color: theme.palette.text.BL1,
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '50px 0',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    width: 160,
    margin: '0 5px',
  },
}))

export default function Form({ userData, isEdit }) {
  const [errors, setErrors] = useErrorState({});

  const [model, setModel] = useState(defaultModel);

  const classes = useStyles();

  useEffect(() => {
    if (userData && isEdit) {
      let data = {};
      for (const key in model) {
        data = { ...data, [key]: userData[key] }
      }
      setModel(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  const handleChange = (prop) => (event) => {
    const newModel = { ...model, [prop]: event.target.value };
    setModel(newModel);

    if (errors[prop]) {
      schema.validateAt(prop, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [prop]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [prop]: err.message });
        });
    }
  };

  const onSave = () => {
    setErrors({});
    const validateShema = (model.password || !isEdit)
      ? schema
      : withoutPasswordSchema;

    validateShema
      .validate(model, { abortEarly: false })
      .then(function (valid) {
        history.push('/user-management')
      })
      .catch(function (err) {
        console.log(err)
        setErrors(parseYupErrors(err), true);
      });
  }

  const handlePassword = () => {
    const pass = generatePassword();

    const newModel = { ...model, 'password': pass, 'confirm_password': pass };
    setModel(newModel);
  }

  const renderDatePicker = (
    <DatePicker
      handleChange={handleChange('dob')}
      inputValue={model['dob'] || '01-01-1970'}
    />
  )

  return (
    <Box className={classes.formContainer}>
      <Grid container direction="row" justify="space-between">
        <Grid item sm={5} className={classes.inputsWrapper} direction={'column'}>
          {personalInputs.map((field, i) => (
            <RenderInputs
              key={`${field.key}-${i}`}
              ind={i}
              field={field.key === 'dob' ? { ...field, endAdornment: renderDatePicker } : field}
              model={model}
              errors={errors}
              label="Personal Information"
              handleChange={handleChange}
            />
          ))}
        </Grid>
        <Grid item sm={5} className={classes.inputsWrapper} direction={'column'}>
          {contactInputs.map((field, i) => (
            <RenderInputs
              key={`${field.key}-${i}`}
              ind={i}
              field={field}
              model={model}
              errors={errors}
              label="Contact information"
              handleChange={handleChange}
            />
          ))}
          <Box mt={'30px'}>
            {protectionInputs.map((field, i) => (
              <RenderInputs
                key={`${field.key}-${i}`}
                ind={i}
                field={field}
                model={model}
                errors={errors}
                label="Account Protection"
                handleChange={handleChange}
                action={handlePassword}
              />
            ))}
            <Box mt={1} mb={2}>
              <Typography className={classes.passwordInfo}>
                * Please leave the password fields empty in order to keep the current password without changing it
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container className={classes.buttonContainer}>
        <Grid item sm={5} className={classes.buttonWrapper}>
          <Button variant="text" onClick={() => history.goBack()} className={classes.button}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onSave} className={classes.button}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

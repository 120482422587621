import React from 'react';
import PageLayout from '@components/layout/page-layout';

import MyTeam from './my-team';
import ViewTeamMember from '../../admin/user-management/view-user/view-team-member';

import { Route, Switch, Redirect } from 'react-router';

export default function Team(props) {

  return (
    <PageLayout>
      <Switch>
        <Route exact path="/team">
          <MyTeam />
        </Route>
        <Route exact path="/team/team-member-view">
          <ViewTeamMember />
        </Route>

        <Redirect to={'/team'} />
      </Switch>
    </PageLayout>
  )
}
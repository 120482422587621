const config = {
  // MuiButton: {
  //     root: {
  //         borderRadius: '12px',
  // '&.minLabel': {
  //     '& .MuiButton-label': {
  //         fontWeight: 'bold',
  //         fontSize: '12px',
  //         fontFamily: 'Roboto',
  //     }
  // },
  //     },
  //     iconSizeMedium: {
  //         height: 24,
  //         width: 24,
  //         '&> *:first-child': {
  //             fontSize: 24
  //         }
  //     },
  //     label: {
  //         fontFamily: 'Quicksand',
  //         fontStyle: 'normal',
  //         fontWeight: 'bold',
  //         fontSize: '16px',
  //         lineHeight: '28px',
  //         letterSpacing: '0.75px',
  //     },
  //     containedPrimary: {
  //         backgroundColor: '#7A5B44',
  //         background: 'linear-gradient(270deg, #9A7C6A 0%, #7A5B44 100%)',
  //         color: '#FCFCFC',
  //         '&:hover': {
  //             background: '#7A5B44',
  //             backgroundColor: '#7A5B44',
  //         },
  //         '&.Mui-disabled': {
  //             background: '#C6C6C6',
  //             backgroundColor: '#C6C6C6'
  //         }
  //     },
  //     containedSecondary: {
  //         border: '2px solid #7A5B44',
  //         background: 'none',
  //         color: '#7A5B44',
  //         '&:hover': {
  //             background: 'none',
  //         },
  //     },
  //     sizeLarge: {
  //         padding: '10px 60px'
  //     },
  //     startIcon: {
  //         display: 'flex',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         marginRight: 0,
  //     },
  //     endIcon: {
  //         display: 'flex',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         marginLeft: 0,
  //     }
  // },
  // MuiIconButton: {
  //     label: {
  //         color: '#7A5B44'
  //     }
  // },
  MuiIconButton: {
    root: {
      '&.active': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
      }
    }
  },
  MuiButton: {
    root: {
      borderRadius: '12px',
      boxSizing: 'border-box',
      fontFamily: 'Quicksand',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.75px',
      textTransform: 'uppercase',
      '&.minLabel': {
        '& .MuiButton-label': {    
          textTransform: 'none',
        }
      },
    },
    // Contained and outlined
    contained: {
      background: 'linear-gradient(270deg, #9A7C6A 0%, #7A5B44 100%)',
      color: '#FFFFFF',
      padding: '10px 30px',
      transition: 'none',
      // Pseudo
      '&:hover': {
        backgroundColor: '#7A5B44',
        background: 'transparent'
      },
      '&.Mui-disabled': {
        background: '#C6C6C6',
        color: '#FFFFFF',
      },
      // Contained sizes
      '&.MuiButton-sizeLarge': {
        padding: '10px 82px',
      }
    },
    outlined: {
      border: '2px solid #7A5B44',
      color: '#7A5B44',
      padding: '8px 44px',
      // Pseudo
      '&:hover': {
        background: '#F8F8F8'
      },
      '&.Mui-disabled': {
        border: '2px solid #C6C6C6',
        color: '#C6C6C6'
      },
      // Outlined sizes
      '&.MuiButton-sizeLarge': {
        padding: '10px 80px',
      },
      '&.MuiButton-sizeSmall': {
        padding: '2px 46px',
        lineHeight: '16px',
      }
    },
    text: {
      color: '#7A5B44',
      // Pseudo
      '&:hover': {
        background: '#F8F8F8'
      },
      // Outlined sizes
      '&.MuiButton-sizeLarge': {
        padding: '10px 80px',
      },
      '&.MuiButton-sizeSmall': {
        padding: '2px 46px',
        lineHeight: '16px',
      }
    },
  }
};

export default config
import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

import BaseTableCell from './base-table-cell';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: '2px 24px !important',
    whiteSpace: 'nowrap',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)'
    }
  },
}));

export default function ButtonTableCell(props) {
  const classes = useStyles();
  const { col, item, handleAction, handlers, preventClick } = props;

  col.colProps = {
    ...col.colProps,
    align: col.colProps?.align || 'right',
    width: col.colProps?.width || 160,
  }

  return (
    <BaseTableCell col={col} item={item} onClick={preventClick}>
      <Button
        variant="outlined"
        size="small"
        onClick={handleAction(item.id, handlers[col.field])}
        className={classes.button}
        disabled={item.isDisabled}
      >
        {col.label}
      </Button>
    </BaseTableCell>
  )
}
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, MenuItem } from "@material-ui/core"; // eslint-disable-line
import subscriptionTheme from '@components/complex/subscription/theme';
import UsageItems from '@components/complex/subscription/items/usage-items';
import MeasuredItem from '@components/complex/subscription/items/measured-item'; // eslint-disable-line
import IconDropdownWithContext from '@components/dropdowns/icon-dropdown-with-context'; // eslint-disable-line
import { CalendarIcon } from '@app/icons'; // eslint-disable-line
import { clients } from '@app/api/admin';

import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { formatThousands } from '@utils/numberUtil';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflowY: 'auto'
  },
  itemWrapper: {
    width: 325,
  }
}))

const dateOptions = [ // eslint-disable-line
  { label: 'Past day', value: 'pastDay' },
  { label: 'Past 7 days', value: 'past7Days' },
  { label: 'Past 30 days', value: 'past30Days' },
  { label: 'Last Quarter', value: 'lastQuater' },
  { label: 'Last Year', value: 'lastYear' },
  { label: 'Custom', value: 'custom' },
]

export default function Searches() {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = useState('past30Days'); // eslint-disable-line

  const [data, setData] = useState({});
  const [isLoad, setIsLoad] = useState(false);
  
  const getData = useCallback(async () => {
    const [
      searches,
      monitoryClients,
      monitorySearches,
      notAcknowledgedSearches
    ] = await Promise.all([
      clients.getPerformedTotalSearches(),
      clients.getTotalClientsOnMonitory(true),
      clients.getTotalSearchesOnMonitory(true),
      clients.getTotalNotAcknowledgedSearches()
    ])

    setData({
      ...searches.data,
      monitoryClients: monitoryClients.data,
      monitorySearches: monitorySearches.data,
      ...notAcknowledgedSearches.data
    });
    setIsLoad(true);
  }, [])

  useEffect(() => {
    getData();
  }, [getData])

  return (
    <ThemeProvider theme={subscriptionTheme}>
      <Box my={5} display="flex" justifyContent="space-between">
        <Typography variant="h5">SEARCHES OVERVIEW</Typography>
        {/* <IconDropdownWithContext icon={<CalendarIcon />} label={dateOptions.find(e => e.value === selectedDate).label}>
          {dateOptions.map(option => (
            <MenuItem selected={option.value === selectedDate} key={option.value} onClick={() => setSelectedDate(option.value)}>{option.label}</MenuItem>
          ))}
        </IconDropdownWithContext> */}
      </Box>
      {isLoad && (
        <Box className={classes.root}>
          <Box display="flex" justifyContent="space-between">
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Number of search names using regular credit (Aleph)"
                value={formatThousands(data.performedRegularSearches)}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Number of deleted search names using regular credit (Aleph)"
                value={formatThousands(data.deletedRegularSearches)}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Number of searches on monitoring using regular credit (Aleph)"
                value={formatThousands(data.monitorySearches?.countWithRegularSearches)}
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" my={7}>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Number of search names using premium credit (Comply advantage API)"
                value={formatThousands(data.performedPremiumSearches)}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Number of deleted search names using premium credit (Comply Advantage API)"
                value={formatThousands(data.deletedPremiumSearches)}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Number of searches on monitoring using premium credit (Comply Advantage API)"
                value={formatThousands(data.monitorySearches?.countWithPremiumSearches)}
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" my={7}>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Number of ongoing monitoring names (Aleph)"
                value={formatThousands(data.monitoryClients?.countWithRegularSearches)}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Number of ongoing monitoring names (Comply Advantage API)"
                value={formatThousands(data.monitoryClients?.countWithPremiumSearches)}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Detected changes"
                value={formatThousands(data.unacknowledgedSearches)}
              />
            </Box>
          </Box>
          {/* <Box display="flex" justifyContent="space-between">
            <Box className={classes.itemWrapper}>
              <MeasuredItem title="REGULAR CREDITS" value={36} minValue={0} maxValue={400} isAdmin />
            </Box>
            <Box className={classes.itemWrapper}>
              <MeasuredItem title="PREMIUM CREDITS" value={36} minValue={0} maxValue={400} isAdmin />
            </Box>
            <Box className={classes.itemWrapper}>
              
            </Box>
          </Box> */}
        </Box>
      )}
    </ThemeProvider>
  )
}
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import UserInfoCard from './components/user-note-list-components/user-info-card';
import { Modal } from '@components/modals';
import history from '@app/history';
import NewInnerHeader from '@components/layout/new-inner-header';

import CustomTooltip from '@components/controls/tooltip';
import CustomButton from '@views/common/button';

import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { backgroundCheck, loader } from '@store/actions';
import { clientApi } from '@app/api';

import { prepareProfilesFetchData } from '@utils/prepare-data/prepareProfilesData';
import { infoCardPrepareFields } from '@utils/prepare-data/prepareInfoCardData';
import { sourcePrepareData, sourcePrepareDataAdvantage } from '@utils/prepare-data/prepareSourcesData';
import prepareTimestamp from '@utils/date';
import { joinStringArray } from '@/utils/joinStringArray';
import { exportBackgroundCheckPDF } from '@/utils/exportPDF';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';

import StepperBlock from './components/user-note-list-components/stepper-block/index';
import InnerHeaderBlock from './components/user-note-list-components/inner-header-block';
import CardListBlock from './components/user-note-list-components/card-list-block';
import NoHitsBlock from './components/user-note-list-components/no-hits-block';
import VersionTextBlock from './components/user-note-list-components/version-text-block';
import ModalContent from './components/modal-content';
import { pluralFormatter } from '@utils/textUtil';
import ExportReportButton from './components/user-note-list-components/export-report';
import UserNoteInstructions from './components/user-note-list-components/user-note-instructions';

const ADVANTAGE_PROVIDER = 'COMPLY_ADVANTAGE';

const tabs = [
  { label: 'All', key: 'all', isActive: true, tagValue: '', tagColor: '#EDEDED' },
  { label: 'Sanctions', key: 's', isActive: false, tagValue: '', tagColor: '#EDEDED' },
  { label: 'PEP', key: 'pep', isActive: false, tagValue: '', tagColor: '#EDEDED' },
  { label: 'Adverse Media', key: 'am', isActive: false, tagValue: '', tagColor: '#EDEDED' },
  { label: 'Adverse Media Links', key: 'aml', isActive: false, tagValue: '', tagColor: '#EDEDED' },
  { label: 'Fitness-Probity', key: 'fp', isActive: false, tagValue: '', tagColor: '#EDEDED' },
  { label: 'Warnings', key: 'w', isActive: false, tagValue: '', tagColor: '#EDEDED' },
  { label: 'Assets', key: 'a', isActive: false, tagValue: '', tagColor: '#EDEDED' },
];
// eslint-disable-next-line
const mainText = "Are you sure you want to suppress the selected listing(s)? The application will exclude it/them from future background checks. You can initiate it/them anytime from the bottom section of the screen.";

export default function UserNoteList(props) {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { baseRoute, linkClient, isSaveToPortfolio, steps } = props;
  const search = new URLSearchParams(location.search);
  const searchId = search.get('searchId');
  const profileId = search.get('profileId');
  const profileClientId = search.get('profileClientId');

  const { currentSearch, sources, backupSources, profile, selectedProfile } = useSelector(store => store.backgroundCheck);
  const { profilesVersionId } = useSelector(store => store.searchHistory);
  const loading = useSelector(store => store.loader.loader);
  const dispatch = useDispatch();

  const [tabActions, setTabActions] = useState([]);
  const [currentTab, setCurrentTab] = useState(null);
  const [isStepper, setIsStepper] = useState(null);
  const [tabIndex, setTabIndex] = useState(null);
  const [deleteComment, setDeleteComment] = useState(null);
  const [showSuppressModal, setShowSuppressModal] = useState(false);
  const [suppressData, setSuppressData] = useState(null);
  const [suppress, setSuppress] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    getCurrentSearch();

    return () => {
      dispatch(backgroundCheck.setSelectedProfile({}));
      dispatch(backgroundCheck.setProfile(null));
      dispatch(backgroundCheck.setSources([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeRisk = async (value) => {
    if (!value || !selectedProfile?.meta?.length) return;

    const data = {
      profileId,
      payload: {
        bgCheckRiskRating: value === 'Not Set' ? null : value,
        whitelisted: selectedProfile.isWhitelisted,
      },
    }
    const res = await clientApi.profile.updateProfileReview(data);

    if (res.status === 200) {
      dispatch(backgroundCheck.setSelectedProfile({
        ...selectedProfile,
        bgCheckRiskRating: res.data.bgCheckRiskRating,
      }));
    }
  }

  const calcRate = (sourcesData) => {
    const data = sourcesData || backupSources;
    let rateCount = 0;

    data.forEach(el => {
      if (el.bgCheckRiskRating === 'Low') {
        rateCount = rateCount + 1;
      } else if (el.bgCheckRiskRating === 'Medium') {
        rateCount = rateCount + 2;
      } else if (el.bgCheckRiskRating === 'High') {
        rateCount = rateCount + 3;
      }
    })

    const result = rateCount / data.filter(el => el.bgCheckRiskRating && el.bgCheckRiskRating !== 'Not Set').length;

    if (result === 0 || !result) {
      return;
    } else if (result > 0 && result < 1.5) {
      handleChangeRisk('Low')
    } else if (result >= 1.5 && result < 2.5) {
      handleChangeRisk('Medium')
    } else if (result >= 2.5) {
      handleChangeRisk('High')
    }
  }

  const getClient = async () => {
    const clientId = profile.clientId || profileClientId;

    let data = {};
    if (clientId) {
      const res = await clientApi.search.getClients(clientId);

      if (res.status === 200) {
        data = infoCardPrepareFields({
          ...res.data,
          name: currentSearch.type === 'PERSON' ? `${res.data.firstName} ${res.data.lastName}`: res.data.name,
          tags: [currentSearch.type],
          entityType: res.data?.type || null,
          bgCheckRiskRating: profile.bgCheckRiskRating,
          isWhitelisted: profile.isWhitelisted,
          clientId: clientId || profile.clientId,
        });
      }
    } else {
      data = infoCardPrepareFields({
        ...currentSearch,
        name: profile.name,
        tags: [currentSearch.type],
        bgCheckRiskRating: profile.bgCheckRiskRating,
        isWhitelisted: profile.isWhitelisted,
        clientId: clientId || profile.clientId,
      });
    }
    dispatch(backgroundCheck.setSelectedProfile({
      ...data,
      profileId: profile.id,
      notes: profile.notes,
      acknowledged: profile.acknowledged,
      versionState: profile.versionState,
      whitelistedReason: profile.whitelistedReason,
      whitelistedAt: profile.whitelistedAt,
    }));
  }

  const getSources = async () => {
    const res = profilesVersionId
      ? (await clientApi.searchVersion.getSourcesByVersionIdAndProfileId(profilesVersionId, profileId))
      : (await clientApi.profile.getSources(profileId));

    if (res.status === 200 && !!res.data.length) {
      const preparedData = currentSearch.searchProvider === ADVANTAGE_PROVIDER
        ? sourcePrepareDataAdvantage(res.data, profile, profilesVersionId)
        : sourcePrepareData(res.data, profile.acknowledged, profilesVersionId);
      const data = !!preparedData.length ? preparedData : [];

      handleLengthListType(data);
      await dispatch(backgroundCheck.setSources(data));
      if (!isEmpty(currentTab)) {
        await handleActiveTab(currentTab?.tabLabel, currentTab?.tabIndex, data);
      }
    } else {
      dispatch(backgroundCheck.setSources([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  useEffect(() => {
    if (!isEmpty(currentSearch) && !isEmpty(profile)) {
      getClient();
      getSources();
      (!profile.bgCheckRiskRating && !profilesVersionId && !!sources?.length) && calcRate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, currentSearch])

  const getCurrentSearch = async () => {
    const res = profilesVersionId
      ? (await clientApi.searchVersion.getSearchByVersionId(profilesVersionId))
      : (await clientApi.search.getSearch(searchId));

    if (res.status === 200) {
      let data = {};
      for (const key in res.data) {
        if (!!res.data[key]) {
          data = { ...data, [key]: res.data[key] }
        }
      }

      dispatch(backgroundCheck.updateCurrentSearch(data));
    }
  }

  const getCurrentProfile = async () => {
    let res;
    if (!!profilesVersionId) {
      res = await clientApi.searchVersion.getProfileByVersionId(profilesVersionId, profileId);

      if (res.status === 200 && !res.data) {
        res = await clientApi.profile.show(profileId);
      }
    } else {
      res = await clientApi.profile.show(profileId);
    }

    if (res.status === 200 && !!res.data) {
      const data = prepareProfilesFetchData([res.data], profilesVersionId);
      dispatch(backgroundCheck.setProfile(data[0]));
    } else {
      dispatch(backgroundCheck.setSources([]));
    }
  }

  const handleExport = async () => {
    dispatch(loader.setLoader(true));

    if (profilesVersionId) {
      const subLink = 'search-version/';
      const data = !!sources.length
        ? { searchId: profilesVersionId, profileId, subLink }
        : { searchId: profilesVersionId, subLink };
      await exportBackgroundCheckPDF(data);
    } else {
      await exportBackgroundCheckPDF({ searchId, profileId });
    }
    enqueueSnackbar('The report was successfully exported!', { variant: 'success' });
  }
  
  useEffect(() => {
    getCurrentProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilesVersionId])

  const handleLengthListType = data => {
    const prepareTabs = tabs
      .map(item => ({
        ...item,
        tagValue: currentSearch.searchProvider === ADVANTAGE_PROVIDER
          ? data.filter(el => el.listType.toLowerCase() === item.key).length || ''
          : data.filter(el => el.listType.toLowerCase() === item.label.toLowerCase()).length || '',
      }))
      .filter(item => item.key === 'all' || !!item.tagValue);

    const quantityTabs = prepareTabs.length === 2
      ? prepareTabs.filter(item => item.key !== 'all')
      : prepareTabs;

    setTabActions(quantityTabs);
    setTabIndex(0);
  }

  const handleCustomStepper = useCallback((label, tabIndex) => {
    if (!tabActions.length) return;

    const tabLength = tabActions.length;
    if (tabLength === 1) {
      setIsStepper(null);
      setTabIndex(null);
      return;
    }
    if (tabIndex === 0 || !tabIndex) {
      setIsStepper(null);
      setTabIndex(null);
    } else if (tabIndex === (tabLength - 1)) {
      setIsStepper(null);
      setTabIndex(null);
    } else {
      setIsStepper(tabIndex + 1);
    }
  }, [tabActions])

  const handleActiveTab = async (tabLabel, tabIndex, data) => {
    const newData = data || backupSources;
    if (tabLabel !== 'All') {
      const filteredData = newData.filter(el => {
        const type = el.listType.toLowerCase();
        const currTab = tabActions.find((el, i) => i === tabIndex);
        return type === (currentSearch.searchProvider === ADVANTAGE_PROVIDER ? currTab?.key : currTab.label.toLowerCase());
      });

      if (!filteredData.length) return;
 
      dispatch(backgroundCheck.setChangedSources(filteredData));
    } else {
      dispatch(backgroundCheck.setSources(newData));
    }
    setCurrentTab({ tabLabel, tabIndex });
    handleCustomStepper(tabLabel, tabIndex);
  }

  const handleSupress = async (supressId) => {
    const id = supressId || suppress?.id;
    const checkedItems = id
      ? [sources.find(el => el.id === id)]
      : sources.filter(el => el.isChecked);
    const relevant = suppress ? false : !!id;

    const buffer = [];
    let changedSources = sources;
    checkedItems.forEach(el => {
      buffer.push(
        new Promise(async resolve => {
          const sourceId = el.id;
          const data = {
            sourceId,
            payload: {
              relevant,
              markedAsIrrelevantReason: suppressData?.label || null,
            },
          }
          const res = await clientApi.source.updateSourceReview(data);

          if (res.status === 200) {
            changedSources = changedSources.map(item => item.id === sourceId
              ? ({
                ...item,
                relevant,
                bgCheckRiskRating: null,
                isChecked: false,
                markedIsIrrelevantOn: prepareTimestamp(res.data.markedIsIrrelevantOn, 'MM/DD/YYYY hh:mm A', false),
                markedAsIsIrrelevantReason: res.data.markedAsIsIrrelevantReason,
              })
              : item
            );
          }

          if (suppressData?.note?.note && suppressData?.note?.note.trim()) {
            let response;
            if (!!suppressData?.note?.id) {
              const noteId = suppressData?.note?.id;
              response = await clientApi.source.updateNote(
                sourceId,
                noteId,
                { note: suppressData.note.note, noteType: 'sourceRelevant' }
              );
            } else {
              response = await clientApi.source.addNote(
                sourceId,
                { note: suppressData.note.note, noteType: 'sourceRelevant' }
              );
            }

            if (response.status === 200) {
              changedSources = changedSources.map(item => item.id === sourceId ? ({ ...item, notes: response.data.notes }) : item);
            }
          }

          resolve();
        })
      )
    })
    await Promise.all(buffer);
    dispatch(backgroundCheck.setChangedSources(changedSources));
    const calcData = backupSources.map(el => {
      const newElement = changedSources.find(item => item.id === el.id);
      return newElement || el;
    });
    calcRate(calcData);
    const titles = checkedItems.map(item => item.title.slice(4).replace(/_/gi, ' ').replace(/dataset/gi, '').trim());
    
    !id && dispatch(backgroundCheck.setSelectAllSources(false));
    enqueueSnackbar(`${titles.length > 1 ? 'Sources' : 'Source'} ${joinStringArray(titles.map(e => `"${e}"`))} ${titles.length > 1 ? 'were' : 'was'} successfully ${!!id ? 'initiated' : 'suppressed'}!`, { variant: 'success' });
    setSuppressData(null);
    setSuppress(null);
    setShowSuppressModal(false);
  }

  const handleDeleteComment = async () => {
    const { cardId, noteId } = deleteComment;
    if (cardId) {
      const res = await clientApi.source.deleteNote(cardId, noteId);

      if (res.status === 200) {
        const changedSources = sources.map(item => item.id === cardId ? ({ ...item, notes: res.data.notes }) : item);
        dispatch(backgroundCheck.setChangedSources(changedSources));
      }
    } else {
      const res = await clientApi.profile.deleteNote(profileId, noteId);
  
      if (res.status === 200) {
        dispatch(backgroundCheck.setSelectedProfile({
          ...selectedProfile,
          notes: res.data.notes,
        }));
      }
    }
    setDeleteComment(null);
  }

  const prepareEditComment = async (cardId, noteId) => {
    let note = '';
    if (!!cardId || cardId === 0) {
      const card = sources.find(el => el.id === cardId);

      if (!card) return;
  
      note = card.notes.find((el) => el.id === noteId);
  
      const changedSources = sources.map(item => item.id === card.id ? ({ ...item, notes: item.notes.filter((el) => el.id !== noteId) }) : item);
      dispatch(backgroundCheck.setChangedSources(changedSources));
    } else {
      if (!selectedProfile.notes) return;
      note = selectedProfile.notes.find((el) => el.id === noteId);
      dispatch(backgroundCheck.setSelectedProfile({
        ...selectedProfile,
        notes: selectedProfile.notes.filter((el) => el.id !== noteId),
      }));
    }
    return note;
  }

  const updateSourceNote = async (cardId, noteId, note) => {
    const res = await clientApi.source.updateNote(cardId, noteId, { note });

    if (res.status === 200) {
      const changedSources = sources.map(item => item.id === cardId ? ({ ...item, notes: res.data.notes }) : item);
      dispatch(backgroundCheck.setChangedSources(changedSources));
    }
  }
  
  const updateProfileNote = async (noteId, note) => {
    const res = await clientApi.profile.updateNote(profileId, noteId, { note });

    if (res.status === 200) {
      dispatch(backgroundCheck.setSelectedProfile({
        ...selectedProfile,
        notes: res.data.notes,
      }));
    }
  }


  const handleEditComment = async (noteId, cardId, note, noChange) => {
    if (!!cardId || cardId === 0) {
      if (!noChange) {
        await updateSourceNote(cardId, noteId, note);
      } else {
        const changedSources = sources.map(item => item.id === cardId ? ({ ...item, notes: [ ...item.notes, note ] }) : item);
        dispatch(backgroundCheck.setChangedSources(changedSources));
      }
    } else {
      if (note) {
        await updateProfileNote(noteId, note);
      } else {
        dispatch(backgroundCheck.setSelectedProfile({
          ...selectedProfile,
          notes: selectedProfile.notes,
        }));
      }
    }
  }

  const onSupress = (id) => {
    const item = sources.find(el => el.id === id);
    const sourceRelevantNotes = item.notes.filter(el => el.noteType === 'sourceRelevant');

    setSuppress({
      id: item.id,
      reason: item.markedAsIsIrrelevantReason,
      note: sourceRelevantNotes[sourceRelevantNotes.length - 1] || ''
    });
    setShowSuppressModal(true);
  }

  const updateExisting = () => {
    let type = 'person';
    if (selectedProfile && selectedProfile.tags && selectedProfile.tags.includes('COMPANY')) {
      type = 'company';
    }
    history.push(`/${baseRoute}/update-existing/` + type + `/${searchId}/${profileId}`)
  }

  const handleCancel = () => {
    setShowSuppressModal(false);
    setDeleteComment(null);
    setSuppressData(null);
    setSuppress(null);
  }

  const handleAcknowledge = () => {
    clientApi.profile.acknowledgeProfile(selectedProfile.profileId).then((res) => {
      const data = prepareProfilesFetchData([res.data], profilesVersionId);
      setCurrentTab({ tabLabel: 'All', tabIndex: 0 });
      dispatch(backgroundCheck.setProfile(data[0]));
      enqueueSnackbar('The changes were successfully acknowledged!', { variant: 'success' });
    });
  }

  const isViewAsknowledgeButton = !selectedProfile.acknowledged && !profilesVersionId && !location.pathname.includes('/background-check');
  const showVersionText = !!sources.length && selectedProfile.profileId && isViewAsknowledgeButton;

  const minusHeight = useMemo(() => showVersionText ? 150 : 62, [showVersionText])

  const renderFooterBlock = () => (
    (!isStepper && !!sources.length && selectedProfile.profileId && !!tabActions.length) ? (
      <StepperBlock
        handleExport={handleExport}
        updateExisting={updateExisting}
        handleChangeRisk={value => handleChangeRisk(value)}
      />
    ) : (!!sources.length && !!tabActions[isStepper]) && (
      <Box display="flex" justifyContent="center">
        <Button
          classes={{ root: classes.buttonNotStep }}
          onClick={() => setTabIndex(isStepper)}
        >
          GO TO {tabActions[isStepper]?.label.toUpperCase()}
        </Button>
      </Box>
    )
  )

  return (
    <React.Fragment>
      <Modal
        open={!!deleteComment}
        onClose={() => setDeleteComment(null)}
        title="Delete note"
        mainText="Are you sure you want to delete this note?"
        actions={
          [
            {
              type: 'main',
              label: 'No',
              action: handleCancel,
            },
            {
              type: 'secondary',
              label: 'Yes',
              action: handleDeleteComment,
              disabled: !!profilesVersionId
            },
          ]
        }
        propsClassses={classes.customButtonModalWidth}
        actionsDirection="row"
      />
      <Modal
        open={showSuppressModal}
        onClose={() => setShowSuppressModal(false)}
        title="Source Suppression"
        actions={
          [
            {
              type: 'main',
              label: 'Cancel',
              action: handleCancel,
            },
            {
              type: 'secondary',
              label: 'Suppress',
              action: () => handleSupress(),
              disabled: !!profilesVersionId || !suppressData
            },
          ]
        }
        propsClassses={classes.customButtonModalWidth}
        actionsDirection="row"
        content={<ModalContent setData={setSuppressData} type="source" data={suppress} disabled={!!profilesVersionId} />}
      />
      <Box display="flex" width="100%" height="100%">
        <UserInfoCard
          handleChangeRisk={(value, k) => handleChangeRisk(value, k)}
          updateExisting={() => updateExisting()}
          linkClient={linkClient}
          setCurrentTab={setCurrentTab}
          isSaveToPortfolio={isSaveToPortfolio}
          getCurrentProfile={getCurrentProfile}
        />

        <Box px={5} pt={steps ? 0 : 5} width="calc(100% - 310px - 2*40px)" display="flex" flexDirection="column" height={steps ? '100%' : 'calc(100% - 40px)'}>
          {(selectedProfile.profileId && steps) &&
            <Box mb={2} mt="-4px">
              <NewInnerHeader
                title={steps?.title}
                leftAligned
                stepper={{
                  currentStep: steps?.currentStep,
                  stepsCount: steps?.stepsCount
                }}
              />
            </Box>
          }
          {selectedProfile.profileId && 
            <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
              <Box display="flex" alignItems="center">
                <Typography variant="h3">{sources.length || (loading ? '  ' : sources.length)} {pluralFormatter(sources.length, 'hit', 'hits')} found</Typography>
                <Box ml={1}>
                  <UserNoteInstructions count={sources.length} />
                </Box>
              </Box>
              <Box display="flex" alignItems="center" height={40}>
                <Box>
                  <ExportReportButton handleExport={handleExport} />
                </Box>
                {isViewAsknowledgeButton &&
                  <Box ml={2}>
                    <CustomTooltip title="Confirm that you’ve reviewed the changes" placement="top">
                      <span>
                        <CustomButton
                          action={handleAcknowledge}
                          label={'ACKNOWLEDGE'}
                          customClasses="customButtonBordered"
                          propsClassses={classes.customButtonSize}
                        />
                      </span>
                    </CustomTooltip>
                  </Box>
                }
              </Box>
            </Box>
          }

          {(!sources.length && selectedProfile.profileId && !loading) ?
            <Box mt={2} pt={3} style={{ borderTop: '2px solid #EFEFEF' }}>
              <NoHitsBlock name={selectedProfile.name} />
            </Box>
            :
            <Box display="flex" flexDirection="column" flexGrow={1} height={steps ? 'calc(100% - 40px - 98px - 16px)' : 'calc(100% - 40px)'}>
              {(!!sources.length && selectedProfile.profileId && !!tabActions.length) && (
                <InnerHeaderBlock
                  tabActions={tabActions}
                  handleActiveTab={handleActiveTab}
                  tabIndex={tabIndex}
                  setShowSuppressModal={setShowSuppressModal}
                />
              )}
              {showVersionText && (
                <VersionTextBlock versionState={selectedProfile.versionState} />
              )}
              {(!!sources.length && selectedProfile.profileId && !!tabActions.length) && (
                <CardListBlock
                  tabs={tabs}
                  calcRate={calcRate}
                  handleChangeRisk={value => handleChangeRisk(value)}
                  handleSupress={handleSupress}
                  handleDeleteComment={(id, noteId) => {
                    setDeleteComment({ cardId: id, noteId });
                  }}
                  prepareEditComment={prepareEditComment}
                  handleEditComment={handleEditComment}
                  onSupress={onSupress}
                  renderFooterBlock={renderFooterBlock}
                  tabIndex={tabIndex}
                  minusHeight={minusHeight}
                />
              )}
            </Box>
          }
        </Box>
      </Box>
    </React.Fragment>
  )
}

import React, { useCallback, useMemo, useEffect } from  'react';
import { Box, Switch, FormControlLabel } from '@material-ui/core';
import CustomTooltip from '@components/controls/tooltip';
import CustomChip from '@components/controls/custom-chip';

import { useSelector, useDispatch } from 'react-redux';
import { backgroundSearch } from '@app/store/actions';

import { PREMIUM, SWITCH, SANCTIONS, PEP } from '../../constants';
import { useStyles } from '../styles';

export default function SwitchLabels({ sanctions }) {
  const classes = useStyles()

  const { searchModel } = useSelector(state => state.backgroundSearch);
  const dispatch = useDispatch();

  const setTypes = useCallback(() => {
    dispatch(backgroundSearch.setSearchModel({ listTypes: ['SANCTIONS'] }));
  }, [dispatch])

  useEffect(() => {
    if (sanctions) {
      setTypes();
    }
  }, []) // eslint-disable-line

  const switchLabels = useMemo(() => {
    return SWITCH[searchModel.searchProvider];
  }, [searchModel]);

  const checkedSwitch = useCallback((item) => {
    return sanctions
      ? !!searchModel.listTypes.filter(el => el === SANCTIONS).find(el => el === item.value)
      : !!searchModel.listTypes.find(el => el === item.value);
  }, [sanctions, searchModel]);

  const changeSwitch = useCallback((changed, type) => {
    dispatch(backgroundSearch.setSearchModel({
      listTypes: changed ? ([ ...searchModel.listTypes, type ]) : searchModel.listTypes.filter(el => el !== type)
    }))
  }, [searchModel, dispatch]);

  const typeInfo = {
    'SANCTIONS': <React.Fragment>
      <p style={{ textAlign: 'start' }}>
        <b style={{ fontWeight: 700 }}>Searches through</b><br/>
        <span style={{ fontWeight: 400, opacity: 0.9 }}>Sanctions <i>e.g. OFAC SDN List, HM Treasure Sanctions List</i></span><br/>
        <span style={{ fontWeight: 400, opacity: 0.9 }}>Warnings <i>e.g. US Immigration and Customs Wanted</i></span><br/>
        <span style={{ fontWeight: 400, opacity: 0.9 }}>Fitness {'&'} Probity <i>e.g. US System for Award Management Exclusions</i></span><br/>
      </p>
    </React.Fragment>,
    'PEP': <React.Fragment>
      <p style={{ textAlign: 'start' }}>
        <b style={{ fontWeight: 700 }}>Searches through</b><br/>
        <span style={{ fontWeight: 400, opacity: 0.9 }}>Pep Class 1 <i>Heads of State, National Parliaments, National Governments</i></span><br/>
        <span style={{ fontWeight: 400, opacity: 0.9 }}>Pep Class 2 <i>Regional Governments, Regional Parliaments</i></span><br/>
        <span style={{ fontWeight: 400, opacity: 0.9 }}>Pep Class 3 <i>Senior Management {'&'} Boards of SOEs</i></span><br/>
        <span style={{ fontWeight: 400, opacity: 0.9 }}>Pep Class 4 <i>Mayors and Local City Councils</i></span><br/>
      </p>
    </React.Fragment>,
    'ADVERSE_MEDIA': <React.Fragment>
      <p style={{ textAlign: 'start' }}>
        <b style={{ fontWeight: 700 }}>Searches through</b><br/>
        <span style={{ fontWeight: 400, opacity: 0.9 }}>Financial crime, violent crime, sexual crime, terrorism, fraud, narcotics, other crime</span>
      </p>
    </React.Fragment>,
  }

  return (
    switchLabels.map((item, i) => (
      <Box display="flex" alignItems="center" key={`${item.value} - ${i}`}>
        <CustomTooltip
          title="Some features are restricted in this view. For an extensive searching experience please Login or Register."
          placement="top"
          disableHoverListener={sanctions ? item.value !== PEP : true}
        >
          <FormControlLabel
            checked={checkedSwitch(item)}
            classes={{ root: classes.rootControlLabel }}
            key={`switch-tag-${item.label}`}
            control={<Switch />}
            label={
              <CustomTooltip
                title={typeInfo[item.value]}
                placement="top"
                disableHoverListener={searchModel.searchProvider !== PREMIUM || sanctions}
                width={item.value === 'ADVERSE_MEDIA' ? 160 : 325}
              >
                <span>
                  <CustomChip label={item.label} disabled={sanctions ? item.value !== SANCTIONS : false} />
                </span>
              </CustomTooltip>
            }
            onChange={e => changeSwitch(e.target.checked, item.value)}
            disabled={sanctions || false}
          />
        </CustomTooltip>
      </Box>
    ))
  )
}

const setJobs = (jobs) => ({
  type: 'SET_JOBS',
  jobs
});

const jobsManagement = {
  setJobs,
}

export default jobsManagement;

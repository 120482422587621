import React, { useMemo } from 'react';
import _ from 'lodash';
import { Box, Button, Dialog, Grid, LinearProgress, makeStyles, Typography, withStyles } from '@material-ui/core';
import LinkButton from '@components/buttons/link-button';
import ImportCsvPreparingIcon from '@assets/icons/ImportCsv/Preparing.png';
import quotes from '@services/quotes';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#EFEFEF',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#7A5B44',
  },
  bar2Buffer: {
    backgroundColor: 'rgba(122, 91, 68, 0.4)',
  },
  dashed: {
    marginTop: 2.5,
  }
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  quotesWrapper: {
    height: 250,
    position: 'relative',
  },
  iconWrapper: {
    position: 'absolute',
    top: -45,
  },
  titleWrapper: {
    position: 'absolute',
    top: 0,
  },
  quoteWrapper: {
    height: 250,
    zIndex: 1,
    position: 'absolute',
    top: 15,
  },
  quote: {
    width: 225,
  }
}));

export default function ProgressDialog({ open, onClose, isLoading, title, progressText, progressProps, text, finishLabel, onCancel, cancelLabel }) {
  const classes = useStyles();

  const quote = useMemo(() => {
    return _.sample(quotes);
    }, [open]) // eslint-disable-line

  return (
    <Dialog open={open}>
      <Box width="400">
        <Box className={classes.quotesWrapper}>
          <Grid container justify="center" className={classes.iconWrapper}>
            <img alt="" src={ImportCsvPreparingIcon}></img>
          </Grid>
          <Grid container justify="center" className={classes.titleWrapper}>
            <Typography align="center" variant="h3">
              {title}
            </Typography>
          </Grid>
          <Grid container justify="center" alignItems="center" className={classes.quoteWrapper}>
            <Box className={classes.quote}>
              <Typography variant="subtitle2" align="center">“{quote.text}”</Typography>
              <Typography variant="subtitle2" align="center">- <b>{quote.author}</b></Typography>
            </Box>
          </Grid>
        </Box>
        {isLoading && <BorderLinearProgress {...progressProps} />}
        {isLoading &&
                    <Box mt={1} mb="14px">
                      <Typography align="center" variant="subtitle2">{progressText}</Typography>
                    </Box>
        }
        {text &&
                    <Box mt={1} mb="14px">
                      <Typography align="center" variant="subtitle2">{text}</Typography>
                    </Box>
        }
        {(isLoading && onCancel) &&
                    <Box mt={1}>
                      <Grid container justify="center">
                        <LinkButton
                          onClick={onCancel}
                        >
                          {cancelLabel}
                        </LinkButton>
                      </Grid>
                    </Box>
        }
        {!isLoading &&
                    <Box mt={1}>
                      <Grid container justify="center">
                        <Button
                          variant="contained"
                          onClick={onClose}
                          size="large"
                        >
                          {finishLabel || 'Next'}
                        </Button>
                      </Grid>
                    </Box>
        }
      </Box>
    </Dialog>
  )
}

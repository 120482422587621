import React, {useEffect, useState} from 'react';

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
  MenuItem,
  FormControlLabel,
  Switch,
  Select
} from '@material-ui/core';
import {ChevronLeftIcon, InfoOtherIcon} from '@app/icons';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import InnerHeader from '@components/layout/inner-header';
import PageLayout from '@components/layout/page-layout';
import history from '@app/history';
import parseYupErrors from '@app/yup';
import * as yup from 'yup';
import {clientApi} from '@app/api';
import useErrorState from '@utils/errorState';
import {prepareClient} from '@dto/client';
import CustomTooltip from '@components/controls/tooltip';
import { grey } from '@material-ui/core/colors';

const schema = yup.object().shape({
  period: yup.number().oneOf([1, 7, 30], 'Please select Monitoring Period.')
});

const MODEL_DEFAULTS = {
  period: 1,
  notifications: 'email',
  email: ''
}

export default function UpdateMonitoring(props) {

  const {id, search_id} = props.computedMatch.params

    const [clientData, setClientData] = useState() //eslint-disable-line
    const [monitoringData, setMonitoringData] = useState() //eslint-disable-line

  const [model, setModel] = React.useState({
    ...MODEL_DEFAULTS,
    monitory: false,
  });

  const [errors, setErrors] = useErrorState({});

  useEffect(() => {
    clientApi.client
      .show(id)
      .then(({data}) => {
        setClientData(
          data
        )
      })
    clientApi.search
      .getOngoingMonitoring(search_id)
      .then(({data}) => {
        if(data) {
          setMonitoringData(
            data
          )
          setModel({
            ...model,
            monitory: true,
            period: data.timeSliceDays
          })
        }
      })
    // eslint-disable-next-line
    }, [])

  const formFields = () => [
    {
      key: 'period',
      label: 'Monitoring Period',
      type: 'select',
      options: {
        1: 'Daily',
        7: 'Weekly',
        30: 'Monthly',
      }
    },
    {
      key: 'notifications',
      label: 'Monitoring Period',
      type: 'select',
      options: {
        email: 'Email Only'
      }
    },
    // {
    //     key: 'email',
    //     label: 'Alternative Email',
    //     endAdornment: <React.Fragment>
    //         <CustomTooltip title="if someone from your team needs to receive this information, type their email here" placement="top">
    //             <InfoOtherIcon/>
    //         </CustomTooltip>
    //     </React.Fragment>
    // }
  ]

  // delete this when alternative email is enabled again
  // eslint-disable-next-line
    const disableMonitoringAlternativeEmail = {
    key: 'email',
    label: 'Alternative Email',
    endAdornment: <React.Fragment>
      <CustomTooltip title="if someone from your team needs to receive this information, type their email here" placement="top">
        <InfoOtherIcon/>
      </CustomTooltip>
    </React.Fragment>
  }

  const onSave = () => {
    setErrors({});
    schema
      .validate(model, {abortEarly: false})
      .then(async () => {
        try {
          if(model.monitory) {
            (
              !!monitoringData
                ? clientApi.search.updateSearchMonitoring
                : clientApi.search.addSearchToMonitoring
            ) (search_id, {
              timeSliceInDays: model.period
            }).then(() => {
              history.goBack()
            })
          } else {
            clientApi.search.removeFromMonitoring(search_id).then(() => {
              history.goBack()
            })
          }
        } catch (err) {
          setErrors(err.response.data['errorMessages'] || {}, true)
        }
      })
      .catch( err => setErrors(parseYupErrors(err), true));
  }

  const onBack = () => {
    history.goBack()
  }

  const handleChange = prop => event => {
    setModel(
      { ...model, [prop]: event.target.value }
    )
  }

  const setMonitory = value => {
    setModel(
      { ...value? MODEL_DEFAULTS: model, monitory: value }
    )
  }

  return (
    <React.Fragment>
      <PageLayout>
        <Box px={6}>
          <InnerHeader
            title={'Edit Monitoring Details'}
            buttons={
              <React.Fragment>
                <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={onBack}>
                                    Back
                </ButtonWithIcon>
              </React.Fragment>
            } />
        </Box>

        <Box display={'flex'} mt={3} style={{overflowY: 'scroll'}}>
          <Grid container justify={'center'}>
            <Grid item xs={12}>
              <Box display={'flex'} justifyContent={'center'} mt={3}>
                <Typography variant={'caption'} style={{color: grey[500]}}>Add</Typography>
                                &nbsp;
                <Typography variant={'caption'}>{ clientData && prepareClient(clientData).name }</Typography>
                                &nbsp;
                <Typography variant={'caption'} style={{color: grey[500]}}>to the ongoing monitoring list?</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display={'flex'} justifyContent={'center'} mb={3} mt={1}>
                <FormControlLabel
                  control={<Switch checked={model.monitory} onChange={event => setMonitory(event.target.checked)} />}
                  label={model?.monitory ? 'On': 'Off'}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              { !!model.monitory && formFields().map(field => (
                <FormControl
                  key={field.key}
                  id={field.key}
                  fullWidth
                  variant="outlined"
                  error={!!errors[field.key]}
                >
                  <InputLabel htmlFor="name" shrink={field.type === 'date' || undefined}>{field.label}</InputLabel>
                  { field.type !== 'select' &&
                                        <OutlinedInput
                                          value={model[field.key]}
                                          type={field.type === 'date'? 'date': 'text'}
                                          onChange={handleChange(field.key)}
                                          multiline={field.multiline}
                                          rows={field.multiline ? 3 : undefined}
                                          endAdornment={field.endAdornment}
                                        />
                  }
                  { field.type === 'select' &&
                                        <Select
                                          value={model[field.key]}
                                          onChange={handleChange(field.key)}
                                        >
                                          {Object.keys(field.options)
                                            .map(optionValue => (
                                              <MenuItem
                                                key={optionValue}
                                                value={optionValue}
                                              >
                                                {field.options[optionValue]}
                                              </MenuItem>
                                            ))}
                                        </Select>
                  }

                  { errors[field.key] &&
                                        <FormHelperText>{errors[field.key]}</FormHelperText>
                  }
                </FormControl>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Box display={'flex'} justifyContent={'center'} my={3}>
                <Button style={{width: '160px'}} variant={'contained'} onClick={onSave} type="submit">
                                    SAVE
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </PageLayout>
    </React.Fragment>
  )
}
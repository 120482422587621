import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  searchBlock: { position: 'relative' },
  advancedCard: {
    position: 'absolute',
    width: '100%',
    top: 70,
    [theme.breakpoints.down('sm')]: {
      top: 100,
    }
  },
  wrapperFreeSearches: {
    width: '115px',
    [theme.breakpoints.down('sm')]: {
      width: 'initial'
    }
  },
  remainingFreeSearches: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    color: theme.palette.text.GY5,
    textAlign: 'center',
  },
}))
import React, { useState, useEffect, useCallback } from  'react';
import { Grid } from '@material-ui/core';
import OutlinedSearch from '@components/inputs/outlined-search';
import history from '@app/history';
import SearchHistoryFilter from './components/searchHistoryFilter';

import { useDispatch } from 'react-redux';
import { backgroundCheck, searchHistory } from '@store/actions';
import { clientApi } from '@app/api';

import { useStyles } from './styles';
import SearchHistoryTable from './search-history-table';
import prepareTimestamp from '@utils/date';
import debounce from 'awesome-debounce-promise';
import moment from 'moment';

const debouncedSearchIndex = debounce(clientApi.search.getAllSearchesForUser, 500);

const PAGE_LIMIT = 12;
const format = 'MM/DD/YYYY - hh:mm A'

export default function History(props) {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [allPages, setAllPages] = useState(null);
  const [countSearches, setCountSearches] = useState(null);
  const [allSearches, setAllSearches] = useState([]);
  const [inputData, setInputData] = useState('');
  const [inputDataCompare, setInputDataCompare] = useState('');
  const [sort, setSort] = useState('createdOn');
  const [sortDirection, setSortDirection] = useState('DESC');
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [filters, setFilters] = useState({
    acknowledged: null,
    monitory: null,
    searchProvider: null,
  });

  const classes = useStyles();

  const differenceHeight = '340px';

  useEffect(() => {
    dispatch(backgroundCheck.setChangedProfiles({}));
    dispatch(searchHistory.setDefault());
  }, []) // eslint-disable-line

  useEffect(() => {
    getAllSearches().then();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, inputData, sortDirection, page, filters])

  useEffect(() => {
    if(
      (startDate && endDate)
        || (!startDate && !endDate)
    ) {
      getAllSearches().then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])

  const getAllSearches = useCallback(async (value) => {
    const sortData = {
      sortDirection,
      sortBy: sort,
    };

    const requestData = {
      pageNumber: inputDataCompare !== inputData ? 0 : (value === 0 ? 0 : (value || page)),
      pageSize: PAGE_LIMIT,
      name: inputData?.trim() || null,
      start: startDate && endDate ? moment(startDate).utc(true).startOf('day') : null,
      end: startDate && endDate ? moment(endDate).utc(true).endOf('day') : null,
      sortData,
      ...filters
    }
    const res = await debouncedSearchIndex(requestData);

    if (res.status === 200) {
      const searchesData = res.data.entries || res.data;

      const buffer = searchesData.map(async el => {
        let client = '';
        if (!!el.linkedClientId) {
          const resClient = await clientApi.client.show(el.linkedClientId);

          if (resClient.status === 200) {
            client = resClient.data.clientType === 'PERSON'
              ? `${resClient.data.firstName || ''} ${resClient.data.middleName || ''} ${resClient.data.lastName || ''}`.trim()
              : resClient.data.name;
          }
        }

        return ({
          ...el,
          createdOn: prepareTimestamp(el.createdOn, format),
          lastRunOn: el.lastRunOn ? prepareTimestamp(el.lastRunOn, format) : '',
          id: el.searchId,
          reviewed: 'Me',
          monitoring: !!el.ongoingMonitoringId ? 'On' : 'Off',
          client,
        });
      });

      const result = await Promise.all(buffer);

      inputDataCompare !== inputData && setPage(0);
      setAllPages(res.data.pages);
      setCountSearches(res.data.count);
      setAllSearches(result);
      setInputDataCompare(inputData);

      if (res.data.pages !== 0 && (value || page) > res.data.pages - 1) {
        setPage(res.data.pages - 1);
      }
    }
  }, [page, startDate, endDate, inputData, sort, sortDirection, inputDataCompare, filters])

  const onSort = (value) => {
    setPage(0)

    if(sort !== value) {
      setSort(value)
      setSortDirection('ASC')
    } else {
      setSortDirection(sortDirection === 'ASC'? 'DESC': 'ASC')
    }
  }

  const handleInputChange = useCallback(e => {
    setInputData(e.target.value)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setInputData]);

  const renderFilter = (
    <SearchHistoryFilter
      sort={sort}
      setSort={value => setSort(value)}
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      setFilters={setFilters}
    />
  )

  return (
    <React.Fragment>
      <Grid
        container
        classes={{ root: classes.rootWrapper }}
      >
        <Grid item sm={12}>
          <OutlinedSearch
            onEnter={getAllSearches}
            value={inputData}
            onChange={handleInputChange}
            label="Search"
          />
        </Grid>
        <Grid item sm={12}>{renderFilter}</Grid>
        <Grid item sm={12}>
          <SearchHistoryTable
            allSearches={allSearches}
            setAllSearches={setAllSearches}
            page={page}
            setPage={setPage}
            pageSize={PAGE_LIMIT}
            pagesCount={allPages}
            totalCount={countSearches}
            tableMaxHeight={`calc(100vh - ${differenceHeight})`}
            getAllSearches={getAllSearches}
            startData={startDate}
            endData={endDate}
            sortableColumns={['name', 'createdOn', 'reviewed', 'lastRunOn', 'user']}
            onSort={onSort}
            sort={sort}
            sortDirection={sortDirection}
            inputData={inputData}
            setMonitoring={(params) =>
              history.push(`/search-history/update-existing-monitoring-table/${params.searchId}/${params.clientId}`)
            }
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
};

import React from 'react';
import { Box, Card, CardContent, IconButton, Link, Typography } from '@material-ui/core';

import { makeStyles, withTheme } from '@material-ui/core/styles';
import CustomChip from '@components/controls/custom-chip';
import LabelsForRender from '@components/layout/labels-for-render';
import CustomTooltip from '@components/controls/tooltip';
import { BillingVisaIcon, AddActive2Icon, DeleteIcon, EditIcon } from '@app/icons';

const useStyles = makeStyles((theme) => ({
  userCard: props => ({
    background: 'linear-gradient(-37.72deg, rgb(255, 255, 255) 88.91%, rgb(255, 255, 255) 95.98%)',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    border: props.isReviewed ? `1px solid ${theme.palette.background.GY4}` : `1px solid ${theme.palette.common.white}`,
    borderRadius: '12px',
    overflow: 'inherit',
    '&:hover': {
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      cursor: 'pointer',
    },
    height: '100%'
  }),
  cardContentWrapper: props => ({
    marginTop: '24px',
    marginBottom: !props.footer ? '24px' : 0,
    marginLeft: '23px',
    marginRight: '23px'
  }),
  userCardHeader: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  userCardHeaderTitle: props => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: props.noLogged ? '90%' : '180px',
    whiteSpace: 'nowrap',
  }),
  tagsLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 22,
    '& .MuiChip-root': {
      marginRight: 5,
    }
  },
  tagAction: {
    '& .MuiChip-root': {
      margin: '5px 0',
    },
  },
  userCardTags: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: '8px 0',
    padding: 0,
  },
  userCardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  dummyBlock: {
    height: '32px',
  },
  iconButton: {
    padding: 8,
  }
}));


function BillingCard(props) {
  const { data, onEdit, onDelete, makePrimary, onAdd, isAdmin } = props;
  const { clientName, isPrimary, cardType, number, expired, fullName, address } = data || {};

  const classes = useStyles();

  const cardTypes = {
    'visa': <Box><BillingVisaIcon /></Box>
  }
  const labels = data && [
    { field: 'card type', value: cardTypes[cardType], notText: true },
    { field: 'number', value: number },
    { field: 'expires', value: expired },
    { field: 'full name', value: fullName },
    { field: 'address', value: address },
  ]

  return (
    <Card
      onClick={onAdd}
      className={classes.userCard}
      style={{
        background: onAdd ? '#F8F8F8' : 'linear-gradient(-37.72deg, #FFFFFF 88.91%, #FFFFFF 95.98%)',
      }}
    >
      {onAdd ?
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <AddActive2Icon /> <Link variant="body1">Add</Link>
        </Box>
        :
        <Box className={classes.cardContentWrapper}>
          <CardContent classes={{ root: classes.userCardHeader }}>
            <CustomTooltip title={clientName} placement="top">
              <Typography variant="h4" className={classes.userCardHeaderTitle}>
                {clientName}
              </Typography>
            </CustomTooltip>
            {!isAdmin && (
              <Box>
                <CustomTooltip title="Delete" placement="top">
                  <IconButton
                    onClick={onDelete}
                    className={classes.iconButton}
                  >
                    <DeleteIcon />
                  </IconButton>
                </CustomTooltip>
                <CustomTooltip title="Edit" placement="top">
                  <IconButton
                    onClick={onEdit}
                    className={classes.iconButton}
                  >
                    <EditIcon />
                  </IconButton>
                </CustomTooltip>
              </Box>
            )}
          </CardContent>
          <CardContent classes={{ root: classes.userCardTags }}>
            {isPrimary ?
              <Box className={classes.tagsLine}>
                <CustomChip label="Primary" type="ADDED" />
              </Box>
              :
              <Box className={classes.tagsLine}>
                {(makePrimary && !isAdmin) && <Link variant="body1" onClick={makePrimary}>Make Primary</Link>}
              </Box>
            }
          </CardContent>
          <CardContent classes={{ root: classes.userCardContent }}>
            <LabelsForRender
              labels={labels}
              anchorProps={{
                vertical: 'bottom',
                horizontal: -30,
              }}
              med
            />
          </CardContent>
        </Box>
      }
    </Card>
  )
}

export default withTheme(BillingCard);

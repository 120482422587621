const config = {
  fontFamily: [
    'Roboto',
    'Quicksand'
  ].join(','),
  h1: {
    fontFamily: 'Quicksand',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '40px',
    lineHeight: '48px',
    color: '#7A5B44'
  },
  h2: {
    fontFamily: 'Quicksand',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '34px',
    lineHeight: '42px',
    color: '#7A5B44'
  },
  h3: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '28px',
    color: '#232323'
  },
  h4: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#232323'
  },
  h5: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#232323',
  },
  subtitle1: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'center',
    color: '#232323',
    mixBlendMode: 'normal'
  },
  subtitle2: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#232323',
    mixBlendMode: 'normal'
  },
  body1: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#656565',
    mixBlendMode: 'normal'
  },

  body2: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px',
    lineHeight: '14px',
    color: '#656565',
    mixBlendMode: 'normal'
  },
  button: {
    textTransform: 'none'
  }
}

export default config
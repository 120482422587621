const initialState = {
  jobs: [],
};

const jobsManagment = (state = initialState, action) => {

  switch (action.type) {
    case 'SET_JOBS':
      return {
        ...state,
        jobs: action.jobs,
      };
    default:
      return state
  }
};

export default jobsManagment;

import React, { useMemo, useRef } from 'react';
import Dropzone from 'react-dropzone';
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';

import history from '@app/history';
import { ChevronLeftIcon, CloudCloudIcon, DownloadIcon } from '@app/icons';

import ButtonWithIcon from '@components/buttons/button-with-icon';
import InnerHeader from '@components/layout/inner-header';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  dropzone: {
    borderRadius: 12,
    backgroundColor: '#F8F8F8',
    width: '100%',
    outline: 'none',
  },
  timeline: {
    width: 310,
    margin: 0,
  },
  stepNumber: {
    width: 28,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    borderColor: '#7A5B44',
    margin: 0,
    padding: 0,
  },
  hackOpposite: {
    display: 'none'
  },
  timelineContent: {
    paddingTop: 2,
    paddingRight: 0,
  },
  h5: {
    lineHeight: '28px',
  },
  connector: {
    backgroundColor: '#7A5B44',
  },
  body1: {
    color: '#656565',
    fontSize: '12px',
  }
}));

// step 1
export default function ImportCSVUploadFile(props) {
  const classes = useStyles();
  const { uploadFile, type } = props;
  const dropzoneRef = useRef(null);

  const handleDrop = (file) => {
    uploadFile(file[0]);
  }

  const openUpload = () => {
    dropzoneRef?.current?.open();
  }

  const [fileName, filePath] = useMemo(() => {
    switch (type) {
      case 'person':
        return ['Import Portfolio - People Only.csv', '/files/Import Portfolio - People Only.csv'];

      case 'company':
        return ['Import Portfolio - Legal Entities Only.csv', '/files/Import Portfolio - Legal Entities Only.csv'];

      default:
        return ['Import Portfolio - People and Legal Entities.csv', '/files/Import Portfolio - People and Legal Entities.csv'];
    }
  }, [type])

  return (
    <Box height="100%" px={6}>
      <Box>
        <InnerHeader
          title="Upload File"
          buttons={
            <React.Fragment>
              <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={() => { history.goBack() }}>
                {'Back'}
              </ButtonWithIcon>
            </React.Fragment>
          }
        />
      </Box>
      <Box height="calc(100% - 98px)" style={{ overflowY: 'auto' }}>
        <Dropzone
          onDrop={handleDrop}
          multiple={false}
          accept={['.csv']}
          noClick
          ref={dropzoneRef}
        >
          {({ getRootProps, getInputProps }) => (
            <Box {...getRootProps()} mt={7} py={7} className={classes.dropzone}>
              <input {...getInputProps()} />
              <Box>
                <Typography variant="h4" align="center">You're about to upload a list of client names to your account.</Typography>
              </Box>
              <Box mt={7}>
                <Grid container justify="center" alignItems="center">
                  <Box pb={3}>
                    <CloudCloudIcon />
                  </Box>
                  <Box>
                    <Timeline className={classes.timeline}>
                      <TimelineItem>
                        <TimelineOppositeContent className={classes.hackOpposite} />
                        <TimelineSeparator>
                          <TimelineDot className={classes.stepNumber}>
                            <Typography variant="h5" className={classes.h5}>1</Typography>
                          </TimelineDot>
                          <TimelineConnector className={classes.connector} />
                        </TimelineSeparator>
                        <TimelineContent className={classes.timelineContent}>
                          <Typography variant="h5" className={classes.h5}>Upload clients to your Portfolio</Typography>
                          <Typography variant="body1">Prepare file; Select destination, Check list, upload</Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent className={classes.hackOpposite} />
                        <TimelineSeparator>
                          <TimelineDot className={classes.stepNumber}>
                            <Typography variant="h5" className={classes.h5}>2</Typography>
                          </TimelineDot>
                          <TimelineConnector className={classes.connector} />
                        </TimelineSeparator>
                        <TimelineContent className={classes.timelineContent}>
                          <Typography variant="h5" className={classes.h5}>Organize by Folder</Typography>
                          <Typography variant="body1">Assign a folder</Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent className={classes.hackOpposite} />
                        <TimelineSeparator>
                          <TimelineDot className={classes.stepNumber}>
                            <Typography variant="h5" className={classes.h5}>3</Typography>
                          </TimelineDot>
                        </TimelineSeparator>
                        <TimelineContent className={classes.timelineContent}>
                          <Typography variant="h5" className={classes.h5}>Turn on Ongoing Monitoring</Typography>
                          <Typography variant="body1">Daily, weekly or monthly</Typography>
                        </TimelineContent>
                      </TimelineItem>
                    </Timeline>
                  </Box>
                </Grid>
              </Box>
            </Box>
          )}
        </Dropzone>


        <Box mt={2}>
          <Grid container alignItems="center" justify="space-between">
            <Typography variant="body1">
              <b>NOTES: </b>
            </Typography>
            <ButtonWithIcon
              startIcon={<DownloadIcon />}
              href={filePath}
              target="_blank"
              download={fileName}
            >
              {'Download Sample File'}
            </ButtonWithIcon>
          </Grid>
          <Box className={classes.body1}>
            <ol style={{ margin: 0, paddingInlineStart: 15 }}>
              <li>
                <Typography variant="body1">
                  <b>Maximum number of names: 3,000</b>
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  <b>The first row or the column can’t be empty</b>
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  <b>Please make sure you use excel or google sheets when creating CSV.</b>
                </Typography>
                <Typography variant="body1">
                  <b>CSVs created in Numbers (for Mac) are not supported.</b>
                </Typography>
              </li>
              {type !== 'person' &&
                <li>
                  <Typography variant="body1">
                    <b>For Legal entities make sure that field "Entity Type" is filled with one of the following values:</b>
                  </Typography>
                  <ul style={{ margin: 0, fontWeight: 'bold' }}>
                    <li>Corporation</li>
                    <li>Foundation</li>
                    <li>Partnership</li>
                    <li>Trust</li>
                    <li>Limited Liability Company or LLC</li>
                  </ul>
                  <Typography variant="body1">
                    <b>This will enable us to import and define your entities correctly</b>
                  </Typography>
                </li>
              }
            </ol>
          </Box>
        </Box>

        <Box mt={4} mb={4}>
          <Grid container alignItems="center" justify="center">
            <Button
              variant="contained"
              size="large"
              onClick={openUpload}
            >
              {'NEXT'}
            </Button>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

import {ReactComponent as Check} from '../../../../assets/img/icons/landing-check.svg';
import {Button} from '@material-ui/core';
import React from 'react';

export default function CheckedOption (props) {
  return (
    <Button className="checked-option-btn"
      variant="outlined"
      disabled
      startIcon={<Check />}
    >
      {props.children}
    </Button>
  );
}
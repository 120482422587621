import React, { useState } from 'react';
import { Box, MenuItem, makeStyles } from '@material-ui/core';
import PageLayout from '@components/layout/page-layout';
import InnerHeader from '@components/layout/inner-header';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import MenuWithContext from '@components/dropdowns/menu-with-context';
import { AddActive2Icon, ChevronLeftIcon } from '@app/icons';

import history from '@app/history';
import { Route, Switch, Redirect } from 'react-router';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ManageSubscriptions from './manage-subscriptions';
import AddSubscriptions from './manage-subscriptions/add-subscriptions';
import SubscribersList from './subscribers-list';
import SubscriptionPlans from '@components/complex/subscription/plans';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 'calc(100% - 105px)',
    overflowY: 'auto',
    padding: '2px 16px',
  }
}));

const tabs = [
  {
    id: 'subscription',
    label: 'Manage Subscriptions',
  },
  {
    id: 'subscribers-list',
    label: 'Subscribers List',
  },
]

export default function SubscriptionManagement(props) {
  const classes = useStyles(); // eslint-disable-line

  const location = useLocation();
  const { tab, action } = useParams();

  const { userIsAdmin, side } = useSelector(state => state.auth);

  const [tabID, setTabID] = useState(tab || 'subscription');
  const [openModal, setOpenModal] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState([]);

  const onTabChange = (index) => {
    const tabID = tabs[index].id;
    setTabID(tabID);
    history.push(`/subscription-management/${tabID}`)
  }

  const handleMenuActions = (type) => {
    const checkedItems = subscriptionData.filter(el => el.isChecked);
    setOpenModal({
      name: checkedItems.map(el => `"${el.name}"`).join(', '),
      type,
      prefix: 'subscription(s)'
    })
  }

  const handleLabel = () => {
    const str = tabID.replace(/-/, ' ');
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

  const renderHeader = location.pathname.includes('subscription/change')
    ? (
      <InnerHeader
        title="Change Subscription Plan"
        stepper={{}}
        buttons={
          <React.Fragment>
            <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={() => { history.goBack() }}>
              {'Back'}
            </ButtonWithIcon>
          </React.Fragment>
        }
      />
    ) : (
      <InnerHeader
        onTabChange={onTabChange}
        ind={tabs.findIndex(tab => tab.id === tabID)}
        tabs={tabs}
        buttons={(tabID === 'subscription' && (side === 'admin' || userIsAdmin)) && (
          <React.Fragment>
            <ButtonWithIcon
              startIcon={<AddActive2Icon />}
              onClick={() => history.push('/subscription-management/subscription/add-subscription')}
            >
              New {handleLabel()}
            </ButtonWithIcon>
            {false &&
              <MenuWithContext title="More Actions">
                <MenuItem onClick={() => handleMenuActions('delete')}>
                  Delete
                </MenuItem>
                <MenuItem onClick={() => handleMenuActions('block')}>
                  Block
                </MenuItem>
              </MenuWithContext>
            }
          </React.Fragment>
        )}
      />
    )

  return (
    <PageLayout>
      <Box mx={6} height="100%">
        {!(location.pathname.includes('add-subscription') || location.pathname.includes('edit-subscription')) && renderHeader}
        <Box display={'flex'} flexDirection={'column'} height={'calc(100% - 40px)'}>
          <Switch>
            <Route exact path="/subscription-management/subscription">
              <ManageSubscriptions
                action={action}
                openModal={openModal}
                setOpenModal={setOpenModal}
                setSubscriptionData={setSubscriptionData}
                setTabID={setTabID}
                userIsAdmin={userIsAdmin && side === 'client'}
              />
            </Route>
            <Route exact path="/subscription-management/subscription/add-subscription">
              <AddSubscriptions />
            </Route>
            <Route exact path="/subscription-management/subscription/edit-subscription">
              <AddSubscriptions isEdit />
            </Route>
            <Route exact path="/subscription-management/subscribers-list">
              <SubscribersList baseRoute="/subscription-management/subscribers-list" userIsAdmin={userIsAdmin && side === 'client'} />
            </Route>
            <Route exact path="/subscription-management/subscription/change">
              <SubscriptionPlans baseRoute="/subscription-management/subscription/change" isAdmin={true} />
            </Route>

            <Redirect to={`/subscription-management/${tabID}/:${action}?`} />
          </Switch>
        </Box>
      </Box>
    </PageLayout>
  )
}
import React from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((props) => ({
  'root-sm': {
    display: 'flex',
    alignItems: 'center',
    border: props => `1px solid ${props.color}`,
    borderRadius: '17px',
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '17px',
    padding: '0 6px 0 1px',
    color: '#232323'
  },
  'icon-sm': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '15px',
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '7px',
    textAlign: 'center',
    color: '#232323',
    background: props => props.color,
    width: '15px',
    height: '15px',
    marginRight: '4px'
  },
  'root-md': {
    display: 'flex',
    alignItems: 'center',
    border: props => `1px solid ${props.color}`,
    borderRadius: '17px',
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '17px',
    padding: '2px 10px 2px 2px',
    color: '#232323'
  },
  'icon-md': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '26px',
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '7px',
    textAlign: 'center',
    color: '#232323',
    background: props => props.color,
    width: '26px',
    height: '26px',
    marginRight: '7px'
  }
}))

const STYLE_COLORS = {
  custom: '#D5F0F0',
  system: '#F2EFEC'
}

export default function OwnedByBadge({ownedBy, size = 'sm'}) {

  const classes = useStyles({
    color: STYLE_COLORS?.[ownedBy.toLowerCase()] || STYLE_COLORS.system
  })

  const iconChar = ownedBy.substr(0, 1).toUpperCase()

  return (
    <Box className={classes[`root-${size}`]}>
      <Box className={classes[`icon-${size}`]}>
        { iconChar }
      </Box>
      <Box display={'flex'}>
        { ownedBy.charAt(0).toUpperCase() + ownedBy.slice(1).toLowerCase() }
      </Box>
    </Box>
  )
}
export const TABS = [
  {
    id: 'system',
    label: 'System Documents',
  },
  {
    id: 'user',
    label: 'Custom Documents',
  },
]

export const CLASS_COLOR = {
  'Legal Entity': '#D1C2B6',
  'Individual': '#D5F0F0',
  'SYSTEM': '#D1C2B6',
  'USER': '#D5F0F0',
}

export const RADIO_GROUP = [
  { value: 'all', label: 'View All' },
  { value: 'Legal Entity', label: 'View Legal Entities' },
  { value: 'Individual', label: 'View Individual ' },
]

export const VIEW_BY = [
  { name: 'Type', id: 'type' },
  { name: 'Category', id: 'category' },
]

import React from  'react';
import { Button } from '@material-ui/core';
import CustomTooltip from '@components/controls/tooltip';
import { useSelector } from 'react-redux';
import { useStyles } from './styles';

export default function SearchButton({ quantityCredits, action }) {
  const classes = useStyles();

  const { searchModel } = useSelector(state => state.backgroundSearch);

  return (
    <CustomTooltip
      title="You do not have enough credits. Please upgrade your subscription plan."
      placement="top-end"
      disableHoverListener={!!quantityCredits}
    >
      <span>
        <Button
          variant="contained"
          classes={{ root: classes.searchButton }}
          size="large"
          onClick={action}
          disabled={!searchModel.name.trim()}
        >
          SEARCH
        </Button>
      </span>
    </CustomTooltip>
  )
}

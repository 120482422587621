import React, { useState, useEffect } from 'react';
import {
  DropdownDownIcon,
  History2Icon,
} from '@app/icons';
import { Box, Collapse } from '@material-ui/core';
import CustomIconButton from '@components/buttons/button-with-icon';
import { makeStyles } from '@material-ui/core/styles';
import { clientApi } from '@app/api';

import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { searchHistory, loader } from '@store/actions';
import VersionsPagination from '@components/pagination/versions-pagination';
import VersionHistoryList from '../../new-search-components/version-history-list';

export const useStyles = makeStyles((theme) => ({
  spacesUserInfoButton: {
    marginLeft: '-5px',
    marginBottom: 5,
  },
  itemsHistoryWrapper: {
    margin: '0px -18px 0px -16px',
    overflowY: 'auto',
    paddingRight: '2px',
    '&::-webkit-scrollbar': {
      width: '2px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px #EDEDED',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#A4A4A4',
    },
    '& .MuiChip-root': {
      marginTop: 5,
      marginBottom: 5
    }
  },
  customButtonColor: {
    color: theme.palette.text.BL1,
  },
}));

export default function UserInfoCardVersions({
  setCurrentTab,
  accordion,
  setAccordion,
  accordionName,
}) {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [searchId] = useState(search.get('searchId'));

  const { searchVersions, profilesVersion } = useSelector(store => store.searchHistory);
  const dispatch = useDispatch();

  const classes = useStyles();

  const [pagination, setPagination] = useState({
    pageSize: 20,
    count: 0,
    pages: 0,
    pageNumber: 0,
  })
  const { pageSize, count, pages, pageNumber } = pagination;

  const getSearchVersions = async () => {
    const [res, lastVersion] = await Promise.all([
      clientApi.searchVersion.getSearchVersions(searchId, pageNumber, pageSize),
      clientApi.search.getSearch(searchId)
    ])

    if (res.status === 200) {
      const searchVersions = res.data.entries;

      // Add current search info as last version
      if (pageNumber === 0) {
        if (!!searchVersions.length) {
          searchVersions.unshift({
            id: 'last_version',
            searchId: +searchId,
            performedOn: lastVersion.data.lastRunOn,
            acknowledged: lastVersion.data.acknowledged,
          })
        }
      }

      dispatch(searchHistory.setSearchVersion([
        ...searchVersions,
      ]));

      setPagination({
        ...pagination,
        count: res.data.count,
        pages: res.data.pages,
      });

      const currentVersion = searchVersions.find(e => e.id === profilesVersion.id)
      if (currentVersion) {
        dispatch(searchHistory.setProfilesVersion(currentVersion))
      }
      if (profilesVersion?.searchId !== +searchId) {
        if (searchVersions.length) {
          dispatch(searchHistory.setProfilesVersion(searchVersions[0]))
        } else {
          dispatch(searchHistory.setDefault())
        }
      }

    }
  }
  
  useEffect(() => {
    getSearchVersions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const setPageNumber = (pageNumber) => {
    setPagination({
      ...pagination,
      pageNumber,
    })
  }

  const onSelect = async (el) => {
    dispatch(loader.setMoreLoader(true));
    setCurrentTab({ tabLabel: 'All', tabIndex: 0 });

    const version = searchVersions.find(e => e.id === el.id);
    if (version) {
      dispatch(searchHistory.setProfilesVersion(version));
    }
  }

  return (
    <>
      {!!searchVersions.length && (
        <>
          <Box mt={2} className={classes.spacesUserInfoButton}>
            <CustomIconButton
              classes={{ root: classes.customButtonColor }}
              className="minLabel"
              startIcon={<History2Icon />}
              endIcon={<DropdownDownIcon className={accordion[accordionName] ? classes.rotate : ''} />}
              onClick={() => setAccordion(accordionName)}
            >
              Show History
            </CustomIconButton>
          </Box>
          <Collapse in={accordion[accordionName]} className={classes.itemsHistoryWrapper}>
            <VersionHistoryList onSelect={onSelect} />
            {count > pageSize &&
              <Box mt={1}>
                <VersionsPagination
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                  count={count}
                  pages={pages}
                  pageSize={pageSize}
                />
              </Box>
            }
          </Collapse>
        </>
      )}
    </>
  )
}

export const BASE_SANCTIONS = [
  'AU DFAT Sanctions',
  'CA DFATD SEMA Sanctions',
  'CH SECO Sanctions',
  'EU FSF',
  'GB HMT Sanctions',
  'Interpol Red Notices',
  'KG FIU National',
  'UA SFMS Blacklist',
  'UN SC Sanctions',
  'US BIS Denied',
  'US OFAC CONS',
  'US OFAC SDN',
  'Worldbank Debarred',
]

export const PEP = [
  'EU MEPS',
  'US CIA World Leaders',
  'Every Politician',
]

export const PREMIUM_SANCTIONS = [
  'Arab states Sanctions List against Qatar related individuals',
  'Arab states Sanctions List against Qatar related organisations',
  'Argentina Ministerio de Relaciones Exteriores y Culto Sanciones de la ONU',
  'Argentina Public Registry of People and Entities linked to acts of Terrorism and Terrorism Financing',
  'Australia Autonomous Sanctions for Russia Crimea and Sevastopol',
  'Australia Autonomous Sanctions North Korea Designated Vessels',
  'Australian National Security Terrorism List',
  'Austria Oesterreichische Nationalbank OENB Historical Sanctions',
  'Austria Oesterreichische Nationalbank OENB Sanctions',
  'Azerbaijan Financial Intelligence Unit National Sanctions List',
  'Bahrain Terrorists List',
  'Bangladesh Domestic Sanctions List',
  'Belarus State Security Agency List of Organizations and Individuals Involved in Terrorist Activities',
  'Belgium Consolidated List of the National and European Sanctions',
  'Belgium Federal Public Service Finance National Financial Sanctions',
  'Bulgaria Domestic Sanctions under the Council of Ministers Decision 265 of 2003 (Suspended)',
  'Canada OSFI Entity List (Suspended)',
  'Canada OSFI Person List (Suspended)',
  'Canada Regulations Implementing the United Nations Resolutions on the Suppression of Terrorism',
  'Canada Sanctions Justice for Victims of Corrupt Foreign Officials Act',
  'Canadian Freezing Assets of Corrupt Foreign Officials Act',
  'China Ministry of Public Security Terrorist list',
  'Czech Republic Sanctions Government Order to implement specific international measures to combat terrorism',
  'DFAT Australia Consolidated Sanctions List',
  'DFATD Canada Special Economic Measures Act Designations',
  'Estonia Designated Persons under Magnitsky Amendments',
  'EU External Action Service - Consolidated list of Sanctions',
  'European Council List of Designated Vessels',
  'European Council Restrictive Measures on Russian entities destabilising the situation in Ukraine',
  'Finland National Bureau of Investigation Asset Freeze List',
  'France Tresor Direction Generale Liste Unique de Gels',
  'Germany Prohibited Organizations',
  'HM Treasury Ukraine Sanctions',
  'Hong Kong Special Administrative Region Sanctions issued under the UN Sanctions Ordinance',
  'Iceland Sanctions List',
  'India Ministry of Home Affairs Banned Organisations',
  'India Ministry of Home Affairs Designated Terrorists Under Unlawful Activities (Prevention) Act',
  'Indonesia PPATK List of Funders of Proliferation of WMD Sanctions',
  'Indonesia PPATK List of Suspected Terrorists and Terrorist Organizations DTTOT Sanctions',
  'Iran March and May 2017 Sanctions against US entities',
  'Ireland Organisations Sanction Laws',
  'Israel Division of Sanctions Headquarters Prevention of Distribution and Financing of Weapons of Mass Destruction sanctions',
  'Israel National Bureau for Counter Terror Financing Designated associations and terrorists organizations',
  'Israel National Bureau for Counter Terror Financing Designated individuals',
  'Israel National Bureau for Counter Terror Financing Seizures of property of terrorist operatives',
  'Japan METI Export Control Security Division End User list',
  'Japan Ministry of Economy, Trade and Industry Administrative Sanctions',
  'Japan National Public Service Commission International Terrorists List',
  'Japan Public Security Intelligence Agency International Terrorist Organisations',
  'Jersey Proscribed Terrorist Organizations',
  'Kazakhstan Committee on Legal Statistics and Special Records of the Prosecutor General List of Terrorist and Extremist Organizations',
  'Kazakhstan Ministry of Finance Organizations and Individuals Associated with the Financing of Terrorism and Extremism',
  'Kenya Gazette Notices of Sanctions Designations under the Prevention of Terrorism Act',
  'Kyrgyzstan State Commission for Religious Affairs Prohibited Organisations',
  'Kyrgyzstan State Financial Intelligence Service National List of Sanctions',
  'Latvia Designated Persons under Magnitsky Amendments',
  'Latvia National Sanctions List',
  'Lithuania Designated Persons Under Magnitsky Amendments',
  'Luxembourg Liste des Sanctions Financieres Internationales',
  'Malaysia Ministry of Home Affairs List of Sanctioned Entities',
  'Malaysia Ministry of Home Affairs List of Sanctioned Individuals',
  'Malta Financial Services Authority (MFSA) National Sanctions',
  'Mexico Financial Intelligence Unit UN Sanctions for terrorism and non-proliferation',
  'Ministry of Finance Japan Economic Sanctions List',
  'Monaco Economic Sanctions',
  'Monaco Ministerial Orders(Suspended)',
  'New Zealand Police Designated Terrorists',
  'Norway Lovdata laws judicial database (Suspended)',
  'Norway Sanctions and Restrictive Measures',
  'OFAC Consolidated List',
  'OFAC Non-SDN Communist Chinese Military Companies List',
  'OFAC SDN List',
  'OFAC SDN List - Related Entities (Suspended)',
  'Pakistan Federal Investigation Agency High Profile Terrorists',
  'Pakistan Federal Investigation Agency Other High Profile Terrorists',
  'Pakistan National Counter Terrorism Authority List of Organizations Proscribed by the Ministry of Interior',
  'Pakistan National Counter Terrorism Authority Schedule IV Proscribed Persons',
  'Philippines AMLC Sanctions Resolutions',
  'Philippines Anti-Terrorism Council Designations',
  'Philippines Bangko Sentral Philippinas Circulars on Sanctioned Entities after 2012',
  'Public Safety Canada Listed Entities',
  'Qatar National Terrorist Designation Lists',
  'Qatar National Terrorist Designation Lists Arabic Version',
  'Republic of Moldova National Terrorist List',
  'Romania Terrorism Related Sanctions List',
  'Russia Central Bank Special Economic Measures Designations',
  'Russia Government Special Economic Measures on Ukraine',
  'Russian ROSFIN Monitoring List Extremism and Terrorism',
  'Russian ROSFIN Monitoring WMD List',
  'Russian Sanctions Federal Security Service List of Terrorist Organisations',
  'Sanction Netherlands Domestic Terrorist List',
  'Sanction Related Entities',
  'Serbia Government Decisions on Terrorism related Sanctions Designations',
  'Singapore First Schedule of The Terrorism (Suppression of Financing Act) 2 (Suspended)',
  'Singapore First Schedule of the Terrorism (Suppression of Financing) Act',
  'Singapore MAS List',
  'South Africa Targeted Financial Sanctions List organisations',
  'South Africa Targeted Financial Sanctions List persons',
  'South Korea Financial Services Commission Transaction Ban Designations (Suspended)',
  'South Korea Ministry of Economy and Finance Transaction Ban Designations',
  'South Korea Office for Government Policy Coordination North Korea Sanctions List',
  'Spain Ministry of Interior Wanted Terrorists',
  'Sri Lanka Ministry of Defence National Sanctions Designations under UN Regulations concerning terrorism',
  'Sri Lanka\'s Ministry of Defence National Sanctions Designations under UN Regulations concerning the proliferation of Weapons of Mass Destruction',
  'Switzerland Federal Council Freezing of Foreign Illicit Assets',
  'Switzerland SECO List',
  'Taiwan Ministry of Justice Investigation Bureau Sanctions under Anti-terrorism Law',
  'Thailand Designated Persons List (Suspended)',
  'Thailand Designated Persons Sanctions List',
  'Thailand Designated Persons Thai Version',
  'Tunisia Sanctions National list of persons, organizations and entities associated with terrorist offenses',
  'Turkey Financial Crimes Investigation Board Terrorism Asset Freezes',
  'Turkey Ministry of Interior Wanted Terrorists',
  'Ukraine Ministry of Interior Wanted Terrorists',
  'Ukraine Sanctions National Security and Defense Council (NSDC) Special Economic and Other Restrictive Measures – Organisations',
  'Ukraine Sanctions National Security and Defense Council (NSDC) Special Economic and Other Restrictive Measures – Persons',
  'Ukraine State Financial Monitoring Black List',
  'UN Consolidated - Related Entities (Suspended)',
  'UN Liberia Sanctions (Terminated)',
  'United Arab Emirates List of Designated Terrorist Organisations and Groups',
  'United Arab Emirates National List of Terrorist Individuals and Entities in Arabic',
  'United Kingdom HM Treasury Office of Financial Sanctions Implementation Consolidated List',
  'United Kingdom Home Office List of Proscribed Terrorist Organisations',
  'United Kingdom Sanctions Anti-Money Laundering Act 2018',
  'United Nations Consolidated',
  'United Nations Security Council List of Designated Vessels',
  'United States Bureau of Industry and Security Entity List',
  'United States Bureau of Industry and Security Military End User List',
  'United States Bureau of Industry and Security The Denied Persons List',
  'United States Bureau of Industry and Security Unverified List',
  'United States Coast Guard Prohibited Vessels (Suspended)',
  'United States Consolidated Screening List (CSL) (Suspended)',
  'United States Department of State Counter Terrorism Rewards Program',
  'United States Department of State Cuban Restricted Entities List',
  'United States Department of State Nonproliferation Sanctions List',
  'United States Department of State Terror Exclusion List',
  'United States Financial Crimes Enforcement Network 311 Special Measures',
  'Vietnam Sanctions Ministry of Public Security Terrorism List',
  'Philippines Department of Foreign Affairs Terrorist Organizations',
]

export const PEP_CLASS_1 = [
  'Heads of state',
  'Members of the national executive',
  'Members of the national legislatures',
  'Senior officials of military and law enforcement',
  'Senior officials of national agencies',
]

export const PEP_CLASS_2 = [
  'Board members of central banks',
  'Senior judiciary officials (national level)',
  'Top-ranking officials of political parties',
  'Board members of International Organizations (HIOs) & International Sports Federations',
  'Ambassadors, high commissioners and other top diplomatic positions',
  'Members of the regional executive',
  'Members of the regional legislatures',
  'Senior judiciary officials (regional level)',
]

export const PEP_CLASS_3 = [
  'Senior management and board of directors of state owned businesses and organisations',
]

export const PEP_CLASS_4 = [
  'Mayors and members of local government (sub-regional level)',
]

export const RCAS = [
  'Spouse/ partner (including a person who is considered by his national law as equivalent to a spouse)',
  'Siblings/ Half-siblings',
  'Children/ Step-children and their spouses and partners',
  'Parents/ Step-parents',
  'Entities that have a business relationship with a PEP',
  'Entities that are connected within the same organization with a PEP',
  'Close friends of a PEP',
]

export const ADVERCE_MEDIA = [
  'The Comply Advantage adverse media dataset is a state of the art, real-time structured data feed of companies and individuals subject to adverse media. Monitoring thousands of news sources, business and trade journals, in addition to local, regional and national newspapers, the adverse media feed isolates and highlights any entities subject to a range of adverse media.',
]

export const WARNINGS = [
  'Asian Development Bank Sanctions',
  'EU Most Wanted Warnings',
  'European Commission Banned Airlines (Suspended)',
  'European Commission Early Detection and Exclusion System Exclusions',
  'International Criminal Court Closed Cases',
  'International Criminal Court Ongoing Cases and Convictions',
  'International IOSCO Warnings International Organization of Securities Commissions',
  'Interpol Wanted',
  'National Wanted Sites (Suspended)',
  'United Nations Development Program Ineligibility List Organisations',
  'United Nations International Criminal Tribunal for Rwanda',
  'United Nations International Criminal Tribunal for the former Yugoslavia Judgement List',
  'United Nations International Residual Mechanism for Criminal Tribunals Fugitives',
  'United Nations International Residual Mechanism for Criminal Tribunals Ongoing Cases',
  'United Nations Office for Project Services Vendor Sanctions',
  'World Bank Star Asset Recovery Watch (Suspended)',
  'World Bank Star Puppet Masters (Suspended)',
  'World Bank Star Settlements (Suspended)',
  'United Nations Security Council Sanctions Committee 1718 DPRK Panel of Experts Recommendations on Vessel Designations',
  'Botswana Most Wanted Criminals (Suspended)',
  'Ghana Economic and Organized Crime Office Wanted Persons',
  'Ghana Police Service Most Wanted',
  'Ghana Securities and Exchange Commission Enforcement Actions',
  'Kenya Capital Markets Authority Investigations and Enforcement',
  'Kenya Capital Markets Authority Wanted Persons',
  'Kenya National Police Service Most Wanted',
  'Mauritius Independent Commission Against Corruption',
  'Nigeria Securities Exchange Commission',
  'Nigeria EFCC',
  'South Africa Police Service Wanted Persons',
  'Mauritius Financial Services Commission Investor Alerts',
  'Nigeria Independent Corrupt Practices and Other Related Offences Commission Wanted',
  'South Africa Financial Intelligence Centre Administrative Sanctions',
  'South Africa Reserve Bank Administrative Sanctions',
  'South Africa Financial Sector Conduct Authority Enforcement Actions',
  'Nigeria Central Bank Delisted Community Banks',
  'Nigeria Deposit Insurance Corporation Closed Microfinance Banks',
  'Nigeria Deposit Insurance Corporation Closed Financial Institutions',
  'Nigeria National Agency for the Prohibition of Trafficking in Persons Convicted Human Traffickers',
  'Nigeria National Agency for the Prohibition of Trafficking in Persons Sexual Offenders',
  'South Africa National Treasury Database of Restricted Suppliers',
  'Brunei Most Wanted',
  'China Banking Regulatory Commission Administrative Penalties',
  'China Central Bank Administrative Penalties',
  'Hong Kong Mandatory Provident Fund Schemes Authority Enforcement-Criminal Cases',
  'China Economic Fugitives',
  'Hong Kong Securities and Futures Commission Alert List Chinese',
  'Hong Kong Securities and Futures Commission Warnings List',
  'Hong Kong Companies Registry Prosecution Cases',
  'Hong Kong Mandatory Provident Fund Schemes Authority Enforcement- Repeated Convictions',
  'Hong Kong Mandatory Provident Fund Schemes Authority Enforcement-Civil cases',
  'Hong Kong Independent Commission Against Corruption Most Wanted',
  'Hong Kong Police Force - Wanted Persons with Reward Notices',
  'Hong Kong Securities Futures Commission Cold Shoulder Orders',
  'Hong Kong Securities Futures Commission Disqualification Orders',
  'Hong Kong Securities Futures Commission Enforcement Actions Organisations',
  'Hong Kong Securities Futures Commission Enforcement Actions Persons',
  'Hong Kong Securities Futures Commission People Subject to Arrest Warrants',
  'Hong Kong Stock Exchange Enforcement Notices Organisations',
  'China Insurance Regulatory Commission Administrative Penalties Persons',
  'Hong Kong Stock Exchange Enforcement Notices Persons',
  'China Securities Regulatory Commission Warnings',
  'China Securities Regulatory Commission Suspended Advisories',
  'China Securities Regulatory Commission Administrative Enforcement Decisions Organisations',
  'China State Administration of Foreign Exchange Circulars on Violations',
  'China Securities Regulatory Commission Administrative Enforcement Decisions Persons',
  'China State Administration of Foreign Exchange Circulars on Violations Archive',
  'INDIA CBI Red Yellow List (Suspended)',
  'INDIA CBI Reward List (Suspended)',
  'INDIA CBI Wanted List',
  'India Competition Commission (Suspended)',
  'India National Investigation Agency Most Wanted',
  'Indonesia Commodity Futures Trading Supervisory Agency of the Ministry of Trade Revoked Futures Broker List',
  'Indonesia Criminal Investigation Police Most Wanted',
  'Indonesia Financial Services Authority Unlicensed Entities',
  'Japan Aichi Designated Gangsters',
  'Japan Financial Services Agency Unregistered Companies',
  'Japan Financial Services Agency Cold Calling Alerts',
  'Japan FSA Illegal Financial Companies',
  'Japan Financial Services Agency Institutional Investors Warnings 1',
  'Japan JPX',
  'Japan Malicious Money Lenders',
  'Japan MEXT Exclusions',
  'Japan Police Most Wanted',
  'Japan Shizuoka Designated Gangsters',
  'Malaysia Royal Police Wanted Persons',
  'Malaysia Anti-Corruption Commission Corruption Offenders',
  'Malaysia National Bank Wanted Persons',
  'Malaysia Securities Commission Criminal Prosecutions',
  'Malaysia Securities Commission Unauthorized Entities',
  'Malaysia Securities Commission Wanted Persons',
  'Philippines Drug Enforcement Group Most Wanted',
  'Philippines Criminal Investigation and Detection Group Most Wanted',
  'Philippines National Police Most Wanted',
  'Singapore Corrupt Practices Investigation Bureau Corruption Enforcements Companies',
  'Singapore Corrupt Practices Investigation Bureau Corruption Enforcements Persons',
  'Singapore Inland Revenue Authority Tax Crimes Companies',
  'Singapore Inland Revenue Authority Tax Crimes Persons',
  'Singapore Ministry of Home Affairs Enforcements under Internal Security Act',
  'Singapore Ministry of Manpower Convicted Employers',
  'Singapore Monetary Authority Investor Alerts',
  'Singapore Monetary Authority Enforcement Actions Companies',
  'Singapore Monetary Authority Enforcement Actions Persons',
  'Singapore Police Force Commercial Crimes (Suspended)',
  'Singapore Registry of Moneylenders Fined Moneylenders',
  'Singapore SGX Public Disciplinary Actions Companies',
  'Singapore SGX Public Disciplinary Actions Persons',
  'Singapore Casino Regulatory Authority Enforcement Actions',
  'Republic of Korea Financial Supervisory Service Investor Alert',
  'Republic of Korea National Police Agency Wanted',
  'Tajikistan Ministry of Internal Affairs Wanted (Suspended)',
  'Thailand Securities and Exchange Commission',
  'Viet Nam State Securities Commission of Vietnam Enforcements',
  'Vietnam State Securities Commission of Vietnam Administrative Violations persons',
  'Vietnam State Securities Commission of Vietnam Administrative Violations organisations',
  'Thailand Securities and Exchange Commission Enforcement Measures',
  'Thailand National Anti Corruption Commission Cases referred to prosecution',
  'Thailand Securities and Exchange Commission Warnings',
  'India Ministry of Corporate Affairs List of Disqualified Directors Division 164 Bangalore',
  'India University Grants Commission Fake Universities',
  'India Ministry of Social Justice and Empowerment Deblacklisted NGOs',
  'India Income Tax Department Tax Defaulters',
  'India National Stock Exchange Defaulter and Expelled Members',
  'India Financial Intelligence Unit Non Compliant NBFC List',
  'India Financial Intelligence Unit Orders',
  'India National Investigation Agency Arrested Persons in Custody',
  'India Financial Intelligence Unit Judgements',
  'Israel Ministry of Communications Financial Penalties',
  'Japan Ministry of Land, Infrastructure and Transport Exclusions',
  'Japan Agricultural And Rural Development Information Center Exclusion List',
  'Japan Railway Construction, Transport and Technology Agency Exclusion List',
  'Kazakhstan Prosecutor General\'s Office Wanted Persons',
  'Philippines Deposit Insurance Corporation Bulletins',
  'Philippines Central Bank Prohibitions and Revocations',
  'Russian Federation Federal Bailiff Service Most Wanted',
  'Russian Federation Ministry of Justice Suspended Charities under Federal Law Countering Extremist Activities',
  'Russian Federation Russian Gazette Most Wanted Drug Dealers',
  'Russian Federation Ministry of Justice List of Undesirable Foreign and International Non-Governmental Organizations',
  'Russian Federation Federal Penitentiary Service Most Wanted',
  'Taiwan Financial Supervisory Committee Banking Bureau Enforcement Actions',
  'Taiwan Financial Supervisory Committee Insurance Bureau Enforcement Actions',
  'Taiwan Ministry of Justice Investigation Bureau Wanted Persons',
  'Armenia Central Bank Punishments',
  'Armenia Financial Monitoring Center Court Verdicts',
  'Armenia Police Wanted Persons (Suspended)',
  'India Ministry of Corporate Affairs List of Disqualified Directors Section 274',
  'India Ministry of Corporate Affairs Multi-level Marketing Companies',
  'India Ministry of Social Justice and Empowerment Blacklisted NGOs',
  'India Wildlife Crime Control Bureau Court Judgements',
  'India Wildlife Crime Control Bureau Convicts',
  'Taiwan Securities and Futures Investors Protection Center Class Action Cases',
  'Kyrgyzstan Financial Police Wanted',
  'Kyrgyzstan State Committee of National Security Wanted',
  'Georgia State Procurement Agency Blacklist',
  'Pakistan Central Depository Company Disciplinary Registers',
  'Kyrgyzstan State Penitentiary Service Wanted',
  'Kyrgyzstan State Personnel Service Disqualifications',
  'Kyrgyzstan General Prosecutor Convictions',
  'Azerbaijan General Directorate for Combating Trafficking Human Trafficking List',
  'India Ministry of Corporate Affairs Vanishing Companies',
  'India Insurance Regulatory and Development Authority of India Warnings and Penalties',
  'India Ministry of Corporate Affairs Struck Off Companies',
  'Japan National Police Agency Designated Boryokudan',
  'Uzbekistan Ministry of Internal Affairs Wanted',
  'Uzbekistan Main Department of Internal Affairs of Tashkent Wanted',
  'Bangladesh Securities and Exchange Commission Enforcement Actions',
  'China Shanghai Stock Exchange Disciplinary Actions',
  'Hong Kong Monetary Authority Disciplinary Actions',
  'Republic of Korea Financial Supervisory Service Accounting Supervision',
  'Republic of Korea Financial Supervisory Service Administrative Sanctions',
  'Russian Federation Moscow Stock Exchange Violations of Disclosure Requirements',
  'Russian Federation Investigative Committee Criminal Cases related to Prohibited Warfare in Ukraine',
  'Russian Federation State Assay Office Results of AML Supervision',
  'Russian Federation Central Bank Stocks and Bonds Market Cancelled Licenses of Professional Securities Market Participants',
  'Russian Federation Central Bank Stocks and Bonds Market Suspended Licenses of Professional Securities Market Participants',
  'Russian Federation Central Bank Stocks and Bonds Market Cancelled certificates of financial market specialists',
  'Russian Federation Central Bank Revoked Insider Certificates',
  'Russian Federation Central Bank Non-credit financial Organizations AML Reporting Breaches',
  'Russian Federation Federal Tax Service Entities offering illegal lotteries',
  'Russian Federation Federal Tax Service Entities offering illegal gambling',
  'Russian Federation Central Bank Administrative Proceedings',
  'Armenia Police Wanted',
  'Japan FSA Administrative Penalties List',
  'Japan Financial Services Agency Institutional Investors Warnings 2',
  'Hong Kong Environmental Protection Department Convictions',
  'Hong Kong Consumer Council Named Traders',
  'Hong Kong Taxation Institute Disciplinary Orders',
  'Hong Kong Law Society Scam Alerts',
  'Hong Kong Institute of Certified Public Accountants Disciplinary Orders',
  'Hong Kong Bar Association Disciplinary Tribunal',
  'Hong Kong Competition Commission Infringement Notices',
  'Hong Kong Customs and Excise Department Undertakings',
  'Hong Kong Market Misconduct Tribunal Reports',
  'Hong Kong Institute of Certified Public Accountants Resolution by Agreement',
  'Hong Kong Market Misconduct Tribunal Execution of Orders',
  'Hong Kong Judiciary Sentences',
  'Hong Kong Companies Registry Non-Compliant Companies',
  'Hong Kong Companies Registry Dismissed or Withdrawn Applications for Money Lenders Licences',
  'Hong Kong Market Misconduct Tribunal Rulings',
  'Hong Kong Department of Justice Notable Judgements',
  'Hong Kong Companies Registry Companies Prosecuted',
  'India Serious Fraud Investigation Office List of Proclaimed Offenders',
  'India National Housing Bank Penalties',
  'India National Housing Bank Companies Not Valid for Acceptance of Public Deposits',
  'India National Housing Bank Companies against which prohibitory/cancellation order has been issued',
  'India National Housing Bank List of Companies ineligible to carry on the business of housing finance institution',
  'India National Housing Bank List of Companies whose application for Certificate of Registration have been declined/rejected',
  'India National Anti-Profiteering Authority Orders',
  'India Serious Fraud Investigation Office List of Directors convicted',
  'India Ministry of Corporate Affairs List of Proclaimed Offenders under section 82 Criminal Procedure',
  'India Directorate General of Foreign Trade Penalized Companies',
  'Thailand Stock Exchange Disciplinary Actions',
  'India Securities Exchange Board of India Debarred Entities',
  'Republic of Korea Bar Association Disciplinary Actions',
  'Republic of Korea Consumer Agency Scam Suspicious Websites',
  'Singapore Institute of Charted Accountants Major Local Accounting Scandals',
  'Republic of Korea Exchange Sanctioned Members',
  'Singapore Council for Estate Agencies Suspended/Revoked Salespersons',
  'Singapore Council for Estate Agencies Revoked Estate Agents',
  'Singapore Accounting and Corporate Regulatory Authority Disciplinary Proceedings',
  'Malaysia Bar Members Without Practicing Certificates',
  'Malaysia Institute of Accountants Disciplinary Decisions',
  'Malaysia National Bank Financial Consumer Alert',
  'Malaysia Bar Disciplinary Orders',
  'Malaysia National Bank Money Laundering Charges',
  'Republic of Korea Fair Trade Commission Law Violations',
  'Malaysia Ministry of Finance Disciplinary Actions',
  'Indonesia Stock Exchange Trading Suspensions',
  'Singapore ACRA Entities to be struck off',
  'Singapore ACRA Entities struck off',
  'Singapore Securities Association Trading Restriction Announcements',
  'Japan Fair Trade Commission Trail Decisions',
  'Japan Fukuoka Stock Exchange Alerts',
  'Japan Nagoya Stock Exchange Rules Violations',
  'Japan Securities and Exchange Surveillance Commission Inspection Results',
  'Japan National Police Agency Suspected Abduction Cases',
  'Japan Financial Services Association Membership Disposal',
  'Japan Securities and Exchange Commission Court Petition',
  'Indonesia Financial Services Authority Illegal Peer to peer lending platforms',
  'Indonesia Financial Services Authority Entities Without License',
  'Japan Financial Services Agency Misleading Trade Names',
  'Japan Financial Services Agency Misleading Names under Business Trust Act',
  'Japan Fair Trade Commission Antitrust Legal Measures',
  'China Ministry of Housing and Urban-Rural Development Administrative Decisions',
  'China Ministry of Ecology and Environment Punishments for Environmental Violations',
  'China National Bureau of Statistics Dishonest Corporations',
  'Japan Securities and Exchange Commission Criminal Investigations',
  'China National Bureau of Statistics Administrative Penalties',
  'China Supreme People\'s Court Administrative Penalties',
  'India Central Vigilance Commission Penalties for Prosecution',
  'China Credit Bureau Seriously Untrustworthy Debtors in the Financial Sector',
  'China Securities Association Self Disciplinary Measures',
  'China Shanghai Stock Exchange Bond Disciplinary Actions',
  'China Shenzhen Stock Exchange Regulatory Measures',
  'China Shenzhen Stock Exchange Disciplinary Actions',
  'China Banking and Insurance Regulatory Commission Administrative Supervision Measures',
  'China National Medical Products Administration False Advertising Businesses',
  'China Securities Regulatory Commission Illegal OTC Funding Platforms',
  'China Ministry of Housing and Urban-Rural Development Penalty Notification',
  'China Shanghai Stock Exchange Trading Suspensions',
  'China Banking and Insurance Regulatory Commission Administrative Penalties',
  'Republic of Korea Financial Supervisory Service Inspection Enforcements',
  'Philippines Insurance Commission Companies Operating Illegally',
  'Anguilla Investor Alerts',
  'Anguilla Unlicensed Entities',
  'Bahamas Central Bank Warning Notices',
  'Bahamas Royal Bahamas Police Force Most Wanted',
  'Bahamas Securities Commission Enforcements',
  'Belize International Financial Services Commission Warning Notices',
  'Cayman Islands Monetary Authority Enforcement Actions',
  'Costa Rica General Superintendency of Insurance Penalties Applied',
  'Jamaica Constabulary Force Most Wanted',
  'Trinidad and Tobago Financial Intelligence Unit Court Orders Obtained',
  'Trinidad and Tobago Securities and Exchange Commission Contraventions',
  'Trinidad and Tobago Securities and Exchange Commission Investor Alerts',
  'Trinidad and Tobago Financial Intelligence Unit Charges for Money Laundering',
  'Jamaica Fair Trading Commission Consent Agreements',
  'Albania State Police Wanted (Suspended)',
  'Austria Bundesministerium fur Innern Most Wanted',
  'Austria Bundesministerium fur Innern Wanted',
  'Austria Financial Market Authority Sanctions',
  'Austria Financial Markets Authority Investor Warnings',
  'Austria Ministry of Finance Fictitious Companies',
  'Belgium Competition Authority Decisions',
  'Belgium Federal Police Most Wanted Convicted Fugitives',
  'Belgium Federal Police Wanted',
  'Bosnia and Herzegovina Federal Police Wanted',
  'Bosnia and Herzegovina Ministry of Interior of Sarajevo Canton Wanted',
  'Bulgaria Ministry of Interior Wanted',
  'Bulgaria Financial Supervision Commission Notifications',
  'Bulgaria Financial Supervision Commission Trading Suspensions and Delistings',
  'Bulgaria National Bank Administrative Penalties',
  'Bulgaria Commission for Combating Corruption and Withdrawal of Criminal Assets Decisions of non-compliance',
  'Bulgaria Commission for Protection of Competition Penalties',
  'Croatia National Police Most Wanted',
  'Croatia Financial Services Supervisory Agency Warnings',
  'Croatia Financial Services Supervisory Agency Penalty Decisions',
  'Croatia Competition Agency Decisions',
  'Cyprus Betting Authority Warnings',
  'Cyprus Central Bank Administrative Measures',
  'Cyprus Consumer Protection Service Administrative Decisions',
  'Cyprus Police Wanted (Suspended)',
  'Cyprus Securities and Exchange Commission',
  'Czech Republic Office for the Protection of Competition (Suspended)',
  'Czech National Bank Warnings',
  'Denmark Finanstilsynet Foreign Regulators Warnings',
  'Estonia Internal Security Service Judicial Decisions',
  'Estonia Police Most Wanted Persons',
  'Finland Financial Supervisory Authority Warnings from Foreign Regulators',
  'Estonia FinantsInspektsioon Warnings',
  'Finland Financial Supervisory Authority Warnings concerning unauthorized providers',
  'France AMF Crypto Assets Websites Blacklist',
  'France AMF Forex Blacklist',
  'France AMF High Value Goods Non-Authorised Websites Blacklist',
  'France AMF non-authorized binary options websites blacklist',
  'France AMF Sanctions Commission Decisions',
  'France AMF Warnings',
  'France Prudential Supervision and Resolution Authority Disciplinary Actions',
  'Germany Bundesamt für Verfassungsschutz Prohibited Foreigner Extremist Organisations',
  'Germany Bundesamt für Verfassungsschutz Prohibited Islamist Organisations',
  'Germany Bundesamt für Verfassungsschutz Prohibited Rightwing Extremist Organisations',
  'Germany Bundesanstalt fur Finanzdienstleistungsaufsicht Administrative Measures',
  'Germany Bundesanstalt fur Finanzdienstleistungsaufsicht Unauthorized Businesses',
  'Germany Bundesanstalt fur Finanzdienstleistungsaufsicht Unauthorized Businesses 2',
  'Germany Bundeskriminalamt Wanted Persons',
  'Germany State Internal Ministries Prohibited Rightwing Extremist Organisations',
  'Greece Hellenic Capital Market Commission',
  'Hungary National Bank Warnings',
  'Hungary Police Most Wanted',
  'Ireland Central Bank Enforcement Actions',
  'Ireland Central Bank Warnings',
  'Ireland Revenue Commissioners Cash seizures',
  'Ireland Revenue Commissioners Prosecutions',
  'Italy Central Bank Enforcements',
  'Italy Central Bank Unauthorized Entities',
  'Italy Central Directorate of the Criminal Police Most Wanted',
  'Italy Italian Companies and Exchange Commission Warnings',
  'Kosovo Police Wanted Criminals',
  'Latvia Competition Council Decisions',
  'Latvia Financial and Capital Market Commission Penalties Applied for AML Breaches',
  'Latvia Financial and Capital Market Commission Warnings',
  'Lithuania Central Bank Non-licensed entities',
  'Lithuania Central Bank Websites offering illegal financial services',
  'Lithuania Police Most Wanted',
  'Luxembourg Commission de Surveillance du Secteur Financier Warnings',
  'Luxembourg CSSF Administrative Sanctions',
  'Luxembourg Most Wanted',
  'Malta Financial Intelligence Unit Court Orders',
  'Malta Financial Intelligence Unit Administrative Penalties',
  'Malta Financial Services Authority Administrative Penalties',
  'Malta Financial Services Authority Warnings',
  'Malta Gaming Authority Unauthorised Websites',
  'Netherlands Authority for the Financial Markets Warnings',
  'Netherlands Authority for the Financial Markets Warnings from other regulators',
  'Netherlands Central Bank Enforcements and Administrative Sanctions',
  'Aruba Centrale Bank von Aruba Warnings',
  'Netherlands Police Most Wanted',
  'Netherlands Gaming Authority Enforcement Decisions',
  'Netherlands Authority for Consumers and Markets Warnings',
  'Netherlands Authority for Financial Markets Measures Imposed',
  'Norway Finanstilsynet Warnings',
  'Norway Finanstilsynet Warnings from foreign regulators',
  'Norway Norges Bank Observation and exclusion of companies from pension fund portfolio',
  'Poland Office of Competition and Consumer Protection Warnings',
  'Poland Financial Supervision Authority Warnings',
  'Poland General Inspector of Financial Information Administrative Enforcements',
  'Poland Police Most Wanted',
  'RO-Romania Most Wanted List',
  'Romania General Police Inspectorate Wanted',
  'Romania Authority for Financial Supervision Insurance Market Alerts from other Regulators',
  'Romania National Bank Penalties on Banking institutions',
  'Romania National Integrity Agency Conflicts of Interest',
  'Serbia Securities and Exchange Commission Public Censure',
  'Slovakia Office for Public Procurement Penalties applied',
  'Slovakia National Bank Warnings',
  'Slovakia National Bank Final Penalty Decisions',
  'Slovakia Ministry of Interior Wanted Persons',
  'Slovenia Competition Authority Decisions',
  'Slovenia Police Most Wanted',
  'Slovenia Securities Market Agency Measures Imposed',
  'Slovenia Securities Market Agency Warnings',
  'Slovenia Central Bank Disclosure of Measures imposed',
  'Bank of Spain Sanctions',
  'Spain CNMV Unauthorised Entities and Warnings',
  'Spain CNMV Unauthorised Entities from foreign regulators',
  'Spain CNMV Warnings from foreign regulators',
  'Spain General Directorate of Insurance and Pension Funds Non-authorized entities',
  'Spain Guardia Civil Most Wanted',
  'Spain National Securities Market Commission Enforcements',
  'Sweden Finansinspektionen Investor Alerts',
  'Sweden Competition Authority Decisions',
  'Sweden Financial Supervisory Authority Administrative Enforcements',
  'Switzerland Federal Office of Justice Extraditions',
  'Switzerland FINMA companies insolvency',
  'Switzerland FINMA companies placed under investigative agent',
  'Switzerland FINMA final rulings under Art. 34',
  'Switzerland FINMA Unauthorized Services Warnings List',
  'Gibraltar Supreme Court Decisions',
  'Gibraltar Warnings',
  'Isle of Man Public Warnings',
  'Jersey Financial Services Commission Public Statement List (Suspended)',
  'United Kingdom Jersey Financial Commission Public Statements',
  'United Kingdom Financial Conduct Authority Final Notices',
  'United Kingdom Financial Conduct Authority Warnings',
  'United Kingdom Crimestoppers Most Wanted',
  'United Kingdom National Crime Agency Most Wanted',
  'British Virgin Islands Financial Commission Enforcement',
  'Cayman Islands Fraudulent Websites',
  'United Kingdom HM Revenue & Customs Deliberate Tax Defaulters',
  'United Kingdom Financial Conduct Authority Unauthorized Binary Options',
  'United Kingdom Gambling Commission Regulatory Sanctions Register- Individuals',
  'United Kingdom Gambling Commission Regulatory Sanctions Register: Companies',
  'Guernsey Financial Services Commission Disqualified Directors',
  'Guernsey Financial Services Commission Prohibitions',
  'United Kingdom HM Treasury Monetary Penalties',
  'United Kingdom Scotland Police Most Wanted',
  'United Kingdom Serious Fraud Office Cases',
  'United Kingdom Takeover Panel Cold-Shouldering',
  'United Kingdom Unauthorized Internet Banks (Suspended)',
  'United Kingdom England and Wales Court of Appeal Criminal Division',
  'Czech Republic National Police Wanted',
  'Greece Hellenic Gaming Commission Blacklist',
  'Greece Hellenic Gaming Commission Administrative Penalties',
  'North Macedonia Ministry of Internal Affairs Most Wanted',
  'Ukraine National Bank Institutions in reorganization',
  'Belarus Ministry of Tax and Duties High Risk Organisations and Entrepreneurs',
  'Belarus Ministry of Interior Most Wanted',
  'Liechtenstein Financial Market Authority Warnings',
  'Ukraine Security Service Wanted',
  'Andorra Financial Authority Sanctions to Supervised Entities',
  'Moldova National Anticorruption Center Sentences (Suspended)',
  'Moldova Police Wanted',
  'Moldova Border Police Wanted',
  'Monaco Commission for the Control of Financial Activities Warnings',
  'Montenegro Agency for Prevention of Corruption Conflict of Interest Decisions',
  'Montenegro Agency for Protection of Competition Decisions',
  'Hungary Police Arrest Warrants',
  'Hungary National Bank Warnings from foreign regulators',
  'Italy Aribtro Bancario Finanziario Defaulting Intermediaries',
  'Finland Competition and Consumer Protection Authority Prohibitions and Commitments in Competition Matters',
  'Finland Nasdaq Helsinki Disciplinary Committee Decisions',
  'Czech National Bank Penalty Decisions',
  'Ukraine Securities and Stock Market Commission Enforcement Actions',
  'Denmark Finanstilsynet Warnings against illegal business',
  'France AMF Other Blacklists',
  'United Kingdom National Crime Agency Active Ancillary Orders',
  'Denmark Finanstilsynet Supervisory reactions',
  'United Kingdom Financial Conduct Authority Cancellation Notices',
  'United Kingdom Insolvency Service Insolvency practitioner enforcements',
  'United Kingdom HM Revenue & Customs List of Businesses not Complying with AML Regulations',
  'United Kingdom Gambling Commission Enforcements',
  'United Kingdom Prudential Regulation Authority Enforcements',
  'Switzerland Comlot Blacklist',
  'Switzerland Federal Gaming Board Blocked List',
  'France General Directorate for Competition, Consumer Affairs and Fraud Prevention Injunctions for anti-competitive practices',
  'France High Council of the statutory auditor Decisions of regional discipline commissions',
  'France General Directorate for Competition, Consumer Affairs and Fraud Prevention Late payment Enforcements',
  'France General Directorate for Competition, Consumer Affairs and Fraud Prevention Protection of consumers Enforcements',
  'France High Council of the statutory auditor Decisions of the High Council',
  'Germany Bundeskartellamt Fines',
  'Poland Audit Oversight Agency Administrative Penalties',
  'Poland Court of Competition and Consumer Protection Decisions',
  'Poland Ministry of Finance Register of Illegal Gambling Domains',
  'Poland Office of Competition and Consumer Protection President Decisions',
  'Poland Financial Supervisory Authority Penalties Imposed',
  'Belgium Federal Public Service Economy Untrustworthy Telemarketers and Debt Collection Companies',
  'Belgium Gaming Commission Prohibited Gaming Sites',
  'Belgium Financial Services and Markets Authority Administrative Settlements',
  'Belgium Financial Services and Markets Authority Unlawful Companies',
  'Belgium National Chamber of Judicial Officers Internet Scammers',
  'Belgium Financial Services and Markets Authority Consumer Warnings',
  'Belgium Financial Services and Markets Authority Warnings from foreign authorities',
  'Netherlands Intelligence and Investigation Service of the Ministry of Social Affairs and Employment Fair Work Violations',
  'Netherlands Bar Association Suspended or Banned Lawyers',
  'Spain The Accounting and Auditing Institute Sanctions Imposed',
  'Spain Madrid Stock Exchange Trading Exclusions',
  'Spain Barcelona Stock Exchange Trading Exclusions',
  'Ireland Central Bank Revocation Notices',
  'Ireland Property Services Regulatory Authority Enforcements',
  'Ireland Central Bank Trade Suspensions',
  'Ireland Competition and Consumer Protection Commission Convictions',
  'Ireland Law Society of Ireland Solicitors Misconduct Cases',
  'Ireland Chartered Accountants Disciplinary decisions',
  'Denmark Business Authority Decisions',
  'Denmark Business Authority Personal Data Security Breaches',
  'Denmark Maritime Authority Detained Ships',
  'Paris Memorandum of Understanding Banned Vessels',
  'Denmark Finanstilsynet Police Reports',
  'Denmark Bar and Law Society Decisions against lawyers',
  'Denmark Competition and Consumer Authority Fines',
  'Norway Gaming Authority Unlicensed Companies',
  'Norway Consumer Agency Decisions',
  'Norway Control Committee for Fundraising Warnings',
  'Norway Securities Dealers Association Warnings',
  'Norway Securities Dealers Association Complaints',
  'Sweden Gambling Authority Banned Companies',
  'Austria Federal Competition Authority Fines',
  'Sweden National Pension Fund Exclusion List',
  'Sweden Financial Supervisory Authority Market Abuses',
  'Sweden Consumer Agency Cases, Judgements and Injunctions',
  'Sweden Trade Federation Warning List',
  'Ukraine State Fiscal Service Suspects',
  'Ukraine Department of Notary and State Registration Revocations',
  'Denmark Immigration Service Religious Preachers Entry Ban',
  'Azerbaijan Most Wanted',
  'Iraq Central Bank USD Market Blacklist',
  'Israel Antitrust Authority (Suspended)',
  'Pakistan Federal Investigation Agency Most Wanted Human Traffickers and Cyber Criminal',
  'Pakistan Competition Authority Decisions (Suspended)',
  'Saudi Arabia Washington Embassy (Suspended)',
  'Dubai Financial Services Authority',
  'Israel Ministry of Justice Illegal Online Gambling',
  'Israel Bank of Israel Money Laundering Enforcements',
  'Turkey Capital Markets Board Transactions Prohibited List',
  'Turkey Public Procurement Institution Prohibitions',
  'Turkey Banking Regulation and Supervision Agency Revoked Permit Organisations',
  'United Arab Emirates Securities and Commodities Authority Violations',
  'United Arab Emirates Dubai Financial Services Authority Regulatory Actions',
  'United Arab Emirates Securities and Commodities Authority Warnings',
  'Morocco Financial Markets Authority Penalties',
  'Saudi Arabia General Directorate Investigation Most Wanted',
  'Israel Securities Authority Financial Penalties on Mutual Funds',
  'Israel Securities Authority Financial Penalties on Investment Advisors and Portfolio Managers',
  'Israel Securities Authority Financial Penalties on Trading Platforms',
  'Israel Securities Authority Financial Penalties on Public Companies',
  'Israel Securities Authority Investment Consulting Disciplinary Committee Decisions',
  'Israel Securities Authority Financial Penalties on ETFs',
  'United Arab Emirates Financial Services Regulatory Authority Regulatory Actions',
  'United Arab Emirates Financial Services Regulatory Authority Regulatory Alerts',
  'Egypt General Organization for Export and Import Control Suspended Import Companies',
  'Canada British Columbia Securities Commission Cease Trade Orders',
  'British Columbia Securities Commission Denied Persons List (Suspended)',
  'Canada Calgary Crime Stoppers Wanted',
  'Canada Alberta Securities Commission Decisions and Orders',
  'Canada Border Services Agency Wanted',
  'Canada British Columbia Securities Commission List',
  'Canada IIROC Enforcement',
  'Canada IIROC Unpaid Fines',
  'Canada Investment Industry Regulatory Organisation',
  'Canada Ontario Financial Services Commission',
  'Canada Quebec Chambre De La Securite Financiere',
  'Canada Royal Canadian Mounted Police Wanted',
  'Canada Revenue Agency Enforcement notifications',
  'Canada Revenue Agency Revoked Charities',
  'Canadian Securities Administrators Disciplined List',
  'Canadian Securities Commission Investor Alerts',
  'Canada OSFI Warnings Notices',
  'Decisions Bureau de décision et de révision Quebec (Suspended)',
  'Canada Edmonton Police Service Most Wanted',
  'Canada Ontario Financial Services Commission Enforcement Activities',
  'Canada Financial Transactions and Reports Analysis Center Administrative Monetary Penalties',
  'Government of Canada Public notice of administrative monetary penalties Historical',
  'Canada Insurance Councils of Saskatchewan Disciplinary Decisions',
  'Manitoba Securities Commission Enforcement Orders Exceptions (Suspended)',
  'Canada Montreal Exchange Disciplinary Decisions',
  'New Brunswick Financial and Consumer Services Tribunal',
  'Canada Nova Scotia Securities Commission Enforcement Procedings',
  'Ontario Provincial Police',
  'Canada Ontario Securities Commission',
  'Canada Peel Regional Police Most Wanted',
  'Canada Saskatchewan Financial Services Commission Cease Trade Orders',
  'Secretariat of the Council of the Treasury of Quebec',
  'Toronto Most Wanted',
  'British Columbia Securities Commission Cease Trade Orders (Suspended)',
  'Canada CSA Disciplined Persons',
  'Canada British Columbia Insurance Council',
  'Mexico Banco de Mexico Penalties to Financial Intermediaries',
  'Mexico Comision Nacional Bancaria y de Valores Sanctions on Moneychangers',
  'Mexico Comision Nacional Seguros Finanzas Other Supervised Entities',
  'Mexico Comision Nacional Seguros Finanzas Sanctioned Bond Institutions',
  'Mexico Comision Nacional Seguros Finanzas Sanctioned Insurance Agents',
  'Mexico Comision Nacional Seguros Finanzas Sanctioned Insurance Institutions',
  'Mexico Comision Nacional Seguros Finanzas Sanctioned Reinsurance Intermediaries',
  'Mexico Procuradoria General Most Wanted',
  'Mexico Servicio de Administracion Tributaria Definitive List Organisations',
  'Mexico Servicio de Administracion Tributaria Definitive List Persons',
  'Bermuda Monetary Authority Warnings',
  'Bermuda Monetary Authority Enforcement Actions',
  'United States District of Columbia Excluded Parties List Warning',
  'FBI Most Wanted',
  'Guantanamo Bay Detainees',
  'NYSE Non Compliant Issuers',
  'Sanctioned USA Alabama Dept of Corrections 2 (Suspended)',
  'United States Department of Housing and Urban Development Limited Denials of Participation',
  'United States Department of State Consent Agreements',
  'United States Alabama Baldwin and Etowah County Inmates',
  'United States Alabama Calhoun County Inmates',
  'United States Alabama Cherokee and Franklin County Inmates (Suspended)',
  'United States Alabama Fayette County Inmates and Sex Offenders',
  'United States Commodity Futures Trading Commission Red List',
  'United States Department of Justice Foreign Corrupt Practices Act Related Enforcements Organisations',
  'United States Department of Justice Foreign Corrupt Practices Act Related Enforcements Persons',
  'United States Department of State War Crimes Rewards Program Fugitives from Justice',
  'United States Environmental Protection Agency Fugitives',
  'United States FDIC Enforcements',
  'United States Federal Reserve Prohibition from Banking',
  'United States FinCen Enforcement for Failure to Register as a Money Services Business',
  'United States FINRA Disciplined Firms Taping Rule',
  'United States National Credit Union Administration Enforcements',
  'United States OCC Enforcement Banks',
  'United States OCC Enforcement Companies',
  'United States OCC Enforcement Individuals',
  'United States SEC Fictitious Governmental Agencies Archive',
  'United States SEC Fictitious Governmental Agencies Main List',
  'United States SEC PAUSE Impersonators Main List',
  'United States Air Force Fugitives',
  'United States Arizona Department of Insurance Organisations',
  'United States Army Wanted Fugitives and Deserters',
  'United States Bureau of Alcohol Tobacco Firearms and Explosives Commission Most Wanted',
  'United States Drug Enforcement Agency Fugitives',
  'United States Immigration and Customs Wanted',
  'United States Marshals Wanted',
  'United States Office of the Comptroller of the Currency (Suspended)',
  'United States Ohio Department of Corrections Wanted',
  'United States Pennsylvania General Services Department Debarment List Persons',
  'United States Postal Inspection Service Most Wanted',
  'United States Redding Most Wanted (Suspended)',
  'United States Securities and Exchange Commission Unregistered Soliciting Entities',
  'United States Individuals Barred by FINRA',
  'United States Alabama Department of Corrections 3 (Suspended)',
  'United States FINRA Disciplinary Actions Online',
  'United States Alabama Dept of Corrections 1 (Suspended)',
  'United States Florida Department of Corrections Absconders and Escapees',
  'United States SEC Litigation Releases',
  'United States Treasury FinCEN Enforcement Actions',
  'United States Office of the Comptroller of the Currency Unauthorized Banks Alerts',
  'United States Commodity Futures Trading Commission Enforcement Actions Persons',
  'United States Commodity Futures Trading Commission Enforcement Actions Organisations',
  'United States Department of Treasury Office of Foreign Assets Control Advisory on Sanctions Risks related to Syria Shipping',
  'United States CME Group Disciplinary Actions Organisations',
  'United States Department of Health and Human Services Office of the Inspector General Fugitives and Convicts',
  'United States Public Company Accounting Oversight Board Enforcements Organisation',
  'United States Public Company Accounting Oversight Board Enforcements Person',
  'United States Department of Justice Securities and Financial Fraud Persons',
  'Puerto Rico Police Most Wanted',
  'United States CME Group Disciplinary Actions Persons',
  'United States Department of Justice Securities and Financial Fraud Organisations',
  'United States Narcotics Rewards Program Wanted List',
  'United States Federal Reserve Board Enforcement Actions Organisation',
  'United States Federal Reserve Board Enforcement Actions Person',
  'United States Federal Maritime Commission Enforcements',
  'United States Occupational Health and Safety Administration Enforcements',
  'United States Office of the Comptroller of the Currency Unauthorized Banks Alerts Archive',
  'United States Department of Treasury Office of Foreign Assets Control Guidance on Addressing North Korea Illicit Shipping Practices',
  'United States Securities and Exchange Commission Administrative Proceedings - person',
  'United States Securities and Exchange Commission Administrative Proceedings - organisation',
  'United States Minessota Department of Corrections Most Wanted',
  'United States District of Columbia Department of Corrections Most Wanted',
  'United States Rhode Island Top 10 Wanted',
  'United States Massachusetts Department of Correction Most Wanted',
  'United States New York State Police Most Wanted',
  'United States Delaware Crimestoppers',
  'United States Washington State Patrol Most Wanted',
  'United States Florida Highway Patrol Most Wanted Fugitives',
  'United States Louisiana Department of Justice Most Wanted',
  'United States Missouri State Highway Patrol Most Wanted',
  'United States Kansas Bureau of Investigation Most Wanted',
  'United States Tennessee Bureau of Investigation Most Wanted',
  'United States California LAPD Most Wanted',
  'United States California San Francisco Most Wanted',
  'United States Illinois State Police Most Wanted',
  'United States New Jersey State Police Most Wanted',
  'United States Nevada Police Most Wanted',
  'United States Rhode Island Sex Offenders Level II',
  'United States Rhode Island Sex Offenders Level III',
  'United States Maryland Department of Public Safety and Correctional Services Most Wanted',
  'United States Delaware Department of Correction Fugitives',
  'United States Washington Department of Correction Active Warrants',
  'United States Arkansas Department of Corrections Escapees',
  'United States Georgia Department of Corrections Most Wanted Fugitives',
  'United States Louisiana Department of Corrections Most Wanted',
  'United States Missouri Information Analysis Center Most Wanted',
  'United States Tennessee Department of Corrections Most Wanted',
  'United States South Carolina Department of Probation, Parole and Pardon Services Most Wanted',
  'United States California Los Angeles Probation Most Wanted',
  'United States Indiana Department of Corrections Warrants',
  'United States Alabama Department of Corrections Escapees',
  'United States Texas 10 Most Wanted Sex Offenders',
  'United States Texas Fugitives Still Wanted',
  'United States Texas Department of Criminal Justice Most Wanted',
  'United States Michigan Department of Corrections Wanted Escapes',
  'United States Nevada Department of Corrections Escapees',
  'United States Ohio Department of Rehabilitation and Correction Most Wanted',
  'United States West Virginia Department of Corrections Escapees and Absconders',
  'United States Alabama Police Most Wanted',
  'United States Idaho Department of Correction Most Wanted',
  'United States South Dakota Department of Correction Escapees and Walk Aways',
  'United States South Dakota Department of Correction Parole Absconders',
  'United States Oklahoma Department of Corrections Fugitives',
  'United States South Carolina Department of Corrections Escapees',
  'United States Minnesota Predatory Offender Registration',
  'United States North Carolina Department of Public Safety Escaped Inmates',
  'United States Pennsylvania Crime Stoppers Warrants',
  'United States Kansas Department of Corrections Absconders',
  'United States Iowa Most Wanted Sex Offenders (Suspended)',
  'United States Michigan Sex Offenders Wanted Absconders (Suspended)',
  'United States District of Columbia Sex Offenders',
  'United States Minessota Sex Offenders',
  'United States Minessota Sex Offenders with Unknown Address',
  'United States Missouri State Highway Patrol Absonded Sex Offenders',
  'United States New York Department of Financial Services Enforcement Actions organisations',
  'United States New York Department of Financial Services Enforcement Actions persons',
  'United States New York Department of Financial Services Mortgage Enforcement Actions organisations',
  'United States New York Department of Financial Services Mortgage Enforcement Actions persons',
  'United States Delaware Department of Insurance Enforcement Actions and Fines',
  'United States Connecticut Insurance Department Enforcement Actions',
  'United States Wyoming Non-compliant Sex Offenders',
  'United States Connecticut Non-compliant Sex Offenders',
  'United States Delaware Fraud and Consumer Protection Division Administrative and Director Orders',
  'United States Washington Department of Financial Institutions Securities Fraud Alerts',
  'United States Washington Office of the Insurance Commissioner Insurance Fraud Most Wanted',
  'United States New Hampshire Sex Offenders',
  'United States New Jersey Department of Banking and Insurance Insurance Division Companies in Rehabilitation/Liquidation',
  'United States Michigan Department of Insurance and Financial Services Consumer Finance Prohibitions',
  'United States Michigan Department of Licensing and Regulatory Affairs Disciplinary Actions',
  'United States California Department of Insurance Enforcement Actions persons',
  'United States California Department of Insurance Enforcement Actions organisations',
  'United States Wyoming Secretary of State Securities Enforcements',
  'United States District of Columbia Department of Insurance, Securities and Banking Insurance Administrative Actions Organisations',
  'United States District of Columbia Department of Insurance, Securities and Banking Insurance Administrative Actions Persons',
  'United States District of Columbia Department of Insurance, Securities and Banking Securities Administrative Actions Organisations',
  'United States District of Columbia Department of Insurance, Securities and Banking Securities Administrative Actions Persons',
  'United States District of Columbia Department of Insurance, Securities and Banking Banking Administrative Actions',
  'United States Iowa Insurance Division Enforcement Orders and Actions',
  'United States Massachusetts State Police Most Wanted',
  'United States Massachusetts State Police Sex Offender Most Wanted',
  'United States New York Department of Corrections Most Wanted',
  'United States Florida Department of Law Enforcement Sex Offenders',
  'United States Tennessee Bureau of Investigation Wanted Sex Offenders',
  'United States Pennsylvania Non-compliant Sex Offenders',
  'United States Texas Department of Public Safety Captured Fugitives',
  'United States District of Columbia Courts Active Warrant List',
  'United States Oklahoma Sex Offenders',
  'United States New Jersey Non-Compliant Sex Offenders',
  'United States Alaska Non-Compliant Sex Offenders',
  'United States Idaho State Police Violent Sexual Predators',
  'United States Idaho State Police Non-compliant Sex Offenders',
  'United States North Dakota Deliquent Sex Offenders',
  'United States Utah Non-compliant Sex Offenders',
  'United States Maine Drug Enforcement Agency Fugitives',
  'United States New Mexico Non-compliant Sex Offenders',
  'United States Ohio Non-compliant Sex Offenders',
  'United States Delaware Wanted Sex Offenders',
  'United States Alaska Sex Offenders',
  'United States Department of Justice Foreign Agents Registration Act recent cases',
  'United States Vermont Sex Offenders List',
  'United States Tennessee Department of Commerce and Insurance Securities Division Enforcement Actions persons',
  'United States Tennessee Department of Commerce and Insurance Securities Division Enforcement Actions organisation',
  'United States New Hampshire Insurance Department Enforcement Actions person',
  'United States New Hampshire Insurance Department Enforcement Actions organisation',
  'United States South Dakota Department of Labor and Regulation Division of Insurance Administrative Actions',
  'United States Nebraska Department of Insurance Legal Actions Against Companies',
  'United States Nebraska Department of Insurance Legal Actions Against Agencies and Agents',
  'United States Idaho Department of Finance Administrative Actions',
  'United States Indiana Department of Insurance Enforcement Actions',
  'United States South Carolina Attorney General Securities Division Indictments',
  'United States Alaska Department of Insurance Fraud Convictions',
  'United States South Dakota Department of Labor and Regulation Division of Insurance Securities Administrative Actions',
  'United States New Jersey Department of Banking and Insurance Insurance Division Enforcement Actions Organisations',
  'United States Nevada Department of Business and Industry Enforcement Actions',
  'United States West Virginia State Auditors Office Cease and Desist Orders Persons',
  'United States West Virginia State Auditors Office Cease and Desist Orders Organisations',
  'United States Wisconsin Office of the Commissioner of Insurance Administrative Actions',
  'United States Connecticut Department of Banking Enforcement Actions organisations',
  'United States West Virginia Division of Financial Institutions Administrative Actions',
  'United States Colorado Department of Regulatory Agencies Securities Division Enforcement Actions persons',
  'United States Colorado Department of Regulatory Agencies Securities Division Enforcement Actions organisations',
  'United States Michigan Department of Insurance and Financial Services Bank and Credit Union Prohibitions and Removals',
  'United States Michigan Department of Insurance and Financial Services Final Decisions - Persons',
  'United States Maine Office of Securities Enforcement Measures Persons',
  'United States Maine Office of Securities Enforcement Measures Organisations',
  'United States Maine Bureau of Insurance Disciplinary Measures',
  'United States Utah Insurance Department Most Wanted',
  'United States Pennsylvania Insurance Department Enforcement Actions Persons',
  'United States Pennsylvania Insurance Department Enforcement Actions Organisations',
  'United States New Jersey Department of Banking and Insurance Insurance Division Fraud Deterrence Enforcement Actions',
  'United States Utah Insurance Department Administrative Actions Organisations',
  'United States Utah Insurance Department Administrative Actions Persons',
  'United States Pennsylvania General Services Department Debarment List Organisations',
  'United States Arizona Department of Insurance Persons',
  'United States Rhode Island Department of Business Regulation Enforcements Persons',
  'United States Mississippi Secretary of State Securities Division Enforcements Organisations',
  'United States Mississippi Secretary of State Securities Division Enforcements Persons',
  'United States Maine Bureau of Consumer Credit Protection Disciplinary Measures Organisations',
  'United States Maine Bureau of Consumer Credit Protection Disciplinary Measures Persons',
  'United States Louisiana Office of Financial Institutions Securities Division Criminal Prosecutions',
  'United States Colorado Department of Regulatory Agencies Banking Consumer Alerts',
  'United States Alabama State Banking Department Administrative Actions',
  'United States North Carolina Banking Commission Enforcement Actions',
  'United States South Carolina Department of Insurance Enforcement Orders',
  'United States Nebraska Department of Banking and Finance Administrative Actions Organisations',
  'United States Nebraska Department of Banking and Finance Administrative Actions Persons',
  'United States Tennessee Department of Commerce and Insurance Disciplinary Actions on Insurance Agents and Producers',
  'United States Missouri Division of Finance Removal and Prohibition Orders',
  'United States Indiana Secretary of State Securities Division Administrative Actions (Suspended)',
  'United States Illinois Department of Financial and Professional Regulation Enforcement Actions Financial Institutions',
  'United States Kentucky Department of Financial Institutions Other Enforcement Actions Organisations',
  'United States Kentucky Department of Financial Institutions Other Enforcement Actions Persons',
  'United States Missouri Department of Insurance Enforcement Actions',
  'United States Arkansas Securities Department Enforcements Organisations',
  'United States Arkansas Securities Department Enforcements Persons',
  'United States Arizona Department of Public Safety Sex Offenders',
  'United States Arkansas Insurance Department Legal Orders',
  'United States Ohio Department of Commerce Enforcement Actions',
  'United States Connecticut Department of Banking Enforcement Actions persons',
  'Mexico CONDUSEF National Commission for the Protection and Defense of Users of Financial Services',
  'Mexico Comision Nacional de Seguros Y Fianzas - Sanciones a personas que intermediaron sin autorización',
  'United States Colorado Wanted Sex Offenders',
  'Canada London Ontario Police Wanted',
  'Canada London Ontario Police Most Wanted',
  'Canada New Brunswick Financial and Consumer Services Commission Caution List',
  'Canada Police of Quebec Most Wanted',
  'Canada British Columbia Financial Services Authority Mortgage Brokers Enforcements',
  'United States National Futures Association Disciplinary Actions',
  'Canada British Columbia Financial Services Authority Financial Institutions Enforcements',
  'Canadian Securities Administrators Cease Trade Orders',
  'United States Office of Foreign Assets Control Civil Penalties Enforcement Actions',
  'United States Montana Board of Pardons and Parole Absconders and Escapees',
  'United States Indiana Secretary of State Auto Dealer Services Division Administrative Actions',
  'United States Colorado Department of Corrections Absconders Walkaways and Escapees',
  'United States Illinois Department of Insurance Fraud Convictions',
  'United States SEC Trade Suspensions',
  'United States Securities and Exchange Commission Stop Orders',
  'United States Securities and Exchange Commission Accounting and Auditing Enforcement Releases',
  'United States Office of Criminal Investigations Most Wanted Fugitives',
  'United States Pennsylvania Most Wanted Absconders',
  'United States Securities and Exchange Commission Delinquent Fillings',
  'Canada Competition Bureau Penalties',
  'Canada Office of the Commissioner of Lobbying Prohibitions',
  'Mexico General Directorate of Gambling Closed Establishments',
  'Mexico National Digital Platform Sanctioned Entities',
  'Mexico National Digital Platform Sanctioned Public and Private Servants',
  'United States The Treasury Inspector General for Tax Administration Investigations',
  'United States Special Counsel\'s Office Court Cases',
  'United States DEA Diversion Control Division Criminal Cases Against Doctors',
  'United States National Indian Gaming Commission Enforcement Actions',
  'United States Office of Inspector General Department of Transportation Wanted Fugitives',
  'United States Secret Service Most Wanted',
  'United States Department of Transportation Public Interest Exclusions',
  'United States Alcohol and Tobacco Tax and Trade Bureau Administrative Actions',
  'United States Food and drug Administration Advisory Letters',
  'United States IEX Disciplinary Actions',
  'United States North American Securities Administrators Association Cryptosweep Task Force Enforcements',
  'United States Federal Energy Regulatory Commission Civil Penalties',
  'United States Immigration and Customs Enforcement Declined Detainers',
  'United States Department of Defense Communist Chinese Military Companies List',
  'United States Food and drug Administration Criminal Investigations',
  'United States Office of Natural Resources Revenue Civil Penalties',
  'United States Nasdaq Disciplinary Actions',
  'Australia ASIC Banned and Disqualified Organizations',
  'Australia ASIC Fake Regulators',
  'Australia ASIC List of Unlicensed Companies',
  'Australia AUSTRAC Civil Penalty Orders',
  'Australia AUSTRAC Infringement Notices',
  'Australia AUSTRAC Remedial Directions',
  'Australia AUSTRAC Remittance Registration Actions',
  'Australia AUSTRAC Written Notices',
  'Australia Competition and Consumer Commission Infringement Notices',
  'Australia Competition and Consumer Commission Public Warnings',
  'Australia Transaction Reports and Analysis Centre Enforceable Undertakings',
  'Australia Securities Exchange Enforcements',
  'Australia Securities and Investment Commission Enforceable Undertakings Persons',
  'Australia Securities and Investment Commission Enforceable Undertakings Organisations',
  'New Zealand Commerce Commission Enforcements',
  'New Zealand FDAC Decisions',
  'New Zealand FMA Court Cases',
  'New Zealand FMA Orders',
  'New Zealand FMA Warnings Archive',
  'New Zealand Police Wanted',
  'New Zealand Putea Matua Reserve Bank',
  'Australia Australian Prudential Regulatory Authority Enforcement Actions',
  'Australia Securities and Investment Commission Banned Bodies Corporate',
  'Australia ACT Law Society Disciplinary Actions',
  'Australia Legal Profession Conduct Commissioner Disciplinary Actions',
  'Australia Institute of Public Accountants Disciplinary Tribunal Hearings',
  'Australia Transaction Reports and Analysis Center Digital Currency Exchange Provider Registration Actions',
  'Australia Securities and Investment Commission Unlicensed Carried over Instrument Lenders',
  'Australia Office of the Registrar of Indigenous Corporations Prosecutions Outcome',
  'Australia Chartered Accountants Disciplinary Decisions',
  'Australia Charities and Not-for-profits Commission Compliance Decisions',
  'Australia Securities and Investment Commission Public Warning Notices',
  'Australia Securities and Investment Commission Summary Prosecutions',
  'Argentina Federal Police Wanted Fugitives',
  'Argentina Financial Intelligence Unit AML Non-compliance record',
  'Argentina Ministry of Justice Most Wanted',
  'Argentina National Bank Summary Proceedings',
  'Argentina National Bank Summary Proceedings Companies',
  'Argentina National Institute of Associativism and Social Economy Suspended Cooperatives',
  'Argentina National Institute of Associativism and Social Economy Suspended Mutual Funds',
  'Argentina National Securities Commission Disciplinary Resolutions',
  'Argentina National Securities Commission Warning Notes',
  'BR-National Register of Punished Companies CNEP (Suspended)',
  'Brazil - Warning - Registration of Federal Administration Expulsions (CEAF) (Suspended)',
  'Brazil Civil Police Wanted',
  'Brazil Comissao de Valores Mobiliaros Suspensions for Irregular Intermediation Organisations',
  'Brazil Comissao de Valores Mobiliaros Suspensions for Irregular Intermediation Persons',
  'Brazil Council for Financial Activities Control',
  'Brazil Ministerio Publico Federal Operation Maus Caminhos',
  'Brazil Policia Federal Most Wanted List Warnings (Suspended)',
  'Brazil Sanctions National Registry Of Ineligible And Suspended Companies (Suspended)',
  'Brazil Warnings Policia Civil Rio Grande do Sul Wanted (Suspended)',
  'Brazil Ministry of Economy Dirty List of Slave Labor',
  'Chile Investigation Police Most Wanted',
  'Chile Superindendency of Pensions Penalties registry',
  'Chile Superintendency for Gambling Penalty Decisions',
  'Colombia Autorregulador del Mercado de Valores Sanctions',
  'Peru Superintendency of Banks, Insurance and Private Pensions Penalties applied for AML breaches',
  'Peru Superintendency of Banks, Insurance and Private Pensions Unauthorized entities',
  'Peru Superintendency for Securities Markets Penalties Applied',
  'Uruguay Central Bank Register of Infringers to the Law of Checks',
  'Chile Commission for Financial Markets Warnings from Foreign regulators',
  'Brazil Banco Central Administrative Penalty Process',
  'Brazil Comissao de Valores Mobiliaros Leniency Agreements',
  'Brazil Comissao de Valores Mobiliaros Alerts on Unauthorized Activity Persons',
  'Brazil Comissao de Valores Mobiliaros Alerts on Unauthorized Activity Organisations',
  'Brazil Ministerio Publico Federal Operation Lava Jato Criminal Actions Organisations',
  'Brazil Ministerio Publico Federal Operation Lava Jato Criminal Actions Persons',
  'Brazil Comissao de Valores Mobiliaros Suspended Registrations',
  'Brazil Comissao de Valores Mobiliaros Cancelled Registrations',
  'Costa Rica General Superintendence of Securities Investor Alerts',
  'Costa Rica Judicial Investigation Organism Most Wanted',
  'Ecuador Superintendence of Banks Unauthorized Entities',
  'Ecuador Superintendence of Companies Securities and Insurance Stock Market Violations',
  'Costa Rica General Superintendence of Securities Disciplinary Measures',
  'Panama Superintendency of the Stock Market of Panama Cancelled Licenses',
  'Panama Intendance of Supervision and Regulation of Non-Financial Subjects Pecuniary and Administrative Sanctions',
  'Panama Superintendency of Banks of Panama Pecuniary Sanctions',
  'Panama Superintendency of Banks of Panama Warning List',
  'Panama Superintendency of the Stock Market of Panama Pecuniary Sanctions',
  'Panama Superintendence of Insurance and Reinsurance of Panama Pecuniary Sanctions',
  'Panama Autonomous Cooperative Institute Pecuniary Sanctions',
  'Panama Superintendency of the Stock Market of Panama Fines and Penalties',
  'Ecuador Internal Revenue Service Ghost Companies',
  'Nicaragua Superintendence of Banks and other Financial Institutions Warnings',
  'Nicaragua Superintendence of Banks Administrative Sanctions',
  'Chile Santiago Stock Exchange Resolutions',
  'Colombia National Directorate of Tax and Customs Sanctioned Entities',
  'Colombia Superindendence of Industry and Commerce Unfair Competition Decisions',
  'Brazil Ministry of Justice and Public Security Wanted',
  'Brazil Procurados Portal Wanted',
  'Brazil Stock Exchange Public Censures',
  'Brazil Bolsa BalcÃ£o S.A. Notifications and Penalties Applied',
  'United States Department of State Directorate of Defense Trade Controls AECA and ITAR Compliance Actions',
  'United States Texas 10 Most Wanted Fugitives',
  'United States New Jersey Department of Corrections Escapes',
  'United States Iowa Sex Offenders List',
  'United States Michigan Department of Insurance and Financial Services Final Decisions - Organisations',
  'United States Rhode Island Department of Business Regulation Enforcements Organisations',
  'United States Wyoming Department of Insurance Enforcement Actions',
  'United States West Virginia Sex Offenders',
  'United States Alaska Department of Insurance Orders',
  'United States Texas State Securities Board Enforcement Actions',
  'United States New Jersey Department of Banking and Insurance Insurance Division Enforcement Actions Persons',
  'United States California Department of Business Oversight Consumer Alerts Archive',
  'United States Louisiana Department of Insurance Regulatory Actions',
  'United States Illinois Department of Financial and Professional Regulation Banks, Trust Companies and Savings institutions Enforcement Actions',
  'United States Illinois Department of Financial and Professional Regulation Mortgage Banking, Pawnbrokers, Check Printers Enforcement Actions',
  'United States Illinois Department of Financial and Professional Regulation Loan Originators Enforcement Actions',
  'Vietnam Ministry of Public Security Wanted Persons',
  'Romania National Integrity Agency Asset Seizures',
  'Slovakia Antimonopoly Office Decisions',
  'Sri Lanka Securities and Exchange Commission Enforcement Actions (Suspended)',
  'Thailand Royal Police Crime Suppression Division Most Wanted',
  'Ukraine National Police Suspects in Pre-trial Investigation',
  'Ukraine Stock Market Infrastructure Development Agency Offences',
  'United Kingdom Financial Conduct Authority Fines',
  'United States Indiana Most Wanted List',
  'United States OCC Enforcement Actions List Archive til 2011',
  'United States Special Inspector General for Troubled Asset Relief Program Notable Investigations',
  'United States Hawaii Crimestoppers Honolulu',
  'Pakistan National Accountability Bureau',
  'Philippines Office of the Ombudsman Decisions since 2018',
  'Philippines Securities and Exchange Commission Cease and Desist Orders',
  'Philippines Securities and Exchange Commission Revocation orders',
  'Philippines Securities and Exchange Commission Suspension orders',
  'Philippines Securities and Exchange Commission Advisories',
  'New Zealand FMA Warnings Persons',
  'New Zealand FMA Warnings Organisations',
  'China Insurance Regulatory Commission Administrative Penalties Organisations',
  'China Securities Association Warnings',
  'China Credit Bureau Untrustworthy Supervision Companies in the Power Sector',
  'Hong Kong Monetary Authority Fraudulent Bank Websites',
  'Hong Kong Confederation of Insurance Brokers Disciplinary Actions',
  'Indonesia Corruption Eradication Commission Cases with a final decision',
  'Indonesia Corruption Eradication Commission Prosecutions',
  'Indonesia Deposit Insurance Agency Entities in Liquidation',
  'Indonesia Financial Services Authority Entities with ceased activities',
  'World Bank Debarred Firm List',
  'Ireland Revenue Defaulters List',
  'Italy Direzione Distrettuale Antimafia List of Mafia Associated Entrepreneurs',
  'Japan Ministry of Economy Trade and Industry Wmd End User List (Suspended)',
  'Lithuania State Food and Veterinary Service Unreliable Business Operators',
  'Malaysia Ministry of Tourism Revoked Umrah Licenses',
  'Malaysia Ministry of Tourism Unregistered operators',
  'Malaysia Securities Commission Cases Compounded Organisation',
  'Malaysia Securities Commission Cases Compounded Person',
  'Malaysia Securities Commission civil Actions and Regulatory Settlements organisation',
  'Malaysia Securities Commission civil Actions and Regulatory Settlements person',
  'Malaysia Securities Commission Administrative Actions Persons',
  'Malta Financial Services Authority - OBSOLETE (Suspended)',
  'Malaysia Securities Commission Administrative Actions Organisations',
  'Lithuania Gaming Control Authority Illegal Gambling Operators',
  'Lithuania Drug, Tobacco and Alcohol Control Department Blocked Online Platforms',
  'Lithuania Drug, Tobacco and Alcohol Control Department Violations',
  'Lithuania State Consumer Rights Protection Authority Breach of the Public Interest',
  'Lithuania Authority of Audit, Accounting, Property Valuation and Insolvency Management Disciplinary Penalties',
  'Philippines Securities and Exchange Commission Revoked and Suspended Lending Companies',
  'Philippines Insurance Commission Companies under CRL',
  'Philippines Central Bank Delisted pawnshops and money services businesses',
  'Sweden Gambling Authority Other Enforcement Measures',
  'Sweden Nasdaq Stockholm Disciplinary Committee Decisions',
  'Ukraine National Securities and Stock Market Commission Enforcement Actions',
  'Ukraine National Agency for Corruption Prevention Offenses',
  'Ukraine State Tax Service Cancellation of Registration',
  'Lithuania Public Procurement Office Suppliers that Provided False Information',
  'Securities Commission Malaysia Audit Oversight Board Enforcements',
  'Estonia Financial Intelligence Unit Administrative Acts and Penalties',
  'Estonia FinantsInspektsioon Investment Firms Licenses Revoked',
  'Estonia Tax and Customs Board Illegal Gambling Sites',
  'Estonia Tax and Customs Board Tax Debtors',
  'European Council Internal Terrorists',
  'Latvia Consumer Rights Protection Centre Consumer Infringement Cases',
  'Latvia Consumer Rights Protection Centre Sanctions',
  'Latvia Council of Sworn Advocates Disciplinary Sanctions',
  'Latvia Health Inspectorate Suspended Medical Institutions',
  'Latvia Lotteries and Gambling Supervision Authority Blocked Websites',
  'Latvia Lotteries and Gambling Supervision Authority Enforcements',
  'Latvia State Labour Inspectorate Non-compliant Employment Protection',
  'Latvia State Revenue Service Penalties for AML Breaches',
  'Latvia State Security Service Terrorism Cases',
  'Lithuania Authority of Audit, Accounting, Property Valuation and Insolvency Management Disciplinary Penalties',
  'Lithuania Authority of Audit, Accounting, Property Valuation and Insolvency Management Warnings',
  'Lithuania Central Bank Sanctions',
  'Lithuania Competition Authority Disqualification From Public Procurement',
  'Lithuania Public Procurement Office Suppliers that Provided False Information',
  'Lithuania Public Procurement Office Unreliable Suppliers',
  'Lithuania State Food and Veterinary Service Unreliable Business Operators',
  'Portugal Central Bank Additional Enforcement Measures',
  'Ukraine National Agency for Corruption Prevention Offenses',
  'Ukraine State Tax Service Cancellation of Registration',
  'Ukraine Stock Market Infrastructure Development Agency Offences',
]

export const FITNESS_PROBITY = [
  'African Development Bank Debarment and Sanctions Procedures',
  'Inter-American Development Bank Disciplined Firms and Individuals',
  'World Bank Debarred Firm List',
  'European Bank for Reconstruction and Development (EBRD) Ineligible Entities',
  'United Nations Development Program Ineligibility List Persons',
  'World Bank Non-responsible vendors',
  'World Bank Other Procurement Sanctions',
  'Nigeria Institute of Chartered Accountants of Nigeria Suspended Members',
  'Nigeria Institute of Chartered Accountants of Nigeria Delisted Members',
  'China Securities Regulatory Commission Market Ban Decisions',
  'Hong Kong Mandatory Provident Fund Schemes Authority Enforcement News',
  'Hong Kong Securities Futures Commission (Suspended)',
  'Malaysia Bank Negara Enforcement Actions',
  'Bank Negara Malaysia Enforcement Actions Companies Warning Letters',
  'Malaysia Bank Negara Enforcement Actions Invalid Licenses',
  'Securities Commission Malaysia Compounded Cases (Suspended)',
  'Philippines Securities and Exchange Commission (Suspended)',
  'Singapore ACRA PAOC Orders Issued after Audit',
  'Singapore Accounting and Corporate Regulatory Agency PAOC Suspensions',
  'Singapore Competition Consumer Commission Anticompetitive Behavior',
  'Singapore SGX Directors and Executives Watchlist',
  'India Ministry of Corporate Affairs List of Disqualified Directors Section 164 - Ahmedabad',
  'India Ministry of Corporate Affairs List of Disqualified Directors Section 164 Chhattisgarh',
  'India Ministry of Corporate Affairs List of Disqualified Directors Section 164 Coimbatore',
  'India Ministry of Corporate Affairs List of Disqualified Directors Section 164 Uttarakhand',
  'India Ministry of Corporate Affairs List of Disqualified Directors Section 164 Shillong',
  'India Ministry of Corporate Affairs List of Disqualified Directors Section 164 Pune',
  'India Ministry of Corporate Affairs List of Disqualified Directors Section 164 Puducherry',
  'India Ministry of Corporate Affairs List of Disqualified Directors Section 164 Vijayawada',
  'India Ministry of Corporate Affairs List of Disqualified Directors Section 164 Jammu & Kashmir',
  'India Ministry of Corporate Affairs List of Disqualified Directors Section 164 Goa',
  'India Ministry of Corporate Affairs List of Disqualified Directors Section 164 Ernakulam',
  'India Ministry of Corporate Affairs List of Disqualified Directors Section 164 Kolkata',
  'India Ministry of Corporate Affairs List of Disqualified Directors Section 164 HimachalPradesh',
  'India Ministry of Corporate Affairs List of Disqualified Directors Section 164 Jharkhand',
  'India Ministry of Corporate Affairs List of Disqualified Directors Section 164 Gwalior 2016',
  'India Ministry of Corporate Affairs List of Disqualified Directors Section 164 Jaipur',
  'India Ministry of Corporate Affairs List of Disqualified Directors Section 164 Hyderabad',
  'Russian Federation Federal Tax Service Legal entities whose executive bodies include disqualified persons',
  'India Ministry of Corporate Affairs List of Disqualified Directors Section 164 Delhi',
  'Russian Federation Ministry of Justice Register of Excluded Lawyers',
  'Russian Federation Unified Procurement Information System Register of Unscrupulous Contractors',
  'Republic of Korea Bar Association Disciplinary Actions Expulsions',
  'Republic of Korea Institute of Certified Public Accountants Disciplinary Records',
  'Singapore Accounting and Corporate Regulatory Authority Accountants not Allowed to Perform Audit',
  'Malaysia Companies Commission Revoked Auditor\'s Approval',
  'Malaysia Securities Commission Withdrawal and Suspension of Recognition',
  'Malaysia Federation of Investment Managers Disciplinary Actions',
  'Malaysia Securities Commission Revocation and Suspension of Registration',
  'China Ministry of Housing and Urban-Rural Development Revocation of Administrative Licenses',
  'China Credit Bureau Untrustworthy Persons Subject to Enforcement',
  'China Credit Bureau Illegal Overrun and Untrustworthy Party',
  'China Credit Bureau Serious Violations in the Financial Sector',
  'China Credit Bureau Illegal Fund Raising in the Financial Sector',
  'Danish Financial Services Authority (Suspended)',
  'Germany Bundesministerium fur Innern Extremist organisations under observation',
  'Germany Bundesministerium fur Innern Persons from Extremist Organisations under observation',
  'Ireland Central Bank Prohibition Notices',
  'Ireland Competition and Consumer Protection Commission Criminal Court Cases',
  'Ireland Competition and Consumer Protection Commission Criminal Court Cases Organisations',
  'Ireland Office of the Director of Corporate Enforcement Disqualifications',
  'Ireland Office of the Director of Corporate Enforcement prosecutions',
  'Ireland Revenue Defaulters List',
  'Irish Companies Registration Office (Suspended)',
  'Latvia Anti Corruption Bureau Administrative Violations',
  'Romania National Integrity Agency disqualifications',
  'Guernsey Registry Disqualified Directors',
  'FSC Disqualified Commission (Suspended)',
  'Jersey Financial Services Commission Restricted Individuals',
  'United Kingdom Financial Conduct Authority Prohibited Individuals',
  'United Kingdom Insolvency Service Disqualified Directors',
  'United Kingdom Solicitors Discipline Tribunal Persons',
  'United Kingdom Company House Disqualified Directors Register',
  'United Kingdom The Gazette Insolvencies',
  'Isle of Man Financial Services Authority Enforcement Actions',
  'Special inspector general for afghanistan reconstruction',
  'Pakistan Public Procurement Regulatory Authority Blacklisted Firms',
  'Israel Bar Association Suspended and Removed Lawyers',
  'Canada Chartered Professional Accountant of Ontario Disciplinary Decisions',
  'Canada Law Society Tribunal ',
  'Canadian Securities Commission Part 2 (Suspended)',
  'Canadian Securities Commission Part 3 (Suspended)',
  'Canadian Securities Commission Part 4 (Suspended)',
  'Canadian Securities Commission Part 5 (Suspended)',
  'Insurance Council of Manitoba Disciplinary Decisions',
  'Mutual Fund Dealers Association of Canada Enforcement Hearings',
  'Canada Manitoba Securities Commission ( Suspended)',
  'Canada Public Works and Government Services Canada Ineligible and suspended suppliers',
  'Mexico - National Banking Securities Commission (Suspended)',
  'Mexico Comision Nacional Bancaria De Valores Sanctions',
  'United States Federal Deposit Insurance Corporation Failed Bank List',
  'Fitness Probity United States Medicaid Exclusions Lists OIG LEIE',
  'United States Florida Agency for Healthcare Admin Medicaid Sanctioned Providers Organisations',
  'SEC Trading Suspensions (Suspended)',
  'United States Department of Justice Executive Office for Immigration Review Disciplined Practitioners',
  'United States FDIC Prohibition under Section 19',
  'United States Federal Reserve Section 19 Letters',
  'United States System for Award Management Exclusions excl OFAC',
  'United States  Alabama Medicaid Suspended Providers',
  'United States  Arkansas Department of Health Excluded Providers List',
  'United States  Connecticut Department of Social Services Administrative Actions List',
  'United States  Consumer Financial Protection Bureau Enforcement Actions',
  'United States  Excluded or Terminated Montana Medicaid Providers',
  'United States  FDA Clinical Investigators Disqualification Proceedings List',
  'United States  FDA Debarment List Enforcement Actions Drug Product Applications',
  'United States  Georgia Bureau of Investigation',
  'United States  Georgia OIG Exclusions List',
  'United States  Hawaii Medicaid Excluded Providers',
  'United States  KY Medicaid Termination and Exclusion List',
  'United States  Louisiana Department of Health Hospitals Exclusion List',
  'United States Maine Department of Health and Human Services Medicaid Exclusion List',
  'United States  Maryland Dept of Health Mental Hygiene Exclusions List',
  'United States  Massachusetts Health and Human Services List of Suspended or Excluded MassHealth Providers',
  'United States  Michigan Department of Community Health List of Sanctioned Providers',
  'United States  Minnesota Department of Human Services Excluded Provider Lists',
  'United States  Mississippi Gaming Commission Exclusion List',
  'United States  Missouri Department of Social Services Medicaid List of Terminated Providers',
  'United States  Nevada Gaming Control Board Excluded',
  'United States  Nevada GCB Most Wanted',
  'United States  New Jersey Office of the State Comptroller Consolidated Debarment Report',
  'United States  New York Office of the Medicaid Inspector General List of Exclusions',
  'United States  OCC Enforcement Actions List Archive til 2011',
  'United States -Chicago Board Options Exchange DIsciplinary',
  'United States Nasdaq PHLX Disciplinary Actions',
  'United States -NYSE Regulation: Disciplinary Actions',
  'United States Military Health System Excluded Providers Organisation',
  'United States Military Health System Excluded Providers Person',
  'United States Federal Housing Finance Agency Suspended Counterparty Program Person',
  'United States Federal Housing Finance Agency Suspended Counterparty Program Organisation',
  'United States Federal Trade Commission Entities Banned from Debt Relief',
  'Canada Canadian Institute of Chartered Accountants Ontario Disciplined Accountants Decisions',
  'ASIC Disqualified Organisations (Suspended)',
  'Australia ARPA Disqualifications (Suspended)',
  'Australia ASIC Notices',
  'Australia Disqualified Officers (Suspended)',
  'Fitness Probity Australia APRA Disqualification Register (Suspended)',
  'Australia Office of the Registrar of Indigenous Corporations Disqualified Directors',
  'New Zealand FMA Management Bans',
  'New Zealand Serious Fraud Office (Suspended)',
  'Australia Financial Complaints Authority Non-Compliant Members',
  'Brazil Banco Central Disqualifications',
  'Brazil Comptroller General Leniency Agreements',
  'Chile Unidad de Analisis Financiero Enforceable Sanctions',
  'Colombia Financial Superintendence (Suspended)',
  'Paraguay National Directorate of Public Procurement Penalties and Disqualifications',
  'Peru National Contractors Register Disqualifications and Fines',
  'United Kingdom Solicitors Discipline Tribunal Organisations',
  'United States FTC Banned Debt Collectors',
  'India Ministry of Corporate Affairs List of Disqualified Directors Section 164 Chennai',
  'India Ministry of Corporate Affairs List of Disqualified Directors Section 164 Chandigarh',
  'India Ministry of Corporate Affairs List of Disqualified Directors Section 164 Kanpur',
  'United States Arkansas Real Estate Commission Warnings (Suspended)',
  'US System for Award Management Exclusions (Suspended)',
  'Isle of Man Employment Decisions Tribunals (Suspended)',
  'Isle of Man Courts Ordinary Procedure (Suspended)',
  'Lithuania Chamber of Architects Professional Ethics Decisions',
  'Lithuania Authority of Audit, Accounting, Property Valuation and Insolvency Management Revoked Certificates',
  'Latvia Consumer Rights Protection Centre Suspicious Websites',
]

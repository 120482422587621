import riskRatingItemDto from './risk-rating-item-dto';
import _ from 'lodash';

const riskRatingDto = {
  read: data => {
    const transformedData = {
      id: data?.id,
      startDate: data?.startDate,
      dateOfReview: data?.dateOfReview,
      templateId: data?.jsonData?.templateId,
      templateName: data?.jsonData?.templateName,
      items: (data?.jsonData?.questions || []).map(
        riskRatingItemDto.read
      ),
      riskRating: data?.jsonData?.userInput?.riskRating || '',
      nextReview: data?.jsonData?.userInput?.nextReview || '',
      note: data?.jsonData?.userInput?.note || '',
      isDraft: !data?.dateOfReview
    }
    transformedData.items = _.orderBy(
      transformedData.items,
      item => item?.excluded || false
    )
    return transformedData
  },
  write: data => ({
    //templateId: data?.templateId,
    templateName: data?.templateName,
    questions: (data?.items || []).map(
      riskRatingItemDto.write
    ),
    userInput: _.omitBy({
      riskRating: data?.riskRating || null,
      nextReview: (!!data?.riskRating && data?.nextReview) || null,
      draft: data?.isDraft || false,
      note: data?.note
    }, _.isNil)
  })
}

export default riskRatingDto
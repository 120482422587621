import React from 'react';
import {Checkbox} from '@material-ui/core';

import {CheckboxLightActiveIcon, CheckboxLightInactiveIcon, CheckboxLightVariousIcon} from '@app/icons';

export default function CheckboxLight(props) {

  return (
    <Checkbox
      className="checkbox-user-card"
      {...props}
      checkedIcon={<CheckboxLightActiveIcon/>}
      icon={<CheckboxLightInactiveIcon/>}
      indeterminateIcon={<CheckboxLightVariousIcon />}
      color="default"
    />
  )
}
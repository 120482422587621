import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import LabelItem from './label-item';

export const useStyles = makeStyles((theme) => ({
  userInfoRowsWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  labelField: {
    minWidth: '80px',
    maxWidth: '80px',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '10px',
    lineHeight: '20px',
    color: theme.palette.text.BL1,
    textTransform: 'uppercase',
    width: '80px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '20px',
  },
  labelFieldMed: {
    minWidth: '70px',
    maxWidth: '70px',
    width: '70px',
  },
  labelFieldMin: {
    minWidth: '65px',
    maxWidth: '65px',
    width: '65px',
    marginRight: '15px',
  },
  labelValue: props => ({
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '20px',
    color: theme.palette.text.BL1,
    maxWidth: props.fullWidthValue ? '100%' : 250,
    wordBreak: 'break-word',
  }),
  labelLink: {
    maxWidth: 250,
    wordBreak: 'break-word',
  },
  labelValueMin: {
    maxWidth: 230,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  stepSelectWrapper: props => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: props.fullWidth && '100%'
  }),
  stepSelectIconWrapper: {
    marginRight: '5px',
  },
  linkWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  emailPending: {
    color: '#A98320'
  },
  // this should copy Link style from material ui
  linkified: {
    fontSize: 12,
    textDecoration: 'none',
    color: theme.palette.text.LB3,
    cursor: 'pointer',
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  capitalize: {
    textTransform: 'capitalize',
  }
}));

const LabelsForRender = (props) => {
  const renderLabelItem = useCallback((item, k) => {
    return (
      <LabelItem {...props} item={item} index={k} key={`user-label-data-${k}`} />
    )}, [props])

  return (
    <React.Fragment>
      {Object.values(props.labels).map((item, k) => renderLabelItem(item, k) )}
    </React.Fragment>
  )
};

export default LabelsForRender;

import React from 'react';
import {Box} from '@material-ui/core';
import {entityTypes} from '@utils/entityType'
import EntityCard from './card';

export default function EntryPointByType() {

  return <Box display={'flex'} flexWrap={'wrap'} style={{overflowY: 'auto', maxHeight: 'calc(100vh - 200px)'}}>
    { entityTypes.map(entityType => (
      <Box display={'flex'} m={1} key={entityType.name}>
        <EntityCard
          icon={entityType.icon}
          label={entityType.label}
          value={entityType.name}
          mode={'type'}
        />
      </Box>
    )) }
  </Box>
}
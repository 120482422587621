import React, {useEffect, useState} from 'react';
import {Box, Typography} from '@material-ui/core';
import { Modal } from '@components/modals';
import { checkRuleChanges } from '@dto/client';
import { clientApi } from '@app/api';
import {
  ChangelogInputFieldIcon,
  ChangelogDocumentIcon
} from '@app/icons';

function ChangesBox({ changes, title }) {
  return (
    <Box>
      <Typography variant="subtitle1" style={{ fontWeight: 500 }}>{title}</Typography>
      <Box
        mt={2}
        py={2}
        px={3}
        border="1px solid #ECF0ED"
        borderRadius="12px"
        height="50vh"
        maxHeight="50vh"
        style={{ overflowY: 'auto' }}
        width={350}
      >
        {changes.map((change, index) => (
          <Box my="10px" key={index} display="flex" alignItems="center">
            <Box pr={2}>
              {change.type === 'inputField'
                ? <ChangelogInputFieldIcon />
                : <ChangelogDocumentIcon />
              }
            </Box>
            <Box>
              <Typography variant="body1" style={{ color: '#232323'}} align="left">
                {change.message}
              </Typography>
            </Box>
          </Box>
        ))}
        {!changes.length &&
          <Box my={1}>
            <Typography variant="subtitle2" align="left" style={{ opacity: 0.5 }}>No Changes</Typography>
          </Box>
        }
      </Box>
    </Box>
  )
}

export default function CompareVersionsDialog ({ open, onClose, rulebookVersionFromId, rulebookVersionToId, onConfirm }) {

  const [removedChanges, setRemovedChanges] = useState([])
  const [updatedChanges, setUpdatedChanges] = useState([])
  const [addedChanges, setAddedChanges] = useState([])

  const init = async () => {
    const res = await clientApi.clientRulebook.diff(rulebookVersionFromId, rulebookVersionToId)
    const { data } = res;
    const { removedChanges, updatedChanges, addedChanges } = await checkRuleChanges(data);

    setRemovedChanges(removedChanges);
    setUpdatedChanges(updatedChanges);
    setAddedChanges(addedChanges);
  }

  useEffect(() => {
    if (!!open) {
      init()
    }
  }, [open]) //eslint-disable-line

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Entity Definition Updates"
      content={
        <Box display="flex" my={4}>
          <ChangesBox title="Will be Removed" changes={removedChanges} />
          <Box mx={4}>
            <ChangesBox title="Will Change" changes={updatedChanges} />
          </Box>
          <ChangesBox title="Will be Added" changes={addedChanges} />
        </Box>
      }
      actions={
        [
          {
            linkButton: true,
            label: 'CANCEL',
            action: onClose,
            style: { width: 160 },
          },
          {
            type: 'secondary',
            label: 'CONFIRM',
            action: onConfirm,
            style: { width: 160 },
          },
        ]
      }
      actionsDirection="row"
    />
  )
}
import moment from 'moment';
import prepareTimestamp from '@utils/date';
import {
  DocumentsPhotoID,
  DocumentsProofOfAddress,
} from '@app/icons';
import { capitalize, capitalizeSentence } from '@utils/textUtil';
import { getCountryName } from '@services/country-service';

const casesDto = {
  read: data => ({
    ...data,
    initiatedDate: prepareTimestamp(data.initiatedDate, 'MM/DD/YYYY', false),
    updatedDate: prepareTimestamp(data.updatedDate, 'MM/DD/YYYY', false),
    status: getStatus(data.status),
    description: getStatus(data.status, true),
    steps: getSteps(data),
    overview: getOverview(data),
    basicDetails: getBasicDetails(data),
    addressInfo: getAddressInfo(data),
    facialMatch: getFacialMatch(data),
    photoId: getPhotoId(data),
    proofOfAddress: getProofOfAddress(data),
  }),
  writePersonalDetail: data => ({
    dateOfBirth: formatDateForBackend(data.dob),
    documentExpiryDate: formatDateForBackend(data.validUntil),
    documentIssuingDate: formatDateForBackend(data.documentIssuingDate),
    documentId: data.documentId,
    gender: data.gender,
    issuingCountry: data.issuingCountry,
    name: data.firstName,
    nationalIdentifier: data.personalNumber,
    nationality: data.nationality,
    surname: data.lastName,
  }),
  writeAddressInfo: data => ({
    ...data,
    countryCode: data.country,
    country: undefined,
    issuingDate: formatDateForBackend(data.issuingDate),
  })
}

// ensure that we keep UTC date
const formatDateForBackend = (date) => {
  if (!date) return undefined
  return new Date(moment(date).format('YYYY-MM-DD'))
}

export default casesDto

const statusMap = {
  Complete: 'Completed',
  complete: 'completed',
  'Requested Repetition': 'Repeated',
  'requested repetition': 'repeated',
}

const getStatus = (status, shouldCapitalize) => {
  const transformText = shouldCapitalize ? capitalize : (e) => e;
  const text = status?.split('_').map(e => transformText(e.toLowerCase())).join(' ') || '';
  return statusMap[text] || text
}

const getSteps = (data) => {
  const status = getStatus(data.status);
  const overview = {
    title: 'Overview',
    description: getStatus(data.status, true),
    status: getStatus(data.status),
    link: 'overview',
  }

  const basicDetails = {
    title: 'Basic Details',
    description: getStatus(data.authenticityVerificationStep?.basicInfoStatus || data.status, true),
    status: getStatus(data.authenticityVerificationStep?.basicInfoStatus || data.status),
    link: 'basic-details',
    data: getBasicDetails(data),
  }

  const photoId = {
    title: 'Photo ID Authenticity',
    description: getStatus(data.authenticityVerificationStep?.photoIdDocumentStatus || data.status, true),
    status: getStatus(data.authenticityVerificationStep?.photoIdDocumentStatus || data.status),
    link: 'photo-document'
  }

  const facialMatch = {
    title: 'Facial Match',
    description: getStatus(data.facialMatchStep?.status || data.status, true),
    status: getStatus(data.facialMatchStep?.status || data.status),
    link: 'facial-match'
  }

  const addressInfo = {
    title: 'Address Information',
    description: getStatus(data.addressInformationStep?.addressInfoStatus || data.status, true),
    status: getStatus(data.addressInformationStep?.addressInfoStatus || data.status),
    link: 'address-information'
  }

  const addressProof = {
    title: 'Proof of Address Upload',
    description: getStatus(data.addressInformationStep?.proofOfAddressStatus || data.status, true),
    status: getStatus(data.addressInformationStep?.proofOfAddressStatus || data.status),
    link: 'proof-of-address'
  }

  let steps = [overview];
  if (status === 'rejected') {
    return steps;
  }

  if (data.requestedSteps.steps.includes('AUTHENTICITY_VERIFICATION')) {
    steps = steps.concat([basicDetails, photoId]);
  }
  if (data.requestedSteps.steps.includes('FACE_MATCH')) {
    steps = steps.concat([facialMatch]);
  }
  if (data.requestedSteps.steps.includes('ADDRESS')) {
    steps = steps.concat([addressInfo, addressProof]);
  }
  return steps;
}

const getOverview = (data) => {
  const details = getBasicDetails(data);

  const getAuthDocName = (name) => {
    const nameMap = {
      'id': 'National ID Card',
      'national id card': 'National ID Card'
    }

    if (name) {
      if (nameMap[name.toLowerCase()]) {
        return nameMap[name.toLowerCase()]
      }
    }

    return capitalizeSentence(name) || 'Passport, National ID Card'
  }
  return {
    authDocument: {
      name: getAuthDocName(data.authenticityVerificationStep?.authenticityVerificationEIdvData?.documentType),
      url: data.authenticityVerificationStep?.authenticityVerificationEIdvData?.authenticityFrontSideDocumentUrl,
      icon: <DocumentsPhotoID />,
      tooltip: [
        { field: 'name', value: details?.firstName?.value || 'N/A' },
        { field: 'last name', value: details?.lastName?.value || 'N/A' },
        { field: 'gender', value: details?.gender?.value || 'N/A' },
        { field: 'date of birth', value: details?.dob?.value || 'N/A' },
        { field: 'personal number', value: details?.personalNumber?.value || 'N/A' },
        { field: 'nationality', value: details?.nationality?.value || 'N/A' },
        { field: 'document id', value: details?.documentId?.value || 'N/A' },
        { field: 'country', value: details?.issuingCountry?.value || 'N/A' },
        { field: 'valid until', value: details?.validUntil?.value || 'N/A' },
      ],
      type: getDocType(data.authenticityVerificationStep?.authenticityVerificationEIdvData?.authenticityFrontSideDocumentUrl),
      downloadPath: 'overview.authDocument.url',
    },
    addressDocument: {
      name: 'Utility Bill',
      url: data.addressInformationStep?.addressInformationEIdvData?.addressDocumentUrl,
      icon: <DocumentsProofOfAddress />,
      tooltip: [
        { field: 'address line 1', value: details?.addressLine1?.value || 'N/A' },
        { field: 'address line 2', value: details?.addressLine2?.value || 'N/A' },
        { field: 'city', value: details?.city?.value || 'N/A' },
        { field: 'region', value: details?.region?.value || 'N/A' },
        { field: 'postal code', value: details?.postalCode?.value || 'N/A' },
        { field: 'country', value: details?.country?.value || 'N/A' },
        { field: 'document issued date', value: details?.issuingDate?.value || 'N/A' },
      ],
      downloadPath: 'overview.addressDocument.url',
      type: getDocType(data.addressInformationStep?.addressInformationEIdvData?.addressDocumentUrl),
    },
    faceDocument: {
      name: 'Facial Match',
      url: data.facialMatchStep?.facialMatchEIdvData?.faceDocumentUrl,
      icon: <DocumentsPhotoID />,
      downloadPath: 'overview.faceDocument.url',
      type: getDocType(data.facialMatchStep?.facialMatchEIdvData?.faceDocumentUrl),
    },
  }
}

const getBasicDetails = (data) => {
  const userData = data.authenticityVerificationStep?.authenticityVerificationUserData;
  const eidvData = data.authenticityVerificationStep?.authenticityVerificationEIdvData;
  if (!userData && !eidvData) {
    return {
      status: getStatus(data.authenticityVerificationStep?.basicInfoStatus || data.status),
    }
  }

  const getValue = (prop) => {
    return userData?.[`${prop}UpdatedAt`] ? userData[prop] : eidvData[prop]
  }
  const status = getStatus(data.authenticityVerificationStep?.basicInfoStatus || data.status)

  const getEdited = (prop) => {
    if (status === 'completed') {
      return false
    }

    if (userData?.[`${prop}UpdatedAt`]) {
      return false
    } else {
      return eidvData[`${prop}Edited`]
    }
  }

  const addressData = getAddressInfo(data);
  return {
    status,
    // Personal details
    firstName: { value: getValue('name'), edited: getEdited('name') },
    lastName: { value: getValue('surname'), edited: getEdited('surname') },
    gender: { value: capitalize(getValue('gender')?.toLowerCase()), edited: getEdited('gender') },
    dob: { value: prepareTimestamp(getValue('dateOfBirth'), 'MM/DD/YYYY', false), edited: getEdited('dateOfBirth') },
    personalNumber: { value: getValue('nationalIdentifier'), edited: getEdited('nationalIdentifier') },
    nationality: { value: getCountryName(getValue('nationality')), edited: getEdited('nationality') },
    nationalityCode: { value: getValue('nationality'), edited: getEdited('nationality') },
    documentId: { value: getValue('documentId'), edited: getEdited('documentId') },
    issuingCountry: { value: getCountryName(getValue('issuingCountry')), edited: getEdited('issuingCountry') },
    issuingCountryCode: { value: getValue('issuingCountry'), edited: getEdited('issuingCountry') },
    validUntil: { value: prepareTimestamp(getValue('documentExpiryDate'), 'MM/DD/YYYY', false), edited: getEdited('documentExpiryDate') },
    documentIssuingDate: { value: prepareTimestamp(getValue('documentIssuingDate'), 'MM/DD/YYYY', false), edited: getEdited('documentIssuingDate') },

    // Address details
    addressLine1: addressData.addressLine1,
    addressLine2: addressData.addressLine2,
    city: addressData.city,
    region: addressData.region,
    province: addressData.province,
    postalCode: addressData.postalCode,
    country: addressData.country,
    countryCode: addressData.countryCode,
    issuingDate: addressData.issuingDate,
    addressUpdatedByKycUser: addressData.updatedByKycUser,
    addressIsFromDocument: addressData.isFromDocument,

    updatedByKycUser: !!userData,
  }
}
export const getDocType = (docUrl) => {
  if (!docUrl) {
    return docUrl
  }

  /**
   * Assume that extension always comes before AWS parameters, so there is a "?" after extension
   * Assume that the document is either image or pdf
   * So we only check for pdf, otherwise it is image.
   * Null is handled above.
   */
  return docUrl.toLowerCase().includes('.pdf?') ? 'pdf' : 'img';
}

const getAddressInfo = (data) => {
  const userData = data.addressInformationStep?.addressInformationUserData;
  const eidvData = data.addressInformationStep?.addressInformationEIdvData;
  if (!userData && !eidvData) {
    return {
      status: getStatus(data.addressInformationStep?.addressInfoStatus || data.status),
    }
  }

  const status = getStatus(data.addressInformationStep?.addressInfoStatus || data.status)

  const getValue = (prop) => {
    return userData?.[`${prop}UpdatedAt`] ? userData[prop] : eidvData[prop]
  }

  const isAddressEdited = status !=='completed' && !userData?.addressLine1UpdatedAt && !eidvData?.isFromDocument;

  return {
    status,
    // Address details
    addressLine1: { value: getValue('addressLine1'), edited: isAddressEdited },
    addressLine2: { value: getValue('addressLine2'), edited: isAddressEdited },
    city: { value: getValue('city'), edited: isAddressEdited },
    region: { value: getValue('region'), edited: isAddressEdited },
    province: { value: getValue('province'), edited: isAddressEdited },
    postalCode: { value: getValue('postalCode'), edited: isAddressEdited },
    country: { value: getCountryName(getValue('countryCode')), edited: isAddressEdited },
    countryCode: { value: getValue('countryCode'), edited: isAddressEdited },
    issuingDate: { value: prepareTimestamp(getValue('issuingDate'), 'MM/DD/YYYY', false), edited: isAddressEdited },
    addressDocumentUrl: eidvData.addressDocumentUrl,
    addressDocumentType: getDocType(eidvData.addressDocumentUrl),
    addressAcquired: eidvData.addressAcquired,
    isFromDocument: eidvData.isFromDocument,
    updatedByKycUser: !!userData,
  }
}

const getFacialMatch = (data) => {
  const basicDetail = data.authenticityVerificationStep?.authenticityVerificationEIdvData || {};
  const faceDetail = data.facialMatchStep?.facialMatchEIdvData;
  const status = getStatus(data.facialMatchStep?.status || data.status);
  if (!faceDetail) {
    return {
      status,
    }
  }

  return {
    status,
    ...faceDetail,
    documentUrl: basicDetail.authenticityFrontSideDocumentUrl,
    isManuallyApproved: status === 'completed' && (
      !faceDetail.extractedFacePrintMatchedDocumentFrontSide ||
      !faceDetail.faceImageAcquired ||
      !faceDetail.facePrintExtractedFromAcquiredImage ||
      !faceDetail.facePrintValidated
    ),
    manuallyApprovedStatusAt: data.facialMatchStep.manuallyApprovedStatusAt
      && prepareTimestamp(data.facialMatchStep.manuallyApprovedStatusAt, 'MM/DD/YYYY', false),
  }
}


const getPhotoId = (data) => {
  const basicDetail = data.authenticityVerificationStep?.authenticityVerificationEIdvData;
  if (!basicDetail) {
    return {
      status: getStatus(data.authenticityVerificationStep?.photoIdDocumentStatus || data.status),
    }
  }

  return {
    status: getStatus(data.authenticityVerificationStep?.photoIdDocumentStatus || data.status),
    documentFrontUrl: basicDetail.authenticityFrontSideDocumentUrl,
    documentBackUrl: basicDetail.authenticityBackSideDocumentUrl,
    documentType: basicDetail.documentType,
    imageAcquiredDocumentFrontSide: basicDetail.imageAcquiredDocumentFrontSide,
    faceExtractedDocumentFrontSide: basicDetail.faceExtractedDocumentFrontSide,
    documentFrontSideValidated: basicDetail.documentFrontSideValidated,
    imageAcquiredDocumentBackSide: basicDetail.imageAcquiredDocumentBackSide,
    dataExtractedDocumentBackSide: basicDetail.dataExtractedDocumentBackSide,
    documentBackSideValidated: basicDetail.documentBackSideValidated,
    clientVerifiedExtractedData: basicDetail.clientVerifiedExtractedData,
  }
}

const getProofOfAddress = (data) => {
  const addressDetail = data.addressInformationStep?.addressInformationEIdvData;
  if (!addressDetail) {
    return {
      status: getStatus(data.addressInformationStep?.proofOfAddressStatus || data.status),
    }
  }

  return {
    status: getStatus(data.addressInformationStep?.proofOfAddressStatus || data.status),
    addressDocumentUrl: addressDetail.addressDocumentUrl,
    addressAcquired: addressDetail.addressAcquired,
    addressDocumentType: getDocType(addressDetail.addressDocumentUrl),
  }
}

const manualApproveAuthenticity = (data) => {
  const basicInfo = data.authenticityVerificationStep?.authenticityVerificationUserData || {}
  const basicData = {
    manuallyApproveBasicInfoStatus: basicInfo?.manuallyApproveBasicInfoStatus || undefined,
    manuallyApprovePhotoIdDocumentStatus: basicInfo?.manuallyApprovePhotoIdDocumentStatus || undefined,
    dateOfBirth: basicInfo?.dateOfBirth || undefined,
    documentExpiryDate: basicInfo?.documentExpiryDate || undefined,
    documentIssuingDate: basicInfo?.documentIssuingDate || undefined,
    documentId: basicInfo?.documentId || undefined,
    gender: basicInfo?.gender || undefined,
    issuingCountry: basicInfo?.issuingCountry || undefined,
    name: basicInfo?.name || undefined,
    nationalIdentifier: basicInfo?.nationalIdentifier || undefined,
    nationality: basicInfo?.nationality || undefined,
    surname: basicInfo?.surname || undefined,
  }

  return basicData;
}

const manualApproveAddressInfo = (data) => {
  const addressInfo = data.addressInformationStep?.addressInformationUserData || {}
  const addressData = {
    manuallyApproveAddressInfoStatus: addressInfo?.manuallyApproveAddressInfoStatus || undefined,
    manuallyApproveProofOfAddressStatus: addressInfo?.manuallyApproveProofOfAddressStatus || undefined,
    addressLine1: addressInfo?.addressLine1 || undefined,
    addressLine2: addressInfo?.addressLine2 || undefined,
    city: addressInfo?.city || undefined,
    countryCode: addressInfo?.countryCode || undefined,
    issuingDate: addressInfo?.issuingDate || undefined,
    postalCode: addressInfo?.postalCode || undefined,
    province: addressInfo?.province || undefined,
    region: addressInfo?.region || undefined,
  }

  return addressData;
}

export const manualApproveDto = {
  basicDetails: data => ({
    ...manualApproveAuthenticity(data),
    manuallyApproveBasicInfoStatus: true
  }),
  addressInformation: data => ({
    ...manualApproveAddressInfo(data),
    manuallyApproveAddressInfoStatus: true,
  }),
  facialMatch: data => ({
    manuallyApproveStatus: true,
  }),
  photoId: data => ({
    ...manualApproveAuthenticity(data),
    manuallyApprovePhotoIdDocumentStatus: true,
  }),
  proofOfAddress: data => ({
    ...manualApproveAddressInfo(data),
    manuallyApproveProofOfAddressStatus: true,
  })
}
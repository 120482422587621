import dashboard from './dashboard';
import userManagement from './user-management';
import generalSettings from './general-settings';
import communication from './communication';
import adminManagement from './admin-management';
import billingManagement from './billing-management';
import monitoringJobs from './/monitoring-jobs';
import subscriptionManagement from './subscription-management';
import documentManagement from './document-management';
import entityManagement from './entity-management';
import Maintenance from '@views/public/maintenance';

const routes = [
  ...dashboard,
  ...userManagement,
  ...generalSettings,
  ...communication,
  ...adminManagement,
  ...billingManagement,
  ...monitoringJobs,
  ...subscriptionManagement,
  ...documentManagement,
  ...entityManagement,
  {
    path: '/maintenance',
    component: Maintenance,
    guest: false
  }
];

export default routes;
import React from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius:'10px',
    color: props.color || '#232323',
    height: '16px',
    minWidth: '16px',
    padding: '0 2px',

    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: props.fontWeight || 'bold',
    fontSize: '10px',
    lineHeight: '7px'
  })
}));

export default function InlineBadge(props) {
  const classes = useStyles({ length: props.children?.toString().length, color: props.textColor, fontWeight: props.fontWeight });

  return (
    <Box className={clsx(classes.root, props.styleBadgeRoot)} style={{backgroundColor: props.color}}>
      {props.children}
    </Box>
  )
}
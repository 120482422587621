import { createBrowserHistory } from 'history';

const history = createBrowserHistory()

history.back = history.goBack;

history.goBack = (defaultUrl) => {
  const currentPath = history.location.pathname
  history.back()

  // fallback url if we cannot go back
  setTimeout(() => {
    if (currentPath === window.location.pathname) {
      const fallbackUrl = currentPath.split('/')?.[1];
      history.replace(defaultUrl || `/${fallbackUrl || ''}`)
    }
  }, 200)
}
export default history;
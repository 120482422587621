import React, { useEffect, useMemo, useState } from 'react';
import history from '@app/history';
import PageLayout from '@components/layout/page-layout';
import { Box, Button, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import pageNotFound from '@assets/img/pageNotFound.svg';

export default function LoggedNotFound(props) {
  return (
    <Box
      mt={18}
      display="flex"
      width="100%"
      alignItems="center"
      flexDirection="column"
    >
      <Box mb={3}>
        <Typography variant="h1">Page not found!</Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
        <Box>
          <img alt="Not Found" src={pageNotFound} />
        </Box>
        <Box width={320}>
          <Box mb={3}>
            <Typography variant="subtitle2" align="left">
              We looked everywhere but couldn't find that page. So it seems that
              the page you were looking for doesn't exist. Unless, of course,
              the URL has a typo in it.
            </Typography>
          </Box>
          <Button variant="contained" onClick={() => history.push('/')}>GO HOME</Button>
        </Box>
      </Box>
    </Box>
  );
}

/**
 *
 * @param {*} Children
 * @param {Object} checkParams avoid unnecessary renders by checking if params is true
 */
export function withLoggedNotFound(Children, checkParams, withPageLayout) {
  return function (props) {
    const params = useParams();

    const isParamOkay = useMemo(() => {
      let isNotFound = false;

      if (checkParams) {
        Object.entries(checkParams).forEach(([param, values]) => {
          if (!values.includes(params[param])) {
            isNotFound = true;
          }
        });
      }

      return isNotFound;
    }, [params]);

    const [isNotFound, setIsNotFound] = useState(
      checkParams ? isParamOkay : false
    );

    useEffect(() => {
      if (checkParams) {
        setIsNotFound(isParamOkay);
      }
      // eslint-disable-next-line
    }, [params]);

    if (isNotFound) {
      if (withPageLayout) {
        return (
          <PageLayout>
            <LoggedNotFound />
          </PageLayout>
        );
      }
      return <LoggedNotFound />;
    }

    return <Children {...props} pageNotFound={() => setIsNotFound(true)} />;
  };
}

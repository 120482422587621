const config = {
  MuiListItem: {
    root: {
      width: 244,
      height: 53,
      padding: '17px 23px',
      borderRadius: 12,
      margin: '2px 0'
    }
  },
}

export default config
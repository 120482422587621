const LegalEntitySubtypeEnum = {
  'CORPORATION': 'Corporation',
  'FOUNDATION': 'Foundation',
  'LIMITED_LIABILITY_COMPANY': 'Limited Liability Company (LLC)',
  'PARTNERSHIP': 'Partnership',
  'TRUST': 'Trust'
}

export {
  LegalEntitySubtypeEnum
}
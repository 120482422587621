import BillingManagement from '@views/protected/admin/billing-management';

const routes = [
  {
    path: '/billing-management/:tab?/:action?',
    component: BillingManagement,
    guest: false,
  },
]

export default routes;
import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Dashboard from './dashboard';
import Edit from './particular/edit';
import View from './particular/view';
import Clone from './particular/clone';
import Create from './particular/create';
import EntryPointByJurisdiction from './entry-point/by-jurisdiction';
import EntryPointByType from './entry-point/by-type';

export default function EntitySettingRoutes(props) {
  return (
    <Switch>
      <Route path={'/settings/:tab/dashboard/:filterValue/:formType(edit)/:id/:formTab'}>
        <Edit />
      </Route>
      <Route path={'/settings/:tab/dashboard/:filterValue/:formType(view)/:id/:formTab'}>
        <View />
      </Route>
      <Route path={'/settings/:tab/dashboard/:filterValue/:formType(clone)/:id/:stepNumber/:formTab?'}>
        <Clone />
      </Route>
      <Route path={'/settings/:tab/dashboard/:filterValue/:formType(create)/:stepNumber/:formTab?'}>
        <Create />
      </Route>
      <Route path={'/settings/:tab/dashboard/:filterValue'}>
        <Dashboard />
      </Route>
      <Route path={'/settings/entity-by-type'}>
        <EntryPointByType />
      </Route>
      <Route path={'/settings/entity-by-jurisdiction'}>
        <EntryPointByJurisdiction {...props} />
      </Route>
    </Switch>
  )
}
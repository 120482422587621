import {
  DashboardActiveIcon,
  DashboardInactiveIcon,
  GeneralSettingsIcon,
  GeneralSettingsInactive,
  AdminManagement,
  AdminManagementInactive,
  UserManagement,
  UserManagementInactive,
  SubscriptionManagement,
  SubscriptionManagementInactive,
  BillingManagement,
  BillingManagementInactive,
  Communication,
  CommunicationInactive,
  ContinuousScanningActiveIcon,
  ContinuousScanningInactiveIcon,
  DocumentManagement,
  DocumentManagementInactive,
  MyPortfolioActiveIcon,
  MyPortfolioInactiveIcon,
} from '@app/icons';

const items = [
  {
    uri: '/dashboard',
    icons: {
      active: <DashboardActiveIcon />,
      inactive: <DashboardInactiveIcon />
    },
    text: 'Dashboard'
  },
  {
    uri: '/general-settings',
    icons: {
      active: <GeneralSettingsIcon />,
      inactive: <GeneralSettingsInactive />
    },
    text: 'General Settings'
  },
  {
    uri: '/admin-management',
    icons: {
      active: <AdminManagement />,
      inactive: <AdminManagementInactive />
    },
    text: 'Admin Management'
  },
  {
    uri: '/user-management',
    icons: {
      active: <UserManagement />,
      inactive: <UserManagementInactive />
    },
    text: 'User Management'
  },
  {
    uri: '/subscription-management',
    icons: {
      active: <SubscriptionManagement />,
      inactive: <SubscriptionManagementInactive />
    },
    text: 'Subscription Management'
  },
  {
    uri: '/billing-management',
    icons: {
      active: <BillingManagement />,
      inactive: <BillingManagementInactive />
    },
    text: 'Billing Management'
  },
  {
    uri: '/monitoring-jobs',
    icons: {
      active: <ContinuousScanningActiveIcon />,
      inactive: <ContinuousScanningInactiveIcon />
    },
    text: 'Monitoring Jobs'
  },
  {
    uri: '/communication',
    icons: {
      active: <Communication />,
      inactive: <CommunicationInactive />
    },
    text: 'Communication'
  },
  {
    uri: '/document-management',
    icons: {
      active: <DocumentManagement />,
      inactive: <DocumentManagementInactive />
    },
    text: 'Document Management'
  },
  {
    uri: '/entity-management',
    icons: {
      active: <MyPortfolioActiveIcon />,
      inactive: <MyPortfolioInactiveIcon />
    },
    text: 'Entity Management'
  },
]

const adminAccountItems = [
  {
    uri: '/dashboard',
    icons: {
      active: <DashboardActiveIcon />,
      inactive: <DashboardInactiveIcon />
    },
    text: 'Dashboard'
  },
  {
    uri: '/user-management',
    icons: {
      active: <UserManagement />,
      inactive: <UserManagementInactive />
    },
    text: 'User Management'
  },
  {
    uri: '/subscription-management',
    icons: {
      active: <SubscriptionManagement />,
      inactive: <SubscriptionManagementInactive />
    },
    text: 'Subscription Management'
  },
  {
    uri: '/billing-management',
    icons: {
      active: <BillingManagement />,
      inactive: <BillingManagementInactive />
    },
    text: 'Billing Management'
  },
  {
    uri: '/monitoring-jobs',
    icons: {
      active: <ContinuousScanningActiveIcon />,
      inactive: <ContinuousScanningInactiveIcon />
    },
    text: 'Monitoring Jobs'
  },
  {
    uri: '/document-management',
    icons: {
      active: <DocumentManagement />,
      inactive: <DocumentManagementInactive />
    },
    text: 'Document Management'
  },
  {
    uri: '/entity-management',
    icons: {
      active: <MyPortfolioActiveIcon />,
      inactive: <MyPortfolioInactiveIcon />
    },
    text: 'Entity Management'
  },
]

const resolveAdminMenu = userIsAdmin  => userIsAdmin ? adminAccountItems : items;

export default resolveAdminMenu;
import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, Dialog, DialogActions, DialogContent,
  DialogTitle, FormControl, FormControlLabel, InputLabel, OutlinedInput, Typography
} from '@material-ui/core';
import CheckboxLight from '@components/inputs/checkbox-light';
import EVStatusCard from './eidv-status-card';

const allSteps = {
  'AUTHENTICITY_VERIFICATION': true,
  'FACE_MATCH': true,
  'ADDRESS': true,
}

const mapStep = {
  'basic-details': 'AUTHENTICITY_VERIFICATION',
  'photo-document': 'AUTHENTICITY_VERIFICATION',
  'facial-match': 'FACE_MATCH',
  'address-information': 'ADDRESS',
  'proof-of-address': 'ADDRESS',
}

export default function EVRepeatDialog({ open, onClose, onSave, steps, selectedStep }) {
  const [selectedSteps, setSelectedSteps] = useState({})
  const [note, setNote] = useState('')

  useEffect(() => {
    setNote('');
  }, [open])

  useEffect(() => {
    if (selectedStep === 'overview') {
      return setSelectedSteps({ ...allSteps })
    } else {
      const selectedSteps = {}
      selectedSteps[mapStep[selectedStep]] = true
      setSelectedSteps(selectedSteps)
    }

  }, [steps, selectedStep])

  const filteredSteps = useMemo(() => {
    return steps.filter(step => step.link !== 'overview').map(step => ({ ...step, value: mapStep[step.link]}))
  }, [steps])

  const handleSave = () => {
    const steps = [];
    Object.entries(selectedSteps)
      .forEach(([key, value]) =>
        value
        && filteredSteps.some(e => e.value === key)
        && steps.push(key)
      )
    onSave({ note: note || 'N/A', steps });
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>
        Repeat Selected Steps
      </DialogTitle>
      <DialogContent>
        <Typography style={{ color: '#484848' }} align="center">Select the steps you wish to repeat within this session.</Typography>
        <Box my={2} display="flex" justifyContent="center">
          <Box>
            {filteredSteps.map(step => (
              <Box key={step.value}>
                <FormControlLabel
                  labelPlacement="end"
                  label={(
                    <EVStatusCard
                      {...step}
                      noHover
                    />
                  )}
                  control={
                    <CheckboxLight />
                  }
                  onChange={() => setSelectedSteps({ ...selectedSteps, [step.value]: !selectedSteps[step.value] })}
                  checked={selectedSteps[step.value] || false}
                />
              </Box>
            ))}
          </Box>
        </Box>
        <FormControl fullWidth variant="outlined" style={{ position: 'relative' }}>
          <InputLabel>Note</InputLabel>
          <OutlinedInput
            value={note}
            onChange={(e) => setNote(e.target.value)}
            multiline
            inputProps={{
              maxLength: 200
            }}
            rows={3}
          />
          <Box
            position="absolute"
            top={8}
            right={8}
          >
            <Typography variant="body2" align="right">{note.length}/200</Typography>
          </Box>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button style={{ width: '160px' }} variant={'outlined'} onClick={onClose}>
          CANCEL
        </Button>
        <Button
          style={{ width: '160px' }}
          variant={'contained'}
          onClick={handleSave}
          type="submit"
          disabled={filteredSteps.every(e => !selectedSteps[e.value])}
        >
          {'REPEAT'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import {
  Box, Typography, Card, makeStyles, Popover
} from '@material-ui/core';
import { Dropdown3Icon } from '@app/icons';
import history from '@app/history';
import { eidvStatusIcons } from './eidv-status-card';

const useStyles = makeStyles((theme) => ({
  dropdownIcon: {
    position: 'absolute',
    width: 18,
    height: 18,
    right: 16,
    top: 'calc(50% - 9px)',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  optionCard: {
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#EDEDED'
    },
  },
  selectedCard: {
    backgroundColor: '#EDEDED'
  },
  email: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
}));

function EVOption({ title, email, description, status, option, selected, onClick }) {
  const classes = useStyles();

  return (
    <Card onClick={onClick} className={clsx(option && classes.optionCard, selected && classes.selectedCard)}>
      <Box display="flex" alignItems="center" width="100%">
        <Box p={1} pl="12px" width={24} height={24}>
          {eidvStatusIcons[status] || eidvStatusIcons.default}
        </Box>
        <Box pt={1} pb="4px">
          <Typography variant="h5" style={{ fontSize: '12px' }}>{title}</Typography>
          <Typography className={classes.email}>{email}</Typography>
        </Box>
        <Box marginLeft="auto" pl={1} pr={6}>
          <Typography style={{ color: '#484848' }}>{description}</Typography>
        </Box>
      </Box>
    </Card>
  )
}

export default function EVSelect({ cases, selectedCaseId, setSelectedCaseId, path }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverWidth, setPopoverWidth] = useState(0);

  useEffect(() => {
    if (anchorEl) {
      setPopoverWidth(anchorEl.clientWidth);
    }
  }, [anchorEl])

  const handleClick = (caseId) => () => {
    setSelectedCaseId(caseId);
    history.push(`${path}/${caseId}/overview`)
  }

  const selectedCase = useMemo(() => {
    return cases.find(e => e.id === selectedCaseId) || {}
  }, [selectedCaseId, cases])

  return (
    <Box width="100%">
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { width: anchorEl ? anchorEl.clientWidth : popoverWidth }
        }}
      >
        {cases.map(ev => (
          <EVOption
            key={ev.id}
            title={ev.updatedDate}
            description={ev.description}
            status={ev.status}
            email={ev.email}
            option
            selected={ev.id === selectedCaseId}
            onClick={handleClick(ev.id)}
          />
        ))}
      </Popover>
      <Box position="relative" onClick={e => setAnchorEl(e.currentTarget)} width="100%">
        <EVOption
          title={selectedCase.updatedDate}
          email={selectedCase.email}
          description={selectedCase.description}
          status={selectedCase.status}
          selected
        />
        <Box className={classes.dropdownIcon}>
          <Dropdown3Icon />
        </Box>
      </Box>
    </Box>
  )
}
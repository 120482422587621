import auth from '@app/auth';
import store from '@app/store';
import { loader } from '@store/actions';
import config from '../app/config';

const BASE_URL = `${config.api}reports/`;

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const exportBackgroundCheckPDF  = async ({ searchId, subLink, profileId }) => {
  let url = '';
  if (subLink) {
    url = profileId
      ? `${BASE_URL}${subLink}${searchId}?profileId=${profileId}&zoneId=${timeZone}`
      : `${BASE_URL}${subLink}${searchId}?zoneId=${timeZone}`;
  } else {
    url = profileId
      ? `${BASE_URL}${searchId}?profileId=${profileId}&zoneId=${timeZone}`
      : `${BASE_URL}${searchId}?zoneId=${timeZone}`;
  }
  await exportPDF(url)
}

export const exportRiskRatingPDF  = async ({ riskRatingId }) => {
  await exportPDF(
      `${BASE_URL}risk-ratings/${riskRatingId}?zoneId=${timeZone}`
  )
}

const exportPDF = async (url) => {
  try {
    const token = await auth.getAccessToken();
    const oReq = new XMLHttpRequest();

    oReq.open('GET', url, true);
    oReq.setRequestHeader(
      'Authorization', `Bearer ${token}`
    );
    oReq.responseType = 'blob';
    let filename = '';
    oReq.onload = () => {
      const file = oReq.response;
      const disposition = oReq.getResponseHeader('Content-Disposition');
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
      }

      const fileURL = URL.createObjectURL(file);

      if (filename) {
        const a = document.createElement('a');

        if (typeof a.download === 'undefined') {
          window.open(fileURL, '_blank');
        } else {
          a.href = fileURL;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
        }
      } else {
        window.open(fileURL, '_blank');
      }

      store.dispatch(loader.setLoader(false));
    };
    oReq.send();
  } catch (e) {
    console.log('exportPDF error', e);
    store.dispatch(loader.setLoader(false));
  }
}

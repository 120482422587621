import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PageLayout from '@components/layout/page-layout';
import InnerHeader from '@components/layout/inner-header';

import history from '@app/history';
import { Route, Switch, Redirect } from 'react-router';
import { useParams } from 'react-router-dom';

import Jobs from './jobs';

export const useStyles = makeStyles((theme) => ({
  propsTabRoot: {
    maxWidth: '300px'
  }
}));

const tabs = [
  {
    id: 'jobs',
    label: 'Monitoring Jobs',
  },
]

export default function MonitoringJobs(props) {
  const { tab } = useParams();
  const classes = useStyles();

  const [tabID, setTabID] = useState(tab || 'jobs');

  const onTabChange = (index) => {
    const tabID = tabs[index].id;
    setTabID(tabID);
    history.push(`/billing-management/${tabID}`)
  }

  return (
    <PageLayout>
      <Box mx={6} height="100%">
        <InnerHeader
          onTabChange={onTabChange}
          ind={tabs.findIndex(tab => tab.id === tabID)}
          tabs={tabs}
          propsTabRoot={classes.propsTabRoot}
        />
        <Box display={'flex'} flexDirection={'column'} height={'calc(100% - 100px)'}>
          <Switch>
            <Route exact path="/monitoring-jobs/jobs">
              <Jobs />
            </Route>
            
            <Redirect to={`/monitoring-jobs/${tabID}`} />
          </Switch>
        </Box>
      </Box>
    </PageLayout>
  )
}
import React, {useCallback, useMemo, useState} from 'react';
import { Box, Card, Divider, Grid, FormControlLabel, Switch, makeStyles } from '@material-ui/core';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import TableList, { TCELL_TYPES } from '@components/lists/table-list';
import { AddActive2Icon } from '@app/icons';
import Header from './category-card-header';
import { adminApi } from '@app/api';
import {useSnackbar} from 'notistack';
import NoResultsFound from '@components/layout/no-results-found';
import CategoryTypeCreateDialog from '@components/complex/client-view/kyc-documents/components/category-type-create-dialog';

const useStyles = makeStyles((theme) => ({
  card: {
    background: '#FFFFFF',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: '12px',
    overflow: 'inherit',
    '&:hover': {
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      cursor: 'pointer',
    },
    padding: '16px 0',
    width: '100%'
  },
  mark: {
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: '12px 0 0 12px',
    height: '16px',
  },
  switch: {
    width: '36px',
    height: '18px',
    padding: '2px 6px',
    '& .MuiSwitch-switchBase': {
      top: -3,
      padding: '4px',
    },
  },
  switchControl: {
    marginLeft: '-5px',
    '& .MuiFormControlLabel-label': {
      marginLeft: 4,
    }
  }
}));

export default function CategoryCard({ category, setCategory, onDelete, types, categories, fetchCategories, clientType }) {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles();
  const [ openSelectTypeDialog, setOpenSelectTypeDialog]= useState(false)

  const onSelectTypeDialogClose = () => {
    setOpenSelectTypeDialog(false)
  }

  const onSelectTypeDialogOpen = () => {
    setOpenSelectTypeDialog(true)
  }

  const onTypeChange = (id, field, value) => {
    const newCategory = {
      ...category,
      types: category.types.map(t => t.id === id ? ({ ...t, [field]: value }) : t)
    }
    setCategory(newCategory);
  }

  const onSelectSwitch = (id) => {
    const newCategory = {
      ...category,
      types: category
        .types?.map(t =>
          t.id === id ? ({
            ...t,
            isChecked: !t.isChecked,
            expected: false
          }) : t
        )
    }
    setCategory(newCategory);
  }

  const renderName = useCallback((item) => {
    return <span style={
      !item.isChecked
        ? { color: '#aaa' }
        : {}
    }>
      {item.name || 'N/A'}
    </span>;
  }, [])

  const renderRequired = (item) => {
    return !!item.isChecked && <FormControlLabel
      control={
        <Switch
          checked={!!item.expected}
          onClick={() => onTypeChange(item.id, 'expected', !item.expected)}
          classes={{ root: classes.switch }}
          disabled={category?.disabled}
        />}
      label={item.expected ? 'Yes' : 'No'}
      classes={{ root: classes.switchControl }}
    />
  } //eslint-disable-line

  const onSelectType = async type => {
    let newType;
    if (!type.id) {
      newType = (await adminApi.type.createType({ ...type, isSystem: true })).data
      enqueueSnackbar(`Document type "${type.name}" was successfully created!`, { variant: 'success' })
    } else {
      newType = (await adminApi.type.updateType(type.id, {
        ...type,
        categoryIds: [
          ...type.categoryIds,
          category.id
        ],
        isSystem: true,
      })).data
      enqueueSnackbar(`Document type "${type.name}" was successfully linked with the document category!`, { variant: 'success' })
    }
    fetchCategories()
    newType = {
      ...newType,
      isChecked: true,
      expected: false,
    }
    const newCategory = {
      ...category,
      types: [
        ...category?.types || [],
        newType
      ]
    }
    setCategory(newCategory)
    onSelectTypeDialogClose()
  }

  const columns = useMemo(() => !category?.types?.length ?
    []
    : category?.categoryRuleType === 'ONE_OF'
      ? [
        {
          cellType: TCELL_TYPES.CUSTOM,
          colProps: {
            style: {
              width: '40%'
            }
          },
          field: 'name',
          headerName: 'Document Types',
          bold: true,
          custom: renderName,
          triggerSelect: true
        },
      ] : [
        {
          cellType: TCELL_TYPES.CUSTOM,
          colProps: {
            style: {
              width: '40%'
            }
          },
          field: 'name',
          headerName: 'Document Types',
          bold: true,
          custom: renderName,
          triggerSelect: true
        },
        {
          cellType: TCELL_TYPES.CUSTOM,
          colProps: {
            style: {
              width: '40%'
            }
          },
          field: 'expected',
          headerName: 'Is Required',
          custom: renderRequired
        },
  ], [category, renderRequired]) //eslint-disable-line

  const catTypes = useMemo(() => {
    return category.types.filter(e => e.usageTypes.includes(clientType))
  }, [category, clientType])

  return (
    <Box mb={3} width={'100%'}>
      <Card className={classes.card}>
        <Header
          category={category}
          setCategory={setCategory}
          onDelete={onDelete}
        />
        <Divider />
        <Box px={2} mt={1}>
          <Grid container>
            <Grid item sm={12}>
              <TableList
                columns={columns}
                items={catTypes}
                onSelect={category?.disabled ? undefined : onSelectSwitch}
                headAction={category?.disabled ? undefined :
                  <ButtonWithIcon
                    startIcon={<AddActive2Icon />}
                    onClick={onSelectTypeDialogOpen}
                  >
                    Add Document Type
                  </ButtonWithIcon>
                }
              />
              {!category?.types?.length && (
                <Box my={6}>
                  <NoResultsFound>
                    The category types will appear here
                  </NoResultsFound>
                </Box>
              )
              }
            </Grid>
          </Grid>
        </Box>
      </Card>
      {!category?.disabled &&
        <CategoryTypeCreateDialog
          open={openSelectTypeDialog}
          onClose={onSelectTypeDialogClose}
          onSave={onSelectType}
          types={types || []}
          selectedCategory={{
            ...category,
            id: category.categoryId,
          }}
          categories={categories || []}
          title="Add Document Type"
          isAdmin
        />
      }
    </Box>
  )
}

import React, { useEffect, useState, useMemo } from 'react';
import * as yup from 'yup';
import parseYupErrors from '@app/yup';

import {
  Box, FormControl, Typography,
  InputLabel, OutlinedInput, FormHelperText, Card, Link, Collapse, FormControlLabel
} from '@material-ui/core';
import { Modal } from '@components/modals';
import CheckboxLight from '@components/inputs/checkbox-light';
import EidvSentIcon from '@assets/icons/EIDV/EidvSent.png';
import { EidvFingerprintIcon, EidvLocationDocIcon, EidvPersonDocIcon } from '@app/icons';
import { eidv, subscriptionPlans, person } from '@app/api/client';

import { useSnackbar } from 'notistack';
import { handleOnEnter } from '@utils/handleOnEnter';

const types = [
  { 
    name: 'complete', icon: <EidvFingerprintIcon />, label: 'Complete eIDV', sublabel: '\u00A0',
    desc: 'Complete eIDV includes verifying Photo ID document and collecting Proof of Address document.',
    steps: ['ADDRESS', 'AUTHENTICITY_VERIFICATION', 'FACE_MATCH'],
  },
  {
    name: 'id', icon: <EidvLocationDocIcon />, label: 'Photo ID', sublabel: '(only)',
    desc: 'Photo ID (only) includes verifying Photo ID document.',
    steps: ['AUTHENTICITY_VERIFICATION', 'FACE_MATCH'],
  },
  { 
    name: 'address', icon: <EidvPersonDocIcon />, label: 'Proof of Address', sublabel: '(only)',
    desc: 'Proof of address (only) includes collecting Proof of Address document.',
    steps: ['ADDRESS'],
  },
]


const schema = yup.object().shape({
  email: yup.string().trim().email('Invalid email format').required(),
});

export default function ElectronicVerificationAdd(props) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    data, cases, onCreate, forceStart,
    showSendingModal, setShowSendingModal,
    onUpdateEmailCallback, type,

    fromEidv,
    clientSearch, folderId, onClose, setClientError,
  } = props;
  const [showSentModal, setShowSentModal] = useState(false);
  const [selectedType, setSelectedType] = useState('complete');
  const [credits, setCredits] = useState(0);
  const [shouldUpdateEmail, setShouldUpdateEmail] = useState(false);

  const [model, setModel] = useState({
    email: '',
  });
  const [errors, setErrors] = useState({})

  useEffect(() => {
    getCredits();

    return () => {
      window.removeEventListener('focus', getCredits)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (type) {
      setSelectedType(cases.length ? type : 'complete')
    }
  }, [type, cases])

  const getCredits = () => {
    subscriptionPlans.getSubscriptionForUser().then((res) => {
      const subscription = res.data;
      const credits = (subscription.remainingEidvCredits || 0) - (subscription.reservedEidvCredits || 0)
      setCredits(credits < 0 ? 0 : credits)
      window.removeEventListener('focus', getCredits)
    })
  }

  const setCheckCreditOnReturn = () => {
    window.addEventListener('focus', getCredits)
  }

  useEffect(() => {
    setModel({ email: data?.primary_email || '' })
    setShouldUpdateEmail(false);
  }, [data])

  useEffect(() => {
    if (forceStart) {
      setShowSendingModal(true);
    }
  }, [forceStart]) // eslint-disable-line

  const isNew = useMemo(() => {
    return !cases.find(e => !!e.externalSystemClientId)
  }, [cases])

  const handleChange = (prop) => (event) => {
    const newModel = { ...model, [prop]: event.target.value };
    setModel(newModel);

    if (errors[prop]) {
      schema.validateAt(prop, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [prop]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [prop]: err.message });
        });
    }
  };

  const onSubmit = () => {
    setErrors({});
    if (fromEidv) {
      if (!data?.id) {
        return setClientError('Client is a required field')
      }
    }
    schema
      .validate(model, { abortEarly: false })
      .then(() => {
        if (credits <= 0) {
          return enqueueSnackbar('You do not have enough credits.', { variant: 'error' })
        }

        // Handle invite client from eidv
        if (fromEidv) {
          if (data?.id === 'custom') {
            eidv.invite({ email: model.email, folderId }).then(() => {
              setShowSendingModal(false);
              setShowSentModal(true);
            })
            return;
          }
        }

        if (data?.id) {
          if (isNew) { // new case if there is no completed case
            eidv.createNew({ clientId: data?.id, email: model.email }).then(() => {
              setShowSendingModal(false);
              setShowSentModal(true);
            })
          } else { // repeated case
            const steps = types.find(e => e.name === selectedType).steps;
            eidv.createRepeated({ clientId: data?.id, email: model.email, steps }).then(() => {
              setShowSendingModal(false);
              setShowSentModal(true);
            })
          }

          if (shouldUpdateEmail) {
            const updateData = {
              primaryEmail: model.email,

              firstName: data.first_name,
              middleName: data.middle_name,
              lastName: data.last_name,
              gender: data.gender,
              dob: data.dob,
              citizenship: data.citizenship_raw,
              residency: data.residency_raw,
              address: data.address,
              placeOfBirth: data.place_of_birth,
              folderId: data.folder_id,
              folderName: data.folder_name,
              note: data.note,
              otherEmail: data.other_email,
              phoneNumber: data.phone_number,
              company: data.company,
            }

            person.update(data.id, updateData).then(() => {
              onUpdateEmailCallback && onUpdateEmailCallback()
            })
          }
        }
      })
      .catch(err => setErrors(parseYupErrors(err), true));
  }

  const cancelRequest = () => {
    setErrors({})
    setModel({ email: data?.primary_email || '' })
    setSelectedType('complete')
    setShowSendingModal(false);
    fromEidv && onClose()
  }

  const handleCreate = () => {
    setShowSentModal(false);
    onCreate();
  }

  const typeDesc = types.find(e => e.name === selectedType).desc;
  return (
    <React.Fragment>
      <Modal
        open={showSentModal}
        title={`eIDV request for ${data?.name || model.email} sent!`}
        content={
          <Box width={400} mb={1}>
            <Box mt={-3} mb={-1} display="flex" justifyContent="center">
              <img alt="" src={EidvSentIcon}></img>
            </Box>
            <Typography align="center">You will be notified by email once verification is completed.</Typography>
            <Typography align="center">Documents and other information you requested will be</Typography>
            <Typography align="center">uploaded to client’s profile in your Portfolio.</Typography>
          </Box>
        }
        actions={[
          {
            type: 'secondary',
            label: 'OK',
            action: handleCreate,
            style: { width: 150 },
          }
        ]}
        actionsDirection="row"
        modalProps={{
          onKeyDown: handleOnEnter(handleCreate),
        }}
      />

      <Modal
        open={showSendingModal}
        onClose={cancelRequest}
        title={`Send eIDV request for\n${data?.name || 'Client'}`}
        content={
          <Box width={500} mb={1} px={4}>
            {clientSearch || ''}
            <Collapse in={!!data}>
              <Box mt={1}>
                <FormControl fullWidth variant="outlined" error={errors.email}>
                  <InputLabel htmlFor="email">Email</InputLabel>
                  <OutlinedInput value={model.email} onChange={handleChange('email')} />
                  {errors.email &&
                    <FormHelperText>{errors.email}</FormHelperText>
                  }
                </FormControl>
                {data && data.id !== 'custom' &&
                  <FormControlLabel
                    labelPlacement="end"
                    label="Use this email as primary client email"
                    control={
                      <CheckboxLight />
                    }
                    onChange={() => setShouldUpdateEmail(!shouldUpdateEmail)}
                    value={shouldUpdateEmail}
                    checked={shouldUpdateEmail}
                  />
                }
              </Box>
            </Collapse>
            <Collapse in={!isNew}>
              <Box mt={5} display="flex" justifyContent="space-between">
                {types.map(type => (
                  <Box width={145} key={type.name}>
                    <Card
                      onClick={() => setSelectedType(type.name)}
                      style={selectedType === type.name ? { outline: '2px solid #7A5B44' } : {}}
                    >
                      <Box height={135} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        {type.icon}
                        <Box mt={1}>
                          <Typography variant="subtitle2">{type.label}</Typography>
                          <Typography align="center" variant="body1">{type.sublabel}</Typography>
                        </Box>
                      </Box>
                    </Card>
                    {selectedType === type.name &&
                      <Box mt={2}>
                        <Typography variant="h4" align="center" style={{ color: '#7A5B44', fontSize: 16 }}>1 CREDIT</Typography>
                      </Box>
                    }
                  </Box>
                ))}
              </Box>
            </Collapse>
            <Box mt={3}>
              <Typography variant="h4" align="center" style={{ color: '#484848', fontSize: 16 }}>You have <span style={{ color: '#7A5B44' }}>{credits}</span> eIDV credits available</Typography>
              {isNew && <FormHelperText style={{ marginBottom: 6 }}>1 credit will be used for each eIDV request</FormHelperText>}
              <Typography align="center">
                <Link onClick={setCheckCreditOnReturn} href="/my-profile/subscription/plans" target="_blank" style={{ fontWeight: 'normal', fontSize: 12 }}>Click here to buy more credits</Link>
              </Typography>
            </Box>
            <Box mt={3} mb={2} height={40}>
              <Typography variant="subtitle2" style={{ color: '#484848' }}>
                {!isNew ? typeDesc : 'eIDV includes verifying Photo ID document and collecting Proof of Address document.'}
              </Typography>
            </Box>
            <Box mt={3} mb={2}>
              <Typography variant="subtitle2" style={{ color: '#B6514C' }}>
                Credits will be reserved until eIDV process is completed or pending approval. Any rejected or expired requests will return the credit back.
              </Typography>
            </Box>
          </Box>
        }
        actions={[
          {
            type: 'main',
            label: 'CANCEL',
            action: cancelRequest,
            style: { width: 150 },
          },
          {
            type: 'secondary',
            label: 'NEXT',
            action: onSubmit,
            style: { width: 150 },
          }
        ]}
        actionsDirection="row"
        modalProps={{
          onKeyDown: handleOnEnter(onSubmit),
          disableRestoreFocus: true,
        }}
      />
    </React.Fragment>
  )
}
import React, { useEffect } from 'react';
import clsx from 'clsx';
import {
  makeStyles,
} from '@material-ui/core'
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import authRedux from '@app/store/actions/auth'
import MaintenanceInterceptor from '../../MaintenanceInterceptor';

const selectIsGuest = state => !state.auth.user;

export const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: '100%',
  },
  disableEvents: {
    pointerEvents: 'none',
  },
}));

export default function ProtectedRoute(props){
  const isGuest = useSelector(selectIsGuest);
  const { loader } = useSelector(store => store.loader);
  const Component = props.component;
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isGuest) {
      dispatch(authRedux.setLastVisitedPage(location));
    }
  }, [isGuest, location, dispatch])

  return !isGuest ? (
    <Route path={props.path} exact={props.exact}>
      <div className={
        clsx(classes.fullHeight, { [classes.disableEvents]: loader })
      } >
        <MaintenanceInterceptor />
        <Component {...props} />
      </div>
    </Route>
  ) : (
    <Redirect to={{ pathname: '/login' }} />
  );
}
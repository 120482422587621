import React, { useState } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import TableList, { TCELL_TYPES } from '@components/lists/table-list';
import NoResultsFound from '@components/layout/no-results-found';
import { badgeComponent } from './badgeComponent';
import DocumentModal from './modal';
import CategoryDialog from './categoryDialog';
import TypeDialog from './typeDialog';

export const useStyles = makeStyles((theme) => ({
  styleBadgeClass: {
    width: '100px',
    fontWeight: 'normal',
    color: '#000000',
  },
  styleBadgeAssociation: {
    padding: '0 10px',
    fontWeight: 'normal',
    color: '#000000',
  },
  customButtonModalWidth: {
    width: '180px',
  },
  modalContentWrapper: {
    maxWidth: '550px',
    maxHeight: '350px',
    overflowY: 'auto'
  }
}));

const USAGE_TYPES_MAP = {
  'PERSON': 'Person',
  'LEGAL_ENTITY': 'Legal Entity'
}

export default function DocumentsView(props) {
  const {
    ownedBy, viewBy, data, totalCount,
    page, setPage,
    pagesCount, pageSize,
    fetchData, categories,
  } = props;
  const isCustom = ownedBy === 'user'

  const classes = useStyles();

  const [openModal, setOpenModal] = useState(null);
  const [editData, setEditData] = useState(null);

  const renderTypeAssociation = (item) => item.types?.map((el, i) => (
    <Box mr={1} mt="2px" mb="2px" key={`${item.ownedBy}-${el}-${i}`}>
      {badgeComponent(item.ownedBy, el.name, classes.styleBadgeAssociation)}
    </Box>
  ))

  const renderCategoryAssociation = (item) => item.categories?.map((el, i) => (
    <Box mr={1} mt="2px" mb="2px" key={`${item.ownedBy}-${el}-${i}`}>
      {badgeComponent(item.ownedBy, el.name, classes.styleBadgeAssociation)}
    </Box>
  ))

  const renderClasses = (item) => item.usageTypes?.map((el, i) => (
    <Box mr={1} mt="2px" mb="2px" key={i}>
      {badgeComponent(item.ownedBy, USAGE_TYPES_MAP[el], classes.styleBadgeAssociation)}
    </Box>
  ))

  const handleModal = (id, item, type) => {
    setOpenModal(type);
    setEditData(item);
  }

  const onPageChange = (event, value) => {
    setPage(value - 1)
  }

  const handleClose = () => {
    setOpenModal('');
    setEditData(null)
  }

  const tableActions = [
    { label: 'Edit', handler: (id, item) => handleModal(id, item, viewBy) },
    { label: 'Delete', handler: (id, item) => handleModal(id, item, 'delete') },
  ]

  const CATEGORY_TABLE_COLUMNS = [
    { field: 'name', headerName: 'Document Category' },
    { cellType: TCELL_TYPES.CUSTOM, field: 'types', headerName: 'Document Types', custom: item => renderTypeAssociation(item), colProps: { width: '40%' } },
    { cellType: TCELL_TYPES.CUSTOM, field: 'class', headerName: 'Class', custom: item => renderClasses(item) },
    { field: 'association', headerName: 'Association' },
    // { cellType: TCELL_TYPES.CUSTOM, field: 'association', headerName: 'Association', custom: item => renderAssociation(item) },
  ]
  
  const TYPE_TABLE_COLUMNS = [
    { field: 'name', headerName: 'Document Type' },
    { cellType: TCELL_TYPES.CUSTOM, field: 'categories', headerName: 'Document Categories', custom: item => renderCategoryAssociation(item) },
    { cellType: TCELL_TYPES.CUSTOM, field: 'class', headerName: 'Class', custom: item => renderClasses(item) },
    { field: 'association', headerName: 'Association' },
    // { cellType: TCELL_TYPES.CUSTOM, field: 'association', headerName: 'Association', custom: item => renderAssociation(item) },
  ]

  const typeColumns = viewBy === 'type' ? TYPE_TABLE_COLUMNS : CATEGORY_TABLE_COLUMNS;
  const columns = isCustom ? [ ...typeColumns, { field: 'email', headerName: 'Email' }, ] : typeColumns;

  return (
    <Box display={'flex'} flexGrow={'1'} mt={2} flexDirection={'column'}>
      {!!data?.length ? (
        <Grid container>
          <Grid item sm={12}>
            <TableList
              columns={columns}
              items={data}
              onPageChange={onPageChange}
              page={page}
              pageSize={pageSize}
              pagesCount={pagesCount}
              totalCount={totalCount}
              maxHeight="calc(100vh - 340px)"
              threeDotsActions={!isCustom && tableActions}
            />
          </Grid>
        </Grid>
      ) : (
        <Box mt={25} flexGrow={1}>
          <NoResultsFound>
            The list of system documents will appear here
          </NoResultsFound>
        </Box>
      )}

      <CategoryDialog
        open={openModal === 'category'}
        onClose={handleClose}
        fetchData={fetchData}
        editData={editData}
      />

      <TypeDialog
        open={openModal === 'type'}
        onClose={handleClose}
        fetchData={fetchData}
        editData={editData}
        categories={categories}
        types={data}
      />
      
      <DocumentModal
        open={openModal === 'delete'}
        onClose={handleClose}
        editData={editData}
        fetchData={fetchData}
        type={viewBy}
      />
    </Box>
  )
}

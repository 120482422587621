import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.15)',
    borderRadius: '12px',
  },
  showMoreTitle: {
    fontFamily: 'Quicksand',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '48px',
    color: theme.palette.text.LB3,
  },
  subtitleFirst: {
    fontFamily: 'Quicksand',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '38px',
    color: theme.palette.text.BL1,
  },
  subtitleSecond: {
    fontFamily: 'Quicksand',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '28px',
    color: theme.palette.text.LB3,
  },
  buyMoreButton: {
    height: '20px',
    borderRadius: '12px',
  },
  buyMoreButtonLabel: {
    fontFamily: 'Quicksand',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '8px',
    lineHeight: '28px',
    color: '#fff',
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px',
    lineHeight: '18px',
    color: theme.palette.text.GY5,
  }
}));
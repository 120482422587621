import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  sideTree: {
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    display: 'flex',
    flexDirection: 'column',
    transition: 'width 3s',
    height: '100%',
    '&.expended': {
      width: '25%',
      minWidth: '220px',
      overflow: 'hidden'
    },
    '& ul': {
      overflowY: 'auto',
      paddingBottom: 24,
    }
  },
  notFoundText: {
    color: theme.palette.text.GY4
  },
  popover: {
    padding: '35px',
    boxShadow: '0px 9px 26px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    borderRadius: '12px',
    whiteSpace: 'nowrap'
  },
  buttonActive: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  },
  chipDeleteIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

import CalendarSchedule from '@views/protected/client/calendar-schedule';

const routes = [
  {
    path: '/calendar-schedule/:tab?/:action?',
    component: CalendarSchedule,
    guest: false,
  },
]

export default routes;
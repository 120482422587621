import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import ImageZoomTool from '../image/tools/image-zoom';
import ImageDownloadTool from '../image/tools/image-download';
import PdfViewer from './pdf-viewer';

export default function PdfPreview({ height, width, pdfSrc, bgColor, onDownload, documentName, clientName, children }) {
  const [showFull, setShowFull] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null)

  const imageAction = (action) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    action()
  }

  useEffect(() => {
    setTimeout(() => {
      setPdfUrl(pdfSrc)
    }, 2000)
  }, [pdfSrc])

  return (
    <React.Fragment>
      {/* Zoomed image */}
      <PdfViewer
        url={pdfUrl}
        open={!!showFull}
        onClose={() => setShowFull(false)}
        documentName={documentName}
        clientName={clientName}
        onDownload={onDownload}
      />

      <Box
        position="relative"
        height={height}
        width={width}
        bgcolor={bgColor || '#8F8F8F'}
        borderRadius="12px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ cursor: pdfSrc ? 'pointer' : 'default' }}
        onClick={() => pdfSrc && setShowFull(true)}
      >
        {pdfSrc ?
          <Document
            file={pdfUrl}
            noData="Loading PDF..."
            onLoadError={err => { throw err }}
            onSourceError={err => { throw err }}
          >
            <Page
              height={height}
              pageIndex={0}
              onRenderError={err => { throw err }}
              onLoadError={err => { throw err }}
              onGetTextError={err => { throw err }}
              onGetAnnotationsError={err => { throw err }}
            />
          </Document>
          :
          'No Image'
        }
        {pdfUrl && <ImageZoomTool onZoom={() => setShowFull(true)} />}
        {pdfUrl && <ImageDownloadTool onDownload={imageAction(onDownload)} />}
        {children}
      </Box>
    </React.Fragment>
  )
}
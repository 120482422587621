import React from 'react';
import { Box, Typography } from '@material-ui/core';

export default function CurrentStepHeadline(props) {

  const { currentStep, stepsCount } = props;

  return (
    <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
      <Box display={'flex'}>
        {(currentStep || stepsCount) && (<Typography variant={'caption'} style={{ color: '#656565' }}>
          Step {currentStep} out of {stepsCount}
        </Typography>)}
      </Box>
    </Box>
  )
}
import React, { createRef } from 'react';
import { SnackbarProvider } from 'notistack';
import { IconButton, makeStyles } from '@material-ui/core';
import { CloseIcon, SuccessIcon, WarningIcon, ErrorIcon } from '../../app/icons';

export const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: 4,
    position: 'absolute',
    right: 12,
    top: 3,
  },
  snackbar: {
    backgroundColor: '#FFFFFF !important',
    color: '#000000 !important',
    borderRadius: 12,
    padding: '4px 12px',
    width: 500,
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.15)',
    fontSize: 14,
    maxWidth: '90vw',
    position: 'relative',
    '& #notistack-snackbar': {
      paddingLeft: 25,
      paddingRight: 25,
      whiteSpace: 'pre-line',
      '& svg': {
        left: 8,
        top: 7,
        position: 'absolute'
      },
    },
    '& [class*="SnackbarItem-action"]': {
    }
  },
  container: {
    top: 70,
  }
}));

function CustomSnackbarProvider(props) {
  const classes = useStyles();
  const snackRef = createRef();
    
  const onDismiss = key => () => {
    snackRef.current.closeSnackbar(key);
  }

  return (
    <SnackbarProvider
      ref={snackRef}
      maxSnack={4}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={6000}
      classes={{
        containerRoot: classes.container
      }}
      className={classes.snackbar}
      iconVariant={{
        success: <SuccessIcon />,
        error: <ErrorIcon />,
        warning: <WarningIcon />
      }}
      action={(key) => (
        <IconButton onClick={onDismiss(key)} className={classes.iconButton}>
          <CloseIcon />
        </IconButton>
      )}
    >
      {props.children}
    </SnackbarProvider>
  );
}

export default CustomSnackbarProvider;

const localProfilesVersion = localStorage.getItem('profilesVersion');
const profilesVersion = localProfilesVersion ? JSON.parse(localProfilesVersion) : {};

const initialState = {
  profilesVersion,
  profilesVersionId: profilesVersion?.id !== 'last_version' ? profilesVersion?.id || '' : '',
  searchVersions: [],
};

const searchHistory = (state = initialState, action) => {

  switch (action.type) {
    case 'SET_SEARCH_HISTORY_DEFAULT':
      return {
        ...initialState,
        profilesVersion: {},
        profilesVersionId: '',
      };
    case 'SET_PROFILES_VERSION':
      return { ...state, profilesVersion: action.profilesVersion, profilesVersionId: action.id };
    case 'SET_SEARCH_VERSION':
      return { ...state, searchVersions: action.searchVersions };

    default:
      return state
  }
};

export default searchHistory;

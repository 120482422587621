import React from 'react';

import {
  Box, makeStyles, Paper, Typography
} from '@material-ui/core';
import history from '@app/history';

import EVStatusCard from './eidv-status-card';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderRadius: '12px',
    boxShadow: '0px 5px 26px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    padding: '8px 4px',
    display: 'flex',
    flexDirection: 'column',
    transition: 'width 0.4s',
    overflow: 'hidden',
  }
}));

export default function EidvItemsWrapper({ items, path, selected, pending }) {
  const classes = useStyles();

  const handleClick = (link) => () => {
    history.replace(`${path}/${link}`)
  }

  const collapse = selected !== 'overview';
  return (
    <Paper className={classes.wrapper} style={{ width: collapse ? 42 : 226 }}>
      {items.map(item => (
        <Box key={item.link}>
          <EVStatusCard
            {...item}
            onClick={handleClick(item.link)}
            selected={selected === item.link}
            collapse={collapse}
          />
        </Box>
      ))}
      <Box p={2} marginTop="auto" width={175}>
        {(selected === 'overview' && pending) && <Typography variant="body2" style={{ color: '#000', fontWeight: 300 }}>You need to review all the failed steps before finishing the case.</Typography>}
      </Box>
    </Paper>
  )
}
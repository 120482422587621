import React from 'react';
import _ from 'lodash';
import {
  capitalize,
} from '@material-ui/core';
import { Modal } from '@components/modals';

import {clientApi} from '@app/api';
import { useSnackbar } from 'notistack';


export default function DeleteDialog(props) {
  const { enqueueSnackbar } = useSnackbar();

  const { data } = props;
  const isBatch = data && data['ids'] && Array.isArray(data.ids);
  let typeText = data && (['legal_entity', 'company'].includes(data.type) ? 'legal entity' : 'person');
  if(isBatch){
    typeText = data && (['legal_entity', 'company'].includes(data.type) ? 'legal entities': 'people');
  }

  const onDelete = async () => {
    if(['person', 'company', 'legal_entity'].indexOf(_.get(data, 'type')) !== -1){
      if(isBatch) {
        for( const id of data.ids) {
          await clientApi.client.destroy(id)
        }
        enqueueSnackbar(`${data.name} ${data.ids.length > 1 ? 'were' : 'was'} successfully deleted!`, { variant: 'success' });
        props.onDeleted();
      } else {
        clientApi.client.destroy(data.id)
          .then(() => {
            enqueueSnackbar(`${capitalize(typeText)} "${data.name}" was successfully deleted!`, { variant: 'success' });
            props.onDeleted();
          });
      }
    }
    if(_.get(data, 'type') === 'folder'){
      clientApi.folder.destroy(data.id)
        .then(() => {
          enqueueSnackbar(`Folder "${data.name}" was successfully deleted!`, { variant: 'success' });
          props.onDeleted();
        });
    }
  }

  const getTitle = () => {
    if (isBatch) {
      return `Bulk delete ${typeText}`
    }
    return `Delete ${typeText}`;
  }

  const getMainText = () => {
    if (isBatch) {
      return `Are you sure you want to delete the selected ${typeText}?`
    }
    return `Are you sure you want to delete ${typeText} "${_.get(data, 'name')}"?`
  }

  const getAdditionalText = () => {
    return `${typeText === 'folder' ? '\nAll people/legal entities from this folder will be automatically moved to the HOME folder.' : ''}`
        
  }

  return (
    <React.Fragment>
      <Modal
        open={props.open}
        onClose={props.onClose}
        title={getTitle()}
        mainText={getMainText()}
        additionalText={getAdditionalText()}
        actions={
          [
            {
              type: 'main',
              label: 'CANCEL',
              action: props.onClose,
              style: { width: 160 },
            },
            {
              type: 'secondary',
              label: 'DELETE',
              action: onDelete,
              style: { width: 160 },
            },
          ]
        }
        actionsDirection="row"
      />
    </React.Fragment>
  );
}
import React from 'react';
import clsx from 'clsx';
import { Box, Chip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  rootLabelChip: (props) => ({
    height: props.versions ? 18 : 22,
    width: props.versions ? 18 : 'auto',
    borderRadius: props.versions ? '50%' : '',
    fontStyle: 'normal',
    fontWeight: props.type ? 'bold' : 700,
    alignItems: 'center',
    fontSize: 10,
    lineHeight: '8x',
    borderColor: theme.palette.background.GY2,
    '& .MuiChip-label': {
      lineHeight: props.type ? '8px' : '10px',
      color: props.labelColor,
    },
    border: props.border || (props.versions && 0),
    background: props.background || theme.palette.common.white,
    marginLeft: props.versions ? 5 : 0,
    boxShadow:
      props.shadow &&
      '0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.12)',
  }),

  reviewedChip: { color: '#9A7C6A' },
  reviewedBorder: { border: '1px solid #9A7C6A !important' },
  reviewedCircle: { '&::before': { backgroundColor: '#9A7C6A' } },

  errorChip: { color: theme.palette.semantic.error },
  errorBorder: {
    border: `1px solid ${theme.palette.semantic.error} !important`,
  },
  errorCircle: {
    '&::before': { backgroundColor: theme.palette.semantic.error },
  },

  warningChip: { color: theme.palette.semantic.warning },
  warningBorder: {
    border: `1px solid ${theme.palette.semantic.warning} !important`,
  },
  warningCircle: {
    '&::before': { backgroundColor: theme.palette.semantic.warning },
  },

  successChip: { color: theme.palette.semantic.success },
  successBorder: {
    border: `1px solid ${theme.palette.semantic.success} !important`,
  },
  successCircle: {
    '&::before': { backgroundColor: theme.palette.semantic.success },
  },

  suppressedChip: { color: theme.palette.text.GY5 },
  suppressedBorder: {
    border: `1px solid ${theme.palette.text.GY5} !important`,
  },
  suppressedCircle: {
    '&::before': { backgroundColor: theme.palette.text.GY5 },
  },

  requestedChip: { color: '#4A9BB5' },
  requestedBorder: { border: '1px solid #4A9BB5 !important' },
  requestedCircle: { '&::before': { backgroundColor: '#4A9BB5' } },

  canceledChip: { color: '#a98220' },
  canceledBorder: { border: '1px solid #a98220 !important' },
  canceledCircle: { '&::before': { backgroundColor: '#a98220' } },

  pendingChip: { color: '#B5734A' },
  pendingBorder: { border: '1px solid #B5734A !important' },
  pendingCircle: { '&::before': { backgroundColor: '#B5734A' } },

  listingBorder: { border: `1px solid ${theme.palette.text.GY2} !important` },

  circle: {
    '&::before': {
      content: '""',
      width: 8,
      height: 8,
      display: 'inline-block',
      borderRadius: '50%',
      marginRight: 5,
    },
  },
  disabled: {
    color: theme.palette.text.GY3,
  },
  versionsLabel: {
    '& .MuiChip-labelSmall': {
      padding: 0,
      textAlign: 'center',
    },
  },
  icon: {
    marginRight: '7px',
    marginLeft: '4px',
  },
  withIcon: {
    lineHeight: '11px',
  },
}));

const TYPE_ENUM = {
  error: 'error',
  deleted: 'error',
  rejected: 'error',
  expired: 'error',

  warning: 'warning',
  updated: 'warning',
  pending_approval: 'pending',

  success: 'success',
  added: 'success',
  completed: 'success',

  requested: 'requested',

  repeated: 'suppressed',
  suppressed: 'suppressed',

  canceled: 'canceled',

  reviewed: 'reviewed',
};

const CustomChip = ({
  label,
  type,
  onDelete,
  deleteIcon,
  background,
  disabled,
  versions,
  icon,
  coloredBorder,
  labelColor,
  border,
  propsClasses,
  deleteIconStyle,
  shadow,
  chipProps,
}) => {
  const classes = useStyles({
    background,
    type,
    versions,
    labelColor,
    border,
    shadow,
  });

  const contentType = type ? TYPE_ENUM[type.toLowerCase()] : '';

  return (
    <Chip
      variant="outlined"
      size="small"
      label={
        <Box display="flex" alignItems="center" height="11px">
          {icon && <Box className={classes.icon}>{icon}</Box>}
          <span
            className={clsx(
              disabled && classes.disabled,
              type && !icon && type !== 'listing' && classes.circle,
              type &&
                !icon &&
                type !== 'listing' &&
                classes[`${contentType}Circle`],
              icon && classes.withIcon
            )}
          >
            {label}
          </span>
        </Box>
      }
      onDelete={onDelete}
      classes={{
        root: clsx(
          classes.rootLabelChip,
          type && classes[`${contentType}Chip`],
          versions && classes.versionsLabel,
          coloredBorder && classes[`${contentType}Border`],
          propsClasses
        ),
        deleteIcon: deleteIconStyle,
      }}
      deleteIcon={deleteIcon}
      {...chipProps}
    />
  );
};

export default CustomChip;

import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';

import UserNoteList from '../background-check/subpages/user-note-list';
import { useSnackbar } from 'notistack';
import { useLocation, useParams } from 'react-router-dom';
import { clientApi } from '@app/api';

export default function MonitoringUserNoteList(props) {
  const { steps, onNext, baseRoute, isSaveToPortfolio } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { clientId } = useParams();
    
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [searchId] = useState(search.get('searchId'));
  const [profileId] = useState(search.get('profileId'));
  const [searchType] = useState(search.get('searchType'));
  const [profile, setProfile] = useState({});

  useEffect(() => {
    fetch().then()
    }, []) // eslint-disable-line

  const fetch = async () => {
    const profile = (await clientApi.profile.show(profileId)).data;
    setProfile(profile);
  }

  const linkClient = async () => {
    if (profile.clientId) {
      await clientApi.client.unlinkFromProfile(profile.clientId, profileId);
      await clientApi.client.unlinkFromSearch(profile.clientId, searchId);
    }

    await clientApi.client.linkToSearch(clientId, searchId);
    const client = (await clientApi.client.linkToProfile(clientId, profileId)).data;
    await clientApi.search.updateSearchReview(searchId, { bgCheckRiskRating: null })


    if (client.clientType === 'PERSON') {
      enqueueSnackbar(`"${client.firstName} ${client.lastName}" details were successfully updated!`, { variant: 'success' });
    } else {
      enqueueSnackbar(`"${client.name}" details were successfully updated!`, { variant: 'success' });
    }
    return onNext({ searchType, searchId, profileId, clientId });
  }

  return (
    <Box height="100%">
      <UserNoteList
        linkClient={linkClient}
        monitoring
        baseRoute={baseRoute}
        isSaveToPortfolio={isSaveToPortfolio}
        steps={{ ...steps, title: 'Review Profile' }}
      />
    </Box>
  )
}

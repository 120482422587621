import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import InnerHeader from '@components/layout/inner-header';
import ButtonBack from '@components/buttons/button-back';
import history from '@app/history';
import { makeStyles } from '@material-ui/core/styles';
import RiskRatingSettingsCrossFlags from '@assets/img/overlays/risk-rating-settings-cross-flags.svg';

const useStyles = makeStyles(theme => ({
  intro: {
    backgroundColor: '#F8F8F8',
    borderRadius: '12px'
  },
  listItem: {
    display: 'flex',
    minHeight: '60px',
    '& .left': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& .number': {
        display: 'flex',
        fontSize: '14px',
        lineHeight: '28px',
        borderRadius: '50%',
        width: '28px',
        height: '28px',
        color: '#484848',
        border: '1px solid #7A5B44',
        alignItems: 'center',
        justifyContent: 'center'
      },
      '& .line': {
        display: 'flex',
        borderLeft: '1px solid #7A5B44',
        flexGrow: 1,
        width: 0
      }
    },
    '& .right': {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      marginLeft: '10px',
      '& .title': {
        color: '#484848',
        fontSize: '14px',
        lineHeight: '16px'
      },
      '& .description': {
        color: '#656565',
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 300
      }
    }
  }
}))

const listItems = [
  {
    number: 1,
    title: 'Select one of our system templates',
    description: 'Institutional Clients, Trust and Private Clients'
  },
  {
    number: 2,
    title: 'Build the template',
    description: 'Include questions you need'
  },
  {
    number: 3,
    title: 'Add your own questions to complete the template',
    description: 'As many custom questions as you need'
  },
  {
    number: 4,
    title: 'Save',
    description: ''
  }
]

export default function RiskRatingSettingsCreateIntro(props) {

  const classes = useStyles()

  const onBack = () => {
    history.push('/settings/risk-rating')
  }

  const onCreate = () => {
    history.push('/settings/risk-rating/create')
  }

  return (
    <Box display={'flex'} flexDirection={'column'} flexGrow={1} mt={5}>
      <Box display={'flex'}>
        <ButtonBack onClick={onBack} />
      </Box>
      <Box display={'flex'}>
        <InnerHeader
          ind={0}
          tabs={[
            { id: 'title', label: 'Create your own template' }
          ]}
        />
      </Box>
      <Box
        display={'flex'}
        mt={3}
        style={{
          overflowY: 'scroll',
          marginLeft: '-38px',
          marginRight: '-48px'
        }}
        height="calc(100vh - 280px)"
        px={1}
        flexDirection={'column'}
      >
        <Box p={5} display={'flex'} flexDirection={'column'} alignItems={'center'} className={classes.intro}>
          <Box display={'flex'} px={{ md: 5, lg: 20 }} flexDirection={'column'}>
            <Typography variant={'h5'} align={'center'}>
              Does your company have a unique way of assessing risk? We hear you.
              <br />
              Create your custom Risk Rating template in a just a few clicks.
            </Typography>
            <Box display={'flex'} mt={3}>
              <Box display={'flex'} alignItems={'center'} ml={-5}>
                <img src={RiskRatingSettingsCrossFlags} alt={'Flags overlay'} />
              </Box>
              <Box display={'flex'} mt={3} flexGrow={1} flexDirection={'column'} justifyContent={'center'}>
                {
                  listItems.map((listItem, iListItem) => (
                    <Box className={classes.listItem} key={`list-item-${listItem.number}`}>
                      <Box className={'left'}>
                        <Box className={'number'}>
                          {listItem.number}
                        </Box>
                        {iListItem < listItems.length - 1
                          && <Box className={'line'} />
                        }
                      </Box>
                      <Box className={'right'}>
                        <Box className={'title'}>
                          {listItem.title}
                        </Box>
                        <Box className={'description'}>
                          {listItem.description}
                        </Box>
                      </Box>
                    </Box>
                  ))
                }
              </Box>
            </Box>
          </Box>
          <Box display={'flex'} mt={3}>
            <Typography variant={'h5'} align={'center'}>
              Your new custom template will be available to all users in your account
            </Typography>
          </Box>
        </Box>
        <Box display={'flex'} my={4} justifyContent={'center'}>
          <Button
            style={{ width: '250px' }}
            variant={'contained'}
            onClick={onCreate}
          >
            CONTINUE
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
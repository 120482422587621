import React, {useMemo} from 'react';
import {Box} from '@material-ui/core';
import OutlinedSearch from '@components/inputs/outlined-search';
import InlineHeadBadge from '@components/badges/inline-head-badge';
import FilterWithContext from '@components/dropdowns/filter-with-context';
import Filter from './filter';

export default function FilterSearchHeader({ scope, totalCount, search, setSearch, filters, setFilters }) {

  const title = useMemo(() =>
    scope === 'local'?
      'Local Entities': 'Global Entities',
  [scope]
  )

  const onInternalSearch = event => {
    setSearch(event.currentTarget.value)
  }

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'}>
          <Box mr={1}>
            <InlineHeadBadge
              badgeColor={'#CAD7CA'}
              badgeValue={totalCount}
            >
              {title}
            </InlineHeadBadge>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'center'} height={'48px'}>
          { scope === 'local' && (
            <FilterWithContext>
              <Filter
                filters={filters}
                setFilters={setFilters}
                scope={scope}
              />
            </FilterWithContext>
          )}
        </Box>
      </Box>
      <Box display={'flex'} mb={3}>
        <OutlinedSearch
          value={search}
          onChange={onInternalSearch}
          label={`Search ${title}`}
        />
      </Box>
    </Box>
  )
}
import React from 'react';
import { FolderSmallBlankIcon, OptionsIcon } from '@app/icons';
import {Box, IconButton, Menu, MenuItem} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  colorFill: {
    width: '35px',
    height: '23px'
  },
  menu: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    '&:hover svg': {
      display: 'block'
    },
    '&.active svg': {
      display: 'block'
    }
  },
  menuIcon: {
    display: 'none'
  }
}));

export default function FolderIconWithColor(props) {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const classes = useStyles();

  const { data } = props;

  const onMenuOpen = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setMenuAnchorEl(event.currentTarget);
  };

  const onMenuClose = () => {
    setMenuAnchorEl(null);
  };


  const handleAction = (itemId, actionHandler) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    actionHandler(itemId, event);
    onMenuClose();
  }

  return (
    <React.Fragment>
      <Box className={classes.root}>
        <FolderSmallBlankIcon />
        {data.color &&
                <Box className={classes.overlay}>
                  <Box
                    className={classes.colorFill}
                    style={{
                      background: `linear-gradient(180deg, #FFFFFF 59.9%, ${data.color} 100%)`,
                      borderRadius: '4px'
                    }}
                  />
                </Box>
        }
        <IconButton className={clsx(!!menuAnchorEl && 'active', classes.menu)} onClick={onMenuOpen} aria-haspopup="true">
          <OptionsIcon className={classes.menuIcon} />
        </IconButton>
      </Box>
      <Menu
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={() => onMenuClose()}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={handleAction(data.id, props.onUpdate)}>
                    Update
        </MenuItem>
        <MenuItem onClick={handleAction(data.id, props.onDelete)}>
                    Delete
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}
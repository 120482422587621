import React from 'react';
import { Box, Typography } from '@material-ui/core';

import LayoutHeader from '@components/layout/header';
import Footer from '@components/layout/footer';
import { Redirect } from 'react-router-dom';

export default function Unsubscribe() {
  return (
    <React.Fragment>
      <Redirect to="/settings/emails-notifications" />
      <LayoutHeader isMaintenance={true} />

      <Box display="flex" pt={15} pb={5} justifyContent="center" alignItems="center" style={{ backgroundColor: '#D5F0F0', maxWidth: '100%', overflowX: 'hidden', height: 'calc(100vh - 110px - 120px - 40px)' }}>
        <Box zIndex={2}>
          <Typography variant="h1" align="center">
            You have successfully unsubscribed!
          </Typography>
        </Box>
      </Box>
      <Footer />

    </React.Fragment>
  );
}
import React, { useMemo } from 'react';
import { Box, makeStyles, MenuItem, Select, Typography, withStyles } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const PAGE_SIZES = [12, 25, 50, 100]

const SelectStyled = withStyles((theme) => ({
  root: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    borderRadius: '8px !important',
    paddingLeft: 8,
    '&:focus': {
      backgroundColor: '#EDEDED',
    }
  },
  icon: {
    fontSize: 18,
    right: 2,
    top: 'calc(50% - 9px)'
  },
}))(Select);

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    borderRadius: 12,
  },
  menuList: {
    paddingTop: 4,
    paddingBottom: 4,
    '& .MuiMenuItem-root': {
      margin: 0,
      borderRadius: 0,
      height: 24,
      padding: 0,
      paddingLeft: 10,
    }
  },
  fontColor: {
    color: '#ACACAC',
  }
}));


export default function RegularPagination(props) {
  const classes = useStyles()
  const pageSizes = useMemo(() => {
    if (props.pageSizes) return props.pageSizes

    let sizes = PAGE_SIZES
    const isDebug = parseInt(localStorage.getItem('debug') || 0);

    if (isDebug) {
      sizes = PAGE_SIZES.concat([250])
    }

    return sizes
  }, [props.pageSizes])

  const { page, pagesCount, pageSize, setPageSize, totalCount, onChange, footer } = {
    pageSize: 10,
    ...props
  };
  const from = (page * pageSize) + 1;
  const to = Math.min((page + 1) * pageSize, totalCount);

  const onPageSizeChange = (e) => {
    setPageSize(e.target.value)
    onChange(e, 1)
  }


  return (
    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} flexGrow={1} alignItems={setPageSize ? 'flex-start' : 'center'}>
      {setPageSize ?
        <Box display="flex" alignItems="center">
          {setPageSize &&
            <Box display="flex" alignItems="center" mr={2}>
              <Box mr={1}>
                <Typography variant="caption" className={classes.fontColor}>Show rows:</Typography>
              </Box>
              <SelectStyled
                value={pageSize}
                onChange={onPageSizeChange}
                IconComponent={KeyboardArrowDownIcon}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null,
                  classes: {
                    list: classes.menuList,
                    paper: classes.menuPaper,
                  }
                }}
              >
                {pageSizes.map(size => (
                  <MenuItem key={size} value={size}>{size}</MenuItem>
                ))}
              </SelectStyled>
            </Box>
          }
          <Box display="flex" alignItems="center">
            <Typography variant={'caption'} className={classes.fontColor}>
              {from} to {to} out of {totalCount} items
            </Typography>
            {footer}
          </Box>
        </Box>
        :
        <Box display="flex" alignItems="center">
          <Typography variant={'caption'} className={classes.fontColor}>
            Showing {from} to {to} out of {totalCount}
          </Typography>
          {footer}
        </Box>
      }
      <Box display={'flex'}>
        <Pagination count={pagesCount} page={page + 1} onChange={onChange} />
      </Box>
    </Box>
  )
}
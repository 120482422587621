import React, { useState, useEffect, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import Linkify from 'linkifyjs/react';
import { SelectOutlined } from '@components/inputs';
import {Typography, Box} from '@material-ui/core';
import { RateS12HighIcon, RateS12MediumIcon, RateS12LowIcon, RateS12NotRatedIcon } from '@app/icons';
import CustomTooltip from '@components/controls/tooltip';
import RiskRatingIcon from '@components/flexible-icons/risk-rating-icon';
import getRiskRatingLabel from '@utils/riskRatingLabel';

import { useStyles } from './index';

const fields = ['WHITELIST', 'MONITORY', 'BG CHECK RISK'];
const riskFields = ['BG CHECK RISK'];
const capitalizedFields = ['GENDER']

const rating = [
  { value: 'Low', icon: <RateS12LowIcon width={14} height={14} /> },
  { value: 'Medium', icon: <RateS12MediumIcon width={14} height={14} /> },
  { value: 'High', icon: <RateS12HighIcon width={14} height={14} /> },
];

const LabelItem = (props) => {
  const {
    item, index, min, med, handleChangeRisk, handleChangeWhitelist,
    anchorProps, boxProps, risk, noLogged, disabled, handleOngoingMonitoring,
    customElement, isAdmin, labelProps, labelStyleProps, propsWrapper, fullWidth,
    fullWidthValue, valueProps
  } = props;
  const classes = useStyles({ fullWidth, fullWidthValue });
  const [rate, setRate] = useState(rating);

  useEffect(() => {
    if (risk) {
      setRate(rating.filter(item => item.value !== risk));
    }
  }, [risk])

  const handleChange = useCallback((event, ind, field) => {
    if (!event.target.value) return;

    if (riskFields.includes(field)) {
      const value = event.target.value.props.children[1];
      handleChangeRisk(value, ind);
      setRate(rating.filter(item => item.value !== value));
    } else if (field === 'WHITELIST') {
      handleChangeWhitelist(event);
    } else if (field === 'MONITORY') {
      handleOngoingMonitoring(event.target.value);
    }
  }, [handleChangeRisk, handleChangeWhitelist, handleOngoingMonitoring])

  const getRiskRatingValue = useCallback((value) => {
    return (
      <React.Fragment>
        <Box component={'span'} style={{position: 'relative'}}>
          <Box component={'span'} style={{position: 'absolute', top: 0}}>
            <RiskRatingIcon
              iconProps={{ style: {width: '12px', height: '12px'}}}
              risk={value !== 'N/A' ? value?.toLowerCase() : null}
            />
          </Box>
          <Box component={'span'} pl={2}>
            { getRiskRatingLabel(value) }
          </Box>
        </Box>
      </React.Fragment>
    )
  }, [])

  const renderLinkify = useMemo(() => {
    const { fullValue, value } = item;
    const fieldValue = (!!fullValue && fullValue.length > value.length) ? `${value}...` : value;
    return (
      <CustomTooltip
        title={fullValue}
        placement="top"
        disableHoverListener={!fullValue}
        width={'350px'}
      >
        <span><Linkify options={{ target: '_blank', className: classes.linkified }}>{fieldValue}</Linkify></span>
      </CustomTooltip>
    )
  }, [item, classes])

  const handleChangeRate = useMemo(() => {
    const { value } = item;
    if (value === 'Low') {
      return <RateS12LowIcon height={14} width={14} />
    } else if (value === 'Medium') {
      return <RateS12MediumIcon height={14} width={14} />
    } else if (value === 'High') {
      return <RateS12HighIcon height={14} width={14} />
    } else if (value === 'Not Set') {
      return <RateS12NotRatedIcon height={14} width={14} />
    }
  }, [item])

  const renderString = useMemo(() => {
    const { value, field } = item;
    return (
      <Typography
        className={clsx(
          classes.labelValue,
          min && classes.labelValueMin,
          field === 'Email Status' && classes[`email${value}`],
          capitalizedFields.includes(field) && classes.capitalize
        )}
        {...(valueProps || {})}
      >
        {field === 'RISK RATING' ? getRiskRatingValue(value) : renderLinkify}
      </Typography>
    )
  }, [item, min, classes, getRiskRatingValue, renderLinkify, valueProps])

  const renderTooltipTitle = useMemo(() => {
    if (isAdmin) {
      return 'Admin can only view details';
    }
    if (noLogged) {
      return 'Some features are restricted in this view. For an extensive searching experience please Login or Register.';
    }
    if (disabled) {
      return 'Only the last version can be updated, the previous versions are read only';
    }
  }, [isAdmin, noLogged, disabled])

  const renderSelect = useMemo(() => {
    return (
      <SelectOutlined
        value={
          riskFields.includes(item.field) ? (
            <CustomTooltip
              title={'Set BG check risk'}
              placement="top"
              disableHoverListener={noLogged || disabled || isAdmin}
            >
              <span>{item.value}</span>
            </CustomTooltip>
          ) : (
            item.value
          )
        }
        handleChange={(event) => handleChange(event, index, item.field)}
        options={riskFields.includes(item.field)
          ? rate.map(el => (
            <div>
              <span className={classes.stepSelectIconWrapper}>{el.icon}</span>
              {el.value}
            </div>
          ))
          : ['Off', 'On']
        }
        anchorProps={
          riskFields.includes(item.field) ? anchorProps : { ...anchorProps, horizontal: -30 }
        }
        disabled={disabled || noLogged}
      />
    )
  }, [anchorProps, classes, disabled, handleChange, isAdmin, noLogged, rate, index, item])

  const renderValue = useMemo(() => (
    fields.includes(item.field) ? (
      <div className={classes.stepSelectWrapper}>
        <CustomTooltip
          title={renderTooltipTitle}
          placement="top"
          disableHoverListener={!noLogged && !fields.includes(item.field)}
          width={100}
        >
          <div className={classes.stepSelectWrapper}>
            {riskFields.includes(item.field) &&
            <div className={classes.stepSelectIconWrapper}>
              {handleChangeRate}
            </div>
            }
            {renderSelect}
          </div>
        </CustomTooltip>
        {(customElement && customElement[item.field]) && customElement[item.field]}
      </div>
    ) : item.notText ? item.value : renderString
  ), [classes, customElement, handleChangeRate, item, noLogged, renderSelect, renderString, renderTooltipTitle])

  const renderField = useMemo(() => (
    <CustomTooltip
      title="Registration Number"
      placement="top"
      disableHoverListener={item.field !== 'Registration #'}
    >
      <Typography
        className={clsx(
          classes.labelField,
          min && classes.labelFieldMin,
          med && classes.labelFieldMed,
          labelStyleProps
        )}
        {...(labelProps ? labelProps : {})}
      >
        {item.field}
      </Typography>
    </CustomTooltip>
  ), [classes, item, labelProps, min, med, labelStyleProps])

  return (
    <Box className={clsx(classes.userInfoRowsWrapper, propsWrapper)} {...boxProps}>
      {renderField}
      {renderValue}
    </Box>
  )
};

export default LabelItem;

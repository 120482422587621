import React from 'react';
import { Box } from '@material-ui/core';

import history from '@app/history';
import { ChevronLeftIcon } from '@app/icons';

import ButtonWithIcon from '@components/buttons/button-with-icon';
import InnerHeader from '@components/layout/inner-header';

import { useParams } from 'react-router-dom';
import OngoingMonitoringSettings from '@components/complex/ongoing-monitoring-settings';
import { clientApi } from '@app/api';

export default function UpdateExistingMonitoring(props) {
  const { searchId, clientId, type } = useParams();
  const { steps, onNext } = props;

  const handleFinish = async (data, monitor) => {
    if (monitor) {
      if (data.ongoingMonitoringId) {
        await clientApi.ongoingMonitoring.updateOngoingMonitoring(searchId, data);
      } else {
        await clientApi.search.addSearchToMonitoring(searchId, data);
      }
    } else if (data.ongoingMonitoringId) {
      await clientApi.search.removeFromMonitoring(searchId);
    }

    return onNext({ clientId, type });
  }

  return (
    <Box height="100%">
      <Box px={6}>
        <InnerHeader
          title={'Confirm Monitoring Settings'}
          stepper={{
            currentStep: steps?.currentStep || 3,
            stepsCount: steps?.stepsCount || 3
          }}
          buttons={
            <React.Fragment>
              <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={() => { history.goBack() }}>
                {'Back'}
              </ButtonWithIcon>
            </React.Fragment>
          }
        />
      </Box>
      <Box pt={4} style={{ overflowY: 'auto', height: 'calc(100% - 98px - 32px)' }}>
        <OngoingMonitoringSettings
          type={type}
          clientId={clientId}
          searchId={searchId}
          onFinish={handleFinish}
        />
      </Box>
    </Box>
  )
}

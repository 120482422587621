import React from 'react';
import { Route, Switch } from 'react-router';
import RiskRatingSettingsList from './list';
import RiskRatingSettingsCreateIntro from './create-intro';
import RiskRatingSettingsCreate from './create';
import RiskRatingSettingsSaveAs from './save-as';
import RiskRatingSettingsUpdate from './update';
import RiskRatingSettingsView from './view';

export default function RiskRatingSettings() {
  return (
    <Switch>
      <Route exact path="/settings/risk-rating/create/intro">
        <RiskRatingSettingsCreateIntro/>
      </Route>
      <Route exact path="/settings/risk-rating/create">
        <RiskRatingSettingsCreate/>
      </Route>
      <Route exact path="/settings/risk-rating/:templateId/save-as">
        <RiskRatingSettingsSaveAs/>
      </Route>
      <Route exact path="/settings/risk-rating/:templateId/update">
        <RiskRatingSettingsUpdate/>
      </Route>
      <Route exact path="/settings/risk-rating/:templateId/view">
        <RiskRatingSettingsView/>
      </Route>
      <Route exact path="/settings/risk-rating">
        <RiskRatingSettingsList/>
      </Route>
    </Switch>
  )
}
import React, {useMemo} from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
} from '@material-ui/core';

import { getCountryName } from '@services/country-service';
import _ from 'lodash';

export default function ExistsDialog({ open, onClose, data, onView, onEdit }) {

  const title = useMemo(() => {
    return `You have already created a custom ${data.clientType === 'PERSON'? 'Person': _.capitalize(data.legalEntityType) } entity type in ${getCountryName(data.jurisdiction)}. Would you like to create a new version or view existing version?`
  }, [data])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: '30%'
        }
      }}
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <DialogTitle>
          Entity already exists
        </DialogTitle>
        <DialogContent>
          { title }
        </DialogContent>
        <DialogActions>
          <Button variant={'outlined'} onClick={onView}>
            VIEW LAST VERSION
          </Button>
          <Button variant={'contained'} onClick={onEdit}>
            CREATE NEW VERSION
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
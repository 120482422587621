const setDefault = token => ({
  type: 'SET_SCROLL_DEFAULT'
});

const setTriggerScroll = triggerScroll => ({
  type: 'SET_TRIGGER_SCROLL',
  triggerScroll,
});

const setIsRequestSent = isRequestSent => ({
  type: 'SET_REQUEST_SENT',
  isRequestSent,
});

const setNoScroll = noScroll => ({
  type: 'SET_NO_SCROLL',
  noScroll,
});

const setWrapperCardRef = wrapperCardRef => ({
  type: 'SET_WRAPPER_CARD_REF',
  wrapperCardRef,
});

const scroll = {
  setDefault,
  setTriggerScroll,
  setIsRequestSent,
  setNoScroll,
  setWrapperCardRef
}

export default scroll;
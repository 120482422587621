const emails = [
  {
    id: 1231,
    email: 'j.smith@gmail.com',
    module: 'Billing',
    subject: 'Expense report',
    receiver: 'j.wilson@gmail.com',
    updatedOn: '04/04/2021, 11:22 PM',
    emailStatus: 'Pending',
    attachments: 'invoice 12-12-..',
    failedCounter: '1',
    isChecked: false,
  },
  {
    id: 1232,
    email: 'jerome.bell@gmail...',
    module: 'Subscription..',
    subject: 'Report',
    receiver: 'm.mckinney456@g..',
    updatedOn: '04/04/2021, 11:22 PM',
    emailStatus: 'Pending',
    attachments: 'invoice 12-1...',
    failedCounter: '2',
    isChecked: false,
  },
  {
    id: 1233,
    email: 'j.wilson@gmail.com',
    module: 'Sharing Noti...',
    subject: 'Expense report',
    receiver: 'j.wilson@gmail.com',
    updatedOn: '04/04/2021, 11:22 PM',
    emailStatus: 'Failed',
    attachments: 'invoice 12-12...',
    failedCounter: '1',
    isChecked: false,
  },
  {
    id: 1234,
    email: 'm.mc.kinney@gmai..',
    module: 'Document n..',
    subject: 'Report',
    receiver: 'j.smith@gmail.com',
    updatedOn: '04/04/2021, 11:22 PM',
    emailStatus: 'Pending',
    attachments: 'report.xlsx',
    failedCounter: '0',
    isChecked: false,
  },
  {
    id: 1235,
    email: 'flores77@gmail.com',
    module: 'Sign up and l..',
    subject: 'Expense report',
    receiver: 'j.smith@gmail.com',
    updatedOn: '04/04/2021, 11:22 PM',
    emailStatus: 'Sent',
    attachments: 'report.xlsx',
    failedCounter: '0',
    isChecked: false,
  },
  {
    id: 1236,
    email: 'd.robertson@gmail...',
    module: 'Billing',
    subject: 'Report',
    receiver: 'jerome.bell@gmail..',
    updatedOn: '04/04/2021, 11:22 PM',
    emailStatus: 'Sent',
    attachments: 'report.xlsx',
    failedCounter: '2',
    isChecked: false,
  },
  {
    id: 1237,
    email: 'm.mckinney456@g...',
    module: 'Billing',
    subject: 'Report',
    receiver: 'j.smith@gmail.com',
    updatedOn: '04/04/2021, 11:22 PM',
    emailStatus: 'Failed',
    attachments: 'report.xlsx',
    failedCounter: '3',
    isChecked: false,
  },
  {
    id: 1238,
    email: 'j.bell8777@gmail...',
    module: 'Document n..',
    subject: 'Expense report',
    receiver: 'flores77@gmail.com',
    updatedOn: '04/04/2021, 11:22 PM',
    emailStatus: 'Sent',
    attachments: 'report.xlsx',
    failedCounter: '0',
    isChecked: false,
  },
]

const notifications = [
  {
    id: 1231,
    title: 'Payments Transac...',
    notification: 'Lorem...',
    recipient: 'Darrell Steward',
    isRead: 'Yes',
    readDate: '12/12/2020, 11:23 AM',
    notificationDate: '12/12/2020, 11:23 AM',
    isChecked: false,
  },
  {
    id: 1232,
    title: 'New report...',
    notification: 'Lorem...',
    recipient: 'Brooklyn Simmonsd',
    isRead: 'No',
    readDate: '',
    notificationDate: '12/12/2020, 11:23 AM',
    isChecked: false,
  },
  {
    id: 1233,
    title: 'New report...',
    notification: 'Lorem...',
    recipient: 'Theresa Webb',
    isRead: 'Yes',
    readDate: '12/12/2020, 11:23 AM',
    notificationDate: '12/12/2020, 11:23 AM',
    isChecked: false,
  },
  {
    id: 1234,
    title: 'Payments',
    notification: 'Lorem...',
    recipient: 'Kristin Watson',
    isRead: 'Yes',
    readDate: '12/12/2020, 11:23 AM',
    notificationDate: '12/12/2020, 11:23 AM',
    isChecked: false,
  },
  {
    id: 1235,
    title: 'Payments Transac...',
    notification: 'Lorem...',
    recipient: 'Jacob Jones',
    isRead: 'No',
    readDate: '',
    notificationDate: '12/12/2020, 11:23 AM',
    isChecked: false,
  },
  {
    id: 1236,
    title: 'Payments Transac...',
    notification: 'Lorem...',
    recipient: 'Savannah Nguyen',
    isRead: 'Yes',
    readDate: '12/12/2020, 11:23 AM',
    notificationDate: '12/12/2020, 11:23 AM',
    isChecked: false,
  },
  {
    id: 1237,
    title: 'Payments Transac...',
    notification: 'Lorem...',
    recipient: 'Robert Fox',
    isRead: 'Yes',
    readDate: '12/12/2020, 11:23 AM',
    notificationDate: '12/12/2020, 11:23 AM',
    isChecked: false,
  },
]

const emailDetails = [
  {
    field: 'Receiver',
    value: 'j.wilson@gmail.com',
  },
  {
    field: 'Sender',
    value: 'j.wilson@gmail.com',
  },
  {
    field: 'Updated On',
    value: '04/04/2021, 11:22 PM',
  },
  {
    field: 'Actions',
    value: 'Read (04/04/2021, 11:22 PM), CTA Pressed (04/04/2021, 11:22 PM)',
  },
  {
    field: 'Email Status',
    value: 'Pending',
  },
  {
    field: 'Failed Counter',
    value: '0',
  },
]

const attachments = [
  {
    id: '01',
    name: 'report.xlsx',
  },
  {
    id: '02',
    name: 'invoice 12-12-2020.pdf',
  },
  {
    id: '03',
    name: 'report 2021.xlsx',
  },
  {
    id: '04',
    name: 'report-q1.xlsx',
  },
]

const roles = [
  {
    id: 123,
    adminRole: 'Super Admin',
    description: 'Lorem Ipsum dolor sit amet',
    status: 'Active',
    isChecked: false,
  },
  {
    id: 456,
    adminRole: 'Admin',
    description: 'Lorem',
    status: 'Active',
    isChecked: false,
  },
  {
    id: 789,
    adminRole: 'Admin',
    description: 'Lorem',
    status: 'Blocked',
    isChecked: false,
  },
]

const admins = [
  {
    id: 111,
    fullName: 'Cody Fisher',
    firstName: 'Cody',
    lastName: 'Fisher',
    middleName: 'Junior',
    teamLead: '',
    dob: '04/04/1980',
    organization: 'Global KYC Watch',
    address: '235 W Santa Clara St, Ventura, CA 93001',
    city: 'Paris',
    state: 'Ohio',
    zipPost: '056566',
    country: 'US',
    primaryEmail: 'John.S@abccorp.com',
    otherEmail: 'jogh@gmail.com',
    phoneNumber: '+1 23 2789900',
    company: 'ABC corp',
    email: 'j.smith@gmail.com',
    lastLogin: '12/12/2020',
    status: 'Active',
    isChecked: false,
  },
  {
    id: 112,
    fullName: 'Kristin Watson',
    firstName: 'Kristin',
    lastName: 'Watson',
    middleName: 'Junior',
    teamLead: 'Brooklyn Simons',
    dob: '04/04/1980',
    organization: 'Global KYC Watch',
    address: '235 W Santa Clara St, Ventura, CA 93001',
    city: 'Paris',
    state: 'Ohio',
    zipPost: '056566',
    country: 'US',
    primaryEmail: 'John.S@abccorp.com',
    otherEmail: 'jogh@gmail.com',
    phoneNumber: '+1 23 2789900',
    company: 'ABC corp',
    email: 'j.smith@gmail.com',
    lastLogin: '12/12/2020',
    status: 'Active',
    isChecked: false,
  },
  {
    id: 113,
    fullName: 'Esther Howard',
    firstName: 'Esther',
    lastName: 'Howard',
    middleName: 'Junior',
    teamLead: 'Kathryn Murphy',
    dob: '04/04/1980',
    organization: 'Global KYC Watch',
    address: '235 W Santa Clara St, Ventura, CA 93001',
    city: 'Paris',
    state: 'Ohio',
    zipPost: '056566',
    country: 'US',
    primaryEmail: 'John.S@abccorp.com',
    otherEmail: 'jogh@gmail.com',
    phoneNumber: '+1 23 2789900',
    company: 'ABC corp',
    email: 'j.smith@gmail.com',
    lastLogin: '12/12/2020',
    status: 'Active',
    isChecked: false,
  },
  {
    id: 114,
    fullName: 'Jenny Wilson',
    firstName: 'Jenny',
    lastName: 'Wilson',
    middleName: 'Junior',
    teamLead: 'Floyd Miles',
    dob: '04/04/1980',
    organization: 'Global KYC Watch',
    address: '235 W Santa Clara St, Ventura, CA 93001',
    city: 'Paris',
    state: 'Ohio',
    zipPost: '056566',
    country: 'US',
    primaryEmail: 'John.S@abccorp.com',
    otherEmail: 'jogh@gmail.com',
    phoneNumber: '+1 23 2789900',
    company: 'ABC corp',
    email: 'j.smith@gmail.com',
    lastLogin: '12/12/2020',
    status: 'Active',
    isChecked: false,
  },
  {
    id: 115,
    fullName: 'Devon Lane',
    firstName: 'Devon',
    lastName: 'Lane',
    middleName: 'Junior',
    teamLead: 'Arlene McCoy',
    dob: '04/04/1980',
    organization: 'Global KYC Watch',
    address: '235 W Santa Clara St, Ventura, CA 93001',
    city: 'Paris',
    state: 'Ohio',
    zipPost: '056566',
    country: 'US',
    primaryEmail: 'John.S@abccorp.com',
    otherEmail: 'jogh@gmail.com',
    phoneNumber: '+1 23 2789900',
    company: 'ABC corp',
    email: 'j.smith@gmail.com',
    lastLogin: '12/12/2020',
    status: 'Active',
    isChecked: false,
  },
  {
    id: 116,
    fullName: 'Brooklyn Simms',
    firstName: 'Brooklyn',
    lastName: 'Simms',
    middleName: 'Junior',
    teamLead: 'Eleanor Pena',
    dob: '04/04/1980',
    organization: 'Global KYC Watch',
    address: '235 W Santa Clara St, Ventura, CA 93001',
    city: 'Paris',
    state: 'Ohio',
    zipPost: '056566',
    country: 'US',
    primaryEmail: 'John.S@abccorp.com',
    otherEmail: 'jogh@gmail.com',
    phoneNumber: '+1 23 2789900',
    company: 'ABC corp',
    email: 'j.smith@gmail.com',
    lastLogin: '12/12/2020',
    status: 'Blocked',
    isChecked: false,
  },
  {
    id: 117,
    fullName: 'Dianne Russell',
    firstName: 'Dianne',
    lastName: 'Russell',
    middleName: 'Junior',
    teamLead: 'Jerome Bell',
    dob: '04/04/1980',
    organization: 'Global KYC Watch',
    address: '235 W Santa Clara St, Ventura, CA 93001',
    city: 'Paris',
    state: 'Ohio',
    zipPost: '056566',
    country: 'US',
    primaryEmail: 'John.S@abccorp.com',
    otherEmail: 'jogh@gmail.com',
    phoneNumber: '+1 23 2789900',
    company: 'ABC corp',
    email: 'j.smith@gmail.com',
    lastLogin: '12/12/2020',
    status: 'Active',
    isChecked: false,
  },
  {
    id: 118,
    fullName: 'Marvin McKinn',
    firstName: 'Marvin',
    lastName: 'McKinn',
    middleName: 'Junior',
    teamLead: 'Ralph Edwards',
    dob: '04/04/1980',
    organization: 'Global KYC Watch',
    address: '235 W Santa Clara St, Ventura, CA 93001',
    city: 'Paris',
    state: 'Ohio',
    zipPost: '056566',
    country: 'US',
    primaryEmail: 'John.S@abccorp.com',
    otherEmail: 'jogh@gmail.com',
    phoneNumber: '+1 23 2789900',
    company: 'ABC corp',
    email: 'j.smith@gmail.com',
    lastLogin: '12/12/2020',
    status: 'Active',
    isChecked: false,
  },
  {
    id: 119,
    fullName: 'Eleanor Pena',
    firstName: 'Eleanor',
    lastName: 'Pena',
    teamLead: 'Wade Warren',
    dob: '04/04/1980',
    organization: 'Global KYC Watch',
    address: '235 W Santa Clara St, Ventura, CA 93001',
    city: 'Paris',
    state: 'Ohio',
    zipPost: '056566',
    country: 'US',
    primaryEmail: 'John.S@abccorp.com',
    otherEmail: 'jogh@gmail.com',
    phoneNumber: '+1 23 2789900',
    company: 'ABC corp',
    email: 'j.smith@gmail.com',
    lastLogin: '12/12/2020',
    status: 'Active',
    isChecked: false,
  },
]

const personalInformation = [
  {
    field: 'First Name',
    parseField: 'name',
    value: '',
    isAdmin: true,
  },
  {
    field: 'Middle Name',
    parseField: 'middleName',
    value: '',
    isAdmin: true,
  },
  {
    field: 'Last Name',
    parseField: 'lastName',
    value: '',
  },
  {
    field: 'Date of Birth',
    parseField: 'dob',
    value: '',
  },
  {
    field: 'Address',
    parseField: 'address',
    value: '',
  },
  {
    field: 'City',
    parseField: 'city',
    value: '',
  },
  {
    field: 'State',
    parseField: 'state',
    value: '',
  },
  {
    field: 'Zip/Postcode',
    parseField: 'zip',
    value: '',
  },
  {
    field: 'Country',
    parseField: 'country',
    value: '',
  },
]

const contactInformation = [
  {
    field: 'Primary Email',
    parseField: 'email',
    value: '',
  },
  {
    field: 'Other Email',
    parseField: 'otherEmails',
    value: '',
  },
  {
    field: 'Phone Number',
    parseField: 'phoneNumber',
    value: '',
  },
  {
    field: 'Organization',
    parseField: 'organization',
    value: '',
  },
]

const payments = [
  {
    id: 123451,
    email: 'j.smith@gmail.com',
    paymentDate: '12/12/2020',
    amount: '$299.00',
    status: 'Paid',
    listInvoices: '3 Invoices',
    isChecked: false,
    isOpen: false,
    collapse: [
      {
        subscription: 'Subscription: Basic',
        paymentDate: '',
        amount: '$99.99',
        status: '',
        listInvoices: '1 Item',
      },
      {
        subscription: 'Subscription: Pro',
        paymentDate: '',
        amount: '$99.99',
        status: '',
        listInvoices: '1 Item',
      },
      {
        subscription: 'Subscription: Ultra',
        paymentDate: '',
        amount: '$99.99',
        status: '',
        listInvoices: '1 Item',
      },
    ]
  },
  {
    id: 123452,
    email: 'j.smith@gmail.com',
    paymentDate: '12/12/2020',
    amount: '$299.00',
    status: 'Paid',
    listInvoices: '4 Invoices',
    isChecked: false,
  },
  {
    id: 123453,
    email: 'j.smith@gmail.com',
    paymentDate: '12/12/2020',
    amount: '$299.00',
    status: 'Paid',
    listInvoices: '4 Invoices',
    isChecked: false,
  },
  {
    id: 123454,
    email: 'j.smith@gmail.com',
    paymentDate: '12/12/2020',
    amount: '$299.00',
    status: 'Paid',
    listInvoices: '4 Invoices',
    isChecked: false,
  },
  {
    id: 123455,
    email: 'j.smith@gmail.com',
    paymentDate: '12/12/2020',
    amount: '$299.00',
    status: 'Paid',
    listInvoices: '4 Invoices',
    isChecked: false,
  },
  {
    id: 123456,
    email: 'j.smith@gmail.com',
    paymentDate: '12/12/2020',
    amount: '$299.00',
    status: 'Paid',
    listInvoices: '4 Invoices',
    isChecked: false,
  },
  {
    id: 123457,
    email: 'j.smith@gmail.com',
    paymentDate: '12/12/2020',
    amount: '$299.00',
    status: 'Paid',
    listInvoices: '4 Invoices',
    isChecked: false,
  },
  {
    id: 123458,
    email: 'j.smith@gmail.com',
    paymentDate: '12/12/2020',
    amount: '$299.00',
    status: 'Paid',
    listInvoices: '4 Invoices',
    isChecked: false,
  },
]

const promoCodes = [
  {
    id: 1231,
    name: 'Summer Deal',
    code: '1254547892',
    billableTypes: 'Subscription: Basic',
    usageCount: '10 / 30',
    type: '%',
    value: '10%',
    expirationDate: '12/12/2020',
    status: 'Active',
    isChecked: false,
  },
  {
    id: 1232,
    name: 'Total Sale',
    code: '1254547892',
    billableTypes: 'Subscription: Basic',
    usageCount: '10 / 30',
    type: 'Amount',
    value: '$15.00',
    expirationDate: '12/12/2020',
    status: 'Active',
    isChecked: false,
  },
  {
    id: 1233,
    name: '10% OFF',
    code: '1254547892',
    billableTypes: 'Subscription: Basic',
    usageCount: '10 / 30',
    type: 'Amount',
    value: '$15.00',
    expirationDate: '12/12/2020',
    status: 'Expired',
    isChecked: false,
  },
  {
    id: 1234,
    name: '25% OFF',
    code: '1254547892',
    billableTypes: 'Subscription: Basic',
    usageCount: '10 / 30',
    type: '%',
    value: '10%',
    expirationDate: '12/12/2020',
    status: 'Blocked',
    isChecked: false,
  },
]

const creditPacks = [
  {
    id: 111,
    name: 'Small Credit Pack',
    description: 'Lorem',
    regularCredits: '50',
    premiumCredits: '10',
    price: '$299.00',
    createdOn: '12/12/2020',
    updatedOn: '12/12/2020',
    createdBy: 'John Doe',
    status: 'Active',
  },
  {
    id: 222,
    name: 'Large Credit Pack',
    description: 'Lorem',
    regularCredits: '50',
    premiumCredits: '10',
    price: '$299.00',
    createdOn: '12/12/2020',
    updatedOn: '12/12/2020',
    createdBy: 'John Doe',
    status: 'Active',
  },
  {
    id: 333,
    name: 'XXL Credit Pack',
    description: 'Lorem',
    regularCredits: '50',
    premiumCredits: '10',
    price: '$299.00',
    createdOn: '12/12/2020',
    updatedOn: '12/12/2020',
    createdBy: 'John Doe',
    status: 'Active',
  },
  {
    id: 444,
    name: 'New Credit Pack',
    description: 'Lorem',
    regularCredits: '50',
    premiumCredits: '10',
    price: '$299.00',
    createdOn: '12/12/2020',
    updatedOn: '12/12/2020',
    createdBy: 'John Doe',
    status: 'Blocked',
  },
  {
    id: 555,
    name: 'Infinite Credit Pack',
    description: 'Lorem',
    regularCredits: '50',
    premiumCredits: '10',
    price: '$299.00',
    createdOn: '12/12/2020',
    updatedOn: '12/12/2020',
    createdBy: 'John Doe',
    status: 'Active',
  },
]

const teams = [
  {
    id: 123,
    name: 'John Smith (js@gmail.com)',
    email: 'js@gmail.com',
    firstName: 'John',
    lastName: 'Smith',
    country: 'US',
    address: '711-2880 Nulla St. Mankato Mississippi ',
    city: 'Mankato',
    state: 'Mississippi',
    zip: '96522',
    phone: '+199877846484',
    organization: 'ABC Corp',
    role: 'Account Owner',
    subscription: 'Basic',
    lastLogin: '',
    status: 'Active',
    isOpen: false,
    collapse: [
      {
        id: 111,
        name: 'Leslie Alexander',
        email: 'la@gmail.com',
        firstName: 'Leslie',
        lastName: 'Alexander',
        country: 'US',
        address: '711-2880 Nulla St. Mankato Mississippi ',
        city: 'Mankato',
        state: 'Mississippi',
        zip: '96522',
        phone: '+199877846484',
        organization: 'ABC Corp',
        role: 'Admin',
        subscription: '',
        lastLogin: '12/12/2025, 12:00:00 AM',
        status: 'Active',
        isChecked: false,
      },
      {
        id: 222,
        name: 'Devon Lane',
        email: 'dl@gmail.com',
        firstName: 'Devon',
        lastName: 'Lane',
        country: 'US',
        address: '711-2880 Nulla St. Mankato Mississippi ',
        city: 'Mankato',
        state: 'Mississippi',
        zip: '96522',
        phone: '+199877846484',
        organization: 'ABC Corp',
        role: 'Team Member',
        subscription: '',
        lastLogin: '12/12/2025, 12:00:00 AM',
        status: 'Active',
        isChecked: false,
      },
      {
        id: 333,
        name: 'Eleanor Pena',
        email: 'elp@gmail.com',
        firstName: 'Eleanor',
        lastName: 'Pena',
        country: 'US',
        address: '711-2880 Nulla St. Mankato Mississippi ',
        city: 'Mankato',
        state: 'Mississippi',
        zip: '96522',
        phone: '+199877846484',
        organization: 'ABC Corp',
        role: 'Team Member',
        subscription: '',
        lastLogin: '12/12/2025, 12:00:00 AM',
        status: 'Active',
        isChecked: false,
      },
    ]
  },
  {
    id: 456,
    name: 'Devon Lane (devonlane@hmail.com)',
    email: 'devon@gmail.com',
    firstName: 'Devon',
    lastName: 'Lane',
    country: 'FR',
    address: '711-2880 Nulla St. Mankato Mississippi ',
    city: 'Mankato',
    state: 'Mississippi',
    zip: '96522',
    phone: '+199877846484',
    organization: 'Passport',
    role: 'Account Owner',
    subscription: 'Premium',
    lastLogin: '',
    status: 'Active',
    isOpen: false,
    collapse: [
      {
        id: 444,
        name: 'Eleanor Pena',
        email: 'eleanor@gmail.com',
        firstName: 'Eleanor',
        lastName: 'Pena',
        country: 'AU',
        address: '711-2880 Nulla St. Mankato Mississippi ',
        city: 'Mankato',
        state: 'Mississippi',
        zip: '96522',
        phone: '+199877846484',
        organization: 'Passport',
        role: 'Admin',
        subscription: '',
        lastLogin: '12/12/2025, 12:00:00 AM',
        status: 'Active',
        isChecked: false,
      },
    ]
  },
  {
    id: 789,
    name: 'John Doe (jdoe@mail.com)',
    email: 'doe@gmail.com',
    firstName: 'John',
    lastName: 'Doe',
    country: 'AZ',
    address: '711-2880 Nulla St. Mankato Mississippi ',
    city: 'Mankato',
    state: 'Mississippi',
    zip: '96522',
    phone: '+199877846484',
    organization: 'Passport',
    role: 'Account Owner',
    subscription: 'Premium',
    lastLogin: '',
    status: 'Active',
    isOpen: false,
    collapse: [
      {
        id: 555,
        name: 'Rita Zauber',
        email: 'zuber@gmail.com',
        firstName: 'Rita',
        lastName: 'Zauber',
        country: 'DE',
        address: '711-2880 Nulla St. Mankato Mississippi ',
        city: 'Mankato',
        state: 'Mississippi',
        zip: '96522',
        phone: '+199877846484',
        organization: 'Passport',
        role: 'Admin',
        subscription: 'Premium',
        lastLogin: '12/12/2025, 12:00:00 AM',
        status: 'Active',
        isChecked: false,
      },
    ]
  },
]

const members = [
  {
    id: 111,
    teamEmail: 'js@gmail.com',
    teamLeader: 'Samantha Doe',
    name: 'Leslie Alexander',
    email: 'la@gmail.com',
    firstName: 'Leslie',
    lastName: 'Alexander',
    country: 'US',
    address: '711-2880 Nulla St. Mankato Mississippi ',
    city: 'Mankato',
    state: 'Mississippi',
    zip: '96522',
    phone: '+199877846484',
    organization: 'ABC Corp',
    role: 'Admin',
    subscription: '',
    lastLogin: '12/12/2025, 12:00:00 AM',
    status: 'Active',
    isChecked: false,
  },
  {
    id: 222,
    teamEmail: 'js@gmail.com',
    teamLeader: 'Samantha Doe',
    name: 'Devon Lane',
    email: 'dl@gmail.com',
    firstName: 'Devon',
    lastName: 'Lane',
    country: 'US',
    address: '711-2880 Nulla St. Mankato Mississippi ',
    city: 'Mankato',
    state: 'Mississippi',
    zip: '96522',
    phone: '+199877846484',
    organization: 'ABC Corp',
    role: 'Team Member',
    subscription: '',
    lastLogin: '12/12/2025, 12:00:00 AM',
    status: 'Active',
    isChecked: false,
  },
  {
    id: 333,
    teamEmail: 'js@gmail.com',
    teamLeader: 'Samantha Doe',
    name: 'Eleanor Pena',
    email: 'elp@gmail.com',
    firstName: 'Eleanor',
    lastName: 'Pena',
    country: 'US',
    address: '711-2880 Nulla St. Mankato Mississippi ',
    city: 'Mankato',
    state: 'Mississippi',
    zip: '96522',
    phone: '+199877846484',
    organization: 'ABC Corp',
    role: 'Team Member',
    subscription: '',
    lastLogin: '12/12/2025, 12:00:00 AM',
    status: 'Active',
    isChecked: false,
  },
  {
    id: 444,
    teamEmail: 'js@gmail.com',
    teamLeader: 'Samantha Doe',
    name: 'Eleanor Pena',
    email: 'eleanor@gmail.com',
    firstName: 'Eleanor',
    lastName: 'Pena',
    country: 'AU',
    address: '711-2880 Nulla St. Mankato Mississippi ',
    city: 'Mankato',
    state: 'Mississippi',
    zip: '96522',
    phone: '+199877846484',
    organization: 'Passport',
    role: 'Admin',
    subscription: '',
    lastLogin: '12/12/2025, 12:00:00 AM',
    status: 'Active',
    isChecked: false,
  },
  {
    id: 555,
    teamEmail: 'js@gmail.com',
    teamLeader: 'Samantha Doe',
    name: 'Rita Zauber',
    email: 'zuber@gmail.com',
    firstName: 'Rita',
    lastName: 'Zauber',
    country: 'DE',
    address: '711-2880 Nulla St. Mankato Mississippi ',
    city: 'Mankato',
    state: 'Mississippi',
    zip: '96522',
    phone: '+199877846484',
    organization: 'ABC Corp',
    role: 'Team Member',
    subscription: '',
    lastLogin: '12/12/2025, 12:00:00 AM',
    status: 'Active',
    isChecked: false,
  },
  { id: 1,
    name: 'John Doe',
    email: 'jd@gmail.com',
    firstName: 'John',
    lastName: 'Doe',
    country: 'US',
    address: '711-2880 Nulla St. Mankato Mississippi 96522',
    city: 'Mankato',
    state: 'Mississipi',
    zip: '96522',
    phone: '+199877846484',
    status: 'Active',
    isChecked: false
  },
  { id: 2,
    name: 'Samantha Smith',
    email: 'sam@google.com',
    firstName: 'Samantha',
    lastName: 'Smith',
    country: 'US',
    address: '711-2880 Nulla St. Mankato Mississippi 96522',
    city: 'Mankato',
    state: 'Mississipi',
    zip: '96522',
    phone: '+199877846484',
    status: 'Blocked',
    isChecked: false
  },
  { id: 3,
    name: 'Alex Brave',
    email: 'jd@gmail.com',
    firstName: 'Alex',
    lastName: 'Brave',
    country: 'US',
    address: '711-2880 Nulla St. Mankato Mississippi 96522',
    city: 'Mankato',
    state: 'Mississipi',
    zip: '96522',
    phone: '+199877846484',
    status: 'Invited',
    isChecked: false
  },
]

const creditPacksRequests = [
  {
    id: 123,
    requestDate: '12/12/2020',
    userName: 'John Doe',
    email: 'john@doe.com',
    creditPack: 'Small Credit Pack',
    price: '$299.00',
    status: 'New'
  },
  {
    id: 456,
    requestDate: '12/12/2020',
    userName: 'Vanesa Doe',
    email: 'vanesa@doe.com',
    creditPack: 'Small Credit Pack',
    price: '$299.00',
    status: 'Paid'
  },
  {
    id: 789,
    requestDate: '12/12/2020',
    userName: 'Mark Doe',
    email: 'mark@doe.com',
    creditPack: 'Small Credit Pack',
    price: '$299.00',
    status: 'Canceled'
  },
]

const categoryDocuments = [
  {
    id: 1,
    name: 'Evidence Of Formation',
    class: 'Legal Entity',
    association: ['CORPORATION', 'LLC', 'FOUNDATION', 'PARTNERSHIP'],
    email: 'j.smith@gmail.com',
  },
  {
    id: 2,
    name: 'PARTNERSHIP',
    class: 'Legal Entity',
    association: ['CORPORATION', 'LLC', 'TRUST', 'FOUNDATION', 'PARTNERSHIP'],
    email: 'j.smith@gmail.com',
  },
  {
    id: 3,
    name: 'Certificates',
    class: 'Legal Entity',
    association: ['CORPORATION', 'LLC', 'FOUNDATION', 'PARTNERSHIP'],
    email: 'j.smith@gmail.com',
  },
  {
    id: 4,
    name: 'Photo ID',
    class: 'Individual',
    association: ['PEOPLE'],
    email: 'j.smith@gmail.com',
  },
  {
    id: 5,
    name: 'Proof of address',
    class: 'Individual',
    association: ['PEOPLE'],
    email: 'j.smith@gmail.com',
  },
  {
    id: 6,
    name: 'Other',
    class: 'Individual',
    association: ['PEOPLE'],
    email: 'j.smith@gmail.com',
  },
  {
    id: 7,
    name: 'Registers',
    class: 'Legal Entity',
    association: ['CORPORATION', 'LLC', 'FOUNDATION', 'PARTNERSHIP'],
    email: 'j.smith@gmail.com',
  },
]

const typeDocuments = [
  {
    id: 1,
    name: 'Commercial Register Extract',
    class: 'Legal Entity',
    association: ['CORPORATION', 'LLC', 'FOUNDATION', 'PARTNERSHIP'],
    category: 'Evidence Of Formation',
    email: 'j.smith@gmail.com',
  },
  {
    id: 2,
    name: 'Cert of Incorporation/Registration',
    class: 'Legal Entity',
    association: ['CORPORATION', 'LLC', 'TRUST', 'FOUNDATION'],
    category: 'Evidence Of Formation',
    email: 'j.smith@gmail.com',
  },
  {
    id: 3,
    name: 'Memorandum And Articles/Constitution',
    class: 'Legal Entity',
    association: ['CORPORATION', 'LLC', 'FOUNDATION', 'PARTNERSHIP'],
    category: 'Constitutional Documents',
    email: 'j.smith@gmail.com',
  },
  {
    id: 4,
    name: 'Register of Directors and Officers',
    class: 'Legal Entity',
    association: ['PEOPLE'],
    category: 'Registers',
    email: 'j.smith@gmail.com',
  },
  {
    id: 5,
    name: 'Register of Shareholders/Members',
    class: 'Legal Entity',
    association: ['PEOPLE'],
    category: 'Registers',
    email: 'j.smith@gmail.com',
  },
  {
    id: 6,
    name: 'Certification of Incumbency',
    class: 'Legal Entity',
    association: ['CORPORATION', 'LLC', 'FOUNDATION', 'PARTNERSHIP'],
    category: 'Certificates',
    email: 'j.smith@gmail.com',
  },
  {
    id: 7,
    name: 'Certification of Incumbency',
    class: 'Legal Entity',
    association: ['CORPORATION', 'LLC', 'FOUNDATION', 'PARTNERSHIP'],
    category: 'Certificates',
    email: 'j.smith@gmail.com',
  },
  {
    id: 8,
    name: 'Authorized Signatory List',
    class: 'Legal Entity',
    association: ['CORPORATION', 'LLC', 'FOUNDATION', 'PARTNERSHIP'],
    category: 'Other',
    email: 'j.smith@gmail.com',
  },
  {
    id: 9,
    name: 'AML Comfort Letter',
    class: 'Legal Entity',
    association: ['LLC'],
    category: 'Other',
    email: 'j.smith@gmail.com',
  },
  {
    id: 10,
    name: 'Travel Passport',
    class: 'Individual',
    association: ['PEOPLE'],
    category: 'Photo ID',
    email: 'j.smith@gmail.com',
  },
  {
    id: 11,
    name: 'Utility Bill',
    class: 'Individual',
    association: ['PEOPLE'],
    category: 'Proof of address',
    email: 'j.smith@gmail.com',
  },
]

const documents = {
  'type': typeDocuments,
  'category': categoryDocuments,
}

export const dummy = {
  roles,
  admins,
  emails,
  payments,
  promoCodes,
  attachments,
  emailDetails,
  notifications,
  personalInformation,
  contactInformation,
  creditPacks,
  teams,
  members,
  creditPacksRequests,
  documents,
}
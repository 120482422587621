import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Box, Typography } from '@material-ui/core';
import InnerHeader from '@components/layout/inner-header';
import InnerHeaderControls from '@components/layout/inner-header-controls';
import BackButton from '@views/common/back-button';
import { clients } from '@app/api/admin';
import Form from './form';

import { useLocation } from 'react-router-dom';

// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//   input: {
//     width: '100%',
//   },
//   backButton: {
//     marginLeft: 0
//   }
// }))

const tabs = [
  {
    id: 'accountDetails',
    label: 'Account Details',
  },
  {
    id: 'billingDetails',
    label: 'Billing Details',
  },
]

export default function EditUser() {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [userId] = useState(search.get('userId'));

  const [tabID, setTabID] = useState('accountDetails');
  const [tabLabel, setTabLabel] = useState('');
  const [userData, setUserData] = useState(null);

  const getAllUsers = useCallback(async () => {
    const user = (await clients.getUserById(userId)).data;

    if (user) {
      setUserData({
        ...user,
        fullName: `${user.name} ${user.lastName}`,
        teamLead: '',
        lastLogin: '',
        status: 'Active',
      });
    }
  }, [userId])

  useEffect(() => {
    getAllUsers();
  }, [getAllUsers])

  const isAccountDetails = useMemo(() => {
    return tabID === 'accountDetails';
  }, [tabID])

  const onTabChange = (index) => {
    const tabID = tabs[index].id;
    setTabID(tabID);
    setTabLabel(tabs[index].label);
  }

  return (
    <React.Fragment>
      <Box display={'flex'} my={2} mx={1} flexDirection={'column'}>
        <InnerHeaderControls
          controlsLeft={<BackButton wrapped />}
        />
      </Box>
      <Box display={'flex'} mx={6} flexDirection={'column'}>
        <Typography variant="h4">{userData?.fullName}</Typography>
        <Box ml={-1} mb={4}>
          <InnerHeader
            onTabChange={onTabChange}
            ind={tabs.findIndex(tab => tab.id === tabID)}
            tabs={tabs}
          />
        </Box>
      </Box>
      <Box display={'flex'} mx={6} flexDirection={'column'} height={'100%'} overflow={'auto'}>
        {isAccountDetails ? (
          <Form userData={userData} isEdit />
        ) : (
          <Box display={'flex'} my={10} flexDirection={'column'} alignItems={'center'}>
            <Typography variant="h3">{tabLabel}</Typography>
          </Box>
        )}
      </Box>
    </React.Fragment>
  )
}
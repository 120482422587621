import { useState } from 'react';

function useErrorState(defaultState) {
  const [errors, setErrorsState] = useState(defaultState);

  const setErrors = (errors, shouldFocus = false) => {
    setErrorsState(errors);
    if (shouldFocus) {
      const errorIds = Object.keys(errors).filter(e => !!errors[e]);
      if (errorIds.length) {
        const el = document.getElementById(errorIds[0]);
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }
  }

  return [errors, setErrors];
}

export default useErrorState;
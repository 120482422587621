const setDefault = token => ({
  type: 'SET_LOADER_DEFAULT'
});

const setLoader = loader => ({
  type: 'SET_LOADER',
  loader,
});

const setMoreLoader = loader => ({
  type: 'SET_MORE_LOADER',
  loader,
});

const loader = {
  setDefault,
  setLoader,
  setMoreLoader,
}

export default loader;

import React from 'react';
import { Typography, Box } from '@material-ui/core';
import prepareTimestamp from '@utils/date';
import { capitalize } from '@utils/textUtil';

import { useStyles } from '../styles';

export default function AdditionalDataPersons({ item }) {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="row" alignItems="center" width={'100%'}>
      <Box display="flex" flexDirection="row" alignItems="center" width={'22%'}>
        <Typography className={classes.cardTagLabel}>FIRST NAME:</Typography>
        <Typography className={classes.cardTagValue}>{item.firstName || 'N/A'}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" width={'22%'}>
        <Typography className={classes.cardTagLabel}>MIDDLE NAME:</Typography>
        <Typography className={classes.cardTagValue}>{item.middleName || 'N/A'}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" width={'22%'}>
        <Typography className={classes.cardTagLabel}>LAST NAME:</Typography>
        <Typography className={classes.cardTagValue}>{item.lastName || 'N/A'}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" width={'22%'}>
        <Typography className={classes.cardTagLabel}>DOB:</Typography>
        <Typography className={classes.cardTagValue}>{item.dob ? prepareTimestamp(item.dob, 'MM/DD/YYYY') : 'N/A'}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" width={'12%'}>
        <Typography className={classes.cardTagLabel}>GENDER:</Typography>
        <Typography className={classes.cardTagValue}>{item.gender ? capitalize(item.gender) : 'N/A'}</Typography>
      </Box>
    </Box>
  )
}
import axios from '../axios';
import { prepareData, prepareIndexParams } from './api-utils';
import * as queryString from 'query-string';

// TODO: fake client api
const client = {
  index: (params = {}) => {
    return axios.post('clients/search', prepareIndexParams(params));
  },
  store: (data) => {
    return axios.post('clients', prepareData(data));
  },
};

const clients = {
  searchUsers: (params) => {
    return axios.post('admin/search-users', prepareIndexParams(params));
  },
  getTotalEidvs: (statuses, types) => {
    return axios.get('admin/analytics/total-eidvs', {
      params: {
        statuses: statuses,
        types: types,
      },
      paramsSerializer: (params) => {
        return queryString.stringify(params);
      },
    });
  },
  getEidvsPerUser: async (statuses, types, userId) => {
    const response = await axios.get('admin/analytics/eidvs-per-user', {
      params: {
        statuses: statuses,
        types: types,
      },
      paramsSerializer: (params) => {
        return queryString.stringify(params);
      },
    });
    const data = userId
      ? response?.data?.find((item) => item.userId === userId)
      : response?.data;
    return data;
  },
  getPerformedSearchesPerUser: () => {
    return axios.get('admin/analytics/performed-searches-per-user');
  },
  getClientsOnMonitoryPerUser: () => {
    return axios.get('admin/analytics/clients-on-monitory-per-user');
  },
  getSearchesOnMonitoryPerUser: () => {
    return axios.get('admin/analytics/searches-on-monitory-per-user');
  },
  getNotAcknowledgedSearchesPerUser: () => {
    return axios.get('admin/analytics/not-acknowledged-searches-per-user');
  },
  getPerformedTotalSearches: () => {
    return axios.get('admin/analytics/performed-total-searches');
  },
  getTotalClientsOnMonitory: (testUser = false) => {
    return axios.get('admin/analytics/total-clients-on-monitory', { params: { testUser } });
  },
  getTotalSearchesOnMonitory: (testUser = false) => {
    return axios.get('admin/analytics/total-searches-on-monitory', { params: { testUser } });
  },
  getTotalNotAcknowledgedSearches: () => {
    return axios.get('admin/analytics/total-not-acknowledged-searches');
  },
  getTotalEntities: () => {
    return axios.get('admin/analytics/total-entities');
  },
  getEntitiesPerUser: () => {
    return axios.get('admin/analytics/entities-per-user');
  },
  getUserById: (id) => {
    return axios.get(`admin/users/${id}`);
  },
  updateUser: (id, data) => {
    return axios.post(`admin/users/${id}`, data);
  },
  deleteUser: (id) => {
    return axios.delete(`admin/delete-user/${id}`);
  },
  getJobs: (pageNumber = 0, pageSize = 300) => {
    return axios.get('admin/get-jobs', { params: { pageNumber, pageSize } });
  },
};

const subscription = {
  upgradePlanPaid: (userId) => {
    return axios.post(`admin/subscription-upgrade-plan-paid/${userId}`);
  },
  renewalPlanPaid: (userId) => {
    return axios.post(`admin/subscription-renewal-plan-paid/${userId}`);
  },
  getSubscriptionForUser: (userId) => {
    return axios.get(`admin/subscription-by-user/${userId}`);
  },
};

const subscriptionPlans = {
  getAll: () => {
    return axios.get('admin/subscription-plans');
  },
  getById: (subscriptionPlanId) => {
    return axios.get(`admin/subscription-plans/${subscriptionPlanId}`);
  },
  create: (data) => {
    return axios.post('admin/subscription-plans', prepareData(data));
  },
  update: (id, data) => {
    return axios.put(`admin/subscription-plans/${id}`, prepareData(data));
  },
  delete: (id) => {
    return axios.delete(`admin/subscription-plans/${id}`);
  }
}

const invoice = {
  getInvoices: ({
    pageNumber = 0,
    pageSize = 1000,
    ...params
  }) => {
    return axios.post('admin/invoices/search', {
      pageNumber,
      pageSize,
      ...params,
    });
  },
  payInvoice: (invoiceId) => {
    return axios.put(`admin/pay-invoice/${invoiceId}`);
  },
};

const category = {
  getCategories: ({
    ownedBy,
    pageNumber = 0,
    pageSize = 1000,
    name = '',
    typeId,
  }) => {
    return axios.post('admin/document-categories/search', {
      ownedBy,
      pageNumber,
      pageSize,
      name,
      typeId,
      sortBy: 'name',
      sortDirection: 'ASC'
    });
  },
  getCategoryById: (id) => {
    return axios.get(`admin/document-categories/${id}`);
  },
  createCategory: (payload) => {
    return axios.post('admin/document-categories', payload);
  },
  updateCategory: (id, payload) => {
    return axios.put(`admin/document-categories/${id}`, payload);
  },
  deleteCategory: (id) => {
    return axios.delete(`admin/document-categories/${id}`);
  },
  linkTypeToCategory: (id, payload) => {
    return axios.put(`admin/document-categories/${id}/link-to-type/`, payload);
  },
  unlinkTypeFromCategory: (id, payload) => {
    return axios.put(
      `admin/document-categories/${id}/unlink-from-type/`,
      payload
    );
  },
};

const type = {
  getTypes: ({
    ownedBy,
    pageNumber = 0,
    pageSize = 1000,
    name = '',
    categoryId,
  }) => {
    return axios.post('admin/document-types/search', {
      ownedBy,
      pageNumber,
      pageSize,
      name,
      categoryId,
      sortBy: 'name',
      sortDirection: 'ASC'
    });
  },
  getTypeById: (id) => {
    return axios.get(`admin/document-types/${id}`);
  },
  createType: (payload) => {
    return axios.post('admin/document-types', payload);
  },
  updateType: (id, payload) => {
    return axios.put(`admin/document-types/${id}`, payload);
  },
  deleteType: (id) => {
    return axios.delete(`admin/document-types/${id}`);
  },
};

const documents = {
  getAllDocuments: async (payload, viewBy) => {
    const categoryData = (
      await category.getCategories({
        ...payload,
        pageNumber: viewBy === 'category' ? payload.pageNumber : 0,
      })
    ).data;
    const typeData = (
      await type.getTypes({
        ...payload,
        pageNumber: viewBy === 'type' ? payload.pageNumber : 0,
      })
    ).data;

    return { type: typeData, category: categoryData };
  },
};

const rulebooks = {
  getClientRulebooks: ({sort: sortBy = 'name', ...params}) => {
    params = {
      pageNumber: 0,
      pageSize: 1000,
      sortBy,
      ...params,
    };
    return axios.post('admin/client-rulebooks/search', params);
  },
  getClientRulebookById: (id) => {
    return axios.get(`admin/client-rulebooks/${id}`);
  },
  createClientRulebook: (payload) => {
    return axios.post('admin/client-rulebooks', payload);
  },
  createNewClientRulebookVersion: (id, payload) => {
    return axios.post(`admin/client-rulebooks/versions/${id}`, payload);
  },
  updateClientRulebook: (id, payload) => {
    return axios.put(`admin/client-rulebooks/versions/${id}`, payload);
  },
  remove: (id) => {
    return axios.delete(`admin/client-rulebooks/${id}`);
  },
};

const triggers = { // Only used for debug page, nowhere else.
  triggerWithoutRulebook: () => {
    return axios.post('admin/client-rulebooks/trigger-overall-client-status-set-for-clients-without-rulebook')
  },
  triggerLegals: () => {
    return axios.post('admin/client-rulebooks/trigger-rulebooks-migration-for-legals')
  },
  triggerPeople: () => {
    return axios.post('admin/client-rulebooks/trigger-rulebooks-migration-for-people')
  }
}

export {
  client,
  clients,
  invoice,
  subscription,
  subscriptionPlans,
  category,
  type,
  documents,
  rulebooks,
  triggers,
};

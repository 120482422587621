import React, { useState, useCallback, useMemo, useEffect } from  'react';
import { Box, Typography, Collapse } from '@material-ui/core';
import InnerHeader from '@components/layout/inner-header';
import { RadioButtonGroup } from '@components/buttons';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { DropdownDownIcon, DropdownUpIcon } from '@app/icons';
import CustomTooltip from '@components/controls/tooltip';

import SearchInputBlock from './search-input-block';
import AdvancedSearch from './advanced-search';
import SearchButton from './search-button';
import { Modal } from '@components/modals';
import ModalContent from './modal-content';

import { useSelector, useDispatch } from 'react-redux';
import { backgroundSearch } from '@app/store/actions';
import { PREMIUM, TABS, RADIO_ITEMS, CREDITS } from '../constants';
import { useStyles } from './styles';
import { minimumZero } from '@utils/numberUtil';

export default function SearchBlock({ currentPlan, checkExistingSearch, getEntities, openConfirmedModal, setOpenConfirmedModal, sanctions }) {
  const classes = useStyles();

  const { searchModel } = useSelector(state => state.backgroundSearch);
  const dispatch = useDispatch();

  const [isShowMore, setIsShowMore] = useState(false);

  const quantityCredits = useMemo(() => {
    return searchModel.searchProvide === PREMIUM
      ? currentPlan?.remainingPremiumCredits
      : currentPlan?.remainingRegularCredits
  }, [searchModel, currentPlan])


  useEffect(() => {
    if (searchModel.name) {
      onShowMore()
    }
    // eslint-disable-next-line
  }, [searchModel.name])

  const onTabChange = useCallback((index) => {
    const tabID = TABS[index].id;
    dispatch(backgroundSearch.setSearchModel({ searchProvider: tabID }));
    dispatch(backgroundSearch.setIsError(false));
  }, [dispatch])

  const onTypeChange = useCallback((type) => {
    dispatch(backgroundSearch.setSearchModel({ type }));
  }, [dispatch])

  const runSearch = useCallback(async () => {
    setOpenConfirmedModal(false);
    await getEntities();
  }, [getEntities, setOpenConfirmedModal])

  const onShowMore = useCallback(async () => {
    !isShowMore && setIsShowMore(true);
  }, [isShowMore])

  return (
    <Box className={classes.root} pt={3} pb={5} px={8}>
      <InnerHeader
        onTabChange={onTabChange}
        ind={TABS.findIndex(tab => tab.id === searchModel.searchProvider)}
        tabs={TABS}
      />
      <Box mt={3} mb={1} display="flex" alignItems="center" justifyContent="space-between">
        <RadioButtonGroup
          items={RADIO_ITEMS}
          handleChange={(e) => onTypeChange(e.target.value)}
          selectedValue={searchModel.type}
          row={true}
        />
        <Box className={classes.searchCreditWrapper} p={1}>
          <CustomTooltip
            title={`1 credit from the remaining ${minimumZero(currentPlan?.[`available${CREDITS[searchModel.searchProvider]}Credits`])} will be used`}
            placement="top"
          >
            <Typography className={classes.searchCreditValue}>
              {minimumZero(currentPlan?.[`available${CREDITS[searchModel.searchProvider]}Credits`])} {CREDITS[searchModel.searchProvider]?.toUpperCase()} CREDITS AVAILABLE
            </Typography>
          </CustomTooltip>
        </Box>
      </Box>
      <SearchInputBlock action={checkExistingSearch} onShowMore={onShowMore} />
      <Box>
        <ButtonWithIcon
          endIcon={isShowMore ? <DropdownUpIcon /> : <DropdownDownIcon />}
          onClick={() => setIsShowMore(bool => !bool)}
        >
          <Typography className={classes.advancedButtonLabel}>Advanced options</Typography>
        </ButtonWithIcon>
      </Box>
      <Collapse in={isShowMore}>
        <AdvancedSearch sanctions={sanctions} />
      </Collapse>
      <Box mt={2} display="flex" alignItems="center" justifyContent="flex-end">
        <SearchButton quantityCredits={quantityCredits} action={checkExistingSearch} />
      </Box>
      <Modal
        open={!!openConfirmedModal}
        onClose={() => setOpenConfirmedModal(false)}
        title={'Confirm search'}
        actionsDirection="row"
        actions={
          [
            {
              type: 'main',
              label: 'CANCEL',
              action: () => setOpenConfirmedModal(false),
            },
            {
              type: 'secondary',
              label: 'RUN SEARCH',
              action: () => runSearch(),
            },
          ]
        }
        propsClassses={classes.searchModalButton}
        content={<ModalContent />}
      />
    </Box>
  )
}

import React, { useState, useMemo } from 'react';
import { Grid, Typography, Link, FormControlLabel, Switch, Box } from '@material-ui/core';
import RadioButtonGroup from '@components/buttons/radio-button-group';
import { Delete2Icon } from '@app/icons';
import { makeStyles } from '@material-ui/core/styles';
import DateRangePicker from '@components/calendar/date-range-picker';
import CustomChip from '@components/controls/custom-chip';
import prepareTimestamp from '@utils/date';
import FilterWithContext from '@components/dropdowns/filter-with-context';

const useStyles = makeStyles((theme) => ({
  chip: {
    '& .MuiChip-label': {
      paddingRight: '4px'
    },
    verticalAlign: 'unset',
  },
  deleteChipIcon: {
    height: '14px',
    width: '14px',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: theme.palette.background.GY2
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '-3px'
  },
  filterActive: {
    backgroundColor: '#7A5B44',
    '&:hover': {
      backgroundColor: '#7A5B44',
      opacity: 0.9
    }
  }
}))


const sortItems = [
  { label: 'Run Date', value: 'createdOn', },
  { label: 'Search Name', value: 'name', },
];

const filterItems = [
  { value: 'onMonitoring', label: 'On Monitoring', isChecked: false, type: 'monitory', badgeLabel: 'On Monitoring Only' },
  { value: 'notOnMonitoring', label: 'Not on Monitoring', isChecked: false, type: 'monitory', badgeLabel: 'Not Yet On Monitoring' },
  { value: 'withChanges', label: 'With Changes', isChecked: false, type: 'acknowledged', badgeLabel: 'With Changes Only' },
  { value: 'withoutChanges', label: 'Without Changes', isChecked: false, type: 'acknowledged', badgeLabel: 'No Changes' },
  { value: 'ALEPH', label: 'Regular Search', isChecked: false, type: 'searchProvider', badgeLabel: 'Regular Search Only' },
  { value: 'COMPLY_ADVANTAGE', label: 'Premium Search', isChecked: false, type: 'searchProvider', badgeLabel: 'Premium Search Only' },
]

export default function SearchHistoryFilter({ startDate, setStartDate, endDate, setEndDate, sort, setSort, setFilters, rangeDateBackup }) {
  const [filterGroup, setFilterGroup] = useState(filterItems);

  const classes = useStyles();

  const filterBadges = useMemo(() => {
    const buffer = [];
    const monitory = filterGroup.filter(el => el.type === 'monitory');
    const acknowledged = filterGroup.filter(el => el.type === 'acknowledged');
    const searchProvider = filterGroup.filter(el => el.type === 'searchProvider');

    if (!monitory.every(el => el.isChecked)) {
      const noChecked = monitory.find(el => el.isChecked);
      !!noChecked && buffer.push(noChecked);
    }

    if (!acknowledged.every(el => el.isChecked)) {
      const noChecked = acknowledged.find(el => el.isChecked);
      !!noChecked &&  buffer.push(noChecked);
    }

    if (!searchProvider.every(el => el.isChecked)) {
      const noChecked = searchProvider.find(el => el.isChecked);
      !!noChecked &&  buffer.push(noChecked);
    }

    return buffer;
  }, [filterGroup])

  const onClearRange = () => {
    setStartDate(null)
    setEndDate(null)
  }

  const onFilterChange = (event, item) => {
    const changed = !event 
      ? filterGroup.map(el =>
        el.type === item.type ? ({ ...el, isChecked: true }) : el
      )
      : filterGroup.map(el =>
        el.value === event.target.value ? ({ ...el, isChecked: event.target.checked }) : el
      );
    setFilterGroup(changed);

    const currentType = changed.filter(el => el.type === item.type);

    let value = null;

    if (currentType.every(el => el.isChecked)) {
      setFilters(items => ({ ...items, [item.type]: value }));
    } else {
      const checkedItem = currentType.find(el => el.isChecked);

      switch (checkedItem?.value) {
        case 'onMonitoring': {
          value = true;
          break;
        }
        case 'notOnMonitoring': {
          value = false;
          break;
        }
        case 'withChanges': {
          value = false;
          break;
        }
        case 'withoutChanges': {
          value = true;
          break;
        }
        case 'ALEPH': {
          value = 'ALEPH';
          break;
        }
        case 'COMPLY_ADVANTAGE': {
          value = 'COMPLY_ADVANTAGE';
          break;
        }
        default: {
          value = null;
        }
      }
      setFilters(items => ({ ...items, [item.type]: value }));
    }
  }

  const onDelete = (event, item) => {
    onFilterChange(null, item);
  }

  const renderFiltersBadges = () => (
    filterBadges.map((el, i) => (
      <Box key={`${el.value}-${i}`} ml={1}>
        <CustomChip
          label={el.badgeLabel}
          deleteIcon={
            <Box className={classes.deleteChipIcon}>
              <Delete2Icon viewBox="0 0 17 12" />
            </Box>
          }
          onDelete={(e) => onDelete(e, el)}
          propsClasses={classes.chip}
        />
      </Box>
    ))
  )

  const renderBadges = () => {
    if (startDate && endDate) {
      return (
        <React.Fragment>
          {!!filterBadges.length && renderFiltersBadges()}
          <Box ml={1}>
            <CustomChip
              label={`From ${prepareTimestamp(startDate, 'MM/DD/YYYY')} To ${prepareTimestamp(endDate, 'MM/DD/YYYY')}`}
              deleteIcon={
                <Box className={classes.deleteChipIcon}>
                  <Delete2Icon viewBox="0 0 17 12" />
                </Box>
              }
              onDelete={onClearRange}
              propsClasses={classes.chip}
            />
          </Box>
        </React.Fragment>
      )
    }

    if (!!filterBadges.length) {
      return renderFiltersBadges();
    }

    return (
      <Box ml={1}>
        <CustomChip label={'All Search Results'} />
      </Box>
    );
  }

  return (
    <Box my={'30px'} display="flex" alignItems="center">
      <FilterWithContext
        label="FILTER"
        filterActive={(rangeDateBackup || filterGroup.find(el => el.isChecked)) && classes.filterActive}
        align="left"
      >
        <Grid container spacing={2}>
          <Grid item sm={2}>
            <Box display={'flex'} pt={1} pb={1}>
              <Typography variant="h5">
                Sort By
              </Typography>
            </Box>
            <RadioButtonGroup
              items={sortItems}
              handleChange={(e) => setSort(e.target.value)}
              selectedValue={sort}
              formControlSize={40}
            />
          </Grid>
          <Grid item sm={2}>
            {
              [
                {type: 'monitory', groupLabel: 'Monitory'},
                {type: 'acknowledged', groupLabel: 'Changes'},
                {type: 'searchProvider', groupLabel: 'Search Type'}
              ].map((groupInfo, iGroupInfo) => (
                <React.Fragment>
                  <Box display={'flex'} pt={!iGroupInfo? 1: 2} pb={1}>
                    <Typography variant="h5">
                      { groupInfo.groupLabel }
                    </Typography>
                  </Box>
                  <Box display={'flex'} flexDirection={'column'}>
                    {filterGroup.filter(el => el.type === groupInfo.type).map((el, i) => (
                      <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value}-${el.name}-i`}>
                        <FormControlLabel
                          key={`${el.value} - ${i}`}
                          control={<Switch value={el.value} checked={el.isChecked} onClick={(e) => onFilterChange(e, el)} />}
                          label={el.label}
                        />
                      </Box>
                    ))}
                  </Box>
                </React.Fragment>
              ))
            }
          </Grid>
          <Grid item sm={7}>
            <Box display={'flex'} pl={3} pt={1} pb={1}>
              <Typography variant="h5">
                Filter by Run Date
              </Typography>
            </Box>
            <Box display={'flex'} style={{width: '700px'}}>
              <DateRangePicker
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
            </Box>
            <Box display={'flex'} pl={3}>
              <Link
                onClick={onClearRange}
                underline={'none'}
                variant={'body1'}
              >
                CLEAR DATE RANGE
              </Link>
            </Box>
          </Grid>
        </Grid>
      </FilterWithContext>
      {renderBadges()}
    </Box>
  )
}

import React from 'react';
import {Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {ViewListGridIcon, ViewListGridActiveIcon} from '@app/icons';


const useStyles = makeStyles((theme) => ({
  root: {

  }
}));

/**
 * props: {
 *     value: 'cards|list',
 *     onChange: (value)
 * }
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ViewToggle(props) {

  const classes = useStyles();

  return (
    <Button
      aria-label="view-toggle"
      className={classes.root}
      onClick={() => {
        props.onChange && props.onChange(
          props.value === 'cards'? 'list': 'cards'
        )
      }}
    >
      {props.value === 'list' &&
                <ViewListGridActiveIcon/>
      }
      {props.value === 'cards' &&
                <ViewListGridIcon/>
      }
    </Button>
  )
}
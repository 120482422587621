import MonitoringJobs from '@views/protected/admin/monitoring-jobs';

const routes = [
  {
    path: '/monitoring-jobs',
    component: MonitoringJobs,
    guest: false,
  },
]

export default routes;
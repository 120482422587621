import prepareTimestamp from '@utils/date';

export const prepareDocuments = (data) => {
  return (
    data?.map((el) => {
      let dates;
      if (el.updateDate || el.issueDate || el.expiryDate) {
        const uploadDate = el.uploadDate
          ? prepareTimestamp(el.uploadDate, 'MM/DD/YYYY', false)
          : 'N/A';
        const issueDate = el.issueDate
          ? prepareTimestamp(el.issueDate, 'MM/DD/YYYY', false)
          : 'N/A';
        const expiryDate = el.expiryDate
          ? prepareTimestamp(el.expiryDate, 'MM/DD/YYYY', false)
          : 'N/A';

        dates = {
          uploadDate,
          issueDate,
          expiryDate,
        };
      }
      return {
        ...el,
        folderName: `${el.folderName}`,
        ...dates,
      };
    }) || []
  );
};

export const getSortField = {
  documentName: 'documentName',
  uploadDate: 'uploadDate',
  issueDate: 'issueDate',
  expiryDate: 'expiryDate',
  statusLabel: 'status',
};

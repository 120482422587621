import prepareTimestamp from '@utils/date';
import { getCountryName } from '@services/country-service';

export const prepareToDoListData = (data) => {
  return (
    data?.map((el) => {
      let review;
      if (el.dateOfReview || el.nextReview) {
        const dateOfReview = el.dateOfReview
          ? prepareTimestamp(el.dateOfReview, 'MM/DD/YYYY', false)
          : 'N/A';
        const nextReview = el.nextReview
          ? prepareTimestamp(el.nextReview, 'MM/DD/YYYY', false)
          : 'N/A';
        review = `${dateOfReview} / ${nextReview}`;
      }
      let dates;
      if (el.updateDate || el.issueDate || el.expiryDate) {
        const uploadDate = el.uploadDate
          ? prepareTimestamp(el.uploadDate, 'MM/DD/YYYY', false)
          : 'N/A';
        const issueDate = el.issueDate
          ? prepareTimestamp(el.issueDate, 'MM/DD/YYYY', false)
          : 'N/A';
        const expiryDate = el.expiryDate
          ? prepareTimestamp(el.expiryDate, 'MM/DD/YYYY', false)
          : 'N/A';

        dates = {
          uploadDate,
          issueDate,
          expiryDate,
        };
      }
      return {
        ...el,
        name:
          el.clientType === 'PERSON'
            ? `${el.firstName} ${el.lastName}`
            : el.name,
        country: getCountryName(el?.residency || el?.registeredOfficeCountry),
        type: el.clientType === 'PERSON' ? el.clientType : 'LEGAL ENTITY',
        folderName: `${el.folderName}/`,
        review,
        ...dates,
      };
    }) || []
  );
};

const getSortField = (type) => {
  let sortField = {
    folderName: 'folderName',
    riskRating: 'riskRating',
    review: 'nextReview',
    nextReview: 'nextReview',
    type: 'clientType',
    uploadDate: 'uploadDate',
    issueDate: 'issueDate',
    expiryLabel: 'expiryDate',
    statusLabel: 'status',
    initiatedDate: 'initiatedDate',
    updatedDate: 'updatedDate',
  };
  if (type === 'all') {
    return sortField;
  } else {
    return {
      ...sortField,
      name: type === 'person' ? 'firstName' : 'name',
      country: type === 'person' ? 'residency' : 'registeredOfficeCountry',
    };
  }
};
export const prepareSort = (field, type) => {
  const sortFields = getSortField(type);
  return sortFields[field] || null;
};

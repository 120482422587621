import React from 'react';
import { SidesLayout, SidesLayoutRight } from '../../components/layout/sides-layout';
import {
  Box,
  Typography,
  Grid,
  Button,
  Link
} from '@material-ui/core';

import AuthHeader from './auth-header';
import history from '../../app/history';
import auth from '../../app/auth';
import { useSnackbar } from 'notistack';

export default function ActivationFailed(props) {
  const { enqueueSnackbar } = useSnackbar();

  const urlParams = new URLSearchParams(window.location.search);
  const encodedEmail = urlParams.get('user')
  const email = atob(encodedEmail)

  const onResend = function () {
    auth.resendActivationEmail(email)
      .then(() => {
        history.push({
          pathname: '/instructions-sent',
          search: `?user=${btoa(email)}`
        })
      })
      .catch(err => {
        enqueueSnackbar(`Email failed to be resent! Reason: ${err.message}`, { variant: 'error' });
      })
  }

  return (
    <SidesLayout>
      <SidesLayoutRight
        header={<AuthHeader />}
        footer={
          <Box display={'flex'} justifyContent={'center'}>
            <Box display={'flex'}>
              <Typography variant={'body1'} align={'center'}>
                {'If you need any help at all, don’t hesitate to '}
                <Link variant={'body1'} target="_blank" href="mailto:support@kycpassport.com">
                                    Contact Us
                </Link>
                {'. Humans will reply!'}
              </Typography>
            </Box>
          </Box>
        }
      >

        <Grid container justify={'center'} alignItems={'center'}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Box mb={3}>
                <Typography variant={'h1'} align={'center'}>Activation failed</Typography>
              </Box>
            </Grid>
            {email &&
                            <Grid item xs={12}>
                              <Box display={'flex'} justifyContent={'center'} mt={6}>
                                <Button
                                  onClick={onResend}
                                  variant="contained"
                                  size="large"
                                >
                                        RESEND EMAIL
                                </Button>
                              </Box>
                            </Grid>
            }
          </Grid>
        </Grid>

      </SidesLayoutRight>
    </SidesLayout>
  );
}
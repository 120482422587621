import React from 'react';
import { Box, Typography } from '@material-ui/core';
import CustomTooltip from '@components/controls/tooltip';
import { WarningIcon } from '@app/icons';

const Edited = () => (
  <Box>
    <CustomTooltip placement="top" title="This field was edited by client">
      <WarningIcon />
    </CustomTooltip>
  </Box>
)

export default function Value ({ data }) {
  return (
    <Box display="flex" alignItems="center">
      <Typography
        variant="body1"
        style={{
          color: '#232323',
          lineHeight: '20px',
          wordBreak: 'break-word'
        }}
      >
        {data?.value || 'N/A'}
      </Typography>
      {data?.edited && <Edited />}
    </Box>
  )
}
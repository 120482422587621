const config = {
  MuiPickersToolbar: {
    toolbar: {
      minHeight: 30,
      height: 30,
      '& button': {
        '& .MuiButton-label': {
          '& .MuiPickersToolbarText-toolbarBtnSelected': {
            fontSize: 12
          }
        }
      }
    },
  },
  MuiPickersBasePicker: {
    pickerView: {
      minWidth: 168,
      width: 168,
      minHeight: 90,
    }
  },
  MuiPickersYearSelection: {
    container: {
      height: 90,
    }
  },
  MuiPickersYear: {
    root: {
      height: 25,
    }
  },
}

export default config
import React, { useState, useEffect } from  'react';
import { Box, Divider } from '@material-ui/core';
import { DownloadIcon, DownloadInactiveIcon, SaveIcon, SaveDisabledIcon, AddMonitoringIcon, AddMonitoringInactiveIcon } from '@app/icons';
import CustomIconButton from '@components/buttons/button-with-icon';
import CustomTooltip from '@components/controls/tooltip';
import InnerHeader from '@components/layout/inner-header';
import SubPagesFooter from './components/sub-pages-footer';
import { clientApi } from '@app/api';
import { makeStyles } from '@material-ui/core/styles';

import SearchProfiles from './sub-pages/search-profiles';
import SearchSources from './sub-pages/search-sources';

import { sourcePrepareData } from '@utils/prepare-data/prepareSourcesData';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
    height: 'calc(100% - 60px)',
    width: '100%',
  },
  newSearchContentBlockWrapper: {
    width: '100%',
  },
  dividerLength: {
    width: '100%',
    margin: '10px 0',
  },
  positionIcon: {
    margin: '0 0 3px -4px',
  },
}))

const tooltipTitle = 'Some features are restricted in this view. For an extensive searching experience please Login or Register.';
const buttons = [
  { label: 'Add to Ongoing Monitoring', icon: <AddMonitoringInactiveIcon /> || <AddMonitoringIcon /> },
  { label: 'Save to Portfolio', icon: <SaveDisabledIcon /> || <SaveIcon /> },
  { label: 'Export Report', icon: <DownloadInactiveIcon /> || <DownloadIcon /> },
]

export default function SubRoutes({ profilesProps, quantitySearches, profileId, setProfileId }) {
  const classes = useStyles();

  const [searchId] = useState(profilesProps.searchId);
  const [searchName, setSearchName] = useState(profilesProps.name);

  const [subpages, setSubpages] = useState('');

  const [countProfiles, setCountProfiles] = useState(null);
  const [usersList, setUsersList] = useState([]);

  const [sourcesData, setSourcesData] = useState([]);
  const [mappedSources, setMappedSources] = useState([]);
    
  useEffect(() => {
    getData();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId])

  if (!profilesProps) return;

  const getData = async () => {
    const res = await clientApi.search.getProfiles(searchId, 0, 2, false, true);

    if (res.status === 200) {
      const data = res.data.entries.map(el => ({ ...el,  isChecked: false }))
      setCountProfiles(res.data.count);
      setUsersList(data);
      setSubpages('profiles');
    }
  }
  
  const checkboxHandleChange = (id) => {
    const changeUser = usersList.map(
      (user) => user.id === id
        ? ({ ...user, isChecked: !user.isChecked })
        : user
    );
    setUsersList(changeUser);
    // setIsSelectAll(changeUser.every(user => user.isChecked));
  }

  const getSources = async (profileId, name) => {
    const res = await clientApi.profile.getSources(profileId, true);

    if (res.status === 200) {
      setSearchName(name);
      setProfileId(profileId);
      const data = sourcePrepareData(res.data);
      setSourcesData(data);
      setMappedSources(data.filter((el, i) => i <= 2));
      setSubpages('sources');
    }
  }

  return (
    <Box className={'search-results'} display={'flex'} mb={'280px'} mt={'30px'}>
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} className={classes.root}>
        <Box className={classes.newSearchContentBlockWrapper}>
          {quantitySearches !== null && (
            <React.Fragment>
              <InnerHeader
                tabs={[{ label: searchName, infoIcon: true }]}
                searchInfo={subpages === 'profiles'
                  ? `${usersList.length} out of ${countProfiles} Matches listed`
                  : `${mappedSources.length} out of ${sourcesData.length} Hits listed`
                }
                // renderTabContent={handleActiveTab}
                ind={0}
                buttons={
                  buttons.map(el => (
                    <CustomTooltip
                      title={tooltipTitle}
                      placement="top"
                      key={el.label}
                    >
                      <span>
                        <CustomIconButton
                          onClick={() => {}}
                          startIcon={el.icon}
                          className="minLabel"
                          disabled={true}
                          classes={{ startIcon: classes.positionIcon }}
                        >
                          {el.label}
                        </CustomIconButton>
                      </span>
                    </CustomTooltip>
                  ))
                }
              />
              {subpages === 'profiles' && (
                <SearchProfiles
                  usersList={usersList}
                  checkboxHandleChange={checkboxHandleChange}
                  getSources={getSources}
                />
              )}
              {subpages === 'sources' && (
                <SearchSources
                  sourcesData={mappedSources}
                  goBack={() => {
                    setSearchName(profilesProps.name);
                    setProfileId('');
                    setSubpages('profiles')
                  }}
                />
              )}
              <Divider className={classes.dividerLength} />
            </React.Fragment>
          )}
          <SubPagesFooter
            quantitySearches={quantitySearches}
            noProfiles={!usersList.length}
            searchId={searchId}
            profileId={profileId}
            searchName={searchName}
          />
        </Box>
      </Box>
    </Box>
  )
};
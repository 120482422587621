export const sanctionsList = {
  'FTM_US_OFAC': <span>The <span style={{ fontWeight: 'bold' }}>Office of Foreign Assets Control (OFAC)</span> is a financial intelligence and enforcement agency of the U.S. Treasury Department. It administers and enforces economic and trade sanctions in support of U.S. national security and foreign policy objectives.</span>,
  'FTM_US_OFAC_SDN': <span>The <span style={{ fontWeight: 'bold' }}>Office of Foreign Assets Control (OFAC)</span> is a financial intelligence and enforcement agency of the U.S. Treasury Department. It administers and enforces economic and trade sanctions in support of U.S. national security and foreign policy objectives.</span>,
  'FTM_INTERPOL_RED_NOTICES':<span>An <span style={{ fontWeight: 'bold' }}>Interpol Red Notice</span> is a request to law enforcement worldwide to locate and provisionally arrest a person pending extradition, surrender, or similar legal action.<br/><span style={{ fontWeight: 'bold' }}>Interpol</span> (the International Criminal Police Organization) <span style={{ fontWeight: 'bold' }}>circulates</span> notices to member countries listing persons who are wanted for extradition.</span>,
  'FTM_EU_MEPS': <span>Lists  Members of the European Parliament. MEP is a person who has been elected to serve as a popular representative in the European Parliament.</span>,
  'FTM_US_CIA_WORLD_LEADERS': <span>A CIA online directory of World Leaders and Cabinet Members of Foreign Governments. The list covers over 195 governments and is updated weekly. It includes individuals active within the past 15 years.</span>,
  'FTM_AU_DFAT_SANCTIONS': <span>An economic sanctions list created by the Australian government in order to control money laundering and financing of terrorism. The list is implemented, maintained, and enforced by the Department of Foreign Affairs and Trade (DFAT).</span>,
  'FTM_EU_FSF': <span>The consolidated list of persons, groups, and entities subject to EU financial sanctions.</span>,
  'FTM_WORLDBANK_DEBARRED': <span>World Bank Listing of Ineligible Firms and Individuals.</span>,
  'FTM_CH_SECO_SANCTIONS': <span><span style={{ fontWeight: 'bold' }}>The Swiss State Secretariat for Economic Affairs (SECO)</span> is the federal government`s center of excellence for all core issues relating to economic and labor market policy. SECO maintains the Swiss list of sanctioned individuals and entities.</span>,
  'FTM_US_BIS_DENIED': <span>The <span style={{ fontWeight: 'bold' }}>Denied</span> Persons List is a list of people and companies whose export privileges have been <span style={{ fontWeight: 'bold' }}>denied</span> by the Department of Commerce's Bureau of Industry and Security <span style={{ fontWeight: 'bold' }}>(BIS)</span>. An <span style={{ fontWeight: 'bold' }}>American</span> company or individual may not participate in an export transaction with an individual or company on the <span style={{ fontWeight: 'bold' }}>Denied</span> Persons List.</span>,
  'FTM_UN_SC_SANCTIONS': <span><span style={{ fontWeight: 'bold' }}>The United Nations Security Council (UNSC)</span> is a sanctions list of people and companies created in response to a threat to international peace and security.</span>,
  'FTM_EVERYPOLITICIAN': <span>An open dataset of politicians from 233 countries, with over 3.1 million data points so far.</span>,
  'FTM_KG_FIU_NATIONAL': <span>A list of sanctioned individuals and entities published by the Kyrgyzstan State Financial Intelligence Service.</span>,
  'FTM_GB_HMT_SANCTIONS': <span>The HM Treasury Sanctions List contains the names and other identifying information of individuals and entities currently subject to UK sanctions. Those sanctions take the form of asset freezes and market access restrictions.</span>,
  'FTM_CA_DFATD_SEMA_SANCTIONS': <span>The Consolidated Canadian Autonomous Sanctions List includes individuals and entities subject to specific sanctions regulations made under the <span style={{ fontStyle: 'italic' }}>Special Economic Measures Act (SEMA) and the Justice for Victims of Corrupt Foreign Officials Act (JVCFOA)</span>.</span>,
  'FTM_COE_ASSEMBLY': <span>The Parliamentary Assembly of the Council of Europe is the parliamentary arm of the Council of Europe, a 47-nation international organisation dedicated to upholding human rights, democracy and the rule of law. The list contains the names of members and it includes European countries including Turkey and Russia.</span>,
  'FTM_UA_SDFM_BLACKLIST': <span>Ukraine's financial intelligence unit publishes this list of sanctioned individuals.</span>,
  'FTM_UA_SFMS_BLACKLIST': <span>Ukraine's financial intelligence unit publishes this list of sanctioned individuals.</span>,
  'FTM_US_OFAC_CONS': <span>The <span style={{ fontWeight: 'bold' }}>Office of Foreign Assets Control (OFAC)</span> is a financial intelligence and enforcement agency of the U.S. Treasury Department. It administers and enforces economic and trade sanctions in support of U.S. national security and foreign policy objectives.</span>,
  'FTM_CA_LISTED_TERRORISTS': <span>The Canadian governments own list of people and organizations associated with terrorism.</span>,
  'FTM_RU_NAVALNY35': <span>A list of suggested sanctions targets published by Navalny's FBK in a letter to Dominic Raab.</span>,
  'FTM_NZ_RUSSIA_SANCTIONS': <span>The New Zealand government's list of people and companies sanctioned in relation to the Russian attack on Ukraine.</span>,
  'FTM_US_TRADE_CSL': <span>The Consolidated Screening List (CSL) is a list of parties for which the United States Government maintains restrictions on certain exports, reexports, or transfers of items.</span>,
  'FTM_CZ_BUSINESS_REGISTER': <span>Czech business register with company metadata and directorships as obtained from the open data xml dump by ARES.</span>,
  'FTM_RU_RUPEP': <span>RuPEP is a detailed database of politically exposed persons in Russia and Belarus maintained by qualified researchers</span>,
  'FTM_ADB_SANCTIONS': <span>The ADB's published sanctions list. It contains the names of entities who violated the sanctions while ineligible; entities who committed second and subsequent violations; debarred entities who are uncontactable; and cross debarred entities.</span>,
  'FTM_RU_BILLIONAIRES_2021': <span>Based on an annual assessment of wealth and assets compiled and published by Forbes magazine in 2021.</span>,
  'FTM_AR_REPET': <span>Public Registry of Persons and Entities linked to acts of Terrorism and their Financing</span>,
  'FTM_RU_NSD_ISIN': <span>ISIN security identifiers assigned in Russia and subject to a US investment ban.</span>,
  'FTM_DATASET_EU_SANCTIONS_MAP': <span>The EU Sanctions Map is a visualisation of EU sanctions policy. It includes a broader set of policies and entities than the EU consolidated sanctions list.</span>,
  'FTM_IL_MOD_TERRORISTS': <span>The EU Sanctions Map is a visualisation of EU sanctions policy. It includes a broader set of policies and entities than the EU consolidated sanctions list.</span>,
  'FTM_RU_FEDSFM_WMD': <span>List of organizations and individuals in relation to which there is information about their involvement in the proliferation of weapons of mass destruction</span>,
  'FTM_GB_NCA_MOST_WANTED': <span>Most wanted list of criminals and fugitives in Britain, maintained by the United Kingdom's National Crime Agency (NCA)</span>,
  'FTM_GB_COH_PSC': <span>Individuals which have been disqualified from holding the post of a company director in the United Kingdom following a court decision.</span>,
  'FTM_EU_COR_MEMBERS': <span>The European Committee of the Regions (CoR) represents local and regional authorities across the European Union and advises on new laws that have an impact on regions and cities.</span>,
  'FTM_QA_NCTC_SANCTIONS': <span>This dataset contains both UN-mandated and the national sanctions designations for Qatar (Targeted Financial Sanctions).</span>,
  'FTM_PL_MSWIA_SANCTIONS': <span>Decisions by the Polish Interior Ministry regarding entities linked to the aggression in Ukraine</span>,
  'FTM_AE_LOCAL_TERRORISTS': <span>The UAE's asset freeze list of individuals and legal entities that are suspected of, attempt to, or commit terrorist acts.</span>,
  'FTM_NL_MOST_WANTED': <span>The most wanted persons are suspected of serious crimes and are ranked high on the police's hit list.</span>,
  'FTM_IADB_SANCTIONS': <span>The firms and individuals listed have been sanctioned for having engaged in fraudulent, corrupt, collusive, coercive or obstructive practices, in violation of the IDB Group’s Sanctions Procedures and anti-corruption policies.</span>,
  'FTM_EBRD_INELIGIBLE': <span>The EBRD published integrity sanctions. The entities and individuals listed are ineligible to become a Bank Counterparty for the periods indicated.</span>,
  'FTM_AFDB_SANCTIONS': <span>Individuals and firms that have been sanctioned by AfDB, for having participated in coercive, collusive, corrupt, fraudulent or obstructive practices under the Bank’s sanctions system.</span>,
  'FTM_BG_OMNIO_POI': <span>A list of persons and companies sanctioned for involvement with terrorism financing released by the government in Bulgaria</span>,
  'FTM_CA_FACFOA': <span>The Canadian governments maintains a small, Magnitsky-style list of government officials that it alleges to be involved in corruption.</span>,
  'FTM_ICIJ_OFFSHORELEAKS': <span>Combined data from the Panama Papers, Paradise Papers, Pandora Papers and other cross-border investigations conducted by ICIJ and its partners.</span>,
  'FTM_RU_ACF_BRIBETAKERS': <span>A list of key Russian decision-makers from government and business composed by ACF/FBK, the anti-corruption investigative organization founded by Alexei Navalny</span>,
  'FTM_JP_MOF_SANCTIONS': <span>Sanctions imposed by Japan under its Foreign Exchange and Foreign Trade Law.</span>,
  'FTM_US_CUBA_SANCTIONS': <span>Several lists of Cuban companies and hotels that are blocked by the US State Department separately from the sanctions lists maintained by the Treasury.</span>,
  'FTM_OPENCORPORATES': <span>The largest open database of company data in the world, with data from over 140 registries.</span>,
  'FTM_GLEIF': <span>A concatenated data file of all entities which have been issued Legal Entity Identifier (LEI) codes.</span>,
  'FTM_EU_EUROPOL_WANTED': <span>Wanted fugitivies listed on a Europol-operated web site.</span>,
  'FTM_CY_COMPANIES': <span>Cypriot open data on companies, which names officers but no shareholders.</span>,
  'FTM_IN_MHA_BANNED': <span>List of banned organizations under the Unlawful Activities (Prevention) Act.</span>,
  'FTM_RU_EGRUL': <span>Companies database of the Russian Federation as maintained and published by the Federal Tax Service.</span>,
  'FTM_UA_NABC_SANCTIONS': <span>A list of suggested Russia-related sanctions targets composed by the Ukrainian anti-corruption agency.</span>,
  'FTM_UA_NSDC_SANCTIONS': <span>List of individuals and legal entities subject to restrictive measures.</span>,
  'FTM_WD_OLIGARCHS': <span>A list of oiligarchs from CIS countries, most notably the Russian Federation.</span>,
  'FTM_WD_PEPS': <span>Profiles of politically exposed persons from Wikidata, the structured data version of Wikipedia.</span>,
  'FTM_BE_FOD_SANCTIONS': <span>Belgium extends the European FSF sanctions list on the basis of a national terrorist list, issued by the National Security Council.</span>,
  'FTM_LV_BUSINESS_REGISTER': <span>The Latvian company registry lists directors, shareholders and beneficial owners as well as bank accounts for all companies and traders in the country.</span>,
  'FTM_SG_TERRORISTS': <span>Terrorists and terrorist entities designated by the Singapore government on top of the UN security council set of sanctions.</span>,
  'FTM_EU_TRAVEL_BANS': <span>Consolidated information about individuals who have been banned from traveling to the European Union, released as part of the EU Sanctions Map.</span>,
  'FTM_RU_RUPEP_COMPANIES': <span>RuPEP is a detailed database of politically exposed persons in Russia and Belarus companies maintained by qualified researchers</span>,
  'FTM_US_FBI_MOST_WANTED': <span>The FBI Ten Most Wanted Fugitives is a most wanted list maintained by the United States's Federal Bureau of Investigation (FBI).</span>,
  'FTM_NOMINATIM': <span>Nominatim is a geocoding system based on OpenStreetMap, the open map of the world created by a community of mappers.</span>,
  'FTM_ZA_FIC_SANCTIONS': <span>The South African FIC sanctions list, largely based on UN Security Council sanctions.</span>,
  'FTM_NL_TERRORISM_LIST': <span>The Dutch government's sanctions list of people suspected of involvement in terroristic activities.</span>,
  'FTM_WIKIDATA': <span>Profiles of politically exposed persons from Wikidata, the structured data version of Wikipedia.</span>,
  'FTM_FR_TRESOR_GELS_AVOIR': <span>The register lists all persons, entities and vessels subject to asset freezing measures in force on French territory, pursuant to national, European and international (UN) provisions.</span>,
  'FTM_KZ_AFMRK_SANCTIONS': <span>A simple list of sanctioned individuals and entities published by the Financial Monitoring Agency of Kazakhstan.</span>,
  'FTM_US_OCC_ENFACT': <span>The US banking regulator OCC publishes a list of companies and individuals against which punitive measures have been applied.</span>,
  'FTM_MD_COMPANIES': <span>All companies in Moldova, with information on their founders, beneficial owners and directors.</span>,
  'FTM_AZ_FIU_SANCTIONS': <span>Domestic list of natural and legal persons subject to sanctions</span>,
  'FTM_RANSOMWHERE': <span>Cryptocurrency addresses linked to payments for ransomware attacks</span>,
  'FTM_UA_EDR': <span>The beneficial ownership database of legal entities registered in Ukraine (EDR)</span>,
  'FTM_VALIDATION': <span>Validation</span>,
  'FTM_RU_FEDSFM_TERROR': <span>Russia Fedsfm Terror</span>,
  'FTM_SY_OBSALYTICS_OPENSYR': <span>Syria Obsalytics Opensyr</span>,
  'FTM_WD_CURATED': <span>Persons of interest profiles from Wikidata, the structured data version of Wikipedia.</span>,
  'FTM_INTERPOL_YELLOW_NOTICES': <span>Interpol Yellow Notices</span>,

}

// "FTM_GB_COH_DISQUALIFIED": <p>The Companies House disqualified directors register includes details of directors disqualified by the courts and the Insolvency Service.</p>,
// "FTM KG FIU NATIONAL" -not clear if we have implemented just KG list, as it seems that the international database is publicly available
// https://aml-cft.net/library/list-fiFTM EU EEAS SANCTIONS nancial-intelligence-units-fius/

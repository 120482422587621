import React, {useEffect, useMemo, useState} from 'react';

import {
  Box,
  Typography
} from '@material-ui/core';
import history from '@app/history';
import {
  EditIcon,
  SearchHistoryInactiveIcon
} from '@app/icons';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import NoResultsFound from '@components/layout/no-results-found';
import LabelsForRender, { useStyles as labelsStyles } from '@components/layout/labels-for-render';
import prepareTimestamp from '@utils/date';
import { green, grey } from '@material-ui/core/colors';
import RiskRatingIcon from '@components/flexible-icons/risk-rating-icon';
import TableList  from '@components/lists/table-list';
import { user } from '@app/api/client';
import _ from 'lodash';
import {clientApi} from '@app/api';
import {exportBackgroundCheckPDF} from '@utils/exportPDF';
import { loader } from '@store/actions';
import {useDispatch} from 'react-redux';
import { useSnackbar } from 'notistack';
import CustomTooltip from '@components/controls/tooltip';
import getRiskRatingLabel from '@utils/riskRatingLabel';
import DeleteDialog from './components/delete-dialog';

// const RELATED_ENTITIES_COLUMNS = [
//     { field: 'entity', headerName: 'ENTITY' },
//     { field: 'relation', headerName: 'RELATION' }
// ]

const MONITORING_DETAILS_COLUMNS = [
  { field: 'reviewer', headerName: 'REVIEWER' },
  { field: 'risk_score', headerName: 'RISK RATING' },
  { field: 'date', headerName: 'DATE' },
  { field: 'monitoring', headerName: 'MONITORING' },
  { field: 'period', headerName: 'PERIOD' }
]

export default function ComplianceProfileView({readonly, ...props}) {

  const { data, onSetTab } = props;//eslint-disable-line

  const [currentUser, setCurrentUser] = useState({})
  const [ lines, setLines ] = useState([])
  const [ fetched, setFetched ] = useState(false)
  const [ pendingDeleteId, setPendingDeleteId ] = useState(null)

  const labelsClasses = labelsStyles()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    user.getCurrentUser().then(({data}) => {
      setCurrentUser(data)
    })
    }, []) //eslint-disable-line

  useEffect(() => {
    fetch().then()
    }, [data]) // eslint-disable-line

  const alternativeEmail = useMemo(() => {
    return currentUser?.alternativeEmails?.[0]?.email
  }, [currentUser])

  const onRiskUpdate = () => {
    onSetTab('risk-rating')
  }

  // const relatedEntitiesData = [
  //     { entity: 'ACME Inc.', relation: 'Shareholder' }
  // ]

  const getPeriod = timeSliceDays => {
    const timeSlicesMap = {
      1: 'Daily',
      7: 'Weekly',
      30: 'Monthly'
    }
    return timeSlicesMap?.[parseInt(timeSliceDays)]
  }

  const fetch = async () => {
    const {linked_profiles, linked_searches} = data;
    if (!linked_profiles && !linked_searches) return;
    const searches = []
    const profiles  = []
    const linesData = [];

    await Promise.all(linked_profiles.map(async id => {
      const profile = (await clientApi.profile.show(id)).data
      if( profile ){
        profiles.push( profile )
      }
    }))
    await Promise.all(linked_searches.map(async id => {
      const search = (await clientApi.search.show(id)).data
      if( search ){
        searches.push( search )
      }
    }))
    const originSearches = _.keyBy([...searches], 'searchId')
    const remainingSearches = {...originSearches}

    await Promise.all(profiles.map( async profile => {
      const search = originSearches[profile.searchId];
      if(search){
        const ongoing = (await clientApi.search.getOngoingMonitoring(search.searchId))?.data

        linesData.push({
          id: `${search.searchId}/${profile.id}`,
          reviewer: 'Me',
          risk_score: getRiskRatingLabel(profile.bgCheckRiskRating),
          date: search.lastRunOn && prepareTimestamp(search.lastRunOn, 'MM/DD/YYYY'),
          monitoring: !!ongoing? 'On': 'Off',
          period: !!ongoing? getPeriod(ongoing?.timeSliceDays): 'N/A',
          createdOn: search.createdOn,
          search_id: search.searchId,
          profile_id: profile.id
        })
      }
      delete remainingSearches[profile.searchId];
    }))

    await Promise.all(Object.values(remainingSearches).map(async search => {
      const ongoing = (await clientApi.search.getOngoingMonitoring(search.searchId))?.data

      linesData.push({
        id: search.searchId,
        reviewer: 'Me',
        risk_score: getRiskRatingLabel(search.bgCheckRiskRating),
        date: search.lastRunOn && prepareTimestamp(search.lastRunOn, 'MM/DD/YYYY'),
        monitoring: !!ongoing? 'On': 'Off',
        period: !!ongoing? getPeriod(ongoing?.timeSliceDays): 'N/A',
        createdOn: search.createdOn,
        search_id: search.searchId
      })
    }))
    setLines(
      _.orderBy(linesData, ['createdOn'], ['desc'])
    )
    setFetched(true)
  }

  const getMonitoringLabels = () => {
    return [
      { field: 'Risk Rating', notText: true, value: <React.Fragment>
        <RiskRatingIcon
          risk={data?.risk_score}
          withTooltip={true}
          placement={'top'}
          iconProps={{style:{marginTop: '2px', marginLeft: '2px'}}}
        />
                    &nbsp;
        <Typography classes={{root: labelsClasses.labelValue}}>
          {getRiskRatingLabel(data?.risk_score, 'Not Set')}
        </Typography>
        {
          !readonly &&
                        <React.Fragment>
                            &nbsp;
                          <CustomTooltip
                            title="Edit"
                            placement="top"
                          >
                            <Box>
                              <ButtonWithIcon startIcon={<EditIcon />} onClick={onRiskUpdate}/>
                            </Box>
                          </CustomTooltip>
                        </React.Fragment>
        }
      </React.Fragment>},
      {
        field: 'Date of Next Review', notText: true, value: <React.Fragment>
          <SearchHistoryInactiveIcon width={16} stroke={ green[500] } />
                    &nbsp;
          <Typography variant={'caption'} style={{ color: grey[500] }}>
            {data?.next_review_at? prepareTimestamp(data.next_review_at, 'MM/DD/YYYY', false): 'N/A'}
          </Typography>
        </React.Fragment>
      }
    ]
  }

  const onExport = async (search_id, profile_id) => {
    dispatch(loader.setLoader(true));
    await exportBackgroundCheckPDF({ searchId: search_id, profileId: profile_id });
    enqueueSnackbar('The report was successfully exported!', { variant: 'success' });
  }

  const onUpdateMonitoring = (searchId) => {
    history.push(`/portfolio/monitoring-settings/${data.id}/${searchId}`)
  }

  const onDeleteMonitoringStart = (searchId) => {
    setPendingDeleteId(searchId)
  }

  const onDeleteMonitoringConfirm = () => {
    const searchId = pendingDeleteId
    clientApi.search.removeFromMonitoring(searchId)
      .then(() => {
        setLines(
          lines.map(line =>
            line.search_id !== searchId
              ? line
              : {...line, monitoring: 'Off', period: 'N/A'}
          )
        )
        setPendingDeleteId(null)
      })
  }

  return (
    <React.Fragment>
      <Box display={'flex'} flexDirection={'column'} flexGrow={1} mt={3}>
        <Box mb={2}>
          <Typography variant={'h5'} style={{ lineHeight: '24px' }}>
                        Compliance Details
          </Typography>
        </Box>
        <LabelsForRender labelProps={{style: {width: '150px', maxWidth: 'none'}}} labels={getMonitoringLabels()} boxProps={{ mb: 1 }} />
        {/*<Box mt={2} style={{width: '350px'}}>*/}
        {/*    <Typography variant={'h5'} style={{ lineHeight: '24px' }}>*/}
        {/*        Related Entities*/}
        {/*    </Typography>*/}
        {/*    <Box mt={2}>*/}
        {/*        <TableList*/}
        {/*            columns={RELATED_ENTITIES_COLUMNS}*/}
        {/*            items={relatedEntitiesData}*/}
        {/*            sortableColumns={['entity', 'relation']}*/}
        {/*        />*/}
        {/*    </Box>*/}
        {/*</Box>*/}
        <Box mt={2}>
          <Typography variant={'h5'} style={{ lineHeight: '24px' }}>
                        Monitoring Details
          </Typography>
          { alternativeEmail &&
                        <LabelsForRender
                          labelProps={{style: {width: '150px', maxWidth: 'none'}}}
                          labels={[
                            { field: 'Other monitoring email', value: alternativeEmail}
                          ]}
                          boxProps={{ mt: 2 }}
                        />
          }
          <Box mt={2}>
            {!!lines.length &&
                            <TableList
                              columns={MONITORING_DETAILS_COLUMNS}
                              items={lines}
                              sortableColumns={['reviewer', 'risk_score', 'date', 'monitoring', 'period']}
                              threeDotsActions={[
                                {label: 'Download', handler: (id, item) => onExport(item.search_id, item.profile_id)},
                                !readonly &&
                                    {
                                      label: 'Change Monitoring Settings',
                                      handler: (id, item) => onUpdateMonitoring(item.search_id)
                                    },
                                !readonly &&
                                    {
                                      label: 'Remove from Monitoring',
                                      handler: (id, item) => onDeleteMonitoringStart(item.search_id),
                                      hidden: (id, item) => item.monitoring === 'Off'
                                    }
                              ].filter(v => !!v)}
                            />
            }

            { fetched && !lines.length &&
                            <Box mt={5}>
                              <NoResultsFound>
                                    When you run a background check, the history of searches will appear here
                              </NoResultsFound>
                            </Box>
            }
          </Box>
        </Box>
      </Box>
      <DeleteDialog
        open={!!pendingDeleteId}
        onClose={() => setPendingDeleteId(null)}
        onConfirm={onDeleteMonitoringConfirm}
      />
    </React.Fragment>
  )
}
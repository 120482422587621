import React, { useEffect, useState, useMemo } from 'react';
import { Box, Collapse, Dialog, FormControlLabel, Link, Switch, Typography } from '@material-ui/core';
import CustomTooltip from '@components/controls/tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { clientApi } from '@app/api';
import LabelsForRender from '@components/layout/labels-for-render';
import OngoingMonitoringSettings from '@components/complex/ongoing-monitoring-settings';

export const useStyles = makeStyles((theme) => ({
  wrapperLabels: {
    marginBottom: '2px',
    alignItems: 'flex-start'
  },
}));

const periods = {
  '1': 'Daily',
  '7': 'Weekly',
  '30': 'Monthly',
}

export default function UserInfoCardMonitoring({ searchId }) {
  const classes = useStyles();

  const { profilesVersionId } = useSelector(store => store.searchHistory);
  const { selectedProfile } = useSelector(store => store.backgroundCheck);

  const [ongoingMonitoring, setOngoingMonitoring] = useState(null);
  const [ongoingMonitoringOpen, setOngoingMonitoringOpen] = useState(false);

  useEffect(() => {
    const getOngoingMonitoring = async () => {
      const res = await clientApi.ongoingMonitoring.getBySearchId(searchId);

      if (res.status === 200) {
        setOngoingMonitoring(res.data);
      }
    }

    getOngoingMonitoring();
  }, []) // eslint-disable-line

  const handleMonitoring = async () => {
    if (!ongoingMonitoring) {
      setOngoingMonitoringOpen(true);
    } else {
      await clientApi.search.removeFromMonitoring(searchId);
      setOngoingMonitoring(null);
    }
  }

  const handleFinishMonitoring = async (data, monitor) => {
    if (monitor) {
      if (data.ongoingMonitoringId) {
        const res = await clientApi.ongoingMonitoring.updateOngoingMonitoring(searchId, data);
        setOngoingMonitoring(res.data);
      } else {
        const res = await clientApi.search.addSearchToMonitoring(searchId, data);
        setOngoingMonitoring(res.data);
      }
    } else if (data.ongoingMonitoringId) {
      await clientApi.search.removeFromMonitoring(searchId);
      setOngoingMonitoring(null);
    }

    setOngoingMonitoringOpen(false);
  }

  const handleEditMonitoring = () => {
    setOngoingMonitoringOpen(true);
  }

  const labels = useMemo(() => {
    if (!ongoingMonitoring) return [];

    return [
      { field: 'Period', value: periods[ongoingMonitoring.timeSliceDays] },
      { field: 'Notification', value: 'Email' },
    ]
  }, [ongoingMonitoring])

  return (
    <Box>
      <Dialog open={ongoingMonitoringOpen} onClose={() => setOngoingMonitoringOpen(false)}>
        <Box mb={3}>
          <Typography variant="h3" align="center">Change Monitoring</Typography>
        </Box>
        <OngoingMonitoringSettings
          clientId={selectedProfile?.clientId}
          searchId={searchId}
          onFinish={handleFinishMonitoring}
          client={selectedProfile}
          monitoringData={ongoingMonitoring || {}}
        />
      </Dialog>
      <Box>
        <CustomTooltip
          placement="top"
          title="Only the last version can be updated, the previous versions are read only"
          width={100}
          disableHoverListener={!profilesVersionId}
        >
          <FormControlLabel
            control={
              <Switch
                value={!!ongoingMonitoring}
                onChange={handleMonitoring}
                checked={!!ongoingMonitoring}
              />
            }
            label="Monitoring"
            disabled={!!profilesVersionId}
          />
        </CustomTooltip>
      </Box>
      <Collapse in={!!ongoingMonitoring}>
        <Box>
          <LabelsForRender
            labels={labels || []}
            anchorProps={{
              vertical: 'bottom',
              horizontal: -50,
            }}
            propsWrapper={classes.wrapperLabels}
          />
          {!profilesVersionId &&
            <Typography component="span">
              <Link
                onClick={handleEditMonitoring}
              >
                Change
              </Link>
            </Typography>
          }
          {!!profilesVersionId &&
            <CustomTooltip
              placement="top"
              title="Only the last version can be updated, the previous versions are read only"
              width={100}
            >
              <Typography style={{ color: 'rgba(0, 0, 0, 0.38)' }} component="span">
                <b>Change</b>
              </Typography>
            </CustomTooltip>
          }
        </Box>
      </Collapse>
    </Box>
  )
}



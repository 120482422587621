import React, {useEffect, useState} from 'react';
import { Modal } from '@components/modals';
import {
  Box,
  TextField
} from '@material-ui/core';
import * as yup from 'yup';
import parseYupErrors from '@app/yup';

export const schema = yup.object().shape({
  name: yup.string().trim().required().max(100).label('Entity Name')
})

export default function ChangeNameDialog({ open, onClose, initName, onSave }) {

  const [name, setName] = useState(null)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (open) {
      setName(initName)
    }
  }, [open]) // eslint-disable-line

  const onInternalSave = async () => {
    setErrors({});
    try {
      await schema.validate({
        name
      }, { abortEarly: false })
      onSave({name})
    } catch (err) {
      setErrors(parseYupErrors(err))
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Change Entity Name"
      actions={[
        {
          type: 'main',
          label: 'Cancel',
          action: onClose,
          style: { width: 150 },
        },
        {
          type: 'secondary',
          label: 'Save',
          action: onInternalSave,
          style: { width: 150 },
        }
      ]}
      actionsDirection="row"
      content={(
        <Box width={400} pb={4}>
          <Box py={1}>
            <TextField
              value={name || ''}
              onChange={event => {
                setName(event.target.value)
              }}
              fullWidth
              label="Entity Name"
              variant="outlined"
              error={!!errors['name']}
              helperText={!!errors['name'] && errors['name']}
            />
          </Box>
        </Box>
      )}
    />
  )
}
import React from 'react';
import { Modal } from '@components/modals';

export default function SaveWithoutChangesDialog({open, onClose, onConfirm}) {

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title="Saving Risk Rating"
        mainText="It seems you didn't change anything since your last review. Are you sure you want to save this as a new risk assessment?"
        actions={
          [
            {
              type: 'main',
              label: 'NO',
              action: onClose,
              style: { width: 200 },
            },
            {
              type: 'secondary',
              label: 'YES',
              action: onConfirm,
              style: { width: 200 },
            },
          ]
        }
        actionsDirection="row"
      />
    </React.Fragment>
  );
}
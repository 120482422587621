import UserManagement from '@views/protected/admin/user-management'

const routes = [
  {
    path: '/user-management',
    component: UserManagement,
    guest: false,
  }
]

export default routes;
export const PREMIUM = 'COMPLY_ADVANTAGE';

export const PERSON = 'PERSON';

export const SANCTIONS = 'SANCTIONS';

export const PEP = 'PEP';

export const ALPHABLETS = ['1','2','3','4','5','6','7','8','9','0', 'Backspace', 'Delete'];

export const GENDERS = ['Male', 'Female'];

export const YOB = 'yob';

export const BUY_MORE_PATH = '/my-profile/subscription/plans';

export const PREMIUM_TEXT = 'Searches performed with premium credits will effectively be checked against a comprehensive dataset of more than 1300 sources, including Sanctions, 4 PEP classes and Adverse media.';

export const REGULAR_TEXT = 'Searches performed with regular credits will effectively be checked against all the sanctions list and limited PEP sources. Thus, for example, only high-level politicians will be found in this dataset.';

export const MODAL_TEXT = 'Looks like you are about to run the same search again. Would you like to generate a search or update the existing: ';

export const CONFIRM_FIELDS = {
  yob: 'Year of birth',
  registrationNumber: 'Registration #',
}

export const REQUEST_REMOVE_FIELDS = {
  'ALEPH': {
    'PERSON': ['fuzziness', 'registrationNumber'],
    'COMPANY': ['fuzziness', 'yob', 'gender'],
  },
  'COMPLY_ADVANTAGE': {
    'PERSON': ['gender', 'registrationNumber'],
    'COMPANY': ['yob', 'gender',],
  },
}

export const REQUEST_REQUIRE_FIELDS = {
  'ALEPH': {
    'PERSON': ['country', 'yob', 'gender'],
    'COMPANY': ['country', 'registrationNumber'],
  },
  'COMPLY_ADVANTAGE': {
    'PERSON': ['fuzziness', 'country', 'yob'],
    'COMPANY': ['fuzziness', 'country',],
  },
}

export const CREDITS = {
  'ALEPH': 'Regular',
  'COMPLY_ADVANTAGE': 'Premium',
}

export const TABS = [
  {
    id: 'ALEPH',
    label: 'Regular',
    infoIcon: true,
    infoValue: <p><b style={{ fontWeight: 700 }}>Regular: best for SDD</b><br /><span style={{ fontWeight: 400, opacity: 0.9 }}>Searches all sanctions and basic PEP lists</span></p>,
    infoWidth: 120
  },
  {
    id: 'COMPLY_ADVANTAGE',
    label: 'Premium',
    infoIcon: true,
    infoValue: <p><b style={{ fontWeight: 700 }}>Premium: best for EDD</b><br /><span style={{ fontWeight: 400, opacity: 0.9 }}>Searches a comprehensive dataset of all watchlists, including PEP and Adverse media</span></p>,
    infoWidth: 160
  },
]

export const RADIO_ITEMS = [
  { label: 'Person', value: 'PERSON' },
  { label: 'Legal Entity', value: 'COMPANY' },
];

export const SWITCH = {
  'ALEPH': [
    { label: 'SANCTIONS', value: 'SANCTIONS', },
    { label: 'PEP', value: 'PEP' },
  ],
  'COMPLY_ADVANTAGE': [
    { label: 'SANCTIONS', value: 'SANCTIONS', },
    { label: 'PEP', value: 'PEP' },
    { label: 'ADVERSE MEDIA', value: 'ADVERSE_MEDIA' },
  ]
}

export const FUZZINESS_INTERVAL = [
  { name: '0% Exact Match', value: 0 },
  { name: '10%', value: 0.1 },
  { name: '20%', value: 0.2 },
  { name: '30%', value: 0.3 },
  { name: '40%', value: 0.4 },
  { name: '50%', value: 0.5 },
  { name: '60%', value: 0.6 },
  { name: '70%', value: 0.7 },
  { name: '80%', value: 0.8 },
  { name: '90%', value: 0.9 },
  { name: '100% Broad Match', value: 1 }
];

export const FUZZINESS_VALUE = {
  '0': '0%',
  '0.1': '10%',
  '0.2': '20%',
  '0.3': '30%',
  '0.4': '40%',
  '0.5': '50%',
  '0.6': '60%',
  '0.7': '70%',
  '0.8': '80%',
  '0.9': '90%',
  '1': '100%',
}

import React from  'react';
import { Typography, Button } from '@material-ui/core';
import history from '@app/history';

import { BUY_MORE_PATH } from '../constants';
import { useStyles } from './styles';

export default function BuyMoreButton() {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      size="small"
      onClick={() => history.push(BUY_MORE_PATH)}
      className={classes.buyMoreButton}
    >
      <Typography className={classes.buyMoreButtonLabel}>BUY MORE</Typography>
    </Button>
  )
}

import React, { useMemo } from 'react';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import CheckboxLight from '@components/inputs/checkbox-light';

import { makeStyles, withTheme } from '@material-ui/core/styles';
import CustomChip from '@components/controls/custom-chip';
import LabelsForRender from '@components/layout/labels-for-render';
import { setCardTypeColor } from '@utils/setCardTypeColor';
import CustomTooltip from '@components/controls/tooltip';
import { CarbonViewFilledIcon, ChangelogOutdatedIcon } from '@app/icons';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import prepareTimestamp from '@utils/date';

const tooltipTitle =
  'Some features are restricted in this view. For an extensive searching experience please Login or Register.';

const useStyles = makeStyles((theme) => ({
  userCard: (props) => ({
    background:
      'linear-gradient(-37.72deg, rgb(255, 255, 255) 88.91%, rgb(255, 255, 255) 95.98%)',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    border: props.isReviewed
      ? `1px solid ${theme.palette.background.GY4}`
      : `1px solid ${theme.palette.common.white}`,
    borderRadius: '12px',
    overflow: 'inherit',
    '&:hover': {
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      cursor: 'pointer',
    },
    maxHeight: '100%',
    minHeight: '100%',
    height: '100%',
  }),
  cardContentWrapper: (props) => ({
    paddingBottom: !props.footer ? '24px' : 0,
    paddingLeft: '24px',
    paddingRight: '24px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  }),
  userCardHeader: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  userCardHeaderTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tagsLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& .MuiChip-root': {
      marginRight: 5,
    },
  },
  tagAction: {
    '& .MuiChip-root': {
      margin: '5px 0',
      marginRight: '5px',
    },
  },
  userCardTags: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 0,
  },
  listHits: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '5px 0px',
  },
  userCardContent: {
    marginTop: 24,
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  userCardCheckbox: {
    margin: 0,
  },
  dummyBlock: {
    height: '32px',
  },
  whitelistedAt: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '20px',
    color: theme.palette.text.GY4,
    margin: '6px 0',
  },
  whitelistedButton: {
    padding: 0,
    minWidth: 0,
  },
  wrapperLabels: {
    marginBottom: '5px',
    alignItems: 'flex-start',
  },
}));

const UPDATED = 'UPDATED';
const typeTooltip = {
  S: 'sanction',
  SANCTIONS: 'sanction',
  AM: 'adverse-media, adverse-media-general',
  PEP: 'pep, pep-class-1, pep-class-2, pep-class-3, pep-class-4',
  W: 'warning',
  FP: 'fitness-probity',
};

function UserCard(props) {
  const {
    data,
    name,
    onSelect,
    actionCard,
    footer,
    handleChangeWhitelist,
    noLogged,
    disabled,
    lastOpened,
    isLastVersion,
  } = props;
  const {
    acknowledged,
    id,
    versionState,
    versionStateChangedOn,
    isReviewed,
    meta,
    incomplete,
    isWhitelisted,
    whitelistedAt,
    whitelistedReason,
    notes,
    listHits,
    isBasedOnLatestRulebookVersion,
  } = data;
  const profileWhitelistNotes = useMemo(
    () => notes?.filter((el) => el.noteType === 'profileWhitelist') || [],
    [notes]
  );

  const classes = useStyles({ noLogged, isReviewed, footer });

  const typeColor = data.isWhitelisted ? 'suppressed' : versionState;
  const cardColor =
    data.isWhitelisted || isReviewed
      ? setCardTypeColor(
        isReviewed && acknowledged && versionState !== 'DELETED'
          ? 'REVIEWED'
          : typeColor
      )
      : null;
  const whitelistedData = useMemo(
    () => ({
      value: 'On',
      reason: whitelistedReason,
      note: profileWhitelistNotes[+profileWhitelistNotes.length - 1] || '',
    }),
    [whitelistedReason, profileWhitelistNotes]
  );

  const handleSelect = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onSelect(id);
  };

  const handleOutdatedClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    window.open(`/portfolio/view/${id}?review_version=true`, '_blank')
  }

  const getTags = () => {
    const tags = [];
    if (incomplete) {
      tags.push({
        type: UPDATED,
        label: 'INCOMPLETE',
      });
    }
    return tags;
  };

  const renderVersionInfo = () => {
    // Always show deleted state
    if (versionState === 'DELETED') {
      return (
        <span className={classes.tagAction}>
          <CustomChip
            label={`${versionState} ${prepareTimestamp(
              versionStateChangedOn,
              'MM/DD/YYYY'
            )}`}
            type={versionState}
          />
        </span>
      );
    }
    // Show states if it has changed
    else if (
      versionState !== 'UNCHANGED' &&
      versionStateChangedOn &&
      !data.isWhitelisted &&
      // For last (current) version, show changes only if it's not acknowledged
      // For older version, always show changes
      (isLastVersion ? !acknowledged : true)
    ) {
      return (
        <span className={classes.tagAction}>
          <CustomChip
            label={`${versionState} ${prepareTimestamp(
              versionStateChangedOn,
              'MM/DD/YYYY'
            )}`}
            type={versionState}
          />
        </span>
      );
    } else if (whitelistedAt && isWhitelisted) {
      return (
        <span className={classes.tagAction}>
          <CustomChip
            label={`WHITELISTED ${prepareTimestamp(
              whitelistedAt,
              'MM/DD/YYYY'
            )}`}
            type={'suppressed'}
          />
        </span>
      );
    } else {
      return <span className={classes.dummyBlock} />;
    }
  };

  const renderWhitelistElement = (
    <Box display="flex" justifyContent="flex-end" width={'100%'}>
      <CustomTooltip
        title={
          <p style={{ textAlign: 'start' }}>
            Whitelisted Reason: {whitelistedReason} <br />
            Whitelisted At:{' '}
            {prepareTimestamp(whitelistedAt, 'MM/DD/YYYY hh:mm A')} <br />
          </p>
        }
        placement="top"
      >
        <span style={{ marginRight: '2px' }}>
          <ButtonWithIcon
            className={classes.whitelistedButton}
            onClick={(event) =>
              handleChangeWhitelist(event, id, whitelistedData)
            }
          >
            <CarbonViewFilledIcon />
          </ButtonWithIcon>
        </span>
      </CustomTooltip>
    </Box>
  );

  const renderChip = () => {
    return listHits.map((el, i) => (
      <CustomTooltip
        title={typeTooltip[el]}
        placement="top"
        disableHoverListener={!typeTooltip[el]}
        key={`${el}-${i}`}
      >
        <span>
          <CustomChip
            label={el === 'SANCTIONS' ? 'S' : el}
            key={`tag-${i}-${el}`}
          />
        </span>
      </CustomTooltip>
    ));
  };

  return (
    <Card
      className={classes.userCard}
      onClick={() => actionCard && actionCard()}
      style={{
        background:
          cardColor &&
          `linear-gradient(-37.72deg, #FFFFFF 88.91%, ${cardColor} 95.98%)`,
      }}
    >
      <Box className={classes.cardContentWrapper}>
        <CardContent classes={{ root: classes.userCardTags }}>
          {incomplete ? (
            <Box className={classes.tagsLine}>
              {getTags().map((tag, k) => (
                <span className={classes.tagAction}>
                  <CustomChip
                    label={tag.label}
                    type={tag?.type}
                    key={`tag-${k}-${tag}`}
                  />
                </span>
              ))}
            </Box>
          ) : (
            renderVersionInfo()
          )}
          {isReviewed && (
            <span className={classes.tagAction}>
              <CustomChip label="REVIEWED" type={'reviewed'} />
            </span>
          )}
          {lastOpened &&
            <Box className={classes.tagAction}>
              <CustomChip label="Last Opened" background="#7A5B44" labelColor="#FFFFFF" />
            </Box>
          }
          {(isBasedOnLatestRulebookVersion !== undefined && !isBasedOnLatestRulebookVersion) &&
            <CustomTooltip title="There is a newer version of entity definition available. Review Now" placement="top">
              <Box className={classes.tagAction}>
                <CustomChip
                  label={(
                    <Box display="flex" alignItems="center">
                      <Box pr="4px">
                        <ChangelogOutdatedIcon width="14px" height="14px" />
                      </Box>
                      Outdated
                    </Box>
                  )}
                  labelColor="#B6514C"
                  chipProps={{
                    onClick: handleOutdatedClick,
                  }}
                />
              </Box>
            </CustomTooltip>
          }
        </CardContent>
        <CardContent classes={{ root: classes.userCardHeader }}>
          <CustomTooltip title={name} placement="top">
            <Typography variant="h4" className={classes.userCardHeaderTitle}>
              {name}
            </Typography>
          </CustomTooltip>
          {onSelect && (
            <CustomTooltip
              title={
                !!disabled
                  ? 'Only the last version can be updated, the previous versions are read only'
                  : noLogged
                    ? tooltipTitle
                    : data.isChecked
                      ? 'Deselect'
                      : 'Select'
              }
              placement="top"
            >
              <span>
                <CheckboxLight
                  className={classes.userCardCheckbox}
                  checked={data.isChecked}
                  onClick={handleSelect}
                  disabled={disabled || noLogged}
                />
              </span>
            </CustomTooltip>
          )}
        </CardContent>
        {!!listHits && (
          <CardContent classes={{ root: classes.listHits }}>
            <Box className={classes.tagsLine}>{renderChip()}</Box>
          </CardContent>
        )}
        <CardContent classes={{ root: classes.userCardContent }}>
          <LabelsForRender
            handleChangeWhitelist={(event) => handleChangeWhitelist(event, id)}
            labels={meta || []}
            anchorProps={{
              vertical: 'bottom',
              horizontal: -30,
            }}
            noLogged={noLogged}
            disabled={disabled}
            customElement={
              isWhitelisted && { WHITELIST: renderWhitelistElement }
            }
            propsWrapper={classes.wrapperLabels}
            fullWidth
          />
        </CardContent>
        {footer && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            marginTop="auto"
          >
            {footer}
          </Box>
        )}
      </Box>
    </Card>
  );
}

export default withTheme(UserCard);

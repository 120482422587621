import React from 'react';
import clsx from 'clsx';

import {
  Box, Typography, Grid
} from '@material-ui/core';
import {
  EidvBlueCheckIcon,
  EidvBrownWarningIcon,
  EidvRepeatIcon,
  EidvExpiredIcon,
  EidvInProgressIcon,
  EidvNoticeIcon,
  EidvRejectedIcon,
  EidvCanceledIcon,
  EidvRequestedIcon,
} from '@app/icons';
import CustomTooltip from '@components/controls/tooltip';
import { useStyles } from '@components/layout/side-menu-layout';


export const eidvStatusIcons = {
  success: <EidvBlueCheckIcon />,
  completed: <EidvBlueCheckIcon />,
  complete: <EidvBlueCheckIcon />,
  'pending approval': <EidvBrownWarningIcon />,
  repeat: <EidvRepeatIcon />,
  repeated: <EidvRepeatIcon />,
  'requested repetition': <EidvRepeatIcon />,
  expired: <EidvExpiredIcon />,
  'in progress': <EidvInProgressIcon />,
  notice: <EidvNoticeIcon />,
  rejected: <EidvRejectedIcon />,
  canceled: <EidvCanceledIcon />,
  requested: <EidvRequestedIcon />,
  default: <EidvBrownWarningIcon />
}

export default function EVStatusCard({ title, description, status, selected, onClick, collapse, noHover }) {
  const classes = useStyles();

  const item = (
    <Box
      className={clsx(classes.tab, selected && 'active', noHover && 'noHover')}
      style={{ width: noHover ? undefined : collapse ? 40 : 210, padding: 0, }}
      onClick={onClick}
    >
      <Grid container alignItems="center">
        <Box p={1} width={24} height={24}>
          {eidvStatusIcons[status] || eidvStatusIcons.default}
        </Box>
        {!collapse &&
          <Box pr={2}>
            <Box>
              <Typography variant="h5" style={{ fontSize: 12 }}>{title}</Typography>
            </Box>
            <Box>
              <Typography style={{ color: '#484848', fontSize: 10, lineHeight: '14px' }}>{description}</Typography>
            </Box>
          </Box>
        }
      </Grid>
    </Box>
  )

  // Intentionally not using disableHoverListener as the tooltip will stay in place
  // If the card moved during collapse/shown state change and the tooltip will stay in place
  // if we don't remove it.
  return (
    collapse ?
      <CustomTooltip title={title} placement="right">
        {item}
      </CustomTooltip>
      :
      item
  )
}
import AdminManagement from '@views/protected/admin/admin-management';

const routes = [
  {
    path: '/admin-management',
    component: AdminManagement,
    guest: false,
  },
]

export default routes;
import React, {useState} from 'react';

import { IconButton, Popover, Grid } from '@material-ui/core';
import { CalendarIcon, CalendarInactiveIcon } from '@app/icons';
import DatePicker from '@components/calendar/date-picker';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    padding: '6px',
  },
  paper: {
    padding: '15px 0',
  }
}));

export default function InputDatePicker({ handleChange, inputValue, disabled }) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const onChange = date => {
    const formatted = moment(date).format('YYYY-MM-DD');
    handleChange({ target: { value: formatted } });
  }

  const onAccept = date => {
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <IconButton
        classes={{ root: classes.buttonRoot }}
        disabled={disabled}
        onMouseDown={event => {
          setAnchorEl(event.currentTarget)
          const sameParentInputs = event.target.parentElement.parentNode.parentNode.getElementsByTagName('input')
          if(sameParentInputs.length) {
            sameParentInputs[0].focus()
          }
        }}
      >
        {disabled ? <CalendarInactiveIcon /> : <CalendarIcon />}
      </IconButton>
      <Popover
        id={!!anchorEl ? 'simple-popover' : undefined}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        classes={{ paper: classes.paper }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 250
        }}
      >
        <Grid container>
          <DatePicker
            variant="static"
            value={inputValue}
            onChange={onChange}
            onAccept={onAccept}
          />
        </Grid>
      </Popover>
    </React.Fragment>
  )
}
import React, { useState, useEffect, useRef } from 'react';
import { Collapse, Box, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: '#F8F8F8',
    borderRadius: 12,
    '& .MuiInputBase-input': {
      fontSize: 10,
      fontFamily: 'Roboto',
      fontWeight: 300,
      lineHeight: '16px',
      '&::placeholder': {
        fontSize: 10,
        fontWeight: 500,
        opacity: 1,
        color: '#656565',
      },
    },
    '& .MuiInputBase-root': {
      backgroundColor: '#F8F8F8',
      padding: '27px 10px 13px 10px',
      borderRadius: 12,
    },
  },
  title: {
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#656565',
    fontWeight: 'bold',
  },
  titleCounter: {
    fontStyle: 'normal',
    fontSize: '10px',
    lineHeight: '16px',
    color: '#656565',
  },
  commentContainer: {
    backgroundColor: '#F8F8F8',
    position: 'relative',
    margin: '0',
    borderRadius: '12px',
    '&:focus-within': {
      border: '1px solid #A4A4A4',
    }
  },
  titleContainer: {
    position: 'absolute',
    zIndex: 9,
    top: 10,
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100% - 26px)',
    padding: '0 10px',
  }
}));

const AddCommentField = ({ open, setOpen, addComment, editNotes, handleEditComment, value, onChange }) => {
  const classes = useStyles();
  const [text, setText] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
    if (value) {
      setText(value);
    }
  }, [open, value, inputRef])

  useEffect(() => {
    if (editNotes) (
      setText(editNotes.note)
    )
  }, [editNotes])

  const editComment = () => {
    const noteID = editNotes.id || editNotes.noteId;
    if (!text) {
      handleEditComment(noteID, null, false);
      setText('');
    } else if (text === editNotes.note) {
      handleEditComment(noteID, editNotes, true);
      setText('');
    } else {
      handleEditComment(noteID, text, false);
      setText('');
    }
  }

  const handleComment = (e) => {
    if (!text) {
      setOpen && setOpen(false, e);
      return;
    }

    addComment(text);
    !value && setText('');
  }

  return (
    <Collapse in={open}>
      <Box className={classes.commentContainer}>
        <Box className={classes.titleContainer}>
          <span><span className={classes.title}>Me</span></span>
          <span className={classes.titleCounter}>{text?.length}/2000</span>
        </Box>
        <TextField
          inputRef={inputRef}
          onBlur={(e) => editNotes ? editComment() : handleComment(e)}
          value={text}
          id="filled-multiline-static"
          multiline
          fullWidth
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{ maxLength: 2000 }}
          onChange={e => onChange ? onChange(e.target.value) : setText(e.target.value)}
          rows={4}
          variant="filled"
          placeholder="Enter your note here"
          classes={{
            root: classes.input
          }}
        />
      </Box>
    </Collapse>
  )
};

export default AddCommentField;

const documentTypeDto = {
  read: data => ({
    ...data,
    id: data.id,
    name: data.name,
    categoryId: data.categoryId,
    ownedBy: data.ownedBy,
    expected: false,
    ...expirationTypeDto(data.expirationType)
  }),
  write: data => ({
    id: data.id,
    name: data.name,
    categoryId: data.categoryId,
    ownedBy: data.ownedBy
  })
}

export default documentTypeDto

const expirationTypeDto = (expirationType) => {
  switch (expirationType) {
    case 'NEVER':
    case 'PER_TYPE_DEFINED':
      return {
        hasExpiry: false,
        expiryRequired: false,
      }

    case 'USER_DEFINED_REQUIRED':
      return {
        hasExpiry: true,
        expiryRequired: true,
      }

    case 'USER_DEFINED_NOT_REQUIRED':
    default:
      return {
        hasExpiry: true,
        expiryRequired: false,
      }
  }
}
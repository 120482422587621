import React, {useEffect, useState} from 'react';

import {
  Box, FormControl, Grid, InputLabel, MenuItem, Button, Select
} from '@material-ui/core';

import history from '@app/history';
import LinkButton from '@components/buttons/link-button';
import {riskRatingTemplate} from '@app/api/client';
import riskRatingTemplateDto from './dto/risk-rating-template-dto';
import {riskRating} from '@app/api/client';
import CustomTooltip from '@components/controls/tooltip';
import Header from './components/header'

export default function Create(props) {

  const clientData = props.data

  const setClientData = props.setData

  const { baseUri } = props

  const [templateId, setTemplateId] = useState('')

  const [templates, setTemplates] = useState([])

  useEffect(() => {
    if(templateId) {
            onTemplateChange() // eslint-disable-line
    }
    }, [templateId]) // eslint-disable-line

  const onTemplateChange = async () => {
    const riskRatingData = (await riskRating.store({
      clientId: clientData.id,
      riskRatingTemplateId: templateId
    })).data
    setClientData({
      ...clientData,
      hasIncompleteRiskRating: true
    })
    history.push(`${baseUri}/${riskRatingData.id}`)
  }

  useEffect(() => {
        fetchTemplates() //eslint-disable-line
  }, [])

  const fetchTemplates = () => {
    return riskRatingTemplate.index()
      .then(({data}) => {
        setTemplates(
          data.map(riskRatingTemplateDto.read)
            .sort( (a, b) => a.templateName.localeCompare(b.templateName))
        )
      })
  }

  return (
    <React.Fragment>
      <Grid container justify={'center'}>
        <Grid item xs={6}>
          <Header clientData={clientData} baseUri={baseUri}/>

          <Box display={'flex'} flexGrow={1} flexDirection={'column'}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="template">Template</InputLabel>
              <Select
                id={'template'}
                value={templateId}
                onChange={event => setTemplateId(event.target.value)}
              >
                { templates.map(option => (
                  <MenuItem
                    key={option.templateId}
                    value={option.templateId}
                  >
                    {option.templateName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box display={'flex'} justifyContent={'center'}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} my={3}>
                <Box display={'flex'}>
                  <CustomTooltip
                    title={'Before finishing risk rating select a template, set the answer, risk value, weight for all the questions which are not excluded, set risk rating and next review date'}
                    placement="top"
                  >
                    <Box>
                      <Button
                        style={{ width: '250px' }}
                        variant={'contained'}
                        disabled={true}
                      >
                                                FINISH
                      </Button>
                    </Box>
                  </CustomTooltip>
                </Box>
                <Box display={'flex'} my={1}>
                  <CustomTooltip
                    title={'Before exporting report select a template, answer questions, setup risk value and weight'}
                    placement="top"
                  >
                    <Box>
                      <Button
                        style={{ width: '250px' }}
                        variant={'outlined'}
                        disabled={true}
                      >
                                                EXPORT REPORT
                      </Button>
                    </Box>
                  </CustomTooltip>
                </Box>
                <Box display={'flex'}>
                  <CustomTooltip
                    title={'Before saving draft please select a template'}
                    placement="top"
                  >
                    <Box>
                      <LinkButton
                        style={{ width: '250px' }}
                        disabled={true}
                      >
                                                SAVE DRAFT
                      </LinkButton>
                    </Box>
                  </CustomTooltip>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
import React from 'react';
import { Box, Link, Typography } from '@material-ui/core';

import MagnifierImg from '../../assets/landing/backgrounds/magnifier2.png';

import LayoutHeader from '@components/layout/header';
import history from '@app/history';


import Footer from '@components/layout/footer';

export default function NotFound() {

  return (
    <React.Fragment>
      <LayoutHeader/>

      <Box display="flex" pt={15} pb={5} justifyContent="center" alignItems="center" style={{ backgroundColor: '#D5F0F0', maxWidth: '100%', overflowX: 'hidden' }}>
        <img alt={'magnifier'} src={MagnifierImg} style={{ height: 'calc(100vh - 110px - 120px - 40px)' }} />
        <Box zIndex={2} ml={-4} style={{ position: 'absolute' }}>
          <Typography variant="h1" align="center">Page not found</Typography>
          <Typography variant="body1" align="center" style={{ maxWidth: 350, marginTop: 20 }}>The page you were looking for can’t be found or doesn’t exist. Please, go back to our <Link onClick={() => history.push('/')}>Homepage</Link>.</Typography>
        </Box>
      </Box>
      <Footer/>

    </React.Fragment>
  );
}
import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Box, Card, Collapse, IconButton, makeStyles, Typography } from '@material-ui/core';
import RenderInputs from '@components/layout/render-inputs';
import { DropdownThinIcon, WarningIcon, ErrorIcon } from '@app/icons';
import { prepareFieldsForTable } from '@dto/client';

const useStyles = makeStyles((theme) => ({
  downArrow: {
    transition: 'transform 0.4s',
    transform: 'rotate(0deg)',
  },
  upArrow: {
    transform: 'rotate(-180deg)',
  },
  validationMessage: {
    fontWeight: 'bold',
    color: '#484848',
    display: 'block',
    marginRight: 24,
    width: 170,
  },
}))

export default function CardForm({ id, cardIndex, title, fields, model, schema, setModel, errors, setErrors, collapse, setCollapse, withoutCard }) {
  const classes = useStyles()

  const handleChange = (key) => (event) => {
    const newModel = {
      ...model,
      [key]: event.target.value
    }
    setModel(newModel)

    if (errors[key]) {
      schema.validateAt(key, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [key]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [key]: err.message });
        });
    }
  }

  const [hasError, hasWarning] = useMemo(() => {
    const _errors = {}
    const warnings = {}
    Object.entries(errors).forEach(([key, message]) => {
      const field = fields.find(e => e.key === key)
      if (field?.useWarning) {
        warnings[key] = message
      } else {
        _errors[key] = message
      }
    })

    return [
      Object.values(_errors).some(e => !!e),
      Object.values(warnings).some(e => !!e)
    ]
  }, [errors, fields])

  const items = (
    <table id={id} style={{ width: '100%' }}>
      <tbody>
        {(fields.length && Object.keys(model).length) &&
          prepareFieldsForTable(fields).map((row, index) => (
            <tr key={index}>
              {row.left !== null && // Can be null or undefined. Null = row above is multiline, undefined = no field
                <td valign="top" style={{ width: '45%' }} rowSpan={row.left?.multiline ? 2 : 1}>
                  {row.left &&
                    <RenderInputs
                      field={row.left}
                      model={model}
                      handleChange={handleChange}
                      errors={errors}
                      boxProps={{ pb: '12px' }}
                      outsideLabel
                      tabIndex={10 * cardIndex + 1}
                    />
                  }
                </td>
              }
              <td style={{ width: '10%' }}></td>
              {row.right !== null &&
                <td valign="top" style={{ width: '45%' }} rowSpan={row.right?.multiline ? 2 : 1}>
                  {row.right &&
                    <RenderInputs
                      field={row.right}
                      model={model}
                      handleChange={handleChange}
                      errors={errors}
                      boxProps={{ pb: '12px' }}
                      outsideLabel
                      tabIndex={10 * cardIndex + 2}
                    />
                  }
                </td>
              }
            </tr>
          ))
        }
      </tbody>
    </table>
  )

  if (withoutCard) return (
    <Box width="100%" maxWidth={1000} pt={3} pb={2} display="flex" alignItems="center">
      {items}
    </Box>
  )

  return (
    <Card style={{ width: '100%', maxWidth: 1000, marginTop: 12 }}>
      <Box
        id={id}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={3}
        py={2}
        onClick={() => setCollapse(!collapse)}
      >
        <Typography variant="h4" style={{ fontSize: 20, fontWeight: 500 }}>
          {title}
        </Typography>
        <Box display="flex" alignItems="center">
          {hasError &&
            <>
              <ErrorIcon />
              <Typography className={classes.validationMessage}>Missing required system fields</Typography>
            </>
          }
          {(!hasError && hasWarning) &&
            <>
              <WarningIcon />
              <Typography className={classes.validationMessage}>Missing required client fields</Typography>
            </>
          }
          <IconButton style={{ padding: 8, margin: -8 }}>
            <DropdownThinIcon className={clsx(classes.downArrow, collapse && classes.upArrow)} width={24} height={24} />
          </IconButton>
        </Box>
      </Box>
      <Collapse in={collapse}>
        <Box width="calc(100% - 48px)" px={3} pb={3}>
          {items}
        </Box>
      </Collapse>
    </Card>
  )

}

import React, { useState, useMemo } from 'react';
import { Box, Grid, FormControlLabel, Switch, makeStyles } from '@material-ui/core';
import TableList from '@components/lists/table-list';
import OutlinedSearch from '@components/inputs/outlined-search';
import InlineHeadBadge from '@components/badges/inline-head-badge';
import FilterWithContext from '@components/dropdowns/filter-with-context';
import { Modal } from '@components/modals';

import { useSnackbar } from 'notistack';
import { dummy } from '../../dummy';

const useStyles = makeStyles((theme) => ({
  modalButtonWidth: {
    width: '160px',
  },
}));

const TABLE_COLUMNS = [
  { field: 'requestDate', headerName: 'Request Date' },
  { field: 'userName', headerName: 'User' },
  { field: 'email', headerName: 'Email' },
  { field: 'creditPack', headerName: 'Search Credit Pack', bold: true },
  { field: 'price', headerName: 'Price' },
  { field: 'status', headerName: 'Status' },
]

const switchGroup = [
  { value: 'new', label: 'New', isChecked: true },
  { value: 'paid', label: 'Paid', isChecked: true },
  { value: 'canceled', label: 'Canceled', isChecked: true },
]


export default function SearchCreditPacksRequests() {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState(dummy.creditPacksRequests); // eslint-disable-line
  const [search, setSearch] = useState();
  // eslint-disable-next-line
  const [page, setPage] = useState(0);
  const [openModal, setOpenModal] = useState(null);
  const [filters, setFilters] = useState(switchGroup);

  const onSearchChange = (event) => {
    setSearch(event.target.value);
  }

  const onFilterChange = (event) => {
    const changed = filters.map(el =>
      el.value === event.target.value ? ({ ...el, isChecked: event.target.checked }) : el
    );
    setFilters(changed);
  }

  const handleConfirm = () => {
    const actions = { delete: 'deleted', block: 'blocked', unblock: 'unblocked' };
    enqueueSnackbar(
      `Search Credit Pack "${openModal.name}" was successfully ${actions[openModal.type]}!`,
      { variant: 'success' }
    );
    setOpenModal(null);
  }

  const handleMenuAction = (id, item, type) => {
    setOpenModal({ id, name: item.creditPack, type, prefix: 'credit pack' });
  }

  const tableActions = [
    { label: 'Mark as Paid', handler: (id, item) => handleMenuAction(id, item, 'paid') },
    { label: 'Cancel', handler: () => {} },
  ]

  const formattedData = useMemo(() => {
    return data.map(e => {
      const item = { ...e };

      switch (item.status) {
        case 'Paid':
          item.cellProps = {
            status: {
              style: {
                color: '#4E922C'
              }
            }
          }
          item.threeDotsActionsDisabled = ['Mark as Paid']
          break;
        
        case 'Canceled':
          item.cellProps = {
            status: {
              style: {
                color: '#B6514C'
              }
            }
          }
          break;
        
        default:
          break;
      }

      return item;
    })
  }, [data])

  return (
    <Box display={'flex'} flexGrow={'1'} mt={2} flexDirection={'column'}>
      <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'}>
          <Box mr={3}>
            <InlineHeadBadge
              badgeColor={'#CAD7CA'}
              badgeValue={5}
            >
              All requests
            </InlineHeadBadge>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <FilterWithContext>
            {filters.map((el, i) => (
              <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value} - ${i}`}>
                <FormControlLabel
                  control={<Switch value={el.value} checked={el.isChecked} onClick={onFilterChange} />}
                  label={el.label}
                />
              </Box>
            ))}
          </FilterWithContext>
        </Box>
      </Box>
      <Box display={'flex'} mb={3}>
        <OutlinedSearch
          value={search}
          onChange={onSearchChange}
          label="Search credit packs requests"
        />
      </Box>
      <Grid container>
        <Grid item sm={12}>
          <TableList
            columns={TABLE_COLUMNS}
            items={formattedData}
            page={page}
            pageSize={12}
            pagesCount={1}
            totalCount={3}
            sortableColumns={TABLE_COLUMNS.map(el => el.field)}
            handlers={{
              export: (id) => {}
            }}
            maxHeight="calc(100vh - 380px)"
            threeDotsActions={tableActions}
          />
        </Grid>
      </Grid>
      <Modal
        open={!!openModal}
        onClose={() => setOpenModal(null)}
        title={`${openModal?.type} credit pack`}
        mainText={`Are you sure you want to mark as ${openModal?.type} credit pack "${openModal?.name}"?`}
        propsClassses={classes.modalButtonWidth}
        actions={
          [
            {
              type: 'main',
              label: 'No',
              action: () => setOpenModal(null),
            },
            {
              type: 'secondary',
              label: 'Yes',
              action: handleConfirm,
            },
          ]
        }
        actionsDirection="row"
      />
    </Box>
  )
}

import { getCountryName } from '@services/country-service'
import prepareTimestamp from '@utils/date';
import constants, { FIELD_TYPES } from '@app/constants';
import moment from 'moment';
import sub from 'date-fns/sub';
import { cloneDeep } from 'lodash';
import { capitalize } from '@utils/textUtil';
import { Box, Typography } from '@material-ui/core';
import CustomTooltip from '@components/controls/tooltip';
import { WarningIcon } from '@app/icons';
import { LegalEntitySubtypeEnum } from '@services/client-type';
import { clientDocumentType } from '../app/api/client';
import { splitFieldsArrayToTwo } from '../utils/splitArray';

export const OTHER_VALUE = 'Other'
export const OTHER_FIELD = 'OtherField';

export function getSortFields(type) {
  return {
    name: 'name',
    client_type: 'clientType',
    citizenship: 'citizenship',
    residency: 'residency',
    gender: 'gender',
    risk_score: 'riskRating',
    formatted_created_at: 'createdOn',
    last_review_at: 'dateOfReview',
    next_review_at: 'nextReview',
    user: 'user',
    country: 'registeredOfficeCountry',
    formed_at: 'formationDate',
  }
}

export function getSortField(fieldAlias, type) {
  const map = getSortFields(type)
  return map[fieldAlias] || null
}


export const displayLevelMap = {
  'SUMMARY_INFO': 'basicDetails',
  'EXTENDED_SETTINGS': 'extendedDetails',
  'CITIZENSHIP_SETTINGS': 'citizenshipDetails',
  'OWNERSHIPS_SETTINGS': 'ownershipDetails',
  'ACTIVITY': 'activityDetails',
}

const parseStatus = (clientData) => {
  const { clientStatus, properties } = clientData;
  if (!clientStatus || !properties) {
    return clientStatus
  }

  // There are 3 values for violations, true, false, and undefined
  // undefined indicates that the display level has no fields at all
  const violations = {
    basicDetails: false,
  }

  // Set all display level violations to false
  Object.values(properties).forEach(field => {
    if (field?.displayConfig.level) {
      violations[displayLevelMap[field.displayConfig.level]] = false;
    }
  })

  // Find reported violations
  Object.keys(clientStatus.inputFieldViolationMap).forEach(key => {
    const field = properties[key];
    if (field?.displayConfig.level) {
      violations[displayLevelMap[field.displayConfig.level]] = true;
    }
  })

  return {
    ...clientStatus,
    violations,
  }
}

const prepareCommon = (originData, transform = true) => {
  return {
    id: originData.id,
    client_type: originData.clientType,
    risk_score: originData.riskRating || null,
    created_at: originData.createdOn || null,
    formatted_created_at: originData.createdOn ? prepareTimestamp(originData.createdOn, 'MM/DD/YYYY') : null,
    last_review_at: originData.dateOfReview ? prepareTimestamp(originData.dateOfReview, 'MM/DD/YYYY') : null,
    next_review_at: originData.nextReview ? prepareTimestamp(originData.nextReview, 'MM/DD/YYYY', false) : null,
    whitelist: originData.whitelist || null,
    monitory: originData.monitory || null,
    folder_name: originData.folderName || null,
    folder_id: originData.folderId || null,
    primary_email: originData.primaryEmail || null,
    other_email: originData.otherEmail || null,
    phone_number: originData.phoneNumber || null,
    linked_profiles: originData.linkedProfiles || [],
    linked_searches: originData.linkedSearches || [],
    incomplete: originData.incomplete || false,
    note: originData.note || '',
    monitoring: originData.monitory ? 'On' : 'Off',
    basedOnRulebookVersionId: originData.basedOnRulebookVersionId,
    isBasedOnLatestRulebookVersion: originData.isBasedOnLatestRulebookVersion,
    basedOnLatestRulebookVersionId: originData.basedOnLatestRulebookVersionId,
    clientStatus: parseStatus(originData),
    properties: originData.properties,
    typeSpecificProperties: originData.typeSpecificProperties,
    raw: { ...originData },
  }
}

export function preparePerson(originData, transform = true, isAdmin) {
  const data = {
    ...prepareCommon(originData, transform),
    ...{
      first_name: originData.firstName || null,
      middle_name: originData.middleName || null,
      last_name: originData.lastName || null,
      name: [originData.firstName, originData.lastName].filter(v=>!!v).join(' '),
      gender: originData.gender || null,
      date_of_birth: originData.dob ? prepareTimestamp(originData.dob, 'MM/DD/YYYY', false) : null,
      dob: originData.dob,
      residency: originData.residency
                && (transform? getCountryName(originData.residency): originData.residency),
      residency_raw: originData.residency,
      citizenship: originData.citizenship
                && (transform? getCountryName(originData.citizenship): originData.citizenship),
      citizenship_raw: originData.citizenship,
      organization: originData.organization || null,
      place_of_birth: originData.placeOfBirth || null
    }
  }

  return isAdmin ? { ...data, country: data.citizenship } : data
}

export function prepareCompany(originData, transform = true) {
  return {
    ...prepareCommon(originData, transform),
    ...{
      name: originData.name || null,
      formed_at: originData.formationDate ? prepareTimestamp(originData.formationDate, 'MM/DD/YYYY', false) : null,
      country: originData.registeredOfficeCountry
                && (transform? getCountryName(originData.registeredOfficeCountry): originData.registeredOfficeCountry),
      type: originData.type || null,
      folder_name: originData.folderName || null,
      registration_number: originData.typeSpecificProperties?.registrationNumber?.value || null,
    }
  }
}

export function prepareClient(originData, transform = true, isAdmin = false) {
  return originData.clientType === 'PERSON'
    ? preparePerson(originData, transform, isAdmin)
    : prepareCompany(originData, transform)
}

const SYSTEM_ADDRESS_FIELDS = {
  address1: { key: 'address1', label: 'Address 1', type: FIELD_TYPES.INPUT, isAddress: true },
  address2: { key: 'address2', label: 'Address 2', type: FIELD_TYPES.INPUT, isAddress: true },
  city: { key: 'city', label: 'City', type: FIELD_TYPES.INPUT, isAddress: true },
  stateProvinceRegion: { key: 'stateProvinceRegion', label: 'State/Province/Region', type: FIELD_TYPES.INPUT, isAddress: true },
  postalZipCode: { key: 'postalZipCode', label: 'Postal/Zip Code', type: FIELD_TYPES.INPUT, isAddress: true },
}

export const SYSTEM_FIELDS_PERSON = [
  { key: 'externalId', label: 'External ID', type: FIELD_TYPES.INPUT, required: false, size: 100, isDefault: true },
  { key: 'firstName', label: 'First Name', type: FIELD_TYPES.INPUT, required: true, size: 30, isDefault: true },
  { key: 'middleName', label: 'Middle Name', type: FIELD_TYPES.INPUT, size: 30, isDefault: true },
  { key: 'lastName', label: 'Last Name', type: FIELD_TYPES.INPUT, required: true, size: 30, isDefault: true },
  { key: 'gender', label: 'Gender', type: FIELD_TYPES.SELECT, required: true, isDefault: true,
    options: {
      male: 'Male',
      female: 'Female'
    },
  },
  { key: 'dob', label: 'Date of Birth', type: FIELD_TYPES.DATE, isDefault: true,
    tests: [
      (yup) => yup.test('min_date', 'Date of birth should be after 1900', function () {
        return !moment(this.parent.dob).isBefore('01/01/1900')
      }),
      (yup) => yup.max(sub(new Date(), { days: 1 }), 'Date of birth should be before current date'),
    ]
  },
  { key: 'citizenship', label: 'Citizenship', type: FIELD_TYPES.COUNTRY, isDefault: true },
  { ...SYSTEM_ADDRESS_FIELDS.address1 },
  { ...SYSTEM_ADDRESS_FIELDS.address2 },
  { ...SYSTEM_ADDRESS_FIELDS.city },
  { ...SYSTEM_ADDRESS_FIELDS.stateProvinceRegion },
  { key: 'residency', label: 'Residency', type: FIELD_TYPES.COUNTRY, required: true, isDefault: true, isRulebookCountry: true },
  { ...SYSTEM_ADDRESS_FIELDS.postalZipCode },
  { key: 'countryOfBirth', label: 'Country of Birth', type: FIELD_TYPES.COUNTRY, isDefault: true },
  { key: 'placeOfBirth', label: 'Place of Birth', type: FIELD_TYPES.INPUT, isDefault: true },
  { key: 'nationalIdentifier', label: 'Personal Number', type: FIELD_TYPES.INPUT, isDefault: true },
  { key: 'passportNumber', label: 'Passport Number', type: FIELD_TYPES.INPUT, isDefault: true },
  { key: 'primaryEmail', label: 'Primary Email', type: FIELD_TYPES.EMAIL, isDefault: true },
  { key: 'otherEmail', label: 'Other Email', type: FIELD_TYPES.EMAIL, isDefault: true,
    tests: [
      (yup) => yup.test('same_email', 'Primary and Other email must be different', function () {
        return !this.parent.otherEmail || this.parent.primaryEmail !== this.parent.otherEmail
      })
    ],
  },
  { key: 'phoneNumber', label: 'Phone Number', type: FIELD_TYPES.MASKED, mask: constants.phoneMask, isDefault: true },
  { key: 'organization', label: 'Organization', type: FIELD_TYPES.INPUT, size: 60, isDefault: true },
  { key: 'folderId', label: 'Folder', type: FIELD_TYPES.SELECT, required: true, isDefault: true, options: {} },
  { key: 'note', label: 'Note', type: FIELD_TYPES.INPUT, multiline: true, isDefault: true },
]

export const SYSTEM_FIELDS_COMPANY = [
  { key: 'externalId', label: 'External ID', type: FIELD_TYPES.INPUT, required: false, size: 100, isDefault: true },
  { key: 'name', label: 'Legal Entity Name', type: FIELD_TYPES.INPUT, required: true, size: 60, isDefault: true },
  { key: 'type', label: 'Entity Type', type: FIELD_TYPES.SELECT, required: true, isDefault: true, isRulebookCountry: true,
    options: LegalEntitySubtypeEnum,
  },
  { key: 'formationDate', label: 'Date of Incorporation / Formation', type: FIELD_TYPES.DATE, isDefault: true,
    tests: [
      (yup) => yup.test('min_date', 'Date of Incorporation / Formation should be after 1900', function () {
        return !moment(this.parent.formationDate).isBefore('1900-01-01')
      }),
      (yup) => yup.max(sub(new Date(), { days: 1 }),  'Date of Incorporation / Formation should be before current date'),
    ]
  },
  { ...SYSTEM_ADDRESS_FIELDS.address1 },
  { ...SYSTEM_ADDRESS_FIELDS.address2 },
  { ...SYSTEM_ADDRESS_FIELDS.city },
  { ...SYSTEM_ADDRESS_FIELDS.stateProvinceRegion },
  { key: 'registeredOfficeCountry', label: 'Jurisdiction', type: FIELD_TYPES.COUNTRY, required: true, isDefault: true, isRulebookCountry: true },
  { ...SYSTEM_ADDRESS_FIELDS.postalZipCode },
  { key: 'phoneNumber', label: 'Phone Number', type: FIELD_TYPES.MASKED, mask: constants.phoneMask, isDefault: true },
  { key: 'primaryEmail', label: 'Primary Email', type: FIELD_TYPES.EMAIL, isDefault: true },
  { key: 'otherEmail', label: 'Other Email', type: FIELD_TYPES.EMAIL, isDefault: true,
    tests: [
      (yup) => yup.test('same_email', 'Primary and Other email must be different', function () {
        return !this.parent.otherEmail || this.parent.primaryEmail !== this.parent.otherEmail
      })
    ],
  },
  { key: 'folderId', label: 'Folder', type: FIELD_TYPES.SELECT, required: true, isDefault: true, options: {} },
  { key: 'note', label: 'Note', type: FIELD_TYPES.INPUT, multiline: true, isDefault: true },
]

const typeMap = {
  TEXT: FIELD_TYPES.INPUT,
  LIST: FIELD_TYPES.SELECT,
  BOOLEAN: FIELD_TYPES.BOOLEAN,
  COUNTRY_LIST: FIELD_TYPES.COUNTRY,
  DATE: FIELD_TYPES.DATE,
  NUMBER: FIELD_TYPES.NUMBER,
}

export function inputFieldsMapDto(inputFieldsMap, type, excludeRulebookCountry, folders) {
  let fieldsMap

  if (type.toLowerCase() === 'person') {
    fieldsMap = {
      basicDetails: cloneDeep(SYSTEM_FIELDS_PERSON)
        .filter(e => excludeRulebookCountry ? (excludeRulebookCountry && !e.isRulebookCountry) : e)
        .filter(e => folders ? e : e.key !== 'folderId'),
      extendedDetails: [],
      citizenshipDetails: [],
    }
  } else {
    fieldsMap = {
      basicDetails: cloneDeep(SYSTEM_FIELDS_COMPANY)
        .filter(e => excludeRulebookCountry ? (excludeRulebookCountry && !e.isRulebookCountry) : e)
        .filter(e => folders ? e : e.key !== 'folderId'),
      extendedDetails: [],
      ownershipDetails: [],
      activityDetails: [],
    }
  }

  if (!inputFieldsMap) {
    return fieldsMap
  }

  if (folders) {
    const field = fieldsMap.basicDetails.find(e => e.key === 'folderId')
    field.options = folders.reduce(
      (obj, folder) => ({ ...obj, [folder.id]: folder.name }),
      {}
    )
  }

  Object.values(inputFieldsMap)
    .sort((a, b) => {
      if (a.isTypeSpecific && !b.isTypeSpecific) return -1
      if (b.isTypeSpecific && !a.isTypeSpecific) return 1

      return a.displayConfig.order - b.displayConfig.order
    })
    .forEach(prop => {
      const { id, displayConfig, displayName, validation, definition, isTypeSpecific } = prop;

      const field = {
        key: id,
        label: displayName,
        type: typeMap[definition.type],
        required: validation === 'REQUIRED',
        useWarning: true,
        size: definition.size,
        multiline: definition.size > 100,
        dataType: definition.type === 'NUMBER' ? 'number' : undefined,
        options: definition.options?.reduce(
          (obj, option) => ({ ...obj, [option]: option }),
          {}
        ),
        allowOther: definition.allowOther,
        isTypeSpecific,
      }

      if (fieldsMap[displayLevelMap[displayConfig.level]]) {
        if (id === 'registrationNumber') { // Special case to insert registration number as #2 field
          fieldsMap[displayLevelMap[displayConfig.level]].splice(1, 0, field)
        } else {
          fieldsMap[displayLevelMap[displayConfig.level]].push(field)
        }
      }
    })

  return fieldsMap
}

export function parseModel(client, displayLevel, folders) {
  if (!client || !Object.keys(client).length) return {};
  const inputFieldsMap = getClientInputFieldsMap(client)
  const fields = inputFieldsMapDto(inputFieldsMap, client.client_type, false, folders)[displayLevel]

  const model = {}
  fields.forEach(field => {
    switch (true) {
      case field.isDefault:
        model[field.key] = client.raw[field.key] || ''
        break;
      
      case field.isAddress:
        model[field.key] = client.raw.address?.[field.key] || ''
        break;

      case field.isTypeSpecific:
        model[field.key] = client.typeSpecificProperties?.[field.key]?.value || ''
        break;
          
      default:
        model[field.key] = client.properties[field.key]?.value || ''
        break;
    }

    if (field.allowOther) {
      if (model[field.key] &&
        !Object.values(field.options).includes(model[field.key]) &&
        model[field.key] !== OTHER_VALUE
      ) {
        model[field.key + OTHER_FIELD] = model[field.key]
        model[field.key] = OTHER_VALUE
      }
    }
  })

  return model
}

export function getClientInputFieldsMap(client) {
  if (!client || !Object.keys(client).length) return {};

  let inputFieldsMap = {};

  if (client?.typeSpecificProperties) {
    Object.entries(client.typeSpecificProperties).forEach(([key, value]) => {
      inputFieldsMap[key] = {
        ...value,
        isTypeSpecific: true,
      }
    })
  }

  inputFieldsMap = {
    ...inputFieldsMap,
    ...client?.properties,
  }

  return inputFieldsMap;
}

export function parseLabels(client, displayLevel, folders) {
  if (!client || !Object.keys(client).length) return [];

  const inputFieldsMap = getClientInputFieldsMap(client)
  const fields = inputFieldsMapDto(inputFieldsMap, client.client_type, false, folders)[displayLevel]

  const labels = [];
  fields.forEach(field => {
    const label = {
      field: field.label,
      large: true,
    }

    switch (true) {
      case field.isDefault:
        label.value = client.raw[field.key]
        break;

      case field.isAddress:
        label.value = client.raw.address?.[field.key]
        break;

      case field.isTypeSpecific:
        label.value = client.typeSpecificProperties[field.key]?.value
        break;

      default:
        label.value = client.properties[field.key]?.value
        break;
    }

    if (label.value) {
      switch (field.type) {
        case FIELD_TYPES.DATE:
          label.value = prepareTimestamp(label.value, 'MM/DD/YYYY', false)
          break;

        case FIELD_TYPES.BOOLEAN:
          label.value = label.value ? 'Yes' : 'No'
          break;

        case FIELD_TYPES.COUNTRY:
          label.value = getCountryName(label.value)
          break;
      
        default:
          break;
      }

      if (field.label === 'Gender') {
        label.value = capitalize(label.value)
      }

      if (field.key === 'folderId') {
        label.value = folders.find(e => e.id === label.value)?.name
      }
    } else if (field.required) {
      label.value = (
        <Box display="flex" alignItems="center">
          <Typography>N/A</Typography>
          <CustomTooltip
            title={`${label.field} is required`}
            placement="top"
          >
            <WarningIcon />
          </CustomTooltip>
        </Box>
      )
      label.notText = true
    } else {
      label.value = 'N/A'
    }

    labels.push(label)
  })

  return labels;
}

export const generateModelFromFields = (fields) => {
  const model = {}
  fields.forEach(field => {
    model[field.key] = ''
    if (field.defaultValue) {
      model[field.key] = field.defaultValue
    }
  })
  return model
}

export const prepareDataForUpdate = (clientData, model) => {
  const inputFieldsMap = getClientInputFieldsMap(clientData)
  const fieldsMap = inputFieldsMapDto(inputFieldsMap, clientData.client_type)
  let flattenedFields = [];
  Object.values(fieldsMap).forEach((fields) => {
    flattenedFields = flattenedFields.concat(fields);
  })

  const preparedData = {
    address: {},
    properties: {
      propertyMap: {},
    },
    typeSpecificProperties: {
      propertyMap: {},
    },
  }

  const origData = clientData.raw;

  flattenedFields.forEach(field => {
    let data
    let value = model[field.key]

    if (value === undefined) {
      switch (true) {
        case field.isDefault:
          value = value || origData[field.key]
          break;

        case field.isAddress:
          value = value || origData.address?.[field.key]
          break;

        case field.isTypeSpecific:
          value = value || clientData.typeSpecificProperties?.[field.key]?.value
          break;

        default:
          value = value || clientData.properties?.[field.key]?.value
          break;
      }
    }

    if (field.allowOther && value === OTHER_VALUE) {
      value = model[field.key + OTHER_FIELD] || OTHER_VALUE
    }

    switch (field.type) {
      case FIELD_TYPES.DATE:
        data = value ? moment(value).format('YYYY-MM-DD') : undefined
        break;

      default:
        data = value === '' ? undefined : value
        break;
    }

    if (data !== undefined && data !== null) {
      switch (true) {
        case field.isDefault:
          preparedData[field.key] = data
          break;

        case field.isAddress:
          preparedData.address[field.key] = data
          break;

        case field.isTypeSpecific:
          preparedData.typeSpecificProperties.propertyMap[field.key] = {
            id: field.key,
            value: data,
          }
          break;

        default:
          preparedData.properties.propertyMap[field.key] = {
            id: field.key,
            value: data,
          }
          break;
      }
    }
  })

  return preparedData;
}

const fillTypeName = async (changes, typeIds) => {
  const typesMap = {}
  const allRes = await Promise.all([...typeIds].map(id => (
    clientDocumentType.show(id)
  )))

  allRes.forEach(res => {
    typesMap[res.data.id] = res.data;
  })

  changes.forEach(change => {
    if (change.typeId) {
      change.message = change.message.replace('$typeName', typesMap[change.typeId].name)
    }

    if (change.typeIds) {
      change.typeIds.forEach(typeId => {
        change.message = change.message.replace('$typeName', typesMap[typeId].name)
      })
    }
  })
}

const checkRemovedChanges = async (data) => {
  const removedChanges = [];
  const typeIds = new Set(); // To fetch
  
  // Documents
  data.documentRulesDiff.deleted.forEach(doc => {
    doc.types.forEach(type => {
      const message = '"$typeName" document';
      removedChanges.push({
        type: 'document',
        typeId: type.typeId,
        message,
      })
      typeIds.add(type.typeId)
    })
  })

  for (let index = 0; index < data.documentRulesDiff.updatedFrom.length; index++) {
    const oldDoc = data.documentRulesDiff.updatedFrom[index];
    const newDoc = data.documentRulesDiff.updatedTo[index];

    oldDoc.types.forEach(oldType => {
      const newType = newDoc.types.find(e => e.typeId === oldType.typeId)
      if (!newType) {
        const message = '"$typeName" document';
        removedChanges.push({
          type: 'document',
          typeId: oldType.typeId,
          message,
        })
        typeIds.add(oldType.typeId)
      }
    })
  }


  await fillTypeName(removedChanges, typeIds)

  // Input fields
  data.inputFieldsDiff.deleted.forEach(field => {
    const message = `"${field.displayName}" input field`
    removedChanges.push({
      type: 'inputField',
      message,
    })
  })

  return removedChanges;
}

const checkAddedChanges = async (data) => {
  const addedChanges = [];
  const typeIds = new Set(); // To fetch

  // Documents
  data.documentRulesDiff.added.forEach(doc => {
    if (doc.categoryRuleType === 'ONE_OF') {
      const message = `Any of ${doc.types.map(e => '"$typeName"').join(', ')} document`;
      addedChanges.push({
        type: 'document',
        typeIds: doc.types.map(e => e.typeId),
        message,
      })
      doc.types.forEach(e => typeIds.add(e.typeId))
    } else {
      doc.types.forEach(type => {
        const message = `${type.expected ? 'Required' : 'Optional'} "$typeName" document`;
        addedChanges.push({
          type: 'document',
          typeId: type.typeId,
          message,
        })
        typeIds.add(type.typeId)
      })
    }
  })


  for (let index = 0; index < data.documentRulesDiff.updatedFrom.length; index++) {
    const oldDoc = data.documentRulesDiff.updatedFrom[index];
    const newDoc = data.documentRulesDiff.updatedTo[index];

    newDoc.types.forEach(newType => {
      const oldType = oldDoc.types.find(e => e.typeId === newType.typeId)
      if (!oldType) {
        const message = `${newType.expected ? 'Required' : 'Optional'} "$typeName" document`;
        addedChanges.push({
          type: 'document',
          typeId: newType.typeId,
          message,
        })
        typeIds.add(newType.typeId)
      }
    })
  }

  await fillTypeName(addedChanges, typeIds)

  // Input fields
  data.inputFieldsDiff.added.forEach(field => {
    const message = `${(field.validation === 'OPTIONAL' ? 'Optional' : 'Required')} "${field.displayName}" input field`
    addedChanges.push({
      type: 'inputField',
      message,
    })
  })

  return addedChanges;
}

const typeNameMap = {
  TEXT: 'text',
  LIST: 'custom list',
  BOOLEAN: 'boolean',
  COUNTRY_LIST: 'country list',
  DATE: 'date',
  NUMBER: 'number',
}

const displayLevelName = {
  'SUMMARY_INFO': 'Basic Details',
  'EXTENDED_SETTINGS': 'Extended Details',
  'CITIZENSHIP_SETTINGS': 'Citizenship Details',
  'OWNERSHIPS_SETTINGS': 'Ownership Information',
  'ACTIVITY': 'Activity and Regulation',
}

const checkUpdatedChanges = async (data) => {
  const updatedChanges = [];
  const typeIds = new Set(); // To fetch

  // Documents
  for (let index = 0; index < data.documentRulesDiff.updatedFrom.length; index++) {
    const oldDoc = data.documentRulesDiff.updatedFrom[index];
    const newDoc = data.documentRulesDiff.updatedTo[index];

    if (oldDoc.categoryRuleType !== newDoc.categoryRuleType) {
      if (newDoc.categoryRuleType === 'ONE_OF') {
        const message = `${newDoc.types.map(e => '"$typeName"').join(', ')} document will become any of`;
        updatedChanges.push({
          type: 'document',
          typeIds: newDoc.types.map(e => e.typeId),
          message,
        })
        newDoc.types.forEach(e => typeIds.add(e.typeId))
      } else {
        newDoc.types.forEach(type => {
          const message = `"$typeName" document will become ${type.expected ? 'required' : 'optional'}`;
          updatedChanges.push({
            type: 'document',
            typeId: type.typeId,
            message,
          })
          typeIds.add(type.typeId)
        })
      }
    } else {
      newDoc.types.forEach(newType => {
        const oldType = oldDoc.types.find(e => e.typeId === newType.typeId)
        if (oldType && (oldType.expected !== newType.expected)) {
          const message = `"$typeName" document will become ${newType.expected ? 'required' : 'optional'}`;
          updatedChanges.push({
            type: 'document',
            typeId: newType.typeId,
            message,
          })
          typeIds.add(newType.typeId)
        }
      })
    }
  }

  await fillTypeName(updatedChanges, typeIds)

  // Input fields
  for (let index = 0; index < data.inputFieldsDiff.updatedFrom.length; index++) {
    const oldField = data.inputFieldsDiff.updatedFrom[index];
    const newField = data.inputFieldsDiff.updatedTo[index];

    if (oldField.displayName !== newField.displayName) {
      updatedChanges.push({
        type: 'inputField',
        message: `"${oldField.displayName}" input field will be renamed to ${newField.displayName}`
      })
    }

    if (oldField.displayConfig.level !== newField.displayConfig.level) {
      updatedChanges.push({
        type: 'inputField',
        message: `"${oldField.displayName}" input field will be moved under ${displayLevelName[newField.displayConfig.level]}`
      })
    }

    if (oldField.displayConfig.order !== newField.displayConfig.order) {
      updatedChanges.push({
        type: 'inputField',
        message: `"${oldField.displayName}" input field will have a different order`
      })
    }

    if (oldField.validation !== newField.validation) {
      updatedChanges.push({
        type: 'inputField',
        message: `"${oldField.displayName}" input field will become ${newField.validation === 'OPTIONAL' ? 'optional' : 'required'}`
      })
    }

    if (oldField.definition.type !== newField.definition.type) {
      updatedChanges.push({
        type: 'inputField',
        message: `"${oldField.displayName}" input field will use answer type ${typeNameMap[newField.definition.type]}`
      })
      continue; // If type changes, don't need to check other below it
    }

    if (oldField.definition.allowOther !== newField.definition.allowOther) {
      updatedChanges.push({
        type: 'inputField',
        message: `"${oldField.displayName}" input field will ${newField.definition.allowOther ? 'allow adding custom option' : 'disable adding custom option'}`
      })
    }

    if (oldField.definition.options?.length !== newField.definition.options?.length
      || oldField.definition.options?.every(option => newField.definition.options.includes(option))
    ) {
      updatedChanges.push({
        type: 'inputField',
        message: `"${oldField.displayName}" input field will support additional options`
      })
    }

    if (oldField.definition.size !== newField.definition.size) {
      updatedChanges.push({
        type: 'inputField',
        message: `"${oldField.displayName}" input field will allow up to ${newField.definition.size} characters`
      })
    }

  }

  return updatedChanges;
}

export const checkRuleChanges = async (data) => {
  const [
    removedChanges,
    addedChanges,
    updatedChanges
  ] = await Promise.all([
    checkRemovedChanges(data),
    checkAddedChanges(data),
    checkUpdatedChanges(data),
  ])

  return {
    removedChanges,
    addedChanges,
    updatedChanges,
  }
}

export const prepareFieldsForTable = (fields) => {
  const [leftSides, rightSides] = splitFieldsArrayToTwo(fields);
  
  leftSides.slice().forEach((field, index) => {
    if (field.multiline) { // Insert null right after multiline
      leftSides.splice(index + 1, 0, null);
    }
  })

  rightSides.slice().forEach((field, index) => {
    if (field.multiline) { // Insert null right after multiline
      rightSides.splice(index + 1, 0, null);
    }
  })

  // Combine into one array for simplicity
  const rows = []

  // Get sides with more rows
  if (leftSides.length > rightSides.length) {
    leftSides.forEach((left, index) => {
      const right = rightSides[index] || undefined
      rows.push({ left, right })
    })
  } else {
    rightSides.forEach((right, index) => {
      const left = leftSides[index] || undefined
      rows.push({ left, right })
    })
  }

  return rows;
}

import React from 'react';
import {
  Box, Typography
} from '@material-ui/core';

export default function BasicItem(props) {
  const { title, content } = props;
  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Typography variant="h5">{title}</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="h3">{content}</Typography>
      </Box>
    </Box>
  )
}

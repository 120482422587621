import React, { useMemo, useState } from 'react';
import { Box, Card, Typography } from '@material-ui/core';
import { Modal } from '@components/modals';
import {clientApi} from '@app/api';
import { getCountryName } from '@services/country-service'
import prepareTimestamp from '@utils/date';
import LabelsForRender from '@components/layout/labels-for-render';

export function useFindDuplicateClient() {
  const [open, setOpen] = useState(false);
  const [clients, setClients] = useState([]);

  const findDuplicatePerson = async ({
    id,
    residency,
    firstName,
    lastName,
    dob,
    countryOfBirth,
  }) => {
    let duplicates = [];
    const params = {
      firstName,
      lastName,
      residency,
      pageNumber: 0,
      pageSize: 50,
    }

    const res = await clientApi.person.search(params)
    duplicates = duplicates.concat(res.data.entries)

    if (id) duplicates = duplicates.filter(e => e.id !== id);
    if (dob) duplicates = duplicates.filter(e => e.dob === dob);
    if (countryOfBirth) duplicates = duplicates.filter(e => e.countryOfBirth === countryOfBirth);

    if (duplicates.length) {
      setOpen(true);
      setClients(duplicates);
      return true;
    }

    return false;
  }

  const findDuplicateCompany = async ({
    id,
    legalEntityType,
    name,
    registeredOfficeCountry,
    registrationNumber,
  }) => {
    let duplicates = [];
    const params = {
      legalEntityTypes: [legalEntityType],
      searchKeyword: name,
      registeredOfficeCountry,
      pageNumber: 0,
      pageSize: 50,
    }

    const res = await clientApi.company.search(params)
    duplicates = duplicates.concat(res.data.entries)

    if (id) duplicates = duplicates.filter(e => e.id !== id);
    if (registrationNumber) duplicates = duplicates.filter(e => e.typeSpecificProperties?.registrationNumber?.value === registrationNumber);

    if (duplicates.length) {
      setOpen(true);
      setClients(duplicates);
      return true;
    }

    return false;
  }
  const duplicateDialogProps = { open, setOpen, clients };
  return { duplicateDialogProps, findDuplicatePerson, findDuplicateCompany };
}

function PersonItem({ client }) {
  const labels = useMemo(() => {
    const labels = [];

    labels.push({
      field: 'Residency',
      value: getCountryName(client.residency),
      large: true
    })

    if (client.dob) {
      labels.push({
        field: 'DoB',
        value: prepareTimestamp(client.dob, 'MM/DD/YYYY', false),
        large: true
      })
    }

    if (client.countryOfBirth) {
      labels.push({
        field: 'Country of Birth',
        value: getCountryName(client.countryOfBirth),
        large: true,
      })
    }

    return labels;
  }, [client])

  const onClick = () => {
    window.open('/portfolio/view/' + client.id, '_blank').focus()
  }

  const name = useMemo(() => {
    const names = []
    names.push(client.firstName);
    client.middleName && names.push(client.middleName);
    names.push(client.lastName);

    return names.join(' ');
  }, [client])
  return (
    <Box width={300} p={1}>
      <Card onClick={onClick}>
        <Box p={1}>
          <Typography variant="h5" align="center">{name}</Typography>
          <Box p={1} display="flex" justifyContent="center">
            <Box>
              <LabelsForRender
                labels={labels}
                labelProps={{
                  style: {
                    minWidth: 120,
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

function CompanyItem({ client }) {
  const labels = useMemo(() => {
    const labels = [];

    labels.push({
      field: 'Type',
      value: client.type,
      large: true
    })

    labels.push({
      field: 'Jurisdiction',
      value: getCountryName(client.registeredOfficeCountry),
      large: true
    })

    if (client.typeSpecificProperties?.registrationNumber?.value) {
      labels.push({
        field: 'Registration No.',
        value: client.typeSpecificProperties.registrationNumber.value,
        large: true,
      })
    }

    return labels;
  }, [client])

  const onClick = () => {
    window.open('/portfolio/view/' + client.id, '_blank').focus()
  }

  return (
    <Box width={300} p={1}>
      <Card onClick={onClick}>
        <Box p={1}>
          <Typography variant="h5" align="center">{client.name}</Typography>
          <Box p={1} display="flex" justifyContent="center">
            <Box>
              <LabelsForRender
                labels={labels}
                labelProps={{
                  style: {
                    minWidth: 120,
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

export default function DuplicateClientDialog({ open, setOpen, clients, type, onProceed, isEdit }) {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      title="Client already exists"
      content={
        <Box width={400}>
          <Typography align="center">
            We identified potential duplicates in your account.
          </Typography>
          <Typography align="center">Please review the following profiles:</Typography>
          <Box mt={1} mb={4} display="flex" flexDirection="column" alignItems="center" maxHeight={400} style={{ overflowY: 'auto' }}>
            {clients.map(client => (
              type === 'person' ?
                <PersonItem key={client.id} client={client} />
                :
                <CompanyItem key={client.id} client={client} />
            ))}
          </Box>
          <Typography align="center">Do you still want to proceed with {isEdit ? 'updating' : 'adding'} this client?</Typography>
        </Box>
      }
      actions={
        [
          {
            type: 'main',
            label: 'CANCEL',
            action: () => { setOpen(false) },
            style: { width: 160 },
          },
          {
            type: 'secondary',
            label: 'PROCEED',
            action: onProceed,
            style: { width: 160 },
          },
        ]
      }
      actionsDirection="row"
    />
  )
}

import React from 'react';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InlineBadge from './inline-badge';


const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'flex-start',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    textTransform: 'uppercase',
    color: '#656565'
  },
  buttonActive: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  },
}));

export default function InlineHeadBadge(props) {
  const { onClick = () => {}, selected = true, badgeColor, textColor, badgeValue, children } = props;
  const classes = useStyles();

  return (
    <Button onClick={onClick} className={selected && classes.buttonActive}>
      <Box display={'flex'} alignItems={'center'} className={classes.root}>
        <Box display={'flex'} flexGrow={0} mr={1}>{children}</Box>
        <InlineBadge color={badgeColor} textColor={textColor} fontWeight={500}>{badgeValue}</InlineBadge>
      </Box>
    </Button>
  )
}
import React, { useState, useCallback, useEffect, useMemo } from  'react';
import {Box, Grid, Typography, Link, MenuItem} from '@material-ui/core';
import { Modal } from '@components/modals';
import SearchBlock from './components/seacrh-components/search-block';
import SearchCreditsBlock from './components/seacrh-components/search-credits-block';

import { clientApi } from '@app/api';
import { subscriptionPlans } from '@app/api/client';
import { useDispatch, useSelector } from 'react-redux';
import { backgroundCheck, searchHistory, backgroundSearch } from '@store/actions';

import { REQUEST_REMOVE_FIELDS, PREMIUM, MODAL_TEXT } from './components/seacrh-components/constants';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import history from '@app/history';
import { useStyles } from '../styles';
import { injectAvailableCredits } from '@dto/plan';
import MenuWithContext from '@components/dropdowns/menu-with-context';
import AboutBGCheckDialog from '../dialogs/about-bg-check-dialog';

export default function Search(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { search_line, clientId } = useParams();
  const { onNext } = props;

  const currentSearch = useSelector(store => store.backgroundCheck.currentSearch);
  const { searchModel } = useSelector(state => state.backgroundSearch);

  const classes = useStyles();

  const [openModal, setOpenModal] = useState({});
  const [openConfirmedModal, setOpenConfirmedModal] = useState();
  const [currentPlan, setCurrentPlan] = useState(null);
  const [showAboutDialog, setShowAboutDialog] = useState(false);
  const dispatch = useDispatch();

  const quantityCredits = useMemo(() => {
    return searchModel.searchProvider === PREMIUM
      ? currentPlan?.availablePremiumCredits
      : currentPlan?.availableRegularCredits
  }, [searchModel.searchProvider, currentPlan])

  const getPlanById = useCallback(async (currentPlanId) => {
    const res = await subscriptionPlans.getById(currentPlanId);

    if (res.status === 200) {
      injectAvailableCredits(res.data)
      setCurrentPlan(plan => ({ ...res.data, ...plan}));
    }
  }, [])

  useEffect(() => {
    const initiateSearch = async () => {
      dispatch(backgroundCheck.setChangedProfiles({}));
      dispatch(searchHistory.setDefault());
      dispatch(backgroundSearch.setDefault());

      const res = await subscriptionPlans.getSubscriptionForUser();
      let searchProvider
      if (res.status === 200) {
        injectAvailableCredits(res.data)
        setCurrentPlan(res.data);

        if (!!res.data.remainingPremiumCredits) {
          searchProvider = 'COMPLY_ADVANTAGE'
        }
        getPlanById(res.data.currentPlanId)
      }
      if (searchProvider) {
        dispatch(backgroundSearch.setSearchModel({ searchProvider }));
      }
    }

    initiateSearch();

    if(!localStorage.getItem('view-about-bg-check')) {
      setShowAboutDialog(true)
      localStorage.setItem('view-about-bg-check', '1')
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let name
    if (!isEmpty(currentSearch) && currentSearch?.name) {
      name = currentSearch.name
    }
    if (search_line) {
      name = search_line
    }
    if (props.search_line) {
      name = props.search_line
    }
    if (name) {
      dispatch(backgroundSearch.setSearchModel({ name }));
    }
    // eslint-disable-next-line
  }, [search_line, props.search_line, currentSearch])

  useEffect(() => {
    let type
    if (props.forceType) {
      type = (props.forceType === 'LEGAL_ENTITY' ? 'COMPANY' : props.forceType) || 'PERSON'
    }
    if (type) {
      dispatch(backgroundSearch.setSearchModel({ type }));
    }
    // eslint-disable-next-line
  }, [props.forceType])

  const prepareSearchData = useCallback(() => {
    const name = searchModel.name.trim();

    let data;
    for (const key in searchModel) {
      if (!!searchModel[key] || key === 'fuzziness') {
        data = {
          ...data,
          [key]: (key === 'listTypes' && searchModel.searchProvider === 'ALEPH')
            ? searchModel[key].filter(el => el !== 'ADVERSE_MEDIA')
            : searchModel[key]
        }
      }
    }

    if (data.listTypes.length === 0) {
      if (data.searchProvider === 'ALEPH') {
        data.listTypes = ['SANCTIONS', 'PEP']
      } else {
        data.listTypes = ['SANCTIONS', 'PEP', 'ADVERSE_MEDIA']
      }
    }

    data.name = name;
    const remove = REQUEST_REMOVE_FIELDS[searchModel.searchProvider][searchModel.type];
    for (const key of remove) {
      delete data[key];
    }

    if (!!data.yob && +data.yob < 1900) {
      enqueueSnackbar(`"Year of Birth" should be between 1900 and ${new Date().getFullYear()}!`, { variant: 'error' });
      return;
    }

    return data;
  }, [searchModel, enqueueSnackbar])

  const getEntities = useCallback(async() => {
    const data = prepareSearchData();
    
    if (!data) return;
    dispatch(backgroundCheck.updateCurrentSearch(data));
    const res = await clientApi.search.searchEntities(data);

    setOpenModal({});
    if (res.status === 200) {
      onNext({ clientId, searchId: res.data.searchId, searchName: data.name, searchType: data.type });
    }
  }, [clientId, prepareSearchData, dispatch, setOpenModal, onNext])

  const checkExistingSearch = useCallback(async () => {
    if (quantityCredits <= 0 || searchModel.name?.trim()?.length < 2) {
      dispatch(backgroundSearch.setIsError(true));
      return;
    }

    const data = prepareSearchData();
    if (!data) return;

    const res = await clientApi.search.checkExistingSearch(data);

    if (res.status === 200 && !!res.data.length) {
      const search = res.data.pop();
      const country = search.country ? `${search.country}, ` : '';
      const yob = search.yob ? `${search.yob}, ` : '';
      const gender = search.gender ? `${search.gender}` : '';
      const registrationNumber = search.registrationNumber ? `${search.registrationNumber}` : '';
      const name = (!!country || !!yob || !!gender || !!registrationNumber) ? `${data.name}, ` : `${data.name}`;
      const modalText = search.type === 'PERSON'
        ? `${name}${country}${yob}${gender}`.trim()
        : `${name} ${country} ${registrationNumber}`.trim();

      setOpenModal({
        text: modalText,
        id: search?.searchId,
      });
    } else {
      setOpenConfirmedModal(true);
    }

  }, [prepareSearchData, dispatch, quantityCredits, searchModel])

  const handleReRun = async () => {
    const res = await clientApi.search.repeatSearchEntities(openModal.id);

    if (res.status === 200) {
      setOpenModal({});
      history.push(`/search-history/results?searchId=${res.data.searchId}&searchName=${res.data.name}&searchType=${res.data.type}`);
      // onNext({ clientId, searchId: res.data.searchId, searchName: res.data.name, searchType: res.data.type });
    }
  }

  const handleSearchLink = useCallback(() => {
    const data = prepareSearchData();
    if (!data) return;
    history.push(`/search-history/results?searchId=${openModal.id}&searchName=${data.name}&searchType=${data.type}`);
  }, [openModal, prepareSearchData])

  return (
    <Box
      height={'100%'}
      overflow={'auto'}
      style={{
        position: 'relative'
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        style={{
          position: 'sticky',
          top: 0,
          height: '96px',
          width: '100%'
        }}
      >
        <Box display={'flex'} mr={6}>
          <MenuWithContext>
            <MenuItem
              onClick={() => {
                setShowAboutDialog(true)
              }}
            >
              About Background Check
            </MenuItem>
          </MenuWithContext>
        </Box>
      </Box>
      <Box mt={0} mb={4}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item>
            <Typography
              variant="h1"
              align="center"
            >
              Run Background Check
            </Typography>
          </Grid>
          <Grid item container justify="center">
            <Box className={classes.search}>
              <SearchBlock
                currentPlan={currentPlan}
                checkExistingSearch={checkExistingSearch}
                getEntities={getEntities}
                openConfirmedModal={openConfirmedModal}
                setOpenConfirmedModal={setOpenConfirmedModal} />
              <SearchCreditsBlock currentPlan={currentPlan} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Modal
        open={!!openModal.text}
        onClose={() => setOpenModal({})}
        title={'Repeated search'}
        mainText={MODAL_TEXT}
        additionalText={<span><Link className={classes.stepLink} onClick={handleSearchLink}>{openModal.text}</Link>?</span>}
        actionsDirection="row"
        actions={
          [
            {
              type: 'main',
              label: 'RUN NEW SEARCH',
              action: () => {
                setOpenModal({});
                setOpenConfirmedModal(true);
              },
            },
            {
              type: 'secondary',
              label: 'REPEAT SEARCH',
              action: () => handleReRun(),
            },
          ]
        }
        propsClassses={classes.searchModalButton}
      />
      <AboutBGCheckDialog
        open={showAboutDialog}
        onClose={() => {
          setShowAboutDialog(false)
        }}
      />
    </Box>
  )
}

import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import InnerHeader from '@components/layout/inner-header';
import BackButton from '@views/common/back-button';
import _ from 'lodash';
import { adminApi } from '@app/api';
import { useSnackbar } from 'notistack';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { AddActive2Icon } from '@app/icons';
import { v4 as uuidv4 } from 'uuid';

import history from '@app/history';
import { useParams } from 'react-router-dom';
import parseYupErrors from '@app/yup';
import { Modal } from '@components/modals';
import { entityTypes } from '@utils/entityType';
import { validateCategories } from '../utils/validation';
import Details, { schema as detailsSchema } from '../components/details';
import Documents from '../components/documents';
import InputFields from '../components/input-fields';
import { getCountryName } from '@services/country-service';

const TABS = [
  {
    id: 0,
    label: 'Details',
  },
  {
    id: 1,
    label: 'Fields',
  },
  {
    id: 2,
    label: 'Documents'
  },
]

const JOB_TYPES = {
  globalCreate: 'globalCreate',
  globalEdit: 'globalEdit',
  globalClone: 'globalClone',
  localCreate: 'localCreate',
  localEdit: 'localEdit',
  localClone: 'localClone',
}

const getJobType = (scope, type) => {
  if (scope === 'global') {
    switch (type) {
      case 'create':
        return JOB_TYPES.globalCreate
      case 'edit':
        return JOB_TYPES.globalEdit
      case 'clone':
        return JOB_TYPES.globalClone
      default:
        break;
    }
  }
  if (scope === 'local') {
    switch (type) {
      case 'create':
        return JOB_TYPES.localCreate
      case 'edit':
        return JOB_TYPES.localEdit
      case 'clone':
        return JOB_TYPES.localClone
      default:
        break;
    }
  }
}

export default function EntityForm({ categories, fetchCategories, types, type }) {
  const { scope, entityVersionId } = useParams();
  const jobType = getJobType(scope, type);
  const isEdit = type === 'edit'
  const { enqueueSnackbar } = useSnackbar()
  const [tabID, setTabID] = useState(0);
  const [detailsData, setDetailsData] = useState({
    name: '',
    jurisdiction: [JOB_TYPES.globalCreate, JOB_TYPES.globalEdit].includes(jobType) ? 'UNDEFINED' : '',
    entityType: '',
  })
  const [documentRulesData, setDocumentRulesData] = useState([]);
  const [fieldRulesData, setFieldRulesData] = useState([]);
  const [detailsErrors, setDetailsErrors] = useState({});
  const [globalRules, setGlobalRules] = useState(null);
  const [isAddNew, setIsAddNew] = useState(false);
  const [isDetailsFilled, setIsDetailsFilled] = useState(isEdit);
  const [baseRulebook, setBaseRulebook] = useState(null);
  const [existingRulebook, setExistingRulebook] = useState(null);
  const [duplicateRule, setDuplicateRule] = useState(null);
  useEffect(() => {
    setDetailsErrors({});
  }, [detailsData])

  useEffect(() => {
    if (jobType === JOB_TYPES.localCreate) {
      adminApi.rulebooks.getClientRulebooks({
        sortBy: 'name',
        global: true,
        latest: true,
      }).then(res => {
        setGlobalRules(res.data.entries)
      })
    }
  }, [jobType])

  useEffect(() => {
    if (entityVersionId && categories.length && types.length && !existingRulebook) {
      adminApi.rulebooks.getClientRulebookById(entityVersionId).then(res => {
        const { data } = res;
        const newDetailsData = {
          name: data.name || '',
          entityType: data.clientType === 'PERSON' ? data.clientType : data.legalEntityType,
          jurisdiction: jobType === JOB_TYPES.globalClone ? '' : (data.jurisdiction || 'UNDEFINED'),
        }
        setDetailsData({
          ...detailsData,
          ...newDetailsData,
        })
        if (jobType === JOB_TYPES.globalClone) {
          const baseRulebook = data;
          baseRulebook.inputFields.forEach(inputField => {
            inputField.isGlobal = true;
            inputField.dragId = uuidv4();
          })

          setFieldRulesData(baseRulebook.inputFields
            .sort((a, b) => a.displayConfig.order - b.displayConfig.order)
          );
          setBaseRulebook(baseRulebook);
          return;
        }

        const documentRules = data.documentRules.map(rule => {
          const category = categories.find(e => e.id === rule.categoryId)
          const ruleTypes = rule.types.map(type => ({
            ...types.find(e => e.id === type.typeId),
            ...type,
            isChecked: true,
          }))

          category?.types.forEach(type => {
            if (!ruleTypes.some(e => e.id === type.id)) {
              ruleTypes.push(type)
            }
          })

          return {
            ...category,
            ...rule,
            types: ruleTypes,
            existingRule: true,
          }
        })


        setDocumentRulesData(data.basedOnRulebookVersionId
          ? documentRules.filter(e => e.definedByRulebookVersionId !== data.basedOnRulebookVersionId)
          : documentRules
        )

        setFieldRulesData(data.inputFields
          .sort((a, b) => a.displayConfig.order - b.displayConfig.order)
          .map(e => ({
            ...e,
            isGlobal: data.basedOnRulebookVersionId && e.definedByRulebookVersionId === data.basedOnRulebookVersionId,
            dragId: uuidv4(),
          }))
        )

        setExistingRulebook(data)

        if (data.basedOnRulebookVersionId) {
          adminApi.rulebooks.getClientRulebookById(data.basedOnRulebookVersionId).then(res2 => {
            setBaseRulebook(res2.data)
          })
        }
      })
    }
  }, [entityVersionId, categories, types]) // eslint-disable-line

  const validateDetails = async () => {
    setDetailsErrors({});
    try {
      await detailsSchema.validate(detailsData, { abortEarly: false })
      return true
    } catch (err) {
      setDetailsErrors(parseYupErrors(err))
      return false
    }
  }

  const checkForDuplicate = async () => {
    const res = await adminApi.rulebooks.getClientRulebooks({
      global: scope === 'global' && type !== 'clone',
      latest: true,
      jurisdiction: detailsData.jurisdiction,
      clientType: detailsData.entityType === 'PERSON' ? 'PERSON' : 'LEGAL_ENTITY',
      legalEntityType: detailsData.entityType === 'PERSON' ? null : detailsData.entityType,
      pageSize: 1,
      ownedBy: 'SYSTEM',
    })

    if (res.data.entries.length) {
      return res.data.entries[0]
    }

    return null
  }

  const onNextDetails = async () => {
    const isValid = await validateDetails();
    if (isValid) {
      if (type === 'create' || type === 'clone') {
        const duplicate = await checkForDuplicate();
        if (duplicate) {
          setDuplicateRule(duplicate);
          return;
        }
      }

      setTabID(1);
      setIsDetailsFilled(true);
    }
  }

  const onNext = () => {
    switch (tabID) {
      case 0:
        onNextDetails();
        break;
      
      case 1:
        setTabID(2);
        break;

      case 2:
        onSave();
        break;

      default:
        break;
    }
  }

  const onSave = async () => {
    let isValid = true
    isValid = await validateDetails()
    if (!isEdit && !isValid) return

    const documentErrors = validateCategories(documentRulesData, jobType === JOB_TYPES.globalCreate && jobType === JOB_TYPES.globalEdit)
    if (documentErrors.length) {
      enqueueSnackbar(documentErrors.join(' \n'), { variant: 'error' })
      return
    }

    // Field ordering logic
    const inputFields = [];
    let currentOrder = 0;
    fieldRulesData.forEach(inputField => {
      if (inputField.isGlobal) {
        currentOrder = inputField.displayConfig.order;
      } else {
        currentOrder += [JOB_TYPES.globalCreate, JOB_TYPES.globalEdit].includes(jobType) ? 1 : 0.01;
        inputField.displayConfig.order = currentOrder;
        inputFields.push(inputField);
      }
    })

    const preparedData = {
      basedOnRulebookVersionId: baseRulebook?.rulebookVersionId || selectedGlobalRule?.rulebookVersionId,

      name: detailsData.name,
      jurisdiction: detailsData.jurisdiction,
      clientType: detailsData.entityType === 'PERSON' ? 'PERSON' : 'LEGAL_ENTITY',
      legalEntityType: detailsData.entityType === 'PERSON' ? null : detailsData.entityType,

      documentRules: documentRulesData.map(doc => ({
        id: doc.existingRule ? doc.id : undefined,
        categoryId: doc.categoryId,
        categoryRuleType: doc.categoryRuleType,
        types: doc.types
          .filter(t => t.isChecked)
          .map(t => ({
            typeId: t.id,
            expected: t.expected,
          }))
      })),

      inputFields: inputFields.map(e => ({
        ...e,
        dragId: undefined,
        definition: {
          ...e.definition,
          size: e.definition.size ? parseInt(e.definition.size) : null,
        }
      })),
    }

    switch (type) {
      case 'create':
      case 'clone':
        await adminApi.rulebooks.createClientRulebook(preparedData)
        enqueueSnackbar(`Rulebook "${detailsData.name}" was successfully created!`, { variant: 'success' })    
        break;

      case 'edit':
        await adminApi.rulebooks.createNewClientRulebookVersion(existingRulebook.rulebookId, {
          documentRules: preparedData.documentRules,
          inputFields: preparedData.inputFields,
        })
        enqueueSnackbar(`Rulebook "${detailsData.name}" was successfully updated!`, { variant: 'success' })
        break;
    
      default:
        break;
    }

    history.push(`/entity-management/${scope}`)
  }

  // Trigger add function on children component
  const handleAddNew = () => {
    setIsAddNew(true)
  }

  useLayoutEffect(() => {
    if (isAddNew) {
      setIsAddNew(false)
    }
  }, [isAddNew])

  const onCancel = () => {
    history.push(`/entity-management/${scope}`)
  }

  const selectedGlobalRule = useMemo(() => {
    if (globalRules) {
      return globalRules.find(e =>
        e.clientType === detailsData.entityType ||
        e.legalEntityType === detailsData.entityType
      )
    }
  }, [detailsData.entityType, globalRules])

  useEffect(() => {
    if (selectedGlobalRule) {
      const globalFields = selectedGlobalRule.inputFields.map(e => ({
        ...e,
        isGlobal: true,
        dragId: uuidv4(),
      }))

      const combinedFields = [...globalFields, ...fieldRulesData.filter(e => !e.isGlobal)]
      combinedFields.sort((a, b) => a.displayConfig.order - b.displayConfig.order)

      setFieldRulesData(combinedFields);
    }
  }, [selectedGlobalRule]) // eslint-disable-line

  const duplicateText = useMemo(() => {
    const entityType = entityTypes?.find(e => e.name === detailsData.entityType)?.label
    if (jobType === JOB_TYPES.globalCreate) {
      return `You have already created a global ${entityType} entity type. Would you like to create a new version for this entity?`
    } else {
      return `You have already created a ${entityType} entity type in ${getCountryName(detailsData.jurisdiction)}. Would you like to create a new version for this entity?`
    }
  }, [jobType, detailsData])

  const onEditDuplicate = () => {
    history.push(`/entity-management/${duplicateRule.jurisdiction === 'UNDEFINED' ? 'global' : 'local'}/update/${duplicateRule.rulebookVersionId}`)
  }

  return (
    <Box mx={6} height="100%" display="flex" flexDirection="column" justifyContent="flex-start">
      <Modal
        open={!!duplicateRule}
        onClose={() => setDuplicateRule(null)}
        title="Entity Already Exists"
        mainText={duplicateText}
        actions={
          [
            {
              type: 'main',
              label: 'CANCEL',
              action: () => { setDuplicateRule(null) },
              style: { width: 180 },
            },
            {
              type: 'secondary',
              label: 'NEW VERSION',
              action: onEditDuplicate,
              style: { width: 180 },
            },
          ]
        }
        actionsDirection="row"
      />

      <Box>
        <InnerHeader
          title={`${type === 'clone' ? 'Clone' : isEdit ? 'Edit' : 'Add'} ${_.capitalize(scope)} Entity`}
          buttons={
            <BackButton wrapped />
          }
        />
        <InnerHeader
          onTabChange={(id) => setTabID(id)}
          ind={tabID}
          tabs={isDetailsFilled ? TABS : [TABS[0]]}
          buttons={(tabID > 0 &&
            <ButtonWithIcon
              startIcon={<AddActive2Icon />}
              onClick={handleAddNew}
            >
              {tabID === 1 && 'Add Field'}
              {tabID === 2 && 'Add Document Category'}
            </ButtonWithIcon>
          )}
        />
      </Box>

      <Box p={2} flexGrow={1} maxHeight="calc(100% - 98px - 98px - 76px)" style={{ overflowY: 'auto' }}>
        {tabID === 0 &&
          <Details
            scope={scope}
            type={type}
            data={detailsData}
            globalRules={globalRules}
            setData={setDetailsData}
            errors={detailsErrors}
            setErrors={setDetailsErrors}
            rulebookId={existingRulebook?.rulebookId}
            clearDocuments={() => setDocumentRulesData([])}
          />
        }
        {tabID === 1 &&
          <InputFields
            isAddNew={isAddNew}
            inputFields={fieldRulesData}
            setInputFields={setFieldRulesData}
            clientType={detailsData.entityType === 'PERSON' ? 'PERSON' : 'LEGAL_ENTITY'}
            type={type}
          />
        }
        {tabID === 2 &&
          <Documents
            documents={documentRulesData}
            setDocuments={setDocumentRulesData}
            isAddNew={isAddNew}
            selectedGlobalRule={baseRulebook || selectedGlobalRule}
            categories={categories}
            fetchCategories={fetchCategories}
            types={types}
            clientType={detailsData.entityType === 'PERSON' ? 'PERSON' : 'LEGAL_ENTITY'}
          />
        }
      </Box>

      <Box mt="auto" display="flex" justifyContent="flex-end" pb={4}>
        <Button
          variant="text"
          onClick={onCancel}
          style={{ width: 200 }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onNext}
          style={{ width: 200, marginLeft: 8 }}
        >
          {tabID === 2 ? 'Save' : 'Next'}
        </Button>
      </Box>
    </Box>
  )
}

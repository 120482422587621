import axios from '../axios';

import { prepareData, prepareIndexParams, getGuestToken } from './api-utils';
import _ from 'lodash';

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const search = {
  searchEntities: (data, isGuest) => {
    if (isGuest) {
      const guestToken = getGuestToken();
      return axios.post('searches', data, {
        headers: { 'guest-user-token': guestToken },
      });
    }
    return axios.post('searches', data);
  },
  show: (searchId, isGuest) => {
    if (isGuest) {
      const guestToken = getGuestToken();
      return axios.get(`searches/${searchId}`, {
        headers: { 'guest-user-token': guestToken },
      });
    }
    return axios.get(`searches/${searchId}`);
  },
  getProfiles: (
    searchId,
    pageNumber = 0,
    pageSize = 36,
    isWhitelisted = false,
    isGuest
  ) => {
    const params = isWhitelisted
      ? { pageNumber, pageSize }
      : { pageNumber, pageSize, isWhitelisted };

    if (isGuest) {
      const guestToken = getGuestToken();
      return axios.get(`searches/${searchId}/profiles`, {
        params,
        headers: { 'guest-user-token': guestToken },
      });
    }
    return axios.get(`searches/${searchId}/profiles`, { params });
  },
  getClients: (id) => {
    return axios.get(`clients/${id}`);
  },
  getAllSearchesForUser: (requestData) => {
    const {
      pageNumber,
      pageSize,
      name,
      listTypes,
      start,
      end,
      sortData,
      acknowledged,
      monitory,
      searchProvider,
    } = requestData;
    return axios.post('searches/search', {
      pageNumber,
      pageSize,
      name,
      listTypes,
      dateFrom: start,
      dateTo: end,
      ...sortData,
      acknowledged,
      monitory,
      searchProvider,
    });
  },
  getSearch: (id) => {
    return axios.get(`searches/${id}`);
  },
  deleteSearch: (id) => {
    return axios.delete(`searches/${id}`);
  },
  repeatSearchEntities: (id) => {
    return axios.post(`searches/${id}/repeat-search`);
  },
  addSearchToMonitoring: (id, data) => {
    return axios.post(`searches/${id}/ongoing-monitoring`, data);
  },
  updateSearchMonitoring: (id, data) => {
    return axios.put(`searches/${id}/ongoing-monitoring`, data);
  },
  getOngoingMonitoring: (id) => {
    return axios.get(`searches/${id}/ongoing-monitoring`);
  },
  checkExistingSearch: (data) => {
    return axios.post('searches/existing-search', data);
  },
  removeFromMonitoring: (id) => {
    return axios.delete(`searches/${id}/ongoing-monitoring`);
  },
  acknowledgeSearch: (id) => {
    return axios.put(`searches/${id}/acknowledge`);
  },
  getRemainingSearchAttempts: () => {
    const guestToken = getGuestToken();
    return axios.get('searches/remaining-attempts', {
      headers: { 'guest-user-token': guestToken },
    });
  },
  updateSearchReview: (id, data) => {
    return axios.post(`searches/${id}/review`, data);
  },
};

const searchVersion = {
  getSearchVersions: (searchVersionId, pageNumber = 0, pageSize = 12, acknowledged) => {
    return axios.get(`search-versions/${searchVersionId}`, {
      params: {
        pageNumber,
        pageSize,
        acknowledged
      },
    });
  },
  getSearchByVersionId: (searchVersionId) => {
    return axios.get(`search-versions/${searchVersionId}/search`);
  },
  getProfilesByVersionId: (
    id,
    pageNumber = 0,
    pageSize = 36,
    isWhitelisted
  ) => {
    const params = isWhitelisted
      ? { pageNumber, pageSize }
      : { pageNumber, pageSize, isWhitelisted };
    return axios.get(`search-versions/${id}/profiles`, { params });
  },
  getProfileByVersionId: (searchVersionId, profileId) => {
    return axios.get(
      `search-versions/${searchVersionId}/profiles/${profileId}`
    );
  },
  getSourcesByVersionIdAndProfileId: (searchVersionId, profileId) => {
    return axios.get(
      `search-versions/${searchVersionId}/profiles/${profileId}/sources`
    );
  },
  acknowledgeSearchVersion: (searchVersionId) => {
    return axios.put(
      `search-versions/${searchVersionId}/acknowledge`
    )
  }
};

const profile = {
  getSources: (profileId, isGuest) => {
    if (isGuest) {
      const guestToken = getGuestToken();
      return axios.get(`profiles/${profileId}/sources`, {
        headers: { 'guest-user-token': guestToken },
      });
    }
    return axios.get(`profiles/${profileId}/sources`);
  },
  updateProfileReview: ({ profileId, payload }) => {
    return axios.post(`profiles/${profileId}`, { ...payload });
  },
  bulkWhitelistOfProfiles: (payload) => {
    return axios.post('profiles/bulk-whitelist', { ...payload });
  },
  show: (profileId, isGuest) => {
    if (isGuest) {
      const guestToken = getGuestToken();
      return axios.get(`profiles/${profileId}`, {
        headers: { 'guest-user-token': guestToken },
      });
    }
    return axios.get(`profiles/${profileId}`);
  },
  addNote: (profileId, payload) => {
    return axios.post(`profiles/${profileId}/notes`, { ...payload });
  },
  updateNote: (profileId, noteId, note) => {
    return axios.put(`profiles/${profileId}/notes/${noteId}`, { ...note });
  },
  deleteNote: (profileId, noteId) => {
    return axios.delete(`profiles/${profileId}/notes/${noteId}`);
  },
  acknowledgeProfile: (profileId) => {
    return axios.put(`profiles/${profileId}/acknowledge`);
  },
};

const source = {
  updateSourceReview: ({ sourceId, payload }) => {
    return axios.post(`sources/${sourceId}`, { ...payload });
  },
  addNote: (sourceId, payload) => {
    return axios.post(`sources/${sourceId}/notes`, { ...payload });
  },
  updateNote: (sourceId, noteId, note) => {
    return axios.put(`sources/${sourceId}/notes/${noteId}`, { ...note });
  },
  deleteNote: (sourceId, noteId) => {
    return axios.delete(`sources/${sourceId}/notes/${noteId}`);
  },
};

const report = {
  getSearchReport: ({ searchId, profileId }) => {
    const URLToPDF = profileId
      ? `reports/${searchId}?profileId=${profileId}&zoneId=${timeZone}`
      : `reports/${searchId}?zoneId=${timeZone}`;
    return axios.get(URLToPDF);
  },
  getSearchReportForVersion: ({ searchVersionId, profileId }) => {
    const URLToPDF = profileId
      ? `reports/search-version/${searchVersionId}?profileId=${profileId}&zoneId=${timeZone}`
      : `reports/search-version/${searchVersionId}?zoneId=${timeZone}`;
    return axios.get(URLToPDF);
  },
  getRiskRatingReport: ({ riskRatingId }) => {
    return axios.get(`reports/risk-ratings/${riskRatingId}?zoneId=${timeZone}`);
  },
};

const company = {
  index: (params = {}) => {
    params['clientType'] = 'LEGAL_ENTITY';
    return client.index(params);
  },
  show: (id) => {
    return axios.get(`companies/${id}`);
  },
  update: (id, data) => {
    return axios.put(`legalEntities/${id}`, prepareData(data));
  },
  store: (data) => {
    return axios.post('legalEntities', prepareData(data));
  },
  destroy: (id) => {
    return axios.delete(`companies/${id}`);
  },
  search: (params = {}) => {
    params['clientType'] = 'LEGAL_ENTITY';
    return client.search(params);
  },
};

const person = {
  index: (params = {}) => {
    params['clientType'] = 'PERSON';
    return client.index(params);
  },
  show: (id) => {
    return axios.get(`persons/${id}`);
  },
  update: (id, data) => {
    return axios.put(`persons/${id}`, prepareData(data));
  },
  store: (data) => {
    return axios.post('persons', prepareData(data));
  },
  destroy: (id) => {
    return axios.delete(`persons/${id}`);
  },
  search: (params = {}) => {
    params['clientType'] = 'PERSON';
    return client.search(params);
  },
};

const client = {
  index: (params = {}) => {
    return axios.post('clients/search', prepareIndexParams(params));
  },
  show: (id) => {
    return axios.get(`clients/${id}`);
  },
  destroy: (id) => {
    return axios.delete(`clients/${id}`);
  },
  search: (params = {}) => {
    return axios.post('clients/search', params);
  },
  searchByKeyword: (params) => {
    params = {
      pageNumber: 0,
      pageSize: 10000,
      ...params,
    };
    return axios.post('clients/search-by-keyword', params);
  },
  linkToProfile: (id, profile_id) => {
    return axios.put(`clients/${id}/link-to-profile/${profile_id}`);
  },
  unlinkFromProfile: (id, profile_id) => {
    return axios.put(`clients/${id}/unlink-from-profile/${profile_id}`);
  },
  linkToSearch: (id, search_id) => {
    return axios.put(`clients/${id}/link-to-search/${search_id}`);
  },
  unlinkFromSearch: (id, search_id) => {
    return axios.put(`clients/${id}/unlink-from-search/${search_id}`);
  },
  updateNextReview: (id, nextReview) => {
    return axios.patch(`clients/${id}/next-review-date`, {
      nextReview: nextReview,
    });
  },
  bulkFetch: (ids) => {
    return axios.get(
      `/clients/search-by-ids?${ids.map((e) => `ids=${e}`).join('&')}`
    );
  },
  duplicatesCheck: (names, cancelToken) => {
    return axios.post(
      '/clients/duplicates-check',
      { names },
      { headers: { hideLoader: true }, cancelToken }
    );
  },
  getClients: () => {
    return axios.get('clients/monitory');
  },
  getClientsByNextReview: (params) => {
    return axios.get('clients/next-review', { params });
  },
  updateToLatestRulebookVersion: (id) => {
    return axios.patch(`clients/${id}/update-to-latest-rulebook-version`)
  }
};

const folder = {
  index: (params = {}) => {
    params = {
      sortBy: 'name',
      ...params
    }
    return axios.post('folders/search', prepareIndexParams(params));
  },
  show: (id) => {
    return axios.get(`folders/${id}`);
  },
  update: (id, data) => {
    return axios.put(`folders/${id}`, prepareData(data));
  },
  store: (data) => {
    return axios.post('folders', prepareData(data));
  },
  destroy: (id) => {
    return axios.delete(`folders/${id}`);
  },
  addClient: (folderId, clientId) => {
    return axios.put(`folders/${folderId}/addClient`, {
      clientId,
    });
  },
  addClients: (folderId, clientIds, hideLoader = false) => {
    return axios.put(
      `folders/${folderId}/bulk-add-clients`,
      {
        clientIds,
      },
      { headers: { hideLoader } }
    );
  },
};

const user = {
  getCurrentUser: () => {
    return axios.get('users/logged-user');
  },
  updateUser: (data) => {
    return axios.put('users', prepareData(data));
  },
};

const ongoingMonitoring = {
  getBySearchId: (searchId) => {
    return axios.get(`searches/${searchId}/ongoing-monitoring`);
  },
  createOngoingMonitoring: (searchId, timeSliceInDays = 1) => {
    return axios.post(`searches/${searchId}/ongoing-monitoring`, {
      timeSliceInDays,
    });
  },
  deleteOngoingMonitoring: (searchId) => {
    return axios.delete(`searches/${searchId}/ongoing-monitoring`);
  },
  updateOngoingMonitoring: (searchId, data) => {
    return axios.put(`searches/${searchId}/ongoing-monitoring`, data);
  },
  clientsOnOngoingMonitoring: (clientIds) => {
    return axios.post('searches/bulk-clients-on-ongoing-monitoring', clientIds);
  },
  bulkCreate: (data) => {
    return axios.post('searches/bulk-ongoing-monitoring', data, {
      headers: { hideLoader: true },
    });
  },
};

const csv = {
  export: (data) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return axios.post(`reports/csv?zoneId=${timeZone}`, data);
  },
  import: (data) => {
    return axios.post('clients/bulk-create', data, {
      headers: { hideLoader: true },
    });
  },
};

const riskRatingTemplate = {
  index: () => {
    return axios.get('risk-rating-templates');
  },
  show: (id) => {
    return axios.get(`risk-rating-templates/${id}`);
  },
  update: (id, data) => {
    return axios.put(`risk-rating-templates/${id}`, _.omitBy(data, _.isNil));
  },
  store: (data) => {
    return axios.post('risk-rating-templates', _.omitBy(data, _.isNil));
  },
  remove: (id) => {
    return axios.delete(`risk-rating-templates/${id}`);
  },
};

const riskRating = {
  index: (clientId, params) => {
    params = {
      pageNumber: 0,
      pageSize: 10000,
      sortBy: 'id',
      sortDirection: 'DESC',
      ...params,
      clientId
    }
    return axios.post('risk-ratings/search', params);
  },
  show: (id) => {
    return axios.get(`risk-ratings/${id}`);
  },
  update: (id, data) => {
    return axios.put(`risk-ratings/${id}`, _.omitBy(data, _.isNil));
  },
  store: (data) => {
    return axios.post('risk-ratings', _.omitBy(data, _.isNil));
  },
  reset: (clientId) => {
    return axios.put(`risk-ratings/reset/${clientId}`);
  },
  remove: (riskRatingId) => {
    return axios.delete(`risk-ratings/${riskRatingId}`);
  },
};

const subscriptionPlans = {
  getAll: () => {
    return axios.get('subscription-plans');
  },
  getSubscriptionForUser: () => {
    return axios.get('subscriptions');
  },
  getById: (subscriptionPlanId) => {
    return axios.get(`subscription-plans/${subscriptionPlanId}`);
  },
  changeRequestedRenewalSubscriptionPlan: (data) => {
    return axios.put(
      'subscriptions/change-requested-renewal-subscription-plan',
      data
    );
  },
  changeRequestedUpgradeSubscriptionPlan: (data) => {
    return axios.put(
      'subscriptions/change-requested-upgrade-subscription-plan',
      data
    );
  },
};

const invoices = {
  getInvoicesForUser: (params) => {
    return axios.get('invoices', { params });
  },
};

const clientDocumentCategory = {
  index: (params) => {
    params = {
      pageNumber: 0,
      pageSize: 10000,
      sortBy: 'name',
      sortDirection: 'ASC',
      ...params,
    };
    return axios.post('document-categories/search', params);
  },
  show: (id) => {
    return axios.get(`document-categories/${id}`);
  },
  update: (id, data) => {
    return axios.put(`document-categories/${id}`, prepareData(data));
  },
  store: (data) => {
    return axios.post('document-categories', prepareData(data));
  },
  remove: (id) => {
    return axios.delete(`document-categories/${id}`);
  },
  linkToType: (id, typeIds) => {
    return axios.put(`document-categories/${id}/link-to-type/`, typeIds);
  },
  unlinkFromType: (id, typeIds) => {
    return axios.put(`document-categories/${id}/unlink-from-type/`, typeIds);
  },
};

const clientDocumentType = {
  index: (params) => {
    params = {
      pageNumber: 0,
      pageSize: 10000,
      sortBy: 'name',
      sortDirection: 'ASC',
      ...params,
    };
    return axios.post('document-types/search', params);
  },
  show: (id) => {
    return axios.get(`document-types/${id}`);
  },
  update: (id, data) => {
    return axios.put(`document-types/${id}`, prepareData(data));
  },
  updateSystem: (id, data) => {
    return axios.put(
      `document-types/update-system-type/${id}`,
      prepareData(data)
    );
  },
  store: (data) => {
    return axios.post('document-types', prepareData(data));
  },
  remove: (id) => {
    return axios.delete(`document-types/${id}`);
  },
};

const clientDocument = {
  index: (clientId) => {
    return axios.get(`documents?clientId=${clientId}`);
  },
  search: (params) => {
    params = {
      pageNumber: 0,
      pageSize: 10000,
      ...params,
    };
    return axios.post('documents/search', params);
  },
  searchByKeyword: (params) => {
    params = {
      pageNumber: 0,
      pageSize: 10000,
      ...params,
    };
    return axios.post('documents/search-by-keyword', params);
  },
  setExpiryDate: (params) => {
    return axios.post('documents/expiry-date', params);
  },
  show: (id) => {
    return axios.get(`documents/${id}`);
  },
  showVersion: (id) => {
    return axios.get(`document-versions/${id}/document`)
  },
  update: (id, data, hideLoader = false) => {
    return axios.put(`documents/${id}`, data, { headers: { hideLoader } });
  },
  store: (data) => {
    return axios.post('documents', data, { headers: { hideLoader: true } });
  },
  remove: (id) => {
    return axios.delete(`documents/${id}`);
  },
  removeVersion: (id) => {
    return axios.delete(`document-versions/${id}`);
  },
  getVersions: (id) => {
    const params = {
      pageNumber: 0,
      pageSize: 10000,
    }
    return axios.get(`document-versions/${id}`, { params });
  },
  deleteVersion: (id) => {
    return axios.delete(`document-versions/${id}`);
  }
};

const clientRulebook = {
  resolve: ({ jurisdiction, type, legalEntityType }) => {
    /**
     * jurisdiction {AC, AD, AE, ...}
     * legalEntityType {CORPORATION, FOUNDATION, LIMITED_LIABILITY_COMPANY, PARTNERSHIP, TRUST}
     * type {LEGAL_ENTITY, PERSON}
     */
    return axios.get('client-rulebooks/resolve', {
      params: {
        jurisdiction,
        type,
        legalEntityType,
      }
    })
  },
  resolveTypeSpecific: ({ legalEntityType }) => {
    return axios.get('legalEntities/type-specific-input-fields', {
      params: {
        legalEntityType,
      }
    })
  },
  index: ({sort: sortBy = 'name', ...params}) => {
    params = {
      pageNumber: 0,
      pageSize: 25,
      sortBy,
      ...params,
    };
    return axios.post('client-rulebooks/search', params);
  },
  show: (id) => {
    return axios.get(`client-rulebooks/${id}`);
  },
  updateVersion: (id, data) => {
    const reqData = {
      name: data?.name || ''
    }
    return axios.put(`client-rulebooks/versions/${id}`, reqData);
  },
  createVersion: (id, data) => {
    const reqData = prepareData(data)
    if (!reqData.documentRules) {
      reqData.documentRules = []
    }
    if (!reqData.inputFields) {
      reqData.inputFields = []
    }
    return axios.post(`client-rulebooks/versions/${id}`, reqData);
  },
  store: (data) => {
    const reqData = prepareData(data)
    if (!reqData.documentRules) {
      reqData.documentRules = []
    }
    if (!reqData.inputFields) {
      reqData.inputFields = []
    }
    return axios.post('client-rulebooks', reqData);
  },
  remove: (id) => {
    return axios.delete(`client-rulebooks/${id}`);
  },
  removeVersion: (id) => {
    return axios.delete(`client-rulebooks/versions/${id}`);
  },
  diff: (rulebookVersionFromId, rulebookVersionToId) => {
    return axios.get('client-rulebooks/diff', {
      params: {
        rulebookVersionFromId,
        rulebookVersionToId,
      }
    })
  }
}

const rulebook = {
  index: ({sort: sortBy = 'name', ...params}) => {
    params = {
      pageNumber: 0,
      pageSize: 25,
      sortBy,
      ...params,
    };
    return axios.post('document-rulebooks/search', params);
  },
  show: (id) => {
    return axios.get(`document-rulebooks/${id}`);
  },
  update: (id, data) => {
    return axios.put(`document-rulebooks/${id}`, prepareData(data));
  },
  store: (data) => {
    return axios.post('document-rulebooks', prepareData(data));
  },
  remove: (id) => {
    return axios.delete(`document-rulebooks/${id}`);
  },
};

const rulebookUsage = {
  index: (params) => {
    params = {
      pageNumber: 0,
      pageSize: 25,
      ...params,
    };
    return axios.get('document-rulebook-usages', {
      params,
    });
  },
  create: (documentRulebookId) => {
    return axios.post('document-rulebook-usages', { documentRulebookId });
  },
  check: (id) => {
    return axios.post(`document-rulebook-usages/${id}/perform-check`);
  },
};

const eidv = {
  index: (params) => {
    params = {
      pageNumber: 0,
      pageSize: 1000,
      sortBy: 'initiatedDate',
      sortDirection: 'DESC',
      ...params,
    };
    return axios.post('eidv-cases/search', params);
  },
  show: (id) => {
    return axios.get(`eidv-cases/${id}`);
  },
  createNew: (data) => {
    return axios.post('eidv-cases/create-new-for-existing-client', data);
  },
  createRepeated: (data) => {
    return axios.post('eidv-cases/create-repeated-for-existing-client', data);
  },
  repeatCase: (data, id) => {
    return axios.post(
      `eidv-cases/${id}/repeat-steps-for-not-finished-case`,
      data
    );
  },
  invite: (data) => {
    return axios.post('eidv-cases/create-for-non-existing-client', data);
  },
  cancel: (data, id) => {
    return axios.put(`eidv-cases/${id}/cancel-eidv-request`, data);
  },
  updateAuthenticityVerificationUserData: (data, id) => {
    return axios.put(
      `eidv-cases/${id}/update-authenticity-verification-user-data`,
      data
    );
  },
  updateAddressInformationUserData: (data, id) => {
    return axios.put(
      `eidv-cases/${id}/update-address-information-user-data`,
      data
    );
  },
  updateFacialMatchUserData: (data, id) => {
    return axios.put(`eidv-cases/${id}/update-facial-match-user-data`, data);
  },
};

const notifications = {
  index: () => {
    return axios.get('notifications');
  },
  update: (data) => {
    return axios.post('notifications', data);
  }
}

export {
  search,
  user,
  company,
  person,
  folder,
  report,
  profile,
  source,
  client,
  searchVersion,
  ongoingMonitoring,
  csv,
  riskRatingTemplate,
  riskRating,
  subscriptionPlans,
  invoices,
  clientDocumentCategory,
  clientDocumentType,
  clientDocument,
  rulebook,
  rulebookUsage,
  eidv,
  notifications,
  clientRulebook,
};

import MyProfile from '@views/protected/client/my-profile';
import MyAccountEdit from '@views/protected/client/my-profile/my-account-edit';
import BillingDetailsEdit from '@components/complex/billing-details/billing-details-edit';
import PayPayments from '@components/complex/payments/payments';

const routes = [
  {
    path: '/my-profile/my-account/edit',
    component: MyAccountEdit,
    guest: false,
  },
  {
    path: '/my-profile/billing-details/add',
    component: BillingDetailsEdit,
    guest: false,
  },
  {
    path: '/my-profile/billing-details/edit/:billingId',
    component: BillingDetailsEdit,
    guest: false,
  },
  {
    path: '/my-profile/payments/pay',
    component: PayPayments,
    guest: false,
  },
  {
    path: '/my-profile/:tab?',
    component: MyProfile,
    guest: false,
  },
    
]

export default routes;
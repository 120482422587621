import React from 'react';

import {
  makeStyles, IconButton
} from '@material-ui/core';
import { ImageZoomIcon } from '@app/icons';
import CustomTooltip from '@components/controls/tooltip';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 0,
  },
  iconButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    padding: 4,
  }
}));


export default function ImageZoomTool({ onZoom }) {
  const classes = useStyles();

  return (
    <CustomTooltip title="Zoom In" placement="left" width={120}>
      <IconButton
        className={classes.iconButton}
        onClick={onZoom}
      >
        <ImageZoomIcon width={24} height={24} />
      </IconButton>
    </CustomTooltip>
  )
}
import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { Box, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import history from '@app/history';
import PageLayout from '@components/layout/page-layout';
import InnerHeader from '@components/layout/inner-header';

import Documents from './to-do-list/components/documents';
import HeaderButtonsBlock from './header-buttons-block';
import Client from './to-do-list/components/client';
import EidvPage from './to-do-list/components/eidv';
import ToDoList from './to-do-list';
import { TABS } from './constants';
import Calendar from './calendar';

export const useStyles = makeStyles((theme) => ({
  propsTabRoot: {
    maxWidth: '300px',
  },
}));

export default function CalendarSchedule(props) {
  const { tab, action } = useParams();
  const classes = useStyles();

  const [tabID, setTabID] = useState(tab || 'schedule');

  useEffect(() => {
    setTabID(tab);
  }, [tab]);

  const onTabChange = (index) => {
    const tabID = TABS[index].id;
    setTabID(tabID);
    history.push(`/calendar-schedule/${tabID}`);
  };
  return (
    <PageLayout>
      <Box mx={6} height={'100%'}>
        <InnerHeader
          onTabChange={onTabChange}
          ind={TABS.findIndex((tab) => tab.id === tabID)}
          tabs={TABS}
          propsTabRoot={classes.propsTabRoot}
          buttons={<HeaderButtonsBlock location={action ? action : 'all'} />}
        />
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxHeight="calc(100% - 98px)"
          px={2}
          mx={-2}
          style={{ overflowY: 'auto' }}
        >
          <Switch>
            <Route exact path="/calendar-schedule/schedule">
              <ToDoList />
            </Route>
            <Route exact path="/calendar-schedule/schedule/client-reviews">
              <Client action={action} />
            </Route>
            <Route exact path="/calendar-schedule/schedule/eidv-reviews">
              <EidvPage action={action} />
            </Route>
            <Route exact path="/calendar-schedule/schedule/document-reviews">
              <Documents action={action} />
            </Route>
            {/* <Route exact path="/calendar-schedule/schedule/monitoring-reviews">
              <RenderFullMiddleware type="monitoring" />
            </Route> */}

            <Route exact path="/calendar-schedule/calendar">
              <Calendar />
            </Route>

            <Redirect
              to={
                !!action
                  ? `/calendar-schedule/${tabID}/${action}`
                  : `/calendar-schedule/${tabID}`
              }
            />
          </Switch>
        </Box>
      </Box>
    </PageLayout>
  );
}

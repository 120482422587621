const config = {
  MuiTreeView: {
    root: {
      display:'flex',
      flexGrow: 1,
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  MuiTreeItem: {
    root: {
      display:'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      marginTop: '2px',
      width: '100%',
      '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
        background: 'none',
        color: '#232323'
      },
      '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover': {
        background: 'none',
      },
      '&.Mui-selected > .MuiTreeItem-content': {
        background: '#EDEDED'
      },
      '&.Mui-selected > .MuiTreeItem-content:hover': {
        opacity: 1
      },
      '&.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label': {
        background: 'none',
      },
      '&:focus > .MuiTreeItem-content .MuiTreeItem-label': {
        background: 'none',
      },
    },
    content: {
      borderRadius: '12px',
      height: '30px',
      width: 'auto',
      padding: '10px',
      '&:hover':{
        background: 'rgb(248, 248, 248, 0.5)', // #F8F8F8 0.5
        mixBlendMode: 'normal'
      }
    },
    group: {
      display: 'flex',
      flexDirection: 'column'
    },
    label: {
      display: 'flex',
      justifyContent: 'space-between',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '18px',
      textAlign: 'center',
      color: '#656565',
      mixBlendMode: 'normal',
      backgroundColor: 'inherited !important',
      '&:hover': {
        backgroundColor: 'none'
      },
      '&.Mui-selected': {

      }
    },
    iconContainer: {
      width: 'auto',
      flexDirection: 'column'
    }
  }
}

export default config
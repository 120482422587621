import React, { useState } from 'react';
import * as yup from 'yup';
import {
  Box, Button, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel,
  Link, MenuItem, OutlinedInput, Switch, Typography
} from '@material-ui/core';
import history from '@app/history';
import parseYupErrors from '@app/yup';
import constants from '@app/constants';
import { countryCodeList } from '@services/country-service';
import PageLayout from '@components/layout/page-layout';
import InnerHeader from '@components/layout/inner-header';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { ChevronLeftIcon, AddNoteShieldIcon } from '@app/icons';
import InnerFooter from '@components/layout/inner-footer';
import ReactInputMask from 'react-input-mask';
import SelectClearable from '@components/inputs/select-clearable';
import useErrorState from '@utils/errorState';
import { useParams } from 'react-router-dom';

export default function BillingDetailsEdit(props) {
  const { billingId } = useParams();
  const [errors, setErrors] = useErrorState({});
  const [makePrimary, setMakePrimary] = useState(false);
  const [model, setModel] = useState({
    // Billing info
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    // Card info
    cardNumber: '',
    cardName: '',
    expDate: '',
    securityCode: '',
  });

  const schema = yup.object().shape({
    address: yup.string().max(100).required(),
    city: yup.string().max(100).required(),
    state: yup.string().max(100).required(),
    zip: yup.string().max(15).required(),
    country: yup.string().required(),
    cardNumber: yup.string().label('Card Number').required(),
    cardName: yup.string().label('Name').required(),
    expDate: yup.string().label('Expiration Date')
      .test('valid_date', 'Expiration date is invalid (MM/YY)', function () {
        const [month, year] = this.parent.expDate.split(' / ');
        if (!month || !year) return false;
        if (parseInt(month, 10) > 12) return false;
        return true;
      })
      .test('valid_year', 'Card is expired', function () {
        const [month, year] = this.parent.expDate.split(' / ');
                
        // return true here so that valid_date test is used instead
        if (!month || !year) return true;
        if (parseInt(month, 10) > 12) return true;

        const thisYear = new Date().getFullYear();
        const thisMonth = new Date().getMonth();
        if (2000 + parseInt(year, 10) > thisYear) return true;
        if (2000 + parseInt(year, 10) === thisYear && parseInt(month, 10) > thisMonth) return true;
        return false;
      })
      .required(),
    securityCode: yup.string().label('Security Code').min(3).required(),
  });

  const handleChange = (prop) => (event) => {
    const newModel = { ...model, [prop]: event.target.value };
    setModel(newModel);

    if (errors[prop]) {
      schema.validateAt(prop, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [prop]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [prop]: err.message });
        });
    }
  };

  const onSave = () => {
    setErrors({});
    schema
      .validate(model, { abortEarly: false })
      .then(function (valid) {
        // userAPI.updateUser({
        //     ...model,
        //     email: model.email.toLowerCase().trim()
        // })
        //     .then(data => {
        //         enqueueSnackbar('My account details were successfully saved!', { variant: 'success' });
        //         history.push('/my-profile/my-account');
        //     })
        //     .catch(err => {
        //         const errors = err.response.data.errorMessages || {};
        //         setErrors(errors, true);
        //     })
      })
      .catch(function (err) {
        setErrors(parseYupErrors(err), true);
      });
  }

  return (
    <PageLayout>
      <Box px={6}>
        <InnerHeader
          title={billingId ? 'Edit Billing Method' : 'Add Billing Method'}
          buttons={
            <React.Fragment>
              <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={() => { history.goBack() }}>
                                Back
              </ButtonWithIcon>
            </React.Fragment>
          }
        />
      </Box>

      <Box py={4} px={14} maxHeight="calc(100vh - 192px)" overflow="auto">
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container justify="center">
            <Grid item xs={6} container justify="flex-end">
              <Box pr={2}>
                <Box mb={2}>
                  <Typography variant="h5">
                                        Billing Details
                  </Typography>
                </Box>
                <Box width={425}>
                  <FormControl
                    fullWidth
                    id="address"
                    variant="outlined"
                    error={!!errors['address']}
                  >
                    <InputLabel>Address</InputLabel>
                    <OutlinedInput
                      value={model['address']}
                      onChange={handleChange('address')}
                    />

                    {errors['address'] &&
                                            <FormHelperText>{errors['address']}</FormHelperText>
                    }
                  </FormControl>
                </Box>
                <Box width={425}>
                  <FormControl
                    fullWidth
                    id="city"
                    variant="outlined"
                    error={!!errors['city']}
                  >
                    <InputLabel>City</InputLabel>
                    <OutlinedInput
                      value={model['city']}
                      onChange={handleChange('city')}
                    />

                    {errors['city'] &&
                                            <FormHelperText>{errors['city']}</FormHelperText>
                    }
                  </FormControl>
                </Box>
                <Box width={425} display="flex">
                  <Box width={280} mr={1}>
                    <FormControl
                      fullWidth
                      id="state"
                      variant="outlined"
                      error={!!errors['state']}
                    >
                      <InputLabel>State</InputLabel>
                      <OutlinedInput
                        value={model['state']}
                        onChange={handleChange('state')}
                      />

                      {errors['state'] &&
                                                <FormHelperText>{errors['state']}</FormHelperText>
                      }
                    </FormControl>
                  </Box>
                  <Box width={135}>
                    <FormControl
                      fullWidth
                      id="zip"
                      variant="outlined"
                      error={!!errors['zip']}
                    >
                      <InputLabel>Zip/Postcode</InputLabel>
                      <OutlinedInput
                        value={model['zip']}
                        onChange={handleChange('zip')}
                      />

                      {errors['zip'] &&
                                                <FormHelperText>{errors['zip']}</FormHelperText>
                      }
                    </FormControl>
                  </Box>
                </Box>
                <Box width={425}>
                  <FormControl
                    fullWidth
                    id="country"
                    variant="outlined"
                    error={!!errors['country']}
                  >
                    <InputLabel>Country</InputLabel>
                    <SelectClearable
                      value={model['country']}
                      onChange={handleChange('country')}
                    >
                      {Object.keys(countryCodeList).map(optionValue => (
                        <MenuItem
                          key={optionValue}
                          value={optionValue}
                        >
                          {countryCodeList[optionValue]}
                        </MenuItem>
                      ))}
                    </SelectClearable>

                    {errors['country'] &&
                                            <FormHelperText>{errors['country']}</FormHelperText>
                    }
                  </FormControl>
                </Box>
                <Box mt={1}>
                  <FormControlLabel
                    control={<Switch checked={makePrimary} onClick={() => setMakePrimary(!makePrimary)} />}
                    label="Make Primary"
                  />
                </Box>
              </Box>
            </Grid>


            <Grid item xs={6}>
              <Box pl={2}>
                <Box mb={2}>
                  <Typography variant="h5">
                                        Card Info
                  </Typography>
                </Box>
                <Box width={425}>
                  <FormControl
                    fullWidth
                    id="cardNumber"
                    variant="outlined"
                    error={!!errors['cardNumber']}
                  >
                    <InputLabel>Card Number</InputLabel>
                    <ReactInputMask
                      mask={constants.cardMask}
                      value={model['cardNumber']}
                      onChange={handleChange('cardNumber')}
                      maskChar={null}
                    >
                      {(inputProps) => <OutlinedInput {...inputProps} />}
                    </ReactInputMask>
                    {errors['cardNumber'] &&
                                            <FormHelperText>{errors['cardNumber']}</FormHelperText>
                    }
                  </FormControl>
                </Box>
                <Box width={425}>
                  <FormControl
                    fullWidth
                    id="cardName"
                    variant="outlined"
                    error={!!errors['cardName']}
                  >
                    <InputLabel>Name on the card</InputLabel>
                    <OutlinedInput
                      value={model['cardName']}
                      onChange={handleChange('cardName')}
                    />

                    {errors['cardName'] &&
                                            <FormHelperText>{errors['cardName']}</FormHelperText>
                    }
                  </FormControl>
                </Box>
                <Box display="flex">
                  <Box width={135} mr={1}>
                    <FormControl
                      fullWidth
                      id="expDate"
                      variant="outlined"
                      error={!!errors['expDate']}
                    >
                      <InputLabel>Expiration Date</InputLabel>
                      <ReactInputMask
                        mask={constants.expDateMask}
                        value={model['expDate']}
                        onChange={handleChange('expDate')}
                        maskChar={null}
                      >
                        {(inputProps) => <OutlinedInput {...inputProps} />}
                      </ReactInputMask>
                      {errors['expDate'] &&
                                                <FormHelperText>{errors['expDate']}</FormHelperText>
                      }
                    </FormControl>
                  </Box>
                  <Box width={135}>
                    <FormControl
                      fullWidth
                      id="securityCode"
                      variant="outlined"
                      error={!!errors['securityCode']}
                    >
                      <InputLabel>Security Code</InputLabel>
                      <ReactInputMask
                        mask={constants.securityCodeMask}
                        value={model['securityCode']}
                        onChange={handleChange('securityCode')}
                        maskChar={null}
                        type="password"
                        autoComplete="new-password"
                      >
                        {(inputProps) => <OutlinedInput {...inputProps} />}
                      </ReactInputMask>
                      {errors['securityCode'] &&
                                                <FormHelperText>{errors['securityCode']}</FormHelperText>
                      }
                    </FormControl>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" height={62}>
                  <AddNoteShieldIcon />
                  <Box ml={2}>
                    <Typography variant="body1" style={{ color: '#A4A4A4' }}>We keep your sensitive data encrypted and secure</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <input type="submit" onClick={onSave} style={{ display: 'none' }} />
        </form>
      </Box>
      <InnerFooter>
        <Box pb={4} px={14} display="flex" justifyContent="flex-end" alignItems="center" width="100%">
          <Box mr={5}>
            <Link variant="subtitle1" onClick={() => history.goBack()}>
                            CANCEL
            </Link>
          </Box>
          <Button size="large" variant="contained" onClick={onSave}>
                        SAVE
          </Button>
        </Box>
      </InnerFooter>
    </PageLayout>
  )
};

import React, { useCallback, useMemo, useState } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { Modal } from '@components/modals';
import TableList, { TCELL_TYPES } from '@components/lists/table-list';
import { DeleteIcon } from '@app/icons';
import CustomChip from '@components/controls/custom-chip';
import NoResultsFound from '@components/layout/no-results-found';
import SelectOutlined from '@components/inputs/select-outlined';
import CustomTooltip from '@components/controls/tooltip';
import { datadogLogs } from '@datadog/browser-logs'

import { clientApi } from '@app/api';
import { useDispatch, useSelector } from 'react-redux';
import { backgroundCheck } from '@store/actions';
import history from '@app/history';
import { getCountryName } from '@services/country-service';

import { useStyles } from './styles';
import { useSnackbar } from 'notistack';

import { FUZZINESS_VALUE } from '../background-check/subpages/components/seacrh-components/constants';
import { useSuppressSnackbar } from '@utils/useSuppressSnackbar';

const noFields = ['reviewed', 'createdOn', 'searchId', 'id', 'lastRunOn', 'monitoring', 'linkedClientId', 'ongoingMonitoringId', 'remainingFreeSearches', 'client', 'lastAcknowledgedAt', 'acknowledged', 'versionsCount', 'bgCheckRiskRating'];
const options = ['On', 'Off'];
const DELETE = 'delete';

const viewBadge = (item) => {
  const tags = [];
  for (const key in item) {
    if (key === 'listTypes') {
      tags.push(...item[key]);
    } else if (key === 'country' && !!item[key]) {
      const country = getCountryName(item[key]);
      tags.push(country?.toUpperCase());
    } else if (key === 'fuzziness' && !!item[key]) {
      tags.push(FUZZINESS_VALUE[item[key]]);
    } else if (key !== 'name' && !noFields.includes(key) && !!item[key]) {
      tags.push(item[key]);
    }
  }

  return tags.map((tag, i) => (
    <Box ml={i === 0 ? '15px' : '5px'} key={`${tag}-${Math.random()}`}>
      <CustomChip
        label={tag}
      />
    </Box>
  ))
}

const reviewChangeWarning = (item) => {
  if (!item.acknowledged) {
    return (
      <CustomTooltip title="Review changes" placement="top">
        <Box ml="4px">
          <CustomChip label="REVIEW" background="#7A5B44" labelColor="#FFF" />
        </Box>
      </CustomTooltip>
    )
  }
  return null;
}

const versionsCount = (item) => {
  if (!!item.versionsCount) {
    return (
      <CustomTooltip title="Number of search versions" placement="top">
        <span>
          <CustomChip label={item.versionsCount + 1} background="#EDEDED" versions />
        </span>
      </CustomTooltip>
    )
  }
  return null;
}

export default function SearchHistoryTable(props) {
  const { enqueueSnackbar } = useSnackbar();
  const setSuppressSnackbar = useSuppressSnackbar();
  const dispatch = useDispatch();
  const {
    allSearches, setAllSearches,
    page, setPage, pageSize, pagesCount, totalCount,
    tableMaxHeight, getAllSearches, startDate, endDate,
    sortableColumns, sort, onSort, sortDirection,
    inputData, hiddenFields, setMonitoring, hideUserBadges
  } = props;

  const { loader } = useSelector(store => store.loader);
  const [openModal, setOpenModal] = useState({});

  const classes = useStyles({ delete: openModal.type === DELETE });

  const nameDecoration = useCallback((item) => (
    <React.Fragment>
      {!hideUserBadges && versionsCount(item)}
      {!hideUserBadges && reviewChangeWarning(item)}
    </React.Fragment>
  ), [hideUserBadges])

  const handleSelectChange = (item) => async (event) => {
    if (!event.target.value) return;


    if (event.target.value === 'Off') {
      const res = await clientApi.ongoingMonitoring.deleteOngoingMonitoring(item.searchId);
      if (res.status === 200) {
        setAllSearches(items =>
          items.map(el => el.searchId === item.searchId
            ? ({ ...el, monitoring: event.target.value, ongoingMonitoringId: null })
            : el
          )
        )
      }
      return;
    }

    if (!!item.linkedClientId) {
      setMonitoring({ searchId: item.searchId, clientId: item.linkedClientId });
      return;
    } else {
      setOpenModal({ type: item.type, id: item.searchId, name: item.name });
    }
  }

  // eslint-disable-next-line
  const viewSelect = (item) => {
    return (
      <SelectOutlined
        value={item.monitoring}
        handleChange={handleSelectChange(item)}
        options={options.filter(el => el !== item.monitoring)}
        anchorProps={{
          vertical: 'bottom',
          horizontal: -30,
        }}
      />
    )
  }

  const tableActions = useMemo(() => {
    const clientActions = [
      { label: 'View', handler: (id, item) => handleViewSearch(id, item) },
      { label: 'Re-run', handler: (id, item) => handleReRun(id, item) },
    ]

    return clientActions;
    // eslint-disable-next-line
  }, [])

  const TABLE_COLUMNS = useMemo(() => {
    const clientColumns = [
      { cellType: TCELL_TYPES.TEXT_ICON, field: 'name', headerName: 'SEARCH NAME', bold: true, decoration: nameDecoration },
      { field: 'client', headerName: 'CLIENT' },
      { cellType: TCELL_TYPES.CUSTOM, field: '', headerName: '', custom: viewBadge },
      { field: 'createdOn', headerName: 'CREATED ON' },
      { field: 'lastRunOn', headerName: 'LAST RUN DATE' },
      { field: 'reviewed', headerName: 'PERFORMED BY' },
      { cellType: TCELL_TYPES.CUSTOM, field: 'monitoring', headerName: 'MONITORING', custom: viewSelect, preventClick: true },
      { cellType: TCELL_TYPES.ICON_BUTTON, type: 'action', field: 'remove', headerName: '', icon: <DeleteIcon />, tooltip: 'Delete' }
    ]

    if (hiddenFields) {
      return clientColumns.filter(column => !hiddenFields.includes(column.field))
    }
    return clientColumns;
  }, [hiddenFields, nameDecoration, viewSelect])

  const handleDeleteHistory = async () => {
    const res = await clientApi.search.deleteSearch(openModal.id);

    if (res.status === 200) {
      const search = allSearches.find(search => search.searchId === openModal.id);
      enqueueSnackbar(`Search history "${search.name}" was successfully deleted!`, { variant: 'success' });
      setAllSearches(searches => searches.filter(search => search.searchId !== openModal.id));
      getAllSearches();
      setOpenModal({});
    }
  }

  const handleViewSearch = useCallback(async (id, item) => {
    let data = {};
    for (const key in item) {
      if (!!item[key] && !noFields.includes(key)) {
        data = { ...data, [key]: item[key] }
      }
    }

    dispatch(backgroundCheck.updateCurrentSearch(data));
    history.push(`/search-history/results?searchId=${item.id}&searchName=${item.name}&searchType=${item.type}`);
  }, [dispatch])

  const handleReRun = async (id, item) => {
    try {
      setSuppressSnackbar(true);
      const res = await clientApi.search.repeatSearchEntities(id);
  
      if (res.status === 200) {
        history.push(`/search-history/results?searchId=${res.data.searchId}&searchName=${res.data.name}&searchType=${res.data.type}`);
      }
    } catch (error) {
      const err = { ...error };
      if (err.response?.data?.error === 'Search can be repeated only if it is on monitoring.') {
        if (!!item.linkedClientId) {
          setOpenModal({ type: 'notOnMonitoring', item })
        } else {
          setOpenModal({ type: item.type, id: item.searchId, name: item.name, isRerun: true });
        }
      } else if (err.response?.data?.error === 'You don\'t have enough credits to monitor this name. Visit the subscription page from your profile to see the credits and spending.') {
        setOpenModal({ type: 'reRun' });
      } else if (err.response?.data?.error) {
        enqueueSnackbar(err.response.data.error, { variant: 'error '})
      } else {
        datadogLogs.logger.error('Unexpected API call error', { error })
        enqueueSnackbar('Something went wrong...', { variant: 'error '})
      }
    } finally {
      setSuppressSnackbar(false);
    }
  }

  const handleBuyMoreCredits = () => {
    history.push('/my-profile/subscription/plans');
    setOpenModal({});
  }

  const handleSaveToPortfolio = () => {
    history.push(`/search-history/save-to-portfolio/results?searchType=${openModal.type.toLowerCase()}&searchId=${openModal.id}&name=${openModal.name}`);
    setOpenModal({});
  }

  const updateExisting = () => {
    history.push(`/search-history/update-existing/${openModal.type.toLowerCase()}/${openModal.id}`);
    setOpenModal({});
  }

  return (
    <Grid container>
      {allSearches.length ?
        <Grid item sm={12}>
          <TableList
            columns={TABLE_COLUMNS}
            items={allSearches}
            page={page}
            pageSize={pageSize}
            pagesCount={pagesCount}
            totalCount={totalCount}
            onPageChange={(event, value) => setPage(value - 1)}
            sortableColumns={sortableColumns}
            onSort={onSort}
            sort={sort}
            sortDirection={sortDirection}
            threeDotsActions={tableActions}
            handlers={{
              remove: (id, event) => {
                event.preventDefault();
                event.stopPropagation();
                setOpenModal({ type: DELETE, id })
              }
            }}
            onClick={handleViewSearch}
            maxHeight={tableMaxHeight}
          />
        </Grid>
        : !loader && (
          <Box mt={16} flexGrow={1}>
            <NoResultsFound>
              {((!!startDate || !!endDate || !!inputData) && !allSearches.length)
                ? 'No entities found for applied filters'
                : 'When you run a background check, the history of searches will appear here'
              }
            </NoResultsFound>
          </Box>
        )}
      <Modal
        open={openModal.type === 'reRun'}
        onClose={() => setOpenModal({})}
        title="Credit Limit Reached"
        propsClassses={classes.customButtonWidth}
        actions={
          [
            {
              type: 'main',
              label: 'No',
              action: () => setOpenModal({}),
            },
            {
              type: 'secondary',
              label: 'Yes',
              action: () => handleBuyMoreCredits(),
            },
          ]
        }
        content={(
          <Box>
            <Typography className={classes.modalText}>
              You do not have enough credits. A search credit equals one unique name search. Every search spends one search credit.
            </Typography>
            <Box mb={2}>
              <Typography className={classes.modalText}>
                Do you want to buy more credits?
              </Typography>
            </Box>
          </Box>
        )}
        actionsDirection="row"
      />
      <Modal
        open={openModal.type === 'delete'}
        onClose={() => setOpenModal({})}
        title="Delete Search"
        mainText="Are you sure you want to delete the selected search history entry?"
        propsClassses={classes.customButtonWidth}
        actions={
          [
            {
              type: 'main',
              label: 'No',
              action: () => setOpenModal({}),
            },
            {
              type: 'secondary',
              label: 'Yes',
              action: () => handleDeleteHistory(),
            },
          ]
        }
        actionsDirection="row"
      />
      <Modal
        open={['PERSON', 'COMPANY'].includes(openModal.type)}
        onClose={() => setOpenModal({})}
        title={openModal.isRerun ? 'Search Is Not On Monitoring' : 'Set Ongoing monitoring'}
        mainText={
          (openModal.isRerun ? 'Search can be repeated only if it is on monitoring.\n\n' : '')
          +
          `In order to put the search on ongoing monitoring, please link the search with the client, by saving as a new ${openModal.type === 'PERSON' ? 'person' : 'legal entity'} or updating the existing ${openModal.type === 'PERSON' ? 'person' : 'legal entity'}.`
        }
        propsClassses={classes.customButtonWidth}
        actions={
          [
            {
              type: 'main',
              label: 'ATTACH TO EXISTING',
              action: () => updateExisting(),
            },
            {
              type: 'secondary',
              label: 'SAVE IN PORTFOLIO',
              action: () => handleSaveToPortfolio(),
            },
          ]
        }
        actionsDirection="row"
      />
      <Modal
        open={openModal.type === 'notOnMonitoring'}
        onClose={() => setOpenModal({})}
        title="Search Is Not On Monitoring"
        mainText="Search can be repeated only if it is on monitoring."
        propsClassses={classes.customButtonWidth}
        actions={
          [
            {
              type: 'main',
              label: 'Cancel',
              action: () => setOpenModal({}),
            },
            {
              type: 'secondary',
              label: 'Add to Monitoring',
              action: () => handleSelectChange(openModal.item)({ target: { value: 'on' }}),
            },
          ]
        }
        actionsDirection="row"
      />
    </Grid>
  )
};

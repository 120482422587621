import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

const storeSnackbar = state => state.general.snackbar;
const suppressSnackbar = state => state.general.suppressSnackbar;

function StoreSnackbarInterceptor(props) {
  const snackbar = useSelector(storeSnackbar);
  const suppressed = useSelector(suppressSnackbar);
  const { enqueueSnackbar } = useSnackbar();

  // check for snackbar from store as well
  useEffect(() => {
    const { message, options, suppressable } = snackbar;
    if (message) {
      if (suppressable && suppressed) {
        return;
      }

      enqueueSnackbar(message, options);
    }
  }, [snackbar]) // eslint-disable-line

  return null
}

export default StoreSnackbarInterceptor;

import palette from './palette';
import { getColorStyles } from '@app/theme'

const config = {
  MuiLink: {
    root: {
      cursor: 'pointer',
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      ...getColorStyles(palette.text.LB3),
      '&.MuiTypography-subtitle1': {
        fontSize: '16px'
      },
      '&.MuiTypography-subtitle2': {
        fontSize: '14px'
      },
      '&.MuiTypography-body1': {
        fontSize: '12px'
      },
      '&.MuiTypography-body2': {
        fontSize: '10px'
      }
    }
  },
}

export default config
const initialState = {
  portfolioSort: '',
  portfolioFilters: null,
  portfolioSortDirection: ''
};

const portfolio = (state = initialState, action) => {

  switch (action.type) {
    case 'PORTFOLIO_SET_DEFAULT':
      return initialState;
    case 'PORTFOLIO_SET_SORT_FILTERS':
      return {
        ...state,
        portfolioSort: action.sort,
        portfolioFilters: action.filters,
        portfolioSortDirection: action.sortDirection,
      };
    default:
      return state
  }
};

export default portfolio;

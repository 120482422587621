const initialState = {
  invoices: [],
};

const billingManagement = (state = initialState, action) => {

  switch (action.type) {
    case 'SET_INVOICES':
      return {
        ...state,
        invoices: action.invoices,
      };
    default:
      return state
  }
};

export default billingManagement;

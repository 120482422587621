import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';

import history from '@app/history';
import { ChevronLeftIcon } from '@app/icons';
import { clientApi } from '@app/api';
import { backgroundCheck, backgroundSearch } from '@store/actions';
import { capitalize } from '@utils/textUtil';

import ButtonWithIcon from '@components/buttons/button-with-icon';
import InnerHeader from '@components/layout/inner-header';

import { useParams } from 'react-router-dom';
import Search from '../background-check/subpages/search';
import { useDispatch } from 'react-redux';

export default function MonitoringBgCheck(props) {
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const { steps, onNext } = props;
  const [type, setType] = useState('PERSON')
  const [searchLine, setSearchLine] = useState('');

  useEffect(() => {
    if (clientId) {
      clientApi.client.show(clientId).then((res) => {
        const client = res.data;

        dispatch(backgroundCheck.updateCurrentSearch({
          type: client.clientType,
        }));

        if (client.clientType === 'PERSON') {
          dispatch(backgroundSearch.setSearchModel({
            country: client.citizenship || client.residency,
            yob: client.dob ? new Date(client.dob).getFullYear() : undefined,
            gender: capitalize(client.gender),
          }));

          setSearchLine(`${client.firstName} ${client.lastName}`);
        } else {
          dispatch(backgroundSearch.setSearchModel({
            country: client.registeredOfficeCountry,
            registrationNumber: client.registrationNumber,
          }));

          setSearchLine(client.name);
        }
        setType(client.clientType);
      })
    }
  }, [clientId, dispatch])

  return (
    <Box height="100%">
      <Box px={6}>
        <InnerHeader
          title="Background Check"
          stepper={{
            currentStep: steps.currentStep,
            stepsCount: steps.stepsCount
          }}
          buttons={
            <React.Fragment>
              <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={() => { history.goBack() }}>
                {'Back'}
              </ButtonWithIcon>
            </React.Fragment>
          }
        />
      </Box>
      <Box style={{ overflowY: 'auto', height: 'calc(100% - 98px)' }}>
        <Search search_line={searchLine} forceType={type} onNext={onNext} />
      </Box>
    </Box>
  )
}

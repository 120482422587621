import React from 'react';
import { Radio, makeStyles } from '@material-ui/core';
import { EllipseActiveIcon, EllipseInactiveIcon } from '@app/icons';

const useStyles = makeStyles((theme) => ({
  rootRadio: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
})) ;

export default function RadioButton(props) {
  const classes = useStyles();

  return (
    <Radio
      classes={{ root: classes.rootRadio }}
      disableRipple
      {...props}
      checkedIcon={<EllipseActiveIcon />}
      icon={<EllipseInactiveIcon />}
    />
  )
}
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, Typography, InputLabel, OutlinedInput, FormControl } from '@material-ui/core';
// import ButtonWithIcon from '@components/buttons/button-with-icon';
// import { EditIcon } from "@app/icons";
import LabelsForRender from '@components/layout/labels-for-render';
import { clients } from '@app/api/admin';
import { getCountryName } from '@services/country-service';
import { isEmpty } from 'lodash';

import { dummy } from '../../dummy';
import history from '@app/history';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  subtitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '18px',
    color: theme.palette.text.BL1,
    marginBottom: '15px',
  },
  wrapperLabels: {
    marginBottom: '10px',
    alignItems: 'flex-start'
  },
  input: {
    width: '305px',
    marginRight: '10px',
  },
  editButton: {
    height: '30px',
    marginTop: '-8px',
  }
}))

export default function AccountDetails({ userId }) {
  const classes = useStyles();

  const [user, setUser] = useState({});

  const getUsers = useCallback(async () => {
    const currUser = (await clients.getUserById(userId)).data;

    if (currUser) {
      const personalInformation = dummy.personalInformation.map(el => ({
        ...el, value: (el.parseField === 'country'
          ? getCountryName(currUser[el.parseField])
          : currUser[el.parseField]
        )  || 'N/A'  
      }));
      const contactInformation = dummy.contactInformation.map(el => ({
        ...el, value: currUser[el.parseField] || 'N/A'
      }));

      const additionalInfo = [
        { field: 'TEST USER', value: !!currUser.testUser? 'Yes': 'No' }
      ]

      setUser({
        email: currUser.email || 'N/A',
        personalInformation,
        contactInformation,
        additionalInfo
      });
    }
  }, [userId])

  useEffect(() => {
    getUsers();
  }, [getUsers])

  // eslint-disable-next-line
  const handleEdit = (id, item) => {
    history.push(`/user-management/edit-user/?userId=${userId}`)
  }

  return !isEmpty(user) && (
    <React.Fragment>
      <Grid container spacing={12}>
        <Grid item xs={6}>
          <Typography className={classes.subtitle}>Personal Information</Typography>
          <LabelsForRender labels={user.personalInformation || []} propsWrapper={classes.wrapperLabels} />
        </Grid>
        <Grid item xs={6}>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography className={classes.subtitle}>Contact Information</Typography>
            {/* <ButtonWithIcon startIcon={<EditIcon />} onClick={handleEdit} className={classes.editButton}>
              Edit
            </ButtonWithIcon> */}
          </Box>
          <LabelsForRender labels={user.contactInformation || []} propsWrapper={classes.wrapperLabels} />
        </Grid>
      </Grid>
      <Box display={'flex'} mt={4}>
        <Grid container spacing={12}>
          <Grid item xs={6}>
            <Typography className={classes.subtitle}>Account Protection</Typography>
            <FormControl fullWidth variant="outlined" style={{width: '70%'}}>
              <InputLabel htmlFor="name">Email</InputLabel>
              <OutlinedInput
                value={user.email}
                disabled
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.subtitle}>Additional Settings</Typography>
            <LabelsForRender
              labels={user.additionalInfo || []}
              propsWrapper={classes.wrapperLabels}
            />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  )
}
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Popover,
  IconButton,
  InputBase,
} from '@material-ui/core';
import { CloseIcon, SearchOtherIcon, SearchWhiteIcon } from '@app/icons';

import { clientApi } from '@app/api';
import { useSelector } from 'react-redux';
import moment from 'moment';
import history from '@app/history';

import {
  DAYS,
  CLIENT_TYPE,
  GET_MONTHS,
  PLACEHOLDER,
  EXPIRATION_CHECKS,
} from '../../constants';
import { useStyles } from './styles';

export default function CardPopover({
  item,
  isYearly,
  anchorEl,
  setAnchorEl,
  placeholder,
}) {
  const { dateInterval, documentFilter } = useSelector(
    (state) => state.calendarSchedule
  );

  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const classes = useStyles({ placeholder });

  const counter = useMemo(() => {
    if (placeholder === 'People') {
      return item.personsCount;
    } else if (placeholder === 'Legal Entities') {
      return item.legalEntitiesCount;
    } else {
      return item.documentsCount;
    }
  }, [item, placeholder]);

  const preparePayload = useCallback(() => {
    const fromNextDateOfReview = isYearly
      ? moment(`${dateInterval.year}-${item.key}`).startOf('month')
      : moment(
          `${dateInterval.year}-${dateInterval.month}-${item.name}`
      ).add(1, 'months').startOf('day');

    const toNextDateOfReview = isYearly
      ? moment(`${dateInterval.year}-${item.key}`).endOf('month')
      : moment(
          `${dateInterval.year}-${dateInterval.month}-${item.name}`
      ).add(1, 'months').endOf('day');
    if (placeholder === 'Documents') {
      return {
        expirationTypes: EXPIRATION_CHECKS[documentFilter.types],
        fromExpirationDate: fromNextDateOfReview,
        toExpirationDate: toNextDateOfReview,
        pageNumber: 0,
        pageSize: counter || 10,
        sortBy: 'expiryDate',
        sortDirection: 'ASC',
      };
    } else {
      return {
        clientType: CLIENT_TYPE[placeholder],
        fromNextDateOfReview,
        toNextDateOfReview,
        pageNumber: 0,
        pageSize: counter || 10,
        sortBy: 'name',
      };
    }
  }, [placeholder, dateInterval, item, counter, isYearly, documentFilter]);

  const getDocType = (id) => clientApi.clientDocumentType.show(id);
  const getClientType = (id) => clientApi.client.show(id);
  const getDocuments = useCallback(async () => {
    const res = (await clientApi.clientDocument.search({ ...preparePayload() }))
      .data;
    setData([]);
    if (res) {
      const types = await Promise.all(
        res.entries.map(async (item) => {
          const docType = await getDocType(item.typeId);
          const clientType = await getClientType(
            item.associations[0]?.clientId
          );
          return {
            id: clientType.data.id,
            label: `${clientType.data.firstName} ${clientType.data.lastName} - ${docType.data.name}`,
          };
        })
      );
      setData(types);
    }
  }, [preparePayload]);

  const getClients = useCallback(async () => {
    const res = (await clientApi.client.index({ ...preparePayload() })).data;
    const tmpArr = res.entries.map((item) => ({
      label: item.name || `${item.firstName || ''} ${item.lastName || ''}`,
      id: item.id,
    }));
    setData(tmpArr);
  }, [preparePayload]);

  useEffect(() => {
    if (!!anchorEl) {
      placeholder === 'Documents' ? getDocuments() : getClients();
      setSearch('');
    }
  // eslint-disable-next-line
  }, [anchorEl, placeholder]);

  const handleClose = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(null);
      setData([]);
    },
    [setAnchorEl]
  );

  const handleClick = useCallback(
    (event, el) => {
      event.preventDefault();
      event.stopPropagation();
      history.push(
        `/portfolio/view/${el.id}/${
          placeholder === 'Documents' ? 'kyc-documents' : 'risk-rating'
        }`
      );
    },
    [placeholder]
  );

  const onChange = useCallback((event) => {
    setSearch(event.target.value);
  }, []);

  const handleLabel = useCallback(() => {
    const day = moment(
      `${dateInterval.year}-${dateInterval.month + 1}-${item.name}`
    ).format('d');
    return isYearly
      ? `${item.name} ${dateInterval.year}`
      : `${DAYS[day]}, ${item.name} ${GET_MONTHS[dateInterval.month]}`;
  }, [isYearly, item, dateInterval]);

  return (
    <Popover
      id={!!anchorEl ? 'simple-popover' : undefined}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={(event) => handleClose(event)}
      classes={{ paper: classes.paper }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        className={classes.wrapperPopoverContent}
        p={2}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width={'100%'}
          pl={1}
        >
          <Typography className={classes.popoverLabel}>
            {handleLabel()}
          </Typography>
          <IconButton
            classes={{ root: classes.buttonRoot }}
            onClick={(event) => handleClose(event)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.search} my={1}>
          <Box className={classes.searchIcon}>
            {placeholder === 'Documents' ? (
              <SearchWhiteIcon width={14} height={14} />
            ) : (
              <SearchOtherIcon width={14} height={14} />
            )}
          </Box>
          <InputBase
            autoFocus
            value={search}
            onChange={(e) => onChange(e)}
            placeholder={`${counter} ${
              counter === 1 ? PLACEHOLDER[placeholder] : placeholder
            }`}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
          />
        </Box>
        <Box className={classes.popoverContent} mt={1}>
          {!!data.length ? (
            data?.filter(
              (el) => el.label.toLowerCase() === search.toLowerCase()
            ) ? (
                data.map(
                  (el, index) =>
                    el.label.toLowerCase().includes(search.toLowerCase()) && (
                      <Box
                        key={index}
                        className={classes.nameWrapper}
                        onClick={(event) => handleClick(event, el)}
                      >
                        <Typography className={classes.name}>
                          {el?.label ||
                          el?.name ||
                          `${el?.firstName || ''} ${el?.lastName || ''}`}
                        </Typography>
                      </Box>
                    )
                )
              ) : (
                <Box display="flex" alignItems="center" py={'5px'} px={'10px'}>
                  <Typography
                    className={classes.name}
                    style={{ color: '#C6C6C6' }}
                  >
                  No results found
                  </Typography>
                </Box>
              )
          ) : (
            <Box display="flex" alignItems="center" py={'5px'} px={'10px'}>
              <Typography className={classes.name} style={{ color: '#C6C6C6' }}>
                No results found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Popover>
  );
}

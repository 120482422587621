import React, { useCallback, useEffect } from 'react';
import {Box, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import SelectCategoryDialog from '../../../dialogs/select-category-dialog';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import {
  AddIcon
} from '@app/icons';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchCategories,
  fetchTypes,
  selectTypes,
  setSelectCategoryDialog,
  setSelectTypeDialogOpen
} from '../../../duck';
import { clientApi } from '@app/api';
import {useSnackbar} from 'notistack';
import SelectTypeDialog from '../../../dialogs/select-type-dialog';
import NoResultsFound from '@components/layout/no-results-found';
import DocumentRow from './document-row';
import {getIsInheritedItem} from '../../../utils/inheritance'

const useStyles = makeStyles(() => ({
  documentsColumnHead: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: '#232323'
  }
}))

export default function FormDocuments({ rulebook, data, setData, categoryHasType, switchCategoryRuleType, switchTypeInCategory, categoryTypeExpected, readOnly }) {

  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCategories())
    dispatch(fetchTypes())
  }, [dispatch])

  const allTypes = useSelector(selectTypes) || []

  useEffect(() => {
    console.log('Data:', data)
  }, [data])

  const onSelectCategory = async category => {
    let newCategory;
    if (!category.id) {
      newCategory = (await clientApi.clientDocumentCategory.store(
        category
      )).data
      newCategory.types = []
      dispatch(fetchCategories(true))
      enqueueSnackbar(`Entity category "${category.name}" was successfully created!`, { variant: 'success' })
    } else {
      newCategory = category
      newCategory.types = allTypes.filter(t => t.categoryIds.includes(category.id))
    }
    console.log('Selected category:', newCategory)
    const newRulebookCategory = {
      categoryRuleType: 'PER_TYPE',
      categoryId: newCategory.id,
      // Uncomment to select types
      // types: newCategory?.types.map(cType => ({
      //   typeId: cType.id,
      //   expected: false
      // })) || [],
      types: [],
      ref: newCategory,
    }
    console.log('Injected rulebook category:', newRulebookCategory)
    const newCategories = [
      newRulebookCategory,
      ...data.documentRules || []
    ]
    console.log('newCategories', newCategories)
    setData({
      ...data,
      documentRules: newCategories
    })
  }

  const onSelectType = async (type, categoryId) => {
    let newType;
    if (!type.id) {
      newType = (await clientApi.clientDocumentType.store(type)).data
      enqueueSnackbar(`Document type "${type.name}" was successfully created!`, { variant: 'success' })
    } else {
      if (type.ownedBy !== 'SYSTEM') {
        newType = (await clientApi.clientDocumentType.update(type.id, type)).data
      } else {
        newType = (await clientApi.clientDocumentType.updateSystem(type.id, type)).data
      }
      enqueueSnackbar(`Document type "${type.name}" was successfully linked with the document category!`, { variant: 'success' })
    }
    dispatch(fetchTypes(true))
    const newRulebookType = {
      typeId: newType.id,
      expected: false
    }
    const newData = {
      ...data,
      documentRules: data?.documentRules?.map(rulebookCategory => ({
        ...rulebookCategory,
        types: rulebookCategory.categoryId !== categoryId
          ? rulebookCategory.types
          : [
            newRulebookType,
            ...rulebookCategory.types
          ]
      })) || []
    }
    setData(newData)
  }

  const onSelectCategoryDialogOpen = useCallback(() => {
    dispatch(
      setSelectCategoryDialog(true)
    )
  }, [dispatch])

  const onSelectTypeDialogOpen = (
    categoryId,
    excludeIds = []
  ) => () => {
    dispatch(
      setSelectTypeDialogOpen(
        {categoryId, excludeIds}
      ))
  }

  const onInputExclude = index => () => {
    const newDocumentRules = [ ...rulebook?.documentRules ]
    newDocumentRules.splice(index,1)
    setData({
      ...data,
      documentRules: newDocumentRules
    })
  }

  //console.log('Documents', rulebook, rulebook?.documentRules, rulebook?.documentRules)
  return (
    <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
      { !readOnly && (
        <Box display={'flex'} flexGrow={1} justifyContent={'flex-end'}>
          <ButtonWithIcon
            startIcon={<AddIcon />}
            onClick={onSelectCategoryDialogOpen}
          >
            Add Category
          </ButtonWithIcon>
        </Box>
      )}
      {/* Head */}
      { !!rulebook?.documentRules?.length && (
        <Box display={'flex'} flexGrow={1} pl={2}>
          <Grid container>
            <Grid item xs={3}>
              <span className={classes.documentsColumnHead}>
              CATEGORY
              </span>
            </Grid>
            <Grid item xs={5}>
              <span className={classes.documentsColumnHead}>
              TYPES
              </span>
            </Grid>
            <Grid item xs={3}>
              <span className={classes.documentsColumnHead}>
              REQUIRED
              </span>
            </Grid>
            <Grid item xs={1}/>
            {/* <Grid item xs={3} >*/}
            {/*  <span className={classes.documentsColumnHead}> */}
            {/*    CONDITIONAL LOGIC */}
            {/*  </span> */}
            {/* </Grid> */}
          </Grid>
        </Box>
      )}
      {/* Content */}
      
      <Box display={'flex'} flexDirection={'column'} flexGrow={1}>

        { rulebook?.documentRules.map((rulebookCategory, iRulebookCategory) => (

          <DocumentRow
            key={`rulebook-${rulebookCategory.definedByRulebookVersionId}-${rulebookCategory.ref.id}`}
            rulebookCategory={rulebookCategory}
            switchTypeInCategory={switchTypeInCategory}
            switchCategoryRuleType={switchCategoryRuleType}
            categoryTypeExpected={categoryTypeExpected}
            categoryHasType={categoryHasType}
            onSelectTypeDialogOpen={onSelectTypeDialogOpen}
            readOnly={readOnly
              || getIsInheritedItem(rulebook, rulebookCategory)}
            data={data}
            setData={setData}
            onExclude={onInputExclude(iRulebookCategory)}
          />

        ))}

        { !rulebook?.documentRules?.length && (
          <Box display={'flex'} width={'100%'} justifyContent={'center'}>
            <NoResultsFound>
              You did not add any categories to this rulebook
            </NoResultsFound>
          </Box>
        ) }
      </Box>
      {/* Dialogs */}
      <SelectCategoryDialog
        onSave={onSelectCategory}
        clientType={data?.clientType}
      />
      <SelectTypeDialog
        onSave={onSelectType}
        clientType={data?.clientType}
      />
    </Box>
  )
}
import React from  'react';
import { Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  customButtonRoot: {
    margin: '5px 0',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '12px',
    boxShadow: 'none',
    '&.MuiButton-contained': {
      padding: '10px 25px',
    },
  },
  customButtonStandard: {
    background: 'linear-gradient(270deg, #9A7C6A 0%, #7A5B44 100%)',
    border: `2px solid ${theme.palette.text.LB3}`,
    borderRadius: 12,
    color: theme.palette.common.white,
    transition: 'none',
    '&:hover': {
      background: '#7A5B44',
    },
    '&.Mui-disabled': {
      border: '2px solid #C6C6C6',
    }
  },
  customButtonBordered: {
    background: theme.palette.common.white,
    borderRadius: 12,
    color: theme.palette.text.LB3,
    transition: 'none',
    border: `2px solid ${theme.palette.text.LB3}`,
    '&:hover': {
      background: '#F8F8F8',
    }
  },
  customButtonSlim: {
    padding: '2px 14px',
    background: theme.palette.common.white,
    color: theme.palette.text.LB3,
    border: `3px solid ${theme.palette.text.LB3}`,
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '20px',
    '&:hover': {
      background: theme.palette.common.white,
    }
  },
}));

export default function CustomButton({ action, label, customClasses, disabled, propsClassses, fullWidth, style, mouseDown }) {
  const classes = useStyles();
  const props = mouseDown ? { onMouseDown: action } : { onClick: action };

  return (
    <Button
      { ...props }
      variant="contained"
      classes={{ root: clsx(classes.customButtonRoot, classes[customClasses], propsClassses) }}
      disabled={disabled}
      fullWidth={!!fullWidth}
      style={style}
    >
      {label}
    </Button>
  )
}
const setDefault = token => ({
  type: 'SET_BACKGROUND_CHECK_DEFAULT'
});

const updateCurrentSearch = currentSearch => ({
  type: 'UPDATE_CURRENT_SEARCH',
  currentSearch,
});

const setProfile = profile => ({
  type: 'SET_PROFILE',
  profile,
});

const setSources = sources => ({
  type: 'SET_SOURCES',
  sources,
});

const setProfiles = profiles => ({
  type: 'SET_PROFILES',
  profiles,
});

const setChangedProfiles = profiles => ({
  type: 'SET_CHANGED_PROFILES',
  profiles,
});

const setChangedSources = sources => ({
  type: 'SET_CHANGED_SOURCES',
  sources,
});

const setQuantityProfiles = quantity => ({
  type: 'SET_QUANTITY_PROFILES',
  quantity,
});

const setSelectAllProfiles = bool => ({
  type: 'SET_SELECT_ALL_PROFILES',
  bool,
});

const setSelectAllSources = bool => ({
  type: 'SET_SELECT_ALL_SOURCES',
  bool,
});

const setSelectedProfile = selectedProfile => ({
  type: 'SET_SELECTED_PROFILE',
  selectedProfile,
});

const setProfilesPages = profilesPages => ({
  type: 'SET_PROFILES_PAGES',
  profilesPages,
});

const setProfilesPage = profilesPage => ({
  type: 'SET_PROFILES_PAGE',
  profilesPage,
});

const setShowWhitelisted = isShowWhitelisted => ({
  type: 'SET_SHOW_WHITELISTED',
  isShowWhitelisted,
});

const backgroundCheck = {
  setDefault,
  updateCurrentSearch,
  setProfile,
  setSources,
  setProfiles,
  setChangedProfiles,
  setChangedSources,
  setQuantityProfiles,
  setSelectAllProfiles,
  setSelectAllSources,
  setSelectedProfile,
  setProfilesPages,
  setShowWhitelisted,
  setProfilesPage
}

export default backgroundCheck;
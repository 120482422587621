import React from  'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UserCardXL from '@components/cards/user-card-xl';
import BackButton from '@views/common/back-button';
import { handleCardTitle, handleCardDescription } from '@utils/handleTitleCardXL';

const useStyles = makeStyles((theme) => ({
  scrollCard: {
    overflowY: 'auto',
    padding: '0 2px',
    paddingBottom: 25,
  },
  customButtonClasses: {
    marginLeft: -8,
  },
}))

export default function SearchSources({ sourcesData, goBack }) {
  const classes = useStyles();

  return (
    <Box className={classes.scrollCard}>
      <BackButton
        action={goBack}
        customButtonClasses={classes.customButtonClasses}
      />
      <Grid
        container
        alignItems={'center'}
        justify={'center'}
      >
        {sourcesData && sourcesData
          .map((el, k) => (
            <UserCardXL
              {...el}
              title={handleCardTitle(el.title)}
              description={handleCardDescription(el.title) || ''}
              key={`user-card-${k}-${el.id}`}
              noLogged={true}
            />
          ))}
      </Grid>
    </Box>
  )
};
import moment from 'moment';

const initialState = {
  documentFilter: { types: 'idDocuments', categories: [] },
  sort: 'all',
  filters: {
    client: true,
    monitoring: true,
  },
  paginationPage: 0,
  dateInterval: {
    interval: 'Year',
    year: moment().year().toString(),
    month: moment().month(),
    day: moment().date(),
  },
};

const calendarSchedule = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CALENDAR_SCHEDULE_DEFAULT':
      return initialState;
    case 'SET_SORT':
      return { ...state, sort: action.sort };
    case 'SET_DOCUMENT_FILTER':
      return { ...state, documentFilter: action.documentFilter };
    case 'SET_FILTERS':
      return { ...state, filters: action.filters };
    case 'SET_DATE_INTERVAL':
      return { ...state, dateInterval: action.dateInterval };
    case 'SET_PAGINATION_PAGE':
      return { ...state, paginationPage: action.page };
    default:
      return state;
  }
};

export default calendarSchedule;

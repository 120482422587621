import React from 'react';
import { Box, Typography } from '@material-ui/core';
import InnerHeader from '@components/layout/inner-header';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { ChevronLeft } from '@material-ui/icons';
import Form from './form';

import history from '@app/history';

// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//   input: {
//     width: '100%',
//   },
//   backButton: {
//     marginLeft: 0
//   }
// }))

export default function AddUser() {

  return (
    <React.Fragment>
      <Box display={'flex'} mx={6} flexDirection={'column'} height={'100%'}>
        <Box mb={3}>
          <InnerHeader
            buttons={
              <ButtonWithIcon startIcon={<ChevronLeft />} onClick={() => history.goBack()}>
                Back
              </ButtonWithIcon>
            }
          />
        </Box>
        <Box display="flex" mt={1} mb={4} alignItems="center" justifyContent="center">
          <Typography variant="h4">Add New User</Typography>
        </Box>
        <Form />
      </Box>
    </React.Fragment>
  )
}
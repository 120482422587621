import React, { useState } from 'react';

import { Box } from '@material-ui/core';

import { ImageMissingIcon } from '@app/icons';
import ImageZoomTool from './tools/image-zoom';
import ImageDownloadTool from './tools/image-download';
import ImageViewer from './image-viewer';

// ImagePreview accepts different tools as children.
// You can position the tools using absolute positioning.
// By default tools are positioned at center of image.
export default function ImagePreview({ height, width, imgSrc, bgColor, onDownload, documentName, clientName, children }) {
  const [showFull, setShowFull] = useState(false);

  const imageAction = (action) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    action()
  }

  return (
    <React.Fragment>
      {/* Zoomed image */}
      <ImageViewer
        url={imgSrc}
        open={!!showFull}
        onClose={() => setShowFull(false)}
        documentName={documentName}
        clientName={clientName}
        onDownload={onDownload}
      />

      <Box
        position="relative"
        height={height}
        width={width}
        bgcolor={bgColor || '#8F8F8F'}
        borderRadius="12px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ cursor: imgSrc ? 'pointer' : 'default' }}
        onClick={() => imgSrc && setShowFull(true)}
      >
        {imgSrc ?
          <img
            alt="img"
            src={imgSrc}
            height="100%"
            width="100%"
            style={{ objectFit: 'scale-down', objectPosition: '50% 50%' }}
            onError={(err) => { throw new Error(`Image loading error occured! ${err.target.baseURI}; ${err.target.currentSrc}`) }}
          />
          :
          <ImageMissingIcon />
        }
        {imgSrc && <ImageZoomTool onZoom={() => setShowFull(true)} />}
        {imgSrc && <ImageDownloadTool onDownload={imageAction(onDownload)} />}
        {children}
      </Box>
    </React.Fragment>
  )
}
const initialState = {
  currentSearch: {},
  selectedProfile: {},
  profiles: {},
  sources: [],
  backupSources: [],
  profilePaquantityProfilesge: 0,
  selectAllProfiles: false,
  selectAllSources: false,
  quantityProfiles: 0,
  profilesPages: 0,
  profilesPage: 0,
  isShowWhitelisted: localStorage.getItem('isShowWhitelisted') || false,
};

const backgroundCheck = (state = initialState, action) => {

  switch (action.type) {
    case 'SET_BACKGROUND_CHECK_DEFAULT':
      return initialState;
    case 'UPDATE_CURRENT_SEARCH':
      return { ...state, currentSearch: action.currentSearch };
    case 'SET_PROFILE':
      return { ...state, profile: action.profile };
    case 'SET_CHANGED_PROFILES': {
      return { ...state, profiles: action.profiles };
    }
    case 'SET_QUANTITY_PROFILES': {
      return { ...state, quantityProfiles: action.quantity };
    }
    case 'SET_SELECT_ALL_PROFILES': {
      return { ...state, selectAllProfiles: action.bool };
    }
    case 'SET_SOURCES': {
      return { ...state, sources: action.sources, backupSources: action.sources };
    }
    case 'SET_CHANGED_SOURCES': {
      return { ...state, sources: action.sources, backupSources: state.backupSources.map(el => {
        const newElement = action.sources.find(item => item.id === el.id);
        return newElement || el;
      })
      };
    }
    case 'SET_SELECT_ALL_SOURCES': {
      return { ...state, selectAllSources: action.bool };
    }
    case 'SET_SELECTED_PROFILE': {
      return { ...state, selectedProfile: action.selectedProfile };
    }
    case 'SET_PROFILES_PAGES': {
      return { ...state, profilesPages: action.profilesPages };
    }
    case 'SET_PROFILES_PAGE': {
      return { ...state, profilesPage: action.profilesPage };
    }
    case 'SET_SHOW_WHITELISTED': {
      return { ...state, isShowWhitelisted: action.isShowWhitelisted };
    }
    case 'SET_PROFILES':
      const profilesKeys = Object.keys(state.profiles);
      let profiles = {};
      if (profilesKeys.length === 3) {
        const actionKey = Object.keys(action.profiles)[0];

        if (!!state.profiles[actionKey - 2]) {
          profiles = { [actionKey - 2]: state.profiles[actionKey - 2] }
        } 

        if (!!state.profiles[actionKey - 1]) {
          profiles = { ...profiles, [actionKey - 1]: state.profiles[actionKey - 1] }
        }
        profiles = { ...profiles, ...action.profiles }
      } else {
        profiles = { ...state.profiles, ...action.profiles };
      }
      return { ...state, profiles };

    default:
      return state
  }
};

export default backgroundCheck;

import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import moment from 'moment';
import {
  Box,
  Typography,
  ButtonGroup,
  Button,
  FormControl,
} from '@material-ui/core';

import history from '@app/history';
import { ChevronLeftIcon } from '@app/icons';
import { client as clientApi, folder } from '@app/api/client';
import TableList from '@components/lists/table-list';
import { TCELL_TYPES } from '@components/lists/table-list';
import OutlinedSearch from '@components/inputs/outlined-search';
import SelectSearchable from '@components/inputs/select-searchable';
import debugMode from '@utils/debugMode';
import {
  prepareToDoListData,
  prepareSort,
} from '@utils/prepare-data/prepareToDoListData';
import { useFirstRender } from '@hooks/useFirstRender';

import { NoData } from './noData';
import { useStyles } from '../styles';
import { getItems } from '../utils/toDoList';
import { typeComponent } from './typeComponent';
import { getRiskRatingValue } from './getRiskRatingValue';
import { PAGE_SIZE, SORTABLE_COLUMNS, BUTTONS_GROUP } from '../../constants';
import { outdatedChip } from '../../../../../../components/badges/outdated-chip';

const debug = debugMode.getIsDebugMode();

const MAX_COUNT = debug ? 3 : 5;

export default function Client({ action }) {
  const firstRender = useFirstRender();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [clients, setClients] = useState({ count: 0, items: [], pages: 0 });
  const [folders, setFolders] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [sortField, setSortField] = useState('nextReview');
  const [sortDirection, setSortDirection] = useState('ASC');
  const [activeFolder, setActiveFolder] = useState('');
  const [search, setSearch] = useState('');
  const [dateOfReview, setDateOfReview] = useState(6);
  const [buttonsGroup, setButtonsGroup] = useState(BUTTONS_GROUP);

  const changeDateReview = (value, index) => {
    const changed = buttonsGroup.map((el, i) => ({
      ...el,
      active: i === index,
    }));
    setButtonsGroup(changed);
    setDateOfReview(value);
    setPageNumber(0);
  };

  const { sort } = useSelector((state) => state.calendarSchedule);

  useEffect(() => {
    if (!firstRender) {
      const timeOutId = setTimeout(() => getClients(), 1000);
      return () => clearTimeout(timeOutId);
    }
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    getClients();
    // eslint-disable-next-line
  }, [sort, pageNumber, sortDirection, dateOfReview, activeFolder]);

  const params = {
    pageSize: PAGE_SIZE,
    pageNumber: pageNumber,
    toNextDateOfReview: moment.utc().add(dateOfReview, 'months').endOf('day'),
    clientType: sort === 'all' ? null : sort.toUpperCase(),
    sortBy: prepareSort(sortField, sort),
    folderId: !!activeFolder ? activeFolder : null,
    sortDirection: sortDirection,
  };
  const getClients = async () => {
    let clients;
    if (search.length > 1) {
      clients = (
        await clientApi.search({
          ...params,
          searchKeyword: search.trim() || null,
        })
      ).data;
    } else if (!search.length) {
      clients = (await clientApi.index(params)).data;
    } else {
      enqueueSnackbar('Search keyword should have at least 2 characters!', {
        variant: 'error',
      });
    }

    if (clients) {
      const prepareData = prepareToDoListData(clients.entries);
      setClients({
        items: prepareData,
        count: clients.count,
        pages: clients.pages,
      });
      getFolders(clients.entries.length);
    }
  };

  const getFolders = async (length) => {
    if (!!length && !folders.length) {
      setFolders([]);
      const allFolders = (await folder.index({ pageSize: 100, pageNumber: 0 }))
        .data;
      allFolders.entries.map((item) =>
        setFolders((prevState) => [
          ...prevState,
          { label: item.name, value: item.id },
        ])
      );
    }
  };

  const handleSelect = (field) => {
    setPageNumber(0);
    const newData = field ? field.target.value : { label: '', value: '' };
    setActiveFolder(newData);
  };

  const onSort = (value) => {
    setPageNumber(0);
    if (sortField !== value) {
      setSortField(value);
      setSortDirection('ASC');
    } else {
      setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
    }
  };
  const tableProps = action && {
    pagesCount: clients.pages,
    totalCount: clients.count,
    pageSize: PAGE_SIZE,
    page: pageNumber,
    sortableColumns: SORTABLE_COLUMNS[sort],
    onSort: onSort,
    sort: sortField,
    sortDirection: sortDirection,
  };

  const COLUMNS = [
    { field: 'name', headerName: 'Client Name', bold: true, cellType: TCELL_TYPES.TEXT_ICON, decoration: outdatedChip },
    {
      field: 'folderName',
      headerName: 'Folder',
      colProps: {
        width: '100px',
      },
    },
    {
      cellType: TCELL_TYPES.CUSTOM,
      field: 'riskRating',
      headerName: 'Risk Rating',
      custom: getRiskRatingValue,
    },
    { field: 'country', headerName: 'Country' },
    { field: 'review', headerName: 'Review (Last / Next)' },
    {
      cellType: TCELL_TYPES.CUSTOM,
      field: 'type',
      headerName: 'Type',
      custom: (item) => typeComponent(item, classes.styleBadgeRoot),
      colProps: {
        width: '5%',
      },
    },
  ];

  const headerTitleProps = action && {
    ml: -1,
    className: classes.back,
    onClick: () => history.goBack(),
  };
  const formattedData = useMemo(() => {
    return (
      clients?.items?.map((e) => {
        const item = { ...e };

        item.cellProps = {
          name: {
            style: {
              width: '300px',
            },
          },
          folderName: {
            style: {
              color: '#79AEB6',
            },
          },
        };

        return item;
      }) || []
    );
  }, [clients.items]);
  return (
    <Box display={'flex'} flexGrow={'1'} mt={'16px'} flexDirection={'column'}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={classes.header}
      >
        <Box display="flex" alignItems="center" {...headerTitleProps}>
          {action && <ChevronLeftIcon />}
          <Typography variant={action ? 'h3' : 'h4'}>Client Reviews</Typography>
        </Box>
        {action && (
          <>
            <Box ml={3}>
              <ButtonGroup
                fullWidth
                classes={{ root: classes.buttonGroupRoot }}
              >
                {buttonsGroup.map((el, i) => (
                  <Button
                    classes={{
                      root: clsx(classes.mounthButton, el.active && 'active'),
                    }}
                    key={`${el.label}-${i}`}
                    onClick={() => changeDateReview(el.value, i)}
                    disabled={el.active}
                  >
                    {el.label}
                  </Button>
                ))}
              </ButtonGroup>
            </Box>
            <Box display="flex" alignItems="center" ml={2} mr={3} width={350}>
              <Button
                size="small"
                className={classes.reset}
                onClick={handleSelect}
              >
                {'Reset Folders'}
              </Button>
              <FormControl variant="outlined" fullWidth>
                <SelectSearchable
                  value={activeFolder}
                  onChange={handleSelect}
                  options={folders}
                  label="All Folders"
                />
              </FormControl>
            </Box>
            <Box width={350} height={'40px'} display="flex" alignItems="center">
              <OutlinedSearch
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                label="Search"
              />
            </Box>
          </>
        )}
      </Box>
      {!!clients.items.length && (
        <>
          <Box>
            <TableList
              columns={COLUMNS}
              items={getItems(action, formattedData, debug)}
              onClick={(id, item) =>
                history.push(history.push(`/portfolio/view/${id}/risk-rating`))
              }
              maxHeight="calc(100vh - 320px)"
              onPageChange={(event, value) => setPageNumber(value - 1)}
              {...tableProps}
            />
          </Box>
          {clients.items.length > MAX_COUNT && !action && (
            <Box mt={2} mb={2}>
              <Button
                variant="outlined"
                size="small"
                onClick={() =>
                  history.push('/calendar-schedule/sсhedule/client-reviews')
                }
              >
                VIEW ALL
              </Button>
            </Box>
          )}
        </>
      )}
      {!clients.items.length && NoData(action, 'client', classes.noActions)}
    </Box>
  );
}

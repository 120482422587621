const initialState = {
  users: [],
};

const usersManagement = (state = initialState, action) => {

  switch (action.type) {
    case 'SET_USERS':
      return {
        ...state,
        users: action.users,
      };
    default:
      return state
  }
};

export default usersManagement;
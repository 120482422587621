import React, {useEffect, useMemo, useState} from 'react';
import {entitySettingsMode, getDashboardUrlByMode, getModeByUrlParam, getParticularUrl} from '../utils/modeUtil';
import {Box, Button} from '@material-ui/core';
import {useParams} from 'react-router-dom';
import { Route, Switch, Redirect } from 'react-router';
import { countriesISO } from '@services/country-service';
import history from '@app/history';
import {clientApi} from '@app/api';
import Form from './form/form';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import InnerHeader from '@components/layout/inner-header';
import BackButton from '@views/common/back-button';
import parseYupErrors from '@app/yup';
import Details from './form/details';
import _ from 'lodash';
import ExistsDialog from './form/dialogs/exists-dialog';
import {getIsInheritedItem} from '../utils/inheritance'
import {entityTypes} from '@utils/entityType';

const schema = yup.object().shape({
  name: yup.string().trim().required().max(100).label('Entity Name'),
  jurisdiction: yup.string().trim().required().label('Jurisdiction'),
});

const stepsCount = 2;

export default function Create() {
  const { enqueueSnackbar } = useSnackbar()

  const {tab, stepNumber, filterValue} = useParams()
  const [step, setStep] = useState((stepNumber && parseInt(stepNumber)) || 1); //eslint-disable-line
  const [data, setData] = useState({
    name: '',
    jurisdiction: '',
    clientType: 'PERSON',
    documentRules: []
  })

  const [errors, setErrors] = useState({})

  // Exists dialog
  const [existsDialogOpen, setExistsDialogOpen] = useState(false)
  const [conflictVersionId, setConflictVersionId] = useState(null)

  const mode = useMemo(() => {
    return getModeByUrlParam(tab)
  }, [tab])

  const baseRoute = useMemo(() => {
    return getParticularUrl(mode, filterValue, 'create')
  }, [mode, filterValue])

  useEffect(() => {
    const fillFromUrl = {}
    if (mode === entitySettingsMode.TYPE) {
      fillFromUrl.clientType = filterValue === 'PERSON'? 'PERSON': 'LEGAL_ENTITY'
      if (fillFromUrl.clientType === 'LEGAL_ENTITY') {
        fillFromUrl.legalEntityType = filterValue
      }
    } else {
      fillFromUrl.jurisdiction = filterValue
    }
    setData({
      ...data,
      ...fillFromUrl
    })
  }, []) // eslint-disable-line

  const onNext = async () => {
    setErrors({});
    try {
      await schema.validate(data, { abortEarly: false })

      const res = await clientApi.clientRulebook.resolve({
        jurisdiction: data.jurisdiction,
        type: data.clientType,
        legalEntityType:
          data.clientType !== 'PERSON'
            ? data.legalEntityType
            : null
      })

      if ( res.data.ownedBy === 'USER' ) {
        setExistsDialogOpen(true)
        setConflictVersionId(res.data.rulebookVersionId)
        return
      }

      setData({
        ...res.data,
        jurisdiction: data.jurisdiction,
        clientType: data.clientType,
        legalEntityType: data.legalEntityType,
        name: data.name,
        basedOnRulebookVersionId: res.data.rulebookVersionId
      })

      history.push(`${baseRoute}/2/fields`)
      setStep(2)
    } catch (err) {
      const errs = parseYupErrors(err)
      setErrors(errs)
      console.log('Validation errors', errs)
    }
  }

  // const onBack = () => {
  //   if (step === 2) {
  //     history.push(`${baseRoute}/1`)
  //     setStep(1)
  //   } else {
  //     history.push(
  //       getDashboardUrlByMode(mode, filterValue)
  //     )
  //   }
  // }

  const onSave = async () => {
    const reqData = {
      ...data,
      jurisdiction: data.jurisdiction !== 'UNDEFINED'
        ? data.jurisdiction
        : tab === 'entity-by-jurisdiction'
          ? filterValue: null,
      basedOnRulebookVersionId: data.rulebookVersionId,
      clientType: data.clientType,
      documentRules: data.documentRules?.filter( c => {
        return !c.definedByRulebookVersionId || (c.definedByRulebookVersionId === data.rulebookVersionId && data.ownedBy !== 'SYSTEM')
      }).map(c => _.omit(c, ['ref'])),
      inputFields: data.inputFields
        .filter(inputField => !getIsInheritedItem(data, inputField))
        .map(inputField => {
          const newInputField = _.omit(inputField, ['id', 'definedByRulebookVersionId'])
          if (inputField.definition.type === 'LIST') {
            delete newInputField.definition.size
          }
          if (inputField.definition.type === 'COUNTRY_LIST') {
            delete newInputField.definition.options
          }
          return newInputField
        })
    }

    await clientApi.clientRulebook.store(
      reqData
    )
    enqueueSnackbar(`Rulebook "${data.name}" was successfully created!`, { variant: 'success' })
    history.push(
      getDashboardUrlByMode(mode, filterValue)
    )
  }

  const title = useMemo(() => {
    if (mode === entitySettingsMode.JURISDICTION) {
      const entityType = data?.clientType === 'PERSON'
        ? 'PERSON'
        : data?.legalEntityType
      const clientTypeTitle = entityTypes.find(et => et.name === entityType)?.label
      return `Create ${clientTypeTitle} in ${countriesISO?.find(country => country.code === filterValue)?.name}`
    } else {
      return `Add Custom ${filterValue}`
    }
  }, [mode, filterValue, data?.clientType, data?.legalEntityType])

  const onView = () => {
    history.push(
      getParticularUrl(mode, filterValue, 'view', conflictVersionId, null, 'fields')
    )
  }
  const onEdit = () => {
    history.push(
      getParticularUrl(mode, filterValue, 'edit', conflictVersionId, null, 'fields')
    )
  }

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      <InnerHeader
        title={title}
        stepper={{
          currentStep: step,
          stepsCount
        }}
        buttons={
          <BackButton
            wrapped
          />
        }
      />
      <Box display={'flex'} mt={2} style={{overflow: 'auto', maxHeight: 'calc(100vh - 300px)'}}>
        <Box display={'flex'} style={{width: '100%'}}>
          <Switch>
            <Route path={'/settings/:tab/dashboard/:filterValue/:formType(create)/:stepNumber(1)'}>
              <Details
                mode={mode}
                data={data}
                setData={setData}
                errors={errors}
              />
            </Route>
            <Route path={'/settings/:tab/dashboard/:filterValue/:formType(create)/:stepNumber(2)/:formTab?'}>
              <Form
                mode={mode}
                data={data}
                setData={setData}
              />
            </Route>
            <Redirect to={`${baseRoute}/${step}`} />
          </Switch>
        </Box>
      </Box>
      <Box display={'flex'} justifyContent={'flex-end'} mt={5}>
        { step === 1 && (
          <Button
            style={{ width: '200px' }}
            variant={'contained'}
            onClick={onNext}
          >
            NEXT
          </Button>
        )}
        { step === 2 && (
          <>
            <Button
              style={{ width: '200px' }}
              variant={'contained'}
              onClick={onSave}
            >
              CREATE
            </Button>
          </>
        )}
      </Box>
      <ExistsDialog
        data={data}
        onClose={() => {
          setExistsDialogOpen(false)
        }}
        open={existsDialogOpen}
        onView={onView}
        onEdit={onEdit}
      />
    </Box>
  )
}
export default function splitArrayToTwo(arr) {
  if (!arr.length) {
    return [arr, []]
  }
  const middlePoint = Math.ceil(arr.length / 2)

  const arr1 = arr.slice(0, middlePoint);
  const arr2 = arr.slice(middlePoint);

  return [arr1, arr2]
}

export function splitFieldsArrayToTwo(arr) {
  if (!arr.length) {
    return [arr, []]
  }

  // Multiline fields count as 2
  const countArrSize = (array) => array.length + array.filter(field => field.multiline).length;

  let arrSize = countArrSize(arr);
  const middlePoint = Math.ceil(arrSize / 2)

  const arr1 = []
  const arr2 = [...arr]
  while (countArrSize(arr1) < middlePoint) {
    const field = arr2.splice(0, 1);
    arr1.push(field[0])
  }

  return [arr1, arr2]
}
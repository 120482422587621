export const prepareDataForPaginations = (profiles, length) => {
  let key =  0;

  let buffer = {};
  let items = profiles;

  while (!!items.length) {
    buffer = {
      ...buffer,
      [key]: items.slice(0, length),
    }

    items.splice(0, length);
    key++;
  }
  return buffer;
}

import { globalSearch, paginations, scroll } from '@app/store/actions';
import { clientApi } from '@app/api';
import store from '@app/store';
import generalActions from '@store/actions/general';

const FOLDERS = 'Folders';

const DOCUMENTS = 'Documents';

const CLIENT_TYPE = {
  People: 'PERSON',
  'Legal Entities': 'LEGAL_ENTITY',
  Clients: null,
};

export const preparePayloadData = ({ searchType, search, pageNumber }) => {
  const defaultData = {
    pageNumber,
    sortDirection: 'ASC',
    pageSize: 10,
  };
  switch (searchType.value) {
    case FOLDERS:
      return { ...defaultData, folderName: search, sortBy: 'name' };
    case DOCUMENTS:
      return { ...defaultData, searchKeyword: search, sortBy: 'documentName' };
    default:
      return {
        ...defaultData,
        clientType: CLIENT_TYPE[searchType.value],
        searchKeyword: search,
        sortBy: 'name',
      };
  }
};

const getCategories = () => {
  return clientApi.clientDocumentCategory.index();
};

const getTypes = async (data) => {
  const typeArray = [...new Set(data.map((item) => item.typeId))];
  const types = await Promise.all(
    typeArray.map(
      async (item) => (await clientApi.clientDocumentType.show(item)).data
    )
  );
  return types;
};

const prepareData = async (data, type, categories) => {
  let clients;
  let types;
  if (type === DOCUMENTS) {
    types = await getTypes(data);
    clients = await Promise.all(
      data.map((el) => clientApi.client.show(el.associations[0]?.clientId))
    );
  }
  return data.map((el, index) => {
    let category, documentType, clientName;
    if (type === DOCUMENTS) {
      category = categories.find((e) => e.id === el.categoryId);
      documentType = types.find((type) => type.id === el.typeId);
      clientName =
        clients[index]?.data?.clientType === 'PERSON'
          ? `${clients[index]?.data?.firstName} ${clients[index]?.data?.lastName}`
          : clients[index]?.data?.legalEntityName;
    }
    return {
      ...el,
      category: category?.name,
      documentType: documentType?.name,
      clientName: clientName ? clientName : undefined,
      folderName:
        type === DOCUMENTS ? clients[index]?.data.folderName : el.folderName,
      name:
        el.name ||
        el.documentName ||
        `${el.firstName || ''} ${el.lastName || ''}`,
    };
  });
};

const getByKeyword = async (payload, type) => {
  let data;
  if (payload.searchKeyword?.length < 2) {
    store.dispatch(
      generalActions.setSnackbar(
        'Search keyword should have at least 2 characters!',
        { variant: 'error' }
      )
    );
  } else {
    data =
      type === DOCUMENTS
        ? (await clientApi.clientDocument.search(payload)).data
        : (await clientApi.client.search(payload)).data;
  }
  return data;
};

export const getSearches = async (requestData, initial) => {
  store.dispatch(scroll.setIsRequestSent(true));
  const { searchType, search, pageNumber } = requestData;

  const categories =
    searchType.value === DOCUMENTS &&
    search.length > 1 &&
    (await getCategories());

  await store.dispatch(globalSearch.setSearch(search));
  await store.dispatch(globalSearch.setSearchType(searchType));

  const payload = preparePayloadData(requestData);
  console.log(searchType);
  const data =
    searchType.value === FOLDERS
      ? (await clientApi.folder.index(payload)).data
      : await getByKeyword(payload, searchType.value);
  if (data) {
    const prepared = await prepareData(
      data.entries,
      searchType.value,
      categories?.data?.entries
    );

    let dataForSet = initial
      ? { [pageNumber]: prepared }
      : preparePagination({ [pageNumber]: prepared }, pageNumber);

    await store.dispatch(
      globalSearch.setCounters({
        pagesCount: data.pages === 1 ? 0 : data.pages,
        totalCount: data.count,
      })
    );
    await store.dispatch(globalSearch.setData({ ...dataForSet }));

    store.dispatch(paginations.setCurrentItemsLength(prepared.length));
    store.dispatch(scroll.setIsRequestSent(false));

    localStorage.setItem('globalSearch', search);
    localStorage.setItem('globalSearchType', JSON.stringify(searchType));
  }
};

const preparePagination = (dataForSet, pageNumber) => {
  const globalSearchData = store.getState()?.globalSearch?.data;
  const dataKeys = Object.keys(globalSearchData);
  if (!!dataKeys.length && dataKeys.length < 3) {
    return (dataForSet = { ...globalSearchData, ...dataForSet });
  }

  if (!!dataKeys.length && dataKeys.length === 3) {
    const key = pageNumber < dataKeys[0] ? dataKeys[2] : dataKeys[0];
    delete globalSearchData[key];
    return (dataForSet = { ...globalSearchData, ...dataForSet });
  }

  return dataForSet;
};

import React, { useCallback } from  'react';
import { Box, Typography } from '@material-ui/core';

import { CREDITS, REQUEST_REQUIRE_FIELDS, PERSON, CONFIRM_FIELDS, FUZZINESS_VALUE } from '../constants';
import { capitalize } from '@utils/textUtil';
import { getCountryName } from '@services/country-service';
import { useSelector } from 'react-redux';
import { useStyles } from './styles';
import clsx from 'clsx';

export default function ModalContent() {
  const classes = useStyles();

  const { searchModel } = useSelector(state => state.backgroundSearch);

  const handleValue = useCallback((key, model) => {
    if (key === 'country') {
      return getCountryName(model[key]);
    } else if (key === 'fuzziness') {
      return FUZZINESS_VALUE[model[key]];
    } else {
      return model[key];
    }
  }, [])

  const renderAdvancedData = useCallback(() => {
    const buffer = [];
    for (const key in searchModel) {
      if (REQUEST_REQUIRE_FIELDS[searchModel.searchProvider][searchModel.type].includes(key) && (searchModel[key] === 0 || !!searchModel[key])) {
        const field = CONFIRM_FIELDS[key] || capitalize(key);
        const value = 
          <React.Fragment>
            {field}: {<b>{handleValue(key, searchModel)}</b>}
          </React.Fragment>;
        buffer.push(value);
      }
    }

    return !!buffer.length ? (
      <Box display="flex" alignItems="center" justifyContent="center">
        {buffer.map((el, i) => (
          <Typography className={clsx(classes.modalContentText, classes.marginRight)} key={i}>
            {el}<b>{i === buffer?.length - 1 ? '' : ','}</b>
          </Typography>
        ))}
      </Box>
    ) : null;
  }, [searchModel, classes, handleValue])

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Typography className={classes.modalContentText}>
        You are about to use <b>1 {CREDITS[searchModel.searchProvider].toLowerCase()} credit</b> for {searchModel.type === PERSON ? searchModel.type.toLowerCase() : 'legal entity'}
      </Typography>
      <Box my={1}>
        <Typography className={classes.modalContentSubtitle}>
          {searchModel.name}
        </Typography>
      </Box>
      {renderAdvancedData()}
      <Box mt={4} mb={2}>
        <Typography className={classes.modalContentText}>
          Do you want to proceed?
        </Typography>
      </Box>
    </Box>
  )
}
import React from 'react';
import clsx from 'clsx';
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';

import history from '@app/history';
import { ChevronLeftIcon } from '@app/icons';

import ButtonWithIcon from '@components/buttons/button-with-icon';
import InnerHeader from '@components/layout/inner-header';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderRadius: 12,
    backgroundColor: '#F8F8F8',
    width: '100%',
    outline: 'none',
  },
  btnWrapper: {
    marginBottom: 10,
  },
  typeButton: {
    background: 'white',
    margin: 2,
    color: '#232323',
    padding: '10px 0px',
    width: 240,
    textTransform: 'none',
    fontFamily: 'Roboto',
    fontSize: 14,
    '&:hover': {
      background: 'white'
    },
  },
  selectedButton: {
    border: '2px solid #7A5B44',
    margin: 0,
  },
}));

export default function ImportCSVFileContent(props) {
  const { file, type, setType } = props;
  const classes = useStyles();

  const handleNext = () => {
    history.push('/portfolio/import/upload-file')
  }

  return (
    <Box height="100%" px={6}>
      <Box>
        <InnerHeader
          title="File Content"
          buttons={
            <React.Fragment>
              <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={() => { history.goBack() }}>
                {'Back'}
              </ButtonWithIcon>
            </React.Fragment>
          }
        />
      </Box>
      <Box height="calc(100% - 98px)" style={{ overflowY: 'auto' }}>
        <Box mt={7} py={7} className={classes.wrapper}>
          <Box>
            <Typography variant="h4" align="center">Select the type of content in your file</Typography>
            <Typography variant="h4" align="center">{file?.name}</Typography>
          </Box>
          <Box mt={7}>
            <Grid container alignItems="center" justify="center" className={classes.btnWrapper}>
              <Button
                className={clsx(classes.typeButton, type === 'person' && classes.selectedButton)}
                onClick={() => setType('person')}
                variant="contained"
              >
                {'People'}
              </Button>
            </Grid>
            <Grid container alignItems="center" justify="center" className={classes.btnWrapper}>
              <Button
                className={clsx(classes.typeButton, type === 'company' && classes.selectedButton)}
                onClick={() => setType('company')}
                variant="contained"
              >
                {'Legal Entities'}
              </Button>
            </Grid>
            <Grid container alignItems="center" justify="center" className={classes.btnWrapper}>
              <Button
                className={clsx(classes.typeButton, type === 'both' && classes.selectedButton)}
                onClick={() => setType('both')}
                variant="contained"
              >
                {'People and Legal Entities'}
              </Button>
            </Grid>
          </Box>
        </Box>

        <Box mt={4} mb={4}>
          <Grid container alignItems="center" justify="center">
            <Button
              variant="contained"
              onClick={handleNext}
              size="large"
              disabled={!type}
            >
              {'Next'}
            </Button>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

import React from 'react';
import CustomTooltip from '@components/controls/tooltip';
import {Box} from '@material-ui/core';
import {RateBadIcon, RateWarningIcon, RateGoodIcon, RateExemptedIcon, RateCloselyMonitoringIcon, RateNotsetIconNew} from '@app/icons'
import getRiskRatingLabel from '@utils/riskRatingLabel';

/*
Pass risk rating values to obtain flag icon component,
then use as { React.createElement(getRiskRatingIcon(riskRating)) }
*/

export default function RiskRatingIcon({risk, withTooltip = false, placement = 'top', iconProps, ...other}) {

  const tooltip = withTooltip? getRiskRatingLabel(risk, 'Not Set'): null

  return (
    <CustomTooltip title={tooltip} placement={placement}>
      <Box component={'span'} display={'flex'} alignItems={'center'} {...other}>
        { risk === 'closely-monitoring' && <RateCloselyMonitoringIcon {...iconProps}/>}
        { risk === 'exempted' && <RateExemptedIcon {...iconProps}/>}
        { risk === 'high' && <RateBadIcon {...iconProps}/>}
        { risk === 'medium' && <RateWarningIcon {...iconProps}/>}
        { risk === 'low' && <RateGoodIcon {...iconProps}/>}
        { !risk && <RateNotsetIconNew {...iconProps}/>}
      </Box>
    </CustomTooltip>
  )
}
import React, { useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { LoaderOtherIcon } from '@app/icons';
import { useSelector } from 'react-redux';

export const useStyles = makeStyles((theme) => ({
  loaderWrapper: {
    height: '100%',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    opacity: 0.5,
    background: theme.palette.text.BL1,
    zIndex: 9999,
  },
  loader: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    height: '50px',
    width: '50px',
    animation: '$spinner 1s linear infinite',
  },
  '@keyframes spinner': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  hide: {
    display: 'none',
  }
}));

export default function Loader(){
  const classes = useStyles();
  const { loader, moreLoader } = useSelector(store => store.loader);

  const isLoader = useMemo(() => moreLoader || loader, [ moreLoader, loader]);

  return (
    <div className={clsx(classes.loaderWrapper, {[classes.hide]: !isLoader})}>
      <LoaderOtherIcon className={classes.loader}/>
    </div>
  );
}
const getIsDebugMode = () => {
  return !!parseInt(localStorage.getItem('debug') || 0)
}

const setDebugMode = isDebug => {
  localStorage.setItem('debug', String(isDebug? 1: 0))
}

const getSide = () => {
  return localStorage.getItem('side') || 'client'
}

const setSide = value => {
  localStorage.setItem('side', value)
}

const getMaintenance = () => {
  return !!parseInt(localStorage.getItem('fake-maintenance') || 0)
}

const setMaintenance = value => {
  localStorage.setItem('fake-maintenance', String(value ? 1 : 0))
}

const debugMode = {
  getIsDebugMode,
  setDebugMode,
  getSide,
  setSide,
  getMaintenance,
  setMaintenance,
}

export default debugMode
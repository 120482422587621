import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Box, Slide, IconButton } from '@material-ui/core';
import CustomButton from '@views/common/button';
import CustomChip from '@components/controls/custom-chip';
import CustomTooltip from '@components/controls/tooltip';
import { makeStyles } from '@material-ui/core/styles';
import history from '@app/history';
import { ChevronLeftIcon } from '@app/icons';

import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';


import { useAccordion } from '@utils/useAccordion';
import UserInfoCardNotes from './notes';
import UserInfoCardVersions from './versions';
import UserInfoCardMonitoring from './monitoring';
import UserInfoCardProfileInfo from './profile-info';
import UserInfoCardBgCheckRisk from './bg-check-risk';
import UserInfoCardWhitelisted from './whitelisted';

const ACCORDIONS = {
  showNotes: 'showNotes',
  showHistory: 'showHistory',
}

export const useStyles = makeStyles((theme) => ({
  userCardInfoRoot: {
    overflowY: 'auto',
    minWidth: '310px',
    maxWidth: '310px',
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    backgroundColor: theme.palette.common.white,
    height: '100%',
  },
  userCardInfo: {
    padding: '40px 48px',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 2*40px)'
  },
  userCardInfoChips: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& .MuiChip-root': {
      marginRight: 5
    }
  },
  customButtonModalWidth: {
    width: '180px',
  },
  customButtonWidth: {
    width: 232,
  },
  backBtn: {
    position: 'absolute',
    left: -36,
    padding: 0,
    top: -4,
  }
}));

export default function UserInfoCard({
  handleChangeRisk,
  updateExisting,
  linkClient,
  pageNumber,
  setCurrentTab,
  isSaveToPortfolio,
  getCurrentProfile
}) {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [searchId] = useState(search.get('searchId'));
  const [profileId] = useState(search.get('profileId'));

  const { profilesVersionId } = useSelector(store => store.searchHistory);
  const { selectedProfile, currentSearch } = useSelector(store => store.backgroundCheck);
  const [accordion, setAccordion] = useAccordion({ [ACCORDIONS.showHistory]: !!profilesVersionId });

  const classes = useStyles();

  const [userNotes, setUserNotes] = useState(selectedProfile.notes || []);

  useEffect(() => {
    setUserNotes(selectedProfile.notes || []);
    if (selectedProfile.notes && userNotes.length <= selectedProfile.notes.length) {
      !accordion[ACCORDIONS.showHistory] && setAccordion(ACCORDIONS.showNotes, true);
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProfile.notes])

  const handleSaveToPortfolio = useCallback(() => {
    history.push(`/portfolio/create-${currentSearch.type.toLowerCase()}/${searchId}/${profileId}`)
  }, [currentSearch, searchId, profileId])

  if(!selectedProfile.profileId) return null;

  const isSavedClient = !!selectedProfile.clientId;

  return (
    <Slide direction="right" in={!!selectedProfile.profileId} mountOnEnter unmountOnExit>
      <Box className={classes.userCardInfoRoot}>
        <Box className={classes.userCardInfo}>
          <Box position="relative">
            <IconButton onClick={() => history.goBack()} className={classes.backBtn}>
              <ChevronLeftIcon style={{ width: 36, height: 36 }} />
            </IconButton>
            <CustomTooltip placement="top" title={isSavedClient ? 'My saved entity' : 'Search profile'}>
              <Typography variant="h3" component="span">
                {selectedProfile.name}
              </Typography>
            </CustomTooltip>
          </Box>

          <Box mt={1} mb={3} className={classes.userCardInfoChips}>
            {isSavedClient ?
              <CustomChip label="IN PORTFOLIO" background="#7A5B44" labelColor="#FFF" />
              :
              <CustomTooltip width={130} placement="top" title="This search profile is not saved in your Portfolio. You can save it or attach it to one of your existing Portfolio profiles.">
                <Box>
                  <CustomChip label="LOOSE" />
                </Box>
              </CustomTooltip>
            }
            {selectedProfile.tags && selectedProfile.tags.map(tag => <CustomChip label={tag.toUpperCase()} key={`custom-chip-${tag}`} />)}
          </Box>
          {isSavedClient && <UserInfoCardProfileInfo />}
          <Box mt={2}>
            <UserInfoCardBgCheckRisk handleChangeRisk={handleChangeRisk} />
          </Box>

          {isSavedClient &&
            <Box mt={2}>
              <UserInfoCardMonitoring
                searchId={searchId}
              />
            </Box>
          }

          {!isSavedClient &&
            <Box mt={2}>
              <UserInfoCardWhitelisted
                getCurrentProfile={getCurrentProfile}
              />
            </Box>
          }

          <UserInfoCardNotes
            accordion={accordion}
            setAccordion={setAccordion}
            accordionName={ACCORDIONS.showNotes}
          />
          <UserInfoCardVersions
            pageNumber={pageNumber}
            setCurrentTab={setCurrentTab}
            accordion={accordion}
            setAccordion={setAccordion}
            accordionName={ACCORDIONS.showHistory}
          />

          <Box pt={1} display="flex" flexDirection="column" alignItems="center" marginTop="auto">
            {linkClient &&
              <Box>
                {isSaveToPortfolio ? (
                  <CustomButton
                    action={() => handleSaveToPortfolio()}
                    label="SAVE IN PORTFOLIO"
                    customClasses="customButtonStandard"
                    propsClassses={classes.customButtonWidth}
                  />
                ) : (
                  <CustomButton
                    action={linkClient}
                    label="SELECT PROFILE"
                    customClasses="customButtonStandard"
                    propsClassses={classes.customButtonWidth}
                  />
                )}
              </Box>
            }
            {!linkClient &&
              <React.Fragment>
                <CustomButton
                  action={() => isSavedClient ? history.push(`/portfolio/view/${selectedProfile.clientId}`) : handleSaveToPortfolio()}
                  label={isSavedClient ? 'VIEW IN PORTFOLIO' : 'SAVE IN PORTFOLIO'}
                  customClasses="customButtonStandard"
                  propsClassses={classes.customButtonWidth}
                />
                <CustomButton
                  action={() => updateExisting()}
                  label="ATTACH TO EXISTING"
                  customClasses="customButtonBordered"
                  propsClassses={classes.customButtonWidth}
                />
              </React.Fragment>
            }
          </Box>
        </Box>
      </Box>
    </Slide>
  )
}

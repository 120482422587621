import React, { useState } from 'react';
import { Box, MenuItem, makeStyles } from '@material-ui/core';
import PageLayout from '@components/layout/page-layout';
import InnerHeader from '@components/layout/inner-header';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import MenuWithContext from '@components/dropdowns/menu-with-context';
import { AddActive2Icon } from '@app/icons';

import history from '@app/history';
import { Route, Switch, Redirect } from 'react-router';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Invoices from './invoices';
import Payments from './payments';
import PromoCodes from './promo-codes';
import NewInvoices from './invoices/new-invoices';
import NewPayment from './payments/new-payment';
import PromoCodeForm from './promo-codes/promo-code-form';
import SearchCreditPacks from './search-credit-packs';
import SearchCreditPackForm from './search-credit-packs/search-credit-form';
import SearchCreditPacksRequests from './search-credit-packs-requests';

export const useStyles = makeStyles((theme) => ({
  propsTabRoot: {
    maxWidth: '300px'
  }
}));

const tabs = [
  {
    id: 'invoice',
    label: 'Invoices',
  },
  {
    id: 'payment',
    label: 'Payments',
  },
  {
    id: 'promo-code',
    label: 'Promo Codes',
  },
  {
    id: 'credit-pack',
    label: 'Search Credit Packs',
  },
  {
    id: 'credit-packs-requests',
    label: 'Search Credit Packs Requests',
  },
]

const context = ['promo-code', 'payment'];

const paymentMenu = (
  <MenuItem onClick={() => {}}>
    Download
  </MenuItem>
)

export default function BillingManagement(props) {
  const { tab, action } = useParams();
  const classes = useStyles();

  const { userIsAdmin, side } = useSelector(state => state.auth);

  const [tabID, setTabID] = useState(tab || 'invoice');
  const [openModal, setOpenModal] = useState(null);
  const [billingData, setBillingData] = useState([]);

  const onTabChange = (index) => {
    const tabID = tabs[index].id;
    setTabID(tabID);
    history.push(`/billing-management/${tabID}`)
  }

  const handleLabel = () => {
    const str = tabID.replace(/-/, ' ');
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

  const handleMenuActions = (type) => {
    const checkedItems = billingData.filter(el => el.isChecked);
    setOpenModal({
      name: checkedItems.map(el => el.name).join(', '),
      type,
      prefix: 'code(s)'
    })
  }

  const promoCodeMenu = (
    <React.Fragment>
      <MenuItem onClick={() => handleMenuActions('delete')}>
        Delete
      </MenuItem>
      <MenuItem onClick={() => handleMenuActions('block')}>
        Block
      </MenuItem>
    </React.Fragment>
  )

  const menu = {
    'promo-code': promoCodeMenu,
    'payment': paymentMenu,
  }

  return (
    <PageLayout>
      <Box mx={6} height="100%">
        <InnerHeader
          onTabChange={onTabChange}
          ind={tabs.findIndex(tab => tab.id === tabID)}
          tabs={(userIsAdmin && side === 'client') ? [tabs[0]]  : tabs}
          buttons={(!action && (side === 'admin' || tabID !== 'invoice') && tabID !== 'credit-packs-requests') && (
            <React.Fragment>
              <ButtonWithIcon
                startIcon={<AddActive2Icon />}
                onClick={() => history.push(`/billing-management/${tabID}/new-${tabID}`)}
              >
                New {handleLabel()}
              </ButtonWithIcon>
              {context.includes(tabID) && (
                <MenuWithContext title="More Actions">
                  {menu[tabID]}
                </MenuWithContext>
              )}
            </React.Fragment>
          )}
          propsTabRoot={classes.propsTabRoot}
        />
        <Box display={'flex'} flexDirection={'column'} height={'calc(100% - 100px)'}>
          <Switch>
            <Route exact path="/billing-management/invoice">
              <Invoices />
            </Route>
            <Route path="/billing-management/invoice/new-invoice">
              <NewInvoices />
            </Route>
            <Route exact path="/billing-management/payment">
              <Payments />
            </Route>
            <Route exact path="/billing-management/payment/new-payment">
              <NewPayment />
            </Route>
            <Route exact path="/billing-management/promo-code">
              <PromoCodes
                baseRoute="/billing-management/promo-code"
                tabID={tabID}
                openModal={openModal}
                setOpenModal={setOpenModal}
                setBillingData={setBillingData}
              />
            </Route>
            <Route exact path="/billing-management/promo-code/new-promo-code">
              <PromoCodeForm />
            </Route>
            <Route exact path="/billing-management/promo-code/edit-promo-code">
              <PromoCodeForm />
            </Route>
            <Route exact path="/billing-management/credit-pack">
              <SearchCreditPacks 
                baseRoute="/billing-management/credit-pack"
                tabID={tabID}
              />
            </Route>
            <Route exact path="/billing-management/credit-pack/new-credit-pack">
              <SearchCreditPackForm />
            </Route>
            <Route exact path="/billing-management/credit-pack/edit-credit-pack">
              <SearchCreditPackForm />
            </Route>
            <Route exact path="/billing-management/credit-packs-requests">
              <SearchCreditPacksRequests />
            </Route>

            <Redirect to={`/billing-management/${tabID}`} />
          </Switch>
        </Box>
      </Box>
    </PageLayout>
  )
}
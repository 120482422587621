import React, {useMemo} from 'react';
import { Modal } from '@components/modals';


export default function CategoryTypeDeleteDialog({ typeName, open, onClose, onConfirm }) {

  const title = 'Delete Document'

  const description = useMemo(() => `Are you sure you want to delete document "${typeName}"?\n This will remove "${typeName}" from all entities in your account.`, [typeName])

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title={title}
        mainText={description}
        actions={
          [
            {
              type: 'main',
              label: 'NO',
              action: onClose,
              style: { width: 160 },
            },
            {
              type: 'secondary',
              label: 'YES',
              action: onConfirm,
              style: { width: 160 },
            },
          ]
        }
        actionsDirection="row"
      />
    </React.Fragment>
  );
}
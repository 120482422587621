import React, { useState, useCallback } from 'react';
import { makeStyles, Select, MenuItem, IconButton } from '@material-ui/core';
import { DropdownDownIcon, DropdownUpIcon, DropdownDisabledOtherIcon } from '@app/icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  selectWhitelistRoot: {
    fontWeight: 'normal',
    fontStyle: 'normal',
    '&.MuiSelect-select': {
      paddingRight: 0,
    },
    // '&:focus': {
    //   backgroundColor: theme.palette.common.white,
    // },
  },
  downIconRoot: {
    fontSize: '16px',
  },
  selectTextSmall: {
    fontSize: '12px',
    lineHeight: '20px',
    color: theme.palette.text.BL1,
  },
  selectButton: {
    padding: 5,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    }
  },
  menuItemGutters: {
    padding: 15,
  },
  disabled: {
    color: theme.palette.text.GY3,
  },
}));

export default function SelectOutlined({ value, handleChange, options, propClasses, anchorProps, disabled, variant, className, menuWrapperStyle, renderMenu }) {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  const handleClick = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    handleChange(e);
  }, [handleChange])

  const handleOpen = useCallback((bool) => {
    !disabled && setIsOpen(bool);
  }, [disabled, setIsOpen])

  const renderIconComponent = useCallback(() => {
    return (
      <IconButton classes={{ root: classes.selectButton }} onClick={() => handleOpen(!isOpen)}>
        {isOpen ? (
          <DropdownUpIcon classes={{ root: classes.downIconRoot }} />
        ) : (
          disabled
            ? <DropdownDisabledOtherIcon classes={{ root: classes.downIconRoot }}  />
            : <DropdownDownIcon classes={{ root: classes.downIconRoot }} />
        )}
      </IconButton>
    )
  }, [classes, isOpen, disabled, handleOpen])

  const renderSelectMenu = useCallback(() => {
    return (
      options.map((el, ind) => (
        <MenuItem
          classes={{ gutters: classes.menuItemGutters }}
          key={`MenuItem-outlined-select${ind}-${el}`}
          value={el}
        >
          {el}
        </MenuItem>
      ))
    )
  }, [options, classes])

  return (
    <Select
      open={isOpen}
      onOpen={() => handleOpen(true)}
      onClose={() => handleOpen(false)}
      value={value}
      renderValue={val => val}
      onClick={handleClick}
      classes={{
        root: clsx(propClasses || classes.selectTextSmall, classes.selectWhitelistRoot, disabled && classes.disabled),
      }}
      variant={variant}
      IconComponent={() => renderIconComponent()}
      MenuProps={{
        anchorOrigin: anchorProps || {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
        PopoverClasses: { paper: menuWrapperStyle }
      }}
      className={className}
    >
      {renderMenu ? renderMenu() : renderSelectMenu()}
    </Select>
  )
}

import React, { useEffect } from 'react';
import {Box, Popover, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {IconButton} from '@material-ui/core';
import CustomTooltip from '@components/controls/tooltip';
import clsx from 'clsx';

import { FilterIcon, FilterInactiveIcon, FilterDisabledIcon, DropdownDownIcon, DropdownUpIcon, DropdownDownWhiteIcon, DropdownUpWhiteIcon } from '@app/icons';
import ButtonWithIcon from '@components/buttons/button-with-icon';

const useStyles = makeStyles((theme) => ({
  popover: {
    padding: '35px',
    boxShadow: '0px 9px 26px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    borderRadius: '12px',
    whiteSpace: 'nowrap'
  },
  buttonActive: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  },
  buttonLabel: {
    backgroundColor: theme.palette.background.GY2,
    padding: '10px 15px'
  }
}));

export default function FilterWithContext(props) {

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!!props.closeTrigger) {
      handleClose();
    }
  }, [props.closeTrigger])

  const open = Boolean(anchorEl);

  return (
    <Box>
      <CustomTooltip title="Filter" placement="top">
        <span>
          {props.label ? (
            <ButtonWithIcon
              endIcon={open
                ? !!props.filterActive ? <DropdownUpWhiteIcon /> : <DropdownUpIcon />
                : !!props.filterActive ? <DropdownDownWhiteIcon /> : <DropdownDownIcon />
              }
              onClick={handleClick}
              className={clsx(classes.buttonLabel, props.filterActive)}
              disabled={props.disabled}
            >
              <Typography variant="h5" style={{ color: !!props.filterActive && '#fff' }}>{props.label}</Typography>
            </ButtonWithIcon>
          ) : (
            <IconButton
              aria-label="filter-toggle"
              onClick={handleClick}
              className={clsx(open && classes.buttonActive)}
              disabled={props.disabled}
            >
              {props.disabled
                ? <FilterDisabledIcon />
                : props.isActive
                  ? <FilterIcon />
                  : <FilterInactiveIcon />
              }
            </IconButton>
          )}
        </span>
      </CustomTooltip>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: props.align || 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: props.align || 'right'
        }}
        classes={{ paper: classes.popover }}
        PaperProps={props.PaperProps}
      >
        {props.children}
      </Popover>
    </Box>
  )
}
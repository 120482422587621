import { withStyles } from '@material-ui/core/styles';
import {Button} from '@material-ui/core';

const LinkButton = withStyles({
  root: {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '28px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.75px',
    textTransform: 'uppercase',
    color: '#7A5B44',
    '&:hover': {
      backgroundColor: 'inherit'
    }
  }
})(Button)

export default LinkButton
import React, { useMemo } from 'react';

import { Modal } from '@components/modals';
import history from '@app/history';

export default function MonitoringSuccess(props) {
  const { open, onClose, count, folderIdsExist } = props;

  const onPortfolio = () => {
    onClose();
    history.push('/portfolio');
  }

  const onMonitoring = () => {
    onClose();
    history.push('/ongoing-monitoring');
  }

  const onOrganize = () => {
    onClose();
    history.push('/portfolio/organize');
  }

  const actions = useMemo(() => {
    const actions = []

    if (folderIdsExist) {
      actions.push({
        type: 'secondary',
        label: 'ORGANIZE BY FOLDER',
        action: onOrganize,
        style: { width: 250 },
      })
    } else {
      actions.push({
        type: 'secondary',
        label: 'VIEW PORTFOLIO',
        action: onPortfolio,
        style: { width: 250 },
      })
    }

    actions.push({
      type: 'main',
      label: 'VIEW MONITORING',
      action: onMonitoring,
      style: { width: 250 },
    })

    if (folderIdsExist) {
      actions.push({
        label: 'VIEW PORTFOLIO',
        action: onPortfolio,
        style: { width: 250 },
        linkButton: true,
      })
    }

    return actions;
    }, [folderIdsExist]) // eslint-disable-line

  return (
    <React.Fragment>
      <Modal
        open={open}
        title="Adding Clients to Ongoing Monitoring"
        mainText={`Good work! ${count} names will be added to the Ongoing Monitoring list.`}
        additionalText={'\nYou will receive an email once all names get screened against watchlists for the first time. After that, monitoring will be performed automatically, as per your selected settings.'}
        actions={actions}
        actionsDirection="column"
      />
    </React.Fragment>
  );
}
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography, withStyles, IconButton, Input, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { debounce } from 'lodash';
import InView from 'react-intersection-observer';

import {
  FileViewerArrowDown,
  FileViewerArrowDownDisabled,
  FileViewerArrowUp,
  FileViewerArrowUpDisabled,
  FileViewerDownload,
  FileViewerRotateCcw,
  FileViewerRotateCw,
  FileViewerScreenCollapse,
  FileViewerScreenExpand,
  FileViewerZoomIn,
  FileViewerZoomInDisabled,
  FileViewerZoomOut,
  FileViewerZoomOutDisabled,
} from '@app/icons'
import CustomTooltip from '@components/controls/tooltip';
import FileViewerWrapper from '../modals/file-viewer-wrapper';
import { handleOnEnter } from '@utils/handleOnEnter';

const IconButtonNoPad = withStyles(() => ({
  root: {
    padding: 0,
  }
}))(IconButton)

const useStyles = makeStyles((theme) => ({
  pageInputInput: {
    fontSize: 10,
    textAlign: 'center',
    fontWeight: 'normal',
  },
  pageInput: {
    width: 32,
    height: 18,
  },
  pageInputWrapper: {
    border: '1px solid #E7E7E7',
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.15)',
    mixBlendMode: 'multiply',
    borderRadius: '6px',
    height: 32,
  },
  zoomInput: {
    width: 40,
    height: 18,
  }
}));

const MAX_SCALE = 500;
const MIN_SCALE = 15;
const SCALE_INCREMENT = 15;

const Loading = () => (
  <Box display="flex" alignItems="center" justifyContent="center" height="100%" style={{ visibility: 'visible' }}>
    Loading...
  </Box>
)

const Tool = ({ tooltip, children, ...props }) => (
  <CustomTooltip title={tooltip}>
    <span>
      <IconButtonNoPad {...props}>
        {children}
      </IconButtonNoPad>
    </span>
  </CustomTooltip>
)

export default function PdfViewer({ url, open, onClose, documentName, clientName, onDownload }) {
  const classes = useStyles();

  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(100);
  const [scaleInput, setScaleInput] = useState(100)

  // Pagination
  const [page, setPage] = useState(0)
  const [pagesCount, setPagesCount] = useState(0)
  const [pageInput, setPageInput] = useState(0)
  const [lazyLoadedPages, setLazyLoadedPages] = useState([])

  const [fullScreen, setFullScreen] = useState(false);
  const [hideFullScreen, setHideFullScreen] = useState(false);

  useEffect(() => {
    if (!open) {
      setScale(100);
      setPage(0);
      setFullScreen(false);
      setRotate(0);
      setPagesCount(0);
    }
  }, [open])

  useEffect(() => {
    setPageInput(page + 1);
  }, [page])

  useEffect(() => {
    setScaleInput(`${scale}%`);
  }, [scale])

  useEffect(() => {
    lazyLoadPages(0);
  }, [pagesCount]) // eslint-disable-line

  /*
* Remove fullscreen tooltip for a short period
* Because fullscreen tooltip can get stuck open
* When user click on it and the component is resized
* Causing the cursor to stay outside the component and
* onLeave never gets triggered
*/
  useEffect(() => {
    if (hideFullScreen) {
      setHideFullScreen(false);
      setTimeout(() => setHideFullScreen(false), 250)
    }
  }, [hideFullScreen])

  useEffect(() => {
    setHideFullScreen(true);
  }, [fullScreen]) // eslint-disable-line

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPagesCount(numPages);
  }

  const handleScaleInputChange = (e) => {
    let value = e.target.value;
    if (!value) {
      setScaleInput('');
    }

    if (isNaN(value)) {
      return;
    }

    setScaleInput(value);
  }

  const rotateLeft = () => {
    const newRotate = rotate - 90;
    setRotate(newRotate > 0 ? newRotate : newRotate + 360)
  }

  const rotateRight = () => {
    const newRotate = rotate + 90;
    setRotate(newRotate < 360 ? newRotate : newRotate - 360)
  }

  const zoomOut = () => {
    const newScale = scale - SCALE_INCREMENT;
    setScale(newScale < MIN_SCALE ? MIN_SCALE : newScale)
  }

  const zoomIn = () => {
    const newScale = scale + SCALE_INCREMENT;
    setScale(newScale > MAX_SCALE ? MAX_SCALE : newScale)
  }

  const prevPage = () => {
    const newPage = page - 1 < 0 ? 0 : page - 1
    setPage(newPage)
    focusToPage(newPage)
  }

  const nextPage = () => {
    const newPage = page + 1 >= pagesCount ? page : page + 1
    setPage(newPage)
    focusToPage(newPage)
  }

  const focusToPage = (page) => {
    document.getElementById(`page_${page + 1}`)?.scrollIntoView()
  }

  const loadPage = (index) => {
    lazyLoadPages(index);
  }

  const debouncedLoadPage = useMemo(
    () => debounce(loadPage, 500)
  , [pagesCount]); // eslint-disable-line

  const handlePageChange = (index, notLoaded) => (inView) => {
    if (inView) {
      if (notLoaded) {
        debouncedLoadPage(index)
      }
      setPage(index)
    }
  }

  const handlePageInputBlur = () => {
    const value = pageInput;
    if (!value) {
      return setPageInput(page + 1);
    }

    let newPage = +value - 1;
    if (newPage >= pagesCount || newPage < 0) {
      newPage = page
    }

    setPage(newPage)
    setPageInput(newPage + 1)
    focusToPage(newPage)
  }

  const handleScaleBlur = () => {
    const value = scaleInput;
    if (!value) {
      return setScaleInput(scale);
    }

    let newScale = +value.replace('%', '');
    if (newScale >= MAX_SCALE) {
      newScale = MAX_SCALE
    }

    if (newScale < MIN_SCALE) {
      newScale = MIN_SCALE
    }

    setScale(newScale)
  }

  const lazyLoadPages = (page) => {
    const pages = [];
    const maxPage = page + 10;
    const minPage = page - 10;
    for (let index = 0; index < pagesCount; index++) {
      pages.push({
        index,
        skeleton: !(index > minPage && index < maxPage)
      })
    }

    setLazyLoadedPages(pages);
    setTimeout(() => {
      focusToPage(page);
    }, 500)
  }

  return (
    <FileViewerWrapper open={open} onClose={onClose} title={documentName} subtitle={clientName} fullScreen={fullScreen}>
      <Box display="flex" justifyContent="space-between" pt={3} pb={2} pr={1} style={{ borderBottom: '1px solid black' }}>
        <Box display="flex" alignItems="center">
          <Tool tooltip="Previous Page" onClick={prevPage} disabled={page === 0}>
            {page === 0 ? <FileViewerArrowUpDisabled /> : <FileViewerArrowUp />}
          </Tool>

          <Box mx={1} width={80} display="flex" justifyContent="center" alignItems="center" className={classes.pageInputWrapper}>
            <Input
              classes={{
                input: classes.pageInputInput,
              }}
              className={classes.pageInput}
              value={pageInput}
              onKeyDown={handleOnEnter(handlePageInputBlur)}
              onChange={(e) => setPageInput(e.target.value)}
              onBlur={handlePageInputBlur}
              type="number"
            />
            <Box>
              <Typography variant="body2">/</Typography>
            </Box>
            <Box width={32}>
              <Typography align="center" variant="body2" style={{ fontWeight: 700 }}>{pagesCount}</Typography>
            </Box>
          </Box>

          <Tool tooltip="Next Page" onClick={nextPage} disabled={page === pagesCount - 1}>
            {page === pagesCount - 1 ? <FileViewerArrowDownDisabled /> : <FileViewerArrowDown />}
          </Tool>
        </Box>

        <Box display="flex" alignItems="center">
          <Tool tooltip="Zoom Out" onClick={zoomOut} disabled={scale <= 15}>
            {scale <= 15 ? <FileViewerZoomOutDisabled /> : <FileViewerZoomOut />}
          </Tool>
          <Box mx={1} width={58} display="flex" justifyContent="center" alignItems="center" className={classes.pageInputWrapper}>
            <Input
              classes={{
                input: classes.pageInputInput,
              }}
              className={classes.zoomInput}
              value={scaleInput}
              onKeyDown={handleOnEnter(handleScaleBlur)}
              onChange={handleScaleInputChange}
              onBlur={handleScaleBlur}
            />
          </Box>
          <Tool tooltip="Zoom In" onClick={zoomIn} disabled={scale >= 500}>
            {scale >= 500 ? <FileViewerZoomInDisabled /> : <FileViewerZoomIn />}
          </Tool>
        </Box>

        <Box display="flex" alignItems="center">
          <Box>
            <Tool tooltip="Rotate Counter Clockwise" onClick={rotateLeft}>
              <FileViewerRotateCcw />
            </Tool>
          </Box>
          <Box mx={1}>
            <Tool tooltip="Rotate Clockwise" onClick={rotateRight}>
              <FileViewerRotateCw />
            </Tool>
          </Box>
          <div style={{ borderRight: '1px solid #C6C6C6', width: 1, height: '100%' }}></div>
          <Box mx={1}>
            <Tool tooltip="Download" onClick={onDownload}>
              <FileViewerDownload />
            </Tool>
          </Box>
          <div style={{ borderRight: '1px solid #C6C6C6', width: 1, height: '100%' }}></div>
          <Box mx={1}>
            {hideFullScreen ?
              <IconButtonNoPad>
                {fullScreen ? <FileViewerScreenCollapse /> : <FileViewerScreenExpand />}
              </IconButtonNoPad>
              :
              <Tool tooltip={fullScreen ? 'Exit Full Screen' : 'Full Screen'} onClick={() => setFullScreen(!fullScreen)}>
                {fullScreen ? <FileViewerScreenCollapse /> : <FileViewerScreenExpand />}
              </Tool>
            }
          </Box>
        </Box>
      </Box>

      <Box
        height={fullScreen ? 'calc(100vh - 64px - 34px - 75px)': '75vh'}
        style={{
          overflowY: 'auto',
        }}
      >
        <Box
          width={fullScreen ? '100%' : '80vw'}
          minHeight="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            backgroundColor: '#C4C4C4'
          }}
        >
          <Document
            rotate={rotate}
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={err => { throw err }}
            onSourceError={err => { throw err }}
            loading={<Skeleton variant="rect" width="30vw" height="75vh"><Loading /></Skeleton>}
          >
            {lazyLoadedPages.map(page => (
              // This margin is very important
              // Without a top/bottom margin to separate the pages
              // The InView observer can be stuck in between 2 pages
              // Triggering onChange infinitely.
              <Box key={page.index} mb={1}>
                <InView onChange={handlePageChange(page.index, page.skeleton)} threshold={[0.5]}>
                  {({ ref }) => (
                    <div ref={ref} key={`page_${page.index}`} id={`page_${page.index + 1}`}>
                      {page.skeleton ?
                        <Box my={1}>
                          <Skeleton variant="rect" width="100%" height={700}>
                            <Loading />
                          </Skeleton>
                        </Box>
                        :
                        <Page
                          onRenderError={err => { throw err }}
                          onLoadError={err => { throw err }}
                          onGetTextError={err => { throw err }}
                          onGetAnnotationsError={err => { throw err }}
                          key={`page_${page.index}`}
                          pageIndex={page.index}
                          scale={scale / 100}
                          loading={
                            <Skeleton variant="rect" width="100%" height={700}>
                              <Loading />
                            </Skeleton>
                          }
                        />
                      }
                    </div>
                  )}
                </InView>
              </Box>
            ))}
          </Document>
        </Box>
      </Box>
    </FileViewerWrapper>
  )
}

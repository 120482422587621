import React, { useCallback } from  'react';
import { Box, Typography, FormControl } from '@material-ui/core';
import OutlinedSearch from '@components/inputs/outlined-search';
import { SearchIcon, SearchLegalEntity, ErrorIcon } from '@app/icons';

import { useSelector, useDispatch } from 'react-redux';
import { backgroundSearch } from '@app/store/actions';
import { PREMIUM, PERSON } from '../constants';
import history from '@app/history';
import { useStyles } from './styles';

export default function SearchInputBlock({ action, onShowMore }) {
  const classes = useStyles();

  const { searchModel, isError } = useSelector(state => state.backgroundSearch);
  const dispatch = useDispatch();

  const handleInputChange = useCallback(e => {
    dispatch(backgroundSearch.setSearchModel({ name: e.target.value, }));
    isError && dispatch(backgroundSearch.setIsError(false));
    onShowMore();
  }, [isError, dispatch, onShowMore]);

  const onEnter = useCallback(() => {
    if (searchModel.name) {
      action();
      return;
    }
  }, [searchModel, action])

  const renderError = () =>  (
    <Box display="flex" flexDirection="row" alignItems="flex-start" className={classes.errorWrapper}>
      <ErrorIcon width={28} />
      {searchModel.name?.length < 2 ? (
        <Typography className={classes.errorText}>
          The length of the searching value must be at least two characters!
        </Typography>
      ) : (
        <Typography className={classes.errorText}>
          You do not have enough {searchModel.searchProvider === PREMIUM ? 'premium' : 'regular'} credits. Please upgrade your <span className={classes.linkified} onClick={() => history.push('/my-profile/subscription/plans')}>subscription plan</span>.
        </Typography>
      )}
    </Box>
  )

  return (
    <React.Fragment>
      <FormControl fullWidth>
        <OutlinedSearch
          value={searchModel.name}
          onChange={handleInputChange}
          onEnter={onEnter}
          label={searchModel.type === PERSON ? 'Search people' : 'Search legal entities'}
          icons={searchModel.type === PERSON
            ? <SearchIcon />
            : <SearchLegalEntity />
          }
          error={isError}
        />
      </FormControl>
      {isError && renderError()}
    </React.Fragment>
  )
}

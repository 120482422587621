import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

import CustomTooltip from '@components/controls/tooltip';
import BaseTableCell from './base-table-cell';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  content: {
    position: 'absolute',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  spacer: {
    height: 0,
    overflow: 'hidden'
  }
}));

// https://stackoverflow.com/a/19757393

export default function TruncatedTableCell(props) {
  const classes = useStyles();
  const { col, item } = props;

  return (
    <BaseTableCell col={col} item={item}>
      <Box className={classes.container}>
        <CustomTooltip
          title={item?.tooltipValue || item[col.tooltipValue] || item[col.field]}
          placement="top"
          disableHoverListener={!col.useTooltip}
        >
          <Box className={classes.content}>{item[col.field] || 'N/A'}</Box>
        </CustomTooltip>
        <Box className={classes.spacer}>{item[col.field] || 'N/A'}</Box>
        <span>&nbsp;</span>
      </Box>
    </BaseTableCell>
  )
}
import { countryCodeList } from '@services/country-service';
import sub from 'date-fns/sub';
import * as yup from 'yup';

export const personalInputs = [
  {
    key: 'name',
    label: 'First Name',
    type: 'input',
  },
  {
    key: 'middleName',
    label: 'Middle Name',
    type: 'input',
  },
  {
    key: 'lastName',
    label: 'Last Name',
    type: 'input',
  },
  {
    key: 'dob',
    label: 'Date of Birth',
    type: 'input',
    dataType: 'date',
  },
  // {
  //   key: 'organization',
  //   label: 'Organization',
  //   type: 'input',
  // },
  {
    key: 'address',
    label: 'Address',
    type: 'input',
  },
  {
    key: 'city',
    label: 'City',
    type: 'input',
  },
  {
    key: 'state',
    label: 'State',
    type: 'input',
    element: {
      key: 'zipPost',
      label: 'Zip/Postcode',
      type: 'input',
      dataType: 'number',
    }
  },
  {
    key: 'country',
    label: 'Country',
    type: 'select',
    options: countryCodeList
  },
]

export const contactInputs = [
  {
    key: 'primaryEmail',
    label: 'Primary Email',
    type: 'input',
  },
  {
    key: 'otherEmail',
    label: 'Other Email',
    type: 'input',
  },
  {
    key: 'phoneNumber',
    label: 'Phone Number',
    type: 'input',
  },
  {
    key: 'company',
    label: 'Company',
    type: 'input',
  },
]

export const protectionInputs = [
  {
    key: 'email',
    label: 'Email',
    type: 'input',
  },
  {
    key: 'password',
    label: 'Password',
    type: 'input',
    password: true,
    element: {
      key: 'generate',
      label: 'Generate',
      type: 'button',
    }
  },
  {
    key: 'confirm_password',
    label: 'Repeat Password',
    type: 'input',
    password: true,
  },
]

export const defaultModel = {
  name: '',
  middleName: '',
  lastName: '',
  dob: '',
  organization: '',
  address: '',
  city: '',
  state: '',
  zipPost: '',
  country: '',
  primaryEmail: '',
  otherEmail: '',
  phoneNumber: '',
  company: '',
  email: '',
  password: '',
  confirm_password: '',
}

export const schema = yup.object().shape({
  name: yup.string().label('First name').min(3).required('First name is required').max(30).trim(),
  lastName: yup.string().label('Last name').required('Last name is required').max(30),
  email: yup.string().label('Email').lowercase().trim().email('Invalid email format').required('Email is required').max(50),
  dob: yup.date()
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .label('Date of birth')
    .required()
    .max(sub(new Date(), { days: 1 }), 'Date of birth should be before current date')
    .typeError('Invalid date format'),
  primaryEmail: yup.string().label('Primary Email').lowercase().trim().email('Invalid email format').max(50),
  otherEmail: yup.string().label('Other Email').lowercase().trim().email('Invalid email format').max(50),
  password: yup.string().label('Password')
    .test('format', 'Password must have at least 8 characters, one capital letter, one small letter, one number and one special character', function () {
      const regex = new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\\-=\\[\\]{};\':"\\\\|,.<>/?]).{8,}$');
      return regex.test(this.parent.password);
    })
    .test('format_space', 'Password can\'t start or end with space(s)', function () {
      const trimmedPassword = this.parent.password.trim();
      return trimmedPassword === this.parent.password;
    }).required('Password is required'),
  confirm_password: yup.string().label('Confirm password').required('Confirm password is required').test('match', 'Passwords do not match', function () {
    return this.parent.confirm_password === this.parent.password
  }),
});

export const withoutPasswordSchema = yup.object().shape({
  firstName: yup.string().label('First name').min(3).required('First name is required').max(30).trim(),
  lastName: yup.string().label('Last name').required('Last name is required').max(30),
  email: yup.string().label('Email').lowercase().trim().email('Invalid email format').required('Email is required').max(50),
  primaryEmail: yup.string().label('Primary Email').lowercase().trim().email('Invalid email format').max(50),
  otherEmail: yup.string().label('Other Email').lowercase().trim().email('Invalid email format').max(50),
  dob: yup.date()
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .label('Date of birth')
    .required()
    .max(sub(new Date(), { days: 1 }), 'Date of birth should be before current date')
    .typeError('Invalid date format'),
});
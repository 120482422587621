import React, {useCallback, useMemo, useRef, useState} from 'react';
import {Typography, IconButton, Input, Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {
  OptionsIcon,
  Dropdown4Icon,
  DropdownDownIcon
} from '@app/icons';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import _ from 'lodash'
import debounce from 'awesome-debounce-promise';
import CustomIconButton from '@components/buttons/button-with-icon';

const MAX_LENGTH_DEFAULT = 250

export const useStyles = makeStyles((theme) => ({
  cardComment: {
    margin: '5px 0',
    padding: '10px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.GY1,
    border: `1px solid ${theme.palette.common.white}`,
    '&:hover': {
      border: '1px solid #A4A4A4',
    },
    '&.active': {
      border: '1px solid #A4A4A4',
    }
  },
  commentHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  commentTitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '20px',
    color: theme.palette.text.GY5,
  },
  counter: {
    fontStyle: 'normal',
    fontSize: '10px',
    lineHeight: '16px',
    color: theme.palette.text.GY4,
  },
  commentText: {
    fontStyle: 'normal',
    fontSize: '10px',
    lineHeight: '16px',
    color: theme.palette.text.GY4,
    wordWrap: 'break-word',
    '&::placeholder': {
      fontSize: 10,
      fontWeight: 500,
      opacity: 1,
      color: '#656565'
    },
  },
  commentWrapper: {
    overflowY: 'auto',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  isProfileCard: {
    lineHeight: '5px',
    marginBottom: '10px',
  },
  contextButton: {
    borderRadius: 8,
    padding: 2
  },
  moreLessButton: {
    marginBottom: -8,
    '& .MuiButton-label': {
      fontSize: 12,
      fontWeight: 600,
      fontFamily: 'Roboto',
    }
  }
}));

const MORE_LESS_THRESHOLD = 300

const CardComment = (
  {
    comment,
    title,
    handleDeleteComment,
    handleEditComment,
    updatedAt,
    isProfileCard,
    readonly = false,
    inlineEdit = false,
    setComment,
    fullWidth = false,
    placeholder,
    inputProps,
    disabled,
    maxLength,
  },
  ref
) => {
  const classes = useStyles();

  const options = [
    !readonly && handleEditComment && {
      label: 'Edit',
      action: () => {
        handleEditComment()
        setTimeout(() => {
          if (ref && ref.current) {
            ref.current.focus()
            ref.current.selectionStart = ref.current.selectionEnd = 10000
          }
          if (inputRef && inputRef.current) {
            inputRef.current.focus()
            inputRef.current.selectionStart = inputRef.current.selectionEnd = 10000
          }
        }, 0)
      }
    },
    !readonly && handleDeleteComment && {label: 'Delete', action: () => handleDeleteComment()},
  ].filter(v => !!v);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [internalCommentMirror, setInternalCommentMirror] = useState(comment)

  const [showAllLines, setShowAllLines] = useState(false)

  const showMoreLessLinesToggle = useMemo(() => comment?.length > MORE_LESS_THRESHOLD, [comment])

  const inputRef = useRef()

  const onShowMoreLessToggle = () => {
    setShowAllLines(!showAllLines)
  }

  //eslint-disable-next-line
    const debouncedSetComment = useCallback(
    debounce(setComment, 500),
    [setComment]
  )

  const internalSetComment = value => {
    setInternalCommentMirror(value)
    debouncedSetComment(value)
  }

  const finalMaxLength = useMemo(() => maxLength || MAX_LENGTH_DEFAULT, [maxLength])

  const commentText = useMemo(() => {
    if (showMoreLessLinesToggle && !showAllLines) {
      return comment.substring(0, MORE_LESS_THRESHOLD) + '...';
    }
    return comment;
  }, [comment, showAllLines, showMoreLessLinesToggle])

  if (_.isNil(comment)) return null;
  return (
    <div className={clsx(classes.cardComment, !readonly && !!inlineEdit && 'active')}
      style={{width: fullWidth ? '100%' : 'auto'}}>
      <div className={classes.commentHeader}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.commentTitle}>
            {title}
          </Typography>
          {(!!options?.length && !readonly && !disabled) &&
            <Box ml={1}>
              <IconButton
                aria-label="more"
                aria-controls="comment-menu"
                aria-haspopup="true"
                onClick={(event) => setAnchorEl(event.currentTarget)}
                className={classes.contextButton}
              >
                <OptionsIcon/>
              </IconButton>
              <Menu
                id="comment-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                  style: {
                    width: '80px',
                    marginTop: '30px',
                    marginLeft: '10px',
                  },
                }}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option.label}
                    onClick={() => {
                      option.action();
                      setAnchorEl(null);
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          }
          {(updatedAt && !isProfileCard) && (
            <Typography className={classes.counter}>
              {updatedAt}
            </Typography>
          )}
        </div>
        <Typography className={classes.counter}>
          {internalCommentMirror?.length || 0}/{finalMaxLength}
        </Typography>
      </div>
      {(updatedAt && !!isProfileCard) && (
        <Typography className={clsx(classes.counter, classes.isProfileCard)}>
          {updatedAt}
        </Typography>
      )}
      {(readonly || !inlineEdit) &&
        <Box>
          <Typography className={classes.commentText}>
            {commentText}
          </Typography>
          {showMoreLessLinesToggle &&
            <Box display="flex" justifyContent="center">
              <CustomIconButton
                onClick={onShowMoreLessToggle}
                startIcon={
                  showAllLines
                    ? <Dropdown4Icon size="medium" />
                    : <DropdownDownIcon size="medium" />
                }
                className={classes.moreLessButton}
              >
                {showAllLines ? 'Show Less' : 'Show More'}
              </CustomIconButton>
            </Box>
          }
        </Box>
      }
      {!readonly && inlineEdit &&
          <React.Fragment>
            <Input
              classes={{input: classes.commentText}}
              placeholder={placeholder}
              defaultValue={internalCommentMirror}
              onChange={event => internalSetComment(event.target.value)}
              multiline={true}
              rows={!showAllLines? 3: null}
              inputProps={{
                ...inputProps,
                maxLength: finalMaxLength
              }}
              inputRef={ref? ref: inputRef}
            />
            { showMoreLessLinesToggle &&
              <CustomIconButton
                onClick={onShowMoreLessToggle}
                startIcon={
                  showAllLines
                    ? <Dropdown4Icon size="medium" />
                    : <DropdownDownIcon size="medium" />
                }
                className={classes.moreLessButton}
              >
                {showAllLines ? 'Show Less' : 'Show More'}
              </CustomIconButton>
            }
          </React.Fragment>
      }
    </div>
  )
};

export default React.forwardRef(CardComment);
import React, { useEffect, useState } from 'react';
import {
  Box, Button, FormControlLabel,
  Grid, Typography, Switch, FormControl, InputLabel, OutlinedInput, FormHelperText
} from '@material-ui/core';
import * as yup from 'yup';
import parseYupErrors from '@app/yup';
import { InfoBrownOtherIcon } from '@app/icons';
import { clientApi } from '@app/api';
import useErrorState from '@utils/errorState';
import ReactInputMask from 'react-input-mask';
import { Modal } from '@components/modals';
import constants from '@app/constants';
import { useSelector } from 'react-redux';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import { formatUserAttributes } from '@dto/cognitoAttributes';
import ConfirmOtp from '@components/modals/confirm-otp';
import cognito from '../../../../../app/cognito';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

const selectUser = state => state.auth.user;

export default function SmsBasedOtp(props) {
  const userData = useSelector(selectUser);
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useErrorState({});
  const { action } = useParams();

  const [notVerifiedDialogOpen, setNotVerifiedDialogOpen] = useState(false);
  const [phoneDialogOpen, setPhoneDialogOpen] = useState(false);
  const [otpDialogOpen, setOtpDialogOpen] = useState(false);
  const [enableMfaDialogOpen, setEnableMfaDialogOpen] = useState(false);
  const [userAttributes, setUserAttributes] = useState(undefined);
  const [isMfaSet, setIsMfaSet] = useState(false);
  const [shouldEnableMfa, setShouldEnableMfa] = useState(false);
  const [model, setModel] = useState({
    phoneNumber: userData.phoneNumber || '',
        
    // Due to BE limitation, we need to send all user data
    name: userData.name || '',
    middleName: userData.middleName || '',
    lastName: userData.lastName || '',
    dob: userData.dob,
    organization: userData.organization || '',
    address: userData.address || '',
    city: userData.city || '',
    state: userData.state || '',
    zip: userData.zip || '',
    country: userData.country === 'string' ? '' : (userData.country || ''),
    email: userData.email || '',
    otherEmails: userData.otherEmails || '',
  });

  useEffect(() => {
    fetchUserAttributes()
    fetchMfaSetting()
    }, []) // eslint-disable-line

  /**
     * This is to handle cases from edit profile
     * After editing phone number from profile, cognito will send a verification code
     * We redirect user to this page to have them verify their phone
     */
  useEffect(() => {
    if (userAttributes?.phone_number_verified === 'false') {
      if (action === 'verify-number-mfa') {
        setShouldEnableMfa(true);
      }
      if (['verify-number-mfa', 'verify-number'].includes(action)) {
        setOtpDialogOpen(true);
      }
    }
    }, [action, userAttributes]) // eslint-disable-line

  // MFA setting is only updated after logout + login
  // In order to track MFA status, we keep it in localStorage
  const fetchMfaSetting = () => {
    const localStorageMfa = localStorage.getItem('isMfaEnabled')
    if (localStorageMfa !== null) {
      return setIsMfaSet(localStorageMfa === 'true')
    }
    cognito.getMfaSettings().then(res => {
      setIsMfaSet(!!res)
    })
  }

  const fetchUserAttributes = () => {
    cognito.getUserAttributes().then(res => {
      const formattedRes = formatUserAttributes(res)
      setUserAttributes(formattedRes)
    })
  }

  /**
     * 1. Check if user has phone number
     * 2. Check if phone number is verified
     * 3. Enable OTP
     * isVerified - override check and assume number is verified
     */
  const toggleMfaSettings = (isVerified) => {
    if (isVerified || userAttributes?.phone_number_verified === 'true') {
      cognito.setMfaSettings(!isMfaSet).then((res) => {
        setIsMfaSet(!isMfaSet)
        enqueueSnackbar(`Multi-Factor Authentication was successfully ${isMfaSet ? 'disabled' : 'enabled'}!`, { variant: 'success' })
        localStorage.setItem('isMfaEnabled', !isMfaSet)
      })
    } else {
      if (userAttributes?.phone_number) {
        setNotVerifiedDialogOpen(true)
        setShouldEnableMfa(true);
      } else {
        startChangeNumber()
      }
    }
  }

  const closePhoneDialog = () => {
    setModel({ ...model, phoneNumber: userData.phoneNumber || '' });
    setPhoneDialogOpen(false);
  }

  const startChangeNumber = () => {
    setShouldEnableMfa(true);
    setNotVerifiedDialogOpen(false)
    setPhoneDialogOpen(true)
  }

  const startVerification = () => {
    setNotVerifiedDialogOpen(false)
    setShouldEnableMfa(false);
    cognito.getPhoneVerificationCode()
      .then(res => {
        setOtpDialogOpen(true);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' })
      });
  }

  const verifyPhoneNumber = (otp) => {
    cognito.verifyPhoneNumber(otp)
      .then((res) => {
        fetchUserAttributes();
        setOtpDialogOpen(false);
        enqueueSnackbar(`Phone number "${userData.phoneNumber}" was successfully verified!`, { variant: 'success' })
        if (shouldEnableMfa) {
          toggleMfaSettings(true);
        } else {
          setEnableMfaDialogOpen(true);
        }
      })
      .catch((err) => {
        setErrors({ ...errors, otp: 'Invalid one-time password' })
      })
  }

  const handleChange = (prop) => (event) => {
    const newModel = { ...model, [prop]: event.target.value };
    setModel(newModel);

    if (errors[prop]) {
      schema.validateAt(prop, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [prop]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [prop]: err.message });
        });
    }
  };

  const submitPhoneNumber = () => {
    setErrors({});

    schema
      .validate(model, { abortEarly: false })
      .then(function (valid) {
        // if no changes, do nothing
        if (model.phoneNumber === userData.phoneNumber) {
          return setPhoneDialogOpen(false)
        }

        const newModel = { ...model, dob: model.dob ? moment(model.dob).format('YYYY-MM-DD') : null }
        const parsedNumber = parsePhoneNumber(model.phoneNumber)
        clientApi.user.updateUser({
          ...newModel,
          email: model.email.toLowerCase().trim(),
          phoneNumber: { 
            countryCode: parsedNumber.country,
            rawNumberString: parsedNumber.number,
          }
        }).then(() => {
          if (isMfaSet) {
            setIsMfaSet(false)
            enqueueSnackbar('Multi-Factor Authentication was successfully disabled!', { variant: 'success' })
            localStorage.setItem('isMfaEnabled', false)
          }
          props.fetchUser();
          fetchUserAttributes();
          setPhoneDialogOpen(false);

          // OTP is already sent by cognito since you changed number
          setOtpDialogOpen(true);
        })
      })
      .catch(function (err) {
        setErrors(parseYupErrors(err), true);
      });
  }

  const schema = yup.object().shape({
    phoneNumber: yup.string().label('Phone Number').required()
      .test('format', 'Invalid phone number', function () {
        return isValidPhoneNumber(this.parent.phoneNumber)
      }),
  });
  return (
    <Grid container alignItems="center">
      <ConfirmOtp
        open={otpDialogOpen}
        onClose={() => setOtpDialogOpen(false)}
        onResend={startVerification}
        onSubmit={verifyPhoneNumber}
        errorMsg={errors.otp}
      />

      <Modal
        open={phoneDialogOpen}
        onClose={closePhoneDialog}
        title={userData.phoneNumber ? 'Change Phone Number' : 'Activate Phone Number'}
        mainText="A one-time password (OTP) will be sent to your mobile number via SMS."
        content={
          <Box width={400} mb={2}>
            <Grid container>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth error={!!errors.phoneNumber}>
                  <InputLabel shrink>Phone Number (+XXXXXXXXXXXXXXX)</InputLabel>
                  <ReactInputMask
                    mask={constants.phoneMask}
                    value={model.phoneNumber}
                    onChange={handleChange('phoneNumber')}
                    maskChar={null}
                  >
                    {(inputProps) => <OutlinedInput
                      {...inputProps}
                    />}
                  </ReactInputMask>
                  <FormHelperText>{errors.phoneNumber}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            {!!userData.phoneNumber &&
                            <Box display="flex" mt={2}>
                              <Box>
                                <Box width={20}>
                                  <InfoBrownOtherIcon />
                                </Box>
                              </Box>
                              <Box>
                                <Typography style={{ lineHeight: '18px', color: '#7A5B44' }}>If Multi-Factor Authentication is enabled for your account, it uses your current phone number. If you change your phone number, we will have to verify that the new number belongs to you.</Typography>
                              </Box>
                            </Box>
            }
          </Box>
        }
        actions={[{
          type: 'secondary',
          label: 'CONTINUE',
          action: submitPhoneNumber,
          style: { width: 250 },
          disabled: model.phoneNumber === userData.phoneNumber,
        }]}
        actionsDirection="column"
      />

      <Modal
        open={notVerifiedDialogOpen}
        onClose={() => setNotVerifiedDialogOpen(false)}
        title="Phone Number Unverified"
        mainText={`Your current phone number is not yet verified. Would you like to verify the current number ${userData.phoneNumber} or change it?`}
        actions={[
          {
            type: 'main',
            label: 'VERIFY NUMBER',
            action: startVerification,
            style: { width: 200 },
          },
          {
            type: 'secondary',
            label: 'CHANGE NUMBER',
            action: startChangeNumber,
            style: { width: 200 },
          },
        ]}
        actionsDirection="row"
      />

      <Modal
        open={enableMfaDialogOpen}
        onClose={() => setEnableMfaDialogOpen(false)}
        title="Phone Number Verified"
        mainText={'Would you like to enable Multi-Factor Authentication?'}
        actions={[
          {
            type: 'main',
            label: 'CANCEL',
            action: () => setEnableMfaDialogOpen(false),
            style: { width: 200 },
          },
          {
            type: 'secondary',
            label: 'ENABLE',
            action: () => {
              setEnableMfaDialogOpen(false)
              toggleMfaSettings(true)
            },
            style: { width: 200 },
          }
        ]}
        actionsDirection="row"
      />

      <Grid item xs={6}>
        <Typography variant="h5">Multi-Factor Authentication</Typography>
      </Grid>
      <Grid item xs={6} container justify="flex-end">
        <FormControlLabel
          control={<Switch value="On" checked={isMfaSet} onChange={() => toggleMfaSettings()} />}
          label="ON"
        />
      </Grid>

      <Grid item xs={12}>
        <Box mt={1}>
          <Typography variant="body1" style={{ color: '#A4A4A4' }}>SMS-based OTP combines the use of a regular password with a code sent to your mobile number.</Typography>
          <Typography variant="body1" style={{ color: '#A4A4A4' }}>Registering your mobile number for authentication will help secure your account from unauthorized access.</Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box mt={2}>
          <FormControl variant="outlined" fullWidth error={errors.phoneNumber}>
            <InputLabel>Phone Number (XXXXXXXXXXXXXXX)</InputLabel>
            <OutlinedInput disabled value={userData.phoneNumber || 'N/A'} />
          </FormControl>
        </Box>
      </Grid>

      <Grid item xs={12} container>
        <Box mt={2} mr={2}>
          <Button
            style={{ width: 200, padding: '10px' }}
            variant="outlined"
            size="medium"
            onClick={startChangeNumber}
          >
            {userData.phoneNumber ? 'CHANGE NUMBER' : 'SETUP NOW'}
          </Button>
        </Box>
        {userAttributes?.phone_number_verified === 'false' &&
                    <Box mt={2}>
                      <Button
                        style={{ width: 200, padding: '10px' }}
                        variant="contained"
                        size="medium"
                        onClick={startVerification}
                      >
                            VERIFY NUMBER
                      </Button>
                    </Box>
        }
      </Grid>
    </Grid>
  )
};

import React, {useEffect, useState} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Grid, Button
} from '@material-ui/core';
import FormInner from './form-inner';
import history from '@app/history';
import {useParams} from 'react-router-dom';
import {riskRating, riskRatingTemplate} from '@app/api/client';
import riskRatingDto from './dto/risk-rating-dto';
import riskRatingTemplateDto from './dto/risk-rating-template-dto';
import Header from './components/header';
import RisksListTable from './components/list-table';
import riskRatingListDto from './dto/risk-rating-list-dto';
import {exportRiskRatingPDF} from '@utils/exportPDF';
import {useSnackbar} from 'notistack';

export const useStyles = makeStyles((theme) => ({
  borderlessButton: {
    color: theme.palette.text.LB3,
    '&:hover': {
      backgroundColor: 'inherit',
    }
  },
  viewport: {
    // overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  templateLabel: {
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#232323'
  },
  templateValue: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '18px',
    color: '#A4A4A4'
  }
}));

export default function RiskRatingView(props) {

  const { baseUri } = props

  const clientData = props.data

  const [templateId, setTemplateId] = useState(0)
  const [templates, setTemplates] = useState([])

  const [data, setData] = useState(null)
  const [previewLines, setPreviewLines] = useState([])

  const { riskRatingId } = useParams()

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles()

  const onClose = () => {
    history.push(baseUri)
  }

  const fetchTemplates = () => {
    return riskRatingTemplate.index()
      .then(({data}) => {
        setTemplates(
          data.map(riskRatingTemplateDto.read)
            .sort( (a, b) => a.templateName.localeCompare(b.templateName))
        )
      })
  }

  useEffect(() => {
    fetchTemplates()
    }, [])//eslint-disable-line

  useEffect(() => {
    if (templates.length) {
      riskRating.show(riskRatingId)
        .then(({data}) => {
          const parsedData = riskRatingDto.read(data)
          setData(parsedData)
          setTemplateId(templates.find(t => t.templateName === parsedData.templateName)?.templateId)
          // Parse data for preview line on top
          setPreviewLines([
            riskRatingListDto.read(data)
          ])
        })
    }
    }, [templates])//eslint-disable-line

  const onExport = async () => {
    await exportRiskRatingPDF({riskRatingId})
    enqueueSnackbar('The report was successfully exported!', { variant: 'success' });
  }

  return (
    <React.Fragment>
      <Grid container justify={'center'}>
        <Grid item xs={7}>
          <Header clientData={clientData} baseUri={baseUri}/>
        </Grid>
      </Grid>

      <Box
        // maxHeight="calc(100vh - 280px)"
        className={classes.viewport}
        px={2}
      >
        <Grid container justify={'center'}>
          <Grid item xs={7}>
            <Box display={'flex'} flexGrow={1} flexDirection={'column'}>

              <Box display={'flex'} mb={2} mx={-2}>
                <RisksListTable {...{data: clientData, lines: previewLines}} />
              </Box>

              <Box display={'flex'} flexDirection={'column'}>
                <Box display={'flex'} className={classes.templateLabel}>
                                    Template
                </Box>
                <Box display={'flex'} className={classes.templateValue}>
                  {templates.find(t => t.templateId === templateId)?.templateName}
                </Box>
              </Box>

              <FormInner readOnly data={data}/>

              <Box display={'flex'} justifyContent={'center'}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} my={3}>
                  <Box display={'flex'}>
                    <Button style={{ width: '208px' }} variant={'contained'} onClick={onExport}>
                                            EXPORT REPORT
                    </Button>
                  </Box>
                  <Box display={'flex'} my={1}>
                    <Button style={{ width: '208px' }} className={classes.borderlessButton} onClick={onClose}>
                                            CLOSE
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  )
}
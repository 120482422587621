import React, { useState, useEffect, useMemo } from  'react';
import { RadioButtonGroup } from '@components/buttons';
import { Grid, CardContent, Card, FormControl, Switch, FormControlLabel, Box, MenuItem, InputLabel, OutlinedInput } from '@material-ui/core';
import SelectClearable from '@components/inputs/select-clearable';
import CustomChip from '@components/controls/custom-chip';
import CustomTooltip from '@components/controls/tooltip';
import { useStyles } from './styles';
import clsx from 'clsx';

import { countriesISO } from '@services/country-service';

import { useSelector } from 'react-redux'
import { isEmpty, includes } from 'lodash';

const PREMIUM = 'Premium Credits';

const alphabets = ['1','2','3','4','5','6','7','8','9','0', 'Backspace', 'Delete'];
const genders = ['Male', 'Female'];

const switchLabelsAleph = [
  { label: 'SANCTIONS', value: 'SANCTIONS', },
  { label: 'PEP', value: 'PEP' },
];

const switchLabelsPremium = [
  { label: 'SANCTIONS', value: 'SANCTIONS', },
  { label: 'PEP', value: 'PEP' },
  { label: 'ADVERSE MEDIA', value: 'ADVERSE_MEDIA' },
];

const premiumTypes = ['SANCTIONS', 'PEP', 'ADVERSE_MEDIA'];

export default function AdvancedCard({ handleRequestData, searchName, setInputData, sanctions, forceType, type, setType, credits }) {
  const currentSearch = useSelector(store => store.backgroundCheck.currentSearch);

  const classes = useStyles({ sanctions });

  const [yob, setYob] = useState('');
  const [registrationNumber, setRegistrationNumber] = useState('');

  const alephTypes = useMemo(() => {
    return sanctions ? ['SANCTIONS'] : ['SANCTIONS', 'PEP']
  }, [sanctions])

  const [listTypes, setListTypes] = useState(alephTypes);
  const [country, setCountry] = useState('');
  const [gender, setGender] = useState('');

  const switchLabels = useMemo(() => {
    return credits === PREMIUM ? switchLabelsPremium : switchLabelsAleph;
  }, [credits]);

  useEffect(() => {
    if (credits === PREMIUM) {
      setListTypes(premiumTypes);
    } else {
      setListTypes(alephTypes);
    }
    // eslint-disable-next-line
  }, [credits])

  useEffect(() => {
    if (!isEmpty(currentSearch)) {
      setType((currentSearch.type === 'LEGAL_ENTITY' ? 'COMPANY' : currentSearch.type) || type);
      setYob(currentSearch.yob || yob);
      setRegistrationNumber(currentSearch.registrationNumber || registrationNumber);
      setListTypes(currentSearch.listTypes || listTypes);
      setCountry(currentSearch.country || country);
      setGender(currentSearch.gender || gender);
    }
    // eslint-disable-next-line
  }, [currentSearch])

  useEffect(() => {
    const data = type === 'PERSON'
      ? {
        country,
        gender,
        listTypes,
        name: searchName,
        type,
        yob,
      } : {
        listTypes,
        country,
        name: searchName,
        type,
        registrationNumber,
      }
    handleRequestData(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, gender, listTypes, type, yob, registrationNumber, searchName]);

  const changeSwitch = (changed, type) => {
    changed
      ? setListTypes(types => ([...types, type]))
      : setListTypes(types => types.filter(el => el !== type))
  };

  const countryValue = () => {
    const item = countriesISO.find(el => el.code === country);
    return item?.code || '';
  }

  const handleYear = e => {
    const max = new Date().getFullYear();
    if (+e.target.value > max) {
      e.target.value = max
    } else if (+e.target.value < 0) {
      e.target.value = 0
    }
    setYob(e.target.value);
  }

  const radioItems = useMemo(() => {
    let items = [
      { label: 'Person', value: 'PERSON' },
      { label: 'Legal Entity', value: 'COMPANY' },
    ];

    if (forceType) {
      items = forceType === 'LEGAL_ENTITY'
        ? items.filter(e => e.value === 'COMPANY')
        : items.filter(e => e.value === forceType);
    }
    return items;
  }, [forceType])

  const handleNumberInputs = (e) => {
    if (!includes(alphabets, e.key)) {
      e.preventDefault();
    } 
  }

  const typeInfo = {
    'SANCTIONS': <React.Fragment>
      <p style={{ textAlign: 'start' }}>
          Searches through <br/>
          Sanctions <i>e.g. OFAC SDN List, HM Treasure Sanctions List</i> <br/>
          Warnings <i>e.g. US Immigration and Customs Wanted</i> <br/>
          Fitness {'&'} Probity <i>e.g. US System for Award Management Exclusions</i> <br/>
      </p>
    </React.Fragment>,
    'PEP': <React.Fragment>
      <p style={{ textAlign: 'start' }}>
          Searches through <br/>
          Pep Class 1 <i>Heads of State, National Parliaments, National Governments</i> <br/>
          Pep Class 2 <i>Regional Governments, Regional Parliaments</i> <br/>
          Pep Class 3 <i>Senior Management {'&'} Boards of SOEs</i> <br/>
          Pep Class 4 <i>Mayors and Local City Councils</i> <br/>
      </p>
    </React.Fragment>,
    'ADVERSE_MEDIA': <React.Fragment>
      <p style={{ textAlign: 'start' }}>
          Searches through financial crime, violent crime, sexual crime, terrorism, fraud, narcotics, other crime
      </p>
    </React.Fragment>,
  }

  return(
    <Card classes={{ root: classes.cardRoot }}>
      <CardContent classes={{ root: classes.cardContent }} >
        <RadioButtonGroup
          items={radioItems}
          handleChange={(e) => setType(e.target.value)}
          selectedValue={type}
          row={true}
        />
      </CardContent>
      <div className={
        clsx(
          classes.customHR,
          (type === 'PERSON' || forceType) ? classes.hrPerson : classes.hrCompany,
        )
      }
      />
      <Grid container>
        <Grid item sm={4} classes={{ root: classes.personWrapper }}>
          {type === 'PERSON' ? (
            <React.Fragment>
              {searchName && (
                <FormControl className={classes.inputPersonWrapper} variant="outlined">
                  <OutlinedInput
                    id="name"
                    placeholder="Name"
                    value={searchName}
                    onChange={(e) => setInputData(e.target.value)}
                    type="text"
                    classes={{ input: classes.advancedInput }}
                  />
                </FormControl>
              )}
              <FormControl className={classes.inputPersonWrapper} variant="outlined">
                {!countryValue() && (
                  <InputLabel htmlFor="name" className={classes.inputLabel}>Country</InputLabel>
                )}
                <SelectClearable
                  value={countryValue()}
                  onChange={(event) => setCountry(event?.target?.value || '')}
                  style={{ width: '100%' }}
                  classes={{ select: classes.advancedSelect }}
                >
                  {countriesISO.map((item, i) => (
                    <MenuItem
                      key={`${item}-${i}`}
                      value={item.code}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </SelectClearable>
              </FormControl>
              <FormControl className={classes.inputPersonWrapper} variant="outlined">
                <OutlinedInput
                  id="date"
                  placeholder="Year of Birth"
                  value={yob}
                  onChange={(e) => handleYear(e)}
                  inputProps={{ type: 'number', min: 1900, max: new Date().getFullYear().toString() }}
                  type="number"
                  onKeyDown={handleNumberInputs}
                  classes={{ input: classes.advancedInput }}
                />
              </FormControl>
              {credits !== PREMIUM && (
                <FormControl className={classes.inputPersonWrapper} variant="outlined">
                  {!gender && (
                    <InputLabel htmlFor="name" className={classes.inputLabel}>Gender</InputLabel>
                  )}
                  <SelectClearable
                    value={gender}
                    onChange={(event) => setGender(event?.target?.value || '')}
                    style={{ width: '100%' }}
                    classes={{ select: classes.advancedSelect }}
                  >
                    {genders.map((item, i) => (
                      <MenuItem
                        key={`${item}-${i}`}
                        value={item}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </SelectClearable>
                </FormControl>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {searchName && (
                <FormControl className={classes.inputPersonWrapper} variant="outlined">
                  <OutlinedInput
                    id="name"
                    placeholder="Name"
                    value={searchName}
                    onChange={(e) => setInputData(e.target.value)}
                    type="text"
                    classes={{ input: classes.advancedInput }}
                  />
                </FormControl>
              )}
              <FormControl className={classes.inputPersonWrapper} variant="outlined">
                {!countryValue() && (
                  <InputLabel htmlFor="name" className={classes.inputLabel}>Country</InputLabel>
                )}
                <SelectClearable
                  value={countryValue()}
                  onChange={(event) => setCountry(event?.target?.value || '')}
                  style={{ width: '100%' }}
                  classes={{ select: classes.advancedSelect }}
                >
                  {countriesISO.map((item, i) => (
                    <MenuItem
                      key={`${item}-${i}`}
                      value={item.code}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </SelectClearable>
              </FormControl>
              {credits !== PREMIUM && (
                <FormControl className={classes.inputPersonWrapper} variant="outlined">
                  <OutlinedInput
                    id="registrationNumber"
                    placeholder="Registration #"
                    value={registrationNumber}
                    onChange={(e) => setRegistrationNumber(e.target.value)}
                    type="text"
                    classes={{ input: classes.advancedInput }}
                  />
                </FormControl>
              )}
            </React.Fragment>
          )}
        </Grid>
        <Grid item sm={4} classes={{ root: clsx(classes.personWrapper, classes.switchActions) }}>
          {switchLabels.map((item, i) => (
            <Box display="flex" alignItems="center" key={`${item.value} - ${i}`}>
              <CustomTooltip
                title="Some features are restricted in this view. For an extensive searching experience please Login or Register."
                placement="top"
                disableHoverListener={!sanctions}
              >
                <FormControlLabel
                  checked={sanctions
                    ? !!listTypes.filter(el => el === 'SANCTIONS').find(el => el === item.value)
                    : !!listTypes.find(el => el === item.value)
                  }
                  classes={{ root: classes.rootControlLabel }}
                  key={`switch-tag-${item.label}`}
                  control={<Switch classes={{ root: sanctions && classes.switchDisabled }} />}
                  label={
                    <CustomTooltip
                      title={typeInfo[item.value]}
                      placement="top"
                      disableHoverListener={credits !== PREMIUM || sanctions}
                      width={325}
                    >
                      <span>
                        <CustomChip label={item.label} disabled={!!sanctions} />
                      </span>
                    </CustomTooltip>
                  }
                  onChange={e => changeSwitch(e.target.checked, item.value)}
                  disabled={sanctions || false}
                />
              </CustomTooltip>
            </Box>
          ))}
        </Grid>
        {!sanctions && (
          <Grid item sm={4}>
          </Grid>
        )}
      </Grid>
    </Card>
  )
}
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import debounce from 'awesome-debounce-promise';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Button, FormControl } from '@material-ui/core';

import {
  clientDocument,
  clientDocumentCategory,
  clientDocumentType,
  person,
  client as clientApi,
} from '@app/api/client';
import history from '@app/history';
import { ChevronLeftIcon } from '@app/icons';
import { calendarSchedule } from '@app/store/actions';
import TableList from '@components/lists/table-list';
import { TCELL_TYPES } from '@components/lists/table-list';
import OutlinedSearch from '@components/inputs/outlined-search';
import SelectSearchable from '@components/inputs/select-searchable';
import debugMode from '@utils/debugMode';
import { prepareSort } from '@utils/prepare-data/prepareToDoListData';
import { useFirstRender } from '@hooks/useFirstRender';

import { NoData } from './noData';
import { typeComponent } from './typeComponent';
import { useStyles } from '../styles';
import { prepareDocuments } from '../utils/formatter';
import { getItems } from '../utils/toDoList';
import {
  EXPIRATION_CHECKS,
  PAGE_SIZE,
  SORTABLE_COLUMNS,
  DEBOUNCE_DELAY,
} from '../../constants';

const debug = debugMode.getIsDebugMode();

const MAX_COUNT = debug ? 3 : 5;

const debouncedSearchableDropDown = debounce(person.index, DEBOUNCE_DELAY);

export default function Documents({ action }) {
  const firstRender = useFirstRender();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [categories, setCategories] = useState([]);
  const [documents, setDocuments] = useState({ count: 0, items: [], pages: 0 });
  const [pageNumber, setPageNumber] = useState(0);
  const [sortDirection, setSortDirection] = useState('ASC');
  const [sortField, setSortField] = useState('expiryDate');
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [clientSearch, setClientSearch] = useState(null);

  const { documentFilter } = useSelector((state) => state.calendarSchedule);

  const params = {
    // categoryIds: documentFilter.categories,
    toExpirationDate: moment.utc().add(30, 'days').endOf('day'),
    pageSize: 15,
    expirationTypes: EXPIRATION_CHECKS[documentFilter.types],
    clientId: selectedClient.value,
    sortBy: prepareSort(sortField),
    sortDirection: sortDirection,
    pageNumber: pageNumber,
  };

  useEffect(() => {
    if (!firstRender) {
      const timeOutId = setTimeout(() => getDocuments(), DEBOUNCE_DELAY);
      return () => clearTimeout(timeOutId);
    }
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    fetchItems();
    // eslint-disable-next-line
  }, [clientSearch]);

  useEffect(() => {
    getDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, documentFilter, sortField, sortDirection, selectedClient]);

  const getClients = async (items) => {
    const tmp = items.map((item) => item.associations[0].clientId);
    const clientsArray = [...new Set(tmp)];
    const clients = await Promise.all(
      clientsArray.map((item) => clientApi.show(item))
    );
    const formatted = clients.map((item) => item.data);
    return formatted;
  };

  const getType = async (documents) => {
    const tmp = documents.entries.map((item) => item.typeId);
    const typeArray = [...new Set(tmp)];
    const types = await Promise.all(
      typeArray.map(async (item) => (await clientDocumentType.show(item)).data)
    );
    return types;
  };

  const getDocuments = async () => {
    let categoriesLocal;
    if (!categories.length) {
      const category = await clientDocumentCategory.index();
      if (category) {
        setCategories(category.data.entries);
        categoriesLocal = category.data.entries;
      }
    } else {
      categoriesLocal = categories;
    }
    let documents;
    if (search.length > 1) {
      documents = (
        await clientDocument.search({
          ...params,
          searchKeyword: search.trim() || null,
        })
      ).data;
    } else if (!search.length) {
      documents = (await clientDocument.search(params)).data;
    } else {
      enqueueSnackbar('Search keyword should have at least 2 characters!', {
        variant: 'error',
      });
    }
    if (documents) {
      const types = await getType(documents);
      const clients = await getClients(documents.entries);
      const newDocs = documents.entries.map((item, index) => {
        const currentClient = clients.find(
          (client) => client.id === item.associations[0].clientId
        );
        const entityName =
          currentClient.clientType === 'PERSON'
            ? `${currentClient.firstName} ${currentClient.lastName}`
            : currentClient.legalEntityName;
        const documentType = types.find((type) => type.id === item.typeId);
        const category = categoriesLocal.find(
          (category) => category.id === item.categoryId
        );
        return {
          ...item,
          entityName: entityName,
          documentType: documentType.name,
          category: category?.name || 'N/A',
        };
      });
      setDocuments({
        items: prepareDocuments(newDocs),
        count: documents.count,
        pages: documents.pages,
      });
    }
    // eslint-disable-next-line
  };

  const fetchItems = async () => {
    const params = {
      pageNumber: 0,
      pageSize: 30,
      searchKeyword: clientSearch?.trim() || null,
      sortDirection: 'ASC',
      sortBy: 'firstName',
    };
    if (selectedClient.label !== clientSearch) {
      const response =
        clientSearch !== null
          ? (await debouncedSearchableDropDown(params)).data
          : (await person.index(params)).data;
      if (response) {
        let optionsTmp = response.entries.map((item) => ({
          label: `${item.firstName} ${item.lastName}`,
          value: item.id,
        }));
        optionsTmp.push({
          label: 'Consider refining the filter to find your client faster',
          value: '0',
          disabled: true,
        });
        setOptions(optionsTmp);
      }
    }
  };

  const handleViewAll = () => {
    history.push('/calendar-schedule/sсhedule/document-reviews');
  };
  const handleSelect = (field) => {
    setPageNumber(0);
    if (field) {
      setSelectedClient(field.target);
      setClientSearch(field.target.label);
    } else {
      setSelectedClient();
      setClientSearch('');
    }
  };
  const handleSearch = (text) => {
    setPageNumber(0);
    setSearch(text);
  };
  const onSort = (value) => {
    dispatch(calendarSchedule.setPaginationPage(0));
    if (sortField !== value) {
      setSortField(value);
      setSortDirection('ASC');
    } else {
      setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
    }
  };
  const tableProps = action && {
    pagesCount: documents.pages,
    totalCount: documents.count,
    pageSize: PAGE_SIZE,
    page: pageNumber,
    sortableColumns: SORTABLE_COLUMNS['document'],
    onSort: onSort,
    sort: sortField,
    sortDirection: sortDirection,
  };

  const COLUMNS = [
    { field: 'category', headerName: 'Category', bold: true },
    { field: 'documentType', headerName: 'Document Name' },
    {
      field: 'documentName',
      headerName: 'File Name',
      cellType: TCELL_TYPES.TRUNCATED,
      useTooltip: true,
      colProps: {
        width: '15%',
      },
    },
    { field: 'entityName', headerName: 'Client Name' },
    { field: 'uploadDate', headerName: 'Date Added' },
    { field: 'issueDate', headerName: 'Issue Date' },
    { field: 'expiryLabel', headerName: 'Expiry Date' },
    // { field: "docVersion", headerName: "Doc Version" },
    {
      cellType: TCELL_TYPES.CUSTOM,
      field: 'type',
      headerName: 'Type',
      custom: () => typeComponent({ type: 'DOCUMENT' }, classes.styleBadgeRoot),
      colProps: {
        width: '5%',
      },
    },
  ];
  const headerTitleProps = action && {
    ml: -1,
    className: classes.back,
    onClick: () => history.goBack(),
  };
  return (
    <Box display={'flex'} flexGrow={'1'} mt={'16px'} flexDirection={'column'}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={classes.header}
      >
        <Box display="flex" alignItems="center" {...headerTitleProps}>
          {action && <ChevronLeftIcon />}
          <Typography variant={action ? 'h3' : 'h4'}>
            Document Reviews
          </Typography>
        </Box>
        {action && (
          <>
            <Box display="flex" alignItems="center" ml={2} mr={3} width={350}>
              <Button
                size="small"
                className={classes.reset}
                onClick={handleSelect}
              >
                Reset Clients
              </Button>
              <FormControl variant="outlined" fullWidth>
                <SelectSearchable
                  filterOptions={(x) => x}
                  inputValue={clientSearch || ''}
                  getOptionDisabled={(option) => option.disabled}
                  renderOption={(option) => (
                    <span
                      style={{
                        fontStyle: option.value === '0' ? 'italic' : 'normal',
                      }}
                    >
                      {option.label}
                    </span>
                  )}
                  onInputChange={(event, newInputValue) => {
                    if (event?.type === 'change') {
                      setClientSearch(newInputValue);
                    }
                  }}
                  value={selectedClient.value}
                  onChange={handleSelect}
                  options={options}
                  label={'All  Clients'}
                />
              </FormControl>
            </Box>
            <Box width={350} height={'40px'} display="flex" alignItems="center">
              <OutlinedSearch
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
                label="Search"
              />
            </Box>
          </>
        )}
      </Box>
      {!!documents.items.length && (
        <>
          <Box>
            <TableList
              columns={COLUMNS}
              items={getItems(action, documents.items, debug)}
              onClick={(id, item) =>
                history.push(
                  `/portfolio/view/${item.associations[0]?.clientId}/kyc-documents`
                )
              }
              maxHeight="calc(100vh - 320px)"
              onPageChange={(event, value) => setPageNumber(value - 1)}
              {...tableProps}
            />
          </Box>
          {documents.items.length > MAX_COUNT && !action && (
            <Box mt={2} mb={2}>
              <Button variant="outlined" size="small" onClick={handleViewAll}>
                VIEW ALL
              </Button>
            </Box>
          )}
        </>
      )}
      {!documents.items.length && NoData(action, 'document', classes.noActions)}
    </Box>
  );
}

import React  from 'react';
import { Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {

  }
}));

export default function InnerHeaderControls({ controlsLeft, controlsRight }) {
  const classes = useStyles();

  return (
    <Box className={classes.root} display={'flex'} alignItems="center" flexDirection={'row'} py={1}>
      <Box display={'flex'} flexGrow={1}>
        { controlsLeft }
      </Box>
      <Box display={'flex'}>
        { controlsRight }
      </Box>
    </Box>
  )
}
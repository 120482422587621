import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button } from '@material-ui/core';

import InnerHeaderControls from '@components/layout/inner-header-controls';
import BackButton from '@views/common/back-button';
import RenderInputs from '@components/layout/render-inputs';
import history from '@app/history';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import useErrorState from '@utils/errorState';
import * as yup from 'yup';
import parseYupErrors from '@app/yup';
import { dummy } from '../../dummy';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 'calc(100% - 30px)',
    overflowY: 'auto'
  },
  backButton: {
    marginLeft: 0
  },
}))

const inputs = [
  {
    key: 'name',
    label: 'Name',
    type: 'input',
  },
  {
    key: 'description',
    label: 'Description',
    type: 'input',
    multiline: true,
  },
  {
    key: 'regularCredits',
    label: 'Regular Credits Amount',
    type: 'input',
    integer: true
  },
  {
    key: 'premiumCredits',
    label: 'Premium Credits Amount',
    type: 'input',
    integer: true
  },
  {
    key: 'price',
    label: 'Price',
    type: 'input',
    integer: true
  },
]

const defaultModel = {
  name: '',
  description: '',
  regularCredits: '',
  premiumCredits: '',
  price: '',
}

export default function SearchCreditForm() {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [creditId] = useState(search.get('creditId'));

  const { enqueueSnackbar } = useSnackbar();

  const [model, setModel] = useState(defaultModel);

  const classes = useStyles();

  const [errors, setErrors] = useErrorState({});

  useEffect(() => {
    if (creditId) {
      const item = dummy.creditPacks.find(el => el.id === +creditId);

      setModel({
        name: item.name,
        description: item.description || '',
        regularCredits: item.regularCredits,
        premiumCredits: item.premiumCredits,
        price: item.price,
      });
    }
  }, [creditId])

  const handleChange = (prop) => (event) => {
    let newModel;
    if (prop === 'regularCredits' || prop === 'premiumCredits' || prop === 'price') {
      newModel = { ...model, [prop]: /\d+/.test(Number(event.target.value)) ? event.target.value : model[prop] };
    } else {
      newModel = { ...model, [prop]: event.target.value };
    }
    setModel(newModel);

    if (errors[prop]) {
      schema.validateAt(prop, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [prop]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [prop]: err.message });
        });
    }
  };

  const schema = yup.object().shape({
    name: yup.string().label('Name').required().max(30),
    regularCredits: yup.string().label('Regular Credits Amount').required().max(10),
    premiumCredits: yup.string().label('Premium Credits Amount').required().max(10),
    price: yup.string().label('Price').required().max(10),
  });

  const onSave = () => {
    setErrors({});
    schema
      .validate(model, { abortEarly: false })
      .then(function (valid) {
        history.goBack();
        enqueueSnackbar(`Search Credit Pack "${model.name}" was successfully created!`, { variant: 'success' });
      })
      .catch(function (err) {
        console.log(err)
        setErrors(parseYupErrors(err), true);
      });
  }

  return (
    <React.Fragment>
      <InnerHeaderControls
        controlsLeft={<BackButton wrapped customButtonClasses={classes.backButton} />}
      />
      <Box className={classes.container}>
        <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center">
          <Box display="flex" my={3} mb={2} alignItems="center" justifyContent="center">
            <Typography variant="h4">{creditId ? 'Edit' : 'Add new'} search credit pack</Typography>
          </Box>
          {inputs.map((field, i) => (
            <Grid container item sm={4} key={`${field.key}-${i}`}>
              <RenderInputs
                ind={i}
                field={field}
                model={model}
                errors={errors}
                handleChange={handleChange}
              />
            </Grid>
          ))}
          <Box display="flex" mt={5} mb={2} alignItems="center" justifyContent="space-evenly" width={'100%'}>
            <Button style={{ width: '180px' }} variant={'contained'} onClick={onSave}>
              SAVE
            </Button>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  )
}
import React from 'react';
import {Box, IconButton, useScrollTrigger} from '@material-ui/core';
import BackIcon from '../../../assets/img/icons/back.png';
import history from '../../../app/history';

export default function SidesHeader(props) {

  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window : undefined,
  });

  return (
    <Box display="flex" position="fixed" className={`sides-header ${scrollTrigger?'scrolled': ''}`} elevation={(scrollTrigger? 1: 0)}>
      <Box display={{ xs: 'flex', sm: 'none' }} className="sides-header-absolute-inner" alignItems="center">
        <Box display="flex" ml={2}>
          <IconButton size="small" onClick={ () => history.push('/') }>
            <img src={BackIcon} alt="Back" />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" className="sides-header-inner" zIndex={1}>
        {props.children}
      </Box>
    </Box>
  );
}
import { makeStyles } from '@material-ui/core/styles';
import { sidebarWidth } from '@app/constants';

export const useStyles = makeStyles((theme) => ({
  content: {
    paddingRight: 50,
    minHeight: '100%'
  },
  drawerInsideWrap: {
    padding: '15px 0',
    height: '100%',
  },
  drawerRoot: {
    width: '100%',
    justifyContent: 'flex-start',
    // maxHeight: 'calc(100vh - 60px)',
  },
  drawerPaper: {
    whiteSpace: 'nowrap',
    position: 'static',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: theme.palette.drawer,
  },
  listItem: {
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
  },
  listItemRoot: {
    width:'260px',
    '&.Mui-selected': {
      '&:hover': {
        backgroundColor: '#fff',
      },
      backgroundColor: '#fff',
    }
  },
  leftSidebarActionText: {
    marginLeft: '14px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#5C6161',
  },
  leftSidebarActionTextActive: {
    color: '#000',
  },
  baseIconSettings: {
    height: '28px',
    width: '28px',
    color: 'transparent',
  },
  pageLayout: {
    height: '100%',
    maxHeight: 'calc(100% - 64px)',
    overflow: 'hidden',
  },
  leftSidebar: {
    width: sidebarWidth,
  },
  pageContent: {
    width: `calc(100% - ${sidebarWidth}px)`,
    display: 'flex',
    flexDirection: 'column',
    height: 'inherit',
    overflowY: 'hidden'
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    opacity: 0.8,
    background: '#E5E5E5',
    pointerEvents: 'none',
  },
  loader: {
    height: '50px',
    width: '50px',
    animation: '$spinner 1s linear infinite',
  },
  '@keyframes spinner': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  backButtonWrapper: {
    height: '80px',
  }
}))
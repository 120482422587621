import React from 'react';
import {ChevronLeftIcon} from '@app/icons';
import { Box, Breadcrumbs, IconButton, Link, Typography } from '@material-ui/core';

// crumbs: [{ label, onClick }]
export default function ButtonBackBreadcrumb({ goBack, crumbs }) {
  return (
    <Box display={'flex'} alignItems={'center'}>
      <IconButton onClick={goBack} style={{ padding: 4, marginRight: 8 }}>
        <ChevronLeftIcon width={24} height={24} />
      </IconButton>
      <Breadcrumbs aria-label="breadcrumb">
        {crumbs.map((crumb, iCrumb) => (
          <Box key={`${crumb.label}-${iCrumb}`}>
            {
              crumb.onClick ?
                <Link color="inherit" onClick={crumb.onClick}>
                  {crumb.label}
                </Link>
                :
                <Typography>{crumb.label}</Typography>
            }
          </Box>
        ))}
      </Breadcrumbs>
    </Box>
  )
}
import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import PageLayout from '@components/layout/page-layout';
import InnerHeader from '@components/layout/inner-header';
import { Route, Switch, Redirect } from 'react-router';
import { useParams } from 'react-router-dom';
import AllDocuments from './all-documents';
import history from '@app/history';
import Shared from './shared';
import Recieved from './recieved';
import HeaderButtonsBlock from './header-buttons-block';

const TABS = [
  {
    id: 'all',
    label: 'My Documents',
  },
  //   {
  //     id: 'shared',
  //     label: 'Shared',
  //   },
  //   {
  //     id: 'recieved',
  //     label: 'Recieved',
  //   },
];

export default function MyDocuments() {
  const { tab } = useParams();
  const [tabId, setTabId] = useState(tab || 'all');

  useEffect(() => {
    setTabId(tab);
  }, [tab]);

  const onTabChange = (index) => {
    const tabId = TABS[index].id;
    setTabId(tabId);
    history.push(`/documents/${tabId}`);
  };

  return (
    <PageLayout>
      <Box mx={6} height={'100%'}>
        <InnerHeader
          onTabChange={onTabChange}
          ind={TABS.findIndex((tab) => tab.id === tabId)}
          tabs={TABS}
          buttons={<HeaderButtonsBlock />}
        />
        <Box
          display="flex"
          flexDirection="column"
          mx={-2}
          px={2}
          maxHeight="calc(100% - 98px)"
          style={{ overflowY: 'auto' }}
        >
          <Switch>
            <Route exact path="/documents/all">
              <AllDocuments />
            </Route>
            <Route exact path="/documents/shared">
              <Shared />
            </Route>
            <Route exact path="/documents/recieved">
              <Recieved />
            </Route>
            <Redirect to={`/documents/${tabId}`} />
          </Switch>
        </Box>
      </Box>
    </PageLayout>
  );
}

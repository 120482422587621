import React from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {
  RiskRatingValue,
  RiskRatingWeight
} from '@app/icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #EDEDED'
  },
  scale: {
    height: '34px',
    '&:first-child': {
      borderBottom: '1px solid #EDEDED'
    }
  },
  labelCol: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px',
    lineHeight: '18px',
    display: 'flex',
    paddingLeft: '12px',
    alignItems: 'center',
    color: '#656565'
  },
  valueCol: ({readOnly}) => ({
    background: '#F8F8F8',
    width: '7.15%',
    color: '#A4A4A4',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '8px',
    lineHeight: '18px',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderLeft: '1px solid #EDEDED',
    cursor: 'pointer',
    '&.active': {
      background: '#FFFFFF',
      color: '#232323'
    },
    '&.disabled': {
      color: '#EDEDED'
    },
    '&:not(.active):not(.disabled):hover': !readOnly? {
      background: '#FFFFFF',
      color: '#232323'
    }: {}
  })
}))

export default function RiskRatingScale({id, readonly, riskValue, setRiskValue, weightValue, setWeightValue, readOnly = false }) {

  const classes = useStyles({
    readOnly
  })

  const onRiskValueSet = (value) => {
    if(!readonly) {
      setRiskValue(value)
    }
  }

  const onWeightValueSet = (value) => {
    if(!readonly) {
      setWeightValue(value)
    }
  }

  return <Box display={'flex'} flexDirection={'column'} flexGrow={1} className={classes.root}>
    <Box display={'flex'} flexGrow={1} className={classes.scale}>
      <Box display={'flex'} flexGrow={1} className={classes.labelCol}>
        <RiskRatingValue style={{width:'16px', height:'16px'}} /> Risk Value
      </Box>
      {Array.from(Array(11).keys()).map(value => (
        <Box
          key={`${id}-risk-${value}`}
          display={'flex'}
          className={clsx(
            classes.valueCol,
            value === riskValue && 'active',
            readOnly && value !== riskValue && 'disabled'
          )}
          onClick={() => onRiskValueSet(value)}
        >
          {value}
        </Box>
      ))}
    </Box>
    <Box display={'flex'} flexGrow={1} className={classes.scale}>
      <Box display={'flex'} flexGrow={1} className={classes.labelCol}>
        <RiskRatingWeight style={{width:'16px', height:'16px'}} /> Weight
      </Box>
      {Array.from(Array(11).keys()).map(value => (
        <Box
          key={`${id}-risk-${value}`}
          display={'flex'}
          className={clsx(
            classes.valueCol,
            value === weightValue && 'active',
            readOnly && value !== weightValue && 'disabled'
          )}
          onClick={() => onWeightValueSet(value)}
        >
          {value}
        </Box>
      ))}
    </Box>
  </Box>
}
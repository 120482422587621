import React from 'react';
import { RadioGroup, FormControlLabel, makeStyles } from '@material-ui/core';
import RadioButton from './radio-button';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  radioSelected: {
    color: '#232323',
  },
  formControlSize: {
    height: props => props.formControlSize || '32px',
  },
}));

export default function RadioButtonGroup(props) {
  const classes = useStyles(props);
  const {items, handleChange, selectedValue, row, labelPropsStyle } = props;

  return (
    <RadioGroup row={row}>
      {items.map((item, k) => (
        <FormControlLabel
          key={`radio-${item.value}-${k}`}
          value={item.value}
          control={
            <RadioButton
              value={item.value}
              onChange={handleChange}
              checked={selectedValue === item.value}
            />
          }
          label={
            <span className={clsx(selectedValue === item.value ? classes.radioSelected : '', labelPropsStyle)}>
              {item.label}
            </span>
          }
          className={classes.formControlSize}
        />
      ))}
    </RadioGroup>
  )
}
import React, { useEffect, useCallback, useState, useRef } from  'react';
import UserCardXL from '@components/cards/user-card-xl';
import { handleCardTitle, handleCardDescription } from '@utils/handleTitleCardXL';
import { Box, Grid, makeStyles } from '@material-ui/core';

import { backgroundCheck, loader } from '@store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { clientApi } from '@app/api';

import VirtualScroll from 'react-dynamic-virtual-scroll';
import { useWindowSize } from '@utils/useWindowSize';

export const useStyles = makeStyles((theme) => ({
  scrollWrapper: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: 60
  },
  scrollCard: {
    overflowY: 'auto',
    padding: '0 2px',
  },
  marginGreed: {
    margin: '5px 0',
  },
}));

const ADVANTAGE_PROVIDER = 'COMPLY_ADVANTAGE';

export default function CardListBlock({
  tabs,
  handleDeleteComment,
  prepareEditComment,
  handleEditComment,
  handleSupress,
  calcRate,
  onSupress,
  renderFooterBlock,
  tabIndex,
  minusHeight
}) {
  const [width, windowHeight] = useWindowSize(); // eslint-disable-line
  const classes = useStyles({ windowHeight });

  const { profilesVersionId, searchVersions, profilesVersion } = useSelector(store => store.searchHistory);
  const { sources, backupSources, currentSearch } = useSelector(store => store.backgroundCheck);

  const dispatch = useDispatch();

  const [dataRiskScore, setDataRisk] = useState(null);
  const [length, setLength] = useState(sources?.length + 1);

  const scrollRef = useRef();

  useEffect(() => {
    if (!!scrollRef.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  }, [tabIndex, sources?.length])

  useEffect(() => {
    setLength(sources?.length + 1)
  }, [sources])

  const changeRate = useCallback(async () => {
    if (!dataRiskScore) return;

    const { sourceId, riskScore } = dataRiskScore;
    setDataRisk(null);
    const card = sources.find(el => el.id === sourceId);

    const data = {
      sourceId,
      payload: {
        bgCheckRiskRating: riskScore,
        relevant: card.relevant,
      },
    }
    const res = await clientApi.source.updateSourceReview(data);

    if (res.status === 200) {
      const changedSources = sources.map(item => item.id === sourceId ? ({ ...item, bgCheckRiskRating: riskScore }) : item);
      dispatch(backgroundCheck.setChangedSources(changedSources));
      calcRate(backupSources.map(item => item.id === sourceId ? ({ ...item, bgCheckRiskRating: riskScore }) : item));
    }
  }, [dataRiskScore, sources, backupSources, dispatch, setDataRisk, calcRate])

  useEffect(() => {
    if (dataRiskScore) {
      changeRate();
    }
  }, [dataRiskScore, changeRate]);

  useEffect(() => {
    dispatch(loader.setMoreLoader(false));
  }, [sources, dispatch]);

  const onSelect = useCallback((e, id) => {
    const selected = sources.map(
      (item) => item.id === id
        ? ({ ...item, isChecked: !item.isChecked })
        : item
    );
    dispatch(backgroundCheck.setChangedSources(selected));

    const isSelectedAll = selected.every(item => item.isChecked);
    dispatch(backgroundCheck.setSelectAllSources(isSelectedAll));
  }, [sources, dispatch])

  const addComment = useCallback(async (comment, sourceId) => {
    if (!comment.trim()) return;

    const res = await clientApi.source.addNote(sourceId, { note: comment });

    if (res.status === 200) {
      const changedSources = sources.map(item => item.id === sourceId ? ({ ...item, notes: res.data.notes }) : item);
      dispatch(backgroundCheck.setChangedSources(changedSources));
    }
  }, [dispatch, sources])

  const renderCard = useCallback((index) => {
    const el = sources[index];

    return (
      el ? (
        <UserCardXL
          {...el}
          key={`user-card-${index}-${el.id}`}
          title={el.title ? handleCardTitle(el.title, currentSearch.searchProvider === ADVANTAGE_PROVIDER, el.listType) : 'N/A'}
          description={el.title ? handleCardDescription(el.title) : ''}
          tabs={tabs}
          onSelect={onSelect}
          changeRate={(riskScore) => setDataRisk({ riskScore, sourceId: el.id })}
          addComment={(comment) => addComment(comment, el.id)}
          handleSupress={handleSupress}
          onSupress={onSupress}
          handleDeleteComment={(noteId) => handleDeleteComment(el.id, noteId)}
          prepareEditComment={(noteId) => prepareEditComment(el.id, noteId)}
          handleEditComment={(noteId, note, noChange) => handleEditComment(noteId, el.id, note, noChange)}
          isSearchVersions={!!searchVersions.length}
          isProfilesVersion={!!profilesVersionId}
          index={index}
          isAcknowledged={profilesVersion.isAcknowledged}
        />
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
          {renderFooterBlock}
        </Box>
      )
    )
  }, [searchVersions, sources, tabs, addComment, currentSearch, handleDeleteComment, handleEditComment, prepareEditComment, onSelect, handleSupress, onSupress, profilesVersionId, renderFooterBlock, profilesVersion])

  return (
    <Box ref={scrollRef} className={classes.scrollCard} flexGrow={1}>
      <Grid
        container
        alignItems={'center'}
        justify={'center'}
        className={classes.marginGreed}
      >
        <VirtualScroll
          className={classes.scrollWrapper}
          minItemHeight={90}
          totalLength={length}
          length={length}
          renderItem={renderCard}
          buffer={0}
        />
      </Grid>
    </Box>
  )
};

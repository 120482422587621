import React, { useEffect, useState } from 'react';
import {
  Box,
} from '@material-ui/core';
import { Modal } from '@components/modals';
import EnterOtp from '../complex/enter-otp';

export default function ConfirmOtp({ open, errorMsg, onSubmit, onResend, onClose }) {
  const [code, setCode] = useState('');

  useEffect(() => {
    if (!open) {
      setCode('');
    }
    }, [open]) // eslint-disable-line

  const handleSubmitOtp = () => {
    onSubmit(code);
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Phone Number Confirmation"
      mainText="Enter the one-time password you have received in SMS"
      content={
        <Box width={400} margin="auto" mb={3}>
          <EnterOtp
            loaded={open}
            errorMsg={errorMsg}
            onResend={onResend}
            code={code}
            setCode={setCode}
          />
        </Box>
      }
      actions={[{
        type: 'secondary',
        label: 'CONTINUE',
        action: handleSubmitOtp,
        style: { width: 250 },
      }]}
      actionsDirection="column"
    />
  );
}
import React, { useEffect, useState } from 'react';
import {
  AddNoteIcon,
  AddNoteInactiveIcon,
  DropdownDownIcon,
  NotesIcon,
} from '@app/icons';
import { Box, Collapse } from '@material-ui/core';
import CustomIconButton from '@components/buttons/button-with-icon';
import { Modal } from '@components/modals';
import { makeStyles } from '@material-ui/core/styles';
import AddCommentField from '@components/layout/add-comment';
import CardComment from '@components/layout/card-comments';
import CustomTooltip from '@components/controls/tooltip';
import { clientApi } from '@app/api';
import prepareTimestamp from '@utils/date';
import clsx from 'clsx';

import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { backgroundCheck } from '@store/actions';

export const useStyles = makeStyles((theme) => ({
  spacesUserInfoButton: {
    marginLeft: '-5px',
    marginBottom: 5,
  },
  addCommentWrapper: {
    width: 240,
  },
  allCommentsWrapper: {
    width: 240,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '2px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px #EDEDED',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#A4A4A4',
    },
  },
  customButtonColor: {
    color: theme.palette.text.BL1,
  },
  showAddCommentButton: {
    background: theme.palette.background.GY1,
  },
  customButtonModalWidth: {
    width: '180px',
  },
}));

export default function UserInfoCardNotes({
  accordion,
  setAccordion,
  accordionName,
}) {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [profileId] = useState(search.get('profileId'));

  const { profilesVersionId } = useSelector(store => store.searchHistory);
  const { selectedProfile } = useSelector(store => store.backgroundCheck);
  const dispatch = useDispatch();

  const classes = useStyles();

  const [pendingDeleteCommentId, setPendingDeleteCommentId] = useState(null);
  const [showAddComment, setShowAddComment] = useState(false);
  const [userNotes, setUserNotes] = useState(selectedProfile.notes || []);
  const [editNotes, setEditNotes] = useState(null);

  useEffect(() => {
    setUserNotes(selectedProfile.notes || []);
  }, [selectedProfile.notes])

  // Remove selected notes from notes list
  const prepareEditComment = noteId => {
    if (!selectedProfile.notes) return;
    dispatch(backgroundCheck.setSelectedProfile({
      ...selectedProfile,
      notes: selectedProfile.notes.filter((el) => el.id !== noteId),
    }));
  }

  const editComment = noteId => {
    const note = userNotes.find((el) => el.id === noteId);
    setEditNotes({ note: note.note, noteId, notes: userNotes });
    prepareEditComment(noteId);
    setShowAddComment(true);
  }

  const addCommentToProfile = async (comment) => {
    if (!comment.trim()) return;

    clientApi.profile.addNote(profileId, { note: comment }).then((res) => {
      dispatch(backgroundCheck.setSelectedProfile({
        ...selectedProfile,
        notes: res.data.notes,
      }));
    })
  }

  const handleEditComment = (noteId, note, noChange) => {
    if (note && !noChange) {
      clientApi.profile.updateNote(profileId, noteId, { note }).then((res) => {
        dispatch(backgroundCheck.setSelectedProfile({
          ...selectedProfile,
          notes: res.data.notes,
        }));
      });
    } else {
      // Re-add selected note to notes list
      dispatch(backgroundCheck.setSelectedProfile({
        ...selectedProfile,
        notes: editNotes.notes,
      }));
    }
    setEditNotes(null);
    setShowAddComment(false);
  }

  const onDeleteComment = (id) => {
    setPendingDeleteCommentId(id);
  }

  const handleDeleteComment = () => {
    clientApi.profile.deleteNote(profileId, pendingDeleteCommentId).then((res) => {
      dispatch(backgroundCheck.setSelectedProfile({
        ...selectedProfile,
        notes: res.data.notes,
      }));
    }).finally(() => setPendingDeleteCommentId(null));
  }

  // Ignore clicks on "Add note" button
  // It means that user tries to click on the "Add" button, hoping to close the textbox.
  // In this case, we don't have to run open/close textbox twice.
  const handleAddCommentBlur = (open, e) => {
    const ignoredIds = ['addNoteBtn'];
    if (!ignoredIds.includes(e.relatedTarget?.id)) {
      setShowAddComment(open);
    }
  }

  return (
    <>
      <Modal
        open={!!pendingDeleteCommentId}
        onClose={() => setPendingDeleteCommentId(null)}
        title="Delete note"
        mainText="Are you sure you want to delete this note?"
        actions={
          [
            {
              type: 'main',
              label: 'No',
              action: () => setPendingDeleteCommentId(null),
            },
            {
              type: 'secondary',
              label: 'Yes',
              action: () => handleDeleteComment(),
              disabled: profilesVersionId
            },
          ]
        }
        propsClassses={classes.customButtonModalWidth}
        actionsDirection="row"
      />

      <Box mt={3} className={classes.spacesUserInfoButton}>
        <CustomTooltip
          title="Only the last version can be updated, the previous versions are read only"
          placement="top"
          disableHoverListener={!profilesVersionId}
          width={100}
        >
          <span>
            <CustomIconButton
              startIcon={(!!profilesVersionId) ? <AddNoteInactiveIcon /> : <AddNoteIcon />}
              className={clsx('minLabel', showAddComment && classes.showAddCommentButton)}
              onClick={() => setShowAddComment(!showAddComment)}
              disabled={!!profilesVersionId}
              id="addNoteBtn"
            >
              Add Note
            </CustomIconButton>
          </span>
        </CustomTooltip>
        <div className={classes.addCommentWrapper}>
          <AddCommentField
            open={showAddComment}
            setOpen={handleAddCommentBlur}
            addComment={(comment) => {
              addCommentToProfile(comment);
              setShowAddComment(!showAddComment);
            }}
            editNotes={editNotes}
            handleEditComment={handleEditComment}
          />
        </div>
      </Box>
      {!!selectedProfile.notes?.length &&
        <Box className={classes.spacesUserInfoButton}>
          <CustomIconButton
            classes={{ root: classes.customButtonColor }}
            className="minLabel"
            startIcon={<NotesIcon />}
            endIcon={<DropdownDownIcon className={accordion[accordionName] ? classes.rotate : ''} />}
            onClick={() => setAccordion(accordionName)}
          >
            All Notes
          </CustomIconButton>
        </Box>
      }
      <Collapse in={accordion[accordionName]} className={classes.allCommentsWrapper}>
        {selectedProfile.notes && selectedProfile.notes.map((note) => (
          <CardComment
            title={`Me, ${prepareTimestamp(note.createdOn, 'MM/DD/YYYY')}`}
            updatedAt={note.updatedOn ? `Edited ${prepareTimestamp(note.updatedOn, 'MM/DD/YYYY hh:mm A')}` : ''}
            comment={note.note}
            key={`all-comments-${note.id}`}
            handleDeleteComment={() => onDeleteComment(note.id)}
            handleEditComment={() => editComment(note.id)}
            isProfileCard={true}
            disabled={!!profilesVersionId}
            maxLength={2000}
          />
        ))}
      </Collapse>
    </>
  )
}

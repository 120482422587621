import React from 'react';
import NativeCheckbox from '@material-ui/core/Checkbox';

import {CheckboxActiveIcon, CheckboxInactiveIcon} from '@app/icons';

export default function Checkbox(props) {

  return (
    <NativeCheckbox
      {...props}
      className="checkbox-user-card"
      checkedIcon={<CheckboxActiveIcon/>}
      icon={<CheckboxInactiveIcon/>}
    />
  )
}
const config = {
  MuiInputBase: {
    input: {
      '&:focus': {
      },
      padding: 0,
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
      color: '#232323',
      '&.Mui-disabled': {
        opacity: 0.5,
      },
    },
  },
  // OUTLINED INPUT
  MuiOutlinedInput: {
    root: {
      borderRadius: '12px',
      '&$focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#A4A4A4'
      },
      '&.MuiOutlinedInput-marginDense': {
        height: 'auto',
        background: '#F8F8F8'
      },
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent !important',
        opacity: 0.5
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#A4A4A4',
      },
    },
    notchedOutline: {
      top: 0,
      borderColor: 'transparent',
      boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.15)',
      '& legend': {
        display: 'none'
      }
    },
    input: {
      padding: '16px 20px'
    },
    inputMarginDense: {
      paddingTop: '5px',
      paddingBottom: '5px'
    },
    multiline: {
      padding: '24px 20px 16px 20px',
    },
    inputMultiline: {
      color: '#656565',
      lineHeight: '14px',
      fontSize: '12px',
      fontWeight: 300,
    }
  },
  MuiInputLabel: {
    outlined: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '10px',
      color: '#656565',
      transform: 'translate(20px, 21px) scale(1)',
      '&$marginDense': {
        transform: 'translate(20px, 16px) scale(1)'
      },
      '&$shrink': {
        transform: 'translate(20px, 8px) scale(1)'
      },
      '&$error': {
        color: '#000'
      },
      '&$focused': {
        color: '#656565',
      },
      '&.Mui-disabled': {
        color: '#C6C6C6'
      },
    },
    shrink: {
      fontWeight: 'normal !important',
      fontSize: '10px !important',
      lineHeight: '18px !important',
      '& ~ .MuiOutlinedInput-root input': {
        padding: '24px 20px 8px 20px !important'
      }
    },
  },
  MuiFormControl: {
    root: {
      marginTop: '5px',
      marginBottom: '5px'
    },
    marginDense: {
      marginTop: '3px',
      marginBottom: '3px'
    }
  },
  MuiFormHelperText: {
    root: {
      textAlign: 'center'
    }
  },
  MuiSelect: {
    select: {
      '&:focus': {
        backgroundColor: 'transparent'
      }
    },
    icon: {
      '&$disabled': {
        display: 'none'
      }
    },
    outlined: {
      padding: '24px 20px 8px 20px'
    },
  },
}

export default config
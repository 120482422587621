import React, { useMemo } from 'react';
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  MenuItem,
  Grid,
  makeStyles,
  Divider,
  Collapse,
  IconButton
} from '@material-ui/core';
import CustomTooltip from '@components/controls/tooltip';

import CheckboxLight from '@components/inputs/checkbox-light';

import RegularPagination from '@components/pagination/regular-pagination';
import { SortTableIcon, SortTableAscIcon, SortTableDescIcon, DragAndDropIcon } from '@app/icons';
import MenuWithContext from '../dropdowns/menu-with-context';
import DefaultTableCell from './table-cells/default-table-cell';
import TextIconTableCell from './table-cells/text-icon-table-cell';
import IconButtonTableCell from './table-cells/icon-button-table-cell';
import ButtonTableCell from './table-cells/button-table-cell';
import CustomTableCell from './table-cells/custom-table-cell';
import TruncatedTableCell from './table-cells/truncated-table-cell';
import clsx from 'clsx';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const useStyles = makeStyles((theme) => ({
  checkboxCell: {
    width: '0%',
    paddingRight: 0,

  },
  checkboxCellDummy: {
    width: 44,
    paddingRight: 0,
  },
  actionCell: {
    width: 24,
    paddingLeft: 8,
    paddingRight: 8,
  },
  buttonActive: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  },
  headerCell: {
    whiteSpace: 'nowrap'
  },
  headerActionCell: {
    whiteSpace: 'nowrap',
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
  },
  tableRow: {
    '& .inlineActionsHoverOnly': {
      opacity: 0,
      transition: 'opacity 0.2s ease-in-out'
    },
    '&:hover': {
      '& .inlineActionsHoverOnly': {
        opacity: 1
      }
    },
    '&.disabled': {
      background: '#F8F8F8',
      '& .MuiTableCell-body': {
        color: '#A4A4A4'
      }
    }
  }
}));

/** UPDATE THIS LIST WHEN ADDING NEW PROPS
 * Items may contain:
 *  - isDisabled (bool): Disable actions on this row and use disable style
 *  - isChecked (bool): This row is checked on checkbox column
 *  - hiddenFields (array): Array of hidden fields for this row
 *  - rowProps (object): Props to be passed to all TableCell of this item (row)
 *  - cellProps (object): Props to be passed to TableCell with matching field property name
 *  - threeDotsActions (array): Array of labels of whitelisted three dots actions for this row. Only whitelist actions if provided
 *    ex: { firstName: { style: { color: 'red' } } } -> will apply color red to column 'firstName' of this row.
 *  - inlineActions - inline action buttons shown in the end of the row
 *  - inlineActionsHoverOnly - hide inline buttons until row hovered
 *  - disabledRow
 *
 * Columns may contain:
 *  - cellType (TCELL_TYPES): Type of cell the column should be rendered to, default to DefaultTableCell
 *  - field (string): key/name of the field to be used internally
 *  - headerName (string): Text to show as header of the field
 *  - bold (boolean): Should this column be shown in bold format
 *  - colProps (object): Props to be passed to all TableCell of this column
 *  - triggerSelect bool - this column click doing same as select checkbox click
 * 
 *  cellProps > rowProps > colProps
 * 
 * DefaultTableCell:
 *  - useTooltip (bool): Field value will be shown in tooltip when hovered over
 * 
 * TextIconTableCell:
 *  - icon (func): Function to render icon for the item
 *  - useTooltip (bool): Field value will be shown in tooltip when hovered over
 * 
 * IconButtonTableCell:
 *  - field (string): represent which handlers function to be used
 *  - icon (Component): Icon to be used inside the IconButton component
 *  - tooltip (string): Tooltip for the IconButton
 * 
 * ButtonTableCell:
 *  - field (string): represent which handlers function to be used
 *  - label (string): text to be shown on the button
 * 
 * CustomTableCell:
 *  - custom (func): Function to render anything as table cell's child
 *  - preventClick (bool): Exclude the table cell from row's onClick event
 * 
 * TruncatedTableCell:
 *  - useTooltip (bool): Field value will be shown in tooltip when hovered over
 */

const moveItemInArray = (sourceItems, startIndex, endIndex) => {
  const newItems = [...sourceItems]
  const [removed] = newItems.splice(startIndex, 1)
  newItems.splice(endIndex, 0, removed)
  return newItems
}

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  ...(isDragging && {
    background: '#F8F8F8',
    alignItems: 'center',
    display: 'flex'
  })
})

export default function TableList(props) {
  const classes = useStyles();
  const {
    items, // array of items to use
    columns, // array of column config objects
    threeDotsActions, // array of actions to show under three dots button
    handlers, // custom functions of respective fields
    page, pagesCount, totalCount, pageSize, setPageSize, onPageChange, pageSizes,
    onSelect, selectAll, onClick,
    onSort, sortableColumns, sort, sortDirection,
    maxHeight, collapseClick, collapseColumns, onSelectCollapse,
    threeDotsActionsCollapse, headAction,
    inlineActions, inlineActionsHoverOnly, disabledRow,
    showHeader = true,
    sortable, onSortChange, // Drag and drop sorting
    tableRowProps,
  } = props;

  const handleAction = (itemId, actionHandler, collapseItemID) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    actionHandler && actionHandler(itemId, event, collapseItemID);
  }

  // Prevent table cell from triggering row onClick event
  const preventClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  }

  const handleSort = (field) => {
    if (onSort) {
      if (sortableColumns.indexOf(field) !== -1) {
        onSort(field)
      }
    }
  }

  const getSortIcon = (field) => {
    if (field === sort) {
      if (sortDirection === 'ASC') {
        return <SortTableAscIcon />
      } else {
        return <SortTableDescIcon />
      }
    }
    return <SortTableIcon />
  }

  /**
     * For select all, onSelect function will receive param id = 'all'
     */
  const handleSelectAll = () => {
    onSelect('all', !isAllItemChecked);
  }

  const isAllItemChecked = useMemo(() => {
    return items.every((item) => item.isChecked || (!item.isChecked && item.isDisabled));
  }, [items])

  const renderCell = (item, col, i) => {
    switch (col.cellType) {
      case TCELL_TYPES.TEXT_ICON:
        return <TextIconTableCell key={i} col={col} item={item} />;

      case TCELL_TYPES.TRUNCATED:
        return <TruncatedTableCell key={i} col={col} item={item} />;

      case TCELL_TYPES.ICON_BUTTON:
        return <IconButtonTableCell key={i} col={col} item={item} handleAction={handleAction} handlers={handlers} preventClick={preventClick} />;

      case TCELL_TYPES.BUTTON:
        return <ButtonTableCell key={i} col={col} item={item} handleAction={handleAction} handlers={handlers} preventClick={preventClick} />

      case TCELL_TYPES.CUSTOM:
        return <CustomTableCell key={i} col={col} item={item} onSelect={handleAction(item.id, onSelect)} preventClick={preventClick} />;

      default:
        return <DefaultTableCell key={i} col={col} item={item} />;
    }
  }

  const getIsDisabled = (action, item) => {
    if (item.threeDotsActionsDisabled && item.threeDotsActionsDisabled.includes(action.label)) {
      return true
    }
    if (typeof action.disabled === 'undefined') {
      return false
    }
    if (typeof action.disabled === 'function') {
      return action.disabled(item)
    }
    return action.disabled
  }

  const renderThreeDotsActions = (defaultItem, collapseItem) => {
    const item = defaultItem || collapseItem;
    return (
      <TableCell className={classes.actionCell} onClick={preventClick}>
        <Box display={'flex'}>
          <MenuWithContext title="More Actions" disabled={item.isDisabled}>
            {threeDotsActions
              .filter(e => item.threeDotsActions ? item.threeDotsActions.includes(e.label) : true)
              .filter(action => !action.hidden || !action.hidden(item.id, item))
              .map((action, i) => (
                <MenuItem
                  key={`menu-${action.label}-${i}`}
                  onClick={() => action.handler(item.id, item)}
                  disabled={getIsDisabled(action, item)}
                  classes={{ root: action.classes }}
                >
                  {action.label}
                </MenuItem>
              ))}
          </MenuWithContext>
        </Box>
      </TableCell>
    )
  }

  const renderInlineActions = (item) => {
    return (
      <TableCell onClick={preventClick} align={'left'}>
        <Box display={'flex'} alignItems={'center'}>
          {inlineActions
            .filter(action => !action.hidden || !action.hidden(item.id, item))
            .map((action, iInlineAction) => (
              <Box className={inlineActionsHoverOnly && 'inlineActionsHoverOnly'} display={'flex'} key={`item-${item.id}-inline-action-${iInlineAction}`}>
                <CustomTooltip
                  title={action?.tooltip}
                  placement="top"
                >
                  <IconButton
                    onClick={() => action.handler(item.id, item)}
                    size={'small'}
                  >
                    {action.icon}
                  </IconButton>
                </CustomTooltip>
              </Box>
            ))}
        </Box>
      </TableCell>
    )
  }

  // Drag and drop commit order
  const onDragEnd = ({ source, destination }) => {
    if (!destination) {
      return
    }
    const newItems = moveItemInArray(
      items,
      source.index,
      destination.index
    )
    onSortChange(newItems)
  }

  const DraggableComponent = (id, index) => (props) => {
    return (
      <Draggable draggableId={String(id)} index={index}>
        {(provided, snapshot) => (
          <TableRow
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            {...props}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            {props.children}
          </TableRow>
        )}
      </Draggable>
    )
  }

  const DroppableComponent = (props) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={'body'} direction="vertical">
          {(provided) => {
            return (
              <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                {props.children}
                {provided.placeholder}
              </TableBody>
            )
          }}
        </Droppable>
      </DragDropContext>
    )
  }

  return (
    <Box display={'flex'} flexGrow={1} mx={-2}>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer style={{ maxHeight: maxHeight }}>
            <Table stickyHeader>
              {showHeader &&
                <TableHead>
                  <TableRow {...tableRowProps}>
                    {sortable &&
                      <TableCell />
                    }
                    {onSelect &&
                      <TableCell className={classes.checkboxCell}>
                        {selectAll &&
                          <CheckboxLight
                            checked={isAllItemChecked}
                            onClick={handleSelectAll}
                          />
                        }
                      </TableCell>
                    }
                    {columns.map((col, index) => (
                      <TableCell onClick={() => handleSort(col.field)} key={index} className={classes.headerCell} {...col.headerProps}>
                        {col.headerName}
                        {sortableColumns && sortableColumns.indexOf(col.field) !== -1 &&
                          <span>&nbsp;{getSortIcon(col.field)}</span>
                        }
                      </TableCell>
                    ))}
                    {headAction && <TableCell className={classes.headerActionCell}>{headAction}</TableCell>}
                    {inlineActions && <TableCell />}
                    {threeDotsActions && <TableCell />}
                  </TableRow>
                </TableHead>
              }
              <TableBody component={sortable && DroppableComponent}>
                {items.map((item, index) => {
                  return (
                    <React.Fragment key={`table-${item?.id}-row-${index}`}>
                      <TableRow
                        key={item.id}
                        className={clsx(classes.tableRow, (disabledRow && disabledRow(item.id, item)) && 'disabled')}
                        hover={!!onClick}
                        onClick={() => onClick && onClick(item.id, item)}
                        style={{ backgroundColor: item.backgroundColorRow }}
                        component={sortable && DraggableComponent(item.id, index)}
                        {...tableRowProps}
                      >
                        {sortable &&
                          <TableCell>
                            <DragAndDropIcon />
                          </TableCell>
                        }
                        {onSelect &&
                          <TableCell className={classes.checkboxCell} onClick={preventClick}>
                            <CheckboxLight
                              checked={!!item.isChecked}
                              onClick={handleAction(item.id, onSelect)}
                              disabled={item.isDisabled}
                            />
                          </TableCell>
                        }
                        {columns.map((col, i) => renderCell(item, col, i))}
                        {headAction && <TableCell />}
                        {inlineActions && renderInlineActions(item)}
                        {threeDotsActions && renderThreeDotsActions(item, null)}
                      </TableRow>
                      {(item.collapse && item.isOpen) && (
                        <TableCell colSpan={'100%'} style={{ padding: 0 }}>
                          <Collapse in={item.isOpen} timeout="auto">
                            <Table>
                              <TableBody>
                                {item.collapse.map((el, i) => (
                                  <TableRow key={el.id} hover={!!collapseClick} onClick={() => collapseClick && collapseClick(el.id, el)}>
                                    {onSelectCollapse ? (
                                      <TableCell className={classes.checkboxCell} onClick={preventClick} style={{ paddingRight: 16 }}>
                                        <CheckboxLight
                                          checked={!!el.isChecked}
                                          onClick={handleAction(item.id, onSelectCollapse, el.id)}
                                          disabled={el.isDisabled}
                                        />
                                      </TableCell>
                                    ) : onSelect && (
                                      <TableCell className={classes.checkboxCellDummy} />
                                    )}
                                    {collapseColumns.map((col, i) => renderCell(el, col, i))}
                                    {threeDotsActionsCollapse && renderThreeDotsActions(null, el)}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Collapse>
                        </TableCell>
                      )}
                    </React.Fragment>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {page !== undefined &&
          <React.Fragment>
            <Grid item xs={12}>
              <Box mx={2} justifyContent={'center'} my={1}>
                <Divider />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mx={2}>
                <RegularPagination
                  page={page}
                  pagesCount={pagesCount}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  pageSizes={pageSizes}
                  totalCount={totalCount}
                  onChange={onPageChange}
                />
              </Box>
            </Grid>
          </React.Fragment>
        }
      </Grid>
    </Box>
  )
}

export const TCELL_TYPES = {
  TEXT_ICON: 'textIcon',
  ICON_BUTTON: 'iconButton',
  BUTTON: 'button',
  TRUNCATED: 'truncated',
  CUSTOM: 'custom',
}
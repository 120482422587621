import { makeStyles } from '@material-ui/core/styles';
import { sidebarWidth } from '@app/constants';

export const useStyles = makeStyles((theme) => ({
  sideTree: {
    position: 'relative',
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    display: 'flex',
    height: '100%',
    width: sidebarWidth,
    overflow: 'visible',
    flexDirection: 'row',
  },
  sideTreeCollapse: {
    position: 'relative',
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    display: 'flex',
    height: '100%',
    width: '50px',
    alignItems: 'center',
    flexDirection: 'column',
  },
  scrollableTreeWrapper: {
    height: 'calc(90% - 96px)',
  },
  content: {
    width: `calc(100% - ${sidebarWidth}px)`,
    overflowY: 'none',
  },
  borderlessButton: {
    color: theme.palette.text.LB3,
    '&:hover': {
      backgroundColor: 'inherit',
    }
  },
  popover: {
    padding: '35px',
    boxShadow: '0px 9px 26px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    borderRadius: '12px',
    whiteSpace: 'nowrap'
  },
  buttonActive: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  },
  collapseMenuButton: {
    width: '30px',
    height: '30px',
    border: '1px solid #E7E7E7',
    boxSizing: 'border-box',
    boxShadow: '4px 0px 4px rgba(0, 0, 0, 0.02)',
    borderRadius: '20px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    background: '#FFF',
    cursor: 'pointer',
    transition: 'background 0.1s',
    marginTop: '30px',
    '&:hover': {
      background: '#EDEDED',
    },
    '&.in': {
      marginTop: 0,
      position: 'absolute',
      top: '30px',
      right: '-15px'
    }
  },
  chip: {
    '& .MuiChip-label': {
      paddingRight: '4px'
    },
    verticalAlign: 'unset',
  },
  deleteChipIcon: {
    height: '14px',
    width: '14px',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: theme.palette.background.GY2
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '-3px'
  },
}));

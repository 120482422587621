
export const minimumZero = (value) => {
  if (!value) {
    return value;
  }

  return value < 0 ? 0 : value;
}

// Replace thousands with k
export const formatThousands = (value) => {
  if (!value)  {
    return value;
  }

  return value >= 1000 ? `${value / 1000}k` : value
}

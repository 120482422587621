import React from 'react';
import { Typography, Box } from '@material-ui/core';
import prepareTimestamp from '@utils/date';
import ExpiryDateLabel from '@components/flexible-icons/expiry-date-label';

import { useStyles } from '../styles';

export default function AdditionalDocuments({ item }) {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="row" alignItems="center" width={'100%'} justifyContent="space-between">
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography className={classes.cardTagLabel}>CATEGORY</Typography>
        <Typography className={classes.cardTagValue}>
          {item.category || 'N/A'}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" >
        <Typography className={classes.cardTagLabel}>TYPE</Typography>
        <Typography className={classes.cardTagValue}>
          {item.documentType || 'N/A'}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography className={classes.cardTagLabel}>DATE ADDED</Typography>
        <Typography className={classes.cardTagValue}>
          {item.uploadDate
            ? prepareTimestamp(item.uploadDate, 'MM/DD/YYYY')
            : 'N/A'}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography className={classes.cardTagLabel}>ISSUED DATE</Typography>
        <Typography className={classes.cardTagValue}>
          {prepareTimestamp(item.issueDate, 'MM/DD/YYYY') || 'N/A'}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" mr={2}>
        <Typography className={classes.cardTagLabel}>EXPIRY DATE</Typography>
        <Typography className={classes.cardTagValue}>
          {item.expiryDate
            ? ExpiryDateLabel(item.expiryDate, item.status)
            : 'N/A'}
        </Typography>
      </Box>
    </Box>
  );
}

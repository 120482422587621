import React, { useState, useCallback, useEffect, useRef } from 'react';
import {Box, Container, Grid, Button, Typography} from '@material-ui/core';

import MagnifierImg from '../../../assets/landing/backgrounds/magnifier.png';
import Parallax1Img from '../../../assets/landing/backgrounds/parallax-1.png';
import Parallax2Img from '../../../assets/landing/backgrounds/parallax-2.png';
import Parallax3Img from '../../../assets/landing/backgrounds/parallax-3.png';

import CardIcon1Img from '../../../assets/img/cards/card-1.svg';
import CardIcon2Img from '../../../assets/img/cards/card-2.svg';
import CardIcon3Img from '../../../assets/img/cards/card-3.svg';

import {Parallax} from 'react-scroll-parallax';

import CheckedOption from './components/checked-option';
import Search from './components/search';
import Card from './components/card';

import LayoutHeader from '@components/layout/header';
import history from '@app/history';

import AdvancedCard from '@views/protected/client/background-check/advanced-card';

import { clientApi } from '@app/api';
import CustomTooltip from '@components/controls/tooltip';

import SubRoutes from './sub-routes';

import { useStyles } from './styles';
import Footer from '@components/layout/footer';
import { useSnackbar } from 'notistack';

const options = [
  'Background check',
  'Smart updating mechanism',
  'Risk score',
  'More than KYC',
  'Custom Risk Templates',
  'AML Compliance',
  'Document Management',
  'Electronic Identity Verification'
]

export default function Landing() {
  const classes = useStyles();
  const [inputData, setInputData] = useState('');
  const [requestData, setRequestData] = useState({});
  const [profilesProps, setProfilesProps] = useState({});
  const [isProfiles, setIsProfiles] = useState(false);
  const [quantitySearches, setQuantitySearches] = useState();
  const [isEmtySearch, setIsEmptySearch]= useState('');
  const [profileId, setProfileId] = useState('');
  const [type, setType]= useState('PERSON');
  const scrollArea = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const getRemainingSearchAttempts = async () => {
    const res = await clientApi.search.getRemainingSearchAttempts();

    if (res.status === 200) {
      setQuantitySearches(res.data.remainingSearchAttempts);
    }
  }

  useEffect(() => {
    if (!!isProfiles && scrollArea.current) {
      const scrollTop = scrollArea?.current?.scrollTop;
      scrollArea.current.scrollTo(0, scrollTop + 380);
    }
  }, [isProfiles])
    
  useEffect(() => {
    getRemainingSearchAttempts();
  }, [])

  useEffect(() => {
    if (quantitySearches === null) {
      setInputData('');
      setRequestData({});
      setIsEmptySearch('');
    }

  }, [quantitySearches])

  const handleInputChange = e => {
    setInputData(e.target.value);
    setIsEmptySearch('');
    setIsProfiles(false);
  }

  const handleRequestData = useCallback((data) => {
    setRequestData(data)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestData]);

  const getEntities = async () => {
    const name = inputData.trim();

    if (!name || name?.length < 2) {
      setIsEmptySearch(name?.length < 2
        ? 'The length of the searching value must be at least two characters!'
        : 'Name is a required field!');
      return;
    }

    let data = {
      type: 'PERSON',
      name,
      listTypes: ['SANCTIONS'],
    };
    for (const key in requestData) {
      if (!!requestData[key]) {
        data = { ...data, [key]: requestData[key] }
      }
    }

    if (!!data.yob && +data.yob < 1900) {
      enqueueSnackbar(`"Year of Birth" should be between 1900 and ${new Date().getFullYear()}!`, { variant: 'error' });
      return;
    }

    try {
      const res = await clientApi.search.searchEntities(data, true);
    
      if (res.status === 200) {
        setProfilesProps({ searchId: res.data.searchId, name });
        setInputData('');
        setIsProfiles(true);
        setQuantitySearches(res.data.remainingFreeSearches);
      } else if (res.status === 422) {
        setIsProfiles(true);
        setQuantitySearches(null);
      }
    } catch (e) {
      setIsProfiles(true);
      setQuantitySearches(null);
    }
  }

  const redirect = (path) => {
    const { searchId, name } = profilesProps;
    const link = profileId
      ? `/${path}?searchId=${searchId}&profileId=${profileId}&searchName=${name}`
      : searchId
        ? `/${path}?searchId=${searchId}&searchName=${name}`
        : `/${path}`;

    history.push(link);
  }

  return (
    <React.Fragment>

      <Box ref={scrollArea} pt={'64px'} maxHeight="calc(100% - 64px)" id="landing-wrapper">
        <LayoutHeader scrollRef={scrollArea.current} redirect={redirect} />
        <Box className={'position-relative'} display={'flex'}>
          <Box className={'overlay overlay-1'}/>
          <Container>
            <Box mb={'120px'} display={'flex'} mt={'36px'} justifyContent={'center'}>
              <Box display={'flex'} flexDirection={'column'}>
                <Box px={'2rem'}>
                  <Typography
                    variant="h1"
                    align="center"
                  >
                                    Onboard and manage your client<br/>portfolio easily, in one place
                  </Typography>
                </Box>
                <Box mt={'26px'} display={'flex'} justifyContent={'center'} flexWrap={'wrap'}>
                  {
                    options.map( text => {
                      return (
                        <Box display={'flex'} key={text} mx={'7px'} my={'6px'}>
                          <CheckedOption>
                            {text}
                          </CheckedOption>
                        </Box>
                      )
                    })
                  }
                </Box>
                <Box zIndex={'2'} mt={'64px'}>
                  <Box display={'flex'} justifyContent={'center'}>
                    <Button onClick={() => redirect('signup')} variant="contained" size="large">SIGN UP</Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>

        <Box className={'position-relative'} display={'flex'} flexDirection={'column'}>
          <Box className={'overlay overlay-2'}/>
          <Box className={'position-relative'}>
            <Box display={'flex'} justifyContent="center">

              <Box zIndex={1} display={'flex'} className={'magnifier-wrapper'} alignItems={'center'} justifyContent="center">
                <Box mt={'10px'}>
                  <img alt={'magnifier'} src={MagnifierImg} className={'magnifier'}/>
                </Box>
              </Box>

              <Parallax styleOuter={{zIndex: 3}} className={'magnifier-parallax'} disabled1 x={['0%', '25%']} tagOuter="figure">
                <Box mt={'25px'}>
                  <img alt={'parallax'} src={Parallax1Img}/>
                </Box>
              </Parallax>

              <Parallax styleOuter={{zIndex: 0}} className={'magnifier-parallax'} disabled1 x={['50%', '5%']} tagOuter="figure">
                <Box mt={'50px'}>
                  <img alt={'parallax'} src={Parallax2Img}/>
                </Box>
              </Parallax>

              <Parallax styleOuter={{zIndex: 0}} className={'magnifier-parallax'} disabled1 x={['0%', '10%']} tagOuter="figure">
                <img alt={'parallax'} src={Parallax3Img}/>
              </Parallax>

            </Box>
          </Box>

          <Container display={'flex'} className={classes.searchBlock}>
            <Typography
              variant="h1"
              align="center"
            >
                        Search Sanctions Lists
            </Typography>
            <Box display={'flex'} mt={'23px'} flexDirection={'row'} justifyContent={'center'}>
              <Box display={'flex'}>
                <Typography variant="subtitle1" >
                                Search the most relevant sanctions, watch and regulatory law lists.<br/>Save search results and enable continuous monitoring.
                </Typography>
              </Box>
            </Box>
            <Box mt={'20px'} display={'flex'} flexDirection={'row'} justifyContent={'center'} position={'relative'}>
              <CustomTooltip
                title={isEmtySearch}
                placement="top"
                disableHoverListener={!isEmtySearch}
              >
                <div style={{ width: '100%' }}>
                  <Search
                    placeholder="Search people and legal entities"
                    value={inputData}
                    onChange={handleInputChange}
                    disabledAction={(quantitySearches === null || quantitySearches === 0) ? false : !inputData.trim()}
                    action={() => (quantitySearches === null || quantitySearches === 0 ) ? redirect('signup') : getEntities()}
                    actionTitle={(quantitySearches === null || quantitySearches === 0) ? 'REGISTER' : 'SEARCH'}
                    endAdornment={
                      <CustomTooltip
                        title="Register to receive more search credits in the free trial"
                        placement="top"
                        disableHoverListener={quantitySearches !== null}
                      >
                        <span className={classes.wrapperFreeSearches}>
                          <Typography
                            classes={{ root: classes.remainingFreeSearches }}
                            variant="caption"
                            align="right"
                          >
                            {quantitySearches || '0'} Searches Left 
                          </Typography>
                        </span>
                      </CustomTooltip>
                    }
                  />
                </div>
              </CustomTooltip>
              <Box display={'flex'} className={classes.advancedCard} flexDirection={'row'} justifyContent={'center'}>
                {!!inputData.trim() && (
                  <AdvancedCard
                    handleRequestData={handleRequestData}
                    searchName={inputData.trim()}
                    setInputData={setInputData}
                    sanctions={true}
                    type={type}
                    setType={setType}
                  />
                )}
              </Box>
            </Box>
            <Box
              mt={'35px'}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'center'}
              mb={20}
            />
          </Container>
        </Box>

        {isProfiles && 
                <Box className={'position-relative'} display={'flex'} flexDirection={'column'}>
                  <Container display={'flex'}>
                    <SubRoutes
                      profilesProps={profilesProps}
                      quantitySearches={quantitySearches}
                      profileId={profileId}
                      setProfileId={setProfileId}
                    />
                  </Container>
                </Box>
        }

        <Box zIndex={1} mt={'-4px'} height={'493px'} className={'position-relative'} display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} className={'overlay'}>
            <Box className={isProfiles ? 'overlay overlay-3-right' : 'overlay overlay-3-left'}/>
            <Box className={'overlay-hr overlay-3-right'}/>
            <Box className={'overlay-screenshot'}/>
          </Box>
        </Box>

        <Box className={'position-relative'} display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} className={'overlay overlay-4'}/>
          <Box mt={'107px'} mb={'378px'}>
            <Typography
              variant="h1"
              align="center"
            >
                        Our Services
            </Typography>
          </Box>
        </Box>

        <Box className={'position-relative'} display={'flex'} flexDirection={'column'}>
          <Box className={'overlay overlay-5'}/>
          <Box className={'overlay-hr overlay-5-right'}/>

          <Box zIndex={0} mt={'-327px'}>
            <Container>
              <Grid container direction="row" justify="center" alignItems="stretch" spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Card
                    icon={CardIcon1Img}
                    header={<React.Fragment>Background<br/>Check</React.Fragment>}
                    content1={'Search the most relevant sanctions, PEP and Adverse media lists'}
                    content2={'Assign risk, create reports, share with the team'}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card
                    icon={CardIcon2Img}
                    header={<React.Fragment>People<br/>and Legal Entities</React.Fragment>}
                    content1={'Save search results as a datapoint for People and Legal Entities'}
                    content2={'Basic information and risk score will be automatically attached'}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card
                    icon={CardIcon3Img}
                    header={<React.Fragment>One master record<br/>- multiple parties</React.Fragment>}
                    content1={'Aggregate data from multiple sources'}
                    content2={'Full client profiling with automated updating'}
                  />
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box display={'flex'} mt={'65px'} justifyContent={'center'}>
            <Box display={'flex'} align={'center'} className={'text-below-cards'}>
                        Need help keeping client documentation centralized, up to date and well organized?
            </Box>
          </Box>

          <Box display={'flex'} mt={'36px'} mb={'105px'} justifyContent={'center'}>
            <Box display={'flex'}>
              <Button onClick={() => redirect('signup')} variant="contained" size="large">TRY FOR FREE</Button>
            </Box>
          </Box>
        </Box>

        <Footer/>
      </Box>
    </React.Fragment>
  );
}
import React, { useState } from 'react';

import { Box } from '@material-ui/core';
import ReadMode from './read-mode';
import WriteMode from './write-mode';
import { useLocation } from 'react-router-dom';

export default function Details(props) {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const isEdit = search.get('edit');

  const [mode, setMode] = useState(isEdit ? 'write' : 'read')
  return (
    <Box display={'flex'} flexGrow={1} mt={3}>
      {mode === 'read' &&
        <ReadMode changeMode={() => setMode('write')} {...props} />
      }
      {(mode === 'write' && props.data?.id) &&
        <WriteMode changeMode={() => setMode('read')} {...props} />
      }
    </Box>
  )
}
import React, { useState } from 'react';
import { Box, Typography, Grid, Button } from '@material-ui/core';
import { DownloadIcon } from '@app/icons';

import InnerHeaderControls from '@components/layout/inner-header-controls';
import BackButton from '@views/common/back-button';
import RenderInputs from '@components/layout/render-inputs';
import DatePicker from '@components/calendar/input-date-picker'

import history from '@app/history';
import useErrorState from '@utils/errorState';
import * as yup from 'yup';
import parseYupErrors from '@app/yup';
import sub from 'date-fns/sub';

import TableList, { TCELL_TYPES } from '@components/lists/table-list';
import LabelsForRender from '@components/layout/labels-for-render';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 'calc(100vh - 250px)',
    overflowY: 'auto'
  },
  wrapper: {
    padding: '20px'
  },
  paper: {
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    borderRadius: '12px',
    padding: '30px'
  },
  labels: {
    width: '140px',
    minWidth: '140px',
    maxWidth: '140px',
    margin: '5px 0'
  },
  backButton: {
    marginLeft: 0
  },
}))

const inputs = [
  {
    key: 'email',
    label: 'Email',
    type: 'input',
  },
  {
    key: 'invoiceDate',
    label: 'Invoice Date',
    dataType: 'date',
    type: 'input',
  },
  {
    key: 'paymentType',
    label: 'Payment Type',
    options: [
      'Bank Wire Transfer',
      'Cash',
      'Cheque',
      'Manual Credit/Refund',
      'Money Order',
      'Other',
    ],
    type: 'select',
  },
  {
    key: 'note',
    label: 'Details',
    type: 'input',
    multiline: true,
  },
  {
    key: 'amount',
    label: 'Amount',
    dataType: 'number',
    type: 'input',
  },
]

const columns = [
  { field: 'invoiceDate', headerName: 'Invoice Date' },
  { field: 'amount', headerName: 'Amount' },
  { field: 'billableItem', headerName: 'Billable Item' },
  { field: 'description', headerName: 'Description' },
  { cellType: TCELL_TYPES.ICON_BUTTON, field: 'download', headerName: '', icon: <DownloadIcon />, tooltip: 'Download' },
]

const rows = [
  {
    id: 1111,
    invoiceDate: '12/12/2020',
    amount: '$99.99',
    billableItem: '1 Item',
    description: 'Lorem...',
    isChecked: false
  },
  {
    id: 2222,
    invoiceDate: '12/12/2020',
    amount: '$99.99',
    billableItem: '1 Item',
    description: 'Lorem...',
    isChecked: false
  },
  {
    id: 3333,
    invoiceDate: '12/12/2020',
    amount: '$99.99',
    billableItem: '1 Item',
    description: 'Lorem...',
    isChecked: false
  },
  {
    id: 4444,
    invoiceDate: '12/12/2020',
    amount: '$99.99',
    billableItem: '1 Item',
    description: 'Lorem...',
    isChecked: false
  },
]

const ENDING = 'Ending Account Balance';
const UNAPPLIED = 'Unapplied Payment';
const TOTAL = 'Total Payments Applied';

const labels = [
  {
    field: 'Account Balance',
    value: '$100.96',
  },
  {
    field: 'Unpaid Invoices',
    value: '$399.96',
  },
  {
    field: 'Ending Account Balance',
    value: '$100.96',
  },
  {
    field: 'Unapplied Payment',
    value: '$0',
  },
  {
    field: 'Total Payments Applied',
    value: '$0',
  },
]

const defaultModel = {
  email: '',
  invoiceDate: '',
  paymentType: '',
  note: '',
  amount: '',
}

export default function NewPayment() {
  const [model, setModel] = useState(defaultModel);
  const [data, setData] = useState(rows);
  const [summary, setSummary] = useState(labels);

  const classes = useStyles();

  const [errors, setErrors] = useErrorState({});

  const handleChange = (prop) => (event) => {
    const newModel = { ...model, [prop]: event.target.value };
    setModel(newModel);

    if (prop === 'amount') {
      setSummary(fields => fields.map(el => {
        if (el.field === ENDING) {
          return ({ ...el, value: `$${event.target.value}` });
        }
        if (el.field === UNAPPLIED) {
          let value = +event.target.value;
          if (!!data.find(el => el.isChecked)) {
            const  invoicesSum = calcInvoicesSum(data);
            value = !!invoicesSum ? invoicesSum - value : value;

          }
          return ({ ...el, value: `$${value.toFixed(2)}` });
        }

        return el;
      }));
    }

    if (errors[prop]) {
      schema.validateAt(prop, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [prop]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [prop]: err.message });
        });
    }
  };

  const schema = yup.object().shape({
    email: yup.string().label('Email').lowercase().trim().email('Invalid email format').required().max(50),
    invoiceDate: yup.date()
      .nullable()
      .transform((curr, orig) => orig === '' ? null : curr)
      .label('Invoice Date')
      .required()
      .max(sub(new Date(), { days: 1 }), 'Invoice date should be before current date')
      .typeError('Invalid date format'),
    amount: yup.string().label('Amount').required().max(10),
  });

  const onSave = () => {
    setErrors({});
    schema
      .validate(model, { abortEarly: false })
      .then(function (valid) {
        history.goBack()
      })
      .catch(function (err) {
        console.log(err)
        setErrors(parseYupErrors(err), true);
      });
  }

  const onSelect = (id) => {
    const newData = data.map(
      (item) => item.id === id
        ? ({ ...item, isChecked: !item.isChecked })
        : item
    );

    setData(newData);
    calculateUnpaidInvices(newData);
  }

  const calcInvoicesSum = (data) => {
    let invoicesSum = 0;
    data.forEach(el => {
      if (el.isChecked) {
        const num = +el.amount.substr(1);
        invoicesSum = invoicesSum + num;
      }
    });

    return invoicesSum;
  }

  const calculateUnpaidInvices = (data) => {
    const invoicesSum = calcInvoicesSum(data);

    const amount = +model.amount
    const sum = !!invoicesSum ? invoicesSum - amount : amount;
    setSummary(fields => fields.map(el => {
      if (el.field === UNAPPLIED) {
        return ({ ...el, value: `$${sum.toFixed(2)}` });
      }
      if (el.field === TOTAL) {
        return ({ ...el, value: `$${invoicesSum.toFixed(2)}` });
      }

      return el;
    }));
  }

  const renderDatePicker = (
    <DatePicker
      handleChange={handleChange('invoiceDate')}
      inputValue={model['invoiceDate']}
    />
  )

  return (
    <React.Fragment>
      <Box display={'flex'} flexDirection={'column'}>
        <InnerHeaderControls
          controlsLeft={<BackButton wrapped customButtonClasses={classes.backButton} />}
        />
        <Box className={classes.container}>
          <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center">
            <Box display="flex" mb={2} alignItems="center" justifyContent="center">
              <Typography variant="h4">Add new payment</Typography>
            </Box>
            <Grid container justify={'space-between'} className={classes.wrapper}>
              <Grid item sm={5}>
                {inputs.map((field, i) => (
                  <RenderInputs
                    ind={i}
                    field={field.key === 'invoiceDate' ? { ...field, endAdornment: renderDatePicker } : field}
                    model={model}
                    errors={errors}
                    handleChange={handleChange}
                    key={`${field.key}-${i}`}
                  />
                ))}
              </Grid>
              <Grid item sm={6}>
                <Box className={classes.paper}>
                  <Typography variant="h6" gutterBottom component="div">
                    User Unpaid Invoices
                  </Typography>
                  <TableList
                    columns={columns}
                    items={data}
                    onSelect={onSelect}
                    handlers={{
                      download: () => {}
                    }}
                  />
                </Box>
                <Box mt={4} width={'100%'}>
                  <LabelsForRender labels={summary} labelStyleProps={classes.labels} />
                </Box>
              </Grid>
            </Grid>
            <Box display="flex" mt={5} mb={2} width={'100%'} justifyContent="flex-end">
              <Grid container item sm={5}>
                <Button style={{ width: '180px', margin: '0 10px' }} variant={'text'} onClick={() => history.push('/billing-management/payment')}>
                  CANCEL
                </Button>
                <Button style={{ width: '180px', margin: '0 10px' }} variant={'contained'} onClick={onSave}>
                  CREATE
                </Button>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  )
}
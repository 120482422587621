import moment from 'moment';
import { FIELD_TYPES } from '@app/constants';
import { OTHER_FIELD, OTHER_VALUE } from '@dto/client';

export function prepareData(modelMap, fieldsMap) {
  const preparedData = {
    address: {},
    properties: {
      propertyMap: { },
    },
    typeSpecificProperties: {
      propertyMap: { },
    },
  }
  Object.entries(modelMap).forEach(([mapKey, model]) => {
    const fields = fieldsMap[mapKey]
    if (fields) {
      Object.entries(model).forEach(([key, value]) => {
        const field = fields.find(e => e.key === key)
        if (field) {
          let data
          switch (field.type) {
            case FIELD_TYPES.DATE:
              data = value ? moment(value).format('YYYY-MM-DD') : null
              break;
          
            default:
              data = value
              break;
          }

          if (field.allowOther && data === OTHER_VALUE) {
            data = model[key + OTHER_FIELD] || OTHER_VALUE
          }
    
          if (data) {
            switch (true) {
              case field.isDefault:
                preparedData[field.key] = data
                break;
      
              case field.isAddress:
                preparedData.address[field.key] = data
                break;
      
              case field.isTypeSpecific:
                preparedData.typeSpecificProperties.propertyMap[field.key] = {
                  id: field.key,
                  value: data,
                }
                break;
      
              default:
                preparedData.properties.propertyMap[field.key] = {
                  id: field.key,
                  value: data,
                }
                break;
            }
          }
        } else if (!key.includes(OTHER_FIELD)) {
          preparedData[key] = value
        }
      })
    }
  })

  return preparedData;
}

import _ from 'lodash';
import {countryCodeList} from '@services/country-service';

const readTypesMap = {
  FREE_TEXT: 'text',
  BOOLEAN: 'toggle',
  LIST: 'select',
  COUNTRY_LIST: 'select',
  BACKGROUND_CHECK_RISK_LIST: 'select',
  NO_ANSWER: 'none'
}

const featureMap = {
  COUNTRY_LIST: 'country',
  BACKGROUND_CHECK_RISK_LIST: 'background-check'
}

/**
 * Option label may be different from option value and this depends on feature (look featureMap) based on question type
 * eg 'country' feature invokes country full names as labels
 * @param type
 * @param feature
 * @param value
 */

const readValue = (type, feature, value) => {
  if (value) {
    if (type === 'toggle') {
      return value === 'Yes'? 1: 0
    }
    return value
  }
  if (type === 'select' && feature === 'background-check') {
    return 'Low'
  }
  if (type === 'toggle') {
    return 0
  }
  return ''
}

const getRiskWeightValue = (type, valueName, selectedValue, options, data) => {
  let answer = selectedValue
  if (type === 'toggle') {
    answer = selectedValue === 1 ? 'true' : 'false'
  }

  const selectedOption = options.find(e => e.value === answer)
  if (selectedOption?.[valueName]) {
    return selectedOption?.[valueName]
  }

  const defaultName = valueName === 'riskValue' ? 'answerValue' : 'answerWeight'
  return data?.userInput?.[defaultName] !== undefined ? data.userInput[defaultName] : null
}

const writeValue = (type, feature, value) => {
  if((type === 'select' || type === 'text') && !value) {
    return null
  }
  if(type === 'toggle') {
    return !!value? 'Yes': 'No'
  }
  if(type === 'none') {
    return null
  }
  return value
}

const countries = []
for (const [key, value] of Object.entries(countryCodeList)) {
  countries.push({value: key, label: value})
}

const riskRatingItemDto = {
  read: data => {
    const value = readValue(readTypesMap[data?.type], featureMap[data?.type], data?.userInput?.answerText);
    let selectOptions
    if (featureMap[data?.type] === 'country') {
      selectOptions = countries
    } else {
      selectOptions = (data?.possibleAnswers || []).map(option => ({
        value: option?.answerText,
        label: option?.answerText,
        riskValue: option?.answerValue,
        weightValue: option?.answerWeight
      }))
    }

    const type = readTypesMap[data?.type] || 'text';

    return {
      id: data?.id,
      label: data?.questionText,
      type,
      feature: featureMap[data?.type] || null,
      originType: data?.type || null,
      selectOptions,
      riskValue: getRiskWeightValue(type, 'riskValue', value, selectOptions, data),
      weightValue: getRiskWeightValue(type, 'weightValue', value, selectOptions, data),
      excluded: data?.userInput?.excluded || false,
      value,
      error: null,
      useDefaultPerQuestion: data?.possibleAnswers?.some(e => e.answerValue !== null || e.answerWeight !== null),
      // TODO: Replace with notes collection when api adjusted !!!!!
      notes: data?.userInput?.note ?
        [{
          id: 83325,
          createdOn: '2021-02-23T09:27:37.230658',
          note: data?.userInput?.note || ''
        }] : []
    }
  },
  write: data => (
    _.omitBy({
      id: data?.id,
      questionText: data?.label,
      type: data?.originType || 'FREE_TEXT',
      // TODO: Replace with notes collection when api adjusted !!!!!
      userInput: _.omitBy({
        answerValue: data?.riskValue,
        answerWeight: data?.weightValue,
        excluded: !!data?.excluded,
        answerText: writeValue(data?.type, data?.feature, data?.value),
        note: [...data.notes].shift()?.note || ''
      }, _.isNil)
    }, _.isNil)
  ),
  writeTemplate: data => (
    _.omitBy({
      id: `${data?.id}-${Date.now()}`,
      questionText: data?.label,
      type: data?.originType || 'FREE_TEXT',
      possibleAnswers: data?.selectOptions.map(
        option => ({
          answerText: option.value
        })
      )
    }, _.isNil)
  )
}

export default riskRatingItemDto
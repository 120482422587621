import { makeStyles } from '@material-ui/core';

const fontStyle = {
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '28px',
}

export const useStyles = makeStyles((theme) => ({
  title: {
    ...fontStyle,
    fontSize: '28px',
    lineHeight: '28px',
    color: '#484848',
  },
  buttonRoot: {
    padding: 0,
    marginRight: '-10px',
  },
  cardRoot: {
    width: '70%',
    margin: '12px 4px',
    padding: '16px 24px',
    borderRadius: '12px',
    boxShadow: '0px 5px 26px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    '&:hover': {
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      cursor: 'pointer',
    },
  },
  cardName: {
    ...fontStyle,
    fontSize: '18px',
    color: '#232323',
  },
  cardChip: {
    width: 100,
    border: 0,
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '7px',
    color: '#484848'
  },
  folderPath: {
    ...fontStyle,
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#A4A4A4',
  },
  cardTagLabel: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '10px',
    lineHeight: '19px',
    textTransform: 'uppercase',
    color: '#232323',
    marginRight: '5px'
  },
  cardTagValue: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    color: '#232323',
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#A4A4A4',
  },
  scrollRoot: {
    height: 'calc(100vh - 240px)',
    overflowY: 'scroll',
    padding: '5px 0'
  },
  wrapperCard: {
    marginBottom: '20px',
    minHeight: '100%',
    justifyContent: 'flex-start'
  },
}));
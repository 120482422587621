import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomChip from '@components/controls/custom-chip'
import {
  PersonalActiveReviewVersion,
  ChangelogInputFieldIcon,
  ChangelogDocumentIcon
} from '@app/icons';
import * as clientApi from '@app/api/client';
import { Modal } from '@components/modals';
import { checkRuleChanges } from '../../../dto/client';
import { useLocation } from 'react-router-dom';


export const useStyles = makeStyles((theme) => ({
  reviewChip: {
    backgroundColor: '#B6514C !important',
    '&:hover': {
      backgroundColor: '#944541 !important'
    },
    cursor: 'pointer',
  },
  changesBox: {
    border: '1px solid #ECF0ED',
    borderRadius: '12px',
    overflowY: 'auto',
  },
}))

function ChangesBox({ changes, title }) {
  return (
    <Box>
      <Typography variant="subtitle1" style={{ fontWeight: 500 }}>{title}</Typography>
      <Box
        mt={2}
        py={2}
        px={3}
        border="1px solid #ECF0ED"
        borderRadius="12px"
        height="50vh"
        maxHeight="50vh"
        style={{ overflowY: 'auto' }}
        width={350}
      >
        {changes.map((change, index) => (
          <Box my="10px" key={index} display="flex" alignItems="center">
            <Box pr={2}>
              {change.type === 'inputField'
                ? <ChangelogInputFieldIcon />
                : <ChangelogDocumentIcon />
              }
            </Box>
            <Box>
              <Typography variant="body1" style={{ color: '#232323'}} align="left">
                {change.message}
              </Typography>
            </Box>
          </Box>
        ))}
        {!changes.length &&
          <Box my={1}>
            <Typography variant="subtitle2" align="left" style={{ opacity: 0.5 }}>No Changes</Typography>
          </Box>
        }
      </Box>
    </Box>
  )
}

export default function CheckRulebookVersion({ client, refreshClient }) {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const reviewVersion = search.get('review_version');

  const classes = useStyles();
  const [hasRunCheck, setHasRunCheck] = useState(false); // Has run triggered check from review_version param
  const [changeLogOpen, setChangeLogOpen] = useState(!!reviewVersion);
  const [noChangesOpen, setNoChangesOpen] = useState(false);
  const [confirmUpdateOpen, setConfirmUpdateOpen] = useState(false);

  const [removedChanges, setRemovedChanges] = useState([]);
  const [updatedChanges, setUpdatedChanges] = useState([]);
  const [addedChanges, setAddedChanges] = useState([]);

  useEffect(() => {
    if (!hasRunCheck && reviewVersion && client.basedOnRulebookVersionId) {
      checkVersion();
      setHasRunCheck(true);
    }
  }, [reviewVersion, client]) // eslint-disable-line

  const checkVersion = async () => {
    const res = await clientApi.clientRulebook.diff(client.basedOnRulebookVersionId, client.basedOnLatestRulebookVersionId)
    const { data } = res;
    const { removedChanges, updatedChanges, addedChanges } = await checkRuleChanges(data);

    setRemovedChanges(removedChanges);
    setUpdatedChanges(updatedChanges);
    setAddedChanges(addedChanges);

    if (!removedChanges.length &&
      !updatedChanges.length &&
      !addedChanges.length) {
      setChangeLogOpen(false);
      return setNoChangesOpen(true);
    }

    setChangeLogOpen(true);
  }

  const onConfirm = () => {
    clientApi.client.updateToLatestRulebookVersion(client.id).then(() => {
      setConfirmUpdateOpen(false);
      setChangeLogOpen(false);
      refreshClient();
    })
  }

  const showReviewVersion = client && !client.isBasedOnLatestRulebookVersion
  if (!showReviewVersion) return null;
  return (
    <React.Fragment>
      <Modal
        open={confirmUpdateOpen}
        onClose={() => setConfirmUpdateOpen(false)}
        title="Entity Definition Updates"
        mainText="You will have to provide the newly required information. Please note that entity definition updates could affect your client status, resulting in incomplete status due to the new requirements. Would you like to proceed?"
        actions={
          [
            {
              linkButton: true,
              label: 'CANCEL',
              action: () => { setConfirmUpdateOpen(false) },
              style: { width: 160 },
            },
            {
              type: 'secondary',
              label: 'PROCEED',
              action: onConfirm,
              style: { width: 160 },
            },
          ]
        }
        actionsDirection="row"
      />

      <Modal
        open={noChangesOpen}
        onClose={() => setNoChangesOpen(false)}
        title="Entity Definition Updates"
        mainText="The entity definition was updated, but there are no differences between the current and the latest version. It is safe to update the client to use the latest version."
        actions={
          [
            {
              linkButton: true,
              label: 'CANCEL',
              action: () => { setNoChangesOpen(false) },
              style: { width: 160 },
            },
            {
              type: 'secondary',
              label: 'UPDATE',
              action: onConfirm,
              style: { width: 160 },
            },
          ]
        }
        actionsDirection="row"
      />

      <Modal
        open={changeLogOpen}
        onClose={() => setChangeLogOpen(false)}
        title="Entity Definition Updates"
        content={
          <Box display="flex" my={4}>
            <ChangesBox title="Will be Removed" changes={removedChanges} />
            <Box mx={4}>
              <ChangesBox title="Will Change" changes={updatedChanges} />
            </Box>
            <ChangesBox title="Will be Added" changes={addedChanges} />
          </Box>
        }
        actions={
          [
            {
              linkButton: true,
              label: 'CANCEL',
              action: () => { setChangeLogOpen(false) },
              style: { width: 160 },
            },
            {
              type: 'secondary',
              label: 'CONFIRM',
              action: () => setConfirmUpdateOpen(true),
              style: { width: 160 },
            },
          ]
        }
        actionsDirection="row"
      />

      <CustomChip
        background="#B6514C"
        labelColor="#FFFFFF"
        label={(
          <Box display="flex" alignItems="center" px="4px">
            <Box pr={1}>
              <PersonalActiveReviewVersion width="16px" height="16px" />
            </Box>
            There is a newer version of entity definition available. Review Now
          </Box>
        )}
        chipProps={{
          className: classes.reviewChip,
          onClick: checkVersion,
        }}
      />
    </React.Fragment>
  )
}

const setSnackbar = (message, options, suppressable = false) => ({
  type: 'SET_SNACKBAR',
  snackbar: { message, options, suppressable }
});

// This is used when we need to override error snackbar from axios
const setSuppressSnackbar = (suppress) => ({
  type: 'SET_SUPPRESS_SNACKBAR',
  suppressSnackbar: suppress,
});

const general = {
  setSnackbar,
  setSuppressSnackbar
}

export default general;

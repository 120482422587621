import React from  'react';
import {Modal, Typography, makeStyles, DialogActions, DialogContent, Box} from '@material-ui/core';
import CustomButton from '@views/common/button';
import LinkButton from '@components/buttons/link-button';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: props=>({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: props.width || 'unset',
    height: props.height || 'unset',
    background: props.background || theme.palette.common.white,
    boxShadow: '0px 9px 26px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    borderRadius: '12px',
    padding: '50px',
    '&:focus': {
      outline: 'inherit',
    },
  }),
  modalText: {
    width: '406px',
    margin: '22px 0 35px 0',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'center',
    color: theme.palette.text.BL1,
    whiteSpace: 'pre-wrap'
  },
  modalUserText: {
    color: theme.palette.text.LB3,
  },
  dialogActionsRoot: {
    margin: 0,
  },
  dialogTitle: {
    flex: '0 0 auto',
    width: '406px',
    margin: 0,
    padding: '16px 24px',
    textAlign: 'center',
    whiteSpace: 'pre-wrap'
    // textTransform: 'capitalize'
  }
}));

export default function CustomModal(props) {
  const { open, onClose, title, mainText, additionalText, actions, propsClassses, actionsDirection, content, titleContent, modalProps, width, height, background } = props;
  const classes = useStyles({background, width, height});


  const Actions = ({ children }) => (
    actionsDirection === 'row'
      ? <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        {children}
      </DialogActions>
      : <React.Fragment>
        {children}
      </React.Fragment>
  );

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClose}
      disableAutoFocus
      disableRestoreFocus
      {...modalProps}
    >
      <div className={classes.paper}>
        <Box className={classes.dialogTitle}>
          {titleContent || (
            <Typography variant="h3">{title}</Typography>
          )}
        </Box>
        <DialogContent>
          {!!mainText &&
            <Typography className={classes.modalText}>
              {mainText}
              {additionalText}
            </Typography>
          }
          {!!content && content}
        </DialogContent>
        <Actions
          children={
            actions?.map((item, k) => (
              item.linkButton ?
                <LinkButton
                  key={`modal-actions-${k}`}
                  style={item.style || undefined}
                  onClick={item.action}
                  disabled={item.disabled}
                >
                  {item.label}
                </LinkButton>
                :
                <CustomButton
                  key={`modal-actions-${k}`}
                  action={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    item.action(e);
                  }}
                  mouseDown={!!content}
                  label={item.label}
                  customClasses={item.type !== 'main' ? 'customButtonStandard' : 'customButtonBordered'}
                  propsClassses={propsClassses}
                  style={item.style || undefined}
                  disabled={item.disabled}
                />
            ))
          }
        />
      </div>
    </Modal>
  )
}
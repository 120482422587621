import React from 'react';
import { Box } from '@material-ui/core';

import CustomTooltip from '@components/controls/tooltip';
import BaseTableCell from './base-table-cell';

export default function TextIconTableCell(props) {
  const { col, item } = props;

  return (
    <BaseTableCell col={col} item={item}>
      <Box>
        <Box display="flex" alignItems="center">
          <CustomTooltip
            title={item?.tooltipValue || item[col.tooltipValue] || item[col.field]}
            placement="top"
            disableHoverListener={!col.useTooltip}
          >
            <Box display="inline-block">
              {item[col.field] || 'N/A'}
            </Box>
          </CustomTooltip>
          <Box display="flex" alignItems="center">
            {col.decoration(item)}
          </Box>
        </Box>
      </Box>
    </BaseTableCell>
  )
}
import {useEffect} from 'react';

import history from '@app/history';
import {riskRating, riskRatingTemplate} from '@app/api/client';
import {useParams} from 'react-router-dom';
import riskRatingTemplateDto from './dto/risk-rating-template-dto';

export default function CreateFromExisting(props) {

  const { riskRatingId } = useParams()

  const clientData = props.data

  const { baseUri } = props

  const fetchSource = async () => {
    return (await riskRating.show(riskRatingId)).data
  }

  const createDraft = async (data) => {
    const templates = (await riskRatingTemplate.index()).data.map(riskRatingTemplateDto.read)
    const templateName = data?.jsonData?.templateName
    const template = templates.find(t => t.templateName === templateName)

    let riskRatingData = (await riskRating.store({
      clientId:clientData.id,
      riskRatingTemplateId:template.id
    })).data
    const jsonData = data.jsonData
    jsonData.userInput.draft = true
    riskRatingData = (await riskRating.update(
      riskRatingData.id,
      {
        ...jsonData,
        userInput: {
          ...jsonData.userInput,
          nextReview: null,
          riskRating: null
        }
      }
    )).data
    history.push(`${baseUri}/${riskRatingData.id}/${riskRatingId}`)
  }

  useEffect(() => {
    fetchSource().then(
      createDraft
    )
    // eslint-disable-next-line
  }, [])

  return null
}
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Box, Grid, FormControlLabel, Switch, makeStyles } from '@material-ui/core';
import TableList from '@components/lists/table-list';
import OutlinedSearch from '@components/inputs/outlined-search';
import InlineHeadBadge from '@components/badges/inline-head-badge';
import FilterWithContext from '@components/dropdowns/filter-with-context';
import NoResultsFound from '@components/layout/no-results-found';
import { subscriptionPlans, clients } from '@app/api/admin';
import { useSortableData } from '@utils/useSortableData';
import { Modal } from '@components/modals';
import history from '@app/history';
import { capitalize } from '@utils/textUtil';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  blockAction: {
    color: '#B6514C',
  }
}));

const TABLE_COLUMNS = [
  { field: 'name', headerName: 'Name', bold: true },
  { field: 'description', headerName: 'Description' },
  { field: 'period', headerName: 'Period' },
  { field: 'price', headerName: 'Price' },
  { field: 'regularCredits', headerName: 'Regular Credits' },
  { field: 'premiumCredits', headerName: 'Premium Credits' },
  { field: 'eidvCredits', headerName: 'eIDV Credits' },
]

const TABLE_COLUMNS_PROD = [
  { field: 'name', headerName: 'Name', bold: true },
  { field: 'period', headerName: 'Period' },
  { field: 'price', headerName: 'Price' },
  { field: 'regularCredits', headerName: 'Regular Credits' },
  { field: 'premiumCredits', headerName: 'Premium Credits' },
  { field: 'eidvCredits', headerName: 'eIDV Credits' },
  { field: 'visibleFor', headerName: 'Visible For'},
  { field: 'status', headerName: 'Status' },
]

const switchGroup = [
  { value: 'active', label: 'Active Subscriptions', isChecked: true },
  { value: 'blocked', label: 'Blocked Subscriptions', isChecked: true },
]


export default function ManageSubscriptions({ openModal, setOpenModal, setSubscriptionData, setTabID, userIsAdmin }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState([]);
  const [search, setSearch] = useState();
  // eslint-disable-next-line
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState(switchGroup);
  const [plans, setPlans] = useState([]);

  const formattedData = useMemo(() => {
    return data.sort((a, b) => a.id > b.id ? 1 : a.id < b.id ? -1 : 0).map(e => {
      const item = { ...e };

      switch (item.status) {
        case 'Active':
          item.cellProps = {
            status: {
              style: {
                color: '#4E922C'
              }
            }
          }
          item.threeDotsActionsDisabled = ['Unblock']
          break;
        
        case 'Blocked':
          item.cellProps = {
            status: {
              style: {
                color: '#B6514C'
              }
            }
          }
          item.threeDotsActionsDisabled = ['Block']
          break;
        
        default:
          item.threeDotsActionsDisabled = ['Unblock']
          break;
      }

      return item;
    })
  }, [data])

  const { items, sortConfig, requestSort } = useSortableData(formattedData);

  const getAllPlans = async () => {
    const res = await subscriptionPlans.getAll();

    if (res.status === 200) {
      const newPlans = res.data.map(el => ({
        ...el,
        description: `Lorem ${el.name.toLowerCase()}`,
        period: 'Yearly',
        price: `$${el.price}`,
        status: 'Active',
        visibleFor: el.ownedBy === 'SYSTEM' ? 'All' : 'N/A',
      }))

      const userIds = new Set();
      newPlans.filter(e => e.userId).forEach(e => {
        userIds.add(e.userId)
      });
      const users = await Promise.all([...userIds].map(userId => clients.getUserById(userId)))
      const usersMap = {}
      users.forEach(res => {
        const user = res.data;
        usersMap[user.id] = user;
      })

      newPlans.forEach((plan) => {
        if (plan.userId) {
          const user = usersMap[plan.userId]
          if (user) {
            plan.visibleFor = `${user.name} ${user.lastName} (${user.email})`
          }
        }
      })

      setData(newPlans);
      setPlans(newPlans);
    }
  }

  useEffect(() => {
    getAllPlans();
  }, [])

  useEffect(() => {
    setSubscriptionData(data);
  }, [data, setSubscriptionData])

  const onSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
    const changed = plans.filter(el =>
      el.name.toLowerCase().includes(value?.trim()?.toLowerCase()) || el.description.toLowerCase().includes(value?.trim()?.toLowerCase())
    );
    setData(changed);
    // setPage(0);
  }

  const onFilterChange = (event) => {
    const changed = filters.map(el =>
      el.value === event.target.value ? ({ ...el, isChecked: event.target.checked }) : el
    );

    if (event.target.checked) {
      const filtered = plans.filter(el => el.status.toLowerCase() === event.target.value);

      const newData = [];
      filtered.forEach(el => {
        const currElement = data.find(item => item.id === el.id);
        if (!currElement) {
          newData.push(el)
        }
      });
      setData(items => ([ ...items, ...newData ]));
    } else {
      const filtered = [];
      plans.forEach(el => {
        const filter = changed.find(filter => filter.value === el.status.toLowerCase());
        
        filter.isChecked && filtered.push(el);
      })
      setData(filtered);
    }
    setFilters(changed);
  }

  const onSelect = (id) => {
    const newData = data.map(
      (item) => item.id === id
        ? ({ ...item, isChecked: !item.isChecked })
        : item
    );
    setData(newData);
  }

  const handleMenuAction = useCallback((id, item, type) => {
    setOpenModal({ id, name: item.name, type, prefix: 'subscription' });
  }, [setOpenModal])

  const handleView = useCallback((id, item) => {
    setTabID('subscribers-list');
    history.push(`/subscription-management/subscribers-list/?planId=${item.id}`);
  }, [setTabID])

  const handleModalAction = () => {
    if (!openModal) return;

    if (openModal.type === 'delete') {
      subscriptionPlans.delete(openModal.id).then((res) => {
        enqueueSnackbar(`Subscription "${openModal.name}" has been successfully deleted!`, { variant: 'success' })
        getAllPlans();
        setOpenModal(null);
      })
    }
  }

  const tableColumns = useMemo(() => {
    return userIsAdmin ? TABLE_COLUMNS_PROD : TABLE_COLUMNS;
  }, [userIsAdmin])

  const tableActions = useMemo(() => {
    return userIsAdmin ? [
      { label: 'View', handler: handleView  },
      {
        label: 'Edit',
        hidden: (id, item) => item.ownedBy === 'SYSTEM',
        handler: (id, item) => history.push(`/subscription-management/subscription/edit-subscription/?subId=${id}`)
      },
      {
        label: 'Delete',
        hidden: (id, item) => item.ownedBy === 'SYSTEM',
        handler: (id, item) => handleMenuAction(id, item, 'delete')
      },
    ] : [
      { label: 'View', handler: handleView  },
      { label: 'Edit', handler: (id, item) => history.push(`/subscription-management/subscription/edit-subscription/?subId=${id}`) },
      { label: 'Delete', handler: (id, item) => handleMenuAction(id, item, 'delete') },
      { label: 'Block', handler: (id, item) => handleMenuAction(id, item, 'block') },
      { label: 'Unblock', handler: (id, item) => handleMenuAction(id, item, 'unblock'), disabled: true },
    ];
  }, [userIsAdmin, handleView, handleMenuAction])

  return (
    <Box display={'flex'} flexGrow={'1'} mt={2} flexDirection={'column'}>
      <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'}>
          <Box mr={1}>
            <InlineHeadBadge
              badgeColor={'#CAD7CA'}
              badgeValue={formattedData.length || 0}
            >
              SUBSCRIPTIONS
            </InlineHeadBadge>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'center'} height={'40px'}>
          {!userIsAdmin && (
            <FilterWithContext>
              {filters.map((el, i) => (
                <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value} - ${i}`}>
                  <FormControlLabel
                    control={<Switch value={el.value} checked={el.isChecked} onClick={onFilterChange} />}
                    label={el.label}
                  />
                </Box>
              ))}
            </FilterWithContext>
          )}
        </Box>
      </Box>
      <Box display={'flex'} mb={3}>
        <OutlinedSearch
          value={search}
          onChange={onSearchChange}
          label="Search subscriptions"
        />
      </Box>
      {!!formattedData.length ? (
        <Grid container>
          <Grid item sm={12}>
            <TableList
              columns={tableColumns}
              items={items}
              page={page}
              onSelect={onSelect}
              onClick={handleView}
              pageSize={formattedData.length}
              pagesCount={1}
              totalCount={formattedData.length}
              sortableColumns={tableColumns.map(el => el.field).filter(field => field !== 'visibleFor')}
              maxHeight="calc(100vh - 380px)"
              threeDotsActions={tableActions}
              onSort={requestSort}
              sort={sortConfig?.key}
              sortDirection={sortConfig?.direction}
            />
          </Grid>
        </Grid>
      ) : (
        <Box mt={25} flexGrow={1}>
          <NoResultsFound>
            The list of subscriptions will appear here
          </NoResultsFound>
        </Box>
      )}
      <Modal
        open={!!openModal}
        onClose={() => setOpenModal(null)}
        title={`${capitalize(openModal?.type)} ${capitalize(openModal?.prefix)}`}
        mainText={`Are you sure you want to ${openModal?.type} ${openModal?.prefix} "${openModal?.name}"?`}
        propsClassses={classes.modalButtonWidth}
        actions={
          [
            {
              type: 'main',
              label: 'No',
              action: () => setOpenModal(null),
              style: { width: 160 },
            },
            {
              type: 'secondary',
              label: 'Yes',
              action: () => handleModalAction(),
              style: { width: 160 },
            },
          ]
        }
        actionsDirection="row"
      />
    </Box>
  )
}

import React, { useMemo, useState } from 'react';
import { Box, Card, Divider, FormControlLabel, MenuItem, Typography, Switch, TextField, makeStyles, Select } from '@material-ui/core';
import { BurgerIcon } from '@app/icons';
import MenuWithContext from '@components/dropdowns/menu-with-context';
import { EntityInputTypeEnum, EntityInputDisplayLevelEnumPerson, EntityInputDisplayLevelEnumLegalEntity } from '@services/entity-input';
import InputFieldDialog from '../../../client/settings/entity/particular/form/dialogs/input-field-dialog';
import CustomChip from '@components/controls/custom-chip';
import CustomTooltip from '@components/controls/tooltip';

const useStyles = makeStyles((theme) => ({
  switch: {
    width: '36px',
    height: '18px',
    padding: '2px 6px',
    '& .MuiSwitch-switchBase': {
      top: -3,
      padding: '4px',
    },
  },
  switchControl: {
    marginLeft: '-5px',
    '& .MuiFormControlLabel-label': {
      marginLeft: 4,
    }
  },
}));

export default function InputFieldCard({ inputField, setInputField, onDelete, onEdit, disabled, isEdit, clientType }) {
  const classes = useStyles();
  const [fieldDialogOpen, setFieldDialogOpen] = useState(false)
  const isRequired = inputField.validation === 'REQUIRED';

  const handleChangeName = e => {
    setInputField({
      ...inputField,
      displayName: e.target.value,
    })
  }

  const handleChangeType = e => {
    const type = e.target.value
    if (type === 'TEXT' || type === 'LIST') {
      return onEdit()
    }
    setInputField({
      ...inputField,
      definition: {
        allowOther: null,
        options: null,
        size: null,
        type,
      }
    })
  }

  const handleChangeLevel = e => {
    setInputField({
      ...inputField,
      displayConfig: {
        ...inputField.displayConfig,
        level: e.target.value,
      }
    })
  }

  const handleChangeValidation = () => {
    setInputField({
      ...inputField,
      validation: isRequired ? 'OPTIONAL' : 'REQUIRED',
    })
  }

  const handleChangeSize = e => {
    setInputField({
      ...inputField,
      definition: {
        ...inputField.definition,
        size: e.target.value,
      },
    })
  }

  const displayLevels = useMemo(() => {
    return clientType === 'PERSON' ? EntityInputDisplayLevelEnumPerson : EntityInputDisplayLevelEnumLegalEntity
  }, [clientType])

  return (
    <Box display="flex" justifyContent="center" mb={3} width={'100%'}>
      <InputFieldDialog
        open={fieldDialogOpen}
        onClose={() => setFieldDialogOpen(false)}
        onSave={(data) => { console.log({ data }) }}
        origData={inputField}
        clientType={clientType}
        isEdit={isEdit}
      />
      <Card style={{ marginTop: 10, height: 46, marginRight: -8, cursor: disabled ? 'default' : 'pointer' }}>
        <Box pl="12px" pr="20px" display="flex" alignItems="center" height="100%">
          <BurgerIcon />
        </Box>
      </Card>
      <Card style={{ width: '100%', padding: 16 }}>
        <Box display="flex" justifyContent="flex-start" alignItems="center" width="100%">

          <Box display="flex" flexDirection="column" width="calc(40% - 32px)" height="100%" px={2}>
            <Typography variant="body2">Global Field Name</Typography>
            <Box width="100%" height={28} display="flex" alignItems="center">
              <TextField
                fullWidth
                value={inputField.displayName}
                onChange={handleChangeName}
                disabled={disabled}
              />
            </Box>
          </Box>

          <Divider orientation="vertical" flexItem />

          <CustomTooltip
            title={isEdit
              ? 'You cannot change the field type for already saved fields.'
              : null
            }
            placement="top"
          >
            <Box display="flex" flexDirection="column" width="calc(20% - 32px)" height="100%" px={2}>
              <Typography variant="body2">Field Type</Typography>
              <Box width="100%" height={28} display="flex" alignItems="center">
                <Select
                  fullWidth
                  value={inputField.definition.type}
                  onChange={handleChangeType}
                  disabled={isEdit || disabled}
                >
                  {Object.entries(EntityInputTypeEnum).map(([optionValue, optionLabel]) => (
                    <MenuItem
                      key={optionValue}
                      value={optionValue}
                    >
                      {optionLabel}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </CustomTooltip>

          <Divider orientation="vertical" flexItem />

          <Box display="flex" flexDirection="column" width="calc(25% - 32px)" height="100%" px={2}>
            <Typography variant="body2">Display Level</Typography>
            <Box width="100%" height={28} display="flex" alignItems="center">
              <Select
                fullWidth
                value={inputField.displayConfig.level}
                onChange={handleChangeLevel}
                disabled={disabled}
              >
                {Object.entries(displayLevels).map(([optionValue, optionLabel]) => (
                  <MenuItem
                    key={optionValue}
                    value={optionValue}
                  >
                    {optionLabel}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>

          <Divider orientation="vertical" flexItem />

          <Box display="flex" justifyContent="space-between" alignItems="center" width="calc(15% - 35px)" height="100%" px={2}>

            <Box display="flex" flexDirection="column" height="100%">
              <Typography variant="body2">Is Required</Typography>
              <Box width="100%" height={28} display="flex" alignItems="center">
                <FormControlLabel
                  control={<Switch checked={isRequired} onClick={handleChangeValidation} classes={{ root: classes.switch }} />}
                  label={isRequired ? 'Yes' : 'No'}
                  disabled={disabled}
                  classes={{ root: classes.switchControl }}
                />
              </Box>
            </Box>

            <Box>
              {!disabled &&
                <MenuWithContext title="More Actions">
                  <MenuItem onClick={onEdit}>
                    Edit
                  </MenuItem>
                  <MenuItem onClick={onDelete}>
                    Exclude
                  </MenuItem>
                </MenuWithContext>
              }
            </Box>

          </Box>
        </Box>
        {inputField.definition.type === 'TEXT' &&
          <Box display="flex" justifyContent="flex-start" alignItems="center" width="100%" mt={2}>
            <Box display="flex" flexDirection="column" height="100%" px={2}>
              <Typography variant="body2">Max Length</Typography>
              <Box width="100%" height={28} display="flex" alignItems="center">
                <TextField
                  fullWidth
                  value={inputField.definition.size}
                  type="number"
                  onChange={handleChangeSize}
                  disabled={disabled}
                  InputProps={{ readOnly: true }}
                />
              </Box>
            </Box>
          </Box>
        }
        {inputField.definition.type === 'LIST' &&
          <Box display="flex" justifyContent="flex-start" alignItems="center" width="100%" mt={2}>
            <Box display="flex" flexDirection="column" height="100%" px={2}>
              <Typography variant="body2">Custom List Items</Typography>
              <Box display="flex" ml="-2px" flexWrap="wrap">
                {inputField.definition.options.map(option => (
                  <Box py="4px" pr="4px" key={option}>
                    <CustomChip label={option} border="1px solid #656565" />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        }
      </Card>
    </Box>
  )
}

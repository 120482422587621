import React, {useEffect, useMemo, useState} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Menu, MenuItem, Typography
} from '@material-ui/core';

import ButtonWithIcon from '@components/buttons/button-with-icon';
import history from '@app/history';
import NoResultsFound from '@components/layout/no-results-found';
import {
  WarningIcon,
  RateNotsetIcon,
  SearchHistoryInactiveIcon
} from '@app/icons';
import CustomTooltip from '@components/controls/tooltip';
import grey from '@material-ui/core/colors/grey';
import prepareTimestamp from '@utils/date';
import {riskRating} from '@app/api/client';
import riskRatingListDto from './dto/risk-rating-list-dto';
import _ from 'lodash'
import RisksListTable from './components/list-table';
import {exportRiskRatingPDF} from '@utils/exportPDF';
import {useSnackbar} from 'notistack';
import {clientApi} from '@app/api';
import DeleteDialog from './components/delete-dialog';
import RiskRatingIcon from '@components/flexible-icons/risk-rating-icon';
import NewRiskRatingDialog from './components/new-risk-rating-dialog';

const useStyles = makeStyles((theme) => ({
  resumeRiskRatingBtn: {
    '& .MuiButton-startIcon': {
      marginRight: 0
    }
  }
}))

const sortableColumns = ['started', 'finished']

const sortDtoMapping = {
  started: 'startDate',
  finished: 'dateOfReview'
}

export default function RiskRatingList(props) {

  const { data, baseUri, readonly, clientId } = props;
  const setClientData = props.setData;
  const clientData = props.data;

  const [ lines, setLines ] = useState([])//eslint-disable-line
  const [ fetched, setFetched ] = useState(false)//eslint-disable-line
  const [ menuAnchorEl, setMenuAnchorEl ] = React.useState(false)
  const [ sort, setSort ] = useState('finished')//eslint-disable-line
  const [ sortDirection, setSortDirection ] = useState('DESC')//eslint-disable-line
  const [ pendingDeleteId, setPendingDeleteId] = useState(null)
  const [ pendingNewRiskRatingDialog, setPendingNewRiskRatingDialog ] = useState(false)

  const [page, setPage] = React.useState(0)
  const [pageSize] = React.useState(10) //eslint-disable-line
  const [pagesCount, setPagesCount] = React.useState(1)
  const [totalCount, setTotalCount] = React.useState(0)
  const onPageChange = (event, page) => {
    setPage(page - 1)
  }

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  useEffect(() => {

    }, [lines]) // eslint-disable-line

  useEffect(() => {
        fetch() // eslint-disable-line
    }, [sort, sortDirection, page]) // eslint-disable-line

  const lastRiskRating = useMemo(() => {
    const lastUpdateDates = _.map(lines, 'lastUpdatedOn')
    return _.max(lastUpdateDates)
  }, [lines])

  const fetch = () => {
    riskRating.index(clientId, {
      pageNumber: page,
      pageSize,
      sortBy: sortDtoMapping[sort],
      sortDirection
    }).then(({data}) => {
      let newLines = data?.entries?.map(
        riskRatingListDto.read
      ) || []
      setLines( newLines )
      setPagesCount(data?.pages)
      setTotalCount(data?.count)
      setFetched(true)
    })
  }

  const draftRiskRatingId = useMemo(() =>
    lines.find(l => l.isDraft)?.id,
  [lines])

  const onTriggerMenu = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setMenuAnchorEl(event.currentTarget)
  }

  const onCloseMenu = () => {
    setMenuAnchorEl(null)
  }

  const onResume = () => {
    history.push(`${baseUri}/${draftRiskRatingId}`)
  }

  const onView = (item) => {
    if(!readonly) {
      if (!item.isDraft) {
        history.push(`${baseUri}/${item.id}/view`)
      } else {
        onResume()
      }
    }
  }

  const onDelete = async id => {
    setPendingDeleteId(id)
  }

  const onDeleteConfirm = async () => {
    await clientApi.riskRating.remove(pendingDeleteId)
    setClientData({
      ...clientData,
      hasIncompleteRiskRating: false
    })
    setPendingDeleteId(null)
    enqueueSnackbar('Risk Rating draft was successfully deleted!', { variant: 'success' });
    await fetch()
  }

  const onReset = async () => {
    await riskRating.reset(clientId)
    await fetch()
    onCloseMenu()
  }

  const latestFinished = useMemo(() => lines.filter(l => !l.isDraft).sort((a,b) => b.id-a.id)?.[0], [lines])

  const onStartNewWithFlowSelect = () => {
    if(latestFinished) {
      setPendingNewRiskRatingDialog(true)
    } else {
      onStartNew()
    }
  }

  const onStartNew = () => {
    history.push(`${baseUri}/new`)
    onCloseMenu()
  }

  const onStartNewFromExisting = () => {
    history.push(`${baseUri}/${latestFinished.id}/new`)
    onCloseMenu()
  }

  const onExport = async (id) => {
    await exportRiskRatingPDF({
      riskRatingId: id
    })
    enqueueSnackbar('The report was successfully exported!', { variant: 'success' });
  }

  const handleSort = (field) => {
    if (sortableColumns.indexOf(field) !== -1) {
      onSort(field)
    }
  }

  const onSort = (field) => {
    let newSort = 'ASC'
    if(sort === field && sortDirection === 'ASC') {
      newSort = 'DESC'
    }
    setSortDirection(newSort)
    setSort(field)
  }

  return (
    <React.Fragment>
      <Menu
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={onCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        { !readonly && !!draftRiskRatingId &&
          <MenuItem onClick={onReset}>
              Reset Rating
          </MenuItem>
        }
        { !readonly && !!draftRiskRatingId &&
          <MenuItem onClick={onResume}>
              Resume Risk Rating
          </MenuItem>
        }
        { !readonly && !draftRiskRatingId &&
          <MenuItem onClick={onStartNewWithFlowSelect}>
              Start Risk Rating
          </MenuItem>
        }
      </Menu>
      <Box display={'flex'} flexGrow={1} flexDirection={'column'} mt={3}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={'-5px'}>
          <Box display={'flex'} alignItems={'center'}>
            <Typography variant={'h5'}>Risk Ratings</Typography>
            { lastRiskRating &&
              <React.Fragment>
                  &emsp;
                <CustomTooltip title="Last Reviewed" aria-label="Last Reviewed" placement="top">
                  <SearchHistoryInactiveIcon width={12}/>
                </CustomTooltip>
                  &nbsp;
                <Typography variant={'caption'} style={{color: grey[500]}}>{prepareTimestamp(lastRiskRating)}</Typography>
              </React.Fragment>
            }
          </Box>
          <Box display={'flex'} alignItems={'center'}>
            <Box mr={1} onClick={onTriggerMenu} hidden>
              <RiskRatingIcon
                risk={data?.risk_score}
                withTooltip={true}
                placement={'right'}
                style={{cursor: 'pointer'}}
              />
            </Box>
            {!readonly && draftRiskRatingId &&
              <React.Fragment>
                <Box display={'flex'} alignItems={'center'}>
                  <WarningIcon/>
                  <Box>
                    <Typography variant={'body1'}>Review in Progress</Typography>
                  </Box>
                </Box>
                <ButtonWithIcon startIcon={<RateNotsetIcon />} className={classes.resumeRiskRatingBtn} onClick={onResume}>
                      Resume Risk Rating
                </ButtonWithIcon>
              </React.Fragment>
            }
            {!readonly && !draftRiskRatingId &&
              <ButtonWithIcon startIcon={<RateNotsetIcon />} className={classes.resumeRiskRatingBtn} onClick={onStartNewWithFlowSelect}>
                  Start Risk Rating
              </ButtonWithIcon>
            }
          </Box>
        </Box>

        { !!lines.length &&
          <React.Fragment>
            <Box display={'flex'} mt={1} mx={-2}>
              <RisksListTable
                data={data}
                sortableColumns={sortableColumns}
                handleSort={handleSort}
                lines={lines}
                onExport={onExport}
                onView={onView}
                onDelete={onDelete}
                sortDirection={sortDirection}
                sort={sort}
                readOnly={readonly}
                page={page}
                pagesCount={pagesCount}
                totalCount={totalCount}
                pageSize={pageSize}
                onPageChange={onPageChange}
              />
            </Box>
          </React.Fragment>
        }
        { fetched && !lines.length &&
          <Box mt={5}>
            <NoResultsFound>
                  When you add a risk rating, it will appear here
            </NoResultsFound>
          </Box>
        }
      </Box>
      <DeleteDialog
        open={!!pendingDeleteId}
        onClose={() => setPendingDeleteId(null)}
        onConfirm={onDeleteConfirm}
      />
      <NewRiskRatingDialog
        open={!!pendingNewRiskRatingDialog}
        onClose={() => setPendingNewRiskRatingDialog(null)}
        onNew={onStartNew}
        onExisting={onStartNewFromExisting}
      />
    </React.Fragment>
  )
}
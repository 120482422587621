import React from 'react';
import { Modal } from '@components/modals';

export default function DeleteDialog({open, name, jurisdictionName, onClose, onDelete}) {

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title={'Delete Confirmation'}
        mainText={`Are you sure you want to remove entity ${name} from jurisdiction ${jurisdictionName}?`}
        actions={
          [
            {
              type: 'main',
              label: 'CANCEL',
              action: onClose,
              style: { width: 160 },
            },
            {
              type: 'secondary',
              label: 'DELETE',
              action: onDelete,
              style: { width: 160 },
            },
          ]
        }
        actionsDirection="row"
      />
    </React.Fragment>
  );
}
import React, { useCallback, useState, useEffect } from 'react';
import { IconButton, MenuItem, InputBase, Box } from '@material-ui/core';
import { SelectOutlined } from '@components/inputs';
import { CloseIcon, SearchOtherIcon } from '@app/icons';

import { getSearches } from '@utils/global-search-helpers/getSearches';
import { goBack } from '@utils/global-search-helpers/goBack'; // eslint-disable-line
import { useSelector, useDispatch } from 'react-redux';
import { globalSearch, scroll, paginations } from '@app/store/actions';
import { useLocation } from 'react-router-dom';
import history from '@app/history';

import { useStyles } from './styles';

const OPTIONS = [
  { value: 'Clients', color: '#484848', bgColor: '#C6C6C6' },
  { value: 'People', color: '#484848', bgColor: '#D5F0F0' },
  { value: 'Legal Entities', color: '#484848', bgColor: '#D1C2B6' },
  { value: 'Folders', color: '#FFF', bgColor: '#A1B6CA' },
  { value: 'Documents', color: '#FFF', bgColor: '#C0A2B8' },
];

const PLACEHOLDERS = {
  Clients: 'Search for people and legal entities',
  People: 'Search for people',
  'Legal Entities': 'Search for legal entities',
  Folders: 'Search for folders',
  Documents: 'Search by file name',
};

export default function GlobalSearch() {
  const location = useLocation();
  const dispatch = useDispatch();

  const { loader } = useSelector((store) => store.loader);
  const { search, searchType } = useSelector((store) => store.globalSearch);

  const [searchValue, setSearchValue] = useState('');
  const [selectValue, setSelectValue] = useState(OPTIONS[0]);
  const [focus, setFocus] = useState(false);

  const classes = useStyles({
    selectValue: OPTIONS.find((opt) => opt.value === selectValue?.value),
  });

  useEffect(() => {
    setSearchValue(search);
    setSelectValue(searchType);

    return () => {
      setFocus(false);
    };
  }, [search, searchType]);

  const handleSearches = useCallback(async () => {
    dispatch(scroll.setTriggerScroll(true));
    dispatch(paginations.setDefault());
    const trimmedSearch = searchValue.trim()
    if (!location.pathname.includes('/global-search')) {
      localStorage.setItem(
        'prevLocation',
        `${location.pathname}${location.search}`
      );
    }
    await getSearches(
      { searchType: selectValue, search: trimmedSearch, pageNumber: 0 },
      true
    );

    history.push(
      `/global-search/?value=${trimmedSearch}&type=${selectValue.value}`
    );

    dispatch(scroll.setTriggerScroll(false));
  }, [selectValue, searchValue, location, dispatch]);

  const handleSearch = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  const handleKeyDown = useCallback(
    (e) => {
      if (!!e.repeat || loader) return;

      if (e.key === 'Enter') {
        handleSearches();
        dispatch(globalSearch.setPage(0));
      }
    },
    [loader, handleSearches, dispatch]
  );

  const handleChange = useCallback((event) => {
    if (!event.target.value) return;

    const selectedValue = OPTIONS.find((el) => el.value === event.target.value);
    setSelectValue(selectedValue);
  }, []);

  const handleFocus = useCallback(() => {
    !selectValue && setSelectValue(OPTIONS[0]);
    setFocus(true);
  }, [selectValue]);

  const renderMenu = useCallback(() => {
    return OPTIONS.map((el, ind) => (
      <MenuItem
        classes={{
          gutters: classes.menuItemGutters,
          root: classes.menuItemStyle,
        }}
        style={{
          color: el.color,
          backgroundColor: el.bgColor,
          fontWeight: ['Folders', 'Documents'].includes(el.value) ? 400 : 500,
        }}
        key={`MenuItem-select${ind}-${el.value}`}
        value={el.value}
      >
        {el.value}
      </MenuItem>
    ));
  }, [classes]);

  const handleClear = useCallback(() => {
    setSearchValue('');
    setSelectValue(null);
    setFocus(false);
  }, []);

  const renderSelect = useCallback(() => {
    return (
      <SelectOutlined
        value={selectValue?.value}
        handleChange={handleChange}
        renderMenu={renderMenu}
        className={classes.selectRoot}
        propClasses={classes.selectLabel}
        anchorProps={{
          vertical: 35,
          horizontal: -25,
        }}
        menuWrapperStyle={classes.menuWrapperStyle}
        menuItemStyle={classes.menuItemStyle}
      />
    );
  }, [selectValue, classes, handleChange, renderMenu]);

  return (
    <React.Fragment>
      <div className={classes.globalSearchWrapper}>
        {!!selectValue || focus ? (
          renderSelect()
        ) : (
          <Box className={classes.customChip}>
            <SearchOtherIcon height={20} width={20} />
          </Box>
        )}
        <div className={classes.verticalLine} />
        <InputBase
          placeholder={
            !searchValue && focus
              ? PLACEHOLDERS[selectValue?.value]
              : 'Search for anything'
          }
          onFocus={handleFocus}
          // onBlur={() => (!searchValue || !searchValue) && setFocus(false)}
          value={searchValue}
          onChange={handleSearch}
          onKeyDown={handleKeyDown}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
        {!!searchValue && (
          <IconButton
            classes={{ root: classes.buttonRoot }}
            onClick={() => handleClear()}
            // disabled={!location.pathname.includes('/global-search')}
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>
    </React.Fragment>
  );
}

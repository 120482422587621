import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  Switch,
  RadioGroup,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core';

import history from '@app/history';
import { clientApi } from '@app/api';
import { ChevronLeftIcon, SearchIcon } from '@app/icons';
import { ongoingMonittoring } from '@store/actions';

import ButtonWithIcon from '@components/buttons/button-with-icon';
import InnerHeader from '@components/layout/inner-header';
import ViewToggle from '@components/controls/view-toggle';
import OutlinedSearch from '@components/inputs/outlined-search';
import FilterWithContext from '@components/dropdowns/filter-with-context';
import CardList from '@components/lists/card-list';
import TableList, { TCELL_TYPES } from '@components/lists/table-list';
import NoResultsFound from '@components/layout/no-results-found';
import debounce from 'awesome-debounce-promise';

import {prepareClient, getSortField, getSortFields} from '@dto/client';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import getRiskRatingLabel from '@utils/riskRatingLabel';
import { useSnackbar } from 'notistack';
import { useFirstRender } from '@hooks/useFirstRender';
import { outdatedChip } from '../../../../../components/badges/outdated-chip';

const BULK_MONITORING_LIMIT = 3000

const CARD_LABELS = {
  risk_score: 'RISK RATING',
  whitelist: 'WHITELIST',
  date_of_birth: 'DOB',
  gender: 'GENDER',
  citizenship: 'CITIZENSHIP',
  last_review_at: 'LAST REVIEW',
  next_review_at: 'NEXT REVIEW',
}

const TABLE_COLUMNS_PERSON = [
  { field: 'name', headerName: 'Full name', bold: true, colProps: { width: '30%' }, cellType: TCELL_TYPES.TEXT_ICON, decoration: outdatedChip },
  { field: 'folder_name', headerName: 'Folder'},
  { field: 'citizenship', headerName: 'Citizenship' },
  { field: 'risk_score', headerName: 'Risk Rating', colProps: { style: { textTransform: 'capitalize' } } },
  { field: 'formatted_created_at', headerName: 'Date Added' },
  { field: 'last_review_at', headerName: 'Last Review' },
  { field: 'next_review_at', headerName: 'Next Review' }
]

const CARD_LABELS_COMPANY = {
  risk_score: 'RISK RATING',
  type: 'TYPE',
  formed_at: 'FORMATION DATE',
  country: 'COUNTRY',
  last_review_at: 'LAST REVIEW',
  next_review_at: 'NEXT REVIEW',
}

const TABLE_COLUMNS_COMPANY = [
  { field: 'name', headerName: 'Name', bold: true, colProps: { width: '30%' }, cellType: TCELL_TYPES.TEXT_ICON, decoration: outdatedChip },
  { field: 'folder_name', headerName: 'Folder'},
  { field: 'country', headerName: 'Country' },
  { field: 'risk_score', headerName: 'Risk Rating', colProps: { style: { textTransform: 'capitalize' } } },
  { field: 'formatted_created_at', headerName: 'Date Added' },
  { field: 'last_review_at', headerName: 'Last Review' },
  { field: 'next_review_at', headerName: 'Next Review' }
]

const CARD_LABELS_COMBINED = {
  name: 'NAME',
  formatted_created_at: 'CREATED ON',
  risk_score: 'RISK RATING',
  last_review_at: 'LAST REVIEW',
  next_review_at: 'NEXT REVIEW',
}

const TABLE_COLUMNS_COMBINED = [
  { field: 'name', headerName: 'Name', bold: true, colProps: { width: '30%' }, cellType: TCELL_TYPES.TEXT_ICON, decoration: outdatedChip },
  { field: 'folder_name', headerName: 'Folder'},
  { field: 'risk_score', headerName: 'Risk Rating', colProps: { style: { textTransform: 'capitalize' } } },
  { field: 'formatted_created_at', headerName: 'Date Added' },
  { field: 'last_review_at', headerName: 'Last Review' },
  { field: 'next_review_at', headerName: 'Next Review' }
]

const debouncedPersonIndex = debounce(clientApi.person.index, 500);
const debouncedCompanyIndex = debounce(clientApi.company.index, 500);
const debouncedClientIndex = debounce(clientApi.client.index, 500);

const defaultFilters = [
  { type: 'toggle', isChecked: false, value: 'closely-monitoring', label: 'Closely Monitoring', name: 'riskRating' },
  { type: 'toggle', isChecked: false, value: 'high', label: 'High', name: 'riskRating' },
  { type: 'toggle', isChecked: false, value: 'medium', label: 'Medium', name: 'riskRating' },
  { type: 'toggle', isChecked: false, value: 'low', label: 'Low', name: 'riskRating' },
  { type: 'toggle', isChecked: false, value: 'exempted', label: 'Exempted', name: 'riskRating' },
  //
  { type: 'toggle', isChecked: false, value: 'CORPORATION', label: 'Corporation', name: 'legalEntityType' },
  { type: 'toggle', isChecked: false, value: 'FOUNDATION', label: 'Foundation', name: 'legalEntityType' },
  { type: 'toggle', isChecked: false, value: 'LIMITED_LIABILITY_COMPANY', label: 'Limited Liability Company (LLC)', name: 'legalEntityType' },
  { type: 'toggle', isChecked: false, value: 'PARTNERSHIP', label: 'Partnership', name: 'legalEntityType' },
  { type: 'toggle', isChecked: false, value: 'TRUST', label: 'Trust', name: 'legalEntityType' },
]

const tabs = [
  { id: 'person', label: 'People' },
  { id: 'company', label: 'Legal Entities' },
  { id: 'all', label: 'All' }
]

const useStyles = makeStyles((theme) => ({
  selectedCount: {
    color: '#4E922C',
  },
  button: {
    padding: '10px 20px !important',
    width: 200,
  },
  warning: {
    color: '#A98320',
    fontStyle: 'italic',
  }
}));

export default function ChooseClients(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedIds } = useSelector(store => store.ongoingMonittoring);
  const { searchId, profileId, tabId } = useParams();
  const firstRender = useFirstRender();
  const { onNext, onNext2, onChangeTab, steps } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [tab, setTab] = useState(tabId || 'person')

  // Pagination
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(12)
  const [pagesCount, setPagesCount] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const onPageChange = (event, page) => {
    setPage(page - 1)
  }

  const [view, setView] = useState('list'); // list/cards
  const [search, setSearch] = useState(null);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState('name');
  const [sortDirection, setSortDirection] = useState('ASC');
  const [filters, setFilters] = useState(defaultFilters);
  const [selectedItems, setSelectedItems] = useState(() => {
    if (!selectedIds?.length) {
      return {}
    } else {
      const selectedItems = {}
      selectedIds.forEach(id => {
        selectedItems[id] = true;
      })
      return selectedItems
    }
  });


  useEffect(() => {
    if (!firstRender) {
      const timeOutId = setTimeout(() => fetchItems(), 1000);
      return () => clearTimeout(timeOutId);
    }
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    fetchItems()
    // eslint-disable-next-line
    }, [page, sort, filters, sortDirection, tab, pageSize])

  const fetchItems = async () => {
    const riskRatings = filters.filter(el => el.name === 'riskRating' && el.isChecked)?.map(el => el.value);
    const legalEntityTypes = filters.filter(el => el.name === 'legalEntityType' && el.isChecked).map(el => el.value);
    
    const searchKeyword = search?.trim() || ''
    if (searchKeyword.length < 2 && searchKeyword.length) {
      enqueueSnackbar('Search keyword should have at least 2 characters!', {
        variant: 'error',
      });
      return;
    }

    const params = {
      pageNumber: page,
      pageSize,
      searchKeyword: searchKeyword || null,
      sortBy: sort && getSortField(sort, tab),
      sortDirection: sortDirection && sortDirection,
      monitory: false,
      riskRatings,
      legalEntityTypes,
    }

    let data;
    switch (tab) {
      case 'person':
        data = (await debouncedPersonIndex(params)).data
        break;
            
      case 'company':
        data = (await debouncedCompanyIndex(params)).data
        break;

      case 'all':
        data = (await debouncedClientIndex(params)).data
        break;
        
      default:
        break;
    }
    setData((data?.entries || []).map(item => prepareClient({ ...item })))
    setPagesCount(data?.pages || 1);
    setTotalCount(data?.count || 0);
  }


  const onTabChange = (index) => {
    const tabID = tabs[index].id
    setTab(tabID)
    setPage(0);
    onChangeTab(tabID)
  }

  const onSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(0);
  }

  const onClick = (id) => {
    const type = data.find(e => e.id === id).client_type === 'PERSON' ? 'person' : 'company';
    return onNext({ type, searchId, profileId, clientId: id });
  }

  const onFilterChange = (event, name) => {
    const newFilters = filters.map(el => el.value === event.target.value
      ? ({ ...el, isChecked: event.target.checked })
      : el
    );
    setFilters(newFilters)
    setPage(0);
  }

  const onSort = (value) => {
    setPage(0)

    if(sort !== value) {
      setSort(value)
      setSortDirection('ASC')
    } else {
      setSortDirection(sortDirection === 'ASC'? 'DESC': 'ASC')
    }
  }

  const onSelect = (id, isChecked) => {
    if (id === 'all') {
      const selectedInPage = {}
      data.forEach(e => {
        selectedInPage[e.id] = isChecked
      })

      setSelectedItems({ ...selectedItems, ...selectedInPage })
    } else {
      setSelectedItems({ ...selectedItems, [id]: !selectedItems[id] })
    }
  }

  const handleNext = () => {
    const selectedIds = Object.entries(selectedItems).filter(([id, selected]) => !!selected).map(([id, selected]) => +id);
    dispatch(ongoingMonittoring.setSelectedIds(selectedIds));
    onNext2();
  }

  const hasSearched = useMemo(() => {
    if (!!search) {
      return true;
    }

    return !!filters.find((el) => el.isChecked);
  }, [filters, search])

  const TABLE_COLUMNS = useMemo(() => {
    switch (tab) {
      case 'person':
        return TABLE_COLUMNS_PERSON;
      case 'company':
        return TABLE_COLUMNS_COMPANY;
      case 'all':
        return TABLE_COLUMNS_COMBINED;
      default:
        return TABLE_COLUMNS_PERSON;
    }
  }, [tab])

  const selectedCount = useMemo(() => {
    return Object.values(selectedItems).filter(e => !!e).length
  }, [selectedItems])

  const formatItems = useMemo(() => {
    return data.map(e => ({ ...e, isChecked: selectedItems[e.id] }))
  }, [selectedItems, data])

  const formatListItems = useMemo(() => {
    return formatItems.map(e => ({ ...e, risk_score: getRiskRatingLabel(e.risk_score) }))
  }, [formatItems])

  return (
    <Box px={6}>
      <InnerHeader
        title="Choose Clients"
        stepper={{
          currentStep: steps?.currentStep || 1,
          stepsCount: steps?.stepsCount || 3
        }}
        buttons={
          <React.Fragment>
            <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={() => { history.goBack() }}>
                            Back
            </ButtonWithIcon>
          </React.Fragment>
        }
      />
      <InnerHeader
        onTabChange={onTabChange}
        ind={tabs.findIndex(e => e.id === tab)}
        tabs={tabs}
      />
      <Box pt={3}>
        <Grid container>
          <Grid container item xs={12} alignItems="center">
            <Box mr={4} width="calc(100% - 170px)">
              <OutlinedSearch
                value={search}
                onChange={onSearchChange}
                label="Search clients by name"
                icons={<SearchIcon />}
              />
            </Box>
            <FilterWithContext isActive={filters.some(el => el.isChecked)}>
              <Box pr={3}>
                <Grid container spacing={4}>
                  <Grid item xs={tab === 'company'? 4: 6}>
                    <Box display={'flex'} pt={1} pb={2}>
                      <Typography variant="h5">
                        Sort By
                      </Typography>
                    </Box>
                    {tab === 'person' &&
                      <RadioGroup name="sort" value={sort} onChange={e => setSort(e.target.value)}>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'name'}
                            label={'Name'}
                          />
                        </Box>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'citizenship'}
                            label={'Citizenship'}
                          />
                        </Box>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'residency'}
                            label={'Residency'}
                          />
                        </Box>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'gender'}
                            label={'Gender'}
                          />
                        </Box>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'formatted_created_at'}
                            label={'Date Added'}
                          />
                        </Box>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'next_review_at'}
                            label={'Date of Next Review'}
                          />
                        </Box>
                      </RadioGroup>
                    }
                    {tab === 'company' &&
                      <RadioGroup name="sort" value={sort} onChange={e => setSort(e.target.value)}>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'name'}
                            label={'Name'}
                          />
                        </Box>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'country'}
                            label={'Country'}
                          />
                        </Box>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'formationDate'}
                            label={'Formation Date'}
                          />
                        </Box>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'formatted_created_at'}
                            label={'Date Added'}
                          />
                        </Box>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'next_review_at'}
                            label={'Date of Next Review'}
                          />
                        </Box>
                      </RadioGroup>
                    }
                    {tab === 'all' &&
                      <RadioGroup name="sort" value={sort} onChange={e => setSort(e.target.value)}>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'name'}
                            label={'Name'}
                          />
                        </Box>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'formatted_created_at'}
                            label={'Date Added'}
                          />
                        </Box>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'next_review_at'}
                            label={'Date of Next Review'}
                          />
                        </Box>
                      </RadioGroup>
                    }
                  </Grid>
                  <Grid item xs={tab === 'company'? 4: 6}>
                    <Box display={'flex'} pt={1} pb={2}>
                      <Typography variant="h5">
                        Risk Rating
                      </Typography>
                    </Box>
                    { filters
                      .filter(f => f.name === 'riskRating')
                      .map((el, i) => (
                        <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value}-${i}`}>
                          <FormControlLabel
                            control={<Switch value={el.value} checked={el.isChecked} onClick={event => onFilterChange(event, el.name)} />}
                            label={el.label}
                          />
                        </Box>
                      ))}
                  </Grid>
                  { tab === 'company' && (
                    <Grid item xs={4}>
                      <Box display={'flex'} pt={1} pb={2}>
                        <Typography variant="h5">
                          Legal Entity Type
                        </Typography>
                      </Box>
                      { filters
                        .filter(f => f.name === 'legalEntityType')
                        .map((el, i) => (
                          <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value}-${i}`}>
                            <FormControlLabel
                              control={<Switch value={el.value} checked={el.isChecked} onClick={event => onFilterChange(event, el.name)} />}
                              label={el.label}
                            />
                          </Box>
                        ))}
                    </Grid>
                  )}
                </Grid>
              </Box>
            </FilterWithContext>
            <ViewToggle value={view} onChange={setView} />
          </Grid>
          {!!formatItems.length &&
                        <Grid item xs={12}>
                          <Box mt={2}>
                            {view === 'cards' &&
                                    <CardList
                                      extended
                                      companyLabels={CARD_LABELS_COMPANY}
                                      personLabels={CARD_LABELS}
                                      allLabels={CARD_LABELS_COMBINED}
                                      items={formatItems}
                                      page={page}
                                      pagesCount={pagesCount}
                                      pageSize={pageSize}
                                      totalCount={totalCount}
                                      onPageChange={onPageChange}
                                      type={tab}
                                      onClick={onClick}
                                      onSelect={onSelect}
                                      maxHeight="calc(100vh - 570px)"
                                    />
                            }
                            {view === 'list' &&
                                    <TableList
                                      items={formatListItems}
                                      columns={TABLE_COLUMNS}
                                      page={page}
                                      pageSize={pageSize}
                                      setPageSize={setPageSize}
                                      pagesCount={pagesCount}
                                      totalCount={totalCount}
                                      onPageChange={onPageChange}
                                      onClick={onClick}
                                      onSelect={onSelect}
                                      selectAll
                                      sortableColumns={Object.keys(getSortFields(tab))}
                                      onSort={onSort}
                                      sort={sort}
                                      sortDirection={sortDirection}
                                      maxHeight="calc(100vh - 560px)"
                                    />
                            }
                          </Box>
                        </Grid>
          }
          {!formatItems.length &&
                        <Grid item xs={12}>
                          <Box mt={16} flexGrow={1}>
                            <NoResultsFound>
                              {hasSearched
                                ? 'No entities found for the applied filters'
                                : 'When you save entities in your Portfolio, they will appear here'
                              }
                            </NoResultsFound>
                          </Box>
                        </Grid>
          }
        </Grid>
      </Box>
      <Box my={3}>
        <Box mb={1}>
          <Typography className={classes.selectedCount} variant="subtitle2">{selectedCount} item(s) selected</Typography>
          {selectedCount > BULK_MONITORING_LIMIT && <Typography className={clsx(classes.selectedCount, classes.warning)} variant="subtitle2">Max {BULK_MONITORING_LIMIT} items</Typography>}
        </Box>
        <Grid container alignItems="center" justify="center">
          <Button
            variant="contained"
            size="large"
            disabled={!selectedCount || selectedCount > BULK_MONITORING_LIMIT}
            onClick={handleNext}
            className={classes.button}
          >
            {'Next'}
          </Button>
        </Grid>
      </Box>
    </Box>
  )
}

import React, { useState, useMemo } from 'react';
import { Box, Typography } from "@material-ui/core"; // eslint-disable-line
import Subscription from '@components/complex/subscription';
import TableList from '@components/lists/table-list'; // eslint-disable-line
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100vh - 340px)',
    overflowY: 'auto',
    padding: '0 2px'
  }
}))
// eslint-disable-next-line
const SUBSCRIPTION_COLUMNS = [
  { field: 'subscription', headerName: 'Subscription' },
  { field: 'startDate', headerName: 'Start date' },
  { field: 'endDate', headerName: 'End date' },
  { field: 'status', headerName: 'Status' },
]

const subscriptionItems = [
  {
    id: 1111,
    subscription: 'Basic',
    startDate: '12/12/2020',
    endDate: '12/12/2020',
    status: 'Active',
  },
  {
    id: 2222,
    subscription: 'Basic',
    startDate: '12/12/2020',
    endDate: '12/12/2020',
    status: 'Expired',
  },
  {
    id: 3333,
    subscription: 'Basic',
    startDate: '12/12/2020',
    endDate: '12/12/2020',
    status: 'Canceled-Downgraded',
  },
  {
    id: 4444,
    subscription: 'Basic',
    startDate: '12/12/2020',
    endDate: '12/12/2020',
    status: 'Canceled-Downgraded',
  },
  {
    id: 5555,
    subscription: 'Basic',
    startDate: '12/12/2020',
    endDate: '12/12/2020',
    status: 'Expired',
  },
  {
    id: 6666,
    subscription: 'Basic',
    startDate: '12/12/2020',
    endDate: '12/12/2020',
    status: 'Expired',
  },
  {
    id: 7777,
    subscription: 'Basic',
    startDate: '12/12/2020',
    endDate: '12/12/2020',
    status: 'Expired',
  },
  {
    id: 8888,
    subscription: 'Basic',
    startDate: '12/12/2020',
    endDate: '12/12/2020',
    status: 'Expired',
  },
]

export default function Subscriptions({ userId, userName }) {
  const [items] = useState(subscriptionItems);

  const classes = useStyles();

  // eslint-disable-next-line
  const formattedItems = useMemo(() => {
    return items.map(e => {
      const item = { ...e };

      switch (item.status) {
        case 'Active':
          item.cellProps = {
            status: {
              style: {
                color: '#4E922C'
              }
            }
          }
          break;

        case 'Expired':
          item.cellProps = {
            status: {
              style: {
                color: '#B6514C'
              }
            }
          }
          break;
    
        default:
          break;
      }

      return item;
    })
  }, [items])

  return (
    <Box className={classes.root}>
      <Subscription userId={userId} userName={userName} />
      {/* <Box mt={7} mx={2}>
        <Typography variant="h5" style={{ color: '#656565', marginBottom: '24px' }}>HISTORY SUBSCRIPTION PLAN</Typography>
        <TableList
          columns={SUBSCRIPTION_COLUMNS}
          items={formattedItems}
          sortableColumns={['startDate, endDate']}
          page={0}
          pageSize={12}
          pagesCount={1}
          totalCount={8}
        />
      </Box> */}
    </Box>
  )
}
import moment from 'moment';
import prepareTimestamp from '@utils/date';

const documentDto = {
  read: data => ({
    ...data,
    id: data.id,
    categoryId: data.categoryId,
    documentName: data.documentName,
    downloadUrl: data.downloadUrl,
    fileName: data.fileName,
    status: data.status,
    typeId: data.typeId,
    uploadUrl: data.uploadUrl,
    associations: data.associations,
    issueDate: data.issueDate,
    updateDate: data.updateDate,
    uploadDate: data.uploadDate,
    expiryDate: data.expiryDate,
    rulebookId: data.documentRulebookUsageId,
    verifiedBy: data.verifiedBy,
    //
    _issueDate: data.issueDate? prepareTimestamp(data.issueDate, 'MM/DD/YYYY', false): null,
    _updateDate: data.updateDate? prepareTimestamp(data.updateDate, 'MM/DD/YYYY'): null,
    _uploadDate: data.uploadDate? prepareTimestamp(data.uploadDate, 'MM/DD/YYYY'): null,
    _expiryDate: data.expiryDate? prepareTimestamp(data.expiryDate, 'MM/DD/YYYY', false): null,
    version: data.uploadDate? 'V' + prepareTimestamp(data.uploadDate, 'MM.DD.YYYY'): null
  }),
  write: data => ({
    categoryId: data.categoryId,
    documentName: data.documentName || data.fileName,
    expiryDate: !!data.expiryDate? formatDateForBackend(data.expiryDate) : undefined,
    issueDate: !!data.issueDate? formatDateForBackend(data.issueDate) : undefined,
    fileName: data.fileName,
    typeId: data.typeId,
    clientId: data.clientId,
    associationType: data.associationType,
    // documentRulebookUsageId: data.documentRulebookUsageId,
    typeExpirationInMonths: data.requireExpirationInMonths ? data.typeExpirationInMonths : undefined,
  })
}

// ensure that we keep UTC date
const formatDateForBackend = (date) => {
  if (!date) return undefined
  return new Date(moment(date).format('YYYY-MM-DD'))
}

export default documentDto
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import {clientApi} from '@app/api';
import { FIELD_TYPES } from '@app/constants';
import parseYupErrors, { convertFieldsToYupObject } from '@app/yup';
import { prepareDataForUpdate } from '@dto/client';
import { LegalEntitySubtypeEnum } from '@services/client-type';
import RenderInputs from '../../layout/render-inputs';

const JURISDICTION_FIELD = { key: 'registeredOfficeCountry', label: 'Jurisdiction', type: FIELD_TYPES.COUNTRY, required: true, isDefault: true }
const ENTITY_TYPE_FIELD = { key: 'type', label: 'Entity Type', type: FIELD_TYPES.SELECT, required: true, isDefault: true, options: LegalEntitySubtypeEnum }
const RESIDENCY_FIELD = { key: 'residency', label: 'Residency', type: FIELD_TYPES.COUNTRY, required: true, isDefault: true }

export default function ShouldRequireUpdate(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { data, readonly, section, refreshClient } = props;

  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [fields, setFields] = useState([])
  const [model, setModel] = useState({})
  const [schema, setSchema] = useState(null)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (!readonly) {
      const fields = []
      if (data?.client_type === 'LEGAL_ENTITY') {
        if (!data?.raw.registeredOfficeCountry) fields.push(JURISDICTION_FIELD)
        if (!data?.type) fields.push(ENTITY_TYPE_FIELD)
      }
      if (data?.client_type === 'PERSON') {
        if (!data?.raw.residency) fields.push(RESIDENCY_FIELD)
      }

      setFields(fields);
      const model = {}
      fields.forEach((field) => {
        model[field.key] = ''
      })
      setModel(model);
      setSchema(convertFieldsToYupObject(fields))
      setErrors({})
      setShouldUpdate(fields.length);
    }
  }, [data, readonly])


  const handleChange = (key) => (event) => {
    const newModel = {
      ...model,
      [key]: event.target.value
    }
    setModel(newModel)

    if (errors[key]) {
      schema.validateAt(key, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [key]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [key]: err.message });
        });
    }
  }

  const onUpdate = () => {
    schema.validate(model, { abortEarly: false })
      .then(async () => {
        const preparedData = prepareDataForUpdate(data, model)
        if (data?.client_type === 'PERSON') {
          await clientApi.person.update(data.id, preparedData)
          enqueueSnackbar(`Person "${preparedData.firstName} ${preparedData.lastName}" was successfully updated!`, { variant: 'success' });
        } else {
          await clientApi.company.update(data.id, preparedData)
          enqueueSnackbar(`Legal entity "${preparedData.name}" was successfully updated!`, { variant: 'success' });
        }
        refreshClient();
        setShouldUpdate(false);
      })
      .catch((err) => {
        setErrors(parseYupErrors(err))
      })
  }

  return (
    <Dialog open={shouldUpdate}>
      <form onSubmit={(e) => e.preventDefault()}>
        <DialogTitle style={{ textTransform: 'none' }}>
          Select {fields.map(e => e.label).join(' and ')}
        </DialogTitle>
        <DialogContent>
          <Box width={'400px'}>
            <Typography align="center">Before you can proceed with {section},</Typography>
            <Typography align="center">please define {fields.map(e => e.label).join(' and ')} for {data.name}</Typography>
            {fields.map(field => (
              <Box key={field.key} mt={2}>
                <RenderInputs
                  field={field}
                  model={model}
                  handleChange={handleChange}
                  errors={errors}
                />
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button style={{ width: '160px' }} variant={'contained'} onClick={onUpdate} type="submit">
            {'SAVE'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import ButtonWithIcon from '@components/buttons/button-with-icon';

const useStyles = makeStyles((theme) => ({
  regular: {
    color: '#4E922C',
    fontWeight: 'bold'
  },
  changeRoot: {
    padding: 0,
    marginBottom: '-5px'
  },
  startIcon: {
    marginRight: '2px',
  },
  textWidth: {
    width: '125px'
  }
}));

export default function UsageItems({ title, value, additional = {}, action, styleValueWrapper, variant, additionalData }) {
  const classes = useStyles();

  return (
    <Box>
      <Box display="flex" alignItems="flex-start">
        <Typography variant="h5">{title}</Typography>
        {action && (
          <ButtonWithIcon startIcon={action.icon} onClick={action.func} classes={{ root: classes.changeRoot, startIcon: classes.startIcon }}>
            {action.title}
          </ButtonWithIcon>
        )}
      </Box>
      <Box display="flex" alignItems="flex-start" className={styleValueWrapper}>
        <Box display="flex" alignItems="center" mr={8}>
          <Typography variant={variant || 'h3'}>{value}</Typography>
          <Box ml={2}>
            <Typography
              variant="body1"
              className={additional.isText ? classes.textWidth : classes.regular}
            >
              {additional.value}
            </Typography>
          </Box>
        </Box>
        {additionalData}
      </Box>
    </Box>
  )
}

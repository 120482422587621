import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Grid,
  makeStyles,
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Switch,
  FormControl,
  MenuItem,
  Collapse,
} from '@material-ui/core';

import history from '@app/history';
import { ChevronLeftIcon, InfoBrownOtherIcon, RequestUpdateActiveIcon, WarningIcon } from '@app/icons';
import { ongoingMonittoring } from '@store/actions';

import ButtonWithIcon from '@components/buttons/button-with-icon';
import SelectClearable from '@components/inputs/select-clearable';
import RadioButton from '@components/buttons/radio-button';
import InnerHeader from '@components/layout/inner-header';
import CustomTooltip from '@components/controls/tooltip';

import { useDispatch, useSelector } from 'react-redux';
import { clientApi } from '@app/api';
import { injectAvailableCredits } from '@dto/plan';
import { FUZZINESS_INTERVAL } from '../../background-check/subpages/components/seacrh-components/constants';
import { useStyles as useBgCheckStyles } from '../../background-check/subpages/components/seacrh-components/search-block/styles.js';
import { minimumZero } from '@utils/numberUtil';

const useStyles = makeStyles((theme) => ({
  selectedCount: {
    color: '#4E922C',
  },
  button: {
    padding: '10px 20px !important',
    width: 200,
  },
  infoIcon: {
    display: 'inline-block',
    marginRight: 12,
  },
  h5: {
    lineHeight: '18px',
    color: '#656565',
    textIndent: '-13.5px',
  },
  radioBtn: {
    padding: 0,
    margin: '0px 9px'
  },
  error: {
    color: '#B6514C',
  },
  refreshButton: {
    padding: 2,
  },
}));

const CREDIT_TYPES = [
  { label: 'Regular Credits', value: 'regular' },
  { label: 'Premium Credits', value: 'premium' }
]

const LIST_TYPES = [
  { label: 'SANCTIONS', value: 'SANCTIONS' },
  { label: 'PEP', value: 'PEP' },
  { label: 'ADVERSE MEDIA', value: 'ADVERSE_MEDIA', premium: true },
]

export default function SearchSettings(props) {
  const { selectedIds, searchSettings } = useSelector(store => store.ongoingMonittoring);
  const { onNext, steps, onRedirect } = props;
  const classes = useStyles();
  const bgCheckClasses = useBgCheckStyles();
  const dispatch = useDispatch()

  const [alreadyMonitoredCount, setAlreadyMonitoredCount] = useState(0);
  const [credits, setCredits] = useState({ regular: 0, premium: 0 });
  const [fuzziness, setFuzziness] = useState(() => {
    if (searchSettings.fuzziness !== undefined) {
      return searchSettings.fuzziness
    }
    return 0.5;
  })
  const [creditType, setCreditType] = useState(() => {
    if (searchSettings.searchProvider) {
      const creditType = searchSettings.searchProvider === 'ALEPH' ? 'regular' : 'premium'
      return creditType
    }
    return 'regular'
  });
  const [listTypes, setListTypes] = useState(() => {
    const listTypes = {}
    if (searchSettings.listTypes) {
      searchSettings.listTypes.forEach((type) => {
        listTypes[type] = true
      })
    } else {
      LIST_TYPES.forEach((type) => {
        listTypes[type.value] = true
      })
    }
    return listTypes;
  })

  useEffect(() => {
    if (!selectedIds.length) {
      onRedirect();
    }
    clientApi.ongoingMonitoring.clientsOnOngoingMonitoring(selectedIds).then((res) => {
      setAlreadyMonitoredCount(res.data.length)
    })
    getCredits()
  }, [selectedIds]) // eslint-disable-line

  const getCredits = () => {
    clientApi.subscriptionPlans.getSubscriptionForUser().then((res) => {
      injectAvailableCredits(res.data)
      setCredits({
        regular: res.data.availableRegularCredits,
        premium: res.data.availablePremiumCredits,
      })
    })
  }

  const handleCreditChange = (e) => {
    setCreditType(e.target.value)
  }

  const handleListTypeChange = (e) => {
    const type = e.target.value
    setListTypes({ ...listTypes, [type]: !listTypes[type] })
  }

  const handleNext = () => {
    const watchList = LIST_TYPES.filter(type => {
      if (type.premium && creditType !== 'premium') return false;
      if (!listTypes[type.value]) return false;
      return true;
    }).map(e => e.value)

    const searchProvider = creditType === 'regular' ? 'ALEPH' : 'COMPLY_ADVANTAGE'
    dispatch(ongoingMonittoring.setSearchSettings({ listTypes: watchList, searchProvider, fuzziness }));
    onNext();
  }

  const handleBuyCredits = () => {
    window.open('/my-profile/subscription/plans', '_blank')
  }

  const anyListSelected = useMemo(() => {
    return !!LIST_TYPES.filter(type => {
      if (type.premium && creditType !== 'premium') return false;
      if (!listTypes[type.value]) return false;
      return true;
    }).length
  }, [listTypes, creditType])

  const creditNeeded = useMemo(() => {
    return selectedIds.length - alreadyMonitoredCount
  }, [selectedIds, alreadyMonitoredCount])

  const notEnoughCredit = useMemo(() => {
    return credits[creditType] < creditNeeded
  }, [creditType, credits, creditNeeded])

  return (
    <Box px={6}>
      <InnerHeader
        title="Search Settings"
        stepper={{
          currentStep: steps?.currentStep || 2,
          stepsCount: steps?.stepsCount || 3
        }}
        buttons={
          <React.Fragment>
            <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={() => { history.goBack() }}>
              Back
            </ButtonWithIcon>
          </React.Fragment>
        }
      />
      {!alreadyMonitoredCount &&
        <Box mt={5}>
          <Typography variant="h4" align="center">
            {`You’re about to add ${creditNeeded} clients to Ongoing Monitoring`}
          </Typography>
          <Box mt={2}>
            <Typography variant="body1" align="center"><b>{creditNeeded} Search Credits</b> will be spent - one for each name.</Typography>
            <Typography variant="body1" align="center">These credits last for one annual term.</Typography>
          </Box>
        </Box>
      }
      {!!alreadyMonitoredCount &&
        <Box mt={5}>
          <Typography variant="h4" align="center">
            {`You’re about to add ${creditNeeded} clients to Ongoing Monitoring`}
          </Typography>
          <Grid container justify="center">
            <Box mt={4} width="400px" pl="24px">
              <Box>
                <Typography variant="h5" className={classes.h5}>
                  <span className={classes.infoIcon}><InfoBrownOtherIcon /></span>
                  {alreadyMonitoredCount} OF THE SELECTED CLIENTS ARE ALREADY ON YOUR MONITORING LIST
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography variant="body1"><b>0 Search Credits</b> will be used for those name.</Typography>
              </Box>
              <Box mt={3}>
                <Typography variant="h5" className={classes.h5}>
                  <span className={classes.infoIcon}><InfoBrownOtherIcon /></span>
                  {creditNeeded} NEW CLIENTS
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography variant="body1"><b>{creditNeeded} Search Credits</b> will be spent - one for each name.</Typography>
                <Typography variant="body1">These credits last for one annual term.</Typography>
              </Box>
            </Box>
          </Grid>
        </Box>
      }
      <Box mt={4}>
        <Grid container justify="center">
          <Box width="424px">
            <Grid container>
              <Grid item xs={6}>
                <Box>
                  <Box display="flex" height={35} alignItems="center">
                    <Typography variant="h5">Credits</Typography>
                    <Box ml={1}>
                      <ButtonWithIcon compact startIcon={<RequestUpdateActiveIcon />} onClick={getCredits}>Refresh</ButtonWithIcon>
                    </Box>
                  </Box>
                  <Box mt={2}>
                    {CREDIT_TYPES.map(type =>
                      <Box mb={2} key={type.value}>
                        <RadioGroup row>
                          <FormControlLabel
                            control={
                              <RadioButton
                                value={type.value}
                                onChange={handleCreditChange}
                                checked={creditType === type.value}
                                className={classes.radioBtn}
                              />
                            }
                            label={<Typography variant="subtitle2">{creditNeeded} {type.label}</Typography>}
                          />
                        </RadioGroup>
                        <Box pl={4}>
                          <Typography><b>{minimumZero(credits[type.value])}</b> Left</Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Box display="flex" height={35} alignItems="center">
                    <Typography variant="h5">Watchlists</Typography>
                  </Box>
                  <Box mt={1}>
                    {LIST_TYPES.map((type) =>
                      <Box hidden={type.premium && creditType !== 'premium'} key={type.value}>
                        <FormControlLabel
                          control={<Switch value={type.value} checked={listTypes[type.value]} onClick={handleListTypeChange} />}
                          label={type.label}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} container>
                <Collapse in={creditType === 'premium'}>
                  <Box width="175px" my={2}>
                    <Box display="flex" height={35} alignItems="center">
                      <Typography variant="h5">Advanced Search Settings</Typography>
                    </Box>
                    <Box>
                      <FormControl className={bgCheckClasses.inputPersonWrapper} fullWidth variant="outlined">
                        <Box display="flex" flexDirection="row" alignItems="center" width="100%">
                          <SelectClearable
                            value={fuzziness}
                            onChange={(e) => setFuzziness(e.target.value)}
                            clearable={false}
                            style={{ width: '100%' }}
                            classes={{ select: bgCheckClasses.advancedSelect }}
                          >
                            {FUZZINESS_INTERVAL.map(option => (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                              >
                                {option.name}
                              </MenuItem>
                            ))}
                          </SelectClearable>
                          <CustomTooltip
                            title="Level of flexibility of search (0% will check for exact matches only; 100% will look for results with even the slightest match in spelling and phonetics)"
                            placement="top"
                          >
                            <span style={{ marginRight: '-28px' }}>
                              <WarningIcon width="30px" />
                            </span>
                          </CustomTooltip>
                        </Box>
                      </FormControl>
                    </Box>
                  </Box>
                </Collapse>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
      <Box mt={3} pb={1}>
        <Box height="20px">
          {!anyListSelected && <Typography align="center" variant="body1">You need to select at least one watchlist</Typography>}
          {notEnoughCredit &&
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography className={classes.error} align="center" variant="body1">You don't have enough credit(s) to proceed</Typography>
            </Box>
          }
        </Box>
      </Box>
      <Box mt={1} mb={1}>
        <Grid container alignItems="center" justify="center">
          <Button
            variant="outlined"
            size="large"
            onClick={handleBuyCredits}
            className={classes.button}
          >
            {'BUY CREDITS'}
          </Button>
        </Grid>
      </Box>
      <Box mt={1} mb={3}>
        <Grid container alignItems="center" justify="center">
          <Button
            variant="contained"
            size="large"
            disabled={!anyListSelected || notEnoughCredit}
            onClick={handleNext}
            className={classes.button}
          >
            {'Next'}
          </Button>
        </Grid>
      </Box>
      <Box>

      </Box>
    </Box>
  )
}

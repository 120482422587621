import React, {useState} from 'react';
import SelectFolder from '../components/select-folder';
import {Box, Button, Grid, Link, Typography} from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import InnerHeader from '@components/layout/inner-header';
import PageLayout from '@components/layout/page-layout';
import history from '@app/history';
import {clientApi} from '@app/api';
import {prepareClient} from '@dto/client';
import { joinStringArray } from '@utils/joinStringArray';

export default function MoveToFolder(props) {

  const params = props.computedMatch.params
  const { clientType } = params

  const [folderID, setFolderID] = useState()
  const { enqueueSnackbar } = useSnackbar()

  const urlParams = new URLSearchParams(window.location.search)
  const csvIds = urlParams.get('ids')
  const ids = csvIds.split(',')

  const goBack = () => {
    const portfolioTabId = clientType === 'person'? 'tab-persons' : 'tab-companies'
    return history.push(`/portfolio/${portfolioTabId}`)
  }

  const onSave = async () => {
    let folderName = null
    const clientNames = []

    const res = await clientApi.folder.addClients(folderID, ids)

    if (res.status === 200) {
      Promise.all([
        // take folder name from api
        clientApi.folder.show(folderID).then(({data}) => folderName = data.name),
        // take client names
        ...ids.map(async clientId => {
          clientNames.push(
            prepareClient((await clientApi.client.show(clientId))?.data)?.name
          )
        })
      ])
        .then(() => {
          enqueueSnackbar(`${joinStringArray(clientNames.map(e => `"${e}"`))} ${clientNames.length > 1 ? 'were' : 'was'} successfully moved to ${folderName}!`, { variant: 'success' });
          goBack()
        })
    }
  }

  return (
    <React.Fragment>
      <PageLayout>
        <Box px={6}>
          <InnerHeader
            buttons={
              <React.Fragment>
                <ButtonWithIcon startIcon={<ChevronLeft />} onClick={goBack}>
                                    Back
                </ButtonWithIcon>
              </React.Fragment>
            } />
        </Box>

        <Box style={{overflowY: 'auto'}}>
          <Grid container justify={'center'}>
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography variant={'h4'} align={'center'}>
                                    Choose Folder
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={10}>
              <Box mt={2}>
                <SelectFolder folderID={folderID} setFolderID={setFolderID}/>
              </Box>
            </Grid>
            <Grid item xs={10}>
              <Box display={'flex'} direction={'row'} justifyContent={'flex-end'} flexGrow={1} my={3}>
                <Box mx={4} display={'flex'} alignItems={'center'}>
                  <Link onClick={goBack}>
                                        CANCEL
                  </Link>
                </Box>
                <Box mx={1}>
                  <Button disabled={!folderID} style={{width: '160px'}} variant={'contained'} onClick={onSave}>
                                        MOVE
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </PageLayout>
    </React.Fragment>
  )
}
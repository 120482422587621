import React, { useState } from 'react';
import { Box, Card, Typography, Divider, makeStyles } from '@material-ui/core';
import SubscriptionsDate from './subscriptions-date';
import CustomTooltip from '@components/controls/tooltip';

const useStyles = makeStyles((theme) => ({
  card: (props) => ({
    width: '300px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    border: props.active
      ? `1px solid ${theme.palette.background.GY4}`
      : `1px solid ${theme.palette.common.white}`,
    borderRadius: '12px',
    overflow: 'inherit',
    '&:hover': {
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      cursor: 'pointer',
    },
    padding: '22px',
    margin: '0 10px',
  }),
  label: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: theme.palette.text.GY5,
  },
  value: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontFamily: 'Quicksand',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '48px',
    color: theme.palette.text.BL1,
  },
  subscribers: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '28px',
    color: theme.palette.text.BL1,
  },
  subscribersLabel: {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '8px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    color: theme.palette.text.BL1,
    marginLeft: '10px',
  },
}));

export default function SubscriptionCard({ item, onClick }) {
  const classes = useStyles({ active: item.isActive });

  const [nextReview, setNextReview] = useState('');

  return (
    <Card className={classes.card} onClick={() => onClick(item)}>
      <Typography className={classes.label}>Subscription Plan</Typography>
      <Box my={2}>
        <CustomTooltip title={item.name} placement="top">
          <Typography className={classes.value}>
            {item.name === 'Small business plan' ? 'Small business' : item.name}
          </Typography>
        </CustomTooltip>
      </Box>
      <Divider />
      <Box my={1}>
        <SubscriptionsDate value={nextReview} onChange={setNextReview} />
      </Box>
      <Box display="flex" alignItems="center">
        <Typography className={classes.subscribers}>
          {item.subscribers}
        </Typography>
        <Typography className={classes.subscribersLabel}>
          Subscribers
        </Typography>
      </Box>
    </Card>
  );
}

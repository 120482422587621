import React, { useEffect, useMemo, useRef, useState } from 'react';
import PageLayout from '@components/layout/page-layout';
import { Box, makeStyles } from '@material-ui/core';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { CloseIcon } from '@app/icons';

import { Redirect, Route, Switch } from 'react-router';
import history from '@app/history';
import InnerHeader from '@components/layout/inner-header';
import ComingSoon from '@components/complex/coming-soon/coming-soon';
import BillingDetails from '@components/complex/billing-details';
import Payments from '@components/complex/payments';
import Subscription from '@components/complex/subscription';
import { Modal } from '@components/modals';
import debugMode from '@utils/debugMode';

import MyAccount from './my-account';
import SecurityDetails from './security-details';
import SubscriptionPlans from '@components/complex/subscription/plans';

const useStyles = makeStyles((theme) => ({
  header: {
    marginRight: '0px !important'
  },
}));

const debug = debugMode.getIsDebugMode();

export default function MyProfile(props) {
  const classes = useStyles();
  const { tab } = props.computedMatch.params;

  const tabs = [
    {
      id: 'my-account',
      label: 'My Account',
    },
    {
      id: 'security-details',
      label: 'Security Details',
    },
    {
      id: 'billing-details',
      label: 'Billing Details',
      debug: true,
    },
    {
      id: 'payments',
      label: 'Payments',
    },
    {
      id: 'subscription',
      label: 'Subscription',
    }
  ].filter(e => debug || !e.debug)

  const [tabID, setTabID] = useState(tab || 'my-account');
  const [cancelSubModalOpen, setCancelSubModalOpen] = useState(false);
  const [triggerRender, setTriggerRender] = useState(0);
  const pageRoot = useRef(null);

  const onTabChange = (index) => {
    const tabID = tabs[index].id;
    setTabID(tabID);
    history.push(`/my-profile/${tabID}`)
  }

  useEffect(() => {
    if(tab) {
      setTabID(tab);
    }
    }, []) //eslint-disable-line

  useEffect(() => {
    if(tab) {
      setTabID(tab);
    }
  }, [tab])

  // tricks React to re-render when pageRoot ref changed
  useEffect(() => {
    setTriggerRender(triggerRender + 1);
    // eslint-disable-next-line
    }, [pageRoot])

  // eslint-disable-next-line
    const headerButtons = useMemo(() => {
    switch (tab) {
      case 'subscription':
        return (
          <ButtonWithIcon startIcon={<CloseIcon />} onClick={() => setCancelSubModalOpen(true)}>
                        Cancel Subscription
          </ButtonWithIcon>
        )
        
      default:
        return null;
    }
  }, [tab]);


  return (
    <PageLayout {...props}>
      <Box px={6} ref={pageRoot} position="relative">
        <InnerHeader
          onTabChange={onTabChange}
          ind={tabs.findIndex(tab => tab.id === tabID)}
          tabs={tabs}
          propsClassesRoot={classes.header}
          // buttons={headerButtons}
        />
        <Box maxHeight="calc(100vh - 192px)" overflow="auto" pb={4} px={2} mx={-2}>
          <Switch>
            <Route exact path="/my-profile/my-account">
              <MyAccount />
            </Route>
            <Route path="/my-profile/security-details/:action?">
              <SecurityDetails />
            </Route>
            <Route path="/my-profile/billing-details">
              {!debug && <ComingSoon title="Billing Details" />}
              {!!debug && <Box mt={3}><BillingDetails baseRoute="/my-profile/billing-details" /></Box>}
            </Route>
            <Route path="/my-profile/payments/:tab?">
              <Box mt={3}><Payments pageRoot={pageRoot.current} baseRoute="/my-profile/payments" /></Box>
            </Route>
            <Route path="/my-profile/subscription/plans">
              <Box><SubscriptionPlans baseRoute="/my-profile/subscription" /></Box>
            </Route>
            <Route path="/my-profile/subscription">
              <Box mt={3}><Subscription baseRoute="/my-profile/subscription" /></Box>
            </Route>
            <Redirect to="/my-profile/my-account" />
          </Switch>
        </Box>
      </Box>

      <Modal
        open={cancelSubModalOpen}
        onClose={() => setCancelSubModalOpen(false)}
        title="Cancel Subscription"
        mainText={'Are you sure you want to cancel subscription "$name"?'}
        actions={
          [
            {
              type: 'main',
              label: 'NO',
              action: () => setCancelSubModalOpen(false),
              style: { width: 160 },
            },
            {
              type: 'secondary',
              label: 'YES',
              action: () => setCancelSubModalOpen(false),
              style: { width: 160 },
            },
          ]
        }
        actionsDirection="row"
      />
    </PageLayout>
  )
};

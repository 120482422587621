import React from  'react';
import { Box, Typography, Grid } from '@material-ui/core';
import BuyMoreButton from './buy-more-button';

import { REGULAR_TEXT } from '../constants';
import { useStyles } from './styles'
import CreditBar from '@components/complex/subscription/items/credit-bar';
import { minimumZero } from '@utils/numberUtil';

export default function RegularCreditsBlock({ currentPlan }) {
  const classes = useStyles();

  return (
    <Box my={1}>
      <Typography className={classes.subtitleFirst}>Standard Due Diligence</Typography>
      <Grid container display="flex" alignItems="center">
        <Grid item sm={4}>
          <Typography className={classes.subtitleSecond}>
            {minimumZero(currentPlan?.availableRegularCredits)} Regular Credits Available
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <BuyMoreButton />
        </Grid>
      </Grid>
      <Box my={1}>
        <CreditBar
          remainingCredits={minimumZero(currentPlan?.remainingRegularCredits)}
          availableCredits={minimumZero(currentPlan?.availableRegularCredits)}
          reservedCredits={minimumZero(currentPlan?.reservedRegularCredits)}
          maxCredits={minimumZero(currentPlan?.regularCredits)}
          isAdmin
          type="regular"
        />
      </Box>
      <Box my={1}>
        <Typography className={classes.text}>{REGULAR_TEXT}</Typography>
      </Box>
    </Box>
  )
}

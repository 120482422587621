import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Typography, Box } from '@material-ui/core';
import { SelectOutlined } from '@components/inputs';
import CustomTooltip from '@components/controls/tooltip';
import { RateS12HighIcon, RateS12MediumIcon, RateS12LowIcon, RateS12NotRatedIcon, RateNotsetIcon } from '@app/icons';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  stepWrapperRoot: {
    width: '100%',
    margin: '35px 0 0',
    display: 'flex',
    flexDirection: 'column',
  },
  stepWrapperHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
  },
  stepHeaderText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '30px',
    color: theme.palette.text.GY5,
    marginRight: '7px',
  },
  stepSelectWrapper: {
    display: 'flex',
    flexDirection: 'flex-end',
    alignItems: 'center',
    marginBottom: '1px',
  },
  stepHeaderSelect: props => ({
    fontSize: '14px',
    lineHeight: '20px',
    color: props.disabled ? theme.palette.text.GY3 : theme.palette.text.BL1,
  }),
  customStepper: props => ({
    margin: '33px 0 31px 0',
    position: 'relative',
    height: '2px',
    background: props.colorStep || theme.palette.text.LB3,
    '&:before': {
      position: 'absolute',
      content: '""',
      height: '2px',
      background: theme.palette.text.LB3,
      width: '50%',
    }
  }),
  stepsText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '30px',
    textAlign: 'center',
  },
  stepFirstText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '21px',
    textAlign: 'center',
    margin: '20px 0',
    width: '500px',
  },
  stepFirstWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  stepSecondText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    color: theme.palette.tertiary['3_5'],
    margin: '29px 0 10px 0',
  },
  stepButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '8px 0 0',
  },
  stepSelectFlagWrapperr: {
    marginRight: '5px',
  },
  stepSelectFlag: {
    marginRight: '3px',
  },
}))

const rating = [
  { value: 'Low', icon: <RateS12LowIcon width={14} height={14} /> },
  { value: 'Medium', icon: <RateS12MediumIcon width={14} height={14} /> },
  { value: 'High', icon: <RateS12HighIcon width={14} height={14} /> },
]

const CustomStepper = ({ activeStep, handleChange, firstStepText, secondStepText, buttonsBlock, footer, disabled, disabledStepper }) => {
  const { profile, selectedProfile } = useSelector(store => store.backgroundCheck);

  const riskPriority = useMemo(() => (
    (profile?.bgCheckRiskRating || selectedProfile?.bgCheckRiskRating) || 'Not Set'
  ), [profile, selectedProfile])

  const colorStep = activeStep === 1 && '#EFEFEF';
  const classes = useStyles({ colorStep, disabled });

  const [rate, setRate] = useState(rating);

  useEffect(() => {
    if (riskPriority && !disabled) {
      setRate(rating.filter(item => item.value !== riskPriority));
    }
  }, [riskPriority, disabled])

  const handleChangeRisk = (event) => {
    if (!event.target.value) return;

    const value = event.target.value.props.children[1]
    handleChange(value);
    setRate(rating.filter(item => item.value !== value));
  }

  const handleChangeRate = useCallback(() => {
    if (riskPriority === 'Not Set') {
      return disabled ? <RateNotsetIcon height={14} width={14} /> : <RateS12NotRatedIcon height={14} width={14} />
    } else if (riskPriority === 'Low') {
      return <RateS12LowIcon height={14} width={14} />
    } else if (riskPriority === 'Medium') {
      return <RateS12MediumIcon height={14} width={14} />
    } else if (riskPriority === 'High') {
      return <RateS12HighIcon height={14} width={14} />
    } else {
      return disabled ? <RateNotsetIcon height={14} width={14} /> : <RateS12NotRatedIcon height={14} width={14} />
    }
  }, [riskPriority, disabled])

  return (
    <Box className={classes.stepWrapperRoot}>
      <div className={classes.stepWrapperHeader}>
        <Typography className={classes.stepHeaderText}>
          Based on your input, the overall BG search risk is
        </Typography>
        <div className={classes.stepSelectWrapper}>
          <div className={classes.stepSelectFlag}>
            {handleChangeRate()}
          </div>
          <SelectOutlined
            value={
              <CustomTooltip
                title={disabled ? 'Only the last version can be updated, the previous versions are read only' : 'Set BG check risk'}
                placement="top"
              >
                <span>{riskPriority}</span>
              </CustomTooltip>
            }
            handleChange={handleChangeRisk}
            options={
              rate.map(el => (
                <div>
                  <span className={classes.stepSelectFlagWrapperr}>{el.icon}</span>
                  {el.value}
                </div>
              ))
            }
            propClasses={classes.stepHeaderSelect}
            anchorProps={{
              vertical: 'bottom',
              horizontal: -45,
            }}
            disabled={disabled}
          />
        </div>
      </div>
      {!disabledStepper && (
        <React.Fragment>
          <div className={classes.customStepper} />
          <Typography className={classes.stepsText}>
            Step {activeStep} out of 2
          </Typography>
        </React.Fragment>
      )}
      {activeStep === 1 ? (
        firstStepText && (
          <Box className={classes.stepFirstWrapper}>
            <Typography className={classes.stepFirstText}>
              {firstStepText}
            </Typography>
          </Box>
        )
      ) : secondStepText && (
        <Typography className={classes.stepSecondText}>
          {secondStepText}
        </Typography>
      )}
      <Box className={classes.stepButtonWrapper}>
        {buttonsBlock}
      </Box>
      {footer}
    </Box>
  )
}

export default CustomStepper;

import { createStore, compose, combineReducers, applyMiddleware } from 'redux';
import reducers from './reducers';
import reducerRegistry from './reducer-registry';
import thunk from 'redux-thunk';

const store = createStore(combineReducers(reducers), compose(
  applyMiddleware(thunk),
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => f
));

// Replace the store's reducer whenever a new reducer is registered.
reducerRegistry.setReducers(reducers)
reducerRegistry.setChangeListener(reducers => {
  store.replaceReducer(combineReducers(reducers));
});

export default store;
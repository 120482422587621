import React from 'react';

import {
  makeStyles, IconButton
} from '@material-ui/core';
import { ImageDownloadIcon } from '@app/icons';
import CustomTooltip from '@components/controls/tooltip';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    position: 'absolute',
    bottom: 8,
    right: 8,
    padding: 4,
  }
}));


export default function ImageDownloadTool({ onDownload }) {
  const classes = useStyles();

  return (

    <CustomTooltip title="Download" placement="left" width={120}>
      <IconButton
        className={classes.iconButton}
        onClick={onDownload}
      >
        <ImageDownloadIcon width={24} height={24} />
      </IconButton>
    </CustomTooltip>
  )
}
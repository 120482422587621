import React, { useEffect } from  'react';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { loader } from '@store/actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  profilesText: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '30px',
    color: theme.palette.text.GY5,
  },
  userCardInfroHeader: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '28px',
    color: theme.palette.text.BL1,
  },
}))

export default function NoHitsBlock({ name }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loader.setMoreLoader(false));
  }, [dispatch]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Typography className={classes.profilesText}>
        No hits found for:
      </Typography>
      <Typography className={classes.userCardInfroHeader}>
        {name}
      </Typography>
    </Box>
  )
};

import React, { useMemo, useState } from 'react';
import { Box, Card, Typography } from '@material-ui/core';
import CustomTooltip from '@components/controls/tooltip';
import {
  ErrorIcon,
  EditIcon,
  EditInactiveIcon,
  CloseIcon,
  CloseInactiveIcon,
  DocumentsAddDocumentHover,
  DocumentsAddDocumentDark,
  CheckmarkIcon
} from '@app/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  categoryCard: {
    marginRight: 16,
    marginBottom: 16,
    position: 'relative',
    cursor: 'pointer',
    width: 144,
    height: 135,
  },
  categoryCardBadge: {
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '7px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#A4A4A4'
  },
  categoryCardClose: {
    position: 'absolute',
    top: 2,
    right: 2,
    cursor: 'pointer'
  },
  categoryCardEdit: {
    position: 'absolute',
    top: 24,
    right: 2,
    cursor: 'pointer'
  },
  categoryName: {
    width: 120,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

const HoverableIcon = ({ activeIcon, inactiveIcon, className, onClick, tooltip }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <CustomTooltip
      width={120}
      title={tooltip}
      placement="left"
    >
      <Box
        className={className}
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered ? activeIcon : inactiveIcon}
      </Box>
    </CustomTooltip>
  )
}

export default function CategoryCard({ category, onDelete, onClick, onEdit, readonly }) {
  const classes = useStyles()
  const [isHovered, setIsHovered] = useState(false);

  const onInternalDelete = event => {
    event.stopPropagation()
    onDelete()
  }

  const onInternalEdit = event => {
    event.stopPropagation()
    onEdit()
  }

  const hasCompleted = useMemo(() => {
    if (category?.hasCompleted) {
      if (category.rule === 'ONE_OF') {
        return category.hasCompleted
      }
      if (category.rule === 'PER_TYPE') {
        return category.hasCompleted >= category.expectedCount
      }
    }
  }, [category])

  return (
    <Card
      display={'flex'}
      className={classes.categoryCard}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {!!category &&
        <Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            pt={4}
            width="100%"
          >
            {category.icon}
          </Box>
          <Box display="flex" justifyContent="center" mt={1}>
            <CustomTooltip
              width={130}
              title={category.name}
            >
              <Typography className={classes.categoryName} variant="subtitle2" align="center">{category.name}</Typography>
            </CustomTooltip>
          </Box>
          {hasCompleted &&
            <Box
              display={'flex'}
              justifyContent={'center'}
              height={24}
              alignItems={'center'}
            >

              <Box display={'flex'} mr="4px">
                <CheckmarkIcon />
              </Box>
              <Box display={'flex'} className={classes.categoryCardBadge}>
                UPLOADED
              </Box>
            </Box>
          }
          {(!hasCompleted && !!category.expected) &&
            <CustomTooltip
              width={120}
              title="Please upload expected KYC documents"
            >
              <Box
                display={'flex'}
                justifyContent={'center'}
                height={24}
                alignItems={'center'}
              >

                <Box display={'flex'}>
                  <ErrorIcon />
                </Box>
                <Box display={'flex'} className={classes.categoryCardBadge}>
                  EXPECTED {category.rule === 'ONE_OF' ? '' : `(${category.expectedCount - category.hasCompleted})`}
                </Box>
              </Box>
            </CustomTooltip>
          }
          {(!hasCompleted && !category.expected) &&
            <Box
              display={'flex'}
              justifyContent={'center'}
              height={24}
              alignItems={'center'}
              className={classes.categoryCardBadge}
            >
              OPTIONAL
            </Box>
          }
          {(!readonly && !!category.isCustom && isHovered) &&
            <HoverableIcon
              className={classes.categoryCardClose}
              onClick={onInternalDelete}
              activeIcon={<CloseIcon />}
              inactiveIcon={<CloseInactiveIcon />}
              tooltip="Delete Category"
            />
          }
          {(!readonly && !!category.isCustom && isHovered) &&
            <HoverableIcon
              className={classes.categoryCardEdit}
              onClick={onInternalEdit}
              activeIcon={<EditIcon />}
              inactiveIcon={<EditInactiveIcon />}
              tooltip="Edit Category"
            />
          }
        </Box>
      }
      {!category &&
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          height="100%"
          width="100%"
        >
          <CustomTooltip
            width={130}
            title="Add Custom Category"
          >
            {isHovered ? <DocumentsAddDocumentHover /> : <DocumentsAddDocumentDark />}
          </CustomTooltip>
        </Box>
      }
    </Card>
  );
}
import React from 'react';
import { Route, Switch } from 'react-router';
import RiskRatingList from './list'
import RiskRatingCreate from './create'
import RiskRatingForm from './form'
import RiskRatingView from './view';
import RiskRatingFinish from './finish';
import CreateFromExisting from './create-from-existing';

export default function RiskRating(props) {
  const { baseUri } = props

  return (
    <Switch>
      <Route path={`${baseUri}/:riskRatingId/new`}>
        <CreateFromExisting {...props} />
      </Route>
      <Route path={`${baseUri}/finish`}>
        <RiskRatingFinish {...props} />
      </Route>
      <Route path={`${baseUri}/new`}>
        <RiskRatingCreate {...props} />
      </Route>
      <Route path={`${baseUri}/:riskRatingId/view`}>
        <RiskRatingView {...props} />
      </Route>
      <Route path={`${baseUri}/:riskRatingId/:sourceRiskRatingId?`}>
        <RiskRatingForm {...props} />
      </Route>
      <Route path={`${baseUri}`}>
        <RiskRatingList {...props} />
      </Route>
    </Switch>
  )
}
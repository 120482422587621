import { countriesISO } from '@services/country-service';
import prepareTimestamp from '@utils/date';
import { v4 as uuidv4 } from 'uuid';

const types = {
  'pep': 'PEP',
  'sanctions': 'S',
  'sanction': 'S',
  'adverse-media-general': 'AM',
  'adverse-media': 'AM',
  'adverse_media': 'AM',
  'pep-class-1': 'PEP',
  'pep-class-2': 'PEP',
  'pep-class-3': 'PEP',
  'pep-class-4': 'PEP',
  'fitness-probity': 'FP',
  'warning': 'W',
};

export const sourcePrepareData = (data, profileAcknowledged, profilesVersionId) => {
  if (!data.length) return;

  return data.map(el => {
    const meta = [];
    const entity = el.sourceData.entity ? el.sourceData.entity.properties : {};
    const sanction = el.sourceData.sanction ? el.sourceData.sanction.properties : {};
    const properties = { ...entity, ...sanction };
    for (const key in properties) {
      const result = key.replace( /([A-Z])/g, ' $1' );
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      
      let value = properties[key].length > 1
        ? properties[key].join(', ')
        : properties[key].join('');

      meta.push({
        field: finalResult,
        value,
      });
    }

    const markedIsIrrelevantOn = !!el.markedIsIrrelevantOn
      ? prepareTimestamp(el.markedIsIrrelevantOn, 'MM/DD/YYYY hh:mm A', false)
      : '';

    return ({
      id: el.id,
      listType: el.listType,
      notes: el.notes,
      relevant: el.relevant,
      bgCheckRiskRating: el.bgCheckRiskRating,
      title: el.sourceData.sourceTable,
      sourceDataId: el.sourceData.id,
      sourceType: el.sourceData.sourceType,
      versionState: (!!profileAcknowledged && el.versionState !== 'DELETED' && !profilesVersionId) ? 'UNCHANGED' : el.versionState,
      versionStateChangedOn: el.versionStateChangedOn,
      markedAsIsIrrelevantReason: el.markedAsIsIrrelevantReason,
      meta,
      isChecked: false,
      markedIsIrrelevantOn,
    })
  });

}

const noKeys = ['aml_types', 'name', 'title', 'source', 'listing_started_utc', 'listing_ended_utc', 'fields', 'caSourceName'];

export const sourcePrepareDataAdvantage = (data, profile, profilesVersionId) => {
  if (!data.length) return;
  const profileAcknowledged = profile.acknowledged;

  const newData = data.map(el => {
    const meta = [];

    let sourceData = el.sourceData;

    for (const key in sourceData) {
      if (!noKeys.includes(key) && !!sourceData[key]) {
        const field = key === 'caSourceName' ? 'ca source name' : key.replace( /_/g, ' ' );

        let value;
        if (key === 'country_codes') {
          const buffer = [];
          sourceData[key].forEach((code, i) => {
            const country = countriesISO.find(el => el.code === code);
            buffer.push(country.name);
          })
          value = arrayToString(buffer);
        } else {
          value = arrayToString(sourceData[key]);
        }
  
        meta.push({ field, value });
      }
    }

    sourceData.fields?.forEach(field => {
      const key = field.name.toLowerCase();
      const fieldValue = (key === 'date of birth' || key === 'designation date')
        ? prepareTimestamp(field.value, 'MM/DD/YYYY', false)
        : field.value;

      meta.push({ field: key, value: fieldValue });
    });
  
    const markedIsIrrelevantOn = !!el.markedIsIrrelevantOn
      ? prepareTimestamp(el.markedIsIrrelevantOn, 'MM/DD/YYYY hh:mm A', false)
      : '';

    const listingStarted = sourceData?.listing_started_utc
      ? `${prepareTimestamp(sourceData?.listing_started_utc, 'MM/DD/YYYY', false)}`
      : null;
    const listingEnded = sourceData?.listing_ended_utc
      ? `${prepareTimestamp(sourceData?.listing_ended_utc, 'MM/DD/YYYY', false)}`
      : null;

    return ({
      id: el.id,
      meta,
      listType: el.sourceData.aml_types ? advantageParseTypes(el.sourceData.aml_types) : types[el.listType.toLowerCase()],
      listingStarted,
      listingEnded,
      notes: el.notes,
      relevant: el.relevant,
      bgCheckRiskRating: el.bgCheckRiskRating,
      title: el.sourceData.name || el.sourceData.title || el.sourceData.source || el.sourceData.caSourceName,
      versionState: (!!profileAcknowledged && el.versionState !== 'DELETED' && !profilesVersionId) ? 'UNCHANGED' : el.versionState,
      versionStateChangedOn: el.versionStateChangedOn,
      markedAsIsIrrelevantReason: el.markedAsIsIrrelevantReason,
      markedIsIrrelevantOn,
      isChecked: false,
    })
  });

  const adverceMediaLinks = prepareAdverceMediaLinks(profile?.media || []);

  const assets = prepareAssets(profile?.assets || []);

  return [ ...newData, ...adverceMediaLinks, ...assets ];
}

const prepareAdverceMediaLinks = (media) => {
  return media.map(el => {
    const meta = [];

    const noKey = ['title'];

    for (const key in el) {
      if (!noKey.includes(key) && !!el[key]) {
        const field = key.replace( /_/g, ' ' );

        let value;
        if (key === 'date') {
          value = prepareTimestamp(el[key], 'MM/DD/YYYY hh:mm A', false)
        } else {
          value = arrayToString(el[key]);
        }
  
        meta.push({ field, value });
      }
    }

    return ({
      id: uuidv4(),
      listType: 'AML',
      title: el?.title || '(no title)',
      meta,
      relevant: true,
      isChecked: false,
    })
  });
}

const prepareAssets = (assets) => {
  return assets.map(el => {
    const meta = [];

    for (const key in el) {
      if (!noKeys.includes(key) && !!el[key]) {
        const field = key.replace( /_/g, ' ' );

        let value;if (key === 'date') {
          value = prepareTimestamp(el[key], 'MM/DD/YYYY hh:mm A', false)
        } else {
          value = arrayToString(el[key]);
        }
  
        meta.push({ field, value });
      }
    }

    return ({
      id: uuidv4(),
      listType: 'A',
      title: el.source,
      meta,
      relevant: true,
      isChecked: false,
    })
  })
}

const arrayToString = (data) => {
  return  typeof data === 'object'
    ? data.length > 1
      ? data.join(', ')
      : data.join('')
    : data;
}

const advantageParseTypes = (data) => {
  const buffer = [];
  data.forEach(el => {
    const lowerCaseEl = el.toLowerCase()
    if (types[lowerCaseEl] && buffer.indexOf(types[lowerCaseEl]) === -1) {
      buffer.push(types[lowerCaseEl])
    }
  });
  return buffer[0];
}

import React from 'react';

import {
  Box, Button, Grid, Link, Typography
} from '@material-ui/core';
import history from '@app/history';
import Header from './components/header';
import CheckAnimation from '@components/misc/check-animation';
import LabelsForRender, { useStyles as labelsStyles } from '@components/layout/labels-for-render';
import RiskRatingIcon from '@components/flexible-icons/risk-rating-icon';
import prepareTimestamp from '@utils/date';
import getRiskRatingLabel from '@utils/riskRatingLabel';

export default function RiskRatingFinish(props) {

  const { baseUri } = props

  const clientData = props.data

  const labelsClasses = labelsStyles()

  const onClose = () => {
    history.push(baseUri)
  }

  const getLabels = () => {
    return [
      {
        field: 'Risk Rating',
        notText: true,
        value: <React.Fragment>
          <RiskRatingIcon
            risk={clientData?.risk_score}
            withTooltip={true}
            placement={'top'}
            iconProps={{style:{marginTop: '2px', marginLeft: '2px'}}}
          />
                            &nbsp;
          <Typography classes={{root: labelsClasses.labelValue}}>
            {getRiskRatingLabel(clientData?.risk_score, 'Not Set')}
          </Typography>
        </React.Fragment>
      },
      {
        field: 'Next Review Date',
        value: clientData?.next_review_at? prepareTimestamp(clientData.next_review_at, 'MM/DD/YYYY', false): 'N/A'
      }
    ]
  }

  return (
    <React.Fragment>
      <Grid container justify={'center'}>
        <Grid item xs={6}>
          <Header clientData={clientData} baseUri={baseUri} hasBack={false}/>

          <Box display={'flex'} flexGrow={1} flexDirection={'column'} alignItems={'center'} mb={3}>
            <Box display={'flex'}>
              <CheckAnimation/>
            </Box>
            <Box display={'flex'} mt={2}>
              <Link variant={'h3'} underline={'none'}>
                                Risk Assessment Completed!
              </Link>
            </Box>
            <Box display={'flex'} flexDirection={'column'} style={{ width: '300px' }}>
              <Box display={'flex'} my={5} flexDirection={'column'}>
                <LabelsForRender
                  labelProps={{style: {width: '150px', maxWidth: 'none'}}}
                  labels={getLabels()} boxProps={{ mb: 1 }}
                />
              </Box>
              <Box display={'flex'}>
                <Button
                  style={{ width: '100%' }}
                  variant={'contained'}
                  onClick={onClose}
                >
                                    OK
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
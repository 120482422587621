import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput
} from '@material-ui/core';
import InnerHeader from '@components/layout/inner-header';
import ButtonBack from '@components/buttons/button-back';
import history from '@app/history';
import { riskRatingTemplate } from '@app/api/client';
import LinkButton from '@components/buttons/link-button';
import RiskRatingSettingsForm from './form';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import parseYupErrors from '@app/yup';
import CustomTooltip from '@components/controls/tooltip';
import _ from 'lodash';
import debounce from 'awesome-debounce-promise';

const schema = yup.object().shape({
  templateName: yup.string().trim().required()
});

export default function RiskRatingSettingsSaveAs(props) {

  const { templateId } = useParams()

  const [questions, setQuestions] = useState([])

  const [templateName, setTemplateName] = useState('')

  const [errors, setErrors] = useState({})

  const [templateNameDirty, setTemplateNameDirty] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    setErrors({});
    if (templateNameDirty) {
      schema
        .validate({ templateName }, { abortEarly: false })
        .catch(err => {
          setErrors(
            parseYupErrors(err)
          )
        })
    }
  }, [templateName, templateNameDirty])

  const onSave = () => {
    riskRatingTemplate.store({
      templateName: _.trim(templateName),
      questions: questions.filter(q => !q.excluded)
    }).then(({ data }) => {
      enqueueSnackbar(`Risk rating template was successfully saved as "${templateName}"!`, { variant: 'success' })
      goToView(data.id)
    })
  }

  const onBack = () => {
    goToDashboard()
  }

  const goToDashboard = () => {
    history.push('/settings/risk-rating')
  }

  const goToView = (specificTemplateId) => {
    history.push(`/settings/risk-rating/${specificTemplateId || templateId}/view`)
  }

  const onCancel = () => {
    goToView()
  }

  useEffect(() => {
    fetchQuestions()
  }, []) // eslint-disable-line

  const fetchQuestions = () => {
    return riskRatingTemplate.show(templateId)
      .then(({ data }) => {
        setQuestions(
          data.jsonData.questions
        )
      })
  }

  const isSaveDisabled = useMemo(() => !templateName.trim() || !questions.filter(q => !q.excluded).length,
    [templateName, questions]
  )

  const onChangeTemplateValue = value => {
    setTemplateName(value)
    setTemplateNameDirty(true)
  }

  const debouncedOnChangeTemplateValue = debounce(onChangeTemplateValue, 500);

  return (
    <Box display={'flex'} flexDirection={'column'} flexGrow={1} mt={5}>
      <Box display={'flex'}>
        <ButtonBack onClick={onBack} />
      </Box>
      <Box display={'flex'}>
        <InnerHeader
          ind={0}
          tabs={[
            { id: 'title', label: 'Template Details' }
          ]}
        />
      </Box>
      <Box
        display={'flex'}
        px={2}
        flexDirection={'column'}
        style={{
          overflowY: 'scroll'
        }}
        maxHeight="calc(100vh - 280px)"
      >
        <Box
          display={'flex'}
          mt={3}
        >
          <Box display={'flex'} flexGrow={1}>
            <Grid container alignItems={'center'} spacing={3}>
              <Grid item xs={10} md={6}>
                <FormControl fullWidth variant="outlined" error={!!errors?.templateName}>
                  <InputLabel htmlFor="email">Template Name</InputLabel>
                  <OutlinedInput
                    defaultValue={templateName}
                    onChange={event => {
                      debouncedOnChangeTemplateValue(event.target.value)
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box display={'flex'}>
          <Box display={'flex'} flexGrow={1} mt={5}>
            <RiskRatingSettingsForm
              sourceQuestions={null}
              questions={questions}
              setQuestions={setQuestions}
            />
          </Box>
        </Box>
        <Box display={'flex'} flexDirection={'row-reverse'} my={3}>
          <Box display={'flex'}>
            <CustomTooltip
              title={isSaveDisabled && 'Template name and at least one question should be included in the template'}
              placement="top">
              <Box>
                <Button
                  style={{ width: '200px' }}
                  variant={'contained'}
                  onClick={onSave}
                  disabled={isSaveDisabled}
                >
                  SAVE
                </Button>
              </Box>
            </CustomTooltip>
          </Box>
          <Box display={'flex'} mx={3}>
            <LinkButton onClick={onCancel}>
              CANCEL
            </LinkButton>
          </Box>
        </Box>
      </Box>

    </Box>
  )
}
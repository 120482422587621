import React, {useMemo} from 'react';
import {Box} from '@material-ui/core';
import EntityCard from './card';
import { countriesISO } from '@services/country-service';
import ReactCountryFlag from 'react-country-flag'

export default function EntryPointByJurisdiction({searchTerm}) {

  const filteredCountries = useMemo(() => {
    return countriesISO.filter(country => country.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)
  }, [searchTerm])

  return <Box display={'flex'} flexWrap={'wrap'} style={{overflowY: 'auto', maxHeight: 'calc(100vh - 200px)'}}>
    { filteredCountries.map(country => (
      <Box display={'flex'} m={1} key={country.code}>
        <EntityCard
          icon={
            <ReactCountryFlag
              countryCode={country.code}
              style={{
                fontSize: '3.5em',
                lineHeight: '1.5em',
              }}
            />
          }
          label={country.name}
          value={country.code}
          mode={'jurisdiction'}
        />
      </Box>
    )) }
  </Box>
}
import { useDispatch } from 'react-redux';
import generalReducer from '@app/store/actions/general'

// Suppress snackbar interceptor
// Currently used to suppress axios from showing error message
export const useSuppressSnackbar = () => {
  const dispatch = useDispatch();

  const setSuppressSnackbar = (suppress) => {
    dispatch(generalReducer.setSuppressSnackbar(suppress))
  }

  return setSuppressSnackbar;
}
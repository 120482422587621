import React from 'react';
import { Box } from '@material-ui/core';

import BaseTableCell from './base-table-cell';

export default function CustomTableCell(props) {
  const { col, item, preventClick, onSelect } = props;

  return (
    <BaseTableCell col={col} item={item} onClick={col.triggerSelect? onSelect : col.preventClick ? preventClick : undefined}>
      <Box display="inline-block">
        <Box display="flex" alignItems="center" flexWrap="wrap">
          {col.custom(item)}
        </Box>
      </Box>
    </BaseTableCell>
  )
}
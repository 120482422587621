import React from 'react';
import {Button, Container, Grid, Link, Typography, FormControlLabel, RadioGroup, Radio} from '@material-ui/core';
import ButtonWithIcon from '../components/buttons/button-with-icon';
import * as Icons from '@app/icons';
import ViewToggle from '@components/controls/view-toggle';
import CustomTooltip from '@components/controls/tooltip';

export default function ComponentsList(props) {
  // View toggle
  const [view, setView] = React.useState('list');
  // Radio button
  const [selectedRadioValue, setSelectedRadioValue] = React.useState('a');
  const handleRadioChange = (event) => {
    setSelectedRadioValue(event.target.value);
  };

  return (
    <React.Fragment>
      <Container>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Typography variant={'h2'}>
                            Buttons
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <Button variant="contained" size="large">Large</Button>
                        &emsp;
            <Button variant="contained" size="large" disabled>Large</Button>
          </Grid>
          <Grid item sm={12}>
            <Button variant="contained" size="medium">Medium</Button>
                        &emsp;
            <Button variant="contained" size="medium" disabled>Medium</Button>
          </Grid>
          <Grid item sm={12}>
            <Button variant="outlined" size="large">Large</Button>
                        &emsp;
            <Button variant="outlined" size="large" disabled>Large</Button>
          </Grid>
          <Grid item sm={12}>
            <Button variant="outlined" size="medium">Medium</Button>
                        &emsp;
            <Button variant="outlined" size="medium" disabled>Medium</Button>
          </Grid>
          <Grid item sm={12}>
            <Button variant="outlined" size="small">Small</Button>
                        &emsp;
            <Button variant="outlined" size="small" disabled>Small</Button>
          </Grid>
          {/* Button with icon */}
          <Grid item sm={12}>
            <Typography variant={'h2'}>
                            Button with icon
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <ButtonWithIcon startIcon={<Icons.NewPersonIcon />}>
                            New Folder
            </ButtonWithIcon>
          </Grid>
          {/* Links */}
          <Grid item sm={12}>
            <Typography variant={'h2'}>
                            Links
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <Link variant={'subtitle1'}>subtitle1</Link>
                        &emsp;
            <Link variant={'subtitle2'}>subtitle2</Link>
                        &emsp;
            <Link variant={'body1'}>body1</Link>
                        &emsp;
            <Link variant={'body2'}>body2</Link>
          </Grid>
          {/* View toggle */}
          <Grid item sm={12}>
            <Typography variant={'h2'}>
                            View toggle
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <ViewToggle value={view} onChange={setView}/>
          </Grid>
          {/* Radio buttons */}
          <Grid item sm={12}>
            <Typography variant={'h2'}>
                            Radio buttons
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <Grid container>
              <Grid item sm={6}>
                <Radio
                  checked={selectedRadioValue === 'a'}
                  onChange={handleRadioChange}
                  value="a"
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': 'A' }}
                />
                <Radio
                  checked={selectedRadioValue === 'b'}
                  onChange={handleRadioChange}
                  value="b"
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': 'B' }}
                />
              </Grid>
              <Grid item sm={6}>
                <RadioGroup aria-label="gender" name="gender1" value={selectedRadioValue} onChange={handleRadioChange}>
                  <FormControlLabel value="a" control={<Radio />} label="Female" />
                  <FormControlLabel value="b" control={<Radio />} label="Male" />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ backgroundColor: '#EDEDED' }}>
            <Typography variant="h2">Icons</Typography>
            {Object.entries(Icons).map(([name, Icon]) =>
              <CustomTooltip title={name}>
                <Icon />
              </CustomTooltip>
            )}
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}
import React, { useCallback } from  'react';
import { InputLabel, FormControl, MenuItem, OutlinedInput } from '@material-ui/core';
import SelectClearable from '@components/inputs/select-clearable';

import { useSelector, useDispatch } from 'react-redux';
import { backgroundSearch } from '@app/store/actions';

import { countriesISO } from '@services/country-service';
import { PREMIUM } from '../../constants';
import { useStyles } from '../styles';

export default function CompanyInputs() {
  const classes = useStyles()

  const { searchModel } = useSelector(state => state.backgroundSearch);
  const dispatch = useDispatch();

  const handleChange = useCallback((event, key) => {
    const value = event?.target?.value || '';
    dispatch(backgroundSearch.setSearchModel({ [key]: value }));
  }, [dispatch])

  const countryValue = useCallback(() => {
    const item = countriesISO.find(el => el.code === searchModel.country);
    return item?.code || '';
  }, [searchModel])

  return (
    <React.Fragment>
      <FormControl className={classes.inputPersonWrapper} variant="outlined">
        {!countryValue() && (
          <InputLabel htmlFor="name" className={classes.inputLabel}>Country</InputLabel>
        )}
        <SelectClearable
          value={countryValue()}
          onChange={(event) => handleChange(event, 'country')}
          style={{ width: '100%' }}
          classes={{ select: classes.advancedSelect }}
        >
          {countriesISO.map((item, i) => (
            <MenuItem
              key={`${item}-${i}`}
              value={item.code}
            >
              {item.name}
            </MenuItem>
          ))}
        </SelectClearable>
      </FormControl>
      {searchModel.searchProvider !== PREMIUM && (
        <FormControl className={classes.inputPersonWrapper} variant="outlined">
          <OutlinedInput
            id="registrationNumber"
            placeholder="Registration #"
            value={searchModel.registrationNumber}
            onChange={(event) => handleChange(event, 'registrationNumber')}
            type="text"
            classes={{ input: classes.advancedInput }}
          />
        </FormControl>
      )}
    </React.Fragment>
  )
}

import React, { useState, useCallback, useMemo } from  'react';
import { Typography, Link } from '@material-ui/core';
import CustomStepper from '@components/layout/custom-stepper';

import history from '@app/history';
import { prepareProfilesFetchData } from '@utils/prepare-data/prepareProfilesData';

import { backgroundCheck } from '@store/actions';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { clientApi } from '@app/api';

import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';

import FlowBG from './flowBG';
import FlowSH from './flowSH';

const useStyles = makeStyles((theme) => ({
  stepFooter: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    marginTop: '39px',
  },
  stepLink: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.text.LB3,
    marginLeft: '3px',
    '&:hover': {
      cursor: 'pointer',
    }
  },
}))

export default function StepperBlock({ handleExport, updateExisting, handleChangeRisk }) {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const searchId = search.get('searchId');
  const profileId = search.get('profileId');

  const { currentSearch, selectedProfile, sources, profile } = useSelector(store => store.backgroundCheck);
  const { profilesVersionId } = useSelector(store => store.searchHistory);
  const dispatch = useDispatch();

  const [disableExportButton, setDisableExportButton] = useState(false);
  const [activeStep, setActiveStep] = useState(1);

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const riskPriority = useMemo(() => (
    (profile?.bgCheckRiskRating || selectedProfile?.bgCheckRiskRating) || 'Not Set'
  ), [profile, selectedProfile])

  const disabledStepper = !location.pathname.includes('/background-check') && (!!profilesVersionId || (!!selectedProfile.acknowledged && activeStep !== 2) || (!!selectedProfile.acknowledged && activeStep === 2));

  const handleNotNow = useCallback(() => {
    if (activeStep === 1) {
      setActiveStep(2);
    } else {
      history.push('/search-history');
    }
  }, [activeStep, setActiveStep])

  const handleExportInStepper = useCallback(async () => {
    setDisableExportButton(true);
    await handleExport();

    if (!disabledStepper) {
      setActiveStep(2);
    }
  }, [disabledStepper, setDisableExportButton, setActiveStep, handleExport])

  const handleVersionState = useCallback(() => {
    const changedSources = sources.map(el => el.versionState !== 'DELETED' ? ({ ...el, versionState: 'UNCHANGED'  }) : el);
    dispatch(backgroundCheck.setSources(changedSources));
  }, [sources, dispatch])

  const handleAsknowledge = useCallback(async () => {
    const res = await clientApi.profile.acknowledgeProfile(selectedProfile.profileId);

    if (res.status === 200) {
      const data = prepareProfilesFetchData([res.data], profilesVersionId);
      dispatch(backgroundCheck.setProfile(data[0]));
      handleVersionState();
      enqueueSnackbar('The changes were successfully acknowledged!', { variant: 'success' });
      setActiveStep(2);
    }
    setActiveStep(2);
  }, [selectedProfile, profilesVersionId, handleVersionState, setActiveStep, enqueueSnackbar, dispatch])

  const acknowledgeAction = useCallback(() => {
    handleAsknowledge();
    setActiveStep(2);
  }, [handleAsknowledge, setActiveStep])

  const profileAction = useCallback(() => {
    selectedProfile.clientId
      ? history.push(`/portfolio/view/${selectedProfile.clientId}`)
      : history.push(`/portfolio/create-${currentSearch.type.toLowerCase()}/${searchId}/${profileId}`)
  }, [selectedProfile, currentSearch, searchId, profileId])
  
  const exportAction = useCallback(() => {
    handleExportInStepper();
    setDisableExportButton(false);
  }, [handleExportInStepper, setDisableExportButton])

  const isViewAsknowledgeButton = !selectedProfile.acknowledged && !profilesVersionId && !location.pathname.includes('/background-check');

  const stepperButtonBlock = (
    location.pathname.includes('/background-check') ? (
      <FlowBG
        activeStep={activeStep}
        exportAction={exportAction}
        riskPriority={riskPriority}
        disableExportButton={disableExportButton}
        profileAction={profileAction}
        updateExisting={updateExisting}
        selectedProfile={selectedProfile}
        handleNotNow={handleNotNow}
      />
    ) : (
      <FlowSH
        activeStep={activeStep}
        exportAction={exportAction}
        riskPriority={riskPriority}
        disableExportButton={disableExportButton}
        profilesVersionId={profilesVersionId}
        selectedProfile={selectedProfile}
        handleNotNow={handleNotNow}
        acknowledgeAction={acknowledgeAction}
        isViewAsknowledgeButton={isViewAsknowledgeButton}
      />
    )
  )

  return (
    <CustomStepper
      disabled={!!profilesVersionId}
      riskPriority={riskPriority}
      activeStep={activeStep}
      disabledStepper={disabledStepper}
      handleChange={(value) => {
        if(!value) return;
        handleChangeRisk(value);
      }}
      firstStepText={isViewAsknowledgeButton &&
        <span>
          Please confirm that you've acknowledged all changes to the sources for <span style={{ color: '#232323' }}>{selectedProfile.name}</span>.
          All changes that you choose to keep will be saved in search history and within Entity profile.
        </span>
      }
      buttonsBlock={stepperButtonBlock}
      footer={!!location.pathname.includes('/background-check') && (
        <Typography className={classes.stepFooter}>
          This search has been automatically saved in
          <Link
            className={classes.stepLink}
            onClick={() => history.push('/search-history')}
          >
            Search History
          </Link>. 
        </Typography>
      )}
    />
  )
};

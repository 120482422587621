const unsortedCountries = [
  {
    code: 'AC', code3: 'ASC', name: 'Ascension Island', number: '-001',
    aliases: []
  },
  {
    code: 'AD', code3: 'AND', name: 'Andorra', number: '016',
    aliases: [
      'Principality of Andorra',
      'Principality of the Valleys of Andorra'
    ]
  },
  {
    code: 'AE', code3: 'ARE', name: 'United Arab Emirates', number: '784',
    aliases: [
      'United Arab Emirates',
      'The United Arab Emirates'
    ]
  },
  {
    code: 'AF', code3: 'AFG', name: 'Afghanistan', number: '004',
    aliases: [
      'Islamic Republic of Aghanistan'
    ]
  },
  {
    code: 'AG', code3: 'ATG', name: 'Antigua and Barbuda', number: '028',
    aliases: [
    ]
  },
  {
    code: 'AI', code3: 'AIA', name: 'Anguilla', number: '660',
    aliases: []
  },
  {
    code: 'AL', code3: 'ALB', name: 'Albania', number: '008',
    aliases: [
      'Republic of Albania'
    ]
  },
  {
    code: 'AM', code3: 'ARM', name: 'Armenia', number: '051',
    aliases: [
      'Republic of Armenia'
    ]
  },
  {
    code: 'AN', code3: 'ANT', name: 'Netherlands Antilles', number: '530',
    aliases: [
    ]
  },
  {
    code: 'AO', code3: 'AGO', name: 'Angola', number: '024',
    aliases: [
      'Republic of Angola'
    ]
  },
  {
    code: 'AQ', code3: 'ATA', name: 'Antarctica', number: '010',
    aliases: []
  },
  {
    code: 'AR', code3: 'ARG', name: 'Argentina', number: '032',
    aliases: [
      'Argentine Republic'
    ]
  },
  {
    code: 'AS', code3: 'ASM', name: 'American Samoa', number: '016',
    aliases: []
  },
  {
    code: 'AT', code3: 'AUT', name: 'Austria', number: '040',
    aliases: [
      'Republic of Austria'
    ]
  },
  {
    code: 'AU', code3: 'AUS', name: 'Australia', number: '036',
    aliases: [
      'Commonwealth of Australia'
    ]
  },
  {
    code: 'AW', code3: 'ABW', name: 'Aruba', number: '533',
    aliases: []
  },
  {
    code: 'AX', code3: 'ALA', name: 'Aland Islands', number: '248',
    aliases: [
      'Åland Islands',
      'Aland',
      'Åland'
    ]
  },
  {
    code: 'AZ', code3: 'AZE', name: 'Azerbaijan', number: '031',
    aliases: [
      'Republic of Azerbaijan'
    ]
  },
  {
    code: 'BA', code3: 'BIH', name: 'Bosnia and Herzegovina', number: '070',
    aliases: []
  },
  {
    code: 'BB', code3: 'BRB', name: 'Barbados', number: '052',
    aliases: []
  },
  {
    code: 'BD', code3: 'BGD', name: 'Bangladesh', number: '050',
    aliases: [
      'People\'s Republic of Bangladesh'
    ]
  },
  {
    code: 'BE', code3: 'BEL', name: 'Belgium', number: '056',
    aliases: [
      'Kingdom of Belgium'
    ]
  },
  {
    code: 'BF', code3: 'BFA', name: 'Burkina Faso', number: '854',
    aliases: []
  },
  {
    code: 'BG', code3: 'BGR', name: 'Bulgaria', number: '100',
    aliases: [
      'Republic of Bulgaria'
    ]
  },
  {
    code: 'BH', code3: 'BHR', name: 'Bahrain', number: '048',
    aliases: [
      'Kingdom of Bahrain'
    ]
  },
  {
    code: 'BI', code3: 'BDI', name: 'Burundi', number: '108',
    aliases: [
      'Republic of Burundi'
    ]
  },
  {
    code: 'BJ', code3: 'BEN', name: 'Benin', number: '204',
    aliases: [
      'Republic of Benin'
    ]
  },
  {
    code: 'BL', code3: 'BLM', name: 'Saint Barthélemy', number: '652',
    aliases: [
      'Saint Barthelemy'
    ]
  },
  {
    code: 'BM', code3: 'BMU', name: 'Bermuda', number: '060',
    aliases: []
  },
  {
    code: 'BN', code3: 'BRN', name: 'Brunei Darussalam', number: '096',
    aliases: [
      'Nation of Brunei, the Abode of Peace',
      'Brunei'
    ]
  },
  {
    code: 'BO', code3: 'BOL', name: 'Bolivia, Plurinational State of', number: '068',
    aliases: [
      'Plurinational State of Bolivia',
      'Bolivia'
    ]
  },
  {
    code: 'BQ', code3: 'BES', name: 'Bonaire, Sint Eustatius and Saba', number: '535',
    aliases: []
  },
  {
    code: 'BR', code3: 'BRA', name: 'Brazil', number: '076',
    aliases: [
      'Federative Republic of Brazil'
    ]
  },
  {
    code: 'BS', code3: 'BHS', name: 'Bahamas', number: '044',
    aliases: [
      'Commonwealth of The Bahamas',
      'The Bahamas',
      'Bahamas'
    ]
  },
  {
    code: 'BT', code3: 'BTN', name: 'Bhutan', number: '064',
    aliases: [
      'Kingdom of Bhutan'
    ]
  },
  {
    code: 'BU', code3: 'BUMM', name: 'Burma', number: '104',
    aliases: []
  },
  {
    code: 'BV', code3: 'BVT', name: 'Bouvet Island', number: '074',
    aliases: []
  },
  {
    code: 'BW', code3: 'BWA', name: 'Botswana', number: '072',
    aliases: [
      'Republic of Botswana'
    ]
  },
  {
    code: 'BY', code3: 'BLR', name: 'Belarus', number: '112',
    aliases: [
      'Republic of Belarus'
    ]
  },
  {
    code: 'BZ', code3: 'BLZ', name: 'Belize', number: '084',
    aliases: []
  },
  {
    code: 'CA', code3: 'CAN', name: 'Canada', number: '124',
    aliases: []
  },
  {
    code: 'CC', code3: 'CCK', name: 'Cocos (Keeling) Islands', number: '166',
    aliases: [
      'Cocos Keeling Islands',
      'The Cocos Keeling Islands',
      'Cocos Islands',
      'Cocos (Keeling) Islands'
    ]
  },
  {
    code: 'CD', code3: 'COD', name: 'Congo, the Democratic Republic of the', number: '180',
    aliases: [
      'Republic of the Congo',
      'the Democratic Republic of the Congo'
    ]
  },
  {
    code: 'CF', code3: 'CAF', name: 'Central African Republic', number: '140',
    aliases: [
      'Central African Republic',
      'The Central African Republic'
    ]
  },
  {
    code: 'CG', code3: 'COG', name: 'Congo', number: '178',
    aliases: [
      'Congo',
      'The Congo',
      'Republic of the Congo'
    ]
  },
  {
    code: 'CH', code3: 'CHE', name: 'Switzerland', number: '756',
    aliases: [
      'Swiss Confederation'
    ]
  },
  {
    code: 'CI', code3: 'CIV', name: 'Côte d\'Ivoire', number: '384',
    aliases: [
      'Republic of Côte d\'Ivoire',
      'Cote d\'ivoire',
      'cote divoire'
    ]
  },
  {
    code: 'CK', code3: 'COK', name: 'Cook Islands', number: '184',
    aliases: [
      'Cook Islands',
      'The Cook Islands'
    ]
  },
  {
    code: 'CL', code3: 'CHL', name: 'Chile', number: '152',
    aliases: [
      'Republic of Chile'
    ]
  },
  {
    code: 'CM', code3: 'CMR', name: 'Cameroon', number: '120',
    aliases: [
      'Republic of Cameroon'
    ]
  },
  {
    code: 'CN', code3: 'CHN', name: 'China', number: '156',
    aliases: [
      'People\'s Republic of China'
    ]
  },
  {
    code: 'CO', code3: 'COL', name: 'Colombia', number: '170',
    aliases: [
      'Republic of Colombia'
    ]
  },
  {
    code: 'CP', code3: 'CPT', name: 'Clipperton Island', number: '-1',
    aliases: []
  },
  {
    code: 'CR', code3: 'CRI', name: 'Costa Rica', number: '188',
    aliases: [
      'Republic of Costa Rica'
    ]
  },
  {
    code: 'CS', code3: 'SCG', name: 'Serbia and Montenegro', number: '891',
    aliases: []
  },
  {
    code: 'CU', code3: 'CUB', name: 'Cuba', number: '192',
    aliases: [
      'Republic of Cuba'
    ]
  },
  {
    code: 'CV', code3: 'CPV', name: 'Cabo Verde', number: '132',
    aliases: [
      'Republic of Cabo Verde',
      'Cape Verde'
    ]
  },
  {
    code: 'CW', code3: 'CUW', name: 'Curaçao', number: '531',
    aliases: []
  },
  {
    code: 'CX', code3: 'CXR', name: 'Christmas Island', number: '162',
    aliases: []
  },
  {
    code: 'CY', code3: 'CYP', name: 'Cyprus', number: '196',
    aliases: [
      'Republic of Cyprus'
    ]
  },
  {
    code: 'CZ', code3: 'CZE', name: 'Czech Republic', number: '203',
    aliases: [
      'Czech',
      'Czechia'
    ]
  },
  {
    code: 'DE', code3: 'DEU', name: 'Germany', number: '276',
    aliases: [
      'Federal Republic of Germany'
    ]
  },
  {
    code: 'DG', code3: 'DGA', name: 'Diego Garcia', number: '-1',
    aliases: []
  },
  {
    code: 'DJ', code3: 'DJI', name: 'Djibouti', number: '262',
    aliases: [
      'Republic of Djibouti'
    ]
  },
  {
    code: 'DK', code3: 'DNK', name: 'Denmark', number: '208',
    aliases: [
      'Kingdom of Denmark'
    ]
  },
  {
    code: 'DM', code3: 'DMA', name: 'Dominica', number: '212',
    aliases: [
      'Commonwealth of Dominica'
    ]
  },
  {
    code: 'DO', code3: 'DOM', name: 'Dominican Republic', number: '214',
    aliases: [
      'Dominican Republic',
      'The Dominican Republic'
    ]
  },
  {
    code: 'DZ', code3: 'DZA', name: 'Algeria', number: '012',
    aliases: [
      'People\'s Democratic Republic of Algeria'
    ]
  },
  {
    code: 'EA', code3: 'null', name: 'Ceuta, Melilla', number: '-1',
    aliases: []
  },
  {
    code: 'EC', code3: 'ECU', name: 'Ecuador', number: '218',
    aliases: [
      'Republic of Ecuador'
    ]
  },
  {
    code: 'EE', code3: 'EST', name: 'Estonia', number: '233',
    aliases: [
      'Republic of Estonia'
    ]
  },
  {
    code: 'EG', code3: 'EGY', name: 'Egypt', number: '818',
    aliases: [
      'Arab Republic of Egypt'
    ]
  },
  {
    code: 'EH', code3: 'ESH', name: 'Western Sahara', number: '732',
    aliases: []
  },
  {
    code: 'ER', code3: 'ERI', name: 'Eritrea', number: '232',
    aliases: [
      'State of Eritrea'
    ]
  },
  {
    code: 'ES', code3: 'ESP', name: 'Spain', number: '724',
    aliases: [
      'Kingdom of Spain'
    ]
  },
  {
    code: 'ET', code3: 'ETH', name: 'Ethiopia', number: '231',
    aliases: [
      'Federal Democratic Republic of Ethiopia'
    ]
  },
  {
    code: 'EU', code3: 'null', name: 'European Union', number: '-1',
    aliases: [
    ]
  },
  {
    code: 'EZ', code3: 'null', name: 'Eurozone', number: '-1',
    aliases: [
    ]
  },
  {
    code: 'FJ', code3: 'FJI', name: 'Fiji', number: '242',
    aliases: [
      'Republic of Fiji'
    ]
  },
  {
    code: 'FK', code3: 'FLK', name: 'Falkland Islands (Malvinas)', number: '238',
    aliases: [
      'Malvinas',
      'Falkland Islands',
      'Falkland Islands Malvinas',
      'Falkland Islands (Malvinas)',
      'The Falkland Islands',
      'The Falkland Islands Malvinas'
    ]
  },
  {
    code: 'FM', code3: 'FSM', name: 'Micronesia, Federated States of', number: '583',
    aliases: [
      'Federated States of Micronesia',
      'Micronesia'
    ]
  },
  {
    code: 'FO', code3: 'FRO', name: 'Faroe Islands', number: '234',
    aliases: [
      'Faroe Islands',
      'The Faroe Islands'
    ]
  },
  {
    code: 'FR', code3: 'FRA', name: 'France', number: '250',
    aliases: [
      'French Republic'
    ]
  },
  {
    code: 'FX', code3: 'FXX', name: 'France, Metropolitan', number: '249',
    aliases: [
    ]
  },
  {
    code: 'GA', code3: 'GAB', name: 'Gabon', number: '266',
    aliases: [
      'Gabonese Republic'
    ]
  },
  {
    code: 'GB', code3: 'GBR', name: 'United Kingdom', number: '826',
    aliases: [
      'United Kingdom of Great Britain and Northern Ireland',
      'Britain',
      'Great Britain',
      'UK',
    ]
  },
  {
    code: 'GD', code3: 'GRD', name: 'Grenada', number: '308',
    aliases: []
  },
  {
    code: 'GE', code3: 'GEO', name: 'Georgia', number: '268',
    aliases: []
  },
  {
    code: 'GF', code3: 'GUF', name: 'French Guiana', number: '254',
    aliases: []
  },
  {
    code: 'GG', code3: 'GGY', name: 'Guernsey', number: '831',
    aliases: []
  },
  {
    code: 'GH', code3: 'GHA', name: 'Ghana', number: '288',
    aliases: [
      'Republic of Ghana'
    ]
  },
  {
    code: 'GI', code3: 'GIB', name: 'Gibraltar', number: '292',
    aliases: []
  },
  {
    code: 'GL', code3: 'GRL', name: 'Greenland', number: '304',
    aliases: []
  },
  {
    code: 'GM', code3: 'GMB', name: 'Gambia', number: '270',
    aliases: [
      'Republic of The Gambia',
      'The Gambia'
    ]
  },
  {
    code: 'GN', code3: 'GIN', name: 'Guinea', number: '324',
    aliases: [
      'Republic of Guinea'
    ]
  },
  {
    code: 'GP', code3: 'GLP', name: 'Guadeloupe', number: '312',
    aliases: []
  },
  {
    code: 'GQ', code3: 'GNQ', name: 'Equatorial Guinea', number: '226',
    aliases: [
      'Republic of Equatorial Guinea'
    ]
  },
  {
    code: 'GR', code3: 'GRC', name: 'Greece', number: '300',
    aliases: [
      'Hellenic Republic',
      'Hellas'
    ]
  },
  {
    code: 'GS', code3: 'SGS', name: 'South Georgia and the South Sandwich Islands', number: '239',
    aliases: []
  },
  {
    code: 'GT', code3: 'GTM', name: 'Guatemala', number: '320',
    aliases: [
      'Republic of Guatemala'
    ]
  },
  {
    code: 'GU', code3: 'GUM', name: 'Guam', number: '316',
    aliases: []
  },
  {
    code: 'GW', code3: 'GNB', name: 'Guinea-Bissau', number: '624',
    aliases: [
      'Republic of Guinea-Bissau',
      'Guiena Bissau',
      'Republic of Guinea Bissau'
    ]
  },
  {
    code: 'GY', code3: 'GUY', name: 'Guyana', number: '328',
    aliases: [
      'Co‑operative Republic of Guyana'
    ]
  },
  {
    code: 'HK', code3: 'HKG', name: 'Hong Kong', number: '344',
    aliases: []
  },
  {
    code: 'HM', code3: 'HMD', name: 'Heard Island and McDonald Islands', number: '334',
    aliases: []
  },
  {
    code: 'HN', code3: 'HND', name: 'Honduras', number: '340',
    aliases: [
      'Republic of Honduras'
    ]
  },
  {
    code: 'HR', code3: 'HRV', name: 'Croatia', number: '191',
    aliases: [
      'Republic of Croatia'
    ]
  },
  {
    code: 'HT', code3: 'HTI', name: 'Haiti', number: '332',
    aliases: [
      'Republic of Haiti'
    ]
  },
  {
    code: 'HU', code3: 'HUN', name: 'Hungary', number: '348',
    aliases: []
  },
  {
    code: 'IC', code3: 'null', name: 'Canary Islands', number: '-1',
    aliases: [
    ]
  },
  {
    code: 'ID', code3: 'IDN', name: 'Indonesia', number: '360',
    aliases: [
      'Republic of Indonesia'
    ]
  },
  {
    code: 'IE', code3: 'IRL', name: 'Ireland', number: '372',
    aliases: []
  },
  {
    code: 'IL', code3: 'ISR', name: 'Israel', number: '376',
    aliases: [
      'State of Israel'
    ]
  },
  {
    code: 'IM', code3: 'IMN', name: 'Isle of Man', number: '833',
    aliases: []
  },
  {
    code: 'IN', code3: 'IND', name: 'India', number: '356',
    aliases: [
      'Republic of India'
    ]
  },
  {
    code: 'IO', code3: 'IOT', name: 'British Indian Ocean Territory', number: '086',
    aliases: [
      'British Indian Ocean Territory',
      'The British Indian Ocean Territory'
    ]
  },
  {
    code: 'IQ', code3: 'IRQ', name: 'Iraq', number: '368',
    aliases: [
      'Republic of Iraq'
    ]
  },
  {
    code: 'IR', code3: 'IRN', name: 'Iran, Islamic Republic of', number: '364',
    aliases: [
      'Islamic Republic of Iran',
      'Iran'
    ]
  },
  {
    code: 'IS', code3: 'ISL', name: 'Iceland', number: '352',
    aliases: [
      'Republic of Iceland'
    ]
  },
  {
    code: 'IT', code3: 'ITA', name: 'Italy', number: '380',
    aliases: [
      'Italian Republic'
    ]
  },
  {
    code: 'JE', code3: 'JEY', name: 'Jersey', number: '832',
    aliases: []
  },
  {
    code: 'JM', code3: 'JAM', name: 'Jamaica', number: '388',
    aliases: []
  },
  {
    code: 'JO', code3: 'JOR', name: 'Jordan', number: '400',
    aliases: [
      'Hashemite Kingdom of Jordan'
    ]
  },
  {
    code: 'JP', code3: 'JPN', name: 'Japan', number: '392',
    aliases: [
      'Nippon'
    ]
  },
  {
    code: 'KE', code3: 'KEN', name: 'Kenya', number: '404',
    aliases: [
      'Republic of Kenya'
    ]
  },
  {
    code: 'KG', code3: 'KGZ', name: 'Kyrgyzstan', number: '417',
    aliases: [
      'Kyrgyz Republic'
    ]
  },
  {
    code: 'KH', code3: 'KHM', name: 'Cambodia', number: '116',
    aliases: [
      'Kingdom of Cambodia'
    ]
  },
  {
    code: 'KI', code3: 'KIR', name: 'Kiribati', number: '296',
    aliases: [
      'Republic of Kiribati'
    ]
  },
  {
    code: 'KM', code3: 'COM', name: 'Comoros', number: '174',
    aliases: [
      'Union of the Comoros',
      'The Comoros',
    ]
  },
  {
    code: 'KN', code3: 'KNA', name: 'Saint Kitts and Nevis', number: '659',
    aliases: [
      'Federation of Saint Christopher and Nevis'
    ]
  },
  {
    code: 'KP', code3: 'PRK', name: 'Korea, Democratic People\'s Republic of', number: '408',
    aliases: [
      'North Korea',
      'Democratic People\'s Republic of Korea'
    ]
  },
  {
    code: 'KR', code3: 'KOR', name: 'Korea, Republic of', number: '410',
    aliases: [
      'South Korea',
      'Republic of Korea'
    ]
  },
  {
    code: 'KW', code3: 'KWT', name: 'Kuwait', number: '414',
    aliases: [
      'State of Kuwait'
    ]
  },
  {
    code: 'KY', code3: 'CYM', name: 'Cayman Islands', number: '136',
    aliases: [
      'The Cayman Islands'
    ]
  },
  {
    code: 'KZ', code3: 'KAZ', name: 'Kazakhstan', number: '398',
    aliases: [
      'Republic of Kazakhstan'
    ]
  },
  {
    code: 'LA', code3: 'LAO', name: 'Lao People\'s Democratic Republic', number: '418',
    aliases: [
      'Lao People\'s Democratic Republic',
      'Laos',
      'The Lao People\'s Democratic Republic'
    ]
  },
  {
    code: 'LB', code3: 'LBN', name: 'Lebanon', number: '422',
    aliases: [
      'The Lebanese Republic'
    ]
  },
  {
    code: 'LC', code3: 'LCA', name: 'Saint Lucia', number: '662',
    aliases: []
  },
  {
    code: 'LI', code3: 'LIE', name: 'Liechtenstein', number: '438',
    aliases: [
      'Principality of Liechtenstein'
    ]
  },
  {
    code: 'LK', code3: 'LKA', name: 'Sri Lanka', number: '144',
    aliases: [
      'Democratic Socialist Republic of Sri Lanka'
    ]
  },
  {
    code: 'LR', code3: 'LBR', name: 'Liberia', number: '430',
    aliases: [
      'Republic of Liberia'
    ]
  },
  {
    code: 'LS', code3: 'LSO', name: 'Lesotho', number: '426',
    aliases: [
      'Kingdom of Lesotho'
    ]
  },
  {
    code: 'LT', code3: 'LTU', name: 'Lithuania', number: '440',
    aliases: [
      'Republic of Lithuania'
    ]
  },
  {
    code: 'LU', code3: 'LUX', name: 'Luxembourg', number: '442',
    aliases: [
      'Grand Duchy of Luxembourg'
    ]
  },
  {
    code: 'LV', code3: 'LVA', name: 'Latvia', number: '428',
    aliases: [
      'Republic of Latvia'
    ]
  },
  {
    code: 'LY', code3: 'LBY', name: 'Libya', number: '434',
    aliases: [
      'State of Libya'
    ]
  },
  {
    code: 'MA', code3: 'MAR', name: 'Morocco', number: '504',
    aliases: [
      'Kingdom of Morocco'
    ]
  },
  {
    code: 'MC', code3: 'MCO', name: 'Monaco', number: '492',
    aliases: [
      'Principality of Monaco'
    ]
  },
  {
    code: 'MD', code3: 'MDA', name: 'Republic of Moldova', number: '498',
    aliases: [
      'Republic of Moldova',
      'Moldova'
    ]
  },
  {
    code: 'ME', code3: 'MNE', name: 'Montenegro', number: '499',
    aliases: []
  },
  {
    code: 'MF', code3: 'MAF', name: 'Saint Martin (French part)', number: '663',
    aliases: []
  },
  {
    code: 'MG', code3: 'MDG', name: 'Madagascar', number: '450',
    aliases: [
      'Republic of Madagascar'
    ]
  },
  {
    code: 'MH', code3: 'MHL', name: 'Marshall Islands', number: '584',
    aliases: [
      'Republic of the Marshall Islands',
      'Marshall Islands',
      'The Marshall Islands'
    ]
  },
  {
    code: 'MK', code3: 'MKD', name: 'North Macedonia, Republic of', number: '807',
    aliases: [
      'North Macedonia',
      'Republic of North Macedonia'
    ]
  },
  {
    code: 'ML', code3: 'MLI', name: 'Mali', number: '466',
    aliases: [
      'Republic of Mali'
    ]
  },
  {
    code: 'MM', code3: 'MMR', name: 'Myanmar', number: '104',
    aliases: [
      'Republic of the Union of Myanmar'
    ]
  },
  {
    code: 'MN', code3: 'MNG', name: 'Mongolia', number: '496',
    aliases: []
  },
  {
    code: 'MO', code3: 'MAC', name: 'Macao', number: '446',
    aliases: []
  },
  {
    code: 'MP', code3: 'MNP', name: 'Northern Mariana Islands', number: '580',
    aliases: [
      'The Northern Mariana Islands'
    ]
  },
  {
    code: 'MQ', code3: 'MTQ', name: 'Martinique', number: '474',
    aliases: []
  },
  {
    code: 'MR', code3: 'MRT', name: 'Mauritania', number: '478',
    aliases: [
      'Islamic Republic of Mauritania'
    ]
  },
  {
    code: 'MS', code3: 'MSR', name: 'Montserrat', number: '500',
    aliases: []
  },
  {
    code: 'MT', code3: 'MLT', name: 'Malta', number: '470',
    aliases: [
      'Republic of Malta'
    ]
  },
  {
    code: 'MU', code3: 'MUS', name: 'Mauritius', number: '480',
    aliases: [
      'Republic of Mauritius'
    ]
  },
  {
    code: 'MV', code3: 'MDV', name: 'Maldives', number: '462',
    aliases: [
      'Republic of the Maldives'
    ]
  },
  {
    code: 'MW', code3: 'MWI', name: 'Malawi', number: '454',
    aliases: [
      'Republic of Malawi'
    ]
  },
  {
    code: 'MX', code3: 'MEX', name: 'Mexico', number: '484',
    aliases: [
      'United Mexican States'
    ]
  },
  {
    code: 'MY', code3: 'MYS', name: 'Malaysia', number: '458',
    aliases: []
  },
  {
    code: 'MZ', code3: 'MOZ', name: 'Mozambique', number: '508',
    aliases: [
      'Republic of Mozambique'
    ]
  },
  {
    code: 'NA', code3: 'NAM', name: 'Namibia', number: '516',
    aliases: [
      'Republic of Namibia'
    ]
  },
  {
    code: 'NC', code3: 'NCL', name: 'New Caledonia', number: '540',
    aliases: []
  },
  {
    code: 'NE', code3: 'NER', name: 'Niger', number: '562',
    aliases: [
      'Republic of the Niger',
      'The Niger'
    ]
  },
  {
    code: 'NF', code3: 'NFK', name: 'Norfolk Island', number: '574',
    aliases: []
  },
  {
    code: 'NG', code3: 'NGA', name: 'Nigeria', number: '566',
    aliases: [
      'Federal Republic of Nigeria'
    ]
  },
  {
    code: 'NI', code3: 'NIC', name: 'Nicaragua', number: '558',
    aliases: [
      'Republic of Nicaragua'
    ]
  },
  {
    code: 'NL', code3: 'NLD', name: 'Netherlands', number: '528',
    aliases: [
      'Kingdom of the Netherlands',
      'Netherlands',
      'The Netherlands'
    ]
  },
  {
    code: 'NO', code3: 'NOR', name: 'Norway', number: '578',
    aliases: [
      'Kingdom of Norway'
    ]
  },
  {
    code: 'NP', code3: 'NPL', name: 'Nepal', number: '524',
    aliases: [
      'Federal Democratic Republic Of Nepal'
    ]
  },
  {
    code: 'NR', code3: 'NRU', name: 'Nauru', number: '520',
    aliases: [
      'Republic of Nauru'
    ]
  },
  {
    code: 'NT', code3: 'NTZ', name: 'Neutral Zone', number: '536',
    aliases: []
  },
  {
    code: 'NU', code3: 'NIU', name: 'Niue', number: '570',
    aliases: []
  },
  {
    code: 'NZ', code3: 'NZL', name: 'New Zealand', number: '554',
    aliases: []
  },
  {
    code: 'OM', code3: 'OMN', name: 'Oman', number: '512',
    aliases: [
      'Sultanate of Oman'
    ]
  },
  {
    code: 'PA', code3: 'PAN', name: 'Panama', number: '591',
    aliases: [
      'Republic of Panama'
    ]
  },
  {
    code: 'PE', code3: 'PER', name: 'Peru', number: '604',
    aliases: [
      'Republic of Peru'
    ]
  },
  {
    code: 'PF', code3: 'PYF', name: 'French Polynesia', number: '258',
    aliases: []
  },
  {
    code: 'PG', code3: 'PNG', name: 'Papua New Guinea', number: '598',
    aliases: [
      'Independent State of Papua New Guinea'
    ]
  },
  {
    code: 'PH', code3: 'PHL', name: 'Philippines', number: '608',
    aliases: [
      'Republic of the Philippines',
      'The Philippines'
    ]
  },
  {
    code: 'PK', code3: 'PAK', name: 'Pakistan', number: '586',
    aliases: [
      'Islamic Republic of Pakistan',
      'Federation of Pakistan'
    ]
  },
  {
    code: 'PL', code3: 'POL', name: 'Poland', number: '616',
    aliases: [
      'Republic of Poland'
    ]
  },
  {
    code: 'PM', code3: 'SPM', name: 'Saint Pierre and Miquelon', number: '666',
    aliases: []
  },
  {
    code: 'PN', code3: 'PCN', name: 'Pitcairn', number: '612',
    aliases: []
  },
  {
    code: 'PR', code3: 'PRI', name: 'Puerto Rico', number: '630',
    aliases: [
      'Commonwealth of Puerto Rico'
    ]
  },
  {
    code: 'PS', code3: 'PSE', name: 'Palestine, State of', number: '275',
    aliases: [
      'State of Palestine',
      'Palestine'
    ]
  },
  {
    code: 'PT', code3: 'PRT', name: 'Portugal', number: '620',
    aliases: [
      'Portuguese Republic'
    ]
  },
  {
    code: 'PW', code3: 'PLW', name: 'Palau', number: '585',
    aliases: [
      'Republic of Palau'
    ]
  },
  {
    code: 'PY', code3: 'PRY', name: 'Paraguay', number: '600',
    aliases: [
      'Republic of Paraguay'
    ]
  },
  {
    code: 'QA', code3: 'QAT', name: 'Qatar', number: '634',
    aliases: [
      'State of Qatar'
    ]
  },
  {
    code: 'RE', code3: 'REU', name: 'Réunion', number: '638',
    aliases: [
      'Reunion'
    ]
  },
  {
    code: 'RO', code3: 'ROU', name: 'Romania', number: '642',
    aliases: []
  },
  {
    code: 'RS', code3: 'SRB', name: 'Serbia', number: '688',
    aliases: [
      'Republic of Serbia'
    ]
  },
  {
    code: 'RU', code3: 'RUS', name: 'Russian Federation', number: '643',
    aliases: [
      'Russia',
      'The Russian Federation'
    ]
  },
  {
    code: 'RW', code3: 'RWA', name: 'Rwanda', number: '646',
    aliases: [
      'Rwandese Republic'
    ]
  },
  {
    code: 'SA', code3: 'SAU', name: 'Saudi Arabia', number: '682',
    aliases: [
      'Kingdom of Saudi Arabia'
    ]
  },
  {
    code: 'SB', code3: 'SLB', name: 'Solomon Islands', number: '090',
    aliases: []
  },
  {
    code: 'SC', code3: 'SYC', name: 'Seychelles', number: '690',
    aliases: [
      'Republic of Seychelles'
    ]
  },
  {
    code: 'SD', code3: 'SDN', name: 'Sudan', number: '729',
    aliases: [
      'Republic of the Sudan',
      'The Sudan',
    ]
  },
  {
    code: 'SE', code3: 'SWE', name: 'Sweden', number: '752',
    aliases: [
      'Kingdom of Sweden'
    ]
  },
  {
    code: 'SF', code3: 'FIN', name: 'Finland', number: '246',
    aliases: []
  },
  {
    code: 'SG', code3: 'SGP', name: 'Singapore', number: '702',
    aliases: [
      'Republic of Singapore'
    ]
  },
  {
    code: 'SH', code3: 'SHN', name: 'Saint Helena, Ascension and Tristan da Cunha', number: '654',
    aliases: []
  },
  {
    code: 'SI', code3: 'SVN', name: 'Slovenia', number: '705',
    aliases: [
      'Republic of Slovenia'
    ]
  },
  {
    code: 'SJ', code3: 'SJM', name: 'Svalbard and Jan Mayen', number: '744',
    aliases: []
  },
  {
    code: 'SK', code3: 'SVK', name: 'Slovakia', number: '703',
    aliases: [
      'Slovak Republic'
    ]
  },
  {
    code: 'SL', code3: 'SLE', name: 'Sierra Leone', number: '694',
    aliases: [
      'Republic of Sierra Leone'
    ]
  },
  {
    code: 'SM', code3: 'SMR', name: 'San Marino', number: '674',
    aliases: [
      'Republic of San Marino'
    ]
  },
  {
    code: 'SN', code3: 'SEN', name: 'Senegal', number: '686',
    aliases: [
      'Republic of Senegal'
    ]
  },
  {
    code: 'SO', code3: 'SOM', name: 'Somalia', number: '706',
    aliases: [
      'Federal Republic of Somalia'
    ]
  },
  {
    code: 'SR', code3: 'SUR', name: 'Suriname', number: '740',
    aliases: [
      'Republic of Suriname'
    ]
  },
  {
    code: 'SS', code3: 'SSD', name: 'South Sudan', number: '728',
    aliases: [
      'Republic of South Sudan'
    ]
  },
  {
    code: 'ST', code3: 'STP', name: 'Sao Tome and Principe', number: '678',
    aliases: [
      'Democratic Republic of São Tomé and Príncipe',
      'São Tomé and Príncipe',
      'Democratic Republic of Sao Tome and Principe'
    ]
  },
  {
    code: 'SU', code3: 'SUN', name: 'USSR', number: '810',
    aliases: []
  },
  {
    code: 'SV', code3: 'SLV', name: 'El Salvador', number: '222',
    aliases: [
      'Republic of El Salvador'
    ]
  },
  {
    code: 'SX', code3: 'SXM', name: 'Sint Maarten (Dutch part)', number: '534',
    aliases: []
  },
  {
    code: 'SY', code3: 'SYR', name: 'Syrian Arab Republic', number: '760',
    aliases: [
      'Syria'
    ]
  },
  {
    code: 'SZ', code3: 'SWZ', name: 'Eswatini', number: '748',
    aliases: [
      'Kingdom of Eswatini'
    ]
  },
  {
    code: 'TA', code3: 'TAA', name: 'Tristan da Cunha', number: '-1',
    aliases: []
  },
  {
    code: 'TC', code3: 'TCA', name: 'Turks and Caicos Islands', number: '796',
    aliases: [
      'The Turks and Caicos Islands'
    ]
  },
  {
    code: 'TD', code3: 'TCD', name: 'Chad', number: '148',
    aliases: [
      'Republic of Chad'
    ]
  },
  {
    code: 'TF', code3: 'ATF', name: 'French Southern Territories', number: '260',
    aliases: [
      'The French Souther Territories'
    ]
  },
  {
    code: 'TG', code3: 'TGO', name: 'Togo', number: '768',
    aliases: [
      'Togolese Republic'
    ]
  },
  {
    code: 'TH', code3: 'THA', name: 'Thailand', number: '764',
    aliases: [
      'Kingdom of Thailand'
    ]
  },
  {
    code: 'TJ', code3: 'TJK', name: 'Tajikistan', number: '762',
    aliases: [
      'Republic of Tajikistan'
    ]
  },
  {
    code: 'TK', code3: 'TKL', name: 'Tokelau', number: '772',
    aliases: []
  },
  {
    code: 'TL', code3: 'TLS', name: 'Timor-Leste', number: '626',
    aliases: [
      'East Timor',
      'Democratic Republic of Timor-Leste',
      'Timor Leste',
      'Democratic Republic of Timor-Leste'
    ]
  },
  {
    code: 'TM', code3: 'TKM', name: 'Turkmenistan', number: '795',
    aliases: []
  },
  {
    code: 'TN', code3: 'TUN', name: 'Tunisia', number: '788',
    aliases: [
      'Republic of Tunisia'
    ]
  },
  {
    code: 'TO', code3: 'TON', name: 'Tonga', number: '776',
    aliases: [
      'Kingdom of Tonga'
    ]
  },
  {
    code: 'TR', code3: 'TUR', name: 'Turkey', number: '792',
    aliases: [
      'Republic of Turkey'
    ]
  },
  {
    code: 'TT', code3: 'TTO', name: 'Trinidad and Tobago', number: '780',
    aliases: [
      'Republic of Trinidad and Tobago'
    ]
  },
  {
    code: 'TV', code3: 'TUV', name: 'Tuvalu', number: '798',
    aliases: []
  },
  {
    code: 'TW', code3: 'TWN', name: 'Taiwan, Province of China', number: '158',
    aliases: [
      'Republic of China',
      'Taiwan',
    ]
  },
  {
    code: 'TZ', code3: 'TZA', name: 'Tanzania, United Republic of', number: '834',
    aliases: [
      'United Republic of Tanzania',
      'Tanzania'
    ]
  },
  {
    code: 'UA', code3: 'UKR', name: 'Ukraine', number: '804',
    aliases: []
  },
  {
    code: 'UG', code3: 'UGA', name: 'Uganda', number: '800',
    aliases: [
      'Republic of Uganda'
    ]
  },
  {
    code: 'UM', code3: 'UMI', name: 'United States Minor Outlying Islands (the)', number: '581',
    aliases: [
      'United States Minor Outlying Islands',
      'The United States Minor Outlying Islands'
    ]
  },
  {
    code: 'US', code3: 'USA', name: 'United States', number: '840',
    aliases: [
      'USA',
      'U.S.',
      'U.S.A.',
      'U.S.A',
      'America',
      'United States of America',
      'The United States of America',
    ]
  },
  {
    code: 'UY', code3: 'URY', name: 'Uruguay', number: '858',
    aliases: [
      'Oriental Republic of Uruguay'
    ]
  },
  {
    code: 'UZ', code3: 'UZB', name: 'Uzbekistan', number: '860',
    aliases: [
      'Republic of Uzbekistan'
    ]
  },
  {
    code: 'VA', code3: 'VAT', name: 'Holy See (Vatican City State)', number: '336',
    aliases: [
      'Holy See',
      'The Holy See',
    ]
  },
  {
    code: 'VC', code3: 'VCT', name: 'Saint Vincent and the Grenadines', number: '670',
    aliases: [
      'Saint Vincent'
    ]
  },
  {
    code: 'VE', code3: 'VEN', name: 'Venezuela, Bolivarian Republic of', number: '862',
    aliases: [
      'Bolivarian Republic of Venezuela',
      'Venezuela'
    ]
  },
  {
    code: 'VG', code3: 'VGB', name: 'Virgin Islands, British', number: '092',
    aliases: []
  },
  {
    code: 'VI', code3: 'VIR', name: 'Virgin Islands, U.S.', number: '850',
    aliases: []
  },
  {
    code: 'VN', code3: 'VNM', name: 'Viet Nam', number: '704',
    aliases: [
      'Vietnam',
      'Socialist Republic of Vietnam'
    ]
  },
  {
    code: 'VU', code3: 'VUT', name: 'Vanuatu', number: '548',
    aliases: [
      'Republic of Vanuatu'
    ]
  },
  {
    code: 'WF', code3: 'WLF', name: 'Wallis and Futuna', number: '876',
    aliases: []
  },
  {
    code: 'WS', code3: 'WSM', name: 'Samoa', number: '882',
    aliases: [
      'Independent State of Samoa'
    ]
  },
  {
    code: 'XI', code3: 'XXI', name: 'Northern Ireland', number: '-1',
    aliases: []
  },
  {
    code: 'XK', code3: 'XXK', name: 'Kosovo, Republic of', number: '-1',
    aliases: []
  },
  {
    code: 'YE', code3: 'YEM', name: 'Yemen', number: '887',
    aliases: [
      'Republic of Yemen'
    ]
  },
  {
    code: 'YT', code3: 'MYT', name: 'Mayotte', number: '175',
    aliases: []
  },
  {
    code: 'YU', code3: 'YUG', name: 'Yugoslavia', number: '890',
    aliases: []
  },
  {
    code: 'ZA', code3: 'ZAF', name: 'South Africa', number: '710',
    aliases: [
      'Republic of South Africa'
    ]
  },
  {
    code: 'ZM', code3: 'ZMB', name: 'Zambia', number: '894',
    aliases: [
      'Republic of Zambia'
    ]
  },
  {
    code: 'ZR', code3: 'ZAR', name: 'Zaire', number: '180',
    aliases: []
  },
  {
    code: 'ZW', code3: 'ZWE', name: 'Zimbabwe', number: '716',
    aliases: [
      'Republic of Zimbabwe'
    ]
  }
];

const countriesISO = unsortedCountries.sort((a, b) => a.name > b.name ? 1 : -1)

const countryCodeList = countriesISO.reduce((acc, country) => {
  acc[country.code] = country.name;
  return acc;
}, {})

const countryCodeNameAliasesList = countriesISO.reduce((acc, country) => {
  acc[country.code.toLowerCase()] = country.code;
  acc[country.code3.toLowerCase()] = country.code;
  const name = country.name.toLowerCase().replace(/[^a-zA-Z ]/g, '');
  acc[name] = country.code;
  country.aliases.forEach(alias => {
    const strippedAlias = alias.toLowerCase().replace(/[^a-zA-Z ]/g, '');
    acc[strippedAlias] = country.code;
  })
  return acc;
}, {})

export function getCountryName(code) {
  if (countryCodeList[code]) {
    return countryCodeList[code];
  } else {
    const countryAliases = unsortedCountries.filter(el => !!el.aliases.length);

    for (const country of countryAliases) {
      const alias = country.aliases.find(el => el === code);

      if (alias) {
        return country.name;
      }
    }

    return code;
  }
}

export {
  countriesISO,
  countryCodeList,
  countryCodeNameAliasesList
};
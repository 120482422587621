import React, { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';

import GearImg from '../../assets/landing/backgrounds/maintenance.png';

import LayoutHeader from '@components/layout/header';

import Footer from '@components/layout/footer';
import { useSelector } from 'react-redux';
import { maintenanceApi } from '@app/api';
import history from '@app/history';

const CHECK_DELAY = 5 * 1000 // ms

const selectLastPage = state => state.auth.lastVisitedPage;

export default function Maintenance() {
  const lastVisitedPage = useSelector(selectLastPage);

  const healthCheck = async () => {
    const res = await maintenanceApi.healthCheck();

    if (res?.status === 200) {
      if (lastVisitedPage) {
        history.replace(lastVisitedPage);
      } else {
        history.replace('/')
      }
    }
  }

  useEffect(() => {
    const id = setInterval(() => {
      healthCheck()
    }, CHECK_DELAY)

    return () => clearInterval(id)
    }, []) // eslint-disable-line

  return (
    <React.Fragment>
      <LayoutHeader isMaintenance={true} />

      <Box display="flex" pt={15} pb={5} justifyContent="center" alignItems="center" style={{ backgroundColor: '#D5F0F0', maxWidth: '100%', overflowX: 'hidden' }}>
        <img alt={'gear'} src={GearImg} style={{ height: 'calc(100vh - 110px - 120px - 40px)' }} />
        <Box zIndex={2} style={{ position: 'absolute', maxWidth: 350 }}>
          <Typography variant="h1" align="center">
                        Under Maintenance
          </Typography>
          <Typography variant="body1" align="center" style={{ marginTop: 20 }}>
                        We will be back soon...
          </Typography>
        </Box>
      </Box>
      <Footer/>

    </React.Fragment>
  );
}
import React from 'react';
import SecondStepBGCheck from './secondStepBGCheck';
import RenderNotNowButton from './renderNotNowButton';
import RenderExportButton from './renderExportButton';

export default function flowBG({ activeStep, exportAction, riskPriority, disableExportButton, profileAction, updateExisting, selectedProfile, handleNotNow }) {

  return (
    activeStep === 1 ? (
      <React.Fragment>
        <RenderExportButton
          action={exportAction}
          riskPriority={riskPriority}
          disableExportButton={disableExportButton}
        />
        <RenderNotNowButton handleNotNow={handleNotNow} />
      </React.Fragment>
    ) : (
      <React.Fragment>
        <SecondStepBGCheck profileAction={profileAction} updateExisting={updateExisting} selectedProfile={selectedProfile} />
        <RenderNotNowButton handleNotNow={handleNotNow} />
      </React.Fragment>
    )
  )
}
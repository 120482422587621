const config = {
  MuiPopover: {
    paper: {
      borderRadius: 16,
      '&::-webkit-scrollbar-track': {
        borderTopRightRadius: 16,
        borderBottomRightRadius: 16,
        width: 20
      }
    }
  }
}

export default config
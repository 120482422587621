import React, { useState } from 'react';
import clsx from 'clsx';
import { Box, Typography } from '@material-ui/core';
import Dropzone from 'react-dropzone';

import CustomTooltip from '@components/controls/tooltip';
import {
  ErrorIcon, DocumentsFile, CheckmarkIcon,
  DocumentsAddDocumentHover, DocumentsAddDocumentDark, DocumentsUploadDocument,
  EditIcon, EditInactiveIcon, CloseIcon, CloseInactiveIcon
} from '@app/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  categoryType: {
    position: 'relative',
    cursor: 'pointer',
    width: 145,
    borderRadius: 12,
  },
  categoryTypeHovered: {
    backgroundColor: '#EDEDED',
  },
  categoryTypeBadge: {
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '7px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#A4A4A4'
  },
  uploadDropzone: {
    border: '2px dashed #EDEDED',
    borderRadius: '12px',
    width: 115,
    height: 80,
    backgroundColor: '#FFFFFF',
  },
  disabledDropzone: {
    borderStyle: 'solid'
  },
  categoryCardActiveDragIcon: {
    top: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    '& svg': {
      width: '100%',
      height: '100%'
    }
  },
  categoryCardClose: {
    position: 'absolute',
    top: 2,
    right: 2,
    cursor: 'pointer'
  },
  categoryCardEdit: {
    position: 'absolute',
    top: 24,
    right: 2,
    cursor: 'pointer'
  },
  defaultCursor: {
    cursor: 'default',
  }
}))

const HoverableIcon = ({ activeIcon, inactiveIcon, className, onClick, tooltip }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <CustomTooltip
      width={120}
      title={tooltip}
      placement="left"
    >
      <Box
        className={className}
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered ? activeIcon : inactiveIcon}
      </Box>
    </CustomTooltip>
  )
}

export default function CategoryTypeCard({ categoryType, onClick, onDrop, isCustom, onDelete, onEdit, readonly }) {
  const classes = useStyles()
  const [isHovered, setIsHovered] = useState(false)

  const onInternalDelete = event => {
    event.stopPropagation()
    onDelete()
  }

  const onInternalEdit = event => {
    event.stopPropagation()
    onEdit()
  }

  return (
    <Box onClick={readonly ? undefined : onClick}>
      <Dropzone
        onDrop={onDrop}
        multiple={false}
        noClick
        disabled={readonly}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <Box
            display="flex"
            justifyContent="center"
            m={1}
            py={1}
            px={1}
            className={clsx(
              classes.categoryType,
              (!readonly && (isDragActive || isHovered)) && classes.categoryTypeHovered,
              readonly && classes.defaultCursor,
            )}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {!!categoryType &&
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                {(!categoryType.uploaded && !!categoryType.expected) &&
                  <CustomTooltip
                    width={120}
                    title="Please upload required KYC documents"
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      height={24}
                      alignItems="center"
                    >
                      <Box display="flex">
                        <ErrorIcon />
                      </Box>
                      <Box display="flex" className={classes.categoryTypeBadge}>
                        REQUIRED
                      </Box>
                    </Box>
                  </CustomTooltip>
                }
                {(!categoryType.uploaded && !categoryType.expected) &&
                  <Box
                    display="flex"
                    justifyContent="center"
                    height={24}
                    alignItems="center"
                    className={classes.categoryTypeBadge}
                  >
                    OPTIONAL
                  </Box>
                }
                {categoryType.uploaded &&
                  <Box
                    display="flex"
                    justifyContent="center"
                    height={24}
                    alignItems="center"
                  >
                    <Box display="flex" mr="4px">
                      <CheckmarkIcon />
                    </Box>
                    <Box display="flex" className={classes.categoryTypeBadge}>
                      UPLOADED
                    </Box>
                  </Box>
                }


                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className={clsx(classes.uploadDropzone, readonly && classes.disabledDropzone)}
                  mt={1}
                >
                  {!readonly && <DocumentsUploadDocument />}
                </Box>
                <Box display="flex" justifyContent="center" mt={2}>
                  <Typography variant="subtitle2" align="center">{categoryType.name}</Typography>
                </Box>
              </Box>
            }
            {!categoryType &&
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column" pt={3}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width={115}
                  height={80}
                  p="2px"
                  mt={1}
                >
                  {isHovered ? <DocumentsAddDocumentHover /> : <DocumentsAddDocumentDark />}
                </Box>
                <Box display="flex" justifyContent="center" mt={2}>
                  <Typography variant="subtitle2" align="center" style={{ color: '#C6C6C6 ' }}>Create Document</Typography>
                </Box>
              </Box>
            }
            {isDragActive &&
              <Box
                className={classes.categoryCardActiveDragIcon}
              >
                <DocumentsFile />
              </Box>
            }
            {(!readonly && !!isCustom && isHovered) &&
              <HoverableIcon
                className={classes.categoryCardClose}
                onClick={onInternalDelete}
                activeIcon={<CloseIcon />}
                inactiveIcon={<CloseInactiveIcon />}
                tooltip="Delete Document"
              />
            }
            {(!readonly && isHovered && onEdit) &&
              <HoverableIcon
                className={classes.categoryCardEdit}
                onClick={onInternalEdit}
                activeIcon={<EditIcon />}
                inactiveIcon={<EditInactiveIcon />}
                tooltip="Edit Document"
              />
            }
          </Box>
        )}
      </Dropzone>
    </Box>
  );
}
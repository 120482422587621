import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import ProgressDialog from '@components/modals/progress-dialog';
import {
  clientDocumentCategory,
  clientDocument,
  clientDocumentType,
  clientRulebook,
} from '@app/api/client';
import DocumentDialog from './components/document-dialog';
import documentCategoryDto from './dto/document-category-dto';
import { resolveCategoryIconPipe } from './utils/categories-icons';
import documentDto from './dto/document-dto';
import CategoryWrapper from './components/category-wrapper';
import DocumentsList from './components/document-list';
import documentTypeDto from './dto/document-type-dto';
import { prepareData } from '@app/api/api-utils';

export default function KycDocumentsList(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { data, readonly } = props;
  const clientId = data?.id

  const [pendingUpdateDocument, setPendingUpdateDocument] = useState(null)
  const [pendingDocumentDialogShown, setPendingDocumentDialogShown] = useState(false)
  const [pendingDocumentFile, setPendingDocumentFile] = useState(null)
  const [pendingDocumentCategory, setPendingDocumentCategory] = useState(null)
  const [pendingDocumentUploadInProgress, setPendingDocumentUploadInProgress] = useState(false)

  const [documents, setDocuments] = useState([])
  const [categories, setCategories] = useState([])
  const [documentTypes, setDocumentTypes] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [rulebookCategories, setRulebookCategories] = useState([])

  const fetchCategories = async () => {
    const response = await clientDocumentCategory.index()
    const categoriesList =
      resolveCategoryIconPipe(
        response.data.entries.map(
          category => documentCategoryDto.read(category)
        )
      )
    setCategories(categoriesList)
  }

  const fetchDocumentTypes = async () => {
    const response = await clientDocumentType.index(clientId)
    setDocumentTypes(
      response.data.entries.map(
        documentType => documentTypeDto.read(documentType)
      )
    )
  }

  const fetchRulebook = async () => {
    const response = await clientRulebook.show(data.raw.basedOnRulebookVersionId)
    const rulebookCategories = response.data?.documentRules || []
    // Merge categories with similar IDs
    const mergedCategories = {}
    rulebookCategories.forEach(category => {
      const categoryId = category.categoryId
      if (!mergedCategories[categoryId]) {
        mergedCategories[categoryId] = category
      } else {
        const existingCategory = mergedCategories[categoryId]
        const newCategory = {
          ...category,
          categoryRuleType: existingCategory.categoryRuleType === 'PER_TYPE'
            ? 'PER_TYPE'
            : category.categoryRuleType
        }

        const newTypes = newCategory.types.map(type => {
          const existingType = existingCategory.types.find(e => e.typeId === type.typeId)
          return {
            ...type,
            expected: existingType?.expected || !!type.expected
          }
        })
        newCategory.types = newTypes;

        mergedCategories[categoryId] = newCategory;
      }
    })

    setRulebookCategories(Object.values(mergedCategories))
  }

  const fetchDocuments = async () => {
    const response = await clientDocument.search({ clientId })
    setDocuments(
      response.data.entries.map(
        document => documentDto.read(document)
      )
    )
  }

  useEffect(() => {
    if (clientId) {
      Promise.all([
        fetchCategories(),
        fetchDocumentTypes(),
      ]).then(() => {
        fetchRulebook()
        fetchDocuments()
      })
    }
  }, [clientId]) //eslint-disable-line

  const updateCategoryAndTypes = () => {
    fetchCategories();
    fetchDocumentTypes();
  }

  const formattedCategories = useMemo(() => {
    const newCategories = [...categories]

    if (!documentTypes?.length) {
      return newCategories
    }

    // Add types to categories
    newCategories.forEach(category => {
      category.types = documentTypes.filter(type => type.categoryIds.includes(category.id)).map(e => ({ ...e }))
    })

    // Clear uploaded from all types and category
    newCategories.forEach(category => {
      category.hasCompleted = undefined;
      category.types?.forEach(type => {
        type.uploaded = false
      })
    })

    // Check for types that has documents
    documents.forEach((document) => {
      const category = newCategories.find(e => e.id === document.categoryId)
      if (category) {
        const categoryType = category.types?.find(e => e.id === document.typeId)
        if (categoryType) {
          categoryType.uploaded = true
        }
      }
    })

    rulebookCategories.forEach((ruleCategory) => {
      const category = newCategories.find(e => e.id === ruleCategory.categoryId);
      if (category) {
        const uploadedDocuments = documents.filter(document => (
          document.categoryId === ruleCategory.categoryId
          &&
          ruleCategory.types.map(e => e.typeId).includes(document.typeId)
        ))

        switch (ruleCategory.categoryRuleType) {
          case 'ONE_OF':
            if (uploadedDocuments.length > 0) {
              category.hasCompleted = true
            }
            break;

          case 'PER_TYPE':
            const requiredTypes = ruleCategory.types.filter(type => type.expected)
            if (requiredTypes.length) {
              const completedTypes = requiredTypes
                .filter(type => uploadedDocuments.some(e => e.typeId === type.typeId))
              category.hasCompleted = completedTypes.length
            } else {
              category.hasCompleted = uploadedDocuments.length
            }
            break;

          default:
            break;
        }
      }
    })

    return newCategories
  }, [rulebookCategories, categories, documents, documentTypes])

  const onDocumentDialogClose = useCallback(() => {
    closeDocumentDialog()
  }, [])

  const closeDocumentDialog = () => {
    setPendingDocumentDialogShown(false)
    setTimeout(() => { // use slight delay so dialog doesn't get updated before closed 
      setPendingDocumentFile(null)
      setPendingDocumentCategory(null)
      setPendingUpdateDocument(null)
    }, 150)
  }

  const addReqToFormData = (req, formData) => {
    // Yes, we need to somehow convert our object into a JSON string.
    // Then convert said string into a blob
    // Just so we can send it over and set the contentType as application/json
    const str = JSON.stringify(prepareData(req));
    const bytes = new TextEncoder().encode(str);
    const blob = new Blob([bytes], {
      type: 'application/json;charset=utf-8',
    });

    formData.append('req', blob, { contentType: 'application/json' })
  }

  const onDocumentCreate = async data => {
    const category = formattedCategories.find(e => e.id === data.categoryId)
    const documentType = category.types.find(e => e.id === data.typeId)

    // If existing document with the same cat and type exists
    // Upload as a new version of that document instead.
    const existingDocument = documents
      .sort((a, b) => new Date(b.updateDate) - new Date(a.updateDate))
      .find(e => e.categoryId === data.categoryId && e.typeId === data.typeId)

    try {
      const req = documentDto.write({
        ...data,
        clientId,
        associationType: props.data.client_type, // get from client data
      })
      const formData = new FormData();
      formData.append('file', data.file)
      addReqToFormData(req, formData);

      setPendingDocumentUploadInProgress(true)
      if (existingDocument) {
        await clientDocument.update(existingDocument.id, formData, true)
      } else {
        await clientDocument.store(formData)
      }
      enqueueSnackbar(`Document "${documentType?.name}" was successfully uploaded!`, { variant: 'success' });
      setPendingDocumentUploadInProgress(false)
    } catch (err) {
      setPendingDocumentUploadInProgress(false)
    }

    fetchDocuments()
    props.fetchClient();
    closeDocumentDialog()
  }


  const onDocumentUpdate = async data => {
    const category = formattedCategories.find(e => e.id === data.categoryId)
    const documentType = category.types.find(e => e.id === data.typeId)

    const req = documentDto.write({
      ...data,
      clientId,
    })

    const formData = new FormData();
    if (data.file) {
      formData.append('file', data.file)
    }
    addReqToFormData(req, formData);

    if (data.file) {
      setPendingDocumentUploadInProgress(true)
      await clientDocument.update(pendingUpdateDocument.id, formData, true)
      setPendingDocumentUploadInProgress(false)
    } else {
      await clientDocument.update(pendingUpdateDocument.id, formData)
    }
    enqueueSnackbar(`Document "${documentType?.name}" was successfully updated!`, { variant: 'success' });

    fetchDocuments()
    props.fetchClient();
    closeDocumentDialog()
  }

  return (
    <Box>
      {!readonly &&
        <CategoryWrapper
          rulebookCategories={rulebookCategories}
          categories={formattedCategories} setCategories={setCategories}
          selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory}
          documentTypes={documentTypes}
          fetchCategories={updateCategoryAndTypes}
          setPendingDocumentDialogShown={setPendingDocumentDialogShown}
          setPendingDocumentFile={setPendingDocumentFile}
          setPendingDocumentCategory={setPendingDocumentCategory}
          readonly={readonly}
          data={data}
        />
      }
      <Box mt={1}>
        <DocumentsList
          categories={formattedCategories}
          documentTypes={documentTypes}
          documents={documents} setDocuments={setDocuments}
          selectedCategory={selectedCategory}
          fetchClient={props.fetchClient}
          fetchDocuments={fetchDocuments}
          setPendingUpdateDocument={setPendingUpdateDocument}
          setPendingDocumentDialogShown={setPendingDocumentDialogShown}
          setPendingDocumentCategory={setPendingDocumentCategory}
          readonly={readonly}
          data={data}
        />
      </Box>
      <DocumentDialog
        open={!!pendingDocumentDialogShown}
        type={pendingDocumentDialogShown}
        // Passed document means update flow
        document={pendingUpdateDocument}
        category={pendingDocumentCategory}
        file={pendingDocumentFile}
        onClose={onDocumentDialogClose}
        onSave={pendingUpdateDocument ? onDocumentUpdate : onDocumentCreate}
      />
      <ProgressDialog
        open={pendingDocumentUploadInProgress}
        isLoading={pendingDocumentUploadInProgress}
        title={'Uploading Document'}
      />
    </Box>
  )
}
import React, { useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import InnerHeader from '@components/layout/inner-header';
import { useParams } from 'react-router-dom';
import { countriesISO } from '@services/country-service';
import ReactCountryFlag from 'react-country-flag'
import history from '@app/history';
import {
  AddIcon
} from '@app/icons';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { entitySettingsMode, getModeByUrlParam, getDashboardUrlByMode, getParticularUrl } from './utils/modeUtil'
import DashboardTable from './dashboard-table';

const FILTER_TABS_BY_MODE = {
  [entitySettingsMode.TYPE]: [
    {
      id: 'my',
      label: 'My entities'
    },
    {
      id: 'system-local',
      label: 'System local entities'
    },
    {
      id: 'system-global',
      label: 'System global entities'
    }
  ],
  [entitySettingsMode.JURISDICTION]: [
    {
      id: 'my',
      label: 'My entities'
    },
    {
      id: 'system-local',
      label: 'System local entities'
    },
    {
      id: 'system-global',
      label: 'System global entities'
    }
  ]
}

export default function Dashboard() {

  const [searchTerm, setSearchTerm] = useState('')

  const {tab, filterValue} = useParams()

  const mode = useMemo(() => {
    return getModeByUrlParam(tab)
  }, [tab])

  const [fTab, setFilterTab] = useState(null)

  const filterTabs = useMemo(() =>
    FILTER_TABS_BY_MODE[mode],
  [mode]
  )

  const filterTab = useMemo(() =>
    fTab || filterTabs[0]?.id || null,
  [fTab, filterTabs]
  )

  const onFilterTabChange = iFilterTab => {
    const newFilterTab = filterTabs[iFilterTab]?.id
    if (newFilterTab) {
      setFilterTab(
        newFilterTab
      )
    }
  }

  const countryData = useMemo(() => {
    return mode === entitySettingsMode.JURISDICTION? countriesISO?.find(country => country.code === filterValue): null
  }, [mode, filterValue])

  const title = useMemo(() => {
    return mode === entitySettingsMode.TYPE? filterValue: countryData.name
  }, [mode, filterValue]) //eslint-disable-line

  const onCreateEntity = () => {
    history.push(
      getParticularUrl(mode, filterValue, 'create', null,1)
    )
  }

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      <InnerHeader
        hasSearch
        searchLabelText={'Search by name'}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        fullWidth
        endButtons={(
          <ButtonWithIcon
            startIcon={<AddIcon />}
            onClick={onCreateEntity}
          >
            Add Custom Entity
          </ButtonWithIcon>
        )}
        title={title}
        titleAbsolute={false}
        titleLogo={!!countryData? (
          <ReactCountryFlag
            countryCode={countryData.code}
            style={{
              fontSize: '3.5em',
              lineHeight: '1.5em',
            }}
          />
        ): null}
        onBack={() => {
          history.push(
            getDashboardUrlByMode(mode)
          )
        }}
        shiftTabs
        onTabChange={onFilterTabChange}
        ind={filterTabs.findIndex(t => t.id === filterTab)}
        tabs={filterTabs}
      />

      <Box display={'flex'} mt={2} style={{overflowY: 'auto', maxHeight: 'calc(100vh - 200px)'}}>
        <Box display={'flex'} style={{width: '100%'}}>
          <DashboardTable
            filterMode={filterTab}
            mode={mode}
            filterValue={filterValue}
            searchTerm={searchTerm}
          />
        </Box>
      </Box>
    </Box>
  )
}
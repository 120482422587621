import React, { useState } from  'react';
import { Box, Typography, Collapse } from '@material-ui/core';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { DropdownDownIcon, DropdownUpIcon, SearchCreditsOtherIcon, InfoBrownOtherIcon } from '@app/icons';
import RegularCreditsBlock from './regular-credits-block';
import PremiumCreditsBlock from './premium-credits-block';
import { useStyles } from './styles';

export default function SearchCreditsBlock({ currentPlan }) {
  const classes = useStyles();

  const [isShowMore, setIsShowMore] = useState(false);

  return (
    <Box className={classes.root} py={1} px={4} mt={3} display="flex" flexDirection="column" justifyContent="center">
      <Box ml={'-5px'}>
        <ButtonWithIcon
          startIcon={<SearchCreditsOtherIcon />}
          endIcon={isShowMore
            ? <DropdownUpIcon />
            : <DropdownDownIcon />
          }
          onClick={() => setIsShowMore(bool => !bool)}
        >
          <Typography className={classes.showMoreTitle}>My search credits</Typography>
        </ButtonWithIcon>
      </Box>
      <Collapse in={isShowMore}>
        <RegularCreditsBlock currentPlan={currentPlan} />
        <PremiumCreditsBlock currentPlan={currentPlan} />
        <Box display="flex" mb={1}>
          <Box>
            <Box width={20}>
              <InfoBrownOtherIcon />
            </Box>
          </Box>
          <Box>
            <Typography style={{ lineHeight: '18px', color: '#7A5B44' }}>If you have "Reserved" credits it means that some searches are currently in the queue for the Ongoing Monitoring. Once monitoring starts, these credits will be spent.</Typography>
          </Box>
        </Box>
      </Collapse>
    </Box>
  )
}

import { useSelector } from 'react-redux';
import history from '@app/history';
import debugMode from './utils/debugMode';
import { useEffect } from 'react';

const ENABLE_MAINTENANCE = false;

function MaintenanceInterceptor() {
  const isGuest = useSelector(state => !state.auth.user);
  const { side, userIsAdmin } = useSelector(state => state.auth);

  const isInMaintenance = ENABLE_MAINTENANCE || Boolean(debugMode.getMaintenance())

  const allowedRoutes = [
    '/debug', '/sources-list', '/unsubscribe', '/privacy-policy', '/terms-of-use'
  ]

  useEffect(() => {
    if (!isGuest) {
      if (side === 'client' && !userIsAdmin) {
        if (isInMaintenance) {
          if (!allowedRoutes.includes(history.location?.pathname)) {
            history.push('/under-maintenance')
          }
        } else if (history.location?.pathname === '/under-maintenance') {
          history.push('/')
        }
      }
    }
  }, [history.location?.pathname]) // eslint-disable-line

  return null
}

export default MaintenanceInterceptor;

import React, { useState, useMemo } from 'react';
import { Box, MenuItem } from '@material-ui/core';
import InnerHeader from '@components/layout/inner-header';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import MenuWithContext from '@components/dropdowns/menu-with-context';
import { NewPersonIcon } from '@app/icons';
import { Route, Switch, Redirect } from 'react-router';
import { useParams } from 'react-router-dom';

import history from '@app/history';
import { useSelector } from 'react-redux';

import ManageUsers from './manage-users';
import ManageTeams from './manage-teams';

const tabs = [
  {
    id: 'manage-users',
    label: 'Manage Users',
  },
  {
    id: 'manage-teams',
    label: 'Manage Teams',
  },
]

export default function UserManagement(props) {
  const { tab } = useParams();
  const { userIsAdmin, side } = useSelector(state => state.auth);

  const [tabID, setTabID] = useState(tab || 'manage-users');
  const [modalAction, setModalAction] = useState(null);
  const [userData, setUserData] = useState([]);

  const disabledMenuAction = useMemo(() => userData.map(el => el.collapse).flat().find(el => el?.isChecked), [userData])

  const onTabChange = (index) => {
    const tabID = tabs[index].id;
    setTabID(tabID);
    history.push(`/user-management/${tabID}`)
  }

  const handleMenuActions = (type) => {
    const checkedItems = tabID === 'manage-teams'
      ? userData.map(el => el.collapse).flat().filter(el => el.isChecked)
      : userData.filter(el => el.isChecked);

    setModalAction({
      name: checkedItems.map(el => `"${el.fullName || el.name}"`).join(', '),
      type,
      prefix: tabID === 'manage-teams' ? 'team member(s)' : 'user(s)'
    });
  }

  const handleAdd = () => {
    tabID === 'manage-users'
      ? history.push('/user-management/manage-users/add-user')
      : setModalAction({ type: 'invite' });
  }

  return (
    <Box mx={6} height="100%">
      <InnerHeader
        onTabChange={onTabChange}
        ind={tabs.findIndex(tab => tab.id === tabID)}
        tabs={(userIsAdmin && side === 'client') ? [tabs[0]] : tabs}
        buttons={side === 'admin' && (
          <React.Fragment>
            <ButtonWithIcon startIcon={<NewPersonIcon />} onClick={handleAdd}>
              {tabID === 'manage-users' ? 'New User' : 'Invite New Member'}
            </ButtonWithIcon>
            <MenuWithContext title="More Actions">
              <MenuItem onClick={() => handleMenuActions('delete')} disabled={!disabledMenuAction}>
                Delete
              </MenuItem>
              <MenuItem onClick={() => handleMenuActions('block')} disabled={!disabledMenuAction}>
                Block
              </MenuItem>
            </MenuWithContext>
          </React.Fragment>
        )}
      />
      <Switch>
        <Route exact path="/user-management/manage-users">
          <ManageUsers
            setUserData={setUserData}
            userIsAdmin={userIsAdmin && side === 'client'}
          />
        </Route>
        <Route exact path="/user-management/manage-teams">
          <ManageTeams
            modalAction={modalAction}
            setModalAction={setModalAction}
            setUserData={setUserData}
          />
        </Route>

        <Redirect to={`/user-management/${tabID}`} />
      </Switch>
    </Box>
  )
}
const ADDED = 'ADDED';
const UPDATED = 'UPDATED';
const DELETED = 'DELETED';
const UNCHANGED = 'UNCHANGED';
const SUPPRESSED = 'suppressed';
const REVIEWED = 'REVIEWED';

export const setCardTypeColor = (versionState) => {
  let cardColor = null;
  switch (versionState) {
    case REVIEWED: {
      cardColor = 'rgba(154, 124, 106, 0.3)';
      break;
    }
    case ADDED: {
      cardColor = '#E6FCDD';
      break;
    }
    case UPDATED: {
      cardColor = '#FFF6D1';
      break;
    }
    case DELETED: {
      cardColor = '#FFF1F1';
      break;
    }
    case SUPPRESSED: {
      cardColor = '#EDEDED';
      break;
    }
    case UNCHANGED: {
      cardColor = '#ffffff';
      break;
    }
    default: {
      cardColor = '#ffffff';
    }
  }

  return cardColor;
};

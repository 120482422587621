import React from 'react';
import { SidesLayout, SidesLayoutRight } from '@components/layout/sides-layout';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  Grid, Button, FormHelperText
} from '@material-ui/core';

import history from '@app/history';
import auth from '@app/auth';
import parseYupErrors from '@app/yup';
import * as yup from 'yup';
import AuthHeader from './auth-header';
import useErrorState from '@utils/errorState';

export default function ForgotPassword(props) {

  const [model, setModel] = React.useState({
    email: ''
  });

  const [errors, setErrors] = useErrorState({});

  const handleChange = (prop) => (event) => {
    setErrors({});
    setModel({ ...model, [prop]: event.target.value });
  };

  const onSubmit = function () {
    setErrors({});
    schema
      .validate(model, {abortEarly: false})
      .then(function (valid) {
        const email = model.email.trim().toLowerCase();
        auth.forgotPassword(email).finally(() => {
          history.push({
            pathname: '/reset-pass-sent',
            search: `?user=${btoa(email)}`
          });
        })
      })
      .catch(function (err) {
        setErrors(parseYupErrors(err), true);
      });
  }

  const schema = yup.object().shape({
    email: yup.string().lowercase().trim().email('Invalid email format').required()
  });

  return (
    <SidesLayout>
      <SidesLayoutRight
        header={<AuthHeader />}>

        <Grid container justify={'center'} alignItems={'center'}>
          <Grid item xs={10} md={8} lg={6}>
            <Grid item xs={12}>
              <Box mb={5}>
                <Typography variant={'h1'} align={'center'}>Forgot Password?</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display={'flex'} px={3} mb={2}>
                <Typography align={'center'}>
                                    Enter the email address you used when you registered and we’ll send you instructions to reset your password.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={(e) => e.preventDefault()}>
                <FormControl id="email" fullWidth variant="outlined" error={!!errors['email']}>
                  <InputLabel htmlFor="email">Email</InputLabel>
                  <OutlinedInput
                    id="email"
                    value={model.email}
                    onChange={handleChange('email')}
                    type="email"
                  />
                  {!!errors['email'] &&
                                        <FormHelperText>{errors['email'] || null}</FormHelperText>
                  }
                </FormControl>

                <Box display={'flex'} justifyContent={{ xs: 'center', md: 'flex-end' }} mt={5} sm={6}>
                  <Box display={'flex'}>
                    <Button onClick={onSubmit} variant="contained" size="large" type="submit">RESET</Button>
                  </Box>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Grid>

      </SidesLayoutRight>
    </SidesLayout>
  );
}
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import InnerHeaderControls from '@components/layout/inner-header-controls';
import CustomIconButton from '@components/buttons/button-with-icon';
import LabelsForRender from '@components/layout/labels-for-render';
import InnerHeader from '@components/layout/inner-header';
import BackButton from '@views/common/back-button';
import { CloseIcon, HistoryIcon, CommunicationAttachmentsIcon } from '@app/icons';
import { useLocation } from 'react-router-dom';
import { dummy } from '../../dummy';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  modalButtonWidth: {
    width: '160px',
  },
  cancelButton: {
    marginRight: '5px',
  },
  subtitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '18px',
    color: theme.palette.text.BL1,
    marginBottom: '15px',
  },
  wrapperLabels: {
    marginBottom: '10px',
    alignItems: 'flex-start'
  },
  selectedTitle: {
    background: '#484848',
    borderRadius: '24px',
    color: '#FFF'
  },
  attachmentIconLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '14px',
    wordBreak: 'break-word'
  },
  attachmentIcon: {
    stroke: '#FDFDFD',
    '&.selected': {
      stroke: '#A4A4A4'
    }
  },
  attachmentRoot: {
    cursor: 'pointer',
    alignItems: 'center',
    '&:hover $attachmentIcon:not(.selected)': {
      stroke: '#CCC',
      '& path': {
        stroke: '#CCC'
      }
    },
    maxWidth: '150px',
  },
}))

const tabs = [
  {
    id: 'viewDetails',
    label: 'View Details',
  }
]

export default function ViewEmail() {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [subject] = useState(search.get('subject'));

  const [attachmentID, setAttachmentID] = useState(null);
  const [iframeSize, setIframeSize] = useState({});

  const iframeRef = useRef();
  const attachmentRef = useRef();

  const isChrome = useMemo(() => {
    const chrome = navigator.userAgent.match(/Chrome/i) || [];
    return chrome[0] === 'Chrome';
  }, [])

  const classes = useStyles({ isChrome });

  useEffect(() => {
    const iFrame = document.getElementById('iframe_email');

    if (iframeRef.current && iFrame.contentWindow) {
      setIframeSize({
        width: iframeRef.current.clientWidth,
        height: iframeRef.current.offsetHeight
        // height: iFrame.contentWindow.document.body.scrollHeight
      })
    }
  }, [iframeRef, setIframeSize])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (attachmentRef.current && !attachmentRef.current.contains(event.target)) {
        setAttachmentID(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [attachmentRef, setAttachmentID])

  return (
    <React.Fragment>
      <Box display={'flex'} my={2} mx={1} flexDirection={'column'}>
        <InnerHeaderControls
          controlsLeft={<BackButton wrapped />}
        />
      </Box>
      <Box display={'flex'} mx={6} flexDirection={'column'}>
        <Typography variant="h4">{subject}</Typography>
        <Box ml={-1} mb={4}>
          <InnerHeader
            ind={0}
            tabs={tabs}
            buttons={
              <React.Fragment>
                <CustomIconButton
                  onClick={() => {}}
                  startIcon={<CloseIcon />}
                  className={classes.cancelButton}
                >
                  Cancel
                </CustomIconButton>
                <CustomIconButton
                  onClick={() => {}}
                  startIcon={<HistoryIcon />}
                >
                  Resend
                </CustomIconButton>
              </React.Fragment>
            }
          />
        </Box>
      </Box>
      <Box display={'flex'} mx={6} flexDirection={'column'}>
        <Grid container spacing={12}>
          <Grid item xs={6}>
            <Typography className={classes.subtitle}>Email Details</Typography>
            <LabelsForRender labels={dummy.emailDetails} propsWrapper={classes.wrapperLabels} />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.subtitle}>Attachments</Typography>
            <Box display={'flex'} flexDirection={'row'}>
              {dummy.attachments.map(item => (
                <Box
                  ref={attachmentRef}
                  display={'flex'}
                  flexDirection={'column'}
                  className={classes.attachmentRoot}
                  onClick={() => setAttachmentID(item.id)}
                  key={item.id}
                >
                  <Box display={'flex'} className={classes.folderIconGroup}>
                    <CommunicationAttachmentsIcon
                      className={clsx(classes.attachmentIcon, attachmentID === item.id && 'selected')}
                    />
                  </Box>
                  <Box px={2} py={1} className={clsx(classes.attachmentIconLabel, attachmentID === item.id && classes.selectedTitle)}>
                    {item.name}
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt={3} mb={5} mx={6} ref={iframeRef} height={isChrome ? '100%' : '35%'}>
        <Typography className={classes.subtitle}>Message</Typography>
        <iframe
          id="iframe_email"
          title="iframe_email"
          src={'/dummyEmail.html'}
          width={`${iframeSize.width}px`}
          height={`${iframeSize.height}px`}
          frameborder="0"
        />
      </Box>
    </React.Fragment>
  )
}
const setDefault = token => ({
  type: 'PORTFOLIO_SET_DEFAULT'
});

const setSortAndFilters = ({ sort, filters, sortDirection }) => ({
  type: 'PORTFOLIO_SET_SORT_FILTERS',
  sort,
  filters,
  sortDirection
});

const portfolio = {
  setDefault,
  setSortAndFilters,
}

export default portfolio;

const formatCurrency = (text) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(text);
}

export const formatCurrencyInput = (text) => {
  if (!text) return text;

  const strippedText = text.replace(/[^0-9]/g,'');
  if (!strippedText) return strippedText;

  return '$' + strippedText;
}

export const revertFormatCurrencyInput = (text) => {
  if (!text) return text;

  const strippedText = text.replace(/[^0-9]/g,'');
  return strippedText;
}

export default formatCurrency;

import React, { useEffect, useState } from 'react';
import {
  Box, Button, FormControl, FormHelperText, InputAdornment,
  InputLabel, MenuItem, OutlinedInput
} from '@material-ui/core';
import * as yup from 'yup';

import history from '@app/history';
import { clientApi } from '@app/api';
import parseYupErrors from '@app/yup';
import { InfoOtherIcon, ChevronLeftIcon } from '@app/icons';
import { ongoingMonittoring } from '@store/actions';

import ProgressDialog from '@components/modals/progress-dialog';
import CustomTooltip from '@components/controls/tooltip';
import SelectClearable from '@components/inputs/select-clearable';
import InnerHeader from '@components/layout/inner-header';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import useErrorState from '@utils/errorState';
import { useSelector, useDispatch } from 'react-redux';

import MonitoringSuccess from './dialog-success'

const BULK_MONITORING_LIMIT = 3000

const periods = [
  { label: 'Daily', value: 1 },
  { label: 'Weekly', value: 7 },
  { label: 'Monthly', value: 30 },
]

const notifications = [
  { label: 'Email Only', value: 'email' },
  // { label: 'App Only', value: 'app' },
  // { label: 'Email and App', value: 'emailAndApp' },
]

const schema = yup.object().shape({

  timeSliceInDays: yup.number().transform(value => (isNaN(value) ? undefined : value)).label('Monitoring period').required(),
  notifications: yup.string().label('Monitoring notification').required(),
  alternativeEmail: yup.string().label('Monitoring Alternative Email').trim().email('Invalid email format').max(100),
});

export default function OngoingMonitoringSettings(props) {
  const { selectedIds, searchSettings } = useSelector(store => store.ongoingMonittoring);
  const { steps, onRedirect, isImport } = props;
  const dispatch = useDispatch();

  const [model, setModel] = useState({
    timeSliceInDays: 1,
    notifications: 'email',
    alternativeEmail: '',
  });
  const [errors, setErrors] = useErrorState({});

  const [isUploading, setIsUploading] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0)
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [folderIdsExist] = useState(() => {
    if (isImport) {
      return JSON.parse(localStorage.getItem('importCsvIdsFolder'))
    }
    return undefined;
  })

  useEffect(() => {
    if (!selectedIds || !searchSettings.searchProvider) {
      onRedirect()
    }
    }, [selectedIds, searchSettings]) // eslint-disable-line

  const handleChange = (prop) => (event) => {
    const newModel = { ...model, [prop]: event.target.value };
    setModel(newModel);

    if (errors[prop]) {
      schema.validateAt(prop, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [prop]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [prop]: err.message });
        });
    }
  };

  const onSubmit = () => {
    setErrors({});
    schema
      .validate(model, { abortEarly: false })
      .then(() => {
        submit()
      })
      .catch(err => setErrors(parseYupErrors(err), true));
  }

  const submit = async () => {
    setIsUploading(true);
    setShowProgress(true);

    let count = 0

    for (let index = 0; index < selectedIds.length; index += BULK_MONITORING_LIMIT) {
      const clientIds = selectedIds.slice(index, index + BULK_MONITORING_LIMIT);
      const res = await clientApi.ongoingMonitoring.bulkCreate({
        clientIds,
        listTypes: searchSettings.listTypes,
        searchProvider: searchSettings.searchProvider,
        timeSliceInDays: model.timeSliceInDays,
        fuzziness: searchSettings.searchProvider === 'ALEPH' ? undefined : searchSettings.fuzziness,
      })

      count += res.data?.length

      let progress = ((index + BULK_MONITORING_LIMIT) / selectedIds.length) * 100
      progress = progress > 100 ? 100 : progress
      setProgress(Math.floor(progress))
    }

    setIsUploading(false);
    setUploadedCount(count)

    if (isImport) {
      localStorage.removeItem('importCsvIdsMonitoring');
    }
  }

  const uploadSuccess = () => {
    setShowProgress(false);
    setShowSuccessDialog(true);
  }

  const handleSuccessClose = () => {
    dispatch(ongoingMonittoring.setSearchSettings({}));
    dispatch(ongoingMonittoring.setSelectedIds([]));
    setShowSuccessDialog(false);
  }

  return (
    <Box px={6}>
      <ProgressDialog
        open={showProgress}
        onClose={uploadSuccess}
        isLoading={isUploading}
        title={isUploading ? 'Adding Clients to the screening queue' : 'Clients successfully added to the screening queue'}
        progressText={true ? 'Processing...' : `Processing... ${progress}%`}
      />

      <MonitoringSuccess
        open={showSuccessDialog}
        count={uploadedCount}
        onClose={handleSuccessClose}
        folderIdsExist={folderIdsExist}
      />

      <InnerHeader
        title="Monitoring Settings"
        stepper={{
          currentStep: steps?.currentStep || 3,
          stepsCount: steps?.stepsCount || 3
        }}
        buttons={
          <React.Fragment>
            <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={() => { history.goBack() }}>
                            Back
            </ButtonWithIcon>
          </React.Fragment>
        }
      />
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box width="400px" mt={4}>
          <FormControl id="timeSliceInDays" variant="outlined" fullWidth error={errors.timeSliceInDays}>
            <InputLabel>Monitoring Period</InputLabel>
            <SelectClearable
              value={model.timeSliceInDays}
              onChange={handleChange('timeSliceInDays')}
            >
              {periods.map(option => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </SelectClearable>
            <FormHelperText>{errors.timeSliceInDays}</FormHelperText>
          </FormControl>
        </Box>
        <Box width="400px" mt={1}>
          <FormControl id="notifications" variant="outlined" fullWidth error={errors.notifications}>
            <InputLabel>Monitoring Notification</InputLabel>
            <SelectClearable
              value={model.notifications}
              onChange={handleChange('notifications')}
            >
              {notifications.map(option => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </SelectClearable>
            <FormHelperText>{errors.notifications}</FormHelperText>
          </FormControl>
        </Box>
        {false &&
                <Box width="400px" mt={1}>
                  <FormControl id="alternativeEmail" variant="outlined" fullWidth error={errors.alternativeEmail}>
                    <InputLabel>Monitoring Alternative Email</InputLabel>
                    <OutlinedInput
                      value={model.alternativeEmail}
                      onChange={handleChange('alternativeEmail')}
                      endAdornment={
                        <InputAdornment position="end">
                          <CustomTooltip
                            title="If someone from your team needs to receive this information, type their email here"
                            placement="top"
                          >
                            <InfoOtherIcon />
                          </CustomTooltip>
                        </InputAdornment>
                      }
                    />
                    <FormHelperText>{errors.alternativeEmail}</FormHelperText>
                  </FormControl>
                </Box>
        }
        <Box mt={5} mb={2}>
          <Button
            variant="contained"
            size="large"
            onClick={onSubmit}
          >
            {'FINISH'}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

import React from 'react';
import { Modal } from '@components/modals';


export default function DeleteDialog({open, onClose, onConfirm}) {

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title={'Remove from monitoring'}
        mainText={'Are you sure you want to remove this search from monitoring?'}
        actions={
          [
            {
              type: 'main',
              label: 'NO',
              action: onClose,
              style: { width: 160 },
            },
            {
              type: 'secondary',
              label: 'YES',
              action: onConfirm,
              style: { width: 160 },
            },
          ]
        }
        actionsDirection="row"
      />
    </React.Fragment>
  );
}
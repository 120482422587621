import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography
} from '@material-ui/core';

import * as yup from 'yup';
import parseYupErrors from '@app/yup';
import DatePicker from '@components/calendar/input-date-picker';
import FileInput from '@components/inputs/file-input';
import SelectSearchable from '@components/inputs/select-searchable';
import { InfoBrownOtherIcon } from '@app/icons';
import moment from 'moment'

const DEFAULT_DATA = {
  categoryId: '',
  typeId: '',
  fileName: '',
  issueDate: '',
  expiryDate: '',
  file: null,
}

const OTHER_DOCUMENT_TYPE_VALUE = 'other'
const ALLOWED_EXTENSIONS = ['pdf', 'jpg', 'jpeg', 'png', 'bmp']
const MAX_FILE_SIZE = 20971520 // 20MB

export default function DocumentDialog({ open, onClose, onSave, document, file, category, type }) {
  const isEdit = !!document
  const isUpdate = type === 'update'; // On update, only allow name changes.
  const [model, setModel] = useState(DEFAULT_DATA)
  const [errors, setErrors] = useState({}) //eslint-disable-line

  useEffect(() => {
    if (open) {

      setErrors({})
      const newModel = {
        ...DEFAULT_DATA,
        file,
        categoryId: category?.id,
        typeId: !category
          ? OTHER_DOCUMENT_TYPE_VALUE
          : DEFAULT_DATA.typeId
      }

      if (file) {
        newModel.fileName = file.name
      }

      if (category?.selectedType) {
        newModel.typeId = category.selectedType.id
      }
      // Update flow fill model
      if (!!document) {
        newModel.typeId = document.typeId // Only populate typeId on new-version
        if (isUpdate) {
          newModel.issueDate = document.issueDate
          newModel.expiryDate = document.expiryDate
          newModel.fileName = document.documentName
        }
      }
      setModel(newModel)
    }
    // eslint-disable-next-line
  }, [open, category, document])

  const documentType = useMemo(() => {
    if (model.typeId && category) {
      const documentType = category.types.find(type => type.id === model.typeId)

      return {
        ...documentType,
        hasExpiry: !!documentType ? !!documentType.hasExpiry : false,
        expiryRequired: !!documentType ? !!documentType.expiryRequired : false,
      }
    } else {
      return null
    }
  }, [model.typeId, category]) // eslint-disable-line

  const title = useMemo(() => {
    const isEdit = !!document
    let typeName = documentType?.name;
    if (isEdit) {
      const documentType = category?.types.find(type => type.id === document.typeId)
      typeName = documentType?.name
    }

    const action = isEdit
      ? type === 'new-version' ? 'New Version of' : 'Update'
      : 'New'
    return documentType?.name ? `${action} "${typeName}"` : 'New Document Category'
  }, [document, documentType, category, type])

  const schema = yup.object().shape({
    categoryId: yup.string().nullable().trim().label('Category'),
    typeId: yup.string().required().label('Document Type'),
    file: yup.mixed().test('fileRequired', 'File is a required field', (value) => !!value || !!isEdit), // no need to validate on edit
    fileName: yup.string().trim().required().label('File Name').max(100),
    issueDate: yup.string()
      .test('noFutureDate', 'Document date should be before current date', (value) => !value || moment(value).isSameOrBefore(moment()))
      .required().label('Document Date'),
    expiryDate: yup.string().nullable()
      .test('afterIssued', 'Expiry date should not be earlier than document date', function (value) {
        return !value || !documentType?.expiryRequired || !this.parent.issueDate || moment(value).isAfter(moment(this.parent.issueDate))
      })
      .test('noPastDate', 'Expiry date should not be earlier than current date', (value) => !value || !documentType?.expiryRequired || moment(value).isSameOrAfter(moment(), 'days'))
      .test('expiryRequired', 'Expiry Date is a required field', (value) => !!value || !documentType?.expiryRequired),
  });

  const onInternalSave = () => {
    setErrors({});
    schema
      .validate(model, { abortEarly: false })
      .then(() => {
        const extension = (isEdit ? (model.file?.name || document.fileName) : model.file.name).split('.').pop();
        const documentName = model.fileName;
        let fileName = model.fileName;
        const fileNameExt = fileName.split('.').pop();
        if (fileNameExt !== extension) {
          fileName = `${model.fileName}.${extension}`

          // Trim fileName to be under 100 char (including ext)
          if (fileName.length > 100) {
            fileName = `${model.fileName.substring(0, 96)}.${extension}`
          }
        }

        if (!ALLOWED_EXTENSIONS.includes(extension.toLowerCase())) {
          return setErrors({ file: `File type is not supported. Supported types: ${ALLOWED_EXTENSIONS.join(', ')}.` })
        }

        if (model.file?.size > MAX_FILE_SIZE) {
          return setErrors({ file: 'Maximum file size allowed is 20 MB' })
        }

        onSave({
          ...model,
          documentName,
          fileName,
        })
      })
      .catch(err => {
        setErrors(
          parseYupErrors(err)
        )
      })
  }

  const linkModel = (field, isBool = false) => event => {
    setErrors({})
    setModel({
      ...model,
      [field]: !isBool ? event.target.value : !!event.target.checked
    })
  }

  const onFileChange = file => {
    if (file) {
      setErrors({})
      setModel({
        ...model,
        file,
        fileName: file.name
      })
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={(e) => e.preventDefault()}>
        <DialogTitle style={{ textTransform: 'none' }}>{title}</DialogTitle>
        <DialogContent>
          <Box width={'400px'}>
            {isEdit &&
              <FormControl variant="outlined" fullWidth error={!!errors['typeId']}>
                <SelectSearchable
                  value={model.typeId}
                  onChange={linkModel('typeId')}
                  options={category?.types?.map(e => ({ label: e.name, value: e.id })) || []}
                  label="Document Type"
                  error={!!errors.typeId}
                  disabled={isUpdate || type === 'new-version'}
                />
                {!!errors['typeId'] &&
                  <FormHelperText>{errors['typeId'] || null}</FormHelperText>
                }
              </FormControl>
            }

            {isUpdate ?
              <FormControl
                variant="outlined"
                fullWidth
                error={!!errors.fileName}
              >
                <InputLabel>
                  File Name
                </InputLabel>
                <OutlinedInput
                  value={model?.fileName}
                  onChange={linkModel('fileName')}
                />
                {!!errors.fileName &&
                  <FormHelperText>
                    {errors.fileName}
                  </FormHelperText>
                }
              </FormControl>
              :
              <FileInput
                label="File Name"
                onChange={onFileChange}
                file={model?.file}
                fileName={model?.fileName}
                onChangeFileName={linkModel('fileName')}
                error={errors.file || errors.fileName}
                accept={ALLOWED_EXTENSIONS.map(e => `.${e}`)}
              />
            }

            <Grid container>
              <Grid item xs={6} style={{ paddingRight: 4 }}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={!!errors.issueDate}
                  disabled={isUpdate && document?.verifiedBy === 'VERIFIED_VIA_EIDV'}
                >
                  <InputLabel shrink>
                    Document Date
                  </InputLabel>
                  <OutlinedInput
                    value={moment(model.issueDate).format('YYYY-MM-DD')}
                    type={'date'}
                    onChange={linkModel('issueDate')}
                    endAdornment={
                      <DatePicker
                        handleChange={linkModel('issueDate')}
                        inputValue={model.issueDate || ''}
                        disabled={isUpdate && document?.verifiedBy === 'VERIFIED_VIA_EIDV'}
                      />
                    }
                  />
                  {!!errors.issueDate &&
                    <FormHelperText>
                      {errors.issueDate}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>

              {!!documentType?.hasExpiry &&
                <Grid item xs={6} style={{ paddingLeft: 4 }}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={!!errors.expiryDate}
                    disabled={isUpdate && document?.verifiedBy === 'VERIFIED_VIA_EIDV'}
                  >
                    <InputLabel shrink>
                      Expiry Date
                    </InputLabel>
                    <OutlinedInput
                      value={moment(model.expiryDate).format('YYYY-MM-DD')}
                      type={'date'}
                      onChange={linkModel('expiryDate')}
                      endAdornment={
                        <DatePicker
                          handleChange={linkModel('expiryDate')}
                          inputValue={model.expiryDate || ''}
                          disabled={isUpdate && document?.verifiedBy === 'VERIFIED_VIA_EIDV'}
                        />
                      }
                    />
                    {!!errors.expiryDate &&
                      <FormHelperText>
                        {errors.expiryDate}
                      </FormHelperText>
                    }
                  </FormControl>
                </Grid>
              }
            </Grid>
            {documentType?.expirationType === 'PER_TYPE_DEFINED' &&
              <Grid item xs={12}>
                <Box display="flex" px={1} pt={1}>
                  <Box>
                    <Box width={20}>
                      <InfoBrownOtherIcon />
                    </Box>
                  </Box>
                  <Box>
                    <Typography style={{ lineHeight: '18px', color: '#7A5B44' }}>Provide document not older than 3 months, as older documents might not be accepted by counterparties</Typography>
                  </Box>
                </Box>
              </Grid>
            }
            {isUpdate && document?.verifiedBy === 'VERIFIED_VIA_EIDV' &&
              <Grid item xs={12}>
                <Box display="flex" px={1} pt={1}>
                  <Box>
                    <Box width={20}>
                      <InfoBrownOtherIcon />
                    </Box>
                  </Box>
                  <Box>
                    <Typography style={{ lineHeight: '18px', color: '#7A5B44' }}>You cannot update the dates for documents verified via eIDV.</Typography>
                  </Box>
                </Box>
              </Grid>
            }

            {(isEdit && model?.file) &&
              <Box mt={3}>
                <FormHelperText>The selected file will be uploaded as a new version of this document.</FormHelperText>
              </Box>
            }
          </Box>
        </DialogContent>
        <DialogActions>
          <Button style={{ width: '160px' }} variant={'outlined'} onClick={onClose}>
            CANCEL
          </Button>
          <Button style={{ width: '160px' }} variant={'contained'} onClick={onInternalSave} type="submit">
            {'SAVE'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
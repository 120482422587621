const quotes = [
  { text: 'There is no way to happiness. Happiness is the way.', author: 'Thich Nhat Hanh' },
  { text: 'Act as if what you do makes a difference. It does.', author: 'William James' },
  { text: 'People begin to become successful the minute they decide to be.', author: 'Harvey Mackay' },
  { text: 'Walk to your goal firmly and with bold steps.', author: 'Kahlil Gibran' },
  { text: 'Lay plans for something big by starting with it when small.', author: 'Lao Tzu' },
  { text: 'One’s destination is never a place, but a new way of seeing things.', author: 'Henry Miller' },
  { text: 'It’s how you deal with failure that determines how you achieve success.', author: 'David Feherty' },
  { text: 'It takes as much energy to wish as it does to plan.', author: 'Eleanor Roosevelt' },
  { text: 'As we work to create light for others, we naturally light our own way.', author: 'Mary Anne Radmacher' },
  { text: 'That’s what I consider true generosity: You give your all, and yet you always feel as if it costs you nothing.', author: 'Simone de Beauvoir' },
  { text: 'Integrity is doing the right thing even when no one is watching', author: 'C.S. Lewis' },
  { text: 'Achievement happens when we pursue and attain what we want. Success comes when we are in clear pursuit of why we want it.', author: 'Simon Sin' },
  { text: 'All limitations are self-imposed.', author: 'Oliver Wendell Holmes' },
]

export default quotes;

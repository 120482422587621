import React from 'react';
import { SidesLayout, SidesLayoutRight } from '@components/layout/sides-layout';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Grid, Button, FormHelperText
} from '@material-ui/core';

import OutlinedPassword from '@components/inputs/outlined-password';
import auth from '@app/auth';

import * as yup from 'yup';
import parseYupErrors from '@app/yup';
import history from '@app/history';
import AuthHeader from './auth-header';
import useErrorState from '@utils/errorState';

export default function PasswordReset(props) {

  const [model, setModel] = React.useState({
    password: '',
    password_confirm: ''
  });

  const handleChange = (prop) => (event) => {
    setErrors({});
    setModel({ ...model, [prop]: event.target.value });
  };

  const [errors, setErrors] = useErrorState({});

  const urlParams = new URLSearchParams(window.location.search);
  const encodedEmail = urlParams.get('user')
  const email = atob(encodedEmail)
  const code = urlParams.get('code')

  const generateErrorMessage = (err) => {
    switch (err.code) {
      case 'CodeMismatchException':
        return 'Reset link has expired';

      case 'InvalidPasswordException':
        return 'Password must have one capital letter, one small letter, one number and one special character';
            
      case 'ExpiredCodeException':
        return 'Reset link has expired or you have followed the wrong link. Please create a new request';

      default:
        return err.message;
    }
  }

  const onSubmit = () => {
    setErrors({});

    schema
      .validate(model, {abortEarly: false})
      .then(function (valid) {
        auth.resetPassword(email, model.password, code)
          .then(data => {
            history.push('/reset-pass-success');
          })
          .catch(err => {
            setErrors({
              password: generateErrorMessage(err)
            }, true)
          })
      })
      .catch(function (err) {
        setErrors(parseYupErrors(err), true);
      });
  }

  const schema = yup.object().shape({
    password: yup.string().label('Password').required()
      .test('format', 'Password must have at least 8 characters, one capital letter, one small letter, one number and one special character', function () {
        const regex = new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\\-=\\[\\]{};\':"\\\\|,.<>/?]).{8,}$');
        return regex.test(this.parent.password);
      })
      .test('format_space', 'Password can\'t start or end with space(s)', function () {
        const trimmedPassword = this.parent.password.trim();
        return trimmedPassword === this.parent.password;
      }),
    password_confirm: yup.string().label('Confirm new password').required().test('match', 'Passwords do not match', function () {
      return this.parent.password_confirm === this.parent.password
    })
  });

  return (
    <SidesLayout>
      <SidesLayoutRight header={<AuthHeader />}>

        <Grid container justify={'center'} alignItems={'center'}>
          <Grid item xs={10} md={8} lg={6}>
            <Grid item xs={12}>
              <Box mb={5}>
                <Typography variant={'h1'} align={'center'}>Enter New Password</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={(e) => e.preventDefault()}>
                <FormControl id="password" fullWidth variant="outlined" error={!!errors['password']}>
                  <InputLabel htmlFor="password">New Password</InputLabel>
                  <OutlinedPassword value={model.password} onChange={handleChange('password')} />
                  {!!errors['password'] &&
                                        <FormHelperText>{errors['password'] || null}</FormHelperText>
                  }
                </FormControl>
                <FormControl id="password-confirm" fullWidth variant="outlined" error={!!errors['password_confirm']}>
                  <InputLabel htmlFor="password-confirm">Confirm New Password</InputLabel>
                  <OutlinedPassword value={model.password_confirm} onChange={handleChange('password_confirm')} />
                  {!!errors['password_confirm'] &&
                                        <FormHelperText>{errors['password_confirm'] || null}</FormHelperText>
                  }
                </FormControl>
                <Box display={'flex'} justifyContent={{ xs: 'center', md: 'flex-end' }} mt={5} sm={6}>
                  <Box display={'flex'}>
                    <Button onClick={onSubmit} variant="contained" size="large" type="submit">FINISH</Button>
                  </Box>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Grid>

      </SidesLayoutRight>
    </SidesLayout>
  );
}
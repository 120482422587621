import React from 'react';
import { Badge, Box, Menu } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import clsx from 'clsx';
import CustomTooltip from '@components/controls/tooltip';

import { OptionsIcon, OptionsInactiveIcon } from '@app/icons';

const useStyles = makeStyles((theme) => ({
  contextButton: {
    borderRadius: 8,
    padding: 2,
  },
  buttonActive: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  },
  badge: {
    backgroundColor: 'rgb(202, 215, 202)'
  },
  pulsingButton: {
    backgroundColor: 'rgb(202, 215, 202)',
    animationName: '$pulsing',
    animationDuration: '2s',
    animationIterationCount: 'infinite',
  },
  '@keyframes pulsing': {
    '0%': {
      backgroundColor: 'rgb(202, 215, 202)',
    },
    '50%': {
      backgroundColor: 'rgb(213, 240, 240)',
    },
    '100%': {
      backgroundColor: 'rgb(202, 215, 202)',
    }
  }
}));

export default function MenuWithContext(props) {

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(false);
  const [isPulsing, setIsPulsing] = React.useState(true);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!!props.badge) {
      setIsPulsing(false);
    }

    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Badge invisible={!props.badge} badgeContent={props.badge} classes={{ badge: classes.badge }} max={999}>
      <Box>
        <CustomTooltip title={props.title} placement="top">
          <IconButton
            aria-label="filter-toggle"
            onMouseDown={handleClick}
            className={clsx([classes.contextButton, open && classes.buttonActive, (!!props.badge && isPulsing) && classes.pulsingButton])}
            disabled={props.disabled}
          >
            {!!props.disabled ? <OptionsInactiveIcon /> : <OptionsIcon />}
          </IconButton>
        </CustomTooltip>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClick={handleClose}
        >
          {props.children}
        </Menu>
      </Box>
    </Badge>
  )
}
const initialState = {
  rulebooksData: {
    global: [],
    local: [],
  },
};

const entityManagement = (state = initialState, action) => {

  switch (action.type) {
    case 'SET_ENTITY_MANAGEMENT_DEFAULT':
      return initialState;
    case 'SET_DOCUMENTS_DATA':
      return { ...state, rulebooksData: action.data };
    case 'UPDATE_DOCUMENTS_DATA':
      return { ...state, rulebooksData: { ...state.rulebooksData, [action.payload.viewBy]: action.payload.data} };
    default:
      return state
  }
};

export default entityManagement;

import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core';

import { clientApi } from '@app/api';
import { ongoingMonittoring } from '@store/actions';
import { SelectAllActiveIcon } from '@app/icons';

import InnerHeader from '@components/layout/inner-header';
import TableList from '@components/lists/table-list';
import ButtonWithIcon from '@components/buttons/button-with-icon';

import {prepareClient} from '@dto/client';
import { useDispatch, useSelector } from 'react-redux';

const BULK_MONITORING_LIMIT = 3000

const TABLE_COLUMNS_PERSON = [
  { field: 'name', headerName: 'Full name', bold: true, colProps: { width: '30%' } },
  { field: 'date_of_birth', headerName: 'Date of birth' },
  { field: 'residency', headerName: 'Residency' },
  { field: 'gender', headerName: 'Gender', colProps: { style: { textTransform: 'capitalize' } } },
  { field: 'folder_name', headerName: 'Folder' },
]

const TABLE_COLUMNS_COMPANY = [
  { field: 'name', headerName: 'Name', bold: true, colProps: { width: '30%' } },
  { field: 'type', headerName: 'Entity type' },
  { field: 'country', headerName: 'Country' },
  { field: 'registration_number', headerName: 'Registration Number' },
  { field: 'folder_name', headerName: 'Folder' },
]

const TABLE_COLUMNS_COMBINED = [
  { field: 'name', headerName: 'Name', bold: true, colProps: { width: '30%' } },
  { field: 'risk_score', headerName: 'Risk Rating', colProps: { style: { textTransform: 'capitalize' } } },
  { field: 'formatted_created_at', headerName: 'Date Added' },
  { field: 'last_review_at', headerName: 'Last Review' },
  { field: 'next_review_at', headerName: 'Next Review' }
]

const tabs = [
  { id: 'person', label: 'People' },
  { id: 'company', label: 'Legal Entities' },
  { id: 'all', label: 'All' },
]

const useStyles = makeStyles((theme) => ({
  selectedCount: {
    color: '#4E922C',
  },
  button: {
    padding: '10px 20px !important',
    width: 200,
  },
}));

export default function ImportChooseClients(props) {
  const { selectedIds } = useSelector(store => store.ongoingMonittoring);
  const { onNext, steps, onRedirect } = props;
  const classes = useStyles();
  const dispatch = useDispatch();



  const [uploadedIds] = useState(() => {
    return JSON.parse(localStorage.getItem('importCsvIdsMonitoring'))
  })

  const [tab, setTab] = useState(() => {
    if (uploadedIds?.personIds.length && !uploadedIds?.companyIds.length) {
      return 'person';
    } else if (!uploadedIds?.personIds.length && uploadedIds?.companyIds.length) {
      return 'company';
    }
    return 'person';
  })

  const allIds = useMemo(() => {
    switch (tab) {
      case 'person':
        return uploadedIds?.personIds || []    
            
      case 'company':
        return uploadedIds?.companyIds || []
                
      case 'all':
        return (uploadedIds?.personIds || []).concat(uploadedIds?.companyIds || [])
        
      default:
        return uploadedIds?.personIds || []
    }
  }, [tab, uploadedIds])

  const useTabs = useMemo(() => {
    if (uploadedIds?.personIds.length && !uploadedIds?.companyIds.length) {
      return [tabs[0]];
    } else if (!uploadedIds?.personIds.length && uploadedIds?.companyIds.length) {
      return [tabs[1]];
    }
    return tabs;
    }, [uploadedIds]) // eslint-disable-line

  // Pagination
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(12)
  const [pagesCount, setPagesCount] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const onPageChange = (event, page) => {
    setPage(page - 1)
  }

  const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState(() => {
    if (!selectedIds?.length) {
      const selectedItems = {}
      uploadedIds.personIds.forEach(id => {
        selectedItems[id] = true;
      })
      uploadedIds.companyIds.forEach(id => {
        selectedItems[id] = true;
      })
      return selectedItems
    } else {
      const selectedItems = {}
      selectedIds.forEach(id => {
        selectedItems[id] = true;
      })
      return selectedItems
    }
  });

  useEffect(() => {
    if (!uploadedIds ||
            (!uploadedIds?.personIds?.length && !uploadedIds?.companyIds?.length)
    ) {
      onRedirect();
    }
    }, [uploadedIds]) // eslint-disable-line

  useEffect(() => {
    fetchItems()
    // eslint-disable-next-line
    }, [page, tab, pageSize])

  const fetchItems = async () => {
    const totalCount = allIds.length;
    setTotalCount(allIds.length);

    if (totalCount > 0) {
      const startIndex = page * pageSize;
      const idsToFetch = allIds.slice(startIndex, startIndex + pageSize);
    
      const data = (await clientApi.client.bulkFetch(idsToFetch)).data
      setData(data.map(item => prepareClient(item)))
    } else {
      setData([])
    }

    setPagesCount(Math.ceil(allIds.length / pageSize));
  }

  const onTabChange = (index) => {
    const tabID = useTabs[index].id
    setTab(tabID)
    setPage(0);
  }

  const onSelect = (id, isChecked) => {
    if (id === 'all') {
      const selectedInPage = {}
      data.forEach(e => {
        selectedInPage[e.id] = isChecked
      })

      setSelectedItems({ ...selectedItems, ...selectedInPage })
    } else {
      setSelectedItems({ ...selectedItems, [id]: !selectedItems[id] })
    }
  }

  const isAllSelected = useMemo(() => {
    switch (tab) {
      case 'person':
        return uploadedIds.personIds.every(id => !!selectedItems[id])
            
      case 'company':
        return uploadedIds.companyIds.every(id => !!selectedItems[id])

      case 'all':
        return (uploadedIds.personIds.every(id => !!selectedItems[id])
                    && uploadedIds.companyIds.every(id => !!selectedItems[id]))
        
      default:
        return false
    }
  }, [selectedItems, uploadedIds, tab])

  const handleSelectAll = () => {
    const newSelectedItems = {}
    switch (tab) {
      case 'person':
        uploadedIds.personIds.forEach(id => { newSelectedItems[id] = !isAllSelected })
        break;

      case 'company':
        uploadedIds.companyIds.forEach(id => { newSelectedItems[id] = !isAllSelected })
        break;

      case 'all':
        uploadedIds.personIds.forEach(id => { newSelectedItems[id] = !isAllSelected })
        uploadedIds.companyIds.forEach(id => { newSelectedItems[id] = !isAllSelected })
        break;

      default:
        return false
    }

    setSelectedItems({ ...selectedItems, ...newSelectedItems })
  }

  const handleNext = () => {
    // open company tab instead
    if (tab === 'person' && useTabs.length > 1) {
      return onTabChange(1);
    } 
    const selectedIds = Object.entries(selectedItems).filter(([id, selected]) => !!selected).map(([id, selected]) => +id);
    dispatch(ongoingMonittoring.setSelectedIds(selectedIds));
    onNext();
  }

  const TABLE_COLUMNS = useMemo(() => {
    switch (tab) {
      case 'person':
        return TABLE_COLUMNS_PERSON;
      case 'company':
        return TABLE_COLUMNS_COMPANY;
      case 'all':
        return TABLE_COLUMNS_COMBINED;
      default:
        return TABLE_COLUMNS_PERSON;
    }
  }, [tab])

  const selectedCount = useMemo(() => {
    return Object.values(selectedItems).filter(e => !!e).length
  }, [selectedItems])

  const formatItems = useMemo(() => {
    return data.map(e => ({ ...e, isChecked: selectedItems[e.id] }))
  }, [selectedItems, data])

  return (
    <Box px={6}>
      <InnerHeader
        title="Choose Clients"
        stepper={{
          currentStep: steps?.currentStep || 1,
          stepsCount: steps?.stepsCount || 3
        }}
      />
      <InnerHeader
        onTabChange={onTabChange}
        ind={useTabs.findIndex(e => e.id === tab)}
        tabs={useTabs}
        buttons={
          <React.Fragment>
            <ButtonWithIcon startIcon={<SelectAllActiveIcon />} onClick={handleSelectAll}>
              {isAllSelected ? 'Deselect All' : 'Select All'}
            </ButtonWithIcon>
          </React.Fragment>
        }
      />
      <Box pt={3}>
        <Grid container>
          {!!formatItems.length &&
            <Grid item xs={12}>
              <Box mt={2}>
                <TableList
                  items={formatItems}
                  columns={TABLE_COLUMNS}
                  page={page}
                  pageSize={pageSize}
                  pageSizes={[12, 25]}
                  pagesCount={pagesCount}
                  totalCount={totalCount}
                  onPageChange={onPageChange}
                  onClick={onSelect}
                  onSelect={onSelect}
                  selectAll
                  maxHeight="calc(100vh - 560px)"
                  setPageSize={setPageSize}
                />
              </Box>
            </Grid>
          }
        </Grid>
      </Box>
      <Box my={3}>
        <Box mb={1}>
          <Typography className={classes.selectedCount} variant="subtitle2">{selectedCount} item(s) selected</Typography>
          {selectedCount > BULK_MONITORING_LIMIT && <Typography className={classes.selectedCount} variant="subtitle2">Max {BULK_MONITORING_LIMIT} items</Typography>}
        </Box>
        <Grid container alignItems="center" justify="center">
          <Button
            variant="contained"
            size="large"
            disabled={!selectedCount || selectedCount > BULK_MONITORING_LIMIT}
            onClick={handleNext}
            className={classes.button}
          >
            {'Next'}
          </Button>
        </Grid>
      </Box>
    </Box>
  )
}

import React from 'react';
import { Box } from '@material-ui/core';
import RenderInputs from '@components/layout/render-inputs';
import { countryCodeList } from '@services/country-service';

const inputs = [
  {
    key: 'email',
    label: 'Email',
    type: 'input',
  },
  {
    key: 'firstName',
    label: 'First Name',
    type: 'input',
  },
  {
    key: 'lastName',
    label: 'Last Name',
    type: 'input',
  },
  {
    key: 'address',
    label: 'Address',
    type: 'input',
  },
  {
    key: 'city',
    label: 'City',
    type: 'input',
  },
  {
    key: 'state',
    label: 'State',
    type: 'input',
  },
  {
    key: 'country',
    label: 'Country',
    options: countryCodeList,
    type: 'select',
    element: {
      key: 'zip',
      label: 'Zip/Post Code',
      type: 'input',
    }
  },
  {
    key: 'phone',
    label: 'Phone',
    dataType: 'number',
    type: 'input',
  },
]

export default function EditForm({ model, handleChange, errors, disabled }) {

  return <Box width={'425px'} my={4}>
    {inputs.map((field, i) => (
      <RenderInputs
        ind={i}
        field={field}
        model={model}
        errors={errors}
        handleChange={handleChange}
        key={`${field.key}-${i}`}
        disabled={disabled || field.key === 'email'}
      />
    ))}
  </Box>
}
import React from 'react';
import {Box, Button, Hidden} from '@material-ui/core';
import {ReactComponent as Magnifier} from '../../../../assets/img/icons/magnifier.svg';

export default function Search ({ placeholder, value, onChange, action, actionTitle, endAdornment, disabledAction }) {
  const handleKeyDown = (e) => {
    if (!!e.repeat) return;

    if (e.key === 'Enter' && action) {
      action();
    }
  }

  return (
    <React.Fragment>
      <Box px="10px" display="flex" className="search-group" alignItems="center" justifyContent="" >
        <Box ml="10px" >
          <Magnifier />
        </Box>
        <Box flexGrow={1} ml="15px" display="flex" direction="row" alignItems="center">
          <Hidden smUp>
            <input
              placeholder="Search"
              value={value}
              onChange={onChange}
              onKeyDown={handleKeyDown}
            />
          </Hidden>
          <Hidden xsDown>
            <input
              placeholder={placeholder || 'Search people and legal entities'}
              value={value}
              onChange={onChange}
              onKeyDown={handleKeyDown}
            />
          </Hidden>
          <Hidden smDown>
            {endAdornment}
          </Hidden>
        </Box>
        <Button
          variant="contained"
          size="large"
          onClick={() => action()}
          disabled={disabledAction || false}
        >
          {actionTitle || 'SEARCH'}
        </Button>
      </Box>
      <Hidden mdUp>
        <Box mt="10px" display="flex" width="100%" justifyContent="center">
          {endAdornment}
        </Box>
      </Hidden>
    </React.Fragment>
  );
}
export default function listColumns(type) {
  return type === 'PERSON'
    ? [
      { field: 'name', headerName: 'REVIEWER' },
      { field: 'risk', headerName: 'RISK RATING'},
      { field: 'started', headerName: 'STARTED' },
      { field: 'finished', headerName: 'FINISHED' }
    ] : [
      { field: 'name', headerName: 'REVIEWER' },
      { field: 'risk', headerName: 'RISK RATING' },
      { field: 'started', headerName: 'STARTED' },
      { field: 'finished', headerName: 'FINISHED' }
    ]
}
import React from 'react';

import { Box } from '@material-ui/core';
import SidesHeader from './sides-header';

export default function SidesLayoutRight(props) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" {...props}>
      {!!props.header &&
                <SidesHeader>
                  {props.header}
                </SidesHeader>
      }
      <Box display="flex" flexGrow={1} width="100%" mt={{ xs: 10, md: 0 }} mb={props.footer ? 0 : 5}>
        {props.children}
      </Box>
      {props.footer &&
                <Box alignSelf="flex-end" width="100%" height={{ xs: '50px', sm: '100px' }} mt={{ xs: 2, md: 0 }}>
                  {props.footer}
                </Box>
      }
    </Box>
  );
}
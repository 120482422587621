import moment from 'moment';

/**
 * Converts from UTC to users timezone and apply format
 *
 * @param timestamp
 * @param format
 * @param fromUTC
 * @returns {string}
 */
export default function prepareTimestamp(timestamp, format = 'ddd, MM/DD/YYYY - LT', fromUTC = true) {
  if (!timestamp) return timestamp;

  return fromUTC
    ? moment.utc(timestamp).local().format(format)
    : moment(timestamp).format(format)
}
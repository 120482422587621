import React from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((props) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: props => `${props.size}px`,
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '10px',
    textAlign: 'center',
    color: props => props.color,
    background: props => props.backgroundColor,
    minWidth: props => `${props.size}px`,
    height: props => `${props.size}px`,
    marginRight: '4px'
  }
}))

export default function Badge({color = '#FFF', backgroundColor = '#C0A2B8', size = 20, value}) {

  const classes = useStyles({
    color,
    backgroundColor,
    size
  })

  return (
    <Box className={classes.root}>
      { value }
    </Box>
  )
}
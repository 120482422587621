const { countryCodeList } = require('@services/country-service');

const formatAddress = (user) => {
  let formattedAddress = '';
  if (!!user) {
    if (user.address && user.address !== 'string') formattedAddress += `${user.address}, `;
    if (user.city && user.city !== 'string') formattedAddress += `${user.city}, `;
    if (user.state && user.state !== 'string') formattedAddress += `${user.state}, `;
    if (user.country && user.country !== 'string') formattedAddress += `${countryCodeList[user.country]}, `;
    if (user.zip && user.zip !== 'string') formattedAddress += `${user.zip}, `;
        
    const lastComma = formattedAddress.lastIndexOf(', ');
    formattedAddress = formattedAddress.substr(0, lastComma);
  }

  return formattedAddress;
}

export default formatAddress;

import React, { useMemo } from 'react';
import { Box, Card, Typography, MenuItem, makeStyles } from '@material-ui/core';
import MenuWithContext from '@components/dropdowns/menu-with-context';
import LabelsForRender from '@components/layout/labels-for-render';
import { getCountryName } from '@services/country-service'
import history from '@app/history';
import ReactCountryFlag from 'react-country-flag'
import { getIcon } from '@utils/entityType';
import { capitalize } from '@utils/textUtil';

const useStyles = makeStyles((theme) => ({
  card: {
    background: '#FFFFFF',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: '12px',
    overflow: 'inherit',
    '&:hover': {
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      cursor: 'pointer',
    },
    padding: '16px'
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#232323',
  }
}));

export default function EntityCard({ entity, label, scope, setPendingDelete }) {
  const classes = useStyles()

  const labels = useMemo(() => ([
    {
      field: 'ENTITY TYPE', value: entity.legalEntityType ?
        capitalize(entity.legalEntityType?.toLowerCase())
        : capitalize(entity.clientType?.toLowerCase())
    },
    { field: 'JURISDICTION', value: getCountryName(entity.jurisdiction) },
  ]), [entity])

  const icon = useMemo(() => getIcon(entity.legalEntityType || entity.clientType), [entity])

  const onEdit = () => {
    history.push(`/entity-management/${scope}/update/${entity.rulebookVersionId}`)
  }

  const onCloneToJurisdiction = () => {
    history.push(`/entity-management/${scope}/clone-to-jurisdiction/${entity.rulebookVersionId}`)
  }

  const onDeleteStart = () => {
    setPendingDelete(entity)
  }

  return (
    <React.Fragment>
      <Card className={classes.card} onClick={() => onEdit()} style={{ position: 'relative' }}>
        {entity.jurisdiction && entity.jurisdiction !== 'UNDEFINED' &&
          <Box style={{ position: 'absolute', top: 8 }}>
            <ReactCountryFlag
              countryCode={entity.jurisdiction}
              style={{
                fontSize: '1.4em',
                lineHeight: '2em',
              }}
            />
          </Box>
        }
        <Box display="flex" justifyContent="center" mt={1}>
          {icon}
        </Box>
        <Box display="flex" justifyContent="center" mt={2}>
          <Typography className={classes.label}>{label}</Typography>
        </Box>
        {scope === 'local' && (
          <Box mt={2}>
            <LabelsForRender
              labels={labels}
              med
            />
          </Box>
        )}
        <Box display="flex" justifyContent="flex-end" mr={1}>
          <MenuWithContext title="More Actions">
            <MenuItem
              onClick={onEdit}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={onCloneToJurisdiction}
            >
              Clone to Jurisdiction
            </MenuItem>
            <MenuItem
              onClick={onDeleteStart}
            >
              Delete
            </MenuItem>
          </MenuWithContext>
        </Box>
      </Card>
    </React.Fragment>
  )
};

import React from 'react';
import clsx from 'clsx';
import {Button} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import CustomTooltip from '@components/controls/tooltip';

// <ButtonWithIcon startIcon={<NewPersonIcon />}>
//     New Folder
// </ButtonWithIcon>

const CompactButton = withStyles((theme) => ({
  startIcon: {
    marginRight: 0
  }
}))(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '20px',
    color: '#7A5B44',
    textTransform: 'none',
    padding: '5px 10px'
  }
}));

export default function ButtonWithIcon({ children, className, tooltip, tooltipPlacement = 'top', compact, ...other }) {
  const classes = useStyles();

  const ButtonToUse = compact ? CompactButton : Button

  if (!!tooltip) {
    return (
      <CustomTooltip title={tooltip} placement={tooltipPlacement}>
        <span>
          <ButtonToUse className={clsx(classes.root, className)} {...other}>
            {children}
          </ButtonToUse>
        </span>
      </CustomTooltip>
    )
  }
  return (
    <ButtonToUse className={clsx(classes.root, className)} {...other}>
      {children}
    </ButtonToUse>
  )
}
import React from 'react';
import clsx from 'clsx';
import { Box, Typography, FormControl, InputLabel, OutlinedInput, MenuItem, Button, FormHelperText, IconButton, RadioGroup, Radio, FormControlLabel, Collapse } from '@material-ui/core';
import SelectClearable from '@components/inputs/select-clearable';
import SelectSearchable from '@components/inputs/select-searchable';
import OutlinedPassword from '@components/inputs/outlined-password';
import DatePicker from '@components/calendar/input-date-picker';
import CustomTooltip from '@components/controls/tooltip';
import ReactInputMask from 'react-input-mask';
import { countryCodeList } from '@services/country-service';

import { makeStyles } from '@material-ui/core/styles';
import { formatCurrencyInput } from '@utils/formatCurrency';
import { FIELD_TYPES } from '@app/constants';
import { EditIcon } from '@app/icons';
import { OTHER_FIELD, OTHER_VALUE } from '@dto/client';

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    width: '100%',
    background: '#fff'
  },
  elementRoot: {
    marginLeft: '10px',
    width: 'calc(100% - 10px)',
    background: '#fff'
  },
  inputWrapper: props => ({
    padding: '3px 7px 3px 7px',
    background: '#F8F8F8',
    borderRadius: '12px',
    marginTop: !!props.wrapperItem && '5px'
  }),
  maskInput: {
    '& .MuiInputBase-input': {
      '&::placeholder': {
        color: theme.palette.text.BL1,
      }
    }
  },
  inputWarning: {
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#A98320',
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#A98320',
    },
  },
}))

function BoolInput({ field, errors, model, handleChange, noLabel, tabIndex }) {
  const handleBoolChange = (key, value) => {
    if (model[field.key] === value) {
      return handleChange(key)({ target: { value: '' } })
    }
    return handleChange(key)(({ target: { value } }))
  }

  return (
    <FormControl variant="outlined" fullWidth id={field.key} error={!!errors[field.key]} required={field.required}>
      {!noLabel && <Typography>{field.label}{field.required && '*'}</Typography>}
      <RadioGroup value={model[field.key]} row tabIndex={tabIndex}>
        <FormControlLabel
          control={<Radio />}
          onClick={() => handleBoolChange(field.key, true)}
          checked={model[field.key] === true}
          label="Yes"
          style={{ marginLeft: 3, marginRight: 24 }}
        />
        <FormControlLabel
          control={<Radio />}
          onClick={() => handleBoolChange(field.key, false)}
          checked={model[field.key] === false}
          label="No"
        />          
      </RadioGroup>
      {!!errors[field.key] &&
        <FormHelperText>{errors[field.key] || null}</FormHelperText>
      }
    </FormControl>
  )
}

function CountryInput({ field, errors, model, handleChange, disabled, noLabel, tabIndex }) {
  return (
    <Box position="relative" display="flex" alignItems="center" width="100%">
      <FormControl variant="outlined" fullWidth id={field.key} error={!!errors[field.key]}>
        <SelectSearchable
          getOptionDisabled={(option) => option.disabled}
          value={model[field.key]}
          onChange={handleChange(field.key)}
          options={
            Object.keys(countryCodeList).map(optionValue =>
              ({ label: countryCodeList[optionValue], value: optionValue }))
          }
          label={noLabel ? undefined : field.label + (field.required ? ' *' : '')}
          error={!!errors[field.key]}
          disabled={disabled || field.isRulebookCountry}
          tabIndex={tabIndex}
        />
        {!!errors[field.key] &&
          <FormHelperText>{errors[field.key] || null}</FormHelperText>
        }
      </FormControl>
      {/* KYCPT-915 - Disable changing type and residency for now */}
      {false && field.isRulebookCountry &&
        <CustomTooltip title={'Change ' + field.label} placement="top">
          <IconButton onClick={handleChange(field.key)} style={{ position: 'absolute', right: 14, padding: 6 }}>
            <EditIcon />
          </IconButton>
        </CustomTooltip>
      }
    </Box>
  )
}

function SelectSearchableInput({ field, errors, model, handleChange, internalHandleChange, noLabel, tabIndex }) {
  return (
    <FormControl variant="outlined" fullWidth id={field.key} error={!!errors[field.key]} required={field.required}>
      <SelectSearchable
        filterOptions={field.filterOptions === 'default' ? undefined : (x) => x}
        inputValue={model[field.inputKey] || ''}
        overrideLabel={model[field.inputOverrideKey]}
        getOptionDisabled={(option) => option.disabled}
        renderOption={(option) => (
          <span
            style={{
              fontStyle: option.value === '0' ? 'italic' : 'normal',
            }}
          >
            {option.label}
          </span>
        )}
        onInputChange={(event, newInputValue) => {
          if (event?.type === 'change') {
            handleChange(field.inputKey)({ target: { value: newInputValue } });
          }
        }}
        value={model[field.key]}
        onChange={internalHandleChange(field)}
        options={field.options}
        label={noLabel ? undefined : field.label}
        error={!!errors[field.key]}
        tabIndex={tabIndex}
      />
      {!!errors[field.key] &&
        <FormHelperText>{errors[field.key] || null}</FormHelperText>
      }
    </FormControl>
  )
}

function SelectInput({ field, errors, model, handleChange, disabled, noLabel, tabIndex }) {
  return (
    <Box position="relative" display="flex" alignItems="center" width="100%">
      <FormControl
        fullWidth
        variant="outlined"
        id={field.key}
        error={!!errors[field.key]}
        required={field.required}
      >
        {!noLabel && <InputLabel shrink={!!model[field.key] || undefined}>{field.label}</InputLabel>}
        <SelectClearable
          value={model[field.key] || ''}
          onChange={handleChange(field.key)}
          style={{ width: '100%', background: '#fff' }}
          disabled={disabled || field.isRulebookCountry}
          hideIcons={field.isRulebookCountry}
          noLabel={noLabel}
          inputProps={{ tabIndex }}
        >
          {field.allowOther &&
            <MenuItem value={OTHER_VALUE}>Other</MenuItem>
          }
          {Object.entries(field.options)
            .sort((a, b) => a[1].localeCompare(b[1]))
            .map(([value, label]) => (
              <MenuItem
                key={value}
                value={value}
              >
                {label}
              </MenuItem>
            ))
          }
        </SelectClearable>
        {!!errors[field.key] &&
          <FormHelperText>{errors[field.key] || null}</FormHelperText>
        }
      </FormControl>
      {/* KYCPT-915 - Disable changing type and residency for now */}
      {false && field.isRulebookCountry &&
        <CustomTooltip title={'Change ' + field.label} placement="top">
          <IconButton onClick={handleChange(field.key)} style={{ position: 'absolute', right: 14, padding: 6 }}>
            <EditIcon />
          </IconButton>
        </CustomTooltip>
      }
    </Box>
  )
}

function CustomInput({ classes, field, errors, model, handleChange, internalHandleChange, action, disabled, noLabel, tabIndex }) {
  return (
    <FormControl
      fullWidth
      variant="outlined"
      id={field.key}
      error={!!errors[field.key]}
      required={field.required}
    >
      <Box display={'flex'} style={field.multiline ? { height: 148 } : {}}>
        {!noLabel && <InputLabel htmlFor="name" shrink={!!model[field.key] || undefined}>{field.label}</InputLabel>}
        {field.password ? (
          <OutlinedPassword
            value={model[field.key]}
            onChange={handleChange(field.key)}
            classes={{ root: classes.inputRoot }}
            inputProps={{ tabIndex }}
          />
        ) : (
          <OutlinedInput
            value={model[field.key]}
            onChange={internalHandleChange(field)}
            type={field.dataType || 'text'}
            endAdornment={field.endAdornment}
            classes={{ root: classes.inputRoot }}
            multiline={field.multiline}
            rows={field.multiline ? 6 : undefined}
            disabled={disabled}
            inputProps={{ tabIndex }}
          />
        )}
        {(field.element && field.element.type === 'button') && (
          <Button
            variant="outlined"
            onClick={action}
            style={{ marginLeft: '10px' }}
          >
            {field.element.label}
          </Button>
        )}
      </Box>
      {!!errors[field.key] &&
        <FormHelperText>{errors[field.key] || null}</FormHelperText>
      }
    </FormControl>
  )
}

function DateInput({ classes, field, errors, model, handleChange, disabled, noLabel, tabIndex }) {
  return (
    <FormControl
      fullWidth
      variant="outlined"
      id={field.key}
      error={!!errors[field.key]}
      disabled={disabled}
      required={field.required}
    >
      {!noLabel && <InputLabel shrink={true}>{field.label}</InputLabel>}
      <OutlinedInput
        value={model[field.key]}
        onChange={handleChange(field.key)}
        type="date"
        inputProps={{ tabIndex }}
        endAdornment={(
          <DatePicker
            handleChange={handleChange(field.key)}
            inputValue={model[field.key]}
          />
        )}
        classes={{ root: classes.inputRoot }}
      />
      {!!errors[field.key] &&
        <FormHelperText>{errors[field.key] || null}</FormHelperText>
      }
    </FormControl>
  )
}

function MaskedInput({ classes, field, errors, model, handleChange, noLabel, tabIndex }) {
  return (
    <FormControl
      fullWidth
      variant="outlined"
      id={field.key}
      error={!!errors[field.key]}
      required={field.required}
    >
      {!noLabel && <InputLabel>{field.label}</InputLabel>}
      <ReactInputMask
        mask={field.mask ? field.mask : null}
        value={model[field.key]}
        onChange={handleChange(field.key)}
        maskChar={null}
        inputProps={{ tabIndex }}
      >
        {(inputProps) => <OutlinedInput
          {...inputProps}
          placeholder={field.placeholder}
          endAdornment={field.endAdornment}
          classes={{ root: classes.maskInput, input: classes.maskInput }}
        />}
      </ReactInputMask>
      {!!errors[field.key] &&
        <FormHelperText>{errors[field.key] || null}</FormHelperText>
      }
    </FormControl>
  )
}

function OtherInput({ field, model, handleChange, internalHandleChange, errors = {}, action, wrapperItem, disabled, boxProps }) {
  const classes = useStyles({ wrapperItem });

  const otherField = {
    ...field,
    key: field.key + OTHER_FIELD,
    label: field.label + ' (Other)',
    required: false,
  }

  return (
    <Box
      display={'flex'}
      flexDirection={field.element ? 'row' : 'column'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={'100%'}
      className={clsx(
        field.wrapper && classes.inputWrapper,
        field.useWarning && classes.inputWarning,
      )}
      {...boxProps}
    >
      <CustomInput
        field={otherField}
        model={model}
        errors={errors}
        handleChange={handleChange}
        internalHandleChange={internalHandleChange}
        disabled={disabled}
        classes={classes}
        action={action}
      />
    </Box>
  )
}

const DEFAULT_TYPE = [FIELD_TYPES.INPUT, FIELD_TYPES.EMAIL, FIELD_TYPES.NUMBER]
export default function RenderInputs(props) {
  const { field, ind, label, outsideLabel, model, handleChange, errors = {}, action, wrapperItem, disabled, boxProps, tabIndex } = props
  const classes = useStyles({ wrapperItem });

  const internalHandleChange = (field) => (event) => {
    const value = event.target.value

    if (field.integer) {
      if (isNaN(value)) {
        return
      }
    }

    if (field.currency) {
      return handleChange(field.key)({ target: { value: formatCurrencyInput(value) } });
    }

    handleChange(field.key)(event);
  }

  return (
    <React.Fragment>
      {(!ind && label) && (
        <Box mb={2} display={'flex'}>
          <Typography variant="h5">{label}</Typography>
        </Box>
      )}
      {(outsideLabel && field.label) && (
        <Box mb="2px" display={'flex'}>
          <Typography>{field.label}{field.required ? '*' : ''}</Typography>
        </Box>
      )}
      <Box
        display={'flex'}
        flexDirection={field.element ? 'row' : 'column'}
        justifyContent={'space-between'}
        alignItems={'center'}
        width={'100%'}
        className={clsx(
          field.wrapper && classes.inputWrapper,
          field.useWarning && classes.inputWarning,
        )}
        {...boxProps}
      >
        {field.type === FIELD_TYPES.COUNTRY && (
          <CountryInput
            field={field}
            model={model}
            errors={errors}
            handleChange={handleChange}
            disabled={disabled}
            noLabel={outsideLabel}
            tabIndex={tabIndex}
          />
        )}

        {field.type === FIELD_TYPES.SEARCHABLE && (
          <SelectSearchableInput
            field={field}
            model={model}
            errors={errors}
            handleChange={handleChange}
            internalHandleChange={internalHandleChange}
            noLabel={outsideLabel}
            tabIndex={tabIndex}
          />
        )}

        {field.type === FIELD_TYPES.SELECT &&
          <SelectInput
            field={field}
            model={model}
            errors={errors}
            handleChange={handleChange}
            internalHandleChange={internalHandleChange}
            disabled={disabled}
            noLabel={outsideLabel}
            tabIndex={tabIndex}
          />
        }

        {DEFAULT_TYPE.includes(field.type) &&
          <CustomInput
            field={field}
            model={model}
            errors={errors}
            handleChange={handleChange}
            internalHandleChange={internalHandleChange}
            disabled={disabled}
            classes={classes}
            action={action}
            noLabel={outsideLabel}
            tabIndex={tabIndex}
          />
        }

        {field.type === FIELD_TYPES.MASKED &&
          <MaskedInput
            field={field}
            model={model}
            errors={errors}
            handleChange={handleChange}
            classes={classes}
            noLabel={outsideLabel}
            tabIndex={tabIndex}
          />
        }

        {field.type === FIELD_TYPES.DATE &&
          <DateInput
            field={field}
            model={model}
            errors={errors}
            handleChange={handleChange}
            disabled={disabled}
            classes={classes}
            noLabel={outsideLabel}
            tabIndex={tabIndex}
          />
        }

        {field.type === FIELD_TYPES.BOOLEAN &&
          <BoolInput
            field={field}
            model={model}
            errors={errors}
            handleChange={handleChange}
            noLabel={outsideLabel}
            tabIndex={tabIndex}
          />
        }

        {(field.element && field.element.type === 'input') && (
          <FormControl
            variant="outlined"
            id={field.element.key}
            error={!!errors[field.key]}
          >
            <React.Fragment>
              <InputLabel style={{ marginLeft: '10px' }} htmlFor="name" shrink={!!model[field.element.key] || undefined}>{field.element.label}</InputLabel>
              <OutlinedInput
                value={model[field.element.key]}
                onChange={handleChange(field.element.key)}
                type={field.element.dataType || 'text'}
                classes={{ root: classes.elementRoot }}
                disabled={disabled}
              />
              {!!errors[field.element.key] &&
                <FormHelperText>{errors[field.element.key] || null}</FormHelperText>
              }
            </React.Fragment>
          </FormControl>
        )}
        {(field.wrapper && wrapperItem) && (
          wrapperItem
        )}
      </Box>
      {field.allowOther &&
        <Collapse in={model[field.key] === 'Other'}>
          <OtherInput
            {...props}
            internalHandleChange={internalHandleChange}
          />
        </Collapse>
      }
    </React.Fragment>
  )
}
import React, {useEffect, useState} from 'react';
import { Box, FormHelperText, IconButton, InputLabel, TextField, Typography, FormControl, FormControlLabel } from '@material-ui/core';
import {
  DeleteIcon,
  AddIcon
} from '@app/icons'
import { makeStyles } from '@material-ui/core/styles';
import CheckboxLight from '@components/inputs/checkbox-light';
import CustomTooltip from '@components/controls/tooltip';

const useStyles = makeStyles(theme => ({
  container: {
    boxShadow: '0px 0px 9px rgb(0 0 0 / 15%)',
    padding: '16px 20px',
    borderRadius: '12px'
  },
  rootLabel: {
    position: 'relative',
    transform: 'none'
  }
}))

export default function TagsListInput({ values, onChange, label, error, allowOther, allowOtherChange, exists = false, origData }) {

  const classes = useStyles()

  const [ tag, setTag ] = useState('')

  const [ internalError, setInternalError ] = useState(null)

  const [ origValues, setOrigValues ] = useState(null)

  useEffect(() => {
    if (!origValues) {
      setOrigValues([...values])
    }
  }, [values, origValues])

  const canDeleteOptionByIndex = i =>
    !exists || !origValues?.includes(values[i])

  const onTagDelete = i => () => {
    if (canDeleteOptionByIndex(i)) {
      onChange(values.filter((tag, index) => index !== i));
    }
  }

  const onTagCreate = () => {
    setInternalError(null)
    const trimTag = tag.trim()
    if (!trimTag) {
      setInternalError('Please fill the value.')
      return
    }
    if (values.includes(trimTag)) {
      setInternalError('Same item already exists.')
      return
    }
    onChange([...values, trimTag]);
    setTag('')
  }

  return (
    <FormControl
      variant="outlined"
      fullWidth
      error={!!error}
    >
      <Box className={classes.container} display={'flex'} flexDirection={'column'}>
        <Box display={'flex'}>
          <InputLabel shrink={false} className={classes.rootLabel}>{label}</InputLabel>
        </Box>
        {/* List of existing tags */}
        <Box display={'flex'} flexDirection={'column'}>
          {values.map( (tag, iTag) => (
            <Box
              key={tag}
              display={'flex'}
              flexDirection={'space-between'}
              alignItems={'center'}
              style={{
                minHeight: '56px'
              }}
            >
              <Box display={'flex'} flexGrow={1}>
                <Typography variant={'subtitle1'}>{tag}</Typography>
              </Box>
              { canDeleteOptionByIndex(iTag) && (
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={onTagDelete(iTag)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          ))}
        </Box>
        {/* New tag input */}
        <Box display={'flex'} mt={1}>
          <Box display={'flex'} flexGrow={1}>
            <TextField
              value={tag}
              onChange={event => {
                setTag(event.target.value)
                setInternalError(null)
              }}
              label="Add new item"
              variant="outlined"
              fullWidth
              error={!!internalError}
              helperText={internalError}
            />
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            pl={3}
          >
            <IconButton
              edge="end"
              aria-label="create"
              onClick={onTagCreate}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
        <Box>
          {!!error &&
            <FormHelperText>
              {error}
            </FormHelperText>
          }
        </Box>
        <Box>
          <CustomTooltip
            title={
              (origData?.definition?.allowOther && exists)
                ? 'You cannot disable “Allow Other” option if it was enabled and saved, because it may be already used by saved clients in your portfolio.'
                : null
            }
            placement="top-end"
          >
            <FormControlLabel
              labelPlacement="end"
              label="Allow Other"
              control={<CheckboxLight />}
              onChange={allowOtherChange}
              checked={allowOther}
              disabled={origData?.definition?.allowOther && exists}
            />
          </CustomTooltip>
        </Box>
      </Box>
    </FormControl>
  )
}
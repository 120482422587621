import React from 'react';
import { Box, Typography } from '@material-ui/core';
import prepareTimestamp from '@utils/date';
import CustomChip from '@components/controls/custom-chip';
import CustomTooltip from '@components/controls/tooltip';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  versionWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 8px',
    width: '150px',
    height: '18px',
    border: `1px solid ${theme.palette.common.white}`,
    marginBottom: '2px',
    '&:hover': {
      backgroundColor: theme.palette.background.GY1,
      border: `1px solid ${theme.palette.background.GY4}`,
      borderRadius: '14px',
      cursor: 'pointer',
    },
  },
  circle: {
    content: '""',
    width: 10,
    height: 10,
    display: 'inline-block',
    borderRadius: '50%',
    marginRight: 5,
    backgroundColor: theme.palette.text.GY5,
  },
  versionText: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '8px',
  },
  active: {
    backgroundColor: theme.palette.background.GY1,
    border: `1px solid ${theme.palette.background.GY4}`,
    borderRadius: '14px',
    cursor: 'default',
  },
}))

export default function SearchVersionItem({ item, onClick, active }) {
  const { performedOn, id, acknowledged } = item;

  const classes = useStyles();

  if (id === 'no_item') {
    return (
      <Box mb="4px">
        <Typography>No history found. Try changing your filter.</Typography>
      </Box>
    )
  }
  return (
    <Box display="flex" alignItems="center">
      <Box
        className={clsx(
          classes.versionWrapper, active && classes.active
        )}
        onClick={() => !active && onClick()}
      >
        <span className={classes.circle} />
        <Typography className={classes.versionText}>
          {prepareTimestamp(performedOn, 'MM/DD/YYYY - hh:mm A')}
        </Typography>
      </Box>
      {!acknowledged &&
        <CustomTooltip title="Changes" placement="top">
          <Box ml={1}>
            <CustomChip label="C" background="#7A5B44" labelColor="#FFF" />
          </Box>
        </CustomTooltip>
      }
      {id === 'last_version' &&
        <CustomTooltip title="Latest" placement="top">
          <Box ml={1}>
            <CustomChip label="L" background="#A4A4A4" labelColor="#FFF" />
          </Box>
        </CustomTooltip>
      }
    </Box>
  )
}

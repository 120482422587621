import React from 'react';
import { SidesLayout, SidesLayoutRight } from '../../components/layout/sides-layout';
import {
  Box,
  Typography,
  Grid,
  Link
} from '@material-ui/core';

import AuthHeader from './auth-header';
import auth from '@app/auth';
import { useSnackbar } from 'notistack';

export default function ResetPassSent(props) {
  const { enqueueSnackbar } = useSnackbar();
  const urlParams = new URLSearchParams(window.location.search);
  const encodedEmail = urlParams.get('user')
  const email = atob(encodedEmail)

  const onResend = function () {
    auth.forgotPassword(email, true).then(() => {
      enqueueSnackbar('Email was successfully resent!', { variant: 'success' });
    }).catch((err) => {
      enqueueSnackbar(`Email failed to be resent! Reason: ${err.message}`, { variant: 'error' });
    })
  }

  return (
    <SidesLayout>
      <SidesLayoutRight
        header={<AuthHeader />}
        footer={
          <Typography variant={'body1'} align={'center'}>
            {'Didn’t get an email? Check your spam folder or '}
            <Link
              variant={'body1'}
              onClick={onResend}
            >
                            Resend
            </Link>
                        .
          </Typography>
        }
      >

        <Grid container justify={'center'} alignItems={'center'}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Box mb={3}>
                <Typography variant={'h1'} align={'center'}>Reset password instructions sent!</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display={'flex'} mb={2} justifyContent={'center'}>
                <Typography variant={'subtitle1'} align={'center'}>
                                    Please check your email.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

      </SidesLayoutRight>
    </SidesLayout>
  );
}
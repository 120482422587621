import Communication from '@views/protected/admin/communication';

const routes = [
  {
    path: '/communication',
    component: Communication,
    guest: false,
  },
]

export default routes;
import React, { useEffect, useState } from 'react';
import InnerHeader from '@components/layout/inner-header';
import PageLayout from '@components/layout/page-layout';
import { Box, Button } from '@material-ui/core';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { ChevronLeftIcon } from '@app/icons';
import { clientApi } from '@app/api';
import history from '@app/history';
import SelectFolder from '../components/select-folder';
import { useParams } from 'react-router-dom';

export default function CreateChooseFolder({ handleNext, clientId, initFolderId, type, steps }) {
  const [folderId, setFolderId] = useState(initFolderId || '')
  const { searchId } = useParams();
  
  useEffect(() => {
    if (!clientId) {
      if (type === 'person') {
        history.replace('/portfolio/create-person')
      } else {
        history.replace('/portfolio/create-company')
      }
    }
  }, [clientId, type])

  const onNext = (addToFolder) => async () => {
    if (folderId && addToFolder) {
      await clientApi.folder.addClient(folderId, String(clientId))
    }
    handleNext(searchId)
  }

  return (
    <PageLayout>
      <Box px={6}>
        <InnerHeader
          title="Choose folder"
          stepper={{
            currentStep: steps.currentStep,
            stepsCount: steps.stepsCount,
          }}
          buttons={
            <React.Fragment>
              <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={() => history.goBack()}>
                Back
              </ButtonWithIcon>
            </React.Fragment>
          }
        />
      </Box>
      <Box display="flex" alignItems="center" flexDirection="column" px={12}>
        <Box mt={5} width="100%">
          <SelectFolder
            folderID={folderId}
            setFolderID={setFolderId}
          />
          <Box mt={8} mb={3} display="flex" alignItems="center" flexDirection="column">
            <Button style={{ width: '208px', marginBottom: 8 }} variant="contained" onClick={onNext(true)}>
              Next
            </Button>
            <Button style={{ width: '208px' }} variant={'outlined'} onClick={onNext(false)}>
              NOT NOW
            </Button>
          </Box>
        </Box>
      </Box>
    </PageLayout>
  )
}

import React, {useState, useEffect} from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { SettingsActiveIcon, SettingsInactiveIcon } from '@app/icons';

import resolveMenu from '@app/menu';

import { useStyles } from './styles';
import clsx from 'clsx';

import history from '../../../app/history';
import {Box} from '@material-ui/core';

import {useDispatch, useSelector} from 'react-redux';
import { backgroundCheck, searchHistory } from '@store/actions';
import { goBack } from '@utils/global-search-helpers/goBack';

// eslint-disable-next-line
const setting = {
  uri: '/settings/subscription-plans',
  icons: {
    active: <SettingsActiveIcon />,
    inactive: <SettingsInactiveIcon />
  },
  text: 'Settings',
}

export default function PageDrawer(props) {
  const debug = parseInt(localStorage.getItem('debug') || 0);

  const { side, userIsAdmin } = useSelector(state => state.auth);

  useEffect(() => {
    const prepareMenu = side === 'client' 
      ? (!!debug && !userIsAdmin)
        ? [ ...resolveMenu(side, null, debug) ]
        : resolveMenu(side, userIsAdmin, null)
      : resolveMenu(side, null, null);
    setMenu(prepareMenu);
  }, [side, debug, userIsAdmin])

  const [menu, setMenu] = useState([]);

  const classes = useStyles();

  const dispatch = useDispatch();

  const handleListItemClick = (nextPath) => {
    dispatch(backgroundCheck.setDefault());
    dispatch(searchHistory.setDefault());
    localStorage.removeItem('isShowWhitelisted');
    dispatch(backgroundCheck.setShowWhitelisted(false));

    goBack();
    history.push(nextPath);
  };

  const isSelected = uri => window.location.pathname.indexOf(uri) === 0

  return (
    <Drawer
      variant="permanent"
      classes={{
        root: classes.drawerRoot,
        paper: classes.drawerPaper,
      }}
    >
      <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} height={'100%'}>
        <Box display={'flex'} flexDirection={'row'}  justifyContent={'center'}>
          <List>
            {!!menu.length && menu.map(listItem => (
              <ListItem
                button
                key={listItem.uri}
                onClick={() => handleListItemClick(listItem.uri)}
                selected={isSelected(listItem.uri)}
                className={classes.listItem}
                classes={{
                  root: classes.listItemRoot
                }}
              >
                { isSelected(listItem.uri) ?
                  listItem.icons.active
                  :listItem.icons.inactive }
                <ListItemText
                  classes={{
                    primary: clsx(classes.leftSidebarActionText,
                      isSelected(listItem.uri) && classes.leftSidebarActionTextActive)
                  }}
                  primary={listItem.text}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        {/* Settings */}
        {
          !userIsAdmin && <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
            <List>
              <ListItem
                button
                selected={isSelected(setting.uri)}
                onClick={() => handleListItemClick(setting.uri)}
                classes={{
                  root: classes.listItemRoot
                }}
              >
                {isSelected(setting.uri) ? (
                  setting.icons.active
                ) : (
                  setting.icons.inactive
                )}
                <ListItemText
                  classes={{
                    primary: clsx(classes.leftSidebarActionText,
                      isSelected(setting.uri) && classes.leftSidebarActionTextActive)
                  }}
                  primary={setting.text}
                />
              </ListItem>
            </List>
          </Box>
        }
      </Box>
    </Drawer>
  )
}
import { makeStyles } from '@material-ui/core';

const select_label_style = {
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '10px',
}

export const useStyles = makeStyles((theme) => ({
  globalSearchWrapper: {
    padding: '0 10px',
    position: 'relative',
    borderRadius: '12px',
    border: '1px solid #A4A4A4',
    marginRight: '20px',
    height: '40px',
    width: '600px',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
  },
  selectRoot: ({ selectValue }) => ({
    padding: '3px 8px 3px 14px',
    backgroundColor: selectValue?.bgColor,
    borderRadius: '10px',
    minWidth: '110px',
    '& .MuiSelect-select': {
      color: selectValue?.color,
    },
  }),
  customChip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#D5F0F0',
    borderRadius: '10px',
    minWidth: '110px',
    height: '27px',
  },
  selectLabel: {
    ...select_label_style,
  },
  menuWrapperStyle: {
    width: 130,
    border: '1px solid #A4A4A4',
    borderRadius: '10px',
  },
  menuItemStyle: {
    margin: '5px 10px',
    '&:hover': {
      opacity: 0.8
    },
    ...select_label_style
  },
  verticalLine: {
    height: 15,
    width: 2,
    backgroundColor: '#A4A4A4',
    margin: '0 5px 0 20px',
  },
  inputRoot: {
    width: '100%',
  },
  inputInput: {
    width: '100%',
    fontSize: '12px',
    lineHeight: '14px',
    color: '#656565',
  },
  buttonRoot: {
    padding: '2px',
    marginLeft: '10px',
  },
}));

import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Box, Grid, RadioGroup, FormControlLabel, Radio, Switch, makeStyles } from "@material-ui/core"; // eslint-disable-line
import TableList, { TCELL_TYPES } from '@components/lists/table-list';
import OutlinedSearch from '@components/inputs/outlined-search';
import InlineHeadBadge from '@components/badges/inline-head-badge';
import FilterWithContext from "@components/dropdowns/filter-with-context"; // eslint-disable-line
import NoResultsFound from '@components/layout/no-results-found';
import { invoice, clients } from '@app/api/admin';
import prepareTimestamp from '@utils/date';
import { Modal } from '@components/modals';
import debounce from 'awesome-debounce-promise';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  modalButtonWidth: {
    width: '160px',
  },
}));

const TABLE_COLUMNS = [
  { field: 'email', headerName: 'Email' },
  { field: 'price', headerName: 'Total Amount' },
  { field: 'creationDate', headerName: 'Invoice Date' },
  { field: 'status', headerName: 'Invoice Status' },
  { field: 'payDate', headerName: 'Payment Date' },
  // { field: 'export', headerName: 'Actions', cellType: TCELL_TYPES.ICON_BUTTON, icon: <DownloadIcon />, tooltip: 'Export Report', colProps: { align: 'left' } },
  { cellType: TCELL_TYPES.BUTTON, field: 'pay', label: 'PAY NOW', colProps: { align: 'left' } }
]

const radioGroup = [
  { value: 'date', label: 'Filter by Invoice Date' },
  { value: 'dueDate', label: 'Filter by Invoice Due Date' },
  { value: 'paymentDate', label: 'Filter by Payment Date' },
]

const switchGroup = [
  { value: 'paidInvoices', label: 'Paid Invoices', isChecked: true },
  { value: 'unpaidInvoices', label: 'Unpaid Invoices', isChecked: true },
]

const pageSize = 12;

const debouncedGetInvoices = debounce(invoice.getInvoices, 500);
export default function Invoices() {
  const classes = useStyles();
  const { side } = useSelector(state => state.auth);
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [pagesCount, setPagesCount] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [sortKey, setSortKey] = useState('creationDate'); // eslint-disable-line
  const [sort, setSort] = useState('date');
  const [filters, setFilters] = useState(switchGroup);
  const [openModal, setOpenModal] = useState(null);

  const getInvoices = async () => {
    const allInvoices = (await debouncedGetInvoices({
      pageNumber: page,
      pageSize,
      userEmail: search.trim() || null
    })).data;

    if (allInvoices) {
      const prepareInvoices = await Promise.all(allInvoices.entries.map(async el => ({
        ...el,
        email: el.userId ? (await clients.getUserById(el.userId)).data?.email : el.userResidualData?.email || 'N/A',
        price: `$${el.price}`,
        creationDate: el.creationDate ? prepareTimestamp(el.creationDate, 'MM/DD/YYYY') : 'N/A',
        payDate: el.payDate ? prepareTimestamp(el.payDate, 'MM/DD/YYYY') : 'N/A',
        isChecked: false
      })))

      setData([...prepareInvoices]);
      setPagesCount(allInvoices.pages);
      setTotalCount(allInvoices.count);
    }
  }

  useEffect(() => {
    getInvoices();
  }, [page, search]) // eslint-disable-line

  const onSearchChange = (event) => {
    setSearch(event.target.value);
  }

  const onFilterChange = (event) => {
    const changed = filters.map(el =>
      el.value === event.target.value ? ({ ...el, isChecked: event.target.checked }) : el
    );
    setFilters(changed);
  }

  const handlePay = useCallback(async (id) => {
    const res = await invoice.payInvoice(id);

    if (res.status === 200) {
      const invoice = data.find(el => el.id === id);
      enqueueSnackbar(
        `Invoice for "${invoice.email}" with total amount "${invoice.price}" generated on date "${invoice.creationDate}" was successfully marked as paid!`,
        { variant: 'success' }
      );
      setData(data.map(el =>
        el.id === id ? ({ ...el, status: res.data.status, payDate: prepareTimestamp(res.data.payDate, 'MM/DD/YYYY') }) : el
      ));
    }
    setOpenModal(null);
  }, [data, enqueueSnackbar])

  const formattedData = useMemo(() => {
    return data?.map(e => {
      const item = { ...e };

      switch (item.status) {
        case 'PAID':
          item.hiddenFields = ['pay'];
          break;

        case 'UNPAID':
          item.cellProps = {
            status: {
              style: {
                color: '#B6514C'
              }
            }
          }
          break;
        
        default:
          break;
      }

      return item;
    }) || [];
  }, [data])

  const columns = useMemo(() => {
    return side === 'admin'
      ? [
        { field: 'email', headerName: 'Email' },
        { field: 'price', headerName: 'Total Amount' },
        { field: 'creationDate', headerName: 'Invoice Date' },
        { field: 'dueDate', headerName: 'Invoice Due Date' },
        { field: 'status', headerName: 'Invoice Status' },
        { field: 'payDate', headerName: 'Payment Date' },
        { cellType: TCELL_TYPES.BUTTON, field: 'pay', label: 'PAY NOW', colProps: { align: 'left' } }
      ]
      : TABLE_COLUMNS
  }, [side])

  return (
    <Box display={'flex'} flexGrow={'1'} mt={2} flexDirection={'column'}>
      <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'}>
          <Box mr={1}>
            <InlineHeadBadge
              badgeColor={'#CAD7CA'}
              badgeValue={totalCount}
            >
              Invoices
            </InlineHeadBadge>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'center'} height={'48px'}>
          {side === 'admin' && (
            <FilterWithContext>
              <Grid container spacing={6}>
                <Grid item xs={5}>
                  <RadioGroup name="sort" value={sort} onChange={e => setSort(e.target.value)}>
                    {radioGroup.map((el, i) => (
                      <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value} - ${i}`}>
                        <FormControlLabel
                          control={<Radio />}
                          value={el.value}
                          label={el.label}
                        />
                      </Box>
                    ))}
                  </RadioGroup>
                </Grid>
                <Grid item xs={5}>
                  {filters.map((el, i) => (
                    <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value} - ${i}`}>
                      <FormControlLabel
                        control={<Switch value={el.value} checked={el.isChecked} onClick={onFilterChange} />}
                        label={el.label}
                      />
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </FilterWithContext>
          )}
        </Box>
      </Box>
      <Box display={'flex'} mb={3}>
        <OutlinedSearch
          value={search}
          onChange={onSearchChange}
          label="Search invoices"
        />
      </Box>
      {!!formattedData.length ? (
        <Grid container>
          <Grid item sm={12}>
            <TableList
              columns={columns}
              items={formattedData}
              onPageChange={(event, value) => setPage(value - 1)}
              page={page}
              pageSize={pageSize}
              pagesCount={pagesCount}
              totalCount={totalCount}
              sort={sortKey}
              handlers={{
                // export: (id) => {},
                pay: (id) => setOpenModal(id)
              }}
              maxHeight="calc(100vh - 380px)"
            />
          </Grid>
        </Grid>
      ) : (
        <Box mt={25} flexGrow={1}>
          <NoResultsFound>
            The list of invoices will appear here
          </NoResultsFound>
        </Box>
      )}
      <Modal
        open={!!openModal}
        onClose={() => setOpenModal(null)}
        title="Pay Invoice"
        mainText="Are you sure you want to mark the invoice as paid?"
        propsClassses={classes.modalButtonWidth}
        actions={
          [
            {
              type: 'main',
              label: 'No',
              action: () => setOpenModal(null),
            },
            {
              type: 'secondary',
              label: 'Yes',
              action: () => handlePay(openModal),
            },
          ]
        }
        actionsDirection="row"
      />
    </Box>
  )
}

import React, {useCallback, useEffect, useState} from 'react';
import {Box} from '@material-ui/core';
import DatePicker from '@components/calendar/date-picker';

export default function DateRangePicker(props) {
  const {startDate, setStartDate, endDate, setEndDate} = props

  const [internalStartDate, setInternalStartDate] = useState(startDate)
  const [internalEndDate, setInternalEndDate] = useState(endDate)

  useEffect(() => {
    setInternalStartDate(startDate)
    setInternalEndDate(endDate)
  }, [startDate, endDate])

  const renderDay = useCallback((date, selectedDate, dayInCurrentMonth, dayComponent) => {
    const start = new Date(internalEndDate)
    start.setHours(0,0,0,0)
    const end = new Date(internalStartDate)
    end.setHours(0,0,0,0)
    const isInside =
            (!!internalEndDate || !!internalStartDate)
            && (!internalEndDate || (date < start))
            && (!internalStartDate || (date > end))

    const isRangeBorder = date.toLocaleDateString() === start.toLocaleDateString()
            || date.toLocaleDateString() === end.toLocaleDateString()

    return isInside
      ? <Box className={'inside-range'}>
        { dayComponent }
      </Box>
      : isRangeBorder
        ? <Box className={'range-border'}>
          { dayComponent }
        </Box>
        : dayComponent
  }, [internalStartDate, internalEndDate])
  return (
    <Box display={'flex'}>
      <Box display={'flex'}>
        <DatePicker
          variant="static"
          value={internalStartDate}
          onAccept={setStartDate}
          onChange={setInternalStartDate}
          renderDay={renderDay}
          maxDate={endDate || undefined}
        />
      </Box>
      <Box display={'flex'}>
        <DatePicker
          variant="static"
          value={internalEndDate}
          onAccept={setEndDate}
          onChange={setInternalEndDate}
          renderDay={renderDay}
          minDate={startDate || undefined}
        />
      </Box>
    </Box>
  )
}
import React, {useMemo, useState} from 'react';
import {getDashboardUrlByMode, getModeByUrlParam} from '../utils/modeUtil';
import {Box, Button} from '@material-ui/core';
import EntitySettingParticularHeader from './header'
import {useParams} from 'react-router-dom';
import {clientApi} from '@app/api';
import Form from './form/form';
import {useSnackbar} from 'notistack';
import history from '@app/history';
import _ from 'lodash';
import ExtraDialog from './form/dialogs/extra-dialog';

export default function Edit() {
  const {tab, filterValue, id} = useParams()
  const [data, setData] = useState(null)
  const [extraDialogOpen, setExtraDialogOpen] = useState(false)

  const [errors, setErrors] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const mode = useMemo(() => {
    return getModeByUrlParam(tab)
  }, [tab])

  const onChangeExtra = extraData => {
    setData({
      ...data,
      ...extraData
    })
    setExtraDialogOpen(false)
  }

  const onSave = async () => {
    const newRulebook = {
      ...data,
      jurisdiction: data.jurisdiction !== 'UNDEFINED'
        ? data.jurisdiction
        : tab === 'entity-by-jurisdiction'
          ? filterValue: null,
      basedOnRulebookVersionId: data.rulebookVersionId,
      clientType: data.clientType,
      documentRules: data.documentRules?.filter( c => {
        return !c.definedByRulebookVersionId || (c.definedByRulebookVersionId === data.rulebookVersionId && data.ownedBy !== 'SYSTEM')
      }).map(c => _.omit(c, ['ref'])),
      inputFields: data.inputFields
        .filter(inputField =>
          !inputField.definedByRulebookVersionId
          || (inputField.definedByRulebookVersionId === data.rulebookVersionId && data.ownedBy !== 'SYSTEM')
        )
        .map(inputField => {
          const newInputField = { ...inputField }
          if (inputField.definition.type === 'LIST') {
            delete newInputField.definition.size
          }
          if (inputField.definition.type === 'COUNTRY_LIST') {
            delete newInputField.definition.options
          }
          return newInputField
        })
    }

    if (!newRulebook.jurisdiction) {
      setExtraDialogOpen(true)
      return
    }

    if (data?.ownedBy === 'USER') {
      await clientApi.clientRulebook.createVersion(
        data.rulebookId,
        newRulebook
      )
    } else {
      await clientApi.clientRulebook.store(
        newRulebook
      )
    }

    enqueueSnackbar(`Rulebook "${data.name}" was successfully updated!`, { variant: 'success' })
    history.push(
      getDashboardUrlByMode(mode, filterValue)
    )
  }

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      <EntitySettingParticularHeader
        mode={mode}
        filterValue={filterValue}
        jurisdiction={data?.jurisdiction}
        assocType={data?.associationType}
        ownedBy={data?.ownedBy}
        id={id}
      />
      <Box display={'flex'} mt={2} style={{overflow: 'auto', maxHeight: 'calc(100vh - 300px)'}}>
        <Box display={'flex'} style={{width: '100%'}}>
          <Form
            data={data}
            setData={setData}
            errors={errors}
            setErrors={setErrors}
          />
        </Box>
      </Box>
      <Box display={'flex'} flexDirection={'row-reverse'} mt={3}>
        <Button
          style={{ width: '200px' }}
          variant={'contained'}
          onClick={onSave}
        >
          UPDATE
        </Button>
      </Box>
      <ExtraDialog
        onSave={onChangeExtra}
        onClose={() => {
          setExtraDialogOpen(false)
        }}
        open={extraDialogOpen}
      />
    </Box>
  )
}
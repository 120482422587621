import _ from 'lodash'

const entitySettingsMode = {
  JURISDICTION: 'jurisdiction',
  TYPE: 'type'
}

const entitySettingsUrlParamByMode = {
  [entitySettingsMode.JURISDICTION]: 'entity-by-jurisdiction',
  [entitySettingsMode.TYPE]: 'entity-by-type'
}

const getDashboardUrlByMode = (mode, filterValue) => {
  return filterValue
    ? `/settings/${entitySettingsUrlParamByMode[mode]}/dashboard/${filterValue}`
    : `/settings/${entitySettingsUrlParamByMode[mode]}`
}

const getParticularUrl = (mode, filterValue, action, id, step, tab) => {
  let url =  `/settings/${entitySettingsUrlParamByMode[mode]}/dashboard/${filterValue}/${action}`
  if (id) {
    url += `/${id}`
  }
  if (['create', 'clone'].indexOf(action) !== -1 && !!step) {
    url += `/${step}`
  }
  if (tab) {
    url += `/${tab}`
  }
  return url
}

const getModeByUrlParam = urlParam => {
  return _.invert(entitySettingsUrlParamByMode)[urlParam]
}

export {
  entitySettingsMode,
  entitySettingsUrlParamByMode,
  getDashboardUrlByMode,
  getModeByUrlParam,
  getParticularUrl
}
import React from  'react';
import { Grid } from '@material-ui/core';

import Fuzziness from './fuzziness';
import PersonInputs from './person-inputs';
import CompanyInputs from './company-inputs';
import SwitchLabels from './switch-labels';

import { useSelector } from 'react-redux';
import { PREMIUM, PERSON } from '../../constants';
import clsx from 'clsx';
import { useStyles } from '../styles';

export default function AdvancedSearch({ sanctions }) {
  const classes = useStyles()

  const { searchModel } = useSelector(state => state.backgroundSearch);

  return (
    <Grid container>
      <Grid item sm={4} classes={{ root: classes.personWrapper }}>
        {searchModel.searchProvider === PREMIUM && (
          <Fuzziness />
        )}
        {searchModel.type === PERSON ? (
          <PersonInputs />
        ) : (
          <CompanyInputs />
        )}
      </Grid>
      <Grid item sm={4} classes={{ root: clsx(classes.personWrapper, classes.switchActions)}}>
        <SwitchLabels sanctions={sanctions} />
      </Grid>
    </Grid>
  )
}

import React, { useState, useRef, useEffect } from 'react';
import { Box, FormControlLabel, Slide, Switch, Typography } from '@material-ui/core';
import CustomButton from '@views/common/button';
import FilterWithContext from '@components/dropdowns/filter-with-context';

import { clientApi } from '@app/api';
import { useDispatch, useSelector } from 'react-redux';
import { searchHistory, backgroundCheck, paginations } from '@store/actions';
import { prepareProfilesFetchData, profilesPrepareDataAdvantage } from '@utils/prepare-data/prepareProfilesData';
import { prepareDataForPaginations } from '@utils/prepareDataForPaginations';
import { useLocation, useParams } from 'react-router-dom';
import VersionsPagination from '@components/pagination/versions-pagination';
import history from '@app/history';
import { isEmpty } from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import VersionHistoryList from './version-history-list';

const useStyles = makeStyles((theme) => ({
  userCardinfoRoot: {
    height: 'calc(100vh - 64px)',
    overflowY: 'auto',
    position: 'fixed',
    right: 0,
    width: '310px',
    top: '65px',
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
  },
  customButtonWidth: {
    width: '210px',
  },
  fade: {
    transition: 'opacity 0.25s linear',
  },
  filterActive: {
    backgroundColor: '#7A5B44',
    '&:hover': {
      backgroundColor: '#7A5B44',
      opacity: 0.9
    }
  }
}))

const ADVANTAGE_PROVIDER = 'COMPLY_ADVANTAGE';
const initialPage = 0;
const initialQuantity = 36;

export default function HistoryRightCard({
  isShowRightCard,
  setIsShowRightCard,
  setIsResize,
  isNoResetResize,
  redirectLink,
}) {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const searchId = search.get('searchId');
  const [searchName] = useState(search.get('searchName'));
  const [searchType] = useState(search.get('searchType'));
  const { clientId } = useParams();

  const { searchVersions } = useSelector(store => store.searchHistory);
  const [pagination, setPagination] = useState({
    pageSize: 20,
    count: 0,
    pages: 0,
    pageNumber: 0,
  })
  const { pageSize, count, pages, pageNumber } = pagination;

  const { profilesVersion } = useSelector(store => store.searchHistory);
  const { isShowWhitelisted, currentSearch } = useSelector(store => store.backgroundCheck);
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({
    withChanges: false,
    withoutChanges: false,
  })

  const cardRef = useRef(null);

  const classes = useStyles();

  const SH = location.pathname.includes('/search-history/repeat-search');
  const SHU = location.pathname.includes('/search-history/update-existing-no-hits');
  const BG = location.pathname.includes('/background-check/repeat-search');
  const PF = location.pathname.includes('/portfolio/bg-check/add-monitoring-no-hits');
  const OM = location.pathname.includes('/ongoing-monitoring/add-monitoring-no-hits');

  const getLocation = () => {
    if (!!SH) {
      return 'search-history/results';
    } else if (!!SHU) {
      return `search-history/update-existing-results/${clientId}`
    } else if (!!BG) {
      return 'background-check/results';
    } else if (!!PF) {
      return `portfolio/bg-check/add-monitoring-new-search/${clientId}`;
    } else if (!!OM) {
      return `ongoing-monitoring/add-monitoring-new-search/${clientId}`;
    }
  }

  const getSearchVersions = async () => {
    let acknowledged = undefined;
    if (filter.withChanges && !filter.withoutChanges) {
      acknowledged = false;
    } else if (!filter.withChanges && filter.withoutChanges) {
      acknowledged = true; 
    }

    const res = await clientApi.searchVersion.getSearchVersions(
      searchId,
      pageNumber,
      pageSize,
      acknowledged,
    );

    if (res.status === 200) {
      const searchVersions = res.data.entries;

      // Add current search info as last version
      if (pageNumber === 0) {
        if (acknowledged !== undefined && currentSearch.acknowledged === acknowledged) {
          searchVersions.unshift({
            id: 'last_version',
            searchId: +searchId,
            performedOn: currentSearch.lastRunOn,
            acknowledged: currentSearch.acknowledged,
          })
        }
        if (acknowledged === undefined && !!searchVersions.length) {
          searchVersions.unshift({
            id: 'last_version',
            searchId: +searchId,
            performedOn: currentSearch.lastRunOn,
            acknowledged: currentSearch.acknowledged,
          })
        }
      }

      if (!searchVersions.length && acknowledged !== undefined) {
        searchVersions.push({
          id: 'no_item',
        })
      }
      dispatch(searchHistory.setSearchVersion([
        ...searchVersions,
      ]));

      setPagination({
        ...pagination,
        count: res.data.count,
        pages: res.data.pages,
      })

      const currentVersion = searchVersions.find(e => e.id === profilesVersion.id)
      if (currentVersion) {
        dispatch(searchHistory.setProfilesVersion(currentVersion))
      }
      if (profilesVersion?.searchId !== +searchId) {
        if (searchVersions.length) {
          dispatch(searchHistory.setProfilesVersion(searchVersions[0]))
        } else {
          dispatch(searchHistory.setDefault())
        }
      }
    }
  }

  useEffect(() => {
    getSearchVersions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, currentSearch, filter])

  const getProfiles = async (searchId) => {
    const isWhitelisted = isShowWhitelisted;
    const { status, data } = await clientApi.search.getProfiles(searchId, initialPage, initialQuantity, isWhitelisted);
    return { status, data: data?.entries, count: data?.count, pages: data?.pages };
  }

  const getProfilesByVersionId = async (id) => {
    const isWhitelisted = isShowWhitelisted;
    const { status, data  } = await clientApi.searchVersion.getProfilesByVersionId(id, initialPage, initialQuantity, isWhitelisted);
    return { status, data: data?.entries, count: data?.count, pages: data?.pages };
  }

  const setProfilesByVersionId = async (item) => {
    const { id, searchId } = item;
    let res;
    if (id === 'last_version') {
      res = await getProfiles(searchId);
    } else {
      res = await getProfilesByVersionId(id);
    }

    if (res.status === 200) {
      const versionId = id === 'last_version' ? '' : id;

      const changedProfiles = currentSearch.searchProvider === ADVANTAGE_PROVIDER
        ? profilesPrepareDataAdvantage(res.data, versionId, currentSearch.searchProvider)
        : prepareProfilesFetchData(res.data, versionId);

      dispatch(paginations.setCurrentItemsLength(changedProfiles.length));

      const buffer = prepareDataForPaginations(changedProfiles, 36);
      const pagesCount = res.pages;

      dispatch(backgroundCheck.setProfilesPages(pagesCount === 1 ? 0 : pagesCount));
      dispatch(paginations.setCurrentPages([0]));
      dispatch(backgroundCheck.setQuantityProfiles(res.count));
      dispatch(backgroundCheck.setSelectAllProfiles(false));
      dispatch(backgroundCheck.setChangedProfiles(buffer));

      const version = searchVersions.find(e => e.id === id);
      if (version) {
        dispatch(searchHistory.setProfilesVersion(version));
      }

      if (!isEmpty(buffer) && (!!SH || !!SHU || !!BG || !!PF || !!OM)) {
        dispatch(backgroundCheck.setChangedProfiles({}));
        const mainLocation = getLocation();
        history.push(`/${mainLocation}?searchId=${searchId}&searchName=${searchName}&searchType=${searchType}&from=repeat-search`);
      }

      if (isEmpty(buffer) && !SH && !BG) {
        redirectLink();
      }
    }
  }

  const setPageNumber = (pageNumber) => {
    setPagination({
      ...pagination,
      pageNumber,
    })
  }

  const noBubbleUp = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  const handleChangeFilter = (name) => {
    setFilter({
      ...filter,
      [name]: !filter[name]
    })

    setPagination({
      ...pagination,
      pageNumber: 0,
    })
  }

  return (
    <Box width="100%" height="100%"
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        pointerEvents: isShowRightCard ? 'auto' : 'none',
        opacity: isShowRightCard ? 1 : 0,
        zIndex: 999,
      }}
      className={classes.fade}
      onClick={() => setIsShowRightCard(false)}
    >
      <Slide direction="left" in={isShowRightCard} timeout={250} mountOnEnter unmountOnExit>
        <Box className={classes.userCardinfoRoot} ref={cardRef} onClick={noBubbleUp}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={5}
            py={3}
          >
            <Typography variant="h3">
              History
            </Typography>
            <FilterWithContext
              label="FILTER"
              filterActive={Object.values(filter).some(e => !!e) && classes.filterActive}
              align="left"
            >
              <Box>
                <Typography variant="h5">Changes</Typography>
              </Box>
              <Box mt={1}>
                <FormControlLabel
                  onClick={() => handleChangeFilter('withChanges')}
                  control={(
                    <Switch
                      value="withChanges"
                      checked={filter.withChanges}
                    />
                  )}
                  label="With Changes"
                />
              </Box>
              <Box>
                <FormControlLabel
                  onClick={() => handleChangeFilter('withoutChanges')}
                  control={(
                    <Switch
                      value="withoutChanges"
                      checked={filter.withoutChanges}
                    />
                  )}
                  label="Without Changes"
                />
              </Box>
            </FilterWithContext>
          </Box>
          <Box
            style={{ borderBottom: '2px solid #EFEFEF'}}
          ></Box>

          <Box
            mt={3}
            px={4}
            flexGrow={1}
            style={{ overflowY: 'auto' }}
          >
            <VersionHistoryList
              onSelect={setProfilesByVersionId}
            />
          </Box>
          {(count > pageSize) && (
            <Box mb={2}>
              <VersionsPagination
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                count={count}
                pages={pages}
                pageSize={pageSize}
              />
            </Box>
          )}
          <Box pb={2} display="flex" justifyContent="center">
            <CustomButton
              action={() => {
                setIsShowRightCard(!isShowRightCard);
                !isNoResetResize && setIsResize(false);
              }}
              label="CLOSE"
              customClasses="customButtonStandard"
              propsClassses={classes.customButtonWidth}
            />
          </Box>
        </Box>
      </Slide>
    </Box>
  )
}

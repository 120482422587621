import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';
import {ChevronLeftIcon, ChevronRightIcon} from '@app/icons';
import clsx from 'clsx';
import { sidebarWidth } from '@app/constants';

export const useStyles = makeStyles((theme) => ({
  sideMenu: {
    padding: '20px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'visible',
    '&.in': {
      width: `calc(${sidebarWidth}px - 40px)`,
    },
    '&.out': {
      width: 50,
      padding: 0,
      alignItems: 'center'
    }
  },
  content: {
    flexDirection: 'column',
    width: '75%',
    height: '100%',
  },
  // TABS
  tab: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '22px',
    borderRadius: '12px',
    padding: '18px',
    color: '#656565',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    marginTop: '2px',
    '&.active': {
      background: '#F6F6F6',
      color: '#000000',
      cursor: 'default'
    },
    '&.disabled': {
      color: '#C6C6C6',
      cursor: 'default'
    },
    '&:not(.active):not(.disabled):not(.noHover):hover': {
      background: '#F8F8F8',
      cursor: 'pointer'
    },
  },
  subTab: {
    display: 'flex',
    height: 38,
    alignItems: 'center',
    borderRadius: 12,
    marginTop: 4,
    width: '100%',
    boxSizing: 'border-box',
    paddingLeft: 32,
    paddingRight: 32,
    '&.active': {
      background: '#EDEDED',
      '& .MuiTypography-root': {
        fontWeight: 700
      }
    },
    '&:not(.active):hover': {
      background: '#EDEDED',
      cursor: 'pointer'
    }
  },
  collapseMenu: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '&.out': {
      marginTop: '30px',
    },
    '&.in': {
      position: 'absolute',
      top: '30px',
      right: '-15px',
    }
  },
  collapseMenuButton: {
    width: '30px',
    height: '30px',
    border: '1px solid #E7E7E7',
    boxSizing: 'border-box',
    boxShadow: '4px 0px 4px rgba(0, 0, 0, 0.02)',
    borderRadius: '20px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    background: '#FFF',
    cursor: 'pointer',
    transition: 'background 0.1s',
    '&:hover': {
      background: '#EDEDED'
    },
  }
}))

export default function SideMenuLayout({ menu, content, collapseMenu, activeMenuName }) {

  const classes = useStyles()

  const [showMenu, setShowMenu] = useState(true)

  const onToggleMenu = () => setShowMenu(!showMenu)

  return (
    <Box display={'flex'} flexGrow={1}>
      <Box className={clsx(classes.sideMenu, showMenu? 'in': 'out')} timeout={0}>
        { showMenu && menu }
        { collapseMenu &&
          <Box className={clsx(classes.collapseMenu, showMenu? 'in': 'out')} onClick={onToggleMenu}>
            { showMenu &&
                  <Box className={classes.collapseMenuButton}>
                    <ChevronLeftIcon/>
                  </Box>
            }
            { !showMenu &&
                  <React.Fragment>
                    <Box className={classes.collapseMenuButton}>
                      <ChevronRightIcon/>
                    </Box>
                    <Box alignItems={'flex-start'} style={{
                      writingMode: 'tb-rl',
                      cursor: 'pointer'
                    }}>
                      <Box mt={1} style={{textTransform: 'uppercase', transform: 'rotate(180deg)'}}>
                        <Typography variant={'h5'} style={{ wordSpacing: '5px' }}>
                          { activeMenuName }
                        </Typography>
                      </Box>
                    </Box>
                  </React.Fragment>
            }
          </Box>
        }
      </Box>
      <Box display={'flex'} flexGrow={'1'} className={classes.content}>
        { content }
      </Box>
    </Box>
  )
}
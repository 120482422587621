import React, { useEffect, useState } from 'react';
import {
  Box, Button, Collapse, FormControl, FormControlLabel, FormHelperText, InputAdornment,
  InputLabel, MenuItem, OutlinedInput, Switch, Typography
} from '@material-ui/core';
import { prepareClient } from '@dto/client';
import CustomTooltip from '@components/controls/tooltip';
import { clientApi } from '@app/api';
import parseYupErrors from '@app/yup';
import * as yup from 'yup';
import { InfoOtherIcon } from '@app/icons';
import SelectClearable from '@components/inputs/select-clearable';
import useErrorState from '@utils/errorState';


const periods = [
  { label: 'Daily', value: 1 },
  { label: 'Weekly', value: 7 },
  { label: 'Monthly', value: 30 },
]

const notifications = [
  { label: 'Email Only', value: 'email' },
  // { label: 'App Only', value: 'app' },
  // { label: 'Email and App', value: 'emailAndApp' },
]

const schema = yup.object().shape({
  timeSliceInDays: yup.number().transform(value => (isNaN(value) ? undefined : value)).label('Monitoring period').required(),
  notifications: yup.string().label('Monitoring notification').required(),
  alternativeEmail: yup.string().label('Monitoring Alternative Email').trim().email('Invalid email format').max(100),
});

export default function OngoingMonitoringSettings(props) {
  const { clientId, onFinish, client: clientProp, searchId, nextLabel, monitoringData } = props;

  const [client, setClient] = useState(clientProp || {});
  const [model, setModel] = useState({
    timeSliceInDays: 1,
    notifications: 'email',
    alternativeEmail: '',
    ongoingMonitoringId: undefined,
  });
  const [errors, setErrors] = useErrorState({});
  const [shouldMonitor, setShouldMonitor] = useState(true);

  useEffect(() => {
    if (!clientProp) {
      fetch().then()
    }
    if (!monitoringData && searchId) {
      fetchSearch()
    }
    if (monitoringData) {
      readMonitoringData(monitoringData)
    }
    }, [monitoringData]) // eslint-disable-line

  const fetch = async () => {
    const data = (await clientApi.client.show(clientId)).data;
    data.type = data.type || 'PERSON';
    setClient(prepareClient(data))
  }

  const readMonitoringData = (data) => {
    const newModel = { ...model };
    newModel.timeSliceInDays = data.timeSliceDays || model.timeSliceInDays;
    newModel.ongoingMonitoringId = data.id;

    setModel(newModel);
  }

  const fetchSearch = async () => {
    const searchData = (await clientApi.search.show(searchId)).data;

    if (searchData.ongoingMonitoringId) {
      const monitoringData = (await clientApi.search.getOngoingMonitoring(searchId)).data;
            
      const newModel = { ...model };
      newModel.timeSliceInDays = monitoringData.timeSliceDays || model.timeSliceInDays;
      newModel.ongoingMonitoringId = searchData.ongoingMonitoringId;

      setModel(newModel);
    }
  }

  const handleChange = (prop) => (event) => {
    const newModel = { ...model, [prop]: event.target.value };
    setModel(newModel);

    if (errors[prop]) {
      schema.validateAt(prop, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [prop]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [prop]: err.message });
        });
    }

  };

  const onSubmit = () => {
    if (!shouldMonitor) {
      return onFinish(model, shouldMonitor);
    }
    setErrors({});
    schema
      .validate(model, { abortEarly: false })
      .then(() => onFinish(model, shouldMonitor))
      .catch(err => setErrors(parseYupErrors(err), true));
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box>
        <Typography variant="body1">
                    Add <b>{client.name}</b> to ongoing monitoring list?
        </Typography>
      </Box>
      <Box mt={2}>
        <FormControlLabel
          control={<Switch checked={shouldMonitor} onClick={() => setShouldMonitor(!shouldMonitor)} />}
          label={shouldMonitor ? 'On' : 'Off'}
        />
      </Box>
      <Collapse in={shouldMonitor} style={{ width: '35%', minWidth: 250, padding: '0px 5px' }}>
        <Box mt={4}>
          <FormControl id="timeSliceInDays" variant="outlined" fullWidth error={errors.timeSliceInDays}>
            <InputLabel>Monitoring Period</InputLabel>
            <SelectClearable
              value={model.timeSliceInDays}
              onChange={handleChange('timeSliceInDays')}
            >
              {periods.map(option => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </SelectClearable>
            <FormHelperText>{errors.timeSliceInDays}</FormHelperText>
          </FormControl>
        </Box>
        <Box mt={1}>
          <FormControl id="notifications" variant="outlined" fullWidth error={errors.notifications}>
            <InputLabel>Monitoring Notification</InputLabel>
            <SelectClearable
              value={model.notifications}
              onChange={handleChange('notifications')}
            >
              {notifications.map(option => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </SelectClearable>
            <FormHelperText>{errors.notifications}</FormHelperText>
          </FormControl>
        </Box>
        {false &&
                <Box mt={1}>
                  <FormControl id="alternativeEmail" variant="outlined" fullWidth error={errors.alternativeEmail}>
                    <InputLabel>Monitoring Alternative Email</InputLabel>
                    <OutlinedInput
                      value={model.alternativeEmail}
                      onChange={handleChange('alternativeEmail')}
                      endAdornment={
                        <InputAdornment position="end">
                          <CustomTooltip
                            title="If someone from your team needs to receive this information, type their email here"
                            placement="top"
                          >
                            <InfoOtherIcon />
                          </CustomTooltip>
                        </InputAdornment>
                      }
                    />
                    <FormHelperText>{errors.alternativeEmail}</FormHelperText>
                  </FormControl>
                </Box>
        }
      </Collapse>
      <Box mt={5} mb={2}>
        <Button
          variant="contained"
          size="large"
          onClick={onSubmit}
        >
          {nextLabel || 'FINISH'}
        </Button>
      </Box>
    </Box>
  )
}

import {
  Slider
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export default withStyles({
  root: {
    padding: '16px 0'
  },
  marked: {
    marginBottom: 0
  },
  thumb: {
    marginLeft: 10
  },
  active: {},
  valueLabel: {
    transform: 'none',
    top: 0,
    '& > span': {
      borderRadius: '12px',
      background: '#FFFFFF',
      border: '1px solid #E7E7E7',
      boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.15)',
      transform: 'none',
      width: '29px',
      height: '29px',
      '& > span': {
        color: '#232323',
        transform: 'none',
        fontWeight: 'bold',
        fontSize: '8px',
        lineHeight: '9px',
        textAlign: 'center'
      }
    }
  },
  track: {
    display: 'none'
  },
  rail: {
    background: 'linear-gradient(90deg, rgba(27,157,226,1) 20%, rgba(189,55,26,1) 80%)'
  },
  mark: {
    display: 'none'
  },
  markLabel: {
    top: 0,
    fontWeight: 500,
    fontSize: '8px',
    lineHeight: '9px',
    textAlign: 'right',
    color: '#A4A4A4'
  },
  markActive: {

  },
})(Slider)
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Box, Typography, MenuItem } from '@material-ui/core';
import InnerHeaderControls from '@components/layout/inner-header-controls';
import InnerHeader from '@components/layout/inner-header';
import BackButton from '@views/common/back-button';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import MenuWithContext from '@components/dropdowns/menu-with-context';
import { NewGroupActiveIcon } from '@app/icons';
import { Modal } from '@components/modals';

import history from '@app/history';
import { useLocation } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router';
import AccountUsage from './account-usage';
import AccountDetails from './account-details';
import TeamMembers from './team-members';
import Subscriptions from './subscriptions';
import BillingDetails from '@components/complex/billing-details';
import Payments from '@components/complex/payments';
import { useSelector } from 'react-redux';
import { clients } from '@app/api/admin';
import { withLoggedNotFound } from '@views/protected/not-found';

const tabs = [
  {
    id: 'account-details',
    label: 'Account Details',
  },
  {
    id: 'account-usage',
    label: 'Account Usage',
  },
  {
    id: 'team-members',
    label: 'Team Members',
  },
  {
    id: 'billing-details',
    label: 'Billing Details',
  },
  {
    id: 'subscription',
    label: 'Subscription',
  },
  {
    id: 'payments',
    label: 'Payments',
  },
]

const adminUserTabs = [
  {
    id: 'account-details',
    label: 'Account Details',
  },
  {
    id: 'account-usage',
    label: 'Account Usage',
  },
  {
    id: 'subscription',
    label: 'Subscription',
  },
  {
    id: 'payments',
    label: 'Payments',
  },
]

function ViewUser ({ match, pageNotFound }) {
  const { tab } = match.params;
  const location = useLocation();
  const search = new URLSearchParams(location.search);

  const { userIsAdmin, side } = useSelector(store => store.auth);

  const [userId] = useState(+search.get('userId'));
  const [tabID, setTabID] = useState(tab || 'account-details');
  const [user, setUser] = useState(null);
  const [addInvite, setAddInvite] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [modalAction, setModalAction] = useState(null);

  const pageRoot = useRef(null);

  const headerTabs = useMemo(() => {
    return (userIsAdmin && side === 'client') ? adminUserTabs : tabs;
  }, [userIsAdmin, side])

  const getUser = () => {
    clients.getUserById(userId).then((res) => {
      const currUser = res.data;
      setUser(currUser);
    }).catch((err) => {
      pageNotFound();
    });
  }

  const onTabChange = useCallback((index) => {
    const tabID = headerTabs[index].id;
    setTabID(tabID);
    const path = `/user-management/manage-users/view-user/${tabID}/?userId=${userId}`;
    history.push(path)
  }, [userId, headerTabs])

  useEffect(() => {
    getUser();
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!!tab) {
      setTabID(tab);
    }
  }, [tab, onTabChange])

  const handleMenuAction = useCallback((type) => {
    const checked = teamData.filter(el => el.isChecked);
    setModalAction({ name: checked.map(el => `"${el.name}"`).join(', '), type, prefix: 'team members' });
  }, [teamData])

  const handleCloseModal = useCallback(() => {
    setModalAction(null);
  }, [setModalAction])

  return (
    <React.Fragment>
      <Box display={'flex'} my={2} mx={2} flexDirection={'column'}>
        <InnerHeaderControls
          controlsLeft={<BackButton wrapped />}
          controlsRight={(side === 'admin' && location.pathname.includes('/team-members')) && (
            <Box display="flex" alignItems="center" mt={'12px'} mr={5}>
              <ButtonWithIcon startIcon={<NewGroupActiveIcon />} onClick={() => setAddInvite(true)}>
                Invite New Member
              </ButtonWithIcon>
              <MenuWithContext title="More Actions" disabled={!teamData.find(el => el.isChecked)}>
                <MenuItem onClick={() => handleMenuAction('delete')}>
                  Delete
                </MenuItem>
                <MenuItem onClick={() => handleMenuAction('block')}>
                  Block
                </MenuItem>
              </MenuWithContext>
            </Box>
          )}
        />
      </Box>
      <Box display={'flex'} ml={7} mr={4} flexDirection={'column'}>
        {!!user && (<Typography variant="h4">{`${user?.name} ${user?.lastName}`}</Typography>)}
        <Box ml={-1} mb={4}>
          <InnerHeader
            onTabChange={onTabChange}
            ind={headerTabs.findIndex(tab => tab.id === tabID)}
            tabs={headerTabs}
          />
        </Box>
      </Box>

      <Box display={'flex'} mx={6} flexDirection={'column'} ref={pageRoot} style={{overflowY:'auto'}}>
        <Switch>
          <Route path="/user-management/manage-users/view-user/account-usage">
            <AccountUsage userId={userId} baseRoute="/user-management/manage-users/view-user" />
          </Route>
          <Route path="/user-management/manage-users/view-user/account-details">
            <AccountDetails userId={userId} />
          </Route>
          <Route path="/user-management/manage-users/view-user/team-members">
            <TeamMembers userId={userId} addInvite={addInvite} setAddInvite={setAddInvite} setTeamData={setTeamData} />
          </Route>
          <Route path="/user-management/manage-users/view-user/billing-details">
            <BillingDetails baseRoute="/user-management/manage-users/view-user/billing-details" userId={userId} />
          </Route>
          <Route path="/user-management/manage-users/view-user/subscription">
            <Subscriptions userId={userId} userName={`${user?.name} ${user?.lastName}`} />
          </Route>
          <Route path="/user-management/manage-users/view-user/payments">
            <Payments baseRoute="/user-management/manage-users/view-user" tab={tab} userId={userId} />
          </Route>
          <Route path="/user-management/manage-users/view-user/invoices">
            <Payments baseRoute="/user-management/manage-users/view-user" tab={tab} userId={userId} />
          </Route>

          <Redirect to={`/user-management/manage-users/view-user/${tabID}/?userId=${userId}`} />
        </Switch>

        <Modal
          open={!!modalAction}
          onClose={handleCloseModal}
          title={`${modalAction?.type} Team Member`}
          mainText={`Are you sure you want to ${modalAction?.type} ${modalAction?.prefix} ${modalAction?.name}?`}
          actions={
            [
              {
                type: 'main',
                label: 'NO',
                action: handleCloseModal,
                style: { width: 160 },
              },
              {
                type: 'secondary',
                label: 'YES',
                action: handleCloseModal,
                style: { width: 160 },
              },
            ]
          }
          actionsDirection="row"
        />
      </Box>
    </React.Fragment>
  )
}

export default withLoggedNotFound(ViewUser, false, false);
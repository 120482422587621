import React from 'react';
import InnerHeader from '@components/layout/inner-header';
import PageLayout from '@components/layout/page-layout';
import { Box } from '@material-ui/core';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { ChevronLeftIcon } from '@app/icons';
import { clientApi } from '@app/api';
import history from '@app/history';
import OngoingMonitoringSettings from '@components/complex/ongoing-monitoring-settings';
import { useParams } from 'react-router-dom';

export default function CreateSetMonitoring({ steps, handleNext }) {
  const { clientId, searchId } = useParams();

  const handleMonitoring = async (data, monitor) => {
    if (monitor) {
      if (data.ongoingMonitoringId) {
        await clientApi.ongoingMonitoring.updateOngoingMonitoring(searchId, data);
      } else {
        await clientApi.search.addSearchToMonitoring(searchId, data);
      }
    } else if (data.ongoingMonitoringId) {
      await clientApi.search.removeFromMonitoring(searchId);
    }

    handleNext()
  }

  return (
    <PageLayout>
      <Box px={6}>
        <InnerHeader
          title="Add to Monitoring"
          stepper={{
            currentStep: steps.currentStep,
            stepsCount: steps.stepsCount,
          }}
          buttons={
            <React.Fragment>
              <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={() => history.goBack()}>
                Back
              </ButtonWithIcon>
            </React.Fragment>
          }
        />
      </Box>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Box px={6} pt={8}>
          <OngoingMonitoringSettings clientId={clientId} searchId={searchId} onFinish={handleMonitoring} nextLabel="NEXT" />
        </Box>
      </Box>
    </PageLayout>
  )
}

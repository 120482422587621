import React, { useCallback, useRef, useEffect } from 'react';
import { Box, Grid, Divider, IconButton, Typography } from '@material-ui/core';
import PageLayout from '@components/layout/page-layout';
import { CloseIcon } from '@app/icons';

import GlobalSearchCard from './card';
import RegularPagination from '@components/pagination/regular-pagination';
import Scroll from '@components/scroll/scroll';

import { paginations, scroll } from '@store/actions';
import { getSearches } from '@utils/global-search-helpers/getSearches';
import { goBack } from '@utils/global-search-helpers/goBack';
import { useSelector, useDispatch } from 'react-redux';
import { globalSearch } from '@app/store/actions';
import { useStyles } from './styles';

const PAGE_SIZE = 10;

export default function GlobalSearch(props) {
  const wrapCardRef = useRef();

  const dispatch = useDispatch();
  const { data, search, searchType, pagesCount, totalCount, page } =
    useSelector((store) => store.globalSearch);
  const { currentPages } = useSelector((store) => store.paginations);
  const classes = useStyles();

  useEffect(() => {
    if (!data.length) {
      getSearches({ searchType, search, pageNumber: 0 });
    }
    // eslint-disable-next-line
  }, []);

  const handleActions = useCallback(
    async (pageNumber) => {
      await getSearches({ searchType, search, pageNumber });
    },
    [searchType, search]
  );

  const onPageChange = useCallback(
    async (event, value) => {
      const result = value - 1;

      dispatch(globalSearch.setPage(result));
      if (!!data[result]) {
        dispatch(paginations.setCurrentPages([result]));
      } else {
        await getSearches({ searchType, search, pageNumber: result });
        dispatch(paginations.setCurrentPages([result]));
      }
      dispatch(scroll.setTriggerScroll(true));
    },
    [data, searchType, search, dispatch]
  );

  const handlePagination = useCallback(
    (pages, prev, next) => {
      if (next) {
        dispatch(globalSearch.setPage(page + 1));
      } else if (prev) {
        dispatch(globalSearch.setPage(page - 1));
      }
    },
    [page, dispatch]
  );

  return (
    <PageLayout>
      <Box mx={6} height={'100%'}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={3}
          mb={2}
        >
          <Typography className={classes.title}>
            {totalCount} Search Results {!!search ? `for "${search}"` : search}
          </Typography>
          <IconButton
            classes={{ root: classes.buttonRoot }}
            onClick={() => goBack(localStorage.getItem('prevLocation'))}
          >
            <CloseIcon height={50} width={50} />
          </IconButton>
        </Box>
        <Divider />
        {!!data[currentPages[0]]?.length ? (
          <React.Fragment>
            <Box mt={1}>
              <Scroll
                customClasses={classes.scrollRoot}
                action={handleActions}
                quantityItems={totalCount}
                items={data}
                handlePagination={handlePagination}
                allPages={pagesCount}
                isLastPage={pagesCount === page + 1}
                pageSize={10}
              >
                <Grid
                  container
                  direction="column"
                  justify={'center'}
                  ref={wrapCardRef}
                  className={classes.wrapperCard}
                >
                  {data[currentPages[0]]?.map((el, i) => (
                    <GlobalSearchCard
                      key={`${i}`}
                      item={el}
                      searchType={searchType}
                    />
                  ))}
                </Grid>
              </Scroll>
            </Box>
            <RegularPagination
              page={page}
              pagesCount={pagesCount}
              pageSize={PAGE_SIZE}
              totalCount={totalCount}
              onChange={onPageChange}
            />
          </React.Fragment>
        ) : (
          <Box mt={4}>
            <Typography className={classes.title}>No results found</Typography>
            <Box mt={1}>
              <Typography className={classes.text}>
                Try using a different search term
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </PageLayout>
  );
}

const setDefault = token => ({
  type: 'SET_PAGINATION_DEFAULT'
});

const setCurrentPages = currentPages => ({
  type: 'SET_CURRENT_PAGES',
  currentPages,
});

const setCurrentItemsLength = currentItemsLength => ({
  type: 'SET_CURRENT_ITEMS_LENGTH',
  currentItemsLength,
});

const paginations = {
  setDefault,
  setCurrentPages,
  setCurrentItemsLength,
}

export default paginations;

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import {CheckboxColorCheckIcon} from '@app/icons';
import {SvgIcon} from '@material-ui/core';

const styledBy = (property) => (props) => props[property];
const styles = {
  root: {
    '& .MuiIconButton-label': {
      background: styledBy('value'),
      width: '18px',
      height: '18px',
      border: '1px solid #A4A4A4',
      borderRadius: '8px'
    }
  },
  checked: {},
};
const GreenRadio = withStyles(styles)((props) => <Radio color="default" {...props} />);

export default function ColorRadioGroup(props) {

  return (
    <React.Fragment>
      {props.colors.map(color =>
        <GreenRadio
          key={color}
          checked={props.value === color}
          onChange={props.onChange}
          value={color}
          checkedIcon={<CheckboxColorCheckIcon width={10} height={10}/>}
          icon={<SvgIcon/>}
        />
      )}
    </React.Fragment>
  );
}
const setDefault = token => ({
  type: 'SET_ENTITY_MANAGMENT_DEFAULT'
});

const setDocumentsData = data => ({
  type: 'SET_DOCUMENTS_DATA',
  data,
});

const updateRulebooksScopeData = payload => ({
  type: 'UPDATE_DOCUMENTS_DATA',
  payload,
});

const entityManagement = {
  setDefault,
  setDocumentsData,
  updateRulebooksScopeData,
}

export default entityManagement;

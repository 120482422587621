import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input'
import { InfoBrownOtherIcon, ErrorIcon } from '@app/icons';
import CustomChip from '@components/controls/custom-chip';
import { subscriptionPlans } from '@app/api/client';
import history from '@app/history';
import { isValidEmail } from '@utils/textUtil';

const useStyles = makeStyles((theme) => ({
  label: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: '#656565',
    marginLeft: '16px'
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '20px',
    color: '#656565',
    marginLeft: '30px'
  },
  errorWrapper: props => ({
    width: '395px',
    maxHeight: !props.formError && '90px',
    minHeight: !props.formError && '90px',
    height: !props.formError && '90px',
    backgroundColor: '#F8EEEE',
    borderRadius: '12px',
    padding: '15px'
  }),
  errorText: props => ({
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#232323',
    marginLeft: '11px',
    marginTop: props.formError && '3px'
  }),
  linkified: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.palette.text.LB3,
    cursor: 'pointer'
  },
  chip: {
    height: '20px',
    background: '#EEE9E7'
  },
  chipInputRoot: {
    '& .MuiOutlinedInput-root': {
      padding: '14px',
    },
    '& .MuiOutlinedInput-inputAdornedStart': {
      padding: '0 0 0 6px',
      marginTop: '12px'
    }
  },
  deleteIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'red'
    }
  },
  chipRoot: {
    marginLeft: '5px',
    marginTop: '10px'
  }
}))

export default function AddForm({ model, handleChange, errors, setData, formError, setFormError }) {
  const classes = useStyles({ formError });

  const [values, setValues] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(null);

  const getCurrentPlan = useCallback(async () => {
    const currentSubscription = (await subscriptionPlans.getSubscriptionForUser()).data;

    if (currentSubscription) {
      const currentPlan = (await subscriptionPlans.getById(currentSubscription?.currentPlanId))?.data;
      setCurrentPlan(currentPlan);
    }
  }, [])

  useEffect(() => {
    getCurrentPlan();
  }, [getCurrentPlan])
  
  useEffect(() => {
    setData(values);
  }, [values, setData])

  const handleAddChip = (chip) => {
    setFormError('');
    setValues(items => ([ ...items, chip ]));
  }
  const handleDeleteChip = (chip) => {
    const newValues = values.filter(el => el !== chip);
    !newValues.length && setFormError('');
    setValues(newValues);
  }

  const isError = useMemo(() => {
    return values.length > 2 || !!formError;
  }, [values, formError])

  const chipRenderer = (data, key) => {
    const isValid = isValidEmail(data.text?.trim());
    setFormError(isValid ? '' : 'Incorrect email.');

    return (
      <CustomChip
        label={
          <span style={{ color: '#7A5B44', fontSize: '12px' }}>{data.text}</span>
        }
        onDelete={() => handleDeleteChip(data.chip)}
        background={isValid ? '#EDEDED' : '#EEE9E7'}
        propsClasses={classes.chipRoot}
      />
    )
  }

  return (
    <Box width={'425px'} my={4}>
      <ChipInput
        label="Emails"
        value={values}
        onAdd={(chip) => handleAddChip(chip)}
        chipRenderer={chipRenderer}
        variant="outlined"
        fullWidth
        error={isError}
        classes={{ chip: classes.chip, root: classes.chipInputRoot }}
      />
      {isError && (
        <Box display="flex" flexDirection="column" justifyContent="space-between" mt={3} className={classes.errorWrapper}>
          <Box display="flex" flexDirection="row" alignItems="flex-start" ml={'-5px'}>
            <ErrorIcon width={28} />
            <Typography className={classes.errorText}>
              {formError || `You have reached the limit of team members in your ${currentPlan?.name || 'plan'}.`}
            </Typography>
          </Box>
          {!formError && (
            <Typography className={classes.errorText} style={{ marginLeft: '30px' }}>
              View <span className={classes.linkified} onClick={() => history.push('/my-profile/subscription/plans')}>Subscription Plans</span>
            </Typography>
          )}
        </Box>
      )}
      <Box display="flex" flexDirection="column" mt={3}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <InfoBrownOtherIcon />
          <Typography className={classes.label}>INVITE MULTIPLE TEAM MEMBERS</Typography>
        </Box>
        <Typography className={classes.text}>You can enter up to 10 emails separated by comma.</Typography>
      </Box>
      <Box display="flex" flexDirection="column" mt={3}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <InfoBrownOtherIcon />
          <Typography className={classes.label}>Permissions</Typography>
        </Box>
        <Typography className={classes.text}>
          Your team members will have access to the entire account except subscriptions, invoicing, and billing.
        </Typography>
      </Box>
    </Box>
  )
}
import React from 'react';
import { Box, Typography, LinearProgress } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import CurrentStepHeadline from '../stepper/current-step-headline';

import clsx from 'clsx';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(96px - 24px)',
    paddingTop: 24,
    borderBottom: '2px solid #EFEFEF',
    position: 'relative',
    flexGrow: 1
  },
  stepper: {
    position: 'absolute',
    width: '100%',
    left: 0,
    bottom: 0,
    height: 2,
    marginBottom: -2,
    backgroundColor: '#EFEFEF',
  },
  stepperBar: {
    backgroundColor: '#7A5B44',
  },
}));

export default function NewInnerHeader(props) {
  const {
    title,
    buttons,
    stepper,
    propsClassesRoot,
  } = props;

  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, propsClassesRoot)} display={'flex'} flexDirection={'row'}>
      {buttons &&
        <Box alignItems={'center'} display={'flex'} ml={-1.5}>
          {buttons}
        </Box>
      }

      {title &&
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant={'h3'}>
            {title}
          </Typography>
        </Box>
      }
      {stepper &&
        <Box height={'100%'} alignItems={'center'} display={'flex'} marginLeft="auto">
          <CurrentStepHeadline {...stepper} />
        </Box>
      }
      {stepper &&
        <LinearProgress
          className={classes.stepper}
          variant="determinate"
          value={stepper.currentStep / stepper.stepsCount * 100}
          classes={{
            barColorPrimary: classes.stepperBar
          }}
        />
      }
    </Box>
  )
}
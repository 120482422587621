import React, { useEffect, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import debounce from 'awesome-debounce-promise';

import PageLayout from '@components/layout/page-layout';
import InnerHeader from '@components/layout/inner-header';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { AddActive2Icon } from '@app/icons';

import history from '@app/history';
import { adminApi } from '@app/api';
import { capitalize } from '@utils/textUtil';

import DocumentsHeader from './components/header';
import DocumentsView from './components/documentsView';

import { TABS } from './constants';
import { prepareDocumentsData } from '../../../../utils/prepare-data/prepareDocumentsData';
import CategoryDialog from './components/categoryDialog';
import TypeDialog from './components/typeDialog';

export const useStyles = makeStyles((theme) => ({
  propsTabRoot: {
    maxWidth: '300px'
  },
  header: {
    marginBottom: '16px'
  },
  customButtonModalWidth: {
    width: '180px',
  },
}));

const debouncedGetCategories = debounce(adminApi.category.getCategories, 500);
const debouncedGetTypes = debounce(adminApi.type.getTypes, 500);

export default function DocumentManagement(props) {
  const { tab } = useParams();
  const classes = useStyles();

  const [viewBy, setViewBy] = useState('category');
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('all');

  const [page, setPage] = useState(0);
  const [pageSize] = useState(12);
  const [pagesCount, setPagesCount] = useState(1);

  const [categoryData, setCategoryData] = useState([]);
  const [typeData, setTypeData] = useState([]);
  const [catTotalCount, setCatTotalCount] = useState(0);
  const [typeTotalCount, setTypeTotalCount] = useState(0);

  const [tabID, setTabID] = useState(tab || 'system');
  const [openModal, setOpenModal] = useState('');

  const onTabChange = (index) => {
    const tabID = TABS[index].id;
    setTabID(tabID);
    history.push(`/document-management/${tabID}`)
  }

  useEffect(() => {
    getTotalCount();
    if (viewBy !== 'category') fetchCategories(true);
  }, []) // eslint-disable-line

  useEffect(() => {
    if (viewBy === 'type') {
      fetchTypes();
    } else {
      fetchCategories();
    }
  }, [viewBy, tabID, page, search]) // eslint-disable-line

  // Fetch minimum amount just to get the total count
  const getTotalCount = async () => {
    const resCategories = await adminApi.category.getCategories({
      ownedBy: tabID.toUpperCase(),
      pageNumber: 0,
      pageSize: 1,
      name: search?.trim()
    });

    const resTypes = await adminApi.type.getTypes({
      ownedBy: tabID.toUpperCase(),
      pageNumber: 0,
      pageSize: 1,
      name: search?.trim()
    });

    setCatTotalCount(resCategories.data.count);
    setTypeTotalCount(resTypes.data.count);
  }

  const fetchCategories = async (fetchAll) => {
    const res = await debouncedGetCategories({
      ownedBy: tabID.toUpperCase(),
      pageNumber: fetchAll ? 0 : page,
      pageSize: fetchAll ? 10000 : pageSize,
      name: search?.trim()
    });

    const categories = prepareDocumentsData(res.data.entries);

    const getTypes = categories.map(category => adminApi.type.getTypes({ categoryId: category.id }));
    const types = await Promise.all(getTypes);

    for (let index = 0; index < categories.length; index++) {
      const category = categories[index];
      const type = types[index];

      category.types = type.data.entries;
    }

    setCategoryData(categories);
    setPagesCount(res.data.pages)
    setCatTotalCount(res.data.count);
  }

  const fetchTypes = async () => {
    const res = await debouncedGetTypes({
      ownedBy: tabID.toUpperCase(),
      pageNumber: page,
      pageSize,
      name: search?.trim()
    });

    const types = prepareDocumentsData(res.data.entries);

    const getCategories = types.map(type => adminApi.category.getCategories({ typeId: type.id }));
    const categories = await Promise.all(getCategories);

    for (let index = 0; index < types.length; index++) {
      const type = types[index];
      const category = categories[index];

      type.categories = category.data.entries;
    }


    setTypeData(types);
    setPagesCount(res.data.pages)
    setTypeTotalCount(res.data.count);
  }

  const totalCount = {
    category: catTotalCount,
    type: typeTotalCount,
  }

  return (
    <PageLayout>
      <Box mx={6} height="100%">
        <InnerHeader
          onTabChange={onTabChange}
          ind={TABS.findIndex(tab => tab.id === tabID)}
          tabs={TABS}
          propsTabRoot={classes.propsTabRoot}
          buttons={tabID === 'system' && (
            viewBy === 'type' ? (
              <ButtonWithIcon
                startIcon={<AddActive2Icon />}
                onClick={() => setOpenModal(viewBy)}
              >
                Add Document {capitalize(viewBy)}
              </ButtonWithIcon>
            ) : (
              <React.Fragment>
                <ButtonWithIcon
                  startIcon={<AddActive2Icon />}
                  onClick={() => setOpenModal(viewBy)}
                >
                  Add Document {capitalize(viewBy)}
                </ButtonWithIcon>
                <ButtonWithIcon
                  startIcon={<AddActive2Icon />}
                  onClick={() => setOpenModal('type')}
                >
                  Add Document Type
                </ButtonWithIcon>
              </React.Fragment>
            )
          )}
        />

        <Box display={'flex'} flexDirection={'column'} height={'calc(100% - 100px)'}>
          <DocumentsHeader
            viewBy={viewBy} setViewBy={setViewBy}
            search={search} setSearch={setSearch}
            filter={filter} setFilter={setFilter}
            setPage={setPage}
            totalCount={totalCount}
          />
          <DocumentsView
            ownedBy={tabID}
            viewBy={viewBy}
            data={viewBy === 'type' ? typeData : categoryData}
            categories={categoryData}
            totalCount={totalCount[viewBy]}
            page={page} setPage={setPage}
            pagesCount={pagesCount}
            pageSize={pageSize}
            fetchData={viewBy === 'type' ? fetchTypes : fetchCategories}
          />
        </Box>
      </Box>

      <CategoryDialog
        open={openModal === 'category'}
        onClose={() => setOpenModal('')}
        fetchData={fetchCategories}
      />

      <TypeDialog
        open={openModal === 'type'}
        onClose={() => setOpenModal('')}
        fetchData={fetchTypes}
        categories={categoryData}
      />
    </PageLayout>
  )
}
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {
  Box, Grid, Button, capitalize
} from '@material-ui/core';
import FormInner from './form-inner';
import history from '@app/history';
import LinkButton from '@components/buttons/link-button';
import { useParams } from 'react-router-dom';
import {riskRatingTemplate} from '@app/api/client';
import riskRatingTemplateDto from './dto/risk-rating-template-dto';
import riskRatingDto from './dto/risk-rating-dto';
import {riskRating} from '@app/api/client';
import CustomTooltip from '@components/controls/tooltip';
import Header from './components/header'
import _ from 'lodash';
import {exportRiskRatingPDF} from '@utils/exportPDF';
import {useSnackbar} from 'notistack';
import {makeStyles} from '@material-ui/core/styles';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { EditIcon } from '@app/icons';
import EditConfirmDialog from './components/edit-confirm-dialog';
import {clientApi} from '@app/api';
import SaveWithoutChangesDialog from './components/save-without-changes-dialog';

export const useStyles = makeStyles((theme) => ({
  viewport: {
    // overflowY: 'auto',
    overflowX: 'hidden',
    /* Next few to make viewport fill container so that it always focused */
    marginLeft: -30
  },
  templateLabel: {
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#232323'
  },
  templateValue: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '18px',
    color: '#A4A4A4'
  }
}));

export default function RiskRatingForm(props) {

  const classes = useStyles()

  const clientData = props.data

  const setClientData = props.setData

  const { riskRatingId, sourceRiskRatingId } = useParams()

  const { baseUri } = props

  const [templateId, setTemplateId] = useState('')

  const [ready, setReady] = useState(false)

  const [data, setData] = useState(null)

  const [initialData, setInitialData] = useState(null)

  const [pendingEditConfirm, setPendingEditConfirm] = useState(false)

  const [pendingSaveWithoutChanges, setPendingSaveWithoutChanges] = useState(false)

  const [templates, setTemplates] = useState([])

  const isSaved = useRef(false)

  const cleanupOnLeave = async () => {
    // Unmount will tracks not saved 'from existing' flow incomplete risk rating for removal
    console.log(`sourceRiskRatingId: ${sourceRiskRatingId}, isDirty: ${isDirty}, isSaved.current: ${isSaved.current}`)
    if (!!sourceRiskRatingId && !isDirty && !isSaved.current) {
      try {
        await riskRating.remove(riskRatingId)
      } catch {
        console.error(`Failed clean up not complete risk rating ${riskRatingId}`)
      }
    }
  }

  const { enqueueSnackbar } = useSnackbar();

  const onFinish = async () => {
    history.push(`${baseUri}/finish`)
  }

  const onClose = async () => {
    await cleanupOnLeave()
    history.push(`${baseUri}`)
  }

  const onResetDraft = async () => {
    await clientApi.riskRating.remove(riskRatingId)
    setClientData({
      ...clientData,
      hasIncompleteRiskRating: false
    })
    history.push(`${baseUri}/new`)
  }

  useEffect(() => {
    fetchTemplates() //eslint-disable-line
  }, []) //eslint-disable-line

  useEffect(() => {
    if(templates?.length) {
        fetchRiskRating() //eslint-disable-line
    }
  }, [templates]) //eslint-disable-line

  const fetchRiskRating = () => {
    riskRating.show(riskRatingId)
      .then(({data}) => {
        const parsedData = riskRatingDto.read(data)
        setData(parsedData)
        // store initial data to track changes
        setInitialData(parsedData)
        setTemplateId(templates.find(t => t.templateName === parsedData.templateName)?.templateId)
      })
  }

  const isDirty = useMemo(() =>
    !_.isEqual(
      _.omit(initialData, ['riskRating', 'nextReview']),
      _.omit(data, ['riskRating', 'nextReview'])
    ),
  [ data, initialData ]
  )

  const fetchTemplates = () => {
    return riskRatingTemplate.index()
      .then(({data}) => {
        setTemplates(
          data.map(riskRatingTemplateDto.read)
            .sort( (a, b) => a.templateName.localeCompare(b.templateName))
        )
      })
  }

  const validate = (dataToValidate, needScrollToError = false) => {
    dataToValidate.errors = {}
    if(!dataToValidate.items.some(item => !!item.error)) {
      if(!data.riskRating) {
        dataToValidate.errors.riskRating = 'Missing risk rating'
      } else if (!data.nextReview) {
        dataToValidate.errors.nextReview = 'Missing next review date'
      }
      if(!dataToValidate.items.some(item => !item.excluded)) {
        dataToValidate.errors.general = 'At least one question must be included'
      }
    }
    dataToValidate = validateItems(dataToValidate, needScrollToError)
    return dataToValidate
  }

  const validateItems = (dataToValidate, needScrollToError = false) => {
    const dataClone = _.cloneDeep(dataToValidate)
    let valid = true
    dataClone.items = dataClone.items.map(item => {
      let hasError = false
      let missed = []
      if( !item.excluded ) {
        if (['text', 'select'].includes(item.type) && (_.isNil(item.value) || item.value.trim() === '')) {
          hasError = true
          missed.push('answer')
        }
        if (item.riskValue === null) {
          hasError = true
          missed.push('risk value')
        }
        if (item.weightValue === null) {
          hasError = true
          missed.push('weight')
        }
      }
      valid = valid && !hasError
      return {
        ...item,
        error: hasError? `Missing ${missed.join(', ')}`: null
      }
    })
    if(needScrollToError) {
      scrollToError(dataClone)
    }
    return dataClone
  }

  const handleApiValidationErrors = (errorsObj) => {
    if(!errorsObj){
      return
    }
    const errorsIndexed = {}
    for (const [key, value] of Object.entries(errorsObj)) {
      const matches = key.match(/\[(.*?)\]/)
      if(matches) {
        const questionId = matches[1]
        errorsIndexed[questionId] = capitalize(value)
      }
    }
    if(Object.keys(errorsIndexed).length) {
      const dataClone = _.cloneDeep(data)
      dataClone.items = dataClone.items.map(item => ({
        ...item,
        error: errorsIndexed?.[item.id] || null
      }))
      setData(dataClone)
      scrollToError(dataClone)
    }
  }

  const hasAnyError = dataToValidate =>
    dataToValidate.items.some(item => !!item.error)
        || Object.keys(dataToValidate.errors || {}).length

  const onConditionalSave = async () => {
    if (sourceRiskRatingId && !isDirty) {
      setPendingSaveWithoutChanges(true)
    } else {
      await onSave()
    }
  }

  const onSave = async () => {
    const validatedData = validate(data, true)
    if(hasAnyError(validatedData)) {
      setPendingSaveWithoutChanges(false)
      setData(validatedData)
      await riskRating.update(
        riskRatingId,
        riskRatingDto.write({
          ...data,
          isDraft: true
        })
      )
    } else {
      try {
        const {data:newData} = await riskRating.update(
          riskRatingId,
          riskRatingDto.write({
            ...data,
            isDraft: false
          })
        )
        isSaved.current = true
        setClientData({
          ...clientData,
          risk_score: newData?.jsonData?.userInput?.riskRating,
          next_review_at: newData?.jsonData?.userInput?.nextReview,
          last_review_at: newData?.dateOfReview,
          hasIncompleteRiskRating: false
        })
        onFinish()
      } catch ({response}) {
        setPendingSaveWithoutChanges(false)
        handleApiValidationErrors(response?.data?.errorMessages)
      }
    }
  }

  const onExport = async () => {
    await riskRating.update(
      riskRatingId,
      riskRatingDto.write({
        ...data,
        isDraft: true
      })
    )
    await exportRiskRatingPDF({riskRatingId})
    enqueueSnackbar('The report was successfully exported!', { variant: 'success' });
  }

  const scrollToError = (dataValue) => {
    const itemWithError = dataValue?.items.find(item => !!item.error)
    if (itemWithError) {
      itemEls.current[itemWithError.id].scrollIntoView()
    } else if (Object.keys(dataValue.errors).length) {
      const firstKey = Object.keys(dataValue.errors).shift()
      //itemEls.current[firstKey].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
      const target = itemEls.current[firstKey]
      const targetOffset = target.offsetTop - target.parentNode.offsetTop
      viewportRef.current.scroll({top: targetOffset, behavior: 'smooth'})
    }
  }

  const onSaveDraft = async () => {
    try {
      await riskRating.update(
        riskRatingId,
        riskRatingDto.write({
          ...data,
          isDraft: true
        })
      )
      isSaved.current = true
      setClientData({
        ...clientData,
        hasIncompleteRiskRating: true
      })
      onClose()
    } catch ({response}) {
      handleApiValidationErrors(response?.data?.errorMessages)
    }
  }

  const itemEls = useRef({})

  const viewportRef = useRef()

  const setItemEl = useCallback((id, el) => {
    itemEls.current[id] = el
  }, [])

  const setInternalData = newData => {
    if( hasAnyError(newData) ) {
      newData = validate(newData)
    }
    setData(newData)
  }

  return (
    <React.Fragment>
      <Grid container justify={'center'}>
        <Grid item xs={7}>
          <Header clientData={clientData} baseUri={baseUri} onClose={onClose}/>
        </Grid>
      </Grid>

      <Box
        // maxHeight="calc(100vh - 360px)"
        className={classes.viewport}
        ref={viewportRef}
        id={'viewport'}
        px={2}
      >
        <Grid container justify={'center'}>
          <Grid item xs={7}>
            <Box
              display={'flex'}
              flexGrow={1}
              flexDirection={'column'}
            >
              { !!templateId &&
                <Box display={'flex'}>
                  <Box display={'flex'} flexGrow={1} flexDirection={'column'}>
                    <Box display={'flex'} className={classes.templateLabel}>
                            Template
                    </Box>
                    <Box display={'flex'} className={classes.templateValue}>
                      {templates.find(t => t.templateId === templateId)?.templateName}
                    </Box>
                  </Box>
                  <Box display={'flex'}>
                    <ButtonWithIcon startIcon={<EditIcon />} onClick={() => {
                      setPendingEditConfirm(true)
                    }}>
                      Change Template
                    </ButtonWithIcon>
                  </Box>
                </Box>
              }
              { !!templateId &&
                <FormInner {...{data, setData: setInternalData, ready, setReady, setItemEl}}/>
              }
              <Box display={'flex'} justifyContent={'center'}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} my={3}>
                  <Box display={'flex'}>
                    <CustomTooltip
                      title={
                        (!templateId || !ready)
                          && 'Before finishing risk rating select a template, set the answer, risk value, weight for all the questions which are not excluded, set risk rating and next review date'}
                      placement="top"
                    >
                      <Box>
                        <Button
                          style={{ width: '250px' }}
                          variant={'contained'}
                          onClick={onConditionalSave}
                        >
                          FINISH
                        </Button>
                      </Box>
                    </CustomTooltip>
                  </Box>
                  <Box display={'flex'} my={1}>
                    {/*<CustomTooltip*/}
                    {/*  title={*/}
                    {/*    (!templateId || !ready)*/}
                    {/*      && 'Before exporting report select a template, answer questions, setup risk value and weight'}*/}
                    {/*  placement="top"*/}
                    {/*>*/}
                    <Box>
                      <Button
                        style={{ width: '250px' }}
                        variant={'outlined'}
                        onClick={onExport}
                        //disabled={!templateId || !ready}
                      >
                          EXPORT REPORT
                      </Button>
                    </Box>
                    {/*</CustomTooltip>*/}
                  </Box>
                  <Box display={'flex'}>
                    <CustomTooltip
                      title={!templateId && 'Before saving draft please select a template'}
                      placement="top"
                    >
                      <Box>
                        <LinkButton
                          style={{ width: '250px' }}
                          onClick={onSaveDraft}
                          disabled={!templateId}
                        >
                          SAVE DRAFT
                        </LinkButton>
                      </Box>
                    </CustomTooltip>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <EditConfirmDialog
        open={pendingEditConfirm}
        onClose={() => {
          setPendingEditConfirm(false)
        }}
        onConfirm={() => {
          setPendingEditConfirm(false)
          onResetDraft()
        }}
      />
      <SaveWithoutChangesDialog
        open={pendingSaveWithoutChanges}
        onConfirm={onSave}
        onClose={() => setPendingSaveWithoutChanges(false)}
      />
    </React.Fragment>
  )
}
import React from 'react';
import { Box } from '@material-ui/core';

/**
 * To be used inside PageLayout
 * helps align anything inside it to bottom of page
 * @param {*} children 
 */
export default function InnerFooter(props) {
  const { children } = props;
  return (
    <Box display="flex" mt={'auto'}>
      {children}
    </Box>
  );
}
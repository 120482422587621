import React, { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { Box, Button, Grid, makeStyles, Typography, Divider, InputAdornment, IconButton, Menu, MenuItem, capitalize } from '@material-ui/core';
import { useParams } from 'react-router';
import moment from 'moment';

import history from '@app/history';
import { clientApi } from '@app/api';
import { ChevronLeftIcon, InfoOtherIcon, SelectAllActiveIcon, PasswordVisibleActiveIcon, SelectActiveIcon } from '@app/icons';

import Input from '@components/inputs/filled-input';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import InnerHeader from '@components/layout/inner-header';
import CheckboxLight from '@components/inputs/checkbox-light';
import RegularPagination from '@components/pagination/regular-pagination';
import CustomTooltip from '@components/controls/tooltip';
import ProgressDialog from '@components/modals/progress-dialog';
import { countryCodeNameAliasesList } from '@services/country-service';
import { prepareClient } from '@dto/client';
import { createCancelSource } from '../../../../../app/axios';
import { entityTypes } from '.';

const useStyles = makeStyles((theme) => ({
  formLabel: {
    textTransform: 'uppercase',
    fontSize: 12,
  },
  fieldWrapper: {
    marginRight: 10,
  },
  fieldWrapperXs: {
    width: 40,
    marginRight: 10,
  },
  required: {
    color: 'red'
  },
  success: {
    color: '#4E922C',
  },
  error: {
    fontStyle: 'italic',
    color: '#A4A4A4'
  },
  error2: {
    fontStyle: 'italic',
    color: '#B6514C',
    height: 20,
  },
  duplicateMsg: {
    fontStyle: 'italic',
    height: 20,
    color: '#A98320',
  },
  button: {
    padding: '10px 20px !important',
    width: 200,
  },
  duplicate: {
    '& .MuiInputBase-root': {
      backgroundColor: '#FFFCF0',
    }
  },
  iconButton: {
    padding: 1
  },
}));

const tabs = [
  {
    id: 'person',
    label: 'People',
  },
  {
    id: 'company',
    label: 'Legal Entities',
  },
]

const personFields = [
  { label: 'First Name', value: 'personFirstName', required: true, width: 15, showDuplicateTooltip: true },
  { label: 'Last Name', value: 'personLastName', required: true, width: 15 },
  { label: 'Date of Birth', value: 'personDob', tooltip: 'Supported format: MM/DD/YYYY, YYYY-MM-DD', width: 15 },
  { label: 'Residency', value: 'personCountry', required: true, tooltip: 'Uses Alpha-2 codes (US, UK, etc) or full country name', width: 15 },
  { label: 'Gender', value: 'personGender', tooltip: 'Use male, m, female, f', width: 10 },
  { label: 'External ID', value: 'externalId', tooltip: '', width: 15 },
]

const companyFields = [
  { label: 'Legal Entity Name', value: 'entityName', required: true, width: 20, showDuplicateTooltip: true },
  { label: 'Entity Type', value: 'entityType', required: true, tooltip: 'Uses Corporation, Foundation, Partnership, Trust, or Limited Liability Company', width: 20 },
  { label: 'Country', value: 'entityCountry', tooltip: 'Uses Alpha-2 codes (US, UK, etc) or full country name', width: 15 },
  { label: 'Registration Number', value: 'entityNumber', width: 20 },
  { label: 'External ID', value: 'externalId', tooltip: '', width: 15 },
]

const PreviewField = ({ field, error, value, hasDuplicate, isPerson }) => {
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      key={field.value}
      className={classes.fieldWrapper}
      style={{ width: `calc(${field.width}% - 10px)` }}
    >
      <Input
        value={isHovered ? value : error || value}
        inputProps={{ style: (!isHovered && error) ? { color: field.required ? '#B6514C' : '#A4A4A4', fontStyle: 'italic' } : {} }}
        InputProps={{
          readOnly: true,
          endAdornment: (field.showDuplicateTooltip && hasDuplicate) ?
            <InputAdornment>
              <CustomTooltip
                title="We found one or more matching client(s) in your portfolio."
                placement="top"
              >
                <InfoOtherIcon />
              </CustomTooltip>
            </InputAdornment>
            : undefined
        }}
        fullWidth
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        classes={{ root: hasDuplicate ? classes.duplicate : undefined }}
      />
    </Box>
  )
}

const DupeField = ({ id, duplicates }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null)

  if (!duplicates) return null
  if (!duplicates?.length) {
    return (
      <CustomTooltip
        title="We did not find any matching client(s) in you portfolio."
        placement="top"
      >
        <SelectActiveIcon />
      </CustomTooltip>
    )
  }

  const formatName = (rawClient) => {
    const client = prepareClient(rawClient)
    if (client.client_type === 'PERSON') {
      const info = []
      if (client.name) info.push(client.name)
      if (client.date_of_birth) info.push(client.date_of_birth)
      if (client.country) info.push(client.country)
      if (client.gender) info.push(capitalize(client.gender))
      return info.join(' | ')
    } else {
      const info = []
      if (client.name) info.push(client.name)
      if (client.country) info.push(client.country)
      if (client.registration_number) info.push(client.registration_number)
      return info.join(' | ')
    }
  }

  return (
    <Box>
      <CustomTooltip
        title="We found one or more matching client(s) in your portfolio. Click here to view the list."
        placement="top"
      >
        <IconButton className={classes.iconButton} onClick={(e) => setAnchorEl(e.currentTarget)}>
          <PasswordVisibleActiveIcon />
        </IconButton>
      </CustomTooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {_.take(duplicates, 15).map(e => (
          <MenuItem button key={e.id} onClick={() => window.open(`/portfolio/view/${e.id}`, '_blank')}>{formatName(e)}</MenuItem>
        ))}
        {duplicates.length > 15 &&
                    <MenuItem disabled>{`and ${duplicates.length - 15} more`}</MenuItem>
        }
      </Menu>
    </Box>
  )
}

export default function ImportCSVConfirm(props) {
  const {
    parsedFile, type, fieldMapping, fullName,
    omittedRows, setOmittedRows, uploadSelected
  } = props;
  const classes = useStyles();

  const { tab } = useParams();
  const [tabId, setTabId] = useState(() => {
    if (type === 'both') return 'person'
    return type
  });
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0)
  const [isLoading, setIsLoading] = useState(false);
  const [dupeCheckFailed, setDupeCheckFailed] = useState(false);
  const skipDupeCheck = useRef(false);
  const [itemsWithDuplicates, setItemsWithDuplicates] = useState({});
  const [cancelSource] = useState(() => createCancelSource());

  // Pagination
  const [tabPage, setTabPage] = React.useState({ person: 0, company: 0 })
  const [pageSize, setPageSize] = React.useState(12)
  const [pagesCount, setPagesCount] = React.useState(1)
  const [totalCount, setTotalCount] = React.useState(0)
  const onPageChange = (event, newPage) => {
    setTabPage({
      ...tabPage,
      [tabId]: newPage - 1,
    })
  }

  const page = useMemo(() => {
    return tabPage[tabId];
  }, [tabId, tabPage])

  useEffect(() => {
    if (tab) {
      setTabId(tab);
    }
  }, [tab])


  const useTabs = useMemo(() => {
    if (type === 'person') {
      return [tabs[0]];
    } else if (type === 'company') {
      return [tabs[1]];
    }
    return tabs;
  }, [type])

  const onTabChange = (index) => {
    const tabId = useTabs[index].id;
    setTabId(tabId);
    history.replace(`/portfolio/import/confirm/${tabId}`)
  }

  const fieldsToUse = useMemo(() => {
    return tabId === 'person' ? personFields : companyFields;
  }, [tabId])

  const parsedFileToUse = useMemo(() => {
    if (type !== 'both') return parsedFile;

    return parsedFile.filter(row => {
      const typeKey = fieldMapping.type;
      const typeValue = tabId === 'person' ? fieldMapping.personType : fieldMapping.companyType;

      return row[typeKey] === typeValue;
    })
  }, [parsedFile, fieldMapping, tabId, type])

  const paginatedParsedFile = useMemo(() => {
    const start = page * pageSize;
    const end = start + pageSize;
    return parsedFileToUse.slice(start, end);
  }, [page, pageSize, parsedFileToUse])

  useEffect(() => {
    if (parsedFileToUse) {
      setPagesCount(Math.ceil(parsedFileToUse.length / pageSize));
      setTotalCount(parsedFileToUse.length);
    }
  }, [parsedFileToUse, pageSize])

  const isAllChecked = useMemo(() => {
    return !Object.values(omittedRows[tabId]).some(e => !!e);
  }, [omittedRows, tabId])

  const selectedRows = useMemo(() => {
    const deselectedRows =
            Object.values(omittedRows.company).filter(e => !!e).concat(
              Object.values(omittedRows.person).filter(e => !!e))

    return parsedFile.length - deselectedRows.length;
  }, [omittedRows, parsedFile])

  const errors = useMemo(() => {
    const errors = { person: {}, company: {} };

    const checkPerson = (row) => {
      if (fullName) {
        if (!row[fieldMapping.personFullName]) {
          errors.person[row.id] = true;
        } else if (row[fieldMapping.personFullName].split(' ').length < 2) {
          errors.person[row.id] = true;
        }
      } else {
        if (!row[fieldMapping.personFirstName] || !row[fieldMapping.personLastName]) {
          errors.person[row.id] = true;
        } else if (row[fieldMapping.personFirstName].length > 30 || row[fieldMapping.personLastName].length > 30) {
          errors.person[row.id] = true;
        }
      }

      if (!row[fieldMapping.personCountry]) {
        errors.person[row.id] = true;
      }
    }

    const checkCompany = (row) => {
      if (!row[fieldMapping.entityName]) {
        errors.company[row.id] = true;
      } else if (row[fieldMapping.entityName].length > 60) {
        errors.company[row.id] = true;
      }
      const entityType = row[fieldMapping.entityType];
      if (!entityType) {
        errors.company[row.id] = true;
      } else if (!entityTypes[entityType.toLowerCase()]) {
        errors.company[row.id] = true;
      }
    }

    switch (type) {
      case 'person':
        parsedFile.forEach(row => checkPerson(row))
        break;
      case 'company':
        parsedFile.forEach(row => checkCompany(row))
        break;

      case 'both':
        parsedFile.forEach(row => {
          if (row[fieldMapping.type] === fieldMapping.personType) {
            checkPerson(row);
          } else if (row[fieldMapping.type] === fieldMapping.companyType) {
            checkCompany(row);
          }
        })
        break;

      default:
        break;
    }

    return errors;
    }, [parsedFile, fieldMapping, fullName, type]) // eslint-disable-line

  const checkDuplicates = async () => {
    setDupeCheckFailed(false)
    setProgress(0)
    const clientsToCheck = parsedFile.filter(e => !errors.person[e.id] && !errors.company[e.id])
    const clientsName = [];

    if (!clientsToCheck.length) return

    setShowProgress(true)
    setIsLoading(true)

    const getPersonName = (row) => {
      if (fullName) {
        clientsName.push({ id: row.id, name: row[fieldMapping.personFullName], type: 'PERSON' })
      } else {
        const firstName = row[fieldMapping.personFirstName]
        const lastName = row[fieldMapping.personLastName]
        clientsName.push({ id: row.id, name: `${firstName} ${lastName}`, type: 'PERSON' })
        clientsName.push({ id: row.id, name: `${lastName} ${firstName}`, type: 'PERSON' })
      }
    }

    const getCompanyName = (row) => {
      clientsName.push({ id: row.id, name: row[fieldMapping.entityName], type: 'LEGAL_ENTITY' })
    }

    switch (type) {
      case 'person':
        clientsToCheck.forEach(row => getPersonName(row))
        break;
      case 'company':
        clientsToCheck.forEach(row => getCompanyName(row))
        break;

      case 'both':
        clientsToCheck.forEach(row => {
          if (row[fieldMapping.type] === fieldMapping.personType) {
            getPersonName(row);
          } else {
            getCompanyName(row);
          }
        })
        break;

      default:
        break;
    }

    const clientsWithDuplicates = {}
    const INCREMENT = 50
    for (let index = 0; index < clientsName.length; index += INCREMENT) {
      if (skipDupeCheck.current) return;
      const names = clientsName.slice(index, index + INCREMENT);
      let duplicates
      try {
        duplicates = (await clientApi.client.duplicatesCheck(names.map(e => e.name), cancelSource.token)).data
        if (skipDupeCheck.current) return;
      } catch (error) {
        setIsLoading(false)
        setDupeCheckFailed(true)
        return
      }

      names.forEach(({ id, name, type }) => {
        const clientDuplicates = duplicates.filter(dupeClient => {
          if (dupeClient.clientType !== type) return false
          if (type === 'PERSON') {
            return `${dupeClient.firstName} ${dupeClient.lastName}`.toLowerCase() === name.toLowerCase()
          } else {
            return dupeClient.name.toLowerCase() === name.toLowerCase()
          }
        })
        if (!clientsWithDuplicates[id]) {
          clientsWithDuplicates[id] = []
        }
        clientsWithDuplicates[id] = clientsWithDuplicates[id].concat(clientDuplicates)
      })

      // progress percent for progress bar
      let progress = ((index + INCREMENT) / clientsName.length) * 100
      progress = progress > 100 ? 100 : progress
      setProgress(Math.floor(progress))
    }

    setIsLoading(false)
    setItemsWithDuplicates(clientsWithDuplicates)
  }

  const cancelDupeCheck = () => {
    setShowProgress(false);
    skipDupeCheck.current = true;
    setItemsWithDuplicates({})
    cancelSource.cancel()
  }

  // check for duplicates
  useEffect(() => {
    checkDuplicates()
    // eslint-disable-next-line
    }, [errors])

  const handleCloseDupeCheck = () => {
    if (dupeCheckFailed) {
      checkDuplicates()
    } else {
      setShowProgress(false)
    }
  }

  const isPageChecked = useMemo(() => {
    return paginatedParsedFile.every(e => !omittedRows[tabId][e.id])
  }, [omittedRows, tabId, paginatedParsedFile])

  const isPageUnchecked = useMemo(() => {
    return paginatedParsedFile.every(e => !!omittedRows[tabId][e.id])
  }, [omittedRows, paginatedParsedFile, tabId])

  const handlePageCheck = () => {
    const rows = paginatedParsedFile.map(e => e.id);
    const newOmittedRows = {
      ...omittedRows,
      [tabId]: {
        ...omittedRows[tabId]
      },
    };
    rows.forEach(e => {
      newOmittedRows[tabId][e] = isPageChecked
    })
    setOmittedRows(newOmittedRows);
  }

  const handleAllCheck = () => {
    if (!isAllChecked) {
      setOmittedRows({ ...omittedRows, [tabId]: {} })
    } else {
      const rows = parsedFileToUse.map(e => e.id);
      const newOmittedRows = {
        ...omittedRows,
        [tabId]: {},
      };
      rows.forEach(row => newOmittedRows[tabId][row] = true)
      setOmittedRows(newOmittedRows);
    }
  }

  const getRowValue = (row, field) => {
    if (!row) return [false, ''];
    const value = row[fieldMapping[field]];
    if (!value) {
      if (!['personFirstName', 'personLastName', 'personCountry', 'entityName', 'entityType'].includes(field)) {
        return [false, ''];
      }
    }

    switch (field) {
      case 'personFirstName':
        let firstName = value || '';
        if (fullName) {
          firstName = row[fieldMapping.personFullName];
          firstName = firstName ? firstName.split(' ')[0] : '';
        }

        if (firstName.length > 30) {
          return ['Must be under 30 characters', firstName];
        }

        if (!firstName) {
          return ['Must be filled', ''];
        }

        return [false, firstName];

      case 'personLastName':
        let lastName = value;
        if (fullName) {
          lastName = row[fieldMapping.personFullName];
          lastName = lastName ? lastName.split(' ').slice(1).join(' ') : '';
        }

        if (lastName.length > 30) {
          return ['Must be under 30 characters', lastName];
        }

        if (!lastName) {
          return ['Must be filled', ''];
        }

        return [false, lastName];

      case 'entityName':
        let entityName = value;

        if (!entityName) {
          return ['Must be filled', ''];
        }

        if (entityName.length > 60) {
          return ['Must be under 60 characters', entityName];
        }

        return [false, entityName];

      case 'entityType':
        let entityType = value;

        if (!entityType) {
          return ['Must be filled', ''];
        }

        if (!entityTypes[entityType.toLowerCase()]) {
          return ['Entity type is not supported', entityType]
        }

        return [false, entityTypes[entityType.toLowerCase()]];

      case 'entityNumber':
        let entityNumber = value;

        if (entityNumber.length > 100) {
          return ['Must be under 100 characters', entityNumber];
        }

        return [false, entityNumber];

      case 'personDob':
        let date = moment(value, 'MM/DD/YYYY').format('MM/DD/YYYY');
        if (date === 'Invalid date') {
          date = moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY');
        }
        if (date === 'Invalid date') {
          return [date, value]
        }
        return [false, date]

      case 'personCountry':
        if (!value) {
          return ['Must be filled', ''];
        }
        let strippedValue = value.replace(/ +(?= )/g, '');
        strippedValue = strippedValue.replace(/[^a-zA-Z ]/g, '');
        let country = countryCodeNameAliasesList[strippedValue.toLowerCase()];
        if (!country) {
          return ['Invalid code', value];
        }

        return [false, country]

      case 'entityCountry':
        let strippedValue2 = value.replace(/ +(?= )/g, '');
        strippedValue2 = strippedValue2.replace(/[^a-zA-Z ]/g, '');
        let country2 = countryCodeNameAliasesList[strippedValue2.toLowerCase()];
        if (!country2) {
          return ['Invalid code', value];
        }

        return [false, country2]

      case 'personGender':
        const genders = {
          male: 'Male',
          m: 'Male',
          female: 'Female',
          f: 'Female'
        }

        const gender = genders[value.toLowerCase()];
        if (!gender) {
          return ['Invalid format', value];
        }

        return [false, gender]

      default:
        return [false, value];
    }
  }

  const unomittedErrors = useMemo(() => {
    const personErrors = Object.entries(errors.person).filter(([rowId, value]) => !!value && !omittedRows.person[rowId]).map(e => e[0])
    const companyErrors = Object.entries(errors.company).filter(([rowId, value]) => !!value && !omittedRows.company[rowId]).map(e => e[0])
    return { person: personErrors, company: companyErrors, both: personErrors.concat(companyErrors) }
  }, [errors, omittedRows])

  const unselectErrors = () => {
    setOmittedRows({
      person: {
        ...omittedRows.person,
        ...errors.person,
      },
      company: {
        ...omittedRows.company,
        ...errors.company,
      },
    })
  }

  const hasNextTab = useMemo(() => {
    return type === 'both' && tabId === 'person'
  }, [type, tabId])

  const handleNext = () => {
    if (hasNextTab) {
      history.replace('/portfolio/import/confirm/company')
    } else {
      uploadSelected();
    }
  }

  const duplicateRows = useMemo(() => {
    return Object.entries(itemsWithDuplicates).filter(([rowId, value]) => !!value.length && !omittedRows.person[rowId] && !omittedRows.company[rowId]).map(e => e[0])
  }, [itemsWithDuplicates, omittedRows])

  return (
    <Box height="100%" px={6}>
      <ProgressDialog
        open={showProgress}
        onClose={handleCloseDupeCheck}
        isLoading={isLoading}
        title={isLoading ? 'Checking for duplicates' : 'Duplicates check completed'}
        progressText={`Checking... ${progress}%`}
        finishLabel={dupeCheckFailed ? 'Retry' : 'Next'}
        text={dupeCheckFailed ? 'An error has occured' : undefined}
        onCancel={cancelDupeCheck}
        cancelLabel="Skip Duplicate Checks"
      />

      <Box>
        <InnerHeader
          title="Confirm Clients"
          buttons={
            <React.Fragment>
              <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={() => { history.goBack() }}>
                {'Back'}
              </ButtonWithIcon>
            </React.Fragment>
          }
        />
      </Box>
      <Box>
        <InnerHeader
          onTabChange={onTabChange}
          ind={useTabs.findIndex(tab => tab.id === tabId)}
          tabs={useTabs}
          buttons={
            <React.Fragment>
              <ButtonWithIcon startIcon={<SelectAllActiveIcon />} onClick={handleAllCheck}>
                {isAllChecked ? 'Deselect All' : 'Select All'}
              </ButtonWithIcon>
            </React.Fragment>
          }
        />
      </Box>
      <Box mt={4} height={'calc(100% - 410px)'}>
        <Grid container alignItems="center">
          <Box>
            <CheckboxLight
              checked={isPageChecked}
              indeterminate={!isPageChecked && !isPageUnchecked}
              onChange={handlePageCheck}
            />
          </Box>
          <Box className={classes.fieldWrapperXs}>
            <Typography align="right" className={classes.formLabel} variant="h5">
                            ROW
            </Typography>
          </Box>
          {fieldsToUse.map(field => (
            <Box
              key={field.value}
              className={classes.fieldWrapper}
              style={{ width: `calc(${field.width}% - 10px)` }}
            >
              <Grid container alignItems="center">
                <Typography className={classes.formLabel} variant="h5">
                  {field.label}
                  {field.required && <span className={classes.required}>*</span>}
                </Typography>
                {field.tooltip &&
                                    <CustomTooltip
                                      title={field.tooltip}
                                      placement="top"
                                    >
                                      <InfoOtherIcon />
                                    </CustomTooltip>
                }
              </Grid>
            </Box>
          ))}
          <Box className={classes.fieldWrapperXs}>
          </Box>
        </Grid>
        <Box height={'calc(100% - 105px)'} style={{ overflowY: 'auto' }}>
          {paginatedParsedFile.map(row => (
            <Grid key={row.id} container alignItems="center">
              <Box>
                <CheckboxLight
                  checked={!omittedRows[tabId][row.id]}
                  onChange={() => setOmittedRows({
                    ...omittedRows,
                    [tabId]: {
                      ...omittedRows[tabId],
                      [row.id]: !omittedRows[tabId][row.id],
                    }
                  })}
                />
              </Box>
              <Box className={classes.fieldWrapperXs}>
                <Typography align="right" className={classes.formLabel} variant="h5">
                  {row.id}
                </Typography>
              </Box>
              {fieldsToUse.map(field => {
                const [error, value] = getRowValue(row, field.value);
                return (
                  <PreviewField
                    key={field.value}
                    field={field} error={error} value={value}
                    hasDuplicate={!!itemsWithDuplicates[row.id]?.length}
                    isPerson={tabId === 'person'}
                  />
                )
              })}
              <Box>
                <DupeField duplicates={itemsWithDuplicates[row.id]} />
              </Box>
            </Grid>
          ))}
        </Box>
        <Grid item xs={12}>
          <Box width={'100%'} justifyContent={'center'} mt={3} mb={1}>
            <Divider />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box width={'100%'}>
            <RegularPagination
              page={page}
              pagesCount={pagesCount}
              pageSize={pageSize}
              setPageSize={setPageSize}
              totalCount={totalCount}
              onChange={onPageChange}
            />
          </Box>
        </Grid>
      </Box>
      <Box mt={2} mb={2}>
        <Typography className={classes.success} variant="subtitle2">{selectedRows} item(s) selected</Typography>
        {!!unomittedErrors.person.length &&
                    <Typography className={classes.error2} variant="subtitle2">
                      {type === 'both' ? 'Person row(s) ' : 'Row(s) '}
                      {_.take(unomittedErrors.person, 10).join(', ')}
                      {unomittedErrors.person.length > 10 &&
                            <span>
                              {', and '}
                              <CustomTooltip
                                title={`${_.take(unomittedErrors.person, 30).join(', ')}${unomittedErrors.person.length > 30 ? `, and ${unomittedErrors.person.length - 30} more` : ''} `}
                                placement="top"
                              >
                                <u>more</u>
                              </CustomTooltip>
                            </span>
                      }
                      {' are missing required data, please deselect them before you continue with import flow.'}
                    </Typography>
        }
        {!!unomittedErrors.company.length &&
                    <Typography className={classes.error2} variant="subtitle2">
                      {type === 'both' ? 'Legal entities row(s) ' : 'Row(s) '}
                      {_.take(unomittedErrors.company, 10).join(', ')}
                      {unomittedErrors.company.length > 10 &&
                            <span>
                              {', and '}
                              <CustomTooltip
                                title={`${_.take(unomittedErrors.company, 30).join(', ')}${unomittedErrors.company.length > 30 ? `, and ${unomittedErrors.company.length - 30} more` : ''} `}
                                placement="top"
                              >
                                <u>more</u>
                              </CustomTooltip>
                            </span>
                      }
                      {' are missing required data, please deselect them before you continue with import flow.'}
                    </Typography>
        }
        {!!duplicateRows.length &&
                    <Typography className={classes.duplicateMsg} variant="subtitle2">
                      {`There are ${duplicateRows.length} selected item(s) with possible duplicates. `}
                      {'Row(s) '}
                      {_.take(duplicateRows, 10).join(', ')}
                      {duplicateRows.length > 10 &&
                            <span>
                              {', and '}
                              <CustomTooltip
                                title={`${_.take(duplicateRows, 30).join(', ')}${duplicateRows.length > 30 ? `, and ${duplicateRows.length - 30} more` : ''} `}
                                placement="top"
                              >
                                <u>more</u>
                              </CustomTooltip>
                            </span>
                      }
                      {' have matching client(s) in your portfolio.'}
                    </Typography>
        }
      </Box>
      <Box mt={4} mb={4}>
        <Grid container alignItems="center" justify="center">
          {!!unomittedErrors.both.length ?
            <Button
              variant="contained"
              size="large"
              disabled={!selectedRows}
              onClick={unselectErrors}
              className={classes.button}
            >
              {'Deselect Entries with Errors'}
            </Button>
            :
            <CustomTooltip title="Please select at least one client" placement="top" disableHoverListener={!!selectedRows}>
              <span>
                <Button
                  variant="contained"
                  size="large"
                  disabled={!selectedRows}
                  onClick={handleNext}
                  className={classes.button}
                >
                  {hasNextTab ? 'Next' : 'Import'}
                </Button>
              </span>
            </CustomTooltip>
          }
        </Grid>
      </Box>
    </Box>
  )
}

import React from 'react';
import { Box } from '@material-ui/core';
import ButtonWithIcon from '@components/buttons/button-with-icon';
// import MenuWithContext from '@components/dropdowns/menu-with-context';
import { AddActive2Icon } from '@app/icons';

const HeaderButtonsBlock = ({ openEidv }) => {
  return (
    <Box display="flex" alignItems="center">
      <ButtonWithIcon
        startIcon={<AddActive2Icon />}
        onClick={openEidv}
      >
        New eIDV
      </ButtonWithIcon>
      {/* <MenuWithContext>
        <MenuItem>
          item
        </MenuItem>
      </MenuWithContext> */}
    </Box>
  );
};

export default HeaderButtonsBlock;

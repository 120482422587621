import Portfolio from '@views/protected/client/portfolio/index';
import PortfolioCreateClient from '@views/protected/client/portfolio/create-client';
import PortfolioCreatePersonEidvStep from '@views/protected/client/portfolio/forms/person/eidv-step';
import PortfolioUpdatePerson from '@views/protected/client/portfolio/forms/person/update';
import PortfolioUpdateCompany from '@views/protected/client/portfolio/forms/company/update';
import PortfolioViewProfile from '@views/protected/client/portfolio/view';
import OngoingSearchHistory from '@views/protected/client/ongoing-monitoring/search-history';
import { withPageLayout } from '@components/layout/page-layout';
import MoveToFolder from '@views/protected/client/portfolio/other/move-to-folder';
import PortfolioRoutes from '@views/protected/client/portfolio/index/portfolio-routes';
import PortfolioMonitoringSettings from '@views/protected/client/portfolio/forms/update-monitoring';
import PortfolioPeriodicReviews from '@views/protected/client/portfolio/forms/update-periodic-reviews';
import ImportCSV from '@views/protected/client/portfolio/import-csv';
import OrganizeFolder from '@views/protected/client/portfolio/organize-folder';

const routes = [
  // Monitoring settings
  {
    path: '/portfolio/monitoring-settings/:id/:search_id',
    component: PortfolioMonitoringSettings,
    guest: false,
  },
  // Periodic reviews
  {
    path: '/portfolio/periodic-reviews/:id',
    component: PortfolioPeriodicReviews,
    guest: false,
  },
  // From view - search history
  {
    path: '/portfolio/history/:clientId',
    component: withPageLayout(OngoingSearchHistory),
    guest: false,
    baseRoute: 'portfolio'
  },
  // Bg check and add to monitoring steps
  {
    path: '/portfolio/bg-check/',
    component: PortfolioRoutes,
    guest: false,
  },
  // Batch move to folder
  {
    path: '/portfolio/:clientType/move-to-folder',
    component: MoveToFolder,
    guest: false,
  },
  // Update
  {
    path: '/portfolio/person/:id',
    component: PortfolioUpdatePerson,
    guest: false,
  },
  {
    path: '/portfolio/company/:id',
    component: PortfolioUpdateCompany,
    guest: false,
  },
  // View
  {
    path: '/portfolio/view/:id/:tab?',
    component: PortfolioViewProfile,
    guest: false,
  },
  // Create
  {
    path: '/portfolio/create-person',
    component: PortfolioCreateClient,
    guest: false,
  },
  {
    path: '/portfolio/person-eidv/:clientId',
    component: PortfolioCreatePersonEidvStep,
    guest: false,
  },
  {
    path: '/portfolio/create-company',
    component: PortfolioCreateClient,
    guest: false,
  },
  {
    path: '/portfolio/import',
    component: ImportCSV,
    guest: false,
  },
  {
    path: '/portfolio/organize/:tab?',
    component: OrganizeFolder,
    guest: false,
  },
  {
    path: '/portfolio/:tab?/:folderId?',
    component: Portfolio,
    guest: false,
  }
]

export default routes;
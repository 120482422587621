import React, {useCallback, useEffect, useState} from 'react';
import PageLayout from '@components/layout/page-layout';
import {prepareClient} from '@dto/client';
import OutlinedSearch from '@components/inputs/outlined-search';

import {
  Box,
  MenuItem,
} from '@material-ui/core';
import {
  SearchIcon
} from '@app/icons';

import DeleteDialog from '../dialogs/delete-dialog';
import InnerHeader from '@components/layout/inner-header';
import ButtonBackBreadcrumb from '@components/buttons/button-back-breadcrumb';
import history from '@app/history';
import {clientApi} from '@app/api';
import { useDispatch } from 'react-redux';
import { scroll, paginations } from '@app/store/actions';

import { makeStyles } from '@material-ui/core/styles';
import ClientView from '@components/complex/client-view';
import MenuWithContext from '@components/dropdowns/menu-with-context';
import { withLoggedNotFound } from '@views/protected/not-found';
import { getSearches } from '@utils/global-search-helpers/getSearches';
import { useLocation } from 'react-router-dom';

const CLIENTS = { value: 'Clients', color: '#FFFFFF', bgColor: '#C6C6C6' };

export const useStyles = makeStyles((theme) => ({
  scrollableContent: {
    height: 'calc(90% - 96px)',
  }
}))

function Index(props) {

  const params = props.computedMatch.params

  const id = params.id

  const classes = useStyles()
  const location = useLocation();
  const dispatch = useDispatch();

  const [data, setData] = useState({})
  const [folders, setFolders] = useState([])
  const [rootFolderId, setRootFolderId] = useState(null)
  const [pendingDelete, setPendingDelete] = useState(null)
  const [search, setSearch] = useState('')

  useEffect(() => {
    fetch()
    fetchFolders().then()
    }, []) // eslint-disable-line

  const fetch = () => {
    clientApi.client.show(id).then((res) => {
      const data = res.data;
      data.client_type = data.type || 'PERSON'
      setData(prepareClient(data))
    }).catch((err) => {
      props.pageNotFound();
    });
  }

  const fetchFolders = async () => {
    const foldersData = (await clientApi.folder.index({pageSize:1000})).data?.entries || []
    setRootFolderId(
      foldersData.find(f => f.name.toLowerCase() === 'home')?.id
    )
    setFolders(foldersData)
  }

  const onDelete = () => {
    setPendingDelete({
      type: (data?.client_type || '').toLowerCase(),
      id,
      name: data.name
    })
  }

  const getOriginUri = () => {
    const tab = data?.client_type === 'PERSON'? 'tab-persons': 'tab-companies'
    return `/portfolio/${tab}`
  }

  const goBack = () => {
    history.goBack(`${getOriginUri()}/${data.folder_id}`)
  }

  const goBackFolder = () => {
    history.push(`${getOriginUri()}/${data.folder_id}`)
  }

  const goRoot = () => {
    history.push(`${getOriginUri()}/${rootFolderId}`)
  }

  const onSearch = useCallback(async () => {
    dispatch(scroll.setTriggerScroll(true));
    dispatch(paginations.setDefault());
    
    if (!location.pathname.includes('/global-search')) {
      localStorage.setItem('prevLocation', `${location.pathname}${location.search}`);
    }

    await getSearches({ searchType: CLIENTS, search: search, pageNumber: 0 }, true);
    
    history.push(`/global-search/?value=${search}&type=${CLIENTS.value}`);

    dispatch(scroll.setTriggerScroll(false));
  }, [search, location, dispatch])

  return (
    <React.Fragment>
      <DeleteDialog
        data={pendingDelete}
        open={!!pendingDelete}
        onDeleted={() => {
          history.push('/portfolio')
        }}
        onClose={() => {
          setPendingDelete(null)
        }}
      />
      <PageLayout>
        <Box display={'flex'} pr={6}>
          <InnerHeader
            buttons={
              <React.Fragment>
                <Box display={'flex'} justifyContent={'space-between'} flexGrow={1}>
                  <Box display="flex" alignItems="center" ml={4.5}>
                    <ButtonBackBreadcrumb
                      goBack={goBack}
                      crumbs={[
                        { label: 'HOME', onClick: goRoot },
                        (data.folder_name && data.folder_name !== 'HOME') &&
                          { label: data.folder_name, onClick: goBackFolder },
                        { label: data.name }
                      ].filter(e => e)}
                    />
                  </Box>
                  <Box display={'flex'} alignItems={'center'}>
                    <Box display={'flex'} mr={3} style={{
                      width: '400px'
                    }}>
                      <OutlinedSearch
                        label={'Search for people and legal entities'}
                        value={search}
                        onChange={event => setSearch(event.target.value)}
                        onEnter={onSearch}
                        icons={<SearchIcon />}
                      />
                    </Box>
                    <MenuWithContext>
                      <MenuItem onClick={onDelete}>Delete Entity</MenuItem>
                    </MenuWithContext>
                  </Box>
                </Box>
              </React.Fragment>
            }/>
        </Box>
        <Box display={'flex'} flexDirection={'row'} flexGrow={1} className={classes.scrollableContent}>

          <ClientView folders={folders} client={data} clientId={id} baseUri={`/portfolio/view/${id}`} />

        </Box>
      </PageLayout>

    </React.Fragment>
  )
}

export default withLoggedNotFound(Index, false, true);
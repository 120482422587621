import React, {useMemo} from 'react';
import {getDashboardUrlByMode, getParticularUrl} from '../utils/modeUtil';
import ReactCountryFlag from 'react-country-flag';
import InnerHeader from '@components/layout/inner-header';
import { countriesISO } from '@services/country-service';
import history from '@app/history';
import OwnedByBadge from '@components/badges/owned-by-badge';
import {Box} from '@material-ui/core';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import {
  EditIcon
} from '@app/icons';

export default function EntitySettingParticularHeader({mode, filterValue, assocType, jurisdiction, ownedBy, id, readOnly = false}) {

  const countryData = useMemo(() => {
    return jurisdiction && countriesISO?.find(country => country.code === jurisdiction)
  }, [jurisdiction])

  const title = useMemo(() => {
    return assocType && assocType.substr(0, 1).toUpperCase() + assocType.substr(1).toLowerCase()
  }, [assocType]) //eslint-disable-line

  const onEdit = () => {
    history.push(
      getParticularUrl(mode, filterValue, 'edit', id, null, 'fields')
    )
  }

  return <InnerHeader
    fullWidth
    title={title}
    titleAbsolute={false}
    titleGrow={false}
    buttons={(
      <React.Fragment>
        <Box display={'flex'} alignItems={'center'} ml={10}>
          {ownedBy && (
            <OwnedByBadge ownedBy={ownedBy} size={'md'}/>
          )}
          {!!countryData && (
            <Box display={'flex'} alignItems={'center'} ml={2}>
              <ReactCountryFlag
                countryCode={countryData.code}
                style={{
                  fontSize: '3.5em',
                  lineHeight: '1.5em',
                }}
              />
              <Box
                display={'flex'}
                ml={2}
                style={{
                  color: '#232323',
                  fontWeight: 'bold',
                  fontSize: '17px',
                  lineHeight: '20px'
                }}
              >
                {countryData.name}
              </Box>
            </Box>
          )}
          {/*{ !!id && (*/}
          {/*  <Box*/}
          {/*    display={'flex'}*/}
          {/*    style={{*/}
          {/*      position: 'absolute',*/}
          {/*      right: 0*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <ButtonWithIcon startIcon={<AddActive2Icon />} onClick={() => {*/}
          {/*      history.push(*/}
          {/*        getParticularUrl(mode, filterValue, 'create')*/}
          {/*      )*/}
          {/*    }}>*/}
          {/*      New Category*/}
          {/*    </ButtonWithIcon>*/}
          {/*  </Box>*/}
          {/*)}*/}
        </Box>
        { readOnly
          && ownedBy === 'USER'
          && (
            <Box
              display={'flex'}
              flexGrow={1}
              flexDirection={'row-reverse'}
            >
              <ButtonWithIcon
                startIcon={<EditIcon />}
                onClick={onEdit}
              >
                Edit
              </ButtonWithIcon>
            </Box>
          )}
      </React.Fragment>
    )}
    onBack={() => {
      history.push(
        getDashboardUrlByMode(mode, filterValue)
      )
    }}
  />
}
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import {
  Box, Button, Typography, Grid
} from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import history from '@app/history';

import subscriptionTheme from './theme';
import SubscriptionPlan from './items/subscription-plan';
import Expires from './items/expires';
import PaymentMethod from './items/payment-method'; // eslint-disable-line
import BasicItem from './items/basic-item';
import { subscriptionPlans, invoices } from '@app/api/client';
import { subscription, invoice, subscriptionPlans as adminSubscriptionPlans } from '@app/api/admin';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Modal } from '@components/modals';
import prepareTimestamp from '@utils/date';
import moment from 'moment';
import CreditBar from './items/credit-bar';
import { injectAvailableCredits } from '@dto/plan';
import { InfoBrownOtherIcon } from '@app/icons'
import { minimumZero } from '@utils/numberUtil';

const useStyles = makeStyles((theme) => ({
  itemWrapper: {
    // width: 325,
    marginBottom: '20px'
  },
  wrapper: {
    width: '100%'
  },
  cardRoot: {
    // width: '414px',
    // height: '330px',
    background: '#FFFFFF',
    boxShadow: '0px 9px 26px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
    borderRadius: '12px',
    padding: '34px'
  },
  marginLeft: {
    marginLeft: '32px'
  },
  modalButtonWidth: {
    width: '160px',
  },
}));

export default function Subscription(props) {
  const { baseRoute, userId, userName } = props;
  const { side, userIsAdmin } = useSelector(store => store.auth);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [requestedPlan, setRequestedPlan] = useState(null);
  const [openModal, setOpenModal] = useState(null);

  const isAdmin = useMemo(() => {
    return (side === 'admin' || userIsAdmin);
  }, [side, userIsAdmin])

  const subscriptionPlansApi = isAdmin ? adminSubscriptionPlans : subscriptionPlans;

  const getData = useCallback(async () => {
    const currentSubscription = isAdmin
      ? (await subscription.getSubscriptionForUser(userId)).data
      : (await subscriptionPlansApi.getSubscriptionForUser()).data;

    injectAvailableCredits(currentSubscription);

    const userInvoices = isAdmin
      ?   (await invoice.getInvoices({ userId })).data
      :   (await invoices.getInvoicesForUser()).data;
    const lastInvoice = userInvoices.entries
      .filter(el => el.status === 'PAID')
      .sort((a, b) =>
        a.payDate < b.payDate ? 1 : a.payDate > b.payDate ? -1 : 0
      ).sort((a, b) =>
        a.id < b.id ? 1 : a.id > b.id ? -1 : 0
      )[0] || {};

    let renewalPlanLabel = '';
    if (currentSubscription?.currentPlanId === currentSubscription?.paidRenewalPlanId) {
      renewalPlanLabel = 'Auto-Renew';
    } else if (!!currentSubscription?.paidRenewalPlanId) {
      const renewalPlan = (await subscriptionPlansApi.getById(currentSubscription?.paidRenewalPlanId))?.data;
      renewalPlanLabel = `Renew ${renewalPlan.name}`;
    }

    const requestedPlanID = currentSubscription?.paidRenewalPlanId || currentSubscription?.requestedRenewalPlanId || currentSubscription?.requestedUpgradePlanId;
    if (requestedPlanID) {
      const requestedPlan = (await subscriptionPlansApi.getById(requestedPlanID))?.data;
      setRequestedPlan({ ...requestedPlan });
    }

    setCurrentSubscription({
      ...currentSubscription,
      lastPayment: lastInvoice.price || 0,
      renewalPlanLabel
    });
  }, [isAdmin, userId, subscriptionPlansApi])

  useEffect(() => {
    getData();
  }, [getData])

  const getPlanById = useCallback(async (currentPlanId) => {
    const res = await subscriptionPlansApi.getById(currentPlanId);

    if (res.status === 200) {
      setCurrentPlan(res.data);
    }
  }, [subscriptionPlansApi])

  useEffect(() => {
    if (!!currentSubscription?.currentPlanId) {
      getPlanById(currentSubscription?.currentPlanId);
    }
  }, [currentSubscription, getPlanById])

  const expirationDate = useMemo(() => {
    return !!currentSubscription?.expirationDate
      ? prepareTimestamp(currentSubscription.expirationDate, 'MM/DD/YYYY')
      : null;
  }, [currentSubscription])

  const changeSubscriptionPlan = useCallback(async (type) => {
    const res = type === 'upgraded'
      ? await subscription.upgradePlanPaid(userId)
      : await subscription.renewalPlanPaid(userId);

    if (res.status === 200) {
      const newPlanName = (await subscriptionPlansApi.getById(res.data.currentPlanId)).data.name;
      enqueueSnackbar(
        `${userName} has the new plan ${newPlanName} successfully ${type}!`,
        { variant: 'success' }
      );
      let renewalPlanLabel = '';
      if (res.data.currentPlanId === res.data.paidRenewalPlanId) {
        renewalPlanLabel = 'Auto-Renew';
      } else if (!!res.data.paidRenewalPlanId) {
        const renewalPlan = (await subscriptionPlansApi.getById(res.data.paidRenewalPlanId))?.data;
        renewalPlanLabel = `Renew ${renewalPlan.name}`;
      }

      setCurrentSubscription({
        ...res.data,
        renewalPlanLabel
      })
    }
    setOpenModal(null);
  }, [enqueueSnackbar, userId, userName, subscriptionPlansApi])

  const upgradeAction = (type) => {
    setOpenModal({ type, action: 'upgraded' });
  }
    
  const renewAction = (type) => {
    setOpenModal({ type, action: 'renewed' });
  }

  const calculateAvailable = (remaining, reserved) => {
    const available = remaining - reserved;
    return available < 0 ? 0 : available
  }

  return (
    <ThemeProvider theme={subscriptionTheme}>
      {currentPlan && (
        <React.Fragment>
          <Grid container spacing={4} direction="row" classes={{ root: classes.wrapper }}>
            <Grid item sm={5}>
              <Box mb={'11px'} display="flex" alignItems="center">
                <Typography variant="h5">CURRENT SUBSCRIPTION</Typography>
              </Box>
              <Box className={classes.cardRoot}>
                <Box className={classes.itemWrapper}>
                  <SubscriptionPlan
                    isAdmin={isAdmin}
                    name={currentPlan?.name}
                    upgradeAction={currentSubscription?.requestedUpgradePlanId && upgradeAction}
                    renewAction={currentSubscription?.requestedRenewalPlanId && renewAction}
                    chipLabel={(currentSubscription?.requestedRenewalPlanId === currentSubscription?.currentPlanId ||
                                        currentSubscription?.paidRenewalPlanId === currentSubscription?.currentPlanId) ? 'Auto-Renewal' : null
                    }
                  />
                </Box>
                {(!!expirationDate && currentSubscription?.paidRenewalPlanId !== currentSubscription?.currentPlanId) && (
                  <Box className={classes.itemWrapper}>
                    <Expires expirationDate={expirationDate} />
                  </Box>
                )}
                <Box className={(currentSubscription?.paidRenewalPlanId !== requestedPlan?.id || currentSubscription?.paidRenewalPlanId === currentSubscription?.currentPlanId) && classes.itemWrapper}>
                  <BasicItem title="YEARLY PAYMENT" content={`$${currentPlan?.price}`} />
                </Box>
                {(currentSubscription?.paidRenewalPlanId !== requestedPlan?.id || currentSubscription?.paidRenewalPlanId === currentSubscription?.currentPlanId) && (
                  <Box>
                    <BasicItem
                      title="ESTIMATED NEXT BILLING"
                      content={(currentSubscription?.paidRenewalPlanId === currentSubscription?.currentPlanId && expirationDate)
                        ? moment.utc(expirationDate).local().add(1, 'years').format('MM/DD/YYYY')
                        : (expirationDate || 'N/A')
                      }
                    />
                  </Box>
                )}
              </Box>
              {(!!requestedPlan &&
                        (!!currentSubscription?.paidRenewalPlanId ? currentSubscription?.paidRenewalPlanId !== currentSubscription?.currentPlanId :
                          !!currentSubscription?.requestedRenewalPlanId ? currentSubscription?.requestedRenewalPlanId !== currentSubscription?.currentPlanId : currentSubscription?.requestedUpgradePlanId)
              ) && (
                <Box mt={3}>
                  <Box mb={'11px'}>
                    <Typography variant="h5">REQUESTED NEW SUBSCRIPTION</Typography>
                  </Box>
                  <Box className={classes.cardRoot}>
                    <Box className={classes.itemWrapper}>
                      <SubscriptionPlan
                        isAdmin={isAdmin}
                        name={requestedPlan?.name}
                        chipLabel={!!currentSubscription?.paidRenewalPlanId && (currentSubscription?.paidRenewalPlanId === requestedPlan?.id ? 'Paid' : 'Requested')}
                      />
                    </Box>
                    {!currentSubscription?.requestedUpgradePlanId && (
                      <Box className={classes.itemWrapper}>
                        <BasicItem title="ACTIVE FROM" content={expirationDate} />
                      </Box>
                    )}
                    {!!expirationDate && (
                      <Box className={classes.itemWrapper}>
                        <BasicItem
                          title="EXPIRES ON"
                          content={((!!currentSubscription?.paidRenewalPlanId && currentSubscription?.paidRenewalPlanId !== currentSubscription?.currentPlanId) ||
                                                    (!!currentSubscription?.requestedRenewalPlanId && currentSubscription?.requestedRenewalPlanId !== currentSubscription?.currentPlanId))
                            ? moment.utc(currentSubscription?.expirationDate).local().add(1, 'years').format('MM/DD/YYYY')
                            : (!!currentSubscription?.requestedUpgradePlanId && currentSubscription?.currentPlanId !== 0)
                              ? expirationDate
                              : moment.utc(currentSubscription?.expirationDate).local().add(1, 'years').format('MM/DD/YYYY')
                          }
                        />
                      </Box>
                    )}
                    <Box>
                      <BasicItem title="YEARLY PAYMENT" content={`$${requestedPlan?.price}`} />
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>
            <Grid item sm={5} className={classes.marginLeft}>
              <Box className={classes.itemWrapper}>
                <CreditBar
                  title="REGULAR CREDITS"
                  remainingCredits={minimumZero(currentSubscription?.remainingRegularCredits)}
                  availableCredits={minimumZero(currentSubscription?.availableRegularCredits)}
                  reservedCredits={minimumZero(currentSubscription?.reservedRegularCredits)}
                  maxCredits={minimumZero(currentPlan?.regularCredits)}
                  isAdmin={isAdmin}
                  baseRoute={baseRoute}
                  type="regular"
                />
              </Box>
              <Box className={classes.itemWrapper}>
                <CreditBar
                  title="PREMIUM CREDITS"
                  remainingCredits={minimumZero(currentSubscription?.remainingPremiumCredits)}
                  availableCredits={minimumZero(currentSubscription?.availablePremiumCredits)}
                  reservedCredits={minimumZero(currentSubscription?.reservedPremiumCredits)}
                  maxCredits={minimumZero(currentPlan?.premiumCredits)}
                  isAdmin={isAdmin}
                  baseRoute={baseRoute}
                  type="premium"
                />
              </Box>
              <Box className={classes.itemWrapper} display="flex">
                <Box>
                  <Box width={20}>
                    <InfoBrownOtherIcon />
                  </Box>
                </Box>
                <Box>
                  <Typography style={{ color: '#7A5B44' }}>If you have "Reserved" credits it means that some searches are currently in the queue for the Ongoing Monitoring. Once monitoring starts, these credits will be spent.</Typography>
                </Box>
              </Box>
              <Box className={classes.itemWrapper}>
                <CreditBar
                  title="EIDV CREDITS"
                  remainingCredits={minimumZero(currentSubscription?.remainingEidvCredits)}
                  availableCredits={minimumZero(calculateAvailable(currentSubscription?.remainingEidvCredits, currentSubscription?.reservedEidvCredits))}
                  reservedCredits={minimumZero(currentSubscription?.reservedEidvCredits)}
                  maxCredits={minimumZero(currentPlan?.eidvCredits)}
                  isAdmin={isAdmin}
                  baseRoute={baseRoute}
                  type="eidv"
                />
              </Box>
              <Box className={classes.itemWrapper} display="flex">
                <Box>
                  <Box width={20}>
                    <InfoBrownOtherIcon />
                  </Box>
                </Box>
                <Box>
                  <Typography style={{ color: '#7A5B44' }}>If you have "Reserved" credits, it means that some eIDVs have been initiated, but not yet successfully completed.<br/>Once the case is completed, a credit will be spent.</Typography>
                </Box>
              </Box>
              {/* <Box className={classes.itemWrapper}>
                            <MeasuredItem title="TEAM MEMBERS" value={1} minValue={399} maxValue={400} isAdmin={isAdmin} />
                        </Box> */}
              {/* <Box className={classes.itemWrapper} /> */}
            </Grid>
          </Grid>
          {!isAdmin && (
            <Box display="flex" mt={7} justifyContent="space-between">
              <Box className={classes.itemWrapper} />
              <Box className={classes.itemWrapper} display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => history.push(baseRoute + '/plans')}
                >
                                VIEW SUBSCRIPTION PLANS
                </Button>
              </Box>
              <Box className={classes.itemWrapper} />
            </Box>
          )}
        </React.Fragment>
      )}
      <Modal
        open={!!openModal}
        onClose={() => setOpenModal(null)}
        title={`${openModal?.type} subscription`}
        mainText={`Are you sure you want to ${openModal?.type} the subscription plan for user "${userName?.trim()}"?`}
        propsClassses={classes.modalButtonWidth}
        actions={
          [
            {
              type: 'main',
              label: 'No',
              action: () => setOpenModal(null),
            },
            {
              type: 'secondary',
              label: 'Yes',
              action: () => changeSubscriptionPlan(openModal?.action),
            },
          ]
        }
        actionsDirection="row"
      />
    </ThemeProvider>
  )
}

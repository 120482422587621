import React from  'react';
import PageLayout from '@components/layout/page-layout';
import history from '@app/history';
import { Route, Switch } from 'react-router';
import RepeatSearch from '@views/protected/client/background-check/subpages/repeat-search';
import NewSearch from '@views/protected/client/background-check/subpages/new-search';
import UpdateExistingMonitoring from '@views/protected/client/background-check/subpages/update-existing-monitoring';
import MonitoringBgCheck from '@views/protected/client/ongoing-monitoring/monitoring-bg-check';
import MonitoringUserNoteList from '@views/protected/client/ongoing-monitoring/monitoring-bg-user-note-list';
import LoggedNotFound from '@views/protected/not-found';

export default function PortfolioRoutes(props) {

  return (
    <PageLayout>
      <Switch>
        {/* BG check then monitoring steps */}
        <Route path="/portfolio/bg-check/add-monitoring-bg-check/:clientId">
          <MonitoringBgCheck
            steps={{ currentStep: 1, stepsCount: 4 }}
            onNext={(params) =>
              history.push(`/portfolio/bg-check/add-monitoring-new-search/${params.clientId}?searchId=${params.searchId}&searchName=${params.searchName}&searchType=${params.searchType}`)}
          />
        </Route>
        <Route path="/portfolio/bg-check/add-monitoring-new-search/:clientId">
          <NewSearch
            flow="ongoing-monitoring"
            steps={{ currentStep: 2, stepsCount: 4 }}
            onNext={(params) =>
              history.push(`/portfolio/bg-check/add-monitoring-user-note-list/${params.clientId}?searchId=${params.searchId}&profileId=${params.profileId}&searchName=${params.searchName}&searchType=${params.searchType}`)
            }
            onNextAlt={(params) =>
              history.replace(`/portfolio/bg-check/add-monitoring-no-hits/${params.clientId}?searchId=${params.searchId}&searchName=${params.searchName}&searchType=${params.searchType}`)
            }
          />
        </Route>
        <Route path="/portfolio/bg-check/add-monitoring-user-note-list/:clientId">
          <MonitoringUserNoteList
            baseRoute="portfolio/bg-check"
            steps={{ currentStep: 3, stepsCount: 4 }}
            onNext={(params) =>
              history.push(`/portfolio/bg-check/add-monitoring-finalize-bg/${params.searchId}/${params.clientId}/${params.searchType}`)
            }
          />
        </Route>
        <Route path="/portfolio/bg-check/add-monitoring-finalize-bg/:searchId/:clientId/:type">
          <UpdateExistingMonitoring
            flow="ongoing-monitoring"
            steps={{ currentStep: 4, stepsCount: 4 }}
            onNext={(params) =>
              history.push(`/portfolio/view/${params.clientId}/background-check`)
            }
          />
        </Route>

        {/* Add to ongoing monitoring no search results */}
        <Route path="/portfolio/bg-check/add-monitoring-no-hits/:clientId">
          <RepeatSearch
            steps={{ currentStep: 2, stepsCount: 3 }}
            onNext={(params) =>
              history.push(`/portfolio/bg-check/add-monitoring-finalize-no-hits/${params.searchId}/${params.clientId}/${params.searchType}`)
            }
            onNextAlt={(params) =>
              history.push(`/portfolio/bg-check/add-monitoring-bg-check/${params.clientId}`)
            }
            onRedirect={(params) =>
              history.push(`/portfolio/bg-check/add-monitoring-no-hits/${params.clientId}?searchId=${params.searchId}&searchName=${params.searchName}&searchType=${params.searchType}`)
            }
            onWhitelisted={(params) =>
              history.push(`/portfolio/bg-check/add-monitoring-user-note-list/${params.clientId}?searchId=${params.searchId}&searchName=${params.searchName}&searchType=${params.searchType}`)
            }
          />
        </Route>
        <Route path="/portfolio/bg-check/add-monitoring-finalize-no-hits/:searchId/:clientId/:type">
          <UpdateExistingMonitoring
            flow="ongoing-monitoring"
            steps={{ currentStep: 3, stepsCount: 3 }}
            onNext={(params) =>
              history.push(`/portfolio/view/${params.clientId}/background-check`)
            }
          />
        </Route>
        <Route path="*" exact>
          <LoggedNotFound />
        </Route>
      </Switch>
    </PageLayout>
  )
};

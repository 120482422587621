import picker from './picker';
import autocomplete from './autocomplete';
import inputs from './inputs';
import toggle from './toggle';
import radio from './radio';
import checkbox from './checkbox';

const controls = {
  ...inputs,
  ...picker,
  ...autocomplete,
  ...toggle,
  ...radio,
  ...checkbox,
}

export default controls;
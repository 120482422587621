const initialState = {
  snackbar: { message: '', variant: 'success', suppressable: false },
  suppressSnackbar: false,
};

const general = (state = initialState, action) => {

  switch (action.type) {
    case 'SET_SNACKBAR':
      return {
        ...state,
        snackbar: action.snackbar,
      };
    
    case 'SET_SUPPRESS_SNACKBAR':
      return {
        ...state,
        suppressSnackbar: action.suppressSnackbar,
      };

    default:
      return state
  }
};

export default general;

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box,
  Button,
  Card,
  Link,
  makeStyles,
  Typography,
  Collapse,
} from '@material-ui/core';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { DividerDashed } from '@components/layout/divider-dashed';
import {
  ChevronLeftIcon,
  EnterprisePlan,
  InfoBrownOtherIcon,
  DropdownDownIcon,
  DropdownUpIcon,
} from '@app/icons';
import PlanCard from './plan-card';
import { Modal } from '@components/modals';
import history from '@app/history';
import { subscriptionPlans, client } from '@app/api/client';
import { subscription } from '@app/api/admin';
import { useWindowSize } from '@utils/useWindowSize';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  container: (props) => ({
    overflow: 'auto',
    height: props.isSettings ? 'calc(100vh - 210px)' : 'calc(100% - 65px)',
  }),
  bolded: {
    fontWeight: 'bold',
  },
  switchDiv: {
    cursor: 'pointer',
    width: 245,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0 10px 15px 10px',
    width: 220,
    padding: 22,
    // height: 320,
    position: 'relative',
    overflow: 'visible',
  },
  purchaseButton: {
    fontSize: 16,
    lineHeight: '28px',
    padding: '10px',
    width: '100%',
  },
  infoTitle: {
    textTransform: 'uppercase',
    color: '#656565',
    marginRight: '5px',
  },
  infoSubtitle: {
    fontWeight: 500,
    fontSize: '16px',
  },
  info: {
    color: '#656565',
    textAlign: 'left',
  },
  modalText: {
    color: '#656565',
  },
  linkified: {
    textDecoration: 'none',
    color: theme.palette.text.LB3,
    cursor: 'pointer',
    fontWeight: 'bold',
    marginLeft: '3px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default function SubscriptionPlans({ isAdmin, isSettings }) {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [userId] = useState(search.get('userId'));
  const [email] = useState(search.get('email'));
  const [userName] = useState(search.get('userName'));

  const classes = useStyles({ isSettings });
  const [width] = useWindowSize();
  const { enqueueSnackbar } = useSnackbar();

  const [isYearly, setIsYearly] = useState(false); // eslint-disable-line
  const [plans, setPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [isShowMore, setIsShowMore] = useState(false);
  const [isShowEidvInfo, setIsShowEidvInfo] = useState(false);
  const [modalAction, setModalAction] = useState(null);
  const [creditSpending, setCreditSpending] = useState(null);
  const [monitoryClients, setMonitoryClients] = useState(null);

  const getClients = useCallback(async () => {
    const clientsMonitory = (await client.getClients()).data;
    setMonitoryClients(clientsMonitory);
  }, []);

  const getAllPlans = useCallback(async () => {
    const res = await subscriptionPlans.getAll();
    if (res.status === 200) {
      setPlans(
        res.data.sort((a, b) =>
          a.level > b.level ? 1 : a.level < b.level ? -1 : 0
        )
      );
    }
  }, []);

  const getCurrentPlans = useCallback(async () => {
    const res = isAdmin
      ? await subscription.getSubscriptionForUser(userId)
      : await subscriptionPlans.getSubscriptionForUser();

    if (res.status === 200) {
      // const sortedPlans;
      const currentPlan = plans.find(
        (plan) => plan.id === res.data.currentPlanId
      );
      setCurrentPlan({
        ...res.data,
        planLevel: currentPlan.level,
      });
    }
  }, [userId, isAdmin, plans]);

  useEffect(() => {
    getAllPlans();
    if (!isAdmin) {
      getClients();
    }
  }, [getAllPlans, getClients, isAdmin]);

  useEffect(() => {
    if (plans.length > 0) {
      getCurrentPlans();
    }
    // eslint-disable-next-line
  }, [plans]);

  useEffect(() => {
    if (!!plans.length && currentPlan) {
      const plan = plans.find((el) => el.id === currentPlan?.currentPlanId);

      setCreditSpending({
        premiumCredits:
          plan?.premiumCredits -
          currentPlan.remainingPremiumCredits +
          currentPlan.reservedPremiumCredits,
        regularCredits:
          plan?.regularCredits -
          currentPlan.remainingRegularCredits +
          currentPlan.reservedRegularCredits,
        eidvCredits:
          plan?.eidvCredits -
          currentPlan.remainingEidvCredits +
          currentPlan.reservedEidvCredits,
      });
    }
  }, [plans, currentPlan]);

  const isRenewal = useMemo(() => {
    return (
      currentPlan?.expirationDate &&
      moment
        .utc(currentPlan?.expirationDate)
        .local()
        .diff(moment.utc().local(), 'days') <= 30
    );
  }, [currentPlan]);

  const changeSubscriptionPlan = useCallback(
    async (id) => {
      const res = isAdmin
        ? await subscription.upgradePlanPaid(userId)
        : currentPlan?.currentPlanId === id || isRenewal
          ? await subscriptionPlans.changeRequestedRenewalSubscriptionPlan({
            newPlanId: id,
          })
          : await subscriptionPlans.changeRequestedUpgradeSubscriptionPlan({
            newPlanId: id,
          });
      if (res.status === 200) {
        setCurrentPlan({
          ...res.data,
          planLevel: currentPlan?.planLevel,
        });

        if (isAdmin) {
          const planName = plans.find((el) => el.id === id)?.name;
          enqueueSnackbar(
            `${
              userName || email
            } has the new plan "${planName}" successfully assigned!`,
            { variant: 'success' }
          );
          history.goBack();
          return;
        }

        setModalAction({ type: 'success', name: modalAction?.name });
      }
    },
    [
      enqueueSnackbar,
      setModalAction,
      modalAction,
      userId,
      isAdmin,
      email,
      plans,
      userName,
      currentPlan,
      isRenewal,
    ]
  );

  const modalContent = () => {
    const currPlan = plans.find((el) => el.id === currentPlan?.currentPlanId);
    return modalAction?.type === 'confirm' ? (
      <React.Fragment>
        <Box my={3}>
          <Typography
            variant="subtitle2"
            align="center"
            className={classes.modalText}
          >
            Plan details:
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="subtitle2"
            align="center"
            className={classes.modalText}
          >
            <span style={{ fontWeight: 500 }}>
              {modalAction?.premiumCredits} Premium
            </span>{' '}
            search credit limit
          </Typography>
          <Typography
            variant="subtitle2"
            align="center"
            className={classes.modalText}
          >
            <span style={{ fontWeight: 500 }}>
              {modalAction?.regularCredits} Regular
            </span>{' '}
            search credit limit
          </Typography>
          <Typography
            variant="subtitle2"
            align="center"
            className={classes.modalText}
          >
            <span style={{ fontWeight: 500 }}>
              {modalAction?.eidvCredits} eIDV
            </span>{' '}
            credit limit
          </Typography>
        </Box>
        <Box justifyContent={'center'} my={2} mx={'-45px'}>
          <DividerDashed />
        </Box>
        {currentPlan?.currentPlanId === modalAction.id || isRenewal ? (
          <React.Fragment>
            <Box mb={3}>
              <Typography
                variant="subtitle2"
                align="center"
                className={clsx(classes.modalText, classes.bolded)}
              >
                Current balance on the Ongoing Monitoring
              </Typography>
              <Typography
                variant="subtitle2"
                align="center"
                className={clsx(classes.modalText, classes.bolded)}
              >
                Names using Premium credits:{' '}
                {monitoryClients?.clientOnMonitoryWithPremiumSearches}
              </Typography>
              <Typography
                variant="subtitle2"
                align="center"
                className={clsx(classes.modalText, classes.bolded)}
              >
                Names using Regular credits:{' '}
                {monitoryClients?.clientOnMonitoryWithRegularSearches}
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography
                variant="subtitle2"
                align="center"
                className={clsx(classes.modalText, classes.bolded)}
              >
                Current balance on eIDV cases:
              </Typography>
              <Typography
                variant="subtitle2"
                align="center"
                className={clsx(classes.modalText, classes.bolded)}
              >
                Used eIDV credits:{' '}
                {currPlan?.eidvCredits -
                  currentPlan?.remainingEidvCredits +
                  currentPlan?.reservedEidvCredits}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="subtitle2"
                align="center"
                className={clsx(classes.modalText, classes.bolded)}
              >
                {`${isAdmin ? 'New' : 'Your new'} balance will be`}
              </Typography>
              <Typography
                variant="subtitle2"
                align="center"
                className={clsx(classes.modalText, classes.bolded)}
              >
                {modalAction?.premiumCredits -
                  monitoryClients?.clientOnMonitoryWithPremiumSearches}{' '}
                Premium credits
              </Typography>
              <Typography
                variant="subtitle2"
                align="center"
                className={clsx(classes.modalText, classes.bolded)}
              >
                {modalAction?.regularCredits -
                  monitoryClients?.clientOnMonitoryWithRegularSearches}{' '}
                Regular credits
              </Typography>
              <Typography
                variant="subtitle2"
                align="center"
                className={clsx(classes.modalText, classes.bolded)}
              >
                {modalAction?.eidvCredits - creditSpending?.eidvCredits < 0
                  ? 0
                  : modalAction?.eidvCredits - creditSpending?.eidvCredits}{' '}
                eIDV credits
              </Typography>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box mb={3}>
              <Typography
                variant="subtitle2"
                align="center"
                className={classes.modalText}
              >
                Credit spending count for the entire account
              </Typography>
              <Typography
                variant="subtitle2"
                align="center"
                className={clsx(classes.modalText, classes.bolded)}
              >
                {creditSpending?.premiumCredits} Premium credits
              </Typography>
              <Typography
                variant="subtitle2"
                align="center"
                className={clsx(classes.modalText, classes.bolded)}
              >
                {creditSpending?.regularCredits} Regular credits
              </Typography>
              <Typography
                variant="subtitle2"
                align="center"
                className={clsx(classes.modalText, classes.bolded)}
              >
                {creditSpending?.eidvCredits} eIDV credits
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="subtitle2"
                align="center"
                className={clsx(classes.modalText, classes.bolded)}
              >
                {`${isAdmin ? 'New' : 'Your new'} balance will be`}
              </Typography>
              <Typography
                variant="subtitle2"
                align="center"
                className={clsx(classes.modalText, classes.bolded)}
              >
                {modalAction?.premiumCredits - creditSpending?.premiumCredits}{' '}
                Premium credits
              </Typography>
              <Typography
                variant="subtitle2"
                align="center"
                className={clsx(classes.modalText, classes.bolded)}
              >
                {modalAction?.regularCredits - creditSpending?.regularCredits}{' '}
                Regular credits
              </Typography>
              <Typography
                variant="subtitle2"
                align="center"
                className={clsx(classes.modalText, classes.bolded)}
              >
                {modalAction?.eidvCredits - creditSpending?.eidvCredits < 0
                  ? 0
                  : modalAction?.eidvCredits - creditSpending?.eidvCredits}{' '}
                eIDV credits
              </Typography>
            </Box>
          </React.Fragment>
        )}
        <Box justifyContent={'center'} my={2} mx={'-45px'}>
          <DividerDashed />
        </Box>
        <Box>
          <Typography
            variant="subtitle2"
            align="center"
            className={classes.modalText}
          >
            Ongoing Monitoring list counts towards plan's annual credit limit
          </Typography>
        </Box>
      </React.Fragment>
    ) : (
      <Box my={6}>
        <Box mb={3}>
          <Typography
            variant="subtitle2"
            align="center"
            className={classes.modalText}
          >
            You will receive an invoice with payment instructions in your
          </Typography>
          <Typography
            variant="subtitle2"
            align="center"
            className={classes.modalText}
          >
            email.
          </Typography>
          <Typography
            variant="subtitle2"
            align="center"
            className={classes.modalText}
          >
            Once the payment is processed, your new plan will be activated.
          </Typography>
        </Box>
        <Typography
          variant="subtitle2"
          align="center"
          className={classes.modalText}
        >
          You will receive an email with activation confirmation.
        </Typography>
      </Box>
    );
  };

  return (
    <Box className={classes.container}>
      {isAdmin ? (
        <Box display="flex" justifyContent="center" mt={3} mb={2}>
          <Typography variant={'h3'}>Available Plans</Typography>
        </Box>
      ) : (
        !isSettings && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
            mt={4}
            ml={1}
          >
            <Box position="absolute" left={-10}>
              <ButtonWithIcon
                startIcon={<ChevronLeftIcon />}
                onClick={() => history.goBack('/my-profile/subscription')}
              >
                Back
              </ButtonWithIcon>
            </Box>
          </Box>
        )
      )}
      <Box
        display="flex"
        mt={isSettings ? 2 : 4}
        justifyContent={width > 1920 ? 'center' : 'flex-start'}
        flexWrap="wrap"
      >
        {!!plans.length &&
          plans.map(
            (plan) =>
              plan.eidvCredits === 0 && (
                <PlanCard
                  key={plan.id}
                  {...plan}
                  {...currentPlan}
                  planID={plan.id}
                  isCurrent={currentPlan?.currentPlanId === plan.id}
                  disabled={currentPlan?.planLevel > plan.level}
                  action={(data) =>
                    isAdmin
                      ? changeSubscriptionPlan(data.id)
                      : setModalAction(data)
                  }
                  isAdmin={isAdmin}
                  isRenewal={isRenewal}
                  paidRenwealPlanName={
                    plans.find((el) => el.id === currentPlan?.paidRenewalPlanId)
                      ?.name || ''
                  }
                />
              )
          )}
      </Box>
      {!!plans.length && !isAdmin && (
        <React.Fragment>
          <Box my={3} ml={'12px'} display="flex" flexDirection="column">
            <Typography
              align="left"
              variant="subtitle1"
              style={{ color: '#7A5B44', fontWeight: 500 }}
            >
              A search credit equals one unique name search. Every search spends
              one search credit.
            </Typography>
            <Typography
              align="left"
              variant="subtitle1"
              style={{ color: '#7A5B44', fontWeight: 500 }}
            >
              Names on the ongoing monitoring list count towards the plan’s
              search credit allowance.
            </Typography>
          </Box>
          <Box ml={'12px'} my={1}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <InfoBrownOtherIcon />
              <ButtonWithIcon
                endIcon={isShowMore ? <DropdownUpIcon /> : <DropdownDownIcon />}
                onClick={() => setIsShowMore(!isShowMore)}
              >
                <Typography variant="h5" className={classes.infoTitle}>
                  Learn about the types of search credits
                </Typography>
              </ButtonWithIcon>
            </Box>
            <Collapse in={isShowMore}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                mt={3}
                mb={1}
              >
                <Typography variant="h5" className={classes.infoTitle}>
                  Regular Credits
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.infoSubtitle}
                >
                  - for Standard Due diligence
                </Typography>
              </Box>
              <Typography variant="subtitle2" className={classes.info}>
                Searches performed with regular credits will effectively be
                checked against all the sanctions list and limited PEP sources.
                Thus, for example, only high-level politicians will be found in
                this dataset.
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                mt={3}
                mb={1}
              >
                <Typography variant="h5" className={classes.infoTitle}>
                  Premium Credits
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.infoSubtitle}
                >
                  - for Extended Due diligence
                </Typography>
              </Box>
              <Typography variant="subtitle2" className={classes.info}>
                {
                  'Searches performed with premium credits will effectively be checked against a comprehensive dataset of more than '
                }
                <Link href="/sources-list" target="_blank">
                  1300 sources
                </Link>
                {', including Sanctions, 4 PEP classes and Adverse media.'}
              </Typography>
            </Collapse>
          </Box>
        </React.Fragment>
      )}
      <Box
        display="flex"
        mt={isSettings ? 2 : 4}
        justifyContent={width > 1920 ? 'center' : 'flex-start'}
        flexWrap="wrap"
      >
        {!!plans.length &&
          plans.map(
            (plan) =>
              plan.eidvCredits > 0 && (
                <PlanCard
                  key={plan.id}
                  {...plan}
                  {...currentPlan}
                  planID={plan.id}
                  isCurrent={currentPlan?.currentPlanId === plan.id}
                  disabled={currentPlan?.planLevel > plan.level}
                  action={(data) =>
                    isAdmin
                      ? changeSubscriptionPlan(data.id)
                      : setModalAction(data)
                  }
                  isAdmin={isAdmin}
                  isRenewal={isRenewal}
                  paidRenwealPlanName={
                    plans.find((el) => el.id === currentPlan?.paidRenewalPlanId)
                      ?.name || ''
                  }
                />
              )
          )}
        {!!plans.length && !isAdmin && (
          <Card className={classes.card}>
            <Typography variant="h4" align="center">
              Enterprise
            </Typography>
            <Box my={4} display="flex" justifyContent="center">
              <EnterprisePlan />
            </Box>
            <Box mt={2}>
              <Typography variant="body1" align="center">
                Don’t see anything suitable? We are open to make custom plans
                for large companies.
              </Typography>
            </Box>
            <Box>
              <Box mt={3}>
                <Typography variant="body1" align="center">
                  Email us at{' '}
                  <Link
                    variant="body1"
                    href="mailto:support@kycpassport.com"
                    target="_blank"
                  >
                    support@kycpassport.com
                  </Link>
                  .
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography variant="body1" align="center">
                  or
                </Typography>
              </Box>
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button
                  variant="contained"
                  size="medium"
                  className={classes.purchaseButton}
                  href="mailto:support@kycpassport.com"
                  target="_blank"
                >
                  CONTACT SALES
                </Button>
              </Box>
            </Box>
          </Card>
        )}
      </Box>
      {!!plans.length && !isAdmin && (
        <Box ml={'12px'} my={1}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <InfoBrownOtherIcon />
            <ButtonWithIcon
              endIcon={
                isShowEidvInfo ? <DropdownUpIcon /> : <DropdownDownIcon />
              }
              onClick={() => setIsShowEidvInfo(!isShowEidvInfo)}
            >
              <Typography variant="h5" className={classes.infoTitle}>
                Learn about electronic identity verification (eIDV)
              </Typography>
            </ButtonWithIcon>
          </Box>
          <Collapse in={isShowEidvInfo}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              mt={1}
              mb={1}
            >
              <Typography variant="subtitle2" className={classes.info}>
                One eIDV case will spend one eIDV credit. You can review the
                case and request repeated document upload until you’re happy
                with the received documents. Once you accept the documents, they
                will be saved in your account. Updates and partial uploads
                within the same case are free of charge until that case is
                successfully completed.
              </Typography>
            </Box>
          </Collapse>
        </Box>
      )}
      <Modal
        open={!!modalAction}
        onClose={() => setModalAction(null)}
        titleContent={
          <Box>
            <Typography variant="h3">
              {modalAction?.type === 'confirm'
                ? 'You are about to subscribe to'
                : 'You have subscribed to'}
            </Typography>
            <Typography variant="h3">
              {modalAction?.name.includes('plan')
                ? `${modalAction?.name
                  ?.replace('plan', '')
                  ?.toUpperCase()} plan`
                : `${modalAction?.name?.toUpperCase()} plan`}
            </Typography>
          </Box>
        }
        content={modalContent()}
        actions={[
          {
            type: 'secondary',
            label: modalAction?.type === 'confirm' ? 'confirm' : 'ok',
            action: () =>
              modalAction?.type === 'confirm'
                ? changeSubscriptionPlan(modalAction?.id)
                : setModalAction(null),
            style: { width: 160 },
          },
        ]}
        actionsDirection="row"
      />
    </Box>
  );
}

import React from  'react';
import { Button } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttonNotStep: {
    color: theme.palette.text.LB3,
    '&:hover': {
      backgroundColor: 'inherit',
    }
  },
}))

export default function RenderNotNowButton({ handleNotNow }) {
  const classes = useStyles();

  return (
    <Button
      classes={{ root: classes.buttonNotStep }}
      onClick={() => handleNotNow()}
    >
      NOT NOW
    </Button>
  )}
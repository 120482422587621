import { useState } from 'react';


/**
 * This hook is to be used together with multiple collapsible objects
 * the idea is to have only one object open at a time
 * with the possibility to close all as well
 */
export const useAccordion = (defaultValue = {}) => {
  const [accordion, setAccordion] = useState({ ...defaultValue })

  const onSetAccordion = (type, isOpen) => {
    const newAccordion = {};
    if (isOpen === undefined) {
      newAccordion[type] = !accordion[type];
      return setAccordion(newAccordion);
    }

    newAccordion[type] = isOpen;
    setAccordion(newAccordion);
  }

  return [accordion, onSetAccordion];
}
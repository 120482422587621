import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { BulletpointBlueIcon } from '@app/icons';

const useStyles = makeStyles((theme) => ({
  checkedButton: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '32px',
    background: 'rgba(255, 255, 255, 0.6) !important',
    border: '1px solid rgba(255, 255, 255, 0.6) !important',
    mixBlendMode: 'normal',
    borderRadius: '12px',
    textTransform: 'none',
  }
}));

export default function CheckedOption(props) {
  const classes = useStyles();
  return (
    <Button
      className={classes.checkedButton}
      variant="outlined"
      disabled
      startIcon={<BulletpointBlueIcon />}
    >
      {props.children}
    </Button>
  );
}
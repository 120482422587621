import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Box, FormControlLabel, makeStyles } from '@material-ui/core';
import CheckboxLight from '@components/inputs/checkbox-light';
import { WhitelistIcon, WhitelistInactiveIcon } from '@app/icons';
import clsx from 'clsx';

// import PopoverSearchFilter from './popover-search-filter';
import SetMonitoring from './set-monitoring';
import CustomIconButton from '@components/buttons/button-with-icon';
import CustomTooltip from '@components/controls/tooltip';
import { prepareProfilesFetchData, profilesPrepareDataAdvantage } from '@utils/prepare-data/prepareProfilesData';

import { clientApi } from '@app/api';
import { useSelector, useDispatch } from 'react-redux';
import { backgroundCheck, paginations, scroll } from '@store/actions';
import { useSnackbar } from 'notistack';
import { joinStringArray } from '@utils/joinStringArray';
import ModalContent from '../modal-content';
import { Modal } from '@components/modals';
import { isEmpty } from 'lodash';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  badge: {
    backgroundColor: 'rgb(202, 215, 202)'
  },
  pulsingButton: {
    animationName: '$pulsing',
    animationDuration: '2s',
    animationIterationCount: 'infinite',
  },
  '@keyframes pulsing': {
    '0%': {
      backgroundColor: 'rgb(202, 215, 202)',
    },
    '50%': {
      backgroundColor: 'rgb(213, 240, 240)',
    },
    '100%': {
      backgroundColor: 'rgb(202, 215, 202)',
    }
  },
  showWhitelistedRoot: {
    margin: '0 8px',
    height: '32px',
    borderRadius: '12px',
  },
  showWhitelistedLabel: {
    color: theme.palette.text.LB3,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '20px',
    fontFamily: 'Roboto',
    letterSpacing: '0.75px',
  },
  customButtonModalWidth: {
    width: '180px',
  },
}));

const ADVANTAGE_PROVIDER = 'COMPLY_ADVANTAGE';

export default function HeaderFilterLine({ isShowRightCard, hideMonitoring, searchId, redirectLink, getCurrentSearch }) {
  const { enqueueSnackbar } = useSnackbar();
  const { profiles, selectAllProfiles, currentSearch, isShowWhitelisted, profilesPages } = useSelector(store => store.backgroundCheck);
  const { profilesVersionId } = useSelector(store => store.searchHistory);
  const { currentPages } = useSelector(store => store.paginations);
  const dispatch = useDispatch();

  const classes = useStyles();
  const [isPulsing, setIsPulsing] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [whitelistData, setWhitelistData] = useState(null);

  const checkSelectAll = () => {
    const checkedProfiles = profiles[currentPages].every(el => el.isChecked);
    dispatch(backgroundCheck.setSelectAllProfiles(checkedProfiles));
  }

  useEffect(() => {
    if (profiles[currentPages]) {
      checkSelectAll();
    }
  }, [currentPages, profiles]) // eslint-disable-line

  const updateCurrentPage = async (page) => {
    const isWhitelisted = isShowWhitelisted;
    const res = await clientApi.search.getProfiles(searchId, page ?? currentPages[0], 36, isWhitelisted);
    const { status, data } = res;

    // Recursion - if last page is gone, fetch second to last.
    if (!data?.entries?.length && currentPages[0] !== 0) {
      return updateCurrentPage(currentPages[0] - 1);
    }

    if (status !== 200  || !data.entries.length) {
      redirectLink();
    }
    
    const changedProfiles = currentSearch.searchProvider === ADVANTAGE_PROVIDER
      ? profilesPrepareDataAdvantage(data.entries, profilesVersionId, currentSearch.searchProvider)
      : prepareProfilesFetchData(data.entries, profilesVersionId);

    const actualPage = ((profilesPages === data.pages && data.pages > 1) || currentPages[0] === 0)
      ? currentPages[0] 
      : currentPages[0] - 1;

    dispatch(paginations.setCurrentItemsLength(changedProfiles.length));
    dispatch(backgroundCheck.setProfiles({ [actualPage]: changedProfiles }));
    dispatch(backgroundCheck.setQuantityProfiles(data.count));

    dispatch(backgroundCheck.setProfilesPage(actualPage));
    dispatch(backgroundCheck.setProfilesPages(data.pages === 1 ? 0 : data.pages));
    dispatch(paginations.setCurrentPages([actualPage]));

    getCurrentSearch && getCurrentSearch()

  }

  const handleWhitelisted = async () => {
    dispatch(scroll.setIsRequestSent(true));
    currentPages[0] !== 0 && dispatch(scroll.setNoScroll(true));
    dispatch(scroll.setWrapperCardRef(null));

    let checkedItems = [];
    for (const key in profiles) {
      checkedItems = [ ...checkedItems, ...profiles[key].filter(el => el.isChecked) ];
    }

    setIsPulsing(false);

    let changedProfiles = profiles;
    const profileIds = checkedItems.map(el => el.id);
    const payload = {
      profileIds,
      note: whitelistData?.note?.note || null,
      reason: whitelistData?.label || null
    }
    const res = await clientApi.profile.bulkWhitelistOfProfiles(payload);

    if (res.status === 200) {
      for (const key in changedProfiles) {
        const arr = changedProfiles[key].map(item => profileIds.includes(item.id)
          ? ({
            ...item,
            isChecked: false,
            isWhitelisted: true,
            whitelistedAt: moment.utc().local(),
            whitelistedReason: whitelistData?.label || null,
            meta: item.meta.map(el => el.field === 'WHITELIST' ? ({ ...el, value: 'On'}) : el)
          })
          : item
        );
        changedProfiles[key] = arr;
      }
    }
    enqueueSnackbar(`Profile(s) "${joinStringArray(checkedItems.map(e => e.name))}" were successfully added to whitelist!`, { variant: 'success' });

    if (isShowWhitelisted) {
      dispatch(backgroundCheck.setChangedProfiles(changedProfiles));
    } else {
      await updateCurrentPage();
    }

    setWhitelistData(null);
    setOpenModal(false);

    dispatch(scroll.setIsRequestSent(false));
  }

  const selectAll = () => {
    let changedProfiles = profiles;
    changedProfiles[currentPages[0]] = changedProfiles[currentPages[0]].map(el => ({ ...el, isChecked: !selectAllProfiles }));

    dispatch(backgroundCheck.setSelectAllProfiles(!selectAllProfiles));
    dispatch(backgroundCheck.setChangedProfiles(changedProfiles));
  }

  // WAITING BE INTEGATION FOR FILTER
  // const handleChangeType = useCallback(async (checked, value) => {
  //   let types = null;
  //   if (checked) {
  //     types = [ ...listTypes, value ];
  //     setListTypes(types);
  //   } else {
  //     types = listTypes.filter(el => el !== value);
  //     setListTypes(types);
  //   }

  //   const data = { ...currentSearch, listTypes: types };

  //   dispatch(backgroundCheck.setDefault());
  //   dispatch(backgroundCheck.updateCurrentSearch(data));

  //   await clientApi.search.searchEntities(data);
  // }, [currentSearch, listTypes, setListTypes, dispatch])

  const profilesArr = Object.values(profiles);
  const countSelected = useMemo(() => {
    if (!isEmpty(profiles)) {
      let checkedItems = [];
      for (const key in profiles) {
        checkedItems = [ ...checkedItems, ...profiles[key].filter(el => el.isChecked) ];
      }

      return checkedItems?.length;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilesArr, profiles])

  const handleShowWhitelisted = (e) => {
    dispatch(backgroundCheck.setShowWhitelisted(e.target.checked))
    !!e.target.checked
      ? localStorage.setItem('isShowWhitelisted', e.target.checked)
      : localStorage.removeItem('isShowWhitelisted');
    dispatch(backgroundCheck.setSelectAllProfiles(false));
  }

  const buffer = () => {
    let selectedAll = [];
    let checkedProfiles = {};
    for (const key in profiles) {
      if (isEmpty(checkedProfiles)) {
        checkedProfiles = profiles[key].find(el => el.isChecked);
      }
      selectedAll = [ ...selectedAll, ...profiles[key].filter(el => el.isChecked) ];
    }
    return { selectedAll, checkedProfiles };
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mt={'3px'}
      mr={'28px'}
      width={isShowRightCard ? 'calc(100% - 318px)' : 'unset'}
    >
      {/* WAITING BE INTEGATION FOR FILTER
        <PopoverSearchFilter
          handleChangeType={handleChangeType}
          listTypes={listTypes}
        /> 
      */}
      <div />
      <Box display="flex" alignItems="center">
        {!hideMonitoring &&
          <SetMonitoring />
        }
        <CustomTooltip
          title={!!profilesVersionId
            ? 'Only the last version can be updated, the previous versions are read only'
            : 'Include Whitelisted'
          }
          placement="top"
          disableHoverListener={!!isShowWhitelisted}
        >
          <FormControlLabel
            classes={{
              root: classes.showWhitelistedRoot,
              label: classes.showWhitelistedLabel
            }}
            labelPlacement="end"
            label="Show whitelisted"
            control={
              <CheckboxLight
                className="checkbox-user-card"
              />
            }
            onChange={handleShowWhitelisted}
            checked={!!isShowWhitelisted}
            disabled={!!profilesVersionId}
          />
        </CustomTooltip>
        <CustomTooltip
          title={!!profilesVersionId
            ? 'Only the last version can be updated, the previous versions are read only'
            : 'Before whitelisting, select profiles'
          }
          placement="top"
          disableHoverListener={selectAllProfiles || buffer().selectedAll.every(el => el) || !isEmpty(buffer().checkedProfiles)}
        >
          <Box mx={1}>
            <Badge invisible={!countSelected} badgeContent={countSelected} classes={{ badge: classes.badge }}>
              <CustomIconButton
                onClick={() => setOpenModal(true)}
                startIcon={(isEmpty(buffer().checkedProfiles) || !!profilesVersionId) ? <WhitelistInactiveIcon /> : <WhitelistIcon />}
                className={clsx(['minLabel', (!!countSelected && isPulsing) && classes.pulsingButton])}
                disabled={isEmpty(buffer().checkedProfiles) || !!profilesVersionId}
              >
                Whitelist selected
              </CustomIconButton>
            </Badge>
          </Box>
        </CustomTooltip>
        <CustomTooltip
          title="Only the last version can be updated, the previous versions are read only"
          placement="top"
          disableHoverListener={!profilesVersionId}
        >
          <FormControlLabel
            style={{ marginRight: 0 }}
            classes={{
              root: classes.showWhitelistedRoot,
              label: classes.showWhitelistedLabel
            }}
            labelPlacement="start"
            label="Select all"
            control={
              <CheckboxLight
                className="checkbox-user-card"
              />
            }
            onChange={selectAll}
            checked={!!profilesVersionId ? false : selectAllProfiles}
            disabled={!!profilesVersionId}
          />
        </CustomTooltip>
      </Box>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title="Profile Whitelist"
        actions={
          [
            {
              type: 'main',
              label: 'Cancel',
              action: () => {
                setOpenModal(false);
                setWhitelistData(null);
              },
            },
            {
              type: 'secondary',
              label: 'Whitelist',
              action: () => handleWhitelisted(),
              disabled: !!profilesVersionId || !whitelistData
            },
          ]
        }
        propsClassses={classes.customButtonModalWidth}
        actionsDirection="row"
        content={<ModalContent setData={setWhitelistData} type="profile" disabled={!!profilesVersionId} />}
      />
    </Box>
  )
}

import documentTypeDto from './document-type-dto';

const documentCategoryDto = {
  read: data => ({
    id: data.id,
    name: data.name,
    ownedBy: data.ownedBy,
    usageTypes: data.usageTypes,
    types: data?.types?.map(
      type => documentTypeDto.read(type)
    ),
    isCustom: data.ownedBy === 'USER',
    rule: null
  }),
  write: data => ({
    name: data.name
  })
}

export default documentCategoryDto
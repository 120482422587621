import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select,
  TextField, Typography, withStyles, OutlinedInput, IconButton, makeStyles
} from '@material-ui/core';
import * as yup from 'yup';
import parseYupErrors from '@app/yup';
import Checkbox from '@components/inputs/checkbox';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { WarningIcon, AddActive2Icon, DeleteIcon } from '@app/icons';
import { cloneDeep, isEmpty } from 'lodash'
import isTrueIncludeZero from '@utils/isTrueIncludeZero';

const useStyles = makeStyles(theme => ({
  answer: {
    '&:hover': {
      '& .showOnHover': {
        opacity: 1,
        transition: 'opacity 0.4s'
      }
    },
    '& .showOnHover': {
      opacity: 0,
    }
  },
  iconButton: {
    padding: 0,
  },
}))

const SmallTextField = withStyles(theme => ({
  root: {
    height: 28,
  },
  input: {
    fontSize: '12px',
    padding: '0px 12px',
  }
}))(OutlinedInput)

const SmallSelect = withStyles(theme => ({
  outlined: {
    padding: 0,
    paddingRight: '0px !important',
    textAlign: 'center',
    width: 32,
    fontSize: '11px',
  },
  icon: {
    width: 0,
  },
}))(Select)

const defaultRiskWeightTypes = [
  'BACKGROUND_CHECK_RISK_LIST',
  'NO_ANSWER',
  'COUNTRY_LIST',
  'FREE_TEXT',
]

const types = {
  FREE_TEXT: 'Free Text',
  BOOLEAN: 'Boolean',
  LIST: 'Pick List',
  NO_ANSWER: 'No Answer'
}

const listTypes = {
  LIST: 'Custom List',
  COUNTRY_LIST: 'Countries',
  BACKGROUND_CHECK_RISK_LIST: 'BG Check Risk Score',
}

const DEFAULT_RISK_WEIGHT = {
  useAnswerValue: false,
  useAnswerWeight: false,
  answerValue: '',
  answerWeight: '',
}

// possibleAnswers and booleanAnswers use the same format
// we separate them so we don't reuse the same key and override each other
// case: User change model type between LIST and BOOLEAN, their inputs is kept between changes
const DEFAULT_DATA = {
  questionText: '',
  type: '',
  type2: 'LIST',
  possibleAnswers: [
    { answerText: '', ...DEFAULT_RISK_WEIGHT },
  ],
  booleanAnswers: [
    { answerText: 'true', ...DEFAULT_RISK_WEIGHT },
    { answerText: 'false', ...DEFAULT_RISK_WEIGHT },
  ],
  useDefaultPerAnswer: true,
  userInput: { ...DEFAULT_RISK_WEIGHT },
}

const schema = yup.object().shape({
  questionText: yup.string().required().trim().label('Question text'),
  type: yup.string().required().label('Answer type'),
  possibleAnswers: yup.array().nullable().when('type', {
    is: 'LIST',
    then: yup.array().when('type2', {
      is: 'LIST',
      then: yup.array().min(1, 'At least 1 list item should be defined')
        .test('all_text_filled', 'Item name is missing', function () {
          if (!this.parent.possibleAnswers) return true;
          return this.parent.possibleAnswers.every(e => !!e.answerText.trim())
        })
    })
  })
});

const RiskValueInput = ({ label, isActive, handleCheck, value, handleChange }) => {
  return (
    <Box>
      {label && <InputLabel style={{ fontSize: '10px' }}>{label}</InputLabel>}
      <Box display="flex" alignItems="center" pt={label ? 1 : 0}>
        <Box style={{ marginRight: 8, marginLeft: -2 }}>
          <Checkbox style={{ margin: 0 }} onClick={handleCheck} checked={isActive} />
        </Box>
        <SmallSelect
          variant="outlined"
          value={value ?? ''}
          style={{ height: 28 }}
          onChange={handleChange}
        >
          {[...Array(11)].map((x, index) => index).map(value => (
            <MenuItem value={value} key={value}>{value}</MenuItem>
          ))}
        </SmallSelect>
      </Box>
    </Box>
  )
}

export default function CustomQuestionDialog({ open, onClose, onSave, sourceData = null }) {
  const classes = useStyles();
  const [model, setModel] = useState(cloneDeep(DEFAULT_DATA))

  const [errors, setErrors] = useState({})

  const getUniqueId = () => Math.random().toString(36).substring(7)

  useEffect(() => {
    setErrors({})
    if (!sourceData) {
      setModel({
        ...DEFAULT_DATA,
        id: getUniqueId(),
        userInput: {
          ...DEFAULT_DATA.userInput,
        }
      })
    } else {
      let userInput = sourceData.userInput
      if (userInput) {
        if (isTrueIncludeZero(userInput.answerValue)) userInput.useAnswerValue = true;
        if (isTrueIncludeZero(userInput.answerWeight)) userInput.useAnswerWeight = true;
      } else {
        userInput = {}
      }

      const model = {
        ...cloneDeep(DEFAULT_DATA),
        ...sourceData,
        id: sourceData.id,
        userInput: {
          ...DEFAULT_DATA.userInput,
          ...userInput,
        },
      }

      if (sourceData.type === 'BOOLEAN' || sourceData.type === 'LIST') {
        const answerName = sourceData.type === 'BOOLEAN' ? 'booleanAnswers' : 'possibleAnswers';
        const possibleAnswers = sourceData.possibleAnswers?.map(answer => ({
          ...answer,
          useAnswerValue: isTrueIncludeZero(answer.answerValue),
          useAnswerWeight: isTrueIncludeZero(answer.answerWeight),
        })) || [];

        model[answerName] = possibleAnswers
        model.useDefaultPerAnswer = isEmpty(userInput)
      } else {
        model.possibleAnswers = cloneDeep(DEFAULT_DATA.possibleAnswers)
        model.booleanAnswers = cloneDeep(DEFAULT_DATA.booleanAnswers)
      }

      // Ensure boolean always have yes no
      if (sourceData.type === 'BOOLEAN' && model.booleanAnswers.length !== 2) {
        model.booleanAnswers = cloneDeep(DEFAULT_DATA.booleanAnswers)
      }

      if (Object.keys(listTypes).includes(model.type)) {
        model.type = 'LIST';
        model.type2 = sourceData.type;
      }

      setModel(model)
    }
    // eslint-disable-next-line
  }, [open])

  const isCustom = useMemo(() => !(
    sourceData && !sourceData.isCustom
    && sourceData.questionText === model.questionText
    && (model.type === 'LIST' ? sourceData.type === model.type2 : sourceData.type === model.type)
    && (sourceData.type !== 'LIST' || sourceData.possibleAnswers === model.possibleAnswers)
  ), [sourceData, model])

  const handleRiskWeightChange = (name, value) => {
    const newModel = {
      ...model,
      userInput: {
        ...model.userInput,
        [name]: value,
      }
    }

    if (name === 'answerValue') newModel.userInput.useAnswerValue = true
    if (name === 'answerWeight') newModel.userInput.useAnswerWeight = true

    setModel(newModel)
  }

  const handleAnswersChange = (answerName, index, name, value) => {
    const newAnswers = cloneDeep(model[answerName])
    newAnswers[index][name] = value;

    if (name === 'answerValue') newAnswers[index].useAnswerValue = true
    if (name === 'answerWeight') newAnswers[index].useAnswerWeight = true

    const newModel = {
      ...model,
      [answerName]: newAnswers
    }

    setModel(newModel)
    setErrors({})
  }

  const addAnswer = (answerName) => {
    const newAnswers = model[answerName].slice()
    newAnswers.push({ answerText: '', ...DEFAULT_RISK_WEIGHT })

    const newModel = {
      ...model,
      [answerName]: newAnswers
    }

    setModel(newModel)
    setErrors({})
  }

  const deleteAnswer = (answerName, index) => {
    const newAnswers = model[answerName].slice()
    newAnswers.splice(index, 1)

    const newModel = {
      ...model,
      [answerName]: newAnswers
    }

    setModel(newModel)
    setErrors({})
  }

  const onInternalSave = () => {
    setErrors({});
    schema
      .validate(model, { abortEarly: false })
      .then(() => {
        const data = {
          ...model,
          isCustom: isCustom,
          id: !isCustom ? model.id : getUniqueId(),
          type: model.type === 'LIST' ? model.type2 : model.type,
          type2: undefined,
          userInput: null,
          useDefaultPerAnswer: undefined,
          possibleAnswers: undefined,
        }

        const addUserInput = (allowEmpty) => {
          const userInput = {}
          if (model.userInput.useAnswerValue) userInput.answerValue = model.userInput.answerValue
          if (model.userInput.useAnswerWeight) userInput.answerWeight = model.userInput.answerWeight
  
          if (allowEmpty || !isEmpty(userInput)) {
            data.userInput = {
              ...userInput,
              excluded: false,
            }
          }
        }

        if (defaultRiskWeightTypes.includes(data.type)) {
          addUserInput();
        } else { // Boolean or list
          const answersName = model.type === 'BOOLEAN' ? 'booleanAnswers' : 'possibleAnswers';
          if (model.useDefaultPerAnswer) {
            data.possibleAnswers = model[answersName].map(answer => ({
              answerText: answer.answerText,
              answerValue: answer.useAnswerValue ? answer.answerValue : null,
              answerWeight: answer.useAnswerWeight ? answer.answerWeight : null,
            }))
          } else {
            data.possibleAnswers = model[answersName].map(answer => ({ answerText: answer.answerText }))
            addUserInput(true);
          }
        }

        onSave(data)
      })
      .catch(err => {
        console.log({ err, model })
        setErrors(
          parseYupErrors(err)
        )
      })
  }

  const showDefaultRiskWeight = model.type === 'LIST'
    ? defaultRiskWeightTypes.includes(model.type2)
    : defaultRiskWeightTypes.includes(model.type)

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={(e) => e.preventDefault()}>
        <DialogTitle>
          {!sourceData ? 'Add Question' : 'Update Question'}
        </DialogTitle>
        <DialogContent>
          <Box width={'400px'}>
            <TextField
              value={model.questionText}
              onChange={event => {
                setErrors({})
                setModel({
                  ...model,
                  questionText: event.target.value
                })
              }}
              label="Question Text"
              variant="outlined"
              fullWidth
              error={!!errors.questionText}
              helperText={!!errors.questionText && errors.questionText}
            />
            <FormControl
              variant="outlined"
              fullWidth
              error={!!errors.type}
            >
              <InputLabel>Answer Type</InputLabel>
              <Select
                value={model.type}
                onChange={event => {
                  setErrors({})
                  setModel({
                    ...model,
                    type: event.target.value,
                  })
                }}
              >
                {Object.entries(types).map(qtData => (
                  <MenuItem key={qtData[0]} value={qtData[0]}>
                    {qtData[1]}
                  </MenuItem>
                ))}
              </Select>
              {!!errors.type &&
                <FormHelperText>
                  {errors.type}
                </FormHelperText>
              }
            </FormControl>

            <Collapse in={model.type === 'LIST'}>
              <FormControl
                variant="outlined"
                fullWidth
                error={!!errors.type}
              >
                <InputLabel>Pick List Type</InputLabel>
                <Select
                  value={model.type2}
                  onChange={event => {
                    setErrors({})
                    setModel({
                      ...model,
                      type2: event.target.value,
                    })
                  }}
                >
                  {Object.entries(listTypes).map(qtData => (
                    <MenuItem key={qtData[0]} value={qtData[0]}>
                      {qtData[1]}
                    </MenuItem>
                  ))}
                </Select>
                {!!errors.type &&
                  <FormHelperText>
                    {errors.type}
                  </FormHelperText>
                }
              </FormControl>
            </Collapse>

            <Collapse in={!!model.type && !showDefaultRiskWeight}>
              <Box mt={1} pl="20px">
                <FormControlLabel
                  control={(
                    <Checkbox
                      style={{ marginTop: 0, marginBottom: 0 }}
                      onClick={() => setModel({ ...model, useDefaultPerAnswer: !model.useDefaultPerAnswer })}
                      checked={model.useDefaultPerAnswer}
                    />
                  )}
                  label="Define default risk and weight per answer"
                />
              </Box>
            </Collapse>

            <Collapse in={!!model.type && (showDefaultRiskWeight || !model.useDefaultPerAnswer)}>
              <Box display="flex" mt={2} pl="20px">
                <Box mr={4}>
                  <RiskValueInput
                    label="Risk (Default)"
                    isActive={model.userInput.useAnswerValue}
                    handleCheck={() => handleRiskWeightChange('useAnswerValue', !model.userInput.useAnswerValue)}
                    value={model.userInput.answerValue}
                    handleChange={(e) => handleRiskWeightChange('answerValue', e.target.value)}
                  />
                </Box>
                <Box>
                  <RiskValueInput
                    label="Weight (Default)"
                    isActive={model.userInput.useAnswerWeight}
                    handleCheck={() => handleRiskWeightChange('useAnswerWeight', !model.userInput.useAnswerWeight)}
                    value={model.userInput.answerWeight}
                    handleChange={(e) => handleRiskWeightChange('answerWeight', e.target.value)}
                  />
                </Box>
              </Box>
            </Collapse>

            <Collapse in={model.type === 'BOOLEAN' && model.useDefaultPerAnswer}>
              <Box mt={2}>
                <Card style={{ cursor: 'default' }}>
                  <Box p={3}>
                    <table style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          <td><InputLabel style={{ fontSize: '10px' }}>Selection</InputLabel></td>
                          <td style={{ width: 85 }}><InputLabel style={{ fontSize: '10px' }}>Risk (Default)</InputLabel></td>
                          <td style={{ width: 70 }}><InputLabel style={{ fontSize: '10px' }}>Weight (Default)</InputLabel></td>
                        </tr>
                        {model.booleanAnswers.map((answer, index) => (
                          <tr key={index}>
                            <td valign="middle" style={{ paddingTop: 8 }}><Typography variant="h5">{answer.answerText === 'true' ? 'Yes' : 'No'}</Typography></td>
                            <td style={{ paddingTop: 8 }}>
                              <RiskValueInput
                                isActive={answer.useAnswerValue}
                                handleCheck={() => handleAnswersChange('booleanAnswers', index, 'useAnswerValue', !answer.useAnswerValue)}
                                value={answer.answerValue}
                                handleChange={(e) => handleAnswersChange('booleanAnswers', index, 'answerValue', e.target.value)}
                              />
                            </td>
                            <td style={{ paddingTop: 8 }}>
                              <RiskValueInput
                                isActive={answer.useAnswerWeight}
                                handleCheck={() => handleAnswersChange('booleanAnswers', index, 'useAnswerWeight', !answer.useAnswerWeight)}
                                value={answer.answerWeight}
                                handleChange={(e) => handleAnswersChange('booleanAnswers', index, 'answerWeight', e.target.value)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Box>
                </Card>
              </Box>
            </Collapse>

            <Collapse in={model.type === 'LIST' && model.type2 === 'LIST'}>
              <Box mt={2}>
                <Box mb={1} pl="18px" display={'flex'} alignItems="center">
                  <WarningIcon />
                  <Typography variant={'body1'} style={{ paddingLeft: 6 }}>
                    Enter your list in the area below
                  </Typography>
                </Box>
                <Card style={{ cursor: 'default', minHeight: 150 }}>
                  <Box p={2} pr={0}>
                    <Box p={1} pr={0} style={{ maxHeight: 'calc(90vh - 700px)', overflowY: 'auto' }}>
                      <table  style={{ width: '100%', borderSpacing: 0 }}>
                        <tbody>
                          <tr>
                            <td><InputLabel style={{ fontSize: '10px' }}>List Items</InputLabel></td>
                            <td style={{ width: 85 }}>
                              {model.useDefaultPerAnswer && <InputLabel style={{ fontSize: '10px' }}>Risk (Default)</InputLabel>}
                            </td>
                            <td style={{ width: 70 }}>
                              {model.useDefaultPerAnswer && <InputLabel style={{ fontSize: '10px' }}>Weight (Default)</InputLabel>}
                            </td>
                            <td style={{ width: 30 }}>
                            </td>
                          </tr>
                          {model.possibleAnswers?.map((answer, index) => (
                            <tr className={classes.answer} key={index}>
                              <td valign="middle" style={{ paddingTop: 8 }}>
                                <SmallTextField
                                  value={answer.answerText}
                                  onChange={(e) => handleAnswersChange('possibleAnswers', index, 'answerText', e.target.value)}
                                  placeholder="Item name"
                                  style={{ width: '95%' }}
                                  error={!!errors.possibleAnswers && !answer.answerText.trim()}
                                />
                                {(!!errors.possibleAnswers && !answer.answerText.trim()) &&
                                  <FormHelperText error style={{ textAlign: 'left', paddingLeft: 12 }}>
                                    {errors.possibleAnswers}
                                  </FormHelperText>
                                }
                              </td>
                              <td style={{ paddingTop: 8, width: 85 }}>
                                {model.useDefaultPerAnswer &&
                                  <RiskValueInput
                                    isActive={answer.useAnswerValue}
                                    handleCheck={() => handleAnswersChange('possibleAnswers', index, 'useAnswerValue', !answer.useAnswerValue)}
                                    value={answer.answerValue}
                                    handleChange={(e) => handleAnswersChange('possibleAnswers', index, 'answerValue', e.target.value)}
                                  />
                                }
                              </td>
                              <td style={{ paddingTop: 8, width: 75 }}>
                                {model.useDefaultPerAnswer &&
                                  <RiskValueInput
                                    isActive={answer.useAnswerWeight}
                                    handleCheck={() => handleAnswersChange('possibleAnswers', index, 'useAnswerWeight', !answer.useAnswerWeight)}
                                    value={answer.answerWeight}
                                    handleChange={(e) => handleAnswersChange('possibleAnswers', index, 'answerWeight', e.target.value)}
                                  />
                                }
                              </td>
                              <td valign="center" className="showOnHover" style={{ paddingTop: 8 }}>
                                {model.possibleAnswers.length > 1 &&
                                  <IconButton className={classes.iconButton} onClick={() => deleteAnswer('possibleAnswers', index)}>
                                    <DeleteIcon width={20} height={20} />
                                  </IconButton>
                                }
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Box>
                    <Box display={'flex'} p={1}>
                      <ButtonWithIcon
                        startIcon={<AddActive2Icon />}
                        onClick={() => addAnswer('possibleAnswers')}
                      >
                        Add item
                      </ButtonWithIcon>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </Collapse>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button style={{ width: '160px' }} variant={'outlined'} onClick={onClose}>
            CANCEL
          </Button>
          <Button style={{ width: '160px' }} variant={'contained'} onClick={onInternalSave} type="submit">
            SAVE
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
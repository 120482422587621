import MyDocuments from '@views/protected/client/documents';

const routes = [
  {
    path: '/documents/:tab?',
    component: MyDocuments,
    guest: false,
  },
];

export default routes;

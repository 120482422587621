import React from 'react';

import { Button, Box, Link } from '@material-ui/core';
import { Logo } from '@app/icons';
import history from '@app/history';

export default function Footer(props) {

  return (
    <Box display="flex" className="footer" justifyContent="space-between" >
      <Box display="flex" className="logo" >
        <Button onClick={ () => history.push('/') } className="logo-button" disableRipple>
          <Logo className="logo" />
        </Button>
      </Box>
      <Box display="flex" className="footer-copyright" flexDirection="column" >
        <Box><b>Copyright © Commercial Passport Corporation {new Date().getFullYear()} - All rights reserved</b></Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center" mt={1}>
          <Link
            variant={'body1'}
            style={{ fontSize: 10 }}
            href="/terms-of-use"
            target="_blank"
          >
                        Terms and Conditions
          </Link>
          <Box mx={1} height="80%" width="1px" style={{ backgroundColor: 'black' }}>
          </Box>
          <Link
            variant={'body1'}
            style={{ fontSize: 10 }}
            href="/privacy-policy"
            target="_blank"
          >
                        Privacy Policy
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
import React from  'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const FilledInput = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      backgroundColor: '#F8F8F8',
      borderRadius: 8,
      fontSize: 12,
      margin: 0,
      border: '1px solid white',
      '& input': {
        fontSize: 12,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 10,
        color: theme.palette.common.black,
        fontWeight: 500,
      },
      '& input::placeholder': {
        fontSize: 12,
        fontWeight: 500,
        opacity: 1,
        color: '#656565',
      },
      '&:hover': {
        margin: 0,
        border: '1px solid #CCC'
      }
    }
  },
}))(TextField);

export default function Input({ id, inputProps, InputProps, ...other }) {

  return (
    <FilledInput
      id={id}
      size="small"
      InputProps={{
        disableUnderline: true,
        inputProps: {...inputProps},
        ...InputProps,
      }}
      {...other}
    />
  )
} 

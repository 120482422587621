const config = {
  api: (process.env.REACT_APP_API_BASEURL || 'https://api-dev.kycpassport.com/api/v1/'),
  cognito: {
    client_id: (process.env.REACT_APP_COGNITO_CLIENT_ID || '3u4s87lgv1eucgoftf2mubp3hh'),
    user_pool_id: (process.env.REACT_APP_COGNITO_USER_POOL_ID || 'eu-west-2_7kfTtE0lK')
  },
  datadog: {
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_SERVICE,
  }
}

export default config;

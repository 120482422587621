import React from 'react';
import { Box, Typography } from '@material-ui/core';

import GearImg from '../../assets/landing/backgrounds/maintenance.png';
import LayoutHeader from '@components/layout/header';
import Footer from '@components/layout/footer';
import { useSelector } from 'react-redux';


export default function PersonalizedMaintenance() {
  const firstName = useSelector(state => state.auth.user && state.auth.user.name);

  return (
    <React.Fragment>
      <LayoutHeader isMaintenance={true} />

      <Box display="flex" pt={15} pb={5} justifyContent="center" alignItems="center" style={{ backgroundColor: '#D5F0F0', maxWidth: '100%', overflowX: 'hidden' }}>
        <img alt={'gear'} src={GearImg} style={{ height: 'calc(100vh - 110px - 120px - 40px)' }} />
        <Box zIndex={2} style={{ position: 'absolute', maxWidth: 350 }}>
          <Typography variant="h1" align="center">
            Under Maintenance
          </Typography>
          <Typography variant="body1" align="center" style={{ marginTop: 20 }}>
            Sorry {firstName}, the system is currently maintained by our admins, it will be available in the next 2 hours.
          </Typography>
        </Box>
      </Box>
      <Footer />

    </React.Fragment>
  );
}
const setDefault = () => ({
  type: 'SET_GLOBAL_DEFAULT'
});

const setData = (data) => ({
  type: 'SET_DATA',
  data
});

const setSearch = (search) => ({
  type: 'SET_SEARCH',
  search
});

const setSearchType = (searchType) => ({
  type: 'SET_SEARCH_TYPE',
  searchType
});

const setCounters = (counters) => ({
  type: 'SET_COUNTERS',
  counters
});

const setPage = (page) => ({
  type: 'SET_PAGE',
  page
});

const globalSearch = {
  setDefault,
  setData,
  setSearch,
  setSearchType,
  setCounters,
  setPage,
}

export default globalSearch;

import React, { useState, useEffect, useMemo } from 'react';
import { clientApi } from '@app/api';

import {
  Box,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  Switch,
  Popover,
  Typography,
} from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import { saveAs } from 'file-saver';
import { Modal } from '@components/modals';

import InlineHeadBadge from '@components/badges/inline-head-badge';
import InnerHeader from '@components/layout/inner-header';
import ViewToggle from '@components/controls/view-toggle';
import OutlinedSearch from '@components/inputs/outlined-search';
import MenuWithContext from '@components/dropdowns/menu-with-context';
import history from '@app/history';
import { prepareClient, getSortField, getSortFields } from '@dto/client';
import CustomTooltip from '@components/controls/tooltip';
import CardList from '@components/lists/card-list';
import TableList, { TCELL_TYPES } from '@components/lists/table-list';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import NoResultsFound from '@components/layout/no-results-found';
import CustomChip from '@components/controls/custom-chip';
import {
  RemoveActiveIcon,
  ContinuousScanningActiveIcon,
  DownloadIcon,
  SearchIcon,
  SearchLegalEntity,
  Delete2Icon
} from '@app/icons';
import { useLocation, useParams } from 'react-router-dom';
import DeleteDialog from './delete-dialog';
import { withLoggedNotFound } from '@views/protected/not-found';
import { useSelector, useDispatch } from 'react-redux';
import { ongoingMonittoring } from '@store/actions';
import { useStyles } from './styles';
import { useSnackbar } from 'notistack';
import FilterWithContext from '@components/dropdowns/filter-with-context';
import getRiskRatingLabel from '@utils/riskRatingLabel';
import { useFirstRender } from '@hooks/useFirstRender';
import { outdatedChip } from '../../../../components/badges/outdated-chip';

const reviewChangeWarning = (item) => {
  if (item.pendingReview) {
    return (
      <CustomTooltip title="Review changes" placement="top">
        <Box ml="4px">
          <CustomChip label="REVIEW" background="#7A5B44" labelColor="#FFF" />
        </Box>
      </CustomTooltip>
    )
  }
  return null;
}

const combineDecoration = (item) => {
  return (
    <React.Fragment>
      {reviewChangeWarning(item)}
      {outdatedChip(item)}
    </React.Fragment>
  )
}

const TABLE_COLUMNS_BOTH = [
  { cellType: TCELL_TYPES.TEXT_ICON, field: 'name', headerName: 'Name', bold: true, decoration: combineDecoration, colProps: { width: '30%' } },
  { field: 'folder_name', headerName: 'Folder' },
  { field: 'risk_score', headerName: 'Risk Rating', colProps: { style: { textTransform: 'capitalize' } } },
  { field: 'last_review_at', headerName: 'Last Review' },
  { field: 'next_review_at', headerName: 'Next Review' },
  { cellType: TCELL_TYPES.ICON_BUTTON, field: 'remove', icon: <RemoveActiveIcon />, tooltip: 'Remove from Ongoing Monitoring' }
]

const TABLE_COLUMNS_PERSON = [
  { cellType: TCELL_TYPES.TEXT_ICON, field: 'name', headerName: 'Full name', decoration: combineDecoration, bold: true, colProps: { width: '30%' } },
  { field: 'folder_name', headerName: 'Folder' },
  { field: 'citizenship', headerName: 'Citizenship' },
  { field: 'risk_score', headerName: 'Risk Rating', colProps: { style: { textTransform: 'capitalize' } } },
  { field: 'last_review_at', headerName: 'Last Review' },
  { field: 'next_review_at', headerName: 'Next Review' },
  { cellType: TCELL_TYPES.ICON_BUTTON, field: 'remove', icon: <RemoveActiveIcon />, tooltip: 'Remove from Ongoing Monitoring' }
]

const TABLE_COLUMNS_COMPANY = [
  { cellType: TCELL_TYPES.TEXT_ICON, field: 'name', headerName: 'Name', bold: true, decoration: combineDecoration, colProps: { width: '30%' } },
  { field: 'folder_name', headerName: 'Folder' },
  { field: 'country', headerName: 'Country' },
  { field: 'risk_score', headerName: 'Risk Rating', colProps: { style: { textTransform: 'capitalize' } } },
  { field: 'last_review_at', headerName: 'Last Review' },
  { field: 'next_review_at', headerName: 'Next Review' },
  { cellType: TCELL_TYPES.ICON_BUTTON, field: 'remove', icon: <RemoveActiveIcon />, tooltip: 'Remove from Ongoing Monitoring' }
]

const defaultFilters = [
  { type: 'toggle', isChecked: false, value: 'closely-monitoring', label: 'Closely Monitoring', name: 'riskRating' },
  { type: 'toggle', isChecked: false, value: 'high', label: 'High', name: 'riskRating' },
  { type: 'toggle', isChecked: false, value: 'medium', label: 'Medium', name: 'riskRating' },
  { type: 'toggle', isChecked: false, value: 'low', label: 'Low', name: 'riskRating' },
  { type: 'toggle', isChecked: false, value: 'exempted', label: 'Exempted', name: 'riskRating' },
  { type: 'toggle', isChecked: false, value: 'CORPORATION', label: 'Corporation', name: 'legalEntityType' },
  { type: 'toggle', isChecked: false, value: 'FOUNDATION', label: 'Foundation', name: 'legalEntityType' },
  { type: 'toggle', isChecked: false, value: 'LIMITED_LIABILITY_COMPANY', label: 'Limited Liability Company (LLC)', name: 'legalEntityType' },
  { type: 'toggle', isChecked: false, value: 'PARTNERSHIP', label: 'Partnership', name: 'legalEntityType' },
  { type: 'toggle', isChecked: false, value: 'TRUST', label: 'Trust', name: 'legalEntityType' },
]

function Index(props) {
  const firstRender = useFirstRender();
  const { tab } = useParams();
  if (!['tab-all', 'tab-persons', 'tab-companies', undefined].includes(tab)) {
    props.pageNotFound();
  }

  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);
  const locView = locSearch.get('view');

  const { monitorySort, monitoryFilters, monitorySortDirection } = useSelector(store => store.ongoingMonittoring);
  const dispatch = useDispatch();

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar()

  const tabs = [
    {
      id: 'tab-all',
      label: 'All',
    },
    {
      id: 'tab-persons',
      label: 'People',
    },
    {
      id: 'tab-companies',
      label: 'Legal Entities',
    }
  ]

  // Pagination
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(12) //eslint-disable-line
  const [pagesCount, setPagesCount] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [totalNormalCount, setTotalNormalCount] = useState(0)
  const [totalForReviewCount, setTotalForReviewCount] = useState(0)
  const onPageChange = (event, page) => {
    setPage(page - 1)
  }

  const [tabID, setTabID] = useState(tab || 'tab-all');
  const [useForReview, setUseForReview] = useState(locView === 'review');
  const [view, setView] = useState('list'); // list/cards
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [sort, setSort] = useState(monitorySort || 'name');
  const [sortDirection, setSortDirection] = useState(monitorySortDirection || 'ASC');
  const [filters, setFilters] = useState(monitoryFilters || defaultFilters);
  const [pendingDelete, setPendingDelete] = useState(null);
  const [csvModalOpen, setCsvModalOpen] = useState(false);
  const [filterAnchor, setFilterAnchor] = useState(null);

  const [type, clientType] = useMemo(() => {
    const tabTypeMap = {
      'tab-all': 'all',
      'tab-persons': 'person',
      'tab-companies': 'company'
    }

    const clientTypeMap = {
      'tab-all': null,
      'tab-persons': 'PERSON',
      'tab-companies': 'LEGAL_ENTITY'
    }

    return [tabTypeMap[tabID], clientTypeMap[tabID]]
  }, [tabID])

  useEffect(() => {
    return () => {
      dispatch(ongoingMonittoring.setDefault());
    }
  }, [dispatch])

  useEffect(() => {
    if (!firstRender) {
      const timeOutId = setTimeout(() => fetchItems(), 1000);
      return () => clearTimeout(timeOutId);
    }
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    fetchItems()
    // eslint-disable-next-line
  }, [tabID, page, filters, sort, sortDirection, useForReview])

  const generateParams = () => {
    const riskRatings = filters.filter(el => el.name === 'riskRating' && el.isChecked)?.map(el => el.value);
    const legalEntityTypes = filters.filter(el => el.name === 'legalEntityType' && el.isChecked).map(el => el.value);

    return {
      pageNumber: page,
      pageSize,
      searchKeyword: search?.trim() || null,
      sortBy: sort && getSortField(sort),
      sortDirection: sortDirection && sortDirection,
      monitory: true,
      riskRatings,
      legalEntityTypes: type === 'company' ? legalEntityTypes : undefined,
      clientType,
    }
  }

  const fetchItems = async () => {
    if (search.length < 2 && search.length) {
      enqueueSnackbar('Search keyword should have at least 2 characters!', {
        variant: 'error',
      });
      return;
    }

    const params = generateParams();
    const pendingReviewParams = {
      ...params,
      pendingReview: true,
    }

    const [data, pendingReviewData] = await Promise.all([
      clientApi.client.search(params),
      clientApi.client.search(pendingReviewParams),
    ])

    const dataToUse = (useForReview ? pendingReviewData : data).data;
    setData((dataToUse?.entries || []).map(item =>
      ({ ...prepareClient(item, true), isChecked: false, linkedSearches: item.linkedSearches, pendingReview: item.pendingReview })
    ))
    const newPages = dataToUse?.pages || 1;
    setPagesCount(newPages);
    setTotalCount(dataToUse?.count || 0);

    setTotalNormalCount(data?.data.count || 0);
    setTotalForReviewCount(pendingReviewData?.data.count || 0);

    if (page > newPages - 1) {
      setPage(newPages - 1);
    }
  }

  const listData = useMemo(() => data.map(item => ({
    ...item,
    risk_score: getRiskRatingLabel(item.risk_score)
  })), [data])

  const onTabChange = (index) => {
    const tabID = tabs[index].id;
    setTabID(tabID);
    setPage(0);
    history.push(`/ongoing-monitoring/${tabID}`)
  }

  const onForReviewChange = (forReview) => () => {
    if (forReview !== useForReview) {
      setUseForReview(forReview);
      setPage(0);
    }
  }

  const onSelect = (id, isChecked) => {
    if (id === 'all') {
      const newData = data.map(item => ({ ...item, isChecked }))
      setData(newData);
    } else {
      const newData = data.map(
        (item) => item.id === id
          ? ({ ...item, isChecked: !item.isChecked })
          : item
      );
      setData(newData);
    }
  }

  const onSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(0);
  }

  const onFilterChange = (event, name) => {
    const newFilters = filters.map(el => el.value === event.target.value
      ? ({ ...el, isChecked: event.target.checked })
      : el
    );
    setFilters(newFilters)
    dispatch(ongoingMonittoring.setSortAndFilters({ sort, filters: newFilters, sortDirection }));
  }

  const onSort = (value) => {
    setPage(0)

    if (sort !== value) {
      setSort(value)
      setSortDirection('ASC')
      dispatch(ongoingMonittoring.setSortAndFilters({ value, filters, sortDirection: 'ASC' }));
    } else {
      const sorting = sortDirection === 'ASC' ? 'DESC' : 'ASC'
      setSortDirection(sorting)
      dispatch(ongoingMonittoring.setSortAndFilters({ sort, filters, sortDirection: sorting }));
    }
  }

  const handleView = (id, item) => {
    if (item.linkedSearches.length === 1) {
      history.push(`/search-history/results?searchId=${item.linkedSearches[0]}&searchName=${item.name}&searchType=${item.client_type}`);
    } else {
      history.push(`/ongoing-monitoring/history/${id}`)
    }
  }

  const handleAdd = () => {
    history.push(`/ongoing-monitoring/add-monitoring/${type}`)
  }

  const hasSearched = useMemo(() => {
    if (!!search) {
      return true;
    }

    return !!filters.find((el) => el.isChecked);
  }, [filters, search])

  const onDelete = (id) => {
    const item = data.find(v => v.id === id);
    setPendingDelete({
      type,
      id,
      searchIds: item.linkedSearches,
      name:
        type === 'person'
          ? [item.first_name, item.last_name].join(' ')
          : item.name
    })
  }

  const onBatchDelete = () => {
    let searchIds = [];
    const ids = [];
    data.forEach((item) => {
      if (item['isChecked']) {
        ids.push(item.id);
        searchIds = searchIds.concat(item.linkedSearches)
      }
    })
    setPendingDelete({
      type,
      searchIds,
      ids
    })
  }

  const onExportCSV = (exportAll) => {
    const params = {
      ...generateParams(),
      pageSize: 100000,
      pageNumber: 0,
      clientType,
    }

    if (exportAll) params.clientType = undefined;

    clientApi.csv.export(params).then((res) => {
      enqueueSnackbar('The selected list of clients were successfully exported!', { variant: 'success' })
      const file = new Blob([res.data], { type: 'text/csv' });
      saveAs(file, res.headers['content-disposition'].split('filename=')[1]);
    });
    setCsvModalOpen(false);
  }

  const onResetFilter = filterName => {
    setFilters(filters.map(
      (filter, iFilter) => filter.name === filterName ? defaultFilters[iFilter] : filter)
    )
  }

  const renderedFilterBadges = useMemo(() => {
    const badges = []
    // Risk Filters applied
    const riskRatingOptions = filters.filter(f => f.name === 'riskRating')
    const riskRatingSelected = riskRatingOptions.filter(f => f.isChecked)
    if (riskRatingSelected.length) {
      badges.push({
        name: 'riskRating',
        content: `Risk Rating: ${riskRatingSelected.map(f => f.label).join(',')}`
      })
    }
    // Legal entity type
    const legalEntityTypeOptions = filters.filter(f => f.name === 'legalEntityType')
    const legalEntityTypeSelected = legalEntityTypeOptions.filter(f => f.isChecked)
    if (legalEntityTypeSelected.length && type === 'company') {
      badges.push({
        name: 'legalEntityType',
        content: `Legal Entity Type: ${legalEntityTypeSelected.map(f => f.label).join(',')}`
      })
    }
    return badges.map(badge => (
      <Box key={`filter-badge-${badge.name}`} ml={1}>
        <CustomChip
          label={badge.content}
          deleteIcon={
            <Box className={classes.deleteChipIcon}>
              <Delete2Icon viewBox="0 0 17 12" />
            </Box>
          }
          onDelete={() => onResetFilter(badge.name)}
          deleteIconStyle={classes.chipDeleteIcon}
        />
      </Box>
    ))
  }, [filters, type]) //eslint-disable-line

  const countSelected = useMemo(() => {
    return data.filter(e => e.isChecked).length;
  }, [data])

  const columns = useMemo(() => {
    const columnsMap = {
      'tab-all': TABLE_COLUMNS_BOTH,
      'tab-persons': TABLE_COLUMNS_PERSON,
      'tab-companies': TABLE_COLUMNS_COMPANY
    }
    return columnsMap[tabID]
  }, [tabID])

  const clientTerms = useMemo(() => {
    const columnsMap = {
      'tab-all': 'Clients',
      'tab-persons': 'People',
      'tab-companies': 'Legal Entities'
    }
    return columnsMap[tabID]
  }, [tabID])

  return (
    <React.Fragment>
      <DeleteDialog
        data={pendingDelete}
        open={!!pendingDelete}
        onDeleted={() => {
          setPendingDelete(null)
          fetchItems().then()
        }}
        onClose={() => {
          setPendingDelete(null)
        }}
      />
      <Modal
        open={csvModalOpen}
        onClose={() => setCsvModalOpen(false)}
        title="Export CSV"
        mainText={`Do you want to export your entire client list or a list of ${type === 'person' ? 'individuals' : 'legal entities'} only?`}
        actions={
          [
            {
              type: 'main',
              label: `${clientTerms} Only`,
              action: () => onExportCSV(false),
              style: { width: 240 },
            },
            {
              type: 'secondary',
              label: 'All Clients',
              action: () => onExportCSV(true),
              style: { width: 240 },
            },
          ]
        }
        actionsDirection="row"
      />

      <Popover
        open={!!filterAnchor}
        anchorEl={filterAnchor}
        onClose={() => setFilterAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        classes={{ paper: classes.popover }}
      >
      </Popover>

      <Box px={6}>
        <InnerHeader
          onTabChange={onTabChange}
          ind={tabs.findIndex(tab => tab.id === tabID)}
          tabs={tabs}
          buttons={
            <React.Fragment>
              <ButtonWithIcon
                startIcon={<DownloadIcon />}
                onClick={() => { type === 'all' ? onExportCSV(true) : setCsvModalOpen(true) }}
                disabled={totalCount === 0}
                tooltip={totalCount === 0 ? 'You will need at least one entity to enable Export CSV' : undefined}
              >
                Export CSV
              </ButtonWithIcon>
              <ButtonWithIcon startIcon={<ContinuousScanningActiveIcon />} onClick={handleAdd}>
                Add to Ongoing Monitoring
              </ButtonWithIcon>
              <MenuWithContext badge={countSelected}>
                <MenuItem disabled={!countSelected} onClick={onBatchDelete}>Remove</MenuItem>
              </MenuWithContext>
            </React.Fragment>
          }
        />
      </Box>
      <Box display={'flex'} flexGrow={'1'} px={6} mt={2} flexDirection={'column'}>
        <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
          <Box display={'flex'} alignItems="center">
            <Box mr={2}>
              <InlineHeadBadge
                badgeColor={type === 'person' ? '#D5F0F0' : '#D1C2B6'}
                badgeValue={totalNormalCount}
                onClick={onForReviewChange(false)}
                selected={!useForReview}
              >
                {clientTerms}
              </InlineHeadBadge>
            </Box>
            <Box>
              <InlineHeadBadge
                badgeColor={type === 'person' ? '#D5F0F0' : '#D1C2B6'}
                badgeValue={totalForReviewCount}
                onClick={onForReviewChange(true)}
                selected={useForReview}
              >
                {clientTerms}{' for Review'}
              </InlineHeadBadge>
            </Box>
          </Box>
          <Box display={'flex'} flexGrow={1} flexWrap={'wrap'} style={{ minHeight: '48px' }} alignItems={'center'}>
            {renderedFilterBadges}
          </Box>
          <Box display={'flex'}>
            <FilterWithContext isActive={!!renderedFilterBadges.length}>
              <Box pr={3}>
                <Grid container spacing={4}>
                  <Grid item xs={type === 'company' ? 4: 6}>
                    <Box display={'flex'} pt={1} pb={1}>
                      <Typography variant="h5">
                        Sort By
                      </Typography>
                    </Box>
                    {type === 'all' &&
                      <RadioGroup name="sort" value={sort} onChange={e => setSort(e.target.value)}>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'name'}
                            label={'Name'}
                          />
                        </Box>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'next_review_at'}
                            label={'Date of Next Review'}
                          />
                        </Box>
                      </RadioGroup>
                    }
                    {type === 'person' &&
                      <RadioGroup name="sort" value={sort} onChange={e => setSort(e.target.value)}>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'name'}
                            label={'Name'}
                          />
                        </Box>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'citizenship'}
                            label={'Citizenship'}
                          />
                        </Box>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'residency'}
                            label={'Residency'}
                          />
                        </Box>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'gender'}
                            label={'Gender'}
                          />
                        </Box>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'next_review_at'}
                            label={'Date of Next Review'}
                          />
                        </Box>
                      </RadioGroup>
                    }
                    {type === 'company' &&
                      <RadioGroup name="sort" value={sort} onChange={e => setSort(e.target.value)}>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'name'}
                            label={'Name'}
                          />
                        </Box>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'country'}
                            label={'Country'}
                          />
                        </Box>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'formed_at'}
                            label={'Formation Date'}
                          />
                        </Box>
                        <Box display={'flex'} alignItems={'center'} height={'40px'}>
                          <FormControlLabel
                            control={<Radio />}
                            value={'next_review_at'}
                            label={'Date of Next Review'}
                          />
                        </Box>
                      </RadioGroup>
                    }
                  </Grid>
                  <Grid item xs={type === 'company' ? 4: 6}>
                    <Box display={'flex'} pt={1} pb={1}>
                      <Typography variant="h5">
                        Risk Rating
                      </Typography>
                    </Box>
                    {filters
                      .filter(f => f.name === 'riskRating')
                      .map((el, i) => (
                        <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value}-${i}`}>
                          <FormControlLabel
                            control={<Switch value={el.value} checked={el.isChecked} onClick={event => onFilterChange(event, el.name)} />}
                            label={el.label}
                          />
                        </Box>
                      ))}
                  </Grid>
                  {type === 'company' && (
                    <Grid item xs={4}>
                      <Box display={'flex'} pt={1} pb={1}>
                        <Typography variant="h5">
                          Legal Entity Type
                        </Typography>
                      </Box>
                      {filters
                        .filter(el => el.name === 'legalEntityType')
                        .map((el, i) => (
                          <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value}-${i}`}>
                            <FormControlLabel
                              control={<Switch value={el.value} checked={el.isChecked} onClick={event => onFilterChange(event, el.name)} />}
                              label={el.label}
                            />
                          </Box>
                        ))}
                    </Grid>
                  )}
                </Grid>
              </Box>
            </FilterWithContext>
            <ViewToggle value={view} onChange={setView} />
          </Box>
        </Box>
        <Box display={'flex'} mt={1}>
          <OutlinedSearch
            value={search}
            onChange={onSearchChange}
            label={`Search ${clientTerms.toLowerCase()} by name, folder or risk rating`}
            icons={tabID === 'tab-persons'
              ? <SearchIcon />
              : <SearchLegalEntity />
            }
          />
        </Box>
        {!!data.length &&
          <Box display={'flex'} mt={2}>
            {view === 'cards' &&
              <CardList
                onSelect={onSelect}
                extended
                items={data}
                page={page}
                pagesCount={pagesCount}
                pageSize={pageSize}
                totalCount={totalCount}
                onPageChange={onPageChange}
                maxHeight="calc(100vh - 390px)"
                onClick={handleView}
                type={type}
                actions={[
                  { label: 'View', handler: handleView },
                ]}
                companyLabels={{
                  risk_score: 'RISK RATING',
                  type: 'TYPE',
                  formed_at: 'FORMATION DATE',
                  country: 'COUNTRY',
                  last_review_at: 'LAST REVIEW',
                  next_review_at: 'NEXT REVIEW',
                }}
                personLabels={{
                  risk_score: 'RISK RATING',
                  whitelist: 'WHITELIST',
                  date_of_birth: 'DOB',
                  gender: 'GENDER',
                  citizenship: 'CITIZENSHIP',
                  last_review_at: 'LAST REVIEW',
                  next_review_at: 'NEXT REVIEW',
                }}
                allLabels={{
                  risk_score: 'RISK RATING',
                  last_review_at: 'LAST REVIEW',
                  next_review_at: 'NEXT REVIEW',
                }}
              />
            }
            {view === 'list' &&
              <Grid container>
                <Grid item sm={12}>
                  <TableList
                    columns={columns}
                    items={listData}
                    onSelect={onSelect}
                    selectAll
                    page={page}
                    pageSize={pageSize}
                    pagesCount={pagesCount}
                    totalCount={totalCount}
                    onPageChange={onPageChange}
                    sortableColumns={Object.keys(getSortFields())}
                    onSort={onSort}
                    sort={sort}
                    sortDirection={sortDirection}
                    maxHeight="calc(100vh - 380px)"
                    onClick={handleView}
                    threeDotsActions={[
                      { label: 'View', handler: handleView },
                    ]}
                    handlers={{
                      remove: onDelete,
                    }}
                  />
                </Grid>
              </Grid>
            }
          </Box>
        }
        {!data.length &&
          <Box mt={16} flexGrow={1}>
            <NoResultsFound>
              {hasSearched
                ? 'No entities found for the applied filters'
                : useForReview
                  ? 'When entities added on ongoing monitoring have new hits, they will appear here'
                  : 'When you add entities on ongoing monitoring, they will appear here'
              }
            </NoResultsFound>
          </Box>
        }
      </Box>
    </React.Fragment>
  )
}

export default withLoggedNotFound(Index, { tab: ['tab-all', 'tab-persons', 'tab-companies', undefined] });
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import TableList, { TCELL_TYPES } from '@components/lists/table-list';
import OutlinedSearch from '@components/inputs/outlined-search';
import InlineHeadBadge from '@components/badges/inline-head-badge';
import { prepareDataForPaginations } from '@utils/prepareDataForPaginations';
import NoResultsFound from '@components/layout/no-results-found';
import { Modal } from '@components/modals';
import prepareTimestamp from '@utils/date';
import { clients } from '@app/api/admin';

import { useSelector, useDispatch } from 'react-redux';
import { jobsManagement } from '@store/actions';
import { isEmpty } from 'lodash';

export const useStyles = makeStyles((theme) => ({
  linkified: {
    textDecoration: 'none',
    color: theme.palette.text.LB3,
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  customButtonModalWidth: {
    width: '180px',
  },
  modalContentWrapper: {
    maxWidth: '550px',
    maxHeight: '350px',
    overflowY: 'auto'
  }
}));

const pageSize = 12;

export default function Jobs() {
  const { jobs } = useSelector(state => state.jobsManagment);
  const dispatch = useDispatch();

  const classes = useStyles();

  const [backup, setBackup] = useState(null);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [pagesCount, setPagesCount] = useState({});
  const [totalCount, setTotalCount] = useState(null);
  const [openModal, setOpenModal] = useState(null);

  const getJobs = useCallback(async () => {
    const jobsData = (await clients.getJobs(page, pageSize)).data;

    if (jobsData) {
      const prepareData = jobsData?.entries?.map(el => ({
        ...el,
        createdAt: el?.createdAt && prepareTimestamp(el?.createdAt, 'MM/DD/YYYY'),
        // ...el?.meta,
        ...el?.triggeredByUser,
        totalMessageCount: el?.meta?.totalMessageCount || '0',
        successfullyProcessedMessageCount: el?.meta?.successfullyProcessedMessageCount || '0',
        failedMessages: Object.keys(el?.meta?.failedMessages)?.length || '0',
        userId: el?.triggeredByUser?.id,
        id: el.id,
      }))

      setTotalCount(jobsData.count);
      setPagesCount(count => ({ ...count, original: jobsData.pages }));
      dispatch(jobsManagement.setJobs(prepareData));
      setData(prepareData);
    }
  }, [page, dispatch])

  useEffect(() => {
    getJobs();
  }, [getJobs, page])
  
  useEffect(() => {
    if (backup) {
      setData(backup[page]);
    }
  }, [backup, page])

  const handleData = useCallback((dataset) => {
    setTotalCount(dataset.length);
    const prepareData = prepareDataForPaginations(dataset, pageSize);
    setPagesCount(count => ({ ...count, added: Object.keys(prepareData).length }));
    setBackup(prepareData);
  }, [])

  const onSearchChange = useCallback((event) => {
    setPage(0);
    setSearch(event.target.value);

    const filtered = jobs.filter(el => el.email.toLowerCase().includes(event.target.value?.trim()?.toLowerCase()))
    handleData(filtered);
  }, [jobs, handleData])

  const failedMessageItem = useCallback((item) => {
    let value = item.failedMessages;

    return (
      <span
        className={!isEmpty(item.meta?.failedMessages) && classes.linkified}
        onClick={() => !isEmpty(item.meta?.failedMessages) && setOpenModal(item?.meta?.failedMessages)}
      >
        {value}
      </span>
    )
  }, [classes])

  const TABLE_COLUMNS = [
    { field: 'id', headerName: 'Job ID' },
    { field: 'email', headerName: 'Triggered By' },
    { field: 'type', headerName: 'Type' },
    { field: 'createdAt', headerName: 'Created On' },
    { field: 'totalMessageCount', headerName: 'Total' },
    { cellType: TCELL_TYPES.CUSTOM, field: 'failedMessages', headerName: 'Failed', custom: failedMessageItem },
    { field: 'successfullyProcessedMessageCount', headerName: 'Successful' },
    { field: 'status', headerName: 'Status' },
  ]

  const formattedData = useMemo(() => {
    return data?.map(e => {
      const item = { ...e };

      switch (item.status) {
        case 'FINISHED':
          item.cellProps = {
            status: {
              style: {
                color: '#4E922C'
              }
            }
          }
          break;

        case 'STARTED':
          item.cellProps = {
            status: {
              style: {
                color: '#B6514C'
              }
            }
          }
          break;

        default:
          break;
      }

      return item;
    }) || [];
  }, [data])

  const renderModalContent = useCallback(() => {
    return (
      <Box mx={2} mt={2} className={classes.modalContentWrapper}>
        {Object.values(openModal).map(el => (
          <Box mb={2}>
            <Typography variant="body1">{el}</Typography>
          </Box>
        ))}
      </Box>
    )
  }, [openModal, classes])

  return (
    <Box display={'flex'} flexGrow={'1'} mt={2} flexDirection={'column'}>
      <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'}>
          <Box mr={1}>
            <InlineHeadBadge
              badgeColor={'#CAD7CA'}
              badgeValue={totalCount}
            >
              Monitoring Jobs
            </InlineHeadBadge>
          </Box>
        </Box>
      </Box>
      <Box display={'flex'} mb={3}>
        <OutlinedSearch
          value={search}
          onChange={onSearchChange}
          label="Search jobs"
        />
      </Box>
      {!!formattedData.length ? (
        <Grid container>
          <Grid item sm={12}>
            <TableList
              columns={TABLE_COLUMNS}
              items={formattedData}
              onPageChange={(event, value) => setPage(value - 1)}
              page={page}
              pageSize={pageSize}
              pagesCount={!!search.trim() ? pagesCount?.added : pagesCount?.original}
              totalCount={totalCount}
              maxHeight="calc(100vh - 380px)"
            />
          </Grid>
        </Grid>
      ) : (
        <Box mt={25} flexGrow={1}>
          <NoResultsFound>
            The list of jobs will appear here
          </NoResultsFound>
        </Box>
      )}
      <Modal
        open={!!openModal}
        onClose={() => setOpenModal(null)}
        title="Failed Messages"
        actions={
          [
            {
              type: 'secondary',
              label: 'OK',
              action: () => setOpenModal(null),
            },
          ]
        }
        propsClassses={classes.customButtonModalWidth}
        actionsDirection="row"
        content={!!openModal && renderModalContent()}
      />
    </Box>
  )
}

import React, {useEffect, useMemo, useState} from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
  InputLabel,
  ListItemText,
  ListItemIcon,
  Chip,
  InputAdornment
} from '@material-ui/core';
import {countryCodeList} from '@services/country-service';
import SelectClearable from '@components/inputs/select-clearable';
import {entityTypes} from '@utils/entityType';
//import {useSnackbar} from 'notistack';
import SelectSearchable from '@components/inputs/select-searchable';
import {Autocomplete} from '@material-ui/lab';
import ReactCountryFlag from 'react-country-flag'
import {entitySettingsMode} from '../../utils/modeUtil';
import {EditIcon} from '@app/icons'

export default function Details({
  data,
  setData,
  errors,
  clone = false,
  multiple = false,
  mode,
  readOnly,
  formType,
  openChangeNameDialog
}) {

  //const { enqueueSnackbar } = useSnackbar()

  const [entityType, setEntityType] = useState('PERSON')

  useEffect(() => {
    if (data?.clientType) {
      setEntityType(
        data.clientType === 'PERSON'
          ? 'PERSON'
          : data.legalEntityType
      )
    }
  }, []) //eslint-disable-line

  useEffect(() => {
    if (entityType) {
      setData({
        ...data,
        ...{
          clientType: entityType === 'PERSON'
            ? 'PERSON'
            : 'LEGAL_ENTITY',
          legalEntityType: entityType !== 'PERSON'
            ? entityType
            : null
        }
      })
    }
  }, [setData, entityType]) //eslint-disable-line

  const onChange = field =>
    e => setData({
      ...data,
      [field]: e.target.value
    })

  const jurisdictionsOptions = useMemo(() =>
    Object.keys(countryCodeList).map(optionValue =>
      ({ label: countryCodeList[optionValue], value: optionValue })
    ),
  [countryCodeList]) // eslint-disable-line

  return (
    <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
      <Box display="flex" justifyContent="center" mt={2}>
        <Typography variant="h4">Entity Details</Typography>
      </Box>
      <Box display="flex" mt={4}>
        <Grid container>

          <Grid container justify={'center'}>
            <Grid item xs={10} md={10} lg={6}>
              <TextField
                value={data?.name || ''}
                onChange={onChange('name')}
                fullWidth
                label="Entity Name"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name}
                disabled={!!readOnly || formType === 'edit'}
                InputProps={{
                  endAdornment: formType === 'edit' &&
                    (
                      <InputAdornment
                        position="end"
                        onClick={openChangeNameDialog}
                        style={{
                          cursor: 'pointer'
                        }}
                      >
                        <EditIcon />
                      </InputAdornment>
                    )
                }}
              />
            </Grid>
          </Grid>

          { mode === entitySettingsMode.JURISDICTION && (
            <Grid container justify={'center'}>
              <Grid item xs={10} md={10} lg={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                >
                  <InputLabel>Entity Type</InputLabel>
                  <SelectClearable
                    value={entityType}
                    onChange={event => {
                      setEntityType(event.target.value)
                    }}
                    clearable={false}
                    disabled={!!readOnly}
                    renderValue={(entityType) => {
                      const type = entityTypes?.find(e => e.name === entityType)
                      return (
                        <Box display={'flex'}>
                          <Box display={'flex'} style={{width: 20, paddingRight: 16}}>
                            {{
                              ...type.icon,
                              props: {
                                style: {
                                  height: 20
                                }
                              }
                            }}
                          </Box>
                          <Box display={'flex'}>
                            {type.label}
                          </Box>
                        </Box>
                      )
                    }}
                  >
                    { entityTypes.map(type => (
                      <MenuItem
                        key={type.name}
                        value={type.name}
                      >
                        <ListItemIcon
                          style={{
                            minWidth: 20,
                            width: 20,
                            paddingRight: 16
                          }}>
                          {{
                            ...type.icon,
                            props: {
                              style: {
                                height: 20
                              }
                            }
                          }}
                        </ListItemIcon>
                        <ListItemText>
                          {type.label}
                        </ListItemText>
                      </MenuItem>
                    ))}
                  </SelectClearable>
                </FormControl>
              </Grid>
            </Grid>
          )}

          { (mode === entitySettingsMode.TYPE || !mode || clone) && (
            <Grid container justify={'center'}>
              <Grid item xs={10} md={10} lg={6}>
                {/* Multiple Jurisdictions */}
                { multiple && (
                  <Autocomplete
                    multiple
                    options={jurisdictionsOptions}
                    getOptionLabel={({label}) => label}
                    filterSelectedOptions
                    filterOptions={
                      (options, params) =>
                        options.filter(opt => (data?.jurisdictions || []).indexOf(opt.value) === -1)
                    }
                    value={jurisdictionsOptions.filter(jo => (data?.jurisdictions || []).indexOf(jo.value) !== -1)}
                    onChange={(event, newValue) => {
                      setData({
                        ...data,
                        jurisdictions: newValue.map(c => c.value)
                      });
                    }}
                    renderOption={({value, label}) => (
                      <Box display={'flex'} alignItems={'center'}>
                        <Box display={'flex'}>
                          <ReactCountryFlag
                            countryCode={value}
                            style={{
                              fontSize: '1.4em',
                              lineHeight: '2em',
                            }}
                          />
                        </Box>
                        <Box display={'flex'} flexGrow={1} pl={2}>
                          {label}
                        </Box>
                      </Box>
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={
                            <Box display={'flex'} alignItems={'center'}>
                              <Box display={'flex'}>
                                <ReactCountryFlag
                                  countryCode={option.value}
                                  style={{
                                    fontSize: '1.4em',
                                    lineHeight: '2em',
                                  }}
                                />
                              </Box>
                              <Box display={'flex'} flexGrow={1} pl={1}>
                                {option.label}
                              </Box>
                            </Box>
                          }
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Jurisdictions"
                      />
                    )}
                  />
                )}
                {/* Single jurisdiction */}
                { !multiple && (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={!!errors?.jurisdiction}
                  >
                    <SelectSearchable
                      value={data?.jurisdiction}
                      onChange={onChange('jurisdiction')}
                      options={jurisdictionsOptions}
                      label={'Jurisdiction'}
                      disabled={!mode || !!readOnly}
                    />
                    { errors?.jurisdiction &&
                      <FormHelperText>{errors?.jurisdiction}</FormHelperText>
                    }
                  </FormControl>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  )
}
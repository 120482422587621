import reducerRegistry from '@app/store/reducer-registry';

import { clientApi } from '@app/api';
const initialState = {
  categories: null,
  types: null,
  selectCategoryDialogOpen: false,
  selectTypeDialogOpen: null
};

const REDUCER_NAME = 'entity-settings';
const PREFIX = `app/${REDUCER_NAME}/`;

const SET_CATEGORIES = `${PREFIX}/set-categories`
const SET_TYPES = `${PREFIX}/set-types`
const SET_SELECT_CATEGORY_DIALOG_OPEN = `${PREFIX}/set-select-category-dialog-open`
const SET_SELECT_TYPE_DIALOG_OPEN = `${PREFIX}/set-select-type-dialog-open`

// reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      };
    case SET_TYPES:
      return {
        ...state,
        types: action.payload
      };
    case SET_SELECT_CATEGORY_DIALOG_OPEN:
      return {
        ...state,
        selectCategoryDialogOpen: action.payload
      };
    case SET_SELECT_TYPE_DIALOG_OPEN:
      return {
        ...state,
        selectTypeDialogOpen: action.payload
      };
    default:
      return state;
  }
}
reducerRegistry.register(REDUCER_NAME, reducer);

// selectors
export const selectCategories = state => state[REDUCER_NAME].categories;
export const selectTypes = state => state[REDUCER_NAME].types;
export const selectCategoryDialogOpen = state => state[REDUCER_NAME].selectCategoryDialogOpen;
export const selectTypeDialogOpen = state => state[REDUCER_NAME].selectTypeDialogOpen;

// action creators
export const setCategories = payload => ({ payload, type: SET_CATEGORIES });
export const setTypes = payload => ({ payload, type: SET_TYPES });
export const setSelectCategoryDialog = payload => ({ payload, type: SET_SELECT_CATEGORY_DIALOG_OPEN });
export const setSelectTypeDialogOpen = payload => ({ payload, type: SET_SELECT_TYPE_DIALOG_OPEN });
//
const fetchCategories = (force = false) => async (dispatch, getState) => {
  if(!force && getState()[REDUCER_NAME].categories)
    return
  const categoriesData = (await clientApi.clientDocumentCategory.index()).data.entries
  return dispatch(
    setCategories(categoriesData)
  )
}

const fetchTypes = (force = false) => async (dispatch, getState) => {
  if(!force && getState()[REDUCER_NAME].types)
    return
  const typesData = (await clientApi.clientDocumentType.index()).data.entries
  return dispatch(
    setTypes(typesData)
  )
}

export {
  fetchCategories,
  fetchTypes
}
import React from  'react';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { InfoOtherIcon } from '@app/icons';
import CustomButton from '@views/common/button';
import CustomTooltip from '@components/controls/tooltip';
import history from '@app/history';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '20px',
    color: theme.palette.text.GY4,
  },
  text: {
    fontWeight: 400,
    color: theme.palette.text.GY5,
  },
  customButtonWidth: {
    width: '162px',
    margin: '5px'
  },
  noQuantityLabel: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.text.BL1,
    marginBottom: 10,
  },
  noQuantityText: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.text.BL1,
  },
}))

const tooltipTitle = 'Some features are restricted in this view. For an extensive searching experience please Login or Register.';

export default function SubPagesFooter({ quantitySearches, noProfiles, searchId, profileId, searchName }) {
  const classes = useStyles();
  
  const redirect = (path) => {
    const link = profileId
      ? `/${path}?searchId=${searchId}&profileId=${profileId}&searchName=${searchName}`
      : searchId
        ? `/${path}?searchId=${searchId}&searchName=${searchName}`
        : `/${path}`;

    history.push(link);
  }

  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} mt={quantitySearches !== null ? 2 : '100px'}>
      {quantitySearches !== null ? (
        !noProfiles && (
          <React.Fragment>
            <CustomTooltip
              placement="top"
              title={tooltipTitle}
            >
              <span>
                <Button classes={{ label: classes.label }}>
                  LOAD MORE
                </Button>
              </span>
            </CustomTooltip>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} mt={1} mb={4}>
              <InfoOtherIcon />
              <Typography className={clsx(classes.label, classes.text)}>
                Some features are restricted in the view above. For an extensive searching experience please login into your account.
              </Typography>
            </Box>
          </React.Fragment>
        )
      ) : (
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} mt={3} mb={4}>
          <Typography className={classes.noQuantityLabel}>
            There aren't any free searches left. 
          </Typography>
          <Typography className={classes.noQuantityText}>
            Register to receive more search credits in the free trial.
          </Typography>
        </Box>
      )}
      <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
        <CustomButton
          action={() => history.push('/login')}
          label="LOGIN"
          customClasses="customButtonBordered"
          propsClassses={classes.customButtonWidth}
        />
        <CustomButton
          action={() => redirect('signup')}
          label="REGISTER"
          customClasses="customButtonStandard"
          propsClassses={classes.customButtonWidth}
        />
      </Box>
    </Box>
  )
};
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Grid, Typography, Box, IconButton } from '@material-ui/core';
import CustomChip from '@components/controls/custom-chip';
import CustomTooltip from '@components/controls/tooltip';
import CustomButton from '@views/common/button';
import { History2InactiveIcon, ChevronLeftIcon } from '@app/icons';
import prepareTimestamp from '@utils/date';

import { useLocation } from 'react-router-dom';
import { clientApi } from '@app/api';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useWindowSize} from '@utils/useWindowSize';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: props => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: props.isShowRightCard ? 'calc(100% - 298px)' : 'unset',
    marginRight: '28px',
  }),
  profilesText: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '12px',
    color: theme.palette.text.GY5,
  },
  lastRunOnText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '20px',
    color: theme.palette.text.GY4,
  },
  userNameText: {
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '30px',
    color: theme.palette.text.BL1,
  },
  headerChipsWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  tagsLine: {
    '& .MuiChip-root': {
      marginRight: 5,
    }
  },
  customButtonSize: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '30px',
    marginLeft: '15px',
    padding: '2px 16px !important',
    borderRadius: '25px !important',
    '& .MuiButton-label': {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '13px',
      lineHeight: '12px',
    }
  },
  backBtn: {
    padding: 0,
  },
}))

export default function HeaderTitleLine({
  tags,
  isResize,
  lastRunOn,
  searchName,
  setIsResize,
  isShowRightCard,
  setIsShowRightCard,
  isNoResetResize,
  setIsNoResetResize,
  onBack,
  getCurrentSearch,
  currentSearch,
}) {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [searchId] = useState(search.get('searchId'));

  const [width] = useWindowSize();
  const widthRef = useRef();

  const {
    quantityProfiles,
  } = useSelector(store => store.backgroundCheck);
  const { searchVersions, profilesVersionId, profilesVersion } = useSelector(store => store.searchHistory);

  const classes = useStyles({ isShowRightCard, isResize });
  const { enqueueSnackbar } = useSnackbar();

  const isAsknowledge = profilesVersionId
    ? !profilesVersion.acknowledged
    : !currentSearch.acknowledged

  const handleResize = useCallback(() => {
    if (width <= 1600 && (isAsknowledge || isShowRightCard || !!searchVersions.length)) {
      setIsResize(true);
    } else if (width <= 1800 && width >= 1600 && (isShowRightCard || isAsknowledge)) {
      setIsResize(true);
    } else if (width <= 1920 && width >= 1800 && isShowRightCard && isAsknowledge) {
      setIsResize(true);
    }
  }, [width, isAsknowledge, isShowRightCard, setIsResize, searchVersions.length])

  useEffect(() => {
    if (width !== 0) {
      handleResize();
    }
  }, [width, handleResize])
  
  useEffect(() => {
    if (widthRef.current && widthRef.current.offsetWidth >= width / 2) {
      setIsResize(true);
      setIsNoResetResize(true);
    }
  }, [width, setIsResize, widthRef, setIsNoResetResize])

  // eslint-disable-next-line
  const handleAcknowledge = async () => {
    let res;
    if (profilesVersionId) {
      res = await clientApi.searchVersion.acknowledgeSearchVersion(profilesVersionId);
    } else {
      res = await clientApi.search.acknowledgeSearch(searchId);
    }

    if (res.status === 200) {
      getCurrentSearch();
      enqueueSnackbar('The changes were successfully acknowledged!', { variant: 'success' });
      !isNoResetResize && setIsResize(false);
    }
  }

  return (
    <Grid classes={{ root: classes.root }}>
      <Box ref={widthRef} display="flex" alignItems="center">
        {onBack &&
          <IconButton onClick={onBack} className={classes.backBtn}>
            <ChevronLeftIcon style={{ width: 36, height: 36 }} />
          </IconButton>
        }
        <Box>
          <Typography className={classes.profilesText}>
            {quantityProfiles} profile(s) found for:
          </Typography>
          <Box py="4px">
            <Typography className={classes.userNameText}>
              {searchName}
            </Typography>
          </Box>
          <Box className={classes.headerChipsWrapper}>
            {tags && tags.map((tag, i) => (
              <Box className={classes.tagsLine} key={`${tag}-${i}`}>
                <CustomChip
                  label={tag}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box direction="row" display="flex" justifyContent="flex-end" alignItems="center">
        {(profilesVersion.performedOn || lastRunOn) && (
          <Box direction="row" display="flex" alignItems="center" mr={1}>
            <CustomTooltip title="Last Updated" placement="top">
              <History2InactiveIcon />
            </CustomTooltip>
            <Typography className={classes.lastRunOnText}>
              {`${prepareTimestamp(profilesVersion.performedOn || lastRunOn, 'MM/DD/YYYY - hh:mm A')}`}
            </Typography>
          </Box>
        )}
        {isAsknowledge &&
          <CustomTooltip title="Confirm that you’ve reviewed the changes" placement="top">
            <span>
              <CustomButton
                action={() => handleAcknowledge()}
                label={'ACKNOWLEDGE'}
                customClasses="customButtonBordered"
                propsClassses={classes.customButtonSize}
              />
            </span>
          </CustomTooltip>
        }
        {!!searchVersions.length &&
          <CustomButton
            action={() => {
              setIsShowRightCard(!isShowRightCard);
              !isNoResetResize && setIsResize(false);
            }}
            label={isShowRightCard ? 'HIDE HISTORY' : 'SHOW HISTORY'}
            customClasses="customButtonBordered"
            propsClassses={classes.customButtonSize}
          />
        }
      </Box>
    </Grid>
  )
}

import React, { useMemo } from 'react';

import { Modal } from '@components/modals';
import history from '@app/history';

export default function OrganizeSuccess(props) {
  const { open, onClose, monitoringIdsExist } = props;

  const onPortfolio = () => {
    onClose();
    history.push('/portfolio');
  }

  const onMonitoring = () => {
    onClose();
    history.push('/ongoing-monitoring/import-add-monitoring');
  }

  const onViewMonitoring = () => {
    onClose();
    history.push('/ongoing-monitoring');
  }

  const actions = useMemo(() => {
    const actions = [
      {
        type: 'secondary',
        label: 'VIEW PORTFOLIO',
        action: onPortfolio,
        style: { width: 250 },
      },
    ];

    if (monitoringIdsExist) {
      actions.push({
        type: 'main',
        label: 'ADD TO MONITORING',
        action: onMonitoring,
        style: { width: 250 },
      })
    } else {
      actions.push({
        type: 'main',
        label: 'VIEW MONITORING',
        action: onViewMonitoring,
        style: { width: 250 },
      })
    }

    return actions;
    }, [monitoringIdsExist]) // eslint-disable-line

  return (
    <React.Fragment>
      <Modal
        open={open}
        title="Organized Successfully"
        mainText="Done!"
        additionalText={' Imported client(s) were successfully placed in the appropriate folders.'}
        actions={actions}
        actionsDirection="column"
      />
    </React.Fragment>
  );
}
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  rootWrapper: {
    margin: '40px 50px',
    width: 'auto',
  },
  search: {
    minWidth: '1027px',
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    opacity: 0.8,
    background: '#E5E5E5',
    pointerEvents: 'none',
  },
  loader: {
    height: '50px',
    width: '50px',
    animation: '$spinner 1s linear infinite',
  },
  '@keyframes spinner': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  customButtonWidth: props => ({
    width: props.delete ? '160px' : '240px',
  }),
  footerWrapper: {
    margin: '15px 0',
  },
  dividerLength: {
    margin: '10px 0',
  },
  searchMargin: {
    marginBottom: '20px',
  },
  modalText: {
    width: '406px',
    margin: '22px 0 0 0',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'center',
    color: theme.palette.text.BL1,
    whiteSpace: 'pre-wrap'
  },
}))

const setDefault = () => ({
  type: 'SET_BACKGROUND_SEARCH_DEFAULT'
});

const setCurrentPlan = (currentPlan) => ({
  type: 'SET_CURRENT_PLAN',
  currentPlan
});

const setSearchModel = (searchModel) => ({
  type: 'SET_SEARCH_MODEL',
  searchModel
});

const setIsError = (isError) => ({
  type: 'SET_IS_ERROR',
  isError
});

const backgroundSearch = {
  setDefault,
  setCurrentPlan,
  setSearchModel,
  setIsError,
}

export default backgroundSearch;

import Settings from '@views/protected/client/settings';

const routes = [
  {
    path: '/settings/:tab?',
    component: Settings,
    guest: false
  }
]

export default routes;
import React, { useEffect, useState } from 'react';
import { SidesLayout } from '@components/layout/sides-layout';
import LayoutHeader from '@components/layout/header';
import {
  Box,
  Typography,
  Link,
} from '@material-ui/core';

const reqex = /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i;

export default function PrivacyPolicy(props) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if(reqex.test(navigator.userAgent)){
      setIsMobile(true);
    }
    return () => {
      setIsMobile(false);
    }
  }, [])

  const Text = (props) => {
    return (
      <Box mb={2} {...props}>
        <Typography variant={props.variant || 'body1'} style={props?.variant === 'h3' ? { color: '#7A5B44' } : undefined}>
          {props.children}
        </Typography>
      </Box>
    )
  }

  const Wrapper = (props) => {
    if (isMobile) {
      return (
        <React.Fragment>
          <Box mb={6}><LayoutHeader /></Box>
          {props.children}
        </React.Fragment>
      )
    }

    return (
      <SidesLayout>{props.children}</SidesLayout>
    )
        
  }

  return (
    <Wrapper>
      <Box px={4} pb={2} maxHeight="calc(100vh - 16px)" style={{ overflowY: 'auto' }}>
        <Text variant="h1" mt={2}>
                    KYC Passport Privacy Policy
        </Text>
        <Text>
                    Paramount Policy: Commercial Passport Corporation (“CPC”) does not share your information.
        </Text>
        <Text>
                    Your privacy is of great importance to us. This Privacy Policy exists to tell you what kind of information we may gather while you visit our website and how we may use such information.
        </Text>
        <Text>
                    We do not collect any personally identifiable information about individuals with two exceptions: when such information is voluntarily submitted by the visitor through forms, and some incidental statistics of site usage which are collected automatically.
        </Text>
        <Box mb={2}>
          <ul>
            {[
              'Personal Information You Choose to Provide',
              'Information Automatically Collected and Stored',
              'Linked Sites',
              'User\'s Data',
              'Security',
              'Limitation of Liability',
              'Contact information',
              'Changes and Updates to this Policy'
            ].map(e => (
              <li><Typography variant="body1">{e}</Typography></li>
            ))}
          </ul>
        </Box>

        <Text mt={4} variant="h3">
                    Personal Information You Choose to Provide
        </Text>
        <Text>
                    In order to enable visitors of our website to see certain pages, when specifically requested by them, we require some contact information, (such as name, and e-mail address). The information collected shall be used for the purposes specified when it was collected, to respond to visitor’s inquiries, and fulfill e-mail messaging programs to notify visitors about new content or services on our website.
        </Text>
        <Text>
                    Any information given to us shall be stored and managed the greatest care, and will not be used in ways to which you have not consented. We will not sell, rent, or exchange such personally identifiable information to any other organization or entity. Notwithstanding the above, we may indeed disclose personal information if required to do so by subpoenas, court orders or legal process or when such disclosure is made for establishment or exercise of legal rights or in defending against legal claim, or as otherwise required by law.
        </Text>
        <Text>
                    If you decide you no longer wish to receive postal mailings and/or e-mails from us, please let us know by sending an e-mail or writing to us and telling us you no longer wish to receive postal mail and/or e-mail. Please provide us with your exact name, postal address, and/or e-mail address. We will make sure your name is removed from our mailing list.
        </Text>

        <Text mt={4} variant="h3">
                    Information Automatically Collected and Stored
        </Text>
        <Text>
                    In common with many websites, we also measure the performance of our website in order to improve and customize it in favor of our visitors. During your visit, we may automatically collect and temporarily store certain usage information about visits to our websites. This information may include information such as your web request, Internet Protocol (“IP”) address, browser type, referring/ existing pages and URLs, domain names, pages viewed and the date and time of your request and other such information that uniquely identify your browser. Such information is used for system administration and for producing usage statistics. We may entrust third parties with the described performance measurement. Such third parties will be required to use appropriate confidentiality and security measures.
        </Text>
        <Text>
                    Our website may deposit certain bits of information called “cookies” in a visitor’s computer. Generally, a cookie assigns a unique number to the visitor that has no meaning outside the assigning site. Cookies can tell us how and when pages in a website are visited and by how many people. This technology does not collect an individual visitor’s identifying information; rather, this information is also in an aggregate form. The purpose of this technology and the information it provides is again to help us improve our website. Most web browsers allow the user to deny or accept the cookie feature. However, please note that cookies may be necessary to provide you with certain features (e.g. customized delivery of information) available on our website. Further information about cookies can be found at <Link variant="body1" href="http://www.microsoft.com/info/cookies.mspx" target="_blank">http://www.microsoft.com/info/cookies.mspx</Link>
        </Text>

        <Text mt={4} variant="h3">
                    Linked Sites
        </Text>
        <Text>
                    Links on this Site to websites are provided as a convenience to you. Such linked sites are outside our control and responsibility and are not covered by this policy. If you use any such linked sites, you should consult the privacy policies posted on those websites.
        </Text>

        <Text mt={4} variant="h3">
                    User's Data
        </Text>
        <Text>
                    Upon a user’s request, we shall remove all the user’s data from the website. Removed user’s data shall be kept for three additional months (offline) before deletion. Data which cannot be removed includes user’s data which has been exchanged with other users and resides in other user’s accounts.
        </Text>

        <Text mt={4} variant="h3">
                    Contact information
        </Text>
        <Text>
                    For any question or to request modification or deletion of your personal information, please contact <Link variant="body1" target="_blank" href="mailto:support@kycpassport.com">support@kycpassport.com</Link>
        </Text>

        <Text mt={4} variant="h3">
                    Changes and Updates to this Policy
        </Text>
        <Text>
                    We reserve the right to amend this Privacy Policy from time to time. We will provide notification of the change on our website. The Privacy Policy does not create any contractual or other legal right in or on behalf of any party. Commercial Passport’s Terms of Use govern contractual rights with its users exclusively.
        </Text>
      </Box>
    </Wrapper>
  );
}
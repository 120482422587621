import React, {useEffect, useMemo, useState} from 'react';
import {Box, FormControl, InputLabel, MenuItem, Select} from '@material-ui/core';
import moment from 'moment';
import DatePicker from './date-picker';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

const dateFormat = 'MM/DD/YYYY'

const useStyles = makeStyles((theme) => ({
  calendarMenuItemRoot: {
    backgroundColor: 'transparent',
    height: 'auto',
    '& .MuiTouchRipple-root': {
      display: 'none'
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent'
      }
    }
  },
  activeCalendar: {
    padding: 0,
    '& .label': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      padding: '6px 16px',
      borderRadius: '16px'
    }

  }
}))

export default function NextDatePicker(props) {

  const {value, onChange, disabled, defaultValue = 1} = props
  const [nextReviewOpen, setNextReviewOpen] = useState(false)
  const [internalValue, setInternalValue] = useState(0)

  const classes = useStyles()

  useEffect(() => {
    if(!value) {
      onInternalChange(defaultValue)
    } else {
      onInternalChange(4)
    }
    }, [defaultValue]) //eslint-disable-line

  const someYearFromNow = plusYearValue => {
    return moment().add(plusYearValue, 'years')
  }

  const onInternalChange = v => {
    setInternalValue(v)
    if(v < 4) {
      onChange(datesFromNow[v].format('YYYY-MM-DD'))
    }
  }

  const datesFromNow = useMemo(() => ({
    1: someYearFromNow(1),
    2: someYearFromNow(2),
    3: someYearFromNow(3)
  }), [])

  const onCustomDateChange = v => {
    if(internalValue === 4) {
      onChange(moment(v).format('YYYY-MM-DD'))
    }
  }

  const onCustomDateAccept = v => {
    if(internalValue === 4) {
      let matchInternalValue = null
      for( const key in datesFromNow) {
        if(datesFromNow[key].format('YYYY-MM-DD') === moment(v).format('YYYY-MM-DD')) {
          matchInternalValue = key
        }
      }
      setInternalValue(
        matchInternalValue || 4
      )
      setNextReviewOpen(false)
    }
  }
  // Close custom select on click away, recognize background overlay by z-index
  const onClose = event => {
    if(event.target.style?.['z-index'] === '-1') {
      setNextReviewOpen(false)
      // Handle saving previous date by selecting the year and click outside
      if (new Date(value) < new Date()) {
        onChange(null)
      }
    }
  }

  return (
    <React.Fragment>
      <FormControl
        fullWidth
        variant="outlined"
        disabled={disabled}
      >
        <InputLabel shrink={!(internalValue === 4 && !value)}>Next Review Date</InputLabel>
        <Select
          value={internalValue || defaultValue}
          onChange={(event) => onInternalChange(event.target.value)}
          open={nextReviewOpen}
          onOpen={()=>setNextReviewOpen(true)}
          onClose={onClose}
          //inputProps={{ readOnly: readOnly }}
          renderValue={v => {
            if(v === 4) {
              return value? moment(value).format(dateFormat): ''
            } else if(v === 1) {
              return '1 year from today'
            } else {
              return `${v} years from today`
            }
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left'
            }
          }}
        >
          <MenuItem onClick={() => setNextReviewOpen(false)} value={1}>1 year from today</MenuItem>
          <MenuItem onClick={() => setNextReviewOpen(false)} value={2}>2 years from today</MenuItem>
          <MenuItem onClick={() => setNextReviewOpen(false)} value={3}>3 years from today</MenuItem>
          <MenuItem value={4} classes={{root: classes.calendarMenuItemRoot}} className={clsx(internalValue===4 && classes.activeCalendar)}>
            <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
              <Box display={'flex'} className={'label'}>
                                Set custom date
              </Box>
              { internalValue === 4 && nextReviewOpen &&
                            <Box mt={1} display={'flex'} flexGrow={1} justifyContent={'center'}>
                              <Box display={'flex'}>
                                <DatePicker
                                  variant="static"
                                  value={value}
                                  minDate={new Date()}
                                  onChange={onCustomDateChange}
                                  onAccept={onCustomDateAccept}
                                />
                              </Box>
                            </Box>
              }
            </Box>
          </MenuItem>
        </Select>
      </FormControl>
    </React.Fragment>
  );
}
export function capitalize(text) {
  if (!text) return text;
  if (text.length === 1) return text.toUpperCase();

  return text.charAt(0).toUpperCase() + text.slice(1)
}

export function capitalizeSentence(text) {
  if (!text) return text;

  return text.toLowerCase().split(' ').map(capitalize).join(' ');
}

export const arrayToString = (data) => {
  return  typeof data === 'object'
    ? data.length > 1
      ? data.join(', ')
      : data.join('')
    : data;
}

// eslint-disable-next-line
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = (value) => {
  return emailRegex.test(value?.toLowerCase());
}

/**
 * Reverse person name
 * John Doe -> Doe John
 * John Arthur Doe -> Doe John Arthur
 */
export const reverseName = (name) => {
  const names = name.split(' ');
  if (names.length <= 1) {
    return name;
  }

  const lastName = names.pop();
  return `${lastName} ${names.join(' ')}`;
}

/**
 * Remove comma (,) apostrophe (') and dash (-) from names
 */
export const normalizeName = (name) => {
  return name
    .replace(', ', ' ') // Replace comma + space, with space
    .replace(',', '') // Remove comma without space
    .replace('\'', '') // Remove apostrophe
    .replace('-', ' ') // Replace dash with space
}

export const pluralFormatter = (count, singular, plural) => {
  return count === 1 ? singular : plural;
}
import GeneralSettings from '@views/protected/admin/general-settings';

const routes = [
  {
    path: '/general-settings',
    component: GeneralSettings,
    guest: false,
  },
]

export default routes;
import React, { useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import history from '@app/history';

import { clientDocument } from '@app/api/client';
import downloadUrl from '@utils/downloadUrl';
import NoResultsFound from '@components/layout/no-results-found';
import DocumentDeleteDialog from './document-delete-dialog';
import PdfViewer from '@components/pdf/pdf-viewer';
import ImageViewer from '@components/image/image-viewer';
import DocumentCategoryCard from './document-category-card';

const IMAGES_EXTENSION = ['jpg', 'jpeg', 'png', 'bmp'];

export default function DocumentsList(props) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    categories, documentTypes,
    documents,
    setPendingUpdateDocument, setPendingDocumentDialogShown,
    setPendingDocumentCategory,
    fetchClient, fetchDocuments,
    readonly, data,
    selectedCategory,
  } = props;

  const [pendingDocumentDelete, setPendingDocumentDelete] = useState(null)
  const [pdfUrl, setPdfUrl] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const getTypeName = (item) => {
    const type = documentTypes.find(e => e.id === item?.typeId)
    return type?.name
  }

  const groupedDocuments = useMemo(() => {
    const groupedDocumentsMap = {}; // Map by category id
    documents.forEach(doc => {
      if (!groupedDocumentsMap[doc.categoryId]) {
        const category = categories.find(cat => cat.id === doc.categoryId)
        groupedDocumentsMap[doc.categoryId] = {
          ...category,
          documents: [],
        }
      }

      groupedDocumentsMap[doc.categoryId].documents.push(doc)
    })

    return Object.values(groupedDocumentsMap).sort(
      (category_1, category_2) => {
        if (category_1.ownedBy === category_2.ownedBy) {
          return category_1.name === 'Other'
            ? 1 : category_1.name.localeCompare(category_2.name, 'en', { sensitivity: 'base', numeric: true })
        }
        return category_2.ownedBy === 'SYSTEM' ? 1 : -1
      }
    )
  }, [documents, categories])

  const onDocumentDeleteConfirm = async () => {
    if (pendingDocumentDelete.documentVersionId) {
      await clientDocument.removeVersion(pendingDocumentDelete.documentVersionId)
    } else {
      await clientDocument.remove(pendingDocumentDelete.id)
    }
    fetchDocuments();
    fetchClient();

    enqueueSnackbar(`Document "${getTypeName(pendingDocumentDelete)}" was successfully deleted!`, { variant: 'success' });
    setPendingDocumentDelete(null)

  }

  const onDownloadDocument = async (item) => {
    const document = (item.documentVersionId
      ? (await clientDocument.showVersion(item.documentVersionId))
      : (await clientDocument.show(item.id)))
      .data

    downloadUrl(document.downloadUrl, document.fileName)
    enqueueSnackbar(`Document "${getTypeName(item)}" was successfully downloaded!`, { variant: 'success' });
  }

  const onStartDocumentUpdateFlow = async (document, type) => {
    setPendingUpdateDocument(document)
    const category = categories.find(cat => cat.id === document.categoryId)
    if (category) {
      setPendingDocumentCategory(category)
    }
    setPendingDocumentDialogShown(type)
  }
  
  const getFile = async (item) => {
    const extension = item.fileName.split('.').pop();
    if (extension.toLowerCase() === 'pdf') {
      const url = (item.documentVersionId
        ? (await clientDocument.showVersion(item.documentVersionId))
        : (await clientDocument.show(item.id)))
        .data?.downloadUrl

      setPdfUrl(url);
      return setSelectedDocument(item);
    }
    if (IMAGES_EXTENSION.includes(extension.toLowerCase())) {
      const url = (item.documentVersionId
        ? (await clientDocument.showVersion(item.documentVersionId))
        : (await clientDocument.show(item.id)))
        .data?.downloadUrl
      setImgUrl(url);
      return setSelectedDocument(item);
    }
    enqueueSnackbar('Document View is supported only for PDFs and images.', { variant: 'warning' })
  }

  const onRequestUpload = (item) => {
    if (item?.categoryNameRaw.toLowerCase() === 'photo id') {
      return history.push(`/portfolio/view/${data.id}/electronic-verification/new?forceStart=true&type=id`)
    }

    if (item?.categoryNameRaw.toLowerCase() === 'proof of address') {
      return history.push(`/portfolio/view/${data.id}/electronic-verification/new?forceStart=true&type=address`)
    }
    return history.push(`/portfolio/view/${data.id}/electronic-verification/new?forceStart=true`)
  }

  return (
    <Box>
      {groupedDocuments.map(category => (
        <DocumentCategoryCard
          key={category.id}
          category={category}
          readonly={readonly}
          getFile={getFile}
          onStartDocumentUpdateFlow={onStartDocumentUpdateFlow}
          onRequestUpload={onRequestUpload}
          onDownloadDocument={onDownloadDocument}
          setPendingDocumentDelete={setPendingDocumentDelete}
          selectedCategory={selectedCategory}
        />
      ))}
      {(readonly && !groupedDocuments.length) &&
        <Box mt={16} flexGrow={1}>
          <NoResultsFound>
            When you upload documents they will appear here
          </NoResultsFound>
        </Box>
      }
      <DocumentDeleteDialog
        open={!!pendingDocumentDelete}
        isOldVersion={pendingDocumentDelete?.isOldVersion}
        documentName={getTypeName(pendingDocumentDelete)}
        onClose={() => setPendingDocumentDelete(null)}
        onConfirm={onDocumentDeleteConfirm}
      />
      <PdfViewer
        url={pdfUrl}
        open={!!pdfUrl}
        onClose={() => setPdfUrl('')}
        documentName={selectedDocument?.documentName}
        clientName={data.name}
        onDownload={() => onDownloadDocument(selectedDocument)}
      />
      <ImageViewer
        url={imgUrl}
        open={!!imgUrl}
        onClose={() => setImgUrl('')}
        documentName={selectedDocument?.documentName}
        clientName={data.name}
        onDownload={() => onDownloadDocument(selectedDocument)}
      />
    </Box>
  )
}
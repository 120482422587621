import React from 'react';
import { Box, capitalize } from '@material-ui/core';
import RiskRatingIcon from '@components/flexible-icons/risk-rating-icon';
import { RISK_COLOR } from '../../constants';

export const getRiskRatingValue = (item) => {
  const value = item.riskRating || 'N/A';

  return (
    <React.Fragment>
      <Box
        component={'span'}
        display="flex" flexDirection="row" alignItems="center"
      >
        <Box component={'span'}>
          <RiskRatingIcon
            iconProps={{ style: { width: '12px', height: '12px' } }}
            risk={value !== 'N/A' ? value?.toLowerCase() : null}
          />
        </Box>
        <Box component={'span'} pl={1} style={{ color: RISK_COLOR[value] }}>
          {capitalize(value)}
        </Box>
      </Box>
    </React.Fragment>
  );
};

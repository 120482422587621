import React from 'react';
import { Box } from '@material-ui/core';

import CustomTooltip from '@components/controls/tooltip';
import BaseTableCell from './base-table-cell';

export default function DefaultTableCell(props) {
  const { col, item } = props;

  return (
    <BaseTableCell col={col} item={item}>
      <CustomTooltip
        title={item?.tooltipValue || item[col.tooltipValue] || item[col.field]}
        placement="top"
        disableHoverListener={!col.useTooltip}
      >
        <Box display="inline-block">
          {item[col.field] || (col.isEmpty ? null : 'N/A')}
        </Box>
      </CustomTooltip>
    </BaseTableCell>
  )
}
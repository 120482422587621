import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
  Box, Button, FormControl, FormHelperText, Grid, InputAdornment, InputLabel,
  Link, OutlinedInput, Typography
} from '@material-ui/core';
import history from '@app/history';
import { clientApi } from '@app/api';
import { Modal } from '@components/modals';
import parseYupErrors from '@app/yup';
import constants from '@app/constants';
import { countryCodeList } from '@services/country-service';
import PageLayout from '@components/layout/page-layout';
import InnerHeader from '@components/layout/inner-header';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { ChevronLeftIcon, InfoOtherIcon } from '@app/icons';
import InnerFooter from '@components/layout/inner-footer';
import ReactInputMask from 'react-input-mask';
import SelectSearchable from '@components/inputs/select-searchable';
import { useSnackbar } from 'notistack';
import useErrorState from '@utils/errorState';
import DatePicker from '@components/calendar/input-date-picker';
import sub from 'date-fns/sub';
import moment from 'moment';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import CustomTooltip from '@components/controls/tooltip';
import cognito from '@app/cognito';
import auth from '@app/store/actions/auth';

const selectUser = state => state.auth.user;

export default function MyAccountEdit(props) {
  const userData = useSelector(selectUser);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const contactFields = [
    {
      key: 'email',
      label: 'Primary Email',
      disabled: true,
    },
    {
      key: 'otherEmails',
      label: 'Other email',
    },
    {
      key: 'phoneNumber',
      label: 'Phone number',
      mask: constants.phoneMask,
    },
  ]

  const [errors, setErrors] = useErrorState({});
  const [isMfaEnabled, setIsMfaEnabled] = useState(false);
  const [mfaDialogOpen, setMfaDialogOpen] = useState(false);
  const [verifyPhoneOpen, setVerifyPhoneOpen] = useState(false);
  const [model, setModel] = useState({
    // Personal info
    name: userData.name || '',
    middleName: userData.middleName || '',
    lastName: userData.lastName || '',
    dob: userData.dob,
    organization: userData.organization || '',
    address: userData.address || '',
    city: userData.city || '',
    state: userData.state || '',
    zip: userData.zip || '',
    country: userData.country === 'string' ? '' : (userData.country || ''),
    // Contact info
    email: userData.email || '',
    otherEmails: userData.otherEmails || '',
    phoneNumber: userData.phoneNumber || '',
  });

  useEffect(() => {
    const localStorageMfa = localStorage.getItem('isMfaEnabled')
    if (localStorageMfa !== null) {
      setIsMfaEnabled(localStorageMfa === 'true')
    } else {
      cognito.getMfaSettings().then(res => {
        setIsMfaEnabled(!!res)
      })
    }
  }, [])

  const schema = yup.object().shape({
    name: yup.string().label('First name').min(3).required().matches().max(30).trim(),
    middleName: yup.string().label('Middle name').max(30),
    lastName: yup.string().label('Last name').required().max(30),
    email: yup.string().lowercase().trim().email('Invalid email format').required().max(50),
    dob: yup.date()
      .nullable()
      .transform((curr, orig) => orig === '' ? null : curr)
      .label('Date of birth')
      .required()
      .max(sub(new Date(), { days: 1 }), 'Date of birth should be before current date')
      .typeError('Invalid date format'),
    organization: yup.string().max(100),
    address: yup.string().max(100),
    city: yup.string().max(100),
    state: yup.string().max(100),
    zip: yup.string().max(15),
    country: yup.string().label('Country').required(),
    phoneNumber: yup.string().label('Phone Number').required()
      .test('format', 'Please provide a valid phone number', function () {
        return isValidPhoneNumber(this.parent.phoneNumber)
      }),
    otherEmails: yup.string().label('Other email').email('Invalid email format')
  });

  const handleChange = (prop) => (event) => {
    const newModel = { ...model, [prop]: event.target.value }
    setModel(newModel);
        
    if (errors[prop]) {
      schema.validateAt(prop, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [prop]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [prop]: err.message });
        });
    }
  };

  const personalFields = [
    {
      key: 'name',
      label: 'First Name'
    },
    {
      key: 'middleName',
      label: 'Middle Name'
    },
    {
      key: 'lastName',
      label: 'Last Name'
    },
    {
      key: 'dob',
      label: 'Date Of Birth',
      type: 'date',
      endAdornment: <DatePicker
        handleChange={handleChange('dob')}
        inputValue={model['dob'] || '01-01-1970'}
      />
    },
    {
      key: 'organization',
      label: 'Organization',
    },
    {
      key: 'address',
      label: 'Address',
    },
    {
      key: 'city',
      label: 'City',
    },
    {
      key: 'state',
      label: 'State',
    },
    {
      key: 'country',
      label: 'Country',
      type: 'select',
      options: Object.keys(countryCodeList).map(optionValue =>
        ({ label: countryCodeList[optionValue], value: optionValue })),
    },
    {
      key: 'zip',
      label: 'Zip/Postcode',
    }
  ]

  const onSave = () => {
    setErrors({});
    schema
      .validate(model, { abortEarly: false })
      .then(function (valid) {
        if (model.phoneNumber !== userData.phoneNumber) {
          if (isMfaEnabled) {
            setMfaDialogOpen(true)
          } else {
            setVerifyPhoneOpen(true)
          }
        } else {
          updateUser()
        }
      })
      .catch(function (err) {
        setErrors(parseYupErrors(err), true);
      });
  }

  const updateUser = (pushUrl, skipNumber = false) => {
    const newModel = { ...model, dob: moment(model.dob).format('YYYY-MM-DD') }
    const parsedNumber = parsePhoneNumber(skipNumber ? userData.phoneNumber : model.phoneNumber)
    clientApi.user.updateUser({
      ...newModel,
      email: model.email.toLowerCase().trim(),
      phoneNumber: { 
        countryCode: parsedNumber.country,
        rawNumberString: parsedNumber.number,
      }
    })
      .then(data => {
        enqueueSnackbar('My account details were successfully saved!', { variant: 'success' });
        if (pushUrl) {
          clientApi.user.getCurrentUser().then((res) => {
            localStorage.setItem('user', JSON.stringify(res.data));
            dispatch(auth.setUser(res.data));
            history.push(pushUrl);
          })    
        } else {
          history.push('/my-profile/my-account');
        }
      })
      .catch(err => {
        const errors = err.response.data.errorMessages || {};
        setErrors(errors, true);
      })
  }

  const updateAllButNumber = () => {
    updateUser(null, true);
  }

  const verifyNumber = () => {
    cognito.setMfaSettings(false).then((res) => {
      setIsMfaEnabled(false);
      localStorage.setItem('isMfaEnabled', false);
      updateUser('/my-profile/security-details/verify-number-mfa')
    })
  }

  const verifyNumberNoMfa = () => {
    updateUser('/my-profile/security-details/verify-number')
  }

  const cancelUpdate = () => {
    setMfaDialogOpen(false);
    setVerifyPhoneOpen(false);
  }

  return (
    <PageLayout>
      <Modal
        open={mfaDialogOpen}
        onClose={() => setMfaDialogOpen(false)}
        title="Phone Update Confirmation"
        mainText="Multi-factor authentication is enabled for your account. If you change your phone number, we will have to verify that the new number belongs to you."
        actions={[
          {
            type: 'secondary',
            label: 'DO NOT CHANGE NUMBER',
            action: updateAllButNumber,
            style: { width: 300 },
          },
          {
            type: 'main',
            label: 'VERIFY NUMBER',
            action: verifyNumber,
            style: { width: 300 },
          },
          {
            linkButton: true,
            label: 'CANCEL',
            action: cancelUpdate,
            style: { width: 300 },
          }
        ]}
        actionsDirection="column"
      />
      <Modal
        open={verifyPhoneOpen}
        onClose={() => setVerifyPhoneOpen(false)}
        title="Phone Update Confirmation"
        mainText="If you change your phone number, we will have to verify that the new number belongs to you."
        actions={[
          {
            type: 'main',
            label: 'CANCEL',
            action: cancelUpdate,
            style: { width: 200 },
          },
          {
            type: 'secondary',
            label: 'VERIFY NUMBER',
            action: verifyNumberNoMfa,
            style: { width: 200 },
          },
        ]}
        actionsDirection="row"
      />

      <Box px={6}>
        <InnerHeader
          title={'Edit Account Details'}
          buttons={
            <React.Fragment>
              <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={() => { history.goBack() }}>
                                Back
              </ButtonWithIcon>
            </React.Fragment>
          }
        />
      </Box>
      <Box pt={4} pb="2px" px={14} maxHeight="max-content" style={{ overflowY: 'auto' }}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container>
            <Grid item xs={6}>
              <Box mb={2}>
                <Typography variant="h5">
                                Personal Information
                </Typography>
              </Box>
              <Box pr={4}>
                {personalFields.map(field => (
                  <FormControl
                    key={field.key}
                    fullWidth
                    id={field.key}
                    variant="outlined"
                    error={!!errors[field.key]}
                  >
                    {field.type !== 'select' && <InputLabel htmlFor="name" shrink={field.type === 'date' || undefined}>{field.label}</InputLabel>}
                    {field.mask &&
                                        <ReactInputMask
                                          mask={field.mask ? field.mask : null}
                                          value={model[field.key]}
                                          onChange={handleChange(field.key)}
                                          maskChar={null}
                                        >
                                          {(inputProps) => <OutlinedInput
                                            {...inputProps}
                                            placeholder={field.placeholder}
                                            endAdornment={field.endAdornment}
                                          />}
                                        </ReactInputMask>
                    }
                    {(field.type !== 'select' && !field.mask) &&
                                        <OutlinedInput
                                          value={model[field.key]}
                                          type={field.type === 'date' ? 'date' : 'text'}
                                          onChange={handleChange(field.key)}
                                          endAdornment={field.endAdornment}
                                        />
                    }
                    {field.type === 'select' &&
                                        <SelectSearchable
                                          value={model[field.key]}
                                          onChange={handleChange(field.key)}
                                          options={field.options}
                                          label={field.label}
                                          error={!!errors[field.key]}
                                        />
                    }
                    {errors[field.key] &&
                                        <FormHelperText>{errors[field.key]}</FormHelperText>
                    }
                  </FormControl>
                ))}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mb={2}>
                <Typography variant="h5">
                                Contact Information
                </Typography>
              </Box>
              <Box pr={4}>
                {contactFields.map(field => (
                  <FormControl
                    key={field.key}
                    fullWidth
                    id={field.key}
                    variant="outlined"
                    error={!!errors[field.key]}
                    disabled={field.disabled}
                  >
                    <InputLabel htmlFor="name">{field.label}</InputLabel>
                    {field['mask'] &&
                                        <ReactInputMask
                                          mask={field['mask'] ? field.mask : null}
                                          value={model[field.key]}
                                          onChange={handleChange(field.key)}
                                          maskChar={null}
                                        >
                                          {(inputProps) =>
                                            <OutlinedInput
                                              {...inputProps}
                                              endAdornment={
                                                <InputAdornment position="end">
                                                  <CustomTooltip
                                                    title="If you have OTP enabled, changing phone number will disable it and you will have to verify your phone number again."
                                                    placement="top"
                                                  >
                                                    <InfoOtherIcon />
                                                  </CustomTooltip>
                                                </InputAdornment>
                                              }
                                            />
                                          }
                                        </ReactInputMask>
                    }
                    {!field['mask'] &&
                                        <OutlinedInput
                                          value={model[field.key]}
                                          onChange={handleChange(field.key)}
                                        />
                    }
                    {errors[field.key] &&
                                        <FormHelperText>{errors[field.key]}</FormHelperText>
                    }
                  </FormControl>
                ))}
              </Box>
            </Grid>
          </Grid>
          <input type="submit" onClick={onSave} style={{ display: 'none' }} />
        </form>
      </Box>
      <InnerFooter>
        <Box pb={4} px={14} display="flex" justifyContent="flex-end" alignItems="center" width="100%">
          <Box mr={5}>
            <Link variant="subtitle1" onClick={() => history.push('/my-profile/my-account')}>
                            CANCEL
            </Link>
          </Box>
          <Button size="large" variant="contained" onClick={onSave}>
                        SAVE
          </Button>
        </Box>
      </InnerFooter>
    </PageLayout>
  )
};

import React, {useEffect} from 'react';
import _ from 'lodash';

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
  MenuItem, Link
} from '@material-ui/core';
import {ChevronLeftIcon} from '@app/icons';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import InnerHeader from '@components/layout/inner-header';
import PageLayout from '@components/layout/page-layout';
import history from '@app/history';
import parseYupErrors from '@app/yup';
import * as yup from 'yup';
import {clientApi} from '@app/api';
import {countryCodeList} from '@services/country-service';
import ReactInputMask from 'react-input-mask';
import constants from '@app/constants';
import SelectClearable from '@components/inputs/select-clearable';
import { useSnackbar } from 'notistack';
import useErrorState from '@utils/errorState';
import sub from 'date-fns/sub';
import DatePicker from '@components/calendar/input-date-picker';
import moment from 'moment';
import SelectSearchable from '@components/inputs/select-searchable';
import { LegalEntitySubtypeEnum } from '@services/client-type';

const schema = yup.object().shape({
  name: yup.string().label('Legal Entity name').required().max(60),
  type: yup.string().label('Entity type').required().max(50),
  formationDate: yup.date()
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .label('Date of Incorporation / Formation')
    .test('min_date', 'Date of Incorporation / Formation should be after 1900', function () {
      return !moment(this.parent.formationDate).isBefore('1900-01-01')
    })
    .max(sub(new Date(), { days: 1 }), 'Date of Incorporation / Formation should be before current date')
    .typeError('Invalid date format'),
  registeredOfficeCountry: yup.string().label('Registered office country').required(),
  note: yup.string().max(255),
  // Contact
  primaryEmail: yup.string().label('Primary email').trim().email('Invalid email format').max(100),
  otherEmail: yup.string().label('Other email').trim().email('Invalid email format').max(100).test('same_email', 'Primary and Other email must be different', function () {
    return !this.parent.otherEmail || this.parent.primaryEmail !== this.parent.otherEmail
  }),
  phoneNumber: yup.string(),
  registrationNumber: yup.string().label('Registration number').max(100),
  folderId: yup.string().label('Folder').required()
});

export default function Person(props) {
  const { enqueueSnackbar } = useSnackbar();

  const params = props.computedMatch.params;

  const id = params.id;

  useEffect(() => {
    fetchFolderOptions().then(folders => {
      clientApi.client.show(id).then(({data}) => {
        data = _.pickBy(data, _.identity)
        if(!data.folderId) {
          const rootFolder = folders.find(folder => folder.name === 'HOME')
          data.folderId = rootFolder? rootFolder.id: ''
        }
        const newModel = {
          ...model,
          ...data
        }
        setModel(
          newModel
        );
      })
    })
    // eslint-disable-next-line
    }, [])

  const formFields = () => [
    {
      key: 'name',
      label: 'Legal Entity Name'
    },
    {
      key: 'registrationNumber',
      label: 'Registration Number',
    },
    {
      key: 'type',
      label: 'Entity Type',
      type: 'select',
      allowSearch: true,
      options: Object.entries(LegalEntitySubtypeEnum).map(([value, label]) =>
        ({ label, value })),
    },
    {
      key: 'formationDate',
      label: 'Date of Incorporation / Formation',
      type: 'date',
      endAdornment: <DatePicker
        handleChange={handleChange('formationDate')}
        inputValue={model['formationDate'] || '01-01-1970'}
      />
    },
    {
      key: 'registeredOfficeCountry',
      label: 'Registered Office Country',
      type: 'select',
      allowSearch: true,
      options: Object.keys(countryCodeList).map(optionValue =>
        ({ label: countryCodeList[optionValue], value: optionValue })),
    },
    {
      key: 'folderId',
      label: 'Folder',
      type: 'select',
      options: folderOptions
    },
    {
      key: 'note',
      label: 'Note',
      multiline: true
    },
    // Contacts
    {
      key: 'primaryEmail',
      label: 'Primary Email',
      group: 'contacts'
    },
    {
      key: 'otherEmail',
      label: 'Other Email',
      group: 'contacts'
    },
    {
      key: 'phoneNumber',
      label: 'Phone Number',
      mask: constants.phoneMask,
      group: 'contacts'
    }
  ]

  const [model, setModel] = React.useState({
    name: '',
    formationDate: '',
    registeredOfficeCountry: '',
    type: '',
    folderId: '',
    folderName: 'HOME',
    note: '',
    // Contact info
    primaryEmail: '',
    otherEmail: '',
    phoneNumber: '',
    registrationNumber: ''
  });

  const [errors, setErrors] = useErrorState({});

  const [ folderOptions, setFolderOptions ] = React.useState({})

  const fetchFolderOptions = async () => {
    const params = {
      pageNumber: 0,
      pageSize: 1000
    }
    const {data} = await clientApi.folder.index(params)
    const entries = (data?.entries || [])
    const foldersData = entries.reduce((acc, folder) => {
      acc[String(folder.id)] = folder.name
      return acc
    }, {})
    setFolderOptions(foldersData)
    return entries
  }

  const onSave = () => {
    setErrors({});
    schema
      .validate(model, {abortEarly: false})
      .then(async () => {
        try {
          const newModel = { ...model, formationDate: model.formationDate ? moment(model.formationDate).format('YYYY-MM-DD') : null }
          await clientApi.company.update(id, newModel)
          enqueueSnackbar(`"${model.name}" details were successfully updated!`, { variant: 'success' })
          await clientApi.folder.addClient(model.folderId, id)
        } catch (err) {
          setErrors(err.response.data['errorMessages'] || {}, true)
        }
        history.push(`/portfolio/view/${id}`)
      })
      .catch( err => setErrors(parseYupErrors(err), true));
  }

  const onBack = () => {
    history.goBack();
  }

  const handleChange = (prop) => (event) => {
    const value = typeof event.target.value === 'string'
      ? event.target.value.replace(/\s+/g, ' ')
      : event.target.value
    const newModel = { ...model, [prop]: value }
    setModel(newModel);
        
    if (errors[prop]) {
      schema.validateAt(prop, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [prop]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [prop]: err.message });
        });
    }
  };

  return (
    <React.Fragment>
      <PageLayout>
        <Box px={6}>
          <InnerHeader
            title={'Edit Legal Entity'}
            buttons={
              <React.Fragment>
                <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={onBack}>
                                    Back
                </ButtonWithIcon>
              </React.Fragment>
            } />
        </Box>

        <form onSubmit={(e) => e.preventDefault()}>
          <Box style={{overflowY: 'auto'}} height="calc(100vh - 98px - 64px)">
            <Grid container direction={'row'} justify={'center'}>
              <Grid item xs={10}>
                <Grid container spacing={3} item xs={12}>
                  <Grid item xs={6}>
                    <Grid item xs={12}>
                      <Box mb={1} mt={3}>
                        <Typography variant={'h5'}>
                                                Basic Details
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      { formFields().filter(v => !v['group']).map(field => (
                        <FormControl
                          key={field.key}
                          id={field.key}
                          fullWidth
                          variant="outlined"
                          error={!!errors[field.key]}
                        >
                          {!field.allowSearch && <InputLabel htmlFor="name" shrink={field.type === 'date' || undefined}>{field.label}</InputLabel>}
                          {field.mask &&
                                                    <ReactInputMask
                                                      mask={field.mask ? field.mask : null}
                                                      value={model[field.key]}
                                                      onChange={handleChange(field.key)}
                                                      maskChar={null}
                                                    >
                                                      {(inputProps) => <OutlinedInput
                                                        {...inputProps}
                                                        placeholder={field.placeholder}
                                                        endAdornment={field.endAdornment}
                                                      />}
                                                    </ReactInputMask>
                          }
                          { (field.type !== 'select' && !field.mask) &&
                                                <OutlinedInput
                                                  value={model[field.key]}
                                                  type={field.type === 'date'? 'date': 'text'}
                                                  onChange={handleChange(field.key)}
                                                  multiline={field.multiline}
                                                  rows={field.multiline ? 3 : undefined}
                                                  endAdornment={field.endAdornment}
                                                />
                          }
                          {(field.type === 'select' && !field.allowSearch) &&
                                                <SelectClearable
                                                  value={model[field.key]}
                                                  type={field.type === 'date'? 'date': 'text'}
                                                  onChange={handleChange(field.key)}
                                                >
                                                  {Object.keys(field.options).map(optionValue => (
                                                    <MenuItem
                                                      key={optionValue}
                                                      value={optionValue}
                                                    >
                                                      {field.options[optionValue]}
                                                    </MenuItem>
                                                  ))}
                                                </SelectClearable>
                          }
                          {(field.type === 'select' && field.allowSearch) &&
                                                    <SelectSearchable
                                                      value={model[field.key]}
                                                      onChange={handleChange(field.key)}
                                                      options={field.options}
                                                      label={field.label}
                                                      error={!!errors[field.key]}
                                                    />
                          }

                          {errors[field.key] &&
                                                <FormHelperText>{errors[field.key]}</FormHelperText>
                          }
                        </FormControl>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item xs={12}>
                      <Box mb={1} mt={3}>
                        <Typography variant={'h5'}>
                                                Contact Information
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      {formFields().filter(v => v['group'] === 'contacts').map(field => (
                        <FormControl
                          key={field.key}
                          fullWidth
                          id={field.key}
                          variant="outlined"
                          error={!!errors[field.key]}
                        >
                          <InputLabel htmlFor="name" shrink={field.type === 'date' || undefined}>{field.label}</InputLabel>
                          {field.mask &&
                                                    <ReactInputMask
                                                      mask={field.mask ? field.mask : null}
                                                      value={model[field.key]}
                                                      onChange={handleChange(field.key)}
                                                      maskChar={null}
                                                    >
                                                      {(inputProps) => <OutlinedInput {...inputProps} />}
                                                    </ReactInputMask>
                          }
                          {(field.type !== 'select' && !field.mask) &&
                                                    <OutlinedInput
                                                      value={model[field.key]}
                                                      type={field.type === 'date' ? 'date' : 'text'}
                                                      onChange={handleChange(field.key)}
                                                    />
                          }
                          {field.type === 'select' &&
                                                    <SelectClearable
                                                      value={model[field.key]}
                                                      type={field.type === 'date' ? 'date' : 'text'}
                                                      onChange={handleChange(field.key)}
                                                    >
                                                      {Object.keys(field.options).map(optionValue => (
                                                        <MenuItem
                                                          key={optionValue}
                                                          value={optionValue}
                                                        >
                                                          {field.options[optionValue]}
                                                        </MenuItem>
                                                      ))}
                                                    </SelectClearable>
                          }

                          {errors[field.key] &&
                                                    <FormHelperText>{errors[field.key]}</FormHelperText>
                          }
                        </FormControl>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Box display={'flex'} direction={'row'} justifyContent={'flex-end'} flexGrow={1} my={3}>
                    <Box mx={4} display={'flex'} alignItems={'center'}>
                      <Link onClick={onBack}>
                                            CANCEL
                      </Link>
                    </Box>
                    <Box mx={1}>
                      <Button style={{width: '160px'}} variant={'contained'} onClick={onSave} type="submit">
                                            SAVE
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </form>

      </PageLayout>
    </React.Fragment>
  )
}
const config = {
  MuiBreadcrumbs: {
    li: {
      '& .MuiLink-root': {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '20px',
        color: '#79AEB6'
      },
      '& .MuiTypography-body1': {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '20px',
      }
    }
  }
}

export default config
import React, { useMemo } from 'react';

import {
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import {
  EditIcon
} from '@app/icons';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import LabelsForRender from '@components/layout/labels-for-render';
import { parseLabels } from '@dto/client';
import splitArrayToTwo from '@utils/splitArray';

export default function ReadMode(props) {
  const { changeMode, data, readonly, title, displayLevel, folders } = props;

  const labels = useMemo(() => {
    const labels = parseLabels(data, displayLevel, folders)

    return labels;
  }, [data, displayLevel, folders])

  return (
    <Grid container spacing={3} item xs={12}>
      <Grid item xs={6}>
        <Box display={'flex'} alignItems={'center'} mb={2}>
          <Typography variant={'h5'} style={{ lineHeight: '24px' }}>
            {title}
          </Typography>
          {(!readonly && !!labels.length) &&
            <Box ml={1}>
              <ButtonWithIcon
                startIcon={<EditIcon />}
                onClick={changeMode}
                style={{ padding: 0 }}
              >
                Edit
              </ButtonWithIcon>
            </Box>
          }
        </Box>
        {!labels.length &&
          <Typography>No {title.toLowerCase()} have to be completed.</Typography>
        }
        <LabelsForRender
          labels={splitArrayToTwo(labels)[0]}
          boxProps={{ mb: 1 }}
          labelProps={{
            style: {
              width: 150,
              minWidth: 150,
            }
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Box mb={2} height={24}>
        </Box>
        <LabelsForRender
          labels={splitArrayToTwo(labels)[1]}
          boxProps={{ mb: 1 }}
          labelProps={{
            style: {
              width: 150,
              minWidth: 150,
            }
          }}
        />
      </Grid>
    </Grid>
  )
}

import React, { useState } from 'react';
import { Box, Typography, FormControl, InputLabel, Grid, Button, OutlinedInput } from '@material-ui/core';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { AddActive2Icon, RemoveActiveIcon, RemoveInactiveIcon } from '@app/icons';

import InnerHeaderControls from '@components/layout/inner-header-controls';
import BackButton from '@views/common/back-button';
import RenderInputs from '@components/layout/render-inputs';
import DatePicker from '@components/calendar/input-date-picker'
import CustomTooltip from '@components/controls/tooltip';

import history from '@app/history';
import useErrorState from '@utils/errorState';
import * as yup from 'yup';
import parseYupErrors from '@app/yup';
import sub from 'date-fns/sub';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 'calc(100vh - 250px)',
    overflowY: 'auto'
  },
  backButton: {
    marginLeft: 0
  },
  addButton: {
    marginRight: '-10px'
  },
  deleteButton: {
    padding: 0,
    minWidth: 0,
    marginLeft: '5px'
  },
  totalWrapper: {
    maxHeight: '75px',
    overflowY: 'auto',
    margin: '8px 0'
  },
  totalLabel: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '10px',
    lineHeight: '20px',
    color: theme.palette.text.BL1,
  },
  totalSum: {
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '20px',
    color: theme.palette.text.BL1,
  },
  removeButton: {
    minWidth: 24,
    marginLeft: '5px',
    padding: 0,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: '#E3E9E6'
    },
  },
  placeholder: {
    '&::placeholder': {
      fontSize: '16px',
      fontWeight: 500,
      lineheight: '18px',
      opacity: 0.5,
      color: '#232323'
    },
  },
}))

const select = {
  number: 1,
  key: 'item1',
  label: 'Item',
  options: [
    'Subscription: Basic',
    'Subscription: Pro',
    'Subscription: Ultra',
  ],
  type: 'select',
  wrapper: true,
  element: {
    key: 'amount1',
    label: 'Amount',
    type: 'input',
    dataType: 'number',
  }
}

const inputs = [
  {
    key: 'email',
    label: 'Email',
    type: 'input',
  },
  {
    key: 'invoiceDate',
    label: 'Invoice Date',
    dataType: 'date',
    type: 'input',
  },
]

const defaultModel = {
  email: '',
  invoiceDate: '',
  item1: '',
  amount1: '1',
  note: '',
}

export default function NewInvoice() {
  const [model, setModel] = useState(defaultModel);
  const [selectsGroup, setSelectsGroup] = useState([select]);
  const [totalAmount, setTotalAmount] = useState(100);

  const classes = useStyles();

  const [errors, setErrors] = useErrorState({});

  const handleChange = (prop) => (event) => {
    const newModel = { ...model, [prop]: event.target.value };
    if (prop.includes('amount')) {
      calcTotalAmount(newModel);
    }
    setModel(newModel);

    if (errors[prop]) {
      schema.validateAt(prop, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [prop]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [prop]: err.message });
        });
    }
  };

  const schema = yup.object().shape({
    email: yup.string().label('Email').lowercase().trim().email('Invalid email format').required().max(50),
    invoiceDate: yup.date()
      .nullable()
      .transform((curr, orig) => orig === '' ? null : curr)
      .label('Invoice Date')
      .required()
      .max(sub(new Date(), { days: 1 }), 'Invoice date should be before current date')
      .typeError('Invalid date format'),
    item: yup.string().label('Item').required().max(30),
    amount: yup.string().label('Amount').required().max(10),
  });

  const onSave = () => {
    setErrors({});
    schema
      .validate(model, { abortEarly: false })
      .then(function (valid) {
        history.goBack()
      })
      .catch(function (err) {
        console.log(err)
        setErrors(parseYupErrors(err), true);
      });
  }

  const calcTotalAmount = (data) => {
    let amount = 0;
    for (const key in data) {
      if (key.includes('amount')) {
        amount += +data[key];
      }
    }
    setTotalAmount(amount);
  }

  const addItem = () => {
    const number = selectsGroup[selectsGroup.length - 1].number + 1;
    const newSelects = [
      ...selectsGroup,
      { ...select,
        number,
        key: `item${number}`,
        element: { 
          ...select.element,
          key: `amount${number}`,
        }
      }
    ];
    setSelectsGroup(newSelects);
  
    let newModel = {
      ...model,
      [`amount${number}`]: `${number}00`,
      [`item${number}`]: ''
    };

    if (model.amount1) {
      newModel = {
        ...newModel,
        amount1: model.amount1 > 1 ? model.amount1 : `${1}00`,
      };
    }

    setModel(newModel);
    calcTotalAmount(newModel);
  }

  const deleteItem = (number) => {
    let newModel = { ...model };
  
    if (selectsGroup.length > 1) {
      const newSelectsGroup = selectsGroup.filter((el, i) => el.number !== number);
      setSelectsGroup(newSelectsGroup);

      delete newModel[`item${number}`];
      delete newModel[`amount${number}`];

      calcTotalAmount(newModel);
    }
    setModel(newModel);
  }

  const renderDatePicker = (
    <DatePicker
      handleChange={handleChange('invoiceDate')}
      inputValue={model['invoiceDate']}
    />
  )

  return (
    <React.Fragment>
      <Box display={'flex'} flexDirection={'column'}>
        <InnerHeaderControls
          controlsLeft={<BackButton wrapped customButtonClasses={classes.backButton} />}
        />
        <Box className={classes.container}>
          <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center">
            <Box display="flex" mb={2} alignItems="center" justifyContent="center">
              <Typography variant="h4">Add new invoice</Typography>
            </Box>
            {inputs.map((field, i) => (
              <Grid container item sm={5} key={`${field.key}-${i}`}>
                <RenderInputs
                  ind={i}
                  field={field.key === 'invoiceDate' ? { ...field, endAdornment: renderDatePicker } : field}
                  model={model}
                  errors={errors}
                  handleChange={handleChange}
                />
              </Grid>
            ))}
            {selectsGroup.map((field, i) => (
              <Grid container item sm={5} key={`${field.key}-${i}`}>
                <RenderInputs
                  ind={i}
                  field={field}
                  model={model}
                  errors={errors}
                  handleChange={handleChange}
                  wrapperItem={
                    i ? (
                      <CustomTooltip
                        title="Remove"
                        placement="top"
                      >
                        <span>
                          <ButtonWithIcon
                            onClick={() => deleteItem(field.number)}
                            className={classes.removeButton}
                          >
                            <RemoveActiveIcon />
                          </ButtonWithIcon>
                        </span>
                      </CustomTooltip>
                    ) : (
                      <CustomTooltip
                        title={selectsGroup.length > 1 ? 'Remove' : 'The invoice should have at least one item'}
                        placement="top"
                      >
                        <span>
                          <ButtonWithIcon
                            onClick={() => deleteItem(field.number)}
                            className={classes.removeButton}
                            disabled={selectsGroup.length === 1}
                          >
                            {selectsGroup.length > 1 ? <RemoveActiveIcon /> : <RemoveInactiveIcon />}
                          </ButtonWithIcon>
                        </span>
                      </CustomTooltip>
                    )
                  }
                />
              </Grid>
            ))}
            <Grid container item sm={5} display="flex" justify="flex-end">
              <ButtonWithIcon
                startIcon={<AddActive2Icon />}
                onClick={addItem}
                className={classes.addButton}
              >
                Add Item
              </ButtonWithIcon>
            </Grid>
            <Grid container item sm={5} display="flex" direction="row" alignItems="center" className={classes.totalWrapper}>
              <Grid container item sm={8} display="flex" justify="flex-end">
                <Typography className={classes.totalLabel}>TOTAL</Typography>
              </Grid>
              <Grid container item sm={4} display="flex" justify="flex-end" alignItems="center">
                <Typography className={classes.totalSum}>{`$${totalAmount}`}</Typography>
              </Grid>
            </Grid>
            <Grid container item sm={5}>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors['note']}
              >
                <InputLabel htmlFor="name">Note</InputLabel>
                <OutlinedInput
                  value={model['note']}
                  onChange={handleChange('note')}
                  fullWidth
                  multiline
                  rows={5}
                  placeholder="Please enter note"
                  inputProps={{ maxLength: 250 }}
                  classes={{ input: classes.placeholder }}
                />
              </FormControl>
            </Grid>
            <Box display="flex" my={5} alignItems="center" justifyContent="center">
              <Button style={{ width: '180px' }} variant={'contained'} onClick={onSave}>
                SAVE
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  )
}
import React, { useMemo, useState } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import ConfirmDialog from '@components/dialogs/confirm-dialog';
import EntityCard from '../components/entity-card';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { adminApi } from '@app/api';
import NoResultsFound from '@components/layout/no-results-found';
import { capitalize } from '@utils/textUtil';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    overflowY: 'auto',
    padding: '8px 8px 0 8px',
    margin: '-8px -8px 0 -8px'
  }
}));

export default function EntitiesGrid({ fetchData }) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const { rulebooksData } = useSelector(state => state.entityManagement)
  const { scope = 'global' } = useParams()

  const rulebooksScopeData = useMemo(() => rulebooksData[scope],
    [rulebooksData, scope]
  )
  const [pendingDeleteEntity, setPendingDeleteEntity] = useState(null)

  const onDeleteConfirm = async () => {
    await adminApi.rulebooks.remove(pendingDeleteEntity?.rulebookVersionId)
    enqueueSnackbar(`"${capitalize(pendingDeleteEntity?.name)}" was successfully deleted!`, { variant: 'success' })
    fetchData()
    setPendingDeleteEntity(null)
  }

  return (
    <React.Fragment>
      <Box className={classes.container}>
        <Box display={'flex'}>
          <Grid container spacing={2}>
            {rulebooksScopeData.map(entity => (
              <Grid item sm={3} key={entity.rulebookId}>
                <EntityCard
                  entity={entity}
                  scope={scope}
                  label={capitalize(entity.name)}
                  setPendingDelete={setPendingDeleteEntity}
                />
              </Grid>
            ))}
            {
              !rulebooksScopeData.length &&
              <Box mt={8} flexGrow={1}>
                <NoResultsFound>
                  The rulebooks will appear here
                </NoResultsFound>
              </Box>
            }
          </Grid>
        </Box>
      </Box>
      <ConfirmDialog
        open={!!pendingDeleteEntity}
        onClose={() => setPendingDeleteEntity(null)}
        title={'Delete entity'}
        text={!!pendingDeleteEntity && `Are you sure you want to remove "${capitalize(pendingDeleteEntity?.name)}"?`}
        onConfirm={onDeleteConfirm}
      />
    </React.Fragment>
  )
}

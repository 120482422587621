import React from  'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { cardPrepareFields } from '@utils/prepare-data/prepareProfilesData';
import UserCard from '@components/cards/user-card'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  newSearchContentBlock: {
    margin: '30px 0 20px 0',
    width: '100%',
    overflowY: 'auto',
  },
  wrapperUserCard: {
    margin: '5px 0',
    width: '100%',
  },
  noProfilesLabel: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.text.BL1,
  },
  noProfilesText: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.text.BL1,
  },
}))

export default function SearchProfiles({ usersList, checkboxHandleChange, getSources }) {
  const classes = useStyles();

  return (
    <Box ml={4} className={classes.newSearchContentBlock}>
      {!usersList.length ? (
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'baseline'}>
          <Typography className={classes.noProfilesLabel}>There aren't any matches for your search. </Typography>
          <Box mt={'30px'}>
            <Typography className={classes.noProfilesText}>TIPS</Typography>
            <Typography className={classes.noProfilesText}>• Ensure the name is spelled correctly</Typography>
            <Typography className={classes.noProfilesText}>• Try searching with less specific filters</Typography>
            <Typography className={classes.noProfilesText}>• You can narrow down your search later</Typography>
          </Box>
        </Box>
      ) : (
        <Grid container spacing={1} classes={{ root: classes.wrapperUserCard }}>
          {usersList && usersList.map((data) => {
            return (
              <Grid item xs={12} key={`searched-card-${data.id}`}>
                <UserCard
                  data={{ ...data, meta: cardPrepareFields({ ...data.meta, type: data.type }) }}
                  name={data.meta.name}
                  onSelect={checkboxHandleChange}
                  actionCard={() => getSources(data.id, data.meta.name)}
                  noLogged={true}
                />
              </Grid>
            )
          })}
        </Grid>
      )}
    </Box>
  )
}

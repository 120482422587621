import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { AddMonitoringIcon, AddMonitoringInactiveIcon, WarningIcon } from '@app/icons';

// import PopoverSearchFilter from './popover-search-filter';
import CustomIconButton from '@components/buttons/button-with-icon';
import CustomTooltip from '@components/controls/tooltip';
import { Modal } from '@components/modals';

import { useLocation } from 'react-router-dom';
import { clientApi } from '@app/api';
import { useSelector } from 'react-redux';
import history from '@app/history';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  customButtonWidth: {
    width: '230px',
  },
}));

const periods = {
  '1': 'Daily',
  '7': 'Weekly',
  '30': 'Monthly',
}

export default function SetMonitoring({ isReadOnly }) {
  const classes = useStyles();

  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [searchId] = useState(search.get('searchId'));
  const [searchType] = useState(search.get('searchType'));

  const mainText = `In order to put the search on ongoing monitoring, please link the search with the client, by saving as a new ${searchType.toLowerCase()} or updating the existing ${searchType.toLowerCase()}.`;

  const { currentSearch } = useSelector(store => store.backgroundCheck);

  const [ongoingMonitoring, setOngoingMonitoring] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const getOngoingMonitoring = useCallback(async () => {
    const res = await clientApi.ongoingMonitoring.getBySearchId(searchId);

    if (res.status === 200) {
      setOngoingMonitoring(res.data);
    }
  }, [searchId])

  useEffect(() => {
    getOngoingMonitoring();
  }, [getOngoingMonitoring])

  const handleOngoingMonitoring = async () => {
    if (!!currentSearch.linkedClientId) {
      history.push(`/search-history/update-existing-monitoring-table/${searchId}/${currentSearch.linkedClientId}`);
    } else {
      setOpenModal(true);
    }
  }
  
  const handleSaveToPortfolio = () => {
    history.push(`/search-history/save-to-portfolio/results?searchType=${currentSearch.type.toLowerCase()}&searchId=${searchId}&name=${currentSearch.name}`);
    setOpenModal(false);
  }

  const updateExisting = () => {
    history.push(`/search-history/update-existing/${currentSearch.type.toLowerCase()}/${searchId}`);
    setOpenModal(false);
  }

  const ongoingMonitoringLabel = !!ongoingMonitoring ? (
    <React.Fragment>
      <p style={{ textAlign: 'start' }}>
        Ongoing Monitoring: Enabled <br/>
        Monitoring Period: {periods[ongoingMonitoring.timeSliceDays]} <br/>
        Monitoring Notification: Email <br/>
      </p>
    </React.Fragment>
  ) : 'Monitoring not set for this name';

  return (
    <React.Fragment>
      <CustomIconButton
        onClick={() => handleOngoingMonitoring()}
        startIcon={isReadOnly ? <AddMonitoringInactiveIcon /> : <AddMonitoringIcon />}
        className="minLabel"
        disabled={isReadOnly}
      >
        Monitoring Settings
      </CustomIconButton>
      <Box display="flex" alignItems="center" mr={'5px'} ml={'-5px'}>
        <CustomTooltip
          title={ongoingMonitoringLabel}
          placement="top"
        >
          <WarningIcon />
        </CustomTooltip>
      </Box>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title={'Set Ongoing monitoring'}
        mainText={mainText}
        propsClassses={classes.customButtonWidth}
        actions={
          [
            {
              type: 'main',
              label: 'ATTACH TO EXISTING',
              action: () => updateExisting(),
            },
            {
              type: 'secondary',
              label: 'SAVE IN PORTFOLIO',
              action: () => handleSaveToPortfolio(),
            },
          ]
        }
        actionsDirection="row"
      />
    </React.Fragment>
  )
}

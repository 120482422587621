import React from 'react';
import { Route, Switch } from 'react-router';
import KycDocumentsList from './list';

export default function KycDocuments(props) {
  const { baseUri } = props

  return (
    <Switch>
      <Route path={`${baseUri}`}>
        <KycDocumentsList {...props} />                
      </Route>
    </Switch>
  )
}
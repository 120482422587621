import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { Box, Card, Grid, IconButton, Typography } from '@material-ui/core';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import {
  DragAndDropIcon,
  RiskRatingTemplateAddIcon,
  RiskRatingTemplateDeleteIcon,
  RiskRatingTemplateEditIcon,
  RiskRatingTemplateRemoveIcon,
} from '@app/icons';
import CustomQuestionDialog from './dialogs/custom-question-dialog';
import questionTypes from './enums/questionTypes';
import { makeStyles } from '@material-ui/core/styles';
import CustomTooltip from '@components/controls/tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import isTrueIncludeZero from '@utils/isTrueIncludeZero';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const useStyles = makeStyles(theme => ({
  tablePlaceholder: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#A4A4A4'
  },
  questionItem: {
    '&:hover': {
      '& .showOnHover': {
        opacity: 1,
        transition: 'opacity 0.4s'
      }
    },
    '& .showOnHover': {
      opacity: 0,
    }
  },
  rightQuestionItem: {
    borderRadius: '8px',
  },
  questionItemHoverable: {
    '&:hover': {
      backgroundColor: '#F8F8F8',
    }
  },
  questionItemDragging: {
    backgroundColor: '#F8F8F8',
    '& .showOnDragging': {
      opacity: 1,
    },
  },
  questionItemSelected: {
    backgroundColor: 'rgba(0, 0, 0, 0.01)',
    '& p': {
      opacity: 0.5,
    }
  },
  customScrollbar: {
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      boxShadow: 'inset 0 0 14px 14px #656565'
    },
  },
  iconButton: {
    padding: 2,
    marginRight: 1,
  },
  iconButton2: {
    padding: 0,
    margin: '-2px -2px -2px 0px', // Icon used is not standardizes...
  },
}))

const QuestionItem = ({ question, type, added, onClick, hideTooltip }) => {
  const classes = useStyles();
  return (
    <Box py="4px">
      <Card className={clsx(classes.questionItem, added && classes.questionItemSelected)} onClick={onClick}>
        <Box display="flex" px={2} py={1} alignItems="center">
          <Box flexGrow={1}>
            <Box>
              <Typography style={{ color: '#232323' }}><b>{question}</b></Typography>
            </Box>
            <Box mt="4px">
              <Typography>{type}</Typography>
            </Box>
          </Box>
          <Box className="showOnHover">
            {hideTooltip ?
              added ? <RiskRatingTemplateRemoveIcon /> : <RiskRatingTemplateAddIcon />
              :
              <CustomTooltip title={added ? 'Remove question' : 'Select question'} placement="top-end">
                {added ? <RiskRatingTemplateRemoveIcon /> : <RiskRatingTemplateAddIcon />}
              </CustomTooltip>
            }
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

const BadgeLabel = ({ children }) => {
  return (
    <Box
      mx={1}
      width={23}
      height={23}
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{
        borderRadius: '50%',
        backgroundColor: '#E9F4F4'
      }}
    >
      <Typography style={{ color: '#484848' }}>{children}</Typography>
    </Box>
  )
}

const RightQuestionItem = ({ question, type, onUpdate, onDrop, isDragging, isUpdate, onInclude, onExclude }) => {
  const classes = useStyles();

  const getValue = (name) => {
    if (isTrueIncludeZero(question.userInput?.[name])) {
      return question.userInput[name].toString();
    }

    if (question.possibleAnswers?.length) {
      if (question.possibleAnswers.some(e => isTrueIncludeZero(e[name]))) {
        return '✔'
      }
    }
    return undefined;
  }

  const answerValue = getValue('answerValue');
  const answerWeight = getValue('answerWeight');
  return (
    <Box py="4px">
      <Box
        px={2} py={1}
        display="flex"
        alignItems="center"
        className={clsx(
          classes.questionItem,
          classes.questionItemHoverable,
          classes.rightQuestionItem,
          isDragging && classes.questionItemDragging,
          question.excluded && classes.questionItemSelected,
        )}
      >
        <Box ml={-1} pr={1} className="showOnHover showOnDragging">
          <DragAndDropIcon />
        </Box>
        <Box flexGrow={1}>
          <Box>
            <Typography style={{ color: '#232323' }}><b>{question.questionText}</b></Typography>
          </Box>
          <Box mt="4px" height={23} display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              <Typography>{type}</Typography>
            </Box>
            <Box display="flex" pr="4px" alignItems="center">
              <Box display="flex" alignItems="center" style={{ visibility: answerValue ? 'visible' : 'hidden' }}>
                <Typography style={{ color: '#232323' }}><b>RISK</b></Typography>
                <BadgeLabel>{answerValue}</BadgeLabel>
              </Box>
              <Box display="flex" alignItems="center" style={{ visibility: answerWeight ? 'visible' : 'hidden' }}>
                <Typography style={{ color: '#232323' }}><b>WEIGHT</b></Typography>
                <BadgeLabel>{answerWeight}</BadgeLabel>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="showOnHover">
          <Box>
            <CustomTooltip title="Configure question" placement="top-end">
              <IconButton className={classes.iconButton} onClick={onUpdate}>
                <RiskRatingTemplateEditIcon width={16} height={16} />
              </IconButton>
            </CustomTooltip>
          </Box>
          <Box>
            {isUpdate ?
              question.excluded ?
                <CustomTooltip title="Include in your template" placement="bottom-end">
                  <IconButton className={classes.iconButton} onClick={onInclude}>
                    <RiskRatingTemplateAddIcon width={16} height={16} />
                  </IconButton>
                </CustomTooltip>
                :
                <CustomTooltip title="Exclude from your template" placement="bottom-end">
                  <IconButton className={classes.iconButton} onClick={onExclude}>
                    <RiskRatingTemplateRemoveIcon width={16} height={16} />
                  </IconButton>
                </CustomTooltip>
              :
              <CustomTooltip title="Remove question" placement="bottom-end">
                <IconButton className={classes.iconButton} onClick={onDrop}>
                  <RiskRatingTemplateDeleteIcon width={16} height={16} />
                </IconButton>
              </CustomTooltip>
            }
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default function RiskRatingSettingsForm({ sourceQuestions, questions, setQuestions }) {

  const classes = useStyles()
  const isUpdate = !sourceQuestions;

  const use2ColumnLayout = useMediaQuery('(min-width:1368px)');

  const [pendingCustomQuestionId, setPendingCustomQuestionId] = useState(false)

  const onStartCustomQuestionFlow = () => setPendingCustomQuestionId(null)

  const onUpdateQuestionQuestionClick = (id, item) => setPendingCustomQuestionId(id)

  const onSaveCustomQuestion = data => {
    if (pendingCustomQuestionId) {
      setQuestions(
        questions.map(q =>
          q.id === pendingCustomQuestionId
            ? {
              ...q,
              ...data
            }
            : q
        )
      )
    } else {
      setQuestions(
        [
          ...questions,
          {
            ...data,
            isCustom: true
          }
        ]
      )
    }
    setPendingCustomQuestionId(false)
  }

  const formattedSourceQuestions = useMemo(() => {
    return sourceQuestions?.map(q => ({
      ...q,
      typeLabel: questionTypes[q.type]
    }))
  }, [sourceQuestions])

  const formattedQuestions = useMemo(() => {
    return questions.map(q => ({
      ...q,
      typeLabel: questionTypes[q.type]
    }))
  }, [questions])

  const onPickSourceQuestionClick = (id, item) => {
    let newQuestions = []
    let shouldOpenDialog = false;
    if (!questions.some(q => q.id === id)) {
      newQuestions = [
        ...questions,
        item
      ]
      shouldOpenDialog = true
    } else {
      newQuestions = questions.filter(q => q.id !== id)
    }
    setQuestions(newQuestions)
    if (shouldOpenDialog) {
      setPendingCustomQuestionId(id)
    }
  }

  const onDropQuestionClick = (id, item) => {
    setQuestions(
      questions.filter(q => q.id !== id)
    )
  }

  const onIncludeClick = (id, item) => {
    setQuestions(
      questions.map(q =>
        q.id === id
          ? {
            ...q,
            excluded: false
          } : q
      )
    )
  }

  const onExcludeClick = (id, item) => { 
    setQuestions(
      questions.map(q =>
        q.id === id
          ? {
            ...q,
            excluded: true
          } : q
      )
    )
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newQuestions = Array.from(questions);
    const [removed] = newQuestions.splice(result.source.index, 1);
    newQuestions.splice(result.destination.index, 0, removed);

    setQuestions(newQuestions)
  }

  return (
    <React.Fragment>
      <Box display={'flex'} flexGrow={1}>
        <Grid container>
          {sourceQuestions &&
            <Grid item md={use2ColumnLayout ? 6 : 12}>
              <Box display={'flex'} flexDirection={'column'} pr={use2ColumnLayout ? '12px' : 0}>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  px={1}
                  height={'34px'}
                >
                  <CustomTooltip
                    title={'Select questions to move them on the right panel.'}
                    width={180}
                    placement="top">
                    <Typography variant={'h5'}>
                      All Questions
                    </Typography>
                  </CustomTooltip>
                </Box>
                <Box
                  mt={2}
                  borderRadius="8px"
                  maxHeight="calc(100vh - 510px)"
                  height="calc(100vh - 510px)"
                  style={{
                    backgroundColor: '#F8F8F8',
                    padding: '0px 4px',
                    overflowY: 'auto',
                    border: '8px solid #F8F8F8',
                    borderTopWidth: '12px',
                    borderBottomWidth: '12px',
                  }}
                  className={classes.customScrollbar}
                >
                  {formattedSourceQuestions.map(question => (
                    <QuestionItem
                      key={question.id}
                      question={question.questionText}
                      type={question.typeLabel}
                      added={!!formattedQuestions.find(e => e.id === question.id)}
                      onClick={() => onPickSourceQuestionClick(question.id, question)}
                      hideTooltip={!!pendingCustomQuestionId}
                    />
                  ))}
                </Box>
              </Box>
            </Grid>
          }
          <Grid item md={!!sourceQuestions && use2ColumnLayout ? 6 : 12}>
            <Box display={'flex'} flexDirection={'column'} pl={!!sourceQuestions && use2ColumnLayout ? '12px' : 0}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                height={'34px'}
              >
                <Box display={'flex'} px={1}>
                  <Typography variant={'h5'}>
                    Template Questions List
                  </Typography>
                </Box>
                <Box display={'flex'}>
                  <ButtonWithIcon
                    startIcon={<RiskRatingTemplateAddIcon />}
                    onClick={onStartCustomQuestionFlow}
                  >
                    Add Custom Question
                  </ButtonWithIcon>
                </Box>
              </Box>
              <Box mt={2}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <Card
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          width: 'calc(100% - 16px)',
                          maxHeight: 'calc(100vh - 512px)',
                          height: 'calc(100vh - 512px)',
                          padding: '12px 8px',
                          cursor: 'default',
                          overflowY: 'auto',
                        }}
                      >
                        {!formattedQuestions.length &&
                          <Box width="100%" height="100%" display="flex" alignItems="center">
                            <Box width="100%">
                              <Typography variant="h5" align="center">Select questions from the left side to add it here,</Typography>
                              <Typography variant="h5" align="center">or add your own question by clicking "Add Custom Question"</Typography>
                            </Box>
                          </Box>
                        }
                        {formattedQuestions.map((question, index) => (
                          <Draggable key={question.id} draggableId={question.id} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <RightQuestionItem
                                  isDragging={snapshot.isDragging}
                                  question={question}
                                  type={question.typeLabel}
                                  onDrop={() => onDropQuestionClick(question.id, question)}
                                  onUpdate={() => onUpdateQuestionQuestionClick(question.id, question)}
                                  onExclude={() => onExcludeClick(question.id, question)}
                                  onInclude={() => onIncludeClick(question.id, question)}
                                  isUpdate={isUpdate}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </Card>
                    )}
                  </Droppable>
                </DragDropContext>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <CustomQuestionDialog
        open={pendingCustomQuestionId !== false}
        onClose={() => setPendingCustomQuestionId(false)}
        onSave={onSaveCustomQuestion}
        sourceData={pendingCustomQuestionId && questions.find(q => q.id === pendingCustomQuestionId)}
      />
      {/* <TableList
                      columns={TABLE_COLUMNS}
                      items={formattedQuestions}
                      inlineActions={[
                        // Update, only for custom questions or if no source questions
                        {
                          icon: <EditIcon />,
                          tooltip: 'Update question',
                          handler: onUpdateQuestionQuestionClick
                        },
                        // Include/Exclude (when no source shown)
                        {
                          icon: <RiskRatingTemplateAddIcon />,
                          tooltip: 'Include in your template',
                          hidden: (id, item) => {
                            return !(
                              (!!sourceQuestions && item.isCustom && item.excluded)
                              || (!sourceQuestions && item.excluded)
                            )
                          },
                          handler: onIncludeClick
                        },
                        {
                          icon: <RiskRatingTemplateRemoveIcon />,
                          tooltip: 'Remove from your template',
                          hidden: (id, item) => {
                            return !(
                              (!sourceQuestions && !item.excluded)
                            )
                          },
                          handler: onExcludeClick
                        },
                        // Drop (when source shown)
                        {
                          icon: <RiskRatingTemplateRemoveIcon />,
                          tooltip: 'Remove from your template',
                          hidden: (id, item) => {
                            return !sourceQuestions
                          },
                          handler: onDropQuestionClick
                        },
                      ]}
                      inlineActionsHoverOnly
                      disabledRow={(id, item) => {
                        return !!item.excluded
                      }}
                      sortable
                      onSortChange={setQuestions}
                    />
                    {
                      !questions.length &&
                      <Box display={'flex'} className={classes.tablePlaceholder}>
                        Select questions from the left panel
                      </Box>
                    } */}
    </React.Fragment>

  )
}
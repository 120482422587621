import React, { useEffect, useState } from 'react';
import { Box, Grid, Link, Typography, Button } from '@material-ui/core';
import { DevExtractedBgCheckBigIcon } from '@app/icons';
import history from '@app/history';
import InnerHeader from '@components/layout/inner-header';
import PageLayout from '@components/layout/page-layout';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { ChevronLeftIcon } from '@app/icons';
import { useParams } from 'react-router-dom';
import { clientApi } from '@app/api';
import LinkButton from '@components/buttons/link-button';

export default function CreateBgCheck(props) {
  const { cachedModel, steps, type, handleNext } = props;
  const { clientId } = useParams();
  const [name, setName] = useState('');

  useEffect(() => {
    if (cachedModel) {
      if (type === 'person') {
        setName(`${cachedModel.basicDetails.firstName} ${cachedModel.basicDetails.lastName}`);
      } else {
        setName(`${cachedModel.basicDetails.name}`)
      }
    } else {
      const getName = async () => {
        const res = await clientApi.client.show(clientId);
        if (type === 'person') {
          setName(`${res.data.firstName} ${res.data.lastName}`);
        } else {
          setName(`${res.data.name}`)
        }
      }

      getName();
    }
  }, [cachedModel, type, clientId])

  const openClientView = () => {
    history.push(`/portfolio/view/${clientId}`)
  }

  return (
    <PageLayout>
      <Box px={6}>
        <InnerHeader
          title="Background Check"
          stepper={{
            currentStep: steps.currentStep,
            stepsCount: steps.stepsCount,
          }}
          buttons={
            <React.Fragment>
              <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={() => history.goBack()}>
                Back
              </ButtonWithIcon>
            </React.Fragment>
          }
        />
      </Box>
      <Box mt={6}>
        <Grid container>
          <Grid item xs={12} container justify="center">
            <Box mb={5}>
              <DevExtractedBgCheckBigIcon />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={1}>
              <Typography align="center" variant="body1">
                Complete a background check for
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="body1">
              <Link variant="body1" onClick={openClientView}>{name}</Link> in three simple steps
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box mt={8} mb={3} display="flex" alignItems="center" flexDirection="column">
        <Button style={{ width: '208px' }} variant="contained" onClick={() => handleNext(clientId, true)}>
          NEXT
        </Button>
        <Box mt={1} display="flex" justifyContent="center">
          <LinkButton style={{ width: '208px' }} onClick={() => handleNext(clientId, false)}>
            SKIP
          </LinkButton>
        </Box>
      </Box>
    </PageLayout>
  )
}
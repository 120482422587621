
/**
 * Don't assign colors explicitly with color: color.text.LB1 ,
 * use getColorStyles() func instead
 *
 * @type {{semantic: {success: string, warning: string, error: string}, gradients: {BB1: {background: string}, LB1: {background: string}, GB6: {background: string}, LB2: {background: string}, TG2: {background: string}, TG1: {background: string}}, elements: {TQ3: string, GR3: string, LB3: string, BW1: string, LB2: string}, backgrounds: {GY3: string, GY2: string, GY4: string, TQ2: string, TQ1: string, TQ4: string, GR2: string, TQ3: string, GR1: string, BG2: string, BG1: string, GY1: string}, text: {GY3: string, GY5: string, GY4: string, GY6: string, BL1: string, LB1: string, LB3: string}}}
 */
const palette = {
  drawer: '#E9F4F4',
  header: 'linear-gradient(108.7deg, #D5F0F0 53.79%, #DAEDDA 96.77%)',
  text: {
    LB1: 'linear-gradient(100.63deg, #7A5B44 58.63%, rgba(173, 142, 131, 0.92) 143.04%)',
    LB3: '#7A5B44',
    BL1: '#232323',
    GY6: '#484848',
    GY5: '#656565',
    GY4: '#A4A4A4',
    GY3: '#C6C6C6'
  },
  element: {
    BW1: '#534D49',
    LB2: 'linear-gradient(270deg, #9A7C6A 0%, #7A5B44 100%)',
    LB3: '#7A5B44',
    GR3: '#CAD7CA',
    TQ3: 'linear-gradient(108.7deg, #D5F0F0 53.79%, #DAEDDA 96.77%)'
  },
  background: {
    TQ1: '#232323',
    TQ2: '#DDF3F3',
    TQ3: '#D5F0F0',
    TQ4: '#C7EAEE',
    BG1: '#F3EFEC',
    BG2: '#EDE9E6',
    GR1: '#ECF0ED',
    GR2: '#E3E9E6',
    GY1: '#F8F8F8',
    GY2: '#EDEDED',
    GY3: '#C6C6C6',
    GY4: '#A4A4A4',
  },
  semantic: {
    success: '#4E922C',
    warning: '#A98320',
    error: '#B6514C'
  },
  gradient: {
    TG1: 'linear-gradient(108.7deg, #D5F0F0 53.79%, #DAEDDA 96.77%)',
    TG2: 'linear-gradient(103.31deg, #D5F0F0 51.64%, #DAEDDA 115.1%)',
    LB1: 'linear-gradient(100.63deg, #7A5B44 58.63%, rgba(173, 142, 131, 0.92) 143.04%)',
    LB2: 'linear-gradient(270deg, #9A7C6A 0%, #7A5B44 100%)',
    GB6: 'linear-gradient(3.25deg, #534D49 36.41%, #6A6765 88.7%)',
    BB1: 'linear-gradient(14.49deg, #534D49 15.56%, #2E2E2E 75.25%)',
  },
  folder: {
    folder1: '#FFFFFF',
    folder2: '#FFF1FB',
    folder3: '#FFF1F1',
    folder4: '#FFF6F1',
    folder5: '#FFFCF0',
    folder6: '#F5FFF1',
    folder7: '#F1FBFF',
    folder8: '#F1F2FF'
  },
  tertiary: {
    '1_1': '#FFF1FB',
    '1_2': '#FFF1F1',
    '1_3': '#FFF6F1',
    '1_4': '#FFFCF0',
    '1_5': '#F5FFF1',
    '1_6': '#F1FBFF',
    '1_7': '#F1F2FF',

    '2_1': '#E654BD',
    '2_2': '#FA5B54',
    '2_3': '#FEA26E',
    '2_4': '#D6B02C',
    '2_5': '#6EC744',
    '2_6': '#6EDBFE',
    '2_7': '#6E7CFE',

    '3_1': '#B64B99',
    '3_2': '#B6514C',
    '3_3': '#B5734A',
    '3_4': '#A98320',
    '3_5': '#4E922C',
    '3_6': '#4A9BB5',
    '3_7': '#4D55B7',
  },
  error: {
    light: '#e57373',
    main: '#B6514C',
    dark: '#d32f2f',
    contrastText: '#fff',
  }
};

export default palette;
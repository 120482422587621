import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {
  Box, FormControl, InputLabel, MenuItem, Typography, Select, capitalize, FormHelperText
} from '@material-ui/core';

import _ from 'lodash';
import RiskRatingCard from './components/card';
import RiskRatingSlider from './components/slider';
import RiskRatingIcon from '@components/flexible-icons/risk-rating-icon';
import ExcludeDialog from './components/exclude-dialog';
import NoteDeleteDialog from './components/note-delete-dialog';
import NextReviewPicker from '@components/calendar/next-date-picker';
import {makeStyles} from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import prepareTimestamp from '@utils/date';
import CustomTooltip from '@components/controls/tooltip';
import {SaveIcon} from '@app/icons';
import SaveTemplateDialog from './components/save-template-dialog';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import {useSnackbar} from 'notistack';
import riskRatingTemplateDto from './dto/risk-rating-template-dto';
import {riskRatingTemplate} from '@app/api/client';
import debounce from 'awesome-debounce-promise';
import CardComment from '@components/layout/card-comments';

const useStyles = makeStyles((theme) => ({
  riskIcon: {
    width: '18px',
    height: '18px',
    marginTop: '3px'
  }
}))

export default function FormInner({ data, setData, setReady, readOnly = false, setItemEl }) {

  const classes = useStyles()

  const [pendingExcludeID, setPendingExcludeID] = useState()
  const [pendingNoteDeleteID, setPendingNoteDeleteID] = useState()
  const [pendingSaveTemplate, setPendingSaveTemplate] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const onExcludeConfirm = () => {
    if(pendingExcludeID) {
      const index = data.items.findIndex(item => item.id === pendingExcludeID)
      linkState(['items', index, 'excluded'])(1)
      setPendingExcludeID(null)
    }
  }

  const onNoteDeleteConfirm = () => {
    if(pendingNoteDeleteID) {
      const index = data.items.findIndex(item => item.id === pendingNoteDeleteID)
      linkState(['items', index, 'notes'])([])
      setPendingNoteDeleteID(null)
    }
  }

  const orderDataItems = data => {
    data.items = _.orderBy(
      data.items,
      item => item?.excluded || false
    )
  }

  const linkState = useCallback((key, mass = false) =>
    value => {
      if(!readOnly) {
        let dataClone = _.cloneDeep(data)
        const newValue = mass
          ? {
            ..._.get(dataClone, key),
            ...value
          }: value
        _.set(dataClone, key, newValue)
        // and reorder if exclude changes
        if(key.indexOf('excluded') !== -1) {
          orderDataItems(dataClone)
        }
        setData(dataClone);
      }
    }, [data, setData, readOnly])

  const totalRisk = useMemo(() => {
    return (data?.items || []).reduce((acc, item) => {
      if(!item.excluded) {
        acc += (item.riskValue || 0) * (item.weightValue || 0)
      }
      return acc
    }, 0)
  }, [data])

  const maxRisk = useMemo(() => {
    return (data?.items || []).filter(item => !item.excluded).length * 100
  }, [data])

  const complete = useMemo(() =>
    data?.items?.reduce((acc, item) => {
      return acc
                && (
                  (item.riskValue !== null && item.weightValue !== null) || item.excluded
                )
    }, true) && !!data?.items?.some(item => !item.excluded)
  , [data])

  useEffect(() => {
    if(setReady) {
      setReady(!!complete && !!data?.riskRating && !!data?.nextReview)
    }
    }, [complete, data?.riskRating, data?.nextReview]) // eslint-disable-line

  const defaultNextReviewMap = {
    exempted: 3,
    low: 3,
    medium: 2,
    high: 1,
    'closely-monitoring': 1
  }

  // Slider support
  const sliderValue = useMemo(() => {
    return totalRisk/(maxRisk||1)*100
  }, [maxRisk, totalRisk])

  const sliderFakeValue = useMemo(() => {
    return Math.max(0, Math.min(95, sliderValue))
  }, [sliderValue])

  const onRiskRatingChange = useCallback((value) => {
    setData({
      ...data,
      nextReview: null,
      riskRating: value
    })
    }, [linkState]) //eslint-disable-line

  const sliderMarks = useMemo(() => {
    const marks = []
    if(sliderValue > 6) {
      marks.push({ value: 0.5, label: 0})
    }
    if(sliderValue < 94) {
      marks.push({ value: 98.5, label: maxRisk || 1})
    }
    return marks.length? marks: null
  }, [sliderValue, maxRisk])

  const onSaveTemplate = (createTemplateFormData) => {
    const templateData = riskRatingTemplateDto.write(data)
    templateData.templateName = createTemplateFormData?.name
    riskRatingTemplate.store(templateData).then(() => {
      enqueueSnackbar(`Risk rating template "${templateData.templateName}" was successfully saved!`, { variant: 'success' })
    })
  }

  // eslint-disable-next-line
    const debouncedSetNote = useCallback(
    debounce(linkState(['note']), 500),
    [linkState(['note'])]
  )

  const onNoteChange = newNote => {
    debouncedSetNote(newNote)
  }

  return (
    <React.Fragment>
      <Box display={'flex'} flexDirection={'column'} my={2}>
        {
          data?.items && data.items.map((item, index) => (
            <Box
              key={`risk-rating-card-${item.id}`}
              mt={1}
              ref={(element) => setItemEl && setItemEl(item.id, element)}
            >
              <RiskRatingCard
                {...item}
                setValue={linkState(['items', index, 'value'])}
                setExcluded={linkState(['items', index, 'excluded'])}
                setNotes={linkState(['items', index, 'notes'])}
                setRiskValue={linkState(['items', index, 'riskValue'])}
                setWeightValue={linkState(['items', index, 'weightValue'])}
                setError={linkState(['items', index, 'error'])}
                setBatchValues={linkState(['items', index], true)}
                readOnly={readOnly}
                setPendingExcludeID={setPendingExcludeID}
                setPendingNoteDeleteID={setPendingNoteDeleteID}
              />
            </Box>
          ))
        }
        <Box display={'flex'} justifyContent={'center'} ref={(element) => setItemEl && setItemEl('general', element)}>
          { !!data?.errors?.general &&
          <FormHelperText error> {data?.errors?.general}</FormHelperText>
          }
        </Box>
        <Box mt={3} display={'flex'} alignItems={'center'}>
          <CustomTooltip title={'Save this combination of questions for future use. The saved template will be available to all users in your account.'}>
            <Box>
              <ButtonWithIcon
                onClick={() => setPendingSaveTemplate(true)}
                startIcon={<SaveIcon />}
              >
                Save as template...
              </ButtonWithIcon>
            </Box>
          </CustomTooltip>
        </Box>
        <Box mt={4}>
          <RiskRatingSlider
            value={sliderFakeValue}
            valueLabelFormat={() => totalRisk}
            step={1}
            valueLabelDisplay="on"
            min={0}
            max={100}
            marks={sliderMarks}
          />
        </Box>
        <Box mt={'18px'} mb={'21px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box display={'flex'} alignItems={'center'} flexDirection={'row'}>
            { readOnly && data?.riskRating &&
                <React.Fragment>
                  <Box mr={1}>
                    <RiskRatingIcon
                      risk={data?.riskRating}
                      className={classes.riskIcon}
                    />
                  </Box>
                  <Typography style={{fontWeight:'bold', fontSize: '16px'}} variant={'body1'}>{capitalize(data?.riskRating)} Risk</Typography>
                </React.Fragment>
            }
          </Box>
          <Box>
            <Typography style={{fontWeight:'bold'}} variant={'body1'}>Sum Total = {totalRisk}</Typography>
          </Box>
        </Box>
        { !readOnly &&
          <React.Fragment>
            <Box
              mt={2}
              id={'riskRating'}
              ref={(element) => setItemEl && setItemEl('riskRating', element)}
            >
              <CustomTooltip
                title={
                  !complete &&
                          'Before defining risk rating set the answer, risk value and weight for all the questions which are not excluded'}
                placement="top"
              >
                <FormControl
                  fullWidth
                  variant="outlined"
                  disabled={!complete || readOnly}
                  error={!!data?.errors?.riskRating}
                >
                  <InputLabel>Set Risk Rating</InputLabel>
                  <Select
                    value={data?.riskRating || ''}
                    onChange={event => onRiskRatingChange(event.target.value)}
                    //inputProps={{ readOnly: readOnly }}
                  >
                    <MenuItem value={'exempted'}>Exempted</MenuItem>
                    <MenuItem value={'low'}>Low</MenuItem>
                    <MenuItem value={'medium'}>Medium</MenuItem>
                    <MenuItem value={'high'}>High</MenuItem>
                    <MenuItem value={'closely-monitoring'}>Closely Monitoring</MenuItem>
                  </Select>
                  { !!data?.errors?.riskRating &&
                      <FormHelperText> {data?.errors?.riskRating} </FormHelperText>
                  }
                </FormControl>
              </CustomTooltip>
            </Box>
            <Box 
              mt={2}
              ref={(element) => setItemEl && setItemEl('nextReview', element)}
            >
              <CustomTooltip
                title={
                  (!complete || !data?.riskRating) &&
                          'Before defining next review date set the risk rating'}
                placement="top"
              >
                <Box>
                  <NextReviewPicker
                    value={data?.nextReview || ''}
                    defaultValue={defaultNextReviewMap?.[data.riskRating] || 1}
                    onChange={linkState(['nextReview'])}
                    disabled={!complete || !data?.riskRating || readOnly}
                  />
                </Box>
              </CustomTooltip>
            </Box>
            { data?.nextReview &&
              <Box mt={1} display={'flex'} justifyContent={'center'}>
                <Typography variant={'caption'} style={{color: grey[500]}}>
                  Review scheduled for {prepareTimestamp(data.nextReview, 'MM/DD/YYYY', false)}
                </Typography>
              </Box>
            }
          </React.Fragment>
        }
        { (!!data?.note || !readOnly) &&
          <Box mt={2} ref={(element) => setItemEl && setItemEl('note', element)}>
            <CardComment
              title={'Note'}
              comment={data?.note || ''}
              placeholder={''}
              inlineEdit={true}
              setComment={onNoteChange}
              maxLength={2000}
            />
            { !!data?.errors?.note &&
              <FormHelperText error> {data?.errors?.note}</FormHelperText>
            }
          </Box>
        }
      </Box>
      <ExcludeDialog open={!!pendingExcludeID} onClose={() => setPendingExcludeID(null)} onConfirm={onExcludeConfirm}/>
      <NoteDeleteDialog open={!!pendingNoteDeleteID} onClose={() => setPendingNoteDeleteID(null)} onConfirm={onNoteDeleteConfirm}/>
      <SaveTemplateDialog open={!!pendingSaveTemplate} onClose={() => setPendingSaveTemplate(false)} onSave={onSaveTemplate}/>
    </React.Fragment>
  )
}
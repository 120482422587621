import React from  'react';
import PageLayout from '@components/layout/page-layout';
import history from '@app/history';
import { Route, Switch } from 'react-router';
import Search from './subpages/search';
import RepeatSearch from './subpages/repeat-search';
import NewSearch from './subpages/new-search';
import UserNoteList from './subpages/user-note-list';
import UpdateExisting from './subpages/update-existing';
import UpdateExistingConfirm from './subpages/update-existing-confirm';
import UpdateExistingMonitoring from './subpages/update-existing-monitoring';
import LoggedNotFound from '@views/protected/not-found';

export default function BackgroundCheck(props) {

  return (
    <PageLayout>
      <Switch>
        <Route exact path="/background-check/repeat-search">
          <RepeatSearch
            onRedirect={(params) =>
              history.push(`/background-check/repeat-search/?searchId=${params.searchId}&searchName=${params.searchName}&searchType=${params.searchType}`)
            }
            onWhitelisted={(params) =>
              history.push(`/background-check/results?searchId=${params.searchId}&searchName=${params.searchName}&searchType=${params.searchType}`)
            }
            onUpdateExisting={(params) =>
              history.push(`/background-check/update-existing-no-profile/${params.type}/${params.searchId}`)
            }
          />
        </Route>
        <Route exact path="/background-check/results">
          <NewSearch
            onNext={(params) =>
              history.push(`/background-check/user-note-list?searchId=${params.searchId}&profileId=${params.profileId}&searchName=${params.searchName}`)
            }
            onNextAlt={(params) =>
              history.replace(`/background-check/repeat-search/?searchId=${params.searchId}&searchName=${params.searchName}&searchType=${params.searchType}`)
            }
          />
        </Route>
        <Route exact path="/background-check/user-note-list">
          <UserNoteList
            baseRoute="background-check"
          />
        </Route>

        {/* Update monitoring steps */}
        <Route exact path="/background-check/update-existing/:type/:searchId/:profileId">
          <UpdateExisting
            flow="background-check"
            steps={{ currentStep: 1, stepsCount: 3 }}
            onNext={(params) => {
              const link = `/background-check/update-existing-confirm/${params.type}/${params.searchId}/${params.profileId}/${params.clientId}`;
              params.isReplace
                ? history.replace(link)
                : history.push(link)
            }}
          />
        </Route>
        <Route exact path="/background-check/update-existing-confirm/:type/:searchId/:profileId/:clientId/:tab?">
          <UpdateExistingConfirm
            getBaseUri={(params) =>
              `/background-check/update-existing-confirm/${params.type}/${params.searchId}/${params.profileId}/${params.clientId}`}
            flow="background-check"
            steps={{ currentStep: 2, stepsCount: 3 }}
            onNext={(params) =>
              history.push(`/background-check/update-existing-monitoring/${params.searchId}/${params.clientId}`)}
          />
        </Route>
        <Route exact path="/background-check/update-existing-monitoring/:searchId/:clientId">
          <UpdateExistingMonitoring
            flow="background-check"
            steps={{ currentStep: 3, stepsCount: 3 }}
            onNext={(params) =>
              history.push(`/portfolio/view/${params.clientId}/background-check`)}
          />
        </Route>

        {/* Update monitoring no hits steps */} 
        <Route exact path="/background-check/update-existing-no-profile/:type/:searchId">
          <UpdateExisting
            flow="background-check"
            steps={{ currentStep: 1, stepsCount: 3 }}
            onNext={(params) => {
              const link = `/background-check/update-existing-confirm-no-profile/${params.type}/${params.searchId}/${params.clientId}`;
              params.isReplace
                ? history.replace(link)
                : history.push(link)
            }}
          />
        </Route>
        <Route exact path="/background-check/update-existing-confirm-no-profile/:type/:searchId/:clientId/:tab?">
          <UpdateExistingConfirm
            getBaseUri={(params) =>
              `/background-check/update-existing-confirm-no-profile/${params.type}/${params.searchId}/${params.clientId}`}
            flow="background-check"
            steps={{ currentStep: 2, stepsCount: 3 }}
            onNext={(params) =>
              history.push(`/background-check/update-existing-monitoring/${params.searchId}/${params.clientId}`)}
          />
        </Route>

        <Route exact path="/background-check/with/:search_line">
          <Search
            onNext={(params) =>
              history.push(`/background-check/results?searchId=${params.searchId}&searchName=${params.searchName}&searchType=${params.searchType}`)}
          />
        </Route>
        <Route exact path="/background-check">
          <Search
            onNext={(params) =>
              history.push(`/background-check/results?searchId=${params.searchId}&searchName=${params.searchName}&searchType=${params.searchType}`)}
          />
        </Route>
        <Route path="*" exact>
          <LoggedNotFound />
        </Route>
      </Switch>
    </PageLayout>
  )
};

import React from 'react';

import { adminApi } from '@app/api/';
import { useSnackbar } from 'notistack';
import CategoryCreateDialog from '../../../../../components/complex/client-view/kyc-documents/components/category-create-dialog';

export default function CategoryDialog({ open, onClose, editData, fetchData }) {
  const { enqueueSnackbar } = useSnackbar();

  const createDocument = async (model) => {
    await adminApi.category.createCategory({ ...model })
    enqueueSnackbar(`Document Category "${model.name}" was successfully created!`, { variant: 'success' });
    fetchData();
    onClose();
  }

  const updateDocument = async (model) => {
    await adminApi.category.updateCategory(editData.id, { ...model })
    fetchData();

    enqueueSnackbar(`Document Category "${model.name}" was successfully updated!`, { variant: 'success' });
    onClose();
  }

  return (
    <CategoryCreateDialog
      open={open}
      onClose={onClose}
      category={editData}
      onSave={editData ? updateDocument : createDocument}
      title={editData ? 'Update Document Category' : 'Add Document Category'}
    />
  )
}
import React, { useEffect, useMemo } from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  Switch,
  RadioGroup, Typography,
} from '@material-ui/core';

import history from '@app/history';
import { clientApi } from '@app/api';
import { ChevronLeftIcon, SearchIcon, SearchLegalEntity } from '@app/icons';

import ButtonWithIcon from '@components/buttons/button-with-icon';
import InnerHeader from '@components/layout/inner-header';
import ViewToggle from '@components/controls/view-toggle';
import OutlinedSearch from '@components/inputs/outlined-search';
import FilterWithContext from '@components/dropdowns/filter-with-context';
import CardList from '@components/lists/card-list';
import TableList, { TCELL_TYPES } from '@components/lists/table-list';
import NoResultsFound from '@components/layout/no-results-found';
import debounce from 'awesome-debounce-promise';

import { prepareClient, getSortField, getSortFields } from '@dto/client';
import { useParams } from 'react-router-dom';
import getRiskRatingLabel from '@utils/riskRatingLabel';
import { normalizeName, reverseName } from '@utils/textUtil';
import { useSnackbar } from 'notistack';
import { useFirstRender } from '@hooks/useFirstRender';
import { outdatedChip } from '../../../../../components/badges/outdated-chip';

const CARD_LABELS = {
  risk_score: 'RISK RATING',
  //middle_name: 'ALIASES',
  whitelist: 'WHITELIST',
  date_of_birth: 'DOB',
  gender: 'GENDER',
  citizenship: 'CITIZENSHIP',
  last_review_at: 'LAST REVIEW',
  next_review_at: 'NEXT REVIEW',
  monitoring: 'MONITORING',
}

const TABLE_COLUMNS = [
  { field: 'name', headerName: 'Full name', bold: true, colProps: { width: '30%' }, cellType: TCELL_TYPES.TEXT_ICON, decoration: outdatedChip },
  { field: 'folder_name', headerName: 'Folder' },
  { field: 'citizenship', headerName: 'Citizenship' },
  { field: 'risk_score', headerName: 'Risk Rating', colProps: { style: { textTransform: 'capitalize' } } },
  { field: 'formatted_created_at', headerName: 'Date Added' },
  { field: 'last_review_at', headerName: 'Last Review' },
  { field: 'next_review_at', headerName: 'Next Review' }
]

const CARD_LABELS_COMPANY = {
  risk_score: 'RISK RATING',
  type: 'TYPE',
  formed_at: 'FORMATION DATE',
  country: 'COUNTRY',
  last_review_at: 'LAST REVIEW',
  next_review_at: 'NEXT REVIEW',
  monitoring: 'MONITORING',
}

const TABLE_COLUMNS_COMPANY = [
  { field: 'name', headerName: 'Name', bold: true, colProps: { width: '30%' }, cellType: TCELL_TYPES.TEXT_ICON, decoration: outdatedChip },
  { field: 'folder_name', headerName: 'Folder' },
  { field: 'country', headerName: 'Country' },
  { field: 'risk_score', headerName: 'Risk Rating', colProps: { style: { textTransform: 'capitalize' } } },
  { field: 'formatted_created_at', headerName: 'Date Added' },
  { field: 'last_review_at', headerName: 'Last Review' },
  { field: 'next_review_at', headerName: 'Next Review' }
]

const debouncedPersonIndex = debounce(clientApi.person.index, 500);
const debouncedCompanyIndex = debounce(clientApi.company.index, 500);

const defaultFilters = [
  { type: 'toggle', isChecked: false, value: 'closely-monitoring', label: 'Closely Monitoring', name: 'riskRating' },
  { type: 'toggle', isChecked: false, value: 'high', label: 'High', name: 'riskRating' },
  { type: 'toggle', isChecked: false, value: 'medium', label: 'Medium', name: 'riskRating' },
  { type: 'toggle', isChecked: false, value: 'low', label: 'Low', name: 'riskRating' },
  { type: 'toggle', isChecked: false, value: 'exempted', label: 'Exempted', name: 'riskRating' }
]

const defaultFiltersMonitory = {
  monitoryOn: false,
  monitoryOff: false,
}

export default function UpdateExisting(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { type, searchId, profileId } = useParams();
  const firstRender = useFirstRender();
  const { flow, onNext, steps } = props;
  
  const [showMatched, setShowMatched] = React.useState(true);
  const [names, setNames] = React.useState([]);

  // Pagination
  const [page, setPage] = React.useState(0)
  const [pageSize, setPageSize] = React.useState(12) //eslint-disable-line
  const [pagesCount, setPagesCount] = React.useState(1)
  const [totalCount, setTotalCount] = React.useState(0)
  const onPageChange = (event, page) => {
    setPage(page - 1)
  }

  const [view, setView] = React.useState('list'); // list/cards
  const [search, setSearch] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [matchedData, setMatchedData] = React.useState([]);
  const [sort, setSort] = React.useState('name');
  const [sortDirection, setSortDirection] = React.useState('ASC');
  const [filters, setFilters] = React.useState(defaultFilters);
  const [filtersMonitory, setFiltersMonitory] = React.useState({ ...defaultFiltersMonitory });

  const isPerson = useMemo(() => {
    return type === 'person';
  }, [type])

  useEffect(() => {
    const findByNames = async () => {
      const names = [];
      if (profileId) {
        const data = (await clientApi.profile.show(profileId)).data
        const clientName = normalizeName(data.meta.name);
        if (isPerson) {
          names.push(clientName);
          const reversedName = reverseName(clientName);
          if (reversedName !== clientName) {
            names.push(reversedName);
          }
        } else {
          names.push(clientName)
        }
      }
      if (!names.length && searchId) {
        const data = (await clientApi.search.show(searchId)).data
        const clientName = normalizeName(data.name);
        if (isPerson) {
          names.push(clientName);
          const reversedName = reverseName(clientName);
          if (reversedName !== clientName) {
            names.push(reversedName);
          }
        } else {
          names.push(clientName)
        }
      }

      if (names.length) {
        setNames(names);

        const promises = names.map(name => {
          const params = {
            ...prepareParams(name),
            pageSize: 10000,
          }
          if (isPerson) {
            return clientApi.person.index(params)
          } else {
            return clientApi.company.index(params)
          }
        })

        const res = await Promise.all(promises);
        let data = [];
        res.forEach(res => {
          res.data.entries.forEach(item => {
            if (!data.find(d => d.id === item.id)) {
              data.push(item);
            }
          })
        })

        if (data.length) {
          if (data.length === 1) {
            onClick(data[0].id, true)
          }
          setMatchedData(data.map(item => prepareClient(item)));
        }
      } else {
        setShowMatched(false);
      }
    }

    findByNames();
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!firstRender) {
      if (!showMatched) {
        const timeOutId = setTimeout(() => fetchItems(), 1000);
        return () => clearTimeout(timeOutId);
      }
    }
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (!showMatched) {
      fetchItems()
    }
    // eslint-disable-next-line
  }, [page, sort, filters, filtersMonitory, sortDirection])

  useEffect(() => {
    if (!showMatched && !data.length) {
      fetchItems()
    }
    setPage(0);
  }, [showMatched]) // eslint-disable-line

  const prepareParams = (search) => {
    const monitory = flow === 'ongoing-monitoring'
      ? false
      : filtersMonitory.monitoryOn === filtersMonitory.monitoryOff
        ? null
        : filtersMonitory.monitoryOn;

    const riskRatings = filters.filter(el => el.name === 'riskRating').filter(el => el.isChecked)?.map(el => el?.value)

    return {
      pageNumber: page,
      pageSize,
      searchKeyword: search || null,
      sortBy: sort && getSortField(sort, type),
      sortDirection: sortDirection && sortDirection,
      monitory,
      riskRatings
    }
  }

  const fetchItems = async () => {
    const searchKeyword = search?.trim() || ''
    if (searchKeyword.length < 2 && searchKeyword.length) {
      enqueueSnackbar('Search keyword should have at least 2 characters!', {
        variant: 'error',
      });
      return;
    }
    const params = prepareParams(searchKeyword);
    const data = (isPerson)
      ? (await debouncedPersonIndex(params)).data
      : (await debouncedCompanyIndex(params)).data;
    setData((data?.entries || []).map(item => prepareClient({ ...item })))
    setPagesCount(data?.pages || 1);
    setTotalCount(data?.count || 0);
  }

  const onSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(0);
  }

  const onClick = (id, isReplace) => {
    return onNext({ type, searchId, profileId, clientId: id, isReplace });
  }

  const onFilterChange = (event) => {
    setFilters(items => items.map(el => el.value === event.target.value
      ? ({ ...el, isChecked: event.target.checked })
      : el
    ));
    setPage(0);
  }

  const onFilterMonitoryChange = (event) => {
    setFiltersMonitory(elements => ({ ...elements, [event.target.value]: event.target.checked }));
    setPage(0);
  }

  const onSort = (value) => {
    setPage(0)

    if (sort !== value) {
      setSort(value)
      setSortDirection('ASC')
    } else {
      setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC')
    }
  }

  const hasSearched = useMemo(() => {
    if (!!search) {
      return true;
    }

    return !!filters.find((el) => el.isChecked) ||
      Object.entries(filtersMonitory).some(([field, value]) => defaultFiltersMonitory[field] !== value);
  }, [filters, filtersMonitory, search])

  const dataToUse = useMemo(() => {
    if (showMatched) {
      return matchedData.slice(page * pageSize, page * pageSize + pageSize)
    }
    return data;
  }, [data, matchedData, showMatched, page, pageSize])

  const listData = useMemo(() => {
    return dataToUse.map(e => ({ ...e, risk_score: getRiskRatingLabel(e.risk_score) }))
  }, [dataToUse])

  const pagesCountToUse = useMemo(() => {
    if (showMatched) {
      return Math.ceil(matchedData.length / pageSize);
    }
    return pagesCount;
  }, [pagesCount, matchedData, showMatched, pageSize])

  const totalCountToUse = useMemo(() => {
    if (showMatched) {
      return matchedData.length;
    }
    return totalCount;
  }, [totalCount, matchedData, showMatched])

  const searchHeader = (
    <Grid container item xs={12} alignItems="center">
      <Box>
        <RadioGroup
          name="showMatched"
          value={showMatched ? 'matched' : 'all'}
          onChange={e => setShowMatched(e.target.value === 'matched')}
          row
        >
          <Box display={'flex'} alignItems={'center'} height={'40px'}>
            <FormControlLabel
              control={<Radio />}
              value="matched"
              label="Matched Records"
            />
          </Box>
          <Box display={'flex'} alignItems={'center'} height={'40px'}>
            <FormControlLabel
              control={<Radio />}
              value="all"
              label={'All Records'}
            />
          </Box>
        </RadioGroup>
      </Box>
      <Box mr={4} width="calc(100% - 170px - 250px)" visibility={showMatched ? 'hidden' : 'visible'}>
        <OutlinedSearch
          value={search}
          onChange={onSearchChange}
          label={`Search ${isPerson ? 'people' : 'legal entities'}`}
          icons={isPerson
            ? <SearchIcon />
            : <SearchLegalEntity />
          }
        />
      </Box>
      <Box visibility={showMatched ? 'hidden' : 'visible'}>
        <FilterWithContext isActive={filters.some(el => el.isChecked) || filtersMonitory.monitoryOn || filtersMonitory.monitoryOff}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Box display={'flex'} pt={1} pb={2}>
                <Typography variant="h5">
                  Sort By
                </Typography>
              </Box>
              {isPerson &&
                <RadioGroup name="sort" value={sort} onChange={e => setSort(e.target.value)}>
                  <Box display={'flex'} alignItems={'center'} height={'40px'}>
                    <FormControlLabel
                      control={<Radio />}
                      value={'name'}
                      label={'Name'}
                    />
                  </Box>
                  <Box display={'flex'} alignItems={'center'} height={'40px'}>
                    <FormControlLabel
                      control={<Radio />}
                      value={'citizenship'}
                      label={'Citizenship'}
                    />
                  </Box>
                  <Box display={'flex'} alignItems={'center'} height={'40px'}>
                    <FormControlLabel
                      control={<Radio />}
                      value={'residency'}
                      label={'Residency'}
                    />
                  </Box>
                  <Box display={'flex'} alignItems={'center'} height={'40px'}>
                    <FormControlLabel
                      control={<Radio />}
                      value={'gender'}
                      label={'Gender'}
                    />
                  </Box>
                  <Box display={'flex'} alignItems={'center'} height={'40px'}>
                    <FormControlLabel
                      control={<Radio />}
                      value={'formatted_created_at'}
                      label={'Date Added'}
                    />
                  </Box>
                  <Box display={'flex'} alignItems={'center'} height={'40px'}>
                    <FormControlLabel
                      control={<Radio />}
                      value={'next_review_at'}
                      label={'Date of Next Review'}
                    />
                  </Box>
                </RadioGroup>
              }
              {type === 'company' &&
                <RadioGroup name="sort" value={sort} onChange={e => setSort(e.target.value)}>
                  <Box display={'flex'} alignItems={'center'} height={'40px'}>
                    <FormControlLabel
                      control={<Radio />}
                      value={'name'}
                      label={'Name'}
                    />
                  </Box>
                  <Box display={'flex'} alignItems={'center'} height={'40px'}>
                    <FormControlLabel
                      control={<Radio />}
                      value={'country'}
                      label={'Country'}
                    />
                  </Box>
                  <Box display={'flex'} alignItems={'center'} height={'40px'}>
                    <FormControlLabel
                      control={<Radio />}
                      value={'formationDate'}
                      label={'Formation Date'}
                    />
                  </Box>
                  <Box display={'flex'} alignItems={'center'} height={'40px'}>
                    <FormControlLabel
                      control={<Radio />}
                      value={'formatted_created_at'}
                      label={'Date Added'}
                    />
                  </Box>
                  <Box display={'flex'} alignItems={'center'} height={'40px'}>
                    <FormControlLabel
                      control={<Radio />}
                      value={'next_review_at'}
                      label={'Date of Next Review'}
                    />
                  </Box>
                </RadioGroup>
              }
            </Grid>
            <Grid item xs={6}>
              <Box display={'flex'} pt={1} pb={2}>
                <Typography variant="h5">
                  Risk Rating
                </Typography>
              </Box>
              {filters.map((el, i) => (
                <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value}-${i}`}>
                  <FormControlLabel
                    control={<Switch value={el.value} checked={el.isChecked} onClick={onFilterChange} />}
                    label={el.label}
                  />
                </Box>
              ))}
              {flow !== 'ongoing-monitoring' &&
                <React.Fragment>
                  <Box display={'flex'} pt={1} pb={2}>
                    <Typography variant="h5">
                      Monitoring
                    </Typography>
                  </Box>
                  <Box display={'flex'} alignItems={'center'} height={'40px'}>
                    <FormControlLabel
                      control={<Switch value="monitoryOn" checked={filtersMonitory.monitoryOn} onClick={onFilterMonitoryChange} />}
                      label={'On Monitoring'}
                    />
                  </Box>
                  <Box display={'flex'} alignItems={'center'} height={'40px'}>
                    <FormControlLabel
                      control={<Switch value="monitoryOff" checked={filtersMonitory.monitoryOff} onClick={onFilterMonitoryChange} />}
                      label={'Not On Monitoring'}
                    />
                  </Box>
                </React.Fragment>
              }
            </Grid>
          </Grid>
        </FilterWithContext>
      </Box>
      <ViewToggle value={view} onChange={setView} />
    </Grid>
  )

  return (
    <Box px={6}>
      <InnerHeader
        title={`Choose ${isPerson ? 'Person' : 'Legal Entity'}`}
        stepper={{
          currentStep: steps?.currentStep || 1,
          stepsCount: steps?.stepsCount || 3
        }}
        buttons={
          <React.Fragment>
            <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={() => { history.goBack() }}>
              Back
            </ButtonWithIcon>
          </React.Fragment>
        }
      />
      <Box pt={3}>
        <Grid container>
          {searchHeader}
          {!!dataToUse.length &&
            <Grid item xs={12}>
              <Box mt={2}>
                {view === 'cards' &&
                  <CardList
                    extended
                    companyLabels={CARD_LABELS_COMPANY}
                    personLabels={CARD_LABELS}
                    items={dataToUse}
                    page={page}
                    pagesCount={pagesCountToUse}
                    pageSize={pageSize}
                    totalCount={totalCountToUse}
                    onPageChange={onPageChange}
                    type={type}
                    onClick={onClick}
                    maxHeight="calc(100vh - 330px)"
                  />
                }
                {view === 'list' &&
                  <TableList
                    items={listData}
                    columns={isPerson ? TABLE_COLUMNS : TABLE_COLUMNS_COMPANY}
                    page={page}
                    pageSize={pageSize}
                    pagesCount={pagesCountToUse}
                    totalCount={totalCountToUse}
                    onPageChange={onPageChange}
                    onClick={onClick}
                    sortableColumns={Object.keys(getSortFields(type))}
                    onSort={onSort}
                    sort={sort}
                    sortDirection={sortDirection}
                    maxHeight="calc(100vh - 320px)"
                  />
                }
              </Box>
            </Grid>
          }
          {!dataToUse.length &&
            <Grid item xs={12}>
              <Box mt={16} flexGrow={1}>
                <NoResultsFound>
                  {showMatched
                    ? `There are no saved clients with name \n ${names.join(', ')}`
                    : hasSearched
                      ? 'No entities found for the applied filters'
                      : 'When you save entities in your Portfolio, they will appear here'
                  }
                </NoResultsFound>
              </Box>
            </Grid>
          }
        </Grid>
      </Box>
    </Box>
  )
}

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  Switch,
  RadioGroup,
  Radio,
  Typography,
  Grid,
  makeStyles,
} from '@material-ui/core';
import debounce from 'awesome-debounce-promise';

import { eidv, client, person, folder } from '@app/api/client';
import HeaderButtonsBlock from '../header-buttons-block';
import history from '@app/history';
import { Delete2Icon } from '@app/icons';
import prepareTimestamp from '@utils/date';
import { capitalize } from '@utils/textUtil';
import { getCountryName } from '@services/country-service';
import TableList, { TCELL_TYPES } from '@components/lists/table-list';
import CustomChip from '@components/controls/custom-chip';
import SelectSearchable from '@components/inputs/select-searchable';
import FilterWithContext from '@components/dropdowns/filter-with-context';
import NoResultsFound from '@components/layout/no-results-found';
import InlineHeadBadge from '@components/badges/inline-head-badge';
import InnerHeader from '@components/layout/inner-header';
import EidvAdd from './add-eidv';

import {
  PREPARE_SORT,
  DOCUMENTS,
  FILTER_DEFAULTS,
  RADIO_FILTERS,
  SORTABLE_COLUMNS,
} from '../constants';
import { prepareFilters } from '../utils/prepare-filters';
import EidvCancelModal from '../../../../../components/complex/client-view/electronic-verification/components/eidv-cancel-modal';
import { outdatedChip } from '../../../../../components/badges/outdated-chip';

const useStyles = makeStyles((theme) => ({
  chip: {
    '& .MuiChip-label': {
      paddingRight: '4px',
    },
    verticalAlign: 'unset',
  },
  deleteChipIcon: {
    height: '14px',
    width: '14px',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: theme.palette.background.GY2,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '-3px',
  },
}));
const inviteCheck = (item) => {
  return item.clientId ? null : (
    <Box ml={1}>
      <CustomChip ml={2} label="Invited" type="completed" />
    </Box>
  );
};

const combineDecoration = (item) => {
  return (
    <React.Fragment>
      {inviteCheck(item)}
      {outdatedChip(item)}
    </React.Fragment>
  )
}

const COLUMNS = [
  {
    cellType: TCELL_TYPES.TEXT_ICON,
    field: 'clientName',
    headerName: 'Client name',
    decoration: combineDecoration,
    bold: true,
    colProps: { width: '17%' },
  },
  { field: 'folderName', headerName: 'Folder' },
  { field: 'riskRating', headerName: 'Risk Rating' },
  { field: 'residency', headerName: 'Residency' },
  { field: 'citizenship', headerName: 'Citizenship' },
  { field: 'statusLabel', headerName: 'Status' },
  {
    field: 'documents',
    headerName: 'Documents',
    cellType: TCELL_TYPES.TRUNCATED,
    useTooltip: true,
    colProps: {
      width: '12%',
    },
  },
  { field: 'initiatedDate', headerName: 'Created On' },
  { field: 'updatedDate', headerName: 'Updated On' },
];

const debouncedClients = debounce(person.index, 500);

const EIDVPage = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [pages, setPages] = useState(0);
  const [count, setCount] = useState(0);
  const [pageNumber, setPagesNumber] = useState(0);
  const [selectedClient, setSelectedClient] = useState({
    label: '',
    value: '',
  });
  const [clientSearch, setClientSearch] = useState(null);
  const [options, setOptions] = useState([]);
  const [sortDirection, setSortDirection] = useState('DESC');
  const [sortField, setSortField] = useState('initiatedDate');
  const [filters, setFilters] = useState(FILTER_DEFAULTS);
  const [showAddEidv, setShowAddEidv] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({})

  const getEidvs = useCallback(async () => {
    const res = (
      await eidv.index({
        clientId: selectedClient.value,
        pageNumber: pageNumber,
        pageSize: 12,
        sortBy: PREPARE_SORT[sortField],
        sortDirection: sortDirection,
        statuses: prepareFilters(filters),
      })
    ).data;
    if (res) {
      setCount(res.count);
      setPages(res.pages);
      const clients = await getClients(res.entries);
      const folders = await getFolders(res.entries);
      const newData = res.entries.map((item) => {
        const currentClient = clients.find(
          (client) => client.id === item.clientId
        );
        const documents = item.requestedSteps.steps.reduce(
          (previousValue, currentValue) =>
            currentValue !== 'FACE_MATCH'
              ? [...previousValue, DOCUMENTS[currentValue]]
              : previousValue,
          []
        );
        return {
          ...item,
          documents: documents?.join(', '),
          citizenship: item.clientId
            ? getCountryName(currentClient.citizenship)
            : 'N/A',
          clientName: item.clientId
            ? `${currentClient.firstName} ${currentClient.lastName}`
            : item.email,
          riskRating: currentClient?.riskRating
            ? capitalize(currentClient.riskRating)
            : 'N/A',
          residency: currentClient && getCountryName(currentClient.residency),
          folderName: currentClient
            ? currentClient.folderName
            : folders.find((folder) => folder.id === item.folderId).name,
          initiatedDate: item.initiatedDate
            ? prepareTimestamp(item.initiatedDate, 'MM/DD/YYYY', false)
            : 'N/A',
          updatedDate: item.updatedDate
            ? prepareTimestamp(item.updatedDate, 'MM/DD/YYYY', false)
            : 'N/A',
          statusLabel: !!item.status && (
            <CustomChip
              label={
                item.status === 'PENDING_APPROVAL'
                  ? 'PENDING APPROVAL'
                  : item.status
              }
              type={item.status}
            />
          ),
        };
      });
      setData(newData);
    }
  }, [pageNumber, selectedClient, filters, sortDirection, sortField]);

  const getFolders = async (data) => {
    let tmp = data.reduce(
      (previousValue, currentValue) =>
        currentValue.clientId
          ? previousValue
          : [...previousValue, currentValue.folderId],
      []
    );
    tmp = [...new Set(tmp)];
    const folders = await Promise.all(tmp.map((item) => folder.show(item)));
    return folders.map((item) => item.data);
  };

  useEffect(() => {
    getEidvs();
  }, [getEidvs]);
  const onSort = (value) => {
    setPagesNumber(0);
    if (sortField !== value) {
      setSortField(value);
      setSortDirection('DESC');
    } else {
      setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
    }
  };
  const getClients = async (eidvs) => {
    const tmp = eidvs.reduce(
      (previousValue, currentValue) =>
        currentValue.clientId !== null
          ? [...previousValue, currentValue.clientId]
          : previousValue,
      []
    );
    const clientsArray = [...new Set(tmp)];
    const clients = await Promise.all(
      clientsArray.map((item) => client.show(item))
    );
    const formattedData = clients.map((item) => item.data);
    return formattedData;
  };

  const getDropDownData = async (debounce = false) => {
    const params = {
      pageNumber: 0,
      pageSize: 30,
      searchKeyword: clientSearch?.trim(),
      sortDirection: 'ASC',
      sortBy: 'firstName',
    };
    if (selectedClient.label !== clientSearch) {
      const response = debounce
        ? await debouncedClients(params)
        : await person.index(params);
      if (response) {
        const { entries } = response.data;
        const options = entries?.map((item) => ({
          value: item.id,
          label: `${item.firstName} ${item.lastName}`,
        }));
        setOptions([
          ...options,
          {
            label: 'Consider refining the filter to find your client faster',
            value: '0',
            disabled: true,
          },
        ]);
      }
    }
  };

  useEffect(() => {
    if (clientSearch !== null) {
      getDropDownData(true);
    } else {
      getDropDownData();
    }
    // eslint-disable-next-line
  }, [clientSearch]);

  const handleStatuses = (e) => {
    setPagesNumber(0);
    const newFilters = filters.map((el) => {
      if (el.value === e.target.value) {
        return { ...el, isChecked: e.target.checked };
      } else {
        return { ...el, isChecked: el.isChecked };
      }
    });
    setFilters(newFilters);
  };
  const redirect = (clientId, eIdvId) => {
    if (clientId) {
      history.push(
        `/portfolio/view/${clientId}/electronic-verification/${eIdvId}/overview`
      );
    } else {
      history.push(`/electronic-verification/${eIdvId}/overview`);
    }
  };
  const handleThreeDotsAction = (item, type) => {
    if (type === 'view') {
      item.clientId && redirect(item.clientId, item.id);
    }
  };

  const handleCancel = (item) => {
    setSelectedItem(item);
    setShowCancelModal(true);
  }

  const renderedFilterBadges = useMemo(() => {
    const statusFiltersEnabled = filters.filter((f) => f.isChecked);
    const badges = [];
    if (statusFiltersEnabled.length) {
      badges.push({
        name: 'status',
        content: `${
          statusFiltersEnabled.length > 1 ? 'Statuses' : 'Status'
        }: ${statusFiltersEnabled.map((f) => f.label).join(', ')}`,
      });
    }
    return badges.map((badge) => (
      <Box key={`filter-badge-${badge.name}`} ml={1}>
        <CustomChip
          label={badge.content}
          deleteIcon={
            <Box className={classes.deleteChipIcon}>
              <Delete2Icon viewBox="0 0 17 12" />
            </Box>
          }
          onDelete={() => onResetFilter(badge.name)}
          propsClasses={classes.chip}
        />
      </Box>
    ));
  }, [filters]); //eslint-disable-line

  const onResetFilter = (filterName) => {
    setFilters(FILTER_DEFAULTS);
  };

  const handleSelect = (field) => {
    setPagesNumber(0);
    setSelectedClient(field.target);
    setClientSearch(field?.target?.label);
  };

  const handleCreate = () => {
    setShowAddEidv(false);
    getEidvs();
  };

  return (
    <Box mx={6} height={'100%'}>
      <EidvAdd
        open={showAddEidv}
        onClose={() => setShowAddEidv(false)}
        onCreate={handleCreate}
      />
      <EidvCancelModal
        open={showCancelModal}
        setOpen={setShowCancelModal}
        data={selectedItem}
        clientData={{ name: selectedItem.clientName }}
        fetchData={getEidvs}
      />

      <InnerHeader
        justifyTitle="flex-start"
        title="Electronic Identity Verification"
        buttons={<HeaderButtonsBlock openEidv={() => setShowAddEidv(true)} />}
      />
      <Box
        display="flex"
        flexDirection="column"
        height="calc(100% - 98px)"
        maxHeight="calc(100% - 98px)"
      >
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" mt={2}>
            <Box display="flex" height="48px" alignItems="center">
              <Box mr={3}>
                <InlineHeadBadge
                  badgeColor="#C0A2B8"
                  textColor="#FFF"
                  badgeValue={count}
                  fontWeight={500}
                >
                  eIDVS
                </InlineHeadBadge>
              </Box>
            </Box>
            <Box>{renderedFilterBadges}</Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt={'13px'}
            mb={'5px'}
          >
            <FormControl variant="outlined" fullWidth>
              <SelectSearchable
                inputValue={clientSearch}
                getOptionDisabled={(option) => option.disabled}
                renderOption={(option) => (
                  <span
                    style={{
                      fontStyle: option.value === '0' ? 'italic' : 'normal',
                    }}
                  >
                    {option.label}
                  </span>
                )}
                onInputChange={(event, newInputValue) => {
                  if (event?.type === 'change') {
                    setClientSearch(newInputValue);
                  }
                }}
                filterOptions={(x) => x}
                value={selectedClient.value}
                onChange={handleSelect}
                options={options}
                noOptionsText="Consider refining the filter to find your client faster"
                label={'All  Clients'}
              />
            </FormControl>
            <Box ml={2}>
              <FilterWithContext
                isActive={
                  sortField !== 'initiatedDate' || !!renderedFilterBadges.length
                }
              >
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <Box display="flex" pb={1}>
                      <Typography variant="h5">Sort By</Typography>
                    </Box>
                    <RadioGroup
                      name="sort"
                      value={sortField}
                      onChange={(e) => onSort(e.target.value)}
                    >
                      {RADIO_FILTERS.map((el, i) => (
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          height={'40px'}
                          key={`${el.value} - ${i}`}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            value={el.value}
                            label={el.label}
                          />
                        </Box>
                      ))}
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Typography variant="h5">Status</Typography>
                    </Box>
                    {filters.map((item, index) => (
                      <Box display={'flex'} key={index}>
                        <FormControlLabel
                          control={
                            <Switch
                              value={item.value}
                              checked={item.isChecked}
                              onChange={(e) => handleStatuses(e)}
                            />
                          }
                          label={item.label}
                        />
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              </FilterWithContext>
            </Box>
          </Box>
          <Box px={2} mt={2}>
            {!!data.length && (
              <TableList
                onClick={(id, item) => redirect(item.clientId, item.id)}
                columns={COLUMNS}
                items={data}
                onPageChange={(event, value) => setPagesNumber(value - 1)}
                pagesCount={pages}
                totalCount={count}
                pageSize={12}
                page={pageNumber}
                sort={sortField}
                onSort={onSort}
                sortableColumns={SORTABLE_COLUMNS}
                sortDirection={sortDirection}
                threeDotsActions={[
                  {
                    label: 'View',
                    handler: (id, item) => handleThreeDotsAction(item, 'view'),
                  },
                  {
                    label: 'Cancel',
                    handler: (id, item) =>
                      handleCancel(item),
                    disabled: (item) =>
                      !['REQUESTED', 'REPEATED'].includes(item.status),
                  },
                ]}
              />
            )}
            {!data.length && (
              <Box mt={16} flexGrow={1}>
                <NoResultsFound>
                  {filters.length > 5 || selectedClient.label
                    ? 'No electronic identity verifications found for the applied filters'
                    : 'When you create electronic identity verification, they will appear here'}
                </NoResultsFound>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default EIDVPage;

import React from 'react';
import PageLayout from '@components/layout/page-layout';
// import history from "@app/history";

import Communication from './communication';
import ViewEmail from './emails/view-email';

import { Route, Switch } from 'react-router';

export default function Index(props) {

  return (
    <PageLayout>
      <Switch>
        <Route exact path="/communication" component={Communication} />
        <Route exact path="/communication/view-email" component={ViewEmail} />
      </Switch>
    </PageLayout>
  )
}

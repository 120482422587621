import store from '@app/store';
import * as queryString from 'query-string';
import _ from 'lodash';

export const filterDefaults = [
  { type: 'toggle', isChecked: false, value: 'closely-monitoring', label: 'Closely Monitoring', name: 'riskRating' },
  { type: 'toggle', isChecked: false, value: 'high', label: 'High', name: 'riskRating' },
  { type: 'toggle', isChecked: false, value: 'medium', label: 'Medium', name: 'riskRating' },
  { type: 'toggle', isChecked: false, value: 'low', label: 'Low', name: 'riskRating' },
  { type: 'toggle', isChecked: false, value: 'exempted', label: 'Exempted', name: 'riskRating' },
  { type: 'toggle', isChecked: false, value: 'On', label: 'On Monitoring', name: 'monitory', badgeLabel: 'On Monitoring Only' },
  { type: 'toggle', isChecked: false, value: 'Off', label: 'Not on Monitoring', name: 'monitory', badgeLabel: 'Not Yet On Monitoring' },
  { type: 'date-range', value: {from: null, to: null}, label: 'Filter By Next Review Date', name: 'nextReview'},
  { type: 'toggle', isChecked: false, value: 'CORPORATION', label: 'Corporation', name: 'legalEntityType' },
  { type: 'toggle', isChecked: false, value: 'FOUNDATION', label: 'Foundation', name: 'legalEntityType' },
  { type: 'toggle', isChecked: false, value: 'LIMITED_LIABILITY_COMPANY', label: 'Limited Liability Company (LLC)', name: 'legalEntityType' },
  { type: 'toggle', isChecked: false, value: 'PARTNERSHIP', label: 'Partnership', name: 'legalEntityType' },
  { type: 'toggle', isChecked: false, value: 'TRUST', label: 'Trust', name: 'legalEntityType' },
]

const parseQuery = () => {
  return queryString.parse(window.location.hash, {arrayFormat: 'separator', arrayFormatSeparator: '|'})
}

export const resolveQueryFilterSort = () => {
  const { portfolio } = store.getState()
  const parsedQuery = parseQuery()
  const filtersWithoutQuery = (portfolio.portfolioFilters || filterDefaults)
  let filters;
  if(_.isEmpty(parsedQuery)) {
    filters = filtersWithoutQuery
  } else {
    window.location.hash = ''
    filters = filtersWithoutQuery.map(filter => {
      const queryValue = parsedQuery?.[filter.name]
      if(queryValue) {
        if(filter.name === 'nextReview') {
          const from = queryValue?.[0]
          const to = queryValue?.[1]
          return {...filter, value: {from: (from? new Date(from): null), to: (to? new Date(to): null)}}
        } else {
          return {...filter, isChecked: queryValue === filter.value || queryValue?.includes(filter.value) }
        }
      } else {
        return filter
      }
    })
  }
  return {
    filters: filters,
    sort: parsedQuery?.sortBy || portfolio.portfolioSort || 'name',
    sortDirection: parsedQuery?.sortDirection || portfolio.portfolioSortDirection || 'ASC'
  }
}
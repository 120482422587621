import React, { useEffect, useState } from 'react';
import { Box, Card, Collapse, IconButton, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

import TableList, { TCELL_TYPES } from '@components/lists/table-list';
import { clientDocument } from '@app/api/client';
import ExpiryDateLabel from '@components/flexible-icons/expiry-date-label';
import CustomTooltip from '@components/controls/tooltip';
import moment from 'moment';
import { DocumentsVerifiedByEidv, DropdownThinIcon } from '@app/icons';
import documentDto from '../dto/document-dto';
import { getIconByCategoryName } from '../utils/categories-icons';


const useStyles = makeStyles((theme) => ({
  downArrow: {
    transition: 'transform 0.4s',
    transform: 'rotate(0deg)',
  },
  rightArrow: {
    transition: 'transform 0.4s',
    transform: 'rotate(-90deg)',
  },
  downArrow2: {
    transform: 'rotate(0deg) !important',
  },
  upArrow: {
    transform: 'rotate(-180deg)',
  },
}))

const verifiedByEidv = (item) => {
  if (item.verifiedBy === 'VERIFIED_VIA_EIDV') {
    return (
      <CustomTooltip title="Verified via eIDV" placement="top">
        <Box ml={1} display="flex" alignItems="center">
          <DocumentsVerifiedByEidv />
        </Box>
      </CustomTooltip>
    )
  }
  return null;
}

const itemStyle = { style: { fontSize: '12px', fontWeight: 400, color: '#656565', textTransform: 'none' } }
const FILES_COLUMNS = [
  {
    field: 'documentName',
    headerName: 'Name',
    cellType: TCELL_TYPES.TEXT_ICON,
    decoration: verifiedByEidv,
    colProps: { ...itemStyle, width: '35%' },
    headerProps: { ...itemStyle, width: '35%' },
  },
  { field: '_issueDate', headerName: 'Date issued', headerProps: { ...itemStyle, width: '15%' }, colProps: { ...itemStyle, width: '15%' } },
  { field: '_uploadDate', headerName: 'Date added', headerProps: { ...itemStyle, width: '15%' }, colProps: { ...itemStyle, width: '15%' } },
  { field: 'expiryLabel', headerName: 'Exp date', headerProps: { ...itemStyle, width: '15%' }, colProps: { ...itemStyle, width: '15%' } },
  { field: 'version', headerName: 'Doc version', headerProps: { ...itemStyle, width: '15%' }, colProps: { ...itemStyle, width: '15%' } }
]

const HEADER_COLUMNS = [
  ...FILES_COLUMNS,
  { field: 'threeDotsSpaceFiller', headerName: '', headerProps: { width: '24px'}}
]

function DocumentWithVersions({
  documentCategory, document, rulebook, readonly,
  getFile, onStartDocumentUpdateFlow, onRequestUpload,
  onDownloadDocument, setPendingDocumentDelete, triggerCollapse
}) {
  const classes = useStyles();
  const [collapse, setCollapse] = useState(false)
  const [documents, setDocuments] = useState([])

  useEffect(() => {
    if (triggerCollapse && !collapse) {
      setCollapse(true);
    }
  }, [triggerCollapse]) // eslint-disable-line

  const getNameLabel = (doc, notInRulebook) => {
    const smallIcon = getIconByCategoryName({ name: doc.categoryName, isCustom: doc.isCustom }, true);
    const catIcon = (
      <CustomTooltip
        title={notInRulebook}
        placement="right-start"
        disableHoverListener={!notInRulebook}
      >
        <Box display={'flex'} mr={1} width={22}>
          {smallIcon}
        </Box>
      </CustomTooltip>
    )

    return (
      <Box display={'flex'} alignItems={'center'}>
        {catIcon}
        <Box display={'flex'}>
          {doc.documentName}
        </Box>
      </Box>
    )
  }

  const isNotInSystemRulebook = (item) => {
    // Only check System documents
    if (item.isCustom || item.isTypeCustom || item.isOldVersion) {
      return false;
    }

    if (!rulebook?.rulebookData?.categories) {
      return false;
    }

    const category = rulebook.rulebookData.categories.find(cat => cat.categoryId === item.categoryId);
    if (!category) {
      return `There is a new setting for documents in your account.\n${item.categoryName} Category was removed from your settings, but you can still view previously uploaded documents that belonged to this category.`;
    }

    const type = category.types.find(type => type.typeId === item.typeId)
    if (!type) {
      return `There is a new setting for documents in your account.\n${item.typeName} Type was removed from your settings, but you can still view previously uploaded documents of this type.`;
    }

    return false;
  }

  useEffect(() => {
    if (collapse && !documents.length) {
      getDocuments();
    }
  }, [collapse]) // eslint-disable-line

  useEffect(() => {
    if (collapse) {
      getDocuments()
    }
  }, [document]) // eslint-disable-line

  const getDocuments = () => {
    clientDocument.getVersions(document.id).then((res) => {
      // Get all but latest version
      const documents = res.data.entries
        .map(doc => ({
          ...doc.documentVersionSnapshot,
          documentVersionId: doc.id,
          isOldVersion: true,
        }))
        .map(doc => documentDto.read(doc))
        .sort((doc1, doc2) => {
          const date1 = moment(doc1._uploadDate);
          const date2 = moment(doc2._uploadDate);    
          return date1.isAfter(date2) ? -1 : 1
        }).reverse()
      
      // Add latest version
      const documentType = documentCategory.types.find(dt => dt.id === document.typeId)
      documents.unshift({
        ...document,
        categoryName: documentCategory.name,
        isCustom: documentCategory.isCustom,
        isTypeCustom: documentType?.ownedBy === 'USER',
      })

      const formattedDocuments = [];
      documents.forEach(doc => {
        const isNotInRulebook = isNotInSystemRulebook(doc);
        const formattedDoc = {
          ...doc,
          documentName: getNameLabel(doc, isNotInRulebook),
          categoryNameRaw: doc.categoryName,
          expiryLabel:
            !!doc.expiryDate
              ? ExpiryDateLabel(doc.expiryDate, doc.status)
              : documentType?.hasExpiry ? 'N/A' : 'Does not expire'
        }
        formattedDocuments.push(formattedDoc)
      })

      setDocuments(formattedDocuments);
    })
  }

  const documentType = documentCategory.types.find(dt => dt.id === document.typeId)
  return (
    <Box width="100%">
      <Box display="flex" alignItems="center" py={1} onClick={() => setCollapse(!collapse)}>
        <IconButton style={{ padding: 8, margin: -8 }}>
          <DropdownThinIcon className={clsx(classes.rightArrow, collapse && classes.downArrow2)} width={16} height={16} />
        </IconButton>
        <Box ml={1}>
          <Typography style={{ fontWeight: 'bold' }}>{documentType?.name}</Typography>
        </Box>
        {verifiedByEidv(document)}
      </Box>
      <Collapse in={collapse}>
        <Box pl={3}>
          <TableList
            columns={FILES_COLUMNS}
            items={documents}
            showHeader={false}
            sortableColumns={[]}
            onClick={(id, item) => getFile(item)}
            tableRowProps={{
              style: { height: 20 }
            }}
            disabledRow={(id, item) => {
              return isNotInSystemRulebook(item)
            }}
            threeDotsActions={[
              {
                label: 'Edit',
                handler: async (id, item) => {
                  await onStartDocumentUpdateFlow(document, 'update')
                },
                hidden: (id, item) => item.isOldVersion
              },
              {
                label: 'New Version',
                handler: async (id, item) => {
                  await onStartDocumentUpdateFlow(document, 'new-version')
                },
                hidden: (id, item) => item.isOldVersion
              },
              {
                label: 'Request Upload',
                handler: (id, item) => onRequestUpload(item),
                hidden: (id, item) => item.isOldVersion
              },
              {
                label: 'Download',
                handler: async (id, item) => {
                  await onDownloadDocument(item)
                }
              },
              {
                label: 'Delete',
                handler: (id, item) => setPendingDocumentDelete({
                  ...item,
                  typeName: `${documentType.name} ${item.version}`
                })
              },
            ]
              .filter(action => readonly ? action.label === 'Download' : true)
            }
          />
        </Box>
      </Collapse>
    </Box>
  )
}

export default function DocumentCategoryCard(props) {
  const { category, selectedCategory } = props;
  const classes = useStyles();
  const [collapse, setCollapse] = useState(true)

  useEffect(() => {
    if (selectedCategory && selectedCategory.id === category.id && !collapse) {
      setCollapse(true);
    }
  }, [selectedCategory]) // eslint-disable-line

  // Hide the cards instead of filtering it out so we don't need to refetch documents
  const shouldHide = selectedCategory && selectedCategory.id !== category.id;
  return (
    <Card style={{ width: '100%', marginTop: 12, padding: 16, display: shouldHide ? 'none' : undefined }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setCollapse(!collapse)}
      >
        <Typography variant="h5" style={{ fontWeight: 500 }}>
          {category.name}
        </Typography>
        <Box display="flex" alignItems="center">
          <Box mr={3}>
            <Typography style={{ color: '#7A5B44', fontWeight: 'bold' }}>{category.documents.length} Document(s)</Typography>
          </Box>
          <IconButton style={{ padding: 8, margin: -8 }}>
            <DropdownThinIcon className={clsx(classes.downArrow, collapse && classes.upArrow)} width={16} height={16} />
          </IconButton>
        </Box>
      </Box>
      <Collapse in={collapse}>
        <Box pt={2}>
          <Box pl={3}>
            <TableList
              columns={HEADER_COLUMNS}
              items={[]}
              sortableColumns={[]}
              tableRowProps={{
                style: { height: 20 }
              }}
            />
          </Box>
          {category.documents.map(document => (
            <DocumentWithVersions
              key={document.id}
              document={document}
              documentCategory={category}
              triggerCollapse={selectedCategory && selectedCategory.id === category.id}
              {...props}
            />
          ))}
        </Box>
      </Collapse>
    </Card>
  )
}

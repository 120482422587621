const initialState = {
  triggerScroll: false,
  isRequestSent: false,
  noScroll: false,
  wrapperCardRef: null,
};

const scroll = (state = initialState, action) => {

  switch (action.type) {
    case 'SET_SCROLL_DEFAULT':
      return initialState;
    case 'SET_TRIGGER_SCROLL': {
      return { ...state, triggerScroll: action.triggerScroll };
    }
    case 'SET_REQUEST_SENT': {
      return { ...state, isRequestSent: action.isRequestSent };
    }
    case 'SET_NO_SCROLL': {
      return { ...state, noScroll: action.noScroll };
    }
    case 'SET_WRAPPER_CARD_REF': {
      return { ...state, wrapperCardRef: action.wrapperCardRef };
    }

    default:
      return state
  }
};

export default scroll;

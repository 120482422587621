import React, { useEffect, useMemo, useState } from 'react';
import { prepareClient } from '@dto/client';
import moment from 'moment';

import { Box } from '@material-ui/core';
import InnerHeader from '@components/layout/inner-header';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { ChevronLeftIcon } from '@app/icons';
import history from '@app/history';
import { useParams } from 'react-router-dom';
import { clientApi } from '@app/api';
import SearchHistoryTable from '../search-history/search-history-table';
import prepareTimestamp from '@utils/date';
import { withLoggedNotFound } from '@views/protected/not-found';

const format = 'MM/DD/YYYY - hh:mm A'

function OngoingSearchHistory(props) {
  const { clientId } = useParams();
  const [client, setClient] = useState({});
  const [allSearches, setAllSearches] = useState([]);

  useEffect(() => {
    fetchSearches();
    }, []) // eslint-disable-line

  const fetchSearches = () => {
    clientApi.client.show(clientId).then((res) => {
      const data = res.data;
      data.type = data.type || 'PERSON';
      const client = prepareClient(data);
      setClient(client);

      if (data.linkedSearches && data.linkedSearches.length) {
        Promise.all(
          data.linkedSearches.map(search => clientApi.search.show(search))
        ).then(results => {
          const searches = [];
          results.forEach(res => {
            if (res.data) {
              searches.push({
                ...res.data,
                createdOn: moment.utc(res.data.createdOn).local(),
                lastRunOn: prepareTimestamp(res.data.lastRunOn, format),
                id: res.data.searchId,
                monitoring: !!res.data.ongoingMonitoringId ? 'On' : 'Off',
                reviewed: 'Me',
              })
            }
          });
          setAllSearches(searches);
        })
      }
    }).catch((err) => {
      props.pageNotFound();
    })
  }

  // // forward if only one item
  // useEffect(() => {
  //     if (allSearches.length === 1) {
  //         history.push(`/search-history/results?searchId=${allSearches[0].searchId}&name=${allSearches[0].name}`);
  //     }
  // }, [allSearches])

  const sortedSearches = useMemo(() => {
    const sorted = allSearches.sort((a, b) => b.createdOn - a.createdOn);
    return sorted.map(search => ({ ...search, createdOn: prepareTimestamp(search.createdOn.toDate(), format) }));
  }, [allSearches])

  return (
    <React.Fragment>
      <Box px={6}>
        <InnerHeader
          title={`${client.name || 'Client'} Search History`}
          buttons={
            <React.Fragment>
              <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={() => history.goBack()}>
                {'Back'}
              </ButtonWithIcon>
            </React.Fragment>
          }
        />
        <Box mt={5}>
          <SearchHistoryTable
            allSearches={sortedSearches}
            setAllSearches={setAllSearches}
            getAllSearches={fetchSearches}
            tableMaxHeight="calc(100vh - 430px)"
            hiddenFields={['client']}
            setMonitoring={(params) =>
              history.push(`/ongoing-monitoring/update-existing-monitoring/${params.searchId}/${params.clientId}`)
            }
          />
        </Box>
      </Box>
    </React.Fragment>
  )
};

export default withLoggedNotFound(OngoingSearchHistory);
const config = {
  MuiCard: {
    root: {
      borderRadius: '12px',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.3)',
      '&:hover': {
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        cursor: 'pointer',
      },
      '&.disabled': {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          cursor: 'default',
        },
      }
    },
  },
  MuiCardContent: {
    root: {
      '&:last-child': {
        paddingBottom: '16px'
      }
    }
  }
};

export default config
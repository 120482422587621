import React, { useEffect, useMemo } from 'react';
import { useTheme } from '@material-ui/core/styles';

import { clientApi } from '@app/api';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { FolderLargeBlankIcon, FolderAddFolderIcon, AddIcon } from '@app/icons';

import { makeStyles } from '@material-ui/core/styles';
import FolderFormDialog from '../dialogs/folder-form-dialog';
import RegularPagination from '@components/pagination/regular-pagination';
import clsx from 'clsx';
import OutlinedSearch from '@components/inputs/outlined-search';
import debounce from 'awesome-debounce-promise';

const debouncedFolderIndex = debounce(clientApi.folder.index, 1000);

const useStyles = makeStyles((theme) => ({
  folderRoot: {
    cursor: 'pointer',
    alignItems: 'center',
    '&:hover $folderIcon:not(.selected)': {
      stroke: '#CCC',
      '& path': {
        stroke: '#CCC'
      }
    },
    '&:hover .add-folder': {
      borderRadius: 12,
      background: '#F8F8F8',
      transition: 'all .1s ease-in-out',
    }
  },
  folderIconGroup: {
    position: 'relative',
    alignItems: 'top',
    justifyContent: 'center',
    height: '140px'
  },
  folderOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  folderColorFill: {
    width: '67%',
    height: '55%',
    borderRadius: '11px'
  },
  folderIcon: {
    stroke: '#FDFDFD',
    '&.selected': {
      stroke: '#A4A4A4'
    }
  },
  selectedTitle: {
    background: '#484848',
    borderRadius: '24px',
    color: '#FFF'
  },
  folderIconLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    lineHeight: '24px',
    wordBreak: 'break-word'
  }
}));

export default function SelectFolder(props) {

  const { palette } = useTheme()

  const { folderID, setFolderID } = props

  const [openNewFolder, setOpenNewFolder] = React.useState(false)
  const [selectedFolderNameCache, setSelectedFolderNameCache] = React.useState('')

  // Pagination
  const [page, setPage] = React.useState(0)
  const [pagesCount, setPagesCount] = React.useState(1)
  const [totalCount, setTotalCount] = React.useState(0)
  const onPageChange = (event, page) => {
    setPage(page - 1)
  }

  const [folders, setFolders] = React.useState([])
  const classes = useStyles();
  const [search, setSearch] = React.useState(null)

  const onSearch = (value) => {
    setPage(0)
    setSearch(value)
  }

  useEffect(() => {
    fetchItems();
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (search !== null) {
      fetchItems(true);
    }
    // eslint-disable-next-line
  }, [search])

  useEffect(() => {
    if (folderID) {
      clientApi.folder.show(folderID)
        .then(({ data }) => {
          setSelectedFolderNameCache(data.name)
        })
    }
    // eslint-disable-next-line
  }, [])

  const selectedFolderName = useMemo(() => {
    const folderName = folders.find(f => f.id === folderID)?.name
    if (folderName) {
      setSelectedFolderNameCache(folderName)
      return folderName
    } else {
      return selectedFolderNameCache
    }
    // eslint-disable-next-line
  }, [folderID, folders])

  const fetchItems = (debounce = false) => {
    const params = {
      pageNumber: page,
      folderName: search && search
    }
    return (debounce
      ? debouncedFolderIndex(params)
      : clientApi.folder.index(params))
      .then(({ data }) => {
        const foldersData = (data?.entries || [])
        const rootIdx = foldersData.findIndex(f => f.name.toLowerCase() === 'home')
        if (rootIdx !== -1) {
          const rootFolder = foldersData[rootIdx]
          foldersData.splice(rootIdx, 1)
          foldersData.unshift(rootFolder)
          if (!folderID) {
            setFolderID(rootFolder.id)
          }
        }
        setFolders(foldersData);
        setPagesCount(data?.pages || 1);
        setTotalCount(data?.count || 0);
      })
  }

  const onFolderSaved = ({ id, name }) => {
    fetchItems().then(() => {
      setOpenNewFolder(false)
      setFolderID(id)
      setSearch(name)
    })
  }

  return (
    <React.Fragment>

      <FolderFormDialog
        open={openNewFolder}
        defaultName={search || ''}
        onSaved={onFolderSaved}
        onClose={() => {
          setOpenNewFolder(false)
        }}
      />

      <Grid container>
        <Grid item xs={12}>
          <Box width={'30%'}>
            <OutlinedSearch value={search} onChange={(e) => onSearch(e.target.value)} label={'Search folder name'} size={'small'} />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box mx={-6}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  className={classes.folderRoot}
                  onClick={() => setOpenNewFolder(true)}
                >
                  <Box display={'flex'} className={classes.folderIconGroup}>
                    <Box className={classes.folderIcon}>
                      <FolderAddFolderIcon height={'130px'} />
                    </Box>
                  </Box>
                  <Box px={1} py={1} className={clsx(classes.folderIconLabel, 'add-folder')}>
                    <AddIcon />
                    Add Folder&nbsp;
                  </Box>
                </Box>
              </Grid>

              {folders.map(item => (
                <Grid item xs={12} sm={6} md={4} lg={2} key={item.name}>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    className={classes.folderRoot}
                    onClick={() => setFolderID(item.id)}
                  >
                    <Box display={'flex'} className={classes.folderIconGroup}>
                      {item.color &&
                        <Box
                          className={classes.folderOverlay}
                        >
                          <Box
                            className={classes.folderColorFill}
                            style={{
                              background: `linear-gradient(180deg, #FFFFFF 59.9%, ${item.color} 100%)`
                            }}
                          />
                        </Box>
                      }
                      <FolderLargeBlankIcon
                        className={clsx(classes.folderIcon, folderID === item.id && 'selected')}
                      />
                    </Box>
                    <Box px={2} py={1} className={clsx(classes.folderIconLabel, folderID === item.id && classes.selectedTitle)}>
                      {item.name}
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box width={'100%'} justifyContent={'center'} my={3}>
            <Divider />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display={'flex'} flexGrow={1} justifyContent={'center'}>
            {selectedFolderName &&
              <Typography variant={'body1'} style={{ color: palette.semantic.success }}>Selected folder: {selectedFolderName}</Typography>
            }
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box width={'100%'} my={3}>
            <RegularPagination
              page={page}
              pagesCount={pagesCount}
              onChange={onPageChange}
              totalCount={totalCount}
            />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
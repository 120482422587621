import React, { useMemo } from 'react';
import CustomTooltip from '@components/controls/tooltip';

import { DownloadIcon, DownloadInactiveIcon } from '@app/icons';
import CustomIconButton from '@components/buttons/button-with-icon';

import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  stepperTooltipPlacementTop: {
    bottom: '5px',
  },
}))

export default function ExportReportButton({ handleExport }) {
  const { profile, selectedProfile, backupSources } = useSelector(store => store.backgroundCheck);

  const classes = useStyles();

  const bgCheckRiskRating = useMemo(() => (
    (profile?.bgCheckRiskRating || selectedProfile?.bgCheckRiskRating)
  ), [profile, selectedProfile])

  return (
    <CustomTooltip
      title={'Before exporting your report set the BG check risk for this search'}
      placement="top-end"
      disableHoverListener={!!bgCheckRiskRating}
      tooltipPlacementTop={classes.stepperTooltipPlacementTop}
    >
      <div>
        <CustomIconButton
          onClick={handleExport}
          startIcon={(!!bgCheckRiskRating || backupSources.some(el => !!el.bgCheckRiskRating)) ? <DownloadIcon /> : <DownloadInactiveIcon />}
          className="minLabel"
          disabled={!bgCheckRiskRating && !backupSources.some(el => !!el.bgCheckRiskRating)}
        >
          Export Report
        </CustomIconButton>
      </div>
    </CustomTooltip>

  )
};

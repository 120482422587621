import React, { useEffect, useState } from 'react';
import PageLayout from '@components/layout/page-layout';

import { adminApi } from '@app/api';
import EntityManagement from './entity-management';

import { Route, Switch } from 'react-router';
import {Redirect} from 'react-router-dom';

import EntityCreate from './views/create';
import EntityUpdate from './views/update';
import EntityClone from './views/clone';

export default function Index() {
  const [categories, setCategories] = useState([])
  const [types, setTypes] = useState([])

  useEffect(() => {
    fetchCategories()
  }, [])

  const fetchCategories = () => {
    Promise.all([
      adminApi.category.getCategories({ ownedBy: 'SYSTEM' }),
      adminApi.type.getTypes({ ownedBy: 'SYSTEM' })
    ]).then(res => {
      const categories = res[0].data.entries
      const types = res[1].data.entries

      for (let index = 0; index < categories.length; index++) {
        const category = categories[index];
        category.types = types.filter(e => e.categoryIds.includes(category.id));
      }

      setCategories(categories)
      setTypes(types)
    })
  }

  return (
    <PageLayout>
      <Switch>
        <Route exact path="/entity-management/:tab?">
          <EntityManagement categories={categories} types={types} />
        </Route>

        {/* All forms actually use the same form, with very minimum difference.
        We use separate component to ensure that the form gets re-rendered to avoid bugs.
        For example, user might get redirected to update from create (on duplicate check) and
        we want to ensure that the new page is re-rendered so all existing data/state is wiped. */}
        <Route exact path={'/entity-management/:scope(local|global)/create'}>
          <EntityCreate categories={categories} fetchCategories={fetchCategories} types={types} />
        </Route>

        <Route exact path={'/entity-management/:scope/update/:entityVersionId'}>
          <EntityUpdate categories={categories} fetchCategories={fetchCategories} types={types} />
        </Route>

        <Route exact path={'/entity-management/:scope(local|global)/clone-to-jurisdiction/:entityVersionId'}>
          <EntityClone categories={categories} fetchCategories={fetchCategories} types={types} />
        </Route>

        <Redirect to={'/entity-management'}/>

      </Switch>
    </PageLayout>
  )
}

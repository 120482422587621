import React from 'react';
import { Modal } from '@components/modals';


export default function RiskRatingSettingsBatchDeleteDialog({ open, onClose, onConfirm, names }) {

  const getTitle = () => {
    return 'Delete Template';
  }

  const getMainText = () => {
    return `Are you sure you want to delete following templates: "${names?.join('", "')}"?`
  }

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title={getTitle()}
        mainText={getMainText()}
        actions={
          [
            {
              type: 'main',
              label: 'NO',
              action: onClose,
              style: { width: 160 },
            },
            {
              type: 'secondary',
              label: 'YES',
              action: onConfirm,
              style: { width: 160 },
            },
          ]
        }
        actionsDirection="row"
      />
    </React.Fragment>
  );
}
import auth from './auth';
import scroll from './scroll';
import general from './general';
import loader from './loader';
import portfolio from './portfolio';
import paginations from './paginations';
import globalSearch from './globalSearch';
import jobsManagment from './jobsManagment';
import searchHistory from './searchHistory';
import backgroundCheck from './backgroundCheck';
import usersManagement from './usersManagement';
import calendarSchedule from './calendarSchedule';
import backgroundSearch from './backgroundSearch';
import entityManagement from './entityManagement';
import billingManagement from './billingManagement';
import ongoingMonittoring from './ongoingMonittoring';

const reducers = {
  auth,
  scroll,
  loader,
  general,
  portfolio,
  paginations,
  globalSearch,
  jobsManagment,
  searchHistory,
  backgroundCheck,
  usersManagement,
  backgroundSearch,
  calendarSchedule,
  entityManagement,
  billingManagement,
  ongoingMonittoring,
}

export default reducers;
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export function prepareData(data) {
  return _.omitBy(
    _.mapValues(data, (value =>
      typeof value === 'string'
        ? _.trim(value)
        : value)
    ),
    (value) => {
      // Check if it's a date
      if (value?.getMonth) return false
      if (typeof value === 'string') return !value
      if (typeof value === 'object') return _.isEmpty(value)
      return _.isNil(value)
    }
  )
}

export function prepareIndexParams(params){
  return {
    pageNumber: 0,
    pageSize: 10,
    ...params
  }
}

export function getGuestToken(isRegister) {
  let guestToken = localStorage.getItem('guest-token');

  if (isRegister) localStorage.removeItem('guest-token');

  if (!!guestToken) return guestToken;

  guestToken = uuidv4();
  localStorage.setItem('guest-token', guestToken);
  return guestToken;
}

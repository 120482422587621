import React, { useState, useMemo } from 'react';
import { Box, MenuItem } from '@material-ui/core';
import InnerHeader from '@components/layout/inner-header';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { NewPersonIcon, NewGroupActiveIcon } from '@app/icons';
import MenuWithContext from '@components/dropdowns/menu-with-context';

import history from '@app/history';
import ManageAdmins from './manage-admins';
import ManageRoles from './manage-roles';

const tabs = [
  {
    id: 'manageAdmins',
    label: 'Manage Admins',
  },
  {
    id: 'manageRoles',
    label: 'Manage Roles',
  },
]

const defaultModel = {
  role: '',
  note: '',
}

export default function AdminManagement() {
  const [tabID, setTabID] = useState('manageAdmins');
  const [model, setModel] = useState(defaultModel);
  const [openModal, setOpenModal] = useState(null);
  const [manageData, setManageData] = useState([]);
  const [modalAction, setModalAction] = useState(null);

  const isManage = useMemo(() => {
    return tabID === 'manageAdmins';
  }, [tabID])

  const onTabChange = (index) => {
    const tabID = tabs[index].id;
    setTabID(tabID);
  }

  const handleMenuActions = (type) => {
    const checkedItems = manageData.filter(el => el.isChecked);
    setModalAction({
      name: checkedItems.map(el => el.adminRole).join(', '),
      type,
      prefix: 'role(s)'
    });
  }

  return (
    <Box mx={6} height="100%">
      <InnerHeader
        onTabChange={onTabChange}
        ind={tabs.findIndex(tab => tab.id === tabID)}
        tabs={tabs}
        buttons={
          <React.Fragment>
            <ButtonWithIcon
              startIcon={isManage ? <NewPersonIcon /> : <NewGroupActiveIcon />}
              onClick={() => isManage ? history.push('/admin-management/add-admin') : setOpenModal({ type: 'create' })}
            >
              {isManage ? 'New Admin' : 'New Role'}
            </ButtonWithIcon>
            {!isManage && (
              <MenuWithContext title="More Actions">
                <MenuItem onClick={() => handleMenuActions('delete')}>
                  Delete
                </MenuItem>
                <MenuItem onClick={() => handleMenuActions('block')}>
                  Block
                </MenuItem>
              </MenuWithContext>
            )}
          </React.Fragment>
        }
      />
      {isManage ? (
        <ManageAdmins />
      ) : (
        <ManageRoles
          model={model}
          setModel={setModel}
          openModal={openModal}
          setOpenModal={setOpenModal}
          modalAction={modalAction}
          setModalAction={setModalAction}
          setManageData={setManageData}
        />
      )}
    </Box>
  )
}
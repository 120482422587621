import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Box, Grid, Link, Typography
} from '@material-ui/core';
import { eidv } from '@app/api/client';

import LabelsForRender from '@components/layout/labels-for-render';
import CustomChip from '@components/controls/custom-chip';
import EVTitle from '../components/eidv-title';
import BasicDetailsDialog from './basic-details-dialog';
import Value from '../components/label-value';
import EVPendingButtons from '../components/eidv-pending';
import EVError from '../components/ediv-error';
import casesDto, { manualApproveDto } from '../dto';
import ImageWrapper from '../components/eidv-image-preview';

export default function EVAddressInformation({ data, handleDownload, clientData, fetchData, handleRepeat, readonly }) {
  const { enqueueSnackbar } = useSnackbar();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const details = data.addressInfo || {};
  const pending = data.status === 'pending approval' && details.status === 'pending approval';

  const contactInfo = [
    { field: 'address line 1', notText: true, value: <Value data={details.addressLine1} /> },
    { field: 'address line 2', notText: true, value: <Value data={details.addressLine2} /> },
    { field: 'city', notText: true, value: <Value data={details.city} /> },
    { field: 'region/state /province', notText: true, value: <Value data={details.region} /> },
    { field: 'postal code', notText: true, value: <Value data={details.postalCode} /> },
    { field: 'country', notText: true, value: <Value data={details.country} /> },
    { field: 'document issued date', notText: true, value: <Value data={details.issuingDate} /> },
  ]


  const handleSave = (formData) => {
    eidv.updateAddressInformationUserData(casesDto.writeAddressInfo(formData), data.id).then(() => {
      enqueueSnackbar('eIDV Address information was successfully updated!', { variant: 'success' });
      setEditDialogOpen('');
      fetchData();
    })
  }

  const onManualApprove = () => {
    const addressData = manualApproveDto.addressInformation(data);
    eidv.updateAddressInformationUserData(addressData, data?.id).then(() => {
      enqueueSnackbar('eIDV Address Information was approved successfully!', { variant: 'success' })
      fetchData();
    })
  }

  return (
    <React.Fragment>
      <Grid container style={{ marginBottom: 24 }}>
        <BasicDetailsDialog
          open={editDialogOpen}
          type="address"
          onSave={handleSave}
          details={details}
          onClose={() => setEditDialogOpen('')}
        />

        <Grid item xs={6}>
          <Box>
            <EVTitle status={details.status}>Address Information</EVTitle>
            <Box mt={3}>
              <Typography variant="h5">Address</Typography>
            </Box>
            {Object.keys(details).length > 1 &&
              <Box mt={2}>
                <CustomChip
                  label={
                    details.updatedByKycUser ? 'Updated by KYC user'
                      : details.isFromDocument ? 'Extracted from document'
                        : 'Updated by client'}
                  background="#ECF0ED"
                  labelColor="#656565"
                  border="1px solid #CAD7CA"
                />
              </Box>
            }
            <Box mt={2}>
              <LabelsForRender labels={contactInfo} boxProps={{ mb: 1 }} />
            </Box>
          </Box>
          <Box>
            {(pending && !readonly) && <Link onClick={() => setEditDialogOpen(true)} style={{ fontSize: 12 }}>Edit</Link>}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box pl={4}>
            <ImageWrapper
              title="Proof of Address"
              imgSrc={details.addressDocumentUrl}
              clientName={clientData.name}
              onDownload={() => handleDownload('addressInfo.addressDocumentUrl', 'Proof of Address')}
              boxWidth={315}
              imgWidth={250}
              imgHeight={200}
              isPdf={details.addressDocumentType === 'pdf'}
            />
          </Box>
        </Grid>


        {(pending && !details.updatedByKycUser && !details.addressAcquired) &&
          <Grid item xs={12} style={{ marginTop: 16 }}>
            <EVError message="The client address data was not successfully acquired." centerVertically />
          </Grid>
        }

        {(pending && !details.updatedByKycUser && !details.isFromDocument) &&
          <Grid item xs={12} style={{ marginTop: 16 }}>
            <EVError message="The client edited manually the field(s) marked with ! icon." centerVertically />
          </Grid>
        }
      </Grid>
      {(pending && !readonly) &&
        <EVPendingButtons onRepeat={handleRepeat} onApprove={onManualApprove} />
      }
    </React.Fragment>
  )
}
import typography from './typography'
import treeView from './tree-view';
import buttons from './buttons';
import controls from './controls';
import list from './list';
import popover from './popover';
import palette from './palette';
import tabs from './tabs';
import menu from './menu';
import link from './link';
import table from './table';
import dialog from './dialog';
import divider from './divider';
import pagination from './pagination';
import breadcrumbs from './breadcrumbs';
import card from './card';

const theme = {
  palette,
  typography,
  overrides: {
    ...treeView,
    ...buttons,
    ...controls,
    ...list,
    ...popover,
    ...palette,
    ...tabs,
    ...menu,
    ...link,
    ...table,
    ...dialog,
    ...divider,
    ...pagination,
    ...breadcrumbs,
    ...card
  },
  props: {
    MuiInput: {
      disableUnderline: true,
    }
  }
}

export default theme
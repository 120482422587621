import React from  'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  textField: {
    '& .MuiSelect-select': {
      backgroundColor: '#F8F8F8',
      borderRadius: 8,
      fontSize: 12,
      margin: 1,
      paddingLeft: 10,
      paddingRight: 20,
      paddingTop: 6,
      paddingBottom: 6,
      color: theme.palette.common.black,
      fontWeight: 500,
      '&:hover': {
        margin: 0,
        border: '1px solid #CCC'
      }
    }
  },
}));

export default function Select({ id, inputProps, SelectProps, InputProps, children, ...other }) {
  const classes = useStyles();

  return (
    <TextField
      id={id}
      size="small"
      InputProps={{
        disableUnderline: true,
        inputProps: {...inputProps},
        ...InputProps,
      }}
      SelectProps={SelectProps}
      classes={{ root: classes.textField }}
      select
      {...other}
    >
      {children}
    </TextField>
  )
} 

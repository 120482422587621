import React from 'react';
import PageLayout from '@components/layout/page-layout';
// import history from "@app/history";

import AdminManagement from './admin-management';
import AddAdmin from './add-admin';
import ViewAdmin from './view-admin';

import { Route, Switch } from 'react-router';

export default function Index(props) {

  return (
    <PageLayout>
      <Switch>
        <Route exact path="/admin-management" component={AdminManagement} />
        <Route exact path="/admin-management/add-admin" component={AddAdmin} />
        <Route exact path="/admin-management/view-admin" component={ViewAdmin} />
        <Route exact path="/admin-management/edit-admin">
          <AddAdmin isEdit />
        </Route>
      </Switch>
    </PageLayout>
  )
}

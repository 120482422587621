import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Typography, FormControlLabel, RadioGroup, OutlinedInput, makeStyles, InputLabel, FormControl } from '@material-ui/core';
import RadioButton from '@components/buttons/radio-button';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  checkboxRoot: {
    width: '370px',
    paddingRight: '56px',
    marginRight: 0
  },
  firstCheckbox: {
    marginBottom: '16px',
  },
  radioRoot: {
    padding: '6px',
    marginRight: '4px',
  },
  checkboxLabel: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    color: theme.palette.text.BL1,
  },
  footerText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center',
    color: '#B6514C',
    marginBottom: '20px'
  },
  showAddCommentButton: {
    background: theme.palette.background.GY1,
    '&.Mui-disabled': {
      background: theme.palette.background.GY1,
    }
  },
  input: {
    '& .MuiInputBase-input': {
      '&::placeholder': {
        fontWeight: 400,
        opacity: 1,
        color: '#656565',
      },
    },
  },
  formControl: {
    left: '340px',
  },
}));

const COMPANY = 'COMPANY';

export default function ModalContent({ setData, type, data, disabled }) {
  const { currentSearch } = useSelector(store => store.backgroundCheck);
  const seacrhType = useMemo(() => {
    return currentSearch.type === COMPANY ? 'legal entity' : 'person';
  }, [currentSearch])

  const [boxItems, setBoxItems] = useState([
    {
      id: '111',
      label: `Our saved ${seacrhType} is confirmed to be different from the ${seacrhType} in this ${type}`,
      isChecked: false,
      note: { note: '' }
    },
    {
      id: '222',
      label: `This is a duplicated ${type}`,
      isChecked: false,
      note: { note: '' }
    },
    {
      id: '333',
      label: 'Other',
      isChecked: false,
      note: { note: '' }
    },
  ]);
  const [value, setValue] = useState(data?.note || { note: ''});
  const [selected, setSelected] = useState(boxItems.find(el => el.label === data?.reason)?.id || null);

  const classes = useStyles();

  useEffect(() => {
    if (data) {
      setBoxItems(items => items.map(el => el.label === data.reason
        ? ({ ...el, isChecked: true, note: data.note })
        : el
      ));
    }
  }, [data])

  const handleComment = useCallback((note) => {
    setValue(el => ({ ...el, note }));
    setTimeout(async () => {
      const items = boxItems.map(el => el.isChecked ? ({ ...el, note: { ...el.note, note } }) : el);
      const checked = items.find(el => el.isChecked);
      setBoxItems(items);
      await setData(checked);
    }, 0);
  }, [boxItems, setData])

  const handleChange = (event) => {
    if (selected === event.target.value) return;
    setSelected(event.target.value);
    setTimeout(async () => {
      const items = boxItems.map(el => el.id === event.target.value
        ? ({ ...el, isChecked: event.target.checked, note: value })
        : ({ ...el, isChecked: !event.target.checked, note: { note: '' } })
      );
      const checked = items.find(el => el.isChecked);
      setBoxItems(items);
      await setData(checked);
    }, 0);
  }

  return (
    <Box mx={2} mt={2}>
      <Box justifyContent={'center'} my={2} px={3}>
        <Typography variant="h5">Reason</Typography>
      </Box>
      <RadioGroup>
        {boxItems.map((el, i) => (
          <Box key={el.id}>
            <Box
              pl={3}
              pr={2}
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <FormControlLabel
                classes={{ root: classes.checkboxRoot, label: classes.checkboxLabel }}
                labelPlacement="end"
                label={el.label}
                value={el.id}
                onChange={handleChange}
                checked={el.id === selected}
                disabled={disabled}
                control={
                  <RadioButton
                    value={el.id}
                    classes={{ root: clsx(classes.radioRoot, !i && classes.firstCheckbox) }}
                  />
                }
              />
            </Box>
          </Box>
        ))}
      </RadioGroup>

      <Box justifyContent={'center'} mb={2} mt={3} px={3}>
        <Typography variant="h5">Note</Typography>
      </Box>
      <Box mx={3} mt={1}>
        <FormControl
          fullWidth
          variant="outlined"
          disabled={disabled}
        >
          <InputLabel htmlFor="name" shrink={true} classes={{ formControl: classes.formControl }}>{value?.note?.length}/250</InputLabel>
          <OutlinedInput
            fullWidth
            value={value.note || ''}
            onChange={e => handleComment(e.target.value)}
            placeholder="Enter your note here"
            type={'text'}
            multiline
            rows={5}
            inputProps={{ maxLength: 250 }}
            classes={{
              root: classes.input
            }}
          />
        </FormControl>
      </Box>
      <Box justifyContent={'center'} mt={4} mb={2} px={3}>
        <Typography className={classes.footerText}>
          Note: You will no longer receive alerts for hits related to this {type}
        </Typography>
      </Box>
    </Box>
  )
}
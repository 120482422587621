import React, { useEffect, useMemo, useState, useLayoutEffect } from 'react';
import { Box, withStyles, IconButton, Input, makeStyles } from '@material-ui/core';

import {
  FileViewerDownload,
  FileViewerRotateCcw,
  FileViewerRotateCw,
  FileViewerScreenCollapse,
  FileViewerScreenExpand,
  FileViewerZoomIn,
  FileViewerZoomInDisabled,
  FileViewerZoomOut,
  FileViewerZoomOutDisabled,
} from '@app/icons'
import CustomTooltip from '@components/controls/tooltip';
import FileViewerWrapper from '../modals/file-viewer-wrapper';
import { handleOnEnter } from '@utils/handleOnEnter';

const IconButtonNoPad = withStyles(() => ({
  root: {
    padding: 0,
  }
}))(IconButton)

const useStyles = makeStyles((theme) => ({
  pageInputInput: {
    fontSize: 10,
    textAlign: 'center',
    fontWeight: 'normal',
  },
  pageInput: {
    width: 32,
    height: 18,
  },
  pageInputWrapper: {
    border: '1px solid #E7E7E7',
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.15)',
    mixBlendMode: 'multiply',
    borderRadius: '6px',
    height: 32,
  },
  zoomInput: {
    width: 40,
    height: 18,
  }
}));

const MAX_SCALE = 500;
const MIN_SCALE = 15;
const SCALE_INCREMENT = 15;

const Tool = ({ tooltip, children, ...props }) => (
  <CustomTooltip title={tooltip}>
    <span>
      <IconButtonNoPad {...props}>
        {children}
      </IconButtonNoPad>
    </span>
  </CustomTooltip>
)

export default function ImageViewer({ url, open, onClose, documentName, clientName, onDownload }) {
  const classes = useStyles();

  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(100);
  const [scaleInput, setScaleInput] = useState(100)
  const [fullScreen, setFullScreen] = useState(false);
  
  // Image calculations
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [recalculateSize, setRecalculateSize] = useState(false);
  const [hideFullScreen, setHideFullScreen] = useState(false);

  useEffect(() => {
    if (!open) {
      setScale(100);
      setFullScreen(false);
      setRotate(0);
      setWidth(0);
      setHeight(0);
    }
  }, [open])

  useEffect(() => {
    setScaleInput(`${scale}%`);
  }, [scale])

  /*
  * Remove fullscreen tooltip for a short period
  * Because fullscreen tooltip can get stuck open
  * When user click on it and the component is resized
  * Causing the cursor to stay outside the component and
  * onLeave never gets triggered
  */
  useEffect(() => {
    if (hideFullScreen) {
      setHideFullScreen(false);
      setTimeout(() => setHideFullScreen(false), 250)
    }
  }, [hideFullScreen])

  /**
   * Using useLayoutEffect to allow dom to update into fullscreen
   * Before recalculating image parent size.
   */
  useLayoutEffect(() => {
    setRecalculateSize(!recalculateSize);
    setHideFullScreen(true);
  }, [fullScreen]) // eslint-disable-line

  const handleScaleInputChange = (e) => {
    let value = e.target.value;
    if (!value) {
      setScaleInput('');
    }

    if (isNaN(value)) {
      return;
    }

    setScaleInput(value);
  }

  const rotateLeft = () => {
    const newRotate = rotate - 90;
    setRotate(newRotate > 0 ? newRotate : newRotate + 360)
  }

  const rotateRight = () => {
    const newRotate = rotate + 90;
    setRotate(newRotate < 360 ? newRotate : newRotate - 360)
  }

  const zoomOut = () => {
    const newScale = scale - SCALE_INCREMENT;
    setScale(newScale < MIN_SCALE ? MIN_SCALE : newScale)
  }

  const zoomIn = () => {
    const newScale = scale + SCALE_INCREMENT;
    setScale(newScale > MAX_SCALE ? MAX_SCALE : newScale)
  }

  const handleScaleBlur = () => {
    const value = scaleInput;
    if (!value) {
      return setScaleInput(scale);
    }

    let newScale = +value.replace('%', '');
    if (newScale >= MAX_SCALE) {
      newScale = MAX_SCALE
    }

    if (newScale < MIN_SCALE) {
      newScale = MIN_SCALE
    }

    setScale(newScale)
  }

  const onImageLoad = () => {
    const image = document.getElementById('image-viewer-image');
    const imageWidth = image?.naturalWidth || 0;
    const imageHeight = image?.naturalHeight || 0;
    setWidth(imageWidth);
    setHeight(imageHeight);

    const parent = document.getElementById('image-viewer-parent');
    const parentWidth = parent?.clientWidth || 0;
    const parentHeight = parent?.clientHeight || 0;

    if (imageWidth > imageHeight) {
      if (imageWidth > parentWidth) {
        setScale(Math.floor((parentWidth / imageWidth) * 100));
      }
    } else {
      if (imageHeight > parentHeight) {
        setScale(Math.floor((parentHeight / imageHeight) * 100));
      }
    }
    
  }

  /**
   * Using transform: rotate(deg) does not actually change the "physical" shape of the image
   * hence we use a "fake" image, an empty div that resembles the image in width/height.
   * 
   * This fake image helps to trigger overflow and specifying space needed for the image to the parent wrapper.
   * 
   * The image itself is positioned absolute to the parent. We need to match the image position to the
   * fake image.
   * 
   * The best way to do this is to find the center point of the fake image.
   * Then updates the center point of the real image to match the location of the fake center point.
   */
  const imageSize = useMemo(() => {
    const parent = document.getElementById('image-viewer-parent');
    const parentWidth = parent?.clientWidth || 0;
    const parentHeight = parent?.clientHeight || 0;

    const newWidth = width * (scale / 100);
    const newHeight = height * (scale / 100);
    let fakeWidth = newWidth;
    let fakeHeight = newHeight;

    if (rotate % 180 !== 0) {
      fakeWidth = newHeight;
      fakeHeight = newWidth;
    }

    const middleWidth = (parentWidth > fakeWidth ? parentWidth : fakeWidth) / 2
    const middleHeight = (parentHeight > fakeHeight ? parentHeight : fakeHeight) / 2

    const imageMiddleWidth = newWidth / 2;
    const imageMiddleHeight = newHeight / 2;

    const left = middleWidth - imageMiddleWidth;
    const top = middleHeight - imageMiddleHeight;

    
    return {
      width: newWidth, height: newHeight,
      fakeWidth, fakeHeight,
      top, left
    }
  }, [width, height, scale, rotate, recalculateSize]) // eslint-disable-line

  return (
    <FileViewerWrapper open={open} onClose={onClose} title={documentName} subtitle={clientName} fullScreen={fullScreen}>
      <Box display="flex" justifyContent="space-between" pt={3} pb={2} pr={1} style={{ borderBottom: '1px solid black' }}>
        <Box width={150}></Box>

        <Box display="flex" alignItems="center">
          <Tool tooltip="Zoom Out" onClick={zoomOut} disabled={scale <= 15}>
            {scale <= 15 ? <FileViewerZoomOutDisabled /> : <FileViewerZoomOut />}
          </Tool>
          <Box mx={1} width={58} display="flex" justifyContent="center" alignItems="center" className={classes.pageInputWrapper}>
            <Input
              classes={{
                input: classes.pageInputInput,
              }}
              className={classes.zoomInput}
              value={scaleInput}
              onKeyDown={handleOnEnter(handleScaleBlur)}
              onChange={handleScaleInputChange}
              onBlur={handleScaleBlur}
            />
          </Box>
          <Tool tooltip="Zoom In" onClick={zoomIn} disabled={scale >= 500}>
            {scale >= 500 ? <FileViewerZoomInDisabled /> : <FileViewerZoomIn />}
          </Tool>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="flex-end" width={150}>
          <Box>
            <Tool tooltip="Rotate Counter Clockwise" onClick={rotateLeft}>
              <FileViewerRotateCcw />
            </Tool>
          </Box>
          <Box mx={1}>
            <Tool tooltip="Rotate Clockwise" onClick={rotateRight}>
              <FileViewerRotateCw />
            </Tool>
          </Box>
          <div style={{ borderRight: '1px solid #C6C6C6', width: 1, height: '100%' }}></div>
          <Box mx={1}>
            <Tool tooltip="Download" onClick={onDownload}>
              <FileViewerDownload />
            </Tool>
          </Box>
          <div style={{ borderRight: '1px solid #C6C6C6', width: 1, height: '100%' }}></div>
          <Box mx={1}>
            {hideFullScreen ?
              <IconButtonNoPad>
                {fullScreen ? <FileViewerScreenCollapse /> : <FileViewerScreenExpand />}
              </IconButtonNoPad>
              :
              <Tool tooltip={fullScreen ? 'Exit Full Screen' : 'Full Screen'} onClick={() => setFullScreen(!fullScreen)}>
                {fullScreen ? <FileViewerScreenCollapse /> : <FileViewerScreenExpand />}
              </Tool>
            }
          </Box>
        </Box>
      </Box>

      <Box
        height={fullScreen ? 'calc(100vh - 64px - 34px - 75px)' : '75vh'}
        width={fullScreen ? '100%' : '80vw'}
        position="relative"
        style={{
          overflow: 'auto',
          backgroundColor: '#C4C4C4',
        }}
        id="image-viewer-parent"
      >
        {url &&
          <img
            src={url}
            alt={documentName}
            style={{
              transform: `rotate(${rotate}deg)`,
              position: 'absolute',
              width: imageSize.width,
              top: imageSize.top,
              left: imageSize.left
            }}
            id="image-viewer-image"
            onLoad={onImageLoad}
            onError={(err) => { throw new Error('An image error has occured') }}
          />
        }
        <div
          style={{
            width: imageSize.fakeWidth,
            height: imageSize.fakeHeight,
            margin: 'auto',
          }}
        >
        </div>
      </Box>
    </FileViewerWrapper>
  )
}

export const validateCategories = (categories, isRequired) => {
  let allCategoriesHaveTypes = true
  let allHaveCategoriesTypeSelected = true
  let generalErrors = []
  if (!categories.length && isRequired) {
    generalErrors.push('At least one document category should be selected!')
  } else {
    categories.forEach(c => {
      allCategoriesHaveTypes = allCategoriesHaveTypes && !!c.types.length && !!c.types.some(t => t.isChecked)
      allHaveCategoriesTypeSelected = allHaveCategoriesTypeSelected && !!c.categoryRuleType
    })
    if (!allCategoriesHaveTypes) {
      generalErrors.push('At least one document type per category should be selected!')
    }
    if (!allHaveCategoriesTypeSelected) {
      generalErrors.push('All the categories must have rule type selected')
    }
  }
  return generalErrors
}
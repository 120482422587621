import React from 'react';
import {
  Typography,
  DialogContent,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  Link
} from '@material-ui/core';
import { CloseIcon, AboutBgCheckBgLogoIcon, AboutBGCheckRegularIcon, AboutBGCheckPremiumIcon } from '@app/icons';
import history from '@app/history';


export default function AboutBGCheckDialog({open, onClose}) {

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          style: { padding: '30px 50px', width: '100%' }
        }}
        maxWidth={'lg'}
      >
        <Box style={{
          position: 'absolute',
          bottom: 0,
          right: 0
        }}>
          <AboutBgCheckBgLogoIcon />
        </Box>
        <DialogTitle style={{padding: 0}}>
          <Box display={'flex'} alignItems={'center'}>
            <Box display={'flex'} flexGrow={1}>
              <Typography variant={'h1'} style={{ color: '#232323' }}>
                                About Background check
              </Typography>
            </Box>
            <Box display={'flex'}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} mt={2} width={'40%'}>
              <Typography variant={'subtitle1'} align={'left'} style={{color: '#656565'}}>
                                You can use different types of search credits for SDD and EDD.
                                Each name searched will spend 1 search credit.
              </Typography>
            </Box>
            <Box display={'flex'} mt={3}>
              <Box display={'flex'} style={{width: '50%'}}>
                <Box display={'flex'} mr={2}>
                  <AboutBGCheckRegularIcon />
                </Box>
                <Box display={'flex'} flexDirection={'column'} pr={3}>
                  <Typography variant={'subtitle2'} align={'left'} style={{color: '#000', fontWeight: 'bold', textDecoration: 'uppercase'}}>
                                        Regular Credits for Standard Due diligence
                  </Typography>
                  <Typography variant={'subtitle1'} align={'left'} style={{color: '#656565', marginTop: '16px'}}>
                                        Searches performed with regular credits will effectively be checked against the primary sanctions list and limited PEP sources. Thus, for example, only high-level politicians will be found in this dataset.
                  </Typography>
                </Box>
              </Box>
              <Box display={'flex'} style={{width: '50%'}}>
                <Box display={'flex'} mr={2}>
                  <AboutBGCheckPremiumIcon />
                </Box>
                <Box display={'flex'} flexDirection={'column'} pr={3}>
                  <Typography variant={'subtitle2'} align={'left'} style={{color: '#000', fontWeight: 'bold', textDecoration: 'uppercase'}}>
                                        Premium Credits for Extended Due diligence
                  </Typography>
                  <Typography variant={'subtitle1'} align={'left'} style={{color: '#656565', marginTop: '16px'}}>
                    {'Searches performed with premium credits will effectively be checked against a comprehensive dataset of more than '}
                    <Link href="/sources-list" target="_blank">1300 sources</Link>
                    {', including Sanctions, 4 PEP classes and Adverse media.'}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display={'flex'} mt={2}>
              <ul style={{paddingLeft: '18px', margin: 0}}>
                <li>
                  <Typography variant={'subtitle1'} align={'left'} style={{color: '#656565'}}>
                                        A search credit equals one unique name search. Every search spends one search credit.
                  </Typography>
                </li>
                <li>
                  <Typography variant={'subtitle1'} align={'left'} style={{color: '#656565'}}>
                                        Names on the ongoing monitoring list count towards the plan’s search credit allowance.
                  </Typography>
                </li>
              </ul>
            </Box>
            <Box display={'flex'} mt={3}>
              <Typography variant={'h6'} style={{color: '#656565'}}>
                                Make sure you use full set of features of our Background check and Ongoing Monitoring
              </Typography>
            </Box>
            <Box display={'flex'} mt={2}>
              <Typography variant={'subtitle1'} align={'left'} style={{color: '#656565'}}>
                                Use the "whitelist" feature to remove false-positive profiles from your search.<br/>
                                When you open the search profile you can add comments, flags and use the "suppress" feature for removing a false-positive source of information for a particular profile.
              </Typography>
            </Box>
            <Box display={'flex'} flexDirection={'column'} mt={2}>
              <Typography variant={'subtitle1'} align={'left'} style={{color: '#656565'}}>
                                After you've completed review of the search profile you can
              </Typography>
              <ul style={{paddingLeft: '18px', margin: 0}}>
                <li>
                  <Typography variant={'subtitle1'} align={'left'} style={{color: '#656565'}}>
                                        Download the report
                  </Typography>
                </li>
                <li>
                  <Typography variant={'subtitle1'} align={'left'} style={{color: '#656565'}}>
                                        and Save client in your Portfolio.
                  </Typography>
                </li>
              </ul>
            </Box>
            <Box display={'flex'} mt={2}>
              <Typography variant={'subtitle1'} align={'left'} style={{color: '#656565'}}>
                                The search will remain tied to the person/legal entity saved in the Portfolio.<br/>
                                The search can be monitored for the period of one year for the cost of the same credit spent on the initial search. On the annual update, one search credit will be spent for monitoring service for the next annual term.
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions style={{justifyContent:'flex-start', marginTop: '24px', paddingLeft: 0}}>
          <Button variant={'contained'} onClick={() => {
            history.push('/my-profile/subscription/plans')
          }}>
                        View Subscription Plans
          </Button>
          <Button variant={'outlined'} onClick={onClose}>
                        CREATE SEARCH
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
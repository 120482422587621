import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { EidvRedWarningIcon } from '@app/icons';

export default function EVError({ message, centerVertically }) {

  return (
    <Box
      width={384} // 400 with padding
      display="flex"
      alignItems={centerVertically ? 'center' : 'flex-start'}
      p={1}
      style={{
        background: '#F8F8F8',
        borderRadius: '12px',
      }}
    >
      <Box style={{ width: 28, height: 28 }}>
        <EidvRedWarningIcon style={{ width: 28, height: 28 }} />
      </Box>
      <Box p={1}>
        <Typography variant="body1" style={{ color: '#000000' }}>
          {message}
        </Typography>
      </Box>
    </Box>
  )
}
import Landing from '@views/public/landing';
import CASourcesList from '@views/public/ca-sources-list';
import PrivacyPolicy from '@views/auth/privacy-policy';
import TermsOfUse from '@views/auth/terms-of-use';

const routes = [
  {
    path: '/',
    component: Landing,
    guest: true
  },
  {
    path: '/sources-list',
    component: CASourcesList,
    always: true
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    always: true
  },
  {
    path: '/terms-of-use',
    component: TermsOfUse,
    always: true
  },
];

export default routes;
import React from 'react';
import { datadogLogs } from '@datadog/browser-logs'
import config from './app/config';
import store from '@app/store';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    if (config.datadog.clientToken) {
      datadogLogs.init({
        clientToken: config.datadog.clientToken,
        site: config.datadog.site,
        service: config.datadog.service,
        sampleRate: 100,
        beforeSend: (log) => {
          if (log?.error?.config?.headers?.Authorization) {
            log.error.config.headers.Authorization = 'REDACTED'
          }
          const user = store?.getState()?.auth.user
          if (user) {
            log.user = {
              name: user.name,
              lastName: user.lastName,
              middleName: user.middleName,
              email: user.email,
            }
          } else {
            log.user = 'Guest user'
          }
        }
      })

      // datadogLogs.logger.error('What if I have multiple errors?', { context: 'This is the context' })
    }

    window.addEventListener('error', (event) => {
      datadogLogs.logger.error('An UI function error has occured.', {
        error: {
          message: event.message,
          stack: event.error?.stack
        }
      })
    })

    // We probably don't want to use this one as we handle error on axios level
    // window.addEventListener('unhandledrejection', (event) => {
    //   console.log('unhandled listener', { event })
    // })

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    datadogLogs.logger.error('A rendering error has occured.', { error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary
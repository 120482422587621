import React from 'react';
import { Box, Button } from '@material-ui/core';
import LinkButton from '@components/buttons/link-button'

export default function EVPendingButtons({ onRepeat, onApprove }) {

  return (
    <Box marginTop="auto" mb={2} display="flex" justifyContent="flex-end" alignItems="center">
      <Box mr={2}>
        <LinkButton onClick={onRepeat}>
          Repeat Step
        </LinkButton>
      </Box>
      <Box>
        <Button onClick={onApprove} variant="contained" style={{ width: 200 }}>APPROVE</Button>
      </Box>
    </Box>
  )
}
import React, {useEffect, useMemo, useState} from 'react';
import {prepareClient} from '@dto/client';
import clsx from 'clsx';
import debugMode from '@utils/debugMode';

import {
  Box, CircularProgress, Grid, Typography
} from '@material-ui/core';

import {clientApi} from '@app/api';
import CustomTooltip from '@components/controls/tooltip';

import { makeStyles } from '@material-ui/core/styles';
import Details from './details';
import BackgroundCheck from './background-check';
import RiskRating from './risk-rating';
import ComplianceProfile from './compliance-profile/view'
import PeriodicReviews from './periodic-reviews/view'
import CustomChip from '@components/controls/custom-chip'
import {
  PersonalActiveBackgroundChecks,
  PersonalActiveBasicDetails,
  PersonalActiveComplianceNotes,
  PersonalActiveElectronicVerification,
  PersonalActiveIDDocs,
  PersonalActiveKYCDocs,
  PersonalActivePeriodicReviews,
  PersonalActiveSignedDocs,
  PersonalActiveRiskRating,
  PersonalActiveActivity,
  PersonalActiveCitizenshipDetails,
  PersonalActiveExtendedDetails2,
  PersonalActiveOwnershipSettings,
  PersonalInactiveBackgroundChecks,
  PersonalInactiveBasicDetails,
  PersonalInactiveComplianceNotes,
  PersonalInactiveElectronicVerification,
  PersonalInactiveIDDocs,
  PersonalInactiveKYCDocs,
  PersonalInactivePeriodicReviews,
  PersonalInactiveSignedDocs,
  PersonalInactiveRiskRating,
  PersonalInactiveActivity,
  PersonalInactiveCitizenshipDetails,
  PersonalInactiveExtendedDetails2,
  PersonalInactiveOwnershipSettings,
  CheckmarkSuccessIcon,
  CheckmarkWarningIcon,
  CheckmarkErrorIcon,
} from '@app/icons';
import { useParams } from 'react-router-dom';
import history from '@app/history';
import moment from 'moment';
import SideMenuLayout, {useStyles as useSideMenuStyles} from '@components/layout/side-menu-layout';
import KycDocuments from './kyc-documents';
import RiskRatingIcon from '@components/flexible-icons/risk-rating-icon';
import _ from 'lodash';
import ElectronicVerification from './electronic-verification';
import useEidvStatus from './electronic-verification/status';
import ShouldRequireUpdate from './should-require-update';
import getKycDocumentStatus from './kyc-documents/status';
import CheckRulebookVersion from './check-rulebook-version';

export const useStyles = makeStyles((theme) => ({
  greyscaleCheckmark: {
    '& path': {
      fill: '#A2A2A2'
    }
  },
  progressRoot: {
    color: '#B6514C'
  }
}))

const renderMock = data => <Grid container justify={'center'} alignItems={'center'} style={{ height: '100%' }}>
  <Grid item xs={12}>
    <Grid item xs={12}>
      <Box mt={6}>
        <Typography variant={'h1'} align={'center'}>{data.name} Coming Soon</Typography>
      </Box>
    </Grid>
    <Grid item xs={12}>
      <Box display={'flex'} my={5} justifyContent={'center'}>
        <Typography variant={'subtitle1'} align={'center'} style={{ maxWidth: '600px' }}>
                    We're working hard on creating the best digital experience for managing your
                    KYC. This will take just a few more weeks.
        </Typography>
      </Box>
    </Grid>
  </Grid>
</Grid>

// TODO: specify correct condition later on
const nextStageShown = false

export default function ClientView(props) {

  const { client, clientId, nextButton, highlightSearchId, baseUri, folders, readonly = false } = props
  const { tab: routeTab} = useParams()

  const classes = useStyles()
  const sideMenuClasses = useSideMenuStyles()

  const [data, setData] = useState({})
  const [tab, setTab] = useState(routeTab || 'personal-details')
  const [updateEidvStatus, eidvStatusIcon] = useEidvStatus(clientId, classes) 

  // Take client data from parent
  useEffect(() => {
    if (!!client) {
      setData(client)
    }
  }, [client])

  // Load client data by client id if object not passed from parent
  useEffect(() => {
    if(!client) {
      fetch()
    }
  }, [clientId]) // eslint-disable-line

  useEffect(() => {
    if (routeTab) {
      setTab(routeTab)
    }
  }, [routeTab])

  const hasUpcomingReview = useMemo(() => {
    return data?.next_review_at && (moment(data?.next_review_at).format('X') < moment().add(30, 'days').format('X'))
  }, [data])

  const hasBackgroundChecks = useMemo(() => !!(data?.linked_searches || []).length, [data])

  const tabs = useMemo(() => {
    const visibleList = debugMode.getIsDebugMode()
      ? ['personal-details', 'extended-details', 'citizenship-details', 'ownership-details', 'activity-details', 'electronic-verification', 'background-check', 'risk-rating', 'periodic-reviews', 'compliance-profile', 'kyc-documents']
      : ['personal-details', 'extended-details', 'citizenship-details', 'ownership-details', 'activity-details', 'electronic-verification', 'background-check', 'risk-rating', 'periodic-reviews', 'compliance-profile', 'kyc-documents'];

    const getDetailStatus = (displayLevel, name) => {
      if (data?.clientStatus?.violations?.[displayLevel] === undefined) {
        return (
          <CustomTooltip title={`${name} are not defined`} placement="top">
            <CheckmarkWarningIcon className={classes.greyscaleCheckmark} />
          </CustomTooltip>
        )
      }
      if (data?.clientStatus?.violations?.[displayLevel] === true) {
        return (
          <CustomTooltip title={`${name} are incomplete`} placement="top">
            <CheckmarkWarningIcon />
          </CustomTooltip>
        )
      }
      if (data?.clientStatus?.violations?.[displayLevel] === false) {
        return (<CheckmarkSuccessIcon />)
      }
    }

    return [
      {
        slug: 'personal-details',
        name: 'Basic Details',
        disabled: false,
        statusIcon: getDetailStatus('basicDetails', 'Basic Details'),
        component: <Details />,
        displayLevel: 'basicDetails',
        icon: <PersonalActiveBasicDetails />,
        iconInactive: <PersonalInactiveBasicDetails />,
      },
      {
        slug: 'extended-details',
        name: 'Extended Details',
        disabled: false,
        statusIcon: getDetailStatus('extendedDetails', 'Extended Details'),
        component: <Details />,
        displayLevel: 'extendedDetails',
        icon: <PersonalActiveExtendedDetails2 />,
        iconInactive: <PersonalInactiveExtendedDetails2 />,
      },
      {
        slug: 'citizenship-details',
        name: 'Citizenship Details',
        disabled: false,
        statusIcon: getDetailStatus('citizenshipDetails', 'Citizenship Details'),
        component: <Details />,
        displayLevel: 'citizenshipDetails',
        icon: <PersonalActiveCitizenshipDetails />,
        iconInactive: <PersonalInactiveCitizenshipDetails />,
        visibleOnlyTo: 'PERSON'
      },
      {
        slug: 'ownership-details',
        name: 'Ownership Information',
        disabled: false,
        statusIcon: getDetailStatus('ownershipDetails', 'Ownership Information'),
        component: <Details />,
        displayLevel: 'ownershipDetails',
        icon: <PersonalActiveOwnershipSettings />,
        iconInactive: <PersonalInactiveOwnershipSettings />,
        visibleOnlyTo: 'LEGAL_ENTITY'
      },
      {
        slug: 'activity-details',
        name: 'Activity and Regulation',
        disabled: false,
        statusIcon: getDetailStatus('activityDetails', 'Activity and Regulation'),
        component: <Details />,
        displayLevel: 'activityDetails',
        icon: <PersonalActiveActivity />,
        iconInactive: <PersonalInactiveActivity />,
        visibleOnlyTo: 'LEGAL_ENTITY'
      },
      {
        slug: 'compliance-profile',
        name: 'Compliance Profile',
        disabled: false,
        statusIcon: null,
        component: <ComplianceProfile />,
        icon: <PersonalActiveIDDocs />,
        iconInactive: <PersonalInactiveIDDocs />
      },
      {
        slug: 'electronic-verification',
        name: 'Electronic Verification',
        disabled: false,
        statusIcon: eidvStatusIcon,
        // statusIcon: null,
        component: <ElectronicVerification />,
        icon: <PersonalActiveElectronicVerification />,
        iconInactive: <PersonalInactiveElectronicVerification />,
        visibleOnlyTo: 'PERSON'
      },
      {
        slug: 'background-check',
        name: 'Background Checks',
        disabled: false,
        statusIcon: (
          hasBackgroundChecks
            ? <CheckmarkSuccessIcon />
            : <CustomTooltip title="Background Checks are incomplete" placement="top">
              <CheckmarkWarningIcon className={clsx(!!data.incomplete && classes.greyscaleCheckmark)} />
            </CustomTooltip>
        ),
        component: <BackgroundCheck />,
        icon: <PersonalActiveBackgroundChecks />,
        iconInactive: <PersonalInactiveBackgroundChecks />
      },
      {
        slug: 'risk-rating',
        name: 'Risk Ratings',
        disabled: false,
        statusIcon: (
          data.hasIncompleteRiskRating
            ? <CustomTooltip title="Risk Rating is incomplete" placement="top">
              <CheckmarkWarningIcon />
            </CustomTooltip>
            : hasUpcomingReview
              ? <CustomTooltip placement={'top'} title={'Risk Rating is due for review'}>
                <CheckmarkErrorIcon/>
              </CustomTooltip>
              : !data.risk_score
                ? <CustomTooltip title="Risk Rating is not defined" placement="top">
                  <CheckmarkWarningIcon className={clsx(classes.greyscaleCheckmark)} />
                </CustomTooltip>
                : <CheckmarkSuccessIcon/>
        ),
        component: <RiskRating />,
        icon: <PersonalActiveRiskRating />,
        iconInactive: <PersonalInactiveRiskRating />
      },
      {
        slug: 'identification-documents ',
        name: 'Identification Documents ',
        disabled: true,
        statusIcon: null,
        component: null,
        icon: <PersonalActiveIDDocs />,
        iconInactive: <PersonalInactiveIDDocs />
      },
      {
        slug: 'signed-documents',
        name: 'Signed Documents and Forms',
        disabled: true,
        statusIcon: null,
        component: null,
        icon: <PersonalActiveSignedDocs />,
        iconInactive: <PersonalInactiveSignedDocs />
      },
      {
        slug: 'kyc-documents',
        name: 'KYC Documents',
        disabled: false,
        statusIcon: getKycDocumentStatus(data, classes),
        component: <KycDocuments />,
        icon: <PersonalActiveKYCDocs />,
        iconInactive: <PersonalInactiveKYCDocs />
      },
      {
        slug: 'compliance-notes',
        name: 'Compliance Notes',
        disabled: true,
        statusIcon: null,
        component: null,
        icon: <PersonalActiveComplianceNotes />,
        iconInactive: <PersonalInactiveComplianceNotes />
      },
      {
        slug: 'periodic-reviews',
        name: 'Periodic Reviews',
        disabled: false,
        statusIcon: !data.next_review_at
          ? <CustomTooltip title="Periodic Review is not defined" placement="top">
            <CheckmarkWarningIcon className={clsx(classes.greyscaleCheckmark)} />
          </CustomTooltip>
          : hasUpcomingReview
            ? <CustomTooltip placement={'top'} title={'You have an upcoming review'}>
              <CheckmarkErrorIcon/>
            </CustomTooltip>
            : <CheckmarkSuccessIcon/>,
        component: <PeriodicReviews />,
        icon: <PersonalActivePeriodicReviews />,
        iconInactive: <PersonalInactivePeriodicReviews />
      }
    ]
    // whitelist this tabs for now. comment the next row to disable whitelist
      .filter(e => visibleList.includes(e.slug))
    // filter some tabs that only belong to person
      .filter(e => !e.visibleOnlyTo || e.visibleOnlyTo === data.client_type)
    // for now, filter eidv on readonly mode
      .filter(e => !readonly || (readonly && e.slug !== 'electronic-verification'))
    }, [data, eidvStatusIcon, readonly]) // eslint-disable-line

  const fetch = async (updateStatuses) => {
    const results = await Promise.all([
      clientApi.client.show(clientId),
      clientApi.riskRating.index(clientId),
    ])
    const client = results[0].data;

    setData({
      ...prepareClient(client),
      hasIncompleteRiskRating: results[1]?.data?.entries?.some(riskRating => !riskRating.dateOfReview)
    })

    if (updateStatuses) {
      updateEidvStatus();
    }
  }

  const getUri = (tabID) => {
    if(tabID) {
      return `${baseUri}/${tabID}`
    }
    return baseUri
  }

  const onSetTab = (tab) => {
    setTab(tab)
    if (baseUri) {
      history.replace(getUri(tab))
    }
  }

  const progressSteps = useMemo(() => _.sum([
    1, // Basic details always done
    hasBackgroundChecks? 1: 0, // Background checks step
    (!data.hasIncompleteRiskRating && !hasUpcomingReview && !!data?.risk_score)? 1: 0, // Risk Rating step
    1 // Mock for now
  ]), [hasBackgroundChecks, hasUpcomingReview, data])

  const totalSteps = 4

  const progressLabel = `${progressSteps}/${totalSteps}`

  const progress = useMemo(() =>
    progressSteps/totalSteps*100,
  [
    progressSteps,
    totalSteps
  ]
  )

  return (
    <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
      <ShouldRequireUpdate
        data={data}
        readonly={readonly}
        section={tabs.find(e => e.slug === tab)?.name}
        refreshClient={fetch}
      />
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} pl={5} pr={7}>
        <Box display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} pt={2} pb={1} style={{
            fontWeight: 'bold',
            fontSize: '30px',
            lineHeight: '28px',
            color: '#232323'
          }}>
            { data?.type === 'PERSON'
              ? [data?.first_name, data?.last_name].join(' ')
              : data?.name
            }
          </Box>
          <Box display={'flex'}>
            <Box display={'flex'} mr={1}>
              {data?.client_type === 'PERSON' &&
                                <CustomChip background={'#D5F0F0'} labelColor={'#484848'} label={'Person'}/>
              }
              {data?.client_type !== 'PERSON' &&
                                <CustomChip background={'#D1C2B6'} labelColor={'#484848'} label={'Legal Entity'}/>
              }
            </Box>
            <Box display={'flex'} mr={1}>
              <CustomChip background={'#A1B6CA'} labelColor={'#FFF'} label={data?.folder_name}/>
            </Box>
            <Box display={'flex'} alignItems={'center'} mr={1}>
              <RiskRatingIcon
                risk={data?.risk_score}
                withTooltip={true}
                placement={'right'}
                className={classes.riskIcon}
              />
            </Box>
          </Box>
        </Box>
        {/* To be merged with nextStage later */}
        <Box display="flex" justifyContent="flex-end">
          <CheckRulebookVersion client={data} refreshClient={() => fetch(true)} />
        </Box>

        { !!nextStageShown &&
            <Box display={'flex'}>
              <Box display={'flex'} flexDirection={'column'}>
                <Box
                  display={'flex'}
                  justifyContent={'flex-end'}
                  style={{
                    fontWeight: '300',
                    fontSize: '10px',
                    lineHeight: '20px',
                    textAlign: 'right',
                    textTransform: 'uppercase',
                    color: '#232323'
                  }}
                >
                        Next Stage
                </Box>
                <Box
                  display={'flex'}
                  justifyContent={'flex-end'}
                  style={{
                    fontSize: '12px',
                    lineHeight: '20px',
                    textAlign: 'right',
                    color: '#7A5B44',
                    fontWeight: 'bold'
                  }}
                >
                        Periodic Reviews
                </Box>
              </Box>
              <Box
                display={'flex'}
                ml={2}
                style={{
                  position: 'relative'
                }}
              >
                <CircularProgress
                  variant="determinate"
                  classes={{root: classes.progressRoot}}
                  thickness={4}
                  size={48}
                  value={progress}
                />
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%'
                  }}
                >
                  <Box
                    display={'flex'}
                    style={{
                      fontWeight: 300,
                      fontSize: '8px',
                      lineHeight: '12px',
                      textAlign: 'center',
                      textTransform: 'uppercase',
                      color: '#232323'
                    }}>
                    { progressLabel }
                  </Box>
                  <Box
                    display={'flex'}
                    style={{
                      fontSize: '12px',
                      lineHeight: '16px',
                      textAlign: 'center',
                      color: '#B6514C',
                    }}>
                    { `${progress}%` }
                  </Box>
                </Box>
              </Box>
            </Box>
        }
      </Box>
      <Box display={'flex'} flexGrow={1} style={{overflow: 'hidden'}}>
        <SideMenuLayout
          menu={
            tabs.map(tabItem => (
              <Box key={tabItem.slug} className={clsx(
                sideMenuClasses.tab,
                (tab===tabItem.slug) && 'active',
                (tabItem.disabled) && 'disabled'
              )}
              onClick={() => {
                (tab !== tabItem.slug && !tabItem.disabled)
                                     && onSetTab(tabItem.slug)
              }}
              >
                <Box display={'flex'} flexGrow={1} alignItems={'center'}>
                  <Box display={'flex'} mr={1}>
                    {!tabItem.disabled && tabItem.icon && tabItem.icon}
                    {tabItem.disabled && tabItem.icon && tabItem.iconInactive}
                  </Box>
                  {tabItem.name}
                </Box>
                {tabItem.statusIcon && tabItem.statusIcon}
              </Box>
            ))
          }
          content={
            <React.Fragment>
              <Box display={'flex'} flexDirection={'column'} height={'100%'}>
                <Box display={'flex'} height={'100%'} mb={1} pl={4} pr={5} style={{overflowY: 'auto'}}>
                  {tabs.map(tabItem => (
                    tabItem.slug === tab &&
                      <Box key={tabItem.slug} width={'100%'} style={{ position: 'relative', height: '100%', marginRight: 20}}>
                        { tabItem.component
                          ? { ...tabItem.component, ...{ props: {
                            clientId, data, setData,
                            readonly, highlightSearchId,
                            baseUri: getUri(tabItem.slug),
                            onSetTab,
                            updateEidvStatus,
                            fetchClient: fetch,
                            title: tabItem.name,
                            displayLevel: tabItem.displayLevel,
                            folders,
                          }}}
                          : renderMock(tabItem)
                        }
                      </Box>
                  ))}
                </Box>
                {readonly && (
                  <Box width={'100%'}>
                    {/* Next button for ongoing monitoring client update */}
                    <Box display={'flex'} alignItems={'center'}>
                      {nextButton}
                    </Box>
                  </Box>
                )}
              </Box>
            </React.Fragment>
          }
        />
      </Box>
    </Box>
  )
}
import React, { useState, useEffect } from 'react';
import { Popover, IconButton, withTheme, makeStyles } from '@material-ui/core';
import {
  RateIcon, RateGoodIcon, RateWarningIcon, RateBadIcon, RateNotsetIconNew
} from '@app/icons';
import CustomTooltip from '@components/controls/tooltip';

export const useStyles = makeStyles((theme) => ({
  popover: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '12px',
  },
  showRate: {
    borderRadius: '8px',
    padding: '10px',
    margin: '0 5px',
  },
}));

const flags = [
  { type: 'Low', icon: <RateGoodIcon /> },
  { type: 'Medium', icon: <RateWarningIcon /> },
  { type: 'High', icon: <RateBadIcon /> },
];

const Rate = ({ changeRate, riskScore, disabled, tooltipTitle }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(false);
  const [flag, setFlag] = useState(<RateIcon />);
  const [flagsType, setFlagsType] = useState(flags);

  useEffect(() => {
    setFlag(!!disabled ? <RateNotsetIconNew /> : <RateIcon />);

    if (riskScore) {
      const riskFlag = flags.find(el => el.type === riskScore);
      setFlag(riskFlag.icon);
    } else {
      setFlagsType(flags);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskScore, disabled])

  const handleChangeFlag = (item) => {
    setAnchorEl(null);
    const filteredFlags = flags.filter(el => el.type !== item.type);
    setFlagsType(filteredFlags);
    setFlag(item.icon);

    changeRate && changeRate(item.type);
  }

  return (
    <React.Fragment>
      <CustomTooltip
        title={tooltipTitle}
        placement="top"
      >
        <span>
          <IconButton
            onClick={(event) => setAnchorEl(event ? event.currentTarget : null)}
            classes={{ root: classes.showRate }}
            disabled={disabled}
          >
            {flag}
          </IconButton>
        </span>
      </CustomTooltip>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 0,
        }}
        classes={{ paper: classes.popover}}
      >
        {flagsType
          .map(item => (
            <IconButton
              key={item.type}
              onClick={() => handleChangeFlag(item)}
            >
              {item.icon}
            </IconButton>
          ))
        }
      </Popover>
    </React.Fragment>   
  )
}

export default withTheme(Rate);

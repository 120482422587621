import React, {useMemo} from 'react';
import {
  Box,
  FormControlLabel,
  Grid, Link,
  Switch, Typography
} from '@material-ui/core';
import RadioButtonGroup from '@components/buttons/radio-button-group';
import DateRangePicker from '@components/calendar/date-range-picker';

const EMPTY_RANGE_VALUE = {from: null, to: null}

const SORT_ITEMS = {
  person: [
    { label: 'Name', value: 'name' },
    { label: 'Citizenship', value: 'citizenship' },
    { label: 'Residency', value: 'residency' },
    { label: 'Gender', value: 'gender' },
    { label: 'Date Added', value: 'formatted_created_at' },
    { label: 'Date of Next Review', value: 'next_review_at'}
  ],
  company: [
    { label: 'Name', value: 'name' },
    { label: 'Country', value: 'country' },
    { label: 'Formation Date', value: 'formed_at' },
    { label: 'Date Added', value: 'formatted_created_at' },
    { label: 'Date of Next Review', value: 'next_review_at' }
  ]
}

export default function PortfolioFilters({ sort, onSort, clientType, filters, linkFilterState }) {

  const nextReviewRange = useMemo(() =>
    filters.find(f => f.name === 'nextReview')?.value || EMPTY_RANGE_VALUE,
  [filters]
  )

  const onNextReviewClearRange = () => {
    linkFilterState({
      value: EMPTY_RANGE_VALUE
    }, 'nextReview')
  }

  const onNextReviewRangeChange = side => value => {
    const newNextReviewRange = {
      ...nextReviewRange,
      [side]: value
    }
    linkFilterState({
      value: newNextReviewRange
    }, 'nextReview')
  }

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Grid container spacing={2}>
        <Grid item xs={clientType === 'company' ? 3: 4}>
          <Box display={'flex'} pt={1} pb={1}>
            <Typography variant="h5">
              Sort By
            </Typography>
          </Box>
          <RadioButtonGroup
            items={SORT_ITEMS[clientType]}
            handleChange={e => onSort(e.target.value)}
            selectedValue={sort}
            formControlSize={40}
          />
        </Grid>
        {
          [
            {type: 'riskRating', groupLabel: 'Risk Rating'},
            {type: 'legalEntityType', groupLabel: 'Legal Entity Type'},
            {type: 'monitory', groupLabel: 'Monitoring'}
          ]
            .filter(({type}) => clientType === 'company' ||  (clientType === 'person' && type !== 'legalEntityType' ))
            .map((groupInfo, iGroupInfo) => (
              <Grid item xs={clientType === 'company' ? 3 : 4}>
                <Box display={'flex'} flexDirection={'column'}>
                  <Box display={'flex'} pt={1} pb={1}>
                    <Typography variant="h5">
                      { groupInfo.groupLabel }
                    </Typography>
                  </Box>
                  { filters.filter(el => el.name === groupInfo.type).map((el, i) => (
                    el.type === 'toggle' &&
                    <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value}-${el.name}-i`}>
                      <FormControlLabel
                        control={<Switch value={el.value} checked={el.isChecked} onChange={e => linkFilterState(e, el.name)} />}
                        label={el.label}
                      />
                    </Box>
                  ))}
                </Box>
              </Grid>
            ))
        }
      </Grid>
      <Box display={'flex'} justifyContent={'flex-start'}>
        <Box display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} pt={1} pb={1}>
            <Typography variant="h5">
              Filter By Next Review Date
            </Typography>
          </Box>
          <Box display={'flex'}>
            <DateRangePicker
              startDate={nextReviewRange.from}
              setStartDate={onNextReviewRangeChange('from')}
              endDate={nextReviewRange.to}
              setEndDate={onNextReviewRangeChange('to')}
            />
          </Box>
          <Box display={'flex'} pl={3}>
            <Link
              onClick={() => onNextReviewClearRange()}
              underline={'none'}
              variant={'body1'}
            >
              CLEAR DATE RANGE
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
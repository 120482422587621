import React from 'react';
import { Typography, Box, Grid } from '@material-ui/core';
import { getCountryName } from '@services/country-service'

import { useStyles } from '../styles';

export default function AdditionalDataEntities({ item }) {
  const classes = useStyles();

  return (
    <Grid container direction="row">
      <Grid item sm={3}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography className={classes.cardTagLabel}>Entity Type:</Typography>
          <Typography className={classes.cardTagValue}>{item.type || 'N/A'}</Typography>
        </Box>
      </Grid>
      <Grid item sm={3}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography className={classes.cardTagLabel}>Registration Number:</Typography>
          <Typography className={classes.cardTagValue}>{item.registrationNumber || 'N/A'}</Typography>
        </Box>
      </Grid>
      <Grid item sm={3}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography className={classes.cardTagLabel}>Registered Office Country:</Typography>
          <Typography className={classes.cardTagValue}>{item.registeredOfficeCountry ? getCountryName(item.registeredOfficeCountry) : 'N/A'}</Typography>
        </Box>
      </Grid>
    </Grid>
  )
}
const constants = {
  phoneMask: '+999999999999999',
  cardMask: '9999 9999 9999 9999',
  expDateMask: '99 / 99',
  securityCodeMask: '9999'
}

export const sidebarWidth = 310;

export default constants;

export const FIELD_TYPES = {
  INPUT: 'input',
  SELECT: 'select',
  DATE: 'date',
  COUNTRY: 'country',
  EMAIL: 'email',
  MASKED: 'masked',
  BOOLEAN: 'boolean',
  SEARCHABLE: 'selectSearchable',
  NUMBER: 'number',
}
const setDefault = token => ({
  type: 'OM_SET_DEFAULT'
});

const setSortAndFilters = ({ sort, filters, sortDirection }) => ({
  type: 'OM_SET_SORT_FILTERS',
  sort,
  filters,
  sortDirection
});

const setSelectedIds = (selectedIds) => ({
  type: 'OM_SET_SELECTED_IDS',
  selectedIds,
});

const setSearchSettings = (searchSettings) => ({
  type: 'OM_SET_SEARCH_SETTINGS',
  searchSettings,
});

const ongoingMonittoring = {
  setDefault,
  setSortAndFilters,
  setSelectedIds,
  setSearchSettings
}

export default ongoingMonittoring;

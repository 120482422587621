import React, { useState } from 'react';
import { Box } from '@material-ui/core';

import ElectronicVerificationView from '@components/complex/client-view/electronic-verification/view';
import InnerHeader from '@components/layout/inner-header';

const EidvView = () => {
  const [selectedCaseId, setSelectedCaseId] = useState(null);

  return (
    <Box mx={6} height={'100%'}>
      <InnerHeader
        justifyTitle="flex-start"
        title="Electronic Identity Verification"
      />
      <Box display="flex" flexDirection="column" height="calc(100% - 98px - 8px)" maxHeight="calc(100% - 98px - 8px)" mb={1}>
        <ElectronicVerificationView
          path="/electronic-verification"
          selectedCaseId={selectedCaseId}
          setSelectedCaseId={setSelectedCaseId}
          noClient
          redirectOnComplete
        />
      </Box>
    </Box>
  );
}

export default EidvView;
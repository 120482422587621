import React from 'react';
import { SidesLayout, SidesLayoutRight } from '../../components/layout/sides-layout';
import {
  Box,
  Typography,
  Grid,
  Button,
  Link
} from '@material-ui/core';

import history from '@app/history';
import AuthHeader from './auth-header';

export default function AlreadyActivated(props) {
  return (
    <SidesLayout>
      <SidesLayoutRight
        header={<AuthHeader />}
        footer={
          <Box display={'flex'} justifyContent={'center'}>
            <Box display={'flex'}>
              <Typography variant={'body1'} align={'center'}>
                {'If you need any help at all, don’t hesitate to '}
                <Link variant={'body1'} target="_blank" href="mailto:support@kycpassport.com">
                  Contact Us
                </Link>
                {'. Humans will reply!'}
              </Typography>
            </Box>
          </Box>
        }
      >

        <Grid container justify={'center'} alignItems={'center'}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Box mb={3}>
                <Typography variant={'h1'} align={'center'}>The email was already activated!</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display={'flex'} justifyContent={'center'} mt={6}>
                <Button
                  onClick={() => history.push('/login')}
                  variant="contained"
                  size="large"
                >
                  LOGIN
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>

      </SidesLayoutRight>
    </SidesLayout>
  );
}
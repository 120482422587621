const setDefault = () => ({
  type: 'SET_CALENDAR_SCHEDULE_DEFAULT',
});

const setSort = (sort) => ({
  type: 'SET_SORT',
  sort,
});

const setDocumentFilter = (documentFilter) => ({
  type: 'SET_DOCUMENT_FILTER',
  documentFilter,
});

const setFilters = (filters) => ({
  type: 'SET_FILTERS',
  filters,
});

const setDateInterval = (dateInterval) => ({
  type: 'SET_DATE_INTERVAL',
  dateInterval,
});

const setPaginationPage = (page) => ({
  type: 'SET_PAGINATION_PAGE',
  page,
});

const calendarSchedule = {
  setDefault,
  setSort,
  setFilters,
  setDateInterval,
  setDocumentFilter,
  setPaginationPage,
};

export default calendarSchedule;

import Login from '@views/auth/login';
import Registration from '@views/auth/registration';
import RegistrationComingSoon from '@views/auth/registration-coming-soon';
import PasswordReset from '@views/auth/password-reset';
import ForgotPassword from '@views/auth/forgot-password';
import ActivationSent from '@views/auth/activation-sent';
import EmailVerified from '@views/auth/email-verified';
import ResetPassSent from '@views/auth/reset-password-sent';
import ResetPassSuccess from '@views/auth/reset-password-success';
import ActivationFailed from '@views/auth/activation-failed';
import AlreadyActivated from '@views/auth/already-activated';

const routes = [
  {
    path: '/login',
    component: Login,
    guest: true
  },
  {
    path: '/registration',
    component: RegistrationComingSoon,
    guest: true
  },
  {
    path: '/signup',
    component: Registration,
    guest: true
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    guest: true
  },
  {
    path: '/password-reset',
    component: PasswordReset,
    guest: true
  },
  {
    path: '/instructions-sent',
    component: ActivationSent,
    guest: true
  },
  {
    path: '/email-verified',
    component: EmailVerified,
    guest: true
  },
  {
    path: '/reset-pass-sent',
    component: ResetPassSent,
    guest: true
  },
  {
    path: '/reset-pass-success',
    component: ResetPassSuccess,
    guest: true
  },
  {
    path: '/activation-failed',
    component: ActivationFailed,
    guest: true
  },
  {
    path: '/already-activated',
    component: AlreadyActivated,
    guest: true
  },
];

export default routes;
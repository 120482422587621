export const SORTABLE_COLUMNS = [
  'documentName',
  'uploadDate',
  'issueDate',
  'expiryDate',
  'statusLabel',
];

export const FILTER_DEFAULTS = { type: 'date-range', value: {from: null, to: null}, label: 'Filter By Expiration Date', name: 'expiryDate'}

export const PAGE_SIZE = 12;

export const IMAGES_EXTENSION = ['jpg', 'jpeg', 'png', 'bmp'];

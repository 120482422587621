import React, { useState } from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import LabelsForRender from '@components/layout/labels-for-render';
import { eidv } from '@app/api/client';
import CustomChip from '@components/controls/custom-chip';

import EVTitle from '../components/eidv-title';
import BasicDetailsDialog from './basic-details-dialog';
import Value from '../components/label-value';
import EVPendingButtons from '../components/eidv-pending';
import EVError from '../components/ediv-error';
import casesDto, { manualApproveDto } from '../dto';

export default function EVBasicDetails({ data, fetchData, handleRepeat, readonly }) {
  const { enqueueSnackbar } = useSnackbar();
  const [edit, setEdit] = useState('')
  const details = data.basicDetails || {};
  const pending = data.status === 'pending approval' && details.status === 'pending approval';

  const personalDetailsLeft = [
    { field: 'name', notText: true, value: <Value data={details.firstName} /> },
    { field: 'gender', notText: true, value: <Value data={details.gender} /> },
    { field: 'personal number', notText: true, value: <Value data={details.personalNumber} /> },
    { field: 'document id', notText: true, value: <Value data={details.documentId} /> },
    { field: 'valid until', notText: true, value: <Value data={details.validUntil} /> },
  ]

  const personalDetailsRight = [
    { field: 'last name', notText: true, value: <Value data={details.lastName} /> },
    { field: 'date of birth', notText: true, value: <Value data={details.dob} /> },
    { field: 'nationality', notText: true, value: <Value data={details.nationality} /> },
    { field: 'country', notText: true, value: <Value data={details.issuingCountry} /> },
    { field: 'document issuing date', notText: true, value: <Value data={details.documentIssuingDate} /> },
  ]

  const addressDetailsLeft = [
    { field: 'address line 1', notText: true, value: <Value data={details.addressLine1} /> },
    { field: 'city', notText: true, value: <Value data={details.city} /> },
    { field: 'postal code', notText: true, value: <Value data={details.postalCode} /> },
  ]

  const addressDetailsRight = [
    { field: 'address line 2', notText: true, value: <Value data={details.addressLine2} /> },
    { field: 'region/state /province', notText: true, value: <Value data={details.region} /> },
    { field: 'country', notText: true, value: <Value data={details.country} /> },
  ]

  const isError = (
    details.firstName?.edited
    || details.lastName?.edited
    || details.gender?.edited
    || details.dob?.edited
    || details.personalNumber?.edited
    || details.nationality?.edited
    || details.documentId?.edited
    || details.issuingCountry?.edited
  )

  const handleSave = (formData) => {
    if (edit === 'address') {
      eidv.updateAddressInformationUserData(casesDto.writeAddressInfo(formData), data.id).then(() => {
        enqueueSnackbar('eIDV Address details were successfully updated!', { variant: 'success' });
        setEdit('');
        fetchData();
      })
    } else {
      eidv.updateAuthenticityVerificationUserData(casesDto.writePersonalDetail(formData), data.id).then(() => {
        enqueueSnackbar('eIDV Personal details were successfully updated!', { variant: 'success' });
        setEdit('');
        fetchData();
      })
    }
  }

  const onManualApprove = () => {
    const basicData = manualApproveDto.basicDetails(data);
    eidv.updateAuthenticityVerificationUserData(basicData, data?.id).then(() => {
      enqueueSnackbar('eIDV Basic Details were successfully approved!', { variant: 'success' })
      fetchData();
    })
  }

  return (
    <React.Fragment>
      <Box mb={3}>
        <BasicDetailsDialog
          open={!!edit}
          type={edit}
          onSave={handleSave}
          onClose={() => setEdit('')}
          details={details}
        />

        <EVTitle status={details.status}>Basic Details</EVTitle>
        <Box mt={4}>
          <Box mb={2}>
            <Typography variant="h5">Personal details</Typography>
          </Box>
          {Object.keys(details).length > 1 &&
            <Box mb={2}>
              <CustomChip
                label={
                  details.updatedByKycUser ? 'Updated by KYC user'
                    : !isError ? 'Extracted from document'
                      : 'Updated by client'}
                background="#ECF0ED"
                labelColor="#656565"
                border="1px solid #CAD7CA"
              />
            </Box>
          }
          <Box display="flex">
            <Box width={280} pr={3}>
              <LabelsForRender labels={personalDetailsLeft} boxProps={{ mb: 1 }} />
            </Box>
            <Box width={280} pr={3}>
              <LabelsForRender labels={personalDetailsRight} boxProps={{ mb: 1 }} />
            </Box>
          </Box>
          {(pending && !readonly) &&
            <Box>
              <Link onClick={() => setEdit('personal')} style={{ fontSize: 12 }}>Edit</Link>
            </Box>
          }
        </Box>

        {data?.requestedSteps?.steps.includes('ADDRESS') &&
          <Box mt={3}>
            <Box mb={2}>
              <Typography variant="h5">Address details</Typography>
            </Box>
            {Object.keys(data?.addressInfo || []).length > 1 &&
              <Box mb={2}>
                <CustomChip
                  label={
                    details.addressUpdatedByKycUser ? 'Updated by KYC user'
                      : details.addressIsFromDocument ? 'Extracted from document'
                        : 'Updated by client'}
                  background="#ECF0ED"
                  labelColor="#656565"
                  border="1px solid #CAD7CA"
                />
              </Box>
            }
            <Box display="flex">
              <Box width={280} pr={3}>
                <LabelsForRender labels={addressDetailsLeft} boxProps={{ mb: 1 }} />
              </Box>
              <Box width={280} pr={3}>
                <LabelsForRender labels={addressDetailsRight} boxProps={{ mb: 1 }} />
              </Box>
            </Box>
            {(pending && !readonly) &&
              <Box>
                <Link onClick={() => setEdit('address')} style={{ fontSize: 12 }}>Edit</Link>
              </Box>
            }
          </Box>
        }

        {(pending && isError) &&
          <Box mt={2}>
            <EVError message="The client edited manually the field(s) marked with ! icon." centerVertically />
          </Box>
        }
      </Box>

      {(pending && !readonly) &&
        <EVPendingButtons onRepeat={handleRepeat} onApprove={onManualApprove} />
      }
    </React.Fragment>
  )
}
import {FormControl, IconButton, InputAdornment, TextField} from '@material-ui/core';
import React from 'react';
import {SearchOtherIcon} from '@app/icons';
import {makeStyles} from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {

  }
}));

export default function OutlinedSearch({fullWidth = true, ...props}) {
  const { loader } = useSelector(store => store.loader);

  const classes = useStyles();

  const handleKeyDown = (e) => {
    if (!!e.repeat) return;
    if (loader) return;

    if (e.key === 'Enter' && props.onEnter) {
      props.onEnter();
    }
  }

  return (
    <FormControl className={classes.root} fullWidth={fullWidth} variant="outlined">
      <TextField
        variant={'outlined'}
        value={props.value || ''}
        onKeyDown={handleKeyDown}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        onChange={props.onChange}
        placeholder={props.label || 'Search people and legal entities'}
        size={props.size}
        error={props.error}
        InputProps={{
          startAdornment:
                        <InputAdornment position="start">
                          <IconButton
                            onClick={props.onSearch}
                            edge="start"
                          >
                            {props.icons || <SearchOtherIcon width={20} />}
                          </IconButton>
                        </InputAdornment>,
          endAdornment: props.endAdornment
        }}
      />
    </FormControl>
  )
}
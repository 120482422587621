import React, { useState } from 'react';
import PageLayout from '@components/layout/page-layout';
import { Box, Button, OutlinedInput, InputLabel, FormControl, FormLabel } from '@material-ui/core';
import InnerHeader from '@components/layout/inner-header';
import { includes } from 'lodash';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formLabel: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.text.BL1,
    textTransform: 'capitalize',
  }
}))

const alphabets = ['1','2','3','4','5','6','7','8','9','0', 'Backspace', 'Delete'];

const tabs = [
  {
    id: 'general-settings',
    label: 'General Settings',
  },
]

const formFieldsSearch = [
  {
    key: 'numberOfSearch',
    label: 'Number of search attempts for non-logged users',
    group: 'search',
  },
  {
    key: 'numberOfProfiles',
    label: 'Number of profiles per search for non-logged users',
    group: 'search',
  },
  {
    key: 'numberOfSources',
    label: 'Number of sources per profile for non-logged users',
    group: 'search',
  },
]

const formFieldsOther = [
  {
    key: 'payment',
    label: 'Invoice Grace Period (days)',
    group: 'payment',
  },
  {
    key: 'session',
    label: 'User session timeout (minutes)',
    group: 'session',
  },
]

const defaultModel = {
  numberOfSearch: '',
  numberOfProfiles: '',
  numberOfSources: '',
  payment: '',
  session: '',
}

export default function Index(props) {
  const classes = useStyles();

  const [tabID, setTabID] = useState('general-settings');
  const [model, setModel] = useState(defaultModel);

  const onTabChange = (index) => {
    const tabID = tabs[index].id;
    setTabID(tabID);
  }

  const handleChange = (prop) => (event) => {
    const newModel = { ...model, [prop]: event.target.value };
    setModel(newModel);
  };

  const handleNumberInputs = (e) => {
    if (!includes(alphabets, e.key)) {
      e.preventDefault();
    } 
  }

  const renderForm = forms => (
    forms.map((field, i) => (
      <React.Fragment>
        {(i === 0 || field.group !== 'search') &&
          <Box mt={3}>
            <FormLabel classes={{ root: classes.formLabel }}>{field.group}</FormLabel>
          </Box>
        }
        <Box mt={1}>
          <FormControl
            key={field.key}
            fullWidth
            variant="outlined"
            id={field.key}
          >
            <InputLabel htmlFor="name" >{field.label}</InputLabel>
            <OutlinedInput
              value={model[field.key]}
              type="number"
              min={0}
              onChange={handleChange(field.key)}
              onKeyDown={handleNumberInputs}
            />
          </FormControl>
        </Box>
      </React.Fragment>
    ))
  )

  return (
    <PageLayout>
      <Box px={6} >
        <InnerHeader
          onTabChange={onTabChange}
          ind={tabs.findIndex(tab => tab.id === tabID)}
          tabs={tabs}
        />
      </Box>
      <Box mx={12} display="flex" flexDirection="column" justifyContent="space-between" height="100%">
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box display="flex" flexDirection="column" width="100%" mr={4}>
            {renderForm(formFieldsSearch)}
          </Box>
          <Box display="flex" flexDirection="column" width="100%" ml={4} justifyContent="space-between">
            {renderForm(formFieldsOther)}
          </Box>
        </Box>

        <Box py={6} display="flex" justifyContent="flex-end" width="100%">
          <Button
            style={{ width: '180px', marginRight: '10px', borderColor: 'white' }}
            variant={'outlined'}
            onClick={() => setModel(defaultModel)}
          >
              CANCEL
          </Button>
          <Button style={{ width: '180px', marginLeft: '10px' }} variant={'contained'} onClick={() => {}}>
              SAVE
          </Button>
        </Box>
      </Box>
    </PageLayout>
  )
}
import React from 'react';
import InlineBadge from '@components/badges/inline-badge';
import { BADGE_COLOR } from '../../constants';

export const typeComponent = (item, styleBadgeRoot) => {
  return (
    <InlineBadge
      color={BADGE_COLOR[item.type]?.background}
      textColor={BADGE_COLOR[item.type]?.color}
      styleBadgeRoot={styleBadgeRoot}
    >
      {item.type}
    </InlineBadge>
  );
};

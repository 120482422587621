import React from 'react';
import { Box, Dialog, IconButton, makeStyles, Typography } from '@material-ui/core';
import {
  CloseIcon
} from '@app/icons';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    padding: 32,
  },
  fullScreen: {
    padding: 32,
    height: 'calc(100% - 64px)'
  }
}));

export default function FileViewerWrapper({ open, onClose, title, subtitle, fullScreen, children }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.dialogPaper,
        paperFullScreen: classes.fullScreen,
      }}
      maxWidth={false}
      onClose={onClose}
      fullScreen={fullScreen}
    >
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Box>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="body1" style={{ color: '#484848' }}>{subtitle}</Typography>
        </Box>
        <IconButton onClick={onClose} style={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box>
        {children}
      </Box>
    </Dialog>
  )
}

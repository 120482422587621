import React from 'react';
import { Box } from '@material-ui/core';
import moment from 'moment';

import { WarningIcon, ErrorIcon } from '@app/icons';
import CustomTooltip from '@components/controls/tooltip';

export default function ExpiryDateLabel(expiryDate, status) {
  const formattedExpDate = moment(expiryDate).format('MM/DD/YYYY');

  return status === 'EXPIRED' ? (
    <CustomTooltip width={120} title="Document has expired">
      <Box display={'flex'} alignItems={'center'} style={{ color: '#B6514C' }}>
        <Box display={'flex'}>{formattedExpDate}</Box>
        <ErrorIcon />
      </Box>
    </CustomTooltip>
  ) : status === 'EXPIRES_SOON' ? (
    <CustomTooltip title="Document is about to expire">
      <Box display={'flex'} alignItems={'center'} style={{ color: '#A98320' }}>
        <Box display={'flex'}>{formattedExpDate}</Box>
        <WarningIcon />
      </Box>
    </CustomTooltip>
  ) : (
    formattedExpDate
  );
}

import React from 'react';
import Header from '../header';
import PageDrawer from './page-drawer';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';

export default function PageLayout(props) {
  const classes = useStyles();

  return (
    <div className="page-layout">
      <Header isMenu={true} isSearch={false} headerPosition="static" />
      <Box display={'flex'} className={classes.pageLayout}>
        <Box display={'flex'} className={classes.leftSidebar}>
          <PageDrawer />
        </Box>
        <Box display={'flex'} className={classes.pageContent}>
          {props.children}
        </Box>
      </Box>
    </div>
  );
}

export function withPageLayout(Children) {
  return function (props) {
    return (
      <PageLayout>
        <Children {...props} />
      </PageLayout>
    )
  }
}
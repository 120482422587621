import React, { useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Grid,
  Typography
} from '@material-ui/core';
import LinkButton from '@components/buttons/link-button';
import { FIELD_TYPES } from '@app/constants';
import parseYupErrors, { convertFieldsToYupObject } from '@app/yup';
import RenderInputs from '@components/layout/render-inputs';
import { Modal } from '@components/modals';
import { inputFieldsMapDto, parseModel, prepareDataForUpdate, getClientInputFieldsMap, prepareFieldsForTable } from '@dto/client';
import { clientApi } from '@app/api';
import { useSnackbar } from 'notistack';
import { LegalEntitySubtypeEnum } from '@services/client-type';
import DuplicateClientDialog, { useFindDuplicateClient } from '@components/modals/find-duplicate-client';

const JURISDICTION_FIELDS = [
  { key: 'registeredOfficeCountry', label: 'Jurisdiction', type: FIELD_TYPES.COUNTRY, required: true, isDefault: true, },
  { key: 'type', label: 'Entity Type', type: FIELD_TYPES.SELECT, required: true, isDefault: true,
    options: LegalEntitySubtypeEnum,
  },
]

const RESIDENCY_FIELDS = [
  { key: 'residency', label: 'Residency', type: FIELD_TYPES.COUNTRY, required: true, isDefault: true, },
]

function ChangeCountryDialog(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { open, setOpen, data, fetchClient, type } = props
  const [model, setModel] = useState({ })
  const [schema, setSchema] = useState(null)
  const [errors, setErrors] = useState({})
  

  useEffect(() => {
    const fields = type === 'person' ? RESIDENCY_FIELDS : JURISDICTION_FIELDS;

    const model = {}
    fields.forEach((field) => {
      model[field.key] = data.raw[field.key]
    })

    setModel(model)
    setSchema(convertFieldsToYupObject(fields))
    setErrors({})
  }, [data, type])

  const handleChange = (key) => (event) => {
    const newModel = {
      ...model,
      [key]: event.target.value
    }
    setModel(newModel)

    if (errors[key]) {
      schema.validateAt(key, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [key]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [key]: err.message });
        });
    }
  }

  const onSave = async () => {
    schema.validate(model, { abortEarly: false })
      .then(async () => {
        const preparedData = prepareDataForUpdate(data, model)
        if (type === 'person') {
          await clientApi.person.update(data.id, preparedData)
          enqueueSnackbar(`Person "${preparedData.firstName} ${preparedData.lastName}" was successfully updated!`, { variant: 'success' });
        } else {
          await clientApi.company.update(data.id, preparedData)
          enqueueSnackbar(`Legal entity "${preparedData.name}" was successfully updated!`, { variant: 'success' });
        }
        fetchClient();
        setOpen(false);
      })
      .catch((err) => {
        setErrors(parseYupErrors(err))
      })
  }

  const fields = type === 'person' ? RESIDENCY_FIELDS : JURISDICTION_FIELDS;
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      title={`Change ${type === 'person' ? 'Residency' : 'Jurisdiction'}`}
      actions={[
        {
          type: 'main',
          label: 'Cancel',
          action: () => setOpen(false),
          style: { width: 150 },
        },
        {
          type: 'secondary',
          label: 'Apply',
          action: onSave,
          style: { width: 150 },
        }
      ]}
      actionsDirection="row"
      content={(
        <Box width={400} pb={4}>
          <Box py={1}>
            {fields.map((field) => (
              <RenderInputs
                key={field.key}
                field={field}
                model={model}
                handleChange={handleChange}
                errors={errors}
              />
            ))}
          </Box>
        </Box>
      )}
    />
  )
}

export default function WriteMode(props) {
  const { changeMode, data, title, displayLevel, fetchClient, folders } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { duplicateDialogProps, findDuplicatePerson, findDuplicateCompany } = useFindDuplicateClient();
  const [model, setModel] = useState({})
  const [schema, setSchema] = useState(null)
  const [errors, setErrors] = useState({})
  const [hasWarning, setHasWarning] = useState(false);
  const [countryDialogOpen, setCountryDialogOpen] = useState(false)

  const type = data?.client_type.toLowerCase()

  const fields = useMemo(() => {
    const inputFieldsMap = getClientInputFieldsMap(data)
    const fields = inputFieldsMapDto(inputFieldsMap, data?.client_type, false, folders)[displayLevel]
    return fields;
  }, [data, displayLevel, folders])

  useEffect(() => {
    setModel(parseModel(data, displayLevel, folders))
    setSchema(convertFieldsToYupObject(fields))
    setErrors({})
  }, [fields, data, displayLevel, folders])

  const handleChange = (key) => (event) => {
    const field = fields.find(e => e.key === key)
    if (field?.isRulebookCountry) {
      return setCountryDialogOpen(true)
    }

    const newModel = {
      ...model,
      [key]: event.target.value
    }
    setModel(newModel)

    if (errors[key]) {
      schema.validateAt(key, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [key]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [key]: err.message });
        });
    }
  }

  const onSave = async (suppressWarning, skipDuplicate) => {
    let hasError = false
    let hasWarning = false

    try {
      await schema.validate(model, { abortEarly: false })
    } catch (err) {
      const errors = parseYupErrors(err)
      setErrors(errors)
      for (const errorKey in errors) {
        const field = fields.find(e => e.key === errorKey)
        if (field?.useWarning) {
          hasWarning = true;
        } else {
          hasError = true;
        }
      }
    }

    if (hasError) return;
    if (hasWarning && !suppressWarning) return setHasWarning(true);
    try {
      const preparedData = prepareDataForUpdate(data, model)

      if (!skipDuplicate && displayLevel === 'basicDetails') {
        const hasDuplicate = type === 'person' ?
          await findDuplicatePerson({
            id: data.id,
            residency: preparedData.residency,
            firstName: preparedData.firstName,
            lastName: preparedData.lastName,
            dob: preparedData.dob,
            countryOfBirth: preparedData.countryOfBirth,
          })
          :
          await findDuplicateCompany({
            id: data.id,
            legalEntityType: preparedData.type,
            name: preparedData.name,
            registeredOfficeCountry: preparedData.registeredOfficeCountry,
            registrationNumber: preparedData.typeSpecificProperties.propertyMap.registrationNumber?.value,
          })
  
        if (hasDuplicate) return;
      }

      if (type === 'person') {
        await clientApi.person.update(data.id, preparedData)
        enqueueSnackbar(`Person "${preparedData.firstName} ${preparedData.lastName}" was successfully updated!`, { variant: 'success' });
      } else {
        await clientApi.company.update(data.id, preparedData)
        enqueueSnackbar(`Legal entity "${preparedData.name}" was successfully updated!`, { variant: 'success' });
      }

      if (model.folderId !== data.folderId) {
        await clientApi.folder.addClient(model.folderId, data.id)
      }

      fetchClient();
      changeMode();
    } catch (err) {
      console.log({ err })
    }
  }

  const hasError = Object.values(errors).some(e => !!e)

  return (
    <Grid container spacing={3} item xs={12}>
      <DuplicateClientDialog
        {...duplicateDialogProps}
        type={type === 'person' ? 'person' : 'legal entity'}
        onProceed={() => onSave(true, true)}
        isEdit
      />

      <ChangeCountryDialog
        open={countryDialogOpen}
        setOpen={setCountryDialogOpen}
        field={{ key: 'residency', label: 'Residency', type: FIELD_TYPES.COUNTRY, required: true, isDefault: true }}
        type={type}
        {...props}
      />

      <Grid item xs={12}>
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant={'h5'} style={{ lineHeight: '24px' }}>
            {title}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <table style={{ width: '100%' }}>
          <tbody>
            {(fields.length && Object.keys(model).length) &&
              prepareFieldsForTable(fields).map((row, index, array) => (
                <tr key={index}>
                  {row.left && // Can be null
                    <td valign="top" style={{ width: '45%' }} rowSpan={row.left.multiline ? 2 : 1}>
                      <RenderInputs
                        field={row.left}
                        model={model}
                        handleChange={handleChange}
                        errors={errors}
                        boxProps={{ pb: '12px' }}
                        outsideLabel
                        tabIndex="1"
                      />
                    </td>
                  }
                  <td style={{ width: '10%' }}></td>
                  {row.right &&
                    <td valign="top" style={{ width: '45%' }} rowSpan={row.right.multiline ? 2 : 1}>
                      <RenderInputs
                        field={row.right}
                        model={model}
                        handleChange={handleChange}
                        errors={errors}
                        boxProps={{ pb: '12px' }}
                        outsideLabel
                        tabIndex="2"
                      />
                    </td>
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
      </Grid>

      {(hasError && hasWarning) &&
        <Grid item xs={12} style={{ paddingTop: 12 }}>
          <Typography align="right">Fields highlighted in orange are required, but you can complete them later.</Typography>
          <Typography align="right">By clicking on Save button, this client will be saved but will be marked as incomplete.</Typography>
        </Grid>
      }
      <Grid item xs={12} container justify="flex-end" style={{ paddingTop: 12 }}>
        <LinkButton style={{ width: '208px', marginRight: 24 }} onClick={changeMode}>
          Cancel
        </LinkButton>
        <Button style={{ width: '208px' }} variant="contained" onClick={() => onSave(hasWarning)}>
          Save
        </Button>
      </Grid>
    </Grid>
  )
}

import React from 'react';
import {SidesLayout, SidesLayoutRight} from '../../components/layout/sides-layout';
import {
  Box, Button, Typography, Link
} from '@material-ui/core';
import history from '../../app/history';
import CheckedOption from './checked-option';
import AuthHeader from './auth-header';

const options = [
  'Onboard your clients',
  'Keep an eye on sanctions lists',
  'Risk score management',
  'Client portfolio management'
]

export default function EmailVerified(props) {
  return (
    <SidesLayout>
      <SidesLayoutRight
        header={<AuthHeader />}
        footer={
          <Box display={'flex'} justifyContent={'center'}>
            <Box display={'flex'}>
              <Typography variant={'body1'} align={'center'}>
                {'If you need any help at all, don’t hesitate to '}
                <Link variant={'body1'} target="_blank" href="mailto:support@kycpassport.com">
                                    Contact Us
                </Link>
                {'. Humans will reply!'}
              </Typography>
            </Box>
          </Box>
        }
      >

        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} width={'100%'} alignItems={'center'}>
          <Box display={'flex'} justifyContent={'center'}>
            <Box display={'flex'} px={1}>
              <Typography variant={'h1'} align={'center'}>
                                Activation successful!
              </Typography>
            </Box>
          </Box>

          <Box mt={5} display={'flex'} justifyContent={'center'}>
            <Box display={'flex'}>
              <Typography variant={'subtitle1'}>
                                Welcome to KYC Passport!
              </Typography>
            </Box>
          </Box>

          <Box mt={2} display={'flex'} justifyContent={'center'}>
            <Box display={'flex'} flexDirection={'column'}>
              {
                options.map( text => {
                  return (
                    <Box display={'flex'} key={text}>
                      <CheckedOption>
                        <Typography variant={'h5'}>
                          {text}
                        </Typography>
                      </CheckedOption>
                    </Box>
                  )
                })
              }
            </Box>
          </Box>


          <Box mt={5} display={'flex'} justifyContent={'center'}>
            <Box display={'flex'}>
              <Button onClick={() => {history.push('/login')}} variant="contained" size="large">GET STARTED</Button>
            </Box>
          </Box>
        </Box>

      </SidesLayoutRight>
    </SidesLayout>
  );
}
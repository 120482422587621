import React, { useMemo, useState } from 'react';
import {
  Box, Button, FormControl, InputLabel,
  Link, OutlinedInput, Typography, makeStyles
} from '@material-ui/core';
import history from '@app/history';
import TableList from '@components/lists/table-list';
import PageLayout from '@components/layout/page-layout';
import InnerHeader from '@components/layout/inner-header';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import { ChevronLeftIcon } from '@app/icons';
import InnerFooter from '@components/layout/inner-footer';
import { useSnackbar } from 'notistack';
import { Modal } from '@components/modals';
import BillingCard from '../billing-details/billing-card';

const INVOICES = [
  {
    id: 1,
    date: '04/29/2021',
    billable_item: 'Subscription Plan',
    details: 'Pro (Yealy Payment 12/...',
    total_amount: 180.15,
    formatted_amount: '$180.15',
    status: 'Unpaid',
  },
  {
    id: 2,
    date: '04/30/2021',
    billable_item: 'Search Pack',
    details: '+100 Pack',
    total_amount: 130.00,
    formatted_amount: '$130.00',
    status: 'Unpaid',
  },
  {
    id: 3,
    date: '04/31/2021',
    billable_item: 'Search Pack',
    details: '+100 Pack',
    total_amount: 184.15,
    formatted_amount: '$184.15',
    status: 'Unpaid',
  },
]

const billingMethods = [
  {
    id: 1,
    clientName: 'John Smith',
    isPrimary: true,
    cardType: 'visa',
    number: '5698',
    expired: '12/20',
    fullName: 'John Smith',
    address: '235 W Santa Clara St, Ventura, CA 93001',
  },
  {
    id: 2,
    clientName: 'John Smith',
    isPrimary: false,
    cardType: 'visa',
    number: '5697',
    expired: '12/20',
    fullName: 'John Smith',
    address: '235 W Santa Clara St, Ventura, CA 93001',
  }
]

const INVOICE_COLUMNS = [
  { field: 'date', headerName: 'Date' },
  { field: 'billable_item', headerName: 'Billable Item', bold: true },
  { field: 'details', headerName: 'Details' },
  { field: 'formatted_amount', headerName: 'Amount', colProps: { align: 'right' }, headerProps: { align: 'right' } },
]

const useStyles = makeStyles((theme) => ({
  clearCodeBtn: {
    width: 48,
    minWidth: 48,
    height: 48,
    padding: 0,
    background: '#F8F8F8 !important',
    backgroundColor: '#F8F8F8 !important',
    color: 'black',
    '&:hover': {
      background: '#ededed !important',
    }
  },
  tableCell: {
    padding: '15px 0px 20px',
    borderBottom: '2px solid #EFEFEF',
  },
}));

export default function PayPayments(props) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

    const [items, setItems] = useState([ ...INVOICES ]); // eslint-disable-line
  const [promoCode, setPromoCode] = useState('');
  const [disableCode, setDisableCode] = useState(false);
  const [sort, setSort] = useState('date');
  const [sortDirection, setSortDirection] = useState('ASC');
  const [pendingDelete, setPendingDelete] = useState(null);

  const onSort = (value) => {

    if(sort !== value) {
      setSort(value)
      setSortDirection('ASC')
    } else {
      setSortDirection(sortDirection === 'ASC'? 'DESC': 'ASC')
    }
  }

  const handlePendingDelete = billing => () => {
    setPendingDelete({ lastDigits: billing.number, id: billing.id });
  }

  const handleDelete = () => {

  }

  const applyCode = () => {
    enqueueSnackbar('Promo code applied!', { variant: 'success' });
    setDisableCode(true);
  }

  const onPay = () => {
    enqueueSnackbar('Payment success!', { variant: 'success' });
    history.push('/my-profile/payments');
  }

  const sortedItems = useMemo(() => {
    return items.sort((a, b) => {
      if (a[sort] > b[sort]) {
        return sortDirection === 'ASC' ? 1 : -1;
      }
      if (a[sort] < b[sort]) {
        return sortDirection === 'ASC' ? -1 : 1;
      }

      return 0;
    })
  }, [items, sort, sortDirection])

  return (
    <PageLayout>
      <Modal
        open={!!pendingDelete?.id}
        onClose={() => setPendingDelete(null)}
        title="Delete Billing Method"
        mainText={`Are you sure you want to delete the card "${pendingDelete?.lastDigits}"?`}
        actions={
          [
            {
              type: 'main',
              label: 'NO',
              action: () => setPendingDelete(null),
              style: { width: 160 },
            },
            {
              type: 'secondary',
              label: 'YES',
              action: handleDelete,
              style: { width: 160 },
            },
          ]
        }
        actionsDirection="row"
      />
      <Box px={6}>
        <InnerHeader
          title="Payments"
          buttons={
            <React.Fragment>
              <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={() => { history.goBack() }}>
                                Back
              </ButtonWithIcon>
            </React.Fragment>
          }
        />
      </Box>
      <Box pt={4} px={6} py={2} maxHeight="calc(100vh - 65px - 100px - 105px)" style={{ overflowY: 'auto' }}>
        <Box mb={3}>
          <Typography align="left" variant="subtitle2" style={{ color: '#656565' }}>Pay selected invoices with a credit card</Typography>
        </Box>
        <TableList
          columns={INVOICE_COLUMNS}
          items={sortedItems}
          sortableColumns={['date', 'billable_item', 'details', 'formatted_amount']}
          onSort={onSort}
          sort={sort}
          sortDirection={sortDirection}
        />
        <Box width="100%" display="flex" justifyContent="flex-end">
          <FormControl variant="outlined" style={{ width: 425 }}>
            <InputLabel>Promo Code</InputLabel>
            <OutlinedInput
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              style={{ paddingRight: 4 }}
              disabled={disableCode}
              endAdornment={!disableCode
                ? <Button
                  variant="contained" size="medium"
                  disableElevation style={{ width: 128, minWidth: 128 }}
                  onClick={applyCode}
                >
                                    APPLY
                </Button>
                : <Button
                  variant="contained" size="medium"
                  disableElevation
                  className={classes.clearCodeBtn}
                  onClick={() => setDisableCode(false)}
                >
                                    X
                </Button>
              }
            />
          </FormControl>
        </Box>
        <Box mt={1} width="100%">
          <table width="100%">
            <tbody>
              <tr>
                <td className={classes.tableCell}>
                  <Typography align="right" variant="subtitle2"><b>DISCOUNT</b></Typography>
                </td>
                <td className={classes.tableCell} width="200px">
                  <Typography align="right" variant="subtitle2" style={{ color: '#656565' }}><b>-$15.99</b></Typography>
                </td>
              </tr>
              <tr>
                <td className={classes.tableCell}>
                  <Typography align="right" variant="subtitle2"><b>TOTAL</b></Typography>
                </td>
                <td className={classes.tableCell} width="200px">
                  <Typography align="right" variant="subtitle2" style={{ color: '#656565' }}><b>$283.01</b></Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
        <Box mt={3}>
          <Typography align="left" variant="subtitle2"><b>Select Billing Method</b></Typography>
        </Box>
        <Box mt={1} display="flex">
          {billingMethods.map(billing => (
            <Box width={300} mr={2} key={billing.id}>
              <BillingCard data={billing} onEdit={() => history.push(`/my-profile/billing-details/edit/${billing.id}`)} onDelete={handlePendingDelete(billing)} />
            </Box>
          ))}
          <Box width={300}>
            <BillingCard onAdd={() => history.push('/my-profile/billing-details/add')} />
          </Box>
        </Box>

      </Box>
      <InnerFooter>
        <Box pb={4} px={6} display="flex" justifyContent="flex-end" alignItems="center" width="100%">
          <Box mr={5}>
            <Link variant="subtitle1" onClick={() => history.push('/my-profile/payments')}>
                            CANCEL
            </Link>
          </Box>
          <Button size="large" variant="contained" onClick={onPay}>
                        PAY NOW
          </Button>
        </Box>
      </InnerFooter>
    </PageLayout>
  )
};

import React, { useRef } from 'react';
import { FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import { UploadIcon } from '@app/icons';

export default function FileInput({ file, fileName, onChange, onChangeFileName, label, disableUpload, error, accept, ...props }) {

  const fileInputRef = useRef()

  const onInternalChange = ({ target }) => {
    onChange(
      target.files[0]
    )
  }

  return (
    <FormControl
      variant="outlined"
      fullWidth
      error={!!error}
      {...props}
    >
      <input
        style={{ display: 'none' }}
        type={'file'}
        onChange={onInternalChange}
        ref={fileInputRef}
        accept={accept}
      />
      <InputLabel>
        {label}
      </InputLabel>
      <OutlinedInput
        value={fileName}
        onChange={onChangeFileName}
        onClick={() => {
          (!disableUpload && !file) && fileInputRef.current.click()
        }}
        inputProps={{
          style: { cursor: (!disableUpload && !file) ? 'pointer' : undefined }
        }}
        endAdornment={
          !disableUpload &&
          <InputAdornment
            position="end"
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              fileInputRef.current.click();
            }}
          >
            <UploadIcon />
          </InputAdornment>
        }
      />
      {!!error &&
        <FormHelperText>
          {error}
        </FormHelperText>
      }
      {!!file &&
        <FormHelperText error={false} style={{ wordBreak: 'break-all' }}>
          Selected file: {file.name}
        </FormHelperText>
      }
    </FormControl>
  )
}
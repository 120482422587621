import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Box, Grid, FormControlLabel, Switch } from '@material-ui/core';
import TableList from '@components/lists/table-list';
import OutlinedSearch from '@components/inputs/outlined-search';
import InlineHeadBadge from '@components/badges/inline-head-badge';
import FilterWithContext from '@components/dropdowns/filter-with-context';
import NoResultsFound from '@components/layout/no-results-found';
import { Modal } from '@components/modals';
import EditForm from '../form/edit-form';
import InviteForm from '../form/invite-form';

import useErrorState from '@utils/errorState';
import { useSortableData } from '@utils/useSortableData';
import { makeStyles } from '@material-ui/core/styles';
import history from '@app/history';
import { dummy } from '../../dummy';

const useStyles = makeStyles((theme) => ({

}));

const switchGroup = [
  { value: 'active', label: 'Active Team Members', isChecked: true },
  { value: 'blocked', label: 'Blocked Team Members', isChecked: true },
]

const schema = {
  email: '',
  teamLeader: '',
  firstName: '',
  lastName: '',
  country: '',
  address: '',
  state: '',
  city: '',
  zip: '',
  phone: '',
}

export default function TeamMebers({ userId, addInvite, setAddInvite, setTeamData }) {
  const classes = useStyles(); //eslint-disable-line

  const [model, setModel] = useState(schema);
  const [errors, setErrors] = useErrorState({}); //eslint-disable-line

  const [backup, setBackup] = useState(null); //eslint-disable-line
  const [data, setData] = useState(dummy.members);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState(switchGroup);
  const [modalAction, setModalAction] = useState(null);
  
  const TABLE_COLUMNS = [
    { field: 'name', headerName: 'Team Member Name', bold: true },
    { field: 'teamEmail', headerName: 'Team Member Email' },
    { field: 'lastLogin', headerName: 'Team Member Last Login' },
    { field: 'status', headerName: 'Status' },
  ]

  useEffect(() => {
    !!addInvite && setModalAction({ type: 'invite' });
  }, [addInvite])

  useEffect(() => {
    setTeamData(data);
  }, [data, setTeamData])

  const onSearchChange = useCallback((event) => {
    setSearch(event.target.value);

    const filtered = backup.filter(el =>
      el.name.toLowerCase().includes(event.target.value?.trim()?.toLowerCase())
    );
    setData(filtered);
  }, [backup])

  const onSelect = (id) => {
    const newData = data.map(
      (item) => item.id === id
        ? ({ ...item, isChecked: !item.isChecked })
        : item
    );
    setData(newData);
  }

  const handleChange = (prop) => (event) => {
    const newModel = { ...model, [prop]: event.target.value };
    setModel(newModel);
  };

  const onFilterChange = useCallback((event) => {
    const changed = filters.map(el =>
      el.value === event.target.value ? ({ ...el, isChecked: event.target.checked }) : el
    );
    setFilters(changed);
  }, [filters])

  const handleCloseModal = useCallback(() => {
    setModalAction(null);
    setModalAction(false);
  }, [setModalAction])

  const handleEdit = useCallback((id, item, type) => {
    let newModel = {};
    for (const key in model) {
      const value = key === 'phone' ? Number(item[key]) : item[key];
      newModel = { ...newModel, [key]: value }
    }
    setModel(newModel);

    setModalAction({ id, type });
  }, [model])


  const handleView = useCallback((id, item) => {
    history.push(`/user-management/manage-users/view-user/team-members/view-member?userId=${id}`);
  }, [])

  const handleMenuAction = useCallback((id, item, type) => {
    setModalAction({ id, name: `"${item.name}"`, type, prefix: 'team member' });
  }, [])

  const threeDotsActions = useMemo(() => {
    return [
      { label: 'View', handler: handleView },
      { label: 'Edit', handler: (id, item) => handleEdit(id, item, 'edit') },
      { label: 'Delete', handler: (id, item) => handleMenuAction(id, item, 'delete') },
      { label: 'Block', handler: (id, item) => handleMenuAction(id, item, 'block') },
      { label: 'Unblock', handler: (id, item) => handleMenuAction(id, item, 'unblock'), disabled: true },
    ]
  }, [handleEdit, handleView, handleMenuAction])

  const formattedData = useMemo(() => {
    return data.map(e => {
      const item = { ...e };

      switch (item.status) {
        case 'Active':
          item.cellProps = {
            status: {
              style: {
                color: '#4E922C'
              }
            }
          }
          item.threeDotsActionsDisabled = ['Unblock']
          break;

        case 'Blocked':
          item.cellProps = {
            status: {
              style: {
                color: '#B6514C'
              }
            }
          }
          item.threeDotsActionsDisabled = ['Block']
          break;
        
        default:
          item.threeDotsActionsDisabled = ['Unblock']
          break;
      }

      return item;
    }) || [];
  }, [data])

  const { items, sortConfig, requestSort } = useSortableData(formattedData);

  const modalContent = {
    'invite': <InviteForm model={model} errors={errors} handleChange={handleChange} />,
    'view': <EditForm model={model} errors={errors} handleChange={() => {}} disabled />,
    'edit': <EditForm model={model} errors={errors} handleChange={handleChange} />
  }

  return (
    <Box display={'flex'} flexGrow={'1'} flexDirection={'column'} mt={'-16px'}>
      <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'}>
          <Box mr={1}>
            <InlineHeadBadge
              badgeColor={'#CAD7CA'}
              badgeValue={3}
            >
              Team Members
            </InlineHeadBadge>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <FilterWithContext>
            <Grid container spacing={8}>
              <Grid item xs={6}>
                {filters.map((el, i) => (
                  <Box display={'flex'} alignItems={'center'} height={'40px'} key={`${el.value} - ${i}`}>
                    <FormControlLabel
                      control={<Switch value={el.value} checked={el.isChecked} onClick={onFilterChange} />}
                      label={el.label}
                    />
                  </Box>
                ))}
              </Grid>
            </Grid>
          </FilterWithContext>
        </Box>
      </Box>
      <Box display={'flex'} mb={3}>
        <OutlinedSearch
          value={search}
          onChange={onSearchChange}
          label="Search team members"
        />
      </Box>
      {!!items.length ? (
        <Grid container>
          <Grid item sm={12}>
            <TableList
              columns={TABLE_COLUMNS}
              items={items}
              onSelect={onSelect}
              onClick={handleView}
              pageSize={12}
              pagesCount={1}
              totalCount={data.length}
              page={0}
              maxHeight="calc(100vh - 380px)"
              threeDotsActions={threeDotsActions}
              threeDotsActionsCollapse={threeDotsActions}
              sortableColumns={TABLE_COLUMNS.map(el => el.field)}
              onSort={requestSort}
              sort={sortConfig?.key}
              sortDirection={sortConfig?.direction}
            />
          </Grid>
        </Grid>
      ) : (
        <Box mt={25} flexGrow={1}>
          <NoResultsFound>
            The list of team members will appear here
          </NoResultsFound>
        </Box>
      )}
      <Modal
        open={!!modalAction}
        onClose={handleCloseModal}
        title={modalAction?.type === 'edit' ? `${modalAction?.type} Team Member Details` : `${modalAction?.type} Team Member`}
        mainText={`Are you sure you want to ${modalAction?.type} ${modalAction?.prefix} ${modalAction?.name}?`}
        actions={
          [
            {
              type: 'main',
              label: (modalAction?.type === 'edit' || modalAction?.type === 'invite') ? 'Cancel' : 'NO',
              action: handleCloseModal,
              style: { width: 160 },
            },
            {
              type: 'secondary',
              label: modalAction?.type === 'edit' ? 'Save' : modalAction?.type === 'invite' ? 'INVITE' : 'YES',
              action: handleCloseModal,
              style: { width: 160 },
            },
          ]
        }
        actionsDirection="row"
        content={modalContent[modalAction?.type]}
      />
    </Box>
  )
}
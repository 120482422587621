import React, {useState} from 'react';
import {Box, FormControl, FormLabel, FormControlLabel, Grid, Switch, Button, Typography} from '@material-ui/core';
import { clientApi } from '@app/api';
import PageLayout from '@components/layout/page-layout';
import {useDispatch, useSelector} from 'react-redux';
import authRedux from '@app/store/actions/auth'
import cognito from '../../app/cognito';
import debugMode from '@utils/debugMode';
import { triggers } from '../../app/api/admin';

const selectUser = state => state.auth.user;

export default function Debug() {
  const userData = useSelector(selectUser);
  const dispatch = useDispatch()

  const [debug, setDebug] = useState(debugMode.getIsDebugMode())
  const [side, setSide] = useState(debugMode.getSide())
  const [maintenance, setMaintenance] = useState(debugMode.getMaintenance())

  const handleDebugChange = (event) => {
    const value = event.target.checked
    setDebug(value)
    debugMode.setDebugMode(value)
  }

  const handleSideChange = (event) => {
    const value = (event.target.checked ? 'admin' : 'client')
    setSide(value)
    debugMode.setSide(value)
    dispatch(authRedux.setSide(value))
  }

  const handleMaintenanceChange = (event) => {
    const value = event.target.checked
    setMaintenance(value)
    debugMode.setMaintenance(value)
  }

  const forgetMyDevice = () => {
    cognito.forgetCurrentDevice()
  }

  const triggerError = () => {
    throw new Error('Simulated error from debug page.')
  }

  const triggerApiError = () => {
    clientApi.user.updateUser({
      ...userData,
    })
  }

  return (
    <PageLayout>
      <Grid container justify="center">
        <Typography>Testing Version Update: Version 11</Typography>
        <Grid item xs={6}>
          <Box flexDirection={'column'} display={'flex'} mt={10}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Debug mode configuration</FormLabel>
            </FormControl>
            <FormControlLabel
              control={<Switch checked={Boolean(debug)} onChange={handleDebugChange} />}
              label="Enable debug mode"
            />
            <FormControlLabel
              control={<Switch checked={Boolean((side === 'admin'))} onChange={handleSideChange} />}
              label="Enable admin mode"
            />
            <FormControlLabel
              control={<Switch checked={Boolean(maintenance)} onChange={handleMaintenanceChange} />}
              label="Enable fake maintenance"
            />

            <Button size="large" variant="contained" onClick={forgetMyDevice} style={{ width: 150, paddingLeft: 0, paddingRight: 0 }}>
                            Forget Device
            </Button>

            <Box mt={1}>
              <Button size="large" variant="contained" onClick={triggerError} style={{ width: 150, paddingLeft: 0, paddingRight: 0 }}>
                Trigger Error
              </Button>
            </Box>

            <Box mt={1}>
              <Button size="large" variant="contained" onClick={triggerApiError} style={{ width: 150, paddingLeft: 0, paddingRight: 0 }}>
                API Error
              </Button>
            </Box>

            <Box mt={1}>
              <Button size="large" variant="contained" onClick={() => triggers.triggerWithoutRulebook()} style={{ width: 400, paddingLeft: 0, paddingRight: 0 }}>
                trigger overall client status set for clients without rulebook
              </Button>
            </Box>

            <Box mt={1}>
              <Button size="large" variant="contained" onClick={() => triggers.triggerLegals()} style={{ width: 400, paddingLeft: 0, paddingRight: 0 }}>
                trigger rulebooks migration for legals
              </Button>
            </Box>

            <Box mt={1}>
              <Button size="large" variant="contained" onClick={() => triggers.triggerPeople()} style={{ width: 400, paddingLeft: 0, paddingRight: 0 }}>
                trigger rulebooks migration for people
              </Button>
            </Box>

          </Box>
        </Grid>
      </Grid>
    </PageLayout>
  )
}
import React, { useCallback } from  'react';
import { Box, MenuItem, FormControl } from '@material-ui/core';
import { WarningIcon } from '@app/icons';
import SelectClearable from '@components/inputs/select-clearable';
import CustomTooltip from '@components/controls/tooltip';

import { useSelector, useDispatch } from 'react-redux';
import { backgroundSearch } from '@app/store/actions';

import { FUZZINESS_INTERVAL } from '../../constants';
import { useStyles } from '../styles';


export default function Fuzziness() {
  const classes = useStyles()

  const { searchModel } = useSelector(state => state.backgroundSearch);
  const dispatch = useDispatch();

  const fuzzinessValue = useCallback(() => {
    const item = FUZZINESS_INTERVAL.find(el => el.value === searchModel.fuzziness);
    return (item?.value || item?.value === 0) ? item?.value : '';
  }, [searchModel])

  const handleChangeFuzziness = useCallback((event) => {
    const value = event?.target?.value;
    dispatch(backgroundSearch.setSearchModel({ fuzziness: (value || value === 0) ? value : '' }));
  }, [dispatch])

  return (
    <FormControl className={classes.inputPersonWrapper} fullWidth variant="outlined">
      <Box display="flex" flexDirection="row" alignItems="center" width="100%">
        <SelectClearable
          value={fuzzinessValue()}
          onChange={handleChangeFuzziness}
          clearable={false}
          style={{ width: '100%' }}
          classes={{ select: classes.advancedSelect }}
        >
          {FUZZINESS_INTERVAL.map((item, i) => (
            <MenuItem
              key={`${item}-${i}`}
              value={item.value}
            >
              {item.name}
            </MenuItem>
          ))}
        </SelectClearable>
        <CustomTooltip
          title="Level of flexibility of search (0% will check for exact matches only; 100% will look for results with even the slightest match in spelling and phonetics)"
          placement="top"
        >
          <span style={{ marginRight: '-28px' }}>
            <WarningIcon width="30px" />
          </span>
        </CustomTooltip>
      </Box>
    </FormControl>
  )
}

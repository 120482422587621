import React from 'react';
import ButtonWithIcon from '@components/buttons/button-with-icon';
import {ChevronLeftIcon} from '@app/icons';

export default function ButtonBack({onClick}) {
  return (
    <ButtonWithIcon startIcon={<ChevronLeftIcon />} onClick={onClick}>
            Back
    </ButtonWithIcon>
  )
}
import React, { useState, useEffect } from 'react';
import debounce from 'awesome-debounce-promise';
import { Box, Typography, Button, FormControl } from '@material-ui/core';

import prepareTimestamp from '@utils/date';
import history from '@app/history';
import { ChevronLeftIcon } from '@app/icons';
import { client as clientApi, eidv, folder, person } from '@app/api/client';
import TableList from '@components/lists/table-list';
import { TCELL_TYPES } from '@components/lists/table-list';
import SelectSearchable from '@components/inputs/select-searchable';
import debugMode from '@utils/debugMode';
import { prepareSort } from '@utils/prepare-data/prepareToDoListData';

import { NoData } from './noData';
import { useStyles } from '../styles';
import { getItems } from '../utils/toDoList';
import { typeComponent } from './typeComponent';
import { getRiskRatingValue } from './getRiskRatingValue';
import { getCountryName } from '@services/country-service';
import CustomChip from '@components/controls/custom-chip';
import { PAGE_SIZE, SORTABLE_COLUMNS, DOCUMENTS } from '../../constants';

const debug = debugMode.getIsDebugMode();

const MAX_COUNT = debug ? 3 : 5;

const debouncedSearchableDropDown = debounce(person.index, 500);
export default function EidvPage({ action }) {
  const classes = useStyles();
  const [eidvs, setEidvs] = useState({ items: [], count: '', pages: '' });
  const [pageNumber, setPageNumber] = useState(0);
  const [sortField, setSortField] = useState('initiatedDate');
  const [sortDirection, setSortDirection] = useState('ASC');
  const [options, setOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [clientSearch, setClientSearch] = useState(null);

  useEffect(() => {
    fetchItems();
    // eslint-disable-next-line
  }, [clientSearch]);

  useEffect(() => {
    getEidvs();
    // eslint-disable-next-line
  }, [pageNumber, sortDirection, selectedClient, sortField]);

  const params = {
    clientId: selectedClient.value,
    sortDirection: 'ASC',
    pageNumber: pageNumber,
    pageSize: PAGE_SIZE,
    sortBy: prepareSort(sortField),
    statuses: ['EXPIRED', 'PENDING_APPROVAL', 'REJECTED'],
  };
  const fetchItems = async () => {
    const params = {
      pageNumber: 0,
      pageSize: 30,
      searchKeyword: clientSearch?.trim() || null,
      sortDirection: 'ASC',
      sortBy: 'firstName',
    };
    if (selectedClient.label !== clientSearch) {
      const response =
        clientSearch !== null
          ? (await debouncedSearchableDropDown(params)).data
          : (await person.index(params)).data;
      if (response) {
        let optionsTmp = response.entries.map((item) => ({
          label: `${item.firstName} ${item.lastName}`,
          value: item.id,
        }));
        optionsTmp.push({
          label: 'Consider refining the filter to find your client faster',
          value: '0',
          disabled: true,
        });
        setOptions(optionsTmp);
      }
    }
  };
  const fetchFoldersName = async (data) => {
    const tmp = data.reduce(
      (previousValue, currentValue) =>
        currentValue.folderId !== null
          ? [...previousValue, currentValue.folderId]
          : previousValue,
      []
    );
    const folderIds = [...new Set(tmp)];
    const folders = await Promise.all(
      folderIds.map((item) => folder.show(item))
    );
    return folders.map((item) => item.data);
  };

  const fetchClients = async (data) => {
    const tmp = data.reduce(
      (previousValue, currentValue) =>
        currentValue.clientId !== null
          ? [...previousValue, currentValue.clientId]
          : previousValue,
      []
    );
    const clientsArray = [...new Set(tmp)];
    const clients = await Promise.all(
      clientsArray.map((item) => clientApi.show(item))
    );
    const formattedData = clients.map((item) => item.data);
    return formattedData;
  };

  const getEidvs = async () => {
    const response = (await eidv.index(params)).data;

    if (response) {
      const clientsData = await fetchClients(response.entries);
      const folders = await fetchFoldersName(response.entries);
      const formattedEidvs = response.entries.map((item) => {
        const currentClient = clientsData.find(
          (client) => client.id === item.clientId
        );
        const folderName = folders.find(
          (folder) => folder.id === item.folderId
        );
        const documentsTmp = item.requestedSteps.steps.reduce(
          (previousValue, currentValue) =>
            currentValue !== 'FACE_MATCH'
              ? [...previousValue, DOCUMENTS[currentValue]]
              : previousValue,
          []
        );
        return {
          ...item,
          documents: documentsTmp.join(', '),
          citizenship: item.clientId
            ? getCountryName(currentClient.citizenship)
            : 'N/A',
          clientName: item.clientId
            ? `${currentClient.firstName} ${currentClient.lastName}`
            : item.email,
          riskRating: currentClient?.riskRating || 'N/A',
          residency: currentClient && getCountryName(currentClient.residency),
          folderName: currentClient
            ? currentClient.folderName
            : folderName.name,
          initiatedDate: item.initiatedDate
            ? prepareTimestamp(item.initiatedDate, 'MM/DD/YYYY', false)
            : 'N/A',
          updatedDate: item.updatedDate
            ? prepareTimestamp(item.updatedDate, 'MM/DD/YYYY', false)
            : 'N/A',
          statusLabel: !!item.status && (
            <CustomChip
              label={
                item.status === 'PENDING_APPROVAL'
                  ? 'PENDING APPROVAL'
                  : item.status
              }
              type={item.status}
            />
          ),
          type: currentClient ? currentClient.clientType : 'PERSON',
        };
      });
      setEidvs({
        items: formattedEidvs,
        count: response.count,
        pages: response.pages,
      });
    }
  };
  const handleSelect = (field) => {
    setPageNumber(0);
    if (field) {
      setSelectedClient(field.target);
      setClientSearch(field.target.label);
    } else {
      setSelectedClient();
      setClientSearch('');
    }
  };

  const onSort = (value) => {
    setPageNumber(0);
    if (sortField !== value) {
      setSortField(value);
      setSortDirection('ASC');
    } else {
      setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
    }
  };
  const handleRowClick = (id, item) => {
    const link = `/electronic-verification/${item.id}/overview`;
    history.push(
      item.clientId ? `/portfolio/view/${item.clientId}${link}` : link
    );
  };
  const tableProps = action && {
    pagesCount: eidvs.pages,
    totalCount: eidvs.count,
    pageSize: PAGE_SIZE,
    page: pageNumber,
    sortableColumns: SORTABLE_COLUMNS['eidv'],
    onSort: onSort,
    sort: sortField,
    sortDirection: sortDirection,
  };

  const COLUMNS = [
    { field: 'clientName', headerName: 'Client Name', bold: true },
    { field: 'folderName', headerName: 'Folder' },
    {
      cellType: TCELL_TYPES.CUSTOM,
      field: 'riskRating',
      headerName: 'Risk Rating',
      custom: getRiskRatingValue,
    },
    {
      field: 'documents',
      headerName: 'Documents',
      cellType: TCELL_TYPES.TRUNCATED,
      useTooltip: true,
      colProps: {
        width: '12%',
      },
    },
    { field: 'residency', headerName: 'Residency' },
    { field: 'citizenship', headerName: 'Citizenship' },
    { field: 'statusLabel', headerName: 'Verification Status' },
    { field: 'initiatedDate', headerName: 'Created On' },
    { field: 'updatedDate', headerName: 'Updated On' },
    {
      cellType: TCELL_TYPES.CUSTOM,
      field: 'type',
      headerName: 'Type',
      custom: (item) => typeComponent(item, classes.styleBadgeRoot),
      colProps: {
        width: '5%',
      },
    },
  ];

  const headerTitleProps = action && {
    ml: -1,
    className: classes.back,
    onClick: () => history.goBack(),
  };
  return (
    <Box display={'flex'} flexGrow={'1'} mt={'16px'} flexDirection={'column'}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={classes.header}
      >
        <Box display="flex" alignItems="center" {...headerTitleProps}>
          {action && <ChevronLeftIcon />}
          <Typography variant={action ? 'h3' : 'h4'}>
            Electronic Identity Verification Reviews
          </Typography>
        </Box>
        {action && (
          <Box display="flex" alignItems="center" ml={2} mr={3} width={350}>
            <Button
              size="small"
              className={classes.reset}
              onClick={handleSelect}
            >
              Reset Folders
            </Button>
            <FormControl variant="outlined" fullWidth>
              <SelectSearchable
                filterOptions={(x) => x}
                inputValue={clientSearch}
                getOptionDisabled={(option) => option.disabled}
                renderOption={(option) => (
                  <span
                    style={{
                      fontStyle: option.value === '0' ? 'italic' : 'normal',
                    }}
                  >
                    {option.label}
                  </span>
                )}
                onInputChange={(event, newInputValue) => {
                  if (event?.type === 'change') {
                    setClientSearch(newInputValue);
                  }
                }}
                value={selectedClient.value}
                onChange={handleSelect}
                options={options}
                label="All Clients"
              />
            </FormControl>
          </Box>
        )}
      </Box>
      {!!eidvs.items.length && (
        <>
          <Box>
            <TableList
              columns={COLUMNS}
              items={getItems(action, eidvs.items, debug)}
              onClick={handleRowClick}
              maxHeight="calc(100vh - 320px)"
              onPageChange={(event, value) => setPageNumber(value - 1)}
              {...tableProps}
            />
          </Box>
          {eidvs.items.length > MAX_COUNT && !action && (
            <Box mt={2} mb={2}>
              <Button
                variant="outlined"
                size="small"
                onClick={() =>
                  history.push('/calendar-schedule/sсhedule/eidv-reviews')
                }
              >
                VIEW ALL
              </Button>
            </Box>
          )}
        </>
      )}
      {!eidvs.items.length && NoData(action, 'client', classes.noActions)}
    </Box>
  );
}

import React, { useEffect, useState } from 'react';
import {
  Box,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Link,
  makeStyles,
  OutlinedInput,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { InfoBrownOtherIcon } from '@app/icons';
import { Modal } from '@components/modals';
import history from '@app/history';
import * as yup from 'yup';
import parseYupErrors from '@app/yup';

import { eidv, subscriptionPlans } from '@app/api/client';
import EidvInvite from '@assets/icons/EIDV/EidvInvite.png';
import { useSnackbar } from 'notistack';
import RadioButton from '@components/buttons/radio-button';
import SelectSearchable from '@components/inputs/select-searchable';
import { handleOnEnter } from '@utils/handleOnEnter';

const types = [
  { name: 'manual', label: 'Add manually' },
  { name: 'invite', label: 'Invite client' }
]

const useStyles = makeStyles((theme) => ({
  radioBtn: {
    padding: 0,
    margin: '0px 9px'
  },
}));

const schema = yup.object().shape({
  email: yup.string().trim().email('Invalid email format').required(),
  folderId: yup.number(),
});

export default function AddPersonDialog(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { open, onClose, folders, folderId, folderName } = props;

  const [credits, setCredits] = useState(0)
  const [addType, setAddType] = useState('manual');
  const [model, setModel] = useState({
    email: '',
    folderId: 0,
  });
  const [errors, setErrors] = useState({})

  useEffect(() => {
    getCredits();

    return () => {
      window.removeEventListener('focus', getCredits)
    }
  }, []) // eslint-disable-line

  const getCredits = () => {
    subscriptionPlans.getSubscriptionForUser().then((res) => {
      const subscription = res.data;
      const credits = (subscription.remainingEidvCredits || 0) - (subscription.reservedEidvCredits || 0)
      setCredits(credits < 0 ? 0 : credits)
      window.removeEventListener('focus', getCredits)
    })
  }

  const setCheckCreditOnReturn = () => {
    window.addEventListener('focus', getCredits)
  }

  // Get and set HOME as default folder
  useEffect(() => {
    if (open && folders.length) {
      const home = folders.find(e => e.name === 'HOME');
      setModel({ email: '', folderId: home.id })
    }
  }, [folders, open])

  const onNext = () => {
    if (addType === 'manual') {
      history.push(`/portfolio/create-person?folderId=${folderId}&folderName=${folderName}`)
    } else {
      setErrors({});
      schema
        .validate(model, { abortEarly: false })
        .then(() => {
          if (credits <= 0) {
            return enqueueSnackbar('You do not have enough credits.', { variant: 'error' })
          }
          eidv.invite(model).then(() => {
            enqueueSnackbar(`Invitation for "${model.email}" has been sent!`, { variant: 'success' })
            onClose();
          })
        })
        .catch(err => setErrors(parseYupErrors(err), true));
    }
  }

  const handleChange = (prop) => (event) => {
    const newModel = { ...model, [prop]: event.target.value };
    setModel(newModel);

    if (errors[prop]) {
      schema.validateAt(prop, newModel)
        .then(function (valid) {
          setErrors({ ...errors, [prop]: undefined });
        })
        .catch(function (err) {
          setErrors({ ...errors, [prop]: err.message });
        });
    }
  };

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title="Add new Person"
        modalProps={{
          onKeyDown: handleOnEnter(onNext)
        }}
        content={
          <Box width={400} mb={4}>
            <Box mt={2} display="flex" justifyContent="center">
              <img alt="" src={EidvInvite}></img>
            </Box>
            <Box mt={4} display="flex" justifyContent="center">
              <RadioGroup>
                {types.map(type => (
                  <FormControlLabel
                    control={
                      <RadioButton
                        value={type.name}
                        onChange={() => setAddType(type.name)}
                        checked={addType === type.name}
                        className={classes.radioBtn}
                      />
                    }
                    label={<Typography variant="subtitle2">{type.label}</Typography>}
                    key={type.name}
                  />
                ))}
              </RadioGroup>
            </Box>
            <Collapse in={addType === 'invite'}>
              <Box mt={2}>
                <Box mt={2}>
                  <FormControl fullWidth variant="outlined" error={errors.email}>
                    <InputLabel htmlFor="email">Email</InputLabel>
                    <OutlinedInput value={model.email} onChange={handleChange('email')} />
                    {errors.email &&
                      <FormHelperText>{errors.email}</FormHelperText>
                    }
                  </FormControl>
                </Box>
                <Box mt={1}>
                  <FormControl fullWidth variant="outlined">
                    <SelectSearchable
                      value={model.folderId}
                      onChange={handleChange('folderId')}
                      options={folders.map(e => ({ label: e.name, value: e.id }))}
                      label="Select Folder"
                      disableClearable
                    />
                    <FormHelperText>
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box display="flex" mt="4px" pl="6px">
                  <Box>
                    <Box width={20}>
                      <InfoBrownOtherIcon />
                    </Box>
                  </Box>
                  <Box>
                    <Typography style={{ lineHeight: '16px', color: '#7A5B44' }}>
                      Inviting client will initiate an Electronic Identitiy Verification (eIDV) request. Collected information and documents will be saved in folder you selected under client’s profile.
                    </Typography>
                  </Box>
                </Box>
                <Box mt={5}>
                  <Typography variant="h4" align="center" style={{ color: '#484848', fontSize: 16 }}>You have <span style={{ color: '#7A5B44' }}>{credits}</span> eIDV credits available</Typography>
                  <FormHelperText style={{ marginBottom: 6 }}>1 credit will be used for each eIDV request</FormHelperText>
                  <Typography align="center">
                    <Link onClick={setCheckCreditOnReturn} href="/my-profile/subscription/plans" target="_blank" style={{ fontWeight: 'normal', fontSize: 12 }}>Click here to buy more credits</Link>
                  </Typography>
                </Box>
                <Box mt={3}>
                  <Typography variant="subtitle2" style={{ color: '#B6514C' }}>
                    Credits will be reserved until eIDV process is completed or pending approval. Any rejected or expired requests will return the credit back.
                  </Typography>
                </Box>
              </Box>
            </Collapse>
          </Box>
        }
        actions={
          [
            {
              type: 'main',
              label: 'CANCEL',
              action: onClose,
              style: { width: 160 },
            },
            {
              type: 'secondary',
              label: addType === 'manual' ? 'NEXT' : 'INVITE',
              action: onNext,
              style: { width: 160 },
            },
          ]
        }
        actionsDirection="row"
      />
    </React.Fragment>
  );
}
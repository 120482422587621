import { createMuiTheme } from '@material-ui/core';
import theme from '../../../app/theme/common';

const subscriptionTheme = {
  ...theme,
  typography: {
    ...theme.typography,
    h5: {
      ...theme.typography.h5,
      color: '#656565',
      lineHeight: '22px',
    },
    h1: {
      ...theme.typography.h1,
      color: '#232323'
    },
    body1: {
      ...theme.typography.body1,
      color: '#656565'
    }
  }
}

export default createMuiTheme(subscriptionTheme);
import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import SearchVersionItem from '@views/common/search-version-item';

import { useSelector } from 'react-redux';

export default function VersionHistoryList({ onSelect, filter = {} }) {
  const { profilesVersion, searchVersions } = useSelector(store => store.searchHistory);

  const handleSelect = (el) => () => {
    onSelect && onSelect(el)
  }

  const showUnlistedSearch = useMemo(() => {
    if ((profilesVersion.id && searchVersions.length) && !searchVersions.find(e => e.id === profilesVersion.id)) {
      if (profilesVersion.performedOn > searchVersions[0].performedOn) {
        return 'after';
      } else {
        return 'before';
      }
    }

    return false;
  }, [searchVersions, profilesVersion])

  return (
    <>
      {showUnlistedSearch === 'after' &&
        <Box>
          <SearchVersionItem
            item={profilesVersion}
            onClick={handleSelect(profilesVersion)}
            active
          />
          <Box pl={10} mb="4px" lineHeight="4px">.</Box>
          <Box pl={10} mb="4px" lineHeight="4px">.</Box>
          <Box pl={10} mb={1} lineHeight="4px">.</Box>
        </Box>
      }
      {searchVersions.map(el => (
        <SearchVersionItem
          key={el.id}
          item={el}
          onClick={handleSelect(el)}
          active={profilesVersion?.id ? el.id === profilesVersion?.id : el.id === 'last_version'}
        />
      ))}
      {showUnlistedSearch === 'before' &&
        <Box>
          <Box pl={10} mb="4px" lineHeight="4px">.</Box>
          <Box pl={10} mb="4px" lineHeight="4px">.</Box>
          <Box pl={10} mb={2} lineHeight="4px">.</Box>
          <SearchVersionItem
            item={profilesVersion}
            onClick={handleSelect(profilesVersion)}
            active
          />
        </Box>
      }
    </>
  )
}

import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import subscriptionTheme from '@components/complex/subscription/theme';
import UsageItems from '@components/complex/subscription/items/usage-items';
import { clients, subscription, invoice, subscriptionPlans } from '@app/api/admin';
import prepareTimestamp from '@utils/date';
import { capitalize } from '@utils/textUtil';
import history from '@app/history';

import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import CreditBar from '@components/complex/subscription/items/credit-bar';
import { injectAvailableCredits } from '@dto/plan';
import { minimumZero } from '@utils/numberUtil';
import { formatThousands } from '@utils/numberUtil';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflowY: 'auto',
  },
  itemWrapper: {
    width: 325,
  },
  valueWrapper: {
    marginTop: '21px',
  },
}));

export default function AccountUsage({ userId, baseRoute }) {
  const classes = useStyles();

  const [data, setData] = useState({});
  const [eidvsData, setEidvsData] = useState({});
  const [isLoad, setIsLoad] = useState(false);

  const getData = useCallback(async () => {
    const results = await Promise.all([
      clients.getPerformedSearchesPerUser(),
      clients.getClientsOnMonitoryPerUser(),
      clients.getSearchesOnMonitoryPerUser(),
      clients.getNotAcknowledgedSearchesPerUser(),
      subscription.getSubscriptionForUser(userId),
      clients.getEntitiesPerUser(),
    ])

    const usersData = results[0].data;
    const clientsMonitory = results[1].data;
    const searchesMonitory = results[2].data;
    const notAcknowledgedSearches = results[3].data;
    const currSubscription = results[4].data;
    const entities = results[5].data;
    injectAvailableCredits(currSubscription);

    const plan = (
      await subscriptionPlans.getById(currSubscription.currentPlanId)
    ).data;
    const invoices = (await invoice.getInvoices({ userId })).data;

    const userData = usersData.find((el) => el.userId === userId);
    const clientsMonitoryData = clientsMonitory.find((el) => el.userId === userId);
    const searchesMonitoryData = searchesMonitory.find((el) => el.userId === userId);
    const cknowledgedaData = notAcknowledgedSearches.find((el) => el.userId === userId);
    const entitiesData = entities.find((el) => el.userId === userId);

    const lastInvoice =
      invoices.entries
        .sort((a, b) =>
          a.creationDate < b.creationDate
            ? 1
            : a.creationDate > b.creationDate
              ? -1
              : 0
        )
        .sort((a, b) => (a.id < b.id ? 1 : a.id > b.id ? -1 : 0))[0] || {};
    let billingValue = '';
    if (!!lastInvoice.status) {
      billingValue =
        lastInvoice.status === 'PAID'
          ? currSubscription.expirationDate
            ? `Paid ${prepareTimestamp(
              lastInvoice.payDate,
              'MM/DD/YYYY'
            )}, valid through ${prepareTimestamp(
              currSubscription.expirationDate,
              'MM/DD/YYYY'
            )}`
            : `Paid ${prepareTimestamp(lastInvoice.payDate, 'MM/DD/YYYY')}`
          : `Request created on ${prepareTimestamp(
            lastInvoice.creationDate,
            'MM/DD/YYYY'
          )}`;
    }

    setData({
      ...userData,
      ...currSubscription,
      clientsOnMonitoryRegular: clientsMonitoryData?.countWithRegularSearches || 0,
      clientsOnMonitoryPremium: clientsMonitoryData?.countWithPremiumSearches || 0,
      searchesOnMonitoryRegular: searchesMonitoryData?.countWithRegularSearches || 0,
      searchesOnMonitoryPremium: searchesMonitoryData?.countWithPremiumSearches || 0,
      unacknowledgedSearches: cknowledgedaData?.unacknowledgedSearches || 0,
      totalRegularCredits: plan?.regularCredits || 0,
      totalPremiumCredits: plan?.premiumCredits || 0,
      billingStatus: lastInvoice.status
        ? capitalize(lastInvoice.status.toLowerCase())
        : 'N/A',
      billingValue,
      entitiesData,
    });
    setIsLoad(true);
  }, [userId]);

  const getTotalEidvs = async () => {
    const statuses = [
      'PENDING_APPROVAL',
      'REPEATED',
      'REJECTED',
      'EXPIRED',
      'COMPLETED',
      'REQUESTED',
      'CANCELED',
      'IN_PROGRESS',
    ];
    const types = [
      'CLIENT_DOESNT_EXIST',
      'CLIENT_EXISTS_NEW_CASE',
      'CLIENT_EXISTS_REPEATED_CASE',
    ];
    Promise.all([
      clients.getEidvsPerUser(statuses, types, userId),
      clients.getEidvsPerUser(statuses, types.slice(0, 2), userId),
      clients.getEidvsPerUser(statuses, [types[2]], userId),
      ...statuses
        .slice(0, 7)
        .map((item) => clients.getEidvsPerUser([item], types, userId)),
    ]).then((value) =>
      setEidvsData({
        total: value[0]?.eidvsCases || 0,
        clientsWith: value[1]?.eidvsCases || 0,
        additional: value[2]?.eidvsCases || 0,
        pending: value[3]?.eidvsCases || 0,
        repeted: value[4]?.eidvsCases || 0,
        rejected: value[5]?.eidvsCases || 0,
        expired: value[6]?.eidvsCases || 0,
        completed: value[7]?.eidvsCases || 0,
        requested: value[8]?.eidvsCases || 0,
        canceled: value[9]?.eidvsCases || 0,
      })
    );
  };

  useEffect(() => {
    getData();
    getTotalEidvs();
    // eslint-disable-next-line
  }, [getData]);

  const handleDetails = () => {
    history.push(`${baseRoute}/payments`);
  };

  return (
    <ThemeProvider theme={subscriptionTheme}>
      {isLoad && (
        <Box className={classes.root}>
          <Box display="flex" justifyContent="space-between">
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Number of search names using regular credit (Aleph)"
                value={formatThousands(data.performedRegularSearches)}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Number of deleted search names using regular credit (Aleph)"
                value={formatThousands(data.deletedRegularSearches)}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Number of searches on monitoring using regular credit (Aleph)"
                value={formatThousands(data.searchesOnMonitoryRegular)}
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" my={7}>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Number of search names using premium credit (Comply advantage API)"
                value={formatThousands(data.performedPremiumSearches)}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Number of deleted search names using premium credit (Comply Advantage API)"
                value={formatThousands(data.deletedPremiumSearches)}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Number of searches on monitoring using premium credit (Comply Advantage API)"
                value={formatThousands(data.searchesOnMonitoryPremium)}
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" my={7}>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Number of ongoing monitoring names (Aleph)"
                value={formatThousands(data.clientsOnMonitoryRegular)}
                styleValueWrapper={classes.valueWrapper}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Number of ongoing monitoring names (Comply Advantage API)"
                value={formatThousands(data.clientsOnMonitoryPremium)}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Detected changes"
                value={formatThousands(data.unacknowledgedSearches)}
                styleValueWrapper={classes.valueWrapper}
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" my={7}>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Number of people"
                value={formatThousands(data.entitiesData?.persons)}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Number of legal entities"
                value={formatThousands(data.entitiesData?.legalEntities)}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems />
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" my={7}>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Total of electronic verifications requests"
                value={eidvsData.total}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Total of clients with electronic verification requests"
                value={eidvsData.clientsWith}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Total of additional electronic verification requests for clients"
                value={eidvsData.additional}
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" my={7}>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Count of requested electronic verification requests"
                value={eidvsData.requested}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Count of pending review electronic verification requests"
                value={eidvsData.pending}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Count of repeated electronic verification requests"
                value={eidvsData.repeted}
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" my={7}>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Count of rejected electronic verification requests"
                value={eidvsData.rejected}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Count of expired electronic verification requests"
                value={eidvsData.expired}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Count of completed electronic verification requests"
                value={eidvsData.completed}
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" my={7}>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Count of canceled verification requests"
                value={eidvsData.canceled}
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems />
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" mb={7}>
            <Box className={classes.itemWrapper}>
              <CreditBar
                title="REGULAR CREDITS"
                remainingCredits={minimumZero(data?.remainingRegularCredits)}
                availableCredits={minimumZero(data?.availableRegularCredits)}
                reservedCredits={minimumZero(data?.reservedRegularCredits)}
                maxCredits={minimumZero(data?.totalRegularCredits)}
                isAdmin
                baseRoute={baseRoute}
                type="regular"
              />
            </Box>
            <Box className={classes.itemWrapper}>
              <CreditBar
                title="PREMIUM CREDITS"
                remainingCredits={minimumZero(data?.remainingPremiumCredits)}
                availableCredits={minimumZero(data?.availablePremiumCredits)}
                reservedCredits={minimumZero(data?.reservedPremiumCredits)}
                maxCredits={minimumZero(data?.totalPremiumCredits)}
                isAdmin
                baseRoute={baseRoute}
                type="premium"
              />
            </Box>
            <Box className={classes.itemWrapper}>
              {/* <MeasuredItem title="TEAM MEMBERS" value={120} minValue={0} maxValue={400} isAdmin /> */}
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" my={7}>
            <Box className={classes.itemWrapper}>
              <UsageItems title="Team Members" value="Single" />
            </Box>
            <Box className={classes.itemWrapper}>
              <UsageItems
                title="Billing Status"
                value={data.billingStatus}
                additional={{
                  value: data.billingValue,
                  isText: true,
                }}
                action={{ title: 'Details', func: handleDetails }}
              />
            </Box>
            <Box className={classes.itemWrapper}></Box>
          </Box>
        </Box>
      )}
    </ThemeProvider>
  );
}

import React, { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  Box, Button, Card, Grid, MenuItem, Slide, Typography, makeStyles //eslint-disable-line
} from '@material-ui/core';
import TableList, { TCELL_TYPES } from '@components/lists/table-list';
import history from '@app/history';
import { DropdownDownIcon, DotsIcon, DownloadIcon, CalendarIcon, WarningIcon } from '@app/icons'; //eslint-disable-line
import MenuWithContext from "@components/dropdowns/menu-with-context"; //eslint-disable-line
import ButtonWithIcon from '@components/buttons/button-with-icon'; //eslint-disable-line
import { useParams } from 'react-router';
import formatCurrency from '@utils/formatCurrency';
import CustomTooltip from '@components/controls/tooltip';
import IconDropdownWithContext from '../../dropdowns/icon-dropdown-with-context'; //eslint-disable-line
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { invoices, subscriptionPlans } from '@app/api/client';
import { invoice } from '@app/api/admin';
import NoResultsFound from '@components/layout/no-results-found';
import { useSortableData } from '@utils/useSortableData';
import { Modal } from '@components/modals';
import prepareTimestamp from '@utils/date';
import { useSnackbar } from 'notistack';
import { clients, subscriptionPlans as adminSubscriptionPlans } from '@app/api/admin';

const useStyles = makeStyles((theme) => ({
  modalButtonWidth: {
    width: '160px',
  },
}));

const PAGE_SIZE = 12;

const INVOICE_COLUMNS = [
  { field: 'date', headerName: 'Date' },
  { field: 'billable_item', headerName: 'Billable Item', bold: true },
  { cellType: TCELL_TYPES.TRUNCATED, field: 'details', headerName: 'Details', useTooltip: true, colProps: { width: '30%' } },
  { field: 'formatted_amount', headerName: 'Total Amount', align: 'right' },
  { field: 'status', headerName: 'Status' },
  { field: 'payment_date', headerName: 'Payment Date' },
  // { cellType: TCELL_TYPES.ICON_BUTTON, field: 'download', headerName: '', icon: <DownloadIcon />, tooltip: 'Download' },
  { cellType: TCELL_TYPES.BUTTON, field: 'pay', label: 'PAY NOW' }
]
//eslint-disable-next-line
const dateOptions = [
  { label: 'Current Month', value: 'currentMonth' },
  { label: 'Last Month', value: 'lastMonth' },
  { label: 'Last Quarter', value: 'lastQuater' },
  { label: 'Last Year', value: 'lastYear' },
  { label: 'Custom', value: 'custom' },
]

const failWarning = (item) => {
  if (item.status === 'Failed') {
    return (
      <CustomTooltip title="Fail" placement="top">
        <WarningIcon />
      </CustomTooltip>
    )
  }
  return null;
}

const PAYMENT_COLUMNS = [
  { field: 'date', headerName: 'Date' },
  { field: 'billable_item', headerName: 'Billable Item', bold: true },
  { cellType: TCELL_TYPES.TRUNCATED, field: 'details', headerName: 'Details', useTooltip: true },
  { field: 'total_services', headerName: 'Total Services' },
  { field: 'promo_code', headerName: 'Promo Code' },
  { field: 'formatted_amount', headerName: 'Total Amount', colProps: { align: 'right' }, headerProps: { align: 'right' } },
  { cellType: TCELL_TYPES.TEXT_ICON, field: 'status', headerName: 'Status', decoration: failWarning },
  // { cellType: TCELL_TYPES.ICON_BUTTON, field: 'download', headerName: '', icon: <DownloadIcon />, tooltip: 'Download' },
]

const PaymentCard = ({ pageRoot, isInvoice, selectedItems, selectedItemsTotal, handlePay }) => {
  return createPortal(
    <Slide in={isInvoice && !!selectedItems.length} style={{ position: 'absolute', right: 48, top: 50 }} direction="left" unmountOnExit mountOnEnter>
      <Card>
        <Box p="4px" display="flex" justifyContent="space-between" width={550}>
          <Box p="4px 0px 4px 12px">
            <Typography variant="h5" align="left">{selectedItems.length} INVOICES SELECTED</Typography>
            <Box>
              <Typography variant="subtitle2" align="left" style={{ color: '#656565' }}>{selectedItemsTotal}</Typography>
            </Box>
          </Box>
          <Button disableElevation onClick={handlePay} variant="contained" size="medium" style={{ width: 128, minWidth: 128 }}>PAY</Button>
        </Box>
      </Card>
    </Slide>,
    pageRoot
  )
}

export default function Payments(props) {
  const { baseRoute, pageRoot, userId } = props;
  const params = useParams();
  const tab = params.tab || props.tab;
  const { side, userIsAdmin } = useSelector(store => store.auth);
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [fullName] = useState(search.get('fullName'));

  const isAdmin = useMemo(() => {
    return (side === 'admin' || userIsAdmin);
  }, [side, userIsAdmin])
  const isInvoice = useMemo(() => isAdmin ? tab === 'payments' : tab !== 'payments', [tab, isAdmin])

  const [invoiceItems, setInvoiceItems] = useState([]);
  const [paymentItems, setPaymentItems] = useState([]);
  const [openModal, setOpenModal] = useState(null);
  const [user, setUser] = useState(null);

  const [page, setPage] = useState(0);
  const [pagesCount, setPagesCount] = useState(1);
  const [totalCount, setTotalCount] = useState(12);
  const onPageChange = (event, page) => {
    setPage(page - 1);
  }

  const [selectedDate, setSelectedDate] = useState('currentMonth'); //eslint-disable-line

  const getInvoicesForUser = async () => {
    const data = isAdmin
      ? (await invoice.getInvoices({ pageNumber: page, pageSize: PAGE_SIZE, userId })).data
      : (await invoices.getInvoicesForUser({ pageNumber: page, pageSize: PAGE_SIZE })).data;

    if (data) {
      const parsedData = [];
      const buffer = [];
      data.entries.forEach(async el => {
        buffer.push(new Promise(async (resolve) => {
          const resp = isAdmin
            ? await adminSubscriptionPlans.getById(el.toSubscriptionPlanId)
            : await subscriptionPlans.getById(el.toSubscriptionPlanId)

          let currentPlanName = 'N/A';
          if (resp.status === 200) {
            currentPlanName = resp.data.name;
          }

          parsedData.push({
            id: el.id,
            date: prepareTimestamp(el.creationDate, 'MM/DD/YYYY'),
            billable_item: 'Subscription Plan',
            details: currentPlanName,
            formatted_amount: `$${el.price}`,
            total_amount: el.price,
            status: el.status,
            payment_date: el.payDate ? prepareTimestamp(el.payDate, 'MM/DD/YYYY') : 'N/A',
            isChecked: false,
          })
          resolve();
        }))
      })
      await Promise.all(buffer);
      setTotalCount(data.count);
      setPagesCount(data.pages);
      setInvoiceItems(parsedData);
    }
  }

  useEffect(() => {
    getInvoicesForUser();
  }, [page]) // eslint-disable-line

  const getUser = async () => {
    const currUser = (await clients.getUserById(userId)).data;
    setUser(currUser);
  }

  useEffect(() => {
    if (isAdmin) {
      getUser();
    }
  }, [userId, isAdmin]) // eslint-disable-line

  // eslint-disable-next-line
  const [dataItems, setDataItems] = useMemo(() => {
    return isInvoice ? [invoiceItems, setInvoiceItems] : [paymentItems, setPaymentItems];
  }, [invoiceItems, paymentItems, isInvoice])

  // eslint-disable-next-line
  const switchTab = () => {
    const path = isAdmin
      ? `${baseRoute}/${isInvoice ? 'invoices' : 'payments'}/?fullName=${fullName}`
      : `${baseRoute}/${isInvoice ? 'payments' : 'invoices'}`;
    history.push(path);
  }

  const selectedItems = useMemo(() => {
    return dataItems.filter(e => e.isChecked);
  }, [dataItems])

  const selectedItemsTotal = useMemo(() => {
    return formatCurrency(selectedItems.reduce((total, curr) => total + curr.total_amount, 0));
  }, [selectedItems])

  const formattedItems = useMemo(() => {
    return dataItems.map(e => {
      const item = { ...e };

      switch (item.status) {
        case 'PAID':
          item.hiddenFields = ['pay'];
          break;

        case 'UNPAID':
        case 'FAILED':
          item.cellProps = {
            status: {
              style: {
                color: '#B6514C'
              }
            }
          }
          break;

        default:
          break;
      }

      return item;
    })
  }, [dataItems])

  const { items, sortConfig, requestSort } = useSortableData(formattedItems, { key: 'date', direction: 'DSC' });

  const handlePay = async (id) => {
    if (isAdmin) {
      const res = await invoice.payInvoice(id);

      if (res.status === 200) {
        const invoice = items.find(el => el.id === id);
        enqueueSnackbar(
          `Invoice for "${user.name} ${user.lastName}" with total amount "${invoice.formatted_amount}" generated on date "${invoice.date}" was successfully marked as paid!`,
          { variant: 'success' }
        );
        setInvoiceItems(el => el.map(el =>
          el.id === id ? ({ ...el, status: res.data.status, payment_date: prepareTimestamp(res.data.payDate, 'MM/DD/YYYY') }) : el
        ))
      }
      setOpenModal(null);
      return;
    }
    history.push('/my-profile/payments/pay');
  }

  const columns = useMemo(() => {
    return isInvoice
      ? isAdmin
        ? INVOICE_COLUMNS
        : INVOICE_COLUMNS.filter(el => el.field !== 'pay')
      : PAYMENT_COLUMNS;
  }, [isInvoice, isAdmin])

  return (
    <Box mt="-10px">
      {!!pageRoot && <PaymentCard isInvoice={isInvoice} selectedItems={selectedItems} selectedItemsTotal={selectedItemsTotal} pageRoot={pageRoot} handlePay={handlePay} />}

      {/* <Grid container>
                <Grid item xs={6} container alignItems="center">
                    <ButtonWithIcon
                        startIcon={<DotsIcon />}
                        disabled={totalCount === 0}
                        onClick={switchTab}
                        style={{ marginLeft: isAdmin && '-13px' }}
                    >
                        <Box mr={1}>
                            {isInvoice ? 'Invoices' : 'Payments'}
                        </Box>
                        <DropdownDownIcon />
	                </ButtonWithIcon>
                </Grid>
                <Grid item xs={6} container alignItems="center" justify="flex-end">
                    <IconDropdownWithContext icon={<CalendarIcon />} label={dateOptions.find(e => e.value === selectedDate).label}>
                        {dateOptions.map(option => (
                            <MenuItem selected={option.value === selectedDate} key={option.value} onClick={() => setSelectedDate(option.value)}>{option.label}</MenuItem>
                        ))}
                    </IconDropdownWithContext>
                    <MenuWithContext badge={selectedItems.length}>
                        <MenuItem disabled={!selectedItems.length} onClick={() => {}}>Download</MenuItem>
                    </MenuWithContext>
                </Grid>
            </Grid> */}
      {!!invoiceItems.length ? (
        <Box mt={1} ml="-3px">
          <TableList
            columns={columns}
            items={items}
            selectAll
            page={page}
            pageSize={PAGE_SIZE}
            pagesCount={pagesCount}
            totalCount={totalCount}
            onPageChange={onPageChange}
            sortableColumns={['date', 'payment_date']}
            onSort={requestSort}
            sort={sortConfig?.key}
            sortDirection={sortConfig?.direction}
            maxHeight={isAdmin ? 'calc(100vh - 420px)' : 'calc(100vh - 270px)'}
            handlers={{
              // download: () => {},
              pay: id => isAdmin ? setOpenModal(id) : handlePay(id)
            }}
          />
        </Box>
      ) : (
        <Box mt={26} flexGrow={1}>
          <NoResultsFound>
            The list of invoices will appear here
          </NoResultsFound>
        </Box>
      )}
      <Modal
        open={!!openModal}
        onClose={() => setOpenModal(null)}
        title="Pay Invoice"
        mainText="Are you sure you want to mark the invoice as paid?"
        propsClassses={classes.modalButtonWidth}
        actions={
          [
            {
              type: 'main',
              label: 'No',
              action: () => setOpenModal(null),
            },
            {
              type: 'secondary',
              label: 'Yes',
              action: () => handlePay(openModal),
            },
          ]
        }
        actionsDirection="row"
      />
    </Box>
  )
}

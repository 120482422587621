import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  Card,
  CardContent,
  IconButton,
  Box,
  MenuItem,
  Collapse,
  Switch,
  Typography,
  FormControlLabel
} from '@material-ui/core';
import Select from '@components/inputs/filled-select';
import {makeStyles} from '@material-ui/core/styles';
import {
  InfoOtherIcon,
  RetriveIcon,
  AddNoteIcon,
  CloseIcon
} from '@app/icons';
import RiskRatingScale from './scale';
import CustomTooltip from '@components/controls/tooltip';
import FilledTextField from '@components/inputs/filled-input';
import CustomChip from '@components/controls/custom-chip';
import CardComment from '@components/layout/card-comments';
//import MenuWithContext from "@components/dropdowns/menu-with-context";
import CustomIconButton from '@components/buttons/button-with-icon';
import clsx from 'clsx';
import debounce from 'awesome-debounce-promise';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  sum: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '8px',
    lineHeight: '9px',
    color: '#A4A4A4'
  },
  sumIcon: {
    width: '12px',
    height: '12px'
  },
  card: {
    borderRadius: '12px',
    background: '#FFFFFF'
  },
  cardHasError: {
    border: '1px solid #B6514C',
  },
  label: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    color: '#232323',
    flexGrow: 1
  },
  value: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    color: '#232323',
    cursor: 'pointer',
    flexGrow: 1
  },
  filledInput: {
    // '& .MuiInput-root': {
    //     borderRadius: '8px',
    //     border: '1px solid transparent',
    //     '&.Mui-focused': {
    //         border: '1px solid #A4A4A4'
    //     }
    // }
  },
  error: {
    color: theme.palette.semantic.error
  },
  cardValid: {
    background: 'linear-gradient(317deg, #FFFFFF 88%, rgb(230, 252, 221, 0.75) 95%)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 200px'
  }
}))

const RiskRatingCard = ({id, label, type, selectOptions, toggleOptions = ['No', 'Yes'],
  value, setValue, setBatchValues, excluded, setExcluded, notes, setNotes,
  riskValue, setRiskValue, weightValue, setWeightValue, useDefaultPerQuestion,
  readOnly, setPendingExcludeID, setPendingNoteDeleteID, fixedRiskValue,
                                           error, setError}) => { //eslint-disable-line
  // Type: select, toggle, text

  const classes = useStyles()

  useEffect(() => {
    // Lock the notes
    lockNotes()
    }, [])//eslint-disable-line

  const sum = useMemo(() => {
    return riskValue * weightValue
    }, [riskValue, weightValue])//eslint-disable-line

  const noteRef = useRef()

  const [internalValue, setInternalValue] = useState(value)

  //eslint-disable-next-line
    const debouncedSetBatchValues = useCallback(
    debounce(setBatchValues, 500),
    [setBatchValues]
  )

  const onInternalValueChange = (newValue) => {
    setInternalValue(newValue)

    const batchValues = {
      value: newValue,
      error: null,
    }
    if (useDefaultPerQuestion) {
      let option
      if (type === 'toggle') { // Assuming BOOLEAN
        option = selectOptions.find(e => e.value === (newValue === 1 ? 'true' : 'false'))
      } else {
        option = selectOptions.find(e => e.value === newValue)
      }

      batchValues.riskValue = option?.riskValue || null;
      setRiskValue(option.riskValue);
      batchValues.weightValue = option?.weightValue || null;
      setWeightValue(option.weightValue);
    }

    debouncedSetBatchValues(batchValues)
  }

  const lockNotes = () => {
    if(notes && notes.length) {
      setNotes([...notes].reduce((acc, note) => {
        note.locked = true
        acc.push(note)
        return acc
      }, []))
    }
  }

  const valueText = useMemo(() => {
    if(type === 'select') {
      return selectOptions?.find(option => option.value === internalValue)?.label || ''
    }
    if(type === 'toggle') {
      return toggleOptions[internalValue]
    }
    return internalValue
    }, [internalValue])//eslint-disable-line

  const onInclude = () => {
    setExcluded(0)
  }

  const onExclude = () => {
    setPendingExcludeID(id)
  }

  const onAddNote = () => {
    const notesClone = [
      ...notes,
      {note: ''}
    ]
    setNotes(notesClone)
    setTimeout(() => {
      if(noteRef.current){
        noteRef.current.focus()
      }
    }, 500)
  }

  const onDeleteNote = index => () => {
    // This assumes that card have only one note, uncomment and adjust code below to use multiple on demand
    setPendingNoteDeleteID(id)
    // const notesClone = [...notes]
    // notesClone.splice(index)
    // setNotes(notesClone)
    // if(notesOpen) {
    //     onToggleNotes()
    // }
  }

  const onEditNote = index => () => {
    lockNotes()
    const notesClone = [...notes]
    notesClone[index].locked = false
    setNotes(notesClone)
  }

  const setNote = index => (value) => {
    const notesClone = [...notes]
    notes[index].note = value
    setNotes(notesClone)
  }

  const composeNoteTitle = note => {
    // return !!note.createdOn && !!note?.locked
    //     ? `Me, ${moment.utc(note.createdOn).local().format('DD/MM/YYYY')}`
    //     : 'Me'
    return 'Me'
  }

  const isValid = useMemo(() => !excluded
            && !(['text', 'select'].includes(type) && (_.isNil(value) || value.trim() === ''))
            && riskValue !== null
            && weightValue !== null
  , [type, value, riskValue, weightValue, excluded])

  return (
    <React.Fragment>
      <Box display={'flex'} flexDirection={'column'}>
        <Box>
          <Card className={clsx(classes.card, error && classes.cardHasError, isValid && classes.cardValid)}>
            <CardContent style={{paddingBottom:'16px'}}>
              <Box display={'flex'}>
                <Box display={'flex'} justifyContent={!excluded? 'flex-start': 'center'} flexDirection={'column'} flexGrow={1}>
                  <Box
                    display={'flex'}
                  >
                    <Box className={classes.label}>{label}</Box>
                    <Box>
                      { !readOnly && !excluded &&
                          <Box display={'flex'} alignItems={'flex-start'}>
                            <CustomTooltip
                              title={'Exclude'}
                              placement="top"
                            >
                              <IconButton
                                onClick={onExclude}
                                style={{ padding: 0 }}
                              >
                                <CloseIcon/>
                              </IconButton>
                            </CustomTooltip>
                            {/*<Box display={'flex'}>*/}
                            {/*    <MenuWithContext>*/}
                            {/*        <MenuItem onClick={onExclude}>*/}
                            {/*            Exclude*/}
                            {/*        </MenuItem>*/}
                            {/*    </MenuWithContext>*/}
                            {/*</Box>*/}
                          </Box>
                      }
                    </Box>
                  </Box>
                  { !excluded && !readOnly && type !== 'none' &&
                      <Box display={'flex'} mt={1}>
                        { type === 'toggle' &&
                              <Box display={'flex'} my={-1}>
                                <FormControlLabel
                                  classes={{label: classes.value}}
                                  label={!!internalValue? 'Yes': 'No'}
                                  control={
                                    <Switch
                                      checked={!!internalValue}
                                      onChange={event => onInternalValueChange(event.target.checked? 1: 0)}
                                    />
                                  }
                                />
                              </Box>
                        }
                        { type === 'text' &&
                              <FilledTextField className={classes.filledInput} fullWidth value={internalValue} onChange={event => onInternalValueChange(event.target.value)} />
                        }
                        {type === 'select' &&
                              <Select
                                value={internalValue}
                                onChange={event => onInternalValueChange(event.target.value)}
                                SelectProps={{
                                  displayEmpty:true
                                }}
                                InputProps={{
                                  style: {
                                    minWidth: '120px'
                                  }
                                }}
                              >
                                <MenuItem
                                  value=""
                                  disabled
                                  key={`${id}-null`}
                                >
                                      Select
                                </MenuItem>
                                {selectOptions.map(option => <MenuItem
                                  value={option.value}
                                  key={`${id}-${option.value}`}>
                                  {option.label}
                                </MenuItem>
                                )}
                              </Select>
                        }
                      </Box>
                  }
                  { !!readOnly && !excluded && type !== 'none' &&
                      <Box display={'flex'} mt={1} className={classes.value}>
                        {valueText}
                      </Box>
                  }
                </Box>
                { !!excluded &&
                    <Box display={'flex'} mr={-1} alignItems={'flex-start'}>
                      <Box display={'flex'} alignItems={'center'}>
                        <Box display={'flex'}>
                          <CustomChip label={'EXCLUDED'}/>
                        </Box>
                        <Box display={'flex'} ml={1}>
                          <CustomTooltip
                            title={!readOnly? 'Include': 'You can only preview data in completed risk ratings.'}
                            placement="top"
                          >
                            <Box>
                              <IconButton
                                size={'small'}
                                onClick={onInclude}
                                disabled={readOnly}
                              >
                                <RetriveIcon />
                              </IconButton>
                            </Box>
                          </CustomTooltip>
                        </Box>
                      </Box>
                    </Box>
                }
              </Box>
              { !excluded &&
                  <React.Fragment>
                    <Box display={'flex'} my={2}>
                      <RiskRatingScale
                        id={id}
                        riskValue={riskValue}
                        setRiskValue={setRiskValue}
                        weightValue={weightValue}
                        setWeightValue={setWeightValue}
                        fixedRiskValue={fixedRiskValue}
                        readOnly={readOnly}
                      />
                    </Box>
                    <Box display={'flex'} justifyContent={'space-between'} flexDirection={'row'} mt={1}>
                      <Box display={'flex'}>
                        { !notes.length && !readOnly &&
                              <CustomIconButton
                                startIcon={<AddNoteIcon />}
                                onClick={() => onAddNote()}
                              >Add Note</CustomIconButton>
                        }
                      </Box>
                      <CustomTooltip title={'Sum is the Risk Value multiplied by the Question Weight.'} placement="top">
                        <Box display={'flex'} alignItems={'center'} className={classes.sum}>
                          <InfoOtherIcon className={classes.sumIcon}/>
                                  SUM = {sum}
                        </Box>
                      </CustomTooltip>
                    </Box>
                    <Box display={'flex'} flexGrow={1} mt={1}>
                      <Collapse in={!!notes.length && !excluded} style={{width: '100%'}}>
                        {notes && notes.map((note, index) => (
                          <Box display={'flex'} flexGrow={1} key={`all-comments-${index}`}>
                            <CardComment
                              title={composeNoteTitle(note)}
                              comment={note.note}
                              placeholder={'Enter your note here'}
                              handleDeleteComment={!readOnly && onDeleteNote(index)}
                              handleEditComment={!readOnly && !!note?.locked && onEditNote(index)}
                              inlineEdit={!note?.locked}
                              setComment={setNote(index)}
                              fullWidth
                              readonly={readOnly}
                              maxLength={2000}
                              ref={noteRef}
                            />
                          </Box>
                        ))}
                      </Collapse>
                    </Box>
                  </React.Fragment>
              }
            </CardContent>
          </Card>
        </Box>
        { error && !excluded &&
                    <Box display={'flex'} justifyContent={'center'} mt={1}>
                      <Typography variant={'caption'} className={classes.error}>
                        {error}
                      </Typography>
                    </Box>
        }
      </Box>
    </React.Fragment>
  )
}

export default RiskRatingCard
const initialState = {
  monitorySort: '',
  monitoryFilters: null,
  monitorySortDirection: '',
  selectedIds: [],
  searchSettings: {},
};

const ongoingMonittoring = (state = initialState, action) => {

  switch (action.type) {
    case 'OM_SET_DEFAULT':
      return initialState;
    case 'OM_SET_SORT_FILTERS':
      return {
        ...state,
        monitorySort: action.sort,
        monitoryFilters: action.filters,
        monitorySortDirection: action.sortDirection,
      };
    case 'OM_SET_SELECTED_IDS':
      return {
        ...state,
        selectedIds: action.selectedIds,
      };
    case 'OM_SET_SEARCH_SETTINGS':
      return {
        ...state,
        searchSettings: action.searchSettings,
      };
    default:
      return state
  }
};

export default ongoingMonittoring;

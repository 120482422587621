import * as yup from 'yup';
import { FIELD_TYPES } from './constants';

export default function parseYupErrors(err) {
  const inner = err.inner;
  const errors = {};
  for( const e of inner) {
    errors[e.path] = (e.message.charAt(0).toUpperCase() + e.message.slice(1))
      .replace('_', ' ');
  }
  return errors;
}

export function convertFieldsToYupObject(fields) {
  const yupObj = {}

  const generateYupFromField = (field) => {
    let yupItem;
    switch (field.type) {
      case FIELD_TYPES.DATE:
        yupItem = yup.date()
          .nullable()
          .transform((curr, orig) => orig === '' ? null : curr)
          .typeError('Invalid date format')
        break;

      case FIELD_TYPES.EMAIL:
        yupItem = yup.string().trim().email('Invalid email format')
        break;

      case FIELD_TYPES.NUMBER:
        yupItem = yup.number().transform(value => (isNaN(value) ? undefined : value))
        break;

      default:
        yupItem = yup.string().trim()
        break;
    }

    if (field.label) {
      yupItem = yupItem.label(field.label)
    }
    if (field.required) {
      yupItem = yupItem.required()
    }
    if (field.size) {
      yupItem = yupItem.max(field.size)
    }
    if (field.tests) {
      field.tests.forEach(testFunc => {
        yupItem = testFunc(yupItem)
      })
    }

    return yupItem
  }

  fields.forEach(field => {
    yupObj[field.key] = generateYupFromField(field)
  })

  return yup.object().shape(yupObj);
}
